import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import Button from "../../button";
import {
  useDeleteQuery,
  useSingleGetEmployeeQueries,
} from "../../../redux/human-resources/hook/queries";
import AddQuery from "../../modal/hr-section/queries/add-query";
import { useParams } from "react-router-dom";

const EmployeeQueriesTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const [showAddQuery, setShowAddQuery] = useState(false);
  const [queryData, setQueryData] = useState();

  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const { mutateAsync: deleteQuery } = useDeleteQuery();

  const { id } = useParams();

  const { data: queries } = useSingleGetEmployeeQueries(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const tableConfig = {
    headers: ["Date Added", "Employee Name", "Query"],
    keys: ["date_created", "employeeName", "query"],
    mobileHeader: {
      left: {
        title: "Date Added",
        key: "date_created",
      },
      right: {
        title: "Employee Name",
        key: "employeeName",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "green",
      action: (id) => {
        let index = queries.data.findIndex((item) => item.id === id);
        setQueryData(queries.data[index]);
        setTitle("Update Query");
        setButtonText("UPDATE");
        setShowAddQuery(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = queries.data.findIndex((item) => item.id === id);
        deleteQuery(queries.data[index].id);
      },
    },
  ];

  return (
    <div>
      {showAddQuery ? (
        <AddQuery
          isOpen={showAddQuery}
          closeModal={() => {
            setShowAddQuery(false);
          }}
          queryData={queryData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col bg-white rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Queries
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Query"
                type="submit"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => {
                  setTitle("Add Query");
                  setButtonText("Save");
                  setShowAddQuery(true);
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-2" />
        <DataTable
          data={queries}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default EmployeeQueriesTab;
