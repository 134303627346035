import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormSelect from "../../../components/form-select";
import EmployeeRecruitmentLayout from "../../../components/layouts/employee-recruitment";
import AddRecruitmentWorkflow from "../../../components/modal/hr-section/recruitment/add-workflow";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetRecruitmentOnboardingWorkflows,
  useGetRecruitmentWorkflow,
} from "../../../redux/human-resources/hook/recruitment";
import { setAssignWorkflow } from "../../../redux/human-resources/reducers/recruitment";
import { setAlert } from "../../../redux/components/components-slice";
import LoadingBar from "../../../components/loader-bar";
import AddSmallSVG from "../../../assets/svg/add-icon-small.svg";

function RecruitmentWorkflowPage() {
  useEffect(() => {}, []);

  const { assignWorkflowData } = useSelector((state) => state.recruitmentSlice);

  const dispatch = useDispatch();

  const history = useHistory();
  const [showAddWorkflow, setShowAddWorkflow] = useState(false);
  const [payLoad, setPayload] = useState({});
  const [loading, setLoading] = useState(false);
  const [workflowId, setWorkflowId] = useState();

  const [workflow, setWorkflow] = useState();

  const { data: workflow_info, isLoading } =
    useGetRecruitmentWorkflow(workflowId);

  useEffect(() => {
    if (workflow_info) {
      setWorkflow(workflow_info);
    }
  }, [workflow_info]);

  useEffect(() => {
    if (assignWorkflowData) {
      setWorkflowId(assignWorkflowData.workflow_id);
      setPayload(JSON.parse(JSON.stringify(assignWorkflowData)));
    }
  }, [assignWorkflowData]);

  const { data: workflows } = useGetRecruitmentOnboardingWorkflows(1000, 1, "");

  const submitForm = async () => {
    if (!workflowId) {
      dispatch(setAlert(true, "error", "Please select a workflow to continue"));
      return;
    }

    setLoading(true);

    let newData = { ...payLoad, workflow_id: workflowId };

    setLoading(false);

    dispatch(setAssignWorkflow(newData));

    history.push("/recruitment/add-questions");
  };
  return (
    <EmployeeRecruitmentLayout step={3} title={"Assign Recruitment Workflow"}>
      <AddRecruitmentWorkflow
        title="Add recruitment Workflow"
        isOpen={showAddWorkflow}
        closeModal={() => setShowAddWorkflow(false)}
        buttonText="ADD"
        refresh={() => {}}
      />
      <div className="flex flex-col w-full h-fit">
        <span className="header-4">Assign a workflow</span>
        <hr className="divider" />
        <div className="mt-3">
          {workflows ? (
            <FormSelect
              options={workflows}
              value={workflowId}
              readOnly={workflows ? false : true}
              onChange={(selected) => {
                setWorkflow(null);
                setWorkflowId(selected);
              }}
              label="Recruitment Workflows"
            />
          ) : (
            <div className="p4-medium text-color-black">
              Click link below to add a new workflow.
              <hr className="divider" />
            </div>
          )}
          <div className="flex gap-2 items-center justify-left w-full mt-3">
            <img
              src={AddSmallSVG}
              alt={"add_workflow"}
              className={"h-[15px] w-[15px]"}
            />
            <span
              className="p2-medium text-[#0d5225] cursor-pointer hover:underline duration-75 text-[14px] italic"
              onClick={() => setShowAddWorkflow(true)}
            >
              Add New Workflow
            </span>
          </div>
          <hr className="divider" />
          <div className="mt-2 grid md:grid-cols-1 grid-cols-1 gap-4 w-full mb-1">
            <div className="flex flex-col mb-2">
              <span className="header-5 text-color-gray">
                Recruitment Stages
              </span>
            </div>
          </div>
          {isLoading ? (
            <LoadingBar loading={isLoading} />
          ) : (
            <>
              {workflow ? (
                <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full border-2 p-2 rounded mt-2">
                  <div className="flex flex-col">
                    <div className="flex flex-row gap-4">
                      <span className="header-5 w-[30%] text-color-gray"></span>
                      <span className="header-5 w-[40%] text-[#0d5225]">
                        Title
                      </span>
                      <span className="header-5 w-[25%] text-[#0d5225]">
                        Type
                      </span>
                    </div>
                    {workflow?.sub_workflows.map((item) => (
                      <div className="flex flex-row gap-4">
                        <div className="header-5 w-[30%]">
                          Stage {item.index}:{" "}
                        </div>
                        <div className="text-[14px] w-[40%]">{item.name}</div>
                        <div className="header-6 w-[25%]">{item.type}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="italic text-center text-primary-3">
                  Stages not loaded
                </div>
              )}
            </>
          )}
          <hr className="divider" />
          <div className="w-full mt-[20px]">
            <Button
              text="Save and Continue"
              type="submit"
              loading={loading}
              className="!h-[62px]"
              onClick={() => submitForm()}
            />
          </div>
          <div
            className="flex justify-center w-full cursor-pointer"
            onClick={() => history.goBack()}
          >
            <span className="p2-medium text-color-black mt-3">Go Back</span>
          </div>
        </div>
      </div>
    </EmployeeRecruitmentLayout>
  );
}

export default RecruitmentWorkflowPage;
