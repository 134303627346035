import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { payroll } from '../../../api/payroll/payroll';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { formatNumber } from '../../../utils/functions';

export function useGetAdvancedPayroll(limit, page, statusFilter, search) {
  return useQuery(
    ['advanced-payrolls', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.getAdvancedPayrolls({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((payroll) => {
          payroll.formatted_month = moment(payroll.month).format('MMMM, YYYY');
          payroll.created = moment(payroll.created).format('MMMM DD, YYYY');
          payroll.status = payroll.status ? parseFloat(payroll.status) : 0;

          if (payroll.status == 2 ) {
            if (payroll.payments && payroll.payments.length > 0) {
              const payrollPayment = payroll.payments;
              let initiated = false
              for (let i = 0; i < payrollPayment.length; i++) {
                if (payrollPayment[i].status === 2) {
                  initiated = true;
                  break
                }
              }
              if (!initiated) {
                payroll.status = 7
              }
            }
          }

          payroll.formatted_total_amount = payroll.total_amount ? formatNumber(parseFloat(payroll.total_amount).toFixed(2), 2): 0;
          if (payroll.status !== 5){
            switch (payroll.status) {
              case 0:
                payroll.computedActions = [0, 3, 4, 7, 8];
                break;
              case 1:
                payroll.computedActions = [2, 3, 7];
                break;
              case 2:
                payroll.computedActions = [3, 5, 6, 7];
                break;
              case 3:
                payroll.computedActions = [1, 3, 4, 7, 8];
                break;
              case 7:
                payroll.computedActions = [9, 3, 7];
                break;
              default:
                break;
            }
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetBranchAdvancedPayroll(id, limit, page, statusFilter, search) {
  return useQuery(
    ['branch-advanced-payrolls', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.getBranchAdvancedPayrolls({ id, limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((payroll) => {
          let month = moment(payroll.month).format('MMMM, YYYY');
          payroll.month = month;
          payroll.created = moment(payroll.created).format('MMMM DD, YYYY');
          payroll.status = payroll.status?parseFloat(payroll.status): 0;
          payroll.formatted_total_amount = payroll.total_amount? formatNumber(parseFloat(payroll.total_amount).toFixed(2), 2): 0

          switch (payroll.status) {
            case 0:
              payroll.computedActions = [0, 2, 3, 6];
              break;
            case 1:
              payroll.computedActions = [1, 2, 6];
              break;
            case 2:
              payroll.computedActions = [2, 4, 5, 6];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetBasicPayroll(limit, page, statusFilter, search) {
  return useQuery(
    ['basic-payrolls', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.getBasicPayrolls({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((payroll) => {
          payroll.created = moment(payroll.created).format('MMMM DD, YYYY');
          let month = moment(payroll.month).format('MMMM, YYYY');
          payroll.month = month;
          payroll.status = payroll.status?parseFloat(payroll.status): 0;
          payroll.formatted_total_amount = payroll.total_amount? formatNumber(parseFloat(payroll.total_amount).toFixed(2), 2): 0

          switch (payroll.status) {
            case 0:
              payroll.computedActions = [0, 2, 4];
              break;
            case 1:
              payroll.computedActions = [1];
              break;
            case 2:
              payroll.computedActions = [3];
              break;
            // case 3:
            //   payroll.computedActions = [1];
            //   break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetPayrollHistory(
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date,
) {
  return useQuery(
    [
      'payroll-history',
      { limit, page, statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.getPayrollHistory({
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((transaction) => {
          transaction.status = parseFloat(transaction.status);
          transaction.formatted_amount = transaction.amount ? formatNumber(parseFloat(transaction.amount).toFixed(2)): 0
          // transaction.amount = parseFloat(transaction.amount.toFixed(2));
          transaction.created = moment(transaction.created).format('MMMM DD, YYYY h:m:s')
          switch (transaction.status) {
            case 0:
              transaction.computedActions = [0];
              break;
            case 2:
              transaction.computedActions = [1, 2];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useRefBasicPayroll(refId, limit, page, search) {
  return useQuery(
    ['basic-payroll-by-ref', { refId, limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.fetchBasicPayrollByRef({
        refId,
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {
          item.formatted_net_pay = formatNumber(parseFloat(item.net_pay))
          item.computedActions = [0,1,2]
          item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
          item.trans_cost
            ? (item.trans_cost = parseFloat(item.trans_cost))
            : (item.trans_cost = 44.99);
          item.total = formatNumber((
            item.trans_cost + parseFloat(item.net_pay)
          ), 2);
          if (!item.status) {
            item.status = 0
          }
        });
        if (data) {
          data.formatted_total_net_pay = formatNumber(parseFloat(data?.total_net_pay), 2);
          data.formatted_total_trans_cost = formatNumber(parseFloat(data?.total_trans_cost), 2);
          data.formatted_total_payable = formatNumber(parseFloat(data?.total_payable), 2);
        }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useRefBasicPayrollView(refId, limit, page, statusFilter, search) {
  return useQuery(
    ['basic-payroll-by-ref', { refId, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.fetchBasicPayrollByRef({
        refId,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {

          if (item.processed === 1) {
            if (item.status === 2) {
              item.computedActions = [1,2]
            } else if (item.status === 0) {
              item.computedActions = [0,1]
            } else if (item.status === 1) {
              item.computedActions = [1]
            }
          }
          item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
          item.formatted_net_pay = formatNumber(parseFloat(item.net_pay))
          item.trans_cost
            ? (item.trans_cost = parseFloat(item.trans_cost))
            : (item.trans_cost = 44.99);
          item.total = formatNumber((
            item.trans_cost + parseFloat(item.net_pay)
          ), 2);
          if (!item.status) {
            item.status = 0
          }
        });
        if (data) {
          data.total_net_pay = data?.total_net_pay ? formatNumber(parseFloat(data?.total_net_pay), 2) : 0;
          data.total_trans_cost = data?.total_trans_cost ? formatNumber(parseFloat(data?.total_trans_cost), 2) : 0;
          data.total_payable = data?.total_payable ? formatNumber(parseFloat(data?.total_payable), 2) : 0;
        }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useRefAdvancedPayroll(
  refId,
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    ['advanced-payroll-by-ref', { refId, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.fetchAdvancedPayrollByRef({
        refId,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {

          let additional_allowance = 0;
          let additional_allowances = JSON.parse(item.additional_allowances)
          if (additional_allowances && additional_allowances.length > 0) {
            additional_allowances.forEach(item => {
              additional_allowance += item.amount
            });
          }
          item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
          item.formatted_basic_salary = item.basic_salary ? formatNumber(parseFloat(item.basic_salary).toFixed(2), 2) : 0
          item.formatted_transport_allowance = item.transport_allowance ? formatNumber(parseFloat(item.transport_allowance).toFixed(2), 2)  : 0
          item.formatted_housing_allowance = item.housing_allowance ? formatNumber(parseFloat(item.housing_allowance).toFixed(2), 2) : 0
          item.formatted_gross_pay = item.gross_pay ? formatNumber(parseFloat(item.gross_pay).toFixed(2), 2) : 0
          item.formatted_tax = item.tax ? formatNumber(parseFloat(item.tax).toFixed(2), 2) : 0
          item.formatted_pension = item.pension ? formatNumber(parseFloat(item.pension).toFixed(2), 2)  : 0
          item.formatted_net_pay = item.net_pay ? formatNumber(parseFloat(item.net_pay).toFixed(2), 2) : 0
          item.other_accum_allowance =
          parseFloat(item.basic_salary) +
          parseFloat(item.benefit_in_kind) +
          parseFloat(item.bonus) +
          parseFloat(item.housing_allowance) +
          parseFloat(item.transport_allowance) +
          parseFloat(item.leave_allowance) +
          parseFloat(item.lunch_allowance) +
          parseFloat(item.other_allowance) +
          parseFloat(item.utility_allowance) +
          additional_allowance;
          item.formatted_other_accum_allowance = formatNumber(parseFloat(item.other_accum_allowance).toFixed(2), 2)
          item.bank_info = `${item.bank_name}`;
          item.trans_cost = item.trans_cost ? parseFloat(item.trans_cost) : 0;
          item.total = formatNumber((
            item.trans_cost + parseFloat(item.net_pay)
          ), 2);

          item.status = parseInt(item.status);
          item.basic_salary = parseFloat(item.basic_salary);
          item.benefit_in_kind = parseFloat(item.benefit_in_kind);
          item.bonus = parseFloat(item.bonus);
          item.housing_allowance = parseFloat(item.housing_allowance);
          item.transport_allowance = parseFloat(item.transport_allowance);
          item.leave_allowance = parseFloat(item.leave_allowance);
          item.lunch_allowance = parseFloat(item.lunch_allowance);
          item.other_allowance = parseFloat(item.other_allowance);
          item.utility_allowance = parseFloat(item.utility_allowance);
          item.gross_pay = parseFloat(item.gross_pay);
          item.pension = parseFloat(item.pension);
          item.employer_pension = parseFloat(item.employer_pension);
          item.salary_advance = parseFloat(item.salary_advance);
          item.tax = parseFloat(item.tax);
          item.nhf = parseFloat(item.nhf);
          item.nsitf = parseFloat(item.nsitf);
          item.itf = parseFloat(item.itf);
          item.nhis = parseFloat(item.nhis);
          item.employer_nhis = parseFloat(item.employer_nhis);
          item.reimburse = parseFloat(item.reimburse);
          item.net_pay = parseFloat(item.net_pay);
          
          switch (item.status) {
            case 0:
              item.computedActions = [0, 1, 2, 3, 4];
              break;
            case 1:
              item.computedActions = [2, 3];
              break;
            case 2:
              item.computedActions = [1, 2, 3, 4];
              break;
            default:
              break;
          }
        });
        if (data) {
          data.formatted_total_net_pay = data?.total_net_pay ? formatNumber(parseFloat(data?.total_net_pay).toFixed(2), 2) : 0;
          data.formatted_total_trans_cost = data?.total_trans_cost ? formatNumber(parseFloat(data?.total_trans_cost).toFixed(2), 2) : 0;
          data.formatted_total_payable = data?.total_payable ? formatNumber(parseFloat(data?.total_payable).toFixed(2), 2) : 0;
        }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useFetchAdvancedPayrollById(
  id,
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    ['advanced-payroll-by-id', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.fetchAdvancedPayrollById({
        id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {
          if (!item.status) {
            item.status = 0
          }
          let additional_allowance = 0;
          let additional_allowances = JSON.parse(item.additional_allowances)
          if (additional_allowances && additional_allowances.length > 0) {
            additional_allowances.forEach(item => {
              additional_allowance += item.amount
            });
          }
          item.formatted_basic_salary = item.basic_salary ? formatNumber(parseFloat(item.basic_salary).toFixed(2), 2) : 0
          item.formatted_transport_allowance = item.transport_allowance ? formatNumber(parseFloat(item.transport_allowance).toFixed(2)) : 0 
          item.formatted_housing_allowance = item.housing_allowance ? formatNumber(parseFloat(item.housing_allowance).toFixed(2)) : 0
          item.formatted_gross_pay = item.gross_pay ? formatNumber(parseFloat(item.gross_pay).toFixed(2)) : 0
          item.formatted_tax = item.tax ? formatNumber(parseFloat(item.tax).toFixed(2)) : 0
          item.formatted_pension = item.pension ? formatNumber(parseFloat(item.pension).toFixed(2)) : 0 
          item.formatted_net_pay = item.net_pay ? formatNumber(parseFloat(item.net_pay).toFixed(2)) : 0
          item.other_accum_allowance =
            parseFloat(item.basic_salary) +
            parseFloat(item.benefit_in_kind) +
            parseFloat(item.bonus) +
            parseFloat(item.housing_allowance) +
            parseFloat(item.transport_allowance) +
            parseFloat(item.leave_allowance) +
            parseFloat(item.lunch_allowance) +
            parseFloat(item.other_allowance) +
            parseFloat(item.utility_allowance) +
            additional_allowance;
          item.bank_info = `${item.bank_name}`;
          item.formatted_trans_cost = item.trans_cost ? parseFloat(item.trans_cost) : 0;
          item.formatted_other_accum_allowance = item.other_accum_allowance ? formatNumber(parseFloat(item.other_accum_allowance).toFixed(2)) : 0
          item.total = formatNumber((
            item.trans_cost + parseFloat(item.net_pay.toFixed(2))
          ), 2);

          item.status = parseInt(item.status);
          item.basic_salary = parseFloat(item.basic_salary);

          item.employeeName = `${item.employee_first_name} ${item.employee_last_name}`;
          item.other_accum_allowance = parseFloat(
            item.other_accum_allowance,
          );
          item.basic_salary = parseFloat(item.basic_salary);
          item.benefit_in_kind = parseFloat(item.benefit_in_kind);
          item.bonus = parseFloat(item.bonus);
          item.housing_allowance = parseFloat(item.housing_allowance);
          item.transport_allowance = parseFloat(item.transport_allowance);
          item.leave_allowance = parseFloat(item.leave_allowance);
          item.lunch_allowance = parseFloat(item.lunch_allowance);
          item.other_allowance = parseFloat(item.other_allowance);
          item.utility_allowance = parseFloat(item.utility_allowance);
          item.gross_pay = parseFloat(item.gross_pay);
          item.pension = parseFloat(item.pension);
          item.employer_pension = parseFloat(item.employer_pension);
          item.salary_advance = parseFloat(item.salary_advance);
          item.tax = parseFloat(item.tax);
          item.nhf = parseFloat(item.nhf);
          item.nsitf = parseFloat(item.nsitf);
          item.itf = parseFloat(item.itf);
          item.nhis = parseFloat(item.nhis);
          item.employer_nhis = parseFloat(item.employer_nhis);
          item.reimburse = parseFloat(item.reimburse);
          item.net_pay = parseFloat(item.net_pay);

          switch (item.status) {
            case 0:
              item.computedActions = [0, 1, 2];
              break;
            case 1:
              item.computedActions = [1, 2];
              break;
            case 2:
              item.computedActions = [1, 2, 3];
              break;
            default:
              break;
          }
        });
        // if (data) {
        //   data.total_net_pay = data?.total_net_pay, 2);
        //   data.total_trans_cost = data?.total_trans_cost, 2);
        //   data.total_payable = data?.total_payable, 2);
        // }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useExportAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setAlert(true, 'success', 'Fetching Export Data...'));
      return payroll.fetchAdvancedPayrollByRef(payload);
    },
    {
      onSuccess: (data) => {
        data?.data?.forEach((payroll) => {
          payroll.formatted_basic_salary = payroll.housing_allowance ?  parseFloat(payroll.basic_salary) : 0
          payroll.formatted_housing_allowance = payroll.housing_allowance ? parseFloat(payroll.housing_allowance) : 0
          payroll.formatted_transport_allowance = payroll.transport_allowance ? parseFloat(payroll.transport_allowance) : 0
          payroll.formatted_leave_allowance = payroll.leave_allowance ? parseFloat(payroll.leave_allowance) : 0
          payroll.formatted_lunch_allowance = payroll.lunch_allowance ? parseFloat(payroll.lunch_allowance) : 0
          payroll.formatted_benefit_in_kind = payroll.benefit_in_kind ? parseFloat(payroll.benefit_in_kind) : 0
          payroll.formatted_utility_allowance = payroll.utility_allowance ? parseFloat(payroll.utility_allowance) : 0
          payroll.formatted_other_allowance = payroll.other_allowance ? parseFloat(payroll.other_allowance) : 0
          payroll.formatted_bonus = payroll.bonus ? parseFloat(payroll.bonus) : 0
          payroll.formatted_gross_pay = payroll.gross_pay ? parseFloat(payroll.gross_pay) : 0
          payroll.formatted_pension = payroll.pension ? parseFloat(payroll.pension) : 0
          payroll.formatted_tax = payroll.tax ? parseFloat(payroll.tax) : 0
          payroll.formatted_nhf = payroll.nhf ? parseFloat(payroll.nhf) : 0
          payroll.formatted_nhis = payroll.nhis ? parseFloat(payroll.nhis) : 0
          payroll.formatted_salary_advance = payroll.salary_advance ? parseFloat(payroll.salary_advance) : 0
          payroll.formatted_reimburse = payroll.reimburse ? parseFloat(payroll.reimburse) : 0
          payroll.formatted_net_pay = payroll.net_pay ? parseFloat(payroll.net_pay) : 0
          payroll.formatted_employer_pension = payroll.employer_pension ? parseFloat(payroll.employer_pension) : 0
          payroll.formatted_itf = payroll.itf ? parseFloat(payroll.itf) : 0
          payroll.formatted_nsitf = payroll.nsitf ? parseFloat(payroll.nsitf) : 0
        })
        store.dispatch(setAlert(true, 'success', 'Payroll data fetched Successfully'));
       
        return data;
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRecalculateAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.recalculateAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', 'Advanced Payroll recalculated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleRecalculateAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.singleRecalculateAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', 'Advanced Payroll salary recalculated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useExportAnnualReport() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.exportAnnualReport(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunBasicPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runBasicPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', 'Basic payroll run successfully. Proceed to make payment'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunBasicPayrollGroup(payload) {
  return useMutation(
    () => {
      store.dispatch(setIsLoading(true));
      return payroll.runBasicPayrollGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', 'Basic payroll run successfully. Proceed to make payment'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunBasicPayrollSelective() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runBasicPayrollSelective(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', 'Basic payroll run successfully. Proceed to make payment'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunBasicPayrollExcel() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runBasicPayrollExcel(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', 'Basic payroll run from file successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAdvancedPayrollCheck() {
  return useMutation(
    (payload) => {
      return payroll.advancedPayrollCheck(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Advanced payroll charges computed successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAdvancedPayrollCheckGroup() {
  return useMutation(
    (payload) => {
      return payroll.advancedPayrollCheckGroup(payload);
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAdvancedPayrollCheckBranch() {
  return useMutation(
    (payload) => {
      return payroll.advancedPayrollCheckBranch(payload);
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAdvancedPayrollCheckSelective() {
  return useMutation((payload) => {
    return payroll.advancedPayrollCheckSelective(payload);
  },
  {
    onSettled: (data, error, variables, context) => {
      store.dispatch(setIsLoading(false));
    },
  },);
}

export function useDeleteAdvancedPayroll(payload) {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.deleteAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', 'Advanced payroll deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteBasicPayroll(payload) {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.deleteBasicPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', 'Basic payroll deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendPayslip() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.sendBulkPayslip(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Payslips sent successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendSinglePayslip() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.sendSinglePayslip(payload);
    },
    {
      onSuccess: (response, variables, context) => {

        store.dispatch(setAlert(true, 'success', 'Payslip sent successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendSelectPayslip() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.sendSelectPayslip(payload);
    },
    {
      onSuccess: (response, variables, context) => {

        store.dispatch(setAlert(true, 'success', 'Payslips sent successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunAdvancedPayrollGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runAdvancedPayrollGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunAdvancedPayrollBranch() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runAdvancedPayrollBranch(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        queryClient.invalidateQueries('branch-advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunAdvancedPayrollSelective() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.runAdvancedPayrollSelective(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDisburseBasicPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.disburseBasicPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDisburseAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.disburseAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInitiateAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.initiateAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInitiateBasicPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.initiateBasicPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRevertPayroll() {
  return useMutation(
    () => {
      store.dispatch(setIsLoading(true));
      return {
        status: 'success',
        message: 'No Payroll With This Reference Number',
      };
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCheckTransactionStatus() {
  return useMutation(
    (payload) => {
      return payroll.checkTransactionStatus(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('payroll-history');
        queryClient.invalidateQueries('vendor-history');
        queryClient.invalidateQueries('wallet-history');
        store.dispatch(setAlert(true, 'success', 'Transaction Status Updated Successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRetryTransaction() {
  return useMutation(
    (payload) => {
      return payroll.retryTransaction(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('payroll-history');
        queryClient.invalidateQueries('vendor-history');
        queryClient.invalidateQueries('wallet-history');
        store.dispatch(setAlert(true, 'success', 'Transaction retried successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCheckBulkTransactionStatus() {
  return useMutation(
    () => {
      return payroll.refreshTransactionStatus();
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('payroll-history');
        store.dispatch(setAlert(true, 'success', 'All payment statuses refreshed successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRemoveBasicPayrollById() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.removeBasicPayrollById(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payroll-by-ref');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRemoveAdvancedPayrollById() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.removeAdvancedPayrollById(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('useRemoveBasicPayrollById');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditBasicPayrollById() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.editBasicPayrollById(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        // queryClient.invalidateQueries('basic-payroll-by-ref');
        store.dispatch(setAlert(true, 'success', 'Employee payment amount updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddBulkPayrollAdjustments() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.addPayrollAdjustments(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-bonuses');
        queryClient.invalidateQueries('employee-other-deductions');
        queryClient.invalidateQueries('employee-overtimes');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('employee-salary-advances');
        store.dispatch(setAlert(true, response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineBasicPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.declineBasicPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('basic-payrolls');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineAdvancedPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return payroll.declineAdvancedPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payrolls');
        store.dispatch(setAlert(true, 'success', 'Payroll declined successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
