import React, { useState } from "react";
import { useEffect } from "react";
import CloseSVG from "../../../assets/svg/close.svg";
import Button from "../../button";
import { useHistory } from "react-router-dom";
import Modal from "../modal";
import { useGetBanks, useResolveBankAccount } from "../../../redux/bank/hook";
import { useRetryToAnotherBank } from "../../../redux/wallet/hook";
import FormInput from "../../form-input";
import FormDatalist from "../../form-datalist";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../utils/functions";

export function RetryToAnotherBank({ isOpen, closeModal, transaction_id }) {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });
  const {
    mutateAsync: retryToAnotherBank,
    isLoading: retryToAnotherBankLoading,
  } = useRetryToAnotherBank();
  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } =
    useResolveBankAccount();
  const { data: banks } = useGetBanks();
  const [bankname, setBankName] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [account_name, setAccountName] = useState("");

  useEffect(() => {
    if (account_number.length >= 10 && bankname !== "") {
      let payload = {
        bankname,
        account_number,
      };
      setAccountName("");
      resolveAccount(payload)
        .then((response) => {
          setAccountName(response.data.accountname);
        })
        .catch(() => {
          setAccountName("Not Resolved");
        });
    }
  }, [account_number, bankname]);

  const submitForm = async (data) => {
    const requestData = {
      id: transaction_id,
      bank: bankname,
      account_number: account_number,
      account_name: account_name ? account_name : "Not Resolved",
    };

    await retryToAnotherBank(requestData).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className="!max-w-[600px] selection: h-full !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col min-h-fit overflow-auto">
        <div className="flex flex-row justify-between items-center pl-8 pr-8 pt-4">
          <span className="header-4">Retry Payment to Another Bank</span>
          <div className="cursor-pointer" onClick={() => closeModal()}>
            <img src={CloseSVG} alt="close" />
          </div>
        </div>
        <hr className="divider" />
        <form className="form " onSubmit={handleSubmit(submitForm)}>
          <div className="flex flex-col mx-8">
            <div className="flex flex-col md:flex-row justify-between">
              <div className="w-full md:w-1/2 md:pr-2">
                <FormDatalist
                  label="Bank Name"
                  data={banks ? banks : []}
                  value={bankname}
                  onSelect={(value) => {
                    setAccountNumber("");
                    setBankName(value);
                  }}
                />
              </div>
              <div className="w-full md:w-1/2 md:pl-2">
                <FormInput
                  label="Bank Account Number"
                  placeholder={"Enter Account Number"}
                  name="account_number"
                  type="text"
                  value={account_number}
                  error={errors.account_number}
                  errorMessage={
                    errors.account_number && errors.account_number.message
                  }
                  onInput={(e) => setAccountNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full">
              <FormInput
                label="Account Name (Auto-Generated)"
                name="account_name"
                type="text"
                placeholder={
                  resolveAccountLoading
                    ? "Fetching Account Name..."
                    : "Auto Generated"
                }
                readOnly
                value={account_name}
                error={errors.account_name}
                errorMessage={
                  errors.account_name && errors.account_name.message
                }
              />
            </div>
            <div className="w-full mb-[20px]">
              <Button
                text="RETRY PAYMENT"
                type="submit"
                loading={retryToAnotherBankLoading}
                disabled={!bankname || !account_number}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
