import React, { useEffect, useState } from "react";
import Button from "../../../../components/button";
import ButtonSelect from "../../../../components/button-select";
import { useHistory } from "react-router-dom";
import PerformanceEvaluationLayout from "../../../../components/layouts/performance-evaluation";
import { useRouteQuery } from "../../../../hooks/useRouteQuery";

function SelectEvaluationOptions() {
  useEffect(() => {}, []);

  const [evaluationOptions, setEvaluationOptions] = useState("basic");
  const [pip_id, setPipId] = useState();
  const [probation_id, setProbationId] = useState();
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState("");
  const [employee_id, setEmployeeId] = useState("");
  const history = useHistory();

  const query = useRouteQuery();
  useEffect(() => {
    if (query) {
      if (query.get("pip_id")) {
        setPipId(query.get("pip_id"));
        setProbationId(query.get("start_date"));
        setStartDate(query.get("start_date"));
        setEndDate(query.get("end_date"));
        setEmployeeId(query.get("employee_id"));
      } else if (query.get("probation_id")) {
        setProbationId(query.get("probation_id"));
        setStartDate(query.get("start_date"));
        setEndDate(query.get("end_date"));
        setEmployeeId(query.get("employee_id"));
      }
    }
  }, [query]);

  const submit = () => {
    if (evaluationOptions === "basic") {
      if (pip_id) {
        history.push(
          `/performance/evaluate/basic?pip_id=${pip_id}&start_date=${start_date}&end_date=${end_date}&employee_id=${employee_id}&first_name=${query.get(
            "first_name"
          )}&last_name=${query.get("last_name")}&mobile=${query.get(
            "mobile"
          )}&email=${query.get("email")}`
        );
      } else if (probation_id) {
        history.push(
          `/performance/evaluate/basic?probation_id=${probation_id}&start_date=${start_date}&end_date=${end_date}&employee_id=${employee_id}&first_name=${query.get(
            "first_name"
          )}&last_name=${query.get("last_name")}&mobile=${query.get(
            "mobile"
          )}&email=${query.get("email")}`
        );
      } else {
        history.push("/performance/evaluate/basic");
      }
    } else if (evaluationOptions === "advanced") {
      if (pip_id) {
        history.push(
          `/performance/evaluate/advanced?pip_id=${pip_id}&start_date=${start_date}&end_date=${end_date}&employee_id=${employee_id}&first_name=${query.get(
            "first_name"
          )}&last_name=${query.get("last_name")}&mobile=${query.get(
            "mobile"
          )}&email=${query.get("email")}`
        );
      } else if (probation_id) {
        history.push(
          `/performance/evaluate/advanced?probation_id=${probation_id}&start_date=${start_date}&end_date=${end_date}&employee_id=${employee_id}&first_name=${query.get(
            "first_name"
          )}&last_name=${query.get("last_name")}&mobile=${query.get(
            "mobile"
          )}&email=${query.get("email")}`
        );
      } else {
        history.push("/performance/evaluate/advanced");
      }
    }
  };

  return (
    <PerformanceEvaluationLayout step={1} title={"Select Evaluation Type"}>
      <div className="flex flex-col justify-center h-full min-h-[480px]">
        <ButtonSelect
          title={"Basic performance evaluation"}
          subtitle={"Evaluate performance using basic performance workflow"}
          isActive={evaluationOptions === "basic"}
          onClick={() => setEvaluationOptions("basic")}
        />
        <ButtonSelect
          title={"Advanced performance evaluation"}
          subtitle={
            "Evaluate performance using advance performance criteria like; OKR, Balanced scorecard, SMART Goals and predefined Organisational metrics."
          }
          isActive={evaluationOptions === "advanced"}
          onClick={() => setEvaluationOptions("advanced")}
          className="mt-8"
        />
        <ButtonSelect
          title={"360 Degree performance evaluation"}
          subtitle={
            "Evaluate performance from a 360 Degree perspective. Only OKRs and Balanced scorecards can be used for 360 degree performance evaluation."
          }
          isActive={evaluationOptions === "360-degree"}
          onClick={() => setEvaluationOptions("360-degree")}
          className="mt-8"
        />
        <div className="w-full mt-[20px]">
          <Button text="Next" type="submit" onClick={() => submit()} />
        </div>
        <div
          className="flex justify-center w-full cursor-pointer"
          onClick={() => history.push("/performance/evaluations")}
        >
          <span className="p2-medium text-color-black mt-3">
            Back to Dashboard
          </span>
        </div>
      </div>
    </PerformanceEvaluationLayout>
  );
}

export default SelectEvaluationOptions;
