import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Button from "../../../components/button";
import FormInput from "../../../components/form-input";
import FormSelect from "../../../components/form-select";
import EmployeeRegistrationLayout from "../../../components/layouts/employee-registration";
import LoadingBar from "../../../components/loader-bar";
import { useGetPFA } from "../../../redux/bank/hook";
import { formValidation } from "../../../utils/functions";
import Logo from "../../../components/logo";

function CompleteEmployeeInformationPage() {
  useEffect(() => {}, []);

  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const { data: PFAs } = useGetPFA();
  const [payload, setPayload] = useState({});
  const [rsaPin, setRsaPin] = useState("");
  const [taxId, setTaxId] = useState("");
  const [pfa_name, setPFAName] = useState("");

  const { employee } = useSelector((state) => state.employeeSlice);

  const location = useLocation();

  // useEffect(() => {
  //   if (employee) {
  //     setDateJoined(employee.date_joined)
  //   }
  // }, [employee])

  useEffect(() => {
    if (location) {
      setPayload(location.state);
    }
  }, [location]);

  const history = useHistory();

  const {
    register,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const submitForm = async () => {
    setLoading(true);

    let newData = {
      ...payload,
      pfa_name: pfa_name,
      rsa_pin: rsaPin,
      tax_number: taxId,
    };
    setLoading(false);

    history.push(
      "/employees/single-employee-onboarding/salary/" + token,
      newData
    );
  };

  return (
    <EmployeeRegistrationLayout
      step="2"
      company_name={employee?.company_name}
      logo={employee?.logo}
    >
      <div className="flex flex-col justify-center w-full md:pt-5 h-fit gap-4">
        <div className="flex md:min-h-full flex-col w-full max-w-[600px] px-5 md:px-0">
          <form className="form ">
            <div className="w-full text-center flex flex-col pb-[25px] md:pt-[20px] pt-2">
              <span className="header-2-regular text-secondary-2">
                Employment Information
              </span>
              <span className="header-4-regularh text-secondary-2">
                View and update your employment details
              </span>
            </div>
            <hr className="divider -mt-2" />
            <div className="md:hidden flex flex-col md:w-1/2 w-full md:pr-2">
              <span className="header-3 text-color-gray">Company Name</span>
              <span className="header-4 mt-1 text-color-black font-bold">
                {employee?.company_name}
              </span>
              <hr className="divider" />
            </div>
            {payload ? (
              <div className="flex flex-col">
                <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                  <span className="header-5 text-color-gray">
                    Date Of Employment
                  </span>
                  <span className="mt-1 text-color-black font-bold">
                    {moment(payload?.date_joined).format("MMMM DD, YYYY")}
                  </span>
                </div>
                <div className="flex flex-row w-full justify-between mt-2 gap-2">
                  <div className="flex flex-col w-1/2 md:pr-2">
                    <span className="header-5 text-color-gray">
                      Job Position
                    </span>
                    <span className="mt-1 text-color-black font-bold">
                      {payload?.job_position
                        ? payload?.job_position
                        : "Not Set"}
                    </span>
                  </div>
                  <div className="flex flex-col w-1/2 md:pl-2">
                    <span className="header-5 text-color-gray">
                      Staff Number
                    </span>
                    <span className="mt-1 text-color-black font-bold">
                      {payload?.staff_number
                        ? payload?.staff_number
                        : "Not Set"}
                    </span>
                  </div>
                </div>
                <hr className="divider" />
                {PFAs ? (
                  <FormSelect
                    value={pfa_name}
                    options={PFAs}
                    onChange={(selected) => {
                      setPFAName(selected);
                    }}
                    label="Pension Fund Administrator (PFA) - (Optional)"
                    extraClass={"mb-3"}
                  />
                ) : (
                  <LoadingBar loading={true} />
                )}
                <div className="flex flex-col md:flex-row w-full justify-between gap-2">
                  <div className="w-full md:w-1/2">
                    <FormInput
                      label="Pension RSA PIN - (Optional)"
                      name="rsa"
                      type="text"
                      onInput={(e) => setRsaPin(e.target.value)}
                      inputRef={register(formValidation("text", false))}
                      readOnly={loading}
                      error={errors.tax}
                      errorMessage={errors.rsa && errors.rsa.message}
                    />
                  </div>
                  <div className="w-full md:w-1/2 pl-2">
                    <FormInput
                      label="Tax number - (Optional)"
                      name="tax"
                      type="text"
                      onInput={(e) => setTaxId(e.target.value)}
                      inputRef={register(formValidation("text", false))}
                      readOnly={loading}
                      error={errors.tax}
                      errorMessage={errors.tax && errors.tax.message}
                    />
                  </div>
                </div>
                <div className="w-full mt-[20px]">
                  <Button
                    text="Continue"
                    type="submit"
                    className={"!bg-secondary-2"}
                    loading={loading}
                    onClick={() => submitForm()}
                  />
                </div>
              </div>
            ) : null}
          </form>
          <div
            className="flex justify-center w-full cursor-pointer hover:underline"
            onClick={() => history.goBack()}
          >
            <span className="p2-medium text-color-black mt-3">Back</span>
          </div>
        </div>
        <div
          className={
            "md:hidden flex items-center justify-center text-color-black gap-2"
          }
        >
          Powered By:{" "}
          <a href="https://www.ropay.africa" target={"_blank"} rel="noreferrer">
            <Logo width="80px" height="40px" blue />
          </a>
        </div>
      </div>
    </EmployeeRegistrationLayout>
  );
}

export default CompleteEmployeeInformationPage;
