import React, { useEffect } from "react";
import { useState } from "react";
import Button from "../../../components/button";
import DashboardLayout from "../../../components/layouts/dashboard";
import TabView from "../../../components/tabview";
import { useLocation } from "react-router-dom";
import VendorOverviewTab from "../../../components/spend-management-section/vendors/vendors-list-tab";
import VendorHistoryTab from "../../../components/spend-management-section/vendors/transaction-history-tab";
import UnauthorizedPage from "../../errors/unauthorized";
import SelectVendorOption from "../../../components/modal/spend-management/vendor/select-vendor-option";
import SelectVendorPayOption from "../../../components/modal/spend-management/vendor/select-vendor-pay-option";
import { VendorPaySummaryTab } from "../../../components/spend-management-section/vendors/vendor-pay-summary-tab";
import { useSelector } from "react-redux";
import { PurchaseOrderTab } from "../../../components/spend-management-section/vendors/vendors-purchase-orders-tab";
import { InvoicesReceivedTab } from "../../../components/spend-management-section/vendors/vendors-invoices-received-tab";

const VendorPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.vendor_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);

  const location = useLocation();

  useEffect(() => {
    if (location && location.state) {
      setActiveTab(location.state.tab);
    }
  }, [location]);

  const VendorTabs = [
    {
      title: "Vendors List",
      Content:
        user?.permissions?.vendor_permission?.vendor_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? VendorOverviewTab
          : UnauthorizedPage,
    },
    {
      title: "Purchase Orders",
      Content:
        user?.permissions?.vendor_permission?.purchase_order_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? PurchaseOrderTab
          : UnauthorizedPage,
    },
    {
      title: "Invoices Received",
      Content:
        user?.permissions?.vendor_permission?.invoice_received_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? InvoicesReceivedTab
          : UnauthorizedPage,
    },
    {
      title: "Payment Summary",
      Content:
        user?.permissions?.vendor_permission?.payment_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? VendorPaySummaryTab
          : UnauthorizedPage,
    },
    {
      title: "Transaction History",
      Content:
        user?.permissions?.vendor_permission?.history_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? VendorHistoryTab
          : UnauthorizedPage,
    },
  ];

  return (
    <DashboardLayout title="Vendor Management">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col">
          <div>
            <TabView
              Tabs={VendorTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default VendorPage;
