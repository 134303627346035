import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Collapse } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import { useAddEmployeeGroup } from '../../../redux/employees/hook';
import {
  useEditEmployeeGroup,
  useGetEmployeeGroup,
} from '../../../redux/employees/hook/groups';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import CollapsingIcon from '../../collapsing-icon';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import FormSwitch from '../../form-switch';
import FormTextArea from '../../form-textarea';
import Modal from '../modal';
import { useGetSalarySettings } from '../../../redux/company/hook';

function AddEmployeeGroup({
  isOpen,
  closeModal,
  refresh,
  groupData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [groupId, setGroupId] = useState(null);
  const [groupType, setGroupType] = useState();

  const [additionalAllowance, setAdditionalAllowance] = useState([])

  const [totalAllowance, setTotalAllowance] = useState(0);
  const [dayCount, setDayCount] = useState('seven_days');
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(true);

  useEffect(() => {
    if (groupData) {
      setGroupId(groupData);
    }
  }, [groupData]);

  const { data: group } = useGetEmployeeGroup(groupId, 1, 1, -1, '');
  const { data: salarySettings } = useGetSalarySettings();

  const [formData, setFormData] = useState({
    
  });

  useEffect(() => {
    if (group) {
      setGroupType('edit');
      setDayCount(group.setting.days_count);
      setAdditionalAllowance(group.setting.additional_allowances? JSON.parse(group.setting.additional_allowances) : [])
      setFormData({
        name: group.name,
        description: group.description,
        use_global_settings: group.setting.use_global_settings,
        use_fixed_rate: group.setting.use_fixed_rate == 1 ? true : false,
        calc_pension: group.setting.calc_pension,
        calc_tax: group.setting.calc_tax,
        calc_nhf: group.setting.calc_nhf,
        calc_nsitf: group.setting.calc_nsitf,
        calc_itf: group.setting.calc_itf,
        calc_nhis: group.setting.calc_nhis,
        can_apply_for_reimbursements: group.setting?.can_apply_for_reimbursements,
        can_apply_for_advance: group.setting?.can_apply_for_advance,
        basic_salary: group.setting.basic_salary,
        transport_allowance: group.setting.transport_allowance,
        housing_allowance: group.setting.housing_allowance,
        leave_allowance: group.setting.leave_allowance,
        lunch_allowance: group.setting.lunch_allowance,
        utility_allowance: group.setting.utility_allowance,
        other_allowance: group.setting.other_allowance,
        pension_calculator: group.setting.pension_calculator,
        employer_pension: group.setting.employer_pension,
        employee_pension: group.setting.employee_pension,
        employee_nhis: group.setting.employee_nhis,
        employer_nhis: group.setting.employer_nhis,
        benefit_in_kind: group.setting.benefit_in_kind,
        nhf_percentage: group.settingnhf_percentage,
        fixed_rate: group.setting.fixed_rate
      });
    } else if (salarySettings) {
      setGroupType('add');
      setDayCount('seven_days');
      setAdditionalAllowance(salarySettings?.additional_allowances? JSON.parse(salarySettings?.additional_allowances) : [])
      setFormData({
        name: '',
        description: '',
        use_global_settings: false,
        use_fixed_rate: false,
        calc_pension: salarySettings.pension == 1 ? true : false,
        calc_tax: salarySettings.tax,
        calc_nhf:  salarySettings.nhf,
        calc_nsitf:  salarySettings.nsitf,
        calc_itf:  salarySettings.itf,
        calc_nhis:  salarySettings.nhis,
        can_apply_for_reimbursements: salarySettings?.can_apply_for_reimbursements,
        can_apply_for_advance: salarySettings?.can_apply_for_advance,
        basic_salary: salarySettings.basic_salary,
        transport_allowance: salarySettings?.transport_allowance,
        housing_allowance: salarySettings?.housing_allowance,
        leave_allowance: salarySettings?.leave_allowance,
        lunch_allowance: salarySettings?.lunch_allowance,
        utility_allowance: salarySettings?.utility_allowance,
        other_allowance: salarySettings?.other_allowance,
        pension_calculator: salarySettings?.pension_calculator,
        employer_pension: salarySettings?.employer_pension,
        employee_pension: salarySettings?.employee_pension,
        employee_nhis: salarySettings?.employee_nhis,
        employer_nhis: salarySettings?.employer_nhis,
        benefit_in_kind: 0,
        nhf_percentage: salarySettings?.nhf_percentage,
        fixed_rate: 0
        
      });
    }
  }, [salarySettings, group]);

  const { mutateAsync: addGroup, isLoading: addGrouploading } =
    useAddEmployeeGroup();
  const { mutateAsync: editGroup, isLoading: editGrouploading } =
    useEditEmployeeGroup();
    
  const groupSettings = [
    {
      title: 'Use Company Salary Settings',
      name: 'use_global_settings',
    },
    {
      title: 'Use Fixed Tax Rate',
      name: 'use_fixed_rate',
    },
    {
      title: 'Calculate PAYE tax',
      name: 'calc_tax',
    },
    {
      title: 'Calculate Pension',
      name: 'calc_pension',
    },
    {
      title: 'Calculate National Housing Fund (NHF) ',
      name: 'calc_nhf',
    },
    {
      title: 'Calculate National Health Insurance Scheme (NHIS)',
      name: 'calc_nhis',
    },
  ];

  const AdvanceSalarySettings = [
    {
      title: 'Basic (%)',
      name: 'basic_salary',
    },
    {
      title: 'Housing (%)',
      name: 'housing_allowance',
    },
    {
      title: 'Transportation (%)',
      name: 'transport_allowance',
    },
    {
      title: 'Benefit-in-kind (%)',
      name: 'benefit_in_kind',
    },
    {
      title: 'Leave allowance (%)',
      name: 'leave_allowance',
    },
    {
      title: 'Lunch allowance (%)',
      name: 'lunch_allowance',
    },
    {
      title: 'Utility allowance (%)',
      name: 'utility_allowance',
    },
    {
      title: 'Other allowance (%)',
      name: 'other_allowance',
    },
  ];
  const applicationSettings = [
    {
      title: 'Can employees apply for salary advances?',
      name: 'can_apply_for_advance',
    },
    {
      title: 'Can employees apply for reimbursements?',
      name: 'can_apply_for_reimbursements',
    },
  ]

  const dayCountOptions = [
    { value: 'seven_days', label: 'Calendar Days' },
    { value: 'week_days', label: 'Working Days' },
  ];

  const pensionCalculatorOptions = [
    { value: 'bht', label: 'Basic, Housing and Transport Allw.' },
    { value: 'gross', label: 'Gross Payment' },
  ];

  const statutorySettings = [
    {
      title: 'Employer Pension (%)',
      name: 'employer_pension',
    },
    {
      title: 'Employee Pension (%)',
      name: 'employee_pension',
    },
    {
      title: 'Employer NHIS (%) - ReadOnly',
      name: 'employer_nhis',
    },
    {
      title: 'Employee NHIS (%) - ReadOnly',
      name: 'employee_nhis',
    },
    {
      title: 'NHF (%) - ReadOnly',
      name: 'nhf_percentage',
    },
  ];

  useEffect(() => {
    let additional_allowance = 0
    if (additionalAllowance.length > 0) {
      additionalAllowance.forEach(element => {
        additional_allowance += parseInt(element.rate)
      });
    }
    let computeTotalAllowance =
      parseInt(formData.basic_salary) +
      parseInt(formData.housing_allowance) +
      parseInt(formData.transport_allowance) +
      parseInt(formData.benefit_in_kind) +
      parseInt(formData.leave_allowance) +
      parseInt(formData.lunch_allowance) +
      parseInt(formData.utility_allowance) +
      parseInt(formData.other_allowance) +
      parseInt(additional_allowance);
    setTotalAllowance(computeTotalAllowance || 0);
  }, [formData, additionalAllowance]);

  const submitForm = async () => {
    let payload = { ...formData, days_count: DOMPurify.sanitize(dayCount), additional_allowances: JSON.stringify(additionalAllowance) };

    if (groupType === 'add') {
      await addGroup(payload).then(() => {
        closeModal();
      });
    } else if (groupType === 'edit') {
      let editPayload = {
        id: groupId,
        body: payload,
      };
      await editGroup(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='md:min-w-[45%] min-w-[100%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-6' onSubmit={handleSubmit(submitForm)}>
          <div className='flex flex-row justify-between pl-8 pr-8 pt-4 items-center'>
            <span className='header-4'>{title}</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col max-h-fit mx-8'>
            <div className='border bg-highlight p-2 rounded-md mb-2'>
              <FormInput
                label='Payroll Group Name'
                name='name'
                type='text'
                className={'!bg-white'}
                // placeholder={'Enter payroll group name'}
                inputRef={register(formValidation('text'))}
                value={formData.name}
                onInput={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                readOnly={addGrouploading || editGrouploading}
                error={errors.name}
                errorMessage={errors.name && errors.name.message}
              />
              <FormTextArea
                label={'Group Description'}
                name='description'
                // placeholder={'Enter payroll group Description'}
                className={'!bg-white'}
                inputRef={register(formValidation('text'))}
                value={formData.description}
                onInput={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                readOnly={addGrouploading || editGrouploading}
                error={errors.description}
                errorMessage={errors.description && errors.description.message}
              />
            </div>
            <hr className='divider mt-2' />
            <div>
              <span className='header-4'>Payroll Group Settings</span>
            </div>
            <hr className='divider' />
            <div className={'flex w-full flex-col'}>
              {groupSettings.map((item, index) => (
                <>
                  {!formData.use_global_settings ||
                  (formData.use_global_settings &&
                  item.name === 'use_global_settings') ? (
                  <>
                    {!formData.use_fixed_rate || (formData.use_fixed_rate &&
                    item.name === 'use_fixed_rate')|| item.name === 'use_global_settings' ? (
                    <div className='mb-[15px]'>
                      <FormSwitch
                        label={item.title}
                        name={item.name}
                        readOnly={addGrouploading || editGrouploading}
                        onClick={() => {
                          if (item.name === 'use_fixed_rate') {
                            if (!formData[item.name] == true) {
                              setFormData({
                                ...formData,
                                [item.name]: !formData[item.name],
                                'calc_tax' : true,
                              })
                            } else {
                              setFormData({
                                ...formData,
                                [item.name]: !formData[item.name],
                              })
                            }
                          } else {
                            setFormData({
                              ...formData,
                              [item.name]: !formData[item.name],
                            })
                          }}
                        }
                        checked={formData[item.name]}
                      />
                    </div>
                    
                    )
                    :
                    null
                    }
                    
                  </>
                  )
                  :
                  null
                  }
                </>
              ))}
            </div>
            <div className={'flex w-full flex-col mb-3 p-3 border bg-highlight rounded-md pt-4'}>
              {applicationSettings.map((item, index) => (
                <div className=''>
                  <FormSwitch
                    label={item.title}
                    name={item.name}
                    checked={formData[item.name]}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        [item.name]: !formData[item.name],
                      })
                    }
                    value={formData[item.name]}
                  />
                </div>
              ))}
            </div>
            {!formData.use_global_settings ? (
              <>
                <div
                  className={classNames('flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300', {
                    'pt-3 pb-3 px-2 font-extrabold bg-secondary-2-extralight rounded-md mb-2': showAdvancedSettings,
                  })}
                  onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                >
                  <span>Advance Payroll Settings</span>
                  <div className='pr-4'>
                    <CollapsingIcon
                      defaultPosition='left'
                      isOpen={showAdvancedSettings}
                    />
                  </div>
                </div>
                {!formData.use_fixed_rate?
                <div
                  className={classNames('flex flex-col border p-3 bg-gray-50 rounded-md', {
                    hidden: !showAdvancedSettings,
                  })}
                >
                  <div className='flex justify-end mt-2'>
                    <span
                      className={classNames('font-extrabold',
                        {
                          'text-green-700 ': totalAllowance === 100,
                        },
                        {
                          'text-color-error': totalAllowance !== 100,
                        },
                      )}
                    >
                      Total Allowance ({totalAllowance}%)
                    </span>
                  </div>
                  <Collapse isOpen={showAdvancedSettings}>
                    <div className='mt-2 mb-2 text-color-black text-left text-[13px]'>Total Gross Allowance must be equal to 100%</div>
                    <div className='grid md:grid-cols-3 grid-cols-2 gap-2 w-full'>
                      {AdvanceSalarySettings.map((item) => (
                        <FormInput
                          label={item.title}
                          name={item.name}
                          type='text'
                          inputRef={register(
                            formValidation('non-empty', false),
                          )}
                          onInput={(e) =>
                            setFormData({
                              ...formData,
                              [item.name]: e.target.value,
                            })
                          }
                          readOnly={addGrouploading || editGrouploading}
                          error={errors[item.name]}
                          errorMessage={
                            errors[item.name] && errors[item.name].message
                          }
                          value={formData[item.name]}
                        />
                      ))}
                    </div>
                    <hr className='divider mt-2' />
                    {additionalAllowance.length > 0? 
                    <>
                      <div className='grid md:grid-cols-3 grid-cols-2 gap-2 w-full'>
                        {additionalAllowance.map((item, index) => (
                          <FormInput
                            label={item.title + ' (%)'}
                            name={item.name}
                            type='number'
                            onInput={(e) =>
                              {let newArray = JSON.parse(JSON.stringify(additionalAllowance))
                              newArray[index].rate = e.target.value
                              setAdditionalAllowance(newArray)
                            }}
                            // readOnly={loading}
                            value={item.rate}
                          />
                        ))}
                      </div>
                    </>
                    :
                    null
                    }
                    <hr className='divider mt-2' />
                    <div className='grid md:grid-cols-3 grid-cols-1 gap-2 w-full'>
                      {formData.calc_pension?
                      <div className='col-span-2'>
                        <FormSelect
                          value={formData['pension_calculator']}
                          options={pensionCalculatorOptions}
                          onChange={(selected) => {
                            setFormData({
                              ...formData,
                              pension_calculator: selected,
                            });
                          }}
                          label='Pension Calculator'
                        />
                      </div>
                      :
                      null}
                      <div className='col-span-1'>
                      <FormSelect
                        value={dayCount}
                        options={dayCountOptions}
                        onChange={(selected) => {
                          setDayCount(selected);
                        }}
                        label='Day Count'
                      />
                      </div>
                    </div>
                    {formData.calc_pension || formData.calc_nhis || formData.calc_nhf?
                    <>
                      <hr className='divider mt-2' />
                      <div className='mb-2 text-color-black font-semibold text-left'>Statutory Rates (%)</div>
                      <div className='grid md:grid-cols-2 grid-cols-1 gap-2 w-full'>
                        {statutorySettings.map((item) => (
                          <>
                            {!formData.calc_pension && (item.name =='employer_pension' || item.name =='employee_pension')?
                            null
                            :!formData.calc_nhis && (item.name =='employer_nhis' || item.name =='employee_nhis')?
                            null
                            :!formData.calc_nhf && item.name =='nhf_percentage'?
                            null
                            :
                            <FormInput
                              label={item.title}
                              name={item.name}
                              type='text'
                              readOnly={item.name === 'employer_pension' || item.name === 'employee_pension'? false: true}
                              onInput={(e) =>
                                setFormData({
                                  ...formData,
                                  [item.name]: parseFloat(e.target.value),
                                })
                              }
                              value={formData[item.name]}
                            />
                            }
                          </>
                        ))}
                      </div>
                    </>
                    :
                    null
                    }
                  </Collapse>
                </div>
                :
                <div
                  className={classNames('flex flex-col border p-3 bg-gray-50 rounded-md', {
                    hidden: !showAdvancedSettings,
                  })}
                >
                  <Collapse isOpen={showAdvancedSettings}>
                    <div className='grid md:grid-cols-2 grid-cols-1 gap-2 w-full'>
                      <FormInput
                        label={'Fixed Tax Rate (%)'}
                        name={'fixed_rate'}
                        type='number'
                        inputRef={register(
                          formValidation('number', false),
                        )}
                        onInput={(e) =>
                          setFormData({
                            ...formData,
                            'fixed_rate': parseFloat(e.target.value),
                          })
                        }
                        readOnly={addGrouploading || editGrouploading}
                        error={errors.fixed_rate}
                        errorMessage={
                          errors.fixed_rate && errors.fixed_rate.message
                        }
                        value={formData['fixed_rate']}
                      />
                    </div>
                  </Collapse>
                </div>
                }
              </>
            ) : null}

            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                disabled={
                  groupType === 'edit' ? false : !isValid ? true : false
                }
                loading={addGrouploading || editGrouploading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddEmployeeGroup;
