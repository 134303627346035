import { Axios } from '../axios';

const getOtherStatutoryAllowances = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/other-statutory-allowances?limit=${payload.limit}&page=${payload.page}${search}${filter}`,
  );
  return data?.data;
};

const addOtherStatutoryAllowance = async (payload) => {
  const { data } = await Axios.post('/other-statutory-allowances', payload);
  return data;
};
const editOtherStatutoryAllowance = async (payload) => {
  const { data } = await Axios.put('/other-statutory-allowances/'+payload.id, payload.body);
  return data;
};

const markOtherStatutoryAllowance = async (payload) => {
    const { data } = await Axios.put('/other-statutory-allowances/change-status/'+payload);
    return data;
  };

const deleteStatutoryAllowance = async (payload) => {
  const { data } = await Axios.delete('/other-statutory-allowances/'+payload);
  return data;
};

const getSingleEmployeeOtherStatutoryAllowances = async (payload) => {
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(`/other-statutory-allowances/employee/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}`,
  );

  return data?.data;
};

export const otherStatutoryAllowances = {
    getOtherStatutoryAllowances,
    addOtherStatutoryAllowance,
    editOtherStatutoryAllowance,
    markOtherStatutoryAllowance,
    deleteStatutoryAllowance,
    getSingleEmployeeOtherStatutoryAllowances,
};
