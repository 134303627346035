import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormInput from '../../../form-input';
import Modal from '../../modal';
import { useMarkInvoiceReceivedPaid } from '../../../../redux/spend-management/invoice-received/hook';

function MarkInvoiceReceivedModal ({ isOpen, closeModal, invoiceReceivedData}) {

    const [amount, setAmount] = useState(0)
    const {mutateAsync: markAsPaid, isLoading: markInvoicePaidLoading } = useMarkInvoiceReceivedPaid();
    const [invoiceId, setInvoiceId] = useState(null);

    useEffect(() => {
        if (invoiceReceivedData) {
            setInvoiceId(invoiceReceivedData.id)
        }
    }, [invoiceReceivedData])

    const submitForm = async () => {
        let payload = {
            id: invoiceId,
            data: {
                amount: parseFloat(amount)
            }
        }
        await markAsPaid(payload).then(() => {
            closeModal()
        });
    };

    return (
        <Modal
        scrollable={true}
        className='!max-w-[500px] !rounded-none'
        isOpen={isOpen}
        centered={true}
        >
        <div className='flex flex-col min-h-FIT overflow-auto'>
            <form className='form pb-4'>
                <div className='pl-8 pr-8 pt-4'>
                    <div className='flex flex-row justify-between items-center'>
                    <span className='header-4'>Mark Invoice Paid</span>
                    <div className='cursor-pointer' onClick={() => closeModal()}>
                        <img src={CloseSVG} alt='close' />
                    </div>
                    </div>
                </div>
                <hr className='divider' />
                <div className='flex flex-col mx-8 mt-2 '>
                    <div className='w-full'>
                        <FormInput
                            label='Amount Paid'
                            name='total_amount'
                            placeholder={'Enter Amount Paid'}
                            type='number'
                            value={amount}
                            onInput={(e) => {
                                setAmount(e.target.value)
                            }}
                        />
                    </div>
                    <div className='w-full'>
                    <Button
                        text={'Mark as Paid'}
                        type='button'
                        loading={markInvoicePaidLoading}
                        onClick={() => {submitForm()}}
                    />
                    </div>
                </div>
            </form>
        </div>
        </Modal>
    );
}

export default MarkInvoiceReceivedModal;
