import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useDeclineAirtimePurchase,
  useGetAirtimePurchases,
} from "../../redux/airtime/hook";
import { useDeclineAdvancedPayroll } from "../../redux/payroll/hook";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import Button from "../button";
import DataTable from "../datatable";
import SelectAirtimeOption from "../modal/airtime/select-airtime-option";

export const AirtimeSummaryTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: airtimes } = useGetAirtimePurchases(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: declineAirtimePayment } = useDeclineAirtimePurchase();

  const [showPurchaseAirtime, setShowPurchaseAirtime] = useState(false);

  const history = useHistory();

  async function Continue(id, type) {
    let index = airtimes.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === "preview") {
        history.push(`/airtime/preview/${airtimes.data[index].ref}`);
      } else if (type === "view") {
        history.push(`/airtime/view/${airtimes.data[index].ref}`);
      } else if (type === "approve") {
        history.push(`/airtime/preview/${airtimes.data[index].ref}`, "approve");
      } else if (type === "decline") {
        let index = airtimes.data.findIndex((item) => item.id === id);
        declineAirtimePayment(airtimes.data[index].ref);
      }
    }
  }

  const statusConfig = [
    {
      label: "In Progress",
      color: "orange",
      value: 0,
    },
    {
      label: "Successful",
      color: "green",
      value: 1,
    },
    {
      label: "Awaiting Approval",
      color: "orange",
      value: 2,
    },
    {
      label: "Declined",
      color: "red",
      value: 3,
    },
  ];

  const tableConfig = {
    headers: ["Date", "Ref. Number", "Description", "Total Amount", "Status"],
    keys: ["created", "ref", "desc", "total_amount", "status"],
    mobileHeader: {
      left: {
        title: "Date",
        key: "created",
      },
      right: {
        title: "Description",
        key: "desc",
      },
    },
  };

  const actionConfig = [
    {
      name: "Continue",
      color: "green",
      action: (id) => {
        Continue(id, "preview");
      },
    },
    {
      name: "View",
      color: "green",
      action: (id) => {
        Continue(id, "view");
      },
    },
    {
      name: "Approve",
      color: "green",
      action: (id) => {
        Continue(id, "approve");
      },
    },
    {
      name: "Decline",
      color: "red",
      action: (id) => {
        Continue(id, "decline");
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showPurchaseAirtime ? (
        <SelectAirtimeOption
          isOpen={showPurchaseAirtime}
          closeModal={() => setShowPurchaseAirtime(false)}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Airtime Summary
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Purchase Airtime"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"purchase"}
              onClick={() => {
                setShowPurchaseAirtime(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={airtimes}
        tableConfig={tableConfig}
        statusConfig={statusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};
