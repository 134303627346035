import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import { useEditAirtimeById } from '../../../redux/airtime/hook';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import Modal from '../modal';

function AirtimeDetailedView({ isOpen, closeModal, viewData, previewType }) {
  const [payloadData, setPayloadData] = useState(null);
  const [type, setType] = useState(null);
  const [amount, setAmount] = useState(0);
  const [mobile, setMobile] = useState('');
  const [network, setNetwork] = useState('MTN');
  const [id, setId] = useState(null);

  const { mutateAsync: editAirtime, isLoading: editAirtimeLoading } =
    useEditAirtimeById();

  const networkOptions = [
    { value: 'mtn', label: 'MTN' },
    { value: 'airtel', label: 'Airtel' },
    { value: 'glo', label: 'Glo' },
    { value: 'etisalat', label: 'Etisalat' },
  ];

  useEffect(() => {
    if (viewData) {
      setPayloadData(viewData);
      setType(previewType);
      setAmount(parseFloat(viewData.amount.replace('₦', '').replace(',', '').toFixed(2)));
      setNetwork(viewData.network);
      setMobile(viewData.mobile);
      setId(viewData.id);
    }
  }, [viewData]);

  const submitForm = async () => {
    let payload = {
      id: id,
      data: {
        amount,
        mobile,
        network,
      },
    };
    await editAirtime(payload).then((result) => {
      closeModal();
    });
  };
  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <div className='form p-3'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>
                {type === 'view'
                  ? 'Basic Payroll Detailed'
                  : 'Edit Basic Payroll'}
              </span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col md:flex-col w-full justify-between'>
              {payloadData ? (
                <>
                  <span className='h4-regular text-color-gray'>
                    Employee name
                  </span>
                  <span className='h4-regular text-color-black-2'>
                    {payloadData.first_name} {payloadData.last_name}
                  </span>
                  <hr className='divider' />
                  <div className='flex flex-col md:flex-row justify-between'>
                    <div className='w-full md:w-1/2 md:pr-2'>
                      <FormInput
                        label={
                          type === 'view' ? 'Net Pay' : 'Net Pay - Editable'
                        }
                        name='Mobile'
                        type='mobile'
                        readOnly={type === 'view' ? true : false}
                        value={mobile}
                        onInput={(e) => {
                          setMobile(e.target.value);
                        }}
                      />
                    </div>
                    <div className='w-full md:w-1/2 md:pr-2'>
                      <FormSelect
                        defaultValue={network}
                        options={networkOptions}
                        onChange={(selected) => {
                          setNetwork(selected);
                        }}
                        label='Select Network'
                      />
                    </div>
                  </div>
                  <FormInput
                    label='Amount'
                    name='amount'
                    type='text'
                    readOnly={type === 'view' ? true : false}
                    value={amount}
                    onInput={(e) => {
                      let new_value = e.target.value
                      setAmount(parseFloat(new_value.toFixed(2)))
                      
                    }}
                  />
                  {type === 'edit' ? (
                    <div className='w-full mb-[20px]'>
                      <Button
                        text='UPDATE'
                        type='submit'
                        disabled={type === 'view' ? true : false}
                        loading={editAirtimeLoading}
                        onClick={() => submitForm()}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AirtimeDetailedView;
