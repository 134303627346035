import React, { useEffect, useState } from "react";
import { toggleSidebar } from "../../../redux/components/components-slice";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../../components/layouts/dashboard";
import Button from "../../../components/button";
import FormSelect from "../../../components/form-select";
import EmployeeSVG from "../../../assets/svg/employee-green.svg";
import PayoutSVG from "../../../assets/svg/payroll-payout.svg";
import ExpectedPayoutSVG from "../../../assets/svg/expected-payroll-payout.svg";
import WalletSVG from "../../../assets/svg/dashboard-wallet.svg";
import PayrollOverviewChart from "../../../components/payroll-section/dashboard/payroll-overview-chart";
import PayrollChart from "../../../components/payroll-section/dashboard/payroll-chart";
import { Card } from "../../../components/card";
import { useGetDashboard } from "../../../redux/dashboard/hook";
import LoadingBar from "../../../components/loader-bar";
import { useTour } from "@reactour/tour";
import { formatNumber } from "../../../utils/functions";
import moment from "moment";
import DemoVideoModal from "../../../components/modal/demo-videos";

const Dashboard = () => {
  useEffect(() => {}, []);

  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.companySlice);
  const { user } = useSelector((state) => state.userSlice);
  const { data: dashboard, isLoading } = useGetDashboard();
  const [dashboardCards, setDashboardCards] = useState([]);
  const [donutData, setDonutData] = useState(null);
  const [payrollStat, setPayrollStats] = useState(null);

  const { setIsOpen, setCurrentStep } = useTour();
  useEffect(() => {
    if (dashboard) {
      updatePayrollOverview();
      updateDashboardCards();
      updatePayrollStats();
    }
  }, [dashboard]);

  useEffect(() => {
    if (window.innerWidth < 851) {
      dispatch(toggleSidebar());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showOnboardingVideo, setShowOnboardingVideo] = useState(false);
  const [sortOption, setSortOption] = useState("Last 6 months");

  const sortOptions = [{ value: "Last 6 months", label: "Last 6 months" }];

  function updateDashboardCards() {
    const DashboardCards = [
      {
        title: "EMPLOYEES",
        value: dashboard?.activeEmployeeCount,
        icon: EmployeeSVG,
      },
      {
        title: "WALLET BALANCE",
        value: `${
          dashboard?.walletBalance
            ? formatNumber(dashboard.walletBalance, 2)
            : 0
        }`,
        icon: WalletSVG,
      },
      {
        title: "LAST PAYOUT",
        value: `${
          dashboard?.lastPayrollPayout
            ? formatNumber(dashboard.lastPayrollPayout, 2)
            : 0
        }`,
        icon: PayoutSVG,
      },
      {
        title: "EXPECTED PAYOUT",
        value: `${
          dashboard?.expectedPayrollPayout
            ? formatNumber(dashboard.expectedPayrollPayout, 2)
            : 0
        }`,
        icon: ExpectedPayoutSVG,
      },
    ];

    setDashboardCards(DashboardCards);
  }

  function updatePayrollOverview() {
    let data = {
      data: {
        labels: [
          "Compensation",
          "Statutory Deduction",
          "Other Deductions",
          "Reimbursibles",
        ],
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
        datasets: [
          {
            cutout: "70%",
            label: "# of Votes",
            data: [
              dashboard?.lastSixMonthBreakdown?.compensation
                ? dashboard.lastSixMonthBreakdown.compensation
                : 0,
              dashboard?.lastSixMonthBreakdown?.deductions
                ? dashboard.lastSixMonthBreakdown.deductions
                : 0,
              dashboard?.lastSixMonthBreakdown?.other_deductions
                ? dashboard.lastSixMonthBreakdown.other_deductions
                : 0,
              dashboard?.lastSixMonthBreakdown?.reimbursements
                ? dashboard.lastSixMonthBreakdown.reimbursements
                : 0,
            ],
            backgroundColor: [
              "rgb(0, 25, 132)",
              "#f79421",
              "rgb(178,34,34)",
              "#0d5225",
            ],
          },
        ],
      },
      legends: [
        {
          title: "Compensation",
          color: "bg-secondary-2",
        },
        {
          title: "Statutory Deduction",
          color: "bg-[#f79421]",
        },
        {
          title: "Other Deduction",
          color: "bg-[rgb(178,34,34)]",
        },
        {
          title: "Reimbursements",
          color: "bg-[#0d5225]",
        },
      ],
      total: formatNumber(
        parseInt(
          dashboard?.lastSixMonthBreakdown?.compensation
            ? dashboard.lastSixMonthBreakdown.compensation
            : 0
        ) +
          parseInt(
            dashboard?.lastSixMonthBreakdown?.deductions
              ? dashboard.lastSixMonthBreakdown.deductions
              : 0
          ) +
          parseInt(
            dashboard?.lastSixMonthBreakdown?.other_deductions
              ? dashboard.lastSixMonthBreakdown.other_deductions
              : 0
          ) +
          parseInt(
            dashboard?.lastSixMonthBreakdown?.reimbursements
              ? dashboard.lastSixMonthBreakdown.reimbursements
              : 0
          ),
        2
      ),
    };

    setDonutData(data);
  }

  function updatePayrollStats() {
    let data = {
      data: {
        labels: ["", "Sep", "Oct", "Nov", "Dec", "Jan", "Mar"],
        datasets: [
          {
            label: "Amount",
            data: [],
            fill: true,
            borderWidth: 2,
            // backgroundColor: '',
            borderColor: "rgb(0, 25, 132)",
            tension: 0.5,
            pointRadius: 0.5,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 3,
            pointBorderWidth: 0,
            pointBorderColor: "rgb(0, 25, 132)",
            pointHoverBorderColor: "rgb(239, 160, 11)",
          },
        ],
      },
      options: {
        options: {
          plugins: {
            tooltip: {
              backgroundColor: "rgb(236,243,251)",
              borderColor: "rgb(236,243,251)",
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 500000,
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return formatNumber(parseInt(value), 2);
              },
            },
          },
        },
      },
    };

    dashboard?.lastSixMonthPayroll?.reverse();

    let amounts = [];
    let months = [];

    dashboard?.lastSixMonthPayroll?.forEach((element) => {
      amounts.push(element.amount);
      months.push(moment(element.month + "-01").format("MMM YY"));
    });

    data.data.labels = months;
    data.data.datasets[0].data = amounts;

    setPayrollStats(data);
  }
  const onboard = () => {
    setIsOpen(true);
    // helpMeOnboard();
  };

  return (
    <DashboardLayout title="Payroll Dashboard">
      {showOnboardingVideo ? (
        <DemoVideoModal
          isOpen={showOnboardingVideo}
          closeModal={() => setShowOnboardingVideo(false)}
        />
      ) : null}
      <div className="flex flex-col h-full -mt-2">
        <div className="flex flex-col md:flex-row justify-between items-center gap-3">
          <div className="flex flex-col items-start justify-start">
            <span className="header-3"> Welcome {user?.first_name},</span>
            <span className="text-[13px] text-gray-500">
              Quick Guide? &nbsp;
              <span
                onClick={() => onboard()}
                className="text-[13px] mt-2 text-secondary-2 font-bold hover:font-extrabold duration-300 cursor-pointer hover:scale-105 hover:underline"
              >
                Click Here
              </span>
            </span>
          </div>
          <div className="w-full md:w-fit md:mt-0 h-[45px]">
            <Button
              text="QUICK GUIDE VIDEOS"
              type="button"
              className={"px-2 gap-2 h-[42px]"}
              leftIcon={"view"}
              textClass={"text-[14px] font-extrabold"}
              // loading={onboardLoading}
              // disabled={onboardLoading}
              onClick={() => {
                setShowOnboardingVideo(true);
              }}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[15px]">
            <LoadingBar loading={isLoading} />
          </div>
        ) : (
          <>
            <div
              id="dashboard-overview"
              className="mt-4 grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-4 w-full"
            >
              {dashboardCards?.map((item) => (
                <Card
                  title={item.title}
                  subtitle={item.value}
                  icon={item.icon}
                />
              ))}
            </div>

            <div className="flex flex-col bg-color-white lg:flex-row justify-between mt-4 pt-0 border rounded-md">
              {/* Payroll Stat */}
              <div className="w-[100%] md:w-[60%] flex flex-col mb-2">
                <div className="flex flex-row justify-between items-center pl-4 pr-3 p-4">
                  <span className="p2">Payroll stats</span>
                  <div className="hidden md:flex flex-row items-center gap-2">
                    <div className="bg-secondary-2 w-[10px] h-[10px] rounded-[10px] pr-2"></div>
                    <span className="p4">Payrolls</span>
                  </div>
                  <div className="flex items-center h-[27px]">
                    <FormSelect
                      defaultValue={sortOption}
                      options={sortOptions}
                      onChange={(selected) => {
                        setSortOption(selected);
                      }}
                      type="default"
                      extraClass="h-[27px] w-[140px] !text-[12px]"
                    />
                  </div>
                </div>
                <hr className="divider -mt-2" />
                <div className="h-full w-[100%] md:px-1 px-4">
                  {payrollStat ? <PayrollChart data={payrollStat} /> : null}
                </div>
              </div>
              <div className="w-[100%] lg:w-[40%] md:w-[40%] md:p-4 lg:p-4 pt-0 flex flex-col p-4 mt-3">
                <div className="flex flex-col bg-color-white py-2 px-4 pt-0 rounded-md border">
                  <div className="flex flex-row justify-between items-center">
                    <span className="text-[14px] font-extrabold">
                      Payroll Overview
                    </span>
                    <FormSelect
                      defaultValue={sortOption}
                      options={sortOptions}
                      onChange={(selected) => {
                        setSortOption(selected);
                      }}
                      type="default"
                      extraClass="h-[27px] w-[140px] !text-[12px]"
                    />
                  </div>
                  <hr className="divider -mt-1 -mb-2" />
                  <div className="flex items-center justify-center">
                    {dashboard ? (
                      <div className="w-[100%]">
                        <PayrollOverviewChart data={donutData} />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
