import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import {
  PAGINATION_DEFAULT,
  OvertimeStatusConfig,
} from "../../utils/constants";
import Button from "../button";
import DoYouWantToModal from "../modal/do-you-want-to";
import HelpSVG from "../../assets/svg/help.svg";
import InfoModal from "../modal/info-modal";
import {
  useDeleteOvertime,
  useGetEmployeeOvertimes,
} from "../../redux/employees/hook/overtime";
import AddOvertimeModal from "../modal/employees/add-overtime";
import SelectOvertimeOption from "../modal/employees/select-overtime-option";
import { useGetEmployees } from "../../redux/employees/hook";
import OvertimeApplications from "../modal/employees/overtime-applications";

const EmployeeOvertimesTab = () => {
  useEffect(() => {}, []);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { refetch, data: employeeOvertime } = useGetEmployeeOvertimes(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showOvertimeApplications, setShowOvertimeApplications] =
    useState(false);

  const { mutateAsync: deleteOvertime, isLoading: deleteLoading } =
    useDeleteOvertime();

  const [showSelectOvertimeOptions, setShowSelectOvertimeOptions] =
    useState(false);
  const [overtimeData, setOvertimeData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState("Add Salary Advance");
  const [showAddOvertime, setShowAddOvertime] = useState(false);

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const tableConfig = {
    headers: [
      "Employee Name",
      "Phone number",
      "Purpose",
      "Month",
      "Amount",
      "Status",
    ],
    keys: [
      "employeeName",
      "phone",
      "purpose",
      "formatted_month",
      "formatted_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };

  const deleteSingleOvertime = async (id) => {
    let index = employeeOvertime.data.findIndex((item) => item.id === id);
    await deleteOvertime(employeeOvertime.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const actionConfig = [
    {
      name: "Edit/View",
      color: "green",
      action: (id) => {
        let index = employeeOvertime.data.findIndex((item) => item.id === id);
        setButtonText("Update");
        setTitle("Update Salary Advance");
        setOvertimeData(employeeOvertime.data[index]);
        setShowAddOvertime(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = employeeOvertime.data.findIndex((item) => item.id === id);
        setDeleteId(employeeOvertime.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  return (
    <div className="flex flex-col mt-4">
      <div className="flex items-center justify-end w-full">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={HelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      <div className="flex flex-col bg-white rounded border">
        {showDoYouWant ? (
          <DoYouWantToModal
            isOpen={showDoYouWant}
            closeModal={() => setShowDoYouWant(false)}
            id={deleteId}
            loading={deleteLoading}
            title={"Are you sure you want to delete this Employee overtime?"}
            buttonText={"DELETE"}
            btnFunction={deleteSingleOvertime}
          />
        ) : null}
        {showAddOvertime ? (
          <AddOvertimeModal
            isOpen={showAddOvertime}
            closeModal={() => setShowAddOvertime(false)}
            overtimeData={overtimeData}
            title={title}
            buttonText={buttonText}
          />
        ) : null}
        {showSelectOvertimeOptions ? (
          <SelectOvertimeOption
            isOpen={showSelectOvertimeOptions}
            closeModal={() => setShowSelectOvertimeOptions(false)}
          />
        ) : null}
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"salary-advance"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}
        {showOvertimeApplications ? (
          <OvertimeApplications
            isOpen={showOvertimeApplications}
            closeModal={() => setShowOvertimeApplications(false)}
          />
        ) : null}
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Employee Overtimes
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Overtime"
                type="submit"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                disabled={!employees}
                leftIcon={"add"}
                onClick={() => {
                  setShowSelectOvertimeOptions(true);
                }}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="View Applications"
                type="button"
                theme={"primary"}
                leftIcon={"view"}
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                disabled={!employees}
                onClick={() => setShowOvertimeApplications(true)}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="Refetch"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"change"}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={employeeOvertime}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={OvertimeStatusConfig}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default EmployeeOvertimesTab;
