import React, { useState } from "react";
import DataTable from "../../datatable";
import { useHistory } from "react-router-dom";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import {
  useExportPension,
  useGetPensionHistory,
} from "../../../redux/statutory-compliance/hook/pension";
import { formatNumber } from "../../../utils/functions";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

const PensionTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: Pensions } = useGetPensionHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const history = useHistory();

  const tableConfig = {
    headers: [
      "Date Created",
      "Reference Number",
      "Description",
      "Month",
      "Status",
    ],
    keys: ["created", "ref", "desc", "month", "status"],
    mobileHeader: {
      left: {
        title: "Month",
        key: "month",
      },
      right: {
        title: "Description",
        key: "desc",
      },
    },
  };

  function Continue(id, type) {
    let index = Pensions.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === "pay_now") {
        history.push(
          `/statutory-payments/pensions/preview/${Pensions.data[index].ref}`
        );
      } else if (type === "view") {
        history.push(
          `/statutory-payments/pensions/view/${Pensions.data[index].ref}`
        );
      } else if (type === "export") {
        exportPAYEPension(Pensions.data[index].ref);
      }
    }
  }

  const statusConfig = [
    {
      label: "In Progress",
      color: "orange",
      value: 0,
    },
    {
      label: "Successful",
      color: "green",
      value: 1,
    },
    {
      label: "In Progress",
      color: "orange",
      value: 2,
    },
    {
      label: "Paid: Not Filed",
      color: "orange",
      value: 3,
    },
    {
      label: "Filed: Awaiting Confirmation",
      color: "orange",
      value: 4,
    },
  ];

  const actionConfig = [
    {
      name: "Preview",
      color: "orange",
      action: (id) => {
        Continue(id, "pay_now");
      },
    },
    {
      name: "View",
      color: "green",
      action: (id) => {
        Continue(id, "view");
      },
    },
    {
      name: "Export Report",
      color: "orange",
      action: (id) => {
        Continue(id, "export");
      },
    },
  ];

  const workbook = new Excel.Workbook();
  // 'company',
  // 'firstName',
  // 'lastName',
  // 'ref',
  // 'pfaCode',
  // 'pfaName',
  // 'rsaPin',
  // 'employerContribution',
  // 'employeeContribution',
  // 'voluntaryContribution',
  // 'middleName',
  // 'scheduleMonth',
  // 'scheduleYear',
  // 'company_name',
  // 'mobile',
  // 'employercode',
  // 'employeremail',
  // 'employee',
  // 'status'
  const columns = [
    { header: "Date", key: "created" },
    { header: "First Name", key: "firstName" },
    { header: "Last Name", key: "lastName" },
    { header: "Mobile", key: "mobile" },
    { header: "Pension Fund Administrator", key: "pfaName" },
    { header: "RSA Pin", key: "rsaPin" },
    { header: "Pension Month", key: "pension_month" },
    { header: "Employee Contribution", key: "formatted_employee_amount" },
    { header: "Employer Contribution", key: "formatted_employer_amount" },
  ];

  const { mutateAsync: exportPension } = useExportPension();
  const [exportLoading, setSetExportLoading] = useState(false);

  const exportPAYEPension = async (refId) => {
    setSetExportLoading(true);
    let payload = {
      refId,
      limit: 100000,
      page: 1,
      statusFilter: -1,
      search: "",
    };

    await exportPension(payload).then(async (response) => {
      let data = response.data;
      const workSheetName = "payroll-pension-history";
      const workBookName = `PAYE Pensions - ${refId}`;
      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column) => {
          column.width = column.header.length + 10;
          column.alignment = { horizontal: "left" };
        });

        // loop through data and add each one to worksheet
        data?.forEach((singleData) => {
          singleData.pension_month = moment(
            singleData.scheduleYear + "-" + singleData.scheduleMonth
          ).format("MMMM, YYYY");
          singleData.formatted_employee_amount = formatNumber(
            singleData.employeeContribution
          );
          singleData.formatted_employer_amount = formatNumber(
            singleData.employerContribution
          );
          worksheet.addRow(singleData);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: "none" },
              left: { style: "none" },
              bottom: { style: "none" },
              right: { style: "none" },
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        // console.error('<<<ERRROR>>>', error);
        // console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  return (
    <div className="flex flex-col bg-white rounded-md">
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="p3-bold header-4">Pension</div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={Pensions}
        tableConfig={tableConfig}
        statusConfig={statusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={statusConfig}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default PensionTab;
