import DOMPurify from "dompurify";
import React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { formValidation } from "../../../../utils/functions";
import Button from "../../../button";
import FormInput from "../../../form-input";
import FormSelect from "../../../form-select";
import FormSwitch from "../../../form-switch";
import LoadingBar from "../../../loader-bar";
import moment from "moment";
import { AllCountries } from "../../../../constants/countries";
import { store } from "../../../../redux";
import { setAlert } from "../../../../redux/components/components-slice";
import classNames from "classnames";
import {
  useActivateOrDeactivateCustomer,
  useGetCustomer,
  useUpdateCustomer,
} from "../../../../redux/spend-management/customer/hook";

const CustomerProfileTab = () => {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { id } = useParams();
  const { data: customer, isLoading } = useGetCustomer(id);
  const { mutateAsync: updateCustomer, isLoading: loading } =
    useUpdateCustomer();

  const {
    mutateAsync: ActivateOrDeactivate,
    isLoading: ActivateOrDeactivateLoading,
  } = useActivateOrDeactivateCustomer();

  const [editMode, setEditMode] = useState(false);
  const [country, setCountry] = useState(false);

  useEffect(() => {
    if (customer) {
      setCountry(customer?.country);
    }
  }, [customer]);

  function submit(data) {
    const customer_name = DOMPurify.sanitize(data?.customer_name);
    const contact_person = DOMPurify.sanitize(data?.contact_person);
    const email = DOMPurify.sanitize(data?.email);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const street_address = DOMPurify.sanitize(data?.street_address);
    const city = DOMPurify.sanitize(data?.city);
    const state = DOMPurify.sanitize(data?.state);

    let payload = {
      employeeId: id,
      data: {
        customer_name,
        contact_person,
        email,
        mobile,
        country,
        street_address,
        city,
        state,
      },
    };
    updateCustomer(payload).then((result) => {
      setEditMode(false);
    });
  }

  const changeStatus = () => {
    if (customer) {
      ActivateOrDeactivate(employee.id);
    } else {
      store.dispatch(setAlert(true, "info", "No Customer found"));
    }
  };

  return (
    <>
      <div className="bg-white h-full w-full rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Customer Profile
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text={
                  customer?.status == 0
                    ? "ACTIVATE customer"
                    : "DEACTIVATE customer"
                }
                type="button"
                className={classNames(
                  "flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center",
                  {
                    "!bg-red-500": customer?.status == 1,
                    "!bg-hr-primary-1": customer?.status !== 1,
                  }
                )}
                textClass={"!text-[11px]"}
                loading={ActivateOrDeactivateLoading}
                onClick={() => {
                  changeStatus();
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider" />
        {isLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px] px-4">
            <LoadingBar loading={isLoading || loading} />
          </div>
        ) : (
          <>
            {customer ? (
              <div className="px-4 pb-4">
                <div className="w-full md:w-[20%] mt-2">
                  <FormSwitch
                    label={"Edit Mode"}
                    name={"switch-edit-mode"}
                    checked={editMode}
                    onClick={() => setEditMode(!editMode)}
                    value={editMode}
                  />
                </div>
                {editMode ? (
                  <form
                    className="form border bg-highlight p-4 rounded-md w-full"
                    onSubmit={handleSubmit(submit)}
                  >
                    <div className={"flex flex-col mt-3 w-full "}>
                      <div className="flex flex-col md:flex-row justify-between">
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="customer Name"
                            name="customer_name"
                            type="text"
                            defaultValue={customer?.customer_name}
                            inputRef={register(formValidation("text", true))}
                            readOnly={loading}
                            error={errors.customer_name}
                            errorMessage={
                              errors.customer_name &&
                              errors.customer_name.message
                            }
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="Contact Person"
                            name="contact_person"
                            type="text"
                            defaultValue={customer?.contact_person}
                            inputRef={register(formValidation("text", true))}
                            error={errors.contact_person}
                            errorMessage={
                              errors.contact_person &&
                              errors.contact_person.message
                            }
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="Email"
                            name="email"
                            type="text"
                            inputRef={register(formValidation("email", true))}
                            defaultValue={customer?.email}
                            error={errors.email}
                            errorMessage={errors.email && errors.email.message}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row justify-between">
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="Mobile"
                            name="mobile"
                            type="number"
                            inputRef={register(formValidation("number"))}
                            error={errors.mobile}
                            errorMessage={
                              errors.mobile && errors.mobile.message
                            }
                            defaultValue={customer?.mobile}
                          />
                        </div>
                      </div>
                      <hr className="divider" />
                      <div className="flex flex-col md:flex-row justify-between">
                        <div className="w-full md:w-[250px]">
                          <FormSelect
                            defaultValue={country}
                            options={AllCountries}
                            onChange={(selected) => {
                              setCountry(selected);
                            }}
                            label="Country"
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="State"
                            name="state"
                            type="text"
                            defaultValue={customer?.state}
                            inputRef={register(formValidation("text"))}
                            readOnly={loading}
                            error={errors.state}
                            errorMessage={errors.state && errors.state.message}
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="City"
                            name="city"
                            type="text"
                            defaultValue={customer?.city}
                            inputRef={register(formValidation("text"))}
                            readOnly={loading}
                            error={errors.city}
                            errorMessage={errors.city && errors.city.message}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row justify-between">
                        <div className="w-full md:w-[750px]">
                          <FormInput
                            label="Address"
                            name="street_address"
                            type="text"
                            defaultValue={customer?.street_address}
                            inputRef={register(formValidation("text"))}
                            readOnly={loading}
                            error={errors.street_address}
                            errorMessage={
                              errors.street_address &&
                              errors.street_address.message
                            }
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-end w-full mt-[20px]">
                        <Button
                          text="Update Customer Information"
                          type="submit"
                          loading={loading}
                          disabled={loading}
                          className="flex gap-2 !h-[35px] w-fit p-2 "
                          textClass={"!text-[11px]"}
                        />
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="flex flex-col md:flex-row border bg-gray-50 p-2 rounded-md">
                    <div className="w-full md:w-[100%] px-4 pb-4">
                      <div className="grid md:grid-cols-3  grid-cols-1 gap-4 w-full mt-3">
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Customer Name
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {customer.customer_name
                              ? customer.customer_name
                              : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Contact Person
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {customer.contact_person
                              ? customer.contact_person
                              : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Email
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {customer.email ? customer.email : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Phone number{" "}
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {customer.mobile ? customer.mobile : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Date Created
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {moment(customer.created).format("MMMM D, YYYY")}
                          </span>
                        </div>
                      </div>
                      <hr className="divider" />
                      <div className="grid md:grid-cols-3  grid-cols-1 gap-4 w-full mt-3">
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Country
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {customer.country ? customer.country : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            State
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {" "}
                            {customer.state ? customer.state : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">City</span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {" "}
                            {customer.city ? customer.city : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            {" "}
                            Address
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {customer.street_address
                              ? customer.street_address
                              : "Not Set"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default CustomerProfileTab;
