import classNames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormSelectEmployee from "../../../components//employees/form-select-employee";
import FormInput from "../../../components/form-input";
import FormSelect from "../../../components/form-select";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import { useRouteQuery } from "../../../hooks/useRouteQuery";
import { setAlert } from "../../../redux/components/components-slice";
import WarningSVG from "../../../assets/svg/warning.svg";
import {
  useGrossPayReverse,
  useNetPayReverse,
} from "../../../redux/employees/hook";
import { useAddSalary } from "../../../redux/employees/hook/salaries";
import { formatNumber } from "../../../utils/functions";

function AddSingleEmployeePay() {
  useEffect(() => {}, []);
  const history = useHistory();
  const query = useRouteQuery();
  const dispatch = useDispatch();

  const [currency, setCurrency] = useState("naira");
  const currencyOptions = [
    {
      label: "Naira",
      value: "naira",
    },
  ];

  const [payrollCountry, setPayrollCountry] = useState("Nigeria");
  const payrollCountryOptions = [
    {
      label: "Nigeria",
      value: "Nigeria",
    },
  ];

  const [update, setUpdate] = useState(false);

  const [setting, setSetting] = useState("group");
  const [payType, setPayType] = useState("");
  const [payValue, setPayValue] = useState("");
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [additionalCost, setAdditionalCost] = useState(0);
  const [breakDown, setBreakDown] = useState();
  const [totalPayrollCost, setTotalPayrollCost] = useState();
  const [fromSalary, setFromSalary] = useState(false);
  const [employee_id, setEmployee] = useState(null);
  const { mutateAsync: netReverse, isLoading: netLoading } = useNetPayReverse();
  const { mutateAsync: grossReverse, isLoading: grossLoading } =
    useGrossPayReverse();
  const { mutateAsync: addSalary, isLoading: loading } = useAddSalary();
  const { employee } = useSelector((state) => state.employeeSlice);

  useEffect(() => {
    if (query) {
      setPayType(query.get("type"));
      setFromSalary(query.get("salary"));
      setUpdate(query.get("update"));
    }
  }, [query]);

  useEffect(() => {
    if (employee) {
      setEmployee(employee.id);
    }
  }, [employee]);

  useEffect(() => {
    if (breakDown) {
      setTotalPayrollCost(
        parseFloat(breakDown.data.gross_pay) +
          parseFloat(breakDown.data.employer_nhis) +
          parseFloat(breakDown.data.employer_pension) +
          parseFloat(breakDown.data.nsitf) +
          parseFloat(breakDown.data.itf) +
          additionalCost
      );
    }
  }, [breakDown]);

  // const payrollOptions = [
  //   { label: 'Use Company Payroll Settings', value: 'global' },
  //   { label: 'Use Payroll Group Settings', value: 'group' },
  // ];
  const calcNetReverse = async () => {
    if (!employee_id) {
      dispatch(setAlert(true, "error", "Please select an employee"));
      return;
    }
    if (!payValue && payValue < 1) {
      if (payType === "net-pay") {
        dispatch(
          setAlert(
            true,
            "error",
            "Enter a valid net pay amount, greater than 0"
          )
        );
      } else if (payType === "gross-pay") {
        dispatch(
          setAlert(
            true,
            "error",
            "Enter a valid gross pay amount, greater than 0"
          )
        );
      }
      return;
    }

    let payload = {
      payroll_country: payrollCountry,
      setting,
      employee_id,
    };

    if (payType === "net-pay") {
      payload.net_pay = payValue;
      await netReverse(payload).then((result) => {
        setBreakDown(result);
        setShowBreakdown(true);
      });
    } else if (payType === "gross-pay") {
      payload.gross_pay = payValue;
      await grossReverse(payload).then((result) => {
        setBreakDown(result);
        setShowBreakdown(true);
      });
    }
  };
  const submitSalary = async () => {
    breakDown.data.payroll_country = payrollCountry;
    breakDown.data.payroll_currency = currency;
    breakDown.data.additional_allowances = JSON.stringify(
      breakDown.data.additional_allowances
    );
    let payload = { ...breakDown.data, ...{ employee: employee_id } };
    await addSalary(payload).then(() => {
      history.push("/salaries");
    });
  };

  return (
    <EmployeeOnboardingLayout
      step={fromSalary ? 2 : 4}
      title={update ? "Update Compensation" : "Add Compensation"}
    >
      <div className="flex flex-col w-full  h-full">
        <span className="header-4">
          Add Employee monthly
          {payType === "net-pay"
            ? " take home (Net Pay)"
            : payType === "gross-pay"
            ? " (Gross Pay)"
            : null}
        </span>
        <hr className="divider mb-2" />
        <div className="flex flex-col">
          {fromSalary ? (
            <div className="border bg-secondary-2-extralight p-2 rounded-md -pb-2">
              <FormSelectEmployee
                label={"Employee"}
                name="employee"
                placeholder="Type to select Employee"
                setEmployee={setEmployee}
                //  readOnly={loading}
              />
            </div>
          ) : (
            <div className="flex flex-col border bg-secondary-2-extralight p-2 rounded-md">
              <span className="text-[15px] font-extrabold text-color-gray">
                Employee name
              </span>
              <span className="text-[13px] text-color-black-2 font-semibold">
                {employee?.first_name} {employee?.last_name}
              </span>
            </div>
          )}
        </div>
        <hr className="divider mt-2 mb-2" />
        {employee_id ? (
          <>
            <div
              className={classNames(" flex flex-col", {
                hidden: showBreakdown,
              })}
            >
              <FormInput
                label={`Enter Monthly ${
                  payType === "net-pay"
                    ? "Net Pay"
                    : payType === "gross-pay"
                    ? "Gross Pay"
                    : "Full Pay"
                }`}
                placeholder={
                  payType === "net-pay"
                    ? "Enter Employee Net Pay"
                    : payType === "gross-pay"
                    ? "Enter Employee Gross Pay"
                    : "Enter Employee Full Pay"
                }
                name={payType}
                value={payValue}
                onInput={(e) => {
                  setPayValue(e.target.value);
                }}
                type="number"
              />
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full ">
                  <FormInput
                    label={`Additional costs - (HMO, data/airtime,  ...)`}
                    name={"additional_cost"}
                    value={additionalCost}
                    onInput={(e) => {
                      if (e.target.value) {
                        setAdditionalCost(e.target.value);
                      } else {
                        setAdditionalCost(0);
                      }
                    }}
                    type="number"
                  />
                </div>
                {/* <div className='w-full md:w-1/2 md:pl-2'>
                <FormSelect
                  defaultValue={setting}
                  options={payrollOptions}
                  onChange={(selected) => {
                    setSetting(selected);
                  }}
                  label='Payroll Setting'
                />
              </div> */}
              </div>
              <hr className="divider mt-2 mb-2" />
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormSelect
                    value={payrollCountry}
                    readOnly={true}
                    options={payrollCountryOptions}
                    onChange={(selected) => {
                      setPayrollCountry(selected);
                    }}
                    label="Payroll Country"
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-2">
                  <FormSelect
                    value={currency}
                    readOnly={true}
                    options={currencyOptions}
                    onChange={(selected) => {
                      setCurrency(selected);
                    }}
                    label="Payroll Currency"
                  />
                </div>
              </div>

              <div className="w-full mt-[20px]">
                <Button
                  text="VIEW BREAKDOWN"
                  type="submit"
                  disabled={!payValue || !employee_id}
                  loading={netLoading || grossLoading}
                  onClick={() => calcNetReverse()}
                />
              </div>

              <button
                className="flex justify-center w-full cursor-pointer hover:underline hover:scale-110 duration-300 mt-2"
                onClick={() => history.goBack()}
              >
                <span className="p2-medium text-color-black mt-3">
                  {" "}
                  Go Back
                </span>
              </button>
            </div>

            {/* View Breakdown */}
            <div
              className={classNames("flex flex-col", {
                hidden: !showBreakdown,
              })}
            >
              {showBreakdown ? (
                <>
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="Basic"
                        name="basic"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.basic_salary, 2)}
                      />
                    </div>

                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="Housing"
                        name="housing"
                        type="text"
                        readOnly
                        value={formatNumber(
                          breakDown?.data.housing_allowance,
                          2
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="Transportation"
                        name="basic"
                        type="text"
                        readOnly
                        value={formatNumber(
                          breakDown?.data.transportation_allowance,
                          2
                        )}
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="Leave allowance"
                        name="leave"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.leave_allowance, 2)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="Lunch allowance"
                        name="lunch"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.lunch_allowance, 2)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="Benefit in kind"
                        name="housing"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.benefit_in_kind, 2)}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="Utility allowance"
                        name="utility"
                        type="text"
                        readOnly
                        value={formatNumber(
                          breakDown?.data.utility_allowance,
                          2
                        )}
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="Other allowance"
                        name="other"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.other_allowance, 2)}
                      />
                    </div>
                  </div>
                  {typeof breakDown?.data.additional_allowances !== "string" &&
                  breakDown?.data.additional_allowances &&
                  breakDown?.data.additional_allowances.length > 0 ? (
                    <>
                      <hr className="divider mt-2 mb-2" />
                      <div className="text-[15px] font-extrabold mb-2">
                        Additional Allowances
                      </div>
                      <div className="flex flex-col md:flex-row justify-between">
                        {breakDown?.data.additional_allowances.map(
                          (item, index) => (
                            <div
                              className={classNames("w-full md:w-1/2", {
                                " md:pr-2": index % 2 === 0,
                                " md:pl-2": index % 2 !== 0,
                              })}
                            >
                              <FormInput
                                label={item.title}
                                name={item.name}
                                type="text"
                                readOnly
                                value={formatNumber(item.amount, 2)}
                              />
                            </div>
                          )
                        )}
                      </div>
                      <hr className="divider mt-2" />
                    </>
                  ) : null}
                  <hr className="divider" />
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full">
                      <FormInput
                        label="Gross Pay"
                        name="gross_pay"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.gross_pay, 2)}
                      />
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="Tax (PAYE)"
                        name="tax"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.tax, 2)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="Employee Pension"
                        name="employee_pension"
                        type="text"
                        readOnly
                        value={formatNumber(
                          breakDown?.data.employee_pension,
                          2
                        )}
                      />
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full">
                      <FormInput
                        label="Net Pay (Employee take home)"
                        name="net_pay"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.net_pay, 2)}
                        className={`font-bold bg-primary-2 bg-opacity-20 border-b-2 border-black`}
                      />
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="(ITF)"
                        name="itf"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.itf, 2)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="(NSITF)"
                        name="nsitf"
                        type="text"
                        readOnly
                        value={formatNumber(breakDown?.data.nsitf, 2)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="Additional Employment Cost"
                        name="additional_cost"
                        type="number"
                        readOnly
                        value={formatNumber(parseFloat(additionalCost), 2)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                      <FormInput
                        label="Total Employee Payroll Cost "
                        name="total_employee_cost"
                        type="text"
                        readOnly
                        className={`border-lime-600 bg-lime-200 font-bold border-b-2`}
                        value={formatNumber(totalPayrollCost, 2)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row px-4 p-2 border-secondary-4 border-[1.2px] rounded-lg bg-color-warning-light mt-2">
                    <div className="w-[70px] pr-2 flex flex-row items-center ">
                      <img src={WarningSVG} alt="" srcSet="" />
                    </div>
                    <div className=" flex flex-col text-[13px] text-justify gap-2">
                      <div className="flex flex-col pl-4 mt-1 border p-2 bg-highlight rounded-md">
                        <span className="text-[13px] font-bold text-black">
                          Net Pay (Employee take home)
                        </span>
                        <span className="mt-1 text-[11px]">
                          The is the amount that your employee would receive
                          upon running payroll after deducting taxes, pensions
                          and other statutory and non-statutory deductions.
                        </span>
                      </div>
                      <div className="flex flex-col pl-4 mt-1 border p-2 bg-highlight rounded-md">
                        <span className="text-[13px] font-bold text-black">
                          Total Employee Payroll Cost
                        </span>
                        <span className="mt-1 text-[11px]">
                          This represents the total cost to maintain the
                          employee. It includes both payroll and non-payroll
                          costs.
                          <br />
                          <span className="font-semibold">
                            NSITF and ITF
                          </span>{" "}
                          do not affect your payroll at the end of the month.
                          The burden of payment falls on you as the employer,
                          and would only be deducted when you choose to make
                          payment for it.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-[20px]">
                    <Button
                      text="Confirm & Save"
                      type="submit"
                      loading={loading}
                      onClick={() => submitSalary()}
                    />
                  </div>
                  <div
                    className="flex justify-center w-full cursor-pointer hover:underline hover:scale-110 duration-300 mt-2"
                    onClick={() => setShowBreakdown(false)}
                  >
                    <span className="p2-medium text-color-black mt-3">
                      Go Back
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : (
          <div className="h-[60px] flex flex-col gap-3 items-center justify-center w-full">
            <hr className="divider mt-4" />
            <span>No employee selected</span>
            <Button
              text="Go Back"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] w-fit p-2 px-4 "
              textClass={"!text-[11px]"}
              onClick={() => {
                history.goBack();
              }}
            />
          </div>
        )}
      </div>
    </EmployeeOnboardingLayout>
  );
}

export default AddSingleEmployeePay;
