import React, { useEffect } from 'react';
import DataTable from '../../datatable';
import { useState } from 'react';
import {
  payrollHistoryStatusConfig,
  PAGINATION_DEFAULT,
} from '../../../utils/constants';
import {
  useCheckBulkTransactionStatus,
  useCheckTransactionStatus,
  useRetryTransaction,
} from '../../../redux/payroll/hook/index';
import { useGetVendorHistory } from '../../../redux/spend-management/vendor/hook';
import Button from '../../button';
import { useExportTransactionsHistory } from '../../../redux/wallet/hook';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import { RetryToAnotherBank } from '../../modal/wallet/retry-to-another-bank';

const VendorHistoryTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
    start_date: '',
    end_date: '',
  });

  const workbook = new Excel.Workbook();

  const columns = [
    { header: 'Date', key: 'created' },
    { header: 'Name', key: 'name' },
    { header: 'Description', key: 'info' },
    { header: 'Ref', key: 'internalRef' },
    { header: 'Payment Group', key: 'g_type' },
    { header: 'Type', key: 'type' },
    { header: 'Payments Status', key: 'status' },
    { header: 'Outflow', key: 'debit' },
    { header: 'Inflow', key: 'credit' },
  ];

  const dispatch = useDispatch();

  const [exportLoading, setSetExportLoading] = useState(false);

  const { data: vendorHistory } = useGetVendorHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date,
  );

  const { mutateAsync: refreshTransactionStatus, isLoading: refreshLoading } =
    useCheckBulkTransactionStatus();

  const { mutateAsync: exportHistory } = useExportTransactionsHistory();

  const exportWalletHistory = async () => {
    setSetExportLoading(true);
    let payload = {
      limit: 100000,
      page: 1,
      statusFilter: pagination?.statusFilter,
      search: pagination?.search,
      start_date: pagination?.start_date,
      end_date: pagination?.end_date,
      type: pagination?.type,
    };

    await exportHistory(payload).then(async (response) => {
      let data = response.data;
      const workSheetName = 'wallet-history';
      const workBookName = `Wallet History from ${pagination?.start_date} - ${pagination?.end_date}`;
      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        worksheet.columns.forEach((column) => {
          if (column._number > 7) {
            column.width = column.header.length + 5;
            column.style.alignment = { horizontal: 'right' };
            column.numFmt = '0.00';
          } else {
            column.width = column.header.length + 2;
            column.style.alignment = { horizontal: 'left' };
          }
        });

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through data and add each one to worksheet
        data.forEach((singleData) => {
          if (singleData.status === 1) {
            singleData.status = 'Successful';
          } else if (singleData.status === 2) {
            singleData.status = 'Failed';
          } else if (singleData.status === 0) {
            singleData.status = 'Pending';
          } else if (singleData.status === 3) {
            singleData.status = 'Retried: Failed';
          }
          if (singleData.type === 'wallet') {
            singleData.credit = singleData.amount;
            singleData.g_type = 'deposit';
          } else {
            singleData.debit = singleData.amount;
          }
          worksheet.addRow(singleData);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: 'none' },
              left: { style: 'none' },
              bottom: { style: 'none' },
              right: { style: 'none' },
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        // console.error('<<<ERRROR>>>', error);
        // console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  const { mutateAsync: checkTransactionStatus } = useCheckTransactionStatus();

  const { mutateAsync: retryTransaction } = useRetryTransaction();

  const [showRetryToAnotherBank, setShowRetryToAnotherBank] = useState(false);
  const [transactionId, setTransactionId] = useState();

  const tableConfig = {
    headers: [
      'Date Created',
      'Name',
      'Description',
      'Ref',
      'Fee',
      'Amount',
      'Status',
    ],
    keys: [
      'created',
      'name',
      'info',
      'internalRef',
      'fees',
      'amount',
      'status',
    ],
    mobileHeader: {
      left: {
        title: 'Date Created',
        key: 'created',
      },
      right: {
        title: 'Amount',
        key: 'amount',
      },
    },
  };

  const dateConfig = {
    startDate: '0000-00-00',
    endDate: '0000-00-00',
  };

  const actionConfig = [
    {
      name: 'Check Status',
      color: 'green',
      action: async (id) => {
        let index = vendorHistory.data.findIndex((item) => item.id === id);
        await checkTransactionStatus(vendorHistory.data[index].id);
      },
    },
    {
      name: 'Retry',
      color: 'red',
      action: (id) => {
        let index = vendorHistory?.data.findIndex((item) => item.id === id);
        retryTransaction(vendorHistory?.data[index].id);
      },
    },
    {
      name: 'Retry To Another Bank',
      color: 'red',
      action: (id) => {
        let index = payrollHistory.data.findIndex((item) => item.id === id);
        setTransactionId(payrollHistory.data[index].id);
        setShowRetryToAnotherBank(true);
      },
    },
  ];

  return (
    <div className='flex flex-col bg-white rounded'>
      {showRetryToAnotherBank ? (
        <RetryToAnotherBank
          isOpen={showRetryToAnotherBank}
          transaction_id={transactionId}
          closeModal={() => setShowRetryToAnotherBank(false)}
        />
      ) : null}
      <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
        <div className='md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full'>
          Vendor Payment Historyx
        </div>
        <div className='flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full'>
          <div className='md:w-fit w-[100%]'>
            <Button
              text={`Export`}
              type='submit'
              loading={exportLoading}
              className='flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center'
              textClass={'!text-[11px]'}
              leftIcon={'export'}
              onClick={() => exportWalletHistory()}
            />
          </div>
          <div className='md:w-fit w-[100%]'>
            <Button
              text='Refresh Transactions'
              type='button'
              theme='primary'
              className='flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center'
              textClass={'!text-[11px]'}
              leftIcon={'refresh'}
              loading={refreshLoading}
              onClick={() => {
                refreshTransactionStatus();
              }}
            />
          </div>
        </div>
      </div>
      <hr className='divider mt-2 mb-2' />
      <DataTable
        data={vendorHistory}
        refresh={true}
        tableConfig={tableConfig}
        filterByDate={dateConfig}
        filterByStatus={payrollHistoryStatusConfig}
        statusConfig={payrollHistoryStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default VendorHistoryTab;
