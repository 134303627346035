import React, { useEffect } from "react";
import { useState } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Button from "../../button";
import LoadingBar from "../../loader-bar";
import FormSwitch from "../../form-switch";
import FormSelect from "../../form-select";
import FormInput from "../../form-input";
import { formValidation } from "../../../utils/functions";
import FormSelectEmployee from "../../..../..//employees/form-select-employee";
import { useGetTeamSettings } from "../../../redux/human-resources/hook/teams";
import { useSelector } from "react-redux";
import { useUpdateAttendanceSettings } from "../../../redux/human-resources/hook/attendance";
import DOMPurify from "dompurify";
import { useUpdateManagerSettings } from "../../../redux/human-resources/hook/managers";
import { useUpdateLeaveSettings } from "../../../redux/human-resources/hook/leave";

const TeamSettings = () => {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const [sourceId, setSourceId] = useState();

  const settings_type = [
    {
      name: "MANAGER SETTINGS",
      title: "Team Manager Settings",
    },
    {
      name: "ATTENDANCE SETTINGS",
      title: "Team Attendance Settings",
    },
    {
      name: "LEAVE SETTINGS",
      title: "Team Leave Settings",
    },
  ];

  const { id } = useParams();

  const { company } = useSelector((state) => state.companySlice);

  const { data: team_settings, isLoading } = useGetTeamSettings(id);

  const [applyToPayroll, setApplyToPayroll] = useState(true);
  const [clockinWithLink, setClockinWithLink] = useState(true);

  useEffect(() => {
    if (team_settings) {
      setSourceId(team_settings.id);
      if (team_settings.manager_setting) {
        setManager(team_settings.manager_setting?.manager_id);
        setManagerName(team_settings.manager_setting?.manager);
        setManagerSettingId(team_settings.manager_setting?.id);
        setEvaluatePerformance(
          team_settings.manager_setting?.evaluate_performance
        );
        setApproveLeave(team_settings.manager_setting?.approve_leave);
      }
      if (team_settings.leave_setting) {
        setDayCount(
          team_settings.leave_setting?.days_count
            ? team_settings.leave_setting?.days_count
            : "seven_days"
        );
        setApplicationStart(team_settings.leave_setting?.application_start);
      }
      if (team_settings.attendance_setting) {
        setWorkdays([
          {
            day: "Sunday",
            tag: "SUN",
            work: team_settings.attendance_setting?.workdays?.sun,
          },
          {
            day: "Monday",
            tag: "MON",
            work: team_settings.attendance_setting?.workdays?.mon,
          },
          {
            day: "Tuesday",
            tag: "TUE",
            work: team_settings.attendance_setting?.workdays?.tue,
          },
          {
            day: "Wednesday",
            tag: "WED",
            work: team_settings.attendance_setting?.workdays?.wed,
          },
          {
            day: "Thursday",
            tag: "THU",
            work: team_settings.attendance_setting?.workdays?.thu,
          },
          {
            day: "Friday",
            tag: "FRI",
            work: team_settings.attendance_setting?.workdays?.fri,
          },
          {
            day: "Saturday",
            tag: "SAT",
            work: team_settings.attendance_setting?.workdays?.sat,
          },
        ]);
        setApplyToPayroll(
          team_settings.attendance_setting?.apply_to_payroll == 1 ? true : false
        );
        setClockinWithLink(
          team_settings.attendance_setting?.clockin_with_link == 1
            ? true
            : false
        );
        setAutoConfirm(
          team_settings.attendance_setting?.auto_confirm == 1 ? true : false
        );
        setClockIn(
          team_settings.attendance_setting?.clockin_time
            ? team_settings.attendance_setting?.clockin_time
            : "09:00"
        );
        setClockOut(
          team_settings.attendance_setting?.clockout_time
            ? team_settings.attendance_setting?.clockout_time
            : "17:00"
        );
      }
    }
  }, [team_settings]);

  const [selectedAccountIndex, setSelectedAccountIndex] = useState(0);

  //Manager settings
  const { mutateAsync: updateManagerSettings, isLoading: managerLoading } =
    useUpdateManagerSettings();
  const [managerSettingId, setManagerSettingId] = useState();
  const [manager, setManager] = useState();
  const [managerName, setManagerName] = useState();

  const updateManagerSetting = async () => {
    let payload = {
      id: managerSettingId,
      data: {
        manager_id: manager,
        approve_leave: approveLeave,
        evaluate_performance: evaluatePerformance,
      },
    };
    await updateManagerSettings(payload);
  };

  //Leave Settings
  const { mutateAsync: updateLeaveSettings, isLoading: leaveLoading } =
    useUpdateLeaveSettings();

  const [dayCount, setDayCount] = useState("seven_days");
  const [applicationStart, setApplicationStart] = useState(60);
  const dayCountOptions = [
    { value: "", label: "Select Days Count" },
    { value: "seven_days", label: "Calendar Days" },
    { value: "week_days", label: "Working Days" },
  ];

  const updateLeaveSetting = async () => {
    let payload = {
      source: "team",
      source_id: sourceId,
      application_start: applicationStart,
      days_count: dayCount,
    };
    await updateLeaveSettings(payload);
  };

  // Attendance Settings
  const {
    mutateAsync: updateAttendanceSettings,
    isLoading: attendanceLoading,
  } = useUpdateAttendanceSettings();
  const [location, setLocation] = useState(
    company
      ? company.address +
          ", " +
          company.city +
          ", " +
          company.state +
          ", " +
          company.country
      : ""
  );
  const [workDays, setWorkdays] = useState([
    {
      day: "Sunday",
      tag: "SUN",
      work: 0,
    },
    {
      day: "Monday",
      tag: "MON",
      work: 1,
    },
    {
      day: "Tuesday",
      tag: "TUE",
      work: 1,
    },
    {
      day: "Wednesday",
      tag: "WED",
      work: 1,
    },
    {
      day: "Thursday",
      tag: "THU",
      work: 1,
    },
    {
      day: "Friday",
      tag: "FRI",
      work: 1,
    },
    {
      day: "Saturday",
      tag: "SAT",
      work: 1,
    },
  ]);

  const [autoConfirm, setAutoConfirm] = useState(true);
  const [approveLeave, setApproveLeave] = useState(true);
  const [evaluatePerformance, setEvaluatePerformance] = useState(true);
  const [clockIn, setClockIn] = useState("09:00");
  const [clockOut, setClockOut] = useState("17:00");
  // const [clockType, setClockType] = useState('flexible');
  // const clockTypeOptions = [
  //   {label:'Fixed', value: 'fixed'},
  //   {label:'Flexible', value: 'flexible'},
  // ]

  const updateAttendanceSetting = async (data) => {
    const clock_in = DOMPurify.sanitize(data?.clock_in);
    const clock_out = DOMPurify.sanitize(data?.clock_out);
    // const location = DOMPurify.sanitize(data?.location);

    let payload = {
      source: "team",
      source_id: sourceId,
      clockin_time: clock_in,
      clockout_time: clock_out,
      apply_to_payroll: applyToPayroll ? 1 : 0,
      clockin_with_link: clockinWithLink ? 1 : 0,
      auto_confirm: autoConfirm,
      sun: workDays[0].work,
      mon: workDays[1].work,
      tue: workDays[2].work,
      wed: workDays[3].work,
      thu: workDays[4].work,
      fri: workDays[5].work,
      sat: workDays[6].work,
    };
    await updateAttendanceSettings(payload);
  };

  return (
    <>
      <div className="flex flex-col bg-white w-full rounded min-h-fit pb-4">
        <div className="flex flex-col px-8 pt-2">
          <span className="header-4">Team Settings</span>
          <span className="text-color-gray text-[13px]">
            Update and edit your Team settings
          </span>
        </div>
        <hr className="divider mt-2 mb-3" />
        {isLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px] px-8">
            <LoadingBar loading={isLoading} />
          </div>
        ) : (
          <div className="flex flex-col md:flex-row h-full px-8">
            <div className="flex flex-col md:max-w-[450px] w-full ">
              {settings_type.map((item, index) => (
                <div
                  className={classNames(
                    "w-full max-w-[100%] md:max-w-[450px] h-[80px] flex flex-row items-center px-3 justify-between cursor-pointer border border-gray-300 rounded mb-3 hover:bg-gray-50 duration-300",
                    {
                      "bg-[#eff2fe]": index === selectedAccountIndex,
                    }
                  )}
                  onClick={() => {
                    if (selectedAccountIndex !== index) {
                      setSelectedAccountIndex(index);
                    }
                  }}
                  key={index}
                >
                  <div className="flex flex-col">
                    <span className="p4-bold">{item.name}</span>
                    <span className="p4-regular">{item.title}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-[100%] md:ml-4">
              <div className="border border-color-line rounded px-2 md:px-4 py-2 flex flex-col">
                <div className="w-full md:w-[100%]">
                  {selectedAccountIndex === 0 ? (
                    <form>
                      <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full">
                        <FormSelectEmployee
                          label={"Team Lead"}
                          name="employee"
                          placeholder="Type to select team lead"
                          value={manager}
                          empName={managerName}
                          setEmployee={setManager}
                        />
                        <div className={"flex w-[100%] flex-col -mt-4"}>
                          <div className="w-full">
                            <FormSwitch
                              label={"Can Approve Leave?"}
                              name={"approve_leave"}
                              checked={approveLeave}
                              onClick={() => {
                                setApproveLeave(!approveLeave);
                              }}
                            />
                          </div>
                          <div className="w-full">
                            <FormSwitch
                              label={"Can Evaluate Performance?"}
                              name={"evaluate_performance"}
                              checked={evaluatePerformance}
                              onClick={() => {
                                setEvaluatePerformance(!evaluatePerformance);
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex items-center justify-end w-full mt-2">
                          <Button
                            text="Update Manager Settings"
                            type="submit"
                            loading={managerLoading}
                            disabled={!manager ? true : false}
                            theme="primary"
                            className="flex gap-2 !h-[35px] w-fit p-2 "
                            textClass={"!text-[11px]"}
                            onClick={(e) => {
                              e.preventDefault();
                              updateManagerSetting();
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  ) : selectedAccountIndex === 1 ? (
                    <form onSubmit={handleSubmit(updateAttendanceSetting)}>
                      <div className="grid md:grid-cols-1 grid-cols-1 gap-2 w-full">
                        <div className="flex flex-row md:flex-col w-full justify-between">
                          <div className="w-full md:w-1/2 pr-2">
                            <FormInput
                              label="Resumption Time"
                              name="clock_in"
                              type="time"
                              value={clockIn}
                              onInput={(e) => {
                                setClockIn(e.target.value);
                              }}
                              inputRef={register(formValidation("time", true))}
                              error={errors.clock_in}
                              errorMessage={
                                errors.clock_in && errors.clock_in.message
                              }
                            />
                          </div>
                          <div className="w-full md:w-1/2 pl-2">
                            <FormInput
                              label="Closing Time"
                              name="clock_out"
                              type="time"
                              value={clockOut}
                              onInput={(e) => {
                                setClockOut(e.target.value);
                              }}
                              inputRef={register(formValidation("time", true))}
                              error={errors.clock_out}
                              errorMessage={
                                errors.clock_out && errors.clock_out.message
                              }
                            />
                          </div>
                        </div>
                        <div
                          className={
                            "flex w-[100%] flex-col items-center gap-3 mt-1 "
                          }
                        >
                          <div
                            className={
                              "flex w-full items-start justify-between gap-3 flex-col -mt-1"
                            }
                          >
                            <div className="text-color-gray text-[13px] ">
                              Work Days
                            </div>
                            <div className="flex flex-row items-center gap-1 justify-between w-full p-[12px] border-1 border-gray-300 bg-highlight rounded -mt-4">
                              {workDays.map((item, index) => (
                                <button
                                  type="button"
                                  className={
                                    !item.work || item.work === 0
                                      ? "flex flex-row items-center !h-[24px] px-2 border-1 rounded border-[#0d5225] text-[#0d5225] text-[12px] cursor-pointer"
                                      : "flex flex-row items-center !h-[24px] px-2 border-1 rounded border-[#0d5225] bg-[#0d5225] text-color-white text-[12px] cursor-pointer"
                                  }
                                  onClick={() => {
                                    let newArray = JSON.parse(
                                      JSON.stringify(workDays)
                                    );
                                    newArray[index].work =
                                      !item.work || item.work === 0 ? 1 : 0;
                                    setWorkdays(newArray);
                                  }}
                                >
                                  {item.tag}
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                        <hr className="divider" />
                        <div className="w-full">
                          <FormSwitch
                            label={"Apply To Payroll?"}
                            name={"apply_to_payroll"}
                            checked={applyToPayroll}
                            onClick={() => {
                              setApplyToPayroll(!applyToPayroll);
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <FormSwitch
                            label={"Employee Clockin With Link?"}
                            name={"clockin_with_link"}
                            checked={clockinWithLink}
                            onClick={() => {
                              setClockinWithLink(!clockinWithLink);
                            }}
                          />
                        </div>
                        <div className="flex items-center justify-end w-full mt-[20px]">
                          <Button
                            text="Update Attendance Settings"
                            type="submit"
                            loading={attendanceLoading}
                            theme="primary"
                            className="flex gap-2 !h-[35px] w-fit p-2 "
                            textClass={"!text-[11px]"}
                          />
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form onSubmit={handleSubmit(updateLeaveSetting)}>
                      <div className="flex flex-row items-center">
                        <div className="w-full">
                          <FormInput
                            label="Application Start (From Date Of Employment)"
                            name="application_start"
                            type="number"
                            value={applicationStart}
                            onInput={(e) => {
                              setApplicationStart(e.target.value);
                            }}
                            inputRef={register(formValidation("number", true))}
                            error={errors.application_start}
                            errorMessage={
                              errors.application_start &&
                              errors.application_start.message
                            }
                          />
                        </div>
                        <span className="pt-1 italic text-[14px] pl-2 text-[#0d5225]">
                          Days
                        </span>
                      </div>
                      <div className="flex flex-row items-center">
                        <div className="w-full">
                          <FormSelect
                            value={dayCount}
                            options={dayCountOptions}
                            onChange={(selected) => {
                              setDayCount(selected);
                            }}
                            label="Day Count"
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-end w-full mt-[20px]">
                        <Button
                          text="Update Leave Settings"
                          type="submit"
                          loading={leaveLoading}
                          theme="primary"
                          className="flex gap-2 !h-[35px] w-fit p-2 "
                          textClass={"!text-[11px]"}
                        />
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TeamSettings;
