import React, { useEffect, useState } from "react";
import Button from "../../components/button";
import { Card } from "../../components/card";
import DashboardLayout from "../../components/layouts/dashboard";
import SelectAirtimeOption from "../../components/modal/airtime/select-airtime-option";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import UnauthorizedPage from "../errors/unauthorized";
import TabView from "../../components/tabview";
import { AirtimeSummaryTab } from "../../components/airtime/airtime-summary";
import AirtimeHistoryTab from "../../components/airtime/airtime-history";
import PayoutSVG from "../../assets/svg/payout.svg";

const AirtimePage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.airtime_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);
  const location = useLocation();

  useEffect(() => {
    if (location && location.state) {
      setActiveTab(location.state.tab);
    }
  }, [location]);

  const airtimeTabs = [
    {
      title: "Airtime Summary",
      Content:
        user?.permissions?.airtime_permission?.airtime_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? AirtimeSummaryTab
          : UnauthorizedPage,
    },
    {
      title: "Airtime History",
      Content:
        user?.permissions?.airtime_permission?.airtime_history_read === 1 ||
        user.permissions?.company_permission?.role === "Owner"
          ? AirtimeHistoryTab
          : UnauthorizedPage,
    },
  ];
  return (
    <DashboardLayout title="Airtime">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="mt-2">
              <TabView
                Tabs={airtimeTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default AirtimePage;
