import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import { pipStatusConfig, PAGINATION_DEFAULT } from "../../utils/constants";
import Button from "../button";
import DoYouWantToModal from "../modal/do-you-want-to";
import InfoModal from "../modal/info-modal";
import { useGetEmployees } from "../../redux/employees/hook";
import AddPIPModal from "../modal/hr-section/employee-pip/add-pip";
import { useDeletePIP, useGetPIPs } from "../../redux/employees/hook/pip";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const PerformanceImprovementPlanTab = () => {
  useEffect(() => {}, []);
  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const history = useHistory();

  const { refetch, data: Pips } = useGetPIPs(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [showInfoModal, setShowInfoModal] = useState(false);

  const [showAddPIP, setShowAddPIP] = useState(false);
  const [pipData, setPIPData] = useState();
  const [title, setTitle] = useState("Add employee PIP");
  const [buttonText, setButtonText] = useState("Add");

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { mutateAsync: deletePIP, isLoading: deleteLoading } = useDeletePIP();

  const tableConfig = {
    headers: [
      "Employee Name",
      "Phone Number",
      "Purpose",
      "Start Date",
      "End Date",
      "Status",
    ],
    keys: [
      "employeeName",
      "mobile",
      "purpose",
      "formatted_start_date",
      "formatted_end_date",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "mobile",
      },
    },
  };

  const deleteSinglePIP = async (id) => {
    let index = Pips.data.findIndex((item) => item.id === id);
    await deletePIP(Pips.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const actionConfig = [
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = Pips.data.findIndex((item) => item.id === id);
        setButtonText("Update");
        setTitle("Update PIP");
        setPIPData(Pips.data[index]);
        setShowAddPIP(true);
      },
    },
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = Pips.data.findIndex((item) => item.id === id);
        history.push(
          "/employees/performance-improvement-plan/" + Pips.data[index].id
        );
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = Pips.data.findIndex((item) => item.id === id);
        setDeleteId(Pips.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  return (
    <div className="flex flex-col mt-4">
      <div className="flex flex-col bg-white rounded border">
        {showDoYouWant ? (
          <DoYouWantToModal
            isOpen={showDoYouWant}
            closeModal={() => setShowDoYouWant(false)}
            id={deleteId}
            loading={deleteLoading}
            title={
              "Are you sure you want to delete this Performance Improvement Plan?"
            }
            buttonText={"DELETE"}
            btnFunction={deleteSinglePIP}
          />
        ) : null}
        {showAddPIP ? (
          <AddPIPModal
            isOpen={showAddPIP}
            closeModal={() => setShowAddPIP(false)}
            pipData={pipData}
            title={title}
            buttonText={buttonText}
            leftIcon={"add"}
          />
        ) : null}
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"pip"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}

        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[60%] w-full">
            Employees on Performance Improvement Plan (PIP)
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[40%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="ADD EMPLOYEE TO PIP"
                type="submit"
                theme="primary"
                disabled={!employees}
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => {
                  setShowAddPIP(true);
                }}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="REFETCH"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"change"}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={Pips}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={pipStatusConfig}
          filterByStatus={true}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};
