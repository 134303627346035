import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'customer',
  initialState: {
    customer: null,
  },
  reducers: {
    setCustomer: (state, { payload }) => {
      state.customer = payload;
    },
  },
});

// Actions
export const { setCustomer } = slice.actions;

export default slice.reducer;