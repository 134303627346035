import React from 'react';
import PayrollPage from '../../pages/payroll-section/payroll-management/index';
import BasicPayrollPreviewPage from '../../pages/payroll-section/payroll-management/basic-payroll-preview';
import BasicPayrollViewPage from '../../pages/payroll-section/payroll-management/basic-payroll-view';
import AdvancedPayrollPreviewPage from '../../pages/payroll-section/payroll-management/advanced-payroll-preview';
import AdvancedPayrollViewPage from '../../pages/payroll-section/payroll-management/advanced-payroll-view';
import ProtectedRoute from '../protected';

export const PayrollRoutes = [
  <ProtectedRoute exact path='/payroll' component={PayrollPage} />,
  <ProtectedRoute
    exact
    path='/payroll/basic/preview/:refId'
    component={BasicPayrollPreviewPage}
  />,
  <ProtectedRoute
    exact
    path='/payroll/basic/view/:refId'
    component={BasicPayrollViewPage}
  />,
  <ProtectedRoute
    exact
    path='/payroll/advanced/preview/:refId'
    component={AdvancedPayrollPreviewPage}
  />,
  <ProtectedRoute
    exact
    path='/payroll/advanced/view/:id'
    component={AdvancedPayrollViewPage}
  />
]
