import React from "react";

export const Picture = () => (
  <svg fill="#ffffff" height="16" width="16" version="1.1"  viewBox="0 0 340 340" enable-background="new 0 0 340 340" stroke="#ffffff">

    <g id="SVGRepo_bgCarrier" stroke-width="0"/>

    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

    <g id="SVGRepo_iconCarrier"> <path d="m290,45h-20v22.114l-23.406-54.614h-153.188l-32.143,75h-61.263v240h340v-240h-50v-42.5zm-183.406-12.5h126.813l23.571,55h-173.955l23.571-55zm-86.594,75h104.2c-37.852,17.405-64.2,55.681-64.2,100s26.348,82.595 64.2,100h-104.2v-200zm150,10c49.626,0 90,40.374 90,90s-40.374,90-90,90-90-40.374-90-90 40.374-90 90-90zm150,190h-104.2c37.852-17.405 64.2-55.681 64.2-100s-26.348-82.595-64.2-100h104.2v200z"/> </g>

  </svg>
);
