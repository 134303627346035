import React, { useEffect } from "react";
import CloseSVG from "../../../../assets/svg/close.svg";
import Modal from "../../modal";
import moment from "moment";

function ViewSchedule({
  isOpen,
  closeModal,
  refresh,
  scheduleData,
  // title,
  // buttonText,
}) {
  useEffect(() => {}, []);

  return (
    <Modal
      scrollable={true}
      className="!w-[500px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col pb-4 overflow-auto">
        <div className="pl-8 pr-8 pt-4">
          <div className="flex flex-row justify-between items-center">
            <span className="header-4">Attendance Schedule</span>
            <div className="cursor-pointer" onClick={() => closeModal()}>
              <img src={CloseSVG} alt="close" />
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="flex flex-col mx-8">
          <div className="flex flex-col w-full h-fit">
            <div className="flex flex-col">
              <div className="flex flex-col mt-1">
                <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                  <div className="flex flex-col">
                    <span className="h4-regular text-color-gray">Date</span>
                    <span className="mt-1 text-color-black font-bold">
                      {moment(scheduleData?.date).format("MMMM DD, YYYY")}
                    </span>
                  </div>
                </div>
                <hr className="divider" />
                <div className="grid md:grid-cols-2  grid-cols-2 gap-4 w-full">
                  <div className="flex flex-col">
                    <span className="h4-regular text-color-gray">
                      Clockin Time
                    </span>
                    <span className="mt-1 text-hr-primary-1 font-bold">
                      {scheduleData?.clockin_time}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="h4-regular text-color-gray">
                      Clockout Time
                    </span>
                    <span className="mt-1 text-red-400 font-bold">
                      {scheduleData?.clockout_time
                        ? scheduleData?.clockout_time
                        : "Not clocked Out"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ViewSchedule;
