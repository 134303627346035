import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { setEmployeeOnboarding} from '../reducer';
import moment from 'moment';
import { employeeOnboardings } from '../../../api/employees/onboarding';

export function useGetEmployeeOnboardingSummary() {
  return useQuery(
    ['onboarding-summary'],
    () => {
      return employeeOnboardings.getOnboardingSummary();
    }
  );
}

export function useGetOnboardings(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-onboardings', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeOnboardings.getEmployeeOnboardings({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.created = moment(employee?.created).format('MMMM D, YYYY')
          employee.computedActions = [0, 1, 2, 3];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetSingleEmployeeOnboarding(id) {
  return useQuery(
    ['single-employee-onboarding', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeOnboardings.getEmployeeOnboarding(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployeeOnboarding(response.data));
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddEmployeeOnboarding() {
    return useMutation(
      (payload) => {
        return employeeOnboardings.addEmployeeOnboarding(payload);
      },
      {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('employee-onboardings');
            store.dispatch(setAlert(true, 'success', 'Employee assigned to onboarding process successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useAddMultipleEmployeeOnboarding() {
  return useMutation(
    (payload) => {
      return employeeOnboardings.addMultipleEmployeeOnboarding(payload);
    },
    {
      onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('employee-onboardings');
          store.dispatch(setAlert(true, 'success', 'Employee(s) assigned to onboarding process successfully'));
      },
      onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteOnboarding() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.deleteEmployeeOnboarding(payload);
      },
      {
          onSuccess: (response, variables, context) => {
              queryClient.invalidateQueries('employee-onboardings');
              queryClient.invalidateQueries('single-employee-onboarding');
              store.dispatch(setAlert(true, 'success', 'Onboarding Updated successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useGetOnboardingWorkflows(limit, page, search) {
    return useQuery(
      ['onboarding-workflows', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeeOnboardings.getOnboardingWorkflows({
          limit,
          page,
          search,
        });
      },
      {
        select: (data) => {
          data?.data?.forEach((recruitment) => {
            recruitment.created = moment(recruitment.created).format('MMMM D, YYYY');
            recruitment.computedActions = [0, 1, 2, 3];
            recruitment.status = recruitment.application_status;
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetOnboardingWorkflow(id) {
    return useQuery(
        ['employee-onboarding-workflow',  {id} ],
        () => {
          return employeeOnboardings.getOnboardingWorkflow(id);
        },
        {
          enabled: id !== null,
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', response.message));
          },
          onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
          },
        },
    );
    
}

export function useGetSelectOnboardingWorkflows(limit, page, search) {
    return useQuery(
      ['onboarding-creation-workflows', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeeOnboardings.getOnboardingWorkflows({
          limit,
          page,
          search,
        });
      },
      {
        select: (data) => {
          let newData = [];
          newData.push({ value: '', label: 'No Workflow Selected' });
          data?.data?.forEach((item) => {
            newData.push({ value: item.id, label: item.name });
          });
          return newData;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useAddOnboardingWorkflow() {
    return useMutation(
      (payload) => {
        return employeeOnboardings.addOnboardingWorkflow(payload);
      },
      {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('onboarding-workflows');
            store.dispatch(setAlert(true, 'success', 'Added successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useUpdateOnboardingWorkflow() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return employeeOnboardings.updateOnboardingWorkflow(payload);
        },
        {
            onSuccess: (response, variables, context) => {
                queryClient.invalidateQueries('onboarding-workflows');
                store.dispatch(setAlert(true, 'success', 'Updated successfully'));
            },
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useAddOnboardingSubWorkflow() {
    return useMutation(
      (payload) => {
        return employeeOnboardings.addOnboardingSubWorkflow(payload);
      },
      {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('onboarding-subworkflows');
            queryClient.invalidateQueries('employee-onboarding-workflow');
            store.dispatch(setAlert(true, 'success', 'Added successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useUpdateOnboardingSubWorkflow() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return employeeOnboardings.updateOnboardingSubWorkflow(payload);
        },
        {
            onSuccess: (response, variables, context) => {
                queryClient.invalidateQueries('onboarding-subworkflows');
                queryClient.invalidateQueries('employee-onboarding-workflow');
                store.dispatch(setAlert(true, 'success', 'Updated successfully'));
            },
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useDeleteOnboardingWorkflow() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.deleteOnboardingWorkflow(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('onboarding-workflows');
            queryClient.invalidateQueries('employee-onboarding-workflow');
            store.dispatch(setAlert(true, 'success', 'Onboarding workflow updated successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useMarkOnboardingStageComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.markOnboardingStageComplete(payload);
      },
      {
          onSuccess: (response, variables, context) => {
              queryClient.invalidateQueries('single-employee-onboarding');
              store.dispatch(setAlert(true, 'success', 'Updated successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useMarkOnboardingComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.markOnboardingComplete(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('employee-onboardings');
              queryClient.invalidateQueries('single-employee-onboarding');
              store.dispatch(setAlert(true, 'success', 'Updated successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useGenerateOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeOnboardings.generateEmployeeOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useConfirmOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeOnboardings.confirmEmployeeOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployeeOnboarding(response.data));
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEmployeeMarkOnboardingStageComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.employeeMarkStageComplete(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'Updated successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useEmployeeMarkOnboardingComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.employeeMarkOnboardingComplete(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          store.dispatch(setAlert(true, 'success', 'Updated successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
      },
  );
}

export function useSaveAnswer() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.employeeSaveAnswer(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'Updated successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useUploadFile() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeOnboardings.uploadRequestedFile(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'File uploaded successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useClearOnboardingData() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return;
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setEmployeeOnboarding(null));
            store.dispatch(setAlert(true, 'success', 'Session Expired'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

