import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { employeeExits } from '../../../api/employees/exit';
import { setEmployeeExit } from '../reducer';

export function useGetExitSummary() {
  return useQuery(
    ['exit-summary'],
    () => {
      return employeeExits.getExitSummary();
    }
  );
}

export function useGetResignations(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-resignations', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeExits.getEmployeeResignations({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.new_notice_date = moment(employee?.notice_date).format('MMMM D, YYYY')
          employee.new_left_date = moment(employee?.left_date).format('MMMM D, YYYY')
          
          switch (employee.status) {
            case 0:
              employee.computedActions = [0, 1, 2, 3];
              break;
            case 1:
              employee.computedActions = [0, 3];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetTerminations(limit, page, statusFilter, search) {
    return useQuery(
      ['employee-terminations', { limit, page, statusFilter, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeeExits.getEmployeeTerminations({ limit, page, statusFilter, search });
      },
      {
        select: (data) => {
          data?.data?.forEach((employee) => {
            employee.employeeName = `${employee.first_name} ${employee.last_name}`;
            employee.new_notice_date = moment(employee?.notice_date).format('MMMM D, YYYY')
            employee.new_left_date = moment(employee?.left_date).format('MMMM D, YYYY')
            
            switch (employee.status) {
              case 0:
                employee.computedActions = [0, 1, 2, 3];
                break;
              case 1:
                employee.computedActions = [0, 3];
                break;
              default:
                break;
            }
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetExitWorkflows(limit, page, search) {
    return useQuery(
      ['exit-workflows', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeeExits.getExitWorkflows({
          limit,
          page,
          search,
        });
      },
      {
        select: (data) => {
          data?.data?.forEach((recruitment) => {
            recruitment.created = moment(recruitment.created).format('MMMM D, YYYY');
            recruitment.computedActions = [0, 1, 2];
            recruitment.status = recruitment.application_status;
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetSingleEmployeeExit(id) {
  return useQuery(
    ['single-employee-exit', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeExits.getSingleEmployeeExit(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployeeExit(response.data));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetSingleEmployeeExitAnswers(id) {
  return useQuery(
    ['single-employee-exit-answers', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeExits.getSingleEmployeeExitAnswers(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMarkExitStageComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeExits.changeExitStageStatus(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('employee-resignations');
            queryClient.invalidateQueries('employee-terminations');
            queryClient.invalidateQueries('single-employee-exit');
            store.dispatch(setAlert(true, 'success', 'Exit Stage Updated successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useMarkExitComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeExits.changeExitStatus(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('employee-resignations');
            queryClient.invalidateQueries('employee-terminations');
            queryClient.invalidateQueries('single-employee-exit');
            store.dispatch(setAlert(true, 'success', 'Exit Marked successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useGetExitWorkflow(id) {
    return useQuery(
        ['employee-exit-workflow',  {id} ],
        () => {
          return employeeExits.getExitWorkflow(id);
        },
        {
          enabled: id !== null,
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'Exit workflow fetched successfully'));
          },
          onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
          },
        },
    );
    
}

export function useGetExitQuestions(limit, page, search) {
    return useQuery(
      ['exit-questions', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeeExits.getExitQuestions({
          limit,
          page,
          search,
        });
      },
      {
        select: (data) => {
          data?.data?.forEach((recruitment) => {
            recruitment.created = moment(recruitment.created).format('MMMM D, YYYY');
            recruitment.computedActions = [0, 1, 2];
            recruitment.status = recruitment.application_status;
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetSelectExitWorkflows(limit, page, search) {
    return useQuery(
      ['exit-creation-workflows', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeeExits.getExitWorkflows({
          limit,
          page,
          search,
        });
      },
      {
        select: (data) => {
          let newData = [];
          newData.push({ value: '', label: 'No Workflow Selected' });
          data?.data?.forEach((item) => {
            newData.push({ value: item.id, label: item.name });
          });
          return newData;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useAddExit() {
    return useMutation(
      (payload) => {
        return employeeExits.addEmployeeExit(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('employee-resignations');
          queryClient.invalidateQueries('employee-terminations');
          store.dispatch(setAlert(true, 'success', response.message));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useAddBulkEmployeeExit() {
  return useMutation(
    (payload) => {
      return employeeExits.addBulkEmployeeExit(payload);
    },
    {
      onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('employee-resignations');
          queryClient.invalidateQueries('employee-terminations');
          store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateExit() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return employeeExits.updateEmployeeExit(payload);
        },
        {
            onSuccess: (response, variables, context) => {
                queryClient.invalidateQueries('employee-resignations');
                queryClient.invalidateQueries('employee-terminations');
                store.dispatch(setAlert(true, 'success', 'Exit Updated successfully'));
            },
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useDeleteExit() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeExits.deleteEmployeeExit(payload);
      },
      {
          onSuccess: (response, variables, context) => {
              queryClient.invalidateQueries('employee-resignations');
              queryClient.invalidateQueries('employee-terminations');
              queryClient.invalidateQueries('single-employee-exit');
              store.dispatch(setAlert(true, 'success', 'Exit Deleted successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useAddExitWorkflow() {
    return useMutation(
      (payload) => {
        return employeeExits.addExitWorkflow(payload);
      },
      {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('exit-workflows');
            store.dispatch(setAlert(true, 'success', 'Exit Workflow Added successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useUpdateExitWorkflow() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return employeeExits.updateExitWorkflow(payload);
        },
        {
            onSuccess: (response, variables, context) => {
                queryClient.invalidateQueries('exit-workflows');
                store.dispatch(setAlert(true, 'success', 'Exit Workflow Updated successfully'));
            },
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useAddExitQuestions() {
    return useMutation(
      (payload) => {
        return employeeExits.addExitQuestions(payload);
      },
      {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('exit-questions');
            store.dispatch(setAlert(true, 'success', 'Exit Questions Added successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useUpdateExitQuestion() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return employeeExits.updateExitQuestion(payload);
        },
        {
            onSuccess: (response, variables, context) => {
                queryClient.invalidateQueries('exit-questions');
                store.dispatch(setAlert(true, 'success', 'Exit Question Updated successfully'));
            },
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useDeleteExitQuestion() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeExits.deleteExitQuestion(payload);
      },
      {
          onSuccess: (response, variables, context) => {
              queryClient.invalidateQueries('exit-questions');
              store.dispatch(setAlert(true, 'success', 'Exit question deleted successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useGenerateOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeExits.generateEmployeeOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'OTP Generated successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useConfirmOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeExits.confirmEmployeeOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setEmployeeExit(response.data));
        store.dispatch(setAlert(true, 'success', 'OTP Confirmed successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEmployeeMarkExitStageComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeExits.saveExitStage(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'Exit Stage Marked successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useEmployeeMarkExitComplete() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeExits.markExitComplete(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          store.dispatch(setAlert(true, 'success', 'Exit Marked successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
      },
  );
}

export function useSaveAnswer() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return employeeExits.saveExitAnswers(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'Exit Answers Submitted successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}