import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/button";
import DataTable from "../../components/datatable";
import UnauthorizedPage from "../../pages/errors/unauthorized";
import {
  useDeleteEmployeeDocument,
  useGetEmployeeDocuments,
} from "../../redux/document/hook/employee";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import UploadEmployeeDocument from "../modal/documents/upload-employee-document";
import DoYouWantToModal from "../modal/do-you-want-to";
import { useGetEmployees } from "../../redux/employees/hook";

const EmployeeDocumentsTab = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: employeeDocuments } = useGetEmployeeDocuments(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const { mutateAsync: deleteDocument, isLoading: deleteLoading } =
    useDeleteEmployeeDocument();

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission?.role !== "Owner") {
        if (user.permissions.company_permission.doc_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const tableConfig = {
    headers: ["Date Uploaded", "Employee Name", "File Name"],
    keys: ["created", "employeeName", "name"],
    mobileHeader: {
      left: {
        title: "Date Uploaded",
        key: "created",
      },
      right: {
        title: "File Name",
        key: "name",
      },
    },
  };

  const deleteSingleDocument = async (id) => {
    let index = employeeDocuments.data.findIndex((item) => item.id === id);
    await deleteDocument(employeeDocuments.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const actionConfig = [
    {
      name: "Download",
      color: "orange",
      action: (id) => {
        let index = employeeDocuments.data.findIndex((item) => item.id === id);
        window.open(employeeDocuments.data[index].file_url, "_blank");
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = employeeDocuments.data.findIndex((item) => item.id === id);
        setDeleteId(employeeDocuments.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  return (
    <>
      {showDoYouWant ? (
        <DoYouWantToModal
          isOpen={showDoYouWant}
          closeModal={() => setShowDoYouWant(false)}
          id={deleteId}
          loading={deleteLoading}
          title={"Are you sure you want to delete this bonus?"}
          buttonText={"DELETE"}
          btnFunction={deleteSingleDocument}
        />
      ) : null}
      {showUploadDocument ? (
        <UploadEmployeeDocument
          isOpen={showUploadDocument}
          closeModal={() => setShowUploadDocument(false)}
        />
      ) : null}
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col bg-white rounded mt-4 border">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
            <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
              Employee Documents
            </div>
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Upload Document"
                  type="submit"
                  loading={false}
                  disabled={!employees}
                  leftIcon={"upload"}
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[11px]"}
                  onClick={() => {
                    setShowUploadDocument(true);
                  }}
                />
              </div>
            </div>
          </div>
          <hr className="divider mt-2 mb-1" />
          <DataTable
            data={employeeDocuments}
            tableConfig={tableConfig}
            actionConfig={actionConfig}
            updatePagination={(data) => setPagination(data)}
            searchData={true}
            computedActions={true}
            noCheck={true}
          />
        </div>
      )}
    </>
  );
};

export default EmployeeDocumentsTab;
