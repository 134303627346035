import DOMPurify from "dompurify";
import React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { formValidation } from "../../../../utils/functions";
import Button from "../../../button";
import FormInput from "../../../form-input";
import FormSelect from "../../../form-select";
import FormSwitch from "../../../form-switch";
import LoadingBar from "../../../loader-bar";
import {
  useGetBanks,
  useGetPFA,
  useResolveBankAccount,
} from "../../../../redux/bank/hook";
import {
  useActivateOrDeactivateEmployee,
  useActivateOrDeactivatePendingEmployee,
  useUpdateEmployee,
} from "../../../../redux/employees/hook/index";
import moment from "moment";
import { AllCountries } from "../../../../constants/countries";
import { store } from "../../../../redux";
import { setAlert } from "../../../../redux/components/components-slice";
import classNames from "classnames";
import { useGetVendor } from "../../../../redux/spend-management/vendor/hook";
import FormDatalist from "../../../form-datalist";

const VendorProfileTab = () => {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { id } = useParams();
  const { data: vendor, isLoading } = useGetVendor(id);
  const [pfa_name, setPFAName] = useState("");
  const [activateEmployee, setActivateEmployee] = useState(false);
  const { mutateAsync: updateEmployee, isLoading: loading } =
    useUpdateEmployee();

  const {
    mutateAsync: pendingActivateStatusChange,
    isLoading: pendingActivateStatusChangeLoading,
  } = useActivateOrDeactivatePendingEmployee();
  const {
    mutateAsync: ActivateOrDeactivate,
    isLoading: ActivateOrDeactivateLoading,
  } = useActivateOrDeactivateEmployee();
  const [gender, setGender] = useState("Male");
  const [country, setCountry] = useState("nigeria");
  const [maritalStatus, setMaritalStatus] = useState("single");

  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } =
    useResolveBankAccount();
  const { data: banks, bankLoading } = useGetBanks();

  const [editMode, setEditMode] = useState(false);
  const [bankname, setBankName] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [account_name, setAccountName] = useState("");

  useEffect(() => {
    if (vendor) {
      setAccountName(vendor.account_name);
      setAccountNumber(vendor.account_number);
      setBankName(vendor.bank);
    }
  }, [vendor]);

  useEffect(() => {
    if (account_number.length >= 10 && bankname !== "") {
      let payload = {
        bankname,
        account_number,
      };
      setAccountName("");
      resolveAccount(payload).then((response) => {
        setAccountName(response.data.accountname);
      });
    }
  }, [account_number, bankname]);

  useEffect(() => {
    if (vendor) {
      setActivateEmployee(vendor?.status == 1 ? true : false);
      setGender(vendor?.gender);
      setCountry(vendor?.country);
    }
  }, [vendor]);

  function submit(data) {
    const first_name = DOMPurify.sanitize(data?.first_name);
    const last_name = DOMPurify.sanitize(data?.last_name);
    const email = DOMPurify.sanitize(data?.email);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const staff_number = DOMPurify.sanitize(data?.staff_number);
    const dob = DOMPurify.sanitize(data?.dob)
      ? DOMPurify.sanitize(data?.dob)
      : "2003-01-01";
    const street_address = DOMPurify.sanitize(data?.street_address);
    const city = DOMPurify.sanitize(data?.city);
    const state = DOMPurify.sanitize(data?.state);
    const tax_number = DOMPurify.sanitize(data?.tax_number);
    const rsa_pin = DOMPurify.sanitize(data?.rsa_pin);
    const date_joined = DOMPurify.sanitize(data?.date_joined);
    const date_left = DOMPurify.sanitize(data?.date_left);
    const job_position = DOMPurify.sanitize(data?.job_position);

    let payload = {
      employeeId: id,
      data: {
        first_name,
        last_name,
        email,
        mobile,
        gender,
        staff_number,
        tax_number,
        dob,
        country,
        street_address,
        city,
        state,
        pfa_name,
        rsa_pin,
        date_joined,
        date_left,
        job_position,
        marital_status: maritalStatus,
      },
    };

    updateEmployee(payload).then((result) => {
      setEditMode(false);
    });
  }

  const changeStatus = () => {
    if (employee) {
      if (employee.status == 4) {
        pendingActivateStatusChange({
          id: employee.id,
          type: "activate",
        });
      } else {
        ActivateOrDeactivate(employee.id);
      }
    } else {
      store.dispatch(setAlert(true, "info", "No Employee found"));
    }
  };

  return (
    <>
      <div className="bg-white h-full w-full rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Vendor Profile
          </div>
          {/* {singleEmployeeSalary? */}
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text={
                  vendor?.status == 0 ? "ACTIVATE VENDOR" : "DEACTIVATE VENDOR"
                }
                type="button"
                className={classNames(
                  "flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center",
                  {
                    "!bg-red-500": vendor?.status == 1,
                    "!bg-hr-primary-1": vendor?.status !== 1,
                  }
                )}
                textClass={"!text-[11px]"}
                loading={
                  pendingActivateStatusChangeLoading ||
                  ActivateOrDeactivateLoading
                }
                onClick={() => {
                  changeStatus();
                }}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="PAY VENDOR"
                type="submit"
                theme="primary"
                className="flex gap-2 !h-[35px] w-fit p-2 "
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => {
                  // setPurchaseOrderData(null)
                  // setButtonText('SAVE')
                  // setTitle('Add Bonus')
                  // setShowAddPurchaseOrder(true)
                }}
              />
            </div>
          </div>
          {/* :
          null} */}
        </div>
        <hr className="divider" />
        {isLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px] px-4">
            <LoadingBar loading={isLoading || loading} />
          </div>
        ) : (
          <>
            {vendor ? (
              <div className="px-4 pb-4">
                <div className="w-full md:w-[20%] mt-2">
                  <FormSwitch
                    label={"Edit Mode"}
                    name={"switch-edit-mode"}
                    checked={editMode}
                    onClick={() => setEditMode(!editMode)}
                    value={editMode}
                  />
                </div>
                {editMode ? (
                  <form
                    className="form border bg-gray-50 p-4 rounded-md w-full"
                    onSubmit={handleSubmit(submit)}
                  >
                    <div className={"flex flex-col mt-3 w-full "}>
                      <div className="flex flex-col md:flex-row justify-between">
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="Vendor Name"
                            name="vendor_name"
                            type="text"
                            defaultValue={vendor?.vendor_name}
                            inputRef={register(formValidation("text", true))}
                            readOnly={loading}
                            error={errors.vendor_name}
                            errorMessage={
                              errors.vendor_name && errors.vendor_name.message
                            }
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="Contact Person"
                            name="contact_person"
                            type="text"
                            defaultValue={vendor?.contact_person}
                            inputRef={register(formValidation("text", true))}
                            error={errors.contact_person}
                            errorMessage={
                              errors.contact_person &&
                              errors.contact_person.message
                            }
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="Email"
                            name="email"
                            type="text"
                            inputRef={register(formValidation("email", true))}
                            defaultValue={vendor?.email}
                            error={errors.email}
                            errorMessage={errors.email && errors.email.message}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row justify-between">
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="Mobile"
                            name="mobile"
                            type="number"
                            inputRef={register(formValidation("number"))}
                            error={errors.mobile}
                            errorMessage={
                              errors.mobile && errors.mobile.message
                            }
                            defaultValue={vendor?.mobile}
                          />
                        </div>
                      </div>
                      <hr className="divider" />
                      <div className="flex flex-col md:flex-row justify-between">
                        <div className="w-full md:w-[250px]">
                          <FormSelect
                            defaultValue={country}
                            options={AllCountries}
                            onChange={(selected) => {
                              setCountry(selected);
                            }}
                            label="Country"
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="State"
                            name="state"
                            type="text"
                            defaultValue={vendor?.state}
                            inputRef={register(formValidation("text"))}
                            readOnly={loading}
                            error={errors.state}
                            errorMessage={errors.state && errors.state.message}
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="City"
                            name="city"
                            type="text"
                            defaultValue={vendor?.city}
                            inputRef={register(formValidation("text"))}
                            readOnly={loading}
                            error={errors.city}
                            errorMessage={errors.city && errors.city.message}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row justify-between">
                        <div className="w-full md:w-[750px]">
                          <FormInput
                            label="Address"
                            name="street_address"
                            type="text"
                            defaultValue={vendor?.street_address}
                            inputRef={register(formValidation("text"))}
                            readOnly={loading}
                            error={errors.street_address}
                            errorMessage={
                              errors.street_address &&
                              errors.street_address.message
                            }
                          />
                        </div>
                      </div>
                      <hr className="divider" />
                      <div className="flex flex-col md:flex-row justify-between">
                        <div className="w-full md:w-[250px]">
                          <FormDatalist
                            label="Bank Name"
                            data={banks ? banks : []}
                            value={bankname}
                            onSelect={(value) => {
                              setAccountNumber("");
                              setBankName(value);
                            }}
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="Account Number"
                            name="account_number"
                            type="text"
                            value={account_number}
                            error={errors.account_number}
                            errorMessage={
                              errors.account_number &&
                              errors.account_number.message
                            }
                            onInput={(e) => setAccountNumber(e.target.value)}
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="Account Name"
                            name="account_name"
                            type="text"
                            placeholder={
                              resolveAccountLoading
                                ? "Fetching Account Name ...."
                                : ""
                            }
                            readOnly
                            inputRef={register(formValidation("text", true))}
                            value={account_name}
                            error={errors.account_name}
                            errorMessage={
                              errors.account_name && errors.account_name.message
                            }
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-end w-full mt-[20px]">
                        <Button
                          text="Update Vendor Information"
                          type="submit"
                          loading={loading}
                          disabled={loading}
                          className="flex gap-2 !h-[35px] w-fit p-2 "
                          textClass={"!text-[11px]"}
                        />
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="flex flex-col md:flex-row border bg-gray-50 p-2 rounded-md">
                    <div className="w-full md:w-[100%] px-4 pb-4">
                      <div className="grid md:grid-cols-3  grid-cols-1 gap-4 w-full mt-3">
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Vendor Name
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {vendor.vendor_name
                              ? vendor.vendor_name
                              : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Contact Person
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {vendor.contact_person
                              ? vendor.contact_person
                              : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Email
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {vendor.email ? vendor.email : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Phone number{" "}
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {vendor.mobile ? vendor.mobile : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Date Created
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {moment(vendor.created).format("MMMM D, YYYY")}
                          </span>
                        </div>
                      </div>
                      <hr className="divider" />
                      <div className="grid md:grid-cols-3  grid-cols-1 gap-4 w-full mt-3">
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Country
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {vendor.country ? vendor.country : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            State
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {" "}
                            {vendor.state ? vendor.state : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">City</span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {" "}
                            {vendor.city ? vendor.city : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            {" "}
                            Address
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {vendor.street_address
                              ? vendor.street_address
                              : "Not Set"}
                          </span>
                        </div>
                      </div>
                      <hr className="divider" />
                      <div className="grid md:grid-cols-3  grid-cols-1 gap-4 w-full mt-3">
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Bank Name
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {vendor.bank ? vendor.bank : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Account Number
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {" "}
                            {vendor.account_number
                              ? vendor.account_number
                              : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-6 text-color-gray">
                            Account Name
                          </span>
                          <span className="mt-1 text-color-black text-[14px]">
                            {" "}
                            {vendor.account_name
                              ? vendor.account_name
                              : "Not Set"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default VendorProfileTab;
