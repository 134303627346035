import React from 'react';
import ProtectedRoute from '../protected';
import ContactUsPage from '../../pages/contact-us';
import ViewTicketPage from '../../pages/contact-us/view-ticket';

export const ContactUsRoutes = [
  <ProtectedRoute
    exact
    path='/contact-us'
    component={ContactUsPage}
  />,
  <ProtectedRoute
    exact
    path='/contact-us/view-ticket/:id'
    component={ViewTicketPage}
  />,
];