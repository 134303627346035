import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import TabView from "../../../components/tabview";
import RecruitmentOverviewTab from "../../../components/hr-section/recruitment/recruitment-overview";
import RecruitmentWorkflowTab from "../../../components/hr-section/recruitment/recruitment-workflows";
import { CardHr } from "../../../components/modal/hr-section/dashboard/cardHR";
import RecruitmentSVG from "../../../assets/svg/recruit-logo.svg";
import WorkflowSVG from "../../../assets/svg/workflow-widget-icon.svg";
import EmailSVG from "../../../assets/svg/email-widget-icon.svg";
import RecruitmentEmailTemplatesTab from "../../../components/hr-section/recruitment/recruitment-templates";
import { useGetRecruitmentSummary } from "../../../redux/human-resources/hook/recruitment";
import LoadingBar from "../../../components/loader-bar";
import UnauthorizedPage from "../../errors/unauthorized";
import { useSelector } from "react-redux";

const HRRecruitmentPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.recruitment_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);
  const { data: getRecruitmentSummary, isLoading: summaryLoading } =
    useGetRecruitmentSummary();
  const [recruitmentSummary, setRecruitmentSummary] = useState([]);

  useEffect(() => {
    if (getRecruitmentSummary) {
      setRecruitmentSummary([
        {
          title: "TOTAL RECRUITMENTS",
          value: getRecruitmentSummary.recruitments,
          icon: RecruitmentSVG,
        },
        {
          title: "TOTAL WORKFLOWS",
          value: getRecruitmentSummary.workflows,
          icon: WorkflowSVG,
        },
        {
          title: "TOTAL EMAIL TEMPLATE",
          value: getRecruitmentSummary.email_templates,
          icon: EmailSVG,
        },
      ]);
    } else {
      setRecruitmentSummary([
        {
          title: "TOTAL RECRUITMENTS",
          value: 0,
          icon: RecruitmentSVG,
        },
        {
          title: "TOTAL WORKFLOWS",
          value: 0,
          icon: WorkflowSVG,
        },
        {
          title: "TOTAL EMAIL TEMPLATE",
          value: 0,
          icon: EmailSVG,
        },
      ]);
    }
  }, [getRecruitmentSummary]);

  const RecruitmentTabs = [
    {
      title: "Recruitments",
      Content:
        user?.permissions.recruitment_permission.recruitment_read === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? RecruitmentOverviewTab
          : UnauthorizedPage,
    },
    {
      title: "Recruitment Workflows",
      Content:
        user?.permissions.recruitment_permission.workflow_read === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? RecruitmentWorkflowTab
          : UnauthorizedPage,
    },
    {
      title: "Email Templates",
      Content:
        user?.permissions.recruitment_permission.email_read === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? RecruitmentEmailTemplatesTab
          : UnauthorizedPage,
    },
  ];

  return (
    <DashboardLayout title="Recruitment Management">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row justify-between items-center -mt-4">
            {!summaryLoading ? (
              <div className="flex flex-col md:flex-row justify-between items-center w-[100%]">
                <div className="h-fit pb-3 flex flex-col justify-between w-full">
                  <div className="grid md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-3 md:gap-4">
                    <>
                      {recruitmentSummary?.map((item) => (
                        <CardHr
                          title={item.title}
                          subtitle={item.value}
                          icon={item.icon}
                        />
                      ))}
                    </>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row justify-between items-center w-[100%] p-2 pt-4">
                <div className="h-fit pb-3 flex flex-col justify-between w-full">
                  <div className="grid grid-cols-1 gap-4">
                    <LoadingBar loading={summaryLoading} />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-3">
            <TabView
              Tabs={RecruitmentTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default HRRecruitmentPage;
