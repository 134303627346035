import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import { useGetEmployeeSummary } from '../../../../redux/employees/hook';
import {
  useCalculateUpgradeSubscription,
  useUpgradeSubscription,
} from '../../../../redux/human-resources/hook/subscription';
import Modal from '../../modal';
import { formatNumber } from '../../../../utils/functions';
import LoadingBar from '../../../loader-bar';

function UpgradeSubscriptionPlan({ isOpen, closeModal, currentPlan }) {
  const {
    data: calculateUpgradeSubscription,
    isLoading: calculateUpgradeSubscriptionLoading,
  } = useCalculateUpgradeSubscription();
  const {
    mutateAsync: upgradeSubscription,
    isLoading: upgradeSubscriptionLoading,
  } = useUpgradeSubscription();

  const { data: getEmployeeSummary } = useGetEmployeeSummary();

  const submitForm = async () => {
    await upgradeSubscription().then(() => {
      closeModal();
    });
  };

  return (
    <>
        <Modal
            scrollable={true}
            className='!max-w-[600px] !rounded-none'
            isOpen={isOpen}
            centered={true}
        >
            <div className='flex flex-col overflow-auto'>
            <form className='form'>
                <div className='pl-8 pr-8 pt-4'>
                    <div className='flex flex-row justify-between items-center'>
                        <span className='header-4'>Upgrade Subscription Plan</span>
                        <div className='cursor-pointer' onClick={() => closeModal()}>
                        <img src={CloseSVG} alt='close' />
                        </div>
                    </div>
                </div>
                <hr className='divider' />
                {calculateUpgradeSubscriptionLoading ?
                <LoadingBar loading={calculateUpgradeSubscriptionLoading} />
                : calculateUpgradeSubscription ?
                <div className='flex flex-col'>
                  <div className='flex flex-col md:mx-14 items-center p-2'>
                      <span className='header-3'>
                      {currentPlan?.subscription_plan}
                      </span>

                  </div>
                  <hr className='divider' />
                  <div className='flex flex-col md:mx-14 items-center p-2'>
                      <span className='p4 text-center'>
                      {currentPlan?.billing_cycle === 'monthly'
                          ? 'MONTHLY'
                          : 'ANNUAL'}
                      {' BASE PAYMENT'}
                      </span>
                      <span className=' header-4'>
                      {formatNumber(calculateUpgradeSubscription?.base_price, 2)}
                      </span>
                  </div>
                  <hr className='divider' />
                  <div className='flex flex-col md:mx-14 items-center p-2'>
                      <span className='p4 text-center'>
                      {'TOTAL ACTIVE EMPLOYEES'}
                      </span>
                      <span className='header-4'>{getEmployeeSummary?.active}</span>
                  </div>
                  <hr className='divider' />
                  <div className='flex flex-col md:mx-14 items-center p-2'>
                      <span className='p4 text-center'>{'TOTAL PAYABLE'}</span>
                      <span className='header-4'>
                      {formatNumber(calculateUpgradeSubscription?.total_price, 2)}
                      </span>
                  </div>
                  <hr className='divider' />

                  <div className='w-full mt-[20px] pb-4 px-4'>
                      <Button
                        text={'UPGRADE'}
                        type='button'
                        loading={upgradeSubscriptionLoading}
                        onClick={() => {
                            submitForm();
                        }}
                      />
                  </div>
                </div>
                :
                <div className='w-full flex h-[80px] items-center justify-center'>
                  No need for upgrade of this package
                </div>
                }
            </form>
            </div>
        </Modal>
    </>
  );
}

export default UpgradeSubscriptionPlan;
