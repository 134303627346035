import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../../components/button";
import EmployeeRegistrationLayout from "../../../components/layouts/employee-registration";
import ConfirmationModal from "../../../components/modal/confirmation-modal";
import { useUpdateInvitedEmployees } from "../../../redux/employees/hook";
import Logo from "../../../components/logo";
import { useSelector } from "react-redux";

function VerifySingleEmployeeInformationPage() {
  useEffect(() => {}, []);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { mutateAsync: updateInvitedEmployee, isLoading: loading } =
    useUpdateInvitedEmployees();
  const history = useHistory();

  const { employee } = useSelector((state) => state.employeeSlice);

  const location = useLocation();
  const [payload, setPayload] = useState({});

  const { token } = useParams();

  const goToHomePage = () => {
    window.location.replace("https://www.ropay.africa");
  };

  useEffect(() => {
    if (location) {
      setPayload(location.state);
    }
  }, [location]);

  const submitForm = async () => {
    let newData = {
      employeeId: payload.employeeId,
      data: {
        token: token,
        dob: payload.dob,
        gender: payload.gender,
        marital_status: payload.marital_status,
        street_address: payload.street_address,
        state: payload.state,
        city: payload.city,
        country: payload.country,
        account_number: payload.account_number,
        account_name: payload.account_name,
        bank: payload.bank,
        tax_number: payload.tax_number,
        pfa_name: payload.pfa_name,
        rsa_pin: payload.rsa_pin,
      },
    };

    await updateInvitedEmployee(newData).then((result) => {
      setShowConfirmationModal(true);
    });
  };

  return (
    <EmployeeRegistrationLayout
      step="5"
      company_name={employee?.company_name}
      logo={employee?.logo}
    >
      <ConfirmationModal
        isOpen={showConfirmationModal}
        title="Thank you !"
        subtitle={"Your information has been submitted"}
        btnFunction={() => goToHomePage()}
        closeModal={() => goToHomePage()}
      />
      <div className="flex flex-col justify-center w-full md:pt-2 h-fit gap-4">
        <div className="flex md:min-h-max flex-col w-[800px] max-w-[800px] px-5 md:px-0">
          <div className="w-full text-center flex flex-col pb-[25px] md:pt-[20px] pt-2">
            <span className="header-2-regular text-secondary-2">
              Verify and Submit
            </span>
            <span className="header-5-regular text-secondary-2">
              Confirm your information before submitting
            </span>
          </div>
          <hr className="divider -mt-2" />
          <div className="flex flex-col">
            <div className="flex flex-col mt-1">
              <span className="header-4 mb-2">Profile Information</span>
              {/* <hr className='divider mt-2' /> */}
              <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">First Name</span>
                  <span className="mt-1 text-color-black">
                    {payload?.first_name}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Last name</span>
                  <span className="mt-1 text-color-black">
                    {payload?.last_name}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Phone Number</span>
                  <span className="mt-1 text-color-black">
                    {payload?.mobile}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Email</span>
                  <span className="mt-1 text-color-black">
                    {payload?.email}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">
                    Date of birth
                  </span>
                  <span className="mt-1 text-color-black">{payload?.dob}</span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Gender</span>
                  <span className="mt-1 text-color-black">
                    {payload?.gender}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Country</span>
                  <span className="mt-1 text-color-black">
                    {payload?.country}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">State</span>
                  <span className="mt-1 text-color-black">
                    {payload?.state}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">City</span>
                  <span className="mt-1 text-color-black">Lagos</span>
                </div>
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">
                    Street Address
                  </span>
                  <span className="mt-1 text-color-black">
                    {payload?.street_address}
                  </span>
                </div>
              </div>

              <div className="flex mt-4 flex-col">
                <span className="header-4 mb-2">Employment information</span>
                {/* <hr className='divider mt-2' /> */}
                <div className="flex flex-col">
                  <span className="header-5 text-color-gray">Staff Number</span>
                  <span className="mt-1 text-color-black">
                    {payload?.staff_number}
                  </span>
                </div>
                <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full mt-3">
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Date Of Employment
                    </span>
                    <span className="mt-1 text-color-black">
                      {moment(payload?.date_joined).format("MMMM DD, YYYY")}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Job Position
                    </span>
                    <span className="mt-1 text-color-black">
                      {payload?.job_position
                        ? payload?.job_position
                        : "Not Set"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Pension Fund Administrator
                    </span>
                    <span className="mt-1 text-color-black">
                      {payload?.pfa_name ? payload.pfa_name : "Not Set"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Pension R.S.A PIN
                    </span>
                    <span className="mt-1 text-color-black">
                      {payload?.rsa_pin ? payload.rsa_pin : "Not Set"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">Tax ID</span>
                    <span className="mt-1 text-color-black">
                      {payload?.tax_number ? payload.tax_number : "Not Set"}
                    </span>
                  </div>
                </div>

                <span className="header-4 mt-4 mb-2">Salary information</span>
                {/* <hr className='divider mt-2' /> */}
                <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">Bank Name</span>
                    <span className="mt-1 text-color-black">
                      {payload?.bank}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Account Number
                    </span>
                    <span className="mt-1 text-color-black">
                      {payload?.account_number}
                    </span>
                  </div>
                </div>
                <div className="grid md:grid-cols-1  grid-cols-1 w-full mt-4">
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Account Name - (Auto generated)
                    </span>
                    <span className="mt-1 text-color-black font-semibold">
                      {payload?.account_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-[20px]">
            <Button
              text="Confirm & Save"
              type="submit"
              className={"!bg-secondary-2"}
              loading={loading}
              onClick={() => submitForm()}
            />
          </div>
          <div
            className="flex justify-center w-full cursor-pointer hover:underline"
            onClick={() => history.goBack()}
          >
            <span className="p2-medium text-color-black mt-3">Back</span>
          </div>
        </div>
        <div
          className={
            "md:hidden flex items-center justify-center text-color-black gap-2"
          }
        >
          Powered By:{" "}
          <a href="https://www.ropay.africa" target={"_blank"} rel="noreferrer">
            <Logo width="80px" height="40px" blue />
          </a>
        </div>
      </div>
    </EmployeeRegistrationLayout>
  );
}

export default VerifySingleEmployeeInformationPage;
