import DOMPurify from 'dompurify';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import DragSVG from '../../../../assets/svg/reorder.svg';
import { useEditWorkflow } from '../../../../redux/human-resources/hook/workflow';
import { useAddRecruitmentWorkflow } from '../../../../redux/human-resources/hook/recruitment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddSmallSVG from '../../../../assets/svg/add-icon-small.svg';
import { store } from '../../../../redux';
import { setAlert } from '../../../../redux/components/components-slice';
import Modal from '../../modal';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  paddingRight: 16,
  paddingLeft: 16,
  margin: `0 0 8px 0`,
  // borderRadius: '5px',
  // border: '1px solid #E5E4E2',
  // change background colour if dragging
  background: 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: 'whitesmoke',
  padding: grid,
  width: '100%',
});

function AddRecruitmentWorkflow({
  isOpen,
  closeModal,
  workflowData,
  title,
  buttonText,
}) {
  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(subWorkflow[sInd], source.index, destination.index);
      const newState = [...subWorkflow];
      newState[sInd] = items;
      setSubWorkflow(newState);
    } else {
      const result = move(
        subWorkflow[sInd],
        subWorkflow[dInd],
        source,
        destination,
      );
      const newState = [...subWorkflow];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setSubWorkflow(newState.filter((group) => group.length));
    }
  }

  const [maxId, setMaxId] = useState(4);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [subWorkflow, setSubWorkflow] = useState([
    [
      {
        id: `item-${1}`,
        item_id: null,
        name: 'Collect Applications',
        type: 'application',
      },
      {
        id: `item-${2}`,
        item_id: null,
        name: 'Interview',
        type: 'interview',
      },
      {
        id: `item-${3}`,
        item_id: null,
        name: 'Negotiate Salary',
        type: 'negotiate',
      },
      {
        id: `item-${4}`,
        item_id: null,
        name: 'Accept',
        type: 'acceptance',
      },
    ],
  ]);

  useEffect(() => {
    if (workflowData) {
      let newSubWorkflows = [];
      let item_id = 1;
      workflowData.sub_workflows.forEach((item) => {
        let newsubflow = {
          id: `item-${item_id}`,
          item_id: item.id,
          name: item.name,
          type: item.type,
        };
        newSubWorkflows.push(newsubflow);
        item_id++;
      });
      setSubWorkflow([newSubWorkflows]);
      setWorkflowId(workflowData.id);
      setWorkflowType('edit');
      setName(workflowData.name);
      setDescription(workflowData.description);
    }
  }, [workflowData]);

  const [remove, setRemove] = useState([]);

  const workflowTypes = [
    { label: 'Stage Type', value: '' },
    { label: 'Application', value: 'application' },
    { label: 'Interview', value: 'interview' },
    { label: 'Negotiation', value: 'negotiation' },
    { label: 'Task', value: 'task' },
    { label: 'Coding Interview', value: 'coding_interview' },
    { label: 'Job Offer', value: 'acceptance' },
  ];

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [workflowType, setWorkflowType] = useState('add');
  const [workflowId, setWorkflowId] = useState(null);

  const { mutateAsync: addWorkflow, isLoading: addWorkflowloading } =
    useAddRecruitmentWorkflow();
  const { mutateAsync: editWorkflow, isLoading: editWorkflowloading } =
    useEditWorkflow();

  const addStage = () => {
    let newArray = JSON.parse(JSON.stringify(subWorkflow[0]));
    newArray = [
      ...newArray,
      ...[
        {
          id: `item-${maxId + 1}`,
          item_id: null,
          name: '',
          company_id: null,
          workflow_id: null,
          type: 'interview',
        },
      ],
    ];
    setMaxId(maxId + 1);
    setSubWorkflow([newArray]);
  };

  const removeStage = (subIndex) => {
    let newArray = JSON.parse(JSON.stringify(subWorkflow[0]));

    if (newArray[subIndex].item_id !== null) {
      let removeData = remove;
      removeData.push(newArray[subIndex].item_id);
      setRemove(removeData);
    }
    let data = [...newArray];
    if (subIndex > -1) {
      // only splice array when item is found
      data.splice(subIndex, 1); // 2nd parameter means remove one item only
      setSubWorkflow([[...data]]);
    }
  };

  const submitForm = async () => {
    if (subWorkflow && subWorkflow[0].length > 0) {
      let newSubWorkflows = [];
      subWorkflow[0].forEach((item) => {
        if (item.name) {
          let newsubflow = {
            id: item.item_id,
            name: item.name,
            type: item.type,
          };
          newSubWorkflows.push(newsubflow);
        }
      });
      if (newSubWorkflows.length > 0) {
        if (workflowType === 'add') {
          let payload = {
            name: DOMPurify.sanitize(name),
            description: DOMPurify.sanitize(description),
            sub_workflows: newSubWorkflows,
            type: 'recruitment',
          };
          await addWorkflow(payload).then(() => {
            closeModal();
          });
        } else if (workflowType === 'edit') {
          let payload = {
            name: DOMPurify.sanitize(name),
            description: DOMPurify.sanitize(description),
            sub_workflows: newSubWorkflows,
            type: 'recruitment',
            remove: remove.join(','),
          };
          let editPayload = {
            id: workflowId,
            data: payload,
          };
          await editWorkflow(editPayload).then(() => {
            closeModal();
          });
        }
      } else {
        store.dispatch(
          setAlert(true, 'error', 'You must have at least one stage'),
        );
      }
    } else {
      store.dispatch(
        setAlert(true, 'error', 'You must have at least one stage'),
      );
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-6'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col max-h-fit mx-8'>
            <div className='flex flex-col border p-2 rounded-md bg-highlight'>
              <FormInput
                label='Workflow Name'
                name='name'
                type='text'
                placeholder={'Enter the name for this recruitment workflow'}
                value={name}
                onInput={(e) => setName(e.target.value)}
                readOnly={addWorkflowloading || editWorkflowloading}
                error={errors.name}
                errorMessage={errors.name && errors.name.message}
                className={'bg-white'}
              />
              <FormInput
                label={'Workflow Description'}
                name='description'
                placeholder={'Enter brief description'}
                value={description}
                onInput={(e) => setDescription(e.target.value)}
                readOnly={addWorkflowloading || editWorkflowloading}
                error={errors.description}
                errorMessage={errors.description && errors.description.message}
                className={'bg-white'}
              />
            </div>
            <hr className='divider mb-3 mt-2' />
            <div className='flex flex-col'>
              <span className='header-4 text-primary-3'>
                Recruitment Stages
              </span>
              <span className='p3 text-[12px] text-gray-500'>
                Add Recruitment Stages. Drag and Drop to change order.
              </span>
            </div>
            <hr className='divider mt-2 mb-3' />
            <div className='flex rounded-[5px]'>
              <DragDropContext onDragEnd={onDragEnd}>
                {subWorkflow.map((el, ind) => (
                  <Droppable key={ind} droppableId={`${ind}`}>
                    {(provided, snapshot) => (
                      <div
                        className='p-4'
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        {el.map((item, index) => (
                          <>
                            {index === 0 ? (
                              <div className='bg-color-white mb-2 pr-4 pl-4 pt-1 border-r-4 border-error rounded-[5px]'>
                                <div className='flex flex-row items-center flex-wrap !w-[100%] justify-between'>
                                  <div className='flex flex-row gap-2 text-[25px] text-red-500 items-center'>
                                    <span className='text-red-500'>*</span>
                                    <FormInput
                                      name={index + '_stage_name'}
                                      placeholder={'Enter stage Name'}
                                      type='text'
                                      readOnly={true}
                                      value={item.name}
                                      className='!h-[30px] md:w-[250px] w-full text-[13px] border-1 border-gray-300'
                                    />
                                  </div>

                                  <div className='italic text-red-500 text-[12px] md:pl-1'>
                                    ReadOnly
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className='border-r-4 border-r-primary-3 rounded-[5px]'
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                    )}
                                  >
                                    <div className='flex justify-around flex-wrap'>
                                      <div className='flex flex-row items-center md:w-[50%] w-full'>
                                        {index !== 0 ? (
                                          <img
                                            src={DragSVG}
                                            alt={'Refresh'}
                                            className={
                                              'h-[18px] w-[22px] mr-3 cursor-grabbing'
                                            }
                                          />
                                        ) : (
                                          <div className='text-[25px] pr-2 pt-1 text-red-500'>
                                            *
                                          </div>
                                        )}

                                        <FormInput
                                          name={index + '_stage_name'}
                                          placeholder={'Stage Name'}
                                          type='text'
                                          readOnly={false}
                                          value={item.name}
                                          onInput={(e) => {
                                            let newArray = JSON.parse(
                                              JSON.stringify(subWorkflow[ind]),
                                            );

                                            newArray[index].name =
                                              e.target.value;
                                            setSubWorkflow([newArray]);
                                          }}
                                          className='!h-[30px] md:w-[200px] w-[225px] text-[13px] border-1 border-gray-300'
                                        />
                                      </div>
                                      <div className='flex flex-row md:justify-end items-center md:w-[50%] w-full'>
                                        <FormSelect
                                          value={item.type}
                                          options={workflowTypes}
                                          name={item.name}
                                          readOnly={false}
                                          onChange={(selected) => {
                                            if (index !== 0) {
                                              let newArray = JSON.parse(
                                                JSON.stringify(
                                                  subWorkflow[ind],
                                                ),
                                              );

                                              newArray[index].type = selected;
                                              setSubWorkflow([newArray]);
                                            }
                                          }}
                                          className='!min-h-[32px] border-1 w-[100px] rounded text-[11px] mr-2'
                                        />
                                        <div
                                          className='italic text-red-500 text-[10px] font-semibold cursor-pointer hover:underline'
                                          onClick={() => {
                                            removeStage(index);
                                          }}
                                        >
                                          Remove
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            )}
                          </>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </DragDropContext>
            </div>
            <div className='flex flex-row items-center gap-2 mt-3 justify-left w-full cursor-pointer'>
              <img
                src={AddSmallSVG}
                alt={'add_stage'}
                className={'h-[15px] w-[15px]'}
              />
              <span
                className='p4-medium hover:underline hover:italic hover:font-bold text-primary-3 duration-75'
                onClick={() => addStage(true)}
              >
                Add New Stage
              </span>
            </div>
            <hr className='divider mt-3 ' />
            <div className='w-full'>
              <Button
                text={buttonText}
                theme={'third'}
                type='submit'
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
                loading={addWorkflowloading || editWorkflowloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddRecruitmentWorkflow;
