import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import { useGetPFA } from '../../../redux/bank/hook';
import { useEditPension } from '../../../redux/statutory-compliance/hook/pension';
import Button from '../../button';

import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import LoadingBar from '../../loader-bar';
import Modal from '../modal';

function PensionDetailedView({ isOpen, closeModal, viewData }) {
  const [payloadData, setPayloadData] = useState(null);

  const [pfaName, setPfaName] = useState(null);
  const [rsaPin, setRsaPin] = useState(null);
  const [id, setId] = useState(null);
  const { data: PFAs } = useGetPFA();

  const { mutateAsync: editPension, isLoading: editPfaLoading } =
    useEditPension();

  useEffect(() => {
    if (viewData) {
      setPayloadData(viewData);
      setId(viewData.id);
      setPfaName(viewData.pfaName);
      setRsaPin(viewData.rsaPin);
    }
  }, [viewData]);

  const submitForm = async () => {
    let payload = {
      id: id,
      data: {
        pfaName: pfaName,
        rsaPin: rsaPin,
      },
    };
    await editPension(payload).then(() => {
      closeModal();
    });
  };
  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <div className='form'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Employee Pension Details</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            {payloadData ? (
              <div className='flex flex-col md:flex-col w-full justify-between gap-2'>
                <div className='flex flex-col gap-2 border rounded-md bg-secondary-2-extralight p-3'>
                  <div className='flex justify-between w-full'>
                    <span className='text-[14px] font-extrabold text-color-gray'>
                      Employee name<br/>
                      <span className='text-[13px] font-semibold text-color-black-2'>
                        {payloadData?.firstName} {payloadData?.lastName}
                      </span>
                    </span>
                    
                    <span className='text-[14px] font-extrabold text-color-gray text-right'>
                      Employee Mobile<br/>
                      <span className='text-[13px] font-semibold text-color-black-2'>
                        {payloadData?.mobile}
                      </span>
                    </span>
                  </div>
                </div>
                <hr className='divider mt-2 mb-2' />
                
                <div className='flex flex-row justify-between'>
                  <div className='w-1/2 pr-2'>
                    <FormInput
                      label={'Employee Contribution'}
                      name='emp_cont'
                      type='text'
                      readOnly={true}
                      value={payloadData?.table_employeeContribution}
                    />
                  </div>
                  <div className='w-1/2 pl-2'>
                    <FormInput
                      label='Employer Contribution'
                      name='empr_cont'
                      type='text'
                      readOnly
                      value={payloadData?.table_employerContribution}
                    />
                  </div>
                </div>
                <div className='flex flex-row justify-between'>
                  <div className='w-1/2 pr-2'>
                    <FormInput
                      label='Fee'
                      name='fees'
                      type='text'
                      readOnly={true}
                      value={payloadData?.table_fees}
                    />
                  </div>
                  <div className='w-1/2 pl-2'>
                    <FormInput
                      label='Total Pension'
                      name='total_payable'
                      type='text'
                      readOnly
                      value={payloadData?.total}
                    />
                  </div>
                </div>
                <div className='flex flex-row justify-between'>
                  <div className='w-1/2 pr-2'>
                    {PFAs ? (
                      <FormSelect
                        value={pfaName}
                        options={PFAs}
                        onChange={(selected) => {
                          setPfaName(selected);
                        }}
                        label='Select PFA - (Editable)'
                      />
                    ) : (
                      <LoadingBar loading={true} />
                    )}
                  </div>
                  <div className='w-1/2 pl-2'>
                    <FormInput
                      label='RSA PIN - (Editable)'
                      name='rsa_pin'
                      type='text'
                      readOnly={false}
                      value={rsaPin}
                      onInput={(e) => {
                        setRsaPin(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className='w-full mb-[20px]'>
                  <Button
                    text='UPDATE'
                    type='submit'
                    disabled={pfaName || rsaPin ? false : true}
                    loading={editPfaLoading}
                    onClick={() => submitForm()}
                  />
                </div>
                </div>
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PensionDetailedView;
