import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import FormInput from "../../../components/form-input";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import { useHistory } from "react-router-dom";
import { useInviteSingleEmployees } from "../../../redux/employees/hook";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../utils/functions";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import { useRouteQuery } from "../../../hooks/useRouteQuery";
import { useGetRecruitmentApplication } from "../../../redux/human-resources/hook/recruitment";

function InviteSingleEmployee() {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });
  useEffect(() => {}, []);

  const history = useHistory();
  const { mutateAsync, isLoading: loading } = useInviteSingleEmployees();
  const { user } = useSelector((state) => state.userSlice);

  // const [applicantId, setApplicantId] = useState(null);
  const query = useRouteQuery();
  // useEffect(() => {
  //   if (query) {
  //     setApplicantId(query.get('applicant_id'));
  //   }
  // }, [query]);
  // const { data: applicant } = useGetRecruitmentApplication(applicantId);

  const submitForm = async (data) => {
    const first_name = DOMPurify.sanitize(data?.first_name);
    const middle_name = DOMPurify.sanitize(data?.middle_name);
    const last_name = DOMPurify.sanitize(data?.last_name);
    const email = DOMPurify.sanitize(data?.email);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const staff_number = DOMPurify.sanitize(data?.staff_number);
    const job_position = DOMPurify.sanitize(data?.job_position);
    const date_joined = DOMPurify.sanitize(data?.date_joined);
    const applicant_id = query?.get("applicant_id");

    const requestData = {
      first_name,
      middle_name,
      last_name,
      email,
      mobile,
      staff_number,
      job_position,
      date_joined,
      applicant_id,
    };
    await mutateAsync(requestData).then((result) => {
      if (
        user?.hr_subscription?.status !== 1 &&
        user?.hr_subscription?.status !== 2
      ) {
        history.push("/employees/onboarding/add-employee-to-group");
      } else {
        history.push(`/employees/onboarding/assign-onboarding-workflow`);
      }
    });
  };

  return (
    <EmployeeOnboardingLayout step={2} title={"Add Employee Details"}>
      <div className="flex flex-col w-full  h-full">
        <span className="hidden md:flex text-[20px] font-extrabold">
          Add Single Employee - Invite employee to fill biodata
        </span>
        <hr className="divider mb-2 md:mt-2 -mt-12" />

        <div className="mt-2">
          <form className="form " onSubmit={handleSubmit(submitForm)}>
            <div className="p-2 rounded-md border bg-highlight">
              <div className="header-4 mb-2 text-color-gray">
                Basic Employee Information
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormInput
                    label="First name"
                    name="first_name"
                    placeholder={"Enter first name"}
                    className={"!bg-white"}
                    type="text"
                    defaultValue={query?.get("first_name")}
                    inputRef={register(formValidation("text", true))}
                    readOnly={loading}
                    error={errors.first_name}
                    errorMessage={
                      errors.first_name && errors.first_name.message
                    }
                  />
                </div>

                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    label="Last name"
                    name="last_name"
                    placeholder={"Enter last name"}
                    type="text"
                    className={"!bg-white"}
                    defaultValue={query?.get("last_name")}
                    inputRef={register(formValidation("text", true))}
                    readOnly={loading}
                    error={errors.last_name}
                    errorMessage={errors.last_name && errors.last_name.message}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormInput
                    label="Middle Name - (Optional)"
                    name="middle_name"
                    type="text"
                    className={"!bg-white"}
                    placeholder={"Enter Middle Name"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                    error={errors.middle_name}
                    errorMessage={
                      errors.middle_name && errors.middle_name.message
                    }
                  />
                </div>

                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    label="Phone Number"
                    name="mobile"
                    type="text"
                    className={"!bg-white"}
                    defaultValue={query?.get("mobile")}
                    placeholder={"Enter Phone Number"}
                    inputRef={register(formValidation("text", true))}
                    readOnly={loading}
                    error={errors.mobile}
                    errorMessage={errors.mobile && errors.mobile.message}
                  />
                </div>
              </div>
              <FormInput
                label="Email"
                name="email"
                placeholder={"Enter employee email"}
                type="email"
                className={"!bg-white"}
                defaultValue={query?.get("email")}
                inputRef={register(formValidation("email", true))}
                readOnly={loading}
                error={errors.email}
                errorMessage={errors.email && errors.email.message}
              />
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormInput
                    label="Employee ID - (Optional)"
                    placeholder={"Enter employee staff ID"}
                    name="staff_number"
                    type="text"
                    className={"!bg-white"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                  />
                </div>

                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    label="Job Role - (Optional)"
                    placeholder={"Enter position"}
                    name="job_position"
                    type="text"
                    className={"!bg-white"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormInput
                    label="Date Of Employment"
                    name="date_joined"
                    type="date"
                    className={"!bg-white"}
                    inputRef={register(formValidation("date", true))}
                    readOnly={loading}
                    error={errors.date_joined}
                    errorMessage={
                      errors.date_joined && errors.date_joined.message
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full mt-4">
              <Button
                text="Invite Employee"
                type="submit"
                loading={loading}
                disabled={!isValid}
              />
            </div>
            <div
              className="flex justify-center w-full cursor-pointer hover:underline hover:scale-110 duration-300 mt-2"
              onClick={() => history.goBack()}
            >
              <span className="p2-medium text-color-black mt-3">Go Back</span>
            </div>
          </form>
        </div>
      </div>
    </EmployeeOnboardingLayout>
  );
}

export default InviteSingleEmployee;
