import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormSelect from '../../../form-select';
import moment from 'moment';
import FormSelectTeam from '../../../employees/form-select-team';
import FormSelectDepartment from '../../../employees/form-select-department';
import FormSelectBranch from '../../../employees/form-select-branch';
import AsyncSelect from 'react-select/async';
import { employees as employeeAPI } from '../../../../api/employees';
import FormDateRangePicker from '../../../form-date-range-picker';
import Button from '../../../button';
import { useComparePerformanceEvaluation } from '../../../../redux/human-resources/hook/performance';
import { store } from '../../../../redux';
import { setAlert } from '../../../../redux/components/components-slice';

const ComparePerformance = () => {

    const { user } = useSelector((state) => state.userSlice);
    const [source, setSource] = useState('company');
    const [sourceId, setSourceId] = useState(null);
    const [sourceName, setSourceName] = useState(null);

    const sourceOptions = [
        { label: 'Company', value: 'company' },
        { label: 'Team', value: 'team' },
        { label: 'Department', value: 'department' },
        { label: 'Branch', value: 'branch' },
    ];

    const dispatch = useDispatch();

    const [employees, setEmployees] = useState([]);

    const loadOptions = (inputValue) =>
        new Promise((resolve) => {
        employeeAPI.getEmployees({
            limit: 10,
            statusFilter: -1,
            search: inputValue,
            page: 1,
        }).then((response) => {
                
            let data = [];

            response.data.forEach((item) => {
                data.push({
                label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
                value: item.id,
                });
            });

            resolve(data);
            })
            .catch((err) => {
                resolve([]);
            });
    });

    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format("YYYY-MM-DD"));

    const { mutateAsync: comparePerformance, isLoading: comparePerformanceLoading } =
    useComparePerformanceEvaluation();

    const compare = () => {
        // if (employees.length === 0) {
        //     dispatch(setAlert(true, 'info', 'Select at least one employee'));
        // return;
        // }
        // let employeeArrays = [];
    
        // employees.forEach((item) => {
        //     employeeArrays.push(item.value);
        // });
    
        // let employee_ids = employeeArrays.join();
        let payload = {
            employee_ids: '1,2',
            start_date: startDate,
            end_date: endDate
        }
        comparePerformance(payload).then(() => {

        })
    }

    return (
        <div className='flex flex-col mt-1 pb-4 w-full'>
            <div className='flex md:flex-row gap-4 w-full'>
                <div className='px-4 w-full'>
                    <div className='flex flex-col justify-between items-start px-4 border bg-gray-50 rounded-md py-2'>
                        <div className='flex flex-col md:flex-row w-full justify-start -mt-1 gap-4'>
                            
                        </div>
                        <div className='w-full -mt-2 flex md:flex-row flex-col justify-between md:items-end items-center gap-3 py-3'>
                            <div className='w-full md:w-2/4'>
                                <label className='!text-[13px] leading-[23px] font-normal text-color-gray'>
                                    Select employees to compare (Max. 3 employees)
                                </label>
                                <AsyncSelect
                                    isMulti
                                    cacheOptions
                                    defaultOptions
                                    className='text-[13px]'
                                    placeholder={'Type to select multiple employees'}
                                    loadOptions={loadOptions}
                                    onChange={(options) => {
                                        if (options.length <= 3) {
                                            setEmployees(options)
                                        } else {
                                            store.dispatch(setAlert('warning', true, 'You cannot compare more than 3 employees at a time.'))
                                        }
                                        
                                    }}
                                    classNamePrefix='mySelect'
                                />
                            </div>
                            <div className='w-full md:w-1/4 md:pl-2'>
                                <FormDateRangePicker
                                    label='Select comparison period'
                                    start={startDate}
                                    end={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    extraClass='!h-[35px] border-0 !min-w-fit'
                                />
                            </div>
                            <div className='flex justify-end w-full md:w-1/4'>
                                <Button
                                    text='Compare'
                                    type='button'
                                    theme='primary'
                                    className='flex gap-2 !h-[55px] min-w-[150px] md:max-w-[200px] p-2 '
                                    textClass={'!text-[15px]'}
                                    disabled={comparePerformanceLoading}
                                    loading={comparePerformanceLoading}
                                    onClick={() => {
                                        compare()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComparePerformance;