import { Axios } from '../axios';

const getWorkflows = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.get( `/workflows?limit=${payload.limit}&page=${payload.page}&type=${payload.type}${search}`,);
    return data?.data;
};

const addWorkflow = async (payload) => {
    const { data } = await Axios.post('/workflows/', payload.data);
    return data;
};

const updateWorkflow = async (payload) => {
    const { data } = await Axios.put('/workflows/' + payload.id, payload.data);
    return data;
};

const getWorkflow = async (payload) => {
    const { data } = await Axios.get('/workflow' + payload);
    return data;
};

const deleteWorkflow = async (payload) => {
    const { data } = await Axios.delete(`/workflows/${payload}`,);
    return data;
};

export const workflows = {
    getWorkflows,
    addWorkflow,
    updateWorkflow,
    getWorkflow,
    deleteWorkflow,
}