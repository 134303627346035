import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import AsyncSelect from 'react-select/async';
import { employees as employeeAPI } from '../../../../api/employees';
import { setAlert } from '../../../../redux/components/components-slice';
import { useAddEmployeesToDepartment, useGetDepartment } from '../../../../redux/human-resources/hook/departments';
import Modal from '../../modal';

function AddEmployeeToDepartment({
  isOpen,
  closeModal,
  departmentData,
  refresh,
}) {

  const { data: department } = useGetDepartment(departmentData);
  const [employees, setEmployees] = useState([]);
  const { mutateAsync: addEmployeeToDepartment, isLoading } =
    useAddEmployeesToDepartment();

  const dispatch = useDispatch();

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 10,
          statusFilter: -1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const submit = () => {
    if (employees.length === 0) {
      dispatch(setAlert(true, 'info', 'Select at least one employee'));
      return;
    }
    let employeeArrays = [];

    employees.forEach((item) => {
      employeeArrays.push(item.value);
    });

    let employee_ids = employeeArrays.join();

    let payload = {
      department_id: departmentData,
      employee_ids: employee_ids,
    };

    addEmployeeToDepartment(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      {departmentData ? (
        <div className='flex flex-col pb-8 overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Add employee to department</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col'>
              <div className='flex flex-col p-3 border bg-highlight rounded-md'>
                <div className='text-[14px] font-extrabold text-color-gray'>Department Name</div>
                <div className='text-[13px] font-semibold'>{department?.name}</div>
              </div>
              <hr className='divider mt-2' />
              <div>
                <label className='text-[13px] leading-[23px] font-normal text-color-gray mb-1'>
                  Select Employees
                </label>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  defaultOptions
                  className='text-[13px]'
                  placeholder={'Type to select multiple employees'}
                  loadOptions={loadOptions}
                  onChange={(options) => setEmployees(options)}
                  classNamePrefix='mySelect'
                />
              </div>
            </div>
            <div className='w-full mt-4'>
              <Button
                text='ADD TO DEPARTMENT'
                type='submit'
                loading={isLoading}
                onClick={() => submit()}
              />
            </div>
          </div>
        </div>
      ) : null}
    </Modal>
  );
}

export default AddEmployeeToDepartment;
