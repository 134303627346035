import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { performances } from '../../../api/hr/performance';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetPerformanceSummary() {
  return useQuery(['performance-summary'], () => {
    return performances.getPerformanceSummary();
  });
}

export function useGetPerformances(limit, page, statusFilter, search) {
  return useQuery(
    ['performances', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return performances.getPerformances({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((performance) => {
          performance.employeeName = `${performance.first_name} ${performance.last_name}`
          performance.period = `${moment(performance.evaluation_start).format('MMMM D, YYYY')} to ${moment(performance.evaluation_end).format('MMMM D, YYYY')}`
          performance.created = moment(performance.created).format('MMMM D, YYYY');
          performance.status = performance.review_status
          if (performance.review_status === 1) {
            performance.computedActions = [0];
          } else {
            performance.computedActions = [0, 1];
          }
          
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddPerformanceEvaluation() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.evaluatePerformance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performances');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddAdvancedPerformanceEvaluation() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.advancedEvaluatePerformance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performances');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddAdvancedEvaluatePerformanceBySource() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.advancedEvaluatePerformanceBySource(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performances');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGradeBasicPerformance() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.gradeBasicPerformance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performances');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateTrainingRequirements() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.updateTrainingRequirements(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performances');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateReviewerComment() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.updateReviewerComment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performances');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeletePerformance() {
  return useMutation(
    (payload) => {
      return performances.deletePerformance(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('performances');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useGetPerformance(id) {
  return useQuery(
    ['performance', { id }],
    () => {
      return performances.getPerformance(id);
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetPerformanceByEmployee(
  employee_id,
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date,
) {
  return useQuery(
    [
      'performance-by-employee',
      { employee_id, limit, page,statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return performances.getPerformancesByEmployee({
        employee_id,
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      enabled: employee_id !== null ? true : false,
      select(data) {
        data?.data?.forEach((performance) => {
          performance.employeeName = `${performance.first_name} ${performance.last_name}`
          performance.period = `${performance.evaluation_start} to ${performance.evaluation_end}`
          performance.created = `${performance.created.split('T')[0]}`
          performance.computedActions = [0, 1, 2];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetPerformanceWorkflows(limit, page, search) {
  return useQuery(
    ['performance-workflows', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return performances.getPerformanceWorkflows({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((performance) => {
          performance.created = performance.created?.split('T')[0];
          performance.computedActions = [0, 1, 2];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetPerformanceOnboardingWorkflows(limit, page, search) {
  return useQuery(
    ['evaluation-creation-workflows', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return performances.getPerformanceWorkflows({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({
          value: '',
          label: 'No Workflow Selected',
          criteria: [],
          id: null
        });
        data?.data?.forEach((item) => {
          newData.push({
            value: item.id,
            label: item.name,
            criteria: item.sub_workflows,
            id: item.id
          });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetPerformanceWorkflow(id) {
  return useQuery(
    ['performance-workflow', { id }],
    () => {
      return performances.getPerformanceWorkflow(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddPerformanceWorkflow() {
  return useMutation(
    (payload) => {
      return performances.addPerformanceWorkflow(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-workflows');
        store.dispatch(setAlert(true, 'success', response.success));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditPerformanceWorkflow() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.updatePerformanceWorkflow(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-workflows');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMarkPerformanceReviewed() {
  return useMutation(
      (payload) => {
          store.dispatch(setIsLoading(true));
          return performances.markPerformanceReviewed(payload);
      },
      {
          onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('performances');
              queryClient.invalidateQueries('performance');
              store.dispatch(setAlert(true, 'success', 'Performance review successfully'));
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

//OKR Data
export function useGetPerformanceOKRS(limit, page, search) {
  return useQuery(
    ['performance-okrs', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return performances.getPerformanceOkrs({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((performance) => {
          performance.period = `${moment(performance.start_date).format('MMMM D, YYYY')} to ${moment(performance.end_date).format('MMMM D, YYYY')}`
          performance.created = moment(performance.created).format('MMMM D, YYYY');
          performance.computedActions = [0, 1, 2];
          performance.evaluation_frequency = performance.evaluation_frequency? performance.evaluation_frequency : 'None'
          performance.auto_evaluate_converted = parseInt(performance.auto_evaluate) === 0 ? 'No' : 'Yes'
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddPerformanceOKR() {
  return useMutation(
    (payload) => {
      return performances.addPerformanceOKR(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-okrs');
        store.dispatch(setAlert(true, 'success', 'OKR added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditPerformanceOKR() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.updatePerformanceOKR(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-okrs');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteOKR() {
  return useMutation(
    (payload) => {
      return performances.deletePerformanceOKR(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('performance-okrs');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

//Smart Goals
export function useGetPerformanceSmartGoals(limit, page, search) {
  return useQuery(
    ['performance-smart-goals', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return performances.getPerformanceSmartGoals({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((performance) => {
          performance.period = `${moment(performance.start_date).format('MMMM D, YYYY')} to ${moment(performance.end_date).format('MMMM D, YYYY')}`
          performance.created = moment(performance.created).format('MMMM D, YYYY');
          performance.computedActions = [0, 1, 2];
          performance.evaluation_frequency = performance.evaluation_frequency? performance.evaluation_frequency : 'None'
          performance.auto_evaluate_converted = parseInt(performance.auto_evaluate) === 0 ? 'No' : 'Yes'
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddPerformanceSmartGoals() {
  return useMutation(
    (payload) => {
      return performances.addPerformanceSmartGoals(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-smart-goals');
        store.dispatch(setAlert(true, 'success', response.success));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditPerformanceSmartGoals() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.updatePerformanceSmartGoals(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-smart-goals');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGradeSmartGoals() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.gradeSmartGoals(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performances');
        queryClient.invalidateQueries('performance');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteSmartGoals() {
  return useMutation(
    (payload) => {
      return performances.deletePerformanceSmartGoals(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('performance-smart-goals');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

//Technical Appraisals
export function useGetTechnicalAppraisals(limit, page, search) {
  return useQuery(
    ['performance-technical-appraisals', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return performances.getTechnicalAppraisals({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((performance) => {
          performance.created = moment(performance.created).format('MMMM D, YYYY');
          performance.computedActions = [0, 1, 2];
          performance.evaluation_frequency = performance.evaluation_frequency? performance.evaluation_frequency : 'None'
          performance.auto_evaluate_converted = parseInt(performance.auto_evaluate) === 0 ? 'No' : 'Yes'
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddTechnicalAppraisals() {
  return useMutation(
    (payload) => {
      return performances.addTechnicalAppraisal(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-technical-appraisals');
        store.dispatch(setAlert(true, 'success', response.success));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditTechnicalAppraisals() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.updateTechnicalAppraisal(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-technical-appraisals');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGradeTechnicalAppraisals() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.gradeTechnicalAppraisal(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performances');
        queryClient.invalidateQueries('performance');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteTechnicalAppraisal() {
  return useMutation(
    (payload) => {
      return performances.deleteTechnicalAppraisal(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('performance-smart-goals');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

//Organisational Appraisal
export function useGetPerformanceTechnicalAppraisal(type) {
  return useQuery(
    ['performance-technical-appraisal', {type}],
    () => {
      store.dispatch(setIsLoading(true));
      return performances.getPerformanceAppraisalIndicators({type});
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetPerformanceBehaviouralAppraisal(type) {
  return useQuery(
    ['performance-behavioural-appraisal', {type}],
    () => {
      store.dispatch(setIsLoading(true));
      return performances.getPerformanceAppraisalIndicators({type});
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddPerformanceIndicator() {
  return useMutation(
    (payload) => {
      return performances.addPerformanceAppraisalIndicators(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-technical-appraisal');
        queryClient.invalidateQueries('performance-behavioural-appraisal');
        store.dispatch(setAlert(true, 'success', response.success));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditPerformanceIndicator() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.updatePerformanceAppraisalIndicators(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-technical-appraisal');
        queryClient.invalidateQueries('performance-behavioural-appraisal');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeletePerformanceIndicator() {
  return useMutation(
    (payload) => {
      return performances.deletePerformanceAppraisalIndicators(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('performance-technical-appraisal');
        queryClient.invalidateQueries('performance-behavioural-appraisal');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useGradeOrganisationalIndicators() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.gradeOrganisationalIndicators(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performances');
        queryClient.invalidateQueries('performance');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useComparePerformanceEvaluation() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.comparePerformanceEvaluation(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAssignPerformanceEvaluation() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.assignPerformanceEvaluation(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performances');
        queryClient.invalidateQueries('performance');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}


//Balanced Scorecard Data
export function useGetPerformanceBalancedScorecards(limit, page, search) {
  return useQuery(
    ['performance-balanced-scorecards', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return performances.getPerformanceBalancedScorecards({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((performance) => {
          performance.evaluation_frequency = performance.evaluation_frequency? performance.evaluation_frequency : 'None'
          performance.auto_evaluate_converted = parseInt(performance.auto_evaluate) === 0 ? 'No' : 'Yes'
          performance.computedActions = [0, 1, 2];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddPerformanceBalancedScorecard() {
  return useMutation(
    (payload) => {
      return performances.addPerformanceBalancedScorecard(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-balanced-scorecards');
        store.dispatch(setAlert(true, 'success', 'OKR added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditPerformanceBalancedScorecard() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return performances.updatePerformanceBalancedScorecard(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('performance-balanced-scorecards');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteBalancedScorecard() {
  return useMutation(
    (payload) => {
      return performances.deletePerformanceBalancedScorecard(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('performance-balanced-scorecards');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

