import DOMPurify from 'dompurify';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormTextArea from '../../../form-textarea';
import DragSVG from '../../../../assets/svg/reorder.svg';
import RemoveSVG from '../../../../assets/svg/remove.svg';
import {
  useAddPerformanceWorkflow,
  useEditPerformanceWorkflow,
} from '../../../../redux/human-resources/hook/performance';
import { store } from '../../../../redux';
import { setAlert } from '../../../../redux/components/components-slice';
import Modal from '../../modal';

function AddPerformanceWorkflow({
  isOpen,
  closeModal,
  workflowData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [subWorkflow, setSubWorkflow] = useState([
    {
      id: null,
      name: '',
      description: '',
      max_grade: '',
    },
    {
      id: null,
      name: '',
      description: '',
      max_grade: '',
    },
  ]);

  useEffect(() => {}, [subWorkflow]);

  const [workflowName, setWorkflowName] = useState(null);
  const [workflowDescription, setWorkflowDescription] = useState(null);

  const [workflowId, setWorkflowId] = useState(null);

  useEffect(() => {
    if (workflowData) {
      setWorkflowId(workflowData.id);
      setWorkflowName(workflowData.name);
      setWorkflowDescription(workflowData.description);
      setSubWorkflow(workflowData.sub_workflows);
      setPerformanceType('edit');
    } else {
      setPerformanceType('add');
    }
  }, [workflowData]);

  const { mutateAsync: addWorkflow, isLoading: addWorkflowloading } =
    useAddPerformanceWorkflow();
  const { mutateAsync: editWorkflow, isLoading: editWorkflowloading } =
    useEditPerformanceWorkflow();

  const [preformanceType, setPerformanceType] = useState('add');

  const dragItem = useRef();

  const dragOverItem = useRef();
  const [dragPosition, setDragPosition] = useState(null);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    setDragPosition(position);
  };

  const addCriteria = () => {
    setSubWorkflow([
      ...subWorkflow,
      ...[
        {
          id: null,
          name: '',
          description: '',
          max_grade: '',
        },
      ],
    ]);
  };
  const removeCriteria = (index) => {
    let data = [...subWorkflow];
    if (index > -1) {
      data.splice(index, 1);
      setSubWorkflow([...data]);
    }
  };

  const drop = (e) => {
    const copyListItems = [...subWorkflow];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    setSubWorkflow(copyListItems);
    dragItem.current = null;
    dragOverItem.current = null;
    setDragPosition(null);
  };

  const submitForm = async (data) => {
    const name = DOMPurify.sanitize(data?.name);
    const description = DOMPurify.sanitize(data?.description);

    let subflow = [];
    if (subWorkflow.length > 0) {
      subWorkflow?.forEach((item) => {
        if (item.name && item.max_grade) {
          let newSubflow = {
            id: item.id,
            name: item.name,
            description: item.description,
            max_grade: item.max_grade,
          };
          subflow.push(newSubflow);
        }
      });
      let payload = {
        name,
        description,
        sub_workflows: subflow,
        type: 'performance',
      };
      if (subflow.length > 0) {
        if (preformanceType === 'add') {
          await addWorkflow(payload).then(() => {
            closeModal();
          });
        } else {
          let editPayload = {
            id: workflowId,
            data: payload,
          };
          await editWorkflow(editPayload).then(() => {
            closeModal();
          });
        }
      } else {
        store.dispatch(
          setAlert(
            true,
            'info',
            'There must be at least one Evaluation Criteria with Name and Grade',
          ),
        );
      }
    } else {
      store.dispatch(
        setAlert(
          true,
          'info',
          'There must be at least one Evaluation Criteria',
        ),
      );
    }
  };
  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-6' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col max-h-fit mx-8'>
            <div className='flex flex-col border bg-highlight p-2 rounded-md'>
              <FormInput
                label='Workflow Name'
                name='name'
                type='text'
                placeholder='Enter workflow name'
                inputRef={register(formValidation('text', true))}
                value={workflowName}
                onInput={(e) => setWorkflowName(e.target.value)}
                readOnly={addWorkflowloading || editWorkflowloading}
                error={errors.name}
                errorMessage={errors.name && errors.name.message}
                className={'bg-white'}
              />
              <FormTextArea
                label={'Workflow Description'}
                name='description'
                placeholder='Enter workflow description'
                inputRef={register(formValidation('text', true))}
                value={workflowDescription}
                onInput={(e) => setWorkflowDescription(e.target.value)}
                readOnly={addWorkflowloading || editWorkflowloading}
                error={errors.description}
                errorMessage={errors.description && errors.description.message}
                className={'bg-white'}
              />
            </div>
            <hr className='divider mt-2' />
            <div className='flex flex-col'>
              <span className='header-4'>Evaluation Criteria</span>
              <span className='p3 text-[13px] text-color-gray'>
                Add Evaluation Criteria. Drag and Drop to change order.
              </span>
              <hr className='divider mt-2' />
            </div>
            <div>
              {subWorkflow &&
                subWorkflow?.map((item, index) => (
                  <div className='p-2 border mb-2 bg-highlight rounded-md'>
                    {dragPosition &&
                    dragItem.current &&
                    dragOverItem.current &&
                    dragItem.current !== dragOverItem.current &&
                    dragPosition === index ? (
                      <div className='opacity-30 duration-300'>
                        <div className='text-[14px] italic text-[#0d5225]'>
                          Criteria {index + 1}
                        </div>
                        <div className='flex flex-row items-center justify-between first-line:-mt-3 pr-2 pl-2 text-[13px]'>
                          <div className='w-{30%]'>
                            <img
                              src={DragSVG}
                              alt={'Refresh'}
                              className={'h-[50px] w-[50px] cursor-grabbing'}
                            />
                          </div>
                          <div className='W-[70%] pl-2'>
                            <div className='flex flex-row gap-3'>
                              <div className='flex flex-row items-center !w-[100%]'>
                                <FormInput
                                  name={dragItem.current + '_criteria_name'}
                                  type='text'
                                  inputRef={register(
                                    formValidation('text', true, 0),
                                  )}
                                  placeholder='Enter criteria name'
                                  value={subWorkflow[dragItem.current].name}
                                  className='!h-[30px] text-[13px] w-[100%] md:min-w-[260px] bg-white'
                                />
                              </div>
                              <div className='flex flex-row justify-end items-center !w-[50%] '>
                                <FormInput
                                  name={dragItem.current + 'criteria_max_grade'}
                                  type='number'
                                  placeholder='Max grade'
                                  value={
                                    subWorkflow[dragItem.current].max_grade
                                  }
                                  className='!h-[30px] text-[13px] w-[100%] min-w-[100px] bg-white'
                                />
                              </div>
                            </div>
                            <div className='items-center w-full'>
                              <FormTextArea
                                name={
                                  dragItem.current + '_Criteria_description'
                                }
                                type='text'
                                inputRef={register(
                                  formValidation('text', true, 0),
                                )}
                                value={
                                  subWorkflow[dragItem.current].description
                                }
                              />
                            </div>
                          </div>
                          <div className='w-[10%] ml-3 italic cursor-pointer text-[13px] hover:underline text-rose-500 pr-2 font-bold'>
                            Remove
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className='text-[13px] font-semibold italic text-[#0d5225]'>
                      Criteria {index + 1}
                    </div>
                    <div
                      className='flex flex-row items-center justify-between first-line:-mt-3 gap-2 pr-2 pl-2 text-[13px] w-full'
                      key={index}
                      onDragStart={(e) => dragStart(e, index)}
                      onDragEnter={(e) => dragEnter(e, index)}
                      onDragEnd={drop}
                      draggable={index !== 0 ? true : false}
                    >
                      <div className='max-w-[15%]'>
                        <img
                          src={DragSVG}
                          alt={'Refresh'}
                          className={'h-[20px] w-[20px] cursor-grabbing'}
                        />
                      </div>
                      <div className='min-w-[85%]'>
                        <div className='flex flex-row gap-3'>
                          <div className='flex flex-row items-center !w-[100%]'>
                            <FormInput
                              label='Criteria Name'
                              name={index + '_criteria_name'}
                              type='text'
                              placeholder='Enter criteria name'
                              inputRef={register(
                                formValidation('text', true, 0),
                              )}
                              value={item.name}
                              onInput={(e) => {
                                let newArray = JSON.parse(JSON.stringify(subWorkflow));
                                newArray[index].name = e.target.value;
                                setSubWorkflow(newArray);
                              }}
                              className='!h-[30px] !text-[13px] w-[100%] md:min-w-[260px] bg-white'
                            />
                          </div>
                          <div className='flex flex-row justify-end items-center !w-[50%] '>
                            <FormInput
                              label='Max Grade'
                              type='number'
                              placeholder='Enter max grade'
                              defaultValue={item.max_grade}
                              onInput={(e) => {
                                let newArray = JSON.parse(JSON.stringify(subWorkflow));
                                newArray[index].max_grade = e.target.value;
                                setSubWorkflow(newArray);
                              }}
                              className='!h-[30px] !text-[13px] w-[100%]  min-w-[100px] bg-white'
                            />
                          </div>
                        </div>
                        <div className='items-center w-full'>
                          <FormInput
                            label={'Criteria Description'}
                            name={index + '_Criteria_description'}
                            type='text'
                            className='!h-[30px] !text-[13px] w-[100%]  min-w-[100px] bg-white'
                            placeholder='Enter Description'
                            value={item.description}
                            onInput={(e) => {
                              let newArray = JSON.parse(JSON.stringify(subWorkflow));
                              newArray[index].description = e.target.value;
                              setSubWorkflow(newArray);
                            }}
                          />
                        </div>
                      </div>
                      
                    </div>
                    {subWorkflow?.length > 1 ?
                    <div 
                      className='flex w-full justify-end px-4 ml-3 italic cursor-pointer text-[12px] hover:underline text-rose-500 pr-2 font-bold'
                      onClick={() => {
                        removeCriteria(index)
                      }}
                    >
                      Remove
                    </div>
                    :
                    null
                    }
                    
                  </div>
                ))}
              <hr className='divider -mt-1' />
              <div className='flex justify-left w-full mb-3'>
                <span
                  className='p2-medium cursor-pointer hover:underline text-color-black duration-75 italic text-[13px] text-[#0d5225]'
                  onClick={() => addCriteria()}
                >
                  Add New Criteria
                </span>
              </div>
            </div>
            <div className='w-full'>
              <Button
                text={buttonText}
                theme={'third'}
                type='submit'
                loading={addWorkflowloading || editWorkflowloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddPerformanceWorkflow;
