import React, { useEffect, useState } from "react";
import { useGoogleLogin } from "../../redux/user/hook";
import AuthLayout from "../../components/layouts/auth";
import { useRouteQuery } from "../../hooks/useRouteQuery";
import { store } from "../../redux";
import { setAlert } from "../../redux/components/components-slice";
import { useHistory } from "react-router-dom";
import Button from "../../components/button";
import { useSelector } from "react-redux";

const GoogleRedirectPage = () => {
  const { mutate: googleSignIn, isLoading: loading } = useGoogleLogin();

  useEffect(() => {}, []);

  const history = useHistory();
  const query = useRouteQuery();
  const { webToken } = useSelector((state) => state.userSlice);

  useEffect(() => {
    if (query) {
      if (query.get("token")) {
        let payload = {
          source: "web",
          web_token: webToken ? webToken : "no_token",
          token: query.get("token"),
        };
        googleSignIn(payload);
      } else {
        store.dispatch(
          setAlert(true, "info", "No authentication token found.")
        );
      }
    }
  }, [query]);

  return (
    <AuthLayout>
      <>
        <div className="w-full text-center flex flex-col min-h-[500px] max-h-[500px]">
          <div className={"text-[20px] font-bold text-black mt-8"}>
            Google authentication successful
          </div>
          {loading ? (
            <div
              className={
                "flex flex-row w-[100%] items-center justify-center mt-2 animate-pulse text-[22px] font-bold text-black"
              }
            >
              Logging You In...
            </div>
          ) : (
            <div
              className={
                "flex flex-row w-[100%] items-center justify-center mt-2 text-[22px] font-bold text-black"
              }
            >
              Login Failed
            </div>
          )}
        </div>
      </>
    </AuthLayout>
  );
};

export default GoogleRedirectPage;
