import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, path }) => {
  const { user, twoFaToken } = useSelector((state) => state.userSlice);
  const { section } = useSelector((state) => state.sectionSlice);

  const location = useLocation();

  const history = useHistory();

  if (!user) {
    return <Redirect to='/' />;
  }

  function checkOnboarding() {
    
    if (user && user.onboardingStatus === 0) {
      if (location.pathname !== '/onboarding/about-company') {
        history.push('/onboarding/about-company');
      }
      return;
    } else if (user && user.onboardingStatus === 1) {
      if (location.pathname !== '/onboarding/rate-settings') {
        history.push('/onboarding/rate-settings');
      }
      return;
    } else if (
      user &&
      user.onboardingStatus === 2 && location.pathname === '/onboarding/rate-settings'
    ) {
      history.push(section.entryRoute);
      return;
    } else {
      return;
    }
  }

  if (twoFaToken) {
    if (location.pathname.split("/")[1] !== 'verify-2fa') {
      return history.push(`/verify-2fa/${twoFaToken?.token}`);
    }
  } else if (user) {
    checkOnboarding();
  } else {
    history.push('/');
  }

  return <Route component={Component} path={path} history={history} />;
};

export default ProtectedRoute;
