import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelectEmployee from '../../../employees/form-select-employee';
import {
  useAddEmployeePromotion,
  useUpdateEmployeePromotion,
} from '../../../../redux/employees/hook/promotion';
import Modal from '../../modal';
import { useGetEmployee } from '../../../../redux/employees/hook';
import { Editor } from '@tinymce/tinymce-react';
import FormSwitch from '../../../form-switch';

function AddPromotion({
  isOpen,
  closeModal,
  refresh,
  promotionData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();

  const [promotionId, setPromotionId] = useState(null);
  const [promotionChangeType, setPromotionChangeType] = useState('add');

  const [promotionFrom, setPromotionFrom] = useState(null);
  const [promotionTo, setPromotionTo] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);
  const [body, setBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');

  const { data: employeeInView, isLoading } = useGetEmployee(employee);

  useEffect(() => {
    if (promotionData) {
      setPromotionId(promotionData.id);
      setPromotionFrom(promotionData.promotion_from);
      setPromotionTo(promotionData.promotion_to);
      setEffectiveDate(promotionData.effective_date);
      setEmployeeName(`${promotionData.first_name} ${promotionData.last_name}`);
      setEmployee(promotionData.employee_id);
      setPromotionChangeType('edit');
    } else {
      setPromotionChangeType('add');
    }
  }, [promotionData]);

  useEffect(() => {
    if (employeeInView) {
      setPromotionFrom(employeeInView.job_position);
    }
  }, [employeeInView]);

  const { mutateAsync: addPromotion, isLoading: addPromotionloading } =
    useAddEmployeePromotion();

  const { mutateAsync: editPromotion, isLoading: editPromotionloading } =
    useUpdateEmployeePromotion();

  const submitForm = async (data) => {
    if (promotionChangeType === 'add') {
      let payload = {
        employee_id: employee,
        promotion_from: promotionFrom ? promotionFrom : 'Not Provided',
        promotion_to: promotionTo,
        effective_date: effectiveDate,
        send_email: sendEmail ? 1 : 0,
        email_subject: emailSubject,
        email_body: body.toString(),
      };
      addPromotion(payload).then(() => {
        closeModal();
      });
    } else {
      let payload = {
        employee_id: employee,
        promotion_from: promotionFrom ? promotionFrom : 'Not Provided',
        promotion_to: promotionTo,
        effective_date: effectiveDate,
        send_email: 0,
      };
      let editPayload = {
        id: promotionId,
        data: payload,
      };
      editPromotion(editPayload).then(() => {
        closeModal();
      });
    }
  };
  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form' onSubmit={handleSubmit(submitForm)}>
            <div className='px-8 pt-4'>
              <div className='flex flex-row justify-between items-center'>
                <span className='header-4'>{title}</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='close' />
                </div>
              </div>
            </div>
            <hr className='divider mb-2' />
            <div className='flex flex-col mx-8'>
              <div>
                <div className='border bg-highlight p-2 rounded-md'>
                  <FormSelectEmployee
                    label={'Select Employee'}
                    name='employee'
                    placeholder='Type to select Employee'
                    value={employee}
                    empName={employeeName}
                    setEmployee={setEmployee}
                  />
                </div>
              </div>
              <hr className='divider mt-2 mb-2' />
              <div className='flex flex-col md:flex-row w-full justify-between'>
                <div className='w-full md:pr-2 md:w-1/2'>
                  <FormInput
                    label='Promotion From'
                    name='promotion_from'
                    type='text'
                    value={promotionFrom}
                    onInput={(e) => {
                      setPromotionFrom(e.target.value);
                    }}
                    readOnly={true}
                    error={errors.promotion_from}
                    errorMessage={
                      errors.promotion_from && errors.promotion_from.message
                    }
                  />
                </div>
                <div className='w-full md:pl-2 md:w-1/2'>
                  <FormInput
                    placeholder={'Enter new position'}
                    label='Promotion To'
                    name='promotion_to'
                    type='text'
                    value={promotionTo}
                    onInput={(e) => {
                      setPromotionTo(e.target.value);
                    }}
                    error={errors.promotion_to}
                    errorMessage={
                      errors.promotion_to && errors.promotion_to.message
                    }
                  />
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:pr-2 md:w-1/2'>
                  <FormInput
                    label='Effective Date'
                    name='effective_date'
                    type='date'
                    value={effectiveDate}
                    onInput={(e) => {
                      setEffectiveDate(e.target.value);
                    }}
                    error={errors.effective_date}
                    errorMessage={
                      errors.effective_date && errors.effective_date.message
                    }
                  />
                </div>
              </div>
              <div
                className={
                  'flex w-full flex-col gap-2 px-3 border bg-highlight rounded-md pt-4'
                }
              >
                <div className=''>
                  <FormSwitch
                    label={'Do you want to send promotion email to employee?'}
                    name={'send_email'}
                    checked={sendEmail}
                    onClick={() => setSendEmail(!sendEmail)}
                    value={sendEmail}
                  />
                </div>

                {sendEmail ? (
                  <div className='flex flex-col'>
                    <hr className='divider -mt-2 mb-1' />
                    <FormInput
                      label='Subject'
                      name='subject'
                      type='text'
                      value={emailSubject}
                      placeholder={'Email Subject'}
                      onInput={(e) => {
                        setEmailSubject(e.target.value);
                      }}
                      error={errors.subject}
                      errorMessage={errors.subject && errors.subject.message}
                    />
                    <div className='flex md:flex-row flex-col w-full justify-between mb-2'>
                      <div className='w-full'>
                        <span className='text-[13px] text-gray-500'>
                          Promotion Email Body
                        </span>
                        <Editor
                          apiKey={import.meta.env.VITE_APP_TINYMCE_API_KEY}
                          onEditorChange={(editor) => {
                            setBody(editor);
                          }}
                          value={body}
                          init={{
                            height: 300,
                            menubar: false,
                            placeholder: 'Enter Email body here',
                            selector: 'textarea',
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | ' +
                              'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help',
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='w-full mt-[20px] pb-4 '>
                <Button
                  text={buttonText}
                  type='submit'
                  disabled={!employee || !promotionTo || !effectiveDate}
                  loading={addPromotionloading || editPromotionloading}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddPromotion;
