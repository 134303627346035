import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useAddApplicationNote,
  useDeleteApplicationNote,
  useEditApplicationNote,
  useGetApplicationNotes,
} from "../../../../redux/human-resources/hook/recruitment";
import Button from "../../../button";
import AddSmallSVG from "../../../../assets/svg/add-icon-small.svg";
import FormTextArea from "../../../form-textarea";

export const ApplicationNotes = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { id } = useParams();

  const [type, setType] = useState("add");
  const [noteId, setNoteId] = useState();

  const { data: application_notes } = useGetApplicationNotes(id);
  const { user } = useSelector((state) => state.userSlice);

  const {
    mutateAsync: addApplicationNote,
    isLoading: addApplicationNoteloading,
  } = useAddApplicationNote();

  const {
    mutateAsync: editApplicationNote,
    isLoading: editApplicationNoteloading,
  } = useEditApplicationNote();
  const { mutateAsync: deleteApplicationNote } = useDeleteApplicationNote();

  const [comment, setComment] = useState();

  const [addNote, setAddNote] = useState(false);

  useEffect(() => {
    if (application_notes) {
      setType("edit");
      setApplicationNotes(application_notes);
    } else {
      setType("add");
    }
  }, [application_notes]);

  const [applicationNotes, setApplicationNotes] = useState([]);

  const submitForm = async () => {
    let payload = {
      comment,
      recruitment_application_id: id,
    };
    if (type === "add") {
      await addApplicationNote(payload).then(() => {
        setNoteId("");
        setType("add");
        setComment("");
        setAddNote(false);
      });
    } else {
      let editPayload = {
        id: noteId,
        data: payload,
      };
      await editApplicationNote(editPayload).then(() => {
        setNoteId("");
        setType("add");
        setComment("");
        setAddNote(false);
      });
    }
  };
  return (
    <div
      className={`min-h-[120px] flex flex-col  gap-2 justify-start bg-white rounded-[5px] border border-[#dbdce3]`}
    >
      <div className="flex flex-row justify-between items-center bg-blue-100 p-3 font-semibold text-[14px] text-color-black">
        Applicant Notes
      </div>
      {addNote ? (
        <form className="form p-8 pt-2">
          <div className="flex flex-col md:flex-row justify-between gap-3">
            <div className="w-full">
              <FormTextArea
                label="Note"
                name="comment"
                value={comment}
                placeholder={"Enter note here"}
                onInput={(e) => {
                  setComment(e.target.value);
                }}
                type="text"
                className=" text-[14px]"
                error={errors.comment}
                errorMessage={errors.comment && errors.comment.message}
              />
            </div>
          </div>
          <div className="flex md:flex-row justify-end gap-4">
            <Button
              text="Cancel"
              type="button"
              onClick={() => {
                setNoteId("");
                setType("add");
                setComment("");
                setAddNote(false);
              }}
              textClass={"!text-[11px]"}
              className={"h-[35px] w-[100px]"}
              theme={"third"}
            />
            <Button
              text={type === "add" ? "Add" : "Update"}
              type="button"
              disabled={!comment || !id}
              onClick={() => {
                submitForm();
              }}
              textClass={"!text-[11px]"}
              className={"h-[35px] w-[100px]"}
              loading={addApplicationNoteloading || editApplicationNoteloading}
            />
          </div>
        </form>
      ) : (
        <>
          {applicationNotes && applicationNotes.length > 0 ? (
            <div className="flex flex-col pt-3 !max-h-[500px] overflow-y-auto">
              <div className="flex flex-row justify-end items-center gap-1 text-right pr-8 italic text-hr-primary-1 text-[14px] mb-2">
                <img
                  src={AddSmallSVG}
                  alt={"add_note"}
                  className={"h-[15px] w-[15px]"}
                />
                <span
                  className="italic text-hr-primary-1 text-[13px] hover:underline hover:text-[14px] duration-300 cursor-pointer font-bold"
                  onClick={() => {
                    setNoteId("");
                    setType("add");
                    setComment("");
                    setAddNote(true);
                  }}
                >
                  Add Note
                </span>
              </div>
              {applicationNotes?.map((item, index) => (
                <div>
                  <div className="flex md:flex-row  items-end pl-8 pr-8 pb-3 justify-between">
                    <div className="flex flex-col  text-gray-500">
                      <div className="text-[11px] text-gray-500">
                        {item.created.split("T").join(" ").split(".")[0]}
                      </div>
                      <div className="header-5 text-gray-700 pb-1">
                        {item.user_id === user?.id ? "You" : item.user} added a
                        comment
                      </div>
                      <div className="text-[12px] text-gray-600">
                        {item.comment}
                      </div>
                    </div>
                    {item.user_id === user?.id ? (
                      <div className="flex flex-row gap-2 text-[13px] italic">
                        <div
                          className="text-hr-primary-1 hover:underline cursor-pointer duration-300"
                          onClick={() => {
                            setNoteId(item.id);
                            setType("edit");
                            setComment(item.comment);
                            setAddNote(true);
                          }}
                        >
                          Update
                        </div>
                        <div
                          className="text-rose-600 hover:underline cursor-pointer duration-300"
                          onClick={() => {
                            deleteApplicationNote(item.id);
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {index !== applicationNotes.length - 1 ? (
                    <hr className="divider -mt-3 mb-1" />
                  ) : null}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center w-[100%] p-3 italic text-hr-primary-1 text-[14px]">
              <span
                className="text-center w-[100%] p-3 italic text-hr-primary-1 text-[14px] hover:underline hover:text-[16px] duration-300 cursor-pointer font-semibold"
                onClick={() => {
                  setNoteId("");
                  setType("add");
                  setComment("");
                  setAddNote(true);
                }}
              >
                Add Note
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
