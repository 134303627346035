import { Axios } from '../axios';

const getQueries = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.get( `/queries?limit=${payload.limit}&page=${payload.page}${search}`,);
    return data?.data;
};

const getQuery = async (payload) => {
    const { data } = await Axios.get('/queries/' + payload.id);
    return data?.data;
};

const addQuery = async (payload) => {
    const { data } = await Axios.post('/queries', payload);
    return data;
};

const updateQuery = async (payload) => {
    const { data } = await Axios.put('/queries/' + payload.id, payload.data);
    return data;
};
  
const getEmployeeQueries = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.get(`/queries/employee/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${search}`,);
    return data?.data;
};

const changeQueryStatus = async (payload) => {
    const { data } = await Axios.post(
      '/queries/mark-resolved/'+ payload.id, payload.data,
    );
    return data;
};

const deleteQuery = async (payload) => {
    const { data } = await Axios.delete(`/queries/${payload}`,);
    return data;
};

const getQueryNotesByQuery = async (payload) => {
    const { data } = await Axios.get( `/query-notes/get-by-query/${payload}`,);
    return data?.data;
};

const addQueryNote = async (payload) => {
    const { data } = await Axios.post('/query-notes', payload);
    return data;
};

const updateQueryNote = async (payload) => {
    const { data } = await Axios.put('/query-notes/' + payload.id, payload.data);
    return data;
};

const deleteQueryNote = async (payload) => {
    const { data } = await Axios.delete(`/query-notes/${payload}`,);
    return data;
};

export const queries = {
    addQuery,
    getQueries,
    updateQuery,
    getQuery,
    getEmployeeQueries,
    changeQueryStatus,
    deleteQuery,

    getQueryNotesByQuery,
    addQueryNote,
    updateQueryNote,
    deleteQueryNote,
};