import React, { useState } from 'react';
import Button from '../../button';
import Modal from '../modal';
import { useChangeInvitationLinkStatus, useGetRegistrationLink, useRefreshInvitationLink } from '../../../redux/employees/hook';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/components/components-slice';
import CopySVG from '../../../assets/svg/copy.svg';
import CloseSVG from '../../../assets/svg/close.svg';
import WarningSVG from '../../../assets/svg/warning.svg';
import FormSwitch from '../../form-switch';
import LoadingBar from '../../loader-bar';

function InvitationLinkModal({
  isOpen,
  closeModal,
}) {
    const dispatch = useDispatch()
    const { data: link, isLoading } = useGetRegistrationLink();

    const { mutateAsync: refreshInvite, isLoading: refreshloading } = useRefreshInvitationLink();
    const { mutateAsync: changeStatus, isLoading: changeStatusloading } = useChangeInvitationLinkStatus();
    return (
        <Modal
            scrollable={true}
            className='!md:max-w-[60%] max-w-[85%] !rounded-lg border-none'
            isOpen={isOpen}
            centered={true}
        >
            <div className='flex flex-col overflow-auto'>
                <div className='p-4 px-8 pb-0'>
                    <div className='flex flex-row justify-between items-center'>
                    <span className='header-4'>Employee Invitation Link</span>
                    <div className='cursor-pointer' onClick={() => closeModal()}>
                        <img src={CloseSVG} alt='close' />
                    </div>
                    </div>
                </div>
                <hr className='divider' />
                {isLoading?
                    <LoadingBar loading={isLoading} />
                :
                <div className='flex flex-col justify-center items-start w-full gap-2 px-8 pb-4'>
                    <div className='flex flex-col p3 items-center justify-center'>
                        <span className='text-color-black pt-2 text-[16px] font-extrabold'>
                            Employee Registration Link:
                        </span>
                        <span className=' text-secondary-2 pt-1 !text-center text-[14px]'>
                            {link?.link}{' '}
                        </span>
                        <CopyToClipboard
                            text={link?.link}
                            onCopy={() => {
                                dispatch(setAlert(true, 'success', 'Copied successfully'));
                            }}
                        >
                            <button className='flex flex-row mt-2 items-center text-center'>
                                <span className='text-color-black pt-2 text-[14px] text-hr-primary-1 hover:font-bold duration-300'>
                                    Copy to clipboard{' '}
                                </span>
                                <img src={CopySVG} width={15} className='ml-2 mt-2' alt='copy' />
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div className='flex flex-col justify-center mb-2'>
                        <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 mt-2 bg-color-warning-light text-[13px] items-center'>
                            <div className='w-[150px] pr-2 flex flex-row items-center '>
                                <img src={WarningSVG} alt='' srcSet='' />
                            </div>
                            <span className='text-justify !text-[12px]'>
                                You can collect employee information by sharing this link to your employees to fill in all their information before reviewing and activating them on the platform. This saves you a lot of time onboarding one by one or using excel (through multiple upload).
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full'>
                        <div className='w-full md:w-1/2'>
                            <FormSwitch
                                label={'Accessibility Status'}
                                name={'accessibility_status'}
                                checked={link.status}
                                onClick={() => changeStatus()}
                            />
                        </div>
                    </div>
                    <div className='flex justify-end w-full gap-3 -mt-4'>
                        <Button
                            text={'Refresh Link'}
                            type='button'
                            className={'flex gap-2 !h-[35px] w-fit p-2 min-w-[100px]'}
                            textClass={'!text-[11px]'}
                            loading={refreshloading || changeStatusloading}
                            onClick={() => {refreshInvite()}}
                        />
                    </div>
                </div>
                }
            </div>
        </Modal>
    );
}

export default InvitationLinkModal;
