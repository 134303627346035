import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../..';
import moment from 'moment';
import { contact_us } from '../../api/contact-us';


export function useGetSupportTickets(limit, page, statusFilter, search, start_date, end_date) {
    return useQuery(
      ['support-tickets', { limit, page, statusFilter, search, start_date, end_date }],
      () => {
        store.dispatch(setIsLoading(true));
        return contact_us.getTickets({ limit, page, statusFilter, search, start_date, end_date });
      },
      {
        select: (data) => {
          data?.data?.forEach((ticket) => {
            ticket.created = moment(ticket.created).format('MMMM DD, YYYY');
            ticket.computedActions = [0];
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetSupportTicket(id) {
    return useQuery(
      ['support-ticket', { id }],
      () => {
        store.dispatch(setIsLoading(true));
        return contact_us.getTicket(id);
      },
      {
        enabled: id !== null,
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
  }

export function useAddTicket() {
    return useMutation(
      (payload) => {
        return contact_us.addTicket(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('support-tickets');
          store.dispatch(setAlert(true, 'success', 'Ticket added successfully'));
        },
        onSettled: (response, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useAddFeedback() {
    return useMutation(
      (payload) => {
        return contact_us.addFeedback(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('support-tickets');
          store.dispatch(setAlert(true, 'success', 'Feedback added successfully'));
        },
        onSettled: (response, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useAddTicketComment() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return contact_us.addTicketComment(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('ticket-comments');
          store.dispatch(setAlert(true, 'success', 'Message sent successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
  }
  
  export function useGetTicketComments(id) {
    return useQuery(
      ['ticket-comments', id],
      () => {
        store.dispatch(setIsLoading(true));
        return contact_us.geTicketComments(id);
      },
      {
        enabled: id !== null,
        select: (data) => {
          data?.forEach((comment) => {
            comment.created = moment(comment.created).format('MMMM DD, YYYY');
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
  }
  
  export function useEditTicketComment() {
    return useMutation(
      (payload) => {
        return contact_us.updateTicketComment(payload);
      },
      {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('ticket-comments');
            store.dispatch(setAlert(true, 'success', 'Message Updated Successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
  }