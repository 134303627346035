import React, { useEffect } from "react";
import { useState } from "react";
import FormSelect from "../../form-select";
import FormInput from "../../form-input";
import FormSwitch from "../../form-switch";
import Button from "../../button";
import LoadingBar from "../../loader-bar";
import SettingsSVG from "../../../assets/svg/settings-image.svg";
import {
  useEditHMOGroup,
  useGetHMOGroupSettings,
  useGetHMOPlans,
  useGetHMOs,
  useGetOnboardingHMOPlans,
} from "../../../redux/hmo/hook";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { useParams } from "react-router-dom";
import { formatNumber } from "../../../utils/functions";

const HMOGroupSettings = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });
  const { id } = useParams();

  const { data: groupSettings, isLoading } = useGetHMOGroupSettings(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [groupId, setGroupId] = useState(null);

  const { mutateAsync: editGroup, isLoading: editGrouploading } =
    useEditHMOGroup();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [useCompanySettings, setUseCompanySettings] = useState(false);
  const [canSelectHMO, setCanSelectHMO] = useState(false);
  const [hmoName, setHmoName] = useState("");
  const [hmoId, setHmoId] = useState(null);
  const [hmoPlan, setHmoPlan] = useState("");
  const [planId, setPlanId] = useState(null);
  const [hmoBudget, setHmoBudget] = useState("");
  const [maxSubscriptionMonth, setMaxSubscriptionMonth] = useState(0);
  const [canAddDependants, setCanAddDependants] = useState(0);
  const [maxDependants, setMaxDependants] = useState(0);
  const [autoRenew, setAutoRenew] = useState(0);

  const { data: hmos } = useGetHMOs();

  const { data: hmoPlansCompleteInformation, isLoading: completePlansLoading } =
    useGetHMOPlans(hmoId);

  const { data: hmoPlans, isLoading: plansLoading } =
    useGetOnboardingHMOPlans(hmoId);

  const [CurrentHrPlan, setCurrentHrPlan] = useState();

  useEffect(() => {
    if (planId && hmoPlansCompleteInformation) {
      let index = hmoPlansCompleteInformation?.findIndex(
        (item) => item.id == planId
      );
      if (index != -1) {
        setCurrentHrPlan(hmoPlansCompleteInformation[index]);
        setHmoBudget(hmoPlansCompleteInformation[index].price);
        setMaxSubscriptionMonth(
          hmoPlansCompleteInformation[index].payment_duration == "0"
            ? 1
            : hmoPlansCompleteInformation[index].payment_duration
        );
      }
    }
  }, [planId, hmoPlansCompleteInformation]);

  useEffect(() => {
    if (hmoId) {
      setCurrentHrPlan(null);
      setHmoBudget(null);
    }
  }, [hmoId]);

  const [hmoData, setHmoData] = useState([
    { value: "", label: "No HMO Selected", hmo_name: "" },
  ]);

  const [hmoPlanData, setHmoPlanData] = useState([
    { value: "", label: "No Plan Selected", plan_name: "" },
  ]);

  useEffect(() => {
    if (hmos) {
      setHmoData(hmos);
    }
  }, [hmos]);

  useEffect(() => {
    if (hmoPlans) {
      setHmoPlanData(hmoPlans);
    }
  }, [hmoPlans]);

  useEffect(() => {
    if (groupSettings) {
      setUseCompanySettings(groupSettings.use_company_settings);
      setName(groupSettings.group_name);
      setDescription(groupSettings.description);
      setHmoName(groupSettings.hmo_name);
      setCanSelectHMO(groupSettings.can_select_hmo);
      setHmoId(groupSettings.hmo_id);
      setHmoPlan(groupSettings.hmo_plan);
      setPlanId(groupSettings.hmo_plan_id);
      setHmoBudget(groupSettings.hmo_budget);
      setMaxSubscriptionMonth(groupSettings.max_subscription_months);
      setCanAddDependants(groupSettings.can_add_dependant);
      setMaxDependants(groupSettings.max_dependants);
      setAutoRenew(groupSettings.auto_renew);
      setGroupId(groupSettings.group_domain);
    }
  }, [groupSettings]);

  const submitForm = async () => {
    let payload = {
      name: name,
      description: description,
      use_company_settings: useCompanySettings ? 1 : 0,
      can_select_hmo: canSelectHMO ? 1 : 0,
      hmo_name: hmoName,
      hmo_id: hmoId,
      hmo_plan: hmoPlan,
      hmo_plan_id: planId,
      hmo_budget: hmoBudget,
      max_subscription_months: maxSubscriptionMonth,
      can_add_dependant: canAddDependants ? 1 : 0,
      max_dependants: maxDependants,
      auto_renew: autoRenew,
    };

    let editPayload = {
      id: groupId,
      body: payload,
    };
    await editGroup(editPayload).then(() => {
      closeModal();
    });
  };

  return (
    <>
      <div className="flex flex-col bg-white w-full  rounded-md py-3 h-full min-h-fit ">
        <div className="flex flex-col px-4">
          <span className="p1-bold ">Group HMO Settings</span>
          <span className="text-[13px] text-color-gray w-full md:w-[100%]">
            Update and edit your Payroll Group settings
          </span>
        </div>
        <hr className="divider" />
        <div className="flex flex-col px-4">
          <div className="flex flex-col md:flex-row">
            <div className="flex w-full max-w-[100%] px-4 md:px-0 flex-col">
              {isLoading ? (
                <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                  <LoadingBar loading={isLoading} />
                </div>
              ) : (
                <div className="flex flex-row ">
                  <div className="flex flex-col w-full">
                    <div className="flex flex-col md:flex-row ">
                      <div className="flex w-full max-w-[100%] px-4 md:px-0 flex-col">
                        <div className={"flex w-full flex-col mt-2"}>
                          <div className="font-bold">
                            <FormSwitch
                              label={"Use Company Settings"}
                              name={"use_company_settings"}
                              readOnly={editGrouploading}
                              checked={useCompanySettings}
                              onClick={() =>
                                setUseCompanySettings(!useCompanySettings)
                              }
                            />
                          </div>
                        </div>
                        {!useCompanySettings ? (
                          <div className="flex flex-col p-4 border rounded mb-4 !bg-gray-50">
                            <div className={"flex w-full flex-col font-bold"}>
                              <FormSwitch
                                label={"Employee Can Select HMO"}
                                name={"can_select_hmo"}
                                readOnly={editGrouploading}
                                checked={canSelectHMO}
                                onClick={() => {
                                  if (!canSelectHMO) {
                                    setHmoBudget(0);
                                    setMaxSubscriptionMonth(0);
                                    setCanSelectHMO(!canSelectHMO);
                                  } else {
                                    let index =
                                      hmoPlansCompleteInformation?.findIndex(
                                        (item) => item.id == planId
                                      );
                                    if (index && index != -1) {
                                      setCurrentHrPlan(
                                        hmoPlansCompleteInformation[index]
                                          ? hmoPlansCompleteInformation[index]
                                          : null
                                      );
                                      setHmoBudget(
                                        hmoPlansCompleteInformation[index].price
                                          ? hmoPlansCompleteInformation[index]
                                              .price
                                          : null
                                      );
                                      setMaxSubscriptionMonth(
                                        hmoPlansCompleteInformation[index]
                                          .payment_duration
                                          ? hmoPlansCompleteInformation[index]
                                              .payment_duration
                                          : null
                                      );
                                    }
                                    setCanSelectHMO(!canSelectHMO);
                                  }
                                }}
                              />
                            </div>
                            <div className="flex flex-col p-4 border rounded">
                              {!canSelectHMO ? (
                                <>
                                  <div className="flex md:flex-row flex-col w-full justify-between gap-2">
                                    <div className="md:w-1/2 w-full md:pr-2">
                                      <FormSelect
                                        value={hmoId}
                                        options={hmoData}
                                        onChange={(selected) => {
                                          setHmoId(selected);
                                        }}
                                        label="Select HMO"
                                      />
                                    </div>
                                    {hmoPlans ? (
                                      <div className="md:w-1/2 w-full md:pl-2">
                                        <FormSelect
                                          value={planId}
                                          options={hmoPlanData}
                                          onChange={(selected) => {
                                            setPlanId(selected);
                                          }}
                                          label="Select HMO Plan"
                                        />
                                      </div>
                                    ) : plansLoading && hmoId ? (
                                      <div className="flex flex-col w-full text-center align-center justify-center">
                                        <LoadingBar
                                          loading={plansLoading && hmoId}
                                        />
                                      </div>
                                    ) : (
                                      <div className="flex flex-col md:w-1/2 w-full text-center align-center justify-center">
                                        No plan fetched
                                      </div>
                                    )}
                                  </div>
                                  <div className="flex flex-col p-2 border rounded mb-2 w-full">
                                    {CurrentHrPlan ? (
                                      <>
                                        <div className="text-gray-500 text-[15px]">
                                          Plan Name
                                        </div>
                                        <div className="text-[13px] font-extrabold">
                                          {CurrentHrPlan?.name}
                                        </div>
                                        <hr className="divider" />
                                        <div className="font-bold text-[16px] mb-2">
                                          Plan Coverage
                                        </div>
                                        <div className="grid md:grid-cols-3 grid-cols-3 gap-4 w-full pt-2 duration-500">
                                          <div className="flex flex-col">
                                            <div className="text-gray-500 text-[14px]">
                                              Plan Type
                                            </div>
                                            <div className="text-[13px]">
                                              {CurrentHrPlan?.Plan_type}
                                            </div>
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="text-gray-500 text-[14px]">
                                              An Pn Childbirth
                                            </div>
                                            <div className="text-[13px]">
                                              {CurrentHrPlan?.an_pn_childbirth}
                                            </div>
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="text-gray-500 text-[14px]">
                                              Dental
                                            </div>
                                            <div className="text-[13px]">
                                              {CurrentHrPlan?.dental}
                                            </div>
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="text-gray-500 text-[14px]">
                                              Drugs
                                            </div>
                                            <div className="text-[13px]">
                                              {CurrentHrPlan?.drugs}
                                            </div>
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="text-gray-500 text-[14px]">
                                              Emergency
                                            </div>
                                            <div className="text-[13px]">
                                              {CurrentHrPlan?.emergency}
                                            </div>
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="text-gray-500 text-[14px]">
                                              Gym
                                            </div>
                                            <div className="text-[13px]">
                                              {CurrentHrPlan?.gym}
                                            </div>
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="text-gray-500 text-[14px]">
                                              Gynecology
                                            </div>
                                            <div className="text-[13px]">
                                              {CurrentHrPlan?.gynecology}
                                            </div>
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="text-gray-500 text-[14px]">
                                              Optical care
                                            </div>
                                            <div className="text-[13px]">
                                              {CurrentHrPlan?.optical_care}
                                            </div>
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="text-gray-500 text-[14px]">
                                              Surgery Coverage
                                            </div>
                                            <div className="text-[13px]">
                                              {formatNumber(
                                                CurrentHrPlan?.surgery_coverage,
                                                2
                                              )}
                                            </div>
                                          </div>
                                          <div className="flex flex-col">
                                            <div className="text-gray-500 text-[14px]">
                                              Ward Type
                                            </div>
                                            <div className="text-[13px]">
                                              {CurrentHrPlan?.ward_type}
                                            </div>
                                          </div>
                                          <div className="flex flex-col justify-center">
                                            <div className="text-gray-500 text-[14px] hover:underline hover:italic">
                                              <a
                                                href={
                                                  CurrentHrPlan?.benefit_detail_link
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View Full Benefits
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {completePlansLoading && planId ? (
                                          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                                            <LoadingBar
                                              loading={plansLoading}
                                            />
                                          </div>
                                        ) : (
                                          <div className="flex p-2 justify-center items-center w-full">
                                            No HMO Plan selected
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : null}
                              <div className="flex flex-col md:flex-row w-full justify-between gap-2 md:gap-4">
                                <div className="md:w-1/2 w-full md:pr-2">
                                  <FormInput
                                    label={
                                      canSelectHMO
                                        ? "HMO Budget (Monthly) - Editable"
                                        : "HMO Plan Price - (ReadOnly)"
                                    }
                                    placeholder={"Enter budget"}
                                    name={"hmo_budget"}
                                    type="number"
                                    onInput={(e) =>
                                      setHmoBudget(e.target.value)
                                    }
                                    readOnly={canSelectHMO ? false : true}
                                    value={hmoBudget}
                                  />
                                </div>
                                <div className="md:w-1/2 w-full md:pl-2">
                                  <FormInput
                                    label={
                                      canSelectHMO
                                        ? "Subscription Month - (Editable)"
                                        : "Subscription Month - (ReadOnly)"
                                    }
                                    name={"max_subscription_months"}
                                    type="number"
                                    onInput={(e) =>
                                      setMaxSubscriptionMonth(e.target.value)
                                    }
                                    readOnly={canSelectHMO ? false : true}
                                    value={maxSubscriptionMonth}
                                  />
                                </div>
                              </div>
                            </div>
                            <hr className="divider" />
                            <div className={"flex w-full flex-col"}>
                              <div className="flex flex-row md:flex-col w-full justify-between gap-4 items-center">
                                <div className="w-full md:w-1/2 !text-[13px] mt-3">
                                  <FormSwitch
                                    label={"Can employees add Dependants?"}
                                    name={"can_add_dependants"}
                                    readOnly={editGrouploading}
                                    checked={canAddDependants}
                                    onClick={() =>
                                      setCanAddDependants(!canAddDependants)
                                    }
                                  />
                                </div>
                                <div className="w-full md:w-1/2 text-[13px]">
                                  <FormInput
                                    label="How many dependants?"
                                    placeholder={"Max. Dependants"}
                                    name="max_dependants"
                                    className={"max-h-[30px] max-w-[100%]"}
                                    type="number"
                                    value={maxDependants}
                                    onInput={(e) =>
                                      setMaxDependants(e.target.value)
                                    }
                                    readOnly={editGrouploading}
                                  />
                                </div>
                              </div>
                              <div className="mt-2 md:w-[50%] w-full">
                                <FormSwitch
                                  label={"Auto Renew HMO"}
                                  name={"auto_renew"}
                                  readOnly={editGrouploading}
                                  checked={autoRenew}
                                  onClick={() => setAutoRenew(!autoRenew)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <hr className="divider mt-2" />
                        )}

                        <div className="flex justify-end w-full">
                          <Button
                            text={"Update"}
                            type="submit"
                            className="flex gap-2 !h-[35px] w-fit p-2 "
                            textClass={"!text-[11px]"}
                            loading={editGrouploading}
                            onClick={() => {
                              submitForm();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HMOGroupSettings;
