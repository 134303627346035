import React from "react";
import DataTable from "../../../datatable";
import { useState, useEffect } from "react";
import {
  PAGINATION_DEFAULT,
  purchaseOrderStatusConfig,
} from "../../../../utils/constants";
import Button from "../../../button";
import { useHistory } from "react-router-dom";
import {
  useDeletePurchaseOrder,
  useGetPurchaseOrdersByVendor,
} from "../../../../redux/spend-management/purchase-order/hook";
import { useParams } from "react-router-dom";

export const VendorPurchaseOrderTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });
  const history = useHistory();

  const { id } = useParams();

  const { data: purchase_orders } = useGetPurchaseOrdersByVendor(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: deletePurchaseOrder } = useDeletePurchaseOrder();

  const tableConfig = {
    headers: [
      "P.O. Date",
      "P.O. #",
      "Vendor Name",
      "Mobile",
      "Due Date",
      "Total Amount",
      "Status",
      "Due Status",
    ],
    keys: [
      "purchase_order_date",
      "purchase_order_number",
      "vendor_name",
      "vendor_mobile",
      "purchase_order_due_date",
      "total_amount",
      "status",
      "due_status",
    ],
    mobileHeader: {
      left: {
        title: "Vendor Name",
        key: "vendor_name",
      },
      right: {
        title: "Total Amount",
        key: "total_amount",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "orange",
      action: (id) => {
        let index = purchase_orders.data.findIndex((item) => item.id === id);
        history.push(
          `/vendors/purchase-order?purchaseOrderId=${purchase_orders.data[index].id}`
        );
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = purchase_orders.data.findIndex((item) => item.id === id);
        deletePurchaseOrder(purchase_orders.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Invoices
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Purchase Order"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                history.push(`/vendors/purchase-order`);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-2" />
      <DataTable
        data={purchase_orders}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        statusConfig={purchaseOrderStatusConfig}
        updatePagination={(data) => setPagination(data)}
        noCheck={true}
        computedActions={true}
      />
    </div>
  );
};
