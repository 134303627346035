import { Axios } from '../axios';

const getAdvancedPayrolls = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/advanced-payrolls?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );
  return data?.data;
};

const getBranchAdvancedPayrolls = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/advanced-payrolls/by-branch/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );
  
  return data?.data;
};

const getBasicPayrolls = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get( `/basic-payrolls?limit=${payload.limit}&page=${payload.page}${filter}${search}`, );

  return data?.data;
};

const fetchBasicPayrollByRef = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(`/basic-payrolls/by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  {
    timeout: 0
  });
  if (data?.data) {
    data.data.salaries.total_net_pay = data?.data.total_amount
    data.data.salaries.total_payable = data?.data.total_payable
    data.data.salaries.total_trans_cost = data?.data.total_fees
  }
  return data?.data.salaries;
};

const fetchAdvancedPayrollByRef = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(`/advanced-payrolls/by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  {
    timeout: 0
  });
  if (data?.data) {
    data.data.salaries.total_net_pay = parseFloat(data?.data.total_amount)
    data.data.salaries.total_payable = parseFloat(data?.data.total_payable)
    data.data.salaries.total_trans_cost = parseFloat(data?.data.total_fees)
    data.data.salaries.source = data?.data.source
    data.data.salaries.source_id = data?.data.source_id

    data.data.salaries.total_cost_net_pay = parseFloat(data?.data.total_cost_net_pay)
    data.data.salaries.total_cost_pension = parseFloat(data?.data.total_cost_pension)
    data.data.salaries.total_cost_employer_pension = parseFloat(data?.data.total_cost_employer_pension)
    data.data.salaries.total_cost_nhf = parseFloat(data?.data.total_cost_nhf)
    data.data.salaries.total_cost_nhis = parseFloat(data?.data.total_cost_nhis)
    data.data.salaries.total_cost_itf = parseFloat(data?.data.total_cost_itf)
    data.data.salaries.total_cost_nsitf = parseFloat(data?.data.total_cost_nsitf)
    data.data.salaries.total_cost_paye_taxes = parseFloat(data?.data.total_cost_paye_taxes)
    data.data.salaries.total_cost_wht_taxes = parseFloat(data?.data.total_cost_wht_taxes)
    data.data.salaries.total_cost_total_payable = parseFloat(data?.data.total_cost_total_payable)
    data.data.salaries.total_cost_itf_nsitf = parseFloat(data?.data.total_cost_itf_nsitf)
  }
  return data?.data?.salaries;
};

const fetchAdvancedPayrollById = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(`/advanced-payrolls/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  {
    timeout: 0
  });
  if (data.data) {
    data.data.salaries.total_net_pay = data?.data.total_amount
    data.data.salaries.total_payable = data?.data.total_payable
    data.data.salaries.total_trans_cost = data?.data.total_fees
  }
  return data?.data.salaries;
};

const runBasicPayroll = async (payload) => {
  const { data } = await Axios.post('/basic-payroll/run', payload,
  {
    timeout: 0
  });
  return data;
};

const sendBulkPayslip = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/send-payslip', payload,
  {
    timeout: 0
  });
  return data;
};

const addPayrollAdjustments = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/add-payroll-adjustments', payload,
  {
    timeout: 0
  });
  return data;
};

const sendSinglePayslip = async (payload) => {
  const { data } = await Axios.post('advanced-payroll/send-payslip/single', payload,
  {
    timeout: 0
  });
  return data;
};

const sendSelectPayslip = async (payload) => {
  const { data } = await Axios.post('advanced-payroll/send-payslip/select', payload,
  {
    timeout: 0
  });
  return data;
};


const runBasicPayrollGroup = async (payload) => {
  const { data } = await Axios.post('/basic-payroll/run/group', payload,
  {
    timeout: 0
  });
  return data;
};

const runBasicPayrollSelective = async (payload) => {
  const { data } = await Axios.post('/basic-payroll/run/selective', payload,
  {
    timeout: 0
  });
  return data;
};

const getPayrollHistory = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';

  const { data } = await Axios.get(
    `transactions/history?limit=${payload.limit}&page=${payload.page}${search}${filter}${start_date}${end_date}`,
  );

  return data?.data;
};

const runBasicPayrollExcel = async (payload) => {
  const { data } = await Axios.post('/basic-payroll/run/excel', payload,
  {
    timeout: 0
  });
  return data;
};

const advancedPayrollCheck = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/check', payload,
  {
    timeout: 0
  });
  return data?.data;
};


const advancedPayrollCheckGroup = async (payload) => {
  const { data } = await Axios.post(`/advanced-payroll/check/group/${payload.id}`, payload,
  {
    timeout: 0
  });
  return data?.data;
};

const advancedPayrollCheckBranch = async (payload) => {
  const { data } = await Axios.post(`/advanced-payroll/check/branch/${payload.id}`, payload,
  {
    timeout: 0
  });
  return data?.data;
};

const advancedPayrollCheckSelective = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/selective', payload,
  {
    timeout: 0
  });
  return data?.data;
};

const runAdvancedPayroll = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/run', payload,
  {
    timeout: 0
  });
  return data;
};

const runAdvancedPayrollGroup = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/run/group', payload,
  {
    timeout: 0
  });
  return data;
};

const runAdvancedPayrollBranch = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/run/branch', payload,
  {
    timeout: 0
  });
  return data;
};

const runAdvancedPayrollSelective = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/run/selective', payload,
  {
    timeout: 0
  });
  return data;
};

const disburseBasicPayroll = async (payload) => {
  const { data } = await Axios.post('/basic-payroll/disburse', payload,
  {
    timeout: 0
  });
  return data;
};

const disburseAdvancedPayroll = async (payload) => {
  const { data } = await Axios.post('/advanced-payroll/disburse', payload,
  {
    timeout: 0
  });
  return data;
};

const initiateAdvancedPayroll = async (payload) => {
  const { data } = await Axios.post(`/advanced-payroll/initiate-payment`, payload,
  {
    timeout: 0
  });
  return data;
};

const initiateBasicPayroll = async (payload) => {
  const { data } = await Axios.post(`/basic-payroll/initiate-payment`, payload,
  {
    timeout: 0
  });
  return data;
};

const declineBasicPayroll = async (payload) => {
  const { data } = await Axios.get(`/basic-payroll/decline-payment/${payload}`);
  return data;
};

const declineAdvancedPayroll = async (payload) => {
  const { data } = await Axios.get(`/advanced-payroll/decline-payment/${payload}`, );
  return data;
};

const checkTransactionStatus = async (payload) => {
  const { data } = await Axios.get(`/transactions/status/${payload}`);
  return data?.data;
};

const retryTransaction = async (payload) => {
  const { data } = await Axios.get(`/transactions/retry/${payload}`);
  return data?.data;
};

const refreshTransactionStatus = async () => {
  const { data } = await Axios.get('/transactions/bulk/status',
  {
    timeout: 0
  });
  return data;
};

const recalculateAdvancedPayroll = async (payload) => {
  const { data } = await Axios.get(`/advanced-payroll/${payload}/recalculate`,
  {
    timeout: 0
  });
  return data;
};

const singleRecalculateAdvancedPayroll = async (payload) => {
  const { data } = await Axios.get(`/advanced-payroll/single-recalculate/${payload.ref}/${payload.id}'`,
  {
    timeout: 0
  });
  return data;
};


const removeBasicPayrollById = async (payload) => {
  const { data } = await Axios.get(`/basic-payroll/remove-salary/${payload}`);
  return data;
};

const removeAdvancedPayrollById = async (payload) => {
  const { data } = await Axios.get(`/advanced-payroll/remove-salary/${payload}`);
  return data;
};

const editBasicPayrollById = async (payload) => {
  const { data } = await Axios.put(`/basic-payroll/edit-salary/${payload.id}`, payload.data);
  return data;
};

const deleteAdvancedPayroll = async (payload) => {
  const { data } = await Axios.delete(`/advanced-payroll/${payload}`);
  return data;
};

const deleteBasicPayroll = async (payload) => {
  const { data } = await Axios.delete(`/basic-payroll/${payload}`);
  return data;
};

const exportAnnualReport = async (payload) => {
  const { data } = await Axios.get(`/advanced-payroll/annual-report/${payload}`);
  return data;
};

export const payroll = {
  getAdvancedPayrolls,
  getBranchAdvancedPayrolls,
  getBasicPayrolls,
  runBasicPayrollGroup,
  runBasicPayrollSelective,
  fetchBasicPayrollByRef,
  getPayrollHistory,
  fetchAdvancedPayrollByRef,
  fetchAdvancedPayrollById,
  runBasicPayroll,
  runBasicPayrollExcel,
  advancedPayrollCheck,
  advancedPayrollCheckGroup,
  advancedPayrollCheckSelective,
  runAdvancedPayroll,
  runAdvancedPayrollGroup,
  runAdvancedPayrollBranch,
  runAdvancedPayrollSelective,
  disburseBasicPayroll,
  disburseAdvancedPayroll,
  checkTransactionStatus,
  retryTransaction,
  recalculateAdvancedPayroll,
  singleRecalculateAdvancedPayroll,
  removeBasicPayrollById,
  removeAdvancedPayrollById,
  editBasicPayrollById,
  refreshTransactionStatus,
  initiateAdvancedPayroll,
  initiateBasicPayroll,
  sendSinglePayslip,
  sendBulkPayslip,
  sendSelectPayslip,
  declineBasicPayroll,
  declineAdvancedPayroll,
  advancedPayrollCheckBranch,
  deleteAdvancedPayroll,
  deleteBasicPayroll,

  addPayrollAdjustments,
  exportAnnualReport
};
