import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../components/layouts/dashboard";
import TabView from "../../../../components/tabview";
import { useHistory } from "react-router-dom";
import { CardHr } from "../../../../components/modal/hr-section/dashboard/cardHR";
import ResignationSVG from "../../../../assets/svg/resignation.svg";
import TerminationSVG from "../../../../assets/svg/termination.svg";
import WorkflowSVG from "../../../../assets/svg/workflow-widget-icon.svg";
import QuestionsSVG from "../../../../assets/svg/question.svg";
import ExitWorkflowTab from "../../../../components/employeee-exit/exit-workflows";
import ResignationsTab from "../../../../components/employeee-exit/resignations";
import TerminationsTab from "../../../../components/employeee-exit/terminations";
import ExitInterviewQuestionsTab from "../../../../components/employeee-exit/exit_interview_questions";
import { useGetExitSummary } from "../../../../redux/employees/hook/exit";
import UnauthorizedPage from "../../../errors/unauthorized";
import { useSelector } from "react-redux";

const EmployeeExitPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.exit_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);
  const { data: getExitSummary } = useGetExitSummary();
  const [exitSummary, setExitSummary] = useState([]);

  useEffect(() => {
    if (getExitSummary) {
      setExitSummary([
        {
          title: "TOTAL RESIGNATION",
          value: getExitSummary.resignations,
          icon: ResignationSVG,
        },
        {
          title: "TOTAL TERMINATION",
          value: getExitSummary.terminations,
          icon: TerminationSVG,
        },
        {
          title: "TOTAL EXIT WORKFLOWS",
          value: getExitSummary.workflows,
          icon: WorkflowSVG,
        },
        {
          title: "TOTAL EXIT QUESTIONS",
          value: getExitSummary.questions,
          icon: QuestionsSVG,
        },
      ]);
    } else {
      setExitSummary([
        {
          title: "TOTAL RESIGNATION",
          value: 0,
          icon: ResignationSVG,
        },
        {
          title: "TOTAL TERMINATION",
          value: 0,
          icon: TerminationSVG,
        },
        {
          title: "TOTAL EXIT WORKFLOWS",
          value: 0,
          icon: WorkflowSVG,
        },
        {
          title: "TOTAL EXIT QUESTIONS",
          value: 0,
          icon: QuestionsSVG,
        },
      ]);
    }
  }, [getExitSummary]);

  const exitTabs = [
    {
      title: "Resignations",
      Content:
        user?.permissions.exit_permission.resignation_read === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? ResignationsTab
          : UnauthorizedPage,
    },
    {
      title: "Terminations",
      Content:
        user?.permissions.exit_permission.termination_read === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? TerminationsTab
          : UnauthorizedPage,
    },
    {
      title: "Exit Workflows",
      Content:
        user?.permissions.exit_permission.workflow_read === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? ExitWorkflowTab
          : UnauthorizedPage,
    },
    {
      title: "Exit Interview Questions",
      Content:
        user?.permissions.exit_permission.question_read === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? ExitInterviewQuestionsTab
          : UnauthorizedPage,
    },
  ];

  return (
    <DashboardLayout title="Employee Exit">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row justify-between items-center -mt-4">
              <div className="flex flex-col md:flex-row justify-between items-center !w-[100%]">
                <div className="h-fit p-3 flex flex-col justify-between w-full">
                  <div className="grid md:grid-cols-4 lg:grid-cols-4 grid-cols-2 gap-2">
                    {exitSummary?.map((item) => (
                      <CardHr
                        title={item.title}
                        subtitle={item.value}
                        icon={item.icon}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <TabView
                Tabs={exitTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default EmployeeExitPage;
