import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import {
  useAddEmployeeTrainer,
  useUpdateEmployeeTrainer,
} from '../../../../redux/employees/hook/trainers';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import Modal from '../../modal';

function AddTrainer({
  isOpen,
  closeModal,
  refresh,
  trainerData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [trainerId, setTrainerId] = useState(null);
  const [description, setDescription] = useState('');
  const [trainerName, setTrainerName] = useState('');
  const [trainerChangeType, setTrainerChangeType] = useState('add');
  const [trainerType, setTrainerType] = useState('internal');
  const [trainingType, setTrainingType] = useState();
  const [trainerAddress, setTrainerAddress] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();

  useEffect(() => {
    if (trainerData) {
      setTrainerName(trainerData.trainer_name);
      setTrainerType(trainerData.trainer_type);
      setTrainingType(trainerData.training_type);
      setDescription(trainerData.training_description);
      setTrainerAddress(trainerData.trainer_address);
      setEmail(trainerData.email);
      setMobile(trainerData.mobile);
      setTrainerId(trainerData.id);
      setTrainerChangeType('edit');
    } else {
      setTrainerChangeType('add');
    }
  }, [trainerData]);

  const { mutateAsync: addTrainer, isLoading: addTrainerloading } =
    useAddEmployeeTrainer();

  const { mutateAsync: editTrainer, isLoading: editTrainerloading } =
    useUpdateEmployeeTrainer();

  const trainerTypeOptions = [
    { value: 'internal', label: 'Internal' },
    { value: 'external', label: 'External' },
  ];

  const submitForm = async (data) => {
    let payload = {
      trainer_name: trainerName,
      trainer_type: trainerType,
      training_type: trainingType,
      training_description: description,
      trainer_address: trainerAddress,
      email: email,
      mobile: mobile,
    };

    if (trainerChangeType === 'add') {
      addTrainer(payload).then(() => {
        closeModal();
      });
    } else {
      let editPayload = {
        id: trainerId,
        data: payload,
      };
      editTrainer(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-row md:flex-col w-full justify-between'>
              <div className='w-full md:pr-2 md:w-1/2'>
                <FormInput
                  label='Trainer Name'
                  name='trainer_name'
                  type='text'
                  placeholder={'Enter trainer name'}
                  value={trainerName}
                  onInput={(e) => {
                    setTrainerName(e.target.value);
                  }}
                  error={errors.trainer_name}
                  errorMessage={
                    errors.trainer_name && errors.trainer_name.message
                  }
                />
              </div>
              <div className='w-full md:pl-2 md:w-1/2'>
                <FormSelect
                  value={trainerType}
                  options={trainerTypeOptions}
                  onChange={(selected) => {
                    setTrainerType(selected);
                  }}
                  label='Select Trainer Type'
                />
              </div>
            </div>
            <div className='flex flex-row md:flex-col w-full justify-between'>
              <div className='w-full md:pr-2 md:w-2/3'>
                <FormInput
                  label='Email'
                  name='email'
                  type='email'
                  placeholder={'Enter trainer email'}
                  value={email}
                  onInput={(e) => {
                    setEmail(e.target.value);
                  }}
                  error={errors.email}
                  errorMessage={errors.email && errors.email.message}
                />
              </div>
              <div className='w-full md:pl-2 md:w-1/3'>
                <FormInput
                  label='Mobile'
                  name='mobile'
                  type='text'
                  placeholder={'Enter trainer mobile'}
                  value={mobile}
                  onInput={(e) => {
                    setMobile(e.target.value);
                  }}
                  error={errors.mobile}
                  errorMessage={errors.mobile && errors.mobile.message}
                />
              </div>
            </div>
            <FormInput
              label='Training Offered'
              name='training_name'
              type='text'
              placeholder={'Enter training offered'}
              value={trainingType}
              onInput={(e) => {
                setTrainingType(e.target.value);
              }}
              error={errors.training_name}
              errorMessage={
                errors.training_name && errors.training_name.message
              }
            />
            <FormInput
              label='Description'
              name='description'
              type='text'
              placeholder={'Enter trainer description'}
              value={description}
              onInput={(e) => {
                setDescription(e.target.value);
              }}
              error={errors.description}
              errorMessage={errors.description && errors.description.message}
            />

            <FormInput
              label='Trainer Address'
              name='trainer_address'
              type='text'
              placeholder={'Enter trainer address'}
              value={trainerAddress}
              onInput={(e) => {
                setTrainerAddress(e.target.value);
              }}
              error={errors.trainer_address}
              errorMessage={
                errors.trainer_address && errors.trainer_address.message
              }
            />
            <div className='w-full mt-[20px] pb-4 '>
              <Button
                text={buttonText}
                type='submit'
                loading={addTrainerloading || editTrainerloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddTrainer;
