import React from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import Modal from '../modal';
import OnboardingDataTable from '../../onboard-datatable';

function AdvancedPayrollPayments({ isOpen, closeModal, viewData }) {

  const tableConfig = {
    //title: 'Basic Payroll Summary',
    headers: ['Date Added', 'Employee Name', 'Mobile Number', 'Effective From', 'Status'],
    keys: ['created', 'employeeName', 'mobile', 'review_start_date', 'status'],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Effective From',
        key: 'review_start_date',
      },
    },
  };
  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        let index = reviews.data.findIndex((item) => item.id === id);
        setSalaryReviewData(reviews.data[index]);
        setShowSingleReview(true);
      },
    },
    {
      name: 'Delete',
      color: 'red',
      action: (id) => {
        let index = reviews.data.findIndex((item) => item.id === id);
        deleteReview(reviews.data[index].id);
      },
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='md:min-w-[80%] min-w-[90%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col !min-h-fit overflow-auto'>
        <div className='pt-4 px-8'>
          <div className='text-center flex flex-col'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-3'>Salary Reviews</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
        </div>
        <hr className='divider' />
        <OnboardingDataTable
            data={viewData?.payments}
            tableConfig={tableConfig}
            noCheck={true}
        />
      </div>
    </Modal>
  );
}

export default AdvancedPayrollPayments;
