import { useMutation } from '@tanstack/react-query';
import { mobile } from '../../api/mobile';
import { store } from '../index';
import { setAlert } from '../components/components-slice';

export function useJoinWaitlist() {
  return useMutation(
    (payload) => {
      return mobile.joinWaitList(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useMobileVerifyEmail() {
  return useMutation(
    (payload) => {
      return mobile.verifyEmail(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Email verified successfully'));
      },
    },
  );
}
