import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import VideoRecorder from "react-video-recorder";
import Button from "../../../components/button";
import {
  useGetVideoRequest,
  useUploadVideo,
} from "../../../redux/human-resources/hook/recruitment";
import FormInput from "../../../components/form-input";
import LoadingBar from "../../../components/loader-bar";
import { Initials } from "../../../utils/functions";

const MIME_TYPES = [
  'video/webm;codecs="vp8,opus"',
  "video/webm;codecs=h264",
  "video/webm;codecs=vp9",
  "video/webm",
  "video/mp4",
];

const ApplicantVideoPage = () => {
  useEffect(() => {}, []);

  const { token } = useParams();

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [applicantMobile, setApplicantMobile] = useState(null);
  const [recording, setRecording] = useState(false);

  const [videoFile, setVideoFile] = useState(null);
  const { data: videoRequest, isLoading: videoRequestLoading } =
    useGetVideoRequest(token, applicantMobile);

  const startRecording = async () => {
    setApplicantMobile(mobile);
    setRecording(true);
  };

  const { mutateAsync: uploadVideo, isLoading: uploadVideoLoading } =
    useUploadVideo();

  const submitVideo = async () => {
    const videoBlob = await fetch(videoFile).then((r) => r.blob());
    const newVideoFile = new File([videoBlob], "video.mp4", {
      type: "video/mp4",
    });
    const formData = new FormData(); // preparing to send to the server

    formData.append("file", newVideoFile); // preparing to send to the server
    formData.append("token", token);
    formData.append("mobile", applicantMobile);
    await uploadVideo(formData).then(() => {
      window.location.assign("https://www.employees.ropay.africa");
    });
  };

  return (
    <div className="flex md:flex-row flex-col w-full">
      {recording ? (
        <>
          <div className="flex flex-col gap-2 md:w-2/5 w-full bg-secondary-2-extralight border-r p-4 md:min-h-screen md:max-h-screen h-fit overflow-y-auto">
            {videoRequestLoading ? (
              <LoadingBar loading={videoRequestLoading} />
            ) : videoRequest ? (
              <>
                <div className="flex flex-row items-center gap-2">
                  <div className="text-[25px] flex justify-center items-center h-[60px] w-[90px]  header-3 relative duration-500 text-white font-bold">
                    {videoRequest?.logo ? (
                      <div>
                        <img
                          src={videoRequest?.logo}
                          alt="email_icon"
                          className="object-fit min-w-[90px] min-h-[60px] max-w-[90px] max-h-[60px] "
                        />
                      </div>
                    ) : (
                      <span className="text-white font-bold text-[30px]">
                        {Initials(videoRequest?.company_name)}
                      </span>
                    )}
                  </div>
                  <button className="hidden lg:flex text-secondary-2 header-3 text-[25px]">
                    {videoRequest?.company_name}
                  </button>
                </div>
                <div className="flex flex-col gap-1 p-2 border bg-white rounded-md px-3">
                  <span className="text-[15px] font-semibold">
                    Instructions
                  </span>
                  <div className="text-[13px] font-normal text-justify">
                    You are expected to answer the questions shown below in the
                    stipulated time-frame by recording your self using your
                    webcam. Please ensure that your in a well lit environment
                    when taking this assessment.
                  </div>
                </div>
                <hr className="divider mb-2" />
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-2 border-r-8 border-r-secondary-2 rounded-xl bg-white p-2">
                    <span className="text-[14px] font-semibold">
                      Video requirement{" "}
                    </span>
                    <td
                      className="text-[13px] font-normal"
                      dangerouslySetInnerHTML={{
                        __html: videoRequest?.question,
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col w-full items-center md:justify-center justify-start h-[300px] md:h-screen px-4 pt-3 gap-3">
                <div className="flex justify-center md:md:w-[65%] w-full h-fit rounded-md border-2 p-2 border-secondary-2 text-[15px] font-extrabold hover:scale-110 duration-300 text-secondary-2">
                  Not Verified
                </div>
                <Button
                  text="RE-VERIFY"
                  type="button"
                  theme="secondary"
                  className="flex gap-2 !h-[35px] w-fit px-2"
                  textClass={"!text-[14px]"}
                  onClick={() => {
                    setRecording(false);
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col md:w-3/5 w-full items-center md:justify-center justify-start h-screen px-4 pt-3">
            {videoRequestLoading ? (
              <LoadingBar loading={videoRequestLoading} />
            ) : videoRequest ? (
              <>
                <div className="mb-3 text-[25px] font-extrabold">
                  Record Answers
                </div>
                <div className="flex justify-center md:md:w-[65%] w-full h-[500px] rounded-md border-2 p-2 border-secondary-2">
                  <VideoRecorder
                    countdownTime={5000}
                    showReplayControls
                    onRecordingComplete={(videoBlob) => {
                      setVideoFile(window.URL.createObjectURL(videoBlob));
                    }}
                    timeLimit={videoRequest?.duration == 1 ? 60000 : 180000}
                  />
                </div>
                <div className="md:w-fit mt-3" id="second-step">
                  <Button
                    text="SUBMIT VIDEO"
                    type="button"
                    theme="primary"
                    disabled={!videoFile}
                    loading={uploadVideoLoading}
                    className="flex gap-2 !h-[35px] w-fit p-2 "
                    textClass={"!text-[11px]"}
                    onClick={() => {
                      submitVideo();
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="flex flex-col md:w-3/5 w-full items-center md:justify-center justify-start h-[300px] md:h-screen px-4 pt-3">
                {/* <div className='flex justify-center md:md:w-[65%] w-full h-fit rounded-md border-2 p-2 border-secondary-2 text-[15px] font-extrabold hover:scale-110 duration-300 text-secondary-2'>
                            Not Verified
                        </div> */}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-2 md:w-2/5 w-full bg-secondary-2-extralight border-r p-4 md:min-h-screen md:max-h-screen h-fit overflow-y-auto">
            <div className="text-[25px] font-extrabold">
              Provide Your Information
            </div>
            <hr className="divider mb-2 -mt-0" />
            <div className="flex flex-col gap-3">
              <form className="form ">
                <div className="p-2 rounded-md border bg-secondary-2-extralight">
                  <div className="flex flex-col justify-between">
                    <div className="w-full">
                      <FormInput
                        label="First name"
                        name="first_name"
                        placeholder={"Enter your first name"}
                        type="text"
                        value={firstName}
                        onInput={(e) => {
                          setFirstName(e.target.value);
                        }}
                      />
                    </div>

                    <div className="w-full">
                      <FormInput
                        label="Last name"
                        name="last_name"
                        placeholder={"Enter your last name"}
                        type="text"
                        value={lastName}
                        onInput={(e) => {
                          setLastName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="w-full">
                      <FormInput
                        label="Phone Number"
                        name="mobile"
                        type="text"
                        value={mobile}
                        onInput={(e) => {
                          setMobile(e.target.value);
                        }}
                        placeholder={"Enter your mobile number"}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end w-full mt-4">
                  <Button
                    text="VERIFY AND CONTINUE"
                    type="button"
                    onClick={() => {
                      startRecording();
                    }}
                    disabled={!firstName || !lastName || !mobile}
                    className="flex gap-2 !h-[35px] w-fit p-2"
                    textClass={"!text-[13px]"}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="flex flex-col md:w-3/5 w-full items-center justify-center h-[300px] md:h-screen px-4 pt-3">
            <div className="flex justify-center md:md:w-[65%] w-full h-fit rounded-md border-2 p-2 border-secondary-2 text-[18px] font-extrabold hover:scale-110 duration-300 text-secondary-2">
              Awaiting Verification
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ApplicantVideoPage;
