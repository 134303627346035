import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import TabView from "../../../components/tabview";
import { StatusText } from "../../../components/fragments/status-text";
import { customerStatusConfig } from "../../../utils/constants";
import UnauthorizedPage from "../../errors/unauthorized";
import { Initials } from "../../../utils/functions";
import { useSelector } from "react-redux";
import { useGetCustomer } from "../../../redux/spend-management/customer/hook";
import { CustomerInvoicesTab } from "../../../components/spend-management-section/customers/view-customer/customer-invoices-tab";
import CustomerProfileTab from "../../../components/spend-management-section/customers/view-customer/customer-profile-tab";

const ViewCustomerPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.customer_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const { data: customer } = useGetCustomer(id);

  //Employee View Tabs
  const customerTabs = [
    {
      title: "Customer information",
      Content:
        user.permissions.customer_permission.customer_read === 1 ||
        user.permissions.company_permission.role === "Owner"
          ? CustomerProfileTab
          : UnauthorizedPage,
    },
    {
      title: "Invoices",
      Content:
        user.permissions.customer_permission.invoice_read === 1 ||
        user.permissions.company_permission.role === "Owner"
          ? CustomerInvoicesTab
          : UnauthorizedPage,
    },
    {
      title: "Recurring Invoices",
      Content:
        user.permissions.customer_permission.invoice_read === 1 ||
        user.permissions.company_permission.role === "Owner"
          ? CustomerInvoicesTab
          : UnauthorizedPage,
    },
    // {
    //   title: 'Payment History',
    //   Content:
    //     user.permissions.employee_permission.salary_read === 1 ||
    //     user.permissions.company_permission.role === 'Owner'
    //       ? customerTransactionHistoryTab
    //       : UnauthorizedPage,
    // },
  ];

  return (
    <DashboardLayout title="View Customer">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex flex-row items-center box-shadow h-[104px]">
              <div className="flex flex-row px-4 justify-between  w-full">
                <div className="flex flex-row items-center justify-between w-full">
                  <div className="flex flex-row items-center">
                    <div className="text-[18px] flex font-bold justify-center gap-4 items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200 relative hover:scale-150 hover:m-2 duration-500">
                      {Initials(customer?.customer_name)}
                    </div>
                    <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                      <span className="p1-bold">
                        {customer?.customer_name}{" "}
                        <span className="p4 text-color-gray">
                          ({customer?.contact_person})
                        </span>
                      </span>
                      <span className="p4 text-color-gray">
                        {customer?.email}
                      </span>
                      <span className="p4 text-color-gray">
                        {customer?.mobile}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <StatusText
                      statusConfig={customerStatusConfig}
                      code={customer?.status}
                      className={
                        "py-2 rounded p1-bold px-3 p2-bold text-[15px]"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              {customerTabs ? (
                <TabView
                  Tabs={customerTabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default ViewCustomerPage;
