import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import {
  PAGINATION_DEFAULT,
  salaryAdvanceApplicationStatusConfig,
} from '../../../../utils/constants';
import DataTable from '../../../datatable';
import ApproveSalaryAdvance from '../approve-salary-advance';
import {
  useDeclineSalaryAdvanceApplication,
  useSingleGetEmployeeSalaryAdvanceApplications,
} from '../../../../redux/employees/hook/salary-advance';
import { useParams } from 'react-router-dom';
import Modal from '../../modal';

function EmployeeSalaryAdvanceApplications({ isOpen, closeModal }) {
  const { id } = useParams();
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });

  const [salaryAdvanceData, setsalaryAdvanceData] = useState();

  const [showApprove, setShowApprove] = useState(false);

  const [approvalType, setApprovalType] = useState('approve');

  const [applicationPay, setApplicationPay] = useState(false);

  const { data: salary_advance } =
    useSingleGetEmployeeSalaryAdvanceApplications(
      id,
      pagination?.limit,
      pagination?.page,
      pagination?.search,
    );

  const { mutateAsync: declineApplication } =
    useDeclineSalaryAdvanceApplication();

  const tableConfig = {
    headers: [
      'Date',
      'Employee Name',
      'Mobile',
      'Repayment Start',
      'Repayment End',
      'Amount',
      'Status',
    ],
    keys: [
      'created',
      'employeeName',
      'mobile',
      'repayment_start',
      'repayment_end',
      'amount',
      'status',
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Status',
        key: 'status',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Approve',
      color: 'green',
      action: (id) => {
        let index = salary_advance.data.findIndex((item) => item.id === id);
        setsalaryAdvanceData(salary_advance.data[index]);
        setApprovalType('approve');
        setApplicationPay(false);
        setShowApprove(true);
      },
    },
    {
      name: 'Pay Now',
      color: 'green',
      action: (id) => {
        let index = salary_advance.data.findIndex((item) => item.id === id);
        setsalaryAdvanceData(salary_advance.data[index]);
        setApprovalType('pay');
        setApplicationPay(true);
        setShowApprove(true);
      },
    },
    {
      name: 'Decline',
      color: 'red',
      action: (id) => {
        let index = salary_advance.data.findIndex((item) => item.id === id);
        declineApplication(salary_advance.data[index].id);
      },
    },
    {
      name: 'Decline Payment',
      color: 'red',
      action: (id) => {
        let index = salary_advance.data.findIndex((item) => item.id === id);
        declineApplication(salary_advance.data[index].id);
      },
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='!min-h-fit !min-w-[80%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      {showApprove ? (
        <ApproveSalaryAdvance
          isOpen={showApprove}
          closeModal={() => setShowApprove(false)}
          approvalType={approvalType}
          applicationPay={applicationPay}
          salaryAdvance={salaryAdvanceData}
        />
      ) : null}
      <div className='flex flex-col !min-h-fit overflow-auto'>
        <div className='pt-4 pr-8 pl-8'>
          <div className='text-center flex flex-col'>
            <div className='flex flex-row justify-between'>
              <span className='header-3'>Salary Advance Applications</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
        </div>
        <hr className='divider' />
        <DataTable
          data={salary_advance}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={salaryAdvanceApplicationStatusConfig}
          updatePagination={(data) => setPagination(data)}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </Modal>
  );
}

export default EmployeeSalaryAdvanceApplications;
