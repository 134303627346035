import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import Button from "../../button";
import {
  useDeleteTechnicalAppraisal,
  useGetTechnicalAppraisals,
} from "../../../redux/human-resources/hook/performance";
import AddTechnicalAppraisals from "../../modal/hr-section/performance/add-technical-appraisal";
import { useGetEmployees } from "../../../redux/employees/hook";

const PerformanceTechnicalAppraisalsTab = () => {
  useEffect(() => {}, []);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const [technicalAppraisalData, setTechnicalAppraisal] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [showTechnicalAppraisal, setShowTechnicalAppraisal] = useState(null);
  const [type, setType] = useState(null);

  const { mutateAsync: deleteTechnicalAppraisal } =
    useDeleteTechnicalAppraisal();

  const { data: technical_appraisals } = useGetTechnicalAppraisals(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const tableConfig = {
    headers: [
      "Title",
      "Source",
      "Source Name",
      // 'Evaluation Frequency',
      // 'Auto-evaluate'
    ],
    keys: [
      "technical_appraisal_title",
      "source",
      "source_name",
      // 'evaluation_frequency',
      // 'auto_evaluate_converted',
    ],
    mobileHeader: {
      left: {
        title: "Title",
        key: "technical_appraisal_title",
      },
      right: {
        title: "Source Name",
        key: "source_name",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Update",
      color: "green",
      action: (id) => {
        let index = technical_appraisals.data.findIndex(
          (item) => item.id === id
        );
        setTitle("Update Technical Appraisal");
        setButtonText("Update");
        setType("edit");
        setTechnicalAppraisal(technical_appraisals.data[index]);
        setShowTechnicalAppraisal(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = technical_appraisals.data.findIndex(
          (item) => item.id === id
        );
        deleteTechnicalAppraisal(technical_appraisals.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col gap-4 -mt-3">
      {showTechnicalAppraisal ? (
        <AddTechnicalAppraisals
          isOpen={showTechnicalAppraisal}
          closeModal={() => setShowTechnicalAppraisal(false)}
          technicalAppraisalData={technicalAppraisalData}
          type={type}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col">
        <div className=" bg-white border border-gray-300 text-[12px] p-3 rounded text-gray-600">
          <span className="font-bold text-[14px]">
            What are Technical Appraisals?{" "}
          </span>
          They are more specific individual or group appraisals based on
          specific skills required to perform task by a single employee or a
          team. For example, software developers are expected to have some
          specific skills different from accountants.
        </div>
      </div>
      <div className="flex flex-col bg-white rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Technical Appraisals
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Technical Appraisal"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                disabled={!employees}
                onClick={() => {
                  setShowTechnicalAppraisal(true);
                  setTechnicalAppraisal(null);
                  setTitle("Add Technical Appraisal");
                  setButtonText("Save");
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={technical_appraisals}
          tableConfig={tableConfig}
          searchData={true}
          updatePagination={(data) => setPagination(data)}
          actionConfig={actionConfig}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default PerformanceTechnicalAppraisalsTab;
