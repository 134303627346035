
import { store } from '../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../../..';
import { hr_subscription } from '../../../api/hr-subscription';
import { updateUser } from '../../user/hook';
import moment from 'moment';

export function useGetPeoplePlans() {
    return useQuery(
      ['people-plans'],
      () => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.getPeoplePlans();
      },
      {
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useGetSubscriptionHistory(limit, page, search) {
    return useQuery(
      ['subscrption-history', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.getSubscriptionHistory({ limit, page, search });
      },
      {
        select: (data) => {
          data?.data?.forEach((company) => {
            company.created = moment(company.created).format('MMMM D, YYYY');
            company.start_date = moment(company.start_date).format('MMMM D, YYYY');
            company.end_date = moment(company.end_date).format('MMMM D, YYYY');
            company.computedActions = [0]
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useSubscribe() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.subscribe(payload);
      },
      {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('current-people-plan');
          store.dispatch(setAlert(true, 'success', 'HR Subscription successfully'));
          updateUser({ reload: false });
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useGetCurrentPeoplePlan() {
    return useQuery(
      ['current-people-plan'],
      () => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.getCurrentPeoplePlan();
      },
      {
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useCalculateRenewSubscription() {
    return useQuery(
      ['renew-subscription-calculation'],
      () => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.getCalculateRenewSubscription();
      },
      {
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useCalculateUpgradeSubscription() {
  return useQuery(
    ['update-subscription-calculation'],
    () => {
      store.dispatch(setIsLoading(true));
      return hr_subscription.getCalculateUpgradeSubscription();
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCalculateEmployeeSubscription(payload) {
    return useQuery(
      ['employee-subscription-calculation', payload],
      () => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.getCalculateEmployeeSubscription(payload);
      },
      {
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useEmployeeSubscribe() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.addEmployeeSubscription(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('current-people-plan');
          store.dispatch(setAlert(true, 'success', 'Employee subscription successfully'));
          updateUser({ reload: false });
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useRenewSubscription() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.renewSubscription(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('current-people-plan');
          store.dispatch(setAlert(true, 'success', 'Subscription renewed successfully'));
          updateUser({ reload: false });
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useUpgradeSubscription() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return hr_subscription.upgradeSubscription(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('current-people-plan');
        store.dispatch(setAlert(true, 'success', 'Subscription upgraded successfully'));
        updateUser({ reload: false });
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCancelSubscription() {
    return useMutation(
      () => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.cancelSubscription();
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('current-people-plan');
          store.dispatch(setAlert(true, 'success', 'Subscription canceled successfully'));
          updateUser({ reload: false });
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useChangeAutoRenew() {
    return useMutation(
      () => {
        store.dispatch(setIsLoading(true));
        return hr_subscription.changeAutoRenew();
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('current-people-plan');
          store.dispatch(setAlert(true, 'success', 'Subscription auto-renew changed successfully'));
          updateUser({ reload: false });
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}


