import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelectEmployee from '../../../employees/form-select-employee';
import FormSwitch from '../../../form-switch';
import Modal from '../../modal';
import { Editor } from '@tinymce/tinymce-react';
import { useExtendProbation } from '../../../../redux/employees/hook/probation';

function ExtendProbation({ isOpen, closeModal, refresh, probation }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync: extendProbation, isLoading: extendProbationLoading } =
    useExtendProbation();

  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();

  useEffect(() => {
    if (probation) {
      setEmployeeName(probation.first_name + ' ' + probation.last_name);
      setEmployee(probation.employee_id);
    }
  }, [probation]);

  const [newEndDate, setNewEndDate] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [body, setBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');

  const submitForm = async () => {
    let payload = {
      probation_id: probation?.id,
      new_end_date: newEndDate,
      send_email: sendEmail ? 1 : 0,
      email_subject: emailSubject,
      email_body: body.toString(),
    };
    extendProbation(payload).then(() => {
      closeModal();
    });
  };

  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form' onSubmit={handleSubmit(submitForm)}>
            <div className='pl-8 pr-8 pt-4'>
              <div className='flex flex-row justify-between items-center'>
                <span className='header-4'>Extend Probation</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='close' />
                </div>
              </div>
            </div>
            <hr className='divider mb-2' />
            <div className='flex flex-col mx-8'>
              <div className='border bg-highlight p-2 rounded-md'>
                <FormSelectEmployee
                  label={'Employee - (ReadOnly)'}
                  name='employee'
                  placeholder='Type to select Employee'
                  value={employee}
                  readOnly={true}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
              <hr className='divider mt-2 mb-2' />
              <div className={'flex w-full flex-col gap-2 '}>
                <FormInput
                  label='New Probation End Date'
                  name='new_end_date'
                  type='date'
                  value={newEndDate}
                  onInput={(e) => {
                    setNewEndDate(e.target.value);
                  }}
                />
              </div>
              <hr className='divider mt-2 mb-2' />
              <div
                className={
                  'flex w-full flex-col gap-2 px-3 border bg-highlight rounded-md pt-4'
                }
              >
                <div className=''>
                  <FormSwitch
                    label={
                      'Do you want to send probation extension notification email to employee?'
                    }
                    name={'send_email'}
                    checked={sendEmail}
                    onClick={() => setSendEmail(!sendEmail)}
                    value={sendEmail}
                  />
                </div>
                {sendEmail ? (
                  <div className='flex flex-col'>
                    <hr className='divider -mt-2 mb-1' />
                    <FormInput
                      label='Subject'
                      name='subject'
                      type='text'
                      value={emailSubject}
                      placeholder={'Email Subject'}
                      onInput={(e) => {
                        setEmailSubject(e.target.value);
                      }}
                    />
                    <div className='flex md:flex-row flex-col w-full justify-between mb-2'>
                      <div className='w-full'>
                        <span className='text-[13px] text-gray-500'>
                          PIP Email Body
                        </span>
                        <Editor
                          apiKey={import.meta.env.VITE_APP_TINYMCE_API_KEY}
                          onEditorChange={(editor) => {
                            setBody(editor);
                          }}
                          value={body}
                          init={{
                            height: 300,
                            menubar: false,
                            placeholder: 'Enter Email body here',
                            selector: 'textarea',
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | ' +
                              'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help',
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='w-full mt-[10px] pb-4 '>
                <Button
                  text={'save and close'}
                  type='submit'
                  loading={extendProbationLoading}
                  disabled={false}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default ExtendProbation;
