import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import ButtonSelect from "../../../components/button-select";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import { useHistory } from "react-router-dom";
import SalaryReviewLayout from "../../../components/layouts/salary-review";

function SelectReviewOptions() {
  useEffect(() => {}, []);

  const [employeeOption, setEmployeeOptions] = useState("single");
  const history = useHistory();

  const submit = () => {
    if (employeeOption === "single") {
      history.push(
        "/employees/review/review-salary-information?type=single&salary=true"
      );
    } else if (employeeOption === "bulk") {
      history.push(
        "/employees/review/review-salary-information?type=bulk&salary=true"
      );
    }
  };

  return (
    <SalaryReviewLayout step={1} title="Review Employee Salary">
      <div className="flex flex-col justify-center h-full min-h-[480px]">
        <ButtonSelect
          title={"Review Single Salary"}
          subtitle={"Review single Employee Salary"}
          isActive={employeeOption === "single"}
          onClick={() => setEmployeeOptions("single")}
        />
        <ButtonSelect
          title={"Review Multiple employees Salaries"}
          subtitle={"Review multiple employees Salaries using a Excel file"}
          isActive={employeeOption === "bulk"}
          onClick={() => setEmployeeOptions("bulk")}
          className="mt-8"
        />

        <div className="w-full mt-[20px]">
          <Button text="Next" type="submit" onClick={() => submit()} />
        </div>
        <div
          className="flex justify-center w-full cursor-pointer"
          onClick={() => history.push("/employees")}
        >
          <span className="p2-medium text-color-black mt-3">
            Back to Dashboard
          </span>
        </div>
      </div>
    </SalaryReviewLayout>
  );
}

export default SelectReviewOptions;
