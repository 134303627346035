import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormSelectEmployee from '../../employees/form-select-employee';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import FormTextArea from '../../form-textarea';
import Modal from '../modal';
import { useAddOtherDeduction, useEditOtherDeductions } from '../../../redux/employees/hook/other-salary-deductions';
import WarningSVG from '../../../assets/svg/warning.svg';
import moment from 'moment';

function AddOtherDeductionModal({
  isOpen,
  closeModal,
  completed,
  refresh,
  otherData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync: addOtherDeduction, isLoading: addOtherDeductionloading } =
  useAddOtherDeduction();
  const {
    mutateAsync: editOtherDeduction,
    isLoading: editOtherDeductionloading,
  } = useEditOtherDeductions();
  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY-'));
  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [amountEdit, setAmountEdit] = useState();
  const [purposeEdit, setPurposeEdit] = useState();
  const [salaryId, setSalaryId] = useState();
  const [otherDeductionType, setOtherDeductionType] = useState();

  useEffect(() => {
    if (otherData) {
      setMonth(otherData.month.split('-')[1].toString());
      setYear(otherData.month.split('-')[0].toString() + '-');
      setAmountEdit(parseFloat(otherData.amount));
      setPurposeEdit(otherData.purpose);
      setEmployee(otherData.employee);
      setEmployeeName(otherData.first_name + ' ' + otherData.last_name);
      setSalaryId(otherData.id);
      setOtherDeductionType('edit');
    } else {
      setOtherDeductionType('add');
    }
  }, [otherData]);

  const submitForm = async (data) => {
    const amount = DOMPurify.sanitize(data?.amount);
    const purpose = DOMPurify.sanitize(data?.purpose);

    if (otherDeductionType === 'add') {
      let payload = {
        employee,
        amount,
        purpose,
        month: year + month,
      };
      await addOtherDeduction(payload).then(() => {
        completed();
      });
    } else if (otherDeductionType === 'edit') {
      let editPayload = {
        id: salaryId,
        body: {
          employee,
          amount: amountEdit,
          purpose: purposeEdit,
          month: year + month,
          status: 2,
        },
      };
      await editOtherDeduction(editPayload).then(() => {
        completed();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto '>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='flex flex-row justify-between pl-8 pr-8 pt-4 items-center'>
            <span className='header-4'>{title}</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div>
              <div className='border bg-highlight p-2 rounded-md mb-2'>
                <FormSelectEmployee
                  label={'Employee'}
                  name='employee'
                  placeholder='Type to select Employee'
                  value={employee}
                  readOnly={otherDeductionType === 'edit' ? true : false}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormSelect
                    value={month}
                    options={monthOptions}
                    onChange={(selected) => {
                      setMonth(selected);
                    }}
                    label='Select Month'
                  />
                </div>

                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormSelect
                    value={year}
                    options={yearOptions}
                    onChange={(selected) => {
                      setYear(selected);
                    }}
                    label='Select Year'
                  />
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-2/3 md:pr-2'>
                  <FormInput
                    label='Purpose'
                    name='purpose'
                    type='text'
                    placeholder='Enter description'
                    defaultValue={purposeEdit}
                    inputRef={register(formValidation('text', true))}
                    readOnly={addOtherDeductionloading || editOtherDeductionloading}
                    onInput={(e) => {
                      setPurposeEdit(e.target.value);
                    }}
                    error={errors.purpose}
                    errorMessage={errors.purpose && errors.purpose.message}
                  />
                </div>

                <div className='w-full md:w-1/3 md:pl-2'>
                  <FormInput
                    label='Amount'
                    name='amount'
                    type='number'
                    placeholder='Enter Amount'
                    defaultValue={amountEdit}
                    inputRef={register(formValidation('number', true))}
                    readOnly={addOtherDeductionloading || editOtherDeductionloading}
                    onInput={(e) => {
                      setAmountEdit(e.target.value);
                    }}
                    error={errors.amount}
                    errorMessage={errors.amount && errors.amount.message}
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-col justify-center'>
              <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light !text-[14px]'>
                <div className='w-[70px] pr-2 flex flex-row items-center '>
                  <img src={WarningSVG} alt='' srcSet='' />
                </div>
                <div className='text-[12px]'>
                  Please note that the Other deductions with be automatically applied on the payroll for the <span className='font-extrabold'>month</span> and <span className='font-extrabold'>year</span> selected
                </div>
              </div>
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                disabled={
                  otherDeductionType === 'edit'
                    ? false
                    : !isValid || !employee
                    ? true
                    : false
                }
                loading={addOtherDeductionloading || editOtherDeductionloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddOtherDeductionModal;
