import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/button";
import DataTable from "../../components/datatable";
import DashboardLayout from "../../components/layouts/dashboard";
import { useRevertPayroll } from "../../redux/payroll/hook";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import { isLoading } from "../../redux/components/components-slice";
import { useSelector } from "react-redux";
import UnauthorizedPage from "../errors/unauthorized";
import { useRefAirtime } from "../../redux/airtime/hook";

const AirtimeViewPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (
          user.permissions.company_permission.payroll_read !== 1 ||
          user.permissions.payroll_permission?.basic_payroll_write !== 1
        ) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { ref } = useParams();

  const { data: airtime } = useRefAirtime(
    ref,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const tableConfig = {
    headers: ["Name", "Mobile", "Description", "Network", "Amount", "Status"],
    keys: ["name", "mobile", "description", "network", "amount", "status"],
    mobileHeader: {
      left: {
        title: "Name",
        key: "name",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };
  const statusConfig = [
    {
      label: "Pending",
      color: "orange",
      value: 0,
    },
    {
      label: "Successful",
      color: "green",
      value: 1,
    },
    {
      label: "Failed",
      color: "red",
      value: 1,
    },
  ];

  return (
    <DashboardLayout title="Airtime View">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex md:flex-row flex-col justify-between md:items-center items-start">
              <div className="flex flex-col">
                <span className="h4 text-color-black">Airtime Breakdown</span>
                <span className="p3 text-color-gray">
                  View processed airtime information
                </span>
              </div>
              {user?.permissions.payroll_permission?.basic_payroll_write ===
                1 || user?.permissions.company_permission?.role === "Owner" ? (
                <div className="w-full md:w-[220px] mt-3 md:mt-0 h-[56px]">
                  <Button
                    text="Export"
                    type="submit"
                    loading={false}
                    disabled={false}
                    className="h-[56px]"
                    onClick={() => ""}
                  />
                </div>
              ) : null}
            </div>

            <div className="mt-6">
              <DataTable
                data={airtime}
                tableConfig={tableConfig}
                statusConfig={statusConfig}
                noCheck={true}
                updatePagination={(data) => setPagination(data)}
                // filterByStatus={true}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default AirtimeViewPage;
