import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import {
  PAGINATION_DEFAULT,
  reimbursementApplicationStatusConfig,
} from '../../../../utils/constants';
import DataTable from '../../../datatable';
import {
  useDeclineApplicationDisbursement,
  useDeclineReimbursementApplication,
} from '../../../../redux/employees/hook/reimbursements';
import ViewAttachment from '../../hr-section/recruitment/view-attachment';
import ApproveReimbursement from '../approve-reimbursment-application';
import { useParams } from 'react-router-dom';
import { useSingleGetEmployeeReimbursementApplications } from '../../../../redux/employees/hook/reimbursements';
import Modal from '../../modal';

function EmployeeReimbursementApplications({ isOpen, closeModal }) {
  const { id } = useParams();
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const [showAttachment, setshowAttachment] = useState(false);
  const [reimbursementData, setReimbursementData] = useState();

  const [showApprove, setShowApprove] = useState(false);

  const [approvalType, setApprovalType] = useState('approve');

  const [applicationPay, setApplicationPay] = useState(false);

  const { data: reimbursement_applications } =
    useSingleGetEmployeeReimbursementApplications(
      id,
      pagination?.limit,
      pagination?.page,
      pagination?.statusFilter,
      pagination?.search,
    );

  const { mutateAsync: declineApplication } =
    useDeclineReimbursementApplication();
  const { mutateAsync: declineApplicationDisburse } =
    useDeclineApplicationDisbursement();

  const tableConfig = {
    headers: [
      'Date',
      'Employee Name',
      'Mobile',
      'Description',
      'Amount',
      'Status',
    ],
    keys: [
      'created',
      'employeeName',
      'mobile',
      'expenses_description',
      'expenses_amount',
      'status',
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Status',
        key: 'status',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Approve',
      color: 'green',
      action: (id) => {
        let index = reimbursement_applications.data.findIndex(
          (item) => item.id === id,
        );
        setReimbursementData(reimbursement_applications.data[index]);
        setApprovalType('approve');
        setApplicationPay(false);
        setShowApprove(true);
      },
    },
    {
      name: 'Approve Payment',
      color: 'green',
      action: (id) => {
        let index = reimbursement_applications.data.findIndex(
          (item) => item.id === id,
        );
        setReimbursementData(reimbursement_applications.data[index]);
        setApprovalType('pay');
        setApplicationPay(true);
        setShowApprove(true);
      },
    },
    {
      name: 'View Attachment',
      color: 'orange',
      action: (id) => {
        let index = reimbursement_applications.data.findIndex(
          (item) => item.id === id,
        );
        setReimbursementData(reimbursement_applications.data[index]);
        setshowAttachment(true);
      },
    },
    {
      name: 'Decline',
      color: 'red',
      action: (id) => {
        let index = reimbursement_applications.data.findIndex(
          (item) => item.id === id,
        );
        declineApplication(reimbursement_applications.data[index].id);
      },
    },
    {
      name: 'Decline Payment',
      color: 'red',
      action: (id) => {
        let index = reimbursement_applications.data.findIndex(
          (item) => item.id === id,
        );
        declineApplicationDisburse(reimbursement_applications.data[index].id);
      },
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='!min-h-screen !min-w-[80%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      {showApprove ? (
        <ApproveReimbursement
          isOpen={showApprove}
          closeModal={() => setShowApprove(false)}
          approvalType={approvalType}
          applicationPay={applicationPay}
          reimbursement={reimbursementData}
        />
      ) : null}
      {showAttachment ? (
        <ViewAttachment
          isOpen={showAttachment}
          closeModal={() => setshowAttachment(false)}
          title='View Attachment'
          reimbursement={reimbursementData}
        />
      ) : null}
      <div className='flex flex-col !min-h-fit overflow-auto'>
        <div className='pt-4 pr-8 pl-8'>
          <div className='text-center flex flex-col'>
            <div className='flex flex-row justify-between'>
              <span className='header-3'>Reimbursement Applications</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
        </div>
        <hr className='divider' />
        <DataTable
          data={reimbursement_applications}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          // filterByStatus={true}
          statusConfig={reimbursementApplicationStatusConfig}
          updatePagination={(data) => setPagination(data)}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </Modal>
  );
}

export default EmployeeReimbursementApplications;
