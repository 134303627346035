import classNames from "classnames";
import React, { useState } from "react";
import { useGetEmployeeToSelect } from "../../redux/employees/hook";
import CollapsingIcon from "../collapsing-icon";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { employeeStatusConfig } from "../../utils/constants";
import { StatusText } from "../fragments/status-text";

const FormSelectEmployee = ({
  id,
  label,
  className,
  readOnly = false,
  value,
  empName,
  error,
  errorMessage,
  theme,
  setSourceName = false,
  setEmployee,
  inputRef,
  ...rest
}) => {
  useEffect(() => {}, []);
  const { section } = useSelector((state) => state.sectionSlice);

  const [text, setText] = useState("");
  const [search, setSearch] = useState("");
  const [showResults, setShowResult] = useState(false);

  const params = {
    limit: 5000,
    page: 1,
    statusFilter: -1,
  };

  const { data: employees, status } = useGetEmployeeToSelect(
    params.limit,
    params.page,
    params.statusFilter,
    search
  );

  useEffect(() => {
    if (empName) {
      setText(empName);
    }
  }, [empName]);

  useEffect(() => {
    if (status === "success" && search.trim()) {
      setShowResult(true);
    }
  }, [status, search]);

  function handleItemSelect(item) {
    setText(item.first_name + " " + item.last_name + " | " + item.mobile);
    if (setSourceName) {
      setSourceName(
        item.first_name + " " + item.last_name + " | " + item.mobile
      );
    }
    setEmployee(item.id);
    setShowResult(false);
  }

  return (
    <>
      <div className="pb-[20px] flex flex-col !min-h-fit">
        <div className="flex w-full flex-col">
          {label && (
            <label className="text-[13px] leading-[23px] font-normal text-color-gray -mb-2">
              {label}
            </label>
          )}
          <div>
            <div
              className="flex items-center justify-center min-h-fit"
              id="datalist"
            >
              <input
                id="datalist-input"
                type="text"
                autoComplete="off"
                ref={inputRef}
                readOnly={readOnly}
                onChange={(event) => {
                  setText(event.target.value);
                  setSearch(event.target.value);
                }}
                className={classNames("!max-h-[35px] !text-[13px]", {
                  "!bg-gray-100": readOnly,
                })}
                value={text}
                {...rest}
              />
              <i id="datalist-icon" className="icon iconfont">
                <CollapsingIcon />
              </i>
            </div>
            {/* Result List */}
            {employees && employees.data && !readOnly ? (
              <ul
                className={classNames(
                  "datalist-items px-0 mt-2 rounded-sm border border-secondary-2 !z-[10000] bg-white !min-w-full",
                  {
                    hidden: employees.data.length === 0 || !showResults,
                  }
                )}
              >
                {employees.data.map((item, index) => (
                  <li
                    key={index}
                    id={item.id}
                    className={
                      section && section.name === "core hr"
                        ? `flex flex-row gap-2 justify-between py-2 cursor-pointer !min-w-fit text-[14px] hover:bg-hr-primary-1 px-3 rounded-sm hover:text-white`
                        : `flex flex-row gap-2 justify-between py-2 cursor-pointer !min-w-fit text-[14px] hover:bg-secondary-2 px-3 rounded-sm hover:text-white`
                    }
                    onClick={() => handleItemSelect(item)}
                  >
                    <div className="flex flex-row items-center text-inherit">
                      <span className="text-inherit">{item.first_name}</span>
                      <span className="pl-2 text-inherit">
                        {item.last_name}
                      </span>
                    </div>
                    <div className="text-inherit">
                      <span className="text-inherit">{item.mobile}</span>
                    </div>
                    <StatusText
                      code={item.status}
                      statusConfig={employeeStatusConfig}
                    />
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
        {error && <span className="text-error">* {errorMessage}</span>}
      </div>
    </>
  );
};

export default FormSelectEmployee;
