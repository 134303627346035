import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import { useUpdateEmployeeTaxNumber } from '../../../redux/statutory-compliance/hook/tax';
import Button from '../../button';
import FormInput from '../../form-input';
import Modal from '../modal';
import { formatNumber } from '../../../utils/functions';

function TaxDetailedView({ isOpen, closeModal, viewData, from }) {
  const [payloadData, setPayloadData] = useState(null);
  const [taxNumber, setTaxNumber] = useState(null);
  const [id, setId] = useState(null);

  const { mutateAsync: editTaxNumber, isLoading: editTaxLoading } =
    useUpdateEmployeeTaxNumber();

  useEffect(() => {
    if (viewData) {
      setPayloadData(viewData);
      setId(viewData.id);
      setTaxNumber(viewData.employee_tax_number);
    }
  }, [viewData]);

  const submitForm = async () => {
    let payload = {
      id: id,
      body: {
        employee_tax_number: taxNumber,
      },
    };
    await editTaxNumber(payload).then((result) => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <div className='form'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{'Edit Tax Information'}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col md:flex-col w-full justify-between'>
              {payloadData ? (
                <>
                  <div className='flex flex-col gap-2 border rounded-md bg-secondary-2-extralight p-3'>
                    <div className='flex justify-between w-full'>
                      <span className='text-[14px] font-extrabold text-color-gray'>
                        Employee name<br/>
                        <span className='text-[13px] font-semibold text-color-black-2'>
                          {payloadData?.first_name} {payloadData?.last_name}
                        </span>
                      </span>
                      
                      <span className='text-[14px] font-extrabold text-color-gray text-right'>
                        Employee Mobile<br/>
                        <span className='text-[13px] font-semibold text-color-black-2'>
                          {payloadData?.employee_mobile}
                        </span>
                      </span>
                    </div>

                  </div>
                  <hr className='divider' />
                  <div className='flex flex-row justify-between'>
                    <div className='w-1/2 pr-2'>
                      <FormInput
                        label='TAX ID - (Editable)'
                        placeholder={'Enter employee tax ID'}
                        name='tax_id'
                        type='text'
                        readOnly={false}
                        value={taxNumber}
                        onInput={(e) => {
                          setTaxNumber(e.target.value);
                        }}
                      />
                    </div>
                    <div className='w-1/2 pl-2'>
                      <FormInput
                        label='Tax amount - (readOnly)'
                        name='tax_amount'
                        type='text'
                        readOnly={true}
                        value={payloadData?.table_tax_amount}
                      />
                    </div>
                  </div>
                  <div className='flex flex-row justify-between'>
                    <div className='w-1/2 pr-2'>
                      <FormInput
                        label='Fee - (readOnly)'
                        name='fees'
                        type='text'
                        readOnly
                        value={payloadData?.table_fees}
                      />
                    </div>
                    <div className='w-1/2 pl-2'>
                      <FormInput
                        label='Total - (readOnly)'
                        name='total_payable'
                        type='text'
                        readOnly
                        value={payloadData?.total}
                      />
                    </div>
                  </div>
                  <div className='w-full mb-[20px]'>
                    <Button
                      text='UPDATE'
                      type='submit'
                      disabled={taxNumber ? false : true}
                      loading={editTaxLoading}
                      onClick={() => submitForm()}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TaxDetailedView;
