import React, { useEffect } from "react";
import { useState } from "react";
import classNames from "classnames";
import FormSwitch from "../form-switch";
import FormInput from "../form-input";
import { useSelector } from "react-redux";
import {
  useGetCompanyAdmins,
  useUpdateApprover,
} from "../../redux/company/hook";
import Button from "../button";
import LoadingBar from "../loader-bar";

const ApprovalSettings = () => {
  useEffect(() => {}, []);

  const [selectedAdminIndex, setSelectedAdminIndex] = useState(0);
  const { section } = useSelector((state) => state.sectionSlice);

  const { data: companyApprovalAdmins, isLoading: approvalLoading } =
    useGetCompanyAdmins();

  const { user } = useSelector((state) => state.userSlice);
  const [initiator, setInitiator] = useState(0);
  const [approver, setApprover] = useState(0);
  const [approvalLimit, setApprovalLimit] = useState(0);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (companyApprovalAdmins) {
      setInitiator(
        companyApprovalAdmins.data[selectedAdminIndex].permissions
          .approval_permission?.initiator
      );
      setApprover(
        companyApprovalAdmins.data[selectedAdminIndex].permissions
          .approval_permission?.approver
      );
      setApprovalLimit(
        companyApprovalAdmins.data[selectedAdminIndex].permissions
          .approval_permission?.approval_limit
      );
      setUserId(
        companyApprovalAdmins.data[selectedAdminIndex].permissions
          .approval_permission?.user_id
      );
    }
  }, [companyApprovalAdmins, selectedAdminIndex]);

  const { mutateAsync: updateApprover, isLoading: updateLoading } =
    useUpdateApprover();

  const submitForm = async (e) => {
    e.preventDefault();
    let payload = {
      id: userId,
      data: {
        initiator: initiator ? 1 : 0,
        approver: approver ? 1 : 0,
        approval_limit: approvalLimit ? approvalLimit : 0,
      },
    };
    await updateApprover(payload);
  };

  return (
    <>
      <div className="flex flex-col bg-white w-full box-shadow h-full min-h-fit rounded -mt-2">
        <div className="flex flex-col px-4  my-3 ">
          <span className="header-4">Approval Management</span>
          <span className="p4 text-color-gray text-[13px]">
            Manage the Admins that can initiate and/or make payments from the
            platform. Only Admins can be added as approvers of transactions.
          </span>
        </div>
        <hr className="divider -mt-1 mb-2" />
        {approvalLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
            <LoadingBar loading={approvalLoading} />
          </div>
        ) : (
          <>
            {companyApprovalAdmins ? (
              <div className="flex flex-col md:flex-row mt-3 h-full pl-8 pb-8 pr-8">
                <div className="flex flex-col md:max-w-[450px] w-full gap-3">
                  {companyApprovalAdmins.data.map((item, index) => (
                    <div
                      className={classNames(
                        "w-full max-w-[100%] md:max-w-[450px] h-[80px] flex flex-row items-center px-3 justify-between cursor-pointer border border-gray-300 rounded hover:bg-gray-100",
                        {
                          "bg-[#eff2fe]": index === selectedAdminIndex,
                        }
                      )}
                      onClick={() => {
                        setSelectedAdminIndex(index);
                      }}
                      key={index}
                    >
                      <div className="flex flex-col">
                        <span className="header-5">
                          {item.first_name} {item.last_name}
                        </span>
                        <span className="p4-regular">{item.email}</span>
                      </div>
                      <div className="flex justify-end w-[60px]">
                        <button className="bg-secondary-2-extralight w-[80px] h-[40px] rounded">
                          <span
                            className={
                              section.name !== "core hr"
                                ? "header-6 text-secondary-2"
                                : "header-6 text-[#0d5225]"
                            }
                          >
                            {item.permissions?.company_permission?.role}
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="w-[100%] md:ml-4">
                  <div className="border border-color-line rounded-md pr-4 pl-4 pt-3 flex flex-col">
                    <div className="flex flex-row justify-between gap-[20px]"></div>
                    <form className="form">
                      <div className={"flex flex-col w-full md:w-[100%]"}>
                        <div className="flex flex-col md:flex-row justify-between">
                          <div className="w-full md:w-[250px] pr-2 pl-2">
                            <FormInput
                              label="First Name"
                              name="first_name"
                              type="text"
                              readOnly
                              value={
                                companyApprovalAdmins?.data[selectedAdminIndex]
                                  .first_name
                              }
                            />
                          </div>
                          <div className="w-full md:w-[250px] pr-2 pl-2">
                            <FormInput
                              label="Last Name"
                              name="last_name"
                              type="text"
                              readOnly
                              value={
                                companyApprovalAdmins?.data[selectedAdminIndex]
                                  .last_name
                              }
                            />
                          </div>
                        </div>
                        <hr className="divider mt-2"></hr>
                        <div className="flex md:flex-row fle-col w-full items-center md:gap-8 gap-4">
                          <div className="w-full md:w-[50%] pr-2 pl-2">
                            <FormSwitch
                              label={"Initiator"}
                              name={"initiator"}
                              checked={initiator}
                              labelClass={"font-semibold"}
                              readOnly={
                                companyApprovalAdmins?.data[selectedAdminIndex]
                                  ?.permissions?.company_permission?.role ===
                                "Owner"
                                  ? true
                                  : false
                              }
                              value={initiator}
                              onClick={() => {
                                if (
                                  companyApprovalAdmins?.data[
                                    selectedAdminIndex
                                  ]?.permissions?.company_permission?.role !==
                                  "Owner"
                                ) {
                                  setInitiator(!initiator);
                                }
                              }}
                            />
                          </div>
                          <div className="w-full md:w-[50%] pr-2 pl-2">
                            <FormSwitch
                              label={"Approver"}
                              name={"approver"}
                              checked={approver}
                              labelClass={"font-semibold"}
                              readOnly={
                                companyApprovalAdmins?.data[selectedAdminIndex]
                                  ?.permissions?.company_permission?.role ===
                                "Owner"
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                if (
                                  companyApprovalAdmins?.data[
                                    selectedAdminIndex
                                  ]?.permissions?.company_permission?.role !==
                                  "Owner"
                                ) {
                                  setApprover(!approver);
                                }
                              }}
                            />
                          </div>
                        </div>

                        {companyApprovalAdmins?.data[selectedAdminIndex]
                          ?.permissions?.company_permission?.role ===
                        "Owner" ? null : approver ? (
                          <div className="flex flex-col justify-between">
                            <hr className="divider -mt-1 mb-2" />
                            <div className="w-full pr-2 pl-2">
                              <FormInput
                                label="Approval Limit"
                                name="approval_limit"
                                type="number"
                                value={approvalLimit}
                                onInput={(e) => {
                                  setApprovalLimit(e.target.value);
                                }}
                              />
                            </div>
                            <hr className="divider -mt-0 mb-2" />
                          </div>
                        ) : null}
                        {companyApprovalAdmins?.data[selectedAdminIndex]
                          ?.permissions?.company_permission?.role !==
                        "Owner" ? (
                          <>
                            <div className="flex justify-end w-full md:mt-0 h-[56px] pb-2">
                              <Button
                                text="Update approver settings"
                                type="submit"
                                loading={updateLoading}
                                disabled={false}
                                className="flex gap-2 !h-[35px] w-fit p-2 "
                                textClass={"!text-[12px]"}
                                onClick={(e) => {
                                  submitForm(e);
                                }}
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default ApprovalSettings;
