import React from 'react';
import Info from './info';

const FormInput = ({
  id,
  name,
  label,
  labelClass,
  type,
  className,
  placeholder,
  accept,
  value,
  search = false,
  inputRef,
  onChange,
  onKeyDown,
  readOnly,
  error,
  errorMessage,
  infos,
  CustomLabel,
  theme,
  ...rest
}) => {
  const checkBoxInputTypes = ['checkbox', 'radio'];
  const themes = ['primary', 'secondary'];
  const selectedTheme = themes.includes(theme) ? theme : 'primary';
  const selectedThemeClass = search
    ? '!h-[35px] p-[15px] px-[4px] pl-[10px] mt-[10px] text-color-black text-[12px] outline-none'
    : selectedTheme === 'primary' && readOnly
    ? 'gb-gray-50 !h-[35px] p-[15px] px-[4px] pl-[10px] mt-[10px] text-color-black text-[12px] outline-none'
    : '!h-[35px] p-[15px] px-[4px] pl-[10px] mt-[10px] text-color-black text-[12px] outline-none';

  const errorClass = '!bg-[#FBEDEA] !border-error focus:!border-error';

  return (
    <div className='pb-[15px] flex flex-col w-full'>
      {!checkBoxInputTypes.includes(type) ? (
      <div className='flex flex-col'>
        {label && (
          <label className={`text-[12px] leading-[23px] font-normal text-color-gray -mb-2 ${labelClass}`}>
            {label}
          </label>
        )}
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          accept={accept}
          // defaultValue={value}
          value={value}
          className={` w-full border border-gray hover:border-black duration-300 rounded-md ${selectedThemeClass} ${className} ${
            error && errorClass
          }`}
          ref={inputRef}
          onChange={onChange}
          onKeyDown={onKeyDown}
          readOnly={readOnly ? true : false}
          {...rest}
        />
      </div>
      ) : (
      <div className='flex'>
        <input
          placeholder={search? placeholder : ''}
          className={` border border-gray ${className}`}
          name={name}
          type={type}
          defaultValue={value}
          ref={inputRef}
          id={id}
          readOnly={readOnly ? true : false}
          onChange={onChange}
          {...rest}
        />
        {CustomLabel}
        <label className='form-check-label ml-2' htmlFor={id}>
          {label}
        </label>
      </div>
      )}
      {error && (
        <span className='text-error text-[12px] italic'>* {errorMessage}</span>
      )}
      {infos && <Info infos={infos} />}
    </div>
  );
};

export default FormInput;
