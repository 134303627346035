import React, { useState } from 'react';
import Button from '../../../button';
import ButtonSelect from '../../../button-select';
import CloseSVG from '../../../../assets/svg/close.svg';
import Modal from '../../modal';
import AddAttendance from './add-attendance';
import BulkAddAttendance from './bulk-add-attendance';

function SelectAttendanceOption({ isOpen, closeModal, refresh }) {
  const [option, setOption] = useState('single');
  const [showAddAttendance, setShowAddAttendance] = useState(false);
  const [showAddBulkAttendance, setShowAddBulkAttendance] = useState(false);

  function submit() {
    if (option === 'single') {
      setShowAddAttendance(true);
    } else if (option === 'bulk') {
      setShowAddBulkAttendance(true);
    }
  }

  return (
    <>
      {showAddAttendance ? (
        <AddAttendance
          isOpen={showAddAttendance}
          closeModal={() => {
            setShowAddAttendance(false);
            closeModal();
          }}
          attendanceData={null}
          title='Add Attendance'
          buttonText='SAVE ATTENDANCE'
        />
      ) : null}

      <BulkAddAttendance
        isOpen={showAddBulkAttendance}
        closeModal={() => setShowAddBulkAttendance(false)}
        completed={() => {
          setShowAddBulkAttendance(false);
          closeModal();
        }}
      />

      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Select Attendance upload option</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col items-center w-full'>
            <span className='text-left text-[14px] -mb-3'>
              Select your desired option to add attendance information
            </span>
            <div className='flex flex-col justify-center h-full min-h-[400px] px-8 w-full'>
              <ButtonSelect
                title={'Add Single Attendance'}
                subtitle={'Add your employee attendance information one after the other'}
                isActive={option === 'single'}
                onClick={() => setOption('single')}
              />

              <ButtonSelect
                title={'Import Attendance Information'}
                subtitle={
                  'Import attendance data to the ropay platform. Most suitable for companies that have physical clocking in machines'
                }
                isActive={option === 'bulk'}
                onClick={() => setOption('bulk')}
                className='mt-8'
              />

              <div className='w-full mt-[20px]'>
                <Button text='NEXT' type='submit' onClick={() => submit()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SelectAttendanceOption;
