import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import LoadingBar from "../../../../components/loader-bar";
import Button from "../../../../components/button";
import classNames from "classnames";
import TickSVG from "../../../../assets/svg/tick.svg";
import moment from "moment";
import { Initials } from "../../../../utils/functions";
import { StatusText } from "../../../../components/fragments/status-text";
import {
  ExitEmployeeStatusConfig,
  exitStatusConfig,
} from "../../../../utils/constants";
import ExitQuestions from "../../../employees/single-employee-exit/exit-questions";
import {
  useGetSingleEmployeeExit,
  useMarkExitComplete,
  useMarkExitStageComplete,
} from "../../../../redux/employees/hook/exit";
import { ExitAnswerView } from "../../../../components/modal/hr-section/employee-exit/exit-answers-view";

const ViewEmployeeExit = () => {
  useEffect(() => {}, []);

  const { id } = useParams();

  const { data: employee_exit, isLoading } = useGetSingleEmployeeExit(id);

  const [exitStages, setExitStages] = useState(false);

  const [showViewQuestions, setshowViewQuestions] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);

  const [currentData, setCurrentData] = useState(null);
  const [exitId, setExitId] = useState(null);

  const { mutateAsync: markStage, isLoading: markStageloading } =
    useMarkExitStageComplete();
  const { mutateAsync: markExit, isLoading: markExitloading } =
    useMarkExitComplete();

  const markStageStatus = async () => {
    let stage = employee_exit?.workflow?.sub_workflow[currentIndex - 1];
    let payload = {
      stage_id: stage?.id,
      status: stage?.employer_status == 1 ? 0 : 1,
    };
    await markStage(payload);
  };

  const markExitStatus = async () => {
    let payload = {
      exit_id: exitId,
      status: employee_exit?.status == 1 ? 0 : 1,
    };
    await markExit(payload);
  };

  useEffect(() => {
    if (currentIndex) {
      if (employee_exit?.workflow && employee_exit.workflow.sub_workflow) {
        setCurrentData(employee_exit.workflow.sub_workflow[currentIndex - 1]);
      }
    }
  }, [currentIndex, employee_exit]);

  useEffect(() => {
    if (employee_exit) {
      setExitId(employee_exit.id);
      if (employee_exit.workflow && employee_exit.workflow.sub_workflow) {
        setExitStages(employee_exit.workflow.sub_workflow);
        setCurrentData(employee_exit.workflow.sub_workflow[currentIndex - 1]);
      }
    }
  }, [employee_exit]);

  return (
    <DashboardLayout title="Employee Exit Page">
      '
      {showViewQuestions ? (
        <ExitQuestions
          isOpen={showViewQuestions}
          closeModal={() => setshowViewQuestions(false)}
          stage={employee_exit?.workflow?.sub_workflow[currentIndex - 1]}
        />
      ) : null}
      <div className="flex flex-col gap-4 -mt-8">
        <div className="flex flex-row items-center box-shadow h-[104px]">
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-row px-[55px] justify-between  w-full">
              <div className="flex flex-row items-center">
                <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200">
                  {Initials(
                    employee_exit?.first_name,
                    employee_exit?.last_name
                  )}
                </div>
                <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                  <span className="p1-bold">
                    {employee_exit?.first_name + " " + employee_exit?.last_name}
                  </span>
                  <span className="p4 text-color-gray">
                    {employee_exit?.email}
                  </span>
                </div>
              </div>
              {employee_exit ? (
                <div className="flex flex-row items-center">
                  <StatusText
                    statusConfig={exitStatusConfig}
                    code={employee_exit?.status}
                    className={"py-2 rounded p1-bold px-3 p2-bold text-[13px]"}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="flex flex-col bg-white min-h-fit">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] mt-3">
            <div className="p3-bold header-4">Employee Exit Profile</div>
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:items-center">
              <div className="md:w-fit w-[100%]">
                <Button
                  text={
                    currentData?.status == 0
                      ? "Mark Exit Reviewed"
                      : "Mark Exit Not Reviewed"
                  }
                  type="button"
                  className="flex gap-2 !h-[35px] px-3 !max-w-[220px]"
                  textClass="!text-[11px] !text-hr-primary-1"
                  leftIcon={"check"}
                  onClick={() => {
                    markExitStatus();
                  }}
                  loading={markExitloading}
                />
              </div>
            </div>
          </div>
          <hr className="divider" />
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <>
              {employee_exit ? (
                <>
                  <div className="flex md:flex-row flex-col">
                    <div className=" flex flex-col gap-4 w-full">
                      <div>
                        {isLoading ? (
                          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                            <LoadingBar loading={isLoading} />
                          </div>
                        ) : (
                          <>
                            {exitStages ? (
                              <div className="flex flex-col md:flex-row h-full p-4 pt-1">
                                <div className="flex flex-col md:max-w-[400px] w-full p-4 pt-3 pb-3 border border-gray-400 rounded">
                                  <span className="header-4 text-color-gray mb-2">
                                    Exit Stages
                                  </span>
                                  {exitStages?.map((item, index) => (
                                    <div
                                      className={classNames(
                                        "w-full max-w-[100%] md:max-w-[450px] h-[80px] rounded-md flex flex-row items-center px-3 justify-between cursor-pointer  border-1 mb-3 ",
                                        {
                                          "bg-green-50 cursor-default":
                                            index === currentIndex - 1,
                                          "hover:bg-gray-50":
                                            index !== currentIndex - 1,
                                        }
                                      )}
                                      onClick={() => {
                                        if (index !== currentIndex - 1) {
                                          setCurrentIndex(index + 1);
                                        }
                                      }}
                                      key={index}
                                    >
                                      <div className="flex flex-col">
                                        <span className="h6">{item.name}</span>
                                        <span className="p5">
                                          {item.description}
                                        </span>
                                      </div>
                                      <div className="flex flex-col">
                                        <div
                                          className={`flex items-center justify-center w-[30px] h-[30px] rounded-[30px] p-2 ${
                                            item.employee_status === 1
                                              ? "bg-[#0d5225]"
                                              : "border border-color-black"
                                          }`}
                                        >
                                          {item.employee_status === 1 ? (
                                            <img
                                              src={TickSVG}
                                              alt="tick"
                                              style={{
                                                width: "25px",
                                                height: "325px",
                                              }}
                                            />
                                          ) : (
                                            <div className="header-4">
                                              {index + 1}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div className="flex flex-col gap-2 w-[100%] md:ml-4 duration-500">
                                  <div className="border border-color-line rounded pr-4 pl-4 pt-4 pb-2 flex flex-col">
                                    {currentData ? (
                                      <>
                                        <div className="flex flex-row gap-3 items-center justify-end -mt-2">
                                          <Button
                                            text={
                                              currentData?.status == 0
                                                ? "Mark stage reviewed"
                                                : "Mark stage not reviewed"
                                            }
                                            type="button"
                                            className="flex gap-2 !h-[35px] px-3 !max-w-[220px]"
                                            textClass="!text-[11px] !text-hr-primary-1"
                                            leftIcon={"check"}
                                            theme="primary"
                                            loading={markStageloading}
                                            onClick={() => markStageStatus()}
                                          />
                                        </div>
                                        <hr className="divider" />
                                        <div className="grid grid-cols-2 gap-4 w-full bg-highlight p-2 rounded px-4">
                                          <div className="flex flex-col">
                                            <span className="header-6 text-color-gray">
                                              Exit Stage
                                            </span>
                                            <span className="text-[14px] mt-1 text-color-black font-bold duration-500">
                                              {currentData?.name}
                                            </span>
                                          </div>
                                          <div className="flex flex-col">
                                            <span className="header-5 text-color-gray">
                                              Date Added
                                            </span>
                                            <span className="text-[14px] mt-1 text-color-black font-bold duration-500">
                                              {moment(
                                                currentData?.created
                                              ).format("MMMM D, YYYY")}
                                            </span>
                                          </div>
                                        </div>
                                        <hr className="divider" />
                                        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full">
                                          <div className="flex flex-col">
                                            <span className="header-6 text-color-gray">
                                              Task
                                            </span>
                                            <span className="text-[14px] mt-1 text-color-black font-bold duration-500">
                                              {currentData?.task}
                                            </span>
                                          </div>
                                        </div>
                                        <hr className="divider" />
                                        <div className="flex justify-between gap-3 w-full">
                                          <div className="flex flex-col gap-2">
                                            <span className="header-6 text-color-gray">
                                              Employee Completion Status
                                            </span>
                                            <div className="flex flex-row items-center">
                                              <StatusText
                                                statusConfig={
                                                  ExitEmployeeStatusConfig
                                                }
                                                code={
                                                  currentData?.employee_status
                                                }
                                                className={
                                                  "rounded p3-bold px-3 text-[13px]"
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="flex flex-col gap-2">
                                            <span className="header-6 text-color-gray">
                                              Review Status
                                            </span>
                                            <div className="flex flex-row items-center">
                                              <StatusText
                                                statusConfig={exitStatusConfig}
                                                code={currentData?.status}
                                                className={
                                                  "rounded px-3 p3-bold text-[13px]"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <hr className="divider mt-2" />
                                        <div className="flex flex-col gap-4 w-full md:w-[100%] duration-500">
                                          <ExitAnswerView
                                            exit={employee_exit}
                                            title={"Employee Exit Answers"}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <LoadingBar loading={isLoading} />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewEmployeeExit;
