import React, { useEffect } from "react";

import DataTable from "../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import {
  useDeletePayrollGroup,
  useGetEmployeeGroups,
} from "../../redux/employees/hook/groups";
import AddEmployeeGroup from "../modal/employees/add-employee-group";
import Button from "../button";
import DoYouWantToModal from "../modal/do-you-want-to";
import HelpSVG from "../../assets/svg/help.svg";
import InfoModal from "../modal/info-modal";
import AddEmployeeToGroup from "../modal/employees/add-employee-to-group";

const EmployeeGroupsTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: employeeGroups } = useGetEmployeeGroups(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [showInfoModal, setShowInfoModal] = useState(false);

  const [showAddEmployeeGroup, setShowAddEmployeeGroup] = useState(false);
  const [showAddEmployeeToGroup, setShowAddEmployeeToGroup] = useState(false);
  const [groupData, setGroupData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { mutateAsync: deletePayrollGroup, isLoading: deleteLoading } =
    useDeletePayrollGroup();

  const history = useHistory();

  const tableConfig = {
    // title: 'Group Management',
    headers: ["Group name", "Members", "Descriptions"],
    keys: ["name", "members", "description"],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Group name",
        key: "name",
      },
    },
  };

  const deleteSinglePayrollGroup = async (id) => {
    let index = employeeGroups.data.findIndex((item) => item.id === id);
    await deletePayrollGroup(employeeGroups.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => history.push("/employees/group/view/" + id),
    },
    {
      name: "Add Employees",
      color: "orange",
      action: (id) => {
        let index = employeeGroups.data.findIndex((item) => item.id === id);
        setGroupData(employeeGroups.data[index]);
        setShowAddEmployeeToGroup(true);
      },
    },
    {
      name: "Edit",
      color: "orange",
      action: (id) => {
        let index = employeeGroups.data.findIndex((item) => item.id === id);
        setButtonText("Update");
        setTitle("Update Payroll Group");
        setGroupData(employeeGroups.data[index].id);
        setShowAddEmployeeGroup(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = employeeGroups.data.findIndex((item) => item.id === id);
        setDeleteId(employeeGroups.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  return (
    <div className="flex flex-col mt-4">
      <div className="flex items-center justify-end w-full">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={HelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      <div className="flex flex-col bg-white rounded border">
        {showDoYouWant ? (
          <DoYouWantToModal
            isOpen={showDoYouWant}
            closeModal={() => setShowDoYouWant(false)}
            id={deleteId}
            loading={deleteLoading}
            title={"Are you sure you want to delete this payroll group?"}
            buttonText={"DELETE"}
            btnFunction={deleteSinglePayrollGroup}
          />
        ) : null}
        {showAddEmployeeGroup ? (
          <AddEmployeeGroup
            isOpen={showAddEmployeeGroup}
            closeModal={() => setShowAddEmployeeGroup(false)}
            groupData={groupData}
            title={title}
            buttonText={buttonText}
          />
        ) : null}
        {showAddEmployeeToGroup ? (
          <AddEmployeeToGroup
            isOpen={showAddEmployeeToGroup}
            groupData={groupData}
            closeModal={() => setShowAddEmployeeToGroup(false)}
          />
        ) : null}
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"payroll-group"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Payroll Groups
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Payroll Group"
                type="submit"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => {
                  setGroupData(null);
                  setButtonText("Save");
                  setTitle("Add Payroll Group");
                  setShowAddEmployeeGroup(true);
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={employeeGroups}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          // bulkActionConfig={bulkActionConfig}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default EmployeeGroupsTab;
