import { companies } from '../../api';
import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { updateUser } from '../user/hook';
import { useMutation, useQuery } from '@tanstack/react-query';
import { setCompany } from './reducer';
import { queryClient } from '../..';
import { setUser } from '../user/user-slice';
import { updateSectionData } from '../section/hook';

export function useCreateCompany() {
  return useMutation(
    (payload) => {
      return companies.create(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Company created successfully.'));
        updateUser({ reload: false });
      },
    },
  );
}

export function useGetAdvancedPayrollSettings() {
  return useQuery(
    ['advanced-payroll-settings'],
    () => {
      return companies.getAdvancedPayrollSettings();
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateAdvancedPayrollSettings() {
  return useMutation(
    (payload) => {
      return companies.updateAdvancedPayrollSettings(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('advanced-payroll-settings');
        store.dispatch(setAlert(true, 'success', 'Advanced payroll updated successfully.'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateCompany() {
  return useMutation(
    (payload) => {
      return companies.update(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        updateCurrentCompany();
        updateUser({ reload: false });
        store.dispatch(setAlert(true, 'success', 'Company information updated successfully.'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateCompanyCurrency() {
  return useMutation(
    (payload) => {
      return companies.updateCurrency(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        updateCurrentCompany();
        updateUser({ reload: false });
        store.dispatch(setAlert(true, 'success', 'Company information updated successfully.'));
      },
    },
  );
}

export function useUpdateBalanceLimit() {
  return useMutation(
    (payload) => {
      return companies.updateBalanceLimit(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        updateCurrentCompany();
        updateUser({ reload: false });
        store.dispatch(setAlert(true, 'success', 'Balance limit updated successfully.'));
      },
    },
  );
}

export function useCompleteFundWallet() {
  return useMutation(
    () => {
      return companies.completeFundWallet();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Wallet account information generated successfully.'));
        updateUser({ reload: false });
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

// Route::get('/wallet/check-bvn-verification', 'check_bvn_verification');
    //     Route::post('/wallet/verify-bvn', 'verify_bvn');
    //     Route::post('/wallet/generate-account-details', 'generate_wallet_details');

export function useGetUserCompanies() {
  return useQuery(['user-companies'], companies.getUserCompanies);
}

export function useGetCompanyAdmins() {
  return useQuery(['company-admins'], companies.getCompanyAdmins);
}

export function useGetCompanySelectAdmins() {
  return useQuery(
    ['select-company-admin'],
    () => {
      return companies.getCompanyAdmins();
    },
    {
      select: (data) => {
        let newData = [];

        newData.push({ value: null, label: 'No Admin Selected' });
        data?.data?.forEach((item) => {
          newData.push({
            value: item.id,
            label: item.first_name + ' ' + item.last_name + ' | ' + item.email,
          });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function updateCurrentCompany() {
  let currentCompanyId = store.getState().companySlice.company.id;

  companies.getCompany(currentCompanyId).then((response) => {
    store.dispatch(setCompany(response.data));
  });
}

export function useSwitchCurrentCompany() {
  return useMutation(
    (payload) => {
      return companies.switchCompany(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        // store.dispatch(setCompany(response.data.company));
        store.dispatch(
          setAlert(true, 'success', 'Company Switched Successfully'),
        );
        updateUser({ reload: true });
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateSalarySettings() {
  return useMutation(
    (payload) => {
      return companies.updateSalarySettings(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('company-settings');
        store.dispatch(setAlert(true, 'success', 'Salary Settings Updated Successfully'));
        updateUser({ reload: false });
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddSalarySettings() {
  return useMutation(
    (payload) => {
      return companies.addSalarySettings(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Salary Settings Added Successfully.'));
        updateUser({ reload: true });
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBVNVerification() {
  return useMutation(
    (payload) => {
      return companies.bvnVerification(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setCompany(response.data?.company));
        store.dispatch(setUser(response.data));
        updateSectionData(response.data.section);
        store.dispatch(setAlert(true, 'success', 'BVN verified successfully.'));
      },
      onSettled: () => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetSalarySettings() {
  return useQuery(
    ['company-settings'],
    () => {
      return companies.getSalarySettings();
    },
    {
      select(data) {
        data.total_rate = 100;
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddCompanyAdmin() {
  return useMutation(
    (payload) => {
      return companies.addCompanyAdmin(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('company-admins');
        store.dispatch(setAlert(true, 'success', 'Admin added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateCompanyAdmin() {
  return useMutation(
    (payload) => {
      return companies.updateCompanyAdmin(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('company-admins');
        store.dispatch(setAlert(true, 'success', 'Admin updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRemoveCompanyAdmin() {
  return useMutation(
    (payload) => {
      return companies.removeCompanyAdmin(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('company-admins');
        store.dispatch(setAlert(true, 'success', 'Admin removed successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetTierVerification() {
  return useQuery(
    ['company-verification'],
    () => {
      store.dispatch(setIsLoading(true));
      return companies.getierVerification();
    },
    {
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useTransferAccountOwnership() {
  return useMutation(
    (payload) => {
      return companies.transferAccountOwnership(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('company-admins');
        store.dispatch(setAlert(true, 'success', 'Account ownership transferred successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateApprover() {
  return useMutation(
    (payload) => {
      return companies.updateApprover(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('company-admins');
        store.dispatch(setAlert(true, 'success', 'Approver updated successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCreateOtp() {
  return useMutation(
    () => {
      return companies.generateTaxPin();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'OTP created successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useHelpMeOnboard() {
  return useMutation(
    () => {
      store.dispatch(setIsLoading(true));
      return companies.helpMeOnboard();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(
          setAlert(
            true,
            'success',
            'Onboard Email Sent. A member of our team would reach out shortly.',
          ),
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCompanyUploadProfilePicture() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return companies.companyUploadProfilePicture(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setCompany(response.data));
        store.dispatch(setAlert(true, 'success', 'Company logo uploaded successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCheckCompanyKYCStatus() {
  return useQuery(
    ['kyc-status'],
    () => {
      return companies.checkCompanyKYC();
    },
    {
      select: (data) => {
        return data.data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUploadCompanyKYCDocument() {
  return useMutation(
    (payload) => {
      return companies.uploadCompanyKYCDocument(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('kyc-status');
        updateCurrentCompany();
        updateUser({ reload: false });
        store.dispatch(setAlert(true, 'success', 'Company KYC document uploaded successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateCompanyKYCDocument() {
  return useMutation(
    (payload) => {
      return companies.uploadCompanyKYCDocument(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('kyc-status');
        updateCurrentCompany();
        updateUser({ reload: false });
        store.dispatch(setAlert(true, 'success', 'Company KYC document updated successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteCompanyKYCDocument() {
  return useMutation(
    (payload) => {
      return companies.deleteCompanyKYCDocument(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('kyc-status');
        updateCurrentCompany();
        updateUser({ reload: false });
        store.dispatch(setAlert(true, 'success', 'Company KYC document deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
