import React from 'react';
import GuestRoute from '../guest';
import OnboardGuarantorPage from '../../pages/hr/guarantor-management/onboard-guarantor';

export const GuarantorRoutes = () => [
  <GuestRoute
    exact
    path='/guarantor/complete/:token'
    component={OnboardGuarantorPage}
  />,
];
