import React from 'react';
import Button from './button';
import { useHistory } from 'react-router-dom';
import history from '../routes/history';
import { useDispatch } from 'react-redux';

const OnboardingCard = (props) => {
  const isLastStep = props.currentStep === props.steps.length - 1;
  const content = props.steps[props.currentStep].content;
  const title = props.steps[props.currentStep].title;
  const route = props.steps[props.currentStep].nextStepRoute;

  //  const dispatch = useDispatch();

  return (
    <div className='flex !w-fit px-3 py-4 -m-6 self-center justify-self-center flex-col !h-full'>
      <div className='min-w-[250px]'>
        {/* Check if the step.content is a function or a string */}
        <div className='flex'>
          <span className='p4-medium'>{title}</span>
        </div>

        <hr className='!h-[0.5px]' />

        <span className='p4'>{content}</span>

        {/* {typeof content === 'function'
          ? content({ ...props, someOtherStuff: 'Custom Text' })
          : content} */}

        <hr className='!h-[0.5px]' />

        <div className='flex flex-row justify-between items-center'>
          <div className='flex w-1/3'></div>

          <div className='flex w-1/3'>
            <span className='text-[15px] text-gray-500'>
              {`${props.currentStep + 1} of ${props.steps.length}`}
            </span>
          </div>

          <div className='flex items-end justify-end w-1/3'>
            <Button
              text={isLastStep ? 'Close' : 'Next'}
              className={'!h-[40px] min-w-[70px]'}
              textClass={'!text-[11px]'}
              onClick={() => {
                if (isLastStep) {
                  props.setCurrentStep(0);
                  props.setIsOpen(false);
                } else {
                  props.setCurrentStep((s) => s + 1);
                }

                //If route is set for next step push to the next page
                if (route) {
                  history.push(route);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingCard;
