import React, { useState } from 'react';
import {
  UncontrolledDropdown,
} from 'reactstrap';
import Info from './info';
import ChooseFileSVG from '../assets/svg/choose-file.svg';
import { useSelector } from 'react-redux';

const FormFileInputWithOnchange = ({
  id,
  name,
  label,
  multiSource = false,
  placeholder,
  accept,
  value,
  inputRef,
  onChange,
  onKeyDown,
  readOnly,
  error,
  errorMessage,
  infos,
  ...rest
}) => {
  const [fileName, setFileName] = useState('')
  const { section } = useSelector((state) => state.sectionSlice);
  return (
    <>
      {multiSource ? (
        <div className={!section || section.name !== 'core hr'
          ?'mt-10 max-w-full h-[170px] bg-hr-primary-1 flex justify-center items-center flex-col'
          :'mt-10 max-w-full h-[170px] bg-hr-primary-1 flex justify-center items-center flex-col'
          }
        >
          <div className=''>
            <UncontrolledDropdown group className='flex flex-row h-[45px]'>
              {/* <FileButton color='primary'>Choose File</FileButton> */}
              <div className='file-select-wrapper w-[95%]'>
                <button className={!section || section.name === 'payroll'
                  ?'w-full rounded-md border-r border-r-color-white  flex flex-row justify-center px-3 items-center !bg-secondary-2 h-full'
                  :'w-full rounded-md border-r border-r-color-white  flex flex-row justify-center px-3 items-center !bg-[#0d5225] h-full'
                  }
                >
                  <div>
                    <img src={ChooseFileSVG} alt='' />
                  </div>
                  <span className='ml-2 text-color-white'>Choose File</span>
                </button>
                <input
                  type='file'
                  accept={accept}
                  ref={inputRef}
                  onChange={(e) => {
                    setFileName(e.target.value)
                  }}
                  onKeyDown={onKeyDown}
                  readOnly={readOnly ? true : false}
                  value={value}
                  {...rest}
                  name='file'
                />
              </div>

              {/* <DropdownToggle
                caret
                className={!section || section.name !== 'core hr'
                  ?'bg-hr-primary-1 !w-[60px] !px-0'
                  :'!bg-[#0d5225] !w-[60px] !px-0'
                  }
              />
              <DropdownMenu>
                <DropdownItem header>Upload from</DropdownItem>
                <DropdownItem disabled>Google Drive</DropdownItem>
                <DropdownItem disabled>One Drive</DropdownItem>
                <DropdownItem disabled>From URL</DropdownItem>
                <DropdownItem divider />
                <DropdownItem disabled>Documents</DropdownItem>
              </DropdownMenu> */}
            </UncontrolledDropdown>
          </div>

          <span className='max-w-[100%] flex flex-wrap p4-medium mt-2'> {fileName?fileName.split("\\")[2]:'or drop file here'}</span>

          {error && <span className='text-red-600'>* {errorMessage}</span>}
          {infos && <Info infos={infos} />}
        </div>
      ) : (
        <div className='pb-[20px]'>
          <div className='flex flex-col'>
            {label && (
              <label className='text-[13px] leading-[23px] font-normal text-color-gray'>
                {label}
              </label>
            )}
            <input
              name={name}
              type='file'
              // placeholder={placeholder}
              accept={accept}
              // defaultValue={value}
              value={value}
              className='file-input bg-color-off-white rounded-md h-[65px] p-[20px] border-[0.5px] border-color-gray mt-[10px] text-color-black w-[100%]'
              ref={inputRef}
              onChange={onChange}
              onKeyDown={onKeyDown}
              readOnly={readOnly ? true : false}
              {...rest}
            />
              
          </div>

          {error && <span className='text-red-600'>* {errorMessage}</span>}
          {infos && <Info infos={infos} />}
        </div>
      )}
    </>
  );
};

export default FormFileInputWithOnchange;
