import React, { useEffect } from "react";
import DataTable from "../../components/datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import { useGetReferredUsers } from "../../redux/referrals/hook";

const ReferredUsersPage = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
  });

  const { data: ReferredUsers } = useGetReferredUsers(
    pagination?.limit,
    pagination?.page
  );

  const tableConfig = {
    headers: ["Date Created", "First Name", "Last Name"],
    keys: ["created", "first_name", "last_name"],
    mobileHeader: {
      left: {
        title: "First Name",
        key: "first_name",
      },
      right: {
        title: "Last Name",
        key: "last_name",
      },
    },
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col bg-white rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Users Referred
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={ReferredUsers}
          tableConfig={tableConfig}
          actionConfig={null}
          filterByStatus={false}
          updatePagination={(data) => setPagination(data)}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default ReferredUsersPage;
