import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Filler,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import FormDateRangePicker from "../../form-date-range-picker";
import moment from "moment";
import LoadingBar from "../../loader-bar";
import { useGetFullSalaryAdvanceReport } from "../../../redux/employees/hook/salary-advance";
import { formatNumber } from "../../../utils/functions";

const SaladReport = () => {
  ChartJS.register(
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Filler,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const [start_date, setStartDate] = useState(
    moment().subtract(3, "month").format("YYYY-MM-DD")
  );
  const [end_date, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const options = {
    responsive: true,
    options: {
      plugins: {
        legend: {
          position: "top",
        },
        // title: {
        //   display: false,
        //   text: 'Chart.js Bar Chart',
        // },
        tooltip: {
          backgroundColor: "rgb(236,243,251)",
          borderColor: "rgb(236,243,251)",
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 20000,
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return formatNumber(parseInt(value), 2);
            },
          },
        },
      },
    },
  };
  const { data: saladReport, isLoading: reportLoading } =
    useGetFullSalaryAdvanceReport(start_date, end_date);
  const [labels, setLabels] = useState([]);

  const [data, setData] = useState({
    data: {
      labels: labels,
      datasets: [
        {
          type: "bar",
          label: "Advances Added",
          backgroundColor: "rgb(75, 192, 192)",
          data: saladReport?.advance_added_array?.reverse(),
          borderColor: "white",
          borderWidth: 2,
        },
        {
          type: "bar",
          label: "Advances Deducted",
          backgroundColor: "rgb(53, 162, 235)",
          data: saladReport?.advance_deducted_array?.reverse(),
        },
      ],
    },
  });

  useEffect(() => {
    if (saladReport) {
      let data = {
        data: {
          labels,
          datasets: [
            {
              type: "bar",
              label: "Advances Added",
              backgroundColor: "rgb(0, 25, 132)",
              data: saladReport?.advance_added_array?.reverse(),
              borderColor: "white",
              borderWidth: 2,
            },
            {
              type: "bar",
              label: "Advances Deducted",
              backgroundColor: "rgb(53, 162, 235)",
              data: saladReport?.advance_deducted_array?.reverse(),
            },
          ],
        },
      };
      data.data.labels = saladReport?.formatted_months.reverse();
      setData(data);
    }
  }, [saladReport]);

  return (
    <>
      {reportLoading ? (
        <LoadingBar loading={reportLoading} />
      ) : (
        <>
          <div className="flex flex-row w-full justify-end min-h-[46px] items-center px-4">
            <div className="flex flex-row justify-between items-center md:px-2 my-2 mt-3 w-full">
              <div className="p3-bold header-4 md:w-1/2 w-full">
                Salary Advance Summary
              </div>
              {/* <div className='flex flex-row items-center justify-end gap-[10px] md:w-1/2 w-full'>
                        <Button
                            text={`Export Report`}
                            type='submit'
                            className='flex gap-2 !h-[35px] max-w-fit p-2'
                            textClass={'!text-[11px]'}
                            leftIcon={'export'}
                            // onClick={() => exportWalletHistory()}
                        />
                    </div> */}
            </div>
          </div>
          <hr className="divider mb-0 mt-2" />
          <div className="flex md:flex-row flex-col pt-0 h-full pb-0 gap-3">
            <div className="flex flex-col md:w-2/5 w-full border-r items-center p-3 px-4 bg-highlight">
              {/* <div className='text-[15px] font-extrabold'>SALARY ADVANCE/LOANS ANALYSIS</div>
                    <hr className='divider mt-2 -mb-1' /> */}
              <div className="flex flex-row md:justify-end px-3 justify-start items-center w-fit !text-[13px] ">
                <FormDateRangePicker
                  start={start_date}
                  end={end_date}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  extraClass="!h-[35px] border !min-w-fit"
                />
              </div>
              <hr className="divider -mt-1 mb-2 bg-gray-700" />
              <div className="flex flex-col gap-3 w-full p-2 px-3 border bg-white rounded-md">
                <div className="flex justify-between text-[13px] font-extrabold w-full">
                  <span>ADVANCES ADDED:</span>
                  <span>{formatNumber(saladReport?.advance_added)}</span>
                </div>
                <div className="flex justify-between text-[13px] font-extrabold w-full">
                  <span>ADVANCES DEDUCTED:</span>
                  <span>{formatNumber(saladReport?.advance_deducted)}</span>
                </div>
                <div className="flex justify-between text-[13px] font-extrabold w-full">
                  <span>ADVANCES BALANCE:</span>
                  <span>{formatNumber(saladReport?.advance_balance)}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center md:w-3/5 w-full h-full p-2 px-4">
              <Bar
                data={data?.data}
                options={options}
                height={"120px"}
                width={"170px"}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SaladReport;
