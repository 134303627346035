import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { useSelector } from "react-redux";
import UnauthorizedPage from "../../errors/unauthorized";
import {
  useRefPension,
  useRevertPension,
} from "../../../redux/statutory-compliance/hook/pension";

const PensionViewPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (
          user.permissions.company_permission.statutory_read !== 1 ||
          user.permissions.statutory_permission?.pension_read !== 1
        ) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { refId } = useParams();
  const { data: pension, isLoading: loading } = useRefPension(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const history = useHistory();
  const { mutateAsync: revertPension } = useRevertPension();

  useEffect(() => {
    if (pension) {
      if (loading === false && pension.data.length < 1) {
        revertPension().then(() => {
          history.push("/statutory-payments", { tab: 2 });
        });
      }
    }
  }, []);

  const tableConfig = {
    headers: [
      "Employee",
      "PFA",
      "RSA Pin",
      "Employee",
      "Employer",
      "Fee",
      "Total",
    ],
    keys: [
      "employeeName",
      "pfaName",
      "rsaPin",
      "table_employeeContribution",
      "table_employerContribution",
      "table_fees",
      "total_pension",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Total",
        key: "total_pension",
      },
    },
  };

  return (
    <DashboardLayout title="Pension View">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col bg-white">
              <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
                <div className="p3-bold header-4">Pension Breakdown</div>
                {/* <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
                <div className='md:w-fit w-[100%]'>
                  <Button
                  text='Export'
                  type='submit'
                  theme='primary'
                  className='flex gap-2 !h-[35px] w-fit p-2 '
                  textClass={'!text-[11px]'}
                  leftIcon={'export'}
                  onClick={() => ""}
                  />
                </div>
              </div> */}
              </div>
              <hr className="divider mt-2 mb-2" />
              <div className="w-[100%]">
                <DataTable
                  data={pension}
                  tableConfig={tableConfig}
                  noCheck={true}
                  updatePagination={(data) => setPagination(data)}
                  searchData={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default PensionViewPage;
