import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/button";
import DataTable from "../../components/datatable";
import DashboardLayout from "../../components/layouts/dashboard";
import UploadDocument from "../../components/modal/documents/upload-document";
import {
  useDeleteCompanyDocument,
  useGetCompanyDocuments,
} from "../../redux/document/hook/company";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import UnauthorizedPage from "../errors/unauthorized";

const DocumentsPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: documents } = useGetCompanyDocuments(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const { mutateAsync: deleteDocument } = useDeleteCompanyDocument();

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.doc_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [showUploadDocument, setShowUploadDocument] = useState(false);

  const tableConfig = {
    // title: 'All Documents',
    headers: ["Date Uploaded", "File Name", "Added By"],
    keys: ["created", "name", "user_name"],
    mobileHeader: {
      left: {
        title: "Date",
        key: "date",
      },
      right: {
        title: "Name",
        key: "file_name",
      },
    },
  };

  const actionConfig = [
    {
      name: "Download",
      color: "orange",
      action: (id) => {
        let index = documents.data.findIndex((item) => item.id === id);
        window.open(documents.data[index].url, "_blank");
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = documents.data.findIndex((item) => item.id === id);
        deleteDocument(documents.data[index].id);
      },
    },
  ];

  return (
    <DashboardLayout title="Company Documents">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col -mt-3">
          {showUploadDocument ? (
            <UploadDocument
              isOpen={showUploadDocument}
              closeModal={() => setShowUploadDocument(false)}
            />
          ) : null}
          <div className="flex flex-col bg-white mt-[10px] rounded">
            <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
              <div className="flex flex-col md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
                <span>Documents</span>
                <span className="text-color-gray text-[13px] font-normal">
                  Easily upload documents you may need here
                </span>
              </div>
              <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="Upload Document"
                    type="submit"
                    loading={false}
                    disabled={false}
                    leftIcon={"upload"}
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    textClass={"!text-[11px]"}
                    onClick={() => {
                      setShowUploadDocument(true);
                    }}
                  />
                </div>
              </div>
            </div>
            <hr className="divider mt-2 mb-2" />
            <DataTable
              data={documents}
              tableConfig={tableConfig}
              actionConfig={actionConfig}
              updatePagination={(data) => setPagination(data)}
              computedActions={true}
              searchData={true}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default DocumentsPage;
