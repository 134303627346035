import React from 'react';
import HRPerformancePage from '../../pages/hr/performance-management';
import AdvancedEvaluation from '../../pages/hr/performance-management/performance-process/advanced-evaluation';
import BasicEvaluation from '../../pages/hr/performance-management/performance-process/basic-evaluation';
import SelectEvaluationOptions from '../../pages/hr/performance-management/performance-process/select-option';
import ViewPerformanceEvaluation from '../../pages/hr/performance-management/view-performance-evaluation';
import ProtectedRoute from '../protected';
import PerformanceAnalysis from '../../pages/hr/performance-management/performance-analysis';

export const HRPerformanceRoutes = [
  <ProtectedRoute exact path='/performance/evaluations' component={HRPerformancePage} />,
  <ProtectedRoute exact path='/performance/basic-performance-criteria' component={HRPerformancePage} />,
  <ProtectedRoute exact path='/performance/organisational-criteria' component={HRPerformancePage} />,
  <ProtectedRoute exact path='/performance/okrs' component={HRPerformancePage} />,
  <ProtectedRoute exact path='/performance/smart-goals' component={HRPerformancePage} />,
  <ProtectedRoute exact path='/performance/balanced-scorecard' component={HRPerformancePage} />,
  <ProtectedRoute exact path='/performance/technical-criteria' component={HRPerformancePage} />,
  <ProtectedRoute exact path='/performance/select-option' component={SelectEvaluationOptions} />,
  <ProtectedRoute exact path='/performance/evaluate/basic' component={BasicEvaluation} />,
  <ProtectedRoute exact path='/performance/evaluate/advanced' component={AdvancedEvaluation} />,
  <ProtectedRoute exact path='/performance/view/:id' component={ViewPerformanceEvaluation} />,
  <ProtectedRoute exact path='/performance/performance-analysis' component={PerformanceAnalysis} />,
];