import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import {
  PAGINATION_DEFAULT,
  invoiceRecievedStatusConfig,
} from '../../../../utils/constants';
import DataTable from '../../../datatable';
import Modal from '../../modal';
import { useDeclineInvoiceReceivedPayment, useGetInvoiceReceivedPayments } from '../../../../redux/spend-management/invoice-received/hook';
import PayInvoiceRecieved from './pay-invoice';

function InvoiceReceivedPayments({ isOpen, closeModal, invoiceId }) {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const [invoicePaymentData, setInvoicePaymentData] = useState();

  const [showPayInvoice, setShowPayInvoice] = useState(false);

  const { data: invoice_payments } = useGetInvoiceReceivedPayments(
    invoiceId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const { mutateAsync: declineInvoicePayment } =
    useDeclineInvoiceReceivedPayment();
  const tableConfig = {
    headers: [
      'Date',
      'Vendor Name',
      'Mobile',
      'Total Amount',
      'Amount Paid',
      'Status',
    ],
    keys: [
      'created',
      'vendor',
      'vendor_mobile',
      'total_amount',
      'amount_paid',
      'status',
    ],
    mobileHeader: {
      left: {
        title: 'Vendor Name',
        key: 'vendor',
      },
      right: {
        title: 'Amount Paid',
        key: 'amount_paid',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Approve',
      color: 'green',
      action: (id) => {
        let index = invoice_payments.data.findIndex(
          (item) => item.id === id,
        );
        setInvoicePaymentData(invoice_payments.data[index]);
        setShowPayInvoice(true);
      },
    },
    {
      name: 'Decline',
      color: 'red',
      action: (id) => {
        let index = invoice_payments.data.findIndex(
          (item) => item.id === id,
        );
        declineInvoicePayment(invoice_payments.data[index].id);
      },
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='!min-h-screen !min-w-[80%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      {showPayInvoice ? (
        <PayInvoiceRecieved
          isOpen={showPayInvoice}
          closeModal={() => setShowPayInvoice(false)}
          invoicePaymentData={invoicePaymentData}
        />
      ) : null}
      <div className='flex flex-col !min-h-fit overflow-auto'>
        <div className='pt-4 pr-8 pl-8'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>Invoice Received Payments</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <DataTable
          data={invoice_payments}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={invoiceRecievedStatusConfig}
          updatePagination={(data) => setPagination(data)}
          computedActions={true}
          searchData={true}
          noCheck={true}
        />
      </div>
    </Modal>
  );
}

export default InvoiceReceivedPayments;
