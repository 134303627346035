import React from 'react';
import HRRecruitmentPage from '../../pages/hr/recruitment';
import ApplicationPage from '../../pages/hr/recruitment-application/recruitment-apply';
import ApplicantBriefPage from '../../pages/hr/recruitment-application/recruitment-brief';
import RecruitmentJobDescriptionPage from '../../pages/hr/recruitment-process/job-description';
import RecruitmentDetailsPage from '../../pages/hr/recruitment-process/recruitment-details';
import RecruitmentQuestionsPage from '../../pages/hr/recruitment-process/recruitment-questions';
import RecruitmentSummaryPage from '../../pages/hr/recruitment-process/recruitment-summary';
import RecruitmentWorkflowPage from '../../pages/hr/recruitment-process/recruitment-workflow';
import ViewRecruitmentPage from '../../pages/hr/recruitment/view-recruitment';
import ViewRecruitmentApplication from '../../pages/hr/recruitment/view-recruitment-applicant';
import RecruitmentStageViewPage from '../../pages/hr/recruitment/view-recruitment-stage';
import GuestRoute from '../guest';
import ProtectedRoute from '../protected';

export const HRRecruitmentRoutes = [
  <ProtectedRoute exact path='/recruitment' component={HRRecruitmentPage} />,
  <ProtectedRoute exact path='/recruitment/view/:id' component={ViewRecruitmentPage} />,
  <ProtectedRoute exact path='/recruitment/add-recruitment-details' component={RecruitmentDetailsPage} />,
  <ProtectedRoute exact path='/recruitment/job-description' component={RecruitmentJobDescriptionPage} />,
  <ProtectedRoute exact path='/recruitment/assign-workflow' component={RecruitmentWorkflowPage} />,
  <ProtectedRoute exact path='/recruitment/add-questions' component={RecruitmentQuestionsPage} />,
  <ProtectedRoute exact path='/recruitment/summary' component={RecruitmentSummaryPage} />,
  <ProtectedRoute exact path='/recruitment/stage/:stage' component={RecruitmentStageViewPage} />,
  <ProtectedRoute exact path='/recruitment/application/:id' component={ViewRecruitmentApplication} />,
];

export const RecruitmentApplicationRoutes = ()=> [
  <GuestRoute
    exact
    path='/recruitment/application-brief/:token'
    component={ApplicantBriefPage}
  />,
  <GuestRoute
    exact
    path='/recruitment/apply/:token'
    component={ApplicationPage}
  />,
];