import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { employeePromotions } from '../../../api/employees/promotion';

export function useGetEmployeePromotions(limit, page, statusFilter, search) {
    return useQuery(
      ['employee-promotions', { limit, page, statusFilter, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeePromotions.getEmployeePromotions({
          limit,
          page,
          statusFilter, 
          search,
        });
      },
      {
        select(data) {
          data?.data?.forEach((promotion) => {
            promotion.created = moment(promotion.created).format('MMMM D, YYYY');
            promotion.formatted_effective_date = moment(promotion.effective_date).format('MMMM, YYYY');
            promotion.employeeName = `${promotion.first_name} ${promotion.last_name}`;
            promotion.computedActions = [0, 1, 2, 3];
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useAddEmployeePromotion() {
    return useMutation(
        (payload) => {
        return employeePromotions.addEmployeePromotion(payload);
        },
        {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('employee-promotions');
            queryClient.invalidateQueries('single-employee-promotion');
            store.dispatch(setAlert(true, 'success', 'Promotion added successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        },
    );
}

export function useUpdateEmployeePromotion() {
    return useMutation(
        (payload) => {
        store.dispatch(setIsLoading(true));
        return employeePromotions.updateEmployeePromotion(payload);
        },
        {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('employee-promotions');
            queryClient.invalidateQueries('single-employee-promotion');
            store.dispatch(setAlert(true, 'success', 'Promotion updated successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        },
    );
}

export function useDeletePromotion() {
    return useMutation(
        (payload) => {
        return employeePromotions.deletePromotion(payload);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('employee-promotions');
                queryClient.invalidateQueries('single-employee-promotion');
                store.dispatch(setAlert(true, 'success', 'Promotion Deleted Successfully'));
            },
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useAddPromotionSalaryReview() {
    return useMutation(
        (payload) => {
        return employeePromotions.addPromotionSalaryReview(payload);
        },
        {
            onSuccess: (response, variables, context) => {
                queryClient.invalidateQueries('salary-reviews');
                queryClient.invalidateQueries('employee-salary-review');
                queryClient.invalidateQueries('employee-promotions');
                queryClient.invalidateQueries('single-employee-promotion');
                store.dispatch(setAlert(true, 'success', 'Salary Reviewed Successfully'));
            },
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useGetSingleEmployeePromotion(id) {
    return useQuery(
        ['single-employee-promotion', { id }],
        () => {
        store.dispatch(setIsLoading(true));
        return employeePromotions.getEmployeePromotion(id);
        },
        {
        enabled: id !== null,
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        },
    );
}