import React from 'react';
import { useHistory } from 'react-router-dom';
import ComingSoonSVG from '../assets/svg/coming-soon.svg';
import Button from '../components/button';

export default function ComingSoon() {
  const history = useHistory();

  return (
    <div className='flex items-center flex-col px-5 md:px-0 '>
      <img src={ComingSoonSVG} alt='' className='max-w-[500px]' />
      <div className='flex flex-col text-center'>
        <span className='header-2 md:header-1'>Under Construction</span>
        <div className='w-full max-w-[700px] mt-2 flex flex-col items-center'>
          <span className='p3 md:header-5-regular'>
            Something exciting is coming soon! <br />
            Stay tuned as we prepare to unveil new features curated just for
            you.
          </span>
          <div className='w-full md:w-[220px] mt-3 md:mt-0 h-[56px]'>
            <Button
              text='Back to Home'
              type='submit'
              className='h-[56px]'
              onClick={() => history.push('/')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
