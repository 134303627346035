import { Axios } from './axios';

const getCompanyDocuments = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/documents?limit=${payload.limit}&page=${payload.page}${search}`,
  );
  return data?.data;
};

const getEmployeeDocuments = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
  
    const { data } = await Axios.get(
      `/employee-documents?limit=${payload.limit}&page=${payload.page}${search}`,
    );
    return data?.data;
};

const getDocumentByEmployee = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
  
    const { data } = await Axios.get(
      `/employee-documents/single/${payload.id}?limit=${payload.limit}&page=${payload.page}${search}`,
    );
    return data?.data;
};

const uploadCompanyDocument = async (payload) => {
    const { data } = await Axios.post(
      '/documents', 
      payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 0
      }
    );
    return data;
  };

  const uploadEmployeeDocument = async (payload) => {
    const { data } = await Axios.post(
      '/employee-documents', 
      payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 0
      }
    );
    return data;
  };

  const deleteCompanyDocument = async (payload) => {
    const { data } = await Axios.delete('/documents/'+payload);
    return data;
  };

  const deleteEmployeeDocument = async (payload) => {
    const { data } = await Axios.delete('/employee-documents/'+payload);
    return data;
  };

export const documents = {
    getCompanyDocuments,
    getEmployeeDocuments,
    getDocumentByEmployee,
    uploadCompanyDocument,
    uploadEmployeeDocument,
    deleteCompanyDocument,
    deleteEmployeeDocument,
  };