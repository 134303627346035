import React from 'react';
import moment from 'moment';
import Logo from '../components/logo';
import FormTextArea from '../components/form-textarea';
import FormInput from '../components/form-input';

// Local storage operations
export const useLocalStorage = {
  set: (key, data) => {
    let stringifiedData = JSON.stringify(data);
    localStorage.setItem(key, stringifiedData);
  },

  get: (key) => {
    const data = JSON.parse(localStorage.getItem(key));

    if (!data) {
      return null;
    }
    return data;
  },

  remove: (key) => {
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },
};

export const getRequestError = (error) => {
  const { response } = error;
  if (
    response &&
    (response?.data?.status?.code === 115 || response?.status === 401)
  ) {
    return response?.data?.status?.desc;
  } else if (response && response?.data?.status === 400) {
    logout();
  } else if (response && response?.data?.errors) {
    if (typeof response?.data?.errors === 'object') {
      return Object.values(response?.data?.errors)?.[0];
    } else {
      return response?.data?.errors;
    }
  } else if (response && response?.data?.message) {
    return response?.data?.message;
  } else if (response && response?.data?.error) {
    return response?.data?.error;
  }
  return 'There might be a problem with your internet connection. Please check and try again.';
};

export const logout = () => {
  useLocalStorage.remove('app_user');
  window.scrollTo(0, 0);
  window.location.reload();
};

export const formatDateInWords = (date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const current_date = new Date(date);
  const month_value = current_date.getMonth();
  const day_value = current_date.getDate();
  const year = current_date.getUTCFullYear();
  let hour = current_date.getHours();
  let mins = current_date.getMinutes();

  if (hour < 10) hour = `0${hour}`;
  if (mins < 10) mins = `0${mins}`;

  return date
    ? `${day_value} ${months[month_value]}, ${year} | ${hour}:${mins}`
    : '';
};

export const formatInputDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

export const Initials = (first = null, second = null) => {
  if (!first && !second) {
    return '';
  } else if (first && !second) {
    return first[0];
  } else if (first && second) {
    return first[0] + second[0];
  }
};

export const convertToCurrency = (
  amount = 0,
  type = 'en-NG',
  currency = 'NGN',
) => {
  return amount.toLocaleString(type, {
    style: 'currency',
    currency: currency,
  });
};

export const formatAmount = (amount, decimals, country = 'nigeria') => {
  if (country == 'nigeria') {
    return amount
      ? `NGN ` +
          Number(amount)
            .toFixed(decimals || 2)
            .replace(/./g, function (c, i, a) {
              return i > 0 && c !== '.' && (a.length - i) % 3 === 0
                ? ',' + c
                : c;
            })
      : `NGN 0.00`;
  } else if (country == 'ghana') {
    return amount
      ? `GHS ` +
          Number(amount)
            .toFixed(decimals || 2)
            .replace(/./g, function (c, i, a) {
              return i > 0 && c !== '.' && (a.length - i) % 3 === 0
                ? ',' + c
                : c;
            })
      : `GHS 0.00`;
  } else if (country == 'rwanda') {
    return amount
      ? `RWF ` +
          Number(amount)
            .toFixed(decimals || 2)
            .replace(/./g, function (c, i, a) {
              return i > 0 && c !== '.' && (a.length - i) % 3 === 0
                ? ',' + c
                : c;
            })
      : `RWF 0.00`;
  }
};

export const formatNumber = (n, decimals = 2) => {
  return n && parseFloat(n).toLocaleString();
};

export const cleanNumber = (number) => {
  let new_value = number;
  if (number) {
    new_value = new_value.replace(',', '');
    new_value = new_value.replace(',', '');
    new_value = new_value.replace(',', '');
    new_value = new_value.replace(',', '');
    new_value = new_value.replace(',', '');
    new_value = new_value.replace(',', '');
  } else {
    new_value = 0;
  }
  return new_value;
};

export const firstLetter = (letter) => {
  var str = letter;
  var res = str.substring(0, 1);
  return res;
};

export const statusText = (status) => {
  switch (status) {
    case 1:
      return 'Active';
    case 2:
      return 'Inactive';
    case 3:
      return 'Disabled';
    default:
      return 'Unknown';
  }
};

export const statusStyling = (status) => {
  switch (status) {
    case 'active':
      return 'success';
    case 'successful':
      return 'success';
    case 'processing':
      return 'success';
    case 'completed':
      return 'secondary';
    case 'pending':
      return 'warning';
    case 'inactive':
      return 'tertiary';
    case 'unsuccessful':
      return 'error';
    default:
      return 'success';
  }
};

export const toDataUrl = async function (url, callback) {
  //Convert to base64
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = () => {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

export const sumOfObjectKeys = (array, key) => {
  return array.reduce((a, b) => a + (b[key] || 0), 0);
};

export const formValidation = (type, required, min, max) => {
  if (type === 'email') {
    return {
      required: required ? 'Email address is required' : false,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    };
  } else if (type === 'text') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'non-empty') {
    return {
      required: required ? 'This field cannot be empty' : false,
    };
  } else if (type === 'number') {
    return {
      required: required ? 'This field is required' : false,
      min: min
        ? {
            value: min,
            message: `Minimum value is ${min}`,
          }
        : null,
      max: max
        ? {
            value: max,
            message: `Maximum value is ${max}`,
          }
        : null,
    };
  } else if (type === 'password') {
    return {
      required: required ? 'This field is required' : false,
      minLength: {
        value: 8,
        message: 'Must be atleast 8 characters',
      },
    };
  } else if (type === 'file') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'select') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'radio') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'checkbox') {
    return {
      required: required ? 'This field is required' : false,
    };
  } else if (type === 'date') {
    return {
      required: required ? 'This field is required' : false,
    };
  }
};

export const payrollJSX = (company, payslip, imageBlob = null) => {
  return (
    <div className='flex flex-col gap-3 min-h-fit max-w-full bg-white p-4'>
      <div className='flex  items-center justify-between bg-color-white p-3 pt-0 text-secondary-2 rounded-md'>
        <div className='w-1/2 flex flex-col items-start gap-0'>
          <div className='flex gap-2 items-center'>
            <div className='text-[20px] flex font-bold justify-center items-center h-[55px] w-[60px] header-3 relative rounded-xl bg-white'>
              {company?.logo ? (
                <img
                  src={imageBlob}
                  alt='company_logo'
                  className='object-fit min-w-[60px] min-h-[45px] max-w-[60px] max-h-[45px] '
                />
              ) : (
                <span className='text-primary-1'>
                  {Initials(company?.company_name)}
                </span>
              )}
            </div>
            <span className='text-secondary-2 text-[18px] pb-4 font-semibold'>
              {company?.company_name}
            </span>
          </div>
          <div className='flex flex-col justify-start gap-2 font-normal -mt-2'>
            <div className='w-full flex justify-start text-[14px] text-secondary-2'>
              {company?.address}, {company?.city}, {company?.state},{' '}
              {company?.country}.
            </div>
            <div className='w-full flex justify-start text-[13px] font-semibold text-secondary-2'>
              {company?.email} | {company?.mobile}
            </div>
          </div>
        </div>
        <div className='w-1/2 flex justify-end text-2xl text-primary-1 pt-0'>
          <div className='w-full flex flex-col items-start'>
            <div className='flex flex-col  justify-end items-end font-normal w-full gap-0'>
              <span className='text-[18px] w-2/3 text-right font-semibold text-secondary-2'>
                Payslip Information
              </span>
              <div className='flex justify-end px-2 items-center h-[42px] gap-2 w-full text-[14px] text-secondary-2'>
                <span className='text-[14px] w-2/3 text-right font-semibold text-hr-primary-1'>
                  Pay Month:
                </span>
                <div className='w-1/2 text-right text-secondary-2 text-[13px]'>
                  {moment(payslip.month).format('MMMM, YYYY')}
                </div>
              </div>
              <div className='flex justify-end px-2 items-center h-[42px] gap-2 w-full text-[14px] text-secondary-2 -mt-4'>
                <span className='text-[14px] w-2/3 text-right font-semibold text-secondary-2'>
                  Payment Date:
                </span>
                <div className='w-1/2 text-right text-secondary-2 text-[14px]'>
                  {moment(payslip.payment_date).format('MMMM DD, YYYY')}
                </div>
              </div>
              {payslip.staff_number ? (
                <div className='flex justify-end px-2 items-center h-[42px] gap-2 w-full text-[14px] text-secondary-2 -mt-4'>
                  <span className='text-[14px] w-2/3 text-right font-semibold text-hr-secondary-2'>
                    Staff Number:
                  </span>
                  <div className='w-1/2 text-right text-secondary-2 text-[14px]'>
                    {payslip.staff_number}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className='flex items-center justify-between bg-secondary-2 p-3 text-color-white rounded-md pb-4 -mt-2'>
        <div className='w-1/2 flex flex-col items-start gap-2 font-normal'>
          <span className='text-[18px] w-full text-left font-semibold text-color-white'>
            {payslip.employee_first_name} {payslip.employee_last_name}
          </span>
          <div className='flex flex-col justify-start gap-2 font-normal'>
            <div className='w-full flex justify-start text-[13px] font-normal text-color-white'>
              {payslip.employee_email} | {payslip.employee_phone}
            </div>
          </div>
        </div>
        <div className='w-1/2 flex flex-col justify-end gap-2 font-normal'>
          <span className='text-[18px] w-full text-right font-semibold text-color-white'>
            {payslip.bank_name}
          </span>
          <div className='w-full flex justify-end text-[13px] font-normal text-color-white'>
            {payslip.account_number} | {payslip.account_name}
          </div>
        </div>
      </div>
      <div className='flex flex-col font-normal'>
        <div className='flex pb-3 bg-white border-b-2 border-b-secondary-2 border-t-2 border-t-secondary-2 h-[50px]'>
          <div className='flex w-2/6 items-center justify-center px-3'>
            <div className='text-secondary-2 !text-[14px] font-extrabold'>
              Earnings
            </div>
          </div>
          <div className='flex w-2/6 items-center justify-center px-3'>
            <div className='text-secondary-2 !text-[14px] font-normal'>
              Type
            </div>
          </div>
          <div className='flex w-2/6 items-center justify-center px-3'>
            <div className='text-secondary-2 !text-[14px] font-normal text-center'>
              Amount
            </div>
          </div>
        </div>
        {payslip.basic_salary && payslip.basic_salary > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Basic Salary
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(payslip.basic_salary)}
              </div>
            </div>
          </div>
        ) : null}

        {payslip.housing_allowance &&
        parseFloat(payslip.housing_allowance) > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Housing Allowance
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(payslip.housing_allowance)}
              </div>
            </div>
          </div>
        ) : null}

        {payslip.transport_allowance &&
        parseFloat(payslip.transport_allowance) > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Transport Allowance
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(parseFloat(payslip.transport_allowance))}
              </div>
            </div>
          </div>
        ) : null}

        {payslip.leave_allowance && parseFloat(payslip.leave_allowance) > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Leave Allowance
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(payslip.leave_allowance)}
              </div>
            </div>
          </div>
        ) : null}
        {payslip.lunch_allowance && parseFloat(payslip.lunch_allowance) > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Lunch Allowance
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(payslip.lunch_allowance)}
              </div>
            </div>
          </div>
        ) : null}

        {payslip.utility_allowance &&
        parseFloat(payslip.utility_allowance) > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Utility Allowance
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(payslip.utility_allowance)}
              </div>
            </div>
          </div>
        ) : null}

        {payslip.benefit_in_kind && payslip.benefit_in_kind > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Benefit In Kind
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(payslip.benefit_in_kind)}
              </div>
            </div>
          </div>
        ) : null}

        {payslip.additional_allowances &&
          JSON.parse(payslip.additional_allowances).length > 0 &&
          JSON.parse(payslip.additional_allowances).map((item) => (
            <div className='flex bg-white h-[40px]'>
              <div className='flex w-2/6 items-center justify-center px-3'>
                <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
              </div>
              <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-normal'>
                  {item.title}
                </div>
              </div>
              <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                  {formatNumber(item.amount)}
                </div>
              </div>
            </div>
          ))}

        {payslip.other_allowance && parseFloat(payslip.other_allowance) > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Other Allowance
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(payslip.other_allowance)}
              </div>
            </div>
          </div>
        ) : null}

        {payslip.overtime && parseFloat(payslip.overtime) > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Overtime
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(payslip.overtime)}
              </div>
            </div>
          </div>
        ) : null}
        {payslip.bonus ? (
          <>
            {JSON.parse(payslip.bonus_breakdown) &&
            JSON.parse(payslip.bonus_breakdown).length > 0 ? (
              <>
                {JSON.parse(payslip.bonus_breakdown).map((item, index) => (
                  <div className='flex bg-white h-[40px]'>
                    <div className='flex w-2/6 items-center justify-center px-3'>
                      <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                    </div>
                    <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                      <div className='text-secondary-2 !text-[12px] font-normal'>
                        {item.description}
                      </div>
                    </div>
                    <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                      <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                        {formatNumber(item.amount)}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : parseFloat(payslip.bonus) > 0 ? (
              <div className='flex bg-white h-[40px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                  <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                </div>
                <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-normal'>
                    Bonus
                  </div>
                </div>
                <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                    {formatNumber(payslip.bonus)}
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
        <div className='flex bg-white h-[50px]'>
          <div className='flex w-2/6 items-center justify-center px-3 bg-highlight pb-2'>
            <div className='text-secondary-2 !text-[15px] font-normal'></div>
          </div>
          <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
            <div className='text-secondary-2 !text-[15px] font-semibold'>
              Gross Pay
            </div>
          </div>
          <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
            <div className='text-secondary-2 !text-[15px] text-center font-extrabold pr-4'>
              {formatNumber(payslip.gross_pay)}
            </div>
          </div>
        </div>
        <div className='flex pb-3 bg-white border-b-2 border-b-secondary-2 border-t-2 border-t-secondary-2 h-[50px]'>
          <div className='flex w-2/6 items-center justify-center px-3'>
            <div className='text-secondary-2 !text-[14px] font-extrabold'>
              Deductions
            </div>
          </div>
          <div className='flex w-2/6 items-center justify-center px-3'>
            <div className='text-secondary-2 !text-[14px] font-normal'>
              Type
            </div>
          </div>
          <div className='flex w-2/6 items-center justify-center px-3'>
            <div className='text-secondary-2 !text-[14px] font-normal text-center'>
              Amount
            </div>
          </div>
        </div>

        <div className='flex bg-white h-[40px]'>
          <div className='flex w-2/6 items-center justify-center px-3'>
            <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
          </div>
          <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
            <div className='text-secondary-2 !text-[12px] font-normal'>
              PAYE Tax
            </div>
          </div>
          <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
            <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
              {formatNumber(payslip.tax)}
            </div>
          </div>
        </div>
        {payslip.pension && parseFloat(payslip.pension) > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Pension
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(payslip.pension)}
              </div>
            </div>
          </div>
        ) : null}
        {payslip.other_statutory_allowance &&
        parseFloat(payslip.other_statutory_allowance) > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Other Statutory Deductions
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(parseFloat(payslip.other_statutory_allowance))}
              </div>
            </div>
          </div>
        ) : null}
        {payslip.salary_advance && parseFloat(payslip.salary_advance) > 0 ? (
          <div className='flex bg-white h-[40px]'>
            <div className='flex w-2/6 items-center justify-center px-3'>
              <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
            </div>
            <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-normal'>
                Advances/Loans
              </div>
            </div>
            <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
              <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                {formatNumber(payslip.salary_advance)}
              </div>
            </div>
          </div>
        ) : null}
        {payslip.other_deductions &&
        parseFloat(payslip.other_deductions) > 0 ? (
          <>
            {JSON.parse(payslip.other_deductions_breakdown) &&
            JSON.parse(payslip.other_deductions_breakdown).length > 0 ? (
              <>
                {JSON.parse(payslip.other_deductions_breakdown).map(
                  (item, index) => (
                    <div className='flex bg-white h-[40px]'>
                      <div className='flex w-2/6 items-center justify-center px-3'>
                        <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                      </div>
                      <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                        <div className='text-secondary-2 !text-[12px] font-normal'>
                          {item.description}
                        </div>
                      </div>
                      <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                        <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                          {formatNumber(item.amount)}
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </>
            ) : payslip.other_deductions &&
              parseFloat(payslip.other_deductions) > 0 ? (
              <div className='flex bg-white h-[40px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                  <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                </div>
                <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-normal'>
                    Other Salary Deductions
                  </div>
                </div>
                <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                    {formatNumber(payslip.other_deductions)}
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        <div className='flex bg-white h-[40px]'>
          <div className='flex w-2/6 items-center justify-center px-3 bg-highlight pb-2'>
            <div className='text-secondary-2 !text-[14px] font-normal'></div>
          </div>
          <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
            <div className='text-secondary-2 !text-[14px] font-semibold'>
              Total Deductions
            </div>
          </div>
          <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
            <div className='text-secondary-2 !text-[14px] text-center font-extrabold pr-4'>
              {formatNumber(
                payslip.tax +
                  payslip.pension +
                  parseFloat(payslip.other_statutory_allowance) +
                  payslip.salary_advance +
                  payslip.other_deductions,
              )}
            </div>
          </div>
        </div>
        {payslip.reimburse && parseFloat(payslip.reimburse) > 0 ? (
          <>
            <div className='flex pb-3 bg-white border-b-2 border-b-secondary-2 border-t-2 border-t-secondary-2 h-[50px]'>
              <div className='flex w-2/6 items-center justify-center px-3'>
                <div className='text-secondary-2 !text-[14px] font-extrabold'>
                  Reimbursements
                </div>
              </div>
              <div className='flex w-2/6 items-center justify-center px-3'>
                <div className='text-secondary-2 !text-[14px] font-normal'>
                  Type
                </div>
              </div>
              <div className='flex w-2/6 items-center justify-center px-3'>
                <div className='text-secondary-2 !text-[14px] font-normal text-center'>
                  Amount
                </div>
              </div>
            </div>
            {JSON.parse(payslip.reimbursement_breakdown) &&
            JSON.parse(payslip.reimbursement_breakdown).length > 0 ? (
              <>
                {JSON.parse(payslip.reimbursement_breakdown).map(
                  (item, index) => (
                    <div className='flex bg-white h-[40px]'>
                      <div className='flex w-2/6 items-center justify-center px-3'>
                        <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                      </div>
                      <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                        <div className='text-secondary-2 !text-[12px] font-normal'>
                          {item.description}
                        </div>
                      </div>
                      <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                        <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                          {formatNumber(item.amount)}
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </>
            ) : payslip.reimburse && parseFloat(payslip.reimburse) > 0 ? (
              <div className='flex bg-white h-[40px]'>
                <div className='flex w-2/6 items-center justify-center px-3'>
                  <div className='text-secondary-2 !text-[12px] font-extrabold'></div>
                </div>
                <div className='flex w-2/6 items-center justify-start px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-normal'>
                    Reimbursements
                  </div>
                </div>
                <div className='flex w-2/6 items-center justify-end px-3 bg-highlight pb-3'>
                  <div className='text-secondary-2 !text-[12px] font-bold text-center pr-4'>
                    {formatNumber(payslip.reimburse)}
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
        <div className='flex bg-white h-[50px]'>
          <div className='flex w-2/6 items-center justify-center px-3 bg-gray-200 pb-2'>
            <div className='text-secondary-2 !text-[16px] font-normal'></div>
          </div>
          <div className='flex w-2/6 items-center justify-start px-3 bg-gray-200 pb-3'>
            <div className='text-secondary-2 !text-[18px] font-semibold'>
              Net Pay
            </div>
          </div>
          <div className='flex w-2/6 items-center justify-end px-3 bg-gray-200 pb-3'>
            <div className='text-secondary-2 !text-[18px] text-center font-extrabold pr-4'>
              {formatNumber(payslip.net_pay)}
            </div>
          </div>
        </div>
      </div>
      <a
        href='https://www.ropay.africa'
        target='_blank'
        className='flex w-full flex-col justify-center items-center p-2 gap-2 text-black'
      >
        <span>Powered By:</span>
        <Logo />
      </a>
    </div>
  );
};

export const performanceJSX = (company, performance, imageBlob = null) => {
  return (
    <div className='flex flex-col gap-3 min-h-fit max-w-full bg-white p-4'>
      <div className='text-[25px] w-full text-center font-extrabold text-hr-primary-1'>
        {performance.first_name}'s - Evaluation Report
      </div>
      <div className='flex items-center justify-between bg-color-white p-3 pt-0 text-hr-primary-1 rounded-md'>
        <div className='w-1/2 flex flex-col items-start gap-0'>
          <div className='flex gap-2 items-center'>
            <div className='text-[20px] flex font-bold justify-center items-center h-[55px] w-[60px] header-3 relative rounded-xl bg-white'>
              {company?.logo ? (
                <img
                  src={imageBlob}
                  alt='company_logo'
                  className='object-fit min-w-[60px] min-h-[45px] max-w-[60px] max-h-[45px] '
                />
              ) : (
                <span className='text-primary-1'>
                  {Initials(company?.company_name)}
                </span>
              )}
            </div>
            <span className='text-black text-[18px] pb-4 font-semibold'>
              {company?.company_name}
            </span>
          </div>
          <div className='flex flex-col justify-start gap-2 font-normal -mt-3'>
            <div className='w-full flex justify-start text-[14px] text-black'>
              {company?.address}, {company?.city}, {company?.state},{' '}
              {company?.country}.
            </div>
            <div className='w-full flex justify-start text-[13px] font-semibold text-black'>
              {company?.email} | {company?.mobile}
            </div>
          </div>
        </div>
      </div>
      <div className='flex items-center justify-between bg-hr-primary-1 p-3 text-color-white rounded-md pb-4 -mt-2'>
        <div className='w-1/2 flex flex-col items-start gap-2 font-normal'>
          <span className='text-[16px] w-full text-left font-semibold text-color-white'>
            {performance.first_name} {performance.last_name}
          </span>
          <div className='flex flex-col justify-start gap-2 font-normal'>
            <div className='w-full flex justify-start text-[13px] font-normal text-color-white'>
              {performance.mobile}
            </div>
          </div>
          {performance.staff_number ? (
            <div className='flex justify-end px-2 items-center h-[42px] gap-2 w-full text-[14px] text-hr-primary-1 -mt-4'>
              <span className='text-[14px] w-2/3 text-right font-semibold text-hr-primary-1'>
                Staff Number:
              </span>
              <div className='w-1/2 text-right text-hr-primary-1 text-[14px]'>
                {performance.staff_number}
              </div>
            </div>
          ) : null}
        </div>
        <div className='w-1/2 flex justify-end text-white pt-0'>
          <div className='w-full flex flex-col items-start'>
            <div className='flex flex-col  justify-end items-end font-normal w-full gap-0'>
              <span className='text-[16px] text-right font-semibold text-white'>
                Period Covered
              </span>
              <div className='flex justify-end items-center gap-2 w-full text-[14px] text-white'>
                <div className='text-right text-white text-[13px] font-normal'>
                  {moment(performance.evaluation_start).format('MMMM DD, YYYY')}{' '}
                  to{' '}
                  {moment(performance.evaluation_end).format('MMMM DD, YYYY')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-3 bg-white mt-2'>
        {performance?.type === 'basic' ? (
          <div className='flex flex-col p-4 border gap-3 rounded-md'>
            {performance?.results?.map((item, index) => (
              <div className='flex flex-col p-4 border rounded bg-highlight '>
                <div className='grid grid-cols-3 gap-4 w-full'>
                  <div className='flex gap-2 col-span-1 items-center'>
                    <div className='font-bold text-[13px]'>
                      Result {index + 1}:
                    </div>
                    <div className='text-[12px]'>{item.name}</div>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <div className='font-bold text-[13px]'>HR Grade:</div>
                    <div className='flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1'>
                      <FormInput
                        name='grade'
                        type='number'
                        value={item.grade}
                        className='!h-[30px] !w-[80px]'
                      />
                      /{item.max_grade}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='flex flex-col p-4 border gap-3 rounded-md'>
            {performance?.organisational_assessments &&
            performance?.organisational_assessments.length > 0 ? (
              <>
                <div
                  className={
                    'flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer bg-highlight rounded-md pl-2 pr-2 pb-3'
                  }
                >
                  <span>
                    General Organisational Assessment (Behaviour and Technical)
                  </span>
                </div>
                <div className={'flex flex-col'}>
                  <div className='flex flex-col p-4 py-2 border gap-3 rounded-md'>
                    {performance?.organisational_assessments?.length > 0 &&
                      performance?.organisational_assessments?.map(
                        (item, index) => (
                          <div className='flex flex-col p-2 px-4 border rounded bg-highlight  gap-1'>
                            <div className='grid grid-cols-3 gap-2 w-full'>
                              <div className='flex gap-2 col-span-1 items-center'>
                                <div className='font-bold text-[15px]'>
                                  Assessment Indicator:{' '}
                                  <span className='text-[14px] font-normal'>
                                    {item.indicator}
                                  </span>
                                </div>
                              </div>
                              <div className='flex gap-2 col-span-1 items-center'>
                                <div className='font-bold text-[15px]'>
                                  Indicator Type:
                                </div>
                                <div className='text-[14px]'>{item.type}</div>
                              </div>
                            </div>
                            <div className='grid grid-cols-4 w-full gap-2'>
                              <>
                                <div className='flex gap-2 items-center'>
                                  <div className='font-bold text-[13px]'>
                                    Team Lead Grade:
                                  </div>
                                  <div className='text-[12px] text-hr-secondary-1 font-normal'>
                                    {item.team_lead_grade}/{item.max_grade}
                                  </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                  <div className='font-bold text-[13px]'>
                                    Employee Grade:
                                  </div>
                                  <div className='text-[12px] text-hr-secondary-1 font-normal'>
                                    {item.employee_grade}/{item.max_grade}
                                  </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                  <div className='font-bold text-[13px]'>
                                    HR Grade:
                                  </div>
                                  <div className='text-[12px] text-hr-secondary-1 font-normal'>
                                    {item.hr_grade}/{item.max_grade}
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        ),
                      )}
                  </div>
                </div>
                <hr className='divider -mt-3 -mb-3' />
              </>
            ) : null}
            {performance?.okr ? (
              <>
                <div
                  className={
                    'flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer bg-highlight rounded-md pl-2 pr-2 pb-3'
                  }
                >
                  <span>Objectives and Key Results (OKRs)</span>
                </div>
                <div className={'flex flex-col'}>
                  <div className='flex flex-col p-4 border rounded-md gap-3'>
                    <div className='flex justify-between items-center gap-4 w-full mb-2 bg-highlight rounded-md border pb-2 px-2'>
                      <div className='flex gap-2 col-span-1 items-center'>
                        <div className='font-extrabold text-[14px]'>
                          OKR Title:
                        </div>
                        <div className='text-[13px]'>
                          {performance?.okr?.okr_title}
                        </div>
                      </div>
                      <div className='flex gap-2 col-span-2 items-center'>
                        <div className='font-bold text-[14px]'>OKR Period:</div>
                        <div className='text-[13px]'>
                          {moment(performance?.okr?.start_date).format(
                            'MMMM D, YYYY',
                          )}{' '}
                          -{' '}
                          {moment(performance?.okr?.end_date).format(
                            'MMMM D, YYYY',
                          )}
                        </div>
                      </div>
                    </div>
                    {performance?.okr?.objectives?.map((item, index) => (
                      <div className='flex flex-col p-4 pt-2 border rounded bg-highlight'>
                        <div className='grid grid-cols-3 gap-2 w-full'>
                          <div className='flex gap-2 col-span-1 items-center'>
                            <div className='font-bold text-[14px]'>
                              Objective {index + 1}:
                            </div>
                            <div className='text-[13px]'>{item.objective}</div>
                          </div>
                          <div className='flex gap-2 col-span-2 items-center'>
                            <div className='font-bold text-[14px]'>
                              Completion Period:
                            </div>
                            <div className='text-[13px]'>
                              {moment(item.start_date).format('MMMM D, YYYY')} -{' '}
                              {moment(item.end_date).format('MMMM D, YYYY')}
                            </div>
                          </div>
                          <div className='flex gap-4 -mt-2 items-center'>
                            <div className='font-bold text-[15px]'>
                              Max Grade:
                            </div>
                            <div className='text-[14px] text-hr-secondary-1 font-extrabold'>
                              {item.max_grade}
                            </div>
                          </div>
                        </div>
                        <div className='flex flex-col gap-2 mt-2'>
                          {item.key_results?.map((subItem, subIndex) => (
                            <div className='p-2 px-4 border rounded-md bg-white'>
                              <div className='flex gap-2 w-full justify-between'>
                                <div className='flex gap-2 items-center'>
                                  <div className='font-bold text-[12px]'>
                                    Key Result {subIndex + 1}:
                                  </div>
                                  <div className='text-[12px]'>
                                    {subItem.key_result}
                                  </div>
                                </div>
                                <div className='flex gap-2 items-center justify-end'>
                                  <div className='font-bold text-[12px]'>
                                    Completion Period:
                                  </div>
                                  <div className='text-[12px]'>
                                    {moment(subItem.start_date).format(
                                      'MMMM DD, YYYY',
                                    )}{' '}
                                    -{' '}
                                    {moment(subItem.end_date).format(
                                      'MMMM DD, YYYY',
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='grid grid-cols-3 w-full mt-2'>
                                <div className='flex gap-2 items-center'>
                                  <div className='font-bold text-[13px]'>
                                    Team Lead Grade:
                                  </div>
                                  <div className='text-[12px] font-normal'>
                                    {subItem.team_lead_grade}/{subItem.weight}
                                  </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                  <div className='font-bold text-[13px]'>
                                    Employee Grade:
                                  </div>
                                  <div className='text-[12px] font-normal'>
                                    {subItem.employee_grade}/{subItem.weight}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                    <div className='flex flex-col gap-2 mt-2'>
                      <div className='flex flex-col gap-2'>
                        <div className='flex flex-col p-4 py-2 border rounded w-full bg-highlight'>
                          <span className='text-color-gray text-[13px]'>
                            Employee Comments:
                          </span>
                          <div className='flex text-black text-[13px] h-fit'>
                            {performance?.okr?.employee_comment}
                          </div>
                        </div>
                        <div className='flex flex-col p-4 py-2 border rounded w-full bg-highlight'>
                          <span className='text-color-gray text-[13px]'>
                            Team Lead Comments:
                          </span>
                          <div className='flex text-black text-[13px] h-fit'>
                            {performance?.okr?.team_lead_comment}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className='divider -mt-3 -mb-3' />
              </>
            ) : null}
            {performance?.smart_goal ? (
              <>
                <div
                  className={
                    'flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer bg-highlight rounded-md pl-2 pr-2 pb-3'
                  }
                >
                  <span>SMART Goals assessed</span>
                </div>
                <div className={'flex flex-col'}>
                  <div className='flex flex-col p-4 border rounded-md gap-3'>
                    <div className='grid grid-cols-2 gap-4 w-full mb-2'>
                      <div className='flex gap-2 col-span-1 items-center'>
                        <div className='font-extrabold text-[14px]'>
                          SMART Goal Title:
                        </div>
                        <div className='text-[13px]'>
                          {performance?.smart_goal?.smart_goal_title}
                        </div>
                      </div>
                      <div className='flex gap-2 col-span-1 items-center'>
                        <div className='font-bold text-[14px]'>
                          SMART Goal Period:
                        </div>
                        <div className='text-[13px]'>
                          {moment(performance?.smart_goal?.start_date).format(
                            'MMMM D, YYYY',
                          )}{' '}
                          -{' '}
                          {moment(performance?.smart_goal?.end_date).format(
                            'MMMM D, YYYY',
                          )}
                        </div>
                      </div>
                    </div>
                    {performance?.smart_goal?.goals?.map((item, index) => (
                      <div className='flex flex-col p-2 px-3 border rounded bg-highlight'>
                        <div className='flex gap-2 w-full justify-between'>
                          <div className='flex gap-2 col-span-1 items-center'>
                            <div className='font-bold text-[15px]'>
                              Goal {index + 1}:
                            </div>
                            <div className='text-[14px]'>{item.smart_goal}</div>
                          </div>
                          <div className='flex gap-2 col-span-2 items-center justify-end'>
                            <div className='font-bold text-[15px]'>
                              Completion Period:
                            </div>
                            <div className='text-[14px]'>
                              {moment(item.start_date).format('MMMM D, YYYY')} -{' '}
                              {moment(item.end_date).format('MMMM D, YYYY')}
                            </div>
                          </div>
                        </div>
                        <div className='grid grid-cols-3 w-full mt-3'>
                          <>
                            <div className='flex gap-2 items-center'>
                              <div className='font-bold text-[13px]'>
                                Team Lead Grade:
                              </div>
                              <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                {item.team_lead_grade}/{item.weight}
                              </div>
                            </div>
                            <div className='flex gap-2 items-center'>
                              <div className='font-bold text-[13px]'>
                                Employee Grade:
                              </div>
                              <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                {item.employee_grade}/{item.weight}
                              </div>
                            </div>
                            <div className='flex gap-2 items-center'>
                              <div className='font-bold text-[13px]'>
                                HR Grade:
                              </div>
                              <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                {item.hr_grade}/{item.weight}
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='flex flex-col gap-2 mt-2'>
                    <div className='flex flex-col gap-2'>
                      <div className='flex flex-col p-4 py-2 border rounded w-full bg-highlight'>
                        <span className='text-color-gray text-[13px]'>
                          HR Comments:
                        </span>
                        <div className='flex text-black text-[13px] h-fit'>
                          {performance?.smart_goal?.hr_comment}
                        </div>
                      </div>
                      <div className='flex flex-col p-4 py-2 border rounded w-full bg-highlight'>
                        <span className='text-color-gray text-[13px]'>
                          Employee Comments:
                        </span>
                        <div className='flex text-black text-[13px] h-fit'>
                          {performance?.smart_goal?.employee_comment}
                        </div>
                      </div>
                      <div className='flex flex-col p-4 py-2 border rounded w-full bg-highlight'>
                        <span className='text-color-gray text-[13px]'>
                          Team Lead Comments:
                        </span>
                        <div className='flex text-black text-[13px] h-fit'>
                          {performance?.smart_goal?.team_lead_comment}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className='divider -mt-3 mb-2' />
              </>
            ) : null}
            {performance?.technical_appraisal ? (
              <>
                <div
                  className={
                    'flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer bg-highlight rounded-md pl-2 pr-2 pb-3'
                  }
                >
                  <span>Technical Appraisals assessed</span>
                </div>
                <div className={'flex flex-col'}>
                  <div className='flex flex-col p-4 border rounded-md gap-3'>
                    <div className='grid grid-cols-1 gap-4 w-full -mb-2'>
                      <div className='flex gap-2 col-span-1 items-center'>
                        <div className='font-extrabold text-[16px]'>
                          Appraisal Title:
                        </div>
                        <div className='text-[15px]'>
                          {
                            performance?.technical_appraisal
                              ?.technical_appraisal_title
                          }
                        </div>
                      </div>
                    </div>
                    {performance?.technical_appraisal?.appraisals?.map(
                      (item, index) => (
                        <div className='flex flex-col p-2 border rounded-md bg-highlight '>
                          <div className='grid grid-cols-2 gap-4 w-full'>
                            <div className='flex gap-2 col-span-1 items-center'>
                              <div className='font-bold text-[15px]'>
                                Appraisal {index + 1}:
                              </div>
                              <div className='text-[14px]'>
                                {item.appraisal}
                              </div>
                            </div>
                          </div>
                          <div className='grid grid-cols-3 w-full mt-2'>
                            <div className='flex gap-2 items-center'>
                              <div className='font-bold text-[13px]'>
                                Team Lead Grade:
                              </div>
                              <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                {item.team_lead_grade}/{item.weight}
                              </div>
                            </div>
                            <div className='flex gap-2 items-center'>
                              <div className='font-bold text-[13px]'>
                                Employee Grade:
                              </div>
                              <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                {item.employee_grade}/{item.weight}
                              </div>
                            </div>
                            <div className='flex gap-2 items-center'>
                              <div className='font-bold text-[13px]'>
                                HR Grade:
                              </div>
                              <div className='text-[13px] text-hr-secondary-1 font-extrabold'>
                                {item.hr_grade}/{item.weight}
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                  <div className='flex flex-col gap-2 mt-2'>
                    <div className='flex flex-col gap-2'>
                      <div className='flex flex-col p-4 py-2 border rounded w-full bg-highlight'>
                        <span className='text-color-gray text-[13px]'>
                          HR Comments:
                        </span>
                        <div className='flex text-black text-[13px] h-fit'>
                          {performance?.technical_appraisal?.hr_comment}
                        </div>
                      </div>
                      <div className='flex flex-col p-4 py-2 border rounded w-full bg-highlight'>
                        <span className='text-color-gray text-[13px]'>
                          Employee Comments:
                        </span>
                        <div className='flex text-black text-[13px] h-fit'>
                          {performance?.technical_appraisal?.employee_comment}
                        </div>
                      </div>
                      <div className='flex flex-col p-4 py-2 border rounded w-full bg-highlight'>
                        <span className='text-color-gray text-[13px]'>
                          Team Lead Comments:
                        </span>
                        <div className='flex text-black text-[13px] h-fit'>
                          {performance?.technical_appraisal?.team_lead_comment}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className='divider -mt-3' />
              </>
            ) : null}
          </div>
        )}
        <div className='p-4 py-2 border rounded w-full -mt-2'>
          <FormTextArea
            label={'Training Requirement'}
            readOnly={false}
            value={performance.training_requirement}
          />
        </div>
        <div className='p-4 py-2 border rounded w-full'>
          <FormTextArea
            label={'Reviewer Comment'}
            readOnly={false}
            value={performance.reveiwer_comment}
          />
        </div>
      </div>
      <a
        href='https://www.ropay.africa'
        target='_blank'
        className='flex w-full flex-col justify-center items-center p-2 gap-2 text-black'
      >
        <span>Powered By:</span>
        <Logo />
      </a>
    </div>
  );
};
