import React, { useState } from 'react';
import {
  UncontrolledDropdown,
} from 'reactstrap';
import Info from './info';
import ChooseFileSVG from '../assets/svg/choose-file.svg';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const FormFileInput = ({
  id,
  name,
  label,
  multiSource = false,
  placeholder,
  accept,
  value,
  inputRef,
  onChange,
  onKeyDown,
  readOnly,
  error,
  errorMessage,
  infos,
  ...rest
}) => {

  const [fileName, setFileName] = useState('')
  const { section } = useSelector((state) => state.sectionSlice);
  
  return (
    <>
      {multiSource ? (
        <div className={'mt-4 mb-2 w-full h-[120px] bg-highlight flex justify-center items-center flex-col rounded-md border'}
        >
          <div className='min-w-full'>
            <UncontrolledDropdown group className='flex flex-row h-[45px]'>
              <div className='file-select-wrapper w-[100%]'>
                <div
                  className={classNames('cursor-pointer w-full rounded-md border-r border-r-color-white  flex flex-row justify-center px-3 items-center h-full bg-secondary-2', {
                    '!bg-hr-secondary-1' : section && section.name === 'core hr',
                    '!bg-spend-primary-1' : section && section.name === 'spend management',
                  })}
                >
                  <img src={ChooseFileSVG} alt='' />
                  <span className='ml-2 text-color-white cursor-pointer text-[13px]'>Choose File</span>
                </div>
                <input
                  type='file'
                  accept={accept}
                  ref={inputRef}
                  onChange={(e) => {
                    setFileName(e.target.value)
                  }}
                  onKeyDown={onKeyDown}
                  readOnly={readOnly ? true : false}
                  value={value}
                  {...rest}
                  name='file'
                />
              </div>
            </UncontrolledDropdown>
          </div>
          <span className='max-w-[100%] flex flex-wrap text-[12px] mt-2'> {fileName?fileName.split("\\")[2]:'or drop file here'}</span>
          {error && <span className='text-red-600'>* {errorMessage}</span>}
          {infos && <Info infos={infos} />}
        </div>
      ) : (
        <div className='pb-[20px] w-full'>
          <div className='flex flex-col items-start'>
            {label && (
              <label className='text-[13px] leading-[23px] font-normal text-color-gray'>
                {label}
              </label>
            )}
            <input
              type='file'
              accept={accept}
              ref={inputRef}
              className={classNames(
                'file-input bg-color-off-white rounded-md h-[65px] p-[20px] border-[0.5px] border-color-gray mt-1 w-full text-[13px]',
                {
                  'file-input': !section || section.name === 'payroll',
                  'spend-file-input': section.name == 'spend management',
                  'hr-file-input': section.name == 'core hr',
                },
              )}
              onChange={(e) => {
                setFileName(e.target.value)
              }}
              onKeyDown={onKeyDown}
              readOnly={readOnly ? true : false}
              value={value}
              {...rest}
              name='file'
            /> 
          </div>
          {error && <span className='text-red-600'>* {errorMessage}</span>}
          {infos && <Info infos={infos} />}
        </div>
      )}
    </>
  );
};

export default FormFileInput;
