import React from 'react';
import { CountryFlag } from './flags';
import { Initials } from '../../utils/functions';

export const PersonProfile = ({ photo_url, first_name='Adedokun', last_name='Agunbiade', country='nigeria' }) => {
  return (
    <div className='text-[18px] flex font-bold justify-center items-center h-[30px] w-[30px] rounded-md border-[2px] header-3 border-secondary-2 bg-green-200 hover:scale-110 hover:p-4 duration-500 select-none relative'>
    {photo_url ? (
    <div>
        <img
        src={photo_url}
        alt='icon'
        className='object-fit min-w-[30px] min-h-[30px] max-w-[30px] max-h-[30px] rounded-md'
        />
    </div>
    ) : (
    <>{Initials(first_name, last_name)}</>
    )}
    {country?
    <CountryFlag country={country.toLowerCase()} className={'min-w-[15px] min-h-[15px] max-w-[15px]'} />
    :
    null
    }
    
</div>
  );
};
