import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../../utils/functions";
import Button from "../../../button";
import FormFileInput from "../../../form-file-input";
import CloseSVG from "../../../../assets/svg/close.svg";
import {
  useGetVendorsForPayment,
  useRunVendorPayExcel,
} from "../../../../redux/spend-management/vendor/hook";
import FormInput from "../../../form-input";
import WarningSVG from "../../../../assets/svg/warning.svg";
import { useHistory } from "react-router-dom";
import Modal from "../../modal";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { vendors as vendorAPI } from "../../../../api/spend/vendor";
import FormSelect from "../../../form-select";
import AsyncSelect from "react-select/async";

function BulkPayVendors({ isOpen, closeModal, refresh, completed }) {
  const { mutateAsync, isLoading: bulkLoading } = useRunVendorPayExcel();
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const [exportType, setexportType] = useState("all");

  const { mutateAsync: vendorsForPayment } = useGetVendorsForPayment();

  const typeOptions = [
    {
      value: "all",
      label: "All Vendors",
    },
    {
      value: "select",
      label: "Selected Vendors",
    },
  ];

  const [vendors, setVendors] = useState([]);

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      vendorAPI
        .getVendors({
          limit: 10,
          page: 1,
          statusFilter: -1,
          search: inputValue,
        })
        .then((response) => {
          let data = [];
          response.data.forEach((item) => {
            data.push({
              label: `${item.vendor_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const columns = [
    { header: "Vendor Name", key: "vendor_name" },
    { header: "Contact Person", key: "contact_person" },
    { header: "Email", key: "email" },
    { header: "Phone Number", key: "mobile" },
    { header: "Bank Name", key: "bank" },
    { header: "Account Number", key: "account_number" },
    { header: "Description", key: "description" },
    { header: "Amount", key: "amount" },
  ];

  const columns2 = [{ header: "List of Banks", key: "bank_name" }];

  const [exportLoading, setSetExportLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const exportTemplate = async () => {
    setSetExportLoading(true);
    await vendorsForPayment().then(async (response) => {
      let data = response?.data ? response.data : [];
      const workSheetName = "vendor_payment_template";
      const workBookName = `Bulk Vendor Payment Template`;
      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        worksheet.columns.forEach((column) => {
          if (column._number !== 8) {
            column.width = 25;
            column.numFmt = "@";
          } else {
            column.width = 18;
            column.style.alignment = { horizontal: "right" };
            column.numFmt = "0.00";
          }
        });

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };
        // loop through all of the rows and set the outline style.

        let bank_data = [
          { bank_name: "Access Bank" },
          { bank_name: "Access Bank (Diamond)" },
          { bank_name: "AccessMobile" },
          { bank_name: "Accion Microfinance Bank" },
          { bank_name: "ALAT by WEMA" },
          { bank_name: "ASO Savings and Loans" },
          { bank_name: "Cellulant" },
          { bank_name: "Citi Bank" },
          { bank_name: "Carbon" },
          { bank_name: "eTranzact" },
          { bank_name: "Ecobank Nigeria" },
          { bank_name: "Ecobank Xpress Account" },
          { bank_name: "Eyowo MFB" },
          { bank_name: "EcoMobile" },
          { bank_name: "Enterprise Bank" },
          { bank_name: "FBN Mortgages Limited" },
          { bank_name: "FIRSTMONIE" },
          { bank_name: "FBNQuest Merchant Bank" },
          { bank_name: "First City Monument Bank" },
          { bank_name: "FCMB Easy Account" },
          { bank_name: "Fidelity Bank" },
          { bank_name: "Fidelity Mobile" },
          { bank_name: "First Bank PLC" },
          { bank_name: "Fortis Microfinance Bank" },
          { bank_name: "FORTIS MOBILE" },
          { bank_name: "FSDH Merchant Bank" },
          { bank_name: "Flutterwave Technology Solutions Limited" },
          { bank_name: "Globus Bank" },
          { bank_name: "GTMobile" },
          { bank_name: "Guaranty Trust Bank" },
          { bank_name: "Heritage Bank" },
          { bank_name: "HOPE PAYMENT SERVICE BANK" },
          { bank_name: "Jaiz Bank" },
          { bank_name: "Keystone Bank" },
          { bank_name: "Kuda Bank" },
          { bank_name: "MIMO (Mkudi)" },
          { bank_name: "MoneyBox" },
          { bank_name: "MONIEPOINT MICROFINANCE BANK" },
          { bank_name: "Momo Payment Service Bank" },
          { bank_name: "New Prudential Bank" },
          { bank_name: "One Finance" },
          { bank_name: "Opay Digital Services/Paycom" },
          { bank_name: "Paga" },
          { bank_name: "Page Financials" },
          { bank_name: "PALMPAY" },
          { bank_name: "Parralex Microfinance bank" },
          { bank_name: "Parallex Bank" },
          { bank_name: "Parkway-ReadyCash" },
          { bank_name: "PARKWAY MICROFINANCE BANK" },
          { bank_name: "PayAttitude Online" },
          { bank_name: "Polaris Bank" },
          { bank_name: "PremiumTrust Bank" },
          { bank_name: "Providus Bank" },
          { bank_name: "PURPLEMONEY MICROFINANCE BANK" },
          { bank_name: "Reliance Microfinance Bank" },
          { bank_name: "RenMoney Microfinance Bank" },
          { bank_name: "Rubies Microfinance Bank" },
          { bank_name: "Sparkle Microfinance Bank" },
          { bank_name: "Stanbic Ease Wallet" },
          { bank_name: "Stanbic IBTC Bank" },
          { bank_name: "Standard Chartered Bank" },
          { bank_name: "Sterling Bank" },
          { bank_name: "Suntrust Bank" },
          { bank_name: "TAJ Bank" },
          { bank_name: "Tangerine Bank" },
          { bank_name: "TagPay" },
          { bank_name: "Titan Trust Bank" },
          { bank_name: "Trident Microfinance Bank" },
          { bank_name: "Union Bank PLC" },
          { bank_name: "United Bank For Africa" },
          { bank_name: "Unity Bank" },
          { bank_name: "VFD Micro Finance Bank" },
          { bank_name: "Wema Bank" },
          { bank_name: "Zenith Bank" },
          { bank_name: "ZENITH EAZY WALLET" },
          { bank_name: "ZenithMobile" },
        ];

        if (exportType == "all") {
          data?.forEach((singleData) => {
            singleData.description = "";
            singleData.amount = "";
            worksheet.addRow(singleData);
          });
        } else if (exportType == "select") {
          vendors.forEach((vendor) => {
            data?.forEach((singleData) => {
              if (vendor.value == singleData.id) {
                singleData.description = "";
                singleData.amount = "";

                worksheet.addRow(singleData);
              }
            });
          });
        }
        const worksheet2 = workbook.addWorksheet("Banks List");
        worksheet2.columns = columns2;
        worksheet2.getRow(1).font = { bold: true };

        bank_data?.forEach((singleData) => {
          worksheet2.addRow(singleData);
        });

        worksheet.eachRow({ includeEmpty: true }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;
            worksheet.getCell(cellAddress).border = {
              top: { style: "none" },
              left: { style: "none" },
              bottom: { style: "none" },
              right: { style: "none" },
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        // console.error('<<<ERRROR>>>', error);
        // console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  const [description, setDescription] = useState("");

  const history = useHistory();

  function onrunVendorSuccess(ref) {
    history.push(`/vendor/pay/preview/${ref}`);
  }

  async function submitForm(data) {
    const formData = new FormData();
    formData.append("file", data?.file[0]);
    formData.append("description", description);
    formData.append("validate", 0);

    await mutateAsync(formData).then((result) => {
      onrunVendorSuccess(result.data.ref);
    });
  }

  return (
    <Modal
      scrollable={true}
      className="!max-w-[700px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col overflow-auto">
        <div className="pt-4 pr-8 pl-8">
          <div className="flex flex-row justify-between items-center">
            <span className="header-4">Pay Multiple Vendor(s)</span>
            <div className="cursor-pointer" onClick={() => completed()}>
              <img src={CloseSVG} alt="close" />
            </div>
          </div>
        </div>
        <hr className="divider" />
        <form className="form p-8 pt-2" onSubmit={handleSubmit(submitForm)}>
          <div className="flex flex-col border p-2 bg-highlight rounded-md">
            <FormSelect
              value={exportType}
              options={typeOptions}
              onChange={(selected) => {
                setexportType(selected);
              }}
              label="Select Template Export Type"
              extraClass={"bg-white"}
            />
            <div>
              {exportType === "select" ? (
                <div>
                  <label className="!text-[13px] leading-[23px] font-normal text-color-gray mb-1">
                    Select Vendors
                  </label>
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    className="text-[13px]"
                    placeholder={"Type to select multiple vendors"}
                    loadOptions={loadOptions}
                    onChange={(options) => {
                      setVendors(options);
                    }}
                    classNamePrefix="mySelect"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <hr className="divider mt-2" />
          <>
            <div className="flex flex-col text-center justify-center items-center">
              <span className="text-[16px] font-extrabold mb-2">
                Upload vendors from XLSX file for payment
              </span>
              <div className=" flex flex-col border p-4 rounded-md bg-highlight mt-2 mb-3 justify-center items-center w-full">
                <Button
                  text="Download template"
                  type="button"
                  theme="primary"
                  loading={exportLoading}
                  className="flex gap-2 !h-[35px] w-[220px] p-2 "
                  textClass={"!text-[11px]"}
                  onClick={() => {
                    exportTemplate();
                  }}
                />
              </div>

              <FormFileInput
                multiSource={false}
                inputRef={register(formValidation("file", true))}
                accept=".xlsx"
              />
            </div>
            <div className="w-full ">
              <FormInput
                label="Description"
                name="description"
                type="text"
                placeholder={"Enter a brief description for this payment batch"}
                value={description}
                error={errors.description}
                errorMessage={errors.description && errors.description.message}
                onInput={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-row px-2 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light">
              <div className="w-[70px] pr-2 flex flex-row items-center ">
                <img src={WarningSVG} alt="" srcSet="" />
              </div>
              <span className="text-[13px]">
                Please note this method does not validate the account
                information provided. Ensure the right information is provided.
              </span>
            </div>
            <div className="w-full mt-[40px]">
              <Button
                text="UPLOAD"
                type="submit"
                loading={bulkLoading}
                disabled={!isValid}
              />
            </div>
          </>
        </form>
      </div>
    </Modal>
  );
}

export default BulkPayVendors;
