import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import FormInput from "../../../components/form-input";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../utils/functions";
import DOMPurify from "dompurify";
import EmployeeRecruitmentLayout from "../../../components/layouts/employee-recruitment";
import FormSelect from "../../../components/form-select";
import { useDispatch, useSelector } from "react-redux";
import { setAssignWorkflow } from "../../../redux/human-resources/reducers/recruitment";
import FormSwitch from "../../../components/form-switch";
import { useGetCompanySelectAdmins } from "../../../redux/company/hook";
import UnauthorizedPage from "../../errors/unauthorized";

function RecruitmentDetailsPage() {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.recruitment_write !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const { data: companyAdmins } = useGetCompanySelectAdmins();

  const [emailReceiverId, setEmailReceiverId] = useState(null);

  const { assignWorkflowData } = useSelector((state) => state.recruitmentSlice);

  useEffect(() => {
    if (assignWorkflowData) {
      setRole(assignWorkflowData.role);
      setApplicationEnd(assignWorkflowData.application_end);
      setJobType(assignWorkflowData.job_type);
      setLocationType(assignWorkflowData.location_type);
      setEducationLevel(assignWorkflowData.min_education);
      setMinSalary(assignWorkflowData.min_salary);
      setMaxSalary(assignWorkflowData.max_salary);
      setExperienceLevel(assignWorkflowData.experience_level);
      setSalaryType(assignWorkflowData.salary_type);
      setYearsOfExperience(assignWorkflowData.years_of_experience);
      setEmailNotification(
        assignWorkflowData.email_notification === 1 ? true : false
      );
      setEmailReceiverId(assignWorkflowData.email_receiver_id);
    }
  }, [assignWorkflowData]);

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });
  const [jobType, setJobType] = useState("Full-time");

  const jobTypeOptions = [
    { value: "Full-time", label: "Full-time" },
    { value: "Part-time ", label: "Part-time" },
  ];

  const [role, setRole] = useState();
  const [applicationEnd, setApplicationEnd] = useState();
  const [yearsOfExperience, setYearsOfExperience] = useState();

  const [locationType, setLocationType] = useState("On Site");

  const [minSalary, setMinSalary] = useState();
  const [maxSalary, setMaxSalary] = useState();

  const [emailNotification, setEmailNotification] = useState(false);

  const locationOptions = [
    { value: "On site", label: "On site" },
    { value: "Remote", label: "Remote" },
    { value: "Hybrid", label: "Hybrid" },
  ];

  const [educationLevel, setEducationLevel] = useState("High School");

  const educationLevelOptions = [
    { value: "High School", label: "High School" },
    { value: "Diploma", label: "Diploma" },
    { value: "Degree", label: "Degree" },
    { value: "MSc.", label: "MSc." },
    { value: "MBA", label: "MBA" },
    { value: "PhD.", label: "PhD." },
  ];

  const [experienceLevel, setExperienceLevel] = useState("Entry");

  const experienceLevelOptions = [
    { value: "Entry", label: "Entry" },
    { value: "Intern", label: "Intern" },
    { value: "Mid-Level", label: "Mid-Level" },
    { value: "Senior-Level", label: "Senior-Level" },
    { value: "Management", label: "Management" },
  ];

  const [salaryType, setSalaryType] = useState("Net Salary");

  const SalaryOptions = [
    { value: "Net Salary", label: "Net Salary" },
    { value: "Gross Salary", label: "Gross Salary" },
  ];

  const submitForm = async () => {
    // if (emailNotification && emailReceiverId === null) {
    //   dispatch(setAlert(true, 'error', 'Please select who receives email'));
    //   return;
    // }
    const requestData = {
      min_salary: DOMPurify.sanitize(minSalary),
      max_salary: DOMPurify.sanitize(maxSalary),
      role: DOMPurify.sanitize(role),
      location_type: DOMPurify.sanitize(locationType),
      years_of_experience: DOMPurify.sanitize(yearsOfExperience),
      salary_type: DOMPurify.sanitize(salaryType),
      experience_level: DOMPurify.sanitize(experienceLevel),
      min_education: DOMPurify.sanitize(educationLevel),
      job_type: DOMPurify.sanitize(jobType),
      application_end: DOMPurify.sanitize(applicationEnd),
      email_notification: emailNotification ? 1 : 0,
      email_receiver_id: emailReceiverId ? parseInt(emailReceiverId) : 0,
    };

    dispatch(setAssignWorkflow({ ...assignWorkflowData, ...requestData }));

    history.push("/recruitment/job-description");
  };

  return (
    <EmployeeRecruitmentLayout step={1} title={"Add Recruitment Details"}>
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col w-full  h-full">
          <span className="md:flex hidden header-3">
            Enter Recruitment Details
          </span>
          <hr className="divider mt-2" />
          <div className="">
            <form className="form " onSubmit={handleSubmit(submitForm)}>
              <div className="flex flex-col md:flex-row justify-between mt-2">
                <div className="md:w-2/3 md:pr-2">
                  <FormInput
                    label="Job Role"
                    placeholder="Enter role here"
                    name="job_role"
                    value={role}
                    onInput={(e) => {
                      setRole(e.target.value);
                    }}
                    type="text"
                    error={errors.job_role}
                    errorMessage={errors.job_role && errors.job_role.message}
                  />
                </div>
                <div className="md:w-1/3 md:pl-2">
                  <FormSelect
                    value={jobType}
                    options={jobTypeOptions}
                    onChange={(selected) => {
                      setJobType(selected);
                    }}
                    label="Job Type"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between mt-2">
                <div className="md:w-1/2 md:pr-2">
                  <FormSelect
                    value={experienceLevel}
                    options={experienceLevelOptions}
                    onChange={(selected) => {
                      setExperienceLevel(selected);
                    }}
                    label=" Experience level"
                  />
                </div>
                <div className="md:w-1/2 md:pl-2">
                  <FormInput
                    label="Years Of Experience - (Optional)"
                    name="years_of_experience"
                    type="number"
                    value={yearsOfExperience}
                    onInput={(e) => {
                      setYearsOfExperience(e.target.value);
                    }}
                    inputRef={register(formValidation("number", true))}
                    error={errors.years_of_experience}
                    errorMessage={
                      errors.years_of_experience &&
                      errors.years_of_experience.message
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormSelect
                    value={locationType}
                    options={locationOptions}
                    onChange={(selected) => {
                      setLocationType(selected);
                    }}
                    label="Location Type"
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    placeholder="Application End"
                    name="application_end"
                    label="Application Ends?"
                    type="date"
                    value={applicationEnd}
                    onInput={(e) => {
                      setApplicationEnd(e.target.value);
                    }}
                    inputRef={register(formValidation("date", true))}
                    error={errors.application_end}
                    errorMessage={"Application end is required"}
                  />
                </div>
              </div>
              <span className="text-color-gray text-[13px]">
                Salary Range (Monthly) - (Optional)
              </span>
              <div className="flex flex-row justify-between items-center -mt-2">
                <div className="w-1/2 md:pr-2">
                  <FormInput
                    name="min_salary"
                    placeholder="Minimum Salary"
                    type="number"
                    value={minSalary}
                    onInput={(e) => setMinSalary(e.target.value)}
                    inputRef={register(formValidation("number", false))}
                    error={minSalary > maxSalary && minSalary}
                    errorMessage={"Minimum Salary Cannot be greater"}
                  />
                </div>
                <div className="w-1/2 md:pl-2 ">
                  <FormInput
                    placeholder="Maximum Salary"
                    name="max_salary"
                    type="number"
                    inputRef={register(formValidation("number", false))}
                    value={maxSalary}
                    onInput={(e) => setMaxSalary(e.target.value)}
                    error={minSalary > maxSalary && maxSalary}
                    errorMessage={"Maximum Salary Cannot be less"}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="md:w-1/2 md:pr-2">
                  <FormSelect
                    value={salaryType}
                    options={SalaryOptions}
                    onChange={(selected) => {
                      setSalaryType(selected);
                    }}
                    label="Salary Type"
                  />
                </div>
                <div className="md:w-1/2 md:pl-2 ">
                  <FormSelect
                    value={educationLevel}
                    options={educationLevelOptions}
                    onChange={(selected) => {
                      setEducationLevel(selected);
                    }}
                    label="Minimum Education"
                  />
                </div>
              </div>
              {companyAdmins ? (
                <>
                  <hr className="divider mt-1" />
                  <div className={"flex w-[100%] flex-col items-center"}>
                    <div className="w-full ">
                      <FormSwitch
                        label={"Receive Application Email?"}
                        name={"email_notification"}
                        checked={emailNotification}
                        onClick={() => {
                          setEmailNotification(!emailNotification);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      {companyAdmins && emailNotification ? (
                        <FormSelect
                          options={companyAdmins}
                          value={emailReceiverId}
                          readOnly={companyAdmins ? false : true}
                          onChange={(selected) => {
                            setEmailReceiverId(selected);
                          }}
                          label="Who Receives Emails?"
                        />
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
              <div className="w-full mt-[20px]">
                <Button
                  text="Save and Continue"
                  type="submit"
                  theme={"third"}
                />
              </div>
              <div
                className="flex justify-center w-full cursor-pointer"
                onClick={() => history.push("/recruitment")}
              >
                <span className="p2-medium text-color-black mt-3">
                  Back To Dashboard
                </span>
              </div>
            </form>
          </div>
        </div>
      )}
    </EmployeeRecruitmentLayout>
  );
}

export default RecruitmentDetailsPage;
