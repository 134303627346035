import { Axios } from '../../axios';

const getNHF = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/statutory-payments/nhf?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const bulkNHF = async (payload) => {
  const { data } = await Axios.post('/statutory-payments/nhf/bulk', payload);
  return data;
};

const fetchNHFByRef = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(`/statutory-payments/nhf/by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}${search}`);
  if (data?.data) {
    data.data.nhfs.total_amount = data?.data.total_amount
    data.data.nhfs.total_payable = data?.data.total_payable
    data.data.nhfs.total_trans_cost = data?.data.total_fees
  }
  return data?.data.nhfs;
};

export const nhfApis = {
    getNHF,
    bulkNHF,
    fetchNHFByRef,
};