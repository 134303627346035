import { Axios } from '../../axios';

const getITF = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/statutory-payments/itf?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const bulkITF = async (payload) => {
  const { data } = await Axios.post('/statutory-payments/itf/bulk', payload);
  return data;
};

const fetchITFByRef = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(`/statutory-payments/itf/by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}${search}`);
  if (data?.data) {
    data.data.itfs.total_amount = data?.data.total_amount
    data.data.itfs.total_payable = data?.data.total_payable
    data.data.itfs.total_trans_cost = data?.data.total_fees
  }
  return data?.data.itfs;
};

export const itfApis = {
    getITF,
    bulkITF,
    fetchITFByRef
};