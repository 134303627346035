import classNames from "classnames";
import React, { useState } from "react";
import CollapsingIcon from "../collapsing-icon";
import { useEffect } from "react";
import { useGetTeams } from "../../redux/human-resources/hook/teams";

const FormSelectTeam = ({
  id,
  label,
  className,
  readOnly = false,
  value,
  teamName,
  error,
  errorMessage,
  theme,
  setTeam,
  setSourceName = false,
  inputRef,
  ...rest
}) => {
  useEffect(() => {}, []);

  const [text, setText] = useState("");
  const [search, setSearch] = useState("");
  const [showResults, setShowResult] = useState(false);

  const params = {
    limit: 5000,
    page: 1,
    statusFilter: -1,
  };

  const { data: teams, status } = useGetTeams(
    params.limit,
    params.page,
    search
  );

  useEffect(() => {
    if (teamName) {
      setText(teamName);
    }
  }, [teamName]);

  useEffect(() => {
    if (status === "success" && search.trim()) {
      setShowResult(true);
    }
  }, [status, search]);

  function handleItemSelect(item) {
    setText(item.name);
    if (setSourceName) {
      setSourceName(item.name);
    }
    setTeam(item.id);
    setShowResult(false);
  }

  return (
    <div className="pb-[20px] flex flex-col">
      <div className="flex w-full flex-col">
        {label && (
          <label className="text-[13px] leading-[23px] font-normal text-color-gray -mb-2">
            {label}
          </label>
        )}
        <div>
          <div
            className="flex items-center justify-center min-h-fit"
            id="datalist"
          >
            <input
              id="datalist-input"
              type="text"
              autoComplete="off"
              ref={inputRef}
              readOnly={readOnly}
              onChange={(event) => {
                setText(event.target.value);
                setSearch(event.target.value);
              }}
              className={classNames("!max-h-[35px] !text-[13px]", {
                "!bg-gray-100": readOnly,
              })}
              value={text}
              {...rest}
            />
            <i id="datalist-icon" className="icon iconfont">
              <CollapsingIcon />
            </i>
          </div>
          {/* Result List */}
          {teams && teams.data && !readOnly ? (
            <ul
              className={classNames(
                "datalist-items px-0 mt-2 rounded-sm border border-secondary-2 !z-[10000] bg-white w-full",
                {
                  hidden: teams.data.length === 0 || !showResults,
                }
              )}
            >
              {teams.data.map((item, index) => (
                <li
                  key={index}
                  className={`flex flex-row justify-between py-2 cursor-pointer text-[14px] hover:bg-secondary-2-extralight px-3 rounded-sm `}
                  onClick={() => handleItemSelect(item)}
                >
                  <div className="flex flex-row items-center text-inherit">
                    <span className="text-inherit">{item.name}</span>
                  </div>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
      {error && <span className="text-error">* {errorMessage}</span>}
    </div>
  );
};

export default FormSelectTeam;
