import React from 'react';
import { useForm } from 'react-hook-form';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormFileInput from '../../../form-file-input';
import CloseSVG from '../../../../assets/svg/close.svg';
import Modal from '../../modal';
import { useCreateBulkCustomers } from '../../../../redux/spend-management/customer/hook';

function BulkAddCustomers({ isOpen, closeModal, refresh, completed }) {
  const { mutateAsync: addBulkCustomers, isLoading } = useCreateBulkCustomers();
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  async function submitForm(data) {
    const formData = new FormData();
    formData.append('file', data?.file[0]);

    await addBulkCustomers(formData).then(() => {
      completed();
    });
  }

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto pb-4'>
        <div className='pt-4 pr-8 pl-8'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>Add Customer(s)</span>
            <div className='cursor-pointer' onClick={() => completed()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <form className='form h-fit flex flex-col px-8 ' onSubmit={handleSubmit(submitForm)}>
          <div className='flex flex-col text-center justify-center items-center'>
            <span className='text-[16px] font-extrabold'>
              Upload Customers from XLSX file
            </span>
            <div className=' flex flex-col border p-4 rounded-md bg-highlight mt-2 mb-3 justify-center items-center w-full'>
              <button className='h-[35px] border-2 text-color-white rounded min-w-[210px] text-[13px] bg-spend-primary-1 uppercase font-semibold'>
                <a
                  href='https://ropay-excel-temporary-prod.s3.amazonaws.com/ropay_bulk_customer_template.xlsx'
                  target='_blank'
                  rel='noreferrer'
                >
                  Download template
                </a>
              </button>
            </div>
            <FormFileInput
              multiSource={true}
              inputRef={register(formValidation('file', true))}
              accept='.xlsx'
            />
          </div>

          <div className='w-full mt-4'>
            <Button
              text='UPLOAD'
              type='submit'
              diabled={!isValid}
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default BulkAddCustomers;
