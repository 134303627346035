import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import DOMPurify from "dompurify";
import FormInput from "../../components/form-input";
import Button from "../../components/button";
import { formValidation } from "../../utils/functions";
import WaitlistLayout from "../../components/layouts/waitlist";
import { useSelector } from "react-redux";
import { useJoinWaitlist } from "../../redux/mobile/hooks";
import { useHistory } from "react-router-dom";

const MobileWaitlistPage = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { section } = useSelector((state) => state.sectionSlice);

  const { mutate: joinWaitList, isLoading: loading } = useJoinWaitlist();

  useEffect(() => {}, []);

  const history = useHistory();

  const submitForm = async (data) => {
    const first_name = DOMPurify.sanitize(data?.first_name);
    const last_name = DOMPurify.sanitize(data?.last_name);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const email = DOMPurify.sanitize(data?.email);

    const requestData = {
      first_name,
      last_name,
      mobile,
      email,
    };
    joinWaitList(requestData).then(() => {
      history.push("/");
    });
  };

  return (
    <WaitlistLayout>
      <>
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <div className="w-full text-center flex flex-col pb-[25px]">
            <span
              className={
                section && section.name === "core hr"
                  ? "header-3-regular md:header-2 text-hr-primary-1"
                  : "header-3-regular md:header-2 text-secondary-2"
              }
            >
              Join the Waitlist
            </span>
            <span className="text-[15px] text-color-gray pt-1">
              Get early access and be one of the first to know when we launch.
            </span>
          </div>
          <FormInput
            label="First name"
            name="first_name"
            type="text"
            inputRef={register(formValidation("text", true))}
            readOnly={loading}
            error={errors.first_name}
            errorMessage={errors.first_name && errors.first_name.message}
          />
          <FormInput
            label="Last name"
            name="last_name"
            type="text"
            inputRef={register(formValidation("text", true))}
            readOnly={loading}
            error={errors.last_name}
            errorMessage={errors.last_name && errors.last_name.message}
          />

          <FormInput
            label="Phone number"
            name="mobile"
            type="text"
            inputRef={register(formValidation("text", true))}
            readOnly={loading}
            error={errors.mobile}
            errorMessage={errors.mobile && errors.mobile.message}
          />
          <FormInput
            label="Email address"
            name="email"
            type="email"
            inputRef={register(formValidation("email", true))}
            readOnly={loading}
            error={errors.email}
            errorMessage={errors.email && errors.email.message}
          />
          <Button
            text="Join"
            type="submit"
            loading={loading}
            disabled={!isValid}
          />
        </form>
      </>
    </WaitlistLayout>
  );
};

export default MobileWaitlistPage;
