import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import {
    useAddSalaryAdvanceApplication,
} from '../../../redux/employees/hook/salary-advance';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import Modal from '../modal';
import FormSelectEmployee from '../../employees/form-select-employee';
import FormSwitch from '../../form-switch';
import FormOtpInput from '../../form-otp-input';
import { useSelector } from 'react-redux';
import moment from 'moment';

function AddSalaryAdvanceApplication({
  isOpen,
  closeModal,
}) {
    const {
        register,
        handleSubmit,
        formState: { isValid },
        errors,
    } = useForm({
        mode: 'onChange',
    });
    const { company } = useSelector((state) => state.companySlice);
    const { user } = useSelector((state) => state.userSlice);

    const [employee, setEmployee] = useState();
    const [employeeName, setEmployeeName] = useState();
    const [payNow, setPayNow] = useState(false);
    const [pin, setPIN] = useState('');
    const { mutateAsync: addSalaryAdvanceApplication, isLoading: addSalaryAdvanceloading } = useAddSalaryAdvanceApplication();
    const [month, setMonth] = useState(moment().format('MM'));
    const [year, setYear] = useState(moment().format('YYYY-'));
    
    const submitForm = async (data) => {
        const amount = DOMPurify.sanitize(data?.amount);
        const purpose = DOMPurify.sanitize(data?.purpose);
        const duration = DOMPurify.sanitize(data?.duration);

        let payload = {
            employee_id: employee,
            duration,
            amount,
            purpose,
            repayment_start: year + month,
            paynow: payNow ? 1 : 0,
            status: 1
        };
        await addSalaryAdvanceApplication(payload).then(() => {
        closeModal();
        });
    };

    return (
        <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
        >
            <div className='flex flex-col overflow-auto '>
                <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
                <div className='pl-8 pr-8 pt-8'>
                    <div className='flex flex-row justify-between'>
                    <span className='header-3'>Add Salary Advance Application</span>
                    <div className='cursor-pointer' onClick={() => closeModal()}>
                        <img src={CloseSVG} alt='close' />
                    </div>
                    </div>
                </div>
                <hr className='divider' />
                <div className='flex flex-col mx-8'>
                    <div className='border bg-highlight p-2 rounded-md mb-2'>
                        <FormSelectEmployee
                            label={'Employee'}
                            name='employee'
                            placeholder='Type to select Employee'
                            value={employee}
                            empName={employeeName}
                            setEmployee={setEmployee}
                            setSourceName={setEmployeeName}
                        />
                    </div>
                    <hr className='divider mb-2 mt-2' />
                    <div>
                    <div className='flex flex-row md:flex-col w-full justify-between'>
                        <div className='w-full md:w-1/2 pr-2'>
                        <FormSelect
                            value={month}
                            options={monthOptions}
                            onChange={(selected) => {
                            setMonth(selected);
                            }}
                            label='Repayment start month'
                        />
                        </div>

                        <div className='w-full md:w-1/2 pl-2'>
                        <FormSelect
                            value={year}
                            options={yearOptions}
                            onChange={(selected) => {
                            setYear(selected);
                            }}
                            label='Repayment start year'
                        />
                        </div>
                    </div>
                    <FormInput
                        label='Purpose'
                        name='purpose'
                        type='text'
                        placeholder='Enter salary advance purpose'
                        inputRef={register(formValidation('text', true))}
                        readOnly={addSalaryAdvanceloading}
                        error={errors.purpose}
                        errorMessage={errors.purpose && errors.purpose.message}
                    />
                    <div className='flex flex-row md:flex-col w-full justify-between'>
                        <div className='w-full md:w-1/2 pr-2'>
                            <FormInput
                                label='Loan Duration (Months)'
                                name='duration'
                                type='number'
                                inputRef={register(formValidation('number', true))}
                                readOnly={addSalaryAdvanceloading}
                                error={errors.duration}
                                errorMessage={errors.duration && errors.duration.message}
                            />
                        </div>

                        <div className='w-full md:w-1/2 pl-2'>
                        <FormInput
                            label='Amount'
                            inputMode='numeric'
                            name='amount'
                            type='number'
                            inputRef={register(formValidation('number', true))}
                            readOnly={addSalaryAdvanceloading }
                            error={errors.amount}
                            errorMessage={errors.amount && errors.amount.message}
                        />
                        </div>
                    </div>
                    </div>
                    <div className='w-full mt-2'>
                        <FormSwitch
                            label={'Do you want to pay now?'}
                            name={'paynow'}
                            checked={payNow}
                            onClick={() => setPayNow(!payNow)}
                            value={payNow}
                        />
                    </div>

                    <div className='flex flex-col gap-3 w-full mt-2'>
                        {!payNow?
                        <Button
                            text={'Save'}
                            type='submit'
                            loading={addSalaryAdvanceloading}
                        />
                        :
                        <>
                            <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-highlight'>
                                <span className='stat-card-header'>
                                    COMPANY WALLET BALANCE{' '}
                                    <span className='!text-[13px] text-color-black font-bold'>
                                        (VFD - {company?.account_number} )
                                    </span>
                                </span>
                                <span className='!text-[16px] text-color-black font-bold'>
                                    {company?.total_balance?.toLocaleString()}
                                </span>
                            </div>
                            <hr className='divider mt-2 mb-2' />
                            <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md'>
                                <FormOtpInput
                                    value={pin}
                                    valueLength={4}
                                    onChange={(value) => setPIN(value)}
                                    label={'Enter your new 4 digit PIN'}
                                />
                            </div>
                            <Button
                                text={
                                user?.permissions.approval_permission?.initiator === 1 &&
                                user?.permissions.approval_permission?.approver === 1
                                    ? 'Pay'
                                    : 'Initiate Payment'
                                }
                                type='submit'
                                disabled={addSalaryAdvanceloading}
                                loading={
                                    addSalaryAdvanceloading
                                }
                            />
                        </>
                        }
                        
                    </div>
                </div>
                </form>
            </div>
        </Modal>
    );
}

export default AddSalaryAdvanceApplication;
