import classNames from 'classnames';
import React from 'react';

export const DueStatusText = ({ code, statusConfig, className }) => {
  return (
    <>
      {code !== undefined ? (
        <span
          className={classNames(
            className,
            'text-[11px] !min-w-fit',
            {
              'text-color-green-medium py-1 px-3 bg-color-green-light rounded border-[1px] border-color-green-medium':
                statusConfig[code].color === 'green',
            },
            {
              'text-color-orange py-1 px-3 bg-color-orange-light rounded border-[1px] border-color-orange':
                statusConfig[code].color === 'orange',
            },
            {
              'text-color-error py-1 px-3 bg-color-error-light rounded border-[1px] border-color-error':
                statusConfig[code].color === 'red',
            },
          )}
        >
          {statusConfig[code].label}
        </span>
      ) : null}
    </>
  );
};
