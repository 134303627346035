import React, { useEffect, useRef, useState } from 'react';
import TickSVG from '../../assets/svg/tick.svg';
import { useHistory } from 'react-router-dom';
import Logo from '../logo';
import ProfileMenu from '../profile-menu';

const HMOSettingsLayout = ({ children, step, title }) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);

  const steps = useRef([
    {
      title: 'Add HMO Settings',
      subtitle: 'Set up your predefined HMO settings',
      completed: false,
    },
  ]);

  useEffect(() => {
    if (step) {
      setCurrentStep(step);
    }
  }, [step, currentStep]);

  return (
    <div
      className='flex flex-col h-screen min-h-screen max-h-screen  overflow-auto'>
      <div className='flex flex-col'>
        <div className='flex justify-end'>
          <div className='mt-[20px] mr-[36px]'>
            <ProfileMenu />
          </div>
        </div>

        {/* Desktop Sidebar */}
        <div className='flex flex-col container justify-center lg:justify-start !px-[40px] lg:px-0 '>
          <div>
            <button
              className='hidden lg:flex px-4'
              onClick={() => history.push('/dashboard')}
            >
              <Logo width='130px' height='50px' />
            </button>
            <div className='flex flex-col mt-[25px]'>
              {/* Mobile Stepper */}
              <div className='flex lg:hidden justify-center w-full mb-[10px]'>
                <div className='flex flex-row w-[100%] my-3'>
                  {steps.current.map((item, index) => (
                    <div
                      key={index}
                      className={`${
                        index + 1 !== steps.current.length
                          ? 'flex flex-row w-full items-center'
                          : ''
                      }`}
                    >
                      <div>
                        <div
                          className={`flex items-center justify-center w-[30px] h-[30px] rounded-[30px] ${
                            index + 1 <= currentStep
                              ? 'bg-secondary-2'
                              : 'border border-color-black'
                          }  ${
                            index + 1 === currentStep
                              ? 'opacity-100'
                              : 'opacity-40'
                          }`}
                        >
                          {index <  currentStep-1  ? (
                            <img
                              src={TickSVG}
                              alt='tick'
                              style={{ width: '16px', height: '16px' }}
                            />
                          ) : (
                            <span
                              className={`text-[16px] font-bold ${
                                index + 1 <= currentStep
                                  ? 'text-white'
                                  : 'text-color-black'
                              }`}
                            >
                              {index + 1}
                            </span>
                          )}
                        </div>
                      </div>

                      {index + 1 !== steps.current.length ? (
                        <div className='bg-color-border w-full h-[1px]'></div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
              <div className='flex flex-col lg:flex-row w-full'>
                <div className='w-full lg:w-1/3 flex flex-col lg:justify-start justify-center'>
                  <div className='w-full lg:w-[100%] flex flex-col'>
                    {/* Header */}
                    <span className='header-3'>{title}</span>
                  </div>

                  <div className='hidden lg:flex w-full mt-5'>
                    <div className='flex flex-col w-[100%]'>
                      {steps.current.map((item, index) => (
                        <div className='flex flex-row mb-[80px] gap-8'>
                          <div className='flex items-center'>
                            <div
                              className={`flex items-center justify-center  w-[50px] h-[50px] rounded-[50px] ${
                                index + 1 <= currentStep
                                  ? 'bg-secondary-2'
                                  : 'border border-color-black'
                              }  ${
                                index + 1 === currentStep
                                  ? 'opacity-100 '
                                  : 'opacity-40 '
                              }`}
                            >
                              {index <  currentStep-1  ? (
                                <img
                                  src={TickSVG}
                                  alt='tick'
                                  style={{ width: '20px', height: '20px' }}
                                />
                              ) : (
                                <span
                                  className={`header-3 ${
                                    index + 1 <= currentStep
                                      ? 'text-white'
                                      : 'text-color-black'
                                  }`}
                                >
                                  {index + 1}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='flex flex-col w-[85%]'>
                            <span
                              className={`text-[18px] font-bold text-color-black leading-[23px] ${
                                index + 1 === currentStep
                                  ? 'text-opacity-100'
                                  : 'text-opacity-40'
                              }`}
                            >
                              {item.title}
                            </span>
                            <div className='w-[70%] mt-[4px]'>
                              <span
                                className={`text-[13px] leading-[20px] text-color-black ${
                                  index + 1 === currentStep
                                    ? 'text-opacity-80'
                                    : 'text-opacity-40'
                                } `}
                              >
                                {item.subtitle}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className='w-full lg:w-2/3 pr-8 pl-8'>
                  {/* Page components */}
                  <div className='lg:max-h-[80vh] overflow-auto h-full'>
                    <div className='w-full flex items-center justify-center flex-col'>
                      <div className='w-full lg:max-w-[100%] flex flex-col lg:flex-row '>
                        <div className='flex lg:hidden mb-[52px] lg:mb-0 w-full mt-4'>
                          <div className='flex flex-col'>
                            <span
                              className={
                                'text-[18px] font-bold text-color-black leading-[23px] text-opacity-100'
                              }
                            >
                              {steps.current[currentStep - 1].title}
                            </span>
                            <div className='mt-[4px]'>
                              <span
                                className={
                                  'text-[16px] leading-[20px] text-color-black text-opacity-80'
                                }
                              >
                                {steps.current[currentStep - 1].subtitle}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className='w-full pb-[50px]  !h-full'>
                          {children}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HMOSettingsLayout;
