import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import AddGuarantor from "../modal/hr-section/guarantors/add-guarantor";
import { useGetEmployeeWithGuarantors } from "../../redux/human-resources/hook/guarantors";
import Button from "../button";
import { useGetEmployees } from "../../redux/employees/hook";

const GuarantorTab = () => {
  useEffect(() => {}, []);
  const { data: employees } = useGetEmployees(1, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: guarantors } = useGetEmployeeWithGuarantors(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const [showAddGuarantor, setShowAddGuarantor] = useState(false);
  const [guarantorData, setGuarantorData] = useState();

  const [title, setTitle] = useState("Add Guarantor");

  const history = useHistory();

  const tableConfig = {
    // title: 'Guarantors Management',
    headers: ["Employee Name", "Mobile", "Guarantors"],
    keys: ["employeeName", "mobile", "guarantor_count"],
    mobileHeader: {
      left: {
        title: "Employee name",
        key: "employeeName",
      },
      right: {
        title: "Guarantors",
        key: "guarantor_count",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = guarantors.data.findIndex((item) => item.id === id);
        history.push("/employees/view/" + guarantors.data[index].employee_id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded mt-4 border">
      {showAddGuarantor ? (
        <AddGuarantor
          isOpen={showAddGuarantor}
          closeModal={() => setShowAddGuarantor(false)}
          guarantorData={guarantorData}
          title={title}
          buttonText="Add Guarantor"
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] py-2 pt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Employees With Guarantors
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Guarantor"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              disabled={!employees}
              leftIcon={"add"}
              onClick={() => {
                setTitle("Add Guarantor");
                setGuarantorData(null);
                setShowAddGuarantor(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={guarantors}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default GuarantorTab;
