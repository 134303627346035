import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'recurringInvoice',
  initialState: {
    recurring_invoice: null,
  },
  reducers: {
    setRecurringInvoice: (state, { payload }) => {
      state.recurring_invoice = payload;
    },
  },
});

// Actions
export const { setRecurringInvoice } = slice.actions;

export default slice.reducer;