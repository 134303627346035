import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Modal from '../../modal';

function OnboardingQuestions({ isOpen, closeModal, stage }) {
  const [question, setQuestion] = useState([]);

  useEffect(() => {
    if (stage && stage.questions) {
      let newQuestions = [];
      stage.questions.forEach((item) => {
        let newResult = {
          question_id: item.id,
          question: item.question,
          answer: item.answer,
          question_type: item.question_type,
          options: item.options,
        };
        newQuestions.push(newResult);
      });
      setQuestion(newQuestions);
    }
  }, [stage]);

  return (
    <Modal
      scrollable={true}
      className='!min-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form '>
          <div className='px-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>
                {stage?.name} Onboarding Questions
              </span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />

          <div className=' flex flex-col mt-3 p-8 pt-2'>
            {question?.map((item, index) => (
              <div className='flex flex-col' key={index}>
                <div className='flex flex-col mb-2'>
                  <span className='header-4'>Question {index + 1}</span>
                  <span className='p4-medium'>{item.question}</span>
                </div>
                {item.question_type === 'multiple_choice' ? (
                  <div className='flex flex-col !min-w-[100%]'>
                    <span className='header-5 mb-2 underline '>Options:</span>
                    {item.options.map((optionItem, optionIndex) => (
                      <div
                        className={
                          'flex flex-row items-center cursor-pointer duration-500 border-1 rounded mb-2 p-2'
                        }
                      >
                        <div className='header-5 pr-2 !min-w-[20%] duration-500'>
                          Option {optionIndex + 1}:
                        </div>
                        <div className='header-5 pr-2 !min-w-[20%] duration-500'>
                          {optionItem.option}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <pre className='border-1 p-3 rounded !max-h-[400px] overflow-auto'>
                    {item.answer}
                  </pre>
                )}
              </div>
            ))}
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default OnboardingQuestions;
