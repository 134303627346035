import React from "react";

import Icon from "../assets/svg";

const Info = ({ infos }) => (
  <div className="info_container text-[13px]">
    <Icon name="infoSquare" />
    <div className="infos">
      {infos?.map((item, i) => (
        <p key={i}>- {item}</p>
      ))}
    </div>
  </div>
);

export default Info;
