import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { formatNumber } from '../../../utils/functions';
import { pips } from '../../../api/employees/pips';

export function useGetPIPs(limit, page, statusFilter, search) {
  return useQuery(
    ['pips', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return pips.getPIPS({
        limit,
        page,
        statusFilter, 
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((pip) => {
          pip.created = moment(pip.created).format('MMMM D, YYYY');
          pip.formatted_start_date = moment(pip.start_date).format('MMMM D, YYYY');
          pip.formatted_end_date = moment(pip.end_date).format('MMMM D, YYYY');
          pip.employeeName = `${pip.first_name} ${pip.last_name}`;
          
          if (moment().isBetween(pip?.start_date, pip?.end_date) && pip.status !== 1 && pip.status !== 3) {
            pip.status = 2
          }
          switch (pip.status) {
            case 0:
              pip.computedActions = [0, 1];
              break;
            case 1:
              pip.computedActions = [0];
              break;
            case 2:
              pip.computedActions = [0, 1, 2, 3];
              break;
            case 3:
              pip.computedActions = [0];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetPerformanceByPIP(
  pip_id,
  limit,
  page,
  statusFilter,
) {
  return useQuery(
    [
      'performance-by-pip',
      { pip_id, limit, page,statusFilter },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return pips.getPIPEvaluations({
        pip_id,
        limit,
        page,
        statusFilter,
      });
    },
    {
      enabled: pip_id ? true : false,
      select(data) {
        data?.data?.forEach((performance) => {
          performance.employeeName = `${performance.first_name} ${performance.last_name}`
          performance.period = `${performance.evaluation_start} to ${performance.evaluation_end}`
          performance.created = `${performance.created.split('T')[0]}`
          performance.computedActions = [0, 1, 2];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetPIPReport(start_date, end_date) {
  return useQuery(
    ['pip-report', { start_date, end_date }],
    () => {
      return pips.getPIPReport({ start_date, end_date });
    },
    {
      select: (data) => {
        let formatted_months = []
        data?.months?.forEach(element => {
        //   formatted_months.push(moment(element+'-01').format('MMM YY'));
        });
        data.formatted_months = formatted_months;
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
    
  );
}

export function useGetPIP(id) {
  return useQuery(
      ['pip', { id }],
      () => {
          return pips.getPIP({ id });
      },
      {
          enabled: id !== null,
          select(data) {
            data.status = data?.status? data.status: 0
            return data;
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useAddPIP() {
  return useMutation(
    (payload) => {
      return pips.addPIP(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('pips');
        store.dispatch(setAlert(true, 'success', 'PIP Added successfully'));
      },
    },
  );
}

export function useEditPIP() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return pips.editPIP(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('pips');
        store.dispatch(setAlert(true, 'success', 'PIP updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkAddPIP() {
  return useMutation(
    (payload) => {
      return pips.bulkAddPIP(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('pips');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useDeletePIP() {
  return useMutation(
    (payload) => {
      return pips.deletePIP(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pips');
        store.dispatch(setAlert(true, 'success', 'PIP deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleGetEmployeePIP(id, limit, page, statusFilter) {
  return useQuery(
    ['single-pips', { id, limit, page, statusFilter }],
    () => {
      store.dispatch(setIsLoading(true));
      return pips.getSingleEmployeePIPs({ id, limit, page, statusFilter });
    },
    {
      select(data) {
        data?.data?.forEach((pips) => {
          pips.amount = parseFloat(pips.amount);
          pips.created = moment(pips.created).format('MMMM D, YYYY');
          pips.formatted_amount = formatNumber(parseFloat(pips.amount).toFixed(2), 2);
          pips.formatted_month = moment(pips.month).format('MMMM, YYYY');
          pips.employeeName = `${pips.first_name} ${pips.last_name}`;

          switch (pips.status) {
            case 1:
              pips.computedActions = [0];
              break;
            case 2:
              pips.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useExtendPIP() {
    return useMutation(
      (payload) => {
        return pips.extendPIP(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('pips');
          store.dispatch(setAlert(true, 'success', 'PIP Added successfully'));
        },
      },
    );
}

export function useConfirmPIPEmployee() {
    return useMutation(
      (payload) => {
        return pips.confirmEmployeePIP(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('pips');
          store.dispatch(setAlert(true, 'success', 'Employee PIP completed successfully'));
        },
      },
    );
}

export function useTerminatePIPEmployee() {
  return useMutation(
    (payload) => {
      return pips.terminateEmployeePIP(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('pips');
        store.dispatch(setAlert(true, 'success', 'Employee PIP completed successfully. Employee terminated'));
      },
    },
  );
}

export function useAddPIPComment() {
  return useMutation(
    (payload) => {
      return pips.addPIPComment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('pips');
        store.dispatch(setAlert(true, 'success', 'PIP comment added successfully'));
      },
    },
  );
}

export function useGetPIPComments(id) {
return useQuery(
  ['pip-comments', id],
  () => {
    store.dispatch(setIsLoading(true));
    return pips.getPIPCommentsByPIP(id);
  },
  {
    enabled: id !== null,
    select: (data) => {
      data?.forEach((query) => {
        query.created = query.created.split('T').join(' ').split('.')[0]
        query.queryName = `${query.first_name} ${query.last_name}`;
      });
      return data;
    },
    onSettled: (data, error, variables, context) => {
      store.dispatch(setIsLoading(false));
    },
  },
);
}

export function useEditPIPComment() {
  return useMutation(
    (payload) => {
      return pips.updatePIPComment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('pip-comments');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeletePIPComment() {
  return useMutation(
    (payload) => {
      return pips.deletePIPComment(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('pip-comments');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}
