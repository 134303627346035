import React, { useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import { PAGINATION_DEFAULT, otherStatutoryAllowancesStatusConfig } from '../../../utils/constants';
import DataTable from '../../datatable';
import Modal from '../modal';
import { useDeleteOtherStatutoryAllowance, useSingleGetEmployeeOtherStatutoryAllowances } from '../../../redux/employees/hook/other-statutory-allowances';
import { useParams } from 'react-router-dom';
import AddStatutoryAllowance from './add-statutory-allowance';

function StatutoryAllowances({ isOpen, closeModal }) {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });

  const { id } = useParams();

  const { data: other_statutory_allowances } = useSingleGetEmployeeOtherStatutoryAllowances(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  const { mutateAsync:  deleteOtherStatutoryAllowance } = useDeleteOtherStatutoryAllowance();
  const [showAddStatutoryAllowance, setShowAddStatutoryAllowance] = useState(false);
  const [otherStatutoryAllowanceData, setOtherStatutoryAllowanceData] = useState()

  const tableConfig = {
    headers: ['Date Added', 'Allowance Name', 'Type',  'Frequency', 'Month', 'Amount',  'Status'],
    keys: ['created', 'allowance_name', 'type',  'frequency', 'formatted_month', 'amount', 'status'],
    mobileHeader: {
      left: {
        title: 'Allowance Name',
        key: 'allowance_name',
      },
      right: {
        title: 'Amount',
        key: 'amount',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Update',
      color: 'green',
      action: (id) => {
        let index = other_statutory_allowances.data.findIndex((item) => item.id === id);
        setOtherStatutoryAllowanceData(other_statutory_allowances.data[index])
        setShowAddStatutoryAllowance(true)
      },
    },
    {
      name: 'Delete',
      color: 'red',
      action: (id) => {
        let index = other_statutory_allowances.data.findIndex((item) => item.id === id);
         deleteOtherStatutoryAllowance(other_statutory_allowances.data[index].id);
      },
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='md:min-w-[80%] min-w-[90%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      {showAddStatutoryAllowance
      ?
      <AddStatutoryAllowance
        isOpen={showAddStatutoryAllowance}
        buttonText={'UPDATE'}
        otherStatutoryAllowanceData={otherStatutoryAllowanceData}
        title={'Update Other Statutory Allowances'}
        closeModal={() => setShowAddStatutoryAllowance(false)}
      />
      :null}
      <div className='flex flex-col !min-h-fit overflow-auto'>
        <div className='pt-4 pr-8 pl-8'>
          <div className='text-center flex flex-col'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Statutory Allowances</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
        </div>
        <hr className='divider' />
        <DataTable
          data={other_statutory_allowances}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={otherStatutoryAllowancesStatusConfig}
          updatePagination={(data) => setPagination(data)}
          computedActions={true}
          searchData={true}
        />
      </div>
    </Modal>
  );
}

export default StatutoryAllowances;
