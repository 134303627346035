import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { leaves } from '../../../api/hr/leave-management';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetLeaveSummary() {
  return useQuery(['leave-summary'], () => {
    return leaves.getLeaveSummary();
  });
}

export function useGetLeaves(limit, page, statusFilter, search) {
  return useQuery(
    ['leaves', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return leaves.getLeaves({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        if (data?.data) {
          data?.data?.forEach((leave) => {
            switch (leave.final_status) {
              case 0:
                leave.computedActions = [0, 1, 2, 3];
                break;
              case 1:
                leave.computedActions = [0, 3];
                break;
              case 2:
                leave.computedActions = [0, 3];
                break;
              default:
                break;
            }
            leave.date_created = leave.created.split('T')[0];
            leave.status = leave.final_status;
            leave.period = `${moment(leave.start_date).format('MMMM D, YYYY')} to ${moment(leave.end_date).format('MMMM D, YYYY')}`
            leave.employeeName = `${leave.first_name} ${leave.last_name}`;
          });
        }

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetLeaveTypes(limit, page, search) {
  return useQuery(
    ['leave-types', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return leaves.getLeaveTypes({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((leave_type) => {
          leave_type.computedActions = [0, 1, 2];
        });
        return data;
      },

      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useDeleteLeaveType() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return leaves.deleteLeaveType(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('leave-types');
        store.dispatch(setAlert(true, 'success', 'Leave type deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetApplicationLeaveTypes(limit, page, search) {
  return useQuery(
    ['application-leave-types', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return leaves.getLeaveTypes({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Leave Type Selected' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.name, label: item.name });
        });
        return newData;
      },

      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddLeaveType() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return leaves.addLeaveType(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('leave-types');
        store.dispatch(setAlert(true, 'success', 'Leave type added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditLeaveType() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return leaves.updateLeaveType(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('leave-types');
        store.dispatch(setAlert(true, 'success', 'Leave type updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddLeave() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return leaves.addLeave(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('leaves');
        store.dispatch(setAlert(true, 'success', 'Leave added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditLeave() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return leaves.updateLeave(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('leaves');
        queryClient.invalidateQueries('leave-settings');
        store.dispatch(setAlert(true, 'success', 'Leave updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteLeave() {
  return useMutation(
    (payload) => {
      return leaves.deleteLeave(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('leaves');
        store.dispatch(setAlert(true, 'success', 'Leave removed successfully'));
      },
    },
  );
}

export function useGetLeave(id) {
  return useQuery(
    ['leave', { id }],
    () => {
      return leaves.getLeave({ id });
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useApproveLeave() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return leaves.approveLeave(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('leaves');
        store.dispatch(setAlert(true, 'success', 'Leave approved successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetLeavesByEmployee(
  employee_id,
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date,
) {
  return useQuery(
    [
      'single-employee-leaves',
      { employee_id, limit, page,statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return leaves.getLeaveByEmployee({
        employee_id,
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      enabled: employee_id !== null ? true : false,
      select(data) {
        data?.data?.forEach((leave) => {
          leave.employeeName = leave.first_name + ' ' + leave.last_name
          leave.date_created = leave.created.split('T')[0];
          leave.status = leave.final_status;
          leave.period = `${leave.start_date} to ${leave.end_date}`;
          leave.employeeName = `${leave.first_name} ${leave.last_name}`;
          leave.computedActions = [0, 1];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useDeclineLeave() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return leaves.declineLeave(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('leaves');
        store.dispatch(setAlert(true, 'success', 'Leave declined successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetLeaveSettings(id) {
  return useQuery(
    ['leave-settings', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return leaves.getLeaveSettings({
        id,
      });
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateLeaveSettings() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return leaves.updateLeaveSettings(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('leave-settings');
        queryClient.invalidateQueries('team-settings');
        queryClient.invalidateQueries('department-settings');
        queryClient.invalidateQueries('branch-settings');
        store.dispatch(setAlert(true, 'success', 'Leave settings updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
