import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import { useSelector } from 'react-redux';
import Logo from '../../../logo';

import ReactToPdf from 'react-to-pdf';
import Modal from '../../modal';

function ExportVendorStub({ isOpen, closeModal, viewData }) {
  const pdfExportComponent = React.useRef(null);

  const { company } = useSelector((state) => state.companySlice);
  const [payloadData, setPayloadData] = useState(null);

  const ref = React.createRef();

  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4, 2],
  };

  useEffect(() => {
    if (viewData) {

      setPayloadData(viewData);
    }
  }, [viewData]);

  return (
    <Modal
      scrollable={true}
      className='!min-w-[75%] selection: !min-h-[100%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col min-h-[600px] w-full overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>Payment Advice</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col items-center p-8 pb-4 rounded justify-center bg-gray-100 h-fit w-full'>
          <div className='rounded p-8 bg-white min-w-[100%] h-full'>
            <div>
              {/* PDF CONTENT */}
              <div ref={ref} className='flex flex-col w-full items-center px-5'>
                <div className='flex flex-row items-center justify-center gap-2 mb-4'>
                  <a
                    href='https://www.ropay.africa'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Logo />
                  </a>
                </div>
                <div className='flex flex-row justify-between w-[100%] h-fit mb-4'>
                  <div className='flex flex-col !max-w-[30%]'>
                    <div className='flex flex-col !max-w-fit'>
                      <div className='header-6'>From:</div>
                      <div className='header-4'>{company?.company_name}</div>
                    </div>
                    <div className='flex flex-col text-[14px] text-color-gray mb-1 mt-2'>
                      {company?.address}, {company?.city},<br />
                      {company?.state},<br />
                      {company?.country}.
                    </div>
                    <div className='flex flex-col text-[14px] text-gray-500'>
                      {company?.mobile}
                    </div>
                  </div>
                  <div className='flex flex-col !max-w-[30%]'>
                    <div className='flex flex-col !max-w-fit'>
                      <div className='header-6'>PAYMENT ADVICE</div>
                      <div className='header-6 mb-3 text-color-gray'>
                        Vendor Payment
                      </div>
                      <div className='header-6'>To:</div>
                      <div className='header-4'>{viewData?.vendor_name}</div>
                    </div>
                    <div className='flex flex-col text-[13px] text-color-gray mb-1 mt-2'>
                      {viewData?.employee_email}
                    </div>
                    <div className='flex flex-col text-[14px] text-gray-500'>
                      {viewData?.employee_phone}
                    </div>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-end w-[100%] h-fit rounded'>
                  <div className='flex flex-row items-center justify-end !min-w-[500px]'>
                    <div className='!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600'>
                      Transaction Date:
                    </div>
                    <div className='!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600'>
                      {viewData?.processed_date}
                    </div>
                  </div>
                  <div className='flex flex-row items-center justify-end !min-w-[500px]'>
                    <div className='!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600'>
                      Reference:
                    </div>
                    <div className='!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600'>
                      {viewData?.ref}
                    </div>
                  </div>
                  <div className='flex flex-row items-center justify-end !min-w-[500px]'>
                    <div className='!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600'>
                      Account number:
                    </div>
                    <div className='!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600'>
                      {viewData?.account_number}
                    </div>
                  </div>
                  <div className='flex flex-row items-center justify-end !min-w-[500px]'>
                    <div className='!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600'>
                      Beneficiary Bank:
                    </div>
                    <div className='!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600'>
                      {viewData?.bank_name}
                    </div>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-end w-full h-fit mt-8 rounded'>
                  <div className='flex flex-row w-full'>
                    <div className='w-1/4 header-6 p-2 bg-gray-100 pr-4 pl-4'>
                      Date
                    </div>
                    <div className='w-1/4 header-6 p-2 bg-gray-100 pr-4 pl-4'>
                      description
                    </div>
                    <div className='w-1/4 header-6 p-2 bg-gray-100 pr-4 pl-4'>
                      Amount
                    </div>
                    <div className='w-1/4 header-6 p-2 bg-gray-100 pr-4 pl-4'></div>
                  </div>
                  <div className='flex flex-row w-full border border-gray-300'>
                    <div className='w-1/4 header-6 p-2 bg-white pr-4 pl-4'>
                      {viewData?.processed_date}
                    </div>
                    <div className='w-1/4 header-6 p-2 bg-white pr-4 pl-4'>
                      {viewData?.description}
                    </div>
                    <div className='w-1/4 header-6 p-2 bg-white pr-4 pl-4'>
                      NGN{viewData?.total}
                    </div>
                    <div className='w-1/4 header-6 text-[13px] p-2 bg-white pr-4 pl-4 text-green-500'>
                      Payment Successful
                    </div>
                  </div>
                </div>
                <div className='flex flex-col justify-center items-center w-full h-fit mt-4 italic text-primary-2'>
                  <a
                    href='https://www.ropay.africa'
                    target='_blank'
                    rel='noreferrer'
                  >
                    www.ropay.africa
                  </a>
                </div>
              </div>
            </div>
          </div>

          <ReactToPdf
            targetRef={ref}
            filename='vendor-payment-advice.pdf'
            // options={options}
            scale={0.85}
          >
            {({ toPdf }) => (
              <div className='flex flex-row gap-3 mb-2 mt-4'>
                <div className='w-full md:w-[120px] md:mt-0 h-[40px]'>
                  <Button
                    text='Download'
                    type='submit'
                    className='h-[40px] pr-3 pl-3'
                    onClick={toPdf}
                  />
                </div>
                {/* <div className='w-full md:w-[220px] md:mt-0 h-[56px]'>
                  <Button
                    text='Send as email'
                    type='button'
                    theme='primary'
                    className='h-[40px]'
                    // onClick={() => exportPDFWithComponent()
                    // }
                  />
                </div> */}
              </div>
            )}
          </ReactToPdf>
        </div>
      </div>
    </Modal>
  );
}

export default ExportVendorStub;
