import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import FormInput from '../../form-input';
import WarningSVG from '../../../assets/svg/warning.svg';
import {
  useApprovePaySalaryAdvanceApplication,
  useApproveSalaryAdvance,
  useDisburseSalaryAdvanceApplication,
} from '../../../redux/employees/hook/salary-advance';
import Modal from '../modal';
import FormOtpInput from '../../form-otp-input';
import { useGetCompanyAccountDetails } from '../../../redux/wallet/hook';
import { formatAmount, formatNumber } from '../../../utils/functions';
import moment from 'moment';

function ApproveSalaryAdvance({
  isOpen,
  closeModal,
  salaryAdvance,
  applicationPay = false,
}) {
  useEffect(() => {
    if (applicationPay) {
      setPayType('approve_and_pay');
    }
  }, [applicationPay]);

  const [pin, setPIN] = useState('');

  const { data: companyAccountDetails  } = useGetCompanyAccountDetails();

  const { user } = useSelector((state) => state.userSlice);

  const [approveChecked, setApproveChecked] = useState(true);
  const [approvePayChecked, setApprovePayChecked] = useState(false);

  const [payType, setPayType] = useState('approve');

  const { mutateAsync: disburseSalaryAdvance, isLoading: disburseLoading } =
    useDisburseSalaryAdvanceApplication();

  const {
    mutateAsync: approveAndPaySalaryAdvance,
    isLoading: approveAndPayLoading,
  } = useApprovePaySalaryAdvanceApplication();

  const { mutateAsync: approveSalaryAdvance, isLoading: approveLoading } =
    useApproveSalaryAdvance();

  const submitForm = async () => {
    if (payType === 'approve') {
      await approveSalaryAdvance(salaryAdvance?.id).then(() => {
        closeModal();
      });
    } else if (payType === 'approve_and_pay') {
      if (
        user?.permissions.approval_permission?.initiator === 1 &&
        !applicationPay
      ) {
        const requestData = {
          transaction_pin: pin,
          salary_advance_id: salaryAdvance?.id,
        };
        await approveAndPaySalaryAdvance(requestData).then(() => {
          closeModal();
        });
      } else if (
        user?.permissions.approval_permission?.approver === 1 &&
        applicationPay
      ) {
        const requestData = {
          transaction_pin: pin,
          salary_advance_id: salaryAdvance?.id,
        };
        await disburseSalaryAdvance(requestData).then(() => {
          closeModal();
        });
      } else if (
        user?.permissions.approval_permission?.initiator === 1 &&
        user?.permissions.approval_permission?.approver === 1
      ) {
        const requestData = {
          transaction_pin: pin,
          salary_advance_id: salaryAdvance?.id,
        };
        await approveAndPaySalaryAdvance(requestData).then(() => {
          closeModal();
        });
      }
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form'>
          <div className='flex flex-row justify-between pl-6 pr-6 pt-4 items-center'>
            <span className='header-4'>Approve Salary Advance</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col px-8'>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4 w-full border rounded-md bg-highlight p-3'>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Employee Name</span>
                <span className='text-color-black text-[13px] font-semibold'>
                  {salaryAdvance?.first_name} {salaryAdvance?.last_name}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Mobile</span>
                <span className='text-color-black text-[13px] font-semibold'>
                  {salaryAdvance?.mobile}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>
                  Repayment Start
                </span>
                <span className='text-color-black text-[13px] font-semibold'>
                  {moment(salaryAdvance?.repayment_start).format('MMMM, YYYY')}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Repayment End</span>
                <span className='text-color-black text-[13px] font-semibold'>
                  {moment(salaryAdvance?.repayment_end).format('MMMM, YYYY')}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Amount</span>
                <span className='text-color-black text-[13px] font-semibold'>
                  {formatAmount(salaryAdvance?.amount)}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Purpose</span>
                <span className='text-color-black text-[13px] font-semibold'>
                  {salaryAdvance?.purpose}
                </span>
              </div>
            </div>
            <hr className='divider mb-1 mt-2' />
            <div className='mt-2 grid md:grid-cols-1 grid-cols-1 gap-4 w-full mb-1'>
              <div className='flex flex-col'>
                <span className='text-[14px] font-extrabold text-color-gray'>
                  Monthly installment Breakdown <span className='text-[13px] font-semibold'>(Automatically deducted from payroll)</span>
                </span>
              </div>
            </div>
            <div className='grid md:grid-cols-1 grid-cols-1 gap-4 w-full border-2 p-2 px-3 rounded mt-1 bg-highlight'>
              <div className='flex flex-col'>
                <div className='flex flex-row gap-4 pb-2'>
                  <span className='header-5 w-[50%] text-secondary-2'>
                    Month
                  </span>
                  <span className='header-5 w-[50%] text-secondary-2'>
                    Amount
                  </span>
                </div>
                {salaryAdvance?.monthly_payments.map((item) => (
                  <div className='flex flex-row gap-4 pb-1'>
                    <div className='text-[13px] font-extrabold w-[50%]'>
                      {moment(item.month).format('MMMM, YYYY')}
                    </div>
                    <div className='text-[13px] w-[50%] font-semibold'>{formatAmount(item.amount)}</div>
                  </div>
                ))}
              </div>
            </div>
            <hr className='divider mb-3' />
            {!applicationPay ? (
              <div>
                <div className='flex md:flex-row flex-col justify-between md:items-center !w-[100%]'>
                  <div className='flex flex-row items-center justify-start gap-3 md:w-1/2 w-full font-bold'>
                    <FormInput
                      label='Approve Only'
                      name='approval_type'
                      type='radio'
                      checked={approveChecked}
                      onClick={() => {
                        setApprovePayChecked(false);
                        if (!approveChecked) {
                          setApproveChecked(true);
                          setPayType('approve');
                        }
                      }}
                    />
                  </div>
                  <div className='flex flex-row items-center justify-start gap-3 md:w-1/2 w-full font-bold'>
                    <FormInput
                      label='Approve and Pay Now'
                      name='approval_type'
                      type='radio'
                      checked={approvePayChecked}
                      onClick={() => {
                        setApproveChecked(false);
                        if (!approvePayChecked) {
                          setApprovePayChecked(!approvePayChecked);
                          setPayType('approve_and_pay');
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <hr className='divider mb-3 mt-1' />
            <div className='w-full mt-0'>
              {payType === 'approve' ? (
                <div className='w-full mb-[20px]'>
                  <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4'>
                    <FormOtpInput
                      value={pin}
                      valueLength={4}
                      onChange={(value) => setPIN(value)}
                      label={'Enter your new 4 digit PIN'}
                    />
                  </div>
                  <Button
                    text='APPROVE'
                    type='button'
                    loading={approveLoading}
                    onClick={() => {
                      submitForm();
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className='flex flex-col justify-center mb-4'>
                    <div className='flex flex-row border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light'>
                      <div className='w-[70px] pr-4 flex flex-row items-center '>
                        <img src={WarningSVG} alt='' srcSet='' />
                      </div>
                      <span className='text-justify text-[12px]'>
                        Ropay will debit <span className="font-semibold">N{formatAmount(salaryAdvance?.amount)}</span>from your
                        account. You will be charged a fee of <span className="font-semibold">{formatAmount(44.99)}</span> for this
                        transaction. Please Make sure the funds are available.
                      </span>
                    </div>
                  </div>
                  {applicationPay &&
                  user?.permissions.approval_permission?.approver === 1 ? (
                    <div className='w-full mb-[20px]'>
                      <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4'>
                        <FormOtpInput
                          value={pin}
                          valueLength={4}
                          onChange={(value) => setPIN(value)}
                          label={'Enter your new 4 digit PIN'}
                        />
                      </div>
                      <Button
                        text={'APPROVE PAYMENT'}
                        type='button'
                        disabled={false}
                        loading={approveAndPayLoading || disburseLoading}
                        onClick={() => submitForm()}
                      />
                    </div>
                  ) : !applicationPay &&
                    user?.permissions.approval_permission?.initiator === 1 ? (
                    <div className='w-full mb-[20px]'>
                      <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4'>
                        <FormOtpInput
                          value={pin}
                          valueLength={4}
                          onChange={(value) => setPIN(value)}
                          label={'Enter your new 4 digit PIN'}
                        />
                      </div>
                      <Button
                        text={
                          user?.permissions.approval_permission?.initiator ===
                            1 &&
                          user?.permissions.approval_permission?.approver === 1
                            ? 'APPROVE AND PAY NOW'
                            : 'INITIATE PAYMENT'
                        }
                        type='button'
                        disabled={!pin || pin.length != 4}
                        loading={approveAndPayLoading || disburseLoading}
                        onClick={() => submitForm()}
                      />
                    </div>
                  ) : !applicationPay &&
                    user?.permissions.approval_permission?.approver === 1 ? (
                    <div className='text-center w-full mb-[20px]'>
                      You are not authorised to Initiate payments <br /> Please
                      Contact Your Administrator.
                    </div>
                  ) : (
                    <div className='text-center w-full mb-[20px]'>
                      You are not Authorised to Approve payment <br /> Please
                      Contact Your Administrator.
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ApproveSalaryAdvance;
