import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../components/layouts/dashboard";
import { useHistory, useParams } from "react-router-dom";
import LoadingBar from "../../../../components/loader-bar";
import Button from "../../../../components/button";
import { Initials } from "../../../../utils/functions";
import { StatusText } from "../../../../components/fragments/status-text";
import {
  PAGINATION_DEFAULT,
  TrainerStatusConfig,
  trainingStatusConfig,
} from "../../../../utils/constants";
import {
  useChangeTrainerStatus,
  useGetSingleTrainer,
  useGetTrainerTrainings,
} from "../../../../redux/employees/hook/trainers";
import DataTable from "../../../../components/datatable";
import {
  useChangeTrainingStatus,
  useDeleteTraining,
} from "../../../../redux/employees/hook/training";

const ViewTrainer = () => {
  useEffect(() => {}, []);

  const { id } = useParams();

  const { data: trainer, isLoading } = useGetSingleTrainer(id);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: trainer_trainings, trainerTrainingsStatusLoading } =
    useGetTrainerTrainings(
      id,
      pagination?.limit,
      pagination?.page,
      pagination?.statusFilter,
      pagination?.search
    );

  const [showAddTraining, setShowAddTraining] = useState(false);
  const [trainingData, setTrainingData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const [tableType, setTableType] = useState("training");

  const switchFunction = () => {
    if (tableType === "training") {
      setTableType("table");
    } else {
      setTableType("training");
    }
  };

  const tableConfig = {
    headers: ["Training Name", "Trainer", "Training Period", "Status"],
    keys: ["training_name", "trainer_name", "period", "status"],
    mobileHeader: {
      left: {
        title: "Training",
        key: "trainer_name",
      },
      right: {
        title: "Status",
        key: "status",
      },
    },
  };

  const { mutateAsync: changeStatus, isLoading: changeTrainerStatusloading } =
    useChangeTrainerStatus();

  const changeTrainerStatus = async () => {
    let payload = {
      id: id,
      data: { status: trainer?.status == 1 ? 0 : 1 },
    };
    await changeStatus(payload);
  };
  const history = useHistory();

  const { mutateAsync: deleteTraining } = useDeleteTraining();

  const { mutateAsync: markTraining } = useChangeTrainingStatus();

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = trainer_trainings.data.findIndex((item) => item.id === id);
        history.push("/training/view/" + trainer_trainings.data[index].id);
      },
    },
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = trainer_trainings.data.findIndex((item) => item.id === id);
        setTitle("Update Training");
        setButtonText("Update");
        setTrainingData(trainer_trainings.data[index]);
        setShowAddTraining(true);
      },
    },
    {
      name: "Change Status",
      color: "green",
      action: async (id) => {
        let index = trainer_trainings.data.findIndex((item) => item.id === id);
        await markTraining({
          id: trainer_trainings.data[index].id,
          status: trainer_trainings.data[index].status == 1 ? 0 : 1,
        });
      },
    },
    {
      name: "Remove",
      color: "red",
      action: async (id) => {
        let index = trainer_trainings.data.findIndex((item) => item.id === id);
        await deleteTraining(trainer_trainings.data[index].id);
      },
    },
  ];

  return (
    <DashboardLayout title="View Trainer">
      '
      <div className="flex flex-col gap-4 -mt-8">
        <div className="flex flex-row items-center box-shadow h-[104px]">
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-row px-[55px] justify-between  w-full">
              <div className="flex flex-row items-center">
                <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200">
                  {Initials(trainer?.trainer_name)}
                </div>
                <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                  <span className="p1-bold">{trainer?.trainer_name}</span>
                  <span className="p4 text-color-gray">{trainer?.email}</span>
                </div>
              </div>
              {trainer ? (
                <div className="flex flex-row items-center">
                  <StatusText
                    statusConfig={TrainerStatusConfig}
                    code={trainer?.status}
                    className={"py-2 rounded p1-bold px-3 p2-bold text-[13px]"}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="flex flex-col bg-white min-h-fit">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] mt-3">
            <div className="p3-bold header-4">Trainings</div>
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:items-center">
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Change Status"
                  type="button"
                  leftIcon={"change"}
                  className="flex gap-2 !h-[35px] w-fit p-2 "
                  onClick={() => {
                    changeTrainerStatus();
                  }}
                  loading={changeTrainerStatusloading}
                  textClass={"!text-[11px]"}
                />
              </div>
            </div>
          </div>
          <hr className="divider" />
          {trainerTrainingsStatusLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={trainerTrainingsStatusLoading} />
            </div>
          ) : (
            <DataTable
              data={trainer_trainings}
              tableConfig={tableConfig}
              statusConfig={trainingStatusConfig}
              updatePagination={(data) => setPagination(data)}
              filterByStatus={true}
              actionConfig={actionConfig}
              computedActions={true}
              tableType={tableType}
              switchFunction={switchFunction}
              layoutSwitch={true}
              searchData={true}
              noCheck={true}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewTrainer;
