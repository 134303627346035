import React, { useState } from 'react';
import Button from '../../../button';
import ButtonSelect from '../../../button-select';
import CloseSVG from '../../../../assets/svg/close.svg';
import Modal from '../../modal';
import AddEmployeeExit from './add-exit';
import AddBulkEmployeeExit from './add-bulk-exit';

function SelectExitOption({ isOpen, closeModal, type }) {
  const [option, setOption] = useState('single');
  const [showAddExit, setShowAddExit] = useState(false);
  const [showAddBulkExit, setShowAddBulkExit] = useState(false);

  function submit() {
    if (option === 'single') {
      setShowAddExit(true);
    } else if (option === 'bulk') {
      setShowAddBulkExit(true);
    }
  }

  return (
    <>
      {showAddExit ? (
        <AddEmployeeExit
          isOpen={showAddExit}
          closeModal={() => setShowAddExit(false)}
          exitData={null}
          exitType={type}
          title={'Add Employee Exit'}
          buttonText={'SAVE EXIT'}
        />
      ) : null}

      <AddBulkEmployeeExit
        isOpen={showAddBulkExit}
        closeModal={() => setShowAddBulkExit(false)}
        exitType={type}
      />

      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Select exit upload option</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col items-center w-full min-h-fit'>
            <span className='text-[15px] -mb-3'>
              Select your desired option to add exit
            </span>
            <div className='flex flex-col justify-center h-full min-h-[400px] max-w-[85%] w-full'>
              <ButtonSelect
                title={'Add Single Employee Exit'}
                subtitle={'Add employee exit one after the other'}
                isActive={option === 'single'}
                onClick={() => setOption('single')}
              />

              <ButtonSelect
                title={'Import Employee Exit'}
                subtitle={
                  'Import employee exit using a pre-defined XLSX template'
                }
                isActive={option === 'bulk'}
                onClick={() => setOption('bulk')}
                className='mt-8'
              />

              <div className='w-full mt-[20px]'>
                <Button text='NEXT' type='submit' onClick={() => submit()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SelectExitOption;
