import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import Modal from '../../modal';
import { Editor } from '@tinymce/tinymce-react';
import FormFileInput from '../../../form-file-input';
import { formValidation } from '../../../../utils/functions';
import { useSendOfferLetter } from '../../../../redux/human-resources/hook/recruitment';

function SendOfferLetter({
  isOpen,
  closeModal,
  applicant,
  source = 'recruitment',
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
  const [subject, setSubject] = useState('OFFER OF EMPLOYMENT');
  const [body, setBody] = useState(
    `
    <p>
      We are delighted to extend an offer of employment to you for the position of [Job Title] at [Company Name]. 
      </br></br>We were thoroughly impressed by your skills, qualifications, and experience during the application process, and we believe that you will be a valuable addition to our team.
      </br></br>Please find attached the offer of employment. Review and sign to confirm acceptance.
    </p>
    `,
  );
  const [closingRemark, setClosingRemark] = useState('Regards,');

  const [nameType, setNameType] = useState('first_name');
  const [salutation, setSalutation] = useState('Hello ');

  const { mutateAsync: sendOfferLetter, isLoading: sendOfferLetterLoading } =
    useSendOfferLetter();

  const salutationOptions = [
    {
      label: 'Hello',
      value: 'Hello',
    },
    {
      label: 'Hi',
      value: 'Hi',
    },
    {
      label: 'Hey',
      value: 'Hey',
    },
    {
      label: 'Dear',
      value: 'Dear',
    },
    {
      label: 'Dear Mr.',
      value: 'Dear Mr.',
    },
    {
      label: 'Dear Mrs.',
      value: 'Dear Mrs.',
    },
    {
      label: 'Good day',
      value: 'Good day',
    },
  ];

  const nameTypeOptions = [
    { label: 'First Name', value: 'first_name' },
    { label: 'Last Name', value: 'last_name' },
    { label: 'Full Name', value: 'full_name' },
  ];

  const closingRemarkOptions = [
    {
      label: 'Kind regards',
      value: 'Kind regards,',
    },
    {
      label: 'Thank you',
      value: 'Thank you,',
    },
    {
      label: 'Looking forward to meeting you',
      value: 'Looking forward to meeting you,',
    },
    {
      label: 'Best',
      value: 'Best,',
    },
    {
      label: 'Sincerely',
      value: 'Sincerely,',
    },
    {
      label: 'Regards',
      value: 'Regards,',
    },
    {
      label: 'Best Regards',
      value: 'Best Regards,',
    },
    {
      label: 'Cheers',
      value: 'Cheers,',
    },
  ];

  const submitForm = async (data) => {
    const formData = new FormData();
    formData.append('file', data?.file[0]);
    formData.append('salutation', salutation);
    if (source == 'recruitment') {
      formData.append('applicant_id', applicant?.id);
    } else {
      formData.append('employee_id', applicant?.id);
    }
    formData.append('subject', subject);
    formData.append('body', body.toString());
    formData.append('name_type', nameType);
    formData.append(
      'type',
      source == 'recruitment' ? 'recruitment' : 'employee',
    );
    formData.append('closing_remark', closingRemark);
    formData.append('mail_type', 'employment_offer');

    await sendOfferLetter(formData).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col pb-4 overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>{title}</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider mb-2' />
        <div className='flex flex-col mx-8'>
          <form className='form' onSubmit={handleSubmit(submitForm)}>
            <div className='flex flex-col border bg-secondary-2-extralight p-2 rounded-md'>
              <span className='text-[15px] font-extrabold text-color-gray'>
                {source == 'recruitment' ? 'Applicant name' : 'Employee Name'}
              </span>
              <span className='text-[13px] text-color-black-2 font-semibold'>
                {applicant?.first_name} {applicant?.last_name}
              </span>
            </div>
            <hr className='divider mb-2 mt-2' />
            <FormInput
              label='Subject'
              name='subject'
              type='text'
              value={subject}
              placeholder={'Enter email subject'}
              onInput={(e) => {
                setSubject(e.target.value);
              }}
              error={errors.subject}
              errorMessage={errors.subject && errors.subject.message}
            />
            <div className='flex md:flex-row flex-col w-full justify-between mt-2'>
              <div className='w-full md:w-1/2 md:pr-2'>
                <FormSelect
                  value={salutation}
                  options={salutationOptions}
                  onChange={(selected) => {
                    setSalutation(selected);
                  }}
                  label='Salutation'
                />
              </div>
              <div className='w-full md:w-1/2 pl-2'>
                <FormSelect
                  defaultValue={nameType}
                  options={nameTypeOptions}
                  onChange={(selected) => {
                    setNameType(selected);
                  }}
                  label='Applicant Name Type'
                />
              </div>
            </div>
            <div className='flex md:flex-row flex-col w-full justify-between mb-2'>
              <div className='w-full'>
                <span className='text-[13px] text-color-gray'>Email Body</span>
                <Editor
                  apiKey={import.meta.env.VITE_APP_TINYMCE_API_KEY}
                  onEditorChange={(editor) => {
                    setBody(editor);
                  }}
                  value={body}
                  init={{
                    placeholder: 'Enter Email body here',
                    height: 300,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
                  }}
                />
              </div>
            </div>
            <FormSelect
              defaultValue={closingRemark}
              options={closingRemarkOptions}
              onChange={(selected) => {
                setClosingRemark(selected);
              }}
              label='Closing Remark'
            />
            <FormFileInput
              label={'Upload Offer Letter'}
              multiSource={false}
              name={'file'}
              inputRef={register(formValidation('file', true))}
              accept='.pdf'
            />
            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                loading={sendOfferLetterLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default SendOfferLetter;
