import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormSelect from "../../../components/form-select";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import AddEmployeeGroup from "../../../components/modal/employees/add-employee-group";
import { useSelector } from "react-redux";
import {
  useAddEmployeesToGroup,
  useGetOnboardingEmployeeGroups,
} from "../../../redux/employees/hook/groups";
import AddSmallSVG from "../../../assets/svg/add-icon-small.svg";

function AddEmployeeToGroup() {
  useEffect(() => {}, []);
  const history = useHistory();
  const [groupId, setGroupId] = useState();
  const [showAddEmployeeGroup, setShowAddEmployeeGroup] = useState(false);
  const { employee } = useSelector((state) => state.employeeSlice);
  const [employeeId, setEmployeeId] = useState();

  const { mutateAsync, isLoading: loading } = useAddEmployeesToGroup();

  useEffect(() => {
    if (employee) {
      setEmployeeId(employee.id);
    }
  }, [employee]);

  const { data: employeeGroups } = useGetOnboardingEmployeeGroups(
    1000,
    1,
    -1,
    ""
  );

  useEffect(() => {}, [employeeGroups]);

  const skipToSalary = () => {
    history.push("/employees/onboarding/add-salary-information/?type=single");
  };

  const submitForm = async ({ addSalary = false }) => {
    const requestData = {
      group: groupId,
      employees: employeeId.toString(),
    };

    await mutateAsync(requestData).then((result) => {
      if (result.status === "success") {
        if (addSalary) {
          history.push(
            "/employees/onboarding/add-salary-information/?type=single"
          );
        } else {
          history.push("/employees");
        }
      }
    });
  };

  return (
    <EmployeeOnboardingLayout step={4} title={"Add Employee To Payroll Group"}>
      <>
        <AddEmployeeGroup
          title="Add Payroll Group"
          isOpen={showAddEmployeeGroup}
          closeModal={() => setShowAddEmployeeGroup(false)}
          buttonText="ADD"
          refresh={() => {}}
        />
        <div className="flex flex-col w-full h-fit">
          <span className="hidden md:flex text-[20px] font-extrabold md:mt-2">
            Assign Onboarding workflow
          </span>
          <hr className="divider mb-2 md:mt-2 -mt-12" />
          <div
            className="flex justify-end w-full cursor-pointer"
            onClick={() => skipToSalary()}
          >
            <span className="text-[14px] font-semibold text-black cursor-pointer hover:underline hover:scale-110 duration-300 mb-2">
              Skip this step
            </span>
          </div>

          <div className="flex flex-col border bg-secondary-2-extralight p-2 rounded-md">
            <span className="text-[15px] font-extrabold text-color-gray">
              Employee name
            </span>
            <span className="text-[14px] font-semibold text-color-black-2">
              {employee?.first_name} {employee?.last_name}
            </span>
          </div>
          <hr className="divider mt-2" />
          <div className="">
            {employeeGroups ? (
              <FormSelect
                options={employeeGroups}
                readOnly={employeeGroups ? false : true}
                onChange={(selected) => {
                  setGroupId(selected);
                }}
                label="Payroll Groups"
              />
            ) : (
              <div className="p3-medium text-color-black">
                No Payroll Group Found. Click link below to add a new Payroll
                Group.
              </div>
            )}
            <hr className="divider" />
            <div className="flex flex-row items-center gap-2 justify-left w-full cursor-pointer">
              <img
                src={AddSmallSVG}
                alt={"add payroll group"}
                className={"h-[15px] w-[15px]"}
              />
              <span
                className="p4-medium hover:underline hover:italic hover:font-bold text-primary-3 duration-75"
                onClick={() => setShowAddEmployeeGroup(true)}
              >
                Add new payroll group
              </span>
            </div>
            <div className="w-full mt-[20px]">
              <Button
                text="Add and Continue"
                type="submit"
                disabled={groupId && employeeId ? false : true}
                loading={loading}
                className="!h-[62px]"
                onClick={() => submitForm({ addSalary: true })}
              />
            </div>
          </div>
        </div>
      </>
    </EmployeeOnboardingLayout>
  );
}

export default AddEmployeeToGroup;
