import { store } from '..';
import { setSection } from './reducer';
import { Sections } from './constants';
import { setTransiting } from '../miscellaneous/reducer';

export async function updateSectionData(section) {
  store.dispatch(setTransiting(true));

  if (!section) {
    //set default section to payroll
    await store.dispatch(setSection(Sections[0]));

    return;
  }

  let currentSection = Sections.find((item) => item.name === section);

  if (currentSection) {
    await store.dispatch(setSection(currentSection))
  } else {
    //Section name is not defined yet so default back to payroll
    await store.dispatch(setSection(Sections[0]));
  }
}
