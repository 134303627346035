import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetCabinetHmo } from "../../redux/employee-folder/hook";
import LoadingBar from "../loader-bar";
import EmptyTableSVG from "../../assets/svg/empty-table.svg";

const HmoFolder = ({ employeeId }) => {
  const { user } = useSelector((state) => state.userSlice);

  const { data: hmo_beneficiary, isLoading } = useGetCabinetHmo(employeeId);

  return (
    <div className="flex flex-col">
      {isLoading ? (
        <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
          <LoadingBar loading={isLoading} />
        </div>
      ) : (
        <div className="flex md:flex-row flex-col gap-4 p-3">
          <div className="flex flex-col md:w-1/2 gap-4 p-3 border rounded-md">
            {isLoading ? (
              <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                <LoadingBar loading={isLoading} />
              </div>
            ) : (
              <>
                {hmo_beneficiary ? (
                  <>
                    <div className="flex flex-row justify-between w-full items-center mb-3">
                      <span className="header-4 p-2 text-white rounded-[8px] bg-hr-primary-1 max-w-fit">
                        HMO Information
                      </span>
                      <StatusText
                        code={hmo_beneficiary?.status}
                        statusConfig={statusConfig}
                        className={
                          "py-2 rounded p1-bold px-3 p2-bold text-[15px]"
                        }
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-4 w-full">
                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">
                          First Name
                        </span>
                        <span className="mt-1 text-color-black font-bold">
                          {hmo_beneficiary?.first_name}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">
                          Last name
                        </span>
                        <span className="mt-1 text-color-black font-bold">
                          {hmo_beneficiary?.last_name}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">
                          Phone Number
                        </span>
                        <span className="mt-1 text-color-black font-bold">
                          {hmo_beneficiary?.mobile}
                        </span>
                      </div>

                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">Email</span>
                        <span className="mt-1 text-color-black font-bold">
                          {hmo_beneficiary?.email}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">
                          HMO Name
                        </span>
                        <span className="mt-1 text-color-black font-bold">
                          {hmo_beneficiary?.hmo_name}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">
                          HMO Plan
                        </span>
                        <span className="mt-1 text-color-black font-bold">
                          {hmo_beneficiary?.hmo_plan}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">
                          Max Dependants
                        </span>
                        <span className="mt-1 text-color-black font-bold">
                          {hmo_beneficiary?.max_dependants}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">
                          Policy Number
                        </span>
                        <span className="mt-1 text-color-black font-bold">
                          {hmo_beneficiary?.policy_number
                            ? hmo_beneficiary?.policy_number
                            : "Not Activated"}
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col justify-start gap-3 w-full items-start mb-3">
                      <span className="header-4 p-2 text-white rounded-[8px] bg-hr-primary-1 max-w-fit">
                        Hospitals
                      </span>
                      {hmo_beneficiary?.hospitals &&
                      hmo_beneficiary?.hospitals.length > 0 ? (
                        <div className="flex flex-col gap-3">
                          {hmo_beneficiary?.hospitals?.map((item, index) => (
                            <div
                              className="flex flex-col p-3 border rounded-md gap-1"
                              key={index}
                            >
                              <div className="text-[15px] font-extrabold">
                                {item.hospital}
                              </div>
                              <div className="flex justify-between text-color-gray">
                                <span className="text-color-gray text-[14px]">
                                  {item.address + item.city + item.state}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="flex flex-col p-3 border rounded-md gap-1 h-[80px] w-full items-center justify-center">
                          No Hospital Selected
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center w-[100%]">
                    <img
                      src={EmptyTableSVG}
                      alt="No Data Fetched"
                      width={"70%"}
                      height={"100px"}
                    />
                    <span className="header-4 p-2 text-white rounded-[8px] bg-hr-primary-1 max-w-fit">
                      No HMO information fetched
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex flex-col md:w-1/2 p-3 border rounded-md">
            {isLoading ? (
              <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                <LoadingBar loading={isLoading} />
              </div>
            ) : hmo_beneficiary?.dependants &&
              hmo_beneficiary?.dependants.length > 0 ? (
              <>
                <span className="header-4 p-2 text-white rounded-[8px] bg-hr-primary-1 max-w-fit">
                  Dependants Summary
                </span>
                <div className="flex flex-col gap-3 mt-3">
                  <div className="text-center font-semibold">
                    {hmo_dependants?.length}/{hmo_beneficiary?.max_dependants}{" "}
                    Dependants Added
                  </div>
                  <div className="flex flex-col gap-3">
                    {hmo_beneficiary?.dependants?.map((item, index) => (
                      <div
                        className="flex flex-col p-3 border rounded-md gap-1"
                        key={index}
                      >
                        <div className="text-[15px] font-extrabold">
                          {item.first_name} {item.last_name}{" "}
                          <span className="text-[13px] text-color-gray">
                            ({item.type})
                          </span>
                        </div>
                        <div className="flex justify-between text-color-gray">
                          <span className="text-color-gray text-[14px]">
                            {item.mobile}
                          </span>
                          <span className="text-color-gray text-[14px]">
                            {item.email}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col w-full items-center justify-center">
                <img
                  src={EmptyTableSVG}
                  alt="No Data Fetched"
                  width={"70%"}
                  height={"100px"}
                />
                <span className="header-4 p-2 text-white rounded-[8px] bg-hr-primary-1 max-w-fit">
                  No HMO dependants fetched
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HmoFolder;
