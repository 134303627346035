import React, { useState } from 'react';
import { useEffect } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  useGetAdvancedPayrollCheck,
  useGetAdvancedPayrollCheckBranch,
  useGetAdvancedPayrollCheckGroup,
  useGetAdvancedPayrollCheckSelective,
  useRunAdvancedPayroll,
  useRunAdvancedPayrollBranch,
  useRunAdvancedPayrollGroup,
  useRunAdvancedPayrollSelective,
} from '../../../redux/payroll/hook';
import Button from '../../button';
import WarningSVG from '../../../assets/svg/warning.svg';
import { useHistory } from 'react-router-dom';
import LoadingBar from '../../loader-bar';
import Modal from '../modal';
import moment from 'moment';
import { useGetBranch } from '../../../redux/human-resources/hook/branches';
import FormSelect from '../../form-select';
import { formatNumber } from '../../../utils/functions';

function AdvancedPayrollBreakdown({
  isOpen,
  closeModal,
  completed,
  source = 'default',
  sourceData,
  payloadData,
}) {
  const [data, setData] = useState(null);

  const { data: branch } = useGetBranch(payloadData?.branch_id);

  const [payFrom, setPayFrom] = useState('company')
  // pay_from
  const payFromOptions = [
    {label: 'Company Wallet', value: 'company'},
    {label: 'Branch Wallet', value: 'branch'},
  ]


  const {
    mutateAsync: getAdvancePayrollCheck,
    isLoading: advancedIsLoading,
  } = useGetAdvancedPayrollCheck();

  const {
    mutateAsync: getAdvancePayrollCheckGroup,
    isLoading: advancedGroupIsLoading,
  } = useGetAdvancedPayrollCheckGroup();

  const {
    mutateAsync: getAdvancePayrollCheckBranch,
    isLoading: advancedBranchIsLoading,
  } = useGetAdvancedPayrollCheckBranch();

  const {
    mutateAsync: getAdvancedPayrollCheckSelective,
    isLoading: advancedSelectiveIsLoading,
  } = useGetAdvancedPayrollCheckSelective();

  const { mutateAsync: runAdvancedPayroll, isLoading: loading } =
    useRunAdvancedPayroll();

  const { mutateAsync: runAdvancedPayrollGroup, isLoading: groupLoading } =
    useRunAdvancedPayrollGroup();

  const { mutateAsync: runAdvancedPayrollBranch, isLoading: branchLoading } =
    useRunAdvancedPayrollBranch();

  const { mutateAsync: runAdvancedPayrollSelective, isLoading: selectiveLoading, } = 
    useRunAdvancedPayrollSelective();

  const history = useHistory();

  function onAdvancedPayrollSuccess() {
    history.push(`/payroll`);
    closeModal();
  }

  const checkPayroll = async () => {
    if (isOpen && source === 'default') {
      let payload = {
        month: payloadData?.month,
        setting: payloadData.setting
      };
      await getAdvancePayrollCheck(payload).then((result) => {
        setData(result)
      });
    } else if (isOpen && source === 'selective') {
      let payload = {
        employee_ids: sourceData,
        month: payloadData?.month,
        setting: payloadData.setting
      };
      await getAdvancedPayrollCheckSelective(payload).then((result) => {
        setData(result);
      });
    } else if (isOpen && source === 'group') {
      let payload = {
        id: sourceData,
        month: payloadData?.month,
        setting: payloadData.setting
      };
      await getAdvancePayrollCheckGroup(payload).then((result) => {
        setData(result);
      });
    } else if (isOpen && source === 'branch') {
      let payload = {
        id: sourceData,
        month: payloadData?.month,
        setting: payloadData.setting
      };
      await getAdvancePayrollCheckBranch(payload).then((result) => {
        setData(result);
      });
    }
  }

  useEffect(() => {
    if (isOpen && source) {
      checkPayroll()
    }
  }, [isOpen, source]);

  const submitForm = async () => {
    payloadData.payment_from = payFrom;
    if (source === 'default') {
      await runAdvancedPayroll(payloadData).then((response) => {
        completed();
      });
    } else if (source === 'group') {
      await runAdvancedPayrollGroup(payloadData).then((response) => {
        history.push(`/payroll`);
        completed();
      });
    } else if (source === 'branch') {
      await runAdvancedPayrollBranch(payloadData).then((response) => {
        completed();
      });
    } else if (source === 'selective') {
      await runAdvancedPayrollSelective(payloadData).then((response) => {
        history.push(`/payroll`);
        completed();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] selection: h-full !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col item overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-3'>Preview Payroll Charges</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col mx-8'>
          {data ? (
            <div className='flex flex-col'>
              <div className='flex flex-col items-center -mt-1'>
                <span className='stat-card-header pb-1'>PAYROLL MONTH</span>
                <span className='p4-medium text-color-black font-bold'>
                  {moment(payloadData.month).format('MMMM, YYYY')}
                </span>
              </div>
              <hr className='divider' />
              <div className='flex flex-col items-center -mt-1'>
                <span className='stat-card-header pb-1'>
                  ACTIVE EMPLOYEES CHARGED
                </span>
                <span className='p4-medium text-color-black font-bold'>
                  {data?.total_salaries}
                </span>
              </div>
              <hr className='divider mt-2' />
              <div className='flex flex-col items-center -mt-1'>
                <span className='stat-card-header pb-1'>FEES PER EMPLOYEE</span>
                <span className='p4-medium text-color-black font-bold'>
                  {formatNumber((
                    data?.total_payroll_fee / data?.total_salaries
                  ), 2)}
                </span>
              </div>
              <hr className='divider mt-2' />
              <div className='flex flex-col items-center -mt-1'>
                <span className='stat-card-header pb-1'>TOTAL FEES</span>
                <span className='p4-medium text-color-black font-bold'>
                  {formatNumber(data?.total_payroll_fee, 2)}
                </span>
              </div>
              {data?.net_pay > 0
              ?
              <>
                <hr className='divider mb-2 mt-2' />
                <div className='flex w-full justify-between p-3 bg-highlight rounded-md'>
                  <div className='flex flex-col items-center -mt-1'>
                    <span className='stat-card-header pb-1'>TOTAL NET PAY</span>
                    <span className='p4-medium text-color-black font-bold'>
                      {formatNumber(data?.net_pay, 2)}
                    </span>
                  </div>
                  <div className='flex flex-col items-center -mt-1'>
                    <span className='stat-card-header pb-1'>TOTAL TAXES</span>
                    <span className='p4-medium text-color-black font-bold'>
                      {formatNumber(data?.tax, 2)}
                    </span>
                  </div>
                  <div className='flex flex-col items-center -mt-1'>
                    <span className='stat-card-header pb-1'>TOTAL PENSION</span>
                    <span className='p4-medium text-color-black font-bold'>
                      {formatNumber(data?.employer_pension + data?.pension, 2)}
                    </span>
                  </div>
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex w-full justify-center items-center p-3 bg-highlight rounded-md'>
                  <div className='flex flex-col items-center -mt-1 bg-highlight'>
                    <span className='stat-card-header pb-1'>TOTAL PAYABLE</span>
                    <span className='p4-medium text-color-black font-bold'>
                      {formatNumber(data?.total_payroll_fee + data?.net_pay + data?.tax + data?.employer_pension + data?.pension, 2)}
                    </span>
                  </div>
                </div>
              </>:
              null
              }
            </div>
          ) : (
            <LoadingBar
              loading={
                advancedIsLoading ||
                advancedGroupIsLoading ||
                advancedSelectiveIsLoading ||
                advancedBranchIsLoading
              }
            />
          )}
          <hr className='divider mb-2' />
          <div className='flex mb-3'>
            <div className='flex flex-col px-4 border-secondary-4 border-[1.2px] rounded-lg p-3 mt-2 bg-color-warning-light w-[100%]'>
              <div className='flex'>
                <div className='w-[70px] pr-2 flex flex-row items-center '>
                  <img src={WarningSVG} alt='' srcSet='' />
                </div>
                <div className='text-[12px]'>
                  <span className='p4-bold'>What does the fee cover?</span>
                  <li>Payroll computation, payment and payslip to employees</li>
                  <li>
                    Pension Computation
                    {data?.tax_pension_total_fees
                      ? ', Payment and Filling'
                      : null}
                  </li>
                  <li>
                    Tax Computation
                    {data?.tax_pension_total_fees
                      ? ', Payment and Filling'
                      : null}
                  </li>
                  {data?.other_payments_total_fees ? (
                    <li>Other Statutory Computation, Payment and Filing</li>
                  ) : null}
                </div>
              </div>
              <div className='text-[12px] mt-3 font-normal text-justify border-secondary-4 border-[1.2px] rounded-lg p-3'><span className='text-[14px] font-extrabold'>***</span> By clicking <span className='text-[12px] font-semibold'>"CONFIRM AND RUN"</span>, you will be charged <span className='text-[12px] font-semibold'>N{formatNumber(data?.total_payroll_fee, 2)}</span> for the computation. Upon computation completion you can proceed to make the payroll payment to your employees. Ensure that you have <span className='text-[12px] font-semibold'>N{formatNumber(data?.total_payroll_fee + data?.net_pay + data?.tax + data?.employer_pension + data?.pension, 2)}</span> in your wallet before continuing.</div>
            </div>
          </div>
          { source == 'branch' && branch?.id && branch && branch.wallet_id?
          <>
            <hr className='divider mb-2 mt-2' />
            <div className='w-full'>
              <FormSelect
                value={payFrom}
                options={payFromOptions}
                onChange={(selected) => {
                  setPayFrom(selected);
                }}
                label='Pay Payroll From'
              />
            </div>
            {payFrom == 'branch' && branch && branch.wallet_id?
            <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-secondary-2-extralight'>
              <span className='stat-card-header font-extrabold'>
                BRANCH WALLET BALANCE{' '}
                <span className='!text-[13px] text-color-black font-bold'>
                  (VFD - {branch?.account_number})
                </span>
              </span>
              <span className='!text-[16px] text-color-black font-bold'>
                {formatNumber(branch?.balance, 2)}
              </span>
            </div>
            :
            null
            }
          </>
          :
          null
          }
          <div className='w-full mb-4 mt-3'>
            <Button
              text='CONFIRM AND RUN'
              type='submit'
              loading={loading || groupLoading || selectiveLoading || branchLoading}
              onClick={() => submitForm()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AdvancedPayrollBreakdown;
