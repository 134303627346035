import { Axios } from '../../axios';

const getNHIS = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/statutory-payments/nhis?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const bulkNHIS = async (payload) => {
  const { data } = await Axios.post('/statutory-payments/nhis/bulk', payload);
  return data;
};

const fetchNHISByRef = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(`/statutory-payments/nhis/by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}${search}`);
  if (data?.data) {
    data.data.nhis.total_amount = data?.data.total_amount
    data.data.nhis.total_payable = data?.data.total_payable
    data.data.nhfs.total_trans_cost = data?.data.total_fees
  }
  return data?.data.nhis;
};

export const nhisApis = {
    getNHIS,
    bulkNHIS,
    fetchNHISByRef,
};