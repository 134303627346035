import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { setCustomer} from './reducer';
import { customers } from '../../../api/spend/customer';


export function useGetCustomers(limit, page, statusFilter, search) {
  return useQuery(
    ['customers', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return customers.getCustomers({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((customer) => {
          //compute action items
          customer.created = moment(customer.created).format('MMMM D, YYYY');
          switch (customer.status) {
            case 0:
                customer.computedActions = [0, 1, 2, 4];
              break;
            case 1:
                customer.computedActions = [0, 1, 3, 4];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetCustomersToInvoice(limit, page, statusFilter, search) {
  return useQuery(
    ['customers-to-invoice', { limit, page, statusFilter, search }],
    () => {
      return customers.getCustomers({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Customer Selected', bank: 'Not Selected', account_number: 'Not Selected', account_name: 'Not Selected' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, 
            label: `${item.customer_name} | ${item.mobile}` ,
            customer_name: item.customer_name,  
            contact_person: item.contact_person, 
            email: item.email, 
            mobile: item.mobile ,
            address: item.street_address,
            state: item.state,
            city: item.city,
            country: item.country,
          });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetCustomer(id) {
  return useQuery(
    ['customer', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return customers.getCustomer(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setCustomer(response.data));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateCustomer() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return customers.updateCustomer(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('customers');
        store.dispatch(setAlert(true, 'success',  'Customer updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCreateSingleCustomer() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return customers.createSingleCustomer(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setCustomer(response.data));
        queryClient.invalidateQueries('customers');
        queryClient.invalidateQueries('customers-to-invoice');
        store.dispatch(setAlert(true, 'success',  'Customer added successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCreateBulkCustomers() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return customers.createBulkCustomer(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('customers');
        queryClient.invalidateQueries('customers-to-invoice');
        store.dispatch(setAlert(true, 'success', 'Customers added successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkActivateCustomers() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return customers.bulkActivateOrDeactivateCustomers(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('customers');
        queryClient.invalidateQueries('customers-to-invoice');
        store.dispatch(setAlert(true, 'success', 'Customer status change successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useActivateOrDeactivateCustomer() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return customers.activateOrDeactivateCustomer(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('customers');
        queryClient.invalidateQueries('customers-to-invoice');
        store.dispatch(setAlert(true, 'success', 'Customer status change successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkActivateOrDeactivateCustomers() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return customers.bulkActivateOrDeactivateCustomers(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('customers');
        queryClient.invalidateQueries('customers-to-invoice');
        store.dispatch(setAlert(true, 'success', 'Customer status change successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetCustomerPaymentHistory(
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date,
) {
  return useQuery(
    [
      'customer-history',
      { limit, page, statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return customers.getCustomerPaymentHistory({
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((transaction) => {
          transaction.created = transaction.created.split('.')[0].replace('T', ' ')
          transaction.status = parseFloat(transaction.status);
          switch (transaction.status) {
            case 0:
              transaction.computedActions = [0];
              break;
            case 2:
              transaction.computedActions = [1];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useDeleteCustomer() {
  return useMutation(
    (payload) => {
      return customers.deleteCustomer(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customers');
        queryClient.invalidateQueries('customers-to-invoice');
        store.dispatch(setAlert(true, 'success', 'Customer deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

