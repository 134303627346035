import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../../components/button";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import {
  PAGINATION_DEFAULT,
  recruitmentApplicationStatusConfig,
} from "../../../utils/constants";
import { useGetRecruitmentStageApplicants } from "../../../redux/human-resources/hook/recruitment";
import MoveApplicant from "../../../components/modal/hr-section/recruitment/move-applicant";
import ReviewStatus from "../../../components/modal/hr-section/recruitment/review-applicant";
import SendStageEmail from "../../../components/modal/hr-section/recruitment/send-email-by-stage";
import SendApplicantsEmail from "../../../components/modal/hr-section/recruitment/send-applicants-email";
import UnauthorizedPage from "../../errors/unauthorized";
import { useSelector } from "react-redux";
import VideoAssessmentModal from "../../../components/modal/hr-section/recruitment/video-assessment";

const RecruitmentStageViewPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.recruitment_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  // const history = useHistory()
  const [applicantIds, setApplicantIds] = useState(false);

  const location = useLocation();

  const [payload, setPayload] = useState();
  const [recruitmentId, setRecruitmentId] = useState();
  const [showMarkReviewed, setMarkReviewed] = useState(false);

  useEffect(() => {
    if (location) {
      if (location.state) {
        setPayload(location.state);
        setRecruitmentId(location.state?.recruitment_id);
      }
    }
  }, [location]);

  const history = useHistory();

  const [showMoveApplicant, setshowMoveApplicant] = useState(false);
  const [showVideoAssessment, setshowVideoAssessment] = useState(false);
  const [applicant, setApplicant] = useState();
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showSendStageEmail, setShowSendStageEmail] = useState(false);

  const { stage } = useParams();
  const { data: stage_applicants } = useGetRecruitmentStageApplicants(
    stage,
    recruitmentId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const tableConfig = {
    headers: [
      "Date Added",
      "Applicant Name",
      "Email",
      "Mobile",
      "Review Status",
    ],
    keys: ["created", "applicantName", "email", "mobile", "status"],
    mobileHeader: {
      left: {
        title: "Applicant Name",
        key: "applicantName",
      },
      right: {
        title: "Review Status",
        key: "status",
      },
    },
  };

  const actionConfig = [
    {
      name: "View Applicant",
      color: "green",
      action: (id) => {
        let index = stage_applicants.data.findIndex((item) => item.id === id);
        history.push(
          `/recruitment/application/${stage_applicants.data[index].id}`
        );
      },
    },
    {
      name: "Advance Applicant",
      color: "orange",
      action: (id) => {
        let index = stage_applicants.data.findIndex((item) => item.id === id);
        setApplicant(stage_applicants.data[index]);
        setshowMoveApplicant(true);
      },
    },
    {
      name: "Change Status",
      color: "green",
      action: (id) => {
        let index = stage_applicants.data.findIndex((item) => item.id === id);
        setApplicant(stage_applicants.data[index]);
        setMarkReviewed(true);
      },
    },
    {
      name: "Send Email",
      color: "orange",
      action: (id) => {
        let index = stage_applicants.data.findIndex((item) => item.id === id);
        setApplicantIds(stage_applicants.data[index].id.toString());
        setShowSendEmail(true);
      },
    },
  ];

  return (
    <DashboardLayout title="Recruitment Stage View">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {showSendEmail ? (
            <SendApplicantsEmail
              title={"Review Status"}
              isOpen={showSendEmail}
              closeModal={() => setShowSendEmail(false)}
              applicantIds={applicantIds}
              buttonText="Review Applicant"
            />
          ) : null}
          {showSendStageEmail ? (
            <SendStageEmail
              title={"Send Email"}
              isOpen={showSendStageEmail}
              closeModal={() => setShowSendStageEmail(false)}
              recruitment_id={recruitmentId}
              buttonText="Send Email"
            />
          ) : null}
          {showMarkReviewed ? (
            <ReviewStatus
              title={"Review Status"}
              isOpen={showMarkReviewed}
              closeModal={() => setMarkReviewed(false)}
              applicant={applicant}
              buttonText="Review Applicant"
            />
          ) : null}
          {showMoveApplicant ? (
            <MoveApplicant
              isOpen={showMoveApplicant}
              closeModal={() => setshowMoveApplicant(false)}
              title=" Move Applicant Stage"
              applicant={applicant}
              buttonText="Move Applicant"
            />
          ) : null}
          {showVideoAssessment && stage != 1 ? (
            <VideoAssessmentModal
              isOpen={showVideoAssessment}
              closeModal={() => setshowVideoAssessment(false)}
              stage={stage}
              recruitment_id={recruitmentId}
            />
          ) : null}

          <div className="flex flex-col bg-white rounded">
            <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-3 mb-2 ">
              <div className="p3-bold header-4">
                Stage {payload?.index}: {payload?.name}
              </div>
              <div className="flex flex-col md:flex-row items-start gap-[10px] md:items-center">
                <div className="md:w-fit w-[100%]" id="second-step">
                  <Button
                    text="Send Email"
                    leftIcon={"email"}
                    type="button"
                    className="flex gap-2 !h-[35px] w-fit px-2 "
                    onClick={() => {
                      setShowSendStageEmail(true);
                    }}
                    textClass={"!text-[11px]"}
                  />
                </div>
                {stage != 1 ? (
                  <div className="md:w-fit w-[100%]" id="second-step">
                    <Button
                      text="Video Assessment"
                      // leftIcon={'email'}
                      type="button"
                      className="flex gap-2 !h-[35px] w-fit px-2 "
                      onClick={() => {
                        setshowVideoAssessment(true);
                      }}
                      textClass={"!text-[11px]"}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <hr className="divider mt-2 mb-2" />
            <DataTable
              data={stage_applicants}
              statusConfig={recruitmentApplicationStatusConfig}
              tableConfig={tableConfig}
              actionConfig={actionConfig}
              updatePagination={(data) => setPagination(data)}
              searchData={true}
              computedActions={true}
            />
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default RecruitmentStageViewPage;
