import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { documents } from '../../../api/documents';

export function useGetEmployeeDocuments (limit, page, search) {
    return useQuery(
      ['employee-documents', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return documents.getEmployeeDocuments({
          limit,
          page,
          search,
        });
      },
      {
        select: (data) => {
          data?.data?.forEach((document) => {
            document.computedActions = [0, 1];
            document.employeeName = `${document.first_name} ${document.last_name}`;
            document.created = moment(document?.created).format('MMMM D, YYYY');
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useAddEmployeeDocument() {
    return useMutation(
      (payload) => {
        return documents.uploadEmployeeDocument(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('employee-documents');
          store.dispatch(setAlert(true, 'success', 'Document added successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useDeleteEmployeeDocument() {
  return useMutation(
    (payload) => {
      return documents.deleteEmployeeDocument(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee-documents');
        store.dispatch(setAlert(true, 'success', 'Document deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}