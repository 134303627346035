import React, { useEffect } from "react";
import DataTable from "../../../datatable";
import { useState } from "react";
import {
  payrollHistoryStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../../utils/constants";
import {
  useCheckTransactionStatus,
  useRetryTransaction,
} from "../../../../redux/payroll/hook/index";
import Button from "../../../button";
import { useGetPaymentHistoryByVendor } from "../../../../redux/spend-management/vendor/hook";
import { useParams } from "react-router-dom";

const VendorTransactionHistoryTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
    start_date: "",
    end_date: "",
  });

  const { id } = useParams();

  const { data: vendorHistory } = useGetPaymentHistoryByVendor(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date
  );

  const { mutateAsync: checkTransactionStatus } = useCheckTransactionStatus();

  const { mutateAsync: retryTransaction } = useRetryTransaction();

  const tableConfig = {
    headers: [
      "Date Created",
      "Name",
      "Description",
      "Ref",
      "Fee",
      "Amount",
      "Status",
    ],
    keys: [
      "created",
      "name",
      "info",
      "internalRef",
      "fees",
      "amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00",
  };

  const actionConfig = [
    {
      name: "Check Status",
      color: "green",
      action: async (id) => {
        let index = vendorHistory.data.findIndex((item) => item.id === id);
        await checkTransactionStatus(vendorHistory.data[index].id);
      },
    },
    {
      name: "Retry",
      color: "red",
      action: (id) => {
        let index = vendorHistory?.data.findIndex((item) => item.id === id);
        retryTransaction(vendorHistory?.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Vendor Payment History
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Export"
              type="submit"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass="!header-5"
              // onClick={() => setShowAttendanceOptions(true)}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-2" />
      <DataTable
        data={vendorHistory}
        refresh={true}
        tableConfig={tableConfig}
        filterByDate={dateConfig}
        filterByStatus={payrollHistoryStatusConfig}
        statusConfig={payrollHistoryStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default VendorTransactionHistoryTab;
