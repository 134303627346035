import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import calendarIcon from '../assets/svg/calendar-icon.svg';
import calendarArrow from '../assets/svg/date-arrow-right.svg';

function FormDateRangePicker({
  start,
  end,
  setStartDate,
  setEndDate,
  extraClass,
  label=false,
  className,
  ...rest
}) {
  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
    'Last Year': [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    ],
  };

  const onApplyClick = (event, picker) => {
    setStartDate(moment(picker.startDate).format('YYYY-MM-DD'));
    setEndDate(moment(picker.endDate).format('YYYY-MM-DD'));
  };

  const handleCallback = (start, end, label) => {
    setStartDate(moment(start).format('YYYY-MM-DD'));
    setEndDate(moment(end).format('YYYY-MM-DD'));
  }

  return (
    <>
      {label && (
        <label className='text-[13px] leading-[23px] font-normal text-color-gray'>
          {label}
        </label>
      )}
      <DateRangePicker
        initialSettings={{
          ranges: ranges,
          alwaysShowCalendars: false,
        }}
        onApply={onApplyClick}
        onCallback={handleCallback}
        // onCallback={onApplyClick}
      >
        
        <button
          className={`hover:shadow-sm flex flex-row md:gap-4 items-center justify-between gap-8 w-full !bg-white rounded-md !min-w-[230px] !h-[35px] pr-2 pl-2 border border-color-gray`}
          {...rest}
        >
          <div className='flex flex-row text-color-gray text-[13px]'>
            <div className='flex flex-row text-color-gray'>{start}</div>
            <div>
              <img
                src={calendarArrow}
                alt={'darr'}
                className={'flex justify-end pr-2 pl-2'}
              />
            </div>
            <div className='flex flex-row text-color-gray'>{end}</div>
          </div>
          <img
            src={calendarIcon}
            alt={'Refresh'}
            className={'flex justify-end text-color-gray'}
          />
        </button>
        
      </DateRangePicker>
    </>
  );
}

export default FormDateRangePicker;
