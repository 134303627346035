import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import {
  PAGINATION_DEFAULT,
  recruitmentStatusConfig,
} from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import {
  useDeleteRecruitment,
  useGetRecruitments,
} from "../../../redux/human-resources/hook/recruitment";
import { useDispatch } from "react-redux";
import Button from "../../button";
import { setAssignWorkflow } from "../../../redux/human-resources/reducers/recruitment";
import DoYouWantToModal from "../../modal/do-you-want-to";

const RecruitmentOverviewTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    satusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const dispatch = useDispatch();

  const [tableType, setTableType] = useState("recruitment");
  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { mutateAsync: deleteRecruitment } = useDeleteRecruitment();

  const switchFunction = () => {
    if (tableType === "recruitment") {
      setTableType("table");
    } else {
      setTableType("recruitment");
    }
  };

  const { data: recruitments } = useGetRecruitments(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const history = useHistory();

  const tableConfig = {
    headers: ["Role", "Job Type", "Location Type", "Application Close"],
    keys: ["role", "job_type", "location_type", "application_end"],
    mobileHeader: {
      left: {
        title: "Role",
        key: "role",
      },
      right: {
        title: "Application Close",
        key: "application_end",
      },
    },
  };

  const deleteSingleRecruitment = async (id) => {
    let index = recruitments.data.findIndex((item) => item.id === id);
    await deleteRecruitment(recruitments.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = recruitments.data.findIndex((item) => item.id === id);
        history.push("/recruitment/view/" + recruitments.data[index].id);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = recruitments.data.findIndex((item) => item.id === id);
        setDeleteId(recruitments.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showDoYouWant ? (
        <DoYouWantToModal
          isOpen={showDoYouWant}
          closeModal={() => setShowDoYouWant(false)}
          id={deleteId}
          title={"Are you sure you want to delete this recruitment?"}
          buttonText={"DELETE"}
          subtitle="You will lose all data including access to applicants if you delete this recruitment."
          btnFunction={deleteSingleRecruitment}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Recruitments
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Recruitment"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                dispatch(setAssignWorkflow(null));
                history.push("/recruitment/add-recruitment-details");
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={recruitments}
        tableConfig={tableConfig}
        statusConfig={recruitmentStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        computedActions={true}
        tableType={tableType}
        switchFunction={switchFunction}
        layoutSwitch={true}
        searchData={true}
        noCheck={true}
      />
    </div>
  );
};

export default RecruitmentOverviewTab;
