import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/button";
import DataTable from "../../components/datatable";
import DashboardLayout from "../../components/layouts/dashboard";
import {
  useCheckTransactionStatus,
  useRefBasicPayrollView,
  useRevertPayroll,
} from "../../redux/payroll/hook";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import BasicPayrollDetailedView from "../../components/modal/payroll/basic-payroll-full-view";
import { isLoading } from "../../redux/components/components-slice";
import { useSelector } from "react-redux";
import UnauthorizedPage from "../errors/unauthorized";

const ActivityLogPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (
          user.permissions.company_permission.payroll_read !== 1 ||
          user.permissions.payroll_permission?.basic_payroll_write !== 1
        ) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const loading = useSelector(isLoading);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { refId } = useParams();
  const { data: activityLog } = useRefBasicPayrollView(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const history = useHistory();
  const { mutateAsync: revertPayroll } = useRevertPayroll();

  useEffect(() => {
    if (activityLog) {
      if (loading === false && activityLog.data.length < 1) {
        revertPayroll().then(() => {
          history.push("/payroll", { tab: 2 });
        });
      }
    }
  }, []);

  const tableConfig = {
    headers: [
      "Employee Name",
      "Net Pay",
      "Transaction Cost",
      "Total",
      "Status",
    ],
    keys: ["employeeName", "net_pay", "trans_cost", "total", "status"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Total",
        key: "total",
      },
    },
  };

  return (
    <DashboardLayout title="Activity Log">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col">
                <span className="h4 text-color-black">Recent Activity</span>
                <span className="p4 text-color-gray">
                  View account activity across all module (Payroll, People and
                  Spend).
                </span>
              </div>
            </div>
            <div className="mt-6">
              <DataTable
                data={activityLog}
                tableConfig={tableConfig}
                noCheck={true}
                updatePagination={(data) => setPagination(data)}
                computedActions={true}
                filterByStatus={true}
                searchData={true}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default ActivityLogPage;
