import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formValidation } from "../../../utils/functions";
import Button from "../../button";
import FormTextArea from "../../form-textarea";
import {
  useAddTicketComment,
  useEditTicketComment,
  useGetTicketComments,
} from "../../../redux/contact-us/hook";
import FormSwitch from "../../form-switch";
import FormInput from "../../form-input";
import FormFileInput from "../../form-file-input";
import DOMPurify from "dompurify";

export const TicketMessages = ({ ticket_status }) => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { id } = useParams();

  const [type, setType] = useState("add");
  const [commentId, setCommentId] = useState();

  const [uploadFile, setUploadFile] = useState(false);

  const { data: ticket_comment } = useGetTicketComments(id);
  const { user } = useSelector((state) => state.userSlice);

  const { mutateAsync: addTicketComment, isLoading: addTicketCommentloading } =
    useAddTicketComment();

  const {
    mutateAsync: editTicketComment,
    isLoading: editTicketCommentloading,
  } = useEditTicketComment();

  const [comment, setComment] = useState();

  const [addComment, setAddComment] = useState(false);

  useEffect(() => {
    if (ticket_comment) {
      setType("edit");
      setTicketComments(ticket_comment);
    } else {
      setType("add");
    }
  }, [ticket_comment]);

  const [ticketComments, setTicketComments] = useState([]);
  const submitForm = async (data) => {
    const formData = new FormData();
    if (uploadFile) {
      formData.append("file", data?.file[0]);
    } else {
      formData.append("file", null);
    }
    formData.append("comment", comment);
    formData.append("upload_file", uploadFile ? 1 : 0);
    formData.append("filename", DOMPurify.sanitize(data?.filename));
    formData.append("ticket_id", id);
    if (type === "add") {
      await addTicketComment(formData).then(() => {
        setCommentId("");
        setType("add");
        setComment("");
        setAddComment(false);
      });
    } else {
      let editPayload = {
        id: commentId,
        data: formData,
      };
      await editTicketComment(editPayload).then(() => {
        setCommentId("");
        setType("add");
        setComment("");
        setAddComment(false);
      });
    }
  };

  return (
    <div
      className={`max-h-[450px] flex flex-col  gap-2 justify-start bg-white rounded-[5px] border border-[#dbdce3]`}
    >
      <div className="flex flex-row justify-between items-center bg-blue-100 p-3">
        <div className="font-medium text-[14px] text-color-black flex flex-col">
          Messages
        </div>
      </div>
      {addComment ? (
        <form className="form p-8 pt-2" onSubmit={handleSubmit(submitForm)}>
          <div className="flex flex-col md:flex-row justify-between gap-3 text-[14px]">
            <div className="w-full">
              <FormTextArea
                label="Message"
                name="comment"
                value={comment}
                placeholder={"Enter new message here"}
                onInput={(e) => {
                  setComment(e.target.value);
                }}
                className="text-[14px]"
                type="text"
                error={errors.comment}
                errorMessage={errors.comment && errors.comment.message}
              />
            </div>
          </div>
          <hr className="divider mt-2 " />
          <div className="flex flex-row justify-between items-center md:w-[100%] w-full">
            <div className="w-full md:w-[100%] text-[14px] -mb-3">
              <FormSwitch
                label={"Do you want to upload document?"}
                name={"upload_file"}
                checked={uploadFile}
                onClick={() => {
                  setUploadFile(!uploadFile);
                }}
              />
            </div>
          </div>
          {uploadFile ? (
            <>
              <FormInput
                label="File Name"
                name="filename"
                type="text"
                inputRef={register(formValidation("text", true))}
                placeholder={"Enter file name"}
                error={errors.filename}
                errorMessage={errors.filename && errors.filename.message}
              />
              <FormFileInput
                multiSource={false}
                name={"file"}
                inputRef={register(formValidation("file", true))}
                accept=".pdf,.jpg,.png,.jpeg"
              />
            </>
          ) : null}
          <hr className="divider mt-2 " />
          <div className="flex md:flex-row justify-end mt-[10px] gap-4">
            <Button
              text="Cancel"
              type="button"
              onClick={() => {
                setCommentId("");
                setType("add");
                setComment("");
                setAddComment(false);
              }}
              textClass={"!text-[11px]"}
              className={"h-[32px] w-fit p-2 !bg-red-600"}
              theme={"third"}
            />
            <Button
              text={type === "add" ? "Add" : "Update"}
              type="submit"
              disabled={!comment || !id}
              textClass={"!text-[11px]"}
              className={"h-[32px] w-fit p-2"}
              loading={addTicketCommentloading || editTicketCommentloading}
            />
          </div>
        </form>
      ) : (
        <>
          {ticketComments && ticketComments.length > 0 ? (
            <div className="flex flex-col pt-3 !max-h-[450px] overflow-y-auto p-4">
              <div className="flex flex-col gap-3">
                {ticketComments?.map((item, index) => (
                  <>
                    {item.type == "user" ? (
                      <div className="flex flex-col border rounded-lg p-2 pl-4 bg-blue-100 md:max-w-[80%] max-w-[85%]">
                        <div className="flex md:flex-row  items-end justify-between">
                          <div className="flex flex-col  text-gray-500 w-full">
                            <div className="text-[11px] text-gray-500 mb-2">
                              <span className="text-[13px] font-bold text-gray-700 pb-1">
                                {item.user_id === user?.id
                                  ? "You"
                                  : item.user_name}{" "}
                                sent a message on{" "}
                              </span>{" "}
                              {item.created.split("T").join(" ").split(".")[0]}
                            </div>
                            <td
                              className="border border-white p-2 rounded !max-h-[150px] overflow-auto !min-h-[60px] overflow-y-auto !text-[13px] min-w-full"
                              dangerouslySetInnerHTML={{
                                __html: item?.comment,
                              }}
                            />
                          </div>
                        </div>
                        {item?.upload_file ? (
                          <>
                            <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mb-2 mt-3 border rounded p-2 bg-blue-50">
                              <div className="flex flex-col items-start gap-1">
                                <span className="header-5 text-color-gray">
                                  Uploaded File:
                                </span>
                                <div className="flex flex-row items-center">
                                  <span className="text-[14px] text-color-black font-extrabold">
                                    {item.filename} -&nbsp;
                                  </span>
                                  <a
                                    className="italic  text-[14px] text-color-gray hover:underline"
                                    href={item.file_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex flex-col border rounded-lg p-2 pl-4 bg-white max-w-[90%] md:max-w-[80%] ml-[15%] md:ml-[20%]">
                        <div className="flex md:flex-row items-start justify-between">
                          <div className="flex flex-col  text-gray-500 w-full">
                            <div className="text-[11px] text-gray-500 mb-2">
                              <span className="text-[13px] font-bold text-gray-700 pb-1">
                                {item.user_id === user?.id
                                  ? "You"
                                  : item.user_name}{" "}
                                sent you a message on{" "}
                              </span>{" "}
                              {item.created.split("T").join(" ").split(".")[0]}
                            </div>
                            <td
                              className="border border-gray-400 p-2 rounded !max-h-[150px] overflow-auto !min-h-[60px] overflow-y-auto !text-[13px] min-w-full"
                              dangerouslySetInnerHTML={{
                                __html: item?.comment,
                              }}
                            />
                          </div>
                        </div>
                        {item?.upload_file ? (
                          <>
                            <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mb-2 mt-3 border rounded p-2 bg-blue-50">
                              <div className="flex flex-col items-start gap-1">
                                <span className="header-5 text-color-gray">
                                  Uploaded File:
                                </span>
                                <div className="flex flex-row items-center">
                                  <span className="text-[14px] text-color-black font-extrabold">
                                    {item.filename} -&nbsp;
                                  </span>
                                  <a
                                    className="italic  text-[14px] hover:underline"
                                    href={item.file_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    )}
                  </>
                ))}
              </div>
              {ticket_status == 1 ? (
                <div className="flex justify-end text-center w-[100%] p-3 italic text-hr-primary-1 text-[14px]">
                  Ticket resolved
                </div>
              ) : (
                <div className="flex flex-row justify-end items-center gap-1 text-right italic text-hr-primary-1 text-[14px] mt-3 mb-2">
                  <Button
                    text="Send new message"
                    type="button"
                    textClass={"!text-[11px]"}
                    className={"h-[32px] w-fit p-2"}
                    onClick={() => {
                      setCommentId(null);
                      setType("add");
                      setComment(null);
                      setAddComment(true);
                    }}
                  />
                </div>
              )}
            </div>
          ) : (
            <>
              {ticket_status == 1 ? (
                <div className="flex justify-end text-center w-[100%] p-3 italic text-hr-primary-1 text-[14px]">
                  Ticket resolved
                </div>
              ) : (
                <div
                  className="text-center w-[100%] p-3 italic text-hr-primary-1 text-[14px]"
                  onClick={() => {
                    setType("add");
                    setComment(null);
                    setCommentId(null);
                    setAddComment(true);
                  }}
                >
                  <span className="text-center w-[100%] p-3 italic text-hr-primary-1 text-[14px] hover:underline hover:text-[16px] duration-300 cursor-pointer">
                    Send new message
                  </span>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
