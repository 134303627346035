import React, { useEffect } from "react";
import { useState } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Button from "../../../button";
import LoadingBar from "../../../loader-bar";
import FormSwitch from "../../../form-switch";
import FormSelect from "../../../form-select";
import FormInput from "../../../form-input";
import { formValidation } from "../../../../utils/functions";
import FormSelectEmployee from "../../../employees/form-select-employee";
import { useGetBranchSettings } from "../../../../redux/human-resources/hook/branches";
import DOMPurify from "dompurify";
import { useUpdateAttendanceSettings } from "../../../../redux/human-resources/hook/attendance";
import { useUpdateLeaveSettings } from "../../../../redux/human-resources/hook/leave";
import { useUpdateManagerSettings } from "../../../../redux/human-resources/hook/managers";

const BranchSettings = () => {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const settings_type = [
    {
      name: "MANAGER SETTINGS",
      title: "Branch Manager Settings",
    },
    {
      name: "ATTENDANCE SETTINGS",
      title: "Branch Attendance Settings",
    },
    {
      name: "LEAVE SETTINGS",
      title: "Branch Leave Settings",
    },
  ];

  const [sourceId, setSourceId] = useState();

  const { id } = useParams();

  // const { company } = useSelector((state) => state.companySlice);
  const [applyToPayroll, setApplyToPayroll] = useState(true);
  const [clockinWithLink, setClockinWithLink] = useState(true);

  const { data: branch_settings, isLoading } = useGetBranchSettings(id);

  useEffect(() => {
    if (branch_settings) {
      setSourceId(branch_settings.id);
      if (branch_settings.manager_setting) {
        setManager(branch_settings.manager_setting?.manager_id);
        setManagerName(branch_settings.manager_setting?.manager);
        setManagerSettingId(branch_settings.manager_setting?.id);
        setEvaluatePerformance(
          branch_settings.manager_setting?.evaluate_performance == 1
            ? true
            : false
        );
        setApproveLeave(
          branch_settings.manager_setting?.approve_leave == 1 ? true : false
        );
        setCanMakePayments(
          branch_settings.manager_setting?.can_make_payments == 1 ? true : false
        );
        setSinglePaymentLimit(
          branch_settings.manager_setting?.single_payment_limit
        );
        setCanInitiatePayment(
          branch_settings.manager_setting?.can_initiate_payment == 1
            ? true
            : false
        );
        setCanReadPayroll(
          branch_settings.manager_setting?.can_read_payroll == 1 ? true : false
        );
        setCanWritePayroll(
          branch_settings.manager_setting?.can_write_payroll == 1 ? true : false
        );
        setCanViewTransactions(
          branch_settings.manager_setting?.can_read_transaction_history == 1
            ? true
            : false
        );
      }
      if (branch_settings.leave_setting) {
        setDayCount(
          branch_settings.leave_setting?.days_count
            ? branch_settings.leave_setting?.days_count
            : "seven_days"
        );
        setApplicationStart(branch_settings.leave_setting?.application_start);
      }
      if (branch_settings.attendance_setting) {
        setWorkdays([
          {
            day: "Sunday",
            tag: "SUN",
            work:
              branch_settings.attendance_setting?.workdays?.sun == 1 ? 1 : 0,
          },
          {
            day: "Monday",
            tag: "MON",
            work:
              branch_settings.attendance_setting?.workdays?.mon == 1 ? 1 : 0,
          },
          {
            day: "Tuesday",
            tag: "TUE",
            work:
              branch_settings.attendance_setting?.workdays?.tue == 1 ? 1 : 0,
          },
          {
            day: "Wednesday",
            tag: "WED",
            work:
              branch_settings.attendance_setting?.workdays?.wed == 1 ? 1 : 0,
          },
          {
            day: "Thursday",
            tag: "THU",
            work:
              branch_settings.attendance_setting?.workdays?.thu == 1 ? 1 : 0,
          },
          {
            day: "Friday",
            tag: "FRI",
            work:
              branch_settings.attendance_setting?.workdays?.fri == 1 ? 1 : 0,
          },
          {
            day: "Saturday",
            tag: "SAT",
            work:
              branch_settings.attendance_setting?.workdays?.sat == 1 ? 1 : 0,
          },
        ]);
        setApplyToPayroll(
          branch_settings.attendance_setting?.apply_to_payroll == 1
            ? true
            : false
        );
        setClockinWithLink(
          branch_settings.attendance_setting?.clockin_with_link == 1
            ? true
            : false
        );
        setAutoConfirm(
          branch_settings.attendance_setting?.auto_confirm == 1 ? true : false
        );
        setClockIn(
          branch_settings.attendance_setting?.clockin_time
            ? branch_settings.attendance_setting?.clockin_time
            : "09:00"
        );
        setClockOut(
          branch_settings.attendance_setting?.clockout_time
            ? branch_settings.attendance_setting?.clockout_time
            : "05:00"
        );
      }
    }
  }, [branch_settings]);

  const [selectedAccountIndex, setSelectedAccountIndex] = useState(0);

  //Manager settings
  const { mutateAsync: updateManagerSettings, isLoading: managerLoading } =
    useUpdateManagerSettings();
  const [managerSettingId, setManagerSettingId] = useState();
  const [manager, setManager] = useState();
  const [managerName, setManagerName] = useState();

  const updateManagerSetting = async (data) => {
    let payload = {
      id: managerSettingId,
      data: {
        manager_id: manager,
        approve_leave: approveLeave ? 1 : 0,
        evaluate_performance: evaluatePerformance ? 1 : 0,
        can_make_payments: canMakePayments ? 1 : 0,
        single_payment_limit: singlePaymentLimit,
        can_initiate_payment: canInitiatePayment ? 1 : 0,
        can_read_payroll: canReadPayroll ? 1 : 0,
        can_write_payroll: canWritePayroll ? 1 : 0,
        can_read_transaction_history: canViewTransactions ? 1 : 0,
      },
    };
    await updateManagerSettings(payload);
  };

  //Leave Settings
  const { mutateAsync: updateLeaveSettings, isLoading: leaveLoading } =
    useUpdateLeaveSettings();

  const [dayCount, setDayCount] = useState("seven_days");
  const [applicationStart, setApplicationStart] = useState(60);
  const dayCountOptions = [
    { value: "seven_days", label: "Calendar Days" },
    { value: "week_days", label: "Working Days" },
  ];

  const updateLeaveSetting = async (data) => {
    let payload = {
      source: "branch",
      source_id: sourceId,
      application_start: applicationStart,
      days_count: dayCount,
    };
    await updateLeaveSettings(payload);
  };

  // Attendance Settings
  const {
    mutateAsync: updateAttendanceSettings,
    isLoading: attendanceLoading,
  } = useUpdateAttendanceSettings();

  // const [location, setLocation] = useState(company? company.address + ', ' + company.city + ', ' + company.state + ', ' + company.country: '');
  const [workDays, setWorkdays] = useState([
    {
      day: "Sunday",
      tag: "SUN",
      work: 0,
    },
    {
      day: "Monday",
      tag: "MON",
      work: 1,
    },
    {
      day: "Tuesday",
      tag: "TUE",
      work: 1,
    },
    {
      day: "Wednesday",
      tag: "WED",
      work: 1,
    },
    {
      day: "Thursday",
      tag: "THU",
      work: 1,
    },
    {
      day: "Friday",
      tag: "FRI",
      work: 1,
    },
    {
      day: "Saturday",
      tag: "SAT",
      work: 0,
    },
  ]);

  const [autoConfirm, setAutoConfirm] = useState(true);
  const [approveLeave, setApproveLeave] = useState(true);
  const [evaluatePerformance, setEvaluatePerformance] = useState(true);

  const [canMakePayments, setCanMakePayments] = useState(false);
  const [singlePaymentLimit, setSinglePaymentLimit] = useState(0);
  const [canInitiatePayment, setCanInitiatePayment] = useState(false);
  const [canReadPayroll, setCanReadPayroll] = useState(false);
  const [canWritePayroll, setCanWritePayroll] = useState(false);
  const [canViewTransactions, setCanViewTransactions] = useState(true);

  const [clockIn, setClockIn] = useState("09:00");
  const [clockOut, setClockOut] = useState("17:00");

  const updateAttendanceSetting = async (data) => {
    const clock_in = DOMPurify.sanitize(data?.clock_in);
    const clock_out = DOMPurify.sanitize(data?.clock_out);
    // const location = DOMPurify.sanitize(data?.location);

    let payload = {
      source: "branch",
      source_id: sourceId,
      clockin_time: clock_in,
      clockout_time: clock_out,
      apply_to_payroll: applyToPayroll ? 1 : 0,
      clockin_with_link: clockinWithLink ? 1 : 0,
      auto_confirm: autoConfirm,
      sun: workDays[0].work,
      mon: workDays[1].work,
      tue: workDays[2].work,
      wed: workDays[3].work,
      thu: workDays[4].work,
      fri: workDays[5].work,
      sat: workDays[6].work,
    };
    await updateAttendanceSettings(payload);
  };

  return (
    <>
      <div className="flex flex-col bg-white w-full rounded min-h-fit pb-4">
        <div className="flex flex-col px-8 pt-3">
          <span className="header-4">Branch Settings</span>
          <span className="text-color-gray text-[13px]">
            Update and edit your Branch settings
          </span>
        </div>
        <hr className="divider mt-3 mb-3" />
        {isLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px] px-8">
            <LoadingBar loading={isLoading} />
          </div>
        ) : (
          <div className="flex flex-col md:flex-row h-full px-8">
            <div className="flex flex-col md:max-w-[450px] w-full">
              {settings_type.map((item, index) => (
                <div
                  className={classNames(
                    "w-full max-w-[100%] md:max-w-[450px] h-[80px] flex flex-row items-center px-3 justify-between cursor-pointer border border-gray-300 rounded mb-3 hover:bg-gray-50 duration-300",
                    {
                      "bg-[#eff2fe] cursor-default":
                        index === selectedAccountIndex,
                    }
                  )}
                  onClick={() => {
                    if (selectedAccountIndex !== index) {
                      setSelectedAccountIndex(index);
                    }
                  }}
                  key={index}
                >
                  <div className="flex flex-col">
                    <span className="p4-bold">{item.name}</span>
                    <span className="p4-regular">{item.title}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-[100%] md:ml-4">
              <div className="border border-color-line rounded px-2 md:px-4 py-2 flex flex-col">
                <div className="w-full md:w-[100%]">
                  {selectedAccountIndex === 0 ? (
                    <form>
                      <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full">
                        <FormSelectEmployee
                          label={"Branch Manager"}
                          name="manager"
                          placeholder="Select Branch Manager"
                          value={manager}
                          empName={managerName}
                          setEmployee={setManager}
                        />
                        <div className={"flex w-[100%] flex-col -mt-4"}>
                          <div className="w-full">
                            <FormSwitch
                              label={"Can Approve Branch Leave?"}
                              name={"approve_leave"}
                              checked={approveLeave}
                              onClick={() => {
                                setApproveLeave(!approveLeave);
                              }}
                            />
                          </div>
                          <div className="w-full">
                            <FormSwitch
                              label={"Can Evaluate Branch Performance?"}
                              name={"evaluate_performance"}
                              checked={evaluatePerformance}
                              onClick={() => {
                                setEvaluatePerformance(!evaluatePerformance);
                              }}
                            />
                          </div>
                          <div className="w-full">
                            <FormSwitch
                              label={"Can Initiate Branch Payment?"}
                              name={"can_initiate_payment"}
                              checked={canInitiatePayment}
                              onClick={() => {
                                setCanInitiatePayment(!canInitiatePayment);
                              }}
                            />
                          </div>
                          <div className="w-full">
                            <FormSwitch
                              label={"Can View Branch Transactions?"}
                              name={"can_read_transaction_history"}
                              checked={canViewTransactions}
                              onClick={() => {
                                setCanViewTransactions(!canViewTransactions);
                              }}
                            />
                          </div>
                          <div className="w-full -mb-2">
                            <FormSwitch
                              label={"Can Approve Branch Payments?"}
                              name={"can_make_payemnts"}
                              checked={canMakePayments}
                              onClick={() => {
                                setCanMakePayments(!canMakePayments);
                              }}
                            />
                          </div>
                          {canMakePayments ? (
                            <div className="p-3 pt-1 pb-1 border bg-white rounded-md">
                              <FormInput
                                label="Branch Payment Limit (0 means no limit)"
                                name="single_payment_limit"
                                placeholder={"Enter Amount"}
                                type="number"
                                value={singlePaymentLimit}
                                onInput={(e) =>
                                  setSinglePaymentLimit(e.target.value)
                                }
                                error={errors.single_payment_limit}
                                errorMessage={
                                  errors.single_payment_limit &&
                                  errors.single_payment_limit.message
                                }
                              />
                            </div>
                          ) : null}
                          <hr className="divider mt-2" />
                          <div className="p-3 pt-3 pb-0 border bg-white rounded-md">
                            <FormSwitch
                              label={"Can View Branch Payroll?"}
                              name={"can_read_payroll"}
                              checked={canReadPayroll}
                              onClick={() => {
                                if (!canReadPayroll === false) {
                                  setCanWritePayroll(!canReadPayroll);
                                }
                                setCanReadPayroll(!canReadPayroll);
                              }}
                            />
                            {canReadPayroll ? (
                              <FormSwitch
                                label={"Can Run Branch Payroll?"}
                                name={"can_write_payrol"}
                                checked={canWritePayroll}
                                onClick={() => {
                                  setCanWritePayroll(!canWritePayroll);
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="flex items-center justify-end w-full mt-2">
                          <Button
                            text="Update Manager Settings"
                            type="button"
                            loading={managerLoading}
                            disabled={!manager ? true : false}
                            className="flex gap-2 !h-[35px] w-fit px-2 items-center"
                            textClass={"!text-[11px]"}
                            onClick={() => {
                              updateManagerSetting();
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  ) : selectedAccountIndex === 1 ? (
                    <form onSubmit={handleSubmit(updateAttendanceSetting)}>
                      <div className="grid md:grid-cols-1 grid-cols-1 gap-2 w-full">
                        <div className="flex flex-row md:flex-col w-full justify-between">
                          <div className="w-full md:w-1/2 pr-2">
                            <FormInput
                              label="Resumption Time"
                              name="clock_in"
                              type="time"
                              value={clockIn}
                              onInput={(e) => {
                                setClockIn(e.target.value);
                              }}
                              inputRef={register(formValidation("time", true))}
                              error={errors.clock_in}
                              errorMessage={
                                errors.clock_in && errors.clock_in.message
                              }
                            />
                          </div>
                          <div className="w-full md:w-1/2 pl-2">
                            <FormInput
                              label="Closing Time"
                              name="clock_out"
                              type="time"
                              value={clockOut}
                              onInput={(e) => {
                                setClockOut(e.target.value);
                              }}
                              inputRef={register(formValidation("time", true))}
                              error={errors.clock_out}
                              errorMessage={
                                errors.clock_out && errors.clock_out.message
                              }
                            />
                          </div>
                        </div>
                        <div
                          className={
                            "flex w-[100%] flex-col items-center gap-3 mt-1 "
                          }
                        >
                          <div
                            className={
                              "flex w-full items-start justify-between gap-3 flex-col -mt-1"
                            }
                          >
                            <div className="text-color-gray text-[13px] ">
                              Work Days
                            </div>
                            <div className="flex flex-row items-center justify-between w-full p-[12px] border-1 border-gray-300 bg-highlight rounded -mt-4">
                              {workDays.map((item, index) => (
                                <button
                                  key={index}
                                  type="button"
                                  className={
                                    !item.work
                                      ? "flex flex-row items-center !h-[24px] px-2 border-1 rounded border-[#0d5225] text-[#0d5225] text-[12px] cursor-pointer"
                                      : "flex flex-row items-center !h-[24px] px-2 border-1 rounded border-[#0d5225] bg-[#0d5225] text-color-white text-[12px] cursor-pointer"
                                  }
                                  onClick={() => {
                                    let newArray = JSON.parse(
                                      JSON.stringify(workDays)
                                    );

                                    newArray[index].work =
                                      !item.work || item.work === 0 ? 1 : 0;
                                    setWorkdays(newArray);
                                  }}
                                >
                                  {item.tag}
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                        <hr className="divider" />
                        <div className="w-full">
                          <FormSwitch
                            label={"Apply To Payroll?"}
                            name={"apply_to_payroll"}
                            checked={applyToPayroll}
                            onClick={() => {
                              setApplyToPayroll(!applyToPayroll);
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <FormSwitch
                            label={"Employee Clockin With Link?"}
                            name={"clockin_with_link"}
                            checked={clockinWithLink}
                            onClick={() => {
                              setClockinWithLink(!clockinWithLink);
                            }}
                          />
                        </div>
                        <div className="flex items-center justify-end w-full mt-[20px]">
                          <Button
                            text="Update Attendance Settings"
                            type="submit"
                            theme="primary"
                            loading={attendanceLoading}
                            className="flex gap-2 !h-[35px] w-fit px-2  items-center"
                            textClass={"!text-[11px]"}
                          />
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form>
                      <div className="flex flex-row items-center">
                        <div className="w-full">
                          <FormInput
                            label="Application Start (From Date Of Employment)"
                            name="application_start"
                            type="number"
                            value={applicationStart}
                            onInput={(e) => {
                              setApplicationStart(e.target.value);
                            }}
                            inputRef={register(formValidation("number", true))}
                            error={errors.application_start}
                            errorMessage={
                              errors.application_start &&
                              errors.application_start.message
                            }
                          />
                        </div>
                        <span className="pt-1 italic text-[14px] pl-2 text-[#0d5225]">
                          Days
                        </span>
                      </div>
                      <div className="flex flex-row items-center">
                        <div className="w-full">
                          <FormSelect
                            value={dayCount}
                            options={dayCountOptions}
                            onChange={(selected) => {
                              setDayCount(selected);
                            }}
                            label="Day Count"
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-end w-full mt-[20px]">
                        <Button
                          text="Update Leave Settings"
                          type="button"
                          theme={"primary"}
                          loading={leaveLoading}
                          className="flex gap-2 !h-[35px] w-fit px-2 items-center"
                          textClass={"!text-[11px]"}
                          onClick={() => {
                            updateLeaveSetting();
                          }}
                        />
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BranchSettings;
