import React, { useEffect } from "react";
import { useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import TabView from "../../../components/tabview";
import { useLocation } from "react-router-dom";
import UnauthorizedPage from "../../errors/unauthorized";
import SelectVendorOption from "../../../components/modal/spend-management/vendor/select-vendor-option";
import SelectVendorPayOption from "../../../components/modal/spend-management/vendor/select-vendor-pay-option";
import { useSelector } from "react-redux";
import CustomerListTab from "../../../components/spend-management-section/customers/customers-list-tab";
import { InvoicesTab } from "../../../components/spend-management-section/customers/invoices-tab";
import { RecurringInvoicesTab } from "../../../components/spend-management-section/customers/recurring-invoices-tab";

const CustomersPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.vendor_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);

  const [showSelectVendorPayOption, setShowSelectVendorPayOption] =
    useState(false);
  const [showSelectAddVendorOption, setShowSelectAddVendorOption] =
    useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location && location.state) {
      setActiveTab(location.state.tab);
    }
  }, [location]);

  const customerTabs = [
    {
      title: "Customers List",
      Content:
        user?.permissions?.customer_permission?.customer_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? CustomerListTab
          : UnauthorizedPage,
    },
    {
      title: "Invoices",
      Content:
        user?.permissions?.customer_permission?.invoice_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? InvoicesTab
          : UnauthorizedPage,
    },
    {
      title: "Recurring Invoices",
      Content:
        user?.permissions?.customer_permission?.invoice_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? RecurringInvoicesTab
          : UnauthorizedPage,
    },
  ];

  return (
    <DashboardLayout title="Customers Management">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col">
          {showSelectAddVendorOption ? (
            <SelectVendorOption
              isOpen={showSelectAddVendorOption}
              closeModal={() => setShowSelectAddVendorOption(false)}
            />
          ) : null}
          {showSelectVendorPayOption ? (
            <SelectVendorPayOption
              isOpen={showSelectVendorPayOption}
              closeModal={() => setShowSelectVendorPayOption(false)}
            />
          ) : null}
          <div>
            <TabView
              Tabs={customerTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default CustomersPage;
