import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../components/button";
import DataTable from "../../components/datatable";
import DashboardLayout from "../../components/layouts/dashboard";
import { updateCurrentCompany } from "../../redux/company/hook";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import WarningSVG from "../../assets/svg/warning.svg";
import {
  useRefAirtime,
  useRemoveAirtimesById,
  useRevertAirtime,
} from "../../redux/airtime/hook";
import AirtimeSummary from "../../components/modal/airtime/airtime-summary";
import AirtimeDetailedView from "../../components/modal/airtime/airtime-full-view";
import UnauthorizedPage from "../errors/unauthorized";
import { useSelector } from "react-redux";
import { useGetCompanyAccountDetails } from "../../redux/wallet/hook";

const AirtimePreviewPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);
  const { data: companyAccountDetails } = useGetCompanyAccountDetails();

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (
          user.permissions.company_permission.airtime_read !== 1 ||
          user.permissions.airtime_permission?.airtime_read !== 1
        ) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { ref } = useParams();

  const { data: airtime, isLoading: loading } = useRefAirtime(
    ref,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: revertAirtime } = useRevertAirtime();
  const { mutateAsync: removeAirtimeEntry } = useRemoveAirtimesById();

  const [showAirtimeSummary, setShowAirtimeSummary] = useState(false);
  const [approve, setApprove] = useState("");
  const [previewData, setPreviewData] = useState();
  const [previewType, setPreviewType] = useState();
  const [showAirtimeDetailedView, setShowAirtimeDetailedView] = useState(false);

  useEffect(() => {
    updateCurrentCompany();
  }, []);

  useEffect(() => {
    if (airtime) {
      if (loading === false && airtime.data.length < 1) {
        revertAirtime().then(() => {
          history.push("/airtime", { tab: 1 });
        });
      }
    }
  }, [airtime, loading]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location) {
      if (location.state === "approve") {
        setApprove("approve");
        showAirtimeSummary(true);
      }
    }
  }, [location]);

  const tableConfig = {
    headers: ["Name", "Mobile", "Network", "Amount"],
    keys: ["name", "mobile", "network", "amount"],
    mobileHeader: {
      left: {
        title: "Name",
        key: "name",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };

  const actionConfig = [
    {
      name: "Edit",
      color: "green",
      action: (id) => {
        let index = airtime.data.findIndex((item) => item.id === id);
        setPreviewData(airtime.data[index]);
        setPreviewType("edit");
        setShowAirtimeDetailedView(true);
      },
    },
    {
      name: "Details",
      color: "orange",
      action: (id) => {
        let index = airtime.data.findIndex((item) => item.id === id);
        setPreviewData(airtime.data[index]);
        setPreviewType("view");
        setShowAirtimeDetailedView(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: async (id) => {
        let index = airtime.data.findIndex((item) => item.id === id);
        await removeAirtimeEntry(airtime.data[index].id);
      },
    },
  ];

  return (
    <DashboardLayout title="Airtime Purchase Preview">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {previewData && showAirtimeDetailedView ? (
            <AirtimeDetailedView
              isOpen={showAirtimeDetailedView}
              closeModal={() => setShowAirtimeDetailedView(false)}
              viewData={previewData}
              previewType={previewType}
            />
          ) : null}
          <div className="flex flex-col">
            <div className="flex md:flex-row flex-col justify-between">
              <div className="flex flex-col">
                <span className="h4 text-color-black">
                  A Quick Preview Before You Submit
                </span>
                <span className="p3 text-color-gray">
                  Please spend a brief moment reviewing these numbers before you
                  submit Airtime For Purchase
                </span>
              </div>
              {user?.permissions.airtime_permission?.airtime_write === 1 ||
              user?.permissions.company_permission?.role === "Owner" ? (
                <div className="w-full md:w-[220px] h-[56px]">
                  <Button
                    text="Submit"
                    type="submit"
                    loading={false}
                    disabled={false}
                    className="h-[56px]"
                    onClick={() => setShowAirtimeSummary(true)}
                  />
                </div>
              ) : null}
            </div>
            <div className="mt-9">
              <AirtimeSummary
                refValue={ref}
                isOpen={showAirtimeSummary}
                closeModal={() => setShowAirtimeSummary(false)}
                airtimeStatus={approve}
                totalAmount={airtime?.total_amount}
                totalAirtime={airtime?.total_amount}
              />

              <div className="flex flex-col">
                <div className="flex flex-col md:flex-row ">
                  <div className="w-[100%] md:w-[60%]">
                    <DataTable
                      data={airtime}
                      noCheck={true}
                      computedActions={true}
                      tableConfig={tableConfig}
                      actionConfig={actionConfig}
                      updatePagination={(data) => setPagination(data)}
                    />
                  </div>
                  <div className="w-[100%] md:w-[40%] max-w-[480px] flex flex-col md:pl-8 md:mt-1 mt-5">
                    <div className=" bg-white flex flex-col w-full p-3 ">
                      <div className="mb-2"></div>
                      <div className="mb-2">
                        <div className="flex flex-row justify-between">
                          <span className="p4-medium text-color-gray">
                            Total Airtime Amount
                          </span>
                          <span className="p4-medium  text-color-black">
                            {airtime?.total_amount}
                          </span>
                        </div>
                        <hr className="divider" />
                      </div>
                      <div className="mb-2">
                        <div className="flex flex-row justify-between">
                          <span className="p4-medium text-color-gray">
                            Transaction cost
                          </span>
                          <span className="p4-medium  text-color-black">
                            {"0.00"}
                          </span>
                        </div>
                        <hr className="divider" />
                      </div>
                      <div className="mb-2">
                        <div className="flex flex-row justify-between">
                          <span className="p4-medium text-secondary-2">
                            Total Payable
                          </span>
                          <span className="p4-medium  text-color-black">
                            {airtime?.total_payable}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-4 mt-8 bg-color-warning-light">
                    <div className="w-[70px] pr-2 flex flex-row items-center ">
                      <img src={WarningSVG} alt="" srcSet="" />
                    </div>
                    <span>
                      After you review and submit this payroll, we will debit{" "}
                      {airtime?.total_amount}. and transaction charge is{" "}
                      {"0.00"}. Please make sure you have these funds available
                      in your account. If everything looks good, submit payroll.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default AirtimePreviewPage;
