import { Axios } from '../axios';

const addAttendance = async (payload) => {
    const { data } = await Axios.post('/attendances', payload);
    return data;
};

const bulkAddBAttendance = async (payload) => {
    const { data } = await Axios.post(
      '/attendances/bulk', 
      payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 0
      }
    );
    return data;
  };

const getAttendanceWorkSchedules = async (payload) => {
    let start_date = payload.start_date != -1 ? 'start_date=' + payload.start_date : '';
    let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';
    const { data } = await Axios.get( `/attendance/work-schedule/${payload.id}?${start_date}${end_date}`, 
    {
      timeout: 0
    });
    return data?.data;
};

const getAttendances = async (payload) => {
    let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get( `/attendances?limit=${payload.limit}&page=${payload.page}${filter}${search}${start_date}${end_date}`,
    {
        timeout: 0
    });
    return data?.data;
};

const getCurrentAttendances = async (payload) => {
    let attendance_date = payload.attendance_date ? '&attendance_date=' + payload.attendance_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get( `/attendances/current?limit=${payload.limit}&page=${payload.page}${filter}${search}${attendance_date}`,
    {
        timeout: 0
    });
    return data?.data;
};

const getAttendanceSummary = async () => {
    const { data } = await Axios.post('/attendances/summary');
    return data?.data;
};

const updateAttendance = async (payload) => {
    const { data } = await Axios.put('/attendances/' + payload.id, payload.data);
    return data;
};

const getAttendanceByEmployee = async (payload) => {
    let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';
    const { data } = await Axios.get(`/attendances/by-employee/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}`,);
    return data?.data;
};
  
const getAttendance = async (payload) => {
    const { data } = await Axios.get('/attendances/' + payload);
    return data.data;
};

const getAttendanceSchedules = async (payload) => {
    let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.get( `/attendance-schedules?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}`,
    {
        timeout: 0
    });
    return data?.data;
};

const getAttendanceScheduleBySource = async (payload) => {
    let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.post(`/attendance-schedules/by-source?limit=${payload.limit}&page=${payload.page}${search}&source_id=${payload.source_id}&source=${payload.source}${start_date}${end_date}`,);
    return data?.data;
};

const getAttendanceSchedule = async (payload) => {
    const { data } = await Axios.get('/attendance-schedule/' + payload);
    return data?.data;
};

const addAttendanceSchedule = async (payload) => {
    const { data } = await Axios.post('/attendance-schedules', payload);
    return data;
};

const updateAttendanceSchedule = async (payload) => {
    const { data } = await Axios.put('/attendance-schedules/' + payload.schedule_id, payload.data);
    return data;
};

const deleteAttendanceSchedule = async (payload) => {
    const { data } = await Axios.delete('/attendance-schedules/' + payload);
    return data;
};

const markAttendance = async (payload) => {
    const { data } = await Axios.get(`/attendance/${payload.type}/` + payload);
    return data?.data;
};

const getAttendanceSettings = async (payload) => {
    let source = payload.source ? '&source=' + payload.source : '&source=company';
    let source_id = payload.source ? '&source_id=' + payload.source_id : null;
    const { data } = await Axios.get(`/attendance-settings?${source_id}${source}`);
    return data?.data;
};

const updateAttendanceSettings = async (payload) => {
    const { data } = await Axios.post('/attendance-settings', payload);
    return data;
};

const bulkAddBulkAttendanceSchedules = async (payload) => {
    const { data } = await Axios.post(
      '/bulk-attendance-schedules',
      payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 0
      }
    );
    return data;
};

const getEmployeeClockInLink = async () => {
    const { data } = await Axios.get('/get-clockin-link' );
    return data?.data;
};

const refreshClockInLink = async () => {
    const { data } = await Axios.post('/refresh-clockin-link');
    return data;
};

const changeClockInLinkSatus = async (payload) => {
    const { data } = await Axios.post('/update-clockin-link-status');
    return data;
};

const fetchCompanyAttendanceInfo = async (payload) => {
    const { data } = await Axios.get(`/clockin/on-site/fetch-company-data/${payload}` );
    return data?.data;
};

const fetchAttendanceDataOnSite = async (payload) => {
    const { data } = await Axios.post('/clockin/on-site/fetch-attendance-data', payload);
    return data;
};

const clockInOutOnSite = async (payload) => {
    const { data } = await Axios.post('/clockin/on-site/clock-in-out', payload);
    return data;
};
const updateApprovedIps = async (payload) => {
    const { data } = await Axios.post('/update-clockin-approved-ips', payload);
    return data;
};



export const attendances = {
    addAttendance,
    bulkAddBAttendance,
    getAttendanceWorkSchedules,
    getAttendances,
    getCurrentAttendances,
    getAttendanceByEmployee,
    getAttendanceSchedules,
    getAttendanceScheduleBySource,
    getAttendanceSummary,
    updateAttendance,
    getAttendance,
    markAttendance,
    getAttendanceSettings,
    updateAttendanceSettings,
    updateAttendanceSchedule,
    getAttendanceSchedule,
    addAttendanceSchedule,
    deleteAttendanceSchedule,
    bulkAddBulkAttendanceSchedules,

    getEmployeeClockInLink,
    refreshClockInLink,
    changeClockInLinkSatus,

    fetchCompanyAttendanceInfo,
    fetchAttendanceDataOnSite,
    clockInOutOnSite,
    updateApprovedIps
};
