import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../utils/constants";

import { useGetEmployeeReimbursements } from "../../../redux/employees/hook/reimbursements";
import AddReImbursement from "../../modal/employees/add-reimbursement";

const ReimbursementRequestTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: employeeReimbursements } = useGetEmployeeReimbursements(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const [showAddReImbursement, setShowAddReImbursement] = useState(false);
  const [reimbData, setReimbData] = useState();

  const tableConfig = {
    // title: 'Reimbursement Management',
    headers: [
      "Employee Name",
      "Phone Number",
      "Purpose",
      "Type",
      "Month",
      "Amount",
    ],
    keys: [
      "employeeName",
      "phone",
      "expenses_description",
      "typeLabel",
      "month",
      "expenses_amount",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "mobile",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "green",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        setReimbData(employeeReimbursements.data[index]);
        setShowAddReImbursement(true);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white">
      {showAddReImbursement ? (
        <AddReImbursement
          isOpen={showAddReImbursement}
          closeModal={() => {
            setShowAddReImbursement(false);
          }}
          reimbData={reimbData}
          title="Edit Reimbursement"
          buttonText="UPDATE"
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="p3-bold header-4">Reimbursements</div>
      </div>
      <hr className="divider mt-2 mb-2" />
      <DataTable
        data={employeeReimbursements}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        // bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default ReimbursementRequestTab;
