import React, { useEffect } from "react";
import DataTable from "../../../datatable";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useGetRecruitmentStages } from "../../../../redux/human-resources/hook/recruitment";
import Button from "../../../button";
import UpdateRecruitmentStages from "../../../modal/hr-section/recruitment/update-stages";
import SendStageEmail from "../../../modal/hr-section/recruitment/send-email-by-stage";

const CurrentStageTab = () => {
  useEffect(() => {}, []);

  const { id } = useParams();

  const { data: recruitment_stages } = useGetRecruitmentStages(id);

  const [showUpdateStages, setShowUpdateStages] = useState(false);

  const [showSendEmail, setShowSendEmail] = useState(false);

  const [stage, setStage] = useState(false);

  const [recruitmentId, setRecruitmentId] = useState();

  useEffect(() => {
    setRecruitmentId(id);
  }, [id]);

  const history = useHistory();

  const tableConfig = {
    headers: ["Date Added", "Stage", "Name", "Type", "Applicant Count"],
    keys: ["created", "index", "name", "type", "0"],
    mobileHeader: {
      left: {
        title: "Name",
        key: "name",
      },
      right: {
        title: "Type",
        key: "type",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = recruitment_stages.data.findIndex((item) => item.id === id);
        let stageData = recruitment_stages.data[index];
        stageData.recruitment_id = recruitmentId;
        history.push(
          `/recruitment/stage/${recruitment_stages.data[index].index}`,
          stageData
        );
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showSendEmail ? (
        <SendStageEmail
          title={"Send Email"}
          isOpen={showSendEmail}
          closeModal={() => setShowSendEmail(false)}
          stage={stage}
          buttonText="Send Email"
        />
      ) : null}
      {showUpdateStages ? (
        <UpdateRecruitmentStages
          isOpen={showUpdateStages}
          closeModal={() => setShowUpdateStages(false)}
          stagesData={recruitment_stages?.data}
          title="Update Recruitment Stages"
          buttonText="UPDATE STAGES"
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Recruitment Stages
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Update Stages"
              type="submit"
              onClick={() => {
                setShowUpdateStages(true);
              }}
              rightIcon={"change"}
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={recruitment_stages}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        // updatePagination={(data) => setPagination(data)}
        computedActions={true}
        noCheck={true}
      />
    </div>
  );
};

export default CurrentStageTab;
