import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import Button from "../../button";
import AddPerformanceSmartGoals from "../../modal/hr-section/performance/add-smart-goal";
import {
  useDeleteSmartGoals,
  useGetPerformanceSmartGoals,
} from "../../../redux/human-resources/hook/performance";

const PerformanceSmartGoalsTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    source: "",
    search: "",
  });

  const [smartGoalData, setSmartGoalData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [showAddSmartGoal, setShowAddSmartGoal] = useState(null);
  const [type, setType] = useState("edit");

  const { mutateAsync: deleteSmartGoal } = useDeleteSmartGoals();

  const { data: smartGoals } = useGetPerformanceSmartGoals(
    pagination?.limit,
    pagination?.page,
    pagination?.source,
    pagination?.search
  );

  const tableConfig = {
    headers: [
      "Title",
      "Source",
      "Source Name",
      // 'Evaluation Frequency',
      // 'Auto-evaluate'
    ],
    keys: [
      "smart_goal_title",
      "source",
      "source_name",
      // 'evaluation_frequency',
      // 'auto_evaluate_converted',
    ],
    mobileHeader: {
      left: {
        title: "Title",
        key: "smart_goal_title",
      },
      right: {
        title: "Period Covered",
        key: "period",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Update",
      color: "green",
      action: (id) => {
        let index = smartGoals.data.findIndex((item) => item.id === id);
        setTitle("Update Smart Goals");
        setButtonText("Update");
        setType("edit");
        setSmartGoalData(smartGoals.data[index]);
        setShowAddSmartGoal(true);
      },
    },
    {
      name: "Duplicate",
      color: "orange",
      action: (id) => {
        let index = smartGoals.data.findIndex((item) => item.id === id);
        setTitle("Duplicate SMART Goals");
        setType("duplicate");
        setButtonText("Duplicate");
        setSmartGoalData(smartGoals.data[index]);
        setShowAddSmartGoal(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = smartGoals.data.findIndex((item) => item.id === id);
        deleteSmartGoal(smartGoals.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col gap-4 -mt-3">
      {showAddSmartGoal ? (
        <AddPerformanceSmartGoals
          isOpen={showAddSmartGoal}
          closeModal={() => setShowAddSmartGoal(false)}
          smartGoalData={smartGoalData}
          title={title}
          type={type}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col">
        <div className=" bg-white border rounded-md text-[12px] p-2 text-gray-600">
          <span className="font-bold text-[14px]">What are SMART Goals?</span>{" "}
          They are Specific, Measurable, Ambitious (yet attainable!), Relevant,
          and Time-bound. SMART goals allow your employees to develop a deep and
          precise understanding of the goal, its purpose, the way success will
          be measured,and the goal's connection to broader company priorities.
          It also creates a roadmap for scoping the work and drafting a plan of
          action.
        </div>
      </div>
      <div className="flex flex-col bg-white rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Smart Goals
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New SMART Goals"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => {
                  setShowAddSmartGoal(true);
                  setSmartGoalData(null);
                  setTitle("Add SMART Goals");
                  setButtonText("Save");
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={smartGoals}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          computedActions={true}
          searchData={true}
          updatePagination={(data) => setPagination(data)}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default PerformanceSmartGoalsTab;
