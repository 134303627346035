import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import NotAuthorized from "../../assets/svg/403.svg";
import Button from "../../components/button";

function UnauthorizedPage() {
  useEffect(() => {}, []);

  const history = useHistory();

  return (
    <div className="flex items-center justify-center flex-col min-h-[91vh] -mt-20 md:-mb-20 -pb-30">
      <div className="flex items-center flex-col max-h-[20%] max-w-[20%] ">
        <img className="object-contain" src={NotAuthorized} alt="" />
      </div>

      <div className="flex flex-col text-center ">
        <span className="header-3 md:header-2">Not Authorized</span>
        <div className="max-w-[700px] mt-2 flex flex-col items-center">
          <span className="p3 md:header-5-regular">
            You are not <span className="font-bold">authorized</span> to view
            the page you are trying to access
            <br />
            Kindly ask your main administrator to grant permission to this page.
          </span>
          <div className="w-full md:w-[220px] mt-4 md:mt-4 h-[56px]">
            <Button
              text="Back"
              type="submit"
              className="h-[56px]"
              onClick={() => history.goBack()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnauthorizedPage;
