import React from 'react';
import { useForm } from 'react-hook-form';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormFileInput from '../../form-file-input';
import CloseSVG from '../../../assets/svg/close.svg';
import { useHistory } from 'react-router-dom';
import { useRunAirtimeExcel } from '../../../redux/airtime/hook';
import FormInput from '../../form-input';
import DOMPurify from 'dompurify';
import Modal from '../modal';

function BulkPurchaseAirtime({ isOpen, closeModal, refresh }) {
  const { mutateAsync: runExcel, isLoading: excelLoading } =
    useRunAirtimeExcel();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const history = useHistory();

  async function submitForm(data) {
    const desc = DOMPurify.sanitize(data?.desription);
    const formData = new FormData();
    formData.append('file', data?.file[0]);
    formData.append('description', desc);

    await runExcel(formData).then((response) => {
      history.push(`/airtime/preview/${response.data.ref}`);
    });
  }

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>Purchase Bulk Airtime</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <form className='form' onSubmit={handleSubmit(submitForm)}>
          <div className='flex flex-col px-8 pb-4'>
            <div className='flex flex-col text-center justify-center items-center'>
              <span className='header-4-medium'>
                Upload Airtime from XLSX file
              </span>
              <button className='mt-3 h-10 border-[1.5px] border-secondary-2 rounded w-[175px]'>
                <span className='p4 text-secondary-2'>
                  <a
                    href='https://ropay-excel-temporary-prod.s3.amazonaws.com/Airtime+Bulk+(1).xlsx'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Download template
                  </a>
                </span>
              </button>
              <FormFileInput
                multiSource={true}
                inputRef={register(formValidation('file', true))}
                accept='.xlsx'
              />
            </div>
            <FormInput
              label='Description'
              name='desription'
              type='text'
              placeholder={'Enter description'}
              readOnly={excelLoading}
              error={errors.desription}
              errorMessage={errors.desription && errors.desription.message}
              inputRef={register(formValidation('text', true))}
            />

            <div className='w-full mt-[20px]'>
              <Button text='RUN AIRTIME' type='submit' loading={excelLoading} />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default BulkPurchaseAirtime;
