import { Axios } from '../axios';

const getEmployeeWithGuarantor = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(
    `/guarantors/employees?limit=${payload.limit}&page=${payload.page}${search}`,
  );
  return data?.data;
};

const addGuarantor = async (payload) => {
  const { data } = await Axios.post('/guarantors', payload);
  return data;
};

const requestGuarantor = async (payload) => {
  const { data } = await Axios.post('/guarantor/invite-guarantor', payload);
  return data;
};

const getGuarantors = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(
    `/guarantors?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );
  return data?.data;
};

const updateGuarantor = async (payload) => {
  const { data } = await Axios.post('/guarantors/' + payload.id, payload.formData);
  return data;
};

const getGuarantor = async (payload) => {
  const { data } = await Axios.get('/guarantors/' + payload.id);
  return data?.data;
};

const getEmployeeGurantors = async (payload) => {
  const { data } = await Axios.get(
    '/guarantors/fetch-by-employee/' + payload.id,
  );
  return data;
};

const guarantorOnboarding = async (payload) => {
  const { data } = await Axios.post('/guarantor/employee-onboard', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
};

const deleteGuarantor = async (payload) => {
  const { data } = await Axios.delete(`/guarantors/${payload}`);
  return data;
};

export const guarantors = {
  getEmployeeWithGuarantor,
  addGuarantor,
  requestGuarantor,
  getGuarantors,
  updateGuarantor,
  getGuarantor,
  guarantorOnboarding,
  getEmployeeGurantors,
  deleteGuarantor,
};
