import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  employeeStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../../utils/constants";
import Button from "../../../button";
import DataTable from "../../../datatable";
import {
  useGetBranchEmployees,
  useRemoveEmployeesFromBranch,
} from "../../../../redux/human-resources/hook/branches";
import AddEmployeeToBranch from "../../../modal/hr-section/branches/add-employee-to-branch";
import FundBranch from "../../../modal/hr-section/branches/fund-branch";

const BranchOverviewTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });
  const { id } = useParams();

  const { data: branch_employees } = useGetBranchEmployees(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [showAddEmployeeToBranch, setShowAddEmployeeToBranch] = useState(false);
  const [showFundBranch, setShowFundBranch] = useState(false);
  const [paymentType, setPaymentType] = useState(false);
  const { mutateAsync: removeFromBranch } = useRemoveEmployeesFromBranch();

  const tableConfig = {
    headers: ["Date Added", "Employee Name", "Email", "Mobile"],
    keys: ["created", "employeeName", "employee_email", "employee_mobile"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "employee_mobile",
      },
    },
  };

  const actionConfig = [
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = branch_employees.data.findIndex((item) => item.id == id);
        if (index != -1) {
          removeFromBranch({
            branchemployee_lists: branch_employees.data[index].id.toString(),
          });
        }
      },
    },
  ];

  const bulkActionConfig = [
    {
      label: "Remove",
      value: "active",
      action: (data) => {
        let newData = data.split(",");
        let result = [];

        newData.forEach((id) => {
          let index = branch_employees.data.findIndex((item) => item.id == id);
          if (index != -1) {
            result.push(branch_employees.data[index].id);
          }
        });
        removeFromBranch({ branchemployee_lists: result.join(",") });
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddEmployeeToBranch ? (
        <AddEmployeeToBranch
          isOpen={showAddEmployeeToBranch}
          branchData={id}
          closeModal={() => setShowAddEmployeeToBranch(false)}
        />
      ) : null}
      {showFundBranch ? (
        <FundBranch
          isOpen={showFundBranch}
          branch_id={id}
          payment_type={paymentType}
          closeModal={() => setShowFundBranch(false)}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Branch Employees List
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Debit Branch"
              type="button"
              theme="secondary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              onClick={() => {
                setPaymentType("debit");
                setShowFundBranch(true);
              }}
            />
          </div>
          <div className="md:w-fit w-[100%]">
            <Button
              text="Fund Branch"
              type="button"
              theme="secondary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              onClick={() => {
                setPaymentType("credit");
                setShowFundBranch(true);
              }}
            />
          </div>
          <div className="md:w-fit w-[100%]">
            <Button
              text="Add Employee to branch"
              type="submit"
              leftIcon={"add"}
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              onClick={() => setShowAddEmployeeToBranch(true)}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2" />
      <DataTable
        data={branch_employees}
        tableConfig={tableConfig}
        statusConfig={employeeStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        searchData={true}
        filterByStatus={true}
      />
    </div>
  );
};

export default BranchOverviewTab;
