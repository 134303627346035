import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { managers } from '../../../api/hr/manager';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useUpdateManagerSettings() {
    return useMutation(
      (payload) => {
        return managers.updateManagerSettings(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('team-settings');
          queryClient.invalidateQueries('department-settings');
          queryClient.invalidateQueries('branch-settings');
          store.dispatch(setAlert(true, 'success', 'Manager settings updated successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
  }