import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { pensionApis } from './../../../api/payroll/statutory-compliance/pension';
import { formatNumber } from '../../../utils/functions';

export function useGetPensionHistory(limit, page, statusFilter, search) {
  return useQuery(
    ['pension', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return pensionApis.getPension({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((pension) => {
          pension.created = moment(pension.created).format('MMMM DD, YYYY');
          pension.month = moment(pension.month).format('MMMM, YYYY');
          pension.status = parseFloat(pension.pension_status)
          if (pension.status === 2) {
            pension.status = 0
          }
          switch (pension.status) {
            case 0:
              pension.computedActions = [0, 2];
              break;
            case 1:
              pension.computedActions = [1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useUploadPension() {
  return useMutation(
    (payload) => {
      return pensionApis.bulkPension(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pension');
        store.dispatch(setAlert(true, 'success', 'Upload Successful'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRefPension(refId, limit, page, search) {
  return useQuery(
    ['pension-by-ref', { refId, limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return pensionApis.fetchPensionByRef({refId, limit, page, search});
    },
    {

      select: (data) => {
        data?.data?.forEach((item) => {
          item.employeeName = `${item.first_name} ${item.last_name}`;
          if (!item.status) {
            item.status = 0
          }
          switch (item.status) {
            case 2:
              item.computedActions = [0];
              break;
            default:
              break;
          }
          item.employeeName = `${item.firstName} ${item.lastName}`;

          item.total_pension = formatNumber((parseFloat(item.employeeContribution) + parseFloat(item.employerContribution)), 2);

          item.table_employeeContribution = formatNumber(parseFloat(item.employeeContribution).toFixed(2), 2);
          item.table_employerContribution = formatNumber(parseFloat(item.employerContribution).toFixed(2), 2);

          if (item.pfaName && item.rsaPin) {
            item.pfa_data = item.pfaName + ' | ' + item.rsaPin
          } else {
            item.pfa_data = 'Not provided'
          }
          

          item.total = formatNumber((
            parseFloat(item.fees) + parseFloat(item.employeeContribution) + parseFloat(item.employerContribution)
          ), 2);
          if (!item.status) {
            item.status = 0
          }
          item.table_fees = formatNumber(item.fees, 2)
          !item.fees
            ? item.fees = 0
            : item.fees = item.fees
        });
        if (data) {
          data.formatted_pension_remitted = formatNumber(parseFloat(data?.pension_remitted).toFixed(2), 2);
          data.formatted_pension_balance = formatNumber(parseFloat(data?.pension_balance).toFixed(2), 2);
          data.formatted_total_employee_pension = formatNumber(parseFloat(data?.employeeContribution).toFixed(2), 2);
          data.formatted_total_employer_pension = formatNumber(parseFloat(data?.employerContribution).toFixed(2), 2);
          data.formatted_total_trans_cost = formatNumber(parseFloat(data?.total_trans_cost).toFixed(2), 2);
          data.formatted_total_pension = formatNumber(parseFloat(data?.total_amount).toFixed(2), 2);
          data.formatted_total_payable = formatNumber(parseFloat(data?.total_payable).toFixed(2), 2);
        }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useExportPension() {
  return useMutation(
    (payload) => {
      store.dispatch(setAlert(true, 'success', 'Fetching Export Data...'));
      return pensionApis.fetchPensionByRef(payload);
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {
          item.employeeName = `${item.first_name} ${item.last_name}`;
          if (!item.status) {
            item.status = 0
          }
          switch (item.status) {
            case 2:
              item.computedActions = [0];
              break;
            default:
              break;
          }
          item.employeeName = `${item.firstName} ${item.lastName}`;

          item.total_pension = formatNumber((parseFloat(item.employeeContribution) + parseFloat(item.employerContribution)), 2);

          item.table_employeeContribution = formatNumber(parseFloat(item.employeeContribution).toFixed(2), 2);
          item.table_employerContribution = formatNumber(parseFloat(item.employerContribution).toFixed(2), 2);

          if (item.pfaName && item.rsaPin) {
            item.pfa_data = item.pfaName + ' | ' + item.rsaPin
          } else {
            item.pfa_data = 'Not provided'
          }
          

          item.total = formatNumber((
            parseFloat(item.fees) + parseFloat(item.employeeContribution) + parseFloat(item.employerContribution)
          ), 2);
          if (!item.status) {
            item.status = 0
          }
          item.table_fees = formatNumber(item.fees, 2)
          !item.fees
            ? item.fees = 0
            : item.fees = item.fees
        });
        if (data) {
          data.formatted_pension_remitted = formatNumber(parseFloat(data?.pension_remitted).toFixed(2), 2);
          data.formatted_pension_balance = formatNumber(parseFloat(data?.pension_balance).toFixed(2), 2);
          data.formatted_total_employee_pension = formatNumber(parseFloat(data?.employeeContribution).toFixed(2), 2);
          data.formatted_total_employer_pension = formatNumber(parseFloat(data?.employerContribution).toFixed(2), 2);
          data.formatted_total_trans_cost = formatNumber(parseFloat(data?.total_trans_cost).toFixed(2), 2);
          data.formatted_total_pension = formatNumber(parseFloat(data?.total_amount).toFixed(2), 2);
          data.formatted_total_payable = formatNumber(parseFloat(data?.total_payable).toFixed(2), 2);
        }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditPension() {
  return useMutation(
    (payload) => {
      return pensionApis.editPensionData(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pension-by-ref');
        store.dispatch(setAlert(true, 'success', ' Pension updated and successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDisbursePension() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return pensionApis.disbursePension(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('pension');
        store.dispatch(setAlert(true, 'success', 'Pension disbursed and successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRevertPension() {
  return useMutation(
    () => {
      store.dispatch(setIsLoading(true));
      return {
        status: 'success',
        message: 'No Pension With This Reference Number',
      };
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Pension fetched successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

