import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { store } from "../..";
import { queryClient } from "../../..";
import { vendors } from "../../../api/spend/vendor";
import { setAlert, setIsLoading } from "../../components/components-slice";
import { setVendor } from "./reducer";
import { formatNumber } from "../../../utils/functions";

export function useGetVendors(limit, page, statusFilter, search) {
  return useQuery(
    ["vendors", { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return vendors.getVendors({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((vendor) => {
          //compute action items
          vendor.created = moment(vendor.created).format("MMMM D, YYYY");
          switch (vendor.status) {
            case 0:
              vendor.computedActions = [0, 1, 2];
              break;
            case 1:
              vendor.computedActions = [0, 1, 2, 3, 4, 5];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useGetVendorPaySummary(limit, page, statusFilter, search) {
  return useQuery(
    ["vendor-pay-summary", { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return vendors.getVendorPaymentsSummary({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((vendor) => {
          vendor.created = moment(vendor.created).format("MMMM D, YYYY");
          vendor.type = vendor.type ? vendor.type : "-";
          vendor.schedule_date = vendor.schedule_date
            ? moment(vendor.schedule_date).format("MMMM D, YYYY")
            : "-";
          vendor.mode = vendor.mode ? vendor.mode : "-";
          vendor.status = parseInt(vendor.status);

          switch (vendor.status) {
            case 0:
              vendor.computedActions = [0, 2];
              break;
            case 1:
              vendor.computedActions = [1];
              break;
            case 2:
              vendor.computedActions = [3, 4];
              break;
            case 3:
              vendor.computedActions = [1];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useGetVendorsToPay(limit, page, statusFilter, search) {
  return useQuery(
    ["vendors-to-pay", { limit, page, statusFilter, search }],
    () => {
      return vendors.getVendors({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({
          value: "",
          label: "No Vendor Selected",
          bank: "Not Selected",
          account_number: "Not Selected",
          account_name: "Not Selected",
        });
        data?.data?.forEach((item) => {
          newData.push({
            value: item.id,
            label: item.vendor_name + " | " + item.mobile,
            vendor_name: item.vendor_name,
            bank: item.bank,
            account_number: item.account_number,
            account_name: item.account_name,
            contact_person: item.contact_person,
            email: item.email,
            mobile: item.mobile,
            address: item.street_address,
            state: item.state,
            city: item.city,
            country: item.country,
          });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useGetVendor(id) {
  return useQuery(
    ["vendor", { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return vendors.getVendor(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setVendor(response.data));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useUpdateVendor() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.updateVendor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendors");
        store.dispatch(
          setAlert(true, "success", "Vendor updated successfully")
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useGetVendorsForPayment() {
  return useMutation(
    () => {
      store.dispatch(setIsLoading(true));
      return vendors.getVendors(1000, 1, 1, "");
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(
          setAlert(true, "success", "Vendor fetched successfully")
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useRevertVendor() {
  return useMutation(
    () => {
      store.dispatch(setIsLoading(true));
      return {
        status: "success",
        message: "No Vendor Payment With This Reference Number",
      };
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useInviteSingleVendor() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.inviteSingleVendor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setVendor(response.data));
        queryClient.invalidateQueries("vendors");
        queryClient.invalidateQueries("vendors-to-pay");
        store.dispatch(
          setAlert(true, "success", "Vendor invited successfully")
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useInviteBulkVendors() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.inviteBulkVendor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendors");
        store.dispatch(
          setAlert(true, "success", "Vendors invited successfully")
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useCreateSingleVendor() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.createSingleVendor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setVendor(response.data));
        queryClient.invalidateQueries("vendors");
        queryClient.invalidateQueries("vendors-to-pay");
        store.dispatch(
          setAlert(true, "success", "Vendor created successfully")
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useUpdateInvitedVendor() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.updateInvitedVendor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setVendor(response.data));
        store.dispatch(
          setAlert(true, "success", "Vendor updated successfully")
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useCreateBulkVendors() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.createBulkVendor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendors");
        store.dispatch(
          setAlert(true, "success", "Vendors created successfully")
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useBulkActivateVendors() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.bulkActivateVendors(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendors");
        store.dispatch(
          setAlert(true, "success", "Vendor status change successfully")
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useActivateOrDeactivateVendor() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.activateOrDeactivateVendor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendors");
        store.dispatch(
          setAlert(true, "success", "Vendor status change successfully")
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useActivateOrDeactivatePendingVendor() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.activateOrDeactivatePendingVendor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendors");
        store.dispatch(
          setAlert(true, "success", "Vendor status change successfully")
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useBulkActivateOrDeactivateVendors() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.bulkActivateOrDeactivateVendors(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendors");
        store.dispatch(
          setAlert(true, "success", "Vendor status change successfully")
        );
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useGetVendorHistory(
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date
) {
  return useQuery(
    [
      "vendor-history",
      { limit, page, statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return vendors.getVendorHistory({
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((transaction) => {
          transaction.created = transaction.created
            .split(".")[0]
            .replace("T", " ");
          transaction.status = parseFloat(transaction.status);
          switch (transaction.status) {
            case 0:
              transaction.computedActions = [0];
              break;
            case 2:
              transaction.computedActions = [1, 2];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}
export function useGetPaymentHistoryByVendor(
  id,
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date
) {
  return useQuery(
    [
      "vendor-history-by-vendor",
      { id, limit, page, statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return vendors.getHistoryByVendor({
        id,
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((transaction) => {
          transaction.created = transaction.created
            .split(".")[0]
            .replace("T", " ");
          transaction.status = parseFloat(transaction.status);
          switch (transaction.status) {
            case 0:
              transaction.computedActions = [0];
              break;
            case 2:
              transaction.computedActions = [1];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useDisburseVendorPay() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.disburseVendorPayment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendor-history");
        store.dispatch(
          setAlert(true, "success", "Payment disbursed successfully")
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useInitiateVendorPay() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.initiateVendorPayment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendor-pay-summary");
        store.dispatch(
          setAlert(true, "success", "Vendor payment initiated successfully")
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useRunVendorPay() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.runVendorPayment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(
          setAlert(true, "success", "Vendor payment run successfully")
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useRunVendorPayExcel() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.runVendorPaymentExcel(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, "success", "Vendor run successfully"));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useRefVendorPay(refId, limit, page, statusFilter, search) {
  return useQuery(
    ["vendor-pay-by-ref", { refId, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return vendors.fetchVendorPayByRef({
        refId,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {
          item.computedActions = [0, 1, 2];
          item.trans_cost = parseFloat(item.trans_cost);
          item.trans_cost
            ? (item.formatted_trans_cost = parseFloat(item.trans_cost))
            : (item.formatted_trans_cost = 44.99);
          item.total = formatNumber(item.trans_cost + item.net_pay, 2);
          item.formatted_net_pay = formatNumber(item.net_pay, 2);
          if (!item.status) {
            item.status = 0;
          }
        });
        if (data) {
          data.formatted_total_net_pay = formatNumber(data?.total_net_pay, 2);
          data.formatted_total_trans_cost = formatNumber(
            data?.total_trans_cost,
            2
          );
          data.formatted_total_payable = formatNumber(data?.total_payable, 2);
        }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useRefVendorPayView(refId, limit, page, statusFilter, search) {
  return useQuery(
    ["vendor-pay-by-ref", { refId, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return vendors.fetchVendorPayByRef({
        refId,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        let total_net_pay = 0;
        let total_trans_cost = 0;
        data?.data?.forEach((item) => {
          if (item.processed === 1) {
            if (item.status === 0) {
              item.computedActions = [0, 1];
            } else if (item.status === 1) {
              item.computedActions = [2];
            }
          }
          item.net_pay = parseFloat(item.net_pay);
          total_net_pay += parseFloat(item.net_pay);
          item.trans_cost
            ? (item.trans_cost = parseFloat(item.trans_cost))
            : (item.trans_cost = 44.99);
          total_trans_cost += parseFloat(item.trans_cost);
          item.total = formatNumber(
            item.trans_cost + parseFloat(item.net_pay),
            2
          );
          item.formatted_net_pay = formatNumber(item.net_pay, 2);
          if (!item.status) {
            item.status = 0;
          }
        });
        if (data) {
          data.formatted_total_net_pay = formatNumber(total_net_pay, 2);
          data.formatted_total_trans_cost = formatNumber(total_trans_cost, 2);
          data.formatted_total_payable = formatNumber(
            total_net_pay + total_trans_cost,
            2
          );
        }

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useDeclineVendorPayment() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.declineVendorPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendor-pay-summary");
        store.dispatch(
          setAlert(true, "success", "Vendor Payment declined successfully")
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useDeleteVendorPayment(payload) {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.deleteVendorPayment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendor-pay-summary");
        store.dispatch(
          setAlert(true, "success", "Vendor batch payment deleted successfully")
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useRemoveVendorPaymentById() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return vendors.removeVendorPaymentById(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("vendor-pay-by-ref");
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useExportVendorTemplate() {
  return useMutation(
    () => {
      store.dispatch(setAlert(true, "success", "Fetching Export Data..."));
      return vendors.getVendorForPayment();
    },
    {
      onSuccess: (data) => {
        store.dispatch(
          setAlert(
            true,
            "success",
            "Vendor payment uploading template fetched successfully"
          )
        );
        return data;
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}
