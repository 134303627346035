import React from 'react';
import EmployeePage from '../../pages/employees';
import AddEmployeePay from '../../pages/employees/onboarding/add-single-employee-pay';
import AddEmployeeToGroup from '../../pages/employees/onboarding/add-employee-to-group';
import AddSalaryInfo from '../../pages/employees/onboarding/add-salary-info';
import AddCompleteSingleSalaryInfo from '../../pages/employees/onboarding/add-complete-single-salary-info';
import CreateBulkEmployee from '../../pages/employees/onboarding/create-bulk-employee';
import CreateSingleEmployee from '../../pages/employees/onboarding/create-single-employee';
import InviteBulkEmployee from '../../pages/employees/onboarding/invite-bulk-employee';
import InviteSingleEmployee from '../../pages/employees/onboarding/invite-single-employee';
import SelectOptions from '../../pages/employees/onboarding/select-options';
import AddBulkEmployeePay from '../../pages/employees/onboarding/add-bulk-employee-pay';
import ViewEmployeePage from '../../pages/employees/view-employee';
import ViewEmployeeGroupPage from '../../pages/employees/view-employee-group';
import CompleteProfileInformationPage from '../../pages/employees/employee-registration/complete-profile-infomation';
import CompleteSalaryInformationPage from '../../pages/employees/employee-registration/complete-salary-infomation';
import CompleteEmployeeInformationPage from '../../pages/employees/employee-registration/complete-employee-infomation';
import ProtectedRoute from '../protected';
import GuestRoute from '../guest';
import VerifySingleEmployeeInformationPage from '../../pages/employees/employee-registration/verify-and-submit-infomation';
import ReviewSalaryInfo from '../../pages/employees/salary-review/review-salary-info';
import ReviewSingleEmployeePay from '../../pages/employees/salary-review/review-single-employee-pay';
import ReviewBulkEmployeePay from '../../pages/employees/salary-review/review-bulk-employee-pay';
import SelectReviewOptions from '../../pages/employees/salary-review/select-review-option';
import ReviewSingleCompleteSalaryInfo from '../../pages/employees/salary-review/review-complete-single-salary-info';
import UploadProfilePicture from '../../pages/employees/employee-registration/upload-profile-picture';
import OnboardingVerification from '../../pages/employees/single-employee-onboarding/verification';
import OnboardingVerified from '../../pages/employees/single-employee-onboarding/onboarding';
import ExitVerification from '../../pages/employees/single-employee-exit/verification';
import ExitQuestions from '../../pages/employees/single-employee-exit/exit-questions';
import ExitVerified from '../../pages/employees/single-employee-exit/exit';
import InitiateEmployeeOnboarding from '../../pages/employees/onboarding/assign-onboarding';
import InvitationLinkPage from '../../pages/employees/onboarding/employee-invitation-link';


const AddingEmployeeRoute = [
  <ProtectedRoute
    exact
    path='/employees/onboarding/select-option'
    component={SelectOptions}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-single-employee/invite'
    component={InviteSingleEmployee}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-single-employee/create'
    component={CreateSingleEmployee}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-bulk-employee/invite'
    component={InviteBulkEmployee}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-single-employee/invitation-link'
    component={InvitationLinkPage}
  />,

  <ProtectedRoute
    exact
    path='/employees/onboarding/add-bulk-employee/create'
    component={CreateBulkEmployee}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-employee-to-group'
    component={AddEmployeeToGroup}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/assign-onboarding-workflow'
    component={InitiateEmployeeOnboarding}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-salary-information'
    component={AddSalaryInfo}
  />,

  <ProtectedRoute
    exact
    path='/employees/onboarding/complete-single-salary-information'
    component={AddCompleteSingleSalaryInfo}
  />,

  <ProtectedRoute
    exact
    path='/employees/onboarding/add-single-salary-information'
    component={AddEmployeePay}
  />,

  <ProtectedRoute
      exact
      path='/employees/onboarding/add-bulk-salary-information'
      component={AddBulkEmployeePay}
    />,
]

const SalaryReviewRoute = [
  <ProtectedRoute
    exact
    path='/employees/review/select-option'
    component={SelectReviewOptions}
  />,
  <ProtectedRoute
    exact
    path='/employees/review/review-salary-information'
    component={ReviewSalaryInfo}
  />,
  <ProtectedRoute
    exact
    path='/employees/review/review-single-salary-information'
    component={ReviewSingleEmployeePay}
  />,
  <ProtectedRoute
  exact
  path='/employees/review/complete-single-salary-information'
  component={ReviewSingleCompleteSalaryInfo}
/>,
<ProtectedRoute
    exact
    path='/employees/review/review-bulk-salary-information'
    component={ReviewBulkEmployeePay}
  />,
]

export const OnboardSingleEmployeeRoutes = ()=> [
  <GuestRoute
    exact
    path='/employees/single-employee-onboarding/:token'
    component={CompleteProfileInformationPage}
  />,
  <GuestRoute
    exact
    path='/employees/single-employee-onboarding/employees/:token'
    component={CompleteEmployeeInformationPage}
  />,
  <GuestRoute
    exact
    path='/employees/single-employee-onboarding/salary/:token'
    component={CompleteSalaryInformationPage}
  />,
  <GuestRoute
    exact
    path='/employees/single-employee-onboarding/upload-picture/:token'
    component={UploadProfilePicture}
  />,
  <GuestRoute
    exact
    path='/employees/single-employee-onboarding/verify/:token'
    component={VerifySingleEmployeeInformationPage}
  />
];

export const SingleOnboardingRoutes = ()=> [
  <GuestRoute
    exact
    path='/employees/onboarding/verification/:token'
    component={OnboardingVerification}
  />,
  <GuestRoute
    exact
    path='/employees/onboarding/verified/:token'
    component={OnboardingVerified}
  />
];

export const SingleExitRoutes = ()=> [
  <GuestRoute
    exact
    path='/employees/exit/verification/:token'
    component={ExitVerification}
  />,
  <GuestRoute
    exact
    path='/employees/exit/verified/questions/:token'
    component={ExitQuestions}
  />,
  <GuestRoute
    exact
    path='/employees/exit/verified/stages/:token'
    component={ExitVerified}
  />,
  
];

export const EmployeeRoute = [
  ...AddingEmployeeRoute,
  ...SalaryReviewRoute,
  <ProtectedRoute exact path='/employees' component={EmployeePage} />,
  <ProtectedRoute exact path='/salaries' component={EmployeePage} />,
  <ProtectedRoute exact path='/payroll-groups' component={EmployeePage} />,
  <ProtectedRoute exact path='/bonuses' component={EmployeePage} />,
  <ProtectedRoute exact path='/overtime' component={EmployeePage} />,
  <ProtectedRoute exact path='/advances-loans' component={EmployeePage} />,
  <ProtectedRoute exact path='/other-deductions' component={EmployeePage} />,
  <ProtectedRoute exact path='/reimbursements' component={EmployeePage} />,
  <ProtectedRoute exact path='/suspensions' component={EmployeePage} />,
  <ProtectedRoute exact path='/employees/view/:id' component={ViewEmployeePage} />,
  <ProtectedRoute
    exact
    path='/employees/group/view/:id'
    component={ViewEmployeeGroupPage}
  />,
  
]
