import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../../components/datatable';
import { PAGINATION_DEFAULT, queryStatusConfig } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { useGetCabinetQueries } from '../../redux/employee-folder/hook';

const QueryFolder = ({employeeId}) => {

  const { user } = useSelector((state) => state.userSlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: queries } = useGetCabinetQueries(
    employeeId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );


  const tableConfig = {
    headers: [
        'Date Added',
        'Employee Name',
        'Query',
        'Status'
    ],
    keys: [
        'created',
        'employeeName',
        'query',
        'status'
    ],
    mobileHeader: {
      left: {
          title: 'Date Added',
          key: 'created',
      },
      right: {
          title: 'Employee Name',
          key: 'employeeName',
      },
        
    },
  };

  return (
    <div className='flex flex-col'>
        <div className='mt-9'>
            <div className='flex md:flex-row gap-4 flex-wrap-reverse'>
                <div className='w-[100%]'>
                    <DataTable
                    data={queries}
                    tableConfig={tableConfig}
                    statusConfig={queryStatusConfig}
                    noCheck={true}
                    updatePagination={(data) => setPagination(data)}
                    />
                </div>
            </div>
        </div>
    </div>
  );
};

export default QueryFolder;
