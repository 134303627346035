import classNames from "classnames";
import React, { Suspense, lazy } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import TableActionIconSVG from "../../assets/svg/table-action-icon.svg";

import LoadingBar from "../loader-bar";

const EmailCard = lazy(() => import("./table-cards/email"));
const TicketCard = lazy(() => import("./table-cards/ticket"));
const LeaveCard = lazy(() => import("./table-cards/leave"));
const RecruitmentCard = lazy(() => import("./table-cards/recruitment"));
const PromotionCard = lazy(() => import("./table-cards/promotion"));
const EmployeeCard = lazy(() => import("./table-cards/employee"));
const TrainingCard = lazy(() => import("./table-cards/training"));
const TrainerCard = lazy(() => import("./table-cards/trainer"));
const TrainingCourseTableCard = lazy(() =>
  import("./table-cards/training-course")
);
const PerformanceCard = lazy(() =>
  import("./table-cards/performance-evaluation")
);
const EmployeeOnboardingCard = lazy(() =>
  import("./table-cards/employee-onboarding")
);
const EmployeeExitCard = lazy(() => import("./table-cards/employee-exit"));
const TeamCard = lazy(() => import("./table-cards/team"));
const DepartmentCard = lazy(() => import("./table-cards/department"));
const BranchCard = lazy(() => import("./table-cards/branch"));
const LeaveTypeCard = lazy(() => import("./table-cards/leave-type"));
const WorkflowCard = lazy(() => import("./table-cards/workflow"));
const SalaryCard = lazy(() => import("./table-cards/salary"));

const TableCard = ({ data, type, computedActions, actionConfig }) => {
  const ActionItem = ({ item, id, sentData }) => {
    return (
      <div
        className="py-2 hover:bg-secondary-2-extralight px-3 pb-1 cursor-pointer"
        onClick={() => {
          item.action(id);
        }}
      >
        <span
          className={classNames(
            {
              "text-color-green-medium": item.color === "green",
            },
            {
              "text-color-orange": item.color === "orange",
            },
            {
              "text-color-error": item.color === "red",
            }
          )}
        >
          {item.name}
        </span>
      </div>
    );
  };

  const Action = ({ data }) => {
    return (
      <>
        {data && computedActions ? (
          <div className="flex justify-end">
            <div className="!max-w-[45px]">
              <UncontrolledDropdown direction="right">
                <DropdownToggle className="bg-transparent border-0 w-full">
                  <img
                    src={TableActionIconSVG}
                    alt=""
                    className="!min-w-[35px]"
                  />
                </DropdownToggle>

                <DropdownMenu
                  container="body"
                  className="!w-[150px] !p-0 !text-[12px]"
                  style={{ zIndex: 1090 }}
                  width="50px"
                >
                  <div className="flex flex-col">
                    {actionConfig?.map((item, index) => (
                      <React.Fragment key={index}>
                        {computedActions ? (
                          <>
                            {data.computedActions ? (
                              <>
                                {data.computedActions.includes(index) ? (
                                  <ActionItem item={item} id={data.id} />
                                ) : null}
                              </>
                            ) : null}
                          </>
                        ) : (
                          <ActionItem item={item} id={data.id} />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        ) : null}
      </>
    );
  };
  return (
    <div
      className={classNames(
        "flex flex-row flex-wrap-reverse justify-between rounded-md hover:bg-green-50 p-3 w-full hover:shadow-md border duration-500 hover:scale-105 mt-1"
      )}
    >
      {type === "email" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <EmailCard data={data} Action={Action} />
        </Suspense>
      ) : type === "ticket" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <TicketCard data={data} Action={Action} />
        </Suspense>
      ) : type === "recruitment" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <RecruitmentCard data={data} Action={Action} />
        </Suspense>
      ) : type === "promotion" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <PromotionCard data={data} Action={Action} />
        </Suspense>
      ) : type === "training" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <TrainingCard data={data} Action={Action} />
        </Suspense>
      ) : type === "trainer" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <TrainerCard data={data} Action={Action} />
        </Suspense>
      ) : type === "training-courses" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <TrainingCourseTableCard data={data} Action={Action} />
        </Suspense>
      ) : type === "performance" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <PerformanceCard data={data} Action={Action} />
        </Suspense>
      ) : type === "leave" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <LeaveCard data={data} Action={Action} />
        </Suspense>
      ) : type === "employee-onboarding" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <EmployeeOnboardingCard data={data} Action={Action} />
        </Suspense>
      ) : type === "team" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <TeamCard data={data} Action={Action} />
        </Suspense>
      ) : type === "department" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <DepartmentCard data={data} Action={Action} />
        </Suspense>
      ) : type === "branch" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <BranchCard data={data} Action={Action} />
        </Suspense>
      ) : type === "employee-exit" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <EmployeeExitCard data={data} Action={Action} />
        </Suspense>
      ) : type === "leave-type" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <LeaveTypeCard data={data} Action={Action} />
        </Suspense>
      ) : type === "workflow" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <WorkflowCard data={data} Action={Action} />
        </Suspense>
      ) : type === "employee" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <EmployeeCard data={data} Action={Action} />
        </Suspense>
      ) : type === "salary" ? (
        <Suspense fallback={<LoadingBar loading={true} />}>
          <SalaryCard data={data} Action={Action} />
        </Suspense>
      ) : type === "exit-questions" ? (
        <div className=" flex flex-col justify-between !min-w-full gap-2 !min-h-[120px]">
          <div className="flex flex-row items-center justify-between w-full -mr-8">
            <div>
              <img src={QuestionSVG} alt="email_icon" />
            </div>
            <Action data={data} />
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex gap-1 bg-highlight p-2 w-full items-center rounded-md">
              <span className="text-[12px] font-extrabold text-left">
                Question:
              </span>
              <div className="text-[12px] font-normal text-left">
                {data?.question}{" "}
              </div>
            </div>
          </div>
          <hr className="divider mb-0 mt-1" />
          <div className="flex flex-row justify-between">
            <div className="flex flex-col w-1/2">
              <span className="text-[13px] font-extrabold text-color-gray">
                Question Type
              </span>
              <span className="text-[12px]">
                {data?.question_type == "multiple_choice"
                  ? "Multiple Choice"
                  : "Open Ended"}
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TableCard;
