import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  useRunBasicPayroll,
  useRunBasicPayrollGroup,
  useRunBasicPayrollSelective,
} from '../../../redux/payroll/hook';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import { useHistory } from 'react-router-dom';
import Modal from '../modal';
import FormTextArea from '../../form-textarea';
import moment from 'moment';
import WarningSVG from '../../../assets/svg/warning.svg';

function RunBasicPayroll({
  isOpen,
  closeModal,
  source = 'default',
  sourceData,
  refresh,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const history = useHistory();
  const { mutateAsync: runBasicPayroll, isLoading: payrollLoading } =
    useRunBasicPayroll();
  const { mutateAsync: runBasicPayrollGroup, isLoading: payrollGroupLoading } =
    useRunBasicPayrollGroup();
  const {
    mutateAsync: runBasicPayrollSelective,
    isLoading: payrollSelectiveLoading,
  } = useRunBasicPayrollSelective();

  function onBasicPayrollSuccess(ref) {
    history.push(`/payroll/basic/preview/${ref}`);
    closeModal();
  }

  const submitForm = async (data) => {
    const desc = DOMPurify.sanitize(data?.desc);
    const month = DOMPurify.sanitize(data?.month);

    let payload = {
      desc,
      month,
    };

    if (source === 'default') {
      await runBasicPayroll(payload).then((response) => {
        onBasicPayrollSuccess(response.data.ref);
      });
    } else if (source === 'group') {
      payload.group_id = sourceData;
      await runBasicPayrollGroup(payload).then((response) => {
        onBasicPayrollSuccess(response.data.ref);
      });
    } else {
      payload.employee_ids = sourceData;
      await runBasicPayrollSelective(payload).then((response) => {
        onBasicPayrollSuccess(response.data.ref);
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none !min-h-screen'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-3' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-3'>Make Employee Payment</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <FormInput
              label='Select Date'
              name='month'
              type='date'
              defaultValue={moment().format('YYYY-MM-DD')}
              inputRef={register(formValidation('text', true))}
              readOnly={
                payrollLoading || payrollGroupLoading || payrollSelectiveLoading
              }
              error={errors.month}
              errorMessage={errors.month && errors.desc.month}
            />
            <FormTextArea
              label='Description'
              placeholder={'Enter payment description'}
              name='desc'
              type='text'
              inputRef={register(formValidation('text', true))}
              readOnly={
                payrollLoading || payrollGroupLoading || payrollSelectiveLoading
              }
              error={errors.desc}
              errorMessage={errors.desc && errors.desc.message}
            />
            <div className='flex flex-col px-4 border-secondary-4 border-[1.2px] rounded-lg p-3 mt-2 bg-color-warning-light w-[100%]'>
              <div className='flex'>
                <div className='w-[100px] pr-2 flex flex-row items-center '>
                  <img src={WarningSVG} alt='' srcSet='' />
                </div>
                <div className='text-[12px] text-justify'>
                  <span className='font-normal'><span className='font-extrabold'>Please note: </span>This method only makes payment to employees based on the net pay provided (can be edited on the next page). This does not cover payroll computation for emaployees and payslips cannot be generated paying employees using this method of payment. To compute payroll and genrate payslip switch to the <span className='font-extrabold'>Payroll Summary</span> tab and click <span className='font-extrabold'>"RUN PAYROLL"</span> button.</span>
                </div>
              </div>
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text='CONTINUE'
                type='submit'
                disabled={!isValid}
                loading={
                  payrollLoading ||
                  payrollGroupLoading ||
                  payrollSelectiveLoading
                }
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default RunBasicPayroll;
