import { Axios } from '../axios';

const getHMOGroups = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/hmo-groups?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const getHMOGroup = async (payload) => {
  const { data } = await Axios.get('/hmo-groups/' + payload.id, payload);
  return data?.data;
};

const getHMOGroupEmployees = async (payload) => {
  const { data } = await Axios.get('/hmo-groups/' + payload.id);
  return data?.data;
};

const getHMOGroupSettings = async (payload) => {
  const { data } = await Axios.get('/hmo-groups/' + payload.id);
  return data?.data;
};

const addHMOGroup = async (payload) => {
  const { data } = await Axios.post('/hmo-groups', payload);
  return data;
};

const editHMOGroup = async (payload) => {
  const { data } = await Axios.put('/hmo-groups/'+payload.id, payload.body);
  return data;
};

const addEmployeesToHMOGroup = async (payload) => {
  const { data } = await Axios.post('/hmo-groups/add-employee', payload);
  return data;
};

const removeEmployeesFromHMOGroup = async (payload) => {
  const { data } = await Axios.post(
    '/hmo-groups/remove-employee',
    payload,
  );
  return data;
};

const moveEmployeesToHMOGroup = async (payload) => {
  const { data } = await Axios.post(
    '/hmo-groups/move-employee/'+ payload.id,
    payload,
  );
  return data;
};

const deleteHMOGroup = async (payload) => {
  const { data } = await Axios.delete('/hmo-groups/'+payload);
  return data;
};

export const hmoGroups = {
    getHMOGroups,
    getHMOGroup,
    getHMOGroupEmployees,
    getHMOGroupSettings,
    addHMOGroup,
    editHMOGroup,
    addEmployeesToHMOGroup,
    removeEmployeesFromHMOGroup,
    moveEmployeesToHMOGroup,
    deleteHMOGroup
};