import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'employee',
  initialState: {
    employee: null,
    employeeOnboarding: null,
    employeeExit: null,
  },
  reducers: {
    setEmployee: (state, { payload }) => {
      state.employee = payload;
    },
    setEmployeeOnboarding: (state, { payload }) => {
      state.employeeOnboarding = payload;
    },
    setEmployeeExit: (state, { payload }) => {
      state.employeeExit = payload;
    },
  },
});

// Actions
export const { setEmployee, setEmployeeOnboarding, setEmployeeExit } = slice.actions;

export default slice.reducer;
