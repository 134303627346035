import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import {
  PAGINATION_DEFAULT,
  OtherDeductionStatusConfig,
} from "../../utils/constants";
import Button from "../button";
import DoYouWantToModal from "../modal/do-you-want-to";
import HelpSVG from "../../assets/svg/help.svg";
import InfoModal from "../modal/info-modal";
import {
  useDeleteOtherDeductions,
  useGetemployeeOtherDeductions,
} from "../../redux/employees/hook/other-salary-deductions";
import AddOtherDeductionModal from "../modal/employees/add-other-deduction";
import SelectOtherDeductionOption from "../modal/employees/select-other-deduction-option";
import { useGetEmployees } from "../../redux/employees/hook";

const EmployeeOtherDeductionsTab = () => {
  useEffect(() => {}, []);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { refetch, data: employeeOtherDeductions } =
    useGetemployeeOtherDeductions(
      pagination?.limit,
      pagination?.page,
      pagination?.statusFilter,
      pagination?.search
    );

  const [showInfoModal, setShowInfoModal] = useState(false);

  const { mutateAsync: deleteOtherDeduction, isLoading: deleteLoading } =
    useDeleteOtherDeductions();

  const [showSelectOtherDeductionOptions, setShowSelectOtherDeductionOptions] =
    useState(false);
  const [overtimeData, setOvertimeData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState("Add Salary Advance");
  const [showAddOtherDeduction, setShowAddOtherDeduction] = useState(false);

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const tableConfig = {
    headers: [
      "Employee Name",
      "Phone number",
      "Purpose",
      "Month",
      "Amount",
      "Status",
    ],
    keys: [
      "employeeName",
      "phone",
      "purpose",
      "formatted_month",
      "formatted_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };

  const deleteSingleOvertime = async (id) => {
    let index = employeeOtherDeductions.data.findIndex(
      (item) => item.id === id
    );
    await deleteOtherDeduction(employeeOtherDeductions.data[index].id).then(
      () => {
        setShowDoYouWant(false);
      }
    );
  };

  const actionConfig = [
    {
      name: "Edit/View",
      color: "green",
      action: (id) => {
        let index = employeeOtherDeductions.data.findIndex(
          (item) => item.id === id
        );
        setButtonText("Update");
        setTitle("Update Deduction");
        setOvertimeData(employeeOtherDeductions.data[index]);
        setShowAddOtherDeduction(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = employeeOtherDeductions.data.findIndex(
          (item) => item.id === id
        );
        setDeleteId(employeeOtherDeductions.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-end w-full mt-4">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={HelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      <div className="flex flex-col bg-white rounded mt-2 border">
        {showDoYouWant ? (
          <DoYouWantToModal
            isOpen={showDoYouWant}
            closeModal={() => setShowDoYouWant(false)}
            id={deleteId}
            loading={deleteLoading}
            title={"Are you sure you want to delete this deduction?"}
            buttonText={"DELETE"}
            btnFunction={deleteSingleOvertime}
          />
        ) : null}
        {showAddOtherDeduction ? (
          <AddOtherDeductionModal
            isOpen={showAddOtherDeduction}
            closeModal={() => setShowAddOtherDeduction(false)}
            otherData={overtimeData}
            title={title}
            buttonText={buttonText}
          />
        ) : null}
        {showSelectOtherDeductionOptions ? (
          <SelectOtherDeductionOption
            isOpen={showSelectOtherDeductionOptions}
            closeModal={() => setShowSelectOtherDeductionOptions(false)}
          />
        ) : null}
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"salary-advance"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Other Salary Deductions
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Deduction"
                type="submit"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                disabled={!employees}
                leftIcon={"add"}
                onClick={() => {
                  setShowSelectOtherDeductionOptions(true);
                }}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="Refetch"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 "
                textClass={"!text-[11px]"}
                leftIcon={"change"}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={employeeOtherDeductions}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={OtherDeductionStatusConfig}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default EmployeeOtherDeductionsTab;
