import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormSelectEmployee from "../../../components/employees/form-select-employee";
import FormInput from "../../../components/form-input";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import { useRouteQuery } from "../../../hooks/useRouteQuery";
import { useAddSalary } from "../../../redux/employees/hook/salaries";
import { useGetSalarySettings } from "../../../redux/company/hook";
import FormSelect from "../../../components/form-select";
import { formatNumber } from "../../../utils/functions";

function AddSingleSalaryInfo() {
  useEffect(() => {}, []);
  const history = useHistory();
  const [fromSalary, setFromSalary] = useState("");
  const [update, setUpdate] = useState(false);
  const query = useRouteQuery();

  const { mutateAsync: addSalary, isLoading: loading } = useAddSalary();

  const [additionalAllowance, setAdditionalAllowance] = useState([]);
  const { data: salarySettings, isLoading } = useGetSalarySettings();

  useEffect(() => {
    if (salarySettings) {
      if (salarySettings?.additional_allowances) {
        let data = [];
        JSON.parse(salarySettings?.additional_allowances).forEach((element) => {
          element.amount = 0;
          data.push(element);
        });
        setAdditionalAllowance(data);
      }
    }
  }, [salarySettings]);

  const [currency, setCurrency] = useState("naira");
  const currencyOptions = [
    {
      label: "Naira",
      value: "naira",
    },
  ];

  const [payrollCountry, setPayrollCountry] = useState("Nigeria");
  const payrollCountryOptions = [
    {
      label: "Nigeria",
      value: "Nigeria",
    },
  ];

  const [employeeId, setEmployeeId] = useState(null);
  const [breakDown, setBreakDown] = useState({
    basic_salary: 0,
    housing_allowance: 0,
    transportation_allowance: 0,
    lunch_allowance: 0,
    benefit_in_kind: 0,
    airtime_allowance: 0,
    data_allowance: 0,
    leave_allowance: 0,
    utility_allowance: 0,
    other_allowance: 0,
    net_pay: 0,
  });
  const { employee } = useSelector((state) => state.employeeSlice);

  useEffect(() => {
    if (query) {
      setFromSalary(query.get("salary"));
      setUpdate(query.get("update"));
    }
  }, [query]);

  useEffect(() => {
    if (employee) {
      setEmployeeId(employee.id);
    }
  }, [employee]);

  const submitSalary = async () => {
    breakDown.payroll_country = payrollCountry;
    breakDown.payroll_currency = currency;
    let payload = {
      ...breakDown,
      ...{ employee: employeeId },
      ...{ additional_allowances: JSON.stringify(additionalAllowance) },
    };
    await addSalary(payload).then(() => {
      history.push("/employees");
    });
  };

  return (
    <EmployeeOnboardingLayout
      step={fromSalary ? 2 : 4}
      title={update ? "Update Compensation" : "Add Compensation"}
    >
      <div className="flex flex-col w-full  h-full">
        <span className="header-4">Add complete salary information</span>
        <hr className="divider mb-2" />

        <div className=" flex flex-col">
          {fromSalary ? (
            <div className="border bg-secondary-2-extralight p-2 rounded-md -pb-2">
              <FormSelectEmployee
                label={"Employee"}
                name="employee"
                placeholder="Type to select Employee"
                setEmployee={setEmployeeId}
                //  readOnly={loading}
              />
            </div>
          ) : (
            <div className="flex flex-col border bg-secondary-2-extralight p-2 rounded-md">
              <span className="text-[15px] font-extrabold text-color-gray">
                Employee name
              </span>
              <span className="text-[13px] text-color-black-2 font-semibold">
                {employee?.first_name} {employee?.last_name}
              </span>
            </div>
          )}
        </div>
        <hr className="divider mb-2 mt-2" />

        <div className={"flex flex-col "}>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-1/2 md:pr-2">
              <FormInput
                label="Basic Salary"
                name="basic"
                type="number"
                value={breakDown.basic_salary}
                onInput={(e) => {
                  let newArray = JSON.parse(JSON.stringify(breakDown));
                  newArray.basic_salary = parseFloat(e.target.value);
                  setBreakDown(newArray);
                }}
              />
            </div>

            <div className="w-full md:w-1/2 md:pl-2">
              <FormInput
                label="Housing Allowance"
                name="housing"
                type="number"
                value={breakDown.housing_allowance}
                onInput={(e) => {
                  let newArray = JSON.parse(JSON.stringify(breakDown));
                  newArray.housing_allowance = parseFloat(e.target.value);
                  setBreakDown(newArray);
                }}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-1/2 md:pr-2">
              <FormInput
                label="Transport Allowance"
                name="transport"
                type="text"
                value={breakDown.transportation_allowance}
                onInput={(e) => {
                  let newArray = JSON.parse(JSON.stringify(breakDown));
                  newArray.transportation_allowance = parseFloat(
                    e.target.value
                  );
                  setBreakDown(newArray);
                }}
              />
            </div>

            <div className="w-full md:w-1/2 md:pl-2">
              <FormInput
                label="Leave Allowance"
                name="leave"
                type="text"
                value={breakDown.leave_allowance}
                onInput={(e) => {
                  let newArray = JSON.parse(JSON.stringify(breakDown));
                  newArray.leave_allowance = parseFloat(e.target.value);
                  setBreakDown(newArray);
                }}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-1/2 md:pr-2">
              <FormInput
                label="Lunch Allowance"
                name="lunch"
                type="text"
                value={formatNumber(breakDown.lunch_allowance, 2)}
                onInput={(e) => {
                  let newArray = JSON.parse(JSON.stringify(breakDown));
                  newArray.lunch_allowance = parseFloat(e.target.value);
                  setBreakDown(newArray);
                }}
              />
            </div>

            <div className="w-full md:w-1/2 md:pl-2">
              <FormInput
                label="Utility Allowance"
                name="utility"
                type="number"
                value={breakDown.utility_allowance}
                onInput={(e) => {
                  let newArray = JSON.parse(JSON.stringify(breakDown));
                  newArray.utility_allowance = parseFloat(e.target.value);
                  setBreakDown(newArray);
                }}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-1/2 md:pr-2">
              <FormInput
                label="Benefit In Kind"
                name="benefit_in_kind"
                type="number"
                value={breakDown.benefit_in_kind}
                onInput={(e) => {
                  let newArray = JSON.parse(JSON.stringify(breakDown));
                  newArray.benefit_in_kind = parseFloat(e.target.value);
                  setBreakDown(newArray);
                }}
              />
            </div>

            <div className="w-full md:w-1/2 md:pl-2">
              <FormInput
                label="Other Allowance"
                name="other"
                type="number"
                value={breakDown.other_allowance}
                onInput={(e) => {
                  let newArray = JSON.parse(JSON.stringify(breakDown));
                  newArray.other_allowance = parseFloat(e.target.value);
                  setBreakDown(newArray);
                }}
              />
            </div>
          </div>
          {additionalAllowance.length > 0 ? (
            <>
              <hr className="divider mt-2 mb-2" />
              <div className="text-[15px] font-extrabold mb-2">
                Additional Allowances
              </div>
              <div className="grid md:grid-cols-3 grid-cols-2 gap-2 w-full">
                {additionalAllowance?.map((item, index) => (
                  <FormInput
                    label={item.title}
                    name={item.name}
                    type="text"
                    value={item.amount}
                    onInput={(e) => {
                      let newArray = JSON.parse(
                        JSON.stringify(additionalAllowance)
                      );
                      newArray[index].amount = parseFloat(e.target.value);
                      setAdditionalAllowance(newArray);
                    }}
                    readOnly={loading}
                  />
                ))}
              </div>
              <hr className="divider mt-2" />
            </>
          ) : null}
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-full">
              <FormInput
                label="Net Pay (Fixed Take Home)"
                name="net_pay"
                type="number"
                value={formatNumber(breakDown.net_pay, 2)}
                onInput={(e) => {
                  let newArray = JSON.parse(JSON.stringify(breakDown));
                  newArray.net_pay = parseFloat(e.target.value);
                  setBreakDown(newArray);
                }}
              />
            </div>
          </div>
          <hr className="divider mt-2 mb-2" />
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-1/2 md:pr-2">
              <FormSelect
                value={payrollCountry}
                readOnly={true}
                options={payrollCountryOptions}
                onChange={(selected) => {
                  setPayrollCountry(selected);
                }}
                label="Payroll Country"
              />
            </div>
            <div className="w-full md:w-1/2 md:pl-2">
              <FormSelect
                value={currency}
                readOnly={true}
                options={currencyOptions}
                onChange={(selected) => {
                  setCurrency(selected);
                }}
                label="Payroll Currency"
              />
            </div>
          </div>
          <div className="w-full mt-[10px]">
            <Button
              text="Add Salary"
              type="submit"
              onClick={() => submitSalary()}
              loading={loading}
            />

            <button
              className="flex justify-center w-full cursor-pointer hover:underline hover:scale-110 duration-300 mt-2"
              onClick={() => history.goBack()}
            >
              <span className="p2-medium text-color-black mt-3">Go Back</span>
            </button>
          </div>
        </div>
      </div>
    </EmployeeOnboardingLayout>
  );
}

export default AddSingleSalaryInfo;
