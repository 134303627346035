import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormSelect from "../../../components/form-select";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import { useSelector } from "react-redux";
import AddSmallSVG from "../../../assets/svg/add-icon-small.svg";
import {
  useAddEmployeeOnboarding,
  useGetOnboardingWorkflow,
  useGetSelectOnboardingWorkflows,
} from "../../../redux/employees/hook/onboarding";
import AddOnboardingWorkflow from "../../../components/modal/hr-section/employee-onboarding/add-workflow";
import { useForm } from "react-hook-form";
import FormSwitch from "../../../components/form-switch";
import FormInput from "../../../components/form-input";

function InitiateEmployeeOnboarding() {
  const {
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {}, []);

  const [workflowId, setWorkflowId] = useState(null);
  const [requestGuarantor, setRequestGuarantor] = useState(true);
  const { data: workflows, isLoading: workflowLoading } =
    useGetSelectOnboardingWorkflows(1000, 1, "");

  const { data: workflow_info } = useGetOnboardingWorkflow(workflowId);

  const { mutateAsync: addOnboarding, isLoading: addOnboardingloading } =
    useAddEmployeeOnboarding();
  const [showAddWorkFlow, setShowAddWorkflow] = useState(false);

  const submitForm = async () => {
    let payload = {
      workflow_id: workflowId,
      employee_id: employeeId,
      request_guarantor: requestGuarantor,
    };
    addOnboarding(payload).then((result) => {
      if (result.status === "success") {
        history.push("/employees/onboarding/add-employee-to-group");
      }
    });
  };

  const history = useHistory();
  const { employee } = useSelector((state) => state.employeeSlice);
  const [employeeId, setEmployeeId] = useState();

  useEffect(() => {
    if (employee) {
      setEmployeeId(employee.id);
    }
  }, [employee]);

  const skipToAddPayrollGroup = () => {
    history.push("/employees/onboarding/add-employee-to-group");
  };

  return (
    <EmployeeOnboardingLayout step={3} title={"Assign Onboarding Workflow"}>
      {(!workflows && !workflowLoading) || showAddWorkFlow ? (
        <AddOnboardingWorkflow
          title={"Add Onboarding Workflow"}
          isOpen={showAddWorkFlow}
          buttonText="Save and Continue"
          closeModal={() => setShowAddWorkflow(false)}
        />
      ) : null}
      <div className="flex flex-col w-full h-fit">
        <div className="flex flex-col">
          <span className="hidden md:flex text-[20px] font-extrabold md:mt-2">
            Assign Onboarding workflow
          </span>
          <hr className="divider mb-2 md:mt-2 -mt-12" />
          <div
            className="flex justify-end w-full cursor-pointer"
            onClick={() => skipToAddPayrollGroup()}
          >
            <span className="text-[14px] font-semibold text-black cursor-pointer hover:underline hover:scale-110 duration-300 mb-2">
              Skip this step
            </span>
          </div>

          <div className="flex flex-col border bg-secondary-2-extralight p-2 rounded-md">
            <span className="text-[15px] font-extrabold text-color-gray">
              Employee name
            </span>
            <span className="text-[14px] font-semibold text-color-black-2">
              {employee?.first_name} {employee?.last_name}
            </span>
          </div>
          <hr className="divider mt-2" />
          <div className="flex flex-row  justify-between items-center md:w-[100%] w-full">
            <div className="w-full md:w-[60%] text-[15px]">
              <FormSwitch
                label={"Request Guarantor?"}
                name={"switch-edit-mode"}
                checked={requestGuarantor == 1 ? true : false}
                onClick={() => {
                  setRequestGuarantor(requestGuarantor == 1 ? 0 : 1);
                }}
              />
            </div>
          </div>
          <hr className="divider -mt-2" />
          <>
            <div className="flex flex-col md:flex-col w-full justify-center items-center">
              {workflows ? (
                <div className="w-full">
                  <FormSelect
                    value={workflowId}
                    options={workflows}
                    onChange={(selected) => {
                      setWorkflowId(selected);
                    }}
                    label="Onboarding Workflows"
                  />
                </div>
              ) : (
                <div className="p4-medium text-[15px] mt-2 text-gray-500 font-semibold">
                  No Onboarding workflow found. Click link below to add
                  workflow.
                  <hr className="divider" />
                </div>
              )}
              <div className="flex gap-2 items-center justify-start w-full mt-2">
                <img
                  src={AddSmallSVG}
                  alt={"add_workflow"}
                  className={"h-[15px] w-[15px]"}
                />
                <span
                  className="p2-medium text-[#0d5225] cursor-pointer hover:underline duration-75 text-[14px] italic"
                  onClick={() => setShowAddWorkflow(true)}
                >
                  Add New Workflow
                </span>
              </div>
            </div>
            <hr className="divider" />
            {workflowId && workflow_info ? (
              <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full border-2 p-2 rounded mt-2 !max-h-[400px] overflow-auto ">
                <div className="flex flex-col">
                  {workflow_info?.sub_workflows.map((item, index) => (
                    <div className="flex flex-col max-h-fit p-2">
                      <div className="flex flex-col rounded-[5px border border-gray-300 p-4 pt-2 pb-2  bg-gray-50 rounded">
                        <span className="header-4 ">Step {index + 1}</span>
                        <div className="w-full">
                          <div className="flex flex-col justify-around flex-wrap">
                            <FormInput
                              name={"stage_name"}
                              placeholder={"Stage Name"}
                              type="text"
                              readOnly={true}
                              value={item.name}
                              className="!h-[30px] mr-4 text-[13px] w-[60%] border-1 border-gray-300"
                            />
                            <FormInput
                              name={"stage_description"}
                              placeholder={"Stage Description"}
                              type="text"
                              readOnly={true}
                              value={item.description}
                              className="!h-[30px] mr-4 text-[13px] border-1 border-gray-300"
                            />
                            <hr className="divider mt-2 " />
                            <div className="flex flex-row  justify-between items-center md:w-[100%] w-full">
                              <div className="w-full md:w-[100%] text-[14px] -mb-3">
                                <FormSwitch
                                  label={
                                    "Do you want to upload any file? (Company Handbook, leave ploicy...)"
                                  }
                                  name={"upload_file"}
                                  readOnly
                                  checked={item.upload_file}
                                />
                              </div>
                            </div>
                            {item.upload_file ? (
                              <>
                                <FormInput
                                  name={"file_name"}
                                  placeholder={"Enter File Name"}
                                  type="text"
                                  readOnly
                                  value={item.filename}
                                  className="!h-[30px] mr-4 text-[13px] w-[100%] border-1 border-gray-300"
                                />
                              </>
                            ) : null}
                            <hr className="divider mt-2 " />
                            <div className="flex flex-row  justify-between items-center md:w-[100%] w-full">
                              <div className="w-full md:w-[100%] text-[15px] -mb-3">
                                <FormSwitch
                                  label={
                                    "Do you want to collect files from employee?"
                                  }
                                  name={"collect_document"}
                                  readOnly
                                  checked={item.collect_document}
                                />
                              </div>
                            </div>
                            {item.collect_document ? (
                              <>
                                <FormInput
                                  name={"document_name"}
                                  placeholder={"Enter File Name"}
                                  type="text"
                                  readOnly={true}
                                  value={item.document_name}
                                  className="!h-[30px] mr-4 text-[13px] w-[60%] border-1 border-gray-300"
                                />
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="italic text-center text-color-gray p-4">
                Onboarding stages not loaded
              </div>
            )}
          </>
          <div className="w-full mt-[20px] pb-4 ">
            <Button
              text={"Add and Continue"}
              type="button"
              onClick={() => submitForm()}
              loading={addOnboardingloading}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </EmployeeOnboardingLayout>
  );
}

export default InitiateEmployeeOnboarding;
