import { Axios } from '../axios';

const getOtherDeductions = async (payload) => {

  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(
    `/other-salary-deductions?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const getOtherDeductionsReport = async (payload) => {
  const { data } = await Axios.get(`/other-salary-deductions/report/${payload.start_date}/${payload.end_date}`);
  return data?.data;
};

const addOtherDeduction = async (payload) => {
  const { data } = await Axios.post('/other-salary-deductions', payload);
  return data;
};
2
const editOtherDeduction = async (payload) => {
  const { data } = await Axios.put('/other-salary-deductions/'+payload.id, payload.body);
  return data;
};

const bulkAddOtherDeduction = async (payload) => {
  const { data } = await Axios.post('/other-salary-deductions/bulk', payload, 
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const deleteOtherDeduction = async (payload) => {
  const { data } = await Axios.delete('other-salary-deductions/'+payload);
  return data;
};

const getSingleEmployeeOtherDeduction = async (payload) => {
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(
    `/other-salary-deductions/employee/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}`,
  );
  return data?.data;
};

export const employeeOtherDeduction = {
    getOtherDeductions,
    getOtherDeductionsReport,
    addOtherDeduction,
    editOtherDeduction,
    bulkAddOtherDeduction,
    deleteOtherDeduction,
    getSingleEmployeeOtherDeduction,
};
