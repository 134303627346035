import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../../components/datatable';
import { PAGINATION_DEFAULT, reImbursementStatusConfig } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { useGetCabinetReimbursements } from '../../redux/employee-folder/hook';

const ReimbursementFolder = ({employeeId}) => {

  const { user } = useSelector((state) => state.userSlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: reimbursements } = useGetCabinetReimbursements(
    employeeId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );


  const tableConfig = {
    headers: [
      'Employee Name',
      'Phone Number',
      'Purpose',
      'Type',
      'Month',
      'Amount',
      'Status'
    ],
    keys: [
      'employeeName',
      'phone',
      'expenses_description',
      'typeLabel',
      'formatted_month',
      'formatted_expenses_amount',
      'status'
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Status',
        key: 'status',
      },
    },
  };

  return (
    <div className='flex flex-col'>
        <div className='mt-9'>
            <div className='flex md:flex-row gap-4 flex-wrap-reverse'>
                <div className='w-[100%]'>
                    <DataTable
                    data={reimbursements}
                    tableConfig={tableConfig}
                    statusConfig={reImbursementStatusConfig}
                    // actionConfig={actionConfig}
                    noCheck={true}
                    // computedActions={true}
                    updatePagination={(data) => setPagination(data)}
                    />
                </div>
            </div>
        </div>
    </div>
  );
};

export default ReimbursementFolder;
