import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/layouts/dashboard";
import OverviewTab from "../../components/employees/overview-tab";
import EmployeeSalariesTab from "../../components/employees/employee-salaries-tab";
import EmployeeGroupsTab from "../../components/employees/employee-groups-tab";
import EmployeeSalaryAdvanceTab from "../../components/employees/employee-salary-advance-tab";
import EmployeeReimbursementTab from "../../components/employees/employee-reimbursements-tab";
import { EmployeeBonusTab } from "../../components/employees/employee-bonus-tab";
// import EmployeeSuspensionsTab from '../../components/employees/employee-suspensions';
import { useGetEmployeeSummary } from "../../redux/employees/hook";
import UnauthorizedPage from "../errors/unauthorized";
import EmployeeSVG from "../../assets/svg/employee-green.svg";
import EmployeeActiveSVG from "../../assets/svg/employee-present.svg";
import EmployeeAwaitingApprovalSVG from "../../assets/svg/employee-awaiting-approval.svg";
import EmployeeSuspendedSVG from "../../assets/svg/employee-suspension.svg";
import { Card } from "../../components/card";
import LoadingBar from "../../components/loader-bar";
import EmployeeOvertimesTab from "../../components/employees/employee-overtimes";
import EmployeeOtherDeductionsTab from "../../components/employees/employee-other-deductions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import EmployeeDocumentsTab from "../../components/employees/employee-documents";
import GuarantorTab from "../../components/employees/guarantor-tab";
import QueriesTab from "../../components/employees/queries-tab";
import { ProbationTab } from "../../components/employees/probation-tab";
import { PerformanceImprovementPlanTab } from "../../components/employees/performance-improvement-plan-tab";
import EmployeeSuspensionsTab from "../../components/employees/employee-suspensions";

const EmployeePage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  const [currentLocation, setCurrentLocation] = useState("employees");

  const location = useLocation();

  useEffect(() => {
    if (location) {
      setCurrentLocation(location.pathname.split("/")[1]);
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.employee_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const { data: getEmployeeSummary, isLoading: summaryLoading } =
    useGetEmployeeSummary();
  const [employeeSummary, setEmployeeSummary] = useState([]);

  useEffect(() => {
    if (getEmployeeSummary) {
      setEmployeeSummary([
        {
          title: "TOTAL EMPLOYEES",
          value: getEmployeeSummary.total,
          icon: EmployeeSVG,
        },
        {
          title: "ACTIVE EMPLOYEES",
          value: getEmployeeSummary.active,
          icon: EmployeeActiveSVG,
        },
        {
          title: "INACTIVE EMPLOYEES",
          value: getEmployeeSummary.inactive,
          icon: EmployeeSuspendedSVG,
        },
        {
          title: "AWAITING ACTIVATION",
          value: getEmployeeSummary.pending ? getEmployeeSummary.pending : 0,
          icon: EmployeeAwaitingApprovalSVG,
        },
      ]);
    }
  }, [getEmployeeSummary]);

  return (
    <DashboardLayout title="Employee Management">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col">
            {summaryLoading ? (
              <LoadingBar loading={summaryLoading} />
            ) : (
              <div className="grid md:grid-cols-4 lg:grid-cols-4 grid-cols-2 gap-4 -mt-2">
                {employeeSummary?.map((item, index) => (
                  <Card
                    key={index}
                    title={item.title}
                    subtitle={item.value}
                    icon={item.icon}
                  />
                ))}
              </div>
            )}
            {currentLocation === "employees" ? (
              <>
                {user?.permissions.employee_permission.employee_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <OverviewTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "salaries" ? (
              <>
                {user?.permissions.employee_permission.salary_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <EmployeeSalariesTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "bonuses" ? (
              <>
                {user?.permissions.employee_permission.bonus_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <EmployeeBonusTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "overtime" ? (
              <>
                {user?.permissions.employee_permission.overtime_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <EmployeeOvertimesTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "payroll-groups" ? (
              <>
                {user?.permissions.employee_permission.group_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <EmployeeGroupsTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "advances-loans" ? (
              <>
                {user?.permissions.employee_permission.advance_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <EmployeeSalaryAdvanceTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "other-deductions" ? (
              <>
                {user?.permissions.employee_permission.other_deductions_read ===
                  1 || user?.permissions.company_permission.role === "Owner" ? (
                  <EmployeeOtherDeductionsTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "reimbursements" ? (
              <>
                {user?.permissions.employee_permission.reimbursement_read ===
                  1 || user?.permissions.company_permission.role === "Owner" ? (
                  <EmployeeReimbursementTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "employee-documents" ? (
              <>
                {user?.permissions.employee_permission.doc_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <EmployeeDocumentsTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "guarantors" ? (
              <>
                {user?.permissions.employee_permission.guarantor_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <GuarantorTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "queries" ? (
              <>
                {user?.permissions.employee_permission.query_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <QueriesTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "probations" ? (
              <>
                {user?.permissions.employee_permission.query_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <ProbationTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "performance-improvement-plans" ? (
              <>
                {user?.permissions.employee_permission.query_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <PerformanceImprovementPlanTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "suspensions" ? (
              <>
                {user?.permissions.employee_permission.suspension_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <EmployeeSuspensionsTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : null}
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default EmployeePage;
