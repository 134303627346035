import React, { useEffect, useState } from "react";

import Button from "../../../components/button";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../utils/functions";
import { useRegistrationUploadProfilePicture } from "../../../redux/employees/hook";
import { useHistory, useLocation, useParams } from "react-router-dom";
import EmployeeRegistrationLayout from "../../../components/layouts/employee-registration";
import FormFileInputWithOnchange from "../../../components/form-file-input-with-onchange";
import Logo from "../../../components/logo";
import { useSelector } from "react-redux";

function UploadProfilePicture() {
  useEffect(() => {}, []);
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { mutateAsync: uploadProfilePicture, isLoading } =
    useRegistrationUploadProfilePicture();

  const { employee } = useSelector((state) => state.employeeSlice);

  const { token } = useParams();

  const location = useLocation();

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    if (location) {
      setPayload(location.state);
    }
  }, [location]);

  const [payload, setPayload] = useState({});

  const history = useHistory();

  const submitForm = async (data) => {
    const formData = new FormData();
    formData.append("file", data?.file[0]);
    formData.append("employee_id", payload?.employeeId);
    formData.append("token", token);

    await uploadProfilePicture(formData).then((result) => {
      history.push(
        "/employees/single-employee-onboarding/verify/" + token,
        payload
      );
    });
  };

  const skip = async () => {
    history.push(
      "/employees/single-employee-onboarding/verify/" + token,
      payload
    );
  };

  return (
    <EmployeeRegistrationLayout
      step="4"
      company_name={employee?.company_name}
      logo={employee?.logo}
    >
      <div className="flex flex-col justify-between w-full md:pt-5 h-[100vh] gap-4">
        <div className="flex flex-col w-full max-w-[600px] px-5 md:px-0">
          <div className="w-full text-center flex flex-col pb-[25px] pt-10">
            <span className="header-2-regular text-secondary-2">
              Profile Picture
            </span>
            <span className="header-4-regular text-secondary-2">
              Upload your profile picture
            </span>
          </div>
          <hr className="divider -mt-2" />
          <div className="flex justify-end w-full  mb-2" onClick={() => skip()}>
            <span className="text-[15px] font-bold text-secondary-2 cursor-pointer hover:underline">
              Skip this step
            </span>
          </div>
          <div className="md:hidden flex flex-col md:w-1/2 w-full md:pr-2">
            <span className="header-3 text-color-gray">Company Name</span>
            <span className="header-4 mt-1 text-color-black font-bold">
              {employee?.company_name}
            </span>
            <hr className="divider" />
          </div>
          <div className="flex flex-col justify-center items-center">
            <form
              className="form flex flex-col justify-center items-center"
              onSubmit={handleSubmit(submitForm)}
            >
              <div className="flex flex-col justify-center items-center">
                <span className="header-5 text-left">(Max. size 2MB)</span>
                <div className="flex flex-row gap-2">
                  <FormFileInputWithOnchange
                    multiSource={false}
                    inputRef={register(formValidation("file", true))}
                    accept=".jpg,.jpeg,.png"
                    name={"file"}
                    error={errors.file}
                    onChange={handleChange}
                    errorMessage={errors.file && errors.file.message}
                  />
                  {file ? (
                    <div className="flex items-center justify-center mt-2 border border-gray-200 p-2 h-[65px] w-[65px] rounded duration-500">
                      <img
                        src={file}
                        alt={"profile_pix"}
                        className="object-contain max-w-[55px] max-h-[55px]"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="w-full mt-[20px]">
                <Button
                  text="Upload"
                  type="submit"
                  className={"!bg-secondary-2"}
                  loading={isLoading}
                  // disabled={!isValid}
                />
              </div>

              <div
                className="flex justify-center w-full cursor-pointer"
                onClick={() => history.goBack()}
              >
                <span className="p2-medium text-color-black mt-3">Back</span>
              </div>
            </form>
          </div>
        </div>
        <div
          className={
            "md:hidden flex items-center justify-center text-color-black gap-2"
          }
        >
          Powered By:{" "}
          <a href="https://www.ropay.africa" target={"_blank"} rel="noreferrer">
            <Logo width="80px" height="40px" blue />
          </a>
        </div>
      </div>
    </EmployeeRegistrationLayout>
  );
}

export default UploadProfilePicture;
