import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import FormInput from "../../../components/form-input";
import { useHistory } from "react-router-dom";
import EmployeeRecruitmentLayout from "../../../components/layouts/employee-recruitment";
import CollapsingIcon from "../../../components/collapsing-icon";
import classNames from "classnames";
import { Collapse } from "reactstrap";
import ShareRecruitmentModal from "../../../components/modal/hr-section/recruitment/recruitment-share";
import FormSelect from "../../../components/form-select";
import {
  useAddRecruitment,
  useGetRecruitmentWorkflow,
} from "../../../redux/human-resources/hook/recruitment";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { formatNumber } from "../../../utils/functions";

function RecruitmentSummaryPage() {
  useEffect(() => {}, []);

  const history = useHistory();
  const { mutateAsync: addRecruitment, isLoading: loading } =
    useAddRecruitment();

  const { assignWorkflowData } = useSelector((state) => state.recruitmentSlice);

  const dispatch = useDispatch();

  const [payLoad, setPayload] = useState({});

  const [workflowIid, setWorkflowIid] = useState();

  const [applicationUrl, setApplicationUrl] = useState("bit/ly.RPYVACS161%");
  const [recruitmentId, setRecruitmentId] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);
  const [showRecruitmentDetails, setShowRecruitmentDetails] = useState(true);
  const [showRecruitmentWorkflow, setShowRecruitmentWorkflow] = useState(false);
  const [showRecruitmentQuestions, setShowRecruitmentQuestions] =
    useState(false);

  const { data: workflow_info } = useGetRecruitmentWorkflow(workflowIid);

  useEffect(() => {
    if (workflow_info) {
      setWorkflow(workflow_info);
    }
  }, [workflow_info]);

  const QuestionTypes = [
    { label: "Multiple Choice", value: "multiple_choice" },
    { label: "Open Ended", value: "open_ended" },
  ];

  const [workflow, setWorkflow] = useState();

  useEffect(() => {
    if (assignWorkflowData) {
      setWorkflowIid(assignWorkflowData.workflow_id);
      setPayload(assignWorkflowData);
    }
  }, [assignWorkflowData]);

  const submitForm = async (data) => {
    await addRecruitment(payLoad).then((result) => {
      if (result && result.status === "success") {
        setApplicationUrl(result.data.application_url);
        setRecruitmentId(result.data.id);
        setShowConfirm(true);
      }
    });
  };

  return (
    <EmployeeRecruitmentLayout step={5} title={"Recruitment Summary"}>
      {showConfirm ? (
        <ShareRecruitmentModal
          isOpen={showConfirm}
          url={applicationUrl}
          recruitmentId={recruitmentId}
        />
      ) : null}
      <div className="flex flex-col w-full  h-full">
        <span className="header-4">Recruitment Summary</span>
        <hr className="divider mb-2" />
        <div className="">
          <div className="flex flex-col">
            {/* Recruitment Details */}
            <div
              className={classNames(
                "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                {
                  "pt-3 pb-3": showRecruitmentDetails,
                }
              )}
              onClick={() => setShowRecruitmentDetails(!showRecruitmentDetails)}
            >
              <span>Recruitment Details</span>
              <div className="pr-4">
                <CollapsingIcon
                  defaultPosition="left"
                  isOpen={showRecruitmentDetails}
                />
              </div>
            </div>

            <div
              className={classNames("flex flex-col", {
                hidden: !showRecruitmentDetails,
              })}
            >
              <Collapse isOpen={showRecruitmentDetails}>
                <div className="mt-2 grid md:grid-cols-2 grid-cols-1 gap-4 w-full">
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">Job Role</span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {payLoad?.role}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">Job Type</span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {payLoad?.job_type}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Salary Range
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {formatNumber(payLoad?.min_salary, 2)} -{" "}
                      {formatNumber(payLoad?.max_salary, 2)}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Salary Type
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {payLoad?.salary_type}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Years Of Experience
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {payLoad?.years_of_experience}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Education Level
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {payLoad?.min_education}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Experience Level
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {payLoad?.experience_level}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Job Location
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {payLoad?.location_type}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Application Ends
                    </span>
                    <span className="mt-1 text-color-black text-[14px]">
                      {moment(payLoad?.application_end).format("MMMM DD, YYYY")}
                    </span>
                  </div>
                </div>
                <div className="mt-4 grid md:grid-cols-1 grid-cols-1 gap-4 w-full">
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray pb-2">
                      Job Description
                    </span>
                    <td
                      className="border-1 p-3 rounded !max-h-[600px] overflow-auto !text-[13px]"
                      dangerouslySetInnerHTML={{
                        __html: payLoad?.job_description,
                      }}
                    />
                  </div>
                </div>
              </Collapse>
            </div>
            {workflow ? (
              <>
                <hr className="divider mt-1 mb-1" />
                {/* Recruitment Workflow */}
                <div
                  className={classNames(
                    "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                    {
                      "pt-3 pb-3": showRecruitmentWorkflow,
                    }
                  )}
                  onClick={() =>
                    setShowRecruitmentWorkflow(!showRecruitmentWorkflow)
                  }
                >
                  <span>Recruitment Workflow</span>
                  <div className="pr-4">
                    <CollapsingIcon
                      defaultPosition="left"
                      isOpen={showRecruitmentWorkflow}
                    />
                  </div>
                </div>
                <div
                  className={classNames(
                    "flex flex-col p-3 border rounded-md mt-2",
                    {
                      hidden: !showRecruitmentWorkflow,
                    }
                  )}
                >
                  <Collapse isOpen={showRecruitmentWorkflow}>
                    <div className="mt-3 grid md:grid-cols-1 grid-cols-1 gap-4 w-full mb-3">
                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">
                          Workflow Name
                        </span>
                        <span className="mt-1 text-color-black text-[14px]">
                          {workflow.name}
                        </span>
                      </div>
                    </div>
                    <div className="mt-2 grid md:grid-cols-1 grid-cols-1 gap-4 w-full mb-1">
                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">
                          Recruitment Stages
                        </span>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full border-2 p-2 rounded mt-2">
                      <div className="flex flex-col">
                        <div className="flex flex-row gap-4">
                          <span className="header-5 w-[30%] text-color-gray"></span>
                          <span className="header-5 w-[40%] text-[#0d5225]">
                            Title
                          </span>
                          <span className="header-5 w-[25%] text-[#0d5225]">
                            Type
                          </span>
                        </div>
                        {workflow.sub_workflows.map((item) => (
                          <div className="flex flex-row gap-4">
                            <div className="header-5 w-[30%]">
                              Stage {item.index}:{" "}
                            </div>
                            <div className="text-[14px] w-[40%]">
                              {item.name}
                            </div>
                            <div className="header-6 w-[25%]">{item.type}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Collapse>
                </div>
              </>
            ) : null}
            {payLoad && payLoad.questions?.length > 0 ? (
              <>
                <hr className="divider mt-1 mb-1" />
                {/* Recruitment Questions */}
                <div
                  className={classNames(
                    "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                    {
                      "pt-3 pb-3": showRecruitmentQuestions,
                    }
                  )}
                  onClick={() =>
                    setShowRecruitmentQuestions(!showRecruitmentQuestions)
                  }
                >
                  <span>Recruitment Questions</span>
                  <div className="pr-4">
                    <CollapsingIcon
                      defaultPosition="left"
                      isOpen={showRecruitmentQuestions}
                    />
                  </div>
                </div>
                <div
                  className={classNames("flex flex-col", {
                    hidden: !showRecruitmentQuestions,
                  })}
                >
                  <Collapse isOpen={showRecruitmentQuestions}>
                    <div className="mt-2 grid md:grid-cols-1 grid-cols-1 gap-4 w-full mb-1">
                      <div className="flex flex-col">
                        <span className="header-4 text-color-gray">
                          Questions
                        </span>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full border-2 p-2 rounded mt-2">
                      <div className="flex flex-col">
                        {payLoad && payLoad.questions?.length > 0 ? (
                          <>
                            {payLoad?.questions.map((item, index) => (
                              <>
                                <span className="font-bold italic text-black underline">
                                  Questions {index + 1}
                                </span>
                                <div className="flex flex-row justify-between items-center !w-[100%]">
                                  <div className="flex flex-row items-center  justify-start">
                                    <FormInput
                                      name={index + "_question"}
                                      placeholder={"Enter Question ???"}
                                      type="text"
                                      readyOnly
                                      value={item.question}
                                      className="!h-[30px] md:w-[220px] w-[150px] text-[13px] border-1 border-gray-300"
                                    />
                                  </div>
                                  <div className="flex flex-row justify-end items-center ">
                                    <FormSelect
                                      value={item.question_type}
                                      options={QuestionTypes}
                                      name={item.question_type}
                                      readOnly
                                      className="!min-h-[32px] border-1 mt-1 w-[150px] rounded text-[13px] p-1 mr-2"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-col items-start justify-start w-full">
                                  {item.question_type === "multiple_choice" ? (
                                    <div className="flex flex-col w-full">
                                      {item.options && item.options.length ? (
                                        <span className="italic text-hr-primary-1 underline mb-3">
                                          Options
                                        </span>
                                      ) : null}
                                      {item.options &&
                                        item.options.length > 0 &&
                                        item.options.map(
                                          (subItem, subIndex) => (
                                            <div
                                              className="flex flex-row justify-start items-center"
                                              key={subIndex}
                                            >
                                              <div className="flex flex-row items-center -mt-4">
                                                <span className="italic mr-4 text-[15px] font-bold">
                                                  {subIndex + 1}.
                                                </span>
                                                <FormInput
                                                  name={subIndex + "_option"}
                                                  placeholder={"Enter Option"}
                                                  type="text"
                                                  readOnly
                                                  value={subItem.option}
                                                  className="!h-[30px] w-[250px] text-[13px] border-1 border-gray-300"
                                                />
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </div>
                                  ) : (
                                    <div className="mb-2"></div>
                                  )}
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <span>No Recruiment Questions Added</span>
                        )}
                      </div>
                    </div>
                  </Collapse>
                </div>
                <hr className="divider mt-1" />
              </>
            ) : null}
          </div>
          <div className="w-full mt-[20px]">
            <Button
              text="Confirm & Save"
              type="submit"
              loading={loading}
              onClick={() => {
                submitForm();
              }}
            />
          </div>
          <div
            className="flex justify-center w-full cursor-pointer"
            onClick={() => history.goBack()}
          >
            <span className="p2-medium text-color-black mt-3">Go Back</span>
          </div>
        </div>
      </div>
    </EmployeeRecruitmentLayout>
  );
}

export default RecruitmentSummaryPage;
