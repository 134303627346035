import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import AddOnboardingSubWorkflow from './add-subworkflow';
import {
  useAddOnboardingWorkflow,
  useUpdateOnboardingWorkflow,
} from '../../../../redux/employees/hook/onboarding';
import Modal from '../../modal';

function AddOnboardingWorkflow({
  isOpen,
  closeModal,
  workflowData,
  title,
  buttonText,
}) {
  const [maxId, setMaxId] = useState(4);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (workflowData) {
      setWorkflowId(workflowData.id);
      setWorkflowType('edit');
      setName(workflowData.name);
      setDescription(workflowData.description);
    }
  }, [workflowData]);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [workflowType, setWorkflowType] = useState('add');
  const [workflowId, setWorkflowId] = useState(null);

  const [workflowDataForSub, setWorkflowDataForSub] = useState(null);

  const { mutateAsync: addWorkflow, isLoading: addWorkflowloading } =
    useAddOnboardingWorkflow();
  const { mutateAsync: editWorkflow, isLoading: editWorkflowloading } =
    useUpdateOnboardingWorkflow();

  const [showAddSubWorkFlow, setShowAddSubWorkflow] = useState(false);

  const submitForm = async () => {
    if (workflowType === 'add') {
      let payload = {
        name,
        type: 'onboarding',
        description,
      };
      await addWorkflow(payload).then((result) => {
        if (result && result.data) {
          setWorkflowDataForSub(result.data);
          setShowAddSubWorkflow(true);
        }
      });
    } else if (workflowType === 'edit') {
      let payload = {
        name,
        description,
        type: 'onboarding',
      };
      let editPayload = {
        workflow_id: workflowId,
        data: payload,
      };
      await editWorkflow(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        {showAddSubWorkFlow ? (
          <AddOnboardingSubWorkflow
            title={'Add Sub Workflows'}
            isOpen={showAddSubWorkFlow}
            workflowData={workflowDataForSub}
            buttonText='Save and Complete'
            closeModal={() => setShowAddSubWorkflow(false)}
          />
        ) : null}
        <form className='form pb-6'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col max-h-fit mx-8'>
            <FormInput
              label='Workflow Name'
              name='name'
              type='text'
              placeholder='Enter worklflow name'
              value={name}
              onInput={(e) => setName(e.target.value)}
              readOnly={addWorkflowloading || editWorkflowloading}
              error={errors.name}
              errorMessage={errors.name && errors.name.message}
            />
            <FormInput
              label={'Workflow Description'}
              name='description'
              placeholder='Enter description'
              value={description}
              onInput={(e) => setDescription(e.target.value)}
              readOnly={addWorkflowloading || editWorkflowloading}
              error={errors.description}
              errorMessage={errors.description && errors.description.message}
            />
            <hr className='divider mt-3 ' />
            <div className='w-full'>
              <Button
                text={buttonText}
                theme={'third'}
                type='submit'
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
                loading={addWorkflowloading || editWorkflowloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddOnboardingWorkflow;
