import React, { useEffect, useState } from 'react';
import Button from '../../button';
import FormSelect from '../../form-select';
import FormOtpInput from '../../form-otp-input';
import { useConfirmOtp, useGenerateOtp } from '../../../redux/employees/hook';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { formValidation } from '../../../utils/functions';
import FormInput from '../../form-input';
import Modal from '../modal';

function EmployeeVerification({ isOpen, closeModal, refresh, setEmployee }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [consent, setConsent] = useState(false);

  const [verificationType, setVerificationType] = useState('email');
  const [minutesLeft, setMinutesLeft] = useState(10);

  const [otp, setOtp] = useState('');
  const [countDownStarted, setCountDownStarted] = useState(false)

  const [buttonText, setButtonText] = useState('GENERATE OTP');
  const [buttonAction, setButtonAction] = useState('generate_otp');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [dateOtpGenerated, setDateOtpGenerated] = useState(new Date());

  const { token } = useParams();
  const [timeLeft, setTimeLeft] = useState({
    minutes: '10',
    seconds: '00',
  });

  const calculateTimeLeft = () => {
    const difference = new Date() - dateOtpGenerated;
    let timeLeft = {};
    if (
      Math.floor((difference / 1000 / 60) % 60) < 10
    ) {
      setMinutesLeft(10 - Math.floor((difference / 1000 / 60) % 60))
      
    } else {
      setButtonAction('generate_otp')
      setCountDownStarted(false)
      setMinutesLeft(0)
    }
    return timeLeft;
  };

  useEffect(() => {
    if (countDownStarted) {
      setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
    }
    
  }, [timeLeft, countDownStarted]);

  const { mutateAsync: generateOtp, isLoading: generateLoading } =
    useGenerateOtp();
  const { mutateAsync: confirmOtp, isLoading: confirmLoading } =
    useConfirmOtp();

  async function submitForm() {
    if (buttonAction === 'generate_otp') {
      let data = {
        token: token,
      };
      await generateOtp(data).then((result) => {
        setMinutesLeft(10)
        setCountDownStarted(true)
        setButtonAction('confirm_otp');
        setButtonText('CONFIRM OTP');
        setShowOtpInput(true);
        setDateOtpGenerated(new Date());
      });
    } else if (buttonAction === 'confirm_otp') {
      let data = {
        token: token,
        pin: otp,
      };
      await confirmOtp(data).then((result) => {
        if (result.status === 'success') {
          closeModal();
        }
      });
    }
  }

  const verificationTypeOptions = [
    {
      label: 'Email',
      value: 'email',
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='!max-w-[750px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto w-full'>
        <div className='p-4'>
          <div className='text-center flex flex-col'>
            <span className='header-3 text-secondary-2'>
              Employee Verification
            </span>
            <hr className='divider' />
            <div className='flex justify-center mt-1 items-center'>
              <div className='w-[85%]'>
                <span className='header-5-regular text-secondary-2'>
                  Generate verification code and consent to data usage
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='px-[50px] pb-3 flex flex-col w-[100%]'>
          <FormSelect
            defaultValue={verificationType}
            options={verificationTypeOptions}
            onChange={(selected) => {
              setVerificationType(selected);
            }}
            label='Verification Channel'
          />
          {showOtpInput ? (
            <>
              <div className='flex w-[100%] justify-center'>
                <FormOtpInput
                  value={otp}
                  valueLength={4}
                  onChange={(value) => setOtp(value)}
                  label='Enter OTP'
                />
              </div>

              <div className='flex flex-row mb-3 justify-between'>
                <span className='text-[14px] text-secondary-2'>
                {minutesLeft < 1?
                <>
                  OTP has expired
                </>
                :
                <>
                  OTP Expires in {' '}
                  <span className=' text-[14px] font-bold'>{minutesLeft} minutes</span>
                </>
                }
                  
                  
                </span>
                {minutesLeft < 1?
                <span
                  className='text-secondary-2 text-[14px] cursor-pointer hover:underline font-bold'
                  onClick={() => {
                    
                    setButtonAction('generate_otp')
                    setCountDownStarted(true)
                    submitForm();
                  }}
                >
                  Resend OTP
                </span>
                : null
                }
              </div>
            </>
          ) : (
            <>
              <div className='flex flex-col w-[100%] border-1  border-gray-300 align-center max-h-[220px] overflow-auto rounded '>
                <div className='header-4 text-center pt-3 pr-3 pl-3'>
                  Consent to the Collecting and Processing of Personal Data
                </div>
                <hr className='divider' />
                <div className='text-justify p4-regular p-3 pb-1'>
                  By sending this form and clicking the option{' '}
                  <span className='p5-bold'>“I AGREE”,</span> I, as the Data
                  Subject, hereby consent to the processing of my below given
                  personal data which personal data I hereby voluntarily provide
                  to Ropayments Limited by the method specified below in this
                  consent. I acknowledge that the Personal Data which relate to
                  my person represent the socalled personal data within the
                  ("NDPR 2019"). At the same time I declare that I am at least
                  16 years old and if not than I confirm that such consent has
                  been expressed or given by the person who has the parental
                  responsibility for me.
                </div>
                <div className='flex flex-col mt-2 p-3 pt-1 pb-1'>
                  <div className='header-5'>Controller</div>
                  <div className='text-justify p4-regular'>
                    The Controller is Ropayments Limited, Company number:
                    RC1906264, with registered office at 256, Murtala Muhammed
                    way, Alagomeji, Yaba, Lagos State, Nigeria, registered in
                    the Companies And Allied Matters Act 2020 (the “Data
                    Controller“).
                  </div>
                </div>
                <div className='flex flex-col p-3 pt-1 pb-1'>
                  <div className='header-5'>Personal Data Processing</div>
                  <div className='p4-bold'>
                    <ol className='list-decimal'>
                      <li className='text-justify p4-regular'>
                        My personal data, which I voluntarily provide to the
                        Controller, are the following:{' '}
                        <span className='p5-bold'>
                          First Name, Last Name, Date of Birth, Address, Email
                          Address, Telephone No., Identification No., Tax
                          Identification No., RSA Pin, Bank information, Gender,
                          Marital Status and other employement information.
                        </span>
                      </li>
                      <li className='text-justify p4-regular mt-2'>
                        I give my consent to the processing of the above
                        personal data for the following purposes:
                        <ol className='list-decimal'>
                          <li className='text-justify p4-regular'>
                            The Controller’s marketing purposes relating to
                            offers of products and services; sending information
                            about organized events, products, services and other
                            activities (e.g. in the form of sent newsletters,
                            telemarketing); contacts for the purpose of market
                            researches; contacts for the purpose of Christmas or
                            Easter greetings or other holidays greetings and
                            sending discount vouchers, gifts, etc.
                          </li>
                        </ol>
                        when the processing of personal data is necessary in
                        order to fulfill these purposes.
                      </li>
                      <li className='text-justify p4-regular mt-2'>
                        The Controller shall process personal data which shall
                        be adequate, relevant and limited to the extent
                        necessary for the purpose of processing.
                      </li>
                      <li className='text-justify p4-regular mt-2'>
                        Besides the Controller, the above personal data shall be
                        collected and processed also by the following subjects:
                        <ol className='list-disc'>
                          <li className='text-justify p4-regular mt-2'>
                            <span className='p5-bold'>
                              Recipients of personal data:
                            </span>{' '}
                            The Controller shall be entitled to transfer my
                            personal data in particular to the following
                            recipients and categories of recipients: the
                            Controller’s suppliers, the Controller’s employees,
                            transport services providers, providers of marketing
                            and advertising services, financial institutions,
                            state authorities within the scope of fulfilling the
                            statutory obligations prescribed by relevant legal
                            regulations; and other parties in other contractual
                            relationships with the Controller.
                          </li>
                          <li className='text-justify p4-regular mt-2'>
                            The Controller shall be entitled to provide my
                            personal data also to relevant legal authorities. I
                            acknowledge that in the case of transmission of
                            personal data to third countries the Data Controller
                            shall have sufficient control mechanisms for their
                            protection, including the analysis relevant to the
                            protection of such third country and/or, as the case
                            may be, the conclusion of a standard contractual
                            clause on protection of personal data approved by
                            NDPR.
                          </li>
                        </ol>
                      </li>
                      <li className='text-justify p4-regular mt-2'>
                        The Controller shall be entitled to process the above
                        personal data for a period of 5 years starting from the
                        date of giving my consent. I acknowledge that prior to
                        the expiration of this period the Controller may contact
                        me with the purpose to renew my consent and that without
                        such renewal my Personal Data shall be, after the
                        expiration of the above period, deleted forever.
                      </li>
                    </ol>
                  </div>
                </div>
                <div className='flex flex-col p-3 pt-1 pb-1'>
                  <div className='header-5'>Data Subject’s Rights </div>
                  <div className='p4-bold'>
                    <ol className='list-decimal'>
                      <li className='text-justify p4-regular mt-2'>
                        I acknowledge that as the Data Subject I have the right
                        to demand from the Controller the access to, the
                        correction and/or deletion of (Article 16 or 17 of the
                        GDPR) and as the case may any limitation of processing
                        as well as the right to object against the carried out
                        processing of my Personal Data. According to the
                        provisions of the NDPR, as the Data Subject, I have the
                        right to the portability of data relating to my person
                        which I have provided to the Controller.
                      </li>
                      <li className='text-justify p4-regular mt-2'>
                        As the Data Subject, I can contact the Controller in
                        relation to the processing of my personal data at the
                        email address: hello@ropay.ng.
                      </li>
                      <li className='text-justify p4-regular mt-2'>
                        I hereby give my consent to the processing of my
                        personal data by the Controller for the purpose and for
                        the period of time specified above. I acknowledge that
                        the controller shall archive consent for the purpose of
                        fulfilling the controller's statutory obligation to
                        demonstrate that my consent to the processing has been
                        given.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className='d_flex remember_section mt-3'>
                <div className='flex flex-row'>
                  <FormInput
                    label=''
                    name='consent'
                    type='checkbox'
                    inputRef={register(formValidation('checkbox', true))}
                    readOnly={generateLoading || confirmLoading}
                    error={errors.consent}
                    errorMessage={errors.consent && errors.consent.message}
                    value={consent}
                    onInput={(e) => setConsent(e.target.checked)}
                    CustomLabel={
                      <span className='p4-medium text-secondary-2 mt-1 pl-3'>
                        I agree with Data Processing Consent
                      </span>
                    }
                  />
                </div>
              </div>
            </>
          )}
          <div className='w-full'>
            <Button
              text={buttonText}
              type='submit'
              className={'!bg-secondary-2'}
              disabled={!consent}
              loading={generateLoading || confirmLoading}
              onClick={() => submitForm()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EmployeeVerification;
