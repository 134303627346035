import React from 'react';
import Info from './info';
import classNames from 'classnames';

const FormTextArea = ({
  id,
  name,
  label,
  type,
  placeholder,
  accept,
  defaultValue,
  inputRef,
  onChange,
  onKeyDown,
  readOnly,
  error,
  errorMessage,
  infos,
  className,
  ...rest
}) => {
  return (
    <div className='pb-[20px] flex flex-col'>
      <div className='flex flex-col'>
        {label && (
          <label className='text-[13px] leading-[23px] font-normal text-color-gray -mb-2'>
            {label}
          </label>
        )}

        <textarea
          name={name}
          type={type}
          placeholder={placeholder}
          accept={accept}
          className={classNames(`border border-color-gray rounded-md p-3 px-2 !text-[13px] mt-[10px] text-color-black ${className}`, {
            '!bg-secondary-2-extralight': readOnly,
          })}
          ref={inputRef}
          onChange={onChange}
          onKeyDown={onKeyDown}
          readOnly={readOnly ? true : false}
          {...rest}
        >
          {defaultValue}
        </textarea>
      </div>
      {error && 
        <span className='text-error text-[13px] italic'>* {errorMessage}</span>
      }
      {infos && <Info infos={infos} />}
    </div>
  );
};

export default FormTextArea;
