import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../..';
import moment from 'moment';
import { referrals } from '../../api/referrals';

export function useGetReferredUsers(limit, page) {
    return useQuery(
      ['referred_users', { limit, page }],
      () => {
        store.dispatch(setIsLoading(true));
        return referrals.getReferredUsers({ limit, page });
      },
      {
        select: (data) => {
          data?.data?.forEach((referral) => {
            referral.created = moment(referral.created).format('MMMM DD, YYYY');
            referral.computedActions = [0];
          });
  
          return data;
        },
        onSettled: () => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetReferredCompanies(limit, page) {
  return useQuery(
    ['referred_companies', { limit, page }],
    () => {
      store.dispatch(setIsLoading(true));
      return referrals.getReferredCompanies({ limit, page });
    },
    {
      select: (data) => {
        data?.data?.forEach((referral) => {
          referral.created = moment(referral.created).format('MMMM DD, YYYY');
          referral.expiry_date = moment(referral.expiry_date).format('MMMM DD, YYYY');
          referral.computedActions = [0];
        });

        return data;
      },
      onSettled: () => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetReferredTransactions(limit, page, type) {
  return useQuery(
    ['referred_transactions', { limit, page, type }],
    () => {
      store.dispatch(setIsLoading(true));
      return referrals.getReferredTransactions({ limit, page, type });
    },
    {
      select: (data) => {
        data?.data?.forEach((referral) => {
          referral.created = moment(referral.created).format('MMMM DD, YYYY');
          
          referral.commission = referral.referral_amount_in ? referral.referral_amount_in : referral.referral_amount_out
          referral.computedActions = [0];
        });

        return data;
      },
      onSettled: () => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetReferralData() {
    return useQuery(
      ['referral_info', {  }],
      () => {
        store.dispatch(setIsLoading(true));
        return referrals.getReferralData();
      },
      {
        onSettled: () => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
  }

export function useGenerateReferral() {
    return useMutation(
      (payload) => {
        return referrals.generateReferral(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('referral_info');
          store.dispatch(setAlert(true, 'success', 'Referral information generated successfully'));
        },
        onSettled: (response, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useCheckReferral() {
  return useMutation(
    () => {
      return referrals.checkReferral();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Referral checked successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateAccountDetails() {
  return useMutation(
    (payload) => {
      return referrals.updateReferralAccountDetails(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('referral_info');
        queryClient.invalidateQueries('referred_transactions');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useWithdrawReferralBalance() {
  return useMutation(
    (payload) => {
      return referrals.withdrawReferralBalance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('referral_info');
        queryClient.invalidateQueries('referred_transactions');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}


