import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT, queryStatusConfig } from "../../utils/constants";
import AddQuery from "../modal/hr-section/queries/add-query";
import {
  useDeleteQuery,
  useGetQueries,
} from "../../redux/human-resources/hook/queries";
import Button from "../button";
import { useHistory } from "react-router-dom";
import DoYouWantToModal from "../modal/do-you-want-to";
import { useGetEmployees } from "../../redux/employees/hook";

const QueriesTab = () => {
  useEffect(() => {}, []);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const [showAddQuery, setShowAddQuery] = useState(false);
  const [queryData, setQueryData] = useState();

  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { mutateAsync: deleteQuery, isLoading: deleteLoading } =
    useDeleteQuery();

  const history = useHistory();

  const { data: queries } = useGetQueries(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const tableConfig = {
    headers: ["Date Added", "Employee Name", "Query", "Status"],
    keys: ["created", "employeeName", "query", "status"],
    mobileHeader: {
      left: {
        title: "Date Added",
        key: "created",
      },
      right: {
        title: "Employee Name",
        key: "employeeName",
      },
    },
  };

  const deleteSingleQuery = async (id) => {
    let index = queries.data.findIndex((item) => item.id === id);
    await deleteQuery(queries.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = queries.data.findIndex((item) => item.id === id);
        history.push("/employees/query/" + queries.data[index].id);
      },
    },
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = queries.data.findIndex((item) => item.id === id);
        setTitle("Update Query");
        setButtonText("Update Query");
        setQueryData(queries.data[index]);
        setShowAddQuery(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = queries.data.findIndex((item) => item.id === id);
        setDeleteId(queries.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded mt-4 border">
      {showDoYouWant ? (
        <DoYouWantToModal
          isOpen={showDoYouWant}
          closeModal={() => setShowDoYouWant(false)}
          id={deleteId}
          loading={deleteLoading}
          title={"Are you sure you want to delete this query?"}
          buttonText={"DELETE"}
          btnFunction={deleteSingleQuery}
        />
      ) : null}
      {showAddQuery ? (
        <AddQuery
          isOpen={showAddQuery}
          closeModal={() => {
            setShowAddQuery(false);
          }}
          queryData={queryData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-2">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Queries
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Query"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              disabled={!employees}
              leftIcon={"add"}
              onClick={() => {
                setTitle("Add Query");
                setButtonText("Save Query");
                setShowAddQuery(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={queries}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        statusConfig={queryStatusConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        searchData={true}
      />
    </div>
  );
};

export default QueriesTab;
