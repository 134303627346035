import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { setPurchaseOrder} from './reducer';
import { purchase_orders } from '../../../api/spend/purchase-order';
import { formatNumber } from '../../../utils/functions';
import history from '../../../routes/history';


export function useGetPurchaseOrders(limit, page, statusFilter, search) {
  return useQuery(
    ['purchase-orders', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return purchase_orders.getPurchaseOrders({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((purchase_order) => {
          //compute action items
          purchase_order.created = moment(purchase_order.created).format('MMMM D, YYYY');
          purchase_order.purchase_order_date = moment(purchase_order.purchase_order_date).format('MMMM D, YYYY');
          purchase_order.purchase_order_due_date = moment(purchase_order.purchase_order_due_date).format('MMMM D, YYYY');
          purchase_order.formatted_total_amount = formatNumber(purchase_order.formatted_total_amount, 2);
          if (purchase_order.status !== 1) {
            if (moment(purchase_order.purchase_order_due_date).diff(moment(purchase_order.purchase_order_date), 'days' ) > 0) {
              purchase_order.due_status = 0
            } else if (moment(purchase_order.purchase_order_due_date).diff(moment(purchase_order.purchase_order_date), 'days' ) == 0) {
              purchase_order.due_status = 2
            } else {
              purchase_order.due_status = 3
            }
          } else {
            purchase_order.due_status = '1'
          }
          
          
          switch (purchase_order.status) {
            case 0:
                purchase_order.computedActions = [0, 1, 2];
              break;
            case 1:
                purchase_order.computedActions = [0, 1, 3];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetPurchaseOrdersByVendor(id, limit, page, statusFilter, search) {
  return useQuery(
    ['purchase_order-by-vendor', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return purchase_orders.getPurchaseOrdersByVendor({ id, limit, page, statusFilter, search });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((purchase_order) => {
          //compute action items
          purchase_order.created = moment(purchase_order.created).format('MMMM D, YYYY');
          purchase_order.purchase_order_date = moment(purchase_order.purchase_order_date).format('MMMM D, YYYY');
          purchase_order.purchase_order_due_date = moment(purchase_order.purchase_order_due_date).format('MMMM D, YYYY');
          if (purchase_order.status !== 1) {
            if (moment(purchase_order.purchase_order_due_date).diff(moment(purchase_order.purchase_order_date), 'days' ) > 0) {
              purchase_order.due_status = 0
            } else if (moment(purchase_order.purchase_order_due_date).diff(moment(purchase_order.purchase_order_date), 'days' ) == 0) {
              purchase_order.due_status = 2
            } else {
              purchase_order.due_status = 3
            }
          } else {
            purchase_order.due_status = '1'
          }
          switch (purchase_order.status) {
            case 0:
                purchase_order.computedActions = [0, 1, 2];
              break;
            case 1:
                purchase_order.computedActions = [0, 1, 3];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetInvoiceReceivedByPurchaseOrder(id, limit, page, statusFilter, search) {
  return useQuery(
    ['invoice-received-by-purchase-order', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return purchase_orders.getInvoiceReceivedByPurchaseOrder({ id, limit, page, statusFilter, search });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((invoice_received) => {
          //compute action items
          invoice_received.created = moment(invoice_received.created).format('MMMM D, YYYY');
          invoice_received.formatted_invoice_date = moment(invoice_received.invoice_date).format('MMMM D, YYYY');
          invoice_received.formatted_invoice_due_date = moment(invoice_received.invoice_due_date).format('MMMM D, YYYY');
          invoice_received.formatted_total_amount = formatNumber(invoice_received.total_amount, 2);
          if (invoice_received.status !== 1) {
            if (moment(invoice_received.invoice_due_date).diff(moment(invoice_received.invoice_date), 'days' ) > 0) {
              invoice_received.due_status = 0
            } else if (moment(invoice_received.invoice_due_date).diff(moment(invoice_received.invoice_date), 'days' ) == 0) {
              invoice_received.due_status = 2
            } else {
              invoice_received.due_status = 3
            }
          } else {
            invoice_received.due_status = '1'
          }
          
          switch (invoice_received.status) {
            case 0:
              invoice_received.computedActions = [0, 1, 2, 3, 4, 5];
              break;
            case 1:
              invoice_received.computedActions = [0, 2, 4];
              break;
            case 2:
              invoice_received.computedActions = [0, 1, 2, 3, 4];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetSelectPurchaseOrderByVendor(id, limit, page, statusFilter, search) {
  return useQuery(
    ['select-purchase-order-by-vendor', { id, limit, page, statusFilter, search }],
    () => {
      return purchase_orders.getPurchaseOrdersByVendor({ id, limit, page, statusFilter, search });
    },
    {
      enabled: id !== null,
      select: (data) => {
        let newData = [];
        newData.push({ value: null, label: 'No P.O. Selected' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, 
            label: item.purchase_order_number + " | " + item.total_amount.toLocaleString(), 
          });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetPurchaseOrder(id) {
  return useQuery(
    ['purchase_order', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return purchase_orders.getPurchaseOrder(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setPurchaseOrder(response));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdatePurchaseOrder() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return purchase_orders.updatePurchaseOrder(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('purchase_orders');
        queryClient.invalidateQueries('purchase_order');
        store.dispatch(setAlert(true, 'success',  'Purchase order updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendPurchaseOrder() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return purchase_orders.sendPurchaseOrder(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success',  'Purchase order sent successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCreateSinglePurchaseOrder() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return purchase_orders.createSinglePurchaseOrder(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('purchase_orders');
        queryClient.invalidateQueries('purchase_order');
        store.dispatch(setAlert(true, 'success',  'Purchase order added successfully'));
        history.push('/vendors/purchase-order?purchaseOrderId=' + response.data.id)
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMarkPurchaseOrderPaid() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return purchase_orders.markPurchaseOrderPaid(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('purchase_order');
        queryClient.invalidateQueries('purchase_orders');
        store.dispatch(setAlert(true, 'success', 'Purchase order status change successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMarkPurchaseOrderUnpaid() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return purchase_orders.markPurchaseOrderUnpaid(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('purchase_orders');
        store.dispatch(setAlert(true, 'success', 'Purchase order status change successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetPurchaseOrderPaymentHistory(
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date,
) {
  return useQuery(
    [
      'purchase-order-history',
      { limit, page, statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return purchase_orders.getInvoicePaymentHistory({
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((transaction) => {
          transaction.created = transaction.created.split('.')[0].replace('T', ' ')
          transaction.status = parseFloat(transaction.status);
          switch (transaction.status) {
            case 0:
              transaction.computedActions = [0];
              break;
            case 2:
              transaction.computedActions = [1];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useDeletePurchaseOrder() {
  return useMutation(
    (payload) => {
      return purchase_orders.deletePurchaseOrder(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('purchase-orders');
        store.dispatch(setAlert(true, 'success', 'Purchase order deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

