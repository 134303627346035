import React, { useEffect } from "react";
import { useState } from "react";
import classNames from "classnames";
import LoadingBar from "../../../loader-bar";
import Button from "../../../button";
import { useHistory, useParams } from "react-router-dom";
import {
  useGetRecruitment,
  useGetRecruitmentApplication,
  useGetRecruitmentStages,
  useMoveBulkRecruitmentApplicantStage,
} from "../../../../redux/human-resources/hook/recruitment";
import TickSVG from "../../../../assets/svg/tick.svg";
import MoveApplicant from "../../../modal/hr-section/recruitment/move-applicant";
import moment from "moment";
import SendOfferLetter from "../../../modal/hr-section/recruitment/send-offer-letter";
import VideoAssessmentModal from "../../../modal/hr-section/recruitment/video-assessment";
import ApplicantAssessmentVideo from "../../../modal/hr-section/recruitment/view-applicant-video";

const ApplicantStages = () => {
  useEffect(() => {}, []);

  const history = useHistory();
  const { id } = useParams();
  const { data: applicant } = useGetRecruitmentApplication(id);
  const [showVideoAssessment, setshowVideoAssessment] = useState(false);
  const [showApplicantVideo, setshowApplicantVideo] = useState(false);

  const [showSendOfferLetter, setShowSendOfferLetter] = useState(false);

  const gotoEmployeeOnboarding = () => {
    history.push(
      `/employees/onboarding/add-single-employee/invite??first_name=${applicant?.first_name}&last_name=${applicant?.last_name}&email=${applicant?.email}&mobile=${applicant?.mobile}&applicant_id=${id}`
    );
  };

  const [stage, setStage] = useState();

  const { mutateAsync: moveApplicant, isLoading: moveLoading } =
    useMoveBulkRecruitmentApplicantStage();

  const { data: recruitmentStages, isLoading: loading } =
    useGetRecruitmentStages(applicant?.recruitment_id);

  const { data: recruitment, isLoading: recruitmentLoading } =
    useGetRecruitment(applicant?.recruitment_id);
  const [showMoveApplicant, setShowMoveApplicant] = useState(false);

  const [currentStage, setCurrrentStage] = useState();

  useEffect(() => {
    if (recruitmentStages) {
      let curStage = "";
      for (let i = 0; i < recruitmentStages.data.length; i++) {
        if (recruitmentStages.data[i].index === applicant?.stage) {
          curStage = recruitmentStages.data[i].name;
          break;
        } else {
          continue;
        }
      }
      setCurrrentStage(curStage);
    }
  }, [recruitmentStages]);

  return (
    <>
      <div className="flex flex-col bg-white rounded">
        {showMoveApplicant ? (
          <MoveApplicant
            title={"Move Applicant"}
            isOpen={showMoveApplicant}
            closeModal={() => setShowMoveApplicant(false)}
            applicant={applicant}
            buttonText="Move Applicant"
          />
        ) : null}
        {showSendOfferLetter ? (
          <SendOfferLetter
            title={"Send Offer Letter"}
            isOpen={showSendOfferLetter}
            closeModal={() => setShowSendOfferLetter(false)}
            applicant={applicant}
            buttonText="SEND LETTER"
          />
        ) : null}
        {showVideoAssessment && applicant?.stage != 1 ? (
          <VideoAssessmentModal
            isOpen={showVideoAssessment}
            closeModal={() => setshowVideoAssessment(false)}
            stage={applicant?.stage}
            recruitment_id={applicant?.recruitment_id}
            applicant={applicant}
          />
        ) : null}
        {showApplicantVideo ? (
          <ApplicantAssessmentVideo
            isOpen={showApplicantVideo}
            closeModal={() => setshowApplicantVideo(false)}
            applicant={applicant}
          />
        ) : null}
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] mt-3">
          <div className="p3-bold header-4">Other Applicant Information</div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            {applicant?.stage < recruitmentStages?.data.length ? (
              <>
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="Advance Applicant"
                    type="button"
                    rightIcon={"forward"}
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    onClick={() => {
                      setShowMoveApplicant(true);
                    }}
                    textClass={"!text-[11px]"}
                  />
                </div>

                {applicant?.video_assessment && applicant?.stage !== 1 ? (
                  <div className="md:w-fit w-[100%]">
                    <Button
                      text="View Video Assessment"
                      type="button"
                      leftIcon={"view"}
                      className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                      onClick={() => {
                        setshowApplicantVideo(true);
                      }}
                      textClass={"!text-[11px]"}
                    />
                  </div>
                ) : applicant?.stage != 1 ? (
                  <div className="md:w-fit w-[100%]">
                    <Button
                      text="Video Assessment"
                      type="button"
                      className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                      onClick={() => {
                        setshowVideoAssessment(true);
                      }}
                      textClass={"!text-[11px]"}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="Onboard As Employee"
                    type="submit"
                    applicant={applicant}
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    leftIcon={"add"}
                    textClass={"!text-[11px]"}
                    onClick={() => {
                      gotoEmployeeOnboarding();
                    }}
                  />
                </div>
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="Send Offer Letter"
                    type="submit"
                    applicant={applicant}
                    leftIcon={"email"}
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    textClass={"!text-[11px]"}
                    onClick={() => {
                      setShowSendOfferLetter(true);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <hr className="divider" />
        {loading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
            <LoadingBar loading={loading} />
          </div>
        ) : (
          <>
            {recruitmentStages ? (
              <div className="flex flex-col md:flex-row h-full p-4 pt-1">
                <div className="flex flex-col md:max-w-[400px] w-full">
                  {moveLoading ? (
                    <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                      <LoadingBar loading={loading} />
                    </div>
                  ) : (
                    <>
                      {recruitmentStages.data.map((item, index) => (
                        <div
                          className={classNames(
                            "w-full max-w-[100%] md:max-w-[450px] h-[80px] rounded-md flex flex-row items-center px-3 justify-between cursor-pointer  border-1 mb-3 hover:bg-gray-50",
                            {
                              "bg-green-100": applicant?.stage === item.index,
                            }
                          )}
                          key={index}
                          onClick={() => {
                            let requestData = {
                              stage: item.index,
                              applicant_ids: id.toString(),
                            };
                            moveApplicant(requestData);
                          }}
                        >
                          <div className="flex flex-col">
                            <span className="text-[15px] font-bold">
                              {item.name}
                            </span>
                            <span className="text-[13px] font-normal">
                              {item.type}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <div
                              className={`flex items-center justify-center w-[40px] h-[40px] rounded-[40px] p-2 ${
                                item.index <= applicant?.stage
                                  ? "bg-[#0d5225]"
                                  : "border border-color-black"
                              }  ${
                                item.index === applicant?.stage
                                  ? "opacity-100 "
                                  : "opacity-40 "
                              }`}
                            >
                              {item.index < applicant?.stage ? (
                                <img
                                  src={TickSVG}
                                  alt="tick"
                                  style={{ width: "30px", height: "30px" }}
                                />
                              ) : (
                                <span
                                  className={`header-4 ${
                                    item.index <= applicant?.stage
                                      ? "text-white"
                                      : "text-color-black"
                                  }`}
                                >
                                  {item.index}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div className="flex flex-col gap-2 w-[100%] md:ml-4">
                  <div className="border border-color-line pr-4 pl-4 pt-4 pb-3 flex flex-col rounded-md">
                    {recruitment ? (
                      <>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full p4">
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Current Stage
                            </span>
                            <span className="mt-1 text-color-black font-bold">
                              {currentStage}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Date Added
                            </span>
                            <span className="mt-1 text-color-black font-bold">
                              {moment(applicant?.created).format(
                                "MMMM D, YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full p4 mt-[20px]">
                          <div className="flex flex-col gap-2 border p-3 !max-h-[600px] rounded-md overflow-auto">
                            <span className="text-[16px] font-bold text-color-gray mb-2">
                              Job Description
                            </span>
                            <div className="border p-3 !max-h-[600px] rounded-md overflow-auto bg-gray-50">
                              <td
                                dangerouslySetInnerHTML={{
                                  __html: recruitment?.job_description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <LoadingBar loading={recruitmentLoading} />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default ApplicantStages;
