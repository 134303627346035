import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Button from "../../../components/button";
import { formValidation } from "../../../utils/functions";
import { useSubmitOfferLetter } from "../../../redux/employees/hook";
import AuthLayout from "../../../components/layouts/auth";
import FormFileInput from "../../../components/form-file-input";
import { useGetOfferByToken } from "../../../redux/human-resources/hook/recruitment";

function SubmitOfferLetter() {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });
  const { token } = useParams();
  const { mutateAsync, isLoading: loading } = useSubmitOfferLetter();
  const { data: request, isLoading } = useGetOfferByToken(token);

  const submitForm = async (data) => {
    const formData = new FormData();
    formData.append("file", data?.file[0]);
    formData.append("token", token);

    await mutateAsync(formData).then(() => {
      window.location.assign("https://www.employees.ropay.africa/register");
    });
  };

  return (
    <AuthLayout step={1} title={"Enter your information"}>
      <div className="flex flex-col w-full h-full">
        <span className="md:header-3 header-4 text-center">
          Upload Signed Offer Letter
        </span>
        <span className="text-[13px] text-center text-color-gray">
          Upload signed offer letter for your employer
        </span>
        <hr className="divider mb-2 mt-2" />
        <form className="form mt-2" onSubmit={handleSubmit(submitForm)}>
          <div className="flex flex-col items-center justify-center p-2 rounded-md border bg-secondary-2-extralight">
            <span className="text-[16px] font-semibold text-secondary-2">
              Hello,
            </span>
            <span className={"text-[25px] font-extrabold  text-secondary-2"}>
              {request?.first_name} {request?.last_name}
            </span>
          </div>
          <hr className="divider mt-2 mb-2" />
          <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3 mb-3 p-2 bg-secondary-2-extralight rounded-md border">
            <div className="flex flex-row items-center gap-8 justify-between">
              <span className="header-5 text-color-gray">
                Offer Letter Sent:
              </span>
              <div className="flex flex-row gap-3 items-center">
                <Button
                  text="Download And Review"
                  type="button"
                  className="flex gap-2 !h-[35px] w-fit p-2 "
                  textClass={"!text-[11px]"}
                  onClick={() => {
                    window.open(
                      request?.file_url,
                      "_blank",
                      "noopener, noreferrer"
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <hr className="divider mb-2 mt-2" />
          <FormFileInput
            label={"Upload Signed Offer Letter"}
            multiSource={false}
            name={"file"}
            inputRef={register(formValidation("file", true))}
            accept=".pdf"
          />
          <div className="w-full mt-[20px]">
            <Button text="SUBMIT DOCUMENT" type="submit" loading={loading} />
          </div>
        </form>
      </div>
    </AuthLayout>
  );
}

export default SubmitOfferLetter;
