import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import { bonusesStatusConfig, PAGINATION_DEFAULT } from "../../utils/constants";
import {
  useDeleteBonus,
  useGetEmployeeBonuses,
} from "../../redux/employees/hook/bonuses";
import AddBonus from "../modal/employees/add-bonus";
import Button from "../button";

import SelectBonusOption from "../modal/employees/select-bonus-option";
import DoYouWantToModal from "../modal/do-you-want-to";
import HelpSVG from "../../assets/svg/help.svg";
import InfoModal from "../modal/info-modal";
import { useGetEmployees } from "../../redux/employees/hook";

export const EmployeeBonusTab = () => {
  useEffect(() => {}, []);
  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { refetch, data: Bonuses } = useGetEmployeeBonuses(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );
  const [showInfoModal, setShowInfoModal] = useState(false);

  const [showAddBonus, setShowAddBonus] = useState(false);
  const [showSelectBonusOptions, setShowSelectBonusOptions] = useState(false);
  const [bonusData, setBonusData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { mutateAsync: deleteBonus, isLoading: deleteLoading } =
    useDeleteBonus();

  const tableConfig = {
    headers: [
      "Employee Name",
      "Phone Number",
      "Purpose",
      "Month",
      "Amount",
      "Status",
    ],
    keys: [
      "employeeName",
      "phone",
      "purpose",
      "formatted_month",
      "formatted_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "phone",
      },
    },
  };

  const deleteSingleBonus = async (id) => {
    let index = Bonuses.data.findIndex((item) => item.id === id);
    await deleteBonus(Bonuses.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "orange",
      action: (id) => {
        let index = Bonuses.data.findIndex((item) => item.id === id);
        setButtonText("Update");
        setTitle("Update Bonus");
        setBonusData(Bonuses.data[index]);
        setShowAddBonus(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = Bonuses.data.findIndex((item) => item.id === id);
        setDeleteId(Bonuses.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  return (
    <div className="flex flex-col mt-4">
      <div className="flex items-center justify-end w-full">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={HelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      <div className="flex flex-col bg-white rounded border">
        {showDoYouWant ? (
          <DoYouWantToModal
            isOpen={showDoYouWant}
            closeModal={() => setShowDoYouWant(false)}
            id={deleteId}
            loading={deleteLoading}
            title={"Are you sure you want to delete this bonus?"}
            buttonText={"DELETE"}
            btnFunction={deleteSingleBonus}
          />
        ) : null}
        {showAddBonus ? (
          <AddBonus
            isOpen={showAddBonus}
            closeModal={() => setShowAddBonus(false)}
            bonusData={bonusData}
            title={title}
            buttonText={buttonText}
            leftIcon={"add"}
          />
        ) : null}
        {showSelectBonusOptions ? (
          <SelectBonusOption
            isOpen={showSelectBonusOptions}
            closeModal={() => setShowSelectBonusOptions(false)}
          />
        ) : null}
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"bonus"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}

        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Bonuses
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Bonus"
                type="submit"
                theme="primary"
                disabled={!employees}
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => {
                  setShowSelectBonusOptions(true);
                }}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="Refetch"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full p-2 "
                textClass={"!text-[11px]"}
                leftIcon={"change"}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={Bonuses}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={bonusesStatusConfig}
          filterByStatus={true}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};
