import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useCancelSubscription,
  useChangeAutoRenew,
  useGetCurrentPeoplePlan,
} from "../../redux/human-resources/hook/subscription";
import Button from "../button";
import FormSwitch from "../form-switch";
import { StatusText } from "../fragments/status-text";
import LoadingBar from "../loader-bar";
import RenewSubscriptionPlan from "../modal/hr-section/subscription/renew-subscription";
import UpgradeSubscriptionPlan from "../modal/hr-section/subscription/upgrade-subscription";

const CurrentHrPlan = () => {
  useEffect(() => {}, []);

  const { company } = useSelector((state) => state.companySlice);
  const [showRenewSubscriptionPlan, setShowRenewSubscriptionPlan] =
    useState(false);
  const [showUpgradeSubscriptionPlan, setShowUpgradeSubscriptionPlan] =
    useState(false);

  const [currentPlan, setCurrentPlan] = useState(null);
  const [autoRenew, setAutoRenew] = useState(false);

  const { mutateAsync: cancelSubscription, isLoading: cancelLoading } =
    useCancelSubscription();
  const { mutateAsync: autoRenewStatus } = useChangeAutoRenew();

  const { data: currentSubscriptionData, isLoading } =
    useGetCurrentPeoplePlan();
  useEffect(() => {
    if (currentSubscriptionData) {
      setAutoRenew(currentSubscriptionData.auto_renew === 1 ? true : false);
    }
  }, [currentSubscriptionData]);

  const subscriptionStatusConfig = [
    {
      label: "Expired",
      value: 0,
      color: "red",
    },
    {
      label: "Active",
      value: 1,
      color: "green",
    },
    {
      label: "Cancelled",
      value: 2,
      color: "orange",
    },
  ];

  return (
    <>
      {showRenewSubscriptionPlan ? (
        <RenewSubscriptionPlan
          currentPlan={currentPlan}
          isOpen={showRenewSubscriptionPlan}
          closeModal={() => setShowRenewSubscriptionPlan(false)}
        />
      ) : null}
      {showUpgradeSubscriptionPlan ? (
        <UpgradeSubscriptionPlan
          currentPlan={currentPlan}
          isOpen={showUpgradeSubscriptionPlan}
          closeModal={() => setShowUpgradeSubscriptionPlan(false)}
        />
      ) : null}
      {company ? (
        <div className="flex flex-col bg-white w-[100%] h-full min-h-fit pb-4 ">
          {!isLoading ? (
            <div className="flex flex-col gap-3">
              {currentSubscriptionData &&
              currentSubscriptionData?.subscription_plan !== "Free Trial" ? (
                <>
                  <div className="flex flex-row gap-3 justify-end md:items-center items-end">
                    <div className="md:w-fit w-[100%]">
                      <Button
                        text="Upgrade Subscription"
                        type="button"
                        theme="primary"
                        className="flex gap-2 !h-[35px] w-fit p-2 "
                        textClass={"!text-[11px]"}
                        onClick={() => {
                          setCurrentPlan(currentSubscriptionData);
                          setShowUpgradeSubscriptionPlan(true);
                        }}
                      />
                    </div>
                    <div className="md:w-fit w-[100%]">
                      <Button
                        text="Cancel Subscription"
                        type="button"
                        theme="secondary"
                        loading={cancelLoading}
                        className="flex gap-2 !h-[35px] w-fit p-2 "
                        textClass={"!text-[11px]"}
                        onClick={() => {
                          cancelSubscription();
                        }}
                      />
                    </div>
                    <div className="md:w-fit w-[100%]">
                      <Button
                        text="Renew Subscription"
                        type="button"
                        theme="primary"
                        className="flex gap-2 !h-[35px] w-fit p-2 "
                        textClass={"!text-[11px]"}
                        onClick={() => {
                          setCurrentPlan(currentSubscriptionData);
                          setShowRenewSubscriptionPlan(true);
                        }}
                      />
                    </div>
                  </div>
                  <hr className="divider mt-2 mb-2" />
                </>
              ) : null}
              <div className="flex w-full flex-col border p-4 bg-highlight rounded-md relative">
                <div className="flex flex-row items-center absolute top-4 right-4">
                  <StatusText
                    statusConfig={subscriptionStatusConfig}
                    code={currentSubscriptionData?.status}
                    className={"py-2 rounded p1-bold px-3 p2-bold !text-[15px]"}
                  />
                </div>
                <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Package Name
                    </span>
                    <span className="mt-1 text-color-black text-[14px] font-semibold">
                      {currentSubscriptionData?.subscription_plan}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">
                      Billing Cycle
                    </span>
                    <span className="mt-1 text-color-black text-[14px] font-semibold">
                      {currentSubscriptionData?.billing_cycle}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">Start Date</span>
                    <span className="mt-1 text-color-black text-[14px] font-semibold">
                      {moment(currentSubscriptionData?.start_date).format(
                        "MMMM D, YYYY"
                      )}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="header-5 text-color-gray">End Date</span>
                    <span className="mt-1 text-color-black text-[14px] font-semibold">
                      {moment(currentSubscriptionData?.end_date).format(
                        "MMMM D, YYYY"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default CurrentHrPlan;
