import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import {
  PAGINATION_DEFAULT,
  salaryAdvanceStatusConfig,
} from "../../utils/constants";
import AddSalaryAdvance from "../modal/employees/add-salary-advance";
import {
  useDeleteSalaryAdvance,
  useGetEmployeeSalaryAdvance,
} from "../../redux/employees/hook/salary-advance";
import Button from "../button";
import SalaryAdvanceApplications from "../modal/employees/salary-advance-applications";
import SelectSalaryAdvanceOption from "../modal/employees/select-salary-advance-option";
import DoYouWantToModal from "../modal/do-you-want-to";
import HelpSVG from "../../assets/svg/help.svg";
import InfoModal from "../modal/info-modal";
import SalaryAdvanceSummary from "../modal/employees/salary-advance-summary";
import { useGetEmployees } from "../../redux/employees/hook";

const EmployeeSalaryAdvanceTab = () => {
  useEffect(() => {}, []);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { refetch, data: employeeAdvance } = useGetEmployeeSalaryAdvance(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [showInfoModal, setShowInfoModal] = useState(false);

  const { mutateAsync: deleteSalaryAdvance, isLoading: deleteLoading } =
    useDeleteSalaryAdvance();

  const [showSalaryAdvanceApplications, setShowSalaryAdvanceApplications] =
    useState(false);
  const [showSelectSalaryAdvanceOptions, setShowSelectSalaryAdvanceOptions] =
    useState(false);
  const [saladData, setSaladData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState("Add Salary Advance");
  const [showAddSalaryAdvance, setShowAddSalaryAdvance] = useState(false);

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [showSummary, setShowSummary] = useState(false);

  const tableConfig = {
    headers: [
      "Employee Name",
      "Phone number",
      "Purpose",
      "Month",
      "Amount",
      "Status",
    ],
    keys: [
      "employeeName",
      "phone",
      "purpose",
      "formatted_month",
      "formatted_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };
  const deleteSingleSalaryAdvance = async (id) => {
    let index = employeeAdvance.data.findIndex((item) => item.id === id);
    await deleteSalaryAdvance(employeeAdvance.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const actionConfig = [
    {
      name: "Edit/View",
      color: "green",
      action: (id) => {
        let index = employeeAdvance.data.findIndex((item) => item.id === id);
        setButtonText("Update");
        setTitle("Update Salary Advance");
        setSaladData(employeeAdvance.data[index]);
        setShowAddSalaryAdvance(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = employeeAdvance.data.findIndex((item) => item.id === id);
        setDeleteId(employeeAdvance.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  return (
    <div className="flex flex-col mt-4">
      <div className="flex items-center justify-end w-full">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={HelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      <div className="flex flex-col bg-white rounded border">
        {showDoYouWant ? (
          <DoYouWantToModal
            isOpen={showDoYouWant}
            closeModal={() => setShowDoYouWant(false)}
            id={deleteId}
            loading={deleteLoading}
            title={"Are you sure you want to delete this Salary Advance?"}
            buttonText={"DELETE"}
            btnFunction={deleteSingleSalaryAdvance}
          />
        ) : null}
        {showSalaryAdvanceApplications ? (
          <SalaryAdvanceApplications
            isOpen={showSalaryAdvanceApplications}
            closeModal={() => setShowSalaryAdvanceApplications(false)}
          />
        ) : null}
        {showAddSalaryAdvance ? (
          <AddSalaryAdvance
            isOpen={showAddSalaryAdvance}
            closeModal={() => setShowAddSalaryAdvance(false)}
            saladData={saladData}
            title={title}
            buttonText={buttonText}
          />
        ) : null}
        {showSelectSalaryAdvanceOptions ? (
          <SelectSalaryAdvanceOption
            isOpen={showSelectSalaryAdvanceOptions}
            closeModal={() => setShowSelectSalaryAdvanceOptions(false)}
          />
        ) : null}
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"salary-advance"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}
        {showSummary ? (
          <SalaryAdvanceSummary
            isOpen={showSummary}
            closeModal={() => setShowSummary(false)}
          />
        ) : null}

        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[25%] w-full">
            Salary Advances/Loans
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[75%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Salary Advance"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                disabled={!employees}
                onClick={() => {
                  setShowSelectSalaryAdvanceOptions(true);
                }}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="View Applications"
                type="button"
                leftIcon={"view"}
                theme={"primary"}
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                disabled={!employees}
                onClick={() => setShowSalaryAdvanceApplications(true)}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="Quick Summary"
                type="button"
                leftIcon={"view"}
                theme={"primary"}
                disabled={!employees}
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                onClick={() => setShowSummary(true)}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="Refetch"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"change"}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={employeeAdvance}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={salaryAdvanceStatusConfig}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          filterByStatus={true}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default EmployeeSalaryAdvanceTab;
