import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import FormInput from '../../form-input';
import Modal from '../modal';
import { Editor } from '@tinymce/tinymce-react';
import FormFileInput from '../../form-file-input';
import FormSwitch from '../../form-switch';
import { formValidation } from '../../../utils/functions';
import { useAddTicket } from '../../../redux/contact-us/hook';
import DOMPurify from 'dompurify';

function AddSupportTicket({ isOpen, closeModal, refresh }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
  const [body, setBody] = useState();

  const [uploadFile, setUploadFile] = useState(false);

  const { mutateAsync: createTicket, isLoading: createTicketLoading } =
    useAddTicket();

  const submitForm = async (data) => {
    const formData = new FormData();
    if (uploadFile) {
      formData.append('file', data?.file[0]);
    } else {
      formData.append('file', null);
    }
    formData.append('subject', DOMPurify.sanitize(data?.subject));
    formData.append('message', body.toString());
    formData.append('upload_file', uploadFile ? 1 : 0);
    formData.append('filename', DOMPurify.sanitize(data?.filename));

    await createTicket(formData).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col pb-4 overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>Add Support Ticket</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col mx-8'>
          <form className='form' onSubmit={handleSubmit(submitForm)}>
            <FormInput
              label='Subject'
              name='subject'
              type='text'
              inputRef={register(formValidation('text', true))}
              placeholder={'Enter support ticket subject'}
              error={errors.subject}
              errorMessage={errors.subject && errors.subject.message}
            />
            <div className='flex md:flex-row flex-col w-full justify-between mb-2'>
              <div className='w-full'>
                <span className='text-[13px] text-gray-500'>Feedback</span>
                <Editor
                  apiKey={import.meta.env.VITE_APP_TINYMCE_API_KEY}
                  onEditorChange={(editor) => {
                    setBody(editor);
                  }}
                  placeholder='Enter Email body here'
                  value={body}
                  init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
                  }}
                />
              </div>
            </div>
            <hr className='divider mt-2 ' />
            <div className='flex flex-row justify-between items-center md:w-[100%] w-full'>
              <div className='w-full md:w-[100%] text-[14px] -mb-3'>
                <FormSwitch
                  label={'Do you want to upload document?'}
                  name={'upload_file'}
                  checked={uploadFile}
                  onClick={() => {
                    setUploadFile(!uploadFile);
                  }}
                />
              </div>
            </div>
            {uploadFile ? (
              <>
                <FormInput
                  label='File Name'
                  name='filename'
                  type='text'
                  inputRef={register(formValidation('text', true))}
                  placeholder={'Enter file name'}
                  error={errors.filename}
                  errorMessage={errors.filename && errors.filename.message}
                />
                <FormFileInput
                  multiSource={false}
                  name={'file'}
                  inputRef={register(formValidation('file', true))}
                  accept='.pdf,.jpg,.png,.jpeg'
                />
              </>
            ) : null}
            <hr className='divider mt-2 ' />
            <div className='w-full mt-[20px]'>
              <Button
                text={'SUBMIT TICKET'}
                type='submit'
                loading={createTicketLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default AddSupportTicket;
