import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import {
  useDeleteSuspension,
  useGetEmployeeSuspensions,
} from "../../redux/employees/hook/suspensions";
import AddSuspension from "../modal/employees/add-suspension";
import Button from "../button";
import DoYouWantToModal from "../modal/do-you-want-to";
import { useGetEmployees } from "../../redux/employees/hook";

const EmployeeSuspensionsTab = () => {
  useEffect(() => {}, []);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { mutateAsync: deleteSuspension, isLoading: deleteLoading } =
    useDeleteSuspension();

  const [showAddSuspension, setShowAddSuspension] = useState(false);
  const [suspData, setSuspData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { data: employee_suspensions } = useGetEmployeeSuspensions(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const deleteSingleSuspension = async (id) => {
    let index = employee_suspensions.data.findIndex((item) => item.id === id);
    await deleteSuspension(employee_suspensions.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const tableConfig = {
    // title: 'Suspension Management',
    headers: [
      "Employee Name",
      "Purpose",
      "Start Date",
      "End Date",
      "Payment Status",
    ],
    keys: [
      "employeeName",
      "purpose",
      "formatted_start_date",
      "formatted_end_date",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Payment Status",
        key: "status",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "orange",
      action: (id) => {
        let index = employee_suspensions.data.findIndex(
          (item) => item.id === id
        );
        setButtonText("Update Suspension");
        setTitle("Update Employee Suspension");
        setSuspData(employee_suspensions.data[index]);
        setShowAddSuspension(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = employee_suspensions.data.findIndex(
          (item) => item.id === id
        );
        setDeleteId(employee_suspensions.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  const statusConfig = [
    {
      label: "Without Pay",
      value: 0,
      color: "orange",
    },
    {
      label: "With Pay",
      value: 1,
      color: "green",
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded mt-4 border">
      {showDoYouWant ? (
        <DoYouWantToModal
          isOpen={showDoYouWant}
          closeModal={() => setShowDoYouWant(false)}
          id={deleteId}
          loading={deleteLoading}
          title={"Are you sure you want to delete this suspension?"}
          buttonText={"DELETE"}
          btnFunction={deleteSingleSuspension}
        />
      ) : null}
      {showAddSuspension ? (
        <AddSuspension
          isOpen={showAddSuspension}
          closeModal={() => {
            setShowAddSuspension(false);
          }}
          suspData={suspData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Suspensions
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Suspend Employee"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"suspension"}
              disabled={!employees}
              onClick={() => {
                setSuspData(null);
                setButtonText("Save Suspension");
                setTitle("Suspend Employee");
                setShowAddSuspension(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={employee_suspensions}
        tableConfig={tableConfig}
        statusConfig={statusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        computedActions={true}
        searchData={true}
        noCheck={true}
      />
    </div>
  );
};

export default EmployeeSuspensionsTab;
