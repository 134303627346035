import React from 'react';
import Logo from '../logo';
import HRAuthLogo from '../../assets/svg/login-4.svg';
import OtherAuthLogo from '../../assets/svg/other_auth_image.svg';
import ForgotLogo from '../../assets/svg/forgot-password.svg';
import ForgotSpLogo from '../../assets/svg/forgot-password-ps.svg';
import pppLogo from '../../assets/img/ppp-white.png';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const OldAuthLayout = ({ children }) => {
  const { section } = useSelector((state) => state.sectionSlice);
  const location = useLocation();

  return (
    <div className='flex flex-row'>
      <div className='hidden md:flex md:w-[40%] position-fixed'>
        <div
          className={
            section && section.name === 'core hr'
              ? 'hidden md:flex h-screen inset-0 w-full radial-gradient-hr justify-center items-center max-h-screen'
              : section && section.name === 'spend management'
              ? 'hidden md:flex h-screen inset-0 w-full radial-gradient-spend justify-center items-center max-h-screen'
              : 'hidden md:flex h-screen inset-0 w-full radial-gradient justify-center items-center max-h-screen'
          }
        >
          <div className='flex justify-center align-center flex-col w-[65%] max-w-[400px]'>
            <div className='mb-4'>
              <Logo white />
            </div>
            <div>
              {location.pathname === '/forgot' ? (
                <>
                  {section && section.name === 'core hr' ? (
                    <img src={ForgotLogo} alt='HR' />
                  ) : (
                    <img src={ForgotSpLogo} alt='Payroll/Spend' />
                  )}
                </>
              ) : (
                <>
                  {section && section.name === 'core hr' ? (
                    <img src={HRAuthLogo} alt='HR' />
                  ) : (
                    <img src={OtherAuthLogo} alt='Payroll/Spend' />
                  )}
                </>
              )}
            </div>
            <div className='flex flew-row justify-center align-center mt-4'>
              <img
                src={pppLogo}
                alt='payroll, people, payments'
                style={{ width: '80%', height: '25px' }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Page Components */}
      <div
        className='md:w-[100%] w-[100%] flex items-center 
        justify-center
        min-h-screen
        md:ml-[40%]
        pb-4
        pt-4'
      >
        <div className='w-[80%] max-w-[480px]'>
          <div className='flex flex-col'>
            <div className='md:hidden flex justify-center'>
              <Logo width={'170px'} height={'76px'} />
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OldAuthLayout;
