import { Axios } from '../axios';

const getRecurringInvoices = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get( `/recurring-invoices?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  return data?.data;
};

const getRecurringInvoicesByCustomer = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get( `/recurring-invoices/by-customer/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  return data?.data;
};

const updateRecurringInvoice = async (payload) => {

  const { data } = await Axios.put( '/recurring-invoices/' + payload.id, payload.data, );
  return data;
};

const getRecurringInvoice= async (payload) => {
  const { data } = await Axios.get('/recurring-invoices/' + payload);
  return data?.data;
};

const createSingleRecurringInvoice = async (payload) => {
  const { data } = await Axios.post('/recurring-invoices', payload);
  return data;
};

const markRecurringInvoice = async (payload) => {
  const { data } = await Axios.post(`/recurring-invoices/mark-template/${payload}`);
  return data;
};

const deleteRecurringInvoice = async (payload) => {
    const { data } = await Axios.delete('recurring-invoices/'+payload);
    return data;
};

export const recurring_invoices = {
    getRecurringInvoices,
    getRecurringInvoicesByCustomer,
    updateRecurringInvoice,
    getRecurringInvoice,
    createSingleRecurringInvoice,
    markRecurringInvoice,
    deleteRecurringInvoice,
};