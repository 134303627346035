import React, { useEffect, useState } from 'react';
import OldAuthLayout from '../../../components/layouts/old-auth';
import FormInput from '../../../components/form-input';
import { useForm } from 'react-hook-form';
import FormSelect from '../../../components/form-select';
import Button from '../../../components/button';
import FormFileInput from '../../../components/form-file-input';
import { formValidation } from '../../../utils/functions';
import { useGuarantorOnboarding } from '../../../redux/human-resources/hook/guarantors';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';
import { countriesAndState, AllCountries } from '../../../constants/countries';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/components/components-slice';

function OnboardGuarantorPage() {
  const [country, setCountry] = useState('Nigeria');
  const [state, setState] = useState('Lagos');
  const [stateOptions, setStateOptions] = useState([]);
  const [gender, setGender] = useState('Male');

  const { mutateAsync: onboardGuarantor, isLoading } = useGuarantorOnboarding();

  const { token } = useParams();

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const genderOptions = [
    {
      label: 'Male',
      value: 'Male',
    },
    {
      label: 'Female',
      value: 'female',
    },
  ];

  useEffect(() => {
    if (country) {
      let getStates = countriesAndState.find((a) => a.name === country);

      if (getStates) {
        let computedStates = [];

        getStates.states.map((item, index) => {
          computedStates.push({ label: item, value: item });
        });

        setStateOptions(computedStates);
      }
    }
  }, [country]);
  const dispatch = useDispatch();

  async function submitForm(data) {
    const formData = new FormData();
    if (data?.file[0].size / (1024 * 1024) < 2) {
      const name = DOMPurify.sanitize(data?.guarantor_name);
      const email = DOMPurify.sanitize(data?.email);
      const mobile = DOMPurify.sanitize(data?.mobile);
      const occupation = DOMPurify.sanitize(data?.occupation);
      const city = DOMPurify.sanitize(data?.city);
      const home_address = DOMPurify.sanitize(data?.home_address);
      const company_name = DOMPurify.sanitize(data?.company_name);
      const work_address = DOMPurify.sanitize(data?.work_address);
      const guarantor = DOMPurify.sanitize(data?.guarantor_company_name);
      const id_number = DOMPurify.sanitize(data?.id_number);

      formData.append('file', data?.file[0]);
      formData.append('name', name);
      formData.append('email', email);
      formData.append('mobile', mobile);
      formData.append('occupation', occupation);
      formData.append('gender', gender);
      formData.append('country', country);
      formData.append('state', state);
      formData.append('city', city);
      formData.append('country', country);
      formData.append('guarantor_company_name', guarantor);
      formData.append('home_address', home_address);
      formData.append('company_name', company_name);
      formData.append('work_address', work_address);
      formData.append('id_number', id_number);
      formData.append('token', token);

      await onboardGuarantor(formData).then(() => {
        window.location.replace('https://www.ropay.africa');
      });
    } else {
      dispatch(
        setAlert(
          true,
          'warning',
          'File too large. File should not be larger than 2mb',
        ),
      );
    }
  }

  return (
    <OldAuthLayout>
      <div className='flex flex-col'>
        <div className='w-full text-center flex flex-col pb-[25px]'>
          <span
            className={'header-3-regular md:header-2 text-secondary-2 pb-1'}
          >
            Guarantor Information
          </span>
          <span className='header-5-regular text-color-gray pt-1'>
            Fill your information below
          </span>
        </div>
        <form className='form' enctype='multipart/form-data'>
          <FormInput
            label='Guarantor Name'
            name='guarantor_name'
            type='text'
            inputRef={register(formValidation('text', true))}
            error={errors.guarantor_name}
            errorMessage={
              errors.guarantor_name && errors.guarantor_name.message
            }
          />
          <FormInput
            label='Email'
            name='email'
            type='email'
            inputRef={register(formValidation('email', true))}
            error={errors.email}
            errorMessage={errors.email && errors.email.message}
          />
          <FormInput
            label='Mobile'
            name='mobile'
            type='text'
            inputRef={register(formValidation('text', true))}
            error={errors.mobile}
            errorMessage={errors.mobile && errors.mobile.message}
          />
          <FormInput
            label='Occupation'
            name='occupation'
            type='text'
            inputRef={register(formValidation('text', true))}
            error={errors.occupation}
            errorMessage={errors.occupation && errors.occupation.message}
          />
          <div className='flex md:flex-row flex-col w-full justify-between'>
            <div className='w-full md:w-1/2'>
              <FormSelect
                value={gender}
                options={genderOptions}
                onChange={(selected) => {
                  setGender(selected);
                }}
                label='Gender'
              />
            </div>
            <div className='w-full md:w-1/2 pl-2'>
              <FormSelect
                defaultValue={country}
                options={AllCountries}
                onChange={(selected) => {
                  setCountry(selected);
                }}
                label='Country'
              />
            </div>
          </div>
          <div className='flex md:flex-row flex-col w-full justify-between'>
            <div className='w-full md:w-1/2'>
              {country == 'Nigeria' ? (
                <FormSelect
                  defaultValue={state}
                  options={stateOptions}
                  onChange={(selected) => {
                    setState(selected);
                  }}
                  label='State'
                />
              ) : (
                <FormInput
                  label='State'
                  name='state'
                  type='text'
                  value={state}
                  onInput={(e) => setState(e.target.value)}
                  error={errors.state}
                  errorMessage={errors.state && errors.state.message}
                />
              )}
            </div>
            <div className='w-full md:w-1/2 pl-2 pt-1'>
              <FormInput
                label='City'
                name='city'
                type='text'
                inputRef={register(formValidation('text', true))}
                error={errors.city}
                errorMessage={errors.city && errors.city.message}
              />
            </div>
          </div>
          <FormInput
            label='Home Address'
            name='home_address'
            type='text'
            inputRef={register(formValidation('text', true))}
            error={errors.home_address}
            errorMessage={errors.home_address && errors.home_address.message}
          />
          <hr className='divider' />
          <FormInput
            label='Guarantor Company Name'
            name='guarantor_company_name'
            type='text'
            inputRef={register(formValidation('text', true))}
            error={errors.guarantor_company_name}
            errorMessage={
              errors.guarantor_company_name &&
              errors.guarantor_company_name.message
            }
          />
          <FormInput
            label='Full Work Address'
            name='work_address'
            type='text'
            inputRef={register(formValidation('text', true))}
            error={errors.work_address}
            errorMessage={errors.work_address && errors.work_address.message}
          />

          <FormInput
            label='ID Number'
            name='id_number'
            type='text'
            inputRef={register(formValidation('text', true))}
            error={errors.id_number}
            errorMessage={errors.id_number && errors.id_number.message}
          />

          <div className='flex flex-col text-center justify-center items-center border-1 p-3 rounded mt-3'>
            <span className='header-5 -mb-4'>
              Upload Guarantor Means of identification (Max Size: 2mb)
            </span>
            <FormFileInput
              multiSource={true}
              inputRef={register(formValidation('file', true))}
              accept='.pdf, .jpg, .png, .jpeg'
            />
          </div>
          <div className='w-full mt-[20px]'>
            <Button
              text={'SUBMIT'}
              type='button'
              loading={isLoading}
              onClick={handleSubmit(submitForm)}
            />
          </div>
        </form>
      </div>
    </OldAuthLayout>
  );
}

export default OnboardGuarantorPage;
