import { Axios } from '../axios';

const getRecrutimentEmailTemplates = async (payload) => {
    const { data } = await Axios.get( `/recruitment-email-templates`,payload);
    return data?.data;
};

const addRecrutimentEmailTemplate = async (payload) => {
    const { data } = await Axios.post('/recruitment-email-templates', payload);
    return data;
};
const updateRecrutimentEmailTemplate = async (payload) => {
    const { data } = await Axios.put(`/recruitment-email-templates/${payload.id}`, payload.data);
    return data;
};

const deleteRecrutimentEmailTemplate= async (payload) => {
    const { data } = await Axios.delete(`/recruitment-email-templates/${payload}`,);
    return data;
};

export const recruitment_templates = {
    getRecrutimentEmailTemplates,
    addRecrutimentEmailTemplate,
    updateRecrutimentEmailTemplate,
    deleteRecrutimentEmailTemplate,
};