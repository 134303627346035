import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormSelect from "../../../components/form-select";
import EmployeeRecruitmentLayout from "../../../components/layouts/employee-recruitment";
import RemoveSVG from "../../../assets/svg/remove.svg";
import FormInput from "../../../components/form-input";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setAssignWorkflow } from "../../../redux/human-resources/reducers/recruitment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddSmallSVG from "../../../assets/svg/add-icon-small.svg";
import { store } from "../../../redux";
import { setAlert } from "../../../redux/components/components-slice";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  paddingRight: 16,
  paddingLeft: 16,
  margin: `0 0 12px 0`,
  // borderRadius: '5px',
  // border: '1px solid #E5E4E2',

  // change background colour if dragging
  background: "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: "whitesmoke",
  padding: grid,
  width: "100%",
});

function RecruitmentQuestionsPage() {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  // const [currentQuestionIndex, setCurrentQuestionIndex] = useState();

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(questions[sInd], source.index, destination.index);
      const newState = [...questions];
      newState[sInd] = items;
      setQuestions(newState);
    } else {
      const result = move(
        questions[sInd],
        questions[dInd],
        source,
        destination
      );
      const newState = [...questions];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setQuestions(newState.filter((group) => group.length));
    }
  }

  useEffect(() => {}, []);

  const { assignWorkflowData } = useSelector((state) => state.recruitmentSlice);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [payLoad, setPayload] = useState({});
  const [oldQuestions, setOldQuestions] = useState({});

  const history = useHistory();

  useEffect(() => {
    if (assignWorkflowData) {
      if (
        assignWorkflowData.questions &&
        assignWorkflowData.questions.length > 0
      ) {
        setQuestions([
          JSON.parse(JSON.stringify(assignWorkflowData.questions)),
        ]);
        setOldQuestions([
          JSON.parse(JSON.stringify(assignWorkflowData.questions)),
        ]);
      }
      setPayload(JSON.parse(JSON.stringify(assignWorkflowData)));
    }
  }, [assignWorkflowData]);

  const [questions, setQuestions] = useState([
    [
      {
        id: `item-${1}`,
        question: "",
        question_type: "multiple_choice",
        options: [{ option: "" }, { option: "" }],
      },
    ],
  ]);

  const QuestionTypes = [
    { label: "Multiple Choice", value: "multiple_choice" },
    { label: "Open Ended", value: "open_ended" },
  ];

  const [maxId, setMaxId] = useState(1);

  const addQuestion = () => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    if (newArray.length < 15) {
      newArray = [
        ...newArray,
        ...[
          {
            id: `item-${maxId + 1}`,
            question: "",
            question_type: "multiple_choice",
            options: [{ option: "" }, { option: "" }],
          },
        ],
      ];
      setMaxId(maxId + 1);
      setQuestions([newArray]);
    }
  };

  const addOption = (index) => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    let data = [...newArray];
    if (data[index].options.length < 4) {
      // only splice array when item is found
      data[index].options.push({ option: "" }); // 2nd parameter means remove one item only
      setQuestions([[...data]]);
    }
  };

  const removeQuestion = (subIndex) => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    let data = [...newArray];
    if (subIndex > -1) {
      // only splice array when item is found
      data.splice(subIndex, 1); // 2nd parameter means remove one item only
      setQuestions([[...data]]);
    }
  };

  const removeOption = (subIndex, optionIndex) => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    let data = [...newArray];
    if (data[subIndex].options.length > 2) {
      if (subIndex > -1 && optionIndex > -1) {
        // only splice array when item is found
        data[subIndex].options.splice(optionIndex, 1); // 2nd parameter means remove one item only
        setQuestions([[...data]]);
      }
    } else {
      store.dispatch(
        setAlert(true, "error", "There must be at least 2 options")
      );
    }
  };

  const submitForm = async () => {
    setLoading(true);
    let newQuestions = [];
    questions[0].forEach((item, index) => {
      if (item.question !== null && item.question !== "") {
        if (item.question_type === "open_ended") {
          item.options = [];
          newQuestions.push(item);
        } else {
          if (item.options.length > 1) {
            newQuestions.push(item);
          }
        }
      }
    });
    if (newQuestions.length > 0) {
      let newData = { ...payLoad, questions: newQuestions };
      dispatch(setAssignWorkflow(newData));
      setLoading(false);
      history.push("/recruitment/summary");
    } else {
      store.dispatch(
        setAlert(
          true,
          "error",
          "You have not added any question. You have to add at least one question, else you can skip this step."
        )
      );
      setLoading(false);
    }
  };

  const skipToSummary = () => {
    setLoading(true);

    let newData = { ...payLoad, questions: [] };
    setLoading(false);
    history.push("/recruitment/summary", newData);
  };

  return (
    <EmployeeRecruitmentLayout step={4} title={"Assign Recruitment Workflow"}>
      <div className="flex flex-col w-full h-fit">
        <div className="hidden md:flex flex-col">
          <span className="header-4">Add Recruitment Questions</span>
          <span className="p3 text-[13px] text-color-gray">
            Add Recruitment Questions. Drag and Drop questions to change order.
          </span>
        </div>
        <hr className="divider md:mt-2 -mt-4" />
        <div
          className="flex justify-end w-full cursor-pointer hover:underline -mt-2"
          onClick={() => skipToSummary()}
        >
          <span className="p4-bold text-primary-3 italic">Skip to summary</span>
        </div>

        <div className="mt-3">
          <div className="flex rounded-[5px]">
            <DragDropContext onDragEnd={onDragEnd}>
              {questions?.map((el, ind) => (
                <Droppable key={ind} droppableId={`${ind}`}>
                  {(provided, snapshot) => (
                    <div
                      className="p-4 "
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {el.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className="border-r-4 border-r-primary-3 rounded-[8px] pt-2"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="flex flex-row justify-between items-center">
                                <span className="font-bold italic text-primary-3">
                                  Question {index + 1}
                                </span>
                                <div
                                  className="text-red-500 text-[12px] hover:font-semibold cursor-pointer hover:italic"
                                  onClick={() => {
                                    removeQuestion(index);
                                  }}
                                >
                                  Remove
                                </div>
                              </div>

                              <div className="flex md:flex-row flex-col justify-between md:items-center !w-[100%]">
                                <div className="flex flex-row items-center justify-start gap-3 md:w-2/3 w-full">
                                  <FormInput
                                    name={index + "_question"}
                                    placeholder={"Enter Question?"}
                                    type="text"
                                    value={item.question}
                                    onInput={(e) => {
                                      let newArray = JSON.parse(
                                        JSON.stringify(questions[ind])
                                      );

                                      newArray[index].question = e.target.value;
                                      setQuestions([newArray]);
                                    }}
                                    className="!h-[30px] md:w-[120%] w-[150%] text-[13px] border-1 border-gray-300"
                                  />
                                </div>
                                <div className="flex flex-row md:justify-end items-center gap-3 md:w-1/3 w-full">
                                  <FormSelect
                                    value={item.question_type}
                                    options={QuestionTypes}
                                    name={item.question_type}
                                    onChange={(selected) => {
                                      let newArray = JSON.parse(
                                        JSON.stringify(questions[ind])
                                      );
                                      newArray[index].question_type = selected;
                                      setQuestions([newArray]);
                                    }}
                                    className="!min-h-[32px] border-1 mt-1 !min-w-[100%] rounded text-[13px]"
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col items-start justify-start w-full">
                                {item.question_type === "multiple_choice" ? (
                                  <div className="flex flex-col w-full">
                                    {item.options && item.options.length ? (
                                      <span className="italic text-hr-primary-1 mb-3 text-[12px]">
                                        Options
                                      </span>
                                    ) : null}
                                    {item.options &&
                                      item.options.length > 0 &&
                                      item.options.map((subItem, subIndex) => (
                                        <div
                                          className="flex flex-row justify-start items-center"
                                          key={subIndex}
                                        >
                                          <div className="flex flex-row items-center -mt-4">
                                            <span className="italic mr-4 text-[15px] font-bold">
                                              {subIndex + 1}.
                                            </span>
                                            <FormInput
                                              name={subIndex + "_option"}
                                              placeholder={"Enter Option"}
                                              type="text"
                                              value={subItem.option}
                                              onInput={(e) => {
                                                let newArray = JSON.parse(
                                                  JSON.stringify(questions[0])
                                                );
                                                newArray[index].options[
                                                  subIndex
                                                ].option = e.target.value;
                                                setQuestions([newArray]);
                                              }}
                                              className="!h-[30px] w-[250px] text-[13px] border-1 border-gray-300"
                                            />
                                            <img
                                              src={RemoveSVG}
                                              alt={"remove"}
                                              className={
                                                "h-[20px] w-[20px] ml-3 cursor-pointer"
                                              }
                                              onClick={() => {
                                                removeOption(index, subIndex);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    {item.options.length < 4 ? (
                                      <span
                                        className="text-[13px] italic text-[#0d5225] cursor-pointer hover:underline hover:text-color-gray duration-75 mb-2"
                                        onClick={() => addOption(index)}
                                      >
                                        Add Option (Max. 4)
                                      </span>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="mb-2"></div>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext>
          </div>
          <div className="flex flex-row items-center gap-2 mt-3 justify-left w-full cursor-pointer">
            <img
              src={AddSmallSVG}
              alt={"add_question"}
              className={"h-[15px] w-[15px]"}
            />
            <span
              className="p4-medium hover:underline hover:italic hover:font-bold text-primary-3 duration-75"
              onClick={() => addQuestion()}
            >
              Add New Question (Max. 15)
            </span>
          </div>
          <hr className="divider mt-3 " />
          <div className="w-full mt-[20px]">
            <Button
              text="Save and Continue"
              type="submit"
              loading={loading}
              className="!h-[62px]"
              onClick={() => submitForm()}
            />
          </div>

          <div
            className="flex justify-center w-full cursor-pointer"
            onClick={() => history.goBack()}
          >
            <span className="p2-medium text-color-black mt-3">Go Back</span>
          </div>
        </div>
      </div>
    </EmployeeRecruitmentLayout>
  );
}

export default RecruitmentQuestionsPage;
