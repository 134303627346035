import { useQuery } from "@tanstack/react-query";
import { admin } from "../../api";
import { store } from "..";
import { setIsLoading } from "../components/components-slice";

export function useGetAdmins() {
    return useQuery(
      ['admins'],
      () => {
        return admin.getAdmins();
      },
      {
        select: (data) => {
          let newData = [];
  
          data?.data?.forEach((item) => {
            newData.push({ value: item.id, label: `${item.first_name} ${item.last_name}`});
          });
  
          return newData;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
  }
  
