import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import {
  useCalculateEmployeeSubscription,
  useEmployeeSubscribe,
} from '../../../../redux/human-resources/hook/subscription';
import LoadingBar from '../../../loader-bar';
import Modal from '../../modal';
import { formatNumber } from '../../../../utils/functions';

function AddEmployeeToHrSubscription({ isOpen, closeModal, employeeHrData }) {

  const {
    data: calculateEmployeeSubscription,
    isLoading: calculateEmployeeSubscriptionLoading,
  } = useCalculateEmployeeSubscription(employeeHrData);
  const { mutateAsync: subscribe, isLoading: subscribeLoading } =
    useEmployeeSubscribe();

  const submitForm = async () => {
    await subscribe(employeeHrData).then(() => {
      closeModal();
    });
  };

  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form'>
            <div className='pl-8 pr-8 pt-4'>
              <div className='flex flex-row justify-between items-center'>
                <span className='header-4'>Renew Subscription Plan</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='close' />
                </div>
              </div>
            </div>
            <hr className='divider' />
            {calculateEmployeeSubscriptionLoading ? (
              <LoadingBar loading={calculateEmployeeSubscriptionLoading} />
            ) : (
              <div className='flex flex-col mx-8'>
                <div className='flex flex-col mx-8 items-center p-2'>
                  <span className='p4 text-center'>DAYS CALCULATED</span>
                  <span className=' header-4'>
                    {calculateEmployeeSubscription?.total_days}
                  </span>
                </div>
                <hr className='divider' />
                <div className='flex flex-col mx-8 items-center p-2'>
                  <span className='p4 text-center'>
                    {'CALCULATED UNIT EMPLOYEE PRICE'}
                  </span>
                  <span className='my-2 header-5'>{formatNumber(calculateEmployeeSubscription?.calculated_employee_unit_price, 2)}</span>
                </div>
                <hr className='divider' />
                <div className='flex flex-col mx-8 items-center p-2'>
                  <span className='p4 text-center'>{'TOTAL EMPLOYEES'}</span>
                  <span className='header-4'>
                    {calculateEmployeeSubscription?.total_employees}
                  </span>
                </div>
                <hr className='divider' />
                <div className='flex flex-col mx-8 items-center p-2'>
                  <span className='p4 text-center'>{'TOTAL PAYABLE'}</span>
                  <span className='header-4'>
                  {formatNumber(calculateEmployeeSubscription?.total_price, 2)}
                  </span>
                </div>
                <hr className='divider' />

                <div className='w-full mt-[20px] pb-4 '>
                  <Button
                    text={'ADD EMPLOYEES'}
                    type='button'
                    loading={subscribeLoading}
                    onClick={() => {
                      submitForm();
                    }}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddEmployeeToHrSubscription;
