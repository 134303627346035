import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import FormTextArea from '../../form-textarea';
import AdvancedPayrollBreakdown from './advanced-payroll-breakdown';
import Modal from '../modal';
import moment from 'moment';
import WarningSVG from '../../../assets/svg/warning.svg';

function RunAdvancedPayroll({
  isOpen,
  closeModal,
  source = 'default',
  sourceData,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY-'));
  const [setting, setSetting] = useState('group');
  const [showAdvancedPayroll, setShowAdvancedPayroll] = useState(false);
  const [payloadData, setPayloadData] = useState(null);

  const submitForm = async (data) => {
    const desc = DOMPurify.sanitize(data?.desc);
    const payment_date = DOMPurify.sanitize(data?.payment_date);

    let payload = {
      desc,
      month: year + month,
      setting,
      payment_date,
    };

    if (source === 'default') {
      onAdvancedPayrollSuccess(payload);
    } else if (source === 'group') {
      payload.group_id = parseInt(sourceData);
      onAdvancedPayrollSuccess(payload);
    } else if (source === 'branch') {
      payload.branch_id = parseInt(sourceData);
      onAdvancedPayrollSuccess(payload);
    } else {
      payload.employee_ids = sourceData;
      onAdvancedPayrollSuccess(payload);
    }
  };

  function onAdvancedPayrollSuccess(payload) {
    setPayloadData(payload);
    setShowAdvancedPayroll(true);
  }

  // const payrollOptions = [
  //   { label: 'Use Company Payroll Settings', value: 'global' },
  //   { label: 'Use Payroll Group Settings', value: 'group' },
  // ];

  return (
    <>
      {showAdvancedPayroll ? (
        <AdvancedPayrollBreakdown
          isOpen={showAdvancedPayroll}
          source={source}
          sourceData={sourceData}
          payloadData={payloadData}
          completed={() => {
            setShowAdvancedPayroll(false);
            closeModal();
          }}
          closeModal={() => setShowAdvancedPayroll(false)}
        />
      ) : null}
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
            <div className='pl-8 pr-8 pt-4'>
              <div className='flex flex-row justify-between items-center'>
                <span className='header-3'>Run Payroll</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='close' />
                </div>
              </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col mx-8'>
              <div>
                <div className='flex flex-col md:flex-row w-full justify-between'>
                  <div className='w-full md:w-1/2 md:pr-2'>
                    <FormSelect
                      value={month}
                      options={monthOptions}
                      onChange={(selected) => {
                        setMonth(selected);
                      }}
                      label='Payroll month'
                    />
                  </div>

                  <div className='w-full md:w-1/2 md:pl-2'>
                    <FormSelect
                      value={year}
                      options={yearOptions}
                      onChange={(selected) => {
                        setYear(selected);
                      }}
                      label='Year'
                    />
                  </div>
                </div>
                <div className='flex flex-col md:flex-row w-full justify-between'>
                  <div className='w-full '>
                    <FormInput
                      label='Select payment date'
                      name='payment_date'
                      type='date'
                      defaultValue={moment().format('YYYY-MM-DD')}
                      inputRef={register(formValidation('text', true))}
                      error={errors.payment_date}
                      errorMessage={
                        errors.payment_date && errors.payment_date.message
                      }
                    />
                  </div>
                  {/* <div className='w-full md:w-1/2 md:pl-2'>
                    <FormSelect
                      defaultValue={setting}
                      options={payrollOptions}
                      onChange={(selected) => {
                        setSetting(selected);
                      }}
                      label='Payroll Setting'
                    />
                  </div> */}
                </div>
                <FormTextArea
                  label='Descripton'
                  placeholder={'Enter payroll description'}
                  name='desc'
                  type='text'
                  inputRef={register(formValidation('text', true))}
                  error={errors.desc}
                  errorMessage={errors.desc && errors.desc.message}
                />
              </div>
              <div className='flex flex-col px-4 border-secondary-4 border-[1.2px] rounded-lg p-3 mt-2 bg-color-warning-light w-[100%]'>
                <div className='flex'>
                  <div className='w-[150px] pr-2 flex flex-row items-center '>
                    <img src={WarningSVG} alt='' srcSet='' />
                  </div>
                  <div className='text-[12px] text-justify'>
                    <span className='font-normal'><span className='font-extrabold'>Please note: </span>Payroll run here does a full computation of employee payroll. It calculates all statutory compliance payments including; Tax, Pension, NHF, NHIS, ITF and NSITF. Payslip is also generated for all employees and can be sent to them at the click of a button. Only active employees who have salaries added will be computed.</span>
                  </div>
                </div>
              </div>
              <div className='w-full mt-[20px]'>
                <Button text='CONTINUE' type='submit' disabled={!isValid} />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default RunAdvancedPayroll;
