import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../components/layouts/dashboard";
import TabView from "../../../../components/tabview";
import { CardHr } from "../../../../components/modal/hr-section/dashboard/cardHR";
import TrainingSVG from "../../../../assets/svg/trainings.svg";
import TrainingOnGoingSVG from "../../../../assets/svg/training-ongoing.svg";
import TrainingComingUpSVG from "../../../../assets/svg/training-coming-up.svg";
import TrainersSVG from "../../../../assets/svg/trainers.svg";
import LoadingBar from "../../../../components/loader-bar";
import TrainingOverviewTab from "../../../../components/employee-training/training-list";
import EmployeeTrainersTab from "../../../../components/employee-training/trainers";
import { useGetTrainingSummary } from "../../../../redux/employees/hook/training";
import UnauthorizedPage from "../../../errors/unauthorized";
import { useSelector } from "react-redux";
import TrainingCoursesTab from "../../../../components/employee-training/training-courses";

const HRTrainingPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.training_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);
  const { data: getTrainingSummary, isLoading: summaryLoading } =
    useGetTrainingSummary();
  const [trainingSummary, setTrainingSummary] = useState([]);

  useEffect(() => {
    if (getTrainingSummary) {
      setTrainingSummary([
        {
          title: "TRAININGS COMPLETED",
          value: getTrainingSummary.completed,
          icon: TrainingSVG,
        },
        {
          title: "TRAININGS ON-GOING",
          value: getTrainingSummary.on_going,
          icon: TrainingOnGoingSVG,
        },
        {
          title: "TRAININGS UP-COMING",
          value: getTrainingSummary.up_coming,
          icon: TrainingComingUpSVG,
        },
        {
          title: "TOTAL TRAINERS",
          value: getTrainingSummary.trainers,
          icon: TrainersSVG,
        },
      ]);
    } else {
      setTrainingSummary([
        {
          title: "TRAININGS COMPLETED",
          value: 0,
          icon: TrainingSVG,
        },
        {
          title: "TRAININGS ONGOING",
          value: 0,
          icon: TrainingOnGoingSVG,
        },
        {
          title: "TRAININGS UP-COMING",
          value: 0,
          icon: TrainingComingUpSVG,
        },
        {
          title: "TOTAL TRAINERS",
          value: 0,
          icon: TrainersSVG,
        },
      ]);
    }
  }, [getTrainingSummary]);

  const RecruitmentTabs = [
    {
      title: "Trainings",
      Content:
        user?.permissions.training_permission.training_read === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? TrainingOverviewTab
          : UnauthorizedPage,
    },
    {
      title: "Trainers",
      Content:
        user?.permissions.training_permission.trainer_read === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? EmployeeTrainersTab
          : UnauthorizedPage,
    },
    // {
    //   title: 'Training Courses',
    //   Content:
    //     user?.permissions.training_permission.training_read === 1 ||
    //     user?.permissions.company_permission.role === 'Owner'
    //       ? TrainingCoursesTab
    //       : UnauthorizedPage,
    // },
  ];

  return (
    <DashboardLayout title="Employee Training Management">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row justify-between items-center -mt-2">
              {!summaryLoading ? (
                <div className="flex flex-col md:flex-row justify-between items-center w-[100%]">
                  <div className="h-fit pb-3 flex flex-col justify-between w-full">
                    <div className="grid md:grid-cols-4 lg:grid-cols-4 grid-cols-2 gap-4">
                      <>
                        {trainingSummary?.map((item) => (
                          <CardHr
                            title={item.title}
                            subtitle={item.value}
                            icon={item.icon}
                          />
                        ))}
                      </>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col md:flex-row justify-between items-center w-[100%] p-2 pt-4">
                  <div className="h-fit pb-3 flex flex-col justify-between w-full">
                    <div className="grid grid-cols-1 gap-4">
                      <LoadingBar loading={summaryLoading} />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-2">
              <TabView
                Tabs={RecruitmentTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default HRTrainingPage;
