import React, { useEffect, useState } from 'react';

export const EmployeeAnswerView = ({stage, title}) => {

    const [result, setResult] = useState([]);

    useEffect(() => {
        if (stage && stage.answers) {
            let newResults = []
            stage.answers.forEach((item) => {
                let newResult = {
                question_id: item.id,
                question: item.question,
                answer: item.answer,
                question_type: item.question_type,
                options: item.options
                }
                newResults.push(newResult);
            })
            setResult(newResults)
        }
    }, [stage])

    return (
        <div
        className={`flex flex-col  gap-2 justify-start bg-white rounded-[5px] border border-[#dbdce3]`}
        >
            <div className='flex flex-row justify-between items-center bg-blue-100 p-3'>
                <div className='font-medium text-[14px] text-color-black flex flex-col'>
                    {title}
                </div>
            </div>
            {stage && stage.answers.length > 0?
            <div className=' flex flex-col max-h-[400px] min-h-[120px] overflow-auto gap-2 mt-3 p-4 pt-1'>
                {result?.map((item, index) => (
                    <div className='flex flex-col border-r-4 gap-2 border-r-hr-primary-1 rounded p-4 bg-highlight' key={index}>
                        <div className='flex flex-col mb-2'>
                            <span className='header-5'>Question {index+1}</span>
                            <span className='p4-medium'>{item.question}</span>
                        </div>
                        {item.question_type === 'multiple_choice' ?
                        <div className='flex flex-col !min-w-[100%]'>
                        {item.options.map((optionItem, optionIndex) => (
                            <div 
                            className={optionItem.index == item.answer?'flex flex-row items-center mb-2 p-2 bg-hr-primary-1 cursor-pointer duration-500 border-1 rounded':'flex flex-row items-center cursor-pointer duration-500 border-1 rounded mb-2 p-2 border-gray-300 bg-white'}>
                            <div className={optionItem.index == item.answer?'header-5 pr-2 !min-w-[20%] !text-white duration-500':'header-5 pr-2 !min-w-[20%] duration-500'}>Option {optionIndex+1}:</div>
                            <div className={optionItem.index == item.answer?'header-5 pr-2 !min-w-[20%] !text-white duration-500':'header-5 pr-2 !min-w-[20%] duration-500'}>{optionItem.option}</div>
                            </div>
                        ))}
                        </div>
                        :
                        <pre className='text-[13px] border p-2 rounded !max-h-[400px] overflow-auto bg-white'>{item.answer}</pre>
                        }
                    </div>
                ))}
            </div>
            :
            <div className='flex flex-col max-h-[60px] min-h-[60px] overflow-auto items-center justify-center italic text-hr-secondary-1'>
                No Answers
            </div>
            }
        </div>
    );
};