import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import {
  probationStatusConfig,
  PAGINATION_DEFAULT,
} from "../../utils/constants";
import Button from "../button";
import DoYouWantToModal from "../modal/do-you-want-to";
import InfoModal from "../modal/info-modal";
import { useGetEmployees } from "../../redux/employees/hook";
import AddProbationModal from "../modal/hr-section/employee-probation/add-probation";
import {
  useDeleteProbation,
  useGetProbations,
} from "../../redux/employees/hook/probation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ProbationTab = () => {
  useEffect(() => {}, []);
  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const history = useHistory();

  const { refetch, data: probations } = useGetProbations(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );
  const [showInfoModal, setShowInfoModal] = useState(false);

  const [showAddProbation, setShowAddProbation] = useState(false);
  const [showSelectProbationOptions, setShowSelectProbationOptions] =
    useState(false);
  const [probationData, setProbationData] = useState();
  const [title, setTitle] = useState("Add employee probation");
  const [buttonText, setButtonText] = useState("Add");

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { mutateAsync: deleteProbation, isLoading: deleteLoading } =
    useDeleteProbation();

  const tableConfig = {
    headers: [
      "Employee Name",
      "Phone Number",
      "Purpose",
      "Start Date",
      "End Date",
      "Status",
    ],
    keys: [
      "employeeName",
      "mobile",
      "purpose",
      "formatted_start_date",
      "formatted_end_date",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "mobile",
      },
    },
  };

  const deleteSingleProbation = async (id) => {
    let index = probations.data.findIndex((item) => item.id === id);
    await deleteProbation(probations.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = probations.data.findIndex((item) => item.id === id);
        history.push("/employees/probation/" + probations.data[index].id);
      },
    },
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = probations.data.findIndex((item) => item.id === id);
        setButtonText("Update");
        setTitle("Update Probation");
        setProbationData(probations.data[index]);
        setShowAddProbation(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = probations.data.findIndex((item) => item.id === id);
        setDeleteId(probations.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  return (
    <div className="flex flex-col mt-4">
      <div className="flex flex-col bg-white rounded border">
        {showDoYouWant ? (
          <DoYouWantToModal
            isOpen={showDoYouWant}
            closeModal={() => setShowDoYouWant(false)}
            id={deleteId}
            loading={deleteLoading}
            title={"Are you sure you want to delete this probation?"}
            buttonText={"DELETE"}
            btnFunction={deleteSingleProbation}
          />
        ) : null}
        {showAddProbation ? (
          <AddProbationModal
            isOpen={showAddProbation}
            closeModal={() => setShowAddProbation(false)}
            probationData={probationData}
            title={title}
            buttonText={buttonText}
          />
        ) : null}
        {/* {showSelectProbationOptions?
          <SelectProbationOption
            isOpen={showSelectProbationOptions}
            closeModal={() => setShowSelectProbationOptions(false)}
          />
        :
        null}  */}
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"probation"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}

        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Employees On Probation
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="Add Employee Probation"
                type="submit"
                theme="primary"
                disabled={!employees}
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => {
                  setShowAddProbation(true);
                }}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="Refetch"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"change"}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={probations}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={probationStatusConfig}
          filterByStatus={true}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};
