import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Info from "./info";

const FormSwitch = ({
  id,
  name,
  label,
  value,
  inputRef,
  onChange,
  onKeyDown,
  readOnly,
  error,
  labelClass,
  errorMessage,
  infos,
  checked,
  classNames = "",
  ...rest
}) => {
  const { section } = useSelector((state) => state.sectionSlice);
  return (
    <div className={`flex flex-col pb-4 ${classNames}`}>
      <div className="flex flex-row justify-between items-center gap-2 w-full">
        <div className={`!text-[13px] ${labelClass}`}>
          <span>{label}</span>
        </div>
        <label className="switch !min-w-[40px]">
          <input type="checkbox" checked={checked} ref={inputRef} {...rest} />
          <span
            className={
              section?.name === "core hr"
                ? "togglehr"
                : section?.name === "spend management"
                ? "toggleSpend"
                : "toggle"
            }
          ></span>
        </label>
      </div>

      {error && <span>* {errorMessage}</span>}
      {infos && <Info infos={infos} />}
    </div>
  );
};

export default FormSwitch;
