import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../components/button";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import {
  updateCurrentCompany,
  useUpdateCompany,
} from "../../../redux/company/hook";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import WarningSVG from "../../../assets/svg/warning.svg";
import PayrollSummary from "../../../components/modal/payroll/payroll-summary";
import { useRefNHF } from "../../../redux/statutory-compliance/hook/nhf";
import StatutorySummary from "../../../components/modal/statutory-compliance/statutory-summary";
import { useSelector } from "react-redux";
import FormInput from "../../../components/form-input";
import { useGetCompanyAccountDetails } from "../../../redux/wallet/hook";

const NHFPreviewPage = () => {
  useEffect(() => {}, []);
  const { company } = useSelector((state) => state.companySlice);
  const { data: companyAccountDetails } = useGetCompanyAccountDetails();

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const [showPayrollSummary, setShowPayrollSummary] = useState(false);
  const [nhfCode, setNHFCode] = useState();
  const [companyName, setCompanyName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();

  const { mutateAsync: updateCompany, isLoading: loading } = useUpdateCompany();

  const { refId } = useParams();
  const { data: nhf } = useRefNHF(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  useEffect(() => {
    if (company) {
      setNHFCode(company.nhfCode);
      setCompanyName(company.company_name);
      setEmail(company.email);
      setMobile(company.mobile);
    }
  }, [company]);

  const tableConfig = {
    headers: ["Employee Name", "Mobile", "Amount"],
    keys: ["employeeName", "employee_mobile", "table_nhf_amount"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };

  const companyNHFUpdate = async () => {
    let payload = {
      company_name: companyName,
      email: email,
      mobile: mobile,
      nhfCode: nhfCode,
    };

    await updateCompany(payload);
  };

  return (
    <DashboardLayout title="NHF Preview">
      <div className="flex flex-col">
        <div className="flex flex-col md:flex-row justify-between gap-3">
          <div className="flex flex-col md:w-2/3 w-full">
            <span className="h4 text-color-black">
              A Quick Preview Before You Submit
            </span>
            <span className="text-[14px] text-color-gray -mt-2">
              Please spend a brief moment reviewing your National Housing Fund
              (NHF) before you submit for Payment
            </span>
          </div>
          <div className="flex md:justify-end justify-center md:w-1/3 w-full">
            <Button
              text="PAY AND FILE"
              type="button"
              loading={false}
              disabled={false}
              className="h-[56px] w-full md:w-[220px] "
              onClick={() => setShowPayrollSummary(true)}
            />
          </div>
        </div>

        <div className="mt-8">
          <>
            <StatutorySummary
              refValue={refId}
              type={"nhf"}
              isOpen={showPayrollSummary}
              closeModal={() => setShowPayrollSummary(false)}
              takeHome={nhf?.formatted_total_amount}
              transCost={nhf?.formatted_total_trans_cost}
              totalAmount={nhf?.formatted_total_payable}
            />
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-[100%] md:w-[75%] rounded-md overflow-auto">
                  <DataTable
                    data={nhf}
                    tableConfig={tableConfig}
                    noCheck={true}
                    searchData={true}
                    updatePagination={(data) => setPagination(data)}
                  />
                </div>
                <div className="md:w-[25%] flex flex-col md:pl-8 md:mt-0 rounded-md bg-white w-full p-3">
                  <div className="flex flex-row justify-between items-center">
                    <div className="w-[70%]">
                      <FormInput
                        label="Company NHF Code"
                        name="nhf_code"
                        className={"rounded-br-none rounded-tr-none"}
                        type="text"
                        readOnly={false}
                        value={nhfCode}
                        onInput={(e) => {
                          setNHFCode(e.target.value);
                        }}
                      />
                    </div>
                    <div className="w-[30%] mt-[25px]">
                      <Button
                        text="UPDATE"
                        type="submit"
                        disabled={
                          companyName && email && mobile && nhfCode
                            ? false
                            : true
                        }
                        loading={loading}
                        textClass="!header-6"
                        theme="primary"
                        className={`h-[35px] pl-1 pr-1 rounded-bl-none rounded-tl-none`}
                        onClick={() => {
                          companyNHFUpdate();
                        }}
                      />
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="mb-2">
                    <div className="flex flex-row justify-between">
                      <span className="text-[13px] text-secondary-2 font-bold">
                        Total NHF
                      </span>
                      <span className="text-[13px] text-color-black">
                        {nhf?.formatted_total_amount}
                      </span>
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="mb-2">
                    <div className="flex flex-row justify-between">
                      <span className="text-[13px] text-secondary-2 font-bold">
                        Fees
                      </span>
                      <span className="text-[13px] text-color-black">
                        {nhf?.formatted_total_trans_cost}
                      </span>
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="mb-2">
                    <div className="flex flex-row justify-between">
                      <span className="text-[16px] text-secondary-2 font-extrabold">
                        Total Payable
                      </span>
                      <span className="text-[15px]  text-color-black font-bold">
                        {nhf?.formatted_total_payable}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NHFPreviewPage;
