import React, { useEffect, useRef, useState } from "react";
import TickSVG from "../../assets/svg/tick.svg";
import { useHistory } from "react-router-dom";
import Logo from "../logo";
import ProfileMenu from "../profile-menu";
import { useRouteQuery } from "../../hooks/useRouteQuery";
import { useSelector } from "react-redux";

const EmployeeOnboardingLayout = ({ children, step, title }) => {
  useEffect(() => {}, []);

  const { section } = useSelector((state) => state.sectionSlice);

  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [fromSalary, setFromSalary] = useState(false);
  const [steps, setSteps] = useState([
    {
      title: "Select onboarding option",
      subtitle:
        "Choose your desired  employee onboarding option. Single oboarding or multiple onboarding.",
      completed: true,
    },
    {
      title: "Add Employee Details",
      subtitle:
        "Fill in all the necessary details to onboard your employees completely or invite them to complete their profile via ropay mobile or web apps.",
      completed: false,
    },
    {
      title: "Assign Onboarding Workflow",
      subtitle:
        "Automate onboarding by adding employee to predefined onboarding workflow.",
      completed: false,
    },
    {
      title: "Add Employee To Payroll Group",
      subtitle:
        "Assign employees to different payroll groups to automate payrll computation for different groups of employees, based on different salary structures",
      completed: false,
    },
    {
      title: "Add Salary information",
      subtitle:
        "Select your desired salary information options to add salary breakdown of your employee.",
      completed: false,
    },
  ]);

  const query = useRouteQuery();

  useEffect(() => {
    if (query) {
      setFromSalary(query.get("salary"));
    }
  }, [query]);

  useEffect(() => {
    if (fromSalary) {
      setSteps([
        {
          title: "Select salary option",
          subtitle:
            "Choose your desired option for onboarding your employee salaries.",
          completed: false,
        },
        {
          title: "Add Salary information",
          subtitle:
            "Select your desired salary information options to input salary details for your employee. With the power of the signature roPay reverse calculator. ",
          completed: false,
        },
      ]);
    }
  }, [fromSalary]);

  useEffect(() => {
    if (step) {
      setCurrentStep(step);
    }
  }, [step, currentStep]);

  return (
    <div className="flex flex-col h-screen min-h-screen max-h-screen overflow-auto">
      <div className="flex flex-col">
        <div className="flex justify-end">
          <div className="mt-[20px] mr-[36px]">
            <ProfileMenu />
          </div>
        </div>

        {/* Desktop Sidebar */}
        <div className="flex flex-col container justify-center lg:justify-start !px-[40px] lg:px-0 ">
          <div>
            <button
              className="hidden lg:flex px-4"
              onClick={() => {
                if (fromSalary) {
                  history.push("/salaries");
                } else {
                  history.push("/employees");
                }
              }}
            >
              <Logo width="130px" height="50px" />
            </button>
            <div className="flex flex-col mt-[25px]">
              {/* Mobile Stepper */}
              <div className="flex lg:hidden justify-center w-full mb-[10px]">
                <div className="flex flex-row w-[100%] my-3">
                  {steps.map((item, index) => (
                    <>
                      <div
                        className={`${
                          index + 1 !== steps.length
                            ? "flex flex-row w-full items-center"
                            : ""
                        }`}
                      >
                        <div>
                          <div
                            className={`flex items-center justify-center w-[30px] h-[30px] rounded-[30px] ${
                              index + 1 <= currentStep &&
                              section.name !== "core hr"
                                ? "bg-secondary-2"
                                : index + 1 <= currentStep &&
                                  section.name === "core hr"
                                ? "bg-[#0d5225]"
                                : "border border-color-black"
                            }  ${
                              index + 1 === currentStep
                                ? "opacity-100"
                                : "opacity-40"
                            }`}
                          >
                            {index < currentStep - 1 ? (
                              <img
                                src={TickSVG}
                                alt="tick"
                                style={{ width: "16px", height: "16px" }}
                              />
                            ) : (
                              <span
                                className={`text-[16px] font-bold ${
                                  index + 1 <= currentStep
                                    ? "text-white"
                                    : "text-color-black"
                                }`}
                              >
                                {index + 1}
                              </span>
                            )}
                          </div>
                        </div>
                        {index + 1 !== steps.length ? (
                          <div className="bg-color-border w-full h-[1px]"></div>
                        ) : null}
                      </div>
                    </>
                  ))}
                </div>
              </div>
              <div className="flex flex-col lg:flex-row w-full">
                <div className="w-full lg:w-[40%] flex flex-col lg:justify-start justify-center">
                  <div className="w-full lg:w-[100%] md:flex hidden flex-col">
                    {/* Header */}
                    <span className="header-3">{title}</span>
                  </div>

                  <div className="hidden lg:flex w-full mt-5">
                    <div className="flex flex-col w-[100%]">
                      {steps.map((item, index) => (
                        <div key={index} className="flex flex-row mb-[80px]">
                          <div className="flex items-center w-[15%]">
                            <div
                              className={`flex items-center justify-center  w-[50px] h-[50px] rounded-[50px] ${
                                index + 1 <= currentStep &&
                                section.name !== "core hr"
                                  ? "bg-secondary-2"
                                  : index + 1 <= currentStep &&
                                    section.name === "core hr"
                                  ? "bg-[#0d5225]"
                                  : "border border-color-black"
                              }  ${
                                index + 1 === currentStep
                                  ? "opacity-100 "
                                  : "opacity-40 "
                              }`}
                            >
                              {index < currentStep - 1 ? (
                                <img
                                  src={TickSVG}
                                  alt="tick"
                                  style={{ width: "20px", height: "20px" }}
                                />
                              ) : (
                                <span
                                  className={`header-3 ${
                                    index + 1 <= currentStep
                                      ? "text-white"
                                      : "text-color-black"
                                  }`}
                                >
                                  {index + 1}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col w-[100%]">
                            <span
                              className={`text-[18px] font-bold text-color-black leading-[23px] ${
                                index + 1 === currentStep
                                  ? "text-opacity-100"
                                  : "text-opacity-40"
                              }`}
                            >
                              {item.title}
                            </span>
                            <div className="w-[80%] mt-[4px]">
                              <span
                                className={`text-[13px] leading-[20px] text-color-black ${
                                  index + 1 === currentStep
                                    ? "text-opacity-80"
                                    : "text-opacity-40"
                                } `}
                              >
                                {item.subtitle}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-[60%] md:-mt-[55px] -mt-4">
                  {/* Page components */}
                  <div className="lg:max-h-[80vh] overflow-auto h-full">
                    <div className="w-full flex items-center justify-center flex-col">
                      <div className="w-full lg:max-w-[80%] flex flex-col lg:flex-row ">
                        <div className="flex lg:hidden mb-[52px] lg:mb-0 w-full mt-4">
                          <div className="flex flex-col">
                            <span
                              className={
                                "text-[18px] font-bold text-color-black leading-[23px] text-opacity-100"
                              }
                            >
                              {steps[currentStep - 1].title}
                            </span>
                            <div className="mt-[4px]">
                              <span
                                className={
                                  "text-[13px] leading-[20px] text-color-black text-opacity-80"
                                }
                              >
                                {steps[currentStep - 1].subtitle}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="w-full pb-[20px] !h-fit mt-2">
                          {children}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeOnboardingLayout;
