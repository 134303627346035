import React, { useEffect } from "react";
import { useState } from "react";
import FormSwitch from "../../form-switch";
import Button from "../../button";
import FormInput from "../../form-input";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../utils/functions";
import LoadingBar from "../../loader-bar";
import CopySVG from "../../../assets/svg/copy.svg";
import WarningSVG from "../../../assets/svg/warning.svg";
import {
  useChangeEmployeeClockInLinkStatus,
  useGetAttendanceSettings,
  useGetEmployeeClockInLink,
  useRefreshEmployeeClockInLink,
  useUpdateApprovedIps,
  useUpdateAttendanceSettings,
} from "../../../redux/human-resources/hook/attendance";
import CopyToClipboard from "react-copy-to-clipboard";
import { setAlert } from "../../../redux/components/components-slice";
import { useDispatch } from "react-redux";

const AttendanceSettings = () => {
  // const [clockType, setClockType] = useState('flexible');
  const { mutateAsync: updateAttendanceSettings, isLoading: loading } =
    useUpdateAttendanceSettings();
  const {
    register,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const dispatch = useDispatch();
  const { data: link } = useGetEmployeeClockInLink();

  const {
    mutateAsync: updateApprovedIps,
    isLoading: updateApprovedIpsloading,
  } = useUpdateApprovedIps();

  const { mutateAsync: refreshInvite, isLoading: refreshloading } =
    useRefreshEmployeeClockInLink();
  const { mutateAsync: changeStatus, isLoading: changeStatusloading } =
    useChangeEmployeeClockInLinkStatus();

  const { data: attendance_setting, isLoading } =
    useGetAttendanceSettings("company");

  const [accessibilityStatus, setAccessibilityStatus] = useState(false);

  useEffect(() => {
    if (link) {
      setAccessibilityStatus(link?.status === 1 ? true : false);
    }
  }, [link]);

  const [workDays, setWorkdays] = useState([
    {
      day: "Sunday",
      tag: "SUN",
      work: false,
    },
    {
      day: "Monday",
      tag: "MON",
      work: true,
    },
    {
      day: "Tuesday",
      tag: "TUE",
      work: true,
    },
    {
      day: "Wednesday",
      tag: "WED",
      work: true,
    },
    {
      day: "Thursday",
      tag: "THU",
      work: true,
    },
    {
      day: "Friday",
      tag: "FRI",
      work: true,
    },
    {
      day: "Saturday",
      tag: "SAT",
      work: false,
    },
  ]);
  const [clockIn, setClockIn] = useState("09:00");
  const [clockOut, setClockOut] = useState("17:00");
  const [autoConfirm, setAutoConfirm] = useState(true);

  const [applyToPayroll, setApplyToPayroll] = useState(true);
  const [clockinWithLink, setClockinWithLink] = useState(true);

  const [ipAddresses, setIpAddresses] = useState([]);
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    if (link) {
      setIpAddresses(
        JSON.parse(link.registered_ips ? link.registered_ips : "[]")
      );
    }
  }, [link]);

  useEffect(() => {
    if (attendance_setting) {
      setWorkdays([
        {
          day: "Sunday",
          tag: "SUN",
          work: attendance_setting.workdays.sun,
        },
        {
          day: "Monday",
          tag: "MON",
          work: attendance_setting.workdays.mon,
        },
        {
          day: "Tuesday",
          tag: "TUE",
          work: attendance_setting.workdays.tue,
        },
        {
          day: "Wednesday",
          tag: "WED",
          work: attendance_setting.workdays.wed,
        },
        {
          day: "Thursday",
          tag: "THU",
          work: attendance_setting.workdays.thu,
        },
        {
          day: "Friday",
          tag: "FRI",
          work: attendance_setting.workdays.fri,
        },
        {
          day: "Saturday",
          tag: "SAT",
          work: attendance_setting.workdays.sat,
        },
      ]);
      setApplyToPayroll(
        attendance_setting.apply_to_payroll == 1 ? true : false
      );
      setClockinWithLink(
        attendance_setting.clockin_with_link == 1 ? true : false
      );
      setAutoConfirm(attendance_setting.auto_confirm);
      setClockIn(attendance_setting.clockin_time);
      setClockOut(attendance_setting.clockout_time);
    }
  }, [attendance_setting]);

  const submit = async () => {
    let payload = {
      source: "company",
      source_id: null,
      clockin_time: clockIn,
      clockout_time: clockOut,
      apply_to_payroll: applyToPayroll ? 1 : 0,
      clockin_with_link: clockinWithLink ? 1 : 0,
      auto_confirm: autoConfirm,
      sun: workDays[0].work,
      mon: workDays[1].work,
      tue: workDays[2].work,
      wed: workDays[3].work,
      thu: workDays[4].work,
      fri: workDays[5].work,
      sat: workDays[6].work,
    };

    updateAttendanceSettings(payload);
  };

  const changeAccessibilityStatus = async () => {
    setAccessibilityStatus(!accessibilityStatus);
    changeStatus();
  };

  const updateIps = async () => {
    let payload = {
      ip_addresses: JSON.stringify(ipAddresses),
    };
    updateApprovedIps(payload);
  };

  return (
    <>
      <div className="flex flex-col bg-white w-full  rounded py-3 pt-2 h-full min-h-fit -mt-8">
        <div className="flex flex-col md:flex-row justify-between md:items-center px-4 gap-[10px] py-2">
          <div className="flex flex-col">
            <span className="header-4">Attendance Settings</span>
            <span className="text-[12px] text-color-gray font-normal">
              Update and edit your Attendance settings
            </span>
          </div>
        </div>
        <hr className="divider mt-2 mb-2" />
        <div className="flex flex-col px-4">
          <div className="flex flex-col md:flex-row">
            <div className="flex w-full max-w-[100%] md:px-0 flex-col">
              {isLoading ? (
                <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                  <LoadingBar loading={isLoading} />
                </div>
              ) : (
                <div className="flex flex-row p-2">
                  <div className="w-full">
                    <div className="flex flex-row md:flex-col w-full justify-between">
                      <div className="w-full md:w-1/2 pr-2">
                        <FormInput
                          label="Resumption Time"
                          name="clock_in"
                          type="time"
                          defaultValue={clockIn}
                          onInput={(e) => {
                            setClockIn(e.target.value);
                          }}
                          inputRef={register(formValidation("time", true))}
                          error={errors.clock_in}
                          errorMessage={
                            errors.clock_in && errors.clock_in.message
                          }
                        />
                      </div>
                      <div className="w-full md:w-1/2 pl-2">
                        <FormInput
                          label="Closing Time"
                          name="clock_out"
                          type="time"
                          defaultValue={clockOut}
                          onInput={(e) => {
                            setClockOut(e.target.value);
                          }}
                          inputRef={register(formValidation("time", true))}
                          error={errors.clock_out}
                          errorMessage={
                            errors.clock_out && errors.clock_out.message
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={
                        "flex w-[100%] flex-col items-center gap-3 mt-1 "
                      }
                    >
                      <div
                        className={
                          "flex w-full items-start justify-between gap-3 flex-col -mt-1"
                        }
                      >
                        <div className="text-color-gray text-[13px] ">
                          Work Days (Choose days employees work)
                        </div>
                        <div className="flex flex-row items-center justify-between w-full p-[12px] border-1 border-gray-300 rounded -mt-4 bg-highlight">
                          {workDays.map((item, index) => (
                            <button
                              key={index}
                              className={
                                !item.work
                                  ? "flex flex-row items-center !h-[24px] px-2 border-1 rounded border-[#0d5225] text-[#0d5225] text-[12px] cursor-pointer"
                                  : "flex flex-row items-center !h-[24px] px-2 border-1 rounded border-[#0d5225] bg-[#0d5225] text-color-white text-[12px] cursor-pointer"
                              }
                              onClick={() => {
                                let newArray = JSON.parse(
                                  JSON.stringify(workDays)
                                );

                                newArray[index].work = !item.work;
                                setWorkdays(newArray);
                              }}
                            >
                              {item.tag}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                    <hr className="divider" />
                    <div className="w-full">
                      <FormSwitch
                        label={"Apply To Payroll?"}
                        name={"apply_to_payroll"}
                        checked={applyToPayroll}
                        onClick={() => {
                          setApplyToPayroll(!applyToPayroll);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <FormSwitch
                        label={"Employee Clockin With Link?"}
                        name={"clockin_with_link"}
                        checked={clockinWithLink}
                        onClick={() => {
                          setClockinWithLink(!clockinWithLink);
                        }}
                      />
                    </div>
                    {clockinWithLink ? (
                      <div className="flex flex-col justify-center items-start w-full gap-4 px-8 pb-4 border bg-gray-50 rounded-md">
                        {link ? (
                          <>
                            <div className="flex flex-col p3 items-center justify-center w-full">
                              <span className="text-color-black pt-2 text-[16px] font-extrabold">
                                Employee Clockin Link
                              </span>
                              <span className=" text-secondary-2 pt-1 !text-center text-[13px]">
                                {link?.link}{" "}
                              </span>
                              <CopyToClipboard
                                text={link?.link}
                                onCopy={() => {
                                  dispatch(
                                    setAlert(
                                      true,
                                      "success",
                                      "Copied successfully"
                                    )
                                  );
                                }}
                              >
                                <button className="flex flex-row mt-2 items-center text-center">
                                  <span className="text-color-black pt-2 text-[14px] text-hr-primary-1 hover:font-bold duration-300">
                                    Copy link to clipboard{" "}
                                  </span>
                                  <img
                                    src={CopySVG}
                                    width={15}
                                    className="ml-2 mt-2"
                                    alt="copy"
                                  />
                                </button>
                              </CopyToClipboard>
                            </div>
                            <div className="flex flex-col justify-center w-full">
                              <div className="flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 mt-2 bg-color-warning-light text-[13px] items-center">
                                <div className="w-[100px] pr-2 flex flex-row items-center ">
                                  <img src={WarningSVG} alt="" srcSet="" />
                                </div>
                                <span>
                                  You can use this link to get your employees to
                                  clockin on site. Set this up on dedicated
                                  computer.
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between items-center w-full">
                              <div className="w-full md:w-1/2">
                                <FormSwitch
                                  label={"Accessibility Status"}
                                  name={"accessibility_status"}
                                  checked={accessibilityStatus}
                                  onClick={() => {
                                    changeAccessibilityStatus();
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              className={
                                "flex w-[100%] flex-col items-center -mt-4"
                              }
                            >
                              <div className="w-full p-2 px-3 border bg-white rounded-md">
                                <div className="flex flex-row gap-2 !min-h-[50px] border-[2px] rounded p-2 flex-wrap">
                                  {ipAddresses?.length > 0 ? (
                                    <>
                                      {ipAddresses?.map((item, index) => (
                                        <div className="flex flex-row">
                                          <div className="flex items-center justify-center font-bold border border-br-none border-gray-300 !max-w-fit rounder-tl rounder-tl p-2 !max-h-[25px] !text-[14px] !text-color-black">
                                            {ipAddresses[index]}
                                          </div>
                                          <button
                                            onClick={() => {
                                              let newArray = JSON.parse(
                                                JSON.stringify(ipAddresses)
                                              );
                                              newArray.splice(index, 1);
                                              setIpAddresses(newArray);
                                            }}
                                            type="button"
                                            className={
                                              "flex items-center justify-center border border-bl-none border-gray-300 !max-w-fit rounder-br rounder-tr p-2 !max-h-[25px] !text-[14px] !text-color-black hover:bg-gray-100 font-bold text-red-700"
                                            }
                                          >
                                            X
                                          </button>
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <div className="flex items-center !w-full justify-center text-color-gray text-[14px] text-center">
                                      No Approved Clockin IP Address Added
                                    </div>
                                  )}
                                </div>
                                <div className="w-full">
                                  <div className="flex flex-row justify-between items-center">
                                    <div className="w-[70%]">
                                      <FormInput
                                        placeholder="Enter IP Address here"
                                        name="ip_address"
                                        className={
                                          "!h-[35px] rounded-br-none rounded-tr-none"
                                        }
                                        type="text"
                                        value={ipAddress}
                                        onInput={(e) => {
                                          setIpAddress(e.target.value);
                                        }}
                                      />
                                    </div>
                                    <div className="w-[30%] pt-[9px]">
                                      <Button
                                        text={"ADD"}
                                        type="button"
                                        disabled={false}
                                        textClass="!header-6"
                                        theme="primary"
                                        className={`!h-[36px] pl-1 pr-1 rounded-bl-none rounded-tl-none border-l-none -mt-3`}
                                        onClick={() => {
                                          if (ipAddress) {
                                            let newArray = JSON.parse(
                                              JSON.stringify(ipAddresses)
                                            );
                                            newArray.push(ipAddress);
                                            setIpAddresses(newArray);
                                            setIpAddress("");
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex md:flex-row flex-col justify-end w-full gap-3">
                              <Button
                                text={"Update Approved Ips"}
                                type="button"
                                theme={"secondary"}
                                className={"flex gap-2 !h-[35px] md:w-fit p-2"}
                                textClass={"!text-[11px]"}
                                loading={refreshloading}
                                onClick={() => {
                                  updateIps();
                                }}
                              />
                              <Button
                                text={"Refresh Link"}
                                type="button"
                                theme={"primary"}
                                className={"flex gap-2 !h-[35px] md:w-fit p-2"}
                                textClass={"!text-[11px]"}
                                loading={refreshloading}
                                onClick={() => {
                                  refreshInvite();
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <div className="flex w-full items-center justify-center h-[40px]">
                            No Clock in link generated
                          </div>
                        )}
                      </div>
                    ) : null}

                    <div className="flex items-center justify-end w-full mt-[20px]">
                      <Button
                        text="Update Attendance Settings"
                        type="submit"
                        theme="primary"
                        loading={loading}
                        onClick={() => submit()}
                        className="flex gap-2 !h-[35px] w-fit p-2"
                        textClass={"!text-[11px]"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceSettings;
