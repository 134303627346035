import React, { useEffect } from "react";
import Loader from "./loader";
import Icon from "../assets/svg";
import { useSelector } from "react-redux";
import classNames from "classnames";

const Button = ({
  className,
  textClass,
  text,
  theme = "primary",
  leftIcon,
  rightIcon,
  loading,
  disabled,
  onClick,
  ...rest
}) => {
  const { section } = useSelector((state) => state.sectionSlice);

  let btnClass =
    theme === "secondary" && section && section.name === "payroll"
      ? "bg-color-white rounded-md border-secondary-2 border-[1.5px] flex items-center justify-center hover:opacity-80 duration-500"
      : theme === "secondary" && section && section.name === "spend management"
      ? "text-spend-primary-1 border-spend-primary-1 !bg-color-white rounded-md border-[1.5px] flex items-center justify-center hover:opacity-80 duration-500"
      : theme === "secondary" && section && section.name === "core hr"
      ? "text-hr-primary-1 border-hr-primary-1 !bg-color-white rounded-md border-[1.5px] flex items-center justify-center hover:opacity-80 duration-500"
      : theme === "third"
      ? "bg-[#0d5225] rounded-[5px] h-[55px] flex justify-center items-center hover:opacity-80 duration-500"
      : section && section.name === "spend management"
      ? "bg-spend-primary-1 rounded-[5px] h-[55px] flex justify-center items-center hover:opacity-80 duration-500"
      : section && section.name === "core hr"
      ? "bg-hr-primary-1 rounded-[5px] h-[55px] flex justify-center items-center hover:opacity-80 duration-500"
      : "bg-secondary-2 rounded-[5px] h-[55px] flex justify-center items-center hover:opacity-80 duration-500";
  return (
    <button
      className={classNames(
        `select-none w-full px-3 ${btnClass} ${className} ${
          loading && "loading"
        }`,
        {
          "bg-gray-500": disabled,
        }
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {loading ? (
        <Loader color={className === "btn_secondary" && "white"} />
      ) : (
        <>
          {leftIcon && (
            <span className="left_icon">
              <Icon name={leftIcon} />
            </span>
          )}
          <span
            className={`${
              theme === "secondary" && section && section.name === "payroll"
                ? "text-secondary-2"
                : theme === "secondary" &&
                  section &&
                  section.name === "spend management"
                ? "text-spend-primary-1"
                : theme === "secondary" && section && section.name === "core hr"
                ? "text-hr-primary-1"
                : "text-white"
            } !capitalize text-[13px] leading-[23.4px] font-medium ${textClass}`}
          >
            {text}
          </span>
          {rightIcon && (
            <span className="right_icon">
              <Icon name={rightIcon} />
            </span>
          )}
        </>
      )}
    </button>
  );
};

export default Button;
