import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormInput from '../../../form-input';
import { useAddTechnicalAppraisals, useEditTechnicalAppraisals } from '../../../../redux/human-resources/hook/performance';
import FormSelectBranch from '../../../employees/form-select-branch';
import FormSelectDepartment from '../../../employees/form-select-department';
import FormSelectEmployee from '../../../employees/form-select-employee';
import FormSelectTeam from '../../../employees/form-select-team';
import { store } from '../../../../redux';
import { setAlert } from '../../../../redux/components/components-slice';
import Modal from '../../modal';
import AddSmallSVG from '../../../../assets/svg/add-icon-small.svg';
import FormSelect from '../../../form-select';
import moment from 'moment';
import FormSwitch from '../../../form-switch';

function AddTechnicalAppraisals({
  isOpen,
  closeModal,
  technicalAppraisalData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [source, setSource] = useState('employee');
  const [sourceId, setSourceId] = useState(null);
  const [sourceName, setSourceName] = useState(null);

  const sourceOptions = [
    { label: 'Employee', value: 'employee' },
    { label: 'Team', value: 'team' },
    { label: 'Department', value: 'department' },
    { label: 'Branch', value: 'branch' },
  ];

  const [technicalAppraisals, seTechnicalAppraisals] = useState([
    {
      appraisal: '',
      weight: 100,
    },
  ]);

  const [technicalAppraisalTitle, setTechnicalAppraisalTitle] = useState(null);

  const [evaluation_frequency, setEvaluationFrequency] = useState('monthly');
  const [auto_evaluate, setAutoEvaluate] = useState(false);
  const [date_type, setDateType] = useState('day');
  const [day, setDay] = useState('last');
  const [interval_count, setIntervalCount] = useState(1);
  const [auto_evaluation_start_date, setAutoEvaluationStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [end_type, setEndType] = useState('none');
  const [occurrences, setOccurences] = useState(100);
  const [auto_evaluation_end_date, setAutoEvaluationEndDate] = useState(moment().add(1, 'days').format('YYYY-MM-DD'));

  const [dateTypeOptions, setDateTypeOptions] = useState(
      [
          {label: 'Day', value:'day'},
          {label: 'First', value:'first'},
          {label: 'Second', value:'second'},
          {label: 'Third', value:'third'},
          {label: 'Fourth', value:'fourth'},
          {label: 'Last', value:'last'},
      ]
  )

  const [dayOptions, setDayOptions] = useState([
      {label: '1st', value: '01'},
      {label: '2nd', value: '02'},
      {label: '3rd', value: '03'},
      {label: '4th', value: '04'},
      {label: '5th', value: '05'},
      {label: '6th', value: '06'},
      {label: '7th', value: '07'},
      {label: '8th', value: '08'},
      {label: '9th', value: '09'},
      {label: '10th', value: '10'},
      {label: '11th', value: '11'},
      {label: '12th', value: '12'},
      {label: '13th', value: '13'},
      {label: '14th', value: '14'},
      {label: '15th', value: '15'},
      {label: '16th', value: '16'},
      {label: '17th', value: '17'},
      {label: '18th', value: '18'},
      {label: '19th', value: '19'},
      {label: '20th', value: '20'},
      {label: '21st', value: '21'},
      {label: '22nd', value: '22'},
      {label: '23rd', value: '23'},
      {label: '24th', value: '24'},
      {label: '25th', value: '25'},
      {label: '26th', value: '26'},
      {label: '27th', value: '27'},
      {label: '28th', value: '28'},
      {label: 'Last', value: 'last'},
  ])

  const endTypeOptions = [
      {label: 'None', value:'none'},
      {label: 'By', value:'by'},
      {label: 'After', value:'after'},
  ]

  const evaluationFrequencyOptions = [
    {
      label: 'Daily',
      value: 'daily'
    },
    {
      label: 'Weekly',
      value: 'weekly'
    },
    {
      label: 'Monthly',
      value: 'monthly'
    },
    {
      label: 'Quarterly',
      value: 'quarterly'
    },
    {
      label: 'Semi-annually',
      value: 'semi-annually'
    },
    {
      label: 'Annually',
      value: 'annually'
    },
  ]

  const [technicalAppraisalId, setTechnicalAppraisalId] = useState(null);

  useEffect(() => {
    if (technicalAppraisalData) {
      setTechnicalAppraisalId(technicalAppraisalData.id);
      setTechnicalAppraisalTitle(technicalAppraisalData.technical_appraisal_title);
      seTechnicalAppraisals(technicalAppraisalData.appraisals);
      setSourceName(technicalAppraisalData.source_name)
      setSourceId(technicalAppraisalData.source_id)
      setSource(technicalAppraisalData.source)
      setEvaluationFrequency(technicalAppraisalData.evaluation_frequency);
      setAutoEvaluate(technicalAppraisalData.auto_evaluate === 1 ? true : false);
      setDateType(technicalAppraisalData.date_type)
      setDay(technicalAppraisalData.day)
      setIntervalCount(technicalAppraisalData.interval_count)
      setAutoEvaluationStartDate(technicalAppraisalData.auto_evaluation_start_date)
      setEndType(technicalAppraisalData.end_type)
      setOccurences(technicalAppraisalData.occurrences)
      setAutoEvaluationEndDate(technicalAppraisalData.auto_evaluation_end_date)
      setPerformanceType('edit');
    } else {
      setPerformanceType('add');
    }
  }, [technicalAppraisalData]);


  const [weightError, setWeightError] = useState()

  const { mutateAsync: addTechnicalAppraisal, isLoading: addTechnicalAppraisalloading } = useAddTechnicalAppraisals();
  const { mutateAsync: editTechnicalAppraisal, isLoading: editTechnicalAppraisalloading } = useEditTechnicalAppraisals();
  const [preformanceType, setPerformanceType] = useState('add');

  const addAppraisal = () => {
    seTechnicalAppraisals([
      ...technicalAppraisals,
      ...[
        {
          appraisal: '',
          weight: '',
        },
      ],
    ]);
  };

  const removeTechnicalAppraisal = (index) => {
    let data = JSON.parse(JSON.stringify(technicalAppraisals));
    if (index > -1) {
      data.splice(index, 1); // 2nd parameter means remove one item only
      seTechnicalAppraisals(data);
    }
  };

  const submitForm = async (data) => {
    const technical_appraisal_title = technicalAppraisalTitle;
    const source_name = sourceName;

    let appraisals = [];
    if (technicalAppraisals.length > 0) {
        technicalAppraisals?.forEach((item) => {
        if (item.appraisal && item.weight) {
          let newAppraisal = {
            appraisal: item.appraisal,
            weight: item.weight,
          };
          appraisals.push(newAppraisal);
        }
      });
      let payload = {
        technical_appraisal_title,
        source,
        source_id: sourceId,
        source_name,
        max_grade: 100,
        evaluation_frequency,
        auto_evaluate: auto_evaluate ? 1 : 0,
        date_type,
        day,
        interval_count,
        auto_evaluation_start_date,
        end_type,
        occurrences,
        auto_evaluation_end_date,
        appraisals: appraisals,
      };
      if (appraisals.length > 0) {
        if (preformanceType === 'add') {
          await addTechnicalAppraisal(payload).then(() => {
            closeModal();
          });
        } else {
          let editPayload = {
            id: technicalAppraisalId,
            data: payload,
          };
          await editTechnicalAppraisal(editPayload).then(() => {
            closeModal();
          });
        }
      } else {
        store.dispatch(
          setAlert(
            true,
            'info',
            'There must be at least one Appraisal',
          ),
        );
      }
    } else {
      store.dispatch(
        setAlert(
          true,
          'info',
          'There must be at least one Smart Goal',
        ),
      );
    }
  };
  
  return (
    <Modal
      scrollable={true}
      className='md:min-w-[1000px] min-w-[100%] min-h-screen !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-6'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col max-h-fit mx-8'>
          <div className='flex flex-row md:flex-col w-full justify-between border bg-highlight p-2 rounded-md mb-2'>
              <div className='w-full md:w-1/2 pr-2'>
                <FormSelect
                  defaultValue={source}
                  options={sourceOptions}
                  onChange={(selected) => {
                    setSource(selected);
                    setSourceName(null)
                    setSourceId(null);
                  }}
                  label='Technical Apparisals For?'
                  extraClass={'bg-white'}
                />
              </div>
              <div className='w-full md:w-1/2 pl-2'>
                {!source || source === 'company' ? null : source ===
                  'employee' ? (
                  <FormSelectEmployee
                    label={'Employee'}
                    name='employee'
                    placeholder='Select Employee'
                    value={sourceId}
                    empName={sourceName}
                    setSourceName={setSourceName}
                    setEmployee={setSourceId}
                  />
                ) : source === 'team' ? (
                  <FormSelectTeam
                    label={'Team'}
                    name='team'
                    placeholder='Select Team'
                    value={sourceId}
                    teamName={sourceName}
                    setSourceName={setSourceName}
                    setTeam={setSourceId}
                  />
                ) : source === 'department' ? (
                  <FormSelectDepartment
                    label={'Department'}
                    name='department'
                    placeholder='Select Department'
                    value={sourceId}
                    departmentName={sourceName}
                    setSourceName={setSourceName}
                    setDepartment={setSourceId}
                  />
                ) : (
                  <FormSelectBranch
                    label={'Branch'}
                    name='branch'
                    placeholder='Select Branch'
                    value={sourceId}
                    branchName={sourceName}
                    setSourceName={setSourceName}
                    setBranch={setSourceId}
                  />
                )}
              </div>
            </div>
            <FormInput
              label='Technical Apparisal title'
              placeholder='Enter Technical Apparisal title'
              name='name'
              type='text'
              value={technicalAppraisalTitle}
              onInput={(e) => setTechnicalAppraisalTitle(e.target.value)}
              readOnly={addTechnicalAppraisalloading || editTechnicalAppraisalloading}
              className='!h-[30px] text-[13px] w-[100%] '
            />
            <div className='flex flex-col mt-2'>
              <span className='header-4'>Appraisals</span>
              <span className='p3 text-[12px] text-color-gray'>
                Add your Technical Appraisals to specific groups for performance evaluation
              </span>
              <hr className='divider mt-2' />
            </div>
            
            <div className='flex flex-col gap-3'>
            {weightError? <div className='flex justify-end w-full text-[14px] text-red-600 font-bold italic'>{weightError}</div>: null}
              {technicalAppraisals &&
                technicalAppraisals?.map((item, index) => (
                  <div className='flex flex-col hover:shadow-2xl shadow-green-500 p-2 rounded-xl border-1 bg-gray-50 overflow-clip'>
                    <div className='flex justify-between'>
                      <div className='text-[13px] font-extrabold italic text-[#0d5225]'>
                          Appraisal {index + 1}
                      </div>
                      {technicalAppraisals?.length > 1 ?
                      <div 
                        className='w-[30%] ml-3 italic cursor-pointer text-[10px] hover:underline text-rose-500 pr-2 font-bold text-right'
                        onClick={() => removeTechnicalAppraisal(index)}
                      >
                        Remove Appraisal
                      </div>
                      :
                      null
                      }
                      
                    </div>
                    <div
                      className='flex flex-row items-center justify-between first-line:-mt-3 pr-2 text-[13px]'
                      key={index}
                    >
                      <div className='flex flex-row items-center !w-[100%]'>
                        <FormInput
                          placeholder='Enter Appraisal '
                          name={index + '_goal_name'}
                          type='text'
                          value={item.appraisal}
                          onInput={(e) => {
                            let newArray = JSON.parse(
                              JSON.stringify(technicalAppraisals),
                            );

                            newArray[index].appraisal = e.target.value;
                            seTechnicalAppraisals(newArray);
                          }}
                          className='!h-[30px] text-[13px] w-[100%] border-1 border-gray-300 md:min-w-[300px]'
                        />
                      </div>
                      <div className='flex flex-row justify-end items-center'>
                        <FormInput
                          type='number'
                          placeholder='Weight'
                          value={item.weight}
                          onInput={(e) => {
                              let newArray = JSON.parse(JSON.stringify(technicalAppraisals));
                              let max_grade =  100
                              let total_weight = 0;
                              newArray.forEach((keyItem, keyIndex) => {
                                if (keyIndex != index) {
                                  if (keyItem.weight != null) {
                                    total_weight = total_weight + parseFloat(keyItem.weight)
                                  } else {
                                    total_weight = total_weight + 0
                                  }
                                } else {
                                  total_weight = total_weight + parseFloat(e.target.value)
                                }
                              })
                              if (total_weight == max_grade) {
                                setWeightError(null)
                              } else {
                                setWeightError('Total key result weight must be exual to (100)')
                              }
                              newArray[index].weight = e.target.value;
                              seTechnicalAppraisals(newArray);
                          }}
                          className='!h-[30px] text-[13px] w-[100%] border-1 border-gray-300 min-w-[100px]'
                        />
                      </div>
                    </div>
                  </div>
                ))}
              
              <div className='flex justify-left w-full mb-3 items-center gap-2'>
                <img
                    src={AddSmallSVG}
                    alt={'add_appraisal'}
                    className={'h-[15px] w-[15px]'}
                />
                <span
                  className='p2-medium cursor-pointer hover:underline text-color-black duration-75 italic text-[13px] text-[#0d5225]'
                  onClick={() => addAppraisal()}
                >
                  New Appraisal
                </span>
              </div>
              <hr className='divider -mt-5' />
            </div>
            {/* <div className='mt-2'>
              <FormSwitch
                label={'Do you want evaluation to be done automatically?'}
                name={'auto_evaluate'}
                checked={auto_evaluate}
                onClick={() =>
                    setAutoEvaluate(!auto_evaluate)
                }
                value={auto_evaluate}
              />
            </div>
            {auto_evaluate?
            <div className='flex flex-col gap-2 -mt-1 mb-4 !w-full border rounded-md p-2 md:gap-3 bg-gray-50 '>
              <div className='text-[23px] font-normal'>Auto-evaluation information</div>
              <div className='flex md:flex-row flex-col gap-2 justify-start items-center text-[13px] mr-4 flex-wrap'>
                <FormSelect
                  name={'evaluation_frequency'}
                  value={evaluation_frequency}
                  options={evaluationFrequencyOptions}
                  onChange={(selected) => {
                      setEvaluationFrequency(selected)
                      if (selected == 'weekly') {
                          setDateType('monday')
                          setDateTypeOptions([
                              {label: 'Monday', value:'monday'},
                              {label: 'Tuesday', value:'tuesday'},
                              {label: 'Wednesday', value:'wednesday'},
                              {label: 'Thursday', value:'thursday'},
                              {label: 'Friday', value:'friday'},
                              {label: 'Saturday', value:'saturday'},
                              {label: 'Sunday', value:'sunday'},
                          ])
                      } else if (selected == 'monthly') {
                        setDateType('day')
                        setDay(1)
                        setDateTypeOptions([
                            {label: 'Day', value:'day'},
                            {label: 'First', value:'first'},
                            {label: 'Second', value:'second'},
                            {label: 'Third', value:'third'},
                            {label: 'Fourth', value:'fourth'},
                            {label: 'Last', value:'last'},
                        ])
                        setDayOptions(
                            [
                                {label: '1st', value: '01'},
                                {label: '2nd', value: '02'},
                                {label: '3rd', value: '03'},
                                {label: '4th', value: '04'},
                                {label: '5th', value: '05'},
                                {label: '6th', value: '06'},
                                {label: '7th', value: '07'},
                                {label: '8th', value: '08'},
                                {label: '9th', value: '09'},
                                {label: '10th', value: '10'},
                                {label: '11th', value: '11'},
                                {label: '12th', value: '12'},
                                {label: '13th', value: '13'},
                                {label: '14th', value: '14'},
                                {label: '15th', value: '15'},
                                {label: '16th', value: '16'},
                                {label: '17th', value: '17'},
                                {label: '18th', value: '18'},
                                {label: '19th', value: '19'},
                                {label: '20th', value: '20'},
                                {label: '21st', value: '21'},
                                {label: '22nd', value: '22'},
                                {label: '23rd', value: '23'},
                                {label: '24th', value: '24'},
                                {label: '25th', value: '25'},
                                {label: '26th', value: '26'},
                                {label: '27th', value: '27'},
                                {label: '28th', value: '28'},
                                {label: 'Last', value: 'last'},
                            ]
                        )
                      } else if (selected == 'yearly') {
                        setDateType('january')
                        setDay(1)
                        setDateTypeOptions([
                            {label: 'January', value:'january'},
                            {label: 'February', value:'february'},
                            {label: 'March', value:'march'},
                            {label: 'April', value:'april'},
                            {label: 'May', value:'may'},
                            {label: 'June', value:'june'},
                            {label: 'July', value:'july'},
                            {label: 'August', value:'august'},
                            {label: 'September', value:'september'},
                            {label: 'October', value:'october'},
                            {label: 'November', value:'november'},
                            {label: 'December', value:'december'},
                        ])
                        setDayOptions(
                            [
                                {label: '1st', value: '01'},
                                {label: '2nd', value: '02'},
                                {label: '3rd', value: '03'},
                                {label: '4th', value: '04'},
                                {label: '5th', value: '05'},
                                {label: '6th', value: '06'},
                                {label: '7th', value: '07'},
                                {label: '8th', value: '08'},
                                {label: '9th', value: '09'},
                                {label: '10th', value: '10'},
                                {label: '11th', value: '11'},
                                {label: '12th', value: '12'},
                                {label: '13th', value: '13'},
                                {label: '14th', value: '14'},
                                {label: '15th', value: '15'},
                                {label: '16th', value: '16'},
                                {label: '17th', value: '17'},
                                {label: '18th', value: '18'},
                                {label: '19th', value: '19'},
                                {label: '20th', value: '20'},
                                {label: '21st', value: '21'},
                                {label: '22nd', value: '22'},
                                {label: '23rd', value: '23'},
                                {label: '24th', value: '24'},
                                {label: '25th', value: '25'},
                                {label: '26th', value: '26'},
                                {label: '27th', value: '27'},
                                {label: '28th', value: '28'},
                                {label: 'Last', value: 'last'},
                            ]
                        )
                      }
                  }}
                  label='How Often?'
                  extraClass={'!max-w-[120px] !min-w-[120px] !text-[12px] !pl-2'}
                />
                <div className={'flex gap-2 mt-3 items-center'}>
                  {evaluation_frequency !== 'daily' ?
                  <>
                      <span className='min-w-fit pb-1'>on</span>
                      <FormSelect
                          options={dateTypeOptions}
                          value={date_type}
                          
                          onChange={(selected) => {
                              setDateType(selected)
                              if (evaluation_frequency == 'monthly' && selected !== 'day') {
                                setDay('monday')
                                setDayOptions(
                                    [
                                        {label: 'Monday', value:'monday'},
                                        {label: 'Tuesday', value:'tuesday'},
                                        {label: 'Wednesday', value:'wednesday'},
                                        {label: 'Thursday', value:'thursday'},
                                        {label: 'Friday', value:'friday'},
                                        {label: 'Saturday', value:'saturday'},
                                        {label: 'Sunday', value:'sunday'},
                                    ],
                                )
                              } else {
                                  setDay(1)
                                  setDayOptions(
                                      [
                                          {label: '1st', value: '01'},
                                          {label: '2nd', value: '02'},
                                          {label: '3rd', value: '03'},
                                          {label: '4th', value: '04'},
                                          {label: '5th', value: '05'},
                                          {label: '6th', value: '06'},
                                          {label: '7th', value: '07'},
                                          {label: '8th', value: '08'},
                                          {label: '9th', value: '09'},
                                          {label: '10th', value: '10'},
                                          {label: '11th', value: '11'},
                                          {label: '12th', value: '12'},
                                          {label: '13th', value: '13'},
                                          {label: '14th', value: '14'},
                                          {label: '15th', value: '15'},
                                          {label: '16th', value: '16'},
                                          {label: '17th', value: '17'},
                                          {label: '18th', value: '18'},
                                          {label: '19th', value: '19'},
                                          {label: '20th', value: '20'},
                                          {label: '21st', value: '21'},
                                          {label: '22nd', value: '22'},
                                          {label: '23rd', value: '23'},
                                          {label: '24th', value: '24'},
                                          {label: '25th', value: '25'},
                                          {label: '26th', value: '26'},
                                          {label: '27th', value: '27'},
                                          {label: '28th', value: '28'},
                                          {label: 'Last', value: 'last'},
                                      ]
                                  )
                              }
                          }}
                          extraClass={'!max-w-[100px] !min-w-[100px] !text-[13px] !pl-2'}
                      />
                      {evaluation_frequency !== 'weekly' ?
                      <FormSelect
                        options={dayOptions}
                        value={day}
                        onChange={(selected) => {
                          setDay(selected)
                        }}
                        extraClass={'!max-w-[100px] !min-w-[100px] !text-[13px]'}
                      />
                      :
                      null}
                  </> :
                  null
                  }
                  <span className='max-w-fit min-w-fit pb-1'>every</span>
                  <div>
                    <FormInput
                      name='interval_count'
                      type='number'
                      className={'!max-w-[40px]'}
                      value={interval_count}
                      onInput={(e) => {
                        setIntervalCount(e.target.value);
                      }}
                    />
                  </div>
                  {evaluation_frequency == 'daily' ?
                  <span className='min-w-fit pb-1'>day(s), </span>
                  :evaluation_frequency == 'weekly' ?
                  <span className='min-w-fit pb-1'>week(s), </span>
                  :evaluation_frequency == 'monthly' ?
                  <span className='min-w-fit pb-1'>month(s), </span>
                  :evaluation_frequency == 'yearly' ?
                  <span className='min-w-fit pb-1'>year(s), </span>
                  :
                  null}
                  <span className='min-w-fit pb-1'>starts on</span>
                  <div>
                    <FormInput
                      name='start_date'
                      type='date'
                      
                      className={'!max-w-[150px] !min-w-[150px] !pb-[15px] !text-[13px]'}
                      value={auto_evaluation_start_date}
                      onInput={(e) => {
                          setAutoEvaluationStartDate(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className={'flex md:mt-3 gap-2 items-center'}>
                    <span className='min-w-fit pb-1'>ends</span>
                    <FormSelect
                      options={endTypeOptions}
                      value={end_type}
                      
                      onChange={(selected) => {
                        setEndType(selected);
                      }}
                      extraClass={'!max-w-[80px] !min-w-[80px] !text-[13px] !pl-2'}
                    />
                    {end_type !== 'none' && (
                      <>
                        {end_type == 'by' ?
                        <FormInput
                          name='end_date'
                          type='date'
                          value={auto_evaluation_end_date}
                          onInput={(e) => {
                            setAutoEvaluationEndDate(e.target.value);
                          }}
                          className={'!max-w-[150px] !min-w-[150px] !pb-[15px] !text-[13px]'}
                        />
                        :
                        <div className='flex gap-2 items-center'>
                          <FormInput
                            name='occurrences'
                            type='text'
                            className={'!max-w-[50px] !pl-2'}
                            
                            value={occurrences}
                            onInput={(e) => {
                              setOccurences(e.target.value)
                            }}
                          />
                          <span className='min-w-fit pb-1'>occurrences</span>
                        </div>
                        }
                      </>
                    )}
                </div>
              </div>
            </div>
            :
            null} */}
            <div className='w-full'>
              <Button
                text={buttonText}
                theme={'third'}
                type='button'
                onClick={()=> submitForm()}
                loading={addTechnicalAppraisalloading || editTechnicalAppraisalloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddTechnicalAppraisals;
