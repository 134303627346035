import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import {
  useGetVendorsToPay,
  useRunVendorPay,
} from '../../../../redux/spend-management/vendor/hook';
import {
  useGetBanks,
  useResolveBankAccount,
} from '../../../../redux/bank/hook';
import FormDatalist from '../../../form-datalist';
import FormSwitch from '../../../form-switch';
import AddVendor from './add-vendor';
import FormTextArea from '../../../form-textarea';
import { useHistory } from 'react-router-dom';
import Modal from '../../modal';

function PayVendor({ isOpen, closeModal, refresh, completed, vendorData }) {
  const {
    register,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { data: vendors } = useGetVendorsToPay(100000, 1, -1, '');

  const { mutateAsync: runVendor, isLoading: runloading } = useRunVendorPay();

  const [vendorId, setVendorId] = useState(null);

  const [vendorName, setVendorName] = useState('');
  const [vendorEmail, setVendorEmail] = useState('');
  const [vendorMobile, setVendorMobile] = useState('');
  const [description, setDescription] = useState('');

  const [contact_person, setContactPerson] = useState('');

  const [bankname, setBankName] = useState('');
  const [account_number, setAccountNumber] = useState('');
  const [account_name, setAccountName] = useState('');

  const [amount, setAmount] = useState();

  const [saveVendor, setSaveVendor] = useState(false);

  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } =
    useResolveBankAccount();
  const { data: banks } = useGetBanks();

  const history = useHistory();

  const [showAddVendor, setShowAddVendor] = useState(false);
  const [payExisting, setPayExisting] = useState(true);

  useEffect(() => {
    if (vendors) {
      if (vendors.length <= 0) {
        setPayExisting(false);
      }
    }
  }, [vendors]);

  useEffect(() => {
    if (vendorData) {
      setPayExisting(true);
      setVendorId(vendorData.id)
      setVendorName(vendorData.vendor_name);
      setBankName(vendorData.bank);
      setAccountNumber(vendorData.account_number);
      setAccountName(vendorData.account_name);
      setContactPerson(vendorData.contact_person);
      setVendorEmail(vendorData.email);
      setVendorMobile(vendorData.mobile);
    }
  }, [vendorData]);

  function onrunVendorSuccess(ref) {
    history.push(`/vendor/pay/preview/${ref}`);
  }

  useEffect(() => {
    if (account_number.length >= 10 && bankname !== '') {
      let payload = {
        bankname,
        account_number,
      };
      setAccountName('');
      resolveAccount(payload)
        .then((response) => {
          setAccountName(response.data.accountname);
        })
        .catch(() => {
          setAccountName('Not Resolved');
        });
    }
  }, [account_number, bankname]);

  const submitForm = async (e) => {
    e.preventDefault();
    let payload = {
      vendor_id: vendorId,
      vendor_name: vendorName,
      contact: contact_person,
      email: vendorEmail,
      mobile: vendorMobile,
      description: description,
      bank_name: bankname,
      account_number: account_number,
      account_name: account_name,
      amount: amount,
      save_vendor: saveVendor ? 1 : 0,
    };

    await runVendor(payload).then((result) => {
      onrunVendorSuccess(result.data.ref);
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      {showAddVendor ? (
        <AddVendor
          isOpen={showAddVendor}
          closeModal={() => {
            setShowAddVendor(false);
            closeModal();
          }}
          vendorData={null}
          title='Add Vendor'
          buttonText='ADD'
        />
      ) : null}
      <div className='flex flex-col min-h-[550px] overflow-auto'>
        <form className='form pb-4'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{'Pay Single Vendor'}</span>
              <div className='cursor-pointer' onClick={() => completed()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8 mt-2 '>
            {vendors && vendors.length > 0 ? (
              <>
                <div className='w-full md:w-[50%] p4-bold'>
                  <FormSwitch
                    label={'Pay Existing Vendor'}
                    checked={payExisting}
                    onClick={() => {
                      setVendorId(null);
                      setVendorName('');
                      setBankName('');
                      setAccountNumber('');
                      setAccountName('');
                      setContactPerson('');
                      setVendorEmail('');
                      setVendorMobile('');
                      setPayExisting(!payExisting);
                    }}
                  />
                </div>
                <hr className='divider -mt-2' />
              </>
            ) : null}
            {payExisting && vendors && vendors.length > 0 ? (
              <div>
                <div className='w-full'>
                  <div className='p-2 rounded border bg-highlight'>
                    <div className='flex flex-col md:flex-row w-full justify-between'>
                      <div className='w-full md:w-1/2 md:pr-2'>
                        <FormDatalist
                          label='Vendor Name'
                          data={vendors ? vendors : []}
                          defaultText={vendorName}
                          onSelect={(value) => {
                            let index = vendors.findIndex(
                              (item) => item.value === value,
                            );
                            if (index !== -1) {
                              setVendorId(value);
                              setVendorName(vendors[index].label);
                              setBankName(vendors[index].bank);
                              setAccountNumber(vendors[index].account_number);
                              setAccountName(vendors[index].account_name);
                              setContactPerson(vendors[index].contact_person);
                              setVendorEmail(vendors[index].email);
                              setVendorMobile(vendors[index].mobile);
                            }
                          }}
                        />
                      </div>
                      <div className='w-full md:w-1/2 md:pl-2'>
                        <FormInput
                          label='Contact Person - ReadOnly'
                          name='contact_person'
                          placeholder={'Auto-generated'}
                          type='text'
                          readOnly
                          value={contact_person}
                        />
                      </div>
                    </div>
                    <div className='flex flex-col md:flex-row w-full justify-between'>
                      <div className='w-full md:w-3/5 md:pr-2'>
                        <FormInput
                          label='Email - ReadOnly'
                          name='email'
                          type='email'
                          placeholder={'Auto-generated'}
                          value={vendorEmail}
                          readOnly
                        />
                      </div>
                      <div className='w-full md:w-2/5 md:pl-2'>
                        <FormInput
                          label='Mobile - ReadOnly'
                          name='mobile'
                          type='text'
                          placeholder={'Auto-generated'}
                          value={vendorMobile}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <hr className='divider' />
                  <div className='flex flex-col md:flex-row w-full justify-between'>
                    <div className='w-full md:w-1/2 md:pr-2'>
                      <FormInput
                        label='Bank - ReadOnly'
                        name='bank_name'
                        type='text'
                        readOnly
                        value={bankname}
                      />
                    </div>

                    <div className='w-full md:w-1/2 md:pl-2'>
                      <FormInput
                        label='Account Number - ReadOnly'
                        name='account_number'
                        type='text'
                        placeholder={'Auto-generated'}
                        readOnly
                        value={account_number}
                      />
                    </div>
                  </div>
                  <FormInput
                    label='Bank Account Name (Auto-Generated)'
                    name='account_name'
                    type='text'
                    placeholder={'Auto-generated'}
                    readOnly
                    value={account_name}
                  />
                  <FormInput
                    label='Description'
                    name='description'
                    type='text'
                    inputRef={register(formValidation('text', true))}
                    error={errors.description}
                    errorMessage={
                      errors.description && errors.description.message
                    }
                    onInput={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                  <FormInput
                    label='Amount'
                    name='amount'
                    type='number'
                    inputRef={register(formValidation('number', true, 0))}
                    error={errors.amount}
                    errorMessage={errors.amount && errors.amount.message}
                    onInput={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className='flex flex-col md:flex-row w-full justify-between'>
                  <div className='w-full md:w-1/2 md:pr-2'>
                    <FormInput
                      label='Vendor Name'
                      name='vendor_name'
                      type='text'
                      inputRef={register(formValidation('text', false))}
                      readOnly={runloading}
                      error={errors.vendor_name}
                      errorMessage={
                        errors.vendor_name && errors.vendor_name.message
                      }
                      onInput={(e) => {
                        setVendorName(e.target.value);
                      }}
                    />
                  </div>

                  <div className='w-full md:w-1/2 md:pl-2'>
                    <FormInput
                      label='Contact Person - (Optional)'
                      name='contact_person'
                      type='text'
                      value={contact_person}
                      onInput={(e) => {
                        setContactPerson(e.target.value);
                      }}
                      readOnly={runloading}
                      error={errors.contact_person}
                      errorMessage={
                        errors.contact_person && errors.contact_person.message
                      }
                    />
                  </div>
                </div>
                <div className='flex flex-col md:flex-row w-full justify-between'>
                  <div className='w-full md:w-3/5 md:pr-2'>
                    <FormInput
                      label='Email'
                      name='email'
                      type='email'
                      inputRef={register(formValidation('email', false))}
                      readOnly={runloading}
                      error={errors.email}
                      errorMessage={errors.email && errors.email.message}
                      onInput={(e) => {
                        setVendorEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className='w-full md:w-2/5 md:pl-2'>
                    <FormInput
                      label='Mobile'
                      name='mobile'
                      type='text'
                      inputRef={register(formValidation('text', false))}
                      readOnly={runloading}
                      error={errors.mobile}
                      errorMessage={errors.mobile && errors.mobile.message}
                      onInput={(e) => {
                        setVendorMobile(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className='flex flex-col md:flex-row w-full justify-between'>
                  <div className='w-full md:w-3/5 md:pr-2'>
                    <FormDatalist
                      label='Bank Name'
                      data={banks ? banks : []}
                      value={bankname}
                      onSelect={(value) => {
                        setAccountNumber('')
                        setBankName(value)
                      }}
                    />
                  </div>

                  <div className='w-full md:w-2/5 md:pl-2'>
                    <FormInput
                      label='Bank Account Number'
                      name='account_number'
                      type='text'
                      defaultValue={account_number}
                      inputRef={register(formValidation('text', true))}
                      error={errors.account_number}
                      errorMessage={
                        errors.account_number && errors.account_number.message
                      }
                      onInput={(e) => setAccountNumber(e.target.value)}
                    />
                  </div>
                </div>
                <FormInput
                  label='Bank Account Name (Auto-Generated)'
                  name='account_name'
                  type='text'
                  placeholder={
                    resolveAccountLoading ? 'Fetching Account Name ....' : 'Auto generated'
                  }
                  readOnly
                  inputRef={register(formValidation('text', true))}
                  value={account_name}
                  error={errors.account_name}
                  errorMessage={
                    errors.account_name && errors.account_name.message
                  }
                />
                <FormTextArea
                  label='Description'
                  name='description'
                  type='text'
                  inputRef={register(formValidation('text', true))}
                  error={errors.description}
                  errorMessage={
                    errors.description && errors.description.message
                  }
                  onInput={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                <FormInput
                  label='Amount'
                  name='amount'
                  type='number'
                  inputRef={register(formValidation('number', true))}
                  error={errors.amount}
                  errorMessage={errors.amount && errors.amount.message}
                  onInput={(e) => {
                    setAmount(e.target.value);
                  }}
                />
                <div className='w-full md:w-[60%] p4-bold'>
                  <FormSwitch
                    label={'Save Vendor'}
                    checked={saveVendor}
                    onClick={() => {
                      setSaveVendor(!saveVendor);
                    }}
                  />
                </div>
              </div>
            )}
            <div className='w-full'>
              <Button
                text={'CONTINUE'}
                type='submit'
                disabled={
                  !vendorName &&
                  !vendorMobile &&
                  !description &&
                  !bankname &&
                  !account_number &&
                  !account_name &&
                  !amount
                }
                loading={runloading}
                onClick={(e) => {
                  submitForm(e);
                }}
              />
            </div>
          </div>
          <div
            className='flex justify-center w-full cursor-pointer'
            onClick={() => closeModal()}
          >
            <span className='p2-medium text-color-black mt-3'>Back</span>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default PayVendor;
