import React, { useState } from 'react';
import Button from '../../../button';
import ButtonSelect from '../../../button-select';
import AddVendor from './add-vendor';
import CloseSVG from '../../../../assets/svg/close.svg';
import BulkAddVendors from './add-bulk-vendor';
import PayVendor from './pay-single-vendor';
import BulkPayVendors from './pay-multiple-vendors';
import Modal from '../../modal';

function SelectVendorPayOption({ isOpen, closeModal, refresh, viewData }) {
  const [option, setOption] = useState('single');
  const [showAddVendor, setShowAddVendor] = useState(false);
  const [showAddBulkVendors, setShowAddBulkVendors] = useState(false);

  function submit() {
    if (option === 'single') {
      setShowAddVendor(true);
    } else if (option === 'bulk') {
      setShowAddBulkVendors(true);
    }
  }
  return (
    <>
      {showAddVendor ? (
        <PayVendor
          isOpen={showAddVendor}
          closeModal={() => {
            setShowAddVendor(false);
          }}
          completed={() => {
            setShowAddVendor(false);
            closeModal();
          }}
          bonusData={null}
          title='Add Vendor'
          buttonText='ADD'
        />
      ) : null}

      <BulkPayVendors
        isOpen={showAddBulkVendors}
        closeModal={() => setShowAddBulkVendors(false)}
        completed={() => {
          setShowAddBulkVendors(false);
          closeModal();
        }}
      />

      <Modal
        scrollable={true}
        className='!md:max-w-[600px] max-w-full !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Pay Vendor(s)</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col items-center w-full'>
            <span className='text-left'>
              Select your desired option to add Vendors
            </span>
            <div className='flex flex-col justify-center h-full min-h-[400px] max-w-[85%] w-full -mt-4'>
              <ButtonSelect
                title={'Pay Single Vendor'}
                subtitle={'Pay your Vendors one after the other'}
                isActive={option === 'single'}
                onClick={() => setOption('single')}
              />
              <ButtonSelect
                title={'Pay Multiple Vendors'}
                subtitle={
                  'Pay multiple Vendors at the same time using a XLSX file'
                }
                isActive={option === 'bulk'}
                onClick={() => setOption('bulk')}
                className='mt-8'
              />
              <div className='w-full mt-[20px]'>
                <Button text='NEXT' type='submit' onClick={() => submit()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SelectVendorPayOption;
