import React, { useEffect, useRef, useState } from 'react';
import TickSVG from '../../assets/svg/tick.svg';
import { useHistory } from 'react-router-dom';
import Logo from '../logo';
import ProfileMenu from '../profile-menu';
import { useSelector } from 'react-redux';
import UpdateUserMobileModal from '../modal/update-user-mobile';
import { store } from '../../redux';
import { updateModalStatus } from '../../redux/components/components-slice';

const OnboardingLayout = ({ children, step }) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const { user } = useSelector((state) => state.userSlice);
  const { section } = useSelector((state) => state.sectionSlice);

  const [showUserMobileModal, setShowUserMobileModal] = useState(false)
  
  const steps = useRef([
    
    {
      title: 'Tell us about your company',
      subtitle: `Let's know about your company to help us provide a better experience for you.`,
      completed: true,
    },
    {
      title: 'Add Salary Settings',
      subtitle: 'Add your salary settings here. Add your salary breakdown so that you never have to compute it again. You can also skip this step and add later.',
      completed: false,
    },
  ]);

  useEffect(() => {
    if (step) {
      setCurrentStep(step);
    }
  }, [step, currentStep]);

  useEffect(() => {
    if (user) {
      if (user.mobile) {
        setShowUserMobileModal(false)
      } else {
        store.dispatch(updateModalStatus(true))
        setShowUserMobileModal(true)
      }
    }
  }, [user]);

  return (
    <div className='flex flex-col h-screen min-h-screen max-h-screen overflow-auto'>
      {showUserMobileModal?
        <UpdateUserMobileModal
          isOpen={showUserMobileModal}
          closeModal={() => {
            setShowUserMobileModal();
          }}
        />
        :
        null
      }
      <div className='flex flex-col'>
        <div className='flex justify-end'>
          <div className='mt-[20px] mr-[36px]'>
            <ProfileMenu />
          </div>
        </div>

        {/* Desktop Sidebar */}
        <div className='flex flex-col container justify-center lg:justify-start !px-[40px] lg:px-0 '>
          <div>
            <button
              className='hidden lg:flex px-4'
              onClick={() => history.push('/dashboard')}
            >
              <Logo width='130px' height='50px' />
            </button>
            <div className='flex flex-col mt-[25px]'>
              {/* Mobile Stepper */}
              <div className='flex lg:hidden justify-center w-full mb-[10px]'>
                <div className='flex flex-row w-[85%] my-3'>
                  {steps.current.map((item, index) => (
                    <div
                      key={index}
                      className={`${
                        index + 1 !== steps.current.length
                          ? 'flex flex-row w-full items-center'
                          : ''
                      }`}
                    >
                      <div>
                        <div
                          className={`flex items-center justify-center w-[30px] h-[30px] rounded-[30px] ${
                            index + 1 <= currentStep &&
                            section &&
                            section.name !== 'core hr'
                              ? 'bg-secondary-2'
                              : index + 1 <= currentStep &&
                                section &&
                                section.name === 'core hr'
                              ? 'bg-[#0d5225]'
                              : 'border border-color-black'
                          }  ${
                            index + 1 === currentStep
                              ? 'opacity-100'
                              : 'opacity-40'
                          }`}
                        >
                          {index < currentStep - 1 ? (
                            <img
                              src={TickSVG}
                              alt='tick'
                              style={{ width: '16px', height: '16px' }}
                            />
                          ) : (
                            <span
                              className={`text-[16px] font-bold ${
                                index + 1 <= currentStep
                                  ? 'text-white'
                                  : 'text-color-black'
                              }`}
                            >
                              {index + 1}
                            </span>
                          )}
                        </div>
                      </div>

                      {index + 1 !== steps.current.length ? (
                        <div className='bg-color-border w-full h-[1px]'></div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
              <div className='flex flex-col lg:flex-row w-full'>
                <div className='w-full lg:w-[50%] flex flex-col lg:justify-start justify-center'>
                  <div className='w-full flex flex-col'>
                    {/* Header */}
                    <span className='header-3 !font-normal'>
                      Welcome,{' '}
                      <span className='font-bold header-4'>
                        {user?.first_name + ' ' + user?.last_name}
                      </span>
                    </span>
                    <span className='p3 mt-2 text-color-black text-opacity-80'>
                      Let’s get you started in 4 easy steps.
                    </span>
                  </div>

                  <hr className='md:hidden flex divider' />

                  <div className='hidden lg:flex w-full mt-5'>
                    <div className='flex flex-col w-[100%]'>
                      {steps.current.map((item, index) => (
                        <div 
                          key={index}
                          className='flex flex-row mb-[80px]'
                        >
                          <div className='flex items-center w-[15%]'>
                            <div
                              className={`flex items-center justify-center  w-[50px] h-[50px] rounded-[50px] ${
                                user?.onboardingStatus > 3 && index == 2 &&
                                section &&
                                section.name !== 'core hr'?
                                'bg-secondary-2'
                                : user?.onboardingStatus > 3 && index == 2 &&
                                section &&
                                section.name === 'core hr'?
                                'bg-[#0d5225]'
                                : index + 1 <= currentStep &&
                                section &&
                                section.name !== 'core hr'
                                  ? 'bg-secondary-2'
                                  : index + 1 <= currentStep &&
                                    section &&
                                    section.name === 'core hr'
                                  ? 'bg-[#0d5225]'
                                  : 'border border-color-black'
                              }  ${
                                index + 1 === currentStep
                                  ? 'opacity-100 '
                                  : 'opacity-40 '
                              }`}
                            >
                              { user?.onboardingStatus > 3 && index == 2?
                               (
                                <img
                                  src={TickSVG}
                                  alt='tick'
                                  style={{ width: '20px', height: '20px' }}
                                />
                              ) : index < currentStep - 1 ? (
                                <img
                                  src={TickSVG}
                                  alt='tick'
                                  style={{ width: '20px', height: '20px' }}
                                />
                              ) : (
                                <span
                                  className={`header-3 ${
                                    index + 1 <= currentStep
                                      ? 'text-white'
                                      : 'text-color-black'
                                  }`}
                                >
                                  {index + 1}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='flex flex-col w-[85%]'>
                            <span
                              className={`text-[20px] font-bold text-color-black leading-[23px] ${
                                index + 1 === currentStep
                                  ? 'text-opacity-100'
                                  : 'text-opacity-40'
                              }`}
                            >
                              {item.title}
                            </span>
                            <div className='w-[80%] mt-[4px] text-justify'>
                              <span
                                className={`text-[13px] text-justify leading-[20px] text-color-black ${
                                  index + 1 === currentStep
                                    ? 'text-opacity-80'
                                    : 'text-opacity-40'
                                } `}
                              >
                                {item.subtitle}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className='w-full lg:w-[60%] md:-mt-[55px]'>
                  {/* Page components */}
                  <div className='lg:max-h-[90vh] overflow-auto'>
                    <div className='w-full flex items-center  justify-center flex-col '>
                      <div className='w-full md:px-8 px-4 flex flex-col lg:flex-row'>
                        <div className='flex lg:hidden md:hidden mb-8 lg:mb-0 w-full mt-4'>
                          <div className='flex flex-col'>
                            <span
                              className={
                                'text-[18px] font-bold text-color-black leading-[23px] text-opacity-100'
                              }
                            >
                              {steps.current[currentStep - 1].title}
                            </span>
                            <div className='mt-[4px]'>
                              <span
                                className={
                                  'text-[13px] text-justify leading-[20px] text-color-black text-opacity-80'
                                }
                              >
                                {steps.current[currentStep - 1].subtitle}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='w-full pb-[50px]'>{children}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingLayout;
