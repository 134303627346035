import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../../utils/functions";
import Button from "../../../button";
import FormFileInput from "../../../form-file-input";
import CloseSVG from "../../../../assets/svg/close.svg";
import { useBulkAddBonus } from "../../../../redux/employees/hook/bonuses";
import FormSelect from "../../../form-select";
import FormSelectEmployee from "../../../employees/form-select-employee";
import FormSelectTeam from "../../../employees/form-select-team";
import FormSelectDepartment from "../../../employees/form-select-department";
import FormSelectBranch from "../../../employees/form-select-branch";
import Modal from "../../modal";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import FormInput from "../../../form-input";
import { employees as employeeAPI } from "../../../../api/employees";
import AsyncSelect from "react-select/async";

function BulkAddAttendanceSchedule({ isOpen, closeModal, refresh, completed }) {
  const { mutateAsync, isLoading } = useBulkAddBonus();
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });
  const [sourceName, setSourceName] = useState(null);
  const [source, setSource] = useState("employee");
  const [sourceId, setSourceId] = useState(null);

  const sourceOptions = [
    { label: "Employee", value: "employee" },
    { label: "Team", value: "team" },
    { label: "Department", value: "department" },
    { label: "Branch", value: "branch" },
    { label: "Company", value: "company" },
  ];

  const [employee, setEmployee] = useState();
  const [team, setTeam] = useState();
  const [department, setDepartment] = useState();
  const [branch, setBranch] = useState();

  const workbook = new Excel.Workbook();

  const columns = [
    { header: "Schedule Date (YYYY-MM-DD)", key: "schedule_date" },
    { header: "Schedule Name", key: "schedule_name" },
    { header: "Resumption Time (HH:MM)", key: "resumption_time" },
    { header: "Closing Time (HH:MM)", key: "closing_time" },
    { header: "Working Day (yes/no)", key: "working_day" },
  ];

  const [scheduleStart, setScheduleStart] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [scheduleEnd, setScheduleEnd] = useState(
    moment().add(7, "days").format("YYYY-MM-DD")
  );

  const [exportLoading, setSetExportLoading] = useState(false);

  const exportTemplate = async () => {
    setSetExportLoading(true);
    let data = [];

    let currDate = moment(scheduleStart).startOf("day");
    let lastDate = moment(scheduleEnd).startOf("day");

    while (currDate.diff(lastDate) < 1) {
      data.push({ schedule_date: currDate.format("YYYY-MM-DD") });
      currDate = currDate.add(1, "days");
    }

    const workSheetName = "schedule_data";
    const workBookName = `Attedance schedule data (pre-filled)`;
    try {
      const fileName = workBookName;

      const worksheet = workbook.addWorksheet(workSheetName);
      worksheet
        .getColumn("E")
        .eachCell({ includeEmpty: true }, function (cell, rowNumber) {
          cell.dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: ["yes", "no"],
          };
        });

      worksheet.columns = columns;
      worksheet.getRow(1).font = { bold: true };

      worksheet.columns.forEach((column) => {
        if (column._number == 5 || column._number == 6) {
          column.width = column.header.length;
          column.numFmt = "hh:mm";
        }
      });

      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 2;
        column.style.alignment = { horizontal: "left" };
      });

      data?.forEach((singleData) => {
        singleData.schedule_name = source + " schedule";
        singleData.resumption_time = "";
        singleData.closing_time = "";
        singleData.working_day = "yes";
        worksheet.addRow(singleData);
      });
      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: true }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: "none" },
            left: { style: "none" },
            bottom: { style: "none" },
            right: { style: "none" },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      // console.error('<<<ERRROR>>>', error);
      // console.error('Something Went Wrong', error.message);
    } finally {
      workbook.removeWorksheet(workSheetName);
    }
    setSetExportLoading(false);
  };

  async function submitForm(data) {
    let source_id = null;
    if (source === "employee") {
      source_id = employee;
    } else if (source === "team") {
      source_id = team;
    } else if (source === "department") {
      source_id = department;
    } else if (source === "branch") {
      source_id = branch;
    }
    const formData = new FormData();
    formData.append("file", data?.file[0]);
    formData.append("source_id", source_id);
    formData.append("source", source);

    await mutateAsync(formData).then(() => {
      completed();
    });
  }

  return (
    <Modal
      scrollable={true}
      className="!max-w-[700px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col overflow-auto">
        <div className="pl-8 pr-8 pt-4">
          <div className="flex flex-row justify-between items-center">
            <span className="header-4">Add Bulk Attendance Schedule</span>
            <div className="cursor-pointer" onClick={() => closeModal()}>
              <img src={CloseSVG} alt="close" />
            </div>
          </div>
        </div>
        <hr className="divider mb-2" />
        <form className="form px-8 pb-4" onSubmit={handleSubmit(submitForm)}>
          <div className="flex flex-col w-full justify-between p-3 py-3 pb-2 border rounded-md bg-secondary-2-extralight">
            <div className="flex flex-row md:flex-col w-full justify-between">
              <div className="w-full md:w-1/3 pr-2">
                <FormSelect
                  defaultValue={source}
                  options={sourceOptions}
                  onChange={(selected) => {
                    setSource(selected);
                    setSourceId(null);
                  }}
                  extraClass={"!bg-white"}
                  label="Schedule For:"
                />
              </div>
              <div className="w-full md:w-2/3 pl-2">
                {!source || source === "company" ? null : source ===
                  "employee" ? (
                  <FormSelectEmployee
                    label={"Employee"}
                    name="employee"
                    placeholder="Select Employee"
                    value={employee}
                    empName={sourceName}
                    setSourceName={setSourceName}
                    setEmployee={setEmployee}
                  />
                ) : source === "team" ? (
                  <FormSelectTeam
                    label={"Select Team"}
                    name="team"
                    placeholder="Select Team"
                    value={team}
                    teamName={sourceName}
                    setSourceName={setSourceName}
                    setTeam={setTeam}
                  />
                ) : source === "department" ? (
                  <FormSelectDepartment
                    label={"Department"}
                    name="department"
                    placeholder="Select Department"
                    value={department}
                    departmentName={sourceName}
                    setSourceName={setSourceName}
                    setDepartment={setDepartment}
                  />
                ) : (
                  <FormSelectBranch
                    label={"Branch"}
                    name="branch"
                    placeholder="Select Branch"
                    value={branch}
                    branchName={sourceName}
                    setSourceName={setSourceName}
                    setBranch={setBranch}
                  />
                )}
              </div>
            </div>
            {source && source == "company" ? (
              <div className="flex flex-row md:flex-col w-full justify-between">
                <div className="w-full md:w-1/2 pr-2">
                  <FormInput
                    label="Schedule Start"
                    name="schedule_start"
                    type="date"
                    value={scheduleStart}
                    className={"!bg-white"}
                    onInput={(e) => {
                      if (e.target.value > scheduleEnd) {
                        setScheduleEnd(e.target.value);
                      }
                      setScheduleStart(e.target.value);
                    }}
                  />
                </div>
                <div className="w-full md:w-1/2 pl-2">
                  <FormInput
                    label="Schedule End"
                    name="schedule_end"
                    type="date"
                    value={scheduleEnd}
                    className={"!bg-white"}
                    min={moment(scheduleStart).format("YYYY-MM-DD")}
                    onInput={(e) => {
                      setScheduleEnd(e.target.value);
                    }}
                  />
                </div>
              </div>
            ) : source &&
              source !== "company" &&
              (team || employee || department || branch) ? (
              <div className="flex flex-row md:flex-col w-full justify-between">
                <div className="w-full md:w-1/2 pr-2">
                  <FormInput
                    label="Schedule Start"
                    name="schedule_start"
                    type="date"
                    value={scheduleStart}
                    className={"!bg-white"}
                    onInput={(e) => {
                      if (e.target.value > scheduleEnd) {
                        setScheduleEnd(e.target.value);
                      }
                      setScheduleStart(e.target.value);
                    }}
                  />
                </div>
                <div className="w-full md:w-1/2 pl-2">
                  <FormInput
                    label="Schedule End"
                    name="schedule_end"
                    type="date"
                    value={scheduleEnd}
                    className={"!bg-white"}
                    min={moment(scheduleStart).format("YYYY-MM-DD")}
                    onInput={(e) => {
                      setScheduleEnd(e.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <hr className="divider mb-2 mt-2" />

          {source && source == "company" ? (
            <div className="p-8 pt-4 border border-secondary-2-extralight rounded-md h-fit flex flex-col px-4 w-full">
              <div className="flex flex-col text-center justify-center items-center w-full">
                <span className="text-[14px] font-extrabold">
                  Upload attendance schedule from XLSX file
                </span>

                <div className=" flex flex-col border p-4 rounded-md bg-secondary-2-extralight mt-2 mb-3 justify-center items-center w-full">
                  <Button
                    text="Download template"
                    type="button"
                    theme="primary"
                    loading={exportLoading}
                    className="flex gap-2 !h-[35px] w-[220px] px-2"
                    textClass={"!text-[11px]"}
                    onClick={() => {
                      exportTemplate();
                    }}
                  />
                </div>
                <FormFileInput
                  multiSource={true}
                  inputRef={register(formValidation("file", true))}
                  accept=".xlsx"
                />
              </div>
              <div className="w-full mt-4">
                <Button
                  text="UPLOAD SCHEDULE"
                  type="submit"
                  loading={isLoading}
                />
              </div>
            </div>
          ) : source &&
            source !== "company" &&
            (team || employee || department || branch) ? (
            <>
              <div className="p-8 pt-4 border border-secondary-2-extralight rounded-md h-fit flex flex-col px-4">
                <div className="flex flex-col text-center justify-center items-center">
                  <span className="text-[14px] font-extrabold">
                    Upload attendance schedule from XLSX file
                  </span>

                  <div className=" flex flex-col border p-4 rounded-md bg-secondary-2-extralight mt-2 -mb-3 justify-center items-center w-full">
                    <Button
                      text="Download template"
                      type="button"
                      theme="primary"
                      loading={exportLoading}
                      className="flex gap-2 !h-[35px] w-[220px] px-2"
                      textClass={"!text-[11px]"}
                      onClick={() => {
                        exportTemplate();
                      }}
                    />
                  </div>
                  <FormFileInput
                    multiSource={true}
                    inputRef={register(formValidation("file", true))}
                    accept=".xlsx"
                  />
                </div>
              </div>
              <div className="w-full mt-2">
                <Button
                  text="UPLOAD SCHEDULE"
                  type="submit"
                  loading={isLoading}
                />
              </div>
            </>
          ) : (
            <div className="p-4 border rounded-md h-fit flex flex-col px-4 items-center justify-center text-[16px] font-semibold text-hr-primary-1">
              Select a source
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
}

export default BulkAddAttendanceSchedule;
