import React, { useState } from 'react';
import Button from '../button';
import Modal from './modal';
import FormOtpInput from '../form-otp-input';
import CloseSVG from '../../assets/svg/close.svg';
import { useRequestPINChangeOTP, useUpdateTransactionPIN } from '../../redux/user/hook';
import FormInput from '../form-input';

function UpdatePINModal({ isOpen, closeModal }) {
  const [otpGenerated, setOtpGenerated] = useState(false)

  const [otp, setOtp] = useState('');

  const [pin, setPIN] = useState('');

  const [password, setPassword] = useState('');

  const { mutateAsync: generatePinOTP, isLoading: generateOTPLoading } =
  useRequestPINChangeOTP();

  const { mutateAsync: updateTransactionPIN, isLoading: updatePINLoading } =
  useUpdateTransactionPIN();

  const requestPinChangeOTP = async () => {
    await generatePinOTP().then((result) => {
      if (result.status == 'success') {
        setOtpGenerated(true)
      }
    })
  }

  const submitPIN = async () => {
    let payload = {
      pin,
      pin_confirmation: pin,
      password,
      otp
    }
    await updateTransactionPIN(payload).then((result) => {
      if (result.status == 'success') {
        closeModal()
      }
    })
  }

  return (
    <Modal
      scrollable={true}
      className='!max-w-[500px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
        <div className='flex flex-col overflow-auto'>
            <div className='pl-8 pr-8 pt-4'>
              <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Update Transaction PIN</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
              </div>
            </div>
            <hr className='divider mb-3' />
            <div className='flex flex-col mx-8 pb-4'>
              {otpGenerated?
              <>
                <div className='flex w-[100%] justify-center border bg-gray-50 p-2 rounded-md mb-4'>
                  <FormOtpInput
                    value={pin}
                    valueLength={4}
                    onChange={(value) => setPIN(value)}
                    label={'Enter your new 4 digit PIN'}
                  />
                </div>
                <div className='w-full'>
                  <FormInput
                    label='Generated otp'
                    placeholder={'Enter OTP Generated (Check your email)'}
                    name='otp'
                    type='text'
                    onInput={(e) => setOtp(e.target.value)}
                  />
                </div>
                <div className='w-full '>
                  <FormInput
                    label='Password'
                    placeholder={'Enter your app password'}
                    name='password'
                    type='password'
                    onInput={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className=' flex flex-row w-full gap-4'>
                  <Button
                    text='UPDATE TRANSACTION PIN'
                    type='button'
                    disabled={!pin || pin.length !== 4}
                    loading={updatePINLoading || generateOTPLoading}
                    onClick={() => submitPIN()}
                  />
                </div>
              </>
              :
              <>
                <div className='flex w-[100%] justify-center border p-2 rounded-md mb-4 mt-2 text-[13px] bg-highlight text-center'>
                  Generate OTP that will be sent to your email, to enable you change your Transaction PIN
                </div>
                <div className=' flex flex-row w-full gap-4'>
                  <Button
                    text='GENERATE PIN CHANGE OTP'
                    type='button'
                    loading={updatePINLoading || generateOTPLoading}
                    onClick={() => requestPinChangeOTP()}
                  />
                </div>
              </>
              }
            </div>
        </div>
    </Modal>
  );
}

export default UpdatePINModal;
