import React, { useState } from 'react';
import { useEffect } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  useDisburseAdvancedPayroll,
  useDisburseBasicPayroll,
  useInitiateAdvancedPayroll,
  useInitiateBasicPayroll,
} from '../../../redux/payroll/hook';
import Button from '../../button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WarningSVG from '../../../assets/svg/warning.svg';
import ConfirmationModal from '../confirmation-modal';
import FormSelect from '../../form-select';
import FormInput from '../../form-input';
import Modal from '../modal';
import { useGetBranch } from '../../../redux/human-resources/hook/branches';
import { formatNumber } from '../../../utils/functions';
import FormOtpInput from '../../form-otp-input';

function PayrollSummary({
  isOpen,
  closeModal,
  takeHome,
  transCost,
  payrollStatus,
  totalAmount,
  refValue,
  type,
  source = 'company',
  source_id,
}) {
  const [pin, setPIN] = useState('');
  const history = useHistory();
  const { company } = useSelector((state) => state.companySlice);
  const { user } = useSelector((state) => state.userSlice);
  const { advanced } = useSelector((state) => state.payrollSlice);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [maxPaymentPercentage, setMaxPaymentPercentage] = useState(100);

  const [payFrom, setPayFrom] = useState('company');

  const payFromOptions = [
    { label: 'Company Wallet', value: 'company' },
    { label: 'Branch Wallet', value: 'branch' },
  ];

  const { data: branch } = useGetBranch(source_id);

  const [paymentType, setPaymentType] = useState('full');
  const [paymentPercentage, setPaymentPercentage] = useState(100);

  const paymentTypeOptions = [
    { value: 'full', label: 'Full Payment' },
    { value: 'part', label: 'Part Payment' },
  ];

  const [initiated, setInitiated] = useState(false);
  const [approvalId, setApprovalId] = useState();

  // const [initiatedIndex, setInitiatedIndex] = useState();
  const [totalInitiated, setTotalInitiated] = useState(0);
  const [percentageInitiated, setPercentageInitiated] = useState(0);

  const [payment, setPayment] = useState(0);

  useEffect(() => {
    if (totalAmount) {
      let total = parseFloat(totalAmount);
      setPayment(formatNumber((total * paymentPercentage) / 100, 2));
    }
  }, [totalAmount, paymentPercentage]);

  useEffect(() => {
    if (advanced && advanced.payments?.length > 0) {
      if (payrollStatus == 'approve') {
        let index = advanced.payments.findIndex((item) => item.status == 2);
        setPercentageInitiated(advanced.initiated_percentage);
        let total = parseFloat(advanced.formatted_total_amount);
        setTotalInitiated(
          (
            (advanced.initiated_percentage / 100) *
            advanced.total_amount
          ).toLocaleString('en-NG', { style: 'currency', currency: 'NGN' }),
        );
        // setInitiatedIndex(index);
        setInitiated(true);
        setApprovalId(advanced.payments[index].id);
      } else if (payrollStatus == 'complete') {
        let totalAlreadyPaid = 0;
        let pays = advanced.payments;
        if (pays && pays.length > 0) {
          pays.forEach((item) => {
            if (item.status == 1) {
              totalAlreadyPaid += item.percentage;
            }
          });
        }
        setMaxPaymentPercentage(100 - totalAlreadyPaid);
        // let index = advanced.payments.findIndex((item) => item.status == 1);
        setPercentageInitiated(100 - advanced.initiated_percentage);
        let total = parseFloat(advanced.formatted_total_amount);
        setPaymentType('part');
        setPaymentPercentage(100 - advanced.initiated_percentage);
        setTotalInitiated(
          (
            (advanced.initiated_percentage / 100) *
            advanced.total_amount
          ).toLocaleString('en-NG', { style: 'currency', currency: 'NGN' }),
        );
        // setInitiatedIndex(index);
        setInitiated(false);
        // setApprovalId(advanced.payments[index].id);
      }
    }
  }, [advanced]);

  const { mutateAsync: disburseBasicPayroll, isLoading: basicLoading } =
    useDisburseBasicPayroll();

  const { mutateAsync: disburseAdvancedPayroll, isLoading: advancedLoading } =
    useDisburseAdvancedPayroll();

  const {
    mutateAsync: initiateAdvancedPayroll,
    isLoading: initiateAdvancedLoading,
  } = useInitiateAdvancedPayroll();

  const { mutateAsync: initiateBasicPayroll, isLoading: initiateBasicLoading } =
    useInitiateBasicPayroll();

  const submitForm = async (data) => {
    if (type === 'advanced') {
      if (
        user?.permissions.approval_permission?.initiator === 1 &&
        user?.permissions.approval_permission?.approver === 1 &&
        payrollStatus !== 'approve'
      ) {
        const requestData = {
          transaction_pin: pin,
          payment_from: payFrom,
          ref: data,
          percentage: paymentType === 'full' ? 100 : paymentPercentage,
        };
        await initiateAdvancedPayroll(requestData).then(() => {
          setShowConfirmation(true);
        });
      } else if (
        user?.permissions.approval_permission?.initiator === 1 &&
        payrollStatus !== 'approve'
      ) {
        const requestData = {
          transaction_pin: pin,
          payment_from: payFrom,
          ref: data,
          percentage: paymentType === 'full' ? 100 : paymentPercentage,
        };
        await initiateAdvancedPayroll(requestData).then(() => {
          history.push(`/payroll`);
        });
      } else if (
        user?.permissions.approval_permission?.approver === 1 &&
        payrollStatus === 'approve'
      ) {
        const requestData = {
          transaction_pin: pin,
          payment_from: payFrom,
          id: approvalId,
        };
        await disburseAdvancedPayroll(requestData).then(() => {
          setShowConfirmation(true);
        });
      }
    } else if (type === 'basic') {
      const requestData = {
        transaction_pin: pin,
        ref: data,
      };
      if (
        user?.permissions.approval_permission?.initiator === 1 &&
        user?.permissions.approval_permission?.approver === 1
      ) {
        await disburseBasicPayroll(requestData).then(() => {
          setShowConfirmation(true);
        });
      } else if (
        user?.permissions.approval_permission?.initiator === 1 &&
        payrollStatus !== 'approve'
      ) {
        await initiateBasicPayroll(requestData).then(() => {
          history.push(`/payroll`);
        });
      } else if (
        user?.permissions.approval_permission?.approver === 1 &&
        payrollStatus === 'approve'
      ) {
        await disburseBasicPayroll(requestData).then(() => {
          setShowConfirmation(true);
        });
      }
    }
  };

  const confirmationNext = () => {
    history.push('/payroll', { tab: 3 });
  };

  const subTitle =
    payrollStatus === 'approve'
      ? `Time to kick back and relax. Ropay will debit ${totalAmount} from your account. Please Make sure the funds are available.`
      : `Time to kick back and relax. Ropay has informed all authorised approvers for ${totalAmount} you just initiated.`;

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmation}
        closeModal={() => setShowConfirmation(false)}
        btnFunction={confirmationNext}
        title={'You did it, ' + user?.first_name}
        subtitle={subTitle}
      />
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none pb-3'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col !max-h-[600px] overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-3'>
                {payrollStatus == 'approve'
                  ? 'Approve Payment'
                  : 'Make Payment'}
              </span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col justify-center mx-8'>
            <div className='flex flex-col'>
              <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-highlight'>
                <span className='stat-card-header'>
                  COMPANY WALLET BALANCE{' '}
                  <span className='!text-[13px] text-color-black font-bold'>
                    (VFD - {company?.account_number} )
                  </span>
                </span>
                <span className='!text-[16px] text-color-black font-bold'>
                  {company?.total_balance?.toLocaleString()}
                </span>
              </div>
              <hr className='divider mt-2' />
              <div className='flex flex-col items-center'>
                <span className='stat-card-header'>
                  TOTAL AMOUNT (TAKE HOME)
                </span>
                <span className='!text-[16px] text-color-black font-bold'>
                  {formatNumber(takeHome, 2)}
                </span>
              </div>
              <hr className='divider' />

              <div className='flex flex-col items-center'>
                <span className='stat-card-header'>TOTAL FEES</span>
                <span className='!text-[16px] text-color-black font-bold'>
                  {formatNumber(transCost)}
                </span>
              </div>
            </div>
            <hr className='divider mt-2' />
            {type === 'advanced' ? (
              <>
                {initiated ? (
                  <div className='flex flex-col justify-center'>
                    {payrollStatus === 'approve' ? (
                      <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light !text-[13px]'>
                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                          <img src={WarningSVG} alt='' srcSet='' />
                        </div>
                        <div className='text-justify'>
                          Ropay will debit{' '}
                          <span className='font-bold'>{totalInitiated}</span>{' '}
                          from your account, being{' '}
                          <span className='font-bold'>
                            {percentageInitiated}%
                          </span>{' '}
                          initiated from the total net pay. Please Make sure the
                          funds are available before proceeding.
                        </div>
                      </div>
                    ) : (
                      <div className='flex flex-row px-2 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light'>
                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                          <img src={WarningSVG} alt='' srcSet='' />
                        </div>
                        <span className='text-[13px]'>
                          Ropay will send {formatNumber(totalAmount)} being{' '}
                          {percentageInitiated}% of the total net pay to
                          authorized aprrovers. Kindly ensure that you have
                          entered have the correct amount for approval.
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='flex flex-col justify-center'>
                    {payrollStatus === 'approve' ? (
                      <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light !text-[13px]'>
                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                          <img src={WarningSVG} alt='' srcSet='' />
                        </div>
                        <div className='text-justify'>
                          Ropay will debit{' '}
                          <span className='font-bold'>{payment}</span> from your
                          account, being{' '}
                          <span className='font-bold'>
                            {paymentPercentage
                              ? `${paymentPercentage.toString()}%`
                              : '100%'}
                          </span>{' '}
                          of the total net pay. Please Make sure the funds are
                          available before proceeding.
                        </div>
                      </div>
                    ) : payrollStatus === 'complete' ? (
                      <div className='flex flex-row px-2 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light'>
                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                          <img src={WarningSVG} alt='' srcSet='' />
                        </div>
                        <span className='text-[13px]'>
                          Ropay will send{' '}
                          <span className='font-bold text-[14px]'>
                            {payment}
                          </span>{' '}
                          being{' '}
                          <span className='font-bold text-[14px]'>
                            {paymentPercentage
                              ? `${paymentPercentage.toString()}%`
                              : `${maxPaymentPercentage}%`}{' '}
                            (balance)
                          </span>{' '}
                          of the total net pay to authorized approvers. Kindly
                          ensure that you have entered have the correct amount
                          for approval.
                        </span>
                      </div>
                    ) : (
                      <div className='flex flex-row px-2 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light'>
                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                          <img src={WarningSVG} alt='' srcSet='' />
                        </div>
                        <span className='text-[13px]'>
                          Ropay will send{' '}
                          <span className='font-bold text-[14px]'>
                            {payment}
                          </span>{' '}
                          being{' '}
                          <span className='font-bold text-[14px]'>
                            {paymentPercentage
                              ? `${paymentPercentage.toString()}%`
                              : `100%`}
                          </span>{' '}
                          of the total net pay to authorized approvers. Kindly
                          ensure that you have entered have the correct amount
                          for approval.
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div className='flex flex-col justify-center'>
                {payrollStatus === 'approve' ? (
                  <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light !text-[14px]'>
                    <div className='w-[70px] pr-2 flex flex-row items-center '>
                      <img src={WarningSVG} alt='' srcSet='' />
                    </div>
                    <div className=' text-[13px]'>
                      Ropay will debit{' '}
                      <span className='font-bold text-[14px]'>
                        {formatNumber(totalAmount)}
                      </span>{' '}
                      from your account. Please Make sure the funds are
                      available before proceeding.
                    </div>
                  </div>
                ) : (
                  <div className='flex flex-row px-2 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light'>
                    <div className='w-[70px] pr-2 flex flex-row items-center '>
                      <img src={WarningSVG} alt='' srcSet='' />
                    </div>
                    <span className='text-[13px]'>
                      Ropay will initiate a payment of{' '}
                      <span className='font-extrabold  text-[14px]'>
                        {formatNumber(totalAmount)}
                      </span>{' '}
                      to authorized aprrovers. Kindly ensure that you have
                      entered have the correct amount for approval.
                    </span>
                  </div>
                )}
              </div>
            )}
            {payrollStatus === 'approve' &&
            user?.permissions.approval_permission?.approver === 1 ? (
              <>
                {user?.permissions.approval_permission?.approver === 1 &&
                source == 'branch' &&
                source_id &&
                branch &&
                branch?.wallet_id ? (
                  <>
                    <hr className='divider mb-2' />
                    <div className='w-full'>
                      <FormSelect
                        value={payFrom}
                        options={payFromOptions}
                        onChange={(selected) => {
                          setPayFrom(selected);
                        }}
                        label='Pay Payroll From'
                      />
                    </div>
                    {payFrom == 'branch' && branch && branch.wallet_id ? (
                      <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-highlight'>
                        <span className='stat-card-header font-extrabold'>
                          BRANCH WALLET BALANCE{' '}
                          <span className='!text-[13px] text-color-black font-bold'>
                            (VFD - {branch?.account_number})
                          </span>
                        </span>
                        <span className='!text-[16px] text-color-black font-bold'>
                          {formatNumber(branch?.balance)}
                        </span>
                      </div>
                    ) : null}
                  </>
                ) : null}
                <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4 mt-3'>
                  <FormOtpInput
                    value={pin}
                    valueLength={4}
                    onChange={(value) => setPIN(value)}
                    label={'Enter your new 4 digit PIN'}
                  />
                </div>
                <div className='w-full mb-4'>
                  <Button
                    text={'Approve Payment'}
                    type='submit'
                    textClass={'!text-[15px]'}
                    disabled={false}
                    loading={
                      basicLoading ||
                      advancedLoading ||
                      initiateBasicLoading ||
                      initiateAdvancedLoading
                    }
                    onClick={() => submitForm(refValue)}
                  />
                </div>
              </>
            ) : payrollStatus !== 'approve' &&
              user?.permissions.approval_permission?.initiator === 1 ? (
              <>
                {type === 'advanced' ? (
                  <>
                    {user?.permissions.approval_permission?.initiator === 1 &&
                    user?.permissions.approval_permission?.approver === 1 &&
                    source == 'branch' &&
                    source_id &&
                    branch &&
                    branch.wallet_id ? (
                      <>
                        <hr className='divider mb-2' />
                        <div className='w-full'>
                          <FormSelect
                            value={payFrom}
                            options={payFromOptions}
                            onChange={(selected) => {
                              setPayFrom(selected);
                            }}
                            label='Pay Payroll From'
                          />
                        </div>
                        {payFrom == 'branch' && branch && branch.wallet_id ? (
                          <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-secondary-2-extralight'>
                            <span className='stat-card-header font-extrabold'>
                              BRANCH WALLET BALANCE{' '}
                              <span className='!text-[13px] text-color-black font-bold'>
                                (VFD - {branch?.account_number})
                              </span>
                            </span>
                            <span className='!text-[16px] text-color-black font-bold'>
                              {formatNumber(branch?.balance, 2)}
                            </span>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    <hr className='divider mb-2 -mt-0' />
                    <div className='w-full'>
                      <FormSelect
                        value={paymentType}
                        options={paymentTypeOptions}
                        readOnly={payrollStatus === 'complete'}
                        onChange={(selected) => {
                          if (selected == 'full') {
                            setPaymentPercentage(100);
                          }
                          setPaymentType(selected);
                        }}
                        label='Payment Type'
                      />
                    </div>
                    {paymentType === 'part' ? (
                      <>
                        <div className='w-full'>
                          <FormInput
                            label='Percentage to pay'
                            name='payment_percentage'
                            type='number'
                            max={maxPaymentPercentage}
                            min={0}
                            value={paymentPercentage}
                            onInput={(e) => {
                              if (
                                e.target.value &&
                                e.target.value > maxPaymentPercentage
                              ) {
                                setPaymentPercentage(maxPaymentPercentage);
                              } else {
                                setPaymentPercentage(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
                <hr className='divider -mt-0' />
                <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4'>
                  <FormOtpInput
                    value={pin}
                    valueLength={4}
                    onChange={(value) => setPIN(value)}
                    label={'Enter your new 4 digit PIN'}
                  />
                </div>
                <div className='w-full mb-4'>
                  <Button
                    text={
                      user?.permissions.approval_permission?.initiator === 1 &&
                      user?.permissions.approval_permission?.approver === 1
                        ? 'Pay'
                        : 'Initiate Payment'
                    }
                    type='submit'
                    disabled={false}
                    loading={
                      basicLoading ||
                      advancedLoading ||
                      initiateBasicLoading ||
                      initiateAdvancedLoading
                    }
                    onClick={() => submitForm(refValue)}
                  />
                </div>
              </>
            ) : payrollStatus !== 'approve' &&
              user?.permissions.approval_permission?.approver === 1 ? (
              <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-highlight mt-4 mb-3 text-center font-semibold text-[13px]'>
                You are not authorised to Initiate payments <br /> Please
                Contact Your Administrator.
              </div>
            ) : (
              <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-highlight mt-4 mb-3 text-[13px] text-center font-semibold'>
                You are not Authorised to Approve payment <br /> Please Contact
                Your Administrator.
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PayrollSummary;
