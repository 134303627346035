import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoadingBar from "../../../loader-bar";
import {
  useGetRecruitmentApplication,
  useGetRecruitmentStages,
} from "../../../../redux/human-resources/hook/recruitment";
import Button from "../../../button";
import ViewResume from "../../../modal/hr-section/recruitment/view-resume";
import SendApplicantsEmail from "../../../modal/hr-section/recruitment/send-applicants-email";
import ReviewStatus from "../../../modal/hr-section/recruitment/review-applicant";
import { ApplicationAnswerView } from "../../../modal/hr-section/recruitment/applicant-answer-view";
import MoveApplicant from "../../../modal/hr-section/recruitment/move-applicant";
import ForwardSVG from "../../../../assets/svg/forward-arrow-white.svg";
import { useSelector } from "react-redux";
import { ApplicationSchedules } from "../../../modal/hr-section/recruitment/applicant-schedule";
import { ApplicationNotes } from "../../../modal/hr-section/recruitment/application-notes";
import SendOfferLetter from "../../../modal/hr-section/recruitment/send-offer-letter";
import { OfferLetter } from "../../../modal/hr-section/recruitment/offer-letter";

const ApplicantProfile = () => {
  useEffect(() => {}, []);
  const history = useHistory();
  const { section } = useSelector((state) => state.sectionSlice);
  const { id } = useParams();

  const gotoEmployeeOnboarding = () => {
    history.push(
      `/employees/onboarding/add-single-employee/invite?first_name=${applicant?.first_name}&last_name=${applicant?.last_name}&email=${applicant?.email}&mobile=${applicant?.mobile}&applicant_id=${id}`
    );
  };

  const { data: applicant, isLoading } = useGetRecruitmentApplication(id);
  const { data: recruitmentStages, isLoading: loading } =
    useGetRecruitmentStages(applicant?.recruitment_id);

  // const [showAnswers, setShowAnswers] = useState(false)

  const [showResume, setShowResume] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showMarkReviewed, setMarkReviewed] = useState(false);
  const [showMoveApplicant, setShowMoveApplicant] = useState(false);
  const [showSendOfferLetter, setShowSendOfferLetter] = useState(false);

  return (
    <>
      <div className="flex flex-col bg-white min-h-fit rounded-md">
        {showMarkReviewed ? (
          <ReviewStatus
            title={"Review Status"}
            isOpen={showMarkReviewed}
            closeModal={() => setMarkReviewed(false)}
            applicant={applicant}
            buttonText="Review Applicant"
          />
        ) : null}
        {showMoveApplicant ? (
          <MoveApplicant
            title={"Move Applicant"}
            isOpen={showMoveApplicant}
            closeModal={() => setShowMoveApplicant(false)}
            applicant={applicant}
            buttonText="Move Applicant"
          />
        ) : null}
        {showSendEmail ? (
          <SendApplicantsEmail
            title={"Review Status"}
            isOpen={showSendEmail}
            closeModal={() => setShowSendEmail(false)}
            applicantIds={id.toString()}
            buttonText="Review Applicant"
          />
        ) : null}
        {showResume ? (
          <ViewResume
            isOpen={showResume}
            closeModal={() => setShowResume(false)}
            title=" Move Applicant Stage"
            applicant={applicant}
            buttonText="Move Applicant"
          />
        ) : null}
        {showSendOfferLetter ? (
          <SendOfferLetter
            title={"Send Offer Letter"}
            isOpen={showSendOfferLetter}
            closeModal={() => setShowSendOfferLetter(false)}
            applicant={applicant}
            buttonText="SEND LETTER"
          />
        ) : null}
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Applicant Profile
          </div>

          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            {applicant?.stage < recruitmentStages?.data.length ? (
              <>
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="Change Status"
                    type="button"
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    textClass={"!text-[11px]"}
                    onClick={() => {
                      setMarkReviewed(true);
                    }}
                  />
                </div>
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="Send Email"
                    leftIcon={"email"}
                    type="button"
                    applicantIds={id.toString()}
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    textClass={"!text-[11px]"}
                    onClick={() => {
                      setShowSendEmail(true);
                    }}
                  />
                </div>
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="Advance Applicant"
                    rightIcon={"forward"}
                    type="button"
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    onClick={() => {
                      setShowMoveApplicant(true);
                    }}
                    textClass={"!text-[11px]"}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="Onboard As Employee"
                    type="submit"
                    applicant={applicant}
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    leftIcon={"add"}
                    textClass={"!text-[11px]"}
                    onClick={() => {
                      gotoEmployeeOnboarding();
                    }}
                  />
                </div>
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="Send Offer Letter"
                    leftIcon={"email"}
                    type="button"
                    applicantIds={id.toString()}
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    textClass={"!text-[11px]"}
                    onClick={() => {
                      setShowSendOfferLetter(true);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <hr className="divider" />
        {isLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
            <LoadingBar loading={isLoading} />
          </div>
        ) : (
          <>
            {applicant ? (
              <div className="flex md:flex-row flex-col gap-4 px-3 pb-4">
                <div className=" flex flex-col gap-4 w-full md:w-[50%]">
                  <div className=" flex flex-col gap-4 w-full border rounded mb-2">
                    <div className="flex flex-row justify-between items-center bg-blue-100 p-3">
                      <div className="font-medium text-[14px] text-color-black flex flex-col">
                        Applicant Basic Information
                      </div>
                    </div>
                    <div className="p-3 pt-0">
                      <div className="grid grid-cols-2 gap-4 w-full">
                        <div className="flex flex-col col-span-2">
                          <span className="header-5 text-color-gray">
                            Applicant name
                          </span>
                          <span className="mt-1 text-color-black font-bold">
                            {applicant.first_name} {applicant.last_name}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            Email Address
                          </span>
                          <span className="mt-1 text-color-black font-bold">
                            {applicant.email ? applicant.email : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            Phone number{" "}
                          </span>
                          <span className="mt-1 text-color-black font-bold">
                            {applicant.mobile ? applicant.mobile : "Not Set"}
                          </span>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4 w-full mt-3">
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            Gender
                          </span>
                          <span className="mt-1 text-color-black">
                            {applicant.gender ? applicant.gender : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            Marital Status
                          </span>
                          <span className="mt-1 text-color-black">
                            {applicant.marital_status
                              ? applicant.marital_status
                              : "Not Set"}
                          </span>
                        </div>
                      </div>
                      <hr className="divider mt-2" />
                      <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
                        <div className="flex flex-row items-center gap-8">
                          <span className="header-5 text-color-gray">
                            Resume:
                          </span>
                          <div className="flex flex-row gap-3 items-center">
                            <Button
                              text="View Resume"
                              type="submit"
                              className="flex gap-2 !h-[35px] w-fit p-2 "
                              textClass={"!text-[11px]"}
                              onClick={() => {
                                setShowResume(true);
                              }}
                            />
                            <a
                              className="italic text-hr-primary-1 text-[14px]"
                              href={applicant.resume_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                      <hr className="divider mt-2" />
                      <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
                        <div className="flex flex-row items-center gap-8">
                          <span className="header-5 text-color-gray">
                            Personal website:
                          </span>
                          <div className="flex flex-row gap-3 items-center">
                            {applicant.personal_website ? (
                              <a
                                className="italic text-hr-primary-1 text-[14px]"
                                href={applicant.personal_website}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {" "}
                                View
                              </a>
                            ) : (
                              <span className="italic text-hr-primary-1 text-[14px]">
                                Not provided
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr className="divider mt-2" />
                      <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
                        <div className="flex flex-row items-center gap-8">
                          <span className="header-5 text-color-gray">
                            Linkedin:
                          </span>
                          <div className="flex flex-row gap-3 items-center">
                            {applicant.linkedin ? (
                              <a
                                className="italic text-hr-primary-1 text-[14px]"
                                href={applicant.linkedin}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {" "}
                                View
                              </a>
                            ) : (
                              <span className="italic text-hr-primary-1 text-[14px]">
                                Not provided
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr className="divider mt-2" />
                      <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
                        <div className="flex flex-row items-center gap-8">
                          <span className="header-5 text-color-gray">
                            Twitter:
                          </span>
                          <div className="flex flex-row gap-3 items-center">
                            {applicant.twitter ? (
                              <a
                                className="italic text-hr-primary-1 text-[14px]"
                                href={applicant.twitter}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {" "}
                                View
                              </a>
                            ) : (
                              <span className="italic text-hr-primary-1 text-[14px]">
                                Not provided
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr className="divider mt-2" />
                      <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
                        <div className="flex flex-row items-center gap-8">
                          <span className="header-5 text-color-gray">
                            Facebook:
                          </span>
                          <div className="flex flex-row gap-3 items-center">
                            {applicant.facebook ? (
                              <a
                                className="italic text-hr-primary-1 text-[14px]"
                                href={applicant.facebook}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {" "}
                                View
                              </a>
                            ) : (
                              <span className="italic text-hr-primary-1 text-[14px]">
                                Not provided
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr className="divider mt-2" />
                      <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
                        <div className="flex flex-row items-center gap-8">
                          <span className="header-5 text-color-gray">
                            Portfolio:
                          </span>
                          <div className="flex flex-row gap-3 items-center">
                            {applicant.portfolio_url ? (
                              <a
                                className="italic text-hr-primary-1 text-[14px]"
                                href={applicant.portfolio_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {" "}
                                View
                              </a>
                            ) : (
                              <span className="italic text-hr-primary-1 text-[14px]">
                                Not provided
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" flex flex-col gap-4 w-full border rounded mb-2">
                    <ApplicationSchedules applicant={applicant} />
                  </div>
                </div>

                <div className="flex flex-col gap-4 w-full md:w-[50%]">
                  <div className="w-[100%] h-fit">
                    <ApplicationNotes />
                  </div>
                  <ApplicationAnswerView applicant={applicant} />
                  <OfferLetter
                    offerLetter={applicant.offer_letter}
                    signedOfferLetter={applicant.signed_offer_letter}
                    type={"recruitment"}
                  />
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default ApplicantProfile;
