import React, { useEffect } from "react";
import CloseSVG from "../../../../assets/svg/close.svg";
import Button from "../../../button";
import Modal from "../../modal";
import { TrainingRemarks } from "./training-remarks";
import moment from "moment";

function TrainedEmployee({ isOpen, closeModal, trainedEmployeeData }) {
  return (
    <Modal
      scrollable={true}
      className="!min-w-[90%]"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col overflow-auto">
        <div className="pl-8 pr-8 pt-4">
          <div className="flex md:flex-row justify-between gap-4 items-center">
            <span className="header-4">
              {" "}
              {trainedEmployeeData?.first_name}'s training information
            </span>
            <div className="cursor-pointer" onClick={() => closeModal()}>
              <img src={CloseSVG} alt="close" />
            </div>
          </div>
        </div>
        <hr className="divider" />
        {trainedEmployeeData ? (
          <div className="flex md:flex-row flex-col gap-4 px-3 pb-4">
            <div className=" flex flex-col gap-4 w-full md:w-[50%]">
              <div className=" flex flex-col gap-4 w-full border rounded mb-2">
                <div className="flex flex-row justify-between items-center bg-blue-100 p-3">
                  <div className="text-[14px] text-color-black flex flex-col font-bold">
                    Training Information
                  </div>
                </div>
                <div className="p-3 pt-0">
                  <div className="grid grid-cols-2 gap-4 w-full">
                    <div className="flex flex-col col-span-2">
                      <span className="header-5 text-color-gray">
                        Employee Name
                      </span>
                      <span className=" text-[14px] mt-1 text-color-black font-bold">
                        {trainedEmployeeData.first_name}{" "}
                        {trainedEmployeeData.last_name}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="header-5 text-color-gray">
                        Email Address
                      </span>
                      <span className=" text-[14px] mt-1 text-color-black font-bold">
                        {trainedEmployeeData.email
                          ? trainedEmployeeData.email
                          : "Not Set"}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="header-5 text-color-gray">
                        Phone number{" "}
                      </span>
                      <span className=" text-[14px] mt-1 text-color-black font-bold">
                        {trainedEmployeeData.mobile
                          ? trainedEmployeeData.mobile
                          : "Not Set"}
                      </span>
                    </div>
                  </div>
                  <hr className="divider mt-2" />
                  <div className="grid grid-cols-2 gap-4 w-full mt-3">
                    <div className="flex flex-col">
                      <span className="header-5 text-color-gray">
                        Training Name
                      </span>
                      <span className=" text-[14px] mt-1 text-color-black font-bold">
                        {trainedEmployeeData.training_name
                          ? trainedEmployeeData.training_name
                          : "Not Set"}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="header-5 text-color-gray">
                        Trainer Name
                      </span>
                      <span className=" text-[14px] mt-1 text-color-black font-bold">
                        {trainedEmployeeData.trainer_name
                          ? trainedEmployeeData.trainer_name
                          : "Not Set"}
                      </span>
                    </div>
                  </div>
                  <hr className="divider mt-2" />
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
                    <div className="flex flex-row items-center gap-8">
                      <span className="header-5 text-color-gray">
                        Training Duration
                      </span>
                      <span className=" text-[14px] mt-1 text-color-black font-bold">
                        {moment(trainedEmployeeData.training_start).format(
                          "MMMM DD, YYYY"
                        )}{" "}
                        <span className="font-light italic">to</span>{" "}
                        {moment(trainedEmployeeData.training_end).format(
                          "MMMM DD, YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                  <hr className="divider mt-2" />
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
                    <div className="flex flex-row items-center gap-8">
                      <span className="header-5 text-color-gray">
                        Training Location
                      </span>
                      <span className=" text-[14px] mt-1 text-color-black font-bold">
                        {trainedEmployeeData.training_location
                          ? trainedEmployeeData.training_location
                          : "Not Set"}
                      </span>
                    </div>
                  </div>
                  <hr className="divider mt-2" />
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
                    <div className="flex flex-row items-center gap-8">
                      <span className="header-5 text-color-gray">
                        Training Type
                      </span>
                      <span className=" text-[14px] mt-1 text-color-black font-bold">
                        {trainedEmployeeData.training_type
                          ? trainedEmployeeData.training_type
                          : "Not Set"}
                      </span>
                    </div>
                  </div>
                  <hr className="divider mt-2" />
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
                    <div className="flex flex-row items-center gap-8">
                      <span className="header-5 text-color-gray">
                        Certificate Earned:
                      </span>

                      <div className="flex flex-row gap-3 items-center">
                        {trainedEmployeeData?.certificate?.file_url ? (
                          <>
                            <Button
                              text="View"
                              type="submit"
                              className="flex gap-2 !h-[35px] w-fit p-2 "
                              textClass={"!text-[11px]"}
                              onClick={() => {
                                setshowResume(true);
                              }}
                            />
                            <a
                              className="italic text-hr-primary-1 text-[14px]"
                              href={trainedEmployeeData?.certificate?.file_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              Download
                            </a>
                          </>
                        ) : (
                          <div className=" text-[14px] mt-1 text-color-black font-bold">
                            No Training Certificate Provided Yet
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-full md:w-[50%]">
              <div className="w-[100%] h-fit">
                <TrainingRemarks remark={trainedEmployeeData?.remarks} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}

export default TrainedEmployee;
