import classNames from 'classnames';
import React, { useMemo } from 'react';

export default function FormOtpInput({
  value,
  valueLength,
  onChange,
  className = '',
  label,
}) {
  const RE_DIGIT = new RegExp(/^\d+$/);

  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [value, valueLength]);

  const inputOnChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      const nextElementSibling = target.nextElementSibling;

      if (nextElementSibling) {
        nextElementSibling.focus();
      }
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }
  };

  const inputOnKeyDown = (e) => {
    const target = e.target;
    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || target.value !== '') {
      return;
    }

    const previousElementSibling = target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnFocus = (e) => {
    const { target } = e;

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div className='w-full'>
      <div className='flex flex-col gap-3'>
        <label className='text-[13px] leading-[23px] font-normal text-color-gray'>
          {label}
        </label>

        <div className='flex flex-row gap-1 md:gap-3 w-[100%] justify-between'>
          {valueItems.map((digit, index) => (
            <input
              key={index}
              type='password'
              inputMode='numeric'
              autoComplete='one-time-code'
              pattern='\d{1}'
              placeholder={'-'}
              maxLength={valueLength}
              value={digit}
              onChange={(e) => inputOnChange(e, index)}
              onKeyDown={inputOnKeyDown}
              onFocus={inputOnFocus}
              className={classNames(
                'w-full aspect-square max-w-[60px] rounded-md border border-gray-300',
                'text-center text-lg bg-white',
                'focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent',
                className,
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
