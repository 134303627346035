import React, { useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import { yearOptions } from '../../../utils/constants/date';
import Button from '../../button';
import FormSelect from '../../form-select';
import Modal from '../modal';
import moment from 'moment';
import { useExportAnnualReport } from '../../../redux/payroll/hook';

function ExportAnnualReport({
  isOpen,
  closeModal,
}) {
    
    const { mutateAsync: exportAnnualReport, isLoading: exportLoading } = useExportAnnualReport();
    const [year, setYear] = useState(moment().format('YYYY-'));
    const submitForm = async () => {
        exportAnnualReport(year.split("-")[0]);
    };

    return (
        <>
        <Modal
            scrollable={true}
            className='!max-w-[600px] !rounded-none'
            isOpen={isOpen}
            centered={true}
        >
            <div className='flex flex-col overflow-auto'>
            <form className='form pb-4'>
                <div className='pl-8 pr-8 pt-4'>
                <div className='flex flex-row justify-between items-center'>
                    <span className='header-3'>Download Annual Report</span>
                    <div className='cursor-pointer' onClick={() => closeModal()}>
                        <img src={CloseSVG} alt='close' />
                    </div>
                </div>
                </div>
                <hr className='divider' />
                <div className='flex flex-col mx-8'>
                <div>
                    <div className='flex flex-col md:flex-row w-full justify-between'>
                    <div className='w-full'>
                        <FormSelect
                        value={year}
                        options={yearOptions}
                        onChange={(selected) => {
                            setYear(selected);
                        }}
                        label='Select Report Year'
                        />
                    </div>
                    </div>
                </div>
                <div className='w-full mt-[20px]'>
                    <Button 
                        className={'flex gap-3'}
                        text='EXPORT' 
                        leftIcon={'export'}
                        type='button' 
                        loading={exportLoading}
                        onClick={() => {
                            submitForm()
                        }}
                    />
                </div>
                </div>
            </form>
            </div>
        </Modal>
        </>
    );
}

export default ExportAnnualReport;
