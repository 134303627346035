import React, { useEffect, useState } from "react";
import CloseSVG from "../../../../assets/svg/close.svg";
import { useEditBasicPayrollById } from "../../../../redux/payroll/hook";
import Button from "../../../button";

import FormInput from "../../../form-input";
import Modal from "../../modal";
import { formatNumber } from "../../../../utils/functions";

function VendorPayDetailedView({ isOpen, closeModal, viewData, previewType }) {
  const [payloadData, setPayloadData] = useState(null);
  const [type, setType] = useState(null);
  const [netPay, setNetPay] = useState(null);
  const [id, setId] = useState(null);

  const { mutateAsync: editVendorPay, isLoading: editVendorPayLoading } =
    useEditBasicPayrollById();

  useEffect(() => {
    if (viewData) {
      setPayloadData(viewData);
      setType(previewType);
      setNetPay(viewData.net_pay);
      setId(viewData.id);
    }
  }, [viewData]);

  const submitForm = async () => {
    let payload = {
      id: id,
      data: {
        net_pay: netPay,
      },
    };
    await editVendorPay(payload).then((result) => {
      closeModal();
    });
  };
  return (
    <Modal
      scrollable={true}
      className="!max-w-[700px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col overflow-auto">
        <div className="form">
          <div className="pl-8 pr-8 pt-4">
            <div className="flex flex-row justify-between items-center">
              <span className="header-4">
                {type === "view"
                  ? "Vendor Payment Detailed"
                  : "Edit Vendor Payment"}
              </span>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <img src={CloseSVG} alt="close" />
              </div>
            </div>
          </div>
          <hr className="divider" />
          <div className="flex flex-col mx-8">
            <div className="flex flex-col md:flex-col w-full justify-between">
              {payloadData ? (
                <>
                  <span className="p4-bold text-color-gray">Vendor name</span>
                  <span className="p3-regular text-color-black-2">
                    {payloadData.vendor_name}
                  </span>
                  <span className="p4-bold text-color-gray mt-2">
                    Bank Information
                  </span>
                  <span className="p3-regular text-color-black-2">
                    Bank Name: {payloadData.bank_name} <br />
                    Account Number: {payloadData.account_number}
                    <br />
                    Account Name: {payloadData.account_name}
                  </span>
                  <hr className="divider" />
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label={
                          type === "view" ? "Amount" : "   Amount - Editable"
                        }
                        name="net_pay"
                        type="text"
                        readOnly={type === "view" ? true : false}
                        value={netPay}
                        onInput={(e) => {
                          setNetPay(parseFloat(e.target.value));
                        }}
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:pr-2">
                      <FormInput
                        label="Fee"
                        name="fee"
                        type="text"
                        readOnly
                        value={formatNumber(payloadData?.trans_cost, 2)}
                      />
                    </div>
                  </div>
                  {type === "edit" ? (
                    <div className="w-full mb-[20px]">
                      <Button
                        text="UPDATE"
                        type="submit"
                        disabled={type === "view" ? true : false}
                        loading={editVendorPayLoading}
                        onClick={() => submitForm()}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default VendorPayDetailedView;
