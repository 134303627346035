import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../components/button";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import {
  useCheckTransactionStatus,
  useRefBasicPayrollView,
  useRevertPayroll,
} from "../../../redux/payroll/hook";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import BasicPayrollDetailedView from "../../../components/modal/payroll/basic-payroll-full-view";
import { isLoading } from "../../../redux/components/components-slice";
import { useSelector } from "react-redux";
import UnauthorizedPage from "../../errors/unauthorized";

const BasicPayrollViewPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (
          user.permissions.company_permission.payroll_read !== 1 ||
          user.permissions.payroll_permission?.basic_payroll_write !== 1
        ) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const loading = useSelector(isLoading);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { refId } = useParams();
  const [previewData, setPreviewData] = useState();
  const [showBasicPayrollDetailedView, setShowBasicPayrollDetailedView] =
    useState(false);
  const { data: basicPayroll } = useRefBasicPayrollView(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: checkTransactionStatus } = useCheckTransactionStatus();

  const history = useHistory();
  const { mutateAsync: revertPayroll } = useRevertPayroll();

  // useEffect(() => {
  //   if (basicPayroll) {
  //     if (loading === false && basicPayroll.data.length < 1){
  //       revertPayroll().then(() => {
  //         history.push('/payroll', {tab: 2})
  //       })
  //     }
  //   }
  // }, []);

  const tableConfig = {
    headers: ["Employee Name", "Amount", "Fee", "Total"],
    keys: ["employeeName", "formatted_net_pay", "trans_cost", "total"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Amount",
        key: "net_pay",
      },
    },
  };

  const actionConfig = [
    {
      name: "Details",
      color: "green",
      action: (id) => {
        let index = basicPayroll.data.findIndex((item) => item.id === id);
        setPreviewData(basicPayroll.data[index]);
        setShowBasicPayrollDetailedView(true);
      },
    },
  ];

  const statusConfig = [
    {
      label: "Pending",
      color: "orange",
      value: 0,
    },
    {
      label: "Successful",
      color: "green",
      value: 1,
    },
    {
      label: "Failed",
      color: "red",
      value: 1,
    },
  ];

  return (
    <DashboardLayout title="Basic Payroll View">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {previewData && showBasicPayrollDetailedView ? (
            <BasicPayrollDetailedView
              isOpen={showBasicPayrollDetailedView}
              closeModal={() => setShowBasicPayrollDetailedView(false)}
              viewData={previewData}
            />
          ) : null}
          <div className="flex flex-col">
            <div className="flex flex-col bg-white rounded">
              <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
                <div className="p3-bold header-4">Basic Payroll Breakdown</div>
                {/* <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
                <div className='md:w-fit w-[100%]'>
                  <Button
                  text='Export'
                  type='submit'
                  theme='primary'
                  className='flex gap-2 !h-[35px] w-fit p-2'
                  textClass={'!text-[11px]'}
                  leftIcon={'export'}
                  onClick={() => ""}
                  />
                </div>
              </div> */}
              </div>
              <hr className="divider mt-2 mb-2" />
              <div className="w-[100%]">
                <DataTable
                  data={basicPayroll}
                  tableConfig={tableConfig}
                  statusConfig={statusConfig}
                  actionConfig={actionConfig}
                  noCheck={true}
                  updatePagination={(data) => setPagination(data)}
                  computedActions={true}
                  filterByStatus={true}
                  searchData={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default BasicPayrollViewPage;
