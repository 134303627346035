import React, { useState } from 'react';
import Button from '../../button';
import ButtonSelect from '../../button-select';
import CloseSVG from '../../../assets/svg/close.svg';
import Modal from '../modal';
import AddOtherDeductionModal from './add-other-deduction';
import BulkAddOtherDeduction from './bulk-add-other-deduction';

function SelectOtherDeductionOption({ isOpen, closeModal, refresh }) {
  const [option, setOption] = useState('single');
  const [showAddOtherDeduction, setShowAddOtherDeduction] = useState(false);
  const [showAddBulkOtherDeduction, setShowAddBulkOtherDeduction] = useState(false);

  function submit() {
    if (option === 'single') {
      setShowAddOtherDeduction(true);
    } else if (option === 'bulk') {
      setShowAddBulkOtherDeduction(true);
    }
  }

  return (
    <>
      {showAddOtherDeduction ? (
        <AddOtherDeductionModal
          isOpen={showAddOtherDeduction}
          completed={() => {
            setShowAddOtherDeduction(false);
            closeModal();
          }}
          closeModal={() => {
            setShowAddOtherDeduction(false);
          }}  
          overtimeData={null}
          title='Add Other Salary Deduction'
          buttonText='SAVE DEDUCTION'
        />
      ) : null}

      {showAddBulkOtherDeduction ? (
      <BulkAddOtherDeduction
        isOpen={showAddBulkOtherDeduction}
        closeModal={() => setShowAddBulkOtherDeduction(false)}
        completed={() => {
          setShowAddBulkOtherDeduction(false);
          closeModal();
        }}
      />
      ) : null}
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Select deduction upload option</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col items-center w-full gap-3'>
            <span className='text-[15px] -mb-2'>
              Select your desired option to add Employee Overtime
            </span>
            <div className='flex flex-col justify-center h-full min-h-[400px] max-w-[85%] w-full'>
              <ButtonSelect
                title={'Add Single Other Salary Deduction'}
                subtitle={'Add Other Salary Deduction one after the other'}
                isActive={option === 'single'}
                onClick={() => setOption('single')}
              />

              <ButtonSelect
                title={'Import Other Salary Deductions'}
                subtitle={
                  'Import Other Salary Deduction using a pre-defined XLSX template'
                }
                isActive={option === 'bulk'}
                onClick={() => setOption('bulk')}
                className='mt-8'
              />

              <div className='w-full mt-[30px] mb-[20px]'>
                <Button text='CONTINUE' type='submit' onClick={() => submit()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SelectOtherDeductionOption;
