import React, { useEffect } from "react";
import { useState } from "react";
import FormSelect from "../../form-select";
import Button from "../../button";
import FormInput from "../../form-input";
import LoadingBar from "../../loader-bar";
import {
  useGetLeaveSettings,
  useUpdateLeaveSettings,
} from "../../../redux/human-resources/hook/leave";
import TeamSettingsSVG from "../../../assets/svg/team-settings.svg";

const LeaveSettings = () => {
  const { data: leave_settings, isLoading } = useGetLeaveSettings("company");
  const [dayCount, setDayCount] = useState("seven_days");
  const [applicationStart, setApplicationStart] = useState(60);

  useEffect(() => {
    if (leave_settings) {
      setDayCount(leave_settings.days_count);
      setApplicationStart(leave_settings.application_start);
    }
  }, [leave_settings]);

  const dayCountOptions = [
    { value: "seven_days", label: "Calendar Days" },
    { value: "week_days", label: "Working Days" },
  ];

  const {
    mutateAsync: updateLeaveSettings,
    isLoading: updateLeaveSettingsLoading,
  } = useUpdateLeaveSettings();

  const submitForm = async () => {
    let payload = {
      source: "company",
      source_id: null,
      application_start: applicationStart,
      days_count: dayCount,
    };
    await updateLeaveSettings(payload);
  };

  return (
    <>
      <div className="flex flex-col bg-white w-full rounded py-3 h-full min-h-fit -mt-12">
        <div className="flex flex-col px-8 pt-2">
          <span className="header-4">Company Leave Settings</span>
          <span className="text-color-gray text-[13px]">
            Update and edit your leave settings
          </span>
        </div>
        <hr className="divider mt-3 mb-3" />
        <div className="flex flex-col px-8">
          <div className="flex flex-col md:flex-row">
            <div className="flex w-full max-w-[100%] px-4 md:px-0 flex-col">
              {isLoading ? (
                <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                  <LoadingBar loading={isLoading} />
                </div>
              ) : (
                <div className="flex flex-row">
                  <div className="md:w-1/2 w-full">
                    <div className="flex flex-row items-center">
                      <div className="w-full pr-2">
                        <FormInput
                          label="Application Start (From Date Of Employment)"
                          name="application_start"
                          type="number"
                          value={applicationStart}
                          onInput={(e) => {
                            setApplicationStart(e.target.value);
                          }}
                        />
                      </div>
                      <span className="pt-1 italic text-[14px] text-[#0d5225]">
                        Days
                      </span>
                    </div>
                    <div className="flex flex-row items-center">
                      <div className="w-full">
                        <FormSelect
                          value={dayCount}
                          options={dayCountOptions}
                          onChange={(selected) => {
                            setDayCount(selected);
                          }}
                          label="Days Count"
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full mt-[20px]">
                      <Button
                        text="UPDATE LEAVE SETTINGS"
                        type="button"
                        theme={"third"}
                        disabled={!applicationStart}
                        loading={updateLeaveSettingsLoading}
                        onClick={() => submitForm()}
                        className="flex gap-2 !h-[35px] w-fit px-2"
                        textClass={"!text-[11px]"}
                      />
                    </div>
                  </div>
                  <div className="hidden md:flex flex-col w-1/2 justify-center items-center h-full">
                    <img
                      src={TeamSettingsSVG}
                      alt={"Refresh"}
                      className={"h-[300px] w-[80%]"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveSettings;
