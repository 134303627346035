import React, { useEffect, useState } from "react";
import FormInput from "../../../components/form-input";
import { Initials, formValidation } from "../../../utils/functions";
import { useForm } from "react-hook-form";
import {
  useClockInOutOnSite,
  useFetchAttendanceDataOnSite,
  useFetchCompanyAttendanceInfo,
} from "../../../redux/human-resources/hook/attendance";
import Button from "../../../components/button";
import { useParams } from "react-router-dom";
import Logo from "../../../components/logo";
import LoadingBar from "../../../components/loader-bar";

const ClockInOutOnSitePage = () => {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });
  useFetchCompanyAttendanceInfo;
  const {
    mutateAsync: fetchAttendanceData,
    isLoading: fetchAttendanceDataloading,
  } = useFetchAttendanceDataOnSite();
  const { mutateAsync: clockInOut, isLoading: clockInOutloading } =
    useClockInOutOnSite();

  const { token } = useParams();

  const [fetchData, setFetchData] = useState(true);
  const [clockStatus, setClockStatus] = useState("clock_in");
  const [mobile, setMobile] = useState();

  const { data: companyData, isLoading: companyDataLoading } =
    useFetchCompanyAttendanceInfo(token);

  const clock = () => {
    let payload = {
      mobile,
      token,
    };
    clockInOut(payload).then((result) => {
      if (result.status === "success") {
        setFetchData(true);
        setMobile("");
      }
    });
  };

  const getAttendanceData = () => {
    let payload = {
      mobile,
      token,
    };
    fetchAttendanceData(payload).then((result) => {
      if (result.status === "success") {
        setFetchData(false);
        if (result.data.clock_status === "Not clocked in") {
          setClockStatus("clock_in");
        } else {
          setClockStatus("clock_out");
        }
      }
    });
  };

  return (
    <div className="flex flex-col w-full relative justify-between items-center max-h-screen min-h-screen gap-4">
      {companyDataLoading ? (
        <LoadingBar loading={companyDataLoading} />
      ) : (
        <>
          {companyData ? (
            <div className="flex flex-col w-full gap-4 justify-between items-center mt-36">
              <div className="flex flex-row items-center gap-4 p-4 border bg-secondary-2-extralight rounded-md">
                <div className="text-[25px] flex justify-center items-center h-[60px] w-[60px] border-[3px] header-3 rounded-[60px] relative hover:scale-110 hover:m-2 hover:opacity-25 duration-500 text-black font-bold">
                  {companyData?.logo ? (
                    <div>
                      <img
                        src={companyData?.logo}
                        alt="email_icon"
                        className="object-fit min-w-[52px] min-h-[52px] max-w-[52px] max-h-[52px] rounded-[52px]"
                      />
                    </div>
                  ) : (
                    <span className="!text-black font-bold text-[30px]">
                      {Initials(companyData?.company_name)}
                    </span>
                  )}
                </div>

                <button className="lg:flex !text-black header-3 text-[30px]">
                  {companyData?.company_name}
                </button>
              </div>
              <div className="flex flex-row justify-center items-center w-1/2">
                <span className="text-[30px] font-extrabold text-center">
                  Company Time Clock
                </span>
              </div>
              {fetchData ? (
                <div className="flex md:flex-row flex-col items-center justify-center gap-4">
                  <FormInput
                    // label='Phone Number'
                    name="mobile"
                    type="text"
                    placeholder={"Enter your mobile number"}
                    className={"md:w-[250px] w-[350px] !h-[35px] mt-3"}
                    inputRef={register(formValidation("text", true))}
                    readOnly={fetchAttendanceDataloading || clockInOutloading}
                    value={mobile}
                    onInput={(e) => {
                      setMobile(e.target.value);
                    }}
                  />
                  <Button
                    text="Fetch Data"
                    type="button"
                    className={"md:w-[220px] w-[70%] !h-[35px] !bg-secondary-2"}
                    loading={fetchAttendanceDataloading}
                    onClick={() => {
                      getAttendanceData();
                    }}
                  />
                </div>
              ) : (
                <div className="flex flex-col gap-3">
                  <Button
                    text={clockStatus == "clock_in" ? "Clock In" : "Clock Out"}
                    type="button"
                    className={"md:w-[300px] w-[70%] !h-[50px] !bg-secondary-2"}
                    loading={clockInOutloading}
                    onClick={() => {
                      clock();
                    }}
                  />
                  <Button
                    text={"Refresh Page"}
                    type="button"
                    theme={"secondary"}
                    className={"md:w-[300px] w-[70%] !h-[50px] !bg-secondary-2"}
                    onClick={() => {
                      setFetchData(true);
                      setMobile("");
                    }}
                  />
                </div>
              )}
            </div>
          ) : null}
        </>
      )}
      <div className={"flex items-center justify-center text-black gap-2 "}>
        Powered By:{" "}
        <a
          href="https://www.ropay.africa"
          target={"_blank"}
          rel="noreferrer"
          className="mt-2"
        >
          {<Logo width="70px" height="35px" />}
        </a>
      </div>
    </div>
  );
};

export default ClockInOutOnSitePage;
