import DOMPurify from 'dompurify';
import React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import FormSwitch from '../../form-switch';
import LoadingBar from '../../loader-bar';
import { useGetPFA } from '../../../redux/bank/hook';
import {
  useActivateOrDeactivateEmployee,
  useActivateOrDeactivatePendingEmployee,
  useActivateOrDeactivatePreEmployee,
  useGetEmployee,
  useUpdateEmployee,
} from '../../../redux/employees/hook/index';
import moment from 'moment';
import { AllCountries } from '../../../constants/countries';
import { store } from '../../../redux';
import { setAlert } from '../../../redux/components/components-slice';
import classNames from 'classnames';
import { OfferLetter } from '../../modal/hr-section/recruitment/offer-letter';
import SendOfferLetter from '../../modal/hr-section/recruitment/send-offer-letter';

const EmployeeProfile = () => {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { id } = useParams();
  const { data: employee, isLoading } = useGetEmployee(id);
  const { data: PFAs } = useGetPFA();
  const [pfa_name, setPFAName] = useState('');
  const [showSendOfferLetter, setShowSendOfferLetter] = useState(false);
  const [activateEmployee, setActivateEmployee] = useState(false);
  const { mutateAsync: updateEmployee, isLoading: loading } =
    useUpdateEmployee();

  const {
    mutateAsync: pendingActivateStatusChange,
    isLoading: pendingActivateStatusChangeLoading,
  } = useActivateOrDeactivatePendingEmployee();
  const {
    mutateAsync: ActivateOrDeactivate,
    isLoading: ActivateOrDeactivateLoading,
  } = useActivateOrDeactivateEmployee();
  const { mutateAsync: activatePreEmployee, isLoading: preActivateLoading } =
    useActivateOrDeactivatePreEmployee();

  const [gender, setGender] = useState('Male');
  const [country, setCountry] = useState('nigeria');
  const [maritalStatus, setMaritalStatus] = useState('single');

  const maritalOptions = [
    { value: 'single', label: 'Single' },
    { value: 'married', label: 'Married' },
  ];

  const [editMode, setEditMode] = useState(false);

  const genderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ];

  useEffect(() => {
    if (employee) {
      setActivateEmployee(employee?.status == 1 ? true : false);
      setGender(employee.gender);
      setCountry(employee.country);
      setPFAName(employee.pfa_name);
      setMaritalStatus(employee.marital_status);
    }
  }, [employee]);

  function submit(data) {
    const first_name = DOMPurify.sanitize(data?.first_name);
    const middle_name = DOMPurify.sanitize(data?.middle_name);
    const last_name = DOMPurify.sanitize(data?.last_name);
    const email = DOMPurify.sanitize(data?.email);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const staff_number = DOMPurify.sanitize(data?.staff_number);
    const dob = DOMPurify.sanitize(data?.dob)
      ? DOMPurify.sanitize(data?.dob)
      : '2003-01-01';
    const street_address = DOMPurify.sanitize(data?.street_address);
    const city = DOMPurify.sanitize(data?.city);
    const state = DOMPurify.sanitize(data?.state);
    const tax_number = DOMPurify.sanitize(data?.tax_number);
    const rsa_pin = DOMPurify.sanitize(data?.rsa_pin);
    const date_joined = DOMPurify.sanitize(data?.date_joined);
    const date_left = DOMPurify.sanitize(data?.date_left);
    const job_position = DOMPurify.sanitize(data?.job_position);
    const next_of_kin_name = DOMPurify.sanitize(data?.next_of_kin_name);
    const next_of_kin_mobile = DOMPurify.sanitize(data?.next_of_kin_mobile);
    const next_of_kin_email = DOMPurify.sanitize(data?.next_of_kin_email);
    const next_of_kin_relationship = DOMPurify.sanitize(
      data?.next_of_kin_relationship,
    );
    const next_of_kin_address = DOMPurify.sanitize(data?.next_of_kin_address);

    let payload = {
      employeeId: id,
      data: {
        first_name,
        middle_name,
        last_name,
        email,
        mobile,
        gender,
        staff_number,
        tax_number,
        dob,
        country,
        street_address,
        city,
        state,
        pfa_name,
        rsa_pin,
        date_joined,
        date_left,
        job_position,
        marital_status: maritalStatus,
        next_of_kin_name,
        next_of_kin_mobile,
        next_of_kin_email,
        next_of_kin_relationship,
        next_of_kin_address,
      },
    };

    updateEmployee(payload).then((result) => {
      setEditMode(false);
    });
  }

  const changeStatus = () => {
    if (employee) {
      if (employee.status == 4) {
        pendingActivateStatusChange({
          id: employee.id,
          type: 'activate',
        });
      } else if (employee.status == 5) {
        activatePreEmployee({
          id: employee.id,
          type: 'activate',
        });
      } else {
        ActivateOrDeactivate(employee.id);
      }
    } else {
      store.dispatch(setAlert(true, 'info', 'No Employee found'));
    }
  };

  return (
    <>
      {showSendOfferLetter ? (
        <SendOfferLetter
          title={'Send Offer Letter'}
          isOpen={showSendOfferLetter}
          source={'employee'}
          closeModal={() => setShowSendOfferLetter(false)}
          applicant={employee}
          buttonText='SEND LETTER'
        />
      ) : null}
      <div className='bg-white h-full w-full rounded'>
        <div className='flex flex-col md:flex-row justify-between items-center px-4 pt-3 gap-[10px] my-2 mt-3'>
          <div className='md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full'>
            Employee Profile
          </div>
          <div className='flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full'>
            <div className='md:w-fit w-[100%]'>
              <Button
                text={
                  employee?.status == 0
                    ? 'ACTIVATE EMPLOYEE'
                    : employee?.status == 4 ||
                      employee?.status == 2 ||
                      employee?.status == 5
                    ? 'ACTIVATE PENDING EMPLOYEE'
                    : 'DEACTIVATE EMPLOYEE'
                }
                type='button'
                className={classNames(
                  'flex gap-2 !h-[35px] md:w-fit w-full px-2 ',
                  {
                    '!bg-red-500': employee?.status == 1,
                    '!bg-hr-primary-1': employee?.status !== 1,
                  },
                )}
                leftIcon={'evaluate'}
                textClass={'!text-[11px]'}
                loading={
                  pendingActivateStatusChangeLoading ||
                  ActivateOrDeactivateLoading ||
                  preActivateLoading
                }
                onClick={() => {
                  changeStatus();
                }}
              />
            </div>
            {employee?.offer_letter ? null : (
              <div className='md:w-fit w-[100%]'>
                <Button
                  text='Send Offer Letter'
                  type='submit'
                  leftIcon={'email'}
                  className='flex gap-2 !h-[35px] md:w-fit w-full px-2 '
                  textClass={'!text-[11px]'}
                  onClick={() => {
                    setShowSendOfferLetter(true);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <hr className='divider' />
        {isLoading ? (
          <div className='flex flex-col w-full text-center justify-center pb-[25px] pt-[5px] px-4'>
            <LoadingBar loading={isLoading || loading} />
          </div>
        ) : (
          <>
            {employee ? (
              <div className='px-4 pb-4'>
                <div className='w-full md:w-[20%] mt-2 !font-semibold'>
                  <FormSwitch
                    label={'Edit Mode'}
                    name={'switch-edit-mode'}
                    checked={editMode}
                    onClick={() => setEditMode(!editMode)}
                    value={editMode}
                  />
                </div>
                {editMode ? (
                  <form
                    className='form border p-4 rounded-md w-full'
                    onSubmit={handleSubmit(submit)}
                  >
                    <div className='w-full space-y-4 mt-3'>
                      {/* Personal Information - First Row */}
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 w-full'>
                        <div className='w-full'>
                          <FormInput
                            label='First Name'
                            name='first_name'
                            type='text'
                            className='!bg-white'
                            defaultValue={employee?.first_name}
                            inputRef={register(formValidation('text', true))}
                            readOnly={loading}
                            error={errors.first_name}
                            errorMessage={
                              errors.first_name && errors.first_name.message
                            }
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Middle Name - (Optional)'
                            name='middle_name'
                            type='text'
                            placeholder='Enter Middle Name'
                            className='!bg-white'
                            inputRef={register(formValidation('text', false))}
                            readOnly={loading}
                            error={errors.middle_name}
                            errorMessage={
                              errors.middle_name && errors.middle_name.message
                            }
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Last Name'
                            name='last_name'
                            type='text'
                            defaultValue={employee?.last_name}
                            className='!bg-white'
                            inputRef={register(formValidation('text', true))}
                            error={errors.last_name}
                            errorMessage={
                              errors.last_name && errors.last_name.message
                            }
                          />
                        </div>
                      </div>

                      {/* Contact Information - Second Row */}
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 w-full'>
                        <div className='w-full'>
                          <FormInput
                            label='Email'
                            name='email'
                            type='text'
                            inputRef={register(formValidation('text', true))}
                            className='!bg-white'
                            defaultValue={employee?.email}
                            error={errors.email}
                            errorMessage={errors.email && errors.email.message}
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Mobile'
                            name='mobile'
                            type='number'
                            inputRef={register(formValidation('number'))}
                            className='!bg-white'
                            error={errors.mobile}
                            errorMessage={
                              errors.mobile && errors.mobile.message
                            }
                            defaultValue={employee?.mobile}
                          />
                        </div>
                        <div className='w-full'>
                          <FormSelect
                            defaultValue={gender}
                            options={genderOptions}
                            extraClass='!bg-white'
                            onChange={(selected) => setGender(selected)}
                            label='Gender'
                          />
                        </div>
                      </div>

                      {/* Employment Details - Third Row */}
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 w-full'>
                        <div className='w-full'>
                          <FormInput
                            label='Staff Number'
                            name='staff_number'
                            inputRef={register(formValidation('text'))}
                            className='!bg-white'
                            error={errors.staff_number}
                            errorMessage={
                              errors.staff_number && errors.staff_number.message
                            }
                            defaultValue={employee?.staff_number}
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Date Of Birth'
                            name='dob'
                            type='date'
                            className='!bg-white'
                            defaultValue={employee?.dob}
                            inputRef={register(formValidation('text'))}
                            error={errors.dob}
                            errorMessage={errors.dob && errors.dob.message}
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Date Joined'
                            name='date_joined'
                            type='date'
                            className='!bg-white'
                            defaultValue={employee?.date_joined?.split(' ')[0]}
                            inputRef={register(formValidation('text'))}
                            error={errors.date_joined}
                            errorMessage={
                              errors.date_joined && errors.date_joined.message
                            }
                          />
                        </div>
                      </div>

                      <hr className='divider' />

                      {/* Additional Details - Fourth Row */}
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 w-full'>
                        <div className='w-full'>
                          <FormInput
                            label='Date Left'
                            name='date_left'
                            type='date'
                            className='!bg-white'
                            defaultValue={employee?.date_left?.split(' ')[0]}
                            inputRef={register(formValidation('text'))}
                            error={errors.date_left}
                            errorMessage={
                              errors.date_left && errors.date_left.message
                            }
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Tax ID'
                            name='tax_number'
                            type='text'
                            className='!bg-white'
                            defaultValue={employee?.tax_number}
                            inputRef={register(formValidation('text'))}
                            error={errors.tax_number}
                            errorMessage={
                              errors.tax_number && errors.tax_number.message
                            }
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Pension RSA Pin'
                            name='rsa_pin'
                            type='text'
                            className='!bg-white'
                            defaultValue={employee?.rsa_pin}
                            inputRef={register(formValidation('text'))}
                            error={errors.rsa_pin}
                            errorMessage={
                              errors.rsa_pin && errors.rsa_pin.message
                            }
                          />
                        </div>
                      </div>

                      {/* PFA and Location - Fifth Row */}
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 w-full'>
                        {PFAs && (
                          <div className='w-full'>
                            <FormSelect
                              value={pfa_name}
                              options={PFAs}
                              onChange={(selected) => setPFAName(selected)}
                              extraClass='!bg-white'
                              label='Pension Fund Administrator (PFA)'
                            />
                          </div>
                        )}
                        <div className='w-full'>
                          <FormSelect
                            defaultValue={country}
                            options={AllCountries}
                            onChange={(selected) => setCountry(selected)}
                            extraClass='!bg-white'
                            label='Country'
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='State'
                            name='state'
                            type='text'
                            className='!bg-white'
                            defaultValue={employee?.state}
                            inputRef={register(formValidation('text'))}
                            readOnly={loading}
                            error={errors.state}
                            errorMessage={errors.state && errors.state.message}
                          />
                        </div>
                      </div>

                      {/* Location and Status - Sixth Row */}
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 w-full'>
                        <div className='w-full'>
                          <FormInput
                            label='City'
                            name='city'
                            type='text'
                            className='!bg-white'
                            defaultValue={employee?.city}
                            inputRef={register(formValidation('text'))}
                            readOnly={loading}
                            error={errors.city}
                            errorMessage={errors.city && errors.city.message}
                          />
                        </div>
                        <div className='w-full'>
                          <FormSelect
                            defaultValue={maritalStatus}
                            options={maritalOptions}
                            onChange={(selected) => setMaritalStatus(selected)}
                            extraClass='!bg-white'
                            label='Marital Status'
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Job Role'
                            name='job_position'
                            type='text'
                            defaultValue={employee?.job_position}
                            inputRef={register(formValidation('text'))}
                            readOnly={loading}
                            className='!bg-white'
                            error={errors.job_position}
                            errorMessage={
                              errors.job_position && errors.job_position.message
                            }
                          />
                        </div>
                      </div>

                      {/* Next of Kin - First Row */}
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 w-full'>
                        <div className='w-full'>
                          <FormInput
                            label='Address'
                            name='street_address'
                            type='text'
                            className='!bg-white'
                            defaultValue={employee?.street_address}
                            inputRef={register(formValidation('text'))}
                            readOnly={loading}
                            error={errors.street_address}
                            errorMessage={
                              errors.street_address &&
                              errors.street_address.message
                            }
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Next Of Kin Name - (Optional)'
                            name='next_of_kin_name'
                            type='text'
                            className='!bg-white'
                            defaultValue={employee?.next_of_kin_name}
                            placeholder='Enter Full Name'
                            inputRef={register(formValidation('text', false))}
                            readOnly={loading}
                            error={errors.next_of_kin_name}
                            errorMessage={
                              errors.next_of_kin_name &&
                              errors.next_of_kin_name.message
                            }
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Next Of Kin Mobile Number - (Optional)'
                            name='next_of_kin_mobile'
                            type='text'
                            className='!bg-white'
                            defaultValue={employee?.next_of_kin_mobile}
                            placeholder='Enter Next Of Kin Mobile'
                            inputRef={register(formValidation('text', false))}
                            readOnly={loading}
                            error={errors.next_of_kin_mobile}
                            errorMessage={
                              errors.next_of_kin_mobile &&
                              errors.next_of_kin_mobile.message
                            }
                          />
                        </div>
                      </div>

                      {/* Next of Kin - Second Row */}
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 w-full'>
                        <div className='w-full'>
                          <FormInput
                            label='Next Of Kin Email - (Optional)'
                            name='next_of_kin_email'
                            type='text'
                            defaultValue={employee?.next_of_kin_email}
                            placeholder='Enter Next Of Kin Email'
                            inputRef={register(formValidation('text', false))}
                            readOnly={loading}
                            error={errors.next_of_kin_email}
                            errorMessage={
                              errors.next_of_kin_email &&
                              errors.next_of_kin_email.message
                            }
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Relationship - (Optional)'
                            name='next_of_kin_relationship'
                            type='text'
                            className='!bg-white'
                            defaultValue={employee?.next_of_kin_relationship}
                            placeholder='What is your relationship?'
                            inputRef={register(formValidation('text', false))}
                            readOnly={loading}
                            error={errors.next_of_kin_relationship}
                            errorMessage={
                              errors.next_of_kin_relationship &&
                              errors.next_of_kin_relationship.message
                            }
                          />
                        </div>
                        <div className='w-full'>
                          <FormInput
                            label='Next Of Kin Address - (Optional)'
                            name='next_of_kin_address'
                            type='text'
                            className='!bg-white'
                            defaultValue={employee?.next_of_kin_address}
                            placeholder='Enter Address of Next Of Kin'
                            inputRef={register(formValidation('text', false))}
                            readOnly={loading}
                            error={errors.next_of_kin_address}
                            errorMessage={
                              errors.next_of_kin_address &&
                              errors.next_of_kin_address.message
                            }
                          />
                        </div>
                      </div>

                      {/* Employee Status Switch */}
                      {activateEmployee === 2 && (
                        <div className='w-full'>
                          <FormSwitch
                            label='Deactivate employee'
                            name='activate-employee'
                            checked={activateEmployee}
                            onClick={() =>
                              setActivateEmployee(!activateEmployee)
                            }
                          />
                        </div>
                      )}

                      {/* Submit Button */}
                      <div className='flex justify-end'>
                        <Button
                          text='Update Employee Information'
                          type='submit'
                          loading={loading}
                          disabled={loading}
                          className='h-[35px] w-fit px-4'
                          textClass='text-sm'
                        />
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className='flex flex-col md:flex-row border rounded-md -mt-2'>
                    <div className='w-[100%] p-3 md:w-3/5'>
                      <div className='header-4 mb-2'>Employee Information</div>
                      <div className='grid md:grid-cols-3 grid-cols-1 gap-4 w-full mt-3'>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            First name
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.first_name
                              ? employee.first_name
                              : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Middle name
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.middle_name
                              ? employee.middle_name
                              : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Last name
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.last_name
                              ? employee.last_name
                              : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Email Address
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.email ? employee.email : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Phone number{' '}
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.mobile ? employee.mobile : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Employee ID
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.staff_number
                              ? employee.staff_number
                              : 'Not Set'}
                          </span>
                        </div>

                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Tax ID
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.tax_number
                              ? employee.tax_number
                              : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Pension RSA Pin
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {' '}
                            {employee.rsa_pin ? employee.rsa_pin : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            PFA Name
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {' '}
                            {pfa_name ? pfa_name : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Date Joined
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {moment(employee.date_joined).format(
                              'MMMM D, YYYY',
                            )}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            {' '}
                            Date Left
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.date_left
                              ? employee.date_left
                              : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Position
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.job_position
                              ? employee.job_position
                              : 'Not Set'}
                          </span>
                        </div>
                      </div>
                      <hr className='divider' />
                      <div className='header-4 mb-2'>
                        Other Employee Information
                      </div>
                      <div className='grid md:grid-cols-3  grid-cols-1 gap-4 w-full mt-3'>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Gender
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.gender ? employee.gender : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Country
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.country ? employee.country : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            State
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {' '}
                            {employee.state ? employee.state : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Marital Status
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {' '}
                            {employee.marital_status
                              ? employee.marital_status
                              : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            City
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {' '}
                            {employee.city ? employee.city : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            {' '}
                            Address
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.street_address
                              ? employee.street_address
                              : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Date of birth
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.dob
                              ? moment(employee.dob).format('MMMM D, YYYY')
                              : 'Not Set'}
                          </span>
                        </div>
                      </div>
                      <hr className='divider' />
                      <div className='header-4 mb-2'>
                        Employee Next Of Kin Information
                      </div>
                      <div className='grid md:grid-cols-3  grid-cols-1 gap-4 w-full mt-3'>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Next Of Kin Name
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.next_of_kin_name
                              ? employee.next_of_kin_name
                              : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Next Of Kin Mobile
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.next_of_kin_mobile
                              ? employee.next_of_kin_mobile
                              : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Next Of Kin Email
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.next_of_kin_email
                              ? employee.next_of_kin_email
                              : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Next Of Kin Relationship
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.next_of_kin_relationship
                              ? employee.next_of_kin_relationship
                              : 'Not Set'}
                          </span>
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[12px] text-color-gray'>
                            Next Of Kin Address
                          </span>
                          <span className='mt-1 text-color-black text-[13px]'>
                            {employee.next_of_kin_address
                              ? employee.next_of_kin_address
                              : 'Not Set'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='w-[100%] p-4 md:w-2/5 h-fit'>
                      <OfferLetter
                        offerLetter={employee?.offer_letter}
                        signedOfferLetter={employee?.signed_offer_letter}
                        type={'employee'}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default EmployeeProfile;
