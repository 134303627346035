import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CloseSVG from '../../../../assets/svg/close.svg';
import { PAGINATION_DEFAULT } from '../../../../utils/constants';
import { formValidation } from '../../../../utils/functions';
import WarningSVG from '../../../../assets/svg/warning.svg';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import FormSelectEmployee from '../../../employees/form-select-employee';
import {
  useAddLeave,
  useEditLeave,
  useGetApplicationLeaveTypes,
} from '../../../../redux/human-resources/hook/leave';
import AddLeaveType from './add-leave-type';
import Modal from '../../modal';
import moment from 'moment';

function AddLeave({
  isOpen,
  closeModal,
  refresh,
  leaveData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });
  const [leaveId, setLeaveId] = useState(null);
  const [leaveChangeType, setLeaveChangeType] = useState('add');

  const { data: leave_types, isLoading: leaveTypeLoading } =
    useGetApplicationLeaveTypes(
      pagination?.limit,
      pagination?.page,
      pagination?.search,
    );

  useEffect(() => {
    if (leaveData) {
      setLeaveId(leaveData.id);
      setLeaveChangeType('edit');
      setLeaveType(leaveData.leave_type);
      setEmployee(leaveData.employee_id);
      setEmployeeName(`${leaveData.first_name} ${leaveData.last_name}`);
      setStartDate(leaveData.start_date);
      setEndDate(leaveData.end_date);
      setPurpose(leaveData.purpose);
    } else {
      setLeaveChangeType('add');
    }
  }, [leaveData]);

  const { mutateAsync: addLeave, isLoading: addLeaveloading } = useAddLeave();

  const { mutateAsync: editLeave, isLoading: editLeaveloading } =
    useEditLeave();

  useEffect(() => {
    if (leave_types) {
      setLeaveTypeOptions(leave_types);
    } else {
      setShowAddLeaveType(true);

      setLeaveTypeOptions([
        { value: 'emergency', label: 'Emergency Leave' },
        { value: 'annual', label: 'Annual Leave' },
        { value: 'paternity', label: 'Paternity' },
        { value: 'maternity', label: 'Maternity' },
        { value: 'sick', label: 'Sick' },
      ]);
    }
  }, [leave_types]);

  const [leaveType, setLeaveType] = useState();

  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().add(7, 'days').format('YYYY-MM-DD'));
  const [purpose, setPurpose] = useState();

  const [leaveTypeOptions, setLeaveTypeOptions] = useState();
  const [showAddLeaveType, setShowAddLeaveType] = useState(false);

  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();

  const history = useHistory();

  const submitForm = async (data) => {
    const start_date = DOMPurify.sanitize(data?.start_date);
    const end_date = DOMPurify.sanitize(data?.end_date);
    const purpose = DOMPurify.sanitize(data?.purpose);

    let payload = {
      employee_id: employee,
      leave_type: leaveType,
      start_date: start_date,
      end_date: end_date,
      purpose,
    };
    if (leaveChangeType === 'add') {
      addLeave(payload).then(() => {
        closeModal();
      });
    } else {
      let editPayload = {
        id: leaveId,
        data: payload,
      };
      editLeave(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <>
      {!leave_types && !leaveTypeLoading ? (
        <AddLeaveType
          title={'Add Leave Type'}
          isOpen={showAddLeaveType}
          buttonText='Save Leave Type'
          closeModal={() => setShowAddLeaveType(false)}
        />
      ) : null}
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form' onSubmit={handleSubmit(submitForm)}>
            <div className='pl-8 pr-8 pt-4'>
              <div className='flex flex-row justify-between items-center'>
                <span className='header-4'>{title}</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='close' />
                </div>
              </div>
            </div>
            <hr className='divider mb-2' />
            <div className='flex flex-col mx-8'>
              <div className='border bg-highlight p-2 rounded-md'>
                <FormSelectEmployee
                  label={'Employee'}
                  name='employee'
                  placeholder='Type to select Employee'
                  value={employee}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
              <hr className='divider mt-2 mb-2' />
              <div className='flex flex-row md:flex-col w-full justify-between'>
                {leave_types && leaveTypeOptions ? (
                  <div className='w-full'>
                    <FormSelect
                      value={leaveType}
                      options={leaveTypeOptions}
                      onChange={(selected) => {
                        setLeaveType(selected);
                      }}
                      label='Leave Type'
                    />
                  </div>
                ) : null}
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:pr-2 md:w-1/2'>
                  <FormInput
                    label='Start Date'
                    name='start_date'
                    type='date'
                    value={startDate}
                    onInput={(e) => {
                      setStartDate(e.target.value);
                    }}
                    inputRef={register(formValidation('date', true))}
                    error={errors.start_date}
                    errorMessage={
                      errors.start_date && errors.start_date.message
                    }
                  />
                </div>
                <div className='w-full md:pl-2 md:w-1/2'>
                  <FormInput
                    label='End Date'
                    name='end_date'
                    type='date'
                    min={moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                    value={endDate}
                    onInput={(e) => {
                      setEndDate(e.target.value);
                    }}
                    inputRef={register(formValidation('date', true))}
                    error={errors.end_date}
                    errorMessage={errors.end_date && errors.end_date.message}
                  />
                </div>
              </div>
              <FormInput
                label='Purpose'
                name='purpose'
                type='text'
                placeholder={'Enter leave purpose'}
                value={purpose}
                onInput={(e) => {
                  setPurpose(e.target.value);
                }}
                inputRef={register(formValidation('text', true))}
                error={errors.purpose}
                errorMessage={errors.purpose && errors.purpose.message}
              />
              <div className='flex flex-row p-2  border-secondary-4 border-[1.2px] rounded-lg mt-2 bg-color-warning-light'>
                <div className='w-[100px] pr-2 flex flex-row items-center '>
                  <img src={WarningSVG} alt='' srcSet='' />
                </div>
                <span className='text-[12px] text-justify px-3'>
                  Leave added here is approved automatically. This is suitable when employee does not have acces to mobile phone or an internet enabled device to apply for leave.
                </span>
              </div>
              <div className='w-full mt-4 pb-4 '>
                <Button
                  text={buttonText}
                  type='submit'
                  loading={addLeaveloading || editLeaveloading}
                  disabled={
                    leaveChangeType === 'edit' ? false : !isValid ? true : false
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddLeave;
