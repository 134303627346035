import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageNotFoundSVG from "../../assets/svg/404.svg";
import Button from "../../components/button";

function PageNotFound() {
  useEffect(() => {}, []);

  const history = useHistory();

  return (
    <div className="flex items-center flex-col px-5 md:px-0 ">
      <img src={PageNotFoundSVG} alt="" />
      <div className="flex flex-col text-center">
        <span className="header-2 md:header-1">Page not found</span>
        <div className="w-full max-w-[700px] mt-2 flex flex-col items-center">
          <span className="p3 md:header-5-regular">
            Sorry we could not find the page you are looking for <br />
            However, you can explore our amazing payroll features curated just
            for you.
          </span>
          <div className="w-full md:w-[220px] mt-3 md:mt-0 h-[56px]">
            <Button
              text="Back to Home"
              type="submit"
              className="h-[56px]"
              onClick={() => history.push("/")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
