import React, { useEffect } from "react";
import { useState } from "react";
import PayrollPlans from "./payroll-plans";
import PeoplePlans from "./people-plans";
import TabView from "../tabview";
import SubscriptionHistory from "./subscription-history";
import CurrentHrPlan from "./current-hr-plan";
import { useRouteQuery } from "../../hooks/useRouteQuery";

const PlansAndBillings = () => {
  useEffect(() => {}, []);

  const [activeTab, setActiveTab] = useState(1);

  const query = useRouteQuery();

  useEffect(() => {
    if (query) {
      if (query.get("select-plan")) {
        setActiveTab(3);
      }
    }
  }, [query]);

  const EmployeeTabs = [
    {
      title: "Payroll Pricing",
      Content: PayrollPlans,
    },
    {
      title: "Current HR/People Plan",
      Content: CurrentHrPlan,
    },
    {
      title: "HR/People Plans",
      Content: PeoplePlans,
    },

    {
      title: "Subscription History",
      Content: SubscriptionHistory,
    },
  ];

  return (
    <>
      <div className="flex flex-col bg-white w-full box-shadow pl-8 py-3 h-fit min-h-fit">
        <span className="header-4">Plans and Billings</span>
        <span className="text-[13px] text-color-gray pt-1">
          Choose the plan that is right for your business
        </span>
        <hr className="divider" />
        <div className=" pr-8">
          <TabView
            Tabs={EmployeeTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
    </>
  );
};

export default PlansAndBillings;
