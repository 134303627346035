import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  useBulkDeleteSalaryReview,
  useDeleteSalaryReview,
  useGetSalaryReviews,
} from '../../../redux/employees/hook/salary-review';
import { PAGINATION_DEFAULT, bonusesStatusConfig, salaryReviewStatusConfig } from '../../../utils/constants';
import DataTable from '../../datatable';
import FormInput from '../../form-input';
import ForwardArrowVG from '../../../assets/svg/forward-arrow.svg';
import Button from '../../button';
import Modal from '../modal';
import { formatNumber } from '../../../utils/functions';

function SalaryReviews({ isOpen, closeModal }) {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
    start_date: '',
    end_date: '',
  });

  useEffect(() => {
    setShowSingleReview(false);
  }, []);

  const { data: reviews } = useGetSalaryReviews(
    pagination?.limit,
    pagination?.page,
    pagination?.start_date,
    pagination?.end_date,
    pagination?.search,
  );

  const [salaryReviewData, setSalaryReviewData] = useState();

  const [showSingleReview, setShowSingleReview] = useState(false);

  const { mutateAsync: deleteBulkReviews } = useBulkDeleteSalaryReview();
  const { mutateAsync: deleteReview } = useDeleteSalaryReview();

  const tableConfig = {
    //title: 'Basic Payroll Summary',
    headers: ['Date Added', 'Employee Name', 'Mobile Number', 'Effective From', 'Status'],
    keys: ['created', 'employeeName', 'mobile', 'review_start_date', 'status'],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Effective From',
        key: 'review_start_date',
      },
    },
  };

  const actionConfig = [
    {
      name: 'View',
      color: 'green',
      action: (id) => {
        let index = reviews.data.findIndex((item) => item.id === id);
        let newArray = JSON.parse(JSON.stringify(reviews.data[index])) 
        newArray.old_additional_allowances = JSON.parse(newArray.old_additional_allowances)
        newArray.new_additional_allowances = JSON.parse(newArray.new_additional_allowances)
        setSalaryReviewData(newArray)
        setShowSingleReview(true);
      },
    },
    {
      name: 'Delete',
      color: 'red',
      action: (id) => {
        let index = reviews.data.findIndex((item) => item.id === id);
        deleteReview(reviews.data[index].id);
      },
    },
  ];

  const bulkActionConfig = [
    {
      label: 'Delete',
      value: 'delete',
      action: (data) => {
        let newData = data.split(',');
        let result = [];

        newData.forEach((id) => {
          let index = reviews.data.findIndex((item) => item.id == id);
          if (index != -1) {
            result.push(reviews.data[index].id);
          }
        });
        deleteBulkReviews({ ids: result.join(',') });
      },
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='md:min-w-[80%] min-w-[90%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col !min-h-fit overflow-auto'>
        <div className='pt-4 pr-8 pl-8'>
          <div className='text-center flex flex-col'>
            <div className='flex flex-row justify-between'>
              <span className='header-3'>Salary Reviews</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
        </div>
        <hr className='divider' />
        {showSingleReview ? (
          <div className='flex flex-col !min-h-fit md:justify-around  justify-center'>
            <div className=' flex flex-col justify-start gap-1 pr-8 pl-8 w-full'>
              <label className='text-[15px] font-extrabold text-color-gray'>
                Employee Name
              </label>
              <span className='text-[13px] font-semibold'>
                {salaryReviewData?.first_name} {salaryReviewData?.last_name} | {salaryReviewData?.mobile}
              </span>
            </div>
            <hr className='divider' />
            <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Basic'
                  name='basic'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_basic, 2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Basic'
                  name='basic'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_basic, 2)}
                  className='!w-[150px]'
                />
              </div>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Housing'
                  name='housing'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_housing, 2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Housing'
                  name='housing'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_housing, 2)}
                  className='!w-[150px]'
                />
              </div>
            </div>
            <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Transport'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_transport, 2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Transportion'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_transport, 2)}
                  className='!w-[150px]'
                />
              </div>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Leave'
                  name='leave'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_leave, 2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Leave'
                  name='leave'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_leave, 2)}
                  className='!w-[150px]'
                />
              </div>
            </div>
            <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Lunch'
                  name='lunch'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_lunch, 2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='Old Lunch'
                  name='lunch'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_lunch, 2)}
                  className='!w-[150px]'
                />
              </div>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='New Benefit in kind'
                  name='housing'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_benefit_in_kind, 2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Benefit in kind'
                  name='housing'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_benefit_in_kind, 2)}
                  className='!w-[150px]'
                />
              </div>
            </div>
            <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Utility'
                  name='utility'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_utility, 2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Utility'
                  name='utility'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_utility, 2)}
                  className='!w-[150px]'
                />
              </div>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Other allowance'
                  name='other'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_other_allowance, 2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Other allowance'
                  name='other'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_other_allowance, 2)}
                  className='!w-[150px]'
                />
              </div>
            </div>
            
            {typeof salaryReviewData?.new_additional_allowances !== 'string' &&
            salaryReviewData?.new_additional_allowances?.length > 0 ?
            <div className='flex flex-col gap-2 pr-8 pl-8'>
              <hr className='divider mt-2 mb-2' />
              <div className='text-[15px] font-extrabold mb-2'>Additional Allowances</div>
              <div className='flex md:flex-row flex-col md:gap-4 md:justify-start justify-center'>
                
                {salaryReviewData?.new_additional_allowances.map((item, index) => (
                <div className='flex flex-row md:w-1/2 items-center gap-2'>
                  {salaryReviewData?.old_additional_allowances.map((subItem, subIndex) => (  
                    <>
                    {subItem.name == item.name?
                    <FormInput
                      label={'Old ' + subItem.title}
                      name={'old_' + subItem.name}
                      type='text'
                      readOnly
                      value={formatNumber(subItem.amount, 2)}
                      className='!w-[150px]'
                    />
                    :
                    null}
                    </>
                  ))}
                  <img src={ForwardArrowVG} width={15} alt='to'/>
                  <FormInput
                    label={'New ' + item.title}
                    name={'new_' + item.name}
                    type='text'
                    readOnly
                    value={formatNumber(item.amount, 2)}
                    className='!w-[150px]'
                  />
                </div>
                ))}
                <hr className='divider mt-2' />
              </div>
            </div>
            :
            null
            }
            <hr className='divider mt-2 mb-2' />
            <div className='flex flex-col md:flex-row justify-start pr-8 pl-8'>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Net Pay'
                  name='net_pay'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_net_pay, 2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} height={15} alt='to' />
                <FormInput
                  label='New Net Pay'
                  name='net_pay'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_net_pay, 2)}
                  className='!w-[150px]'
                />
              </div>
            </div>
            <div className='flex flex-col md:flex-row gap-[10px] md:items-center justify-end mb-3 pr-4'>
              {/* <div className='md:w-fit w-[100%]' id='second-step'>
                <Button
                  text='Get Employee Invitation Link'
                  type='button'
                  theme='primary'
                  leftIcon={'view'}
                  className='flex gap-2 !h-[35px] w-fit p-2 '
                  textClass={'!text-[11px]'}
                  onClick={() => {
                    setShowGenerateLinkModal(true);
                  }}
                />
              </div> */}
              <div className='md:w-fit' id='second-step'>
                <Button
                  text='Back To Table'
                  type='button'
                  theme='primary'
                  className='flex gap-2 !h-[35px] w-fit p-2 '
                  textClass={'!text-[11px]'}
                  onClick={() => {
                    setShowSingleReview(false);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <DataTable
            data={reviews}
            tableConfig={tableConfig}
            actionConfig={actionConfig}
            statusConfig={salaryReviewStatusConfig}
            bulkActionConfig={bulkActionConfig}
            updatePagination={(data) => setPagination(data)}
            computedActions={true}
            searchData={true}
          />
        )}
      </div>
    </Modal>
  );
}

export default SalaryReviews;
