import React from 'react';
import Nigeria from '../../assets/svg/country-flags/ng.svg'
import Ghana from '../../assets/svg/country-flags/gh.svg'
import Rwanda from '../../assets/svg/country-flags/rw.svg'
import Kenya from '../../assets/svg/country-flags/ke.svg'
import Morocco from '../../assets/svg/country-flags/ma.svg'

export const CountryFlag = ({ country, className }) => {
  return (
    <img
        src={ 
            !country || country === 'nigeria'  ?
            Nigeria : country === 'ghana' ?
            Ghana : country === 'rwanda' ?
            Rwanda : country === 'morocco' ? 
            Morocco : Kenya
        }
        alt='country_icon'
        className={`object-fit min-w-[20px] min-h-[20px] max-w-[20px] rounded text-[5px] absolute -right-2 -bottom-2 ${className}`}
    />
  );
};