import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { recruitments } from '../../../api/hr/recruitment';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { setRecruitmentBrief } from '../reducers/recruitment';

export function useGetRecruitmentSummary() {
  return useQuery(['recuitment-summary'], () => {
    return recruitments.getRecruitmentSummary();
  });
}

export function useGetRecruitments(limit, page, statusFilter, search) {
  return useQuery(
    ['recruitments', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return recruitments.getRecruitments({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((recruitment) => {
          recruitment.created = moment(recruitment.created).format(
            'MMMM D, YYYY',
          );
          recruitment.application_end = moment(
            recruitment.application_end,
          ).format('MMMM D, YYYY');
          recruitment.computedActions = [0, 1, 2, 3];
          recruitment.status = recruitment.application_status;
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetRecruitmentWorkflows(limit, page, search) {
  return useQuery(
    ['recruitments-workflows', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return recruitments.getRecruitmentWorkflows({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((recruitment) => {
          recruitment.created = moment(recruitment.created).format(
            'MMMM D, YYYY',
          );
          recruitment.computedActions = [0, 1, 2];
          recruitment.status = recruitment.application_status;
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetRecruitmentOnboardingWorkflows(limit, page, search) {
  return useQuery(
    ['recruitments-creation-workflows', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return recruitments.getRecruitmentWorkflows({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Workflow Selected' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetRecruitmentWorkflow(id) {
  return useQuery(
    ['recruitment-workflow', id],
    () => {
      return recruitments.getRecruitmentWorkflow(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddRecruitmentWorkflow() {
  return useMutation(
    (payload) => {
      return recruitments.addRecruitmentWorkflow(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitments');
        store.dispatch(
          setAlert(true, 'success', 'Recruitment Workflow Added Successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditRecruitmentWorkflow() {
  return useMutation(
    (payload) => {
      return recruitments.updateAssignedWorkflow(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitments');
        queryClient.invalidateQueries('recruitment');
        queryClient.invalidateQueries('recruitment-workflow');
        store.dispatch(
          setAlert(
            true,
            'success',
            'Recruitment Workflow Updated Successfully',
          ),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddRecruitment() {
  return useMutation(
    (payload) => {
      return recruitments.addRecruitment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitments');
        store.dispatch(
          setAlert(true, 'success', 'Recruitment Created Successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditRecruitment() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return recruitments.updateRecruitment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment');
        store.dispatch(
          setAlert(true, 'success', 'Recruitment Updated Successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditRecruitmentStages() {
  return useMutation(
    (payload) => {
      return recruitments.updateRecruitmentStages(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-stages');
        store.dispatch(
          setAlert(true, 'success', 'Recruitment Stages Updated Successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendApplicantsEmail() {
  return useMutation(
    (payload) => {
      return recruitments.sendApplicantsEmail(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-stages');
        store.dispatch(
          setAlert(
            true,
            'success',
            'Applicants emails sending initiated successfully',
          ),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendApplicantsEmailByStage() {
  return useMutation(
    (payload) => {
      return recruitments.SendEmailsByStage(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('stage-applicants');
        store.dispatch(
          setAlert(
            true,
            'success',
            'Applicants emails sending initiated successfully',
          ),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendApplicantsEmailByStatus() {
  return useMutation(
    (payload) => {
      return recruitments.sendEmailsByStatus(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-applications');
        store.dispatch(
          setAlert(
            true,
            'success',
            'Applicants emails sending initiated successfully',
          ),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMarkApplicantReviewed() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return recruitments.markApplicantReviewed(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-applications');
        queryClient.invalidateQueries('recruitment-application');
        queryClient.invalidateQueries('stage-applicants');
        store.dispatch(
          setAlert(true, 'success', 'Applicant review status changed'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetRecruitment(id) {
  return useQuery(
    ['recruitment', id],
    () => {
      return recruitments.getRecruitment(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetRecruitmentByToken(token) {
  return useQuery(
    ['recruitment-by-token', token],
    () => {
      return recruitments.getRecruitmentByToken(token);
    },
    {
      enabled: token !== null,
      onSuccess: (response) => {
        store.dispatch(setRecruitmentBrief(response));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteRecruitment() {
  return useMutation(
    (payload) => {
      return recruitments.deleteRecruitment(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('recruitments');
        store.dispatch(
          setAlert(true, 'success', 'Recrutiment Deleted Successfully'),
        );
      },
    },
  );
}

export function useGetRecruitmentApplications(
  id,
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    ['recruitment-applications', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return recruitments.getRecruitmentApplications({
        id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((applicant) => {
          applicant.created = moment(applicant.created).format('MMMM D, YYYY');
          applicant.computedActions = [0, 1, 2, 3, 4];
          applicant.applicantName = `${applicant.first_name} ${applicant.last_name}`;
          applicant.status = applicant.review_status;
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetFilteredRecruitmentApplications(
  id,
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    [
      'filtered-recruitment-applications',
      { id, limit, page, statusFilter, search },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return recruitments.getRecruitmentApplications({
        id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((applicant) => {
          applicant.created = moment(applicant.created).format('MMMM D, YYYY');
          applicant.computedActions = [0, 1, 2, 3, 4];
          applicant.applicantName = `${applicant.first_name} ${applicant.last_name}`;
          applicant.status = applicant.review_status;
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetRejectedRecruitmentApplications(
  id,
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    [
      'rejected-recruitment-applications',
      { id, limit, page, statusFilter, search },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return recruitments.getRecruitmentApplications({
        id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((applicant) => {
          applicant.created = moment(applicant.created).format('MMMM D, YYYY');
          applicant.computedActions = [0, 1, 2, 3, 4];
          applicant.applicantName = `${applicant.first_name} ${applicant.last_name}`;
          applicant.status = applicant.review_status;
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetRecruitmentApplication(id) {
  return useQuery(
    ['recruitment-application', id],
    () => {
      return recruitments.getRecruitmentApplication(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetOfferByToken(token) {
  return useQuery(
    ['recruitment-application', token],
    () => {
      return recruitments.getOfferByToken(token);
    },
    {
      enabled: token !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddRecruitmentApplication() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return recruitments.addRecruitmentApplication(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-applications');
        store.dispatch(
          setAlert(
            true,
            'success',
            'Recrutiment Application Added Successfully',
          ),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddApplicationNote() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return recruitments.addApplicationNote(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('application-notes');
        store.dispatch(
          setAlert(true, 'success', 'Applicant Note Added Successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetApplicationNotes(id) {
  return useQuery(
    ['application-notes', id],
    () => {
      store.dispatch(setIsLoading(true));
      return recruitments.getApplicationNotes(id);
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.forEach((applicant) => {
          applicant.created = applicant.created
            .split('T')
            .join(' ')
            .split('.')[0];
          applicant.computedActions = [0, 1, 2, 3, 4];
          applicant.applicantName = `${applicant.first_name} ${applicant.last_name}`;
          applicant.status = applicant.review_status;
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditApplicationNote() {
  return useMutation(
    (payload) => {
      return recruitments.updateApplicationNote(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('application-notes');
        store.dispatch(setAlert(true, 'success', 'Note Updated Successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteApplicationNote() {
  return useMutation(
    (payload) => {
      return recruitments.deleteApplicationNote(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('application-notes');
        store.dispatch(setAlert(true, 'success', 'Note Deleted Successfully'));
      },
    },
  );
}

export function useAddRecruitmentQuestions() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return recruitments.addQuestions(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-questions');
        store.dispatch(
          setAlert(true, 'success', 'Recruitment Question Added Successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetRecruitmentQuestions(id, limit, page, search) {
  return useQuery(
    ['recruitment-questions', { id, limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return recruitments.getRecruitmentQuestions({
        id,
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((recruitment_question) => {
          recruitment_question.created = moment(
            recruitment_question.created,
          ).format('MMMM D, YYYY');
          recruitment_question.computedActions = [0, 1, 2];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditRecruitmentQuestion() {
  return useMutation(
    (payload) => {
      return recruitments.updateQuestion(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-questions');
        store.dispatch(
          setAlert(
            true,
            'success',
            'Recruitment Question Updated Successfully',
          ),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteQuestion() {
  return useMutation(
    (payload) => {
      return recruitments.deleteQuestion(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('recruitment-questions');
        store.dispatch(
          setAlert(
            true,
            'success',
            'Recruitment Question Deleted Successfully',
          ),
        );
      },
    },
  );
}

export function useGetRecruitmentQuestion(id) {
  return useQuery(
    ['recruitment-question', { id }],
    () => {
      return recruitments.getRecruitmentQuestion({
        id,
      });
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useChangeRecruitmentApplicationStatus() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return recruitments.activateOrDeactivateRecruitmentApplicationStatus(
        payload,
      );
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitments');
        queryClient.invalidateQueries('recruitment');
        store.dispatch(
          setAlert(true, 'success', 'Recruitment application status changed'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetRecruitmentStages(id) {
  return useQuery(
    ['recruitment-stages', id],
    () => {
      store.dispatch(setIsLoading(true));
      return recruitments.getRecruitmentStages(id);
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((recruitment) => {
          recruitment.created = moment(recruitment.created).format(
            'MMMM D, YYYY',
          );
          recruitment.computedActions = [0, 1];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMoveRecruitmentApplicantStage() {
  return useMutation(
    (payload) => {
      return recruitments.moveApplicantToNextStage(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-stages');
        queryClient.invalidateQueries('stage-applicants');
        store.dispatch(
          setAlert(true, 'success', 'Applicant moved successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useMoveBulkRecruitmentApplicantStage() {
  return useMutation(
    (payload) => {
      return recruitments.moveBulkApplicantsToNextStage(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-stages');
        queryClient.invalidateQueries('stage-applicants');
        store.dispatch(
          setAlert(true, 'success', 'Applicants moved successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetRecruitmentStageApplicants(
  stage,
  recruitment_id,
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    [
      'stage-applicants',
      { stage, recruitment_id, limit, page, statusFilter, search },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return recruitments.getStageApplicants({
        stage,
        recruitment_id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      enabled:
        stage !== null && recruitment_id !== null && !isNaN(recruitment_id),
      select: (data) => {
        data?.data?.forEach((applicant) => {
          applicant.created = applicant.created
            .split('T')
            .join(' ')
            .split('.')[0];
          applicant.applicantName = `${applicant.first_name} ${applicant.last_name}`;
          applicant.computedActions = [0, 1, 2, 3];
          applicant.status = applicant.review_status;
        });
        return data;
      },
      onSuccess: (response, variables, context) => {
        store.dispatch(
          setAlert(
            true,
            'success',
            'Recruitment Applicants fetched successfully',
          ),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetOnboardingStages(id) {
  return useQuery(
    ['onboarding-stages', id],
    () => {
      return recruitments.getRecruitmentStages(id);
    },
    {
      enabled: id !== null,
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Stage Selected' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.index, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendOfferLetter() {
  return useMutation(
    (payload) => {
      return recruitments.sendOfferLetter(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-application');
        queryClient.invalidateQueries('employee');
        store.dispatch(
          setAlert(true, 'success', 'Offer letter resent successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useResendOfferLetter() {
  return useMutation(
    (payload) => {
      return recruitments.resendOfferLetter(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-application');
        queryClient.invalidateQueries('employee');
        store.dispatch(
          setAlert(true, 'success', 'Offer letter resent successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddVideoAssessment() {
  return useMutation(
    (payload) => {
      return recruitments.videoAssessment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-application');
        store.dispatch(
          setAlert(true, 'success', 'Assessment created successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetVideoRequest(token, mobile) {
  return useQuery(
    ['video-recruitment-request', { token, mobile }],
    () => {
      return recruitments.getVideoRecruitmentRequest({ token, mobile });
    },
    {
      enabled: token !== null && mobile !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUploadVideo() {
  return useMutation(
    (payload) => {
      return recruitments.uploadVideo(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(
          setAlert(true, 'success', 'Video uploaded successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useResendVideoAssessment() {
  return useMutation(
    (payload) => {
      return recruitments.resendVideoAssessment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(
          setAlert(true, 'success', 'Video assesment mail sent successfully'),
        );
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}
