import { useMutation, useQuery } from "@tanstack/react-query";
import { store } from "../..";
import { queryClient } from "../../..";
import { setAlert, setIsLoading } from "../../components/components-slice";
import { employeeSalaries } from "./../../../api/employees/salaries";
import { formatAmount, formatNumber } from "../../../utils/functions";

export function useGetEmployeeSalaries(limit, page, statusFilter, search) {
  // const formatter = new Intl.NumberFormat('en-NG');
  return useQuery(
    ["employee-salaries", { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaries.getEmployeeSalaries({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.status = parseInt(employee.status);
          let additional_allowance = 0;
          let additional_allowances = JSON.parse(
            employee.additional_allowances
          );
          if (additional_allowances && additional_allowances.length > 0) {
            additional_allowances.forEach((item) => {
              additional_allowance += parseFloat(item.amount);
            });
          }
          employee.gross_pay =
            parseFloat(employee.basic_salary ? employee.basic_salary : 0) +
            parseFloat(
              employee.airtime_allowance ? employee.airtime_allowance : 0
            ) +
            parseFloat(employee.data_allowance ? employee.data_allowance : 0) +
            parseFloat(
              employee.housing_allowance ? employee.housing_allowance : 0
            ) +
            parseFloat(
              employee.leave_allowance ? employee.leave_allowance : 0
            ) +
            parseFloat(
              employee.lunch_allowance ? employee.lunch_allowance : 0
            ) +
            parseFloat(
              employee.transportation_allowance
                ? employee.transportation_allowance
                : 0
            ) +
            parseFloat(
              employee.utility_allowance ? employee.utility_allowance : 0
            ) +
            parseFloat(
              employee.other_allowance ? employee.other_allowance : 0
            ) +
            parseFloat(additional_allowance);

          employee.other_accum_allowance =
            parseFloat(
              employee.airtime_allowance ? employee.airtime_allowance : 0
            ) +
            parseFloat(employee.data_allowance ? employee.data_allowance : 0) +
            parseFloat(
              employee.leave_allowance ? employee.leave_allowance : 0
            ) +
            parseFloat(
              employee.lunch_allowance ? employee.lunch_allowance : 0
            ) +
            parseFloat(
              employee.utility_allowance ? employee.utility_allowance : 0
            ) +
            parseFloat(
              employee.other_allowance ? employee.other_allowance : 0
            ) +
            parseFloat(additional_allowance);

          employee.formatted_other_accum_allowance = formatAmount(
            parseFloat(employee.other_accum_allowance),
            2
          );
          employee.formatted_basic_salary = formatAmount(
            parseFloat(employee.basic_salary),
            2
          );
          employee.formatted_transportation_allowance = formatAmount(
            parseFloat(employee.transportation_allowance),
            2
          );
          employee.formatted_housing_allowance = formatAmount(
            parseFloat(employee.housing_allowance).toFixed(2),
            2
          );
          employee.formatted_gross_pay = formatAmount(
            parseFloat(employee.gross_pay),
            2
          );

          //compute action items
          employee.computedActions = [0, 1, 2];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useGetBranchEmployeeSalaries(
  branch_id,
  limit,
  page,
  statusFilter,
  search
) {
  // const formatter = new Intl.NumberFormat('en-NG');
  return useQuery(
    [
      "branch-employee-salaries",
      { branch_id, limit, page, statusFilter, search },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaries.getSalariesByBranch({
        branch_id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.status = parseInt(employee.status);
          let additional_allowance = 0;
          let additional_allowances = JSON.parse(
            employee.additional_allowances
          );
          if (additional_allowances && additional_allowances.length > 0) {
            additional_allowances.forEach((item) => {
              additional_allowance += parseFloat(item.amount);
            });
          }
          employee.gross_pay =
            parseFloat(employee.basic_salary ? employee.basic_salary : 0) +
            parseFloat(
              employee.airtime_allowance ? employee.airtime_allowance : 0
            ) +
            parseFloat(employee.data_allowance ? employee.data_allowance : 0) +
            parseFloat(
              employee.housing_allowance ? employee.housing_allowance : 0
            ) +
            parseFloat(
              employee.leave_allowance ? employee.leave_allowance : 0
            ) +
            parseFloat(
              employee.lunch_allowance ? employee.lunch_allowance : 0
            ) +
            parseFloat(
              employee.transportation_allowance
                ? employee.transportation_allowance
                : 0
            ) +
            parseFloat(
              employee.utility_allowance ? employee.utility_allowance : 0
            ) +
            parseFloat(
              employee.other_allowance ? employee.other_allowance : 0
            ) +
            parseFloat(additional_allowance);

          employee.other_accum_allowance =
            parseFloat(
              employee.airtime_allowance ? employee.airtime_allowance : 0
            ) +
            parseFloat(employee.data_allowance ? employee.data_allowance : 0) +
            parseFloat(
              employee.leave_allowance ? employee.leave_allowance : 0
            ) +
            parseFloat(
              employee.lunch_allowance ? employee.lunch_allowance : 0
            ) +
            parseFloat(
              employee.utility_allowance ? employee.utility_allowance : 0
            ) +
            parseFloat(
              employee.other_allowance ? employee.other_allowance : 0
            ) +
            parseFloat(additional_allowance);

          employee.formatted_other_accum_allowance = formatNumber(
            parseFloat(employee.other_accum_allowance).toFixed(2),
            2
          );
          employee.formatted_basic_salary = formatNumber(
            parseFloat(employee.basic_salary).toFixed(2),
            2
          );
          employee.formatted_transportation_allowance = formatNumber(
            parseFloat(employee.transportation_allowance).toFixed(2),
            2
          );
          employee.formatted_housing_allowance = formatNumber(
            parseFloat(employee.housing_allowance).toFixed(2),
            2
          );
          employee.formatted_gross_pay = formatNumber(
            parseFloat(employee.gross_pay).toFixed(2),
            2
          );

          //compute action items
          employee.computedActions = [0, 1, 2];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    }
  );
}

export function useAddSalary() {
  return useMutation(
    (payload) => {
      return employeeSalaries.addSalary(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("employee-salaries");
        store.dispatch(setAlert(true, "success", response.message));
      },
    }
  );
}

export function useGetEmployeeSalariesExport() {
  return useMutation(
    (payload) => {
      return employeeSalaries.getEmployeeSalaries(payload);
    },
    {
      onSettled: (data, error, variables, context) => {
        data?.data?.forEach((employee) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.status = parseInt(employee.status);
          let additional_allowance = 0;
          let additional_allowances = JSON.parse(
            employee.additional_allowances
          );
          if (additional_allowances && additional_allowances.length > 0) {
            additional_allowances.forEach((item) => {
              additional_allowance += parseFloat(item.amount);
            });
          }
          employee.gross_pay =
            parseFloat(employee.basic_salary ? employee.basic_salary : 0) +
            parseFloat(
              employee.airtime_allowance ? employee.airtime_allowance : 0
            ) +
            parseFloat(employee.data_allowance ? employee.data_allowance : 0) +
            parseFloat(
              employee.housing_allowance ? employee.housing_allowance : 0
            ) +
            parseFloat(
              employee.leave_allowance ? employee.leave_allowance : 0
            ) +
            parseFloat(
              employee.lunch_allowance ? employee.lunch_allowance : 0
            ) +
            parseFloat(
              employee.transportation_allowance
                ? employee.transportation_allowance
                : 0
            ) +
            parseFloat(
              employee.utility_allowance ? employee.utility_allowance : 0
            ) +
            parseFloat(
              employee.other_allowance ? employee.other_allowance : 0
            ) +
            parseFloat(additional_allowance);

          employee.other_accum_allowance =
            parseFloat(
              employee.airtime_allowance ? employee.airtime_allowance : 0
            ) +
            parseFloat(employee.data_allowance ? employee.data_allowance : 0) +
            parseFloat(
              employee.leave_allowance ? employee.leave_allowance : 0
            ) +
            parseFloat(
              employee.lunch_allowance ? employee.lunch_allowance : 0
            ) +
            parseFloat(
              employee.utility_allowance ? employee.utility_allowance : 0
            ) +
            parseFloat(
              employee.other_allowance ? employee.other_allowance : 0
            ) +
            parseFloat(additional_allowance);

          employee.formatted_other_accum_allowance = formatAmount(
            parseFloat(employee.other_accum_allowance),
            2
          );
          employee.formatted_basic_salary = formatAmount(
            parseFloat(employee.basic_salary),
            2
          );
          employee.formatted_transportation_allowance = formatAmount(
            parseFloat(employee.transportation_allowance),
            2
          );
          employee.formatted_housing_allowance = formatAmount(
            parseFloat(employee.housing_allowance).toFixed(2),
            2
          );
          employee.formatted_gross_pay = formatAmount(
            parseFloat(employee.gross_pay),
            2
          );
        });
        store.dispatch(setIsLoading(false));
        return data;
      },
      // keepPreviousData: true
    }
  );
}

export function useGetEmployeeSalary(id) {
  return useQuery(
    ["salary", { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaries.getEmployeeSalary(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useGetSalaryByEmployee(id) {
  return useQuery(
    ["employee-salary", { id }],
    () => {
      return employeeSalaries.getSalaryByEmployee(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useEditSalary() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaries.editSalary(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("employee-salaries");
        queryClient.invalidateQueries("employee-salary");
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useAddBulkSalary() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaries.addBulkSalary(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("employee-salaries");
        queryClient.invalidateQueries("employee-salary");
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}

export function useDeleteSalary() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeSalaries.deleteSalary(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries("employee-salaries");
        queryClient.invalidateQueries("employee-salary");
        store.dispatch(setAlert(true, "success", response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    }
  );
}
