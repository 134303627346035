

import { Axios } from './axios';

const getProfile = async (payload) => {
  
    const { data } = await Axios.get(`/my-cabinet/employee-profile/${payload}`);
    return data?.data;
};

const getPayrolls = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/payroll/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getBonuses = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/bonuses/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getSalaryAdvances = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/salary_advances/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getReimbursements = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/reimbursements/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getSuspensions = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/suspensions/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getQueries = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/queries/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getDocuments = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/documents/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getLeaves = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/leaves/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getOnboarding = async (payload) => {
  
    const { data } = await Axios.get(`/my-cabinet/onboarding/${payload}`);
    return data?.data;
};

const getGuarantors = async (payload) => {
  
    const { data } = await Axios.get(`/my-cabinet/guarantors/${payload}`);
    return data?.data;
};

const getAttendance = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/attendance/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getPerformanceAppraisals = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/performance-appraisals/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getTrainings = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/training/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getHmoBeneficiary = async (payload) => {
    const { data } = await Axios.get(`/my-cabinet/hmo-beneficiary/${payload}`);
    return data?.data;
};

const getTaxPayments = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/tax-payments/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getPensionPayments = async (payload) => {
    let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(`/my-cabinet/pension-payments/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}${filter}`);
    return data?.data;
};

const getPromotions= async (payload) => {
    const { data } = await Axios.get(`/my-cabinet/promotion/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}`);
    return data?.data;
};

export const myCabinet = {
    getProfile,
    getPayrolls,
    getBonuses,
    getSalaryAdvances,
    getReimbursements,
    getSuspensions,
    getQueries,
    getDocuments,
    getLeaves,
    getOnboarding,
    getPerformanceAppraisals,
    getGuarantors,
    getAttendance,
    getTrainings,
    getHmoBeneficiary,
    getTaxPayments,
    getPensionPayments,
    getPromotions
  };
