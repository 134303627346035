import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../components/button";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { isLoading } from "../../../redux/components/components-slice";
import { useSelector } from "react-redux";
import UnauthorizedPage from "../../errors/unauthorized";
import {
  useRefTax,
  useRevertTax,
} from "../../../redux/statutory-compliance/hook/tax";

const TaxViewPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (
          user.permissions.company_permission.statutory_read !== 1 ||
          user.permissions.statutory_permission?.taxes_read !== 1
        ) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const loading = useSelector(isLoading);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { refId } = useParams();
  const { data: tax } = useRefTax(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const history = useHistory();
  const { mutateAsync: revertTax } = useRevertTax();

  useEffect(() => {
    if (tax) {
      if (loading === false && tax.data.length < 1) {
        revertTax().then(() => {
          history.push("/statutory-payments", { tab: 2 });
        });
      }
    }
  }, []);

  const tableConfig = {
    headers: ["Name", "Mobile", "Tax ID", "Tax", "Fee", "Month", "Total"],
    keys: [
      "employeeName",
      "employee_mobile",
      "employee_tax_number",
      "table_tax_amount",
      "table_fees",
      "payroll_month",
      "total",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Total",
        key: "total",
      },
    },
  };

  return (
    <DashboardLayout title="Tax View">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col bg-white">
              <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
                <div className="p3-bold header-4">(PAYE) Tax Breakdown</div>
              </div>
              <hr className="divider mt-2 mb-2" />
              <div className="w-[100%]">
                <DataTable
                  data={tax}
                  tableConfig={tableConfig}
                  noCheck={true}
                  updatePagination={(data) => setPagination(data)}
                  searchData={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default TaxViewPage;
