import React, { useEffect } from "react";
import DataTable from "../../components/datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import {
  useGetReferralData,
  useGetReferredTransactions,
  useWithdrawReferralBalance,
} from "../../redux/referrals/hook";
import Button from "../../components/button";
import UpdateReferralDetailsModal from "../../components/modal/referrals/update-referal-details";

const ReferralTransactionsPage = () => {
  useEffect(() => {}, []);
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    type: PAGINATION_DEFAULT.type,
  });

  const { data: referralTransactionsHistory } = useGetReferredTransactions(
    pagination?.limit,
    pagination?.page,
    pagination?.type
  );

  const { data: referral } = useGetReferralData();

  const { mutateAsync: withdrawReferral, isLoading: withdrawLoading } =
    useWithdrawReferralBalance();
  const [showUpdateReferralAccount, setShowUpdateReferralAccount] =
    useState(false);
  const tableConfig = {
    headers: [
      "Date",
      "Referral Code",
      "Transaction Type",
      "Transaction Amount",
      "Referral Amount",
    ],
    keys: ["created", "referral_code", "type", "amount", "commission"],
    mobileHeader: {
      left: {
        title: "User Name",
        key: "username",
      },
      right: {
        title: "Referral Bonus",
        key: "referral_bonus",
      },
    },
  };

  return (
    <div className="flex flex-col">
      {showUpdateReferralAccount ? (
        <UpdateReferralDetailsModal
          isOpen={showUpdateReferralAccount}
          closeModal={() => setShowUpdateReferralAccount(false)}
        />
      ) : null}
      <div className="flex flex-col bg-white rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Referral Transactions History
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="Update Referral Account Details"
                type="button"
                theme="primary"
                disabled={!referral}
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                onClick={() => {
                  setShowUpdateReferralAccount(true);
                }}
              />
            </div>
            {referral?.balance > 0 ? (
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Withdraw Referral Balance"
                  type="button"
                  theme="primary"
                  disabled={!referral}
                  loading={withdrawLoading}
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[11px]"}
                  onClick={() => {
                    withdrawReferral();
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={referralTransactionsHistory}
          tableConfig={tableConfig}
          actionConfig={null}
          updatePagination={(data) => setPagination(data)}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default ReferralTransactionsPage;
