import React, { useEffect, useState } from "react";
import CloseSVG from "../../assets/svg/close.svg";
import WarningSVG from "../../assets/svg/warning.svg";
import Button from "../button";
import Modal from "./modal";
import CollapsingIcon from "../collapsing-icon";
import classNames from "classnames";
import { Collapse } from "reactstrap";
import { useHistory } from "react-router-dom";

function InfoModal({ isOpen, closeModal, type }) {
  const history = useHistory();
  const [title, setTitle] = useState();
  useEffect(() => {
    if (type == "bonus") {
      setTitle("About Bonus");
    } else if (type == "reimbursement") {
      setTitle("Reimbursements");
    } else if (type == "salary-advance") {
      setTitle("Salary Advances");
    } else if (type == "salary") {
      setTitle("Salaries (compensation)");
    } else if (type == "employee") {
      setTitle("Employees");
    } else if (type == "payroll-group") {
      setTitle("Payroll Groups");
    } else if (type == "advanced-payroll") {
      setTitle("Running Payroll");
    } else if (type == "employee-payments") {
      setTitle("Making Employee Payments");
    } else if (type == "paye-tax") {
      setTitle("Making Tax Payments");
    }
  }, [type]);

  const [showAddBonus, setShowAddBonus] = useState(true);
  const [showAddSalaryAdvance, setShowAdSalaryAdvance] = useState(true);
  const [showApproveSalaryAdvance, setShowApproveSalaryAdvance] =
    useState(false);
  const [showAddReimbursement, setShowAdReimbursement] = useState(true);
  const [showApproveReimbursement, setShowApproveReimbursement] =
    useState(false);
  const [showAddSalary, setShowAddSalary] = useState(false);
  const [showReviewSalary, setShowReviewSalary] = useState(false);
  const [showPaySalary, setShowPaySalary] = useState(false);
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [showEmployeeStatus, setShowEmployeeStatus] = useState(false);
  const [showAddPayrollGroup, setShowAddPayrollGroup] = useState(false);
  const [showAddEmployeeToPayrollGroup, setShowAddEmployeeToPayrollGroup] =
    useState(false);
  const [showRunPayrollByPayrollGroup, setShowRunPayrollByPayrollGroup] =
    useState(false);
  return (
    <Modal
      scrollable={true}
      className="!min-w-[700px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="mb-10 overflow-auto">
        <div className="flex flex-col overflow-auto justify-center">
          <div className="pl-8 pr-8 pt-4">
            <div className="flex flex-row justify-between items-center">
              <span className="header-4">{title}</span>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <img src={CloseSVG} alt="close" />
              </div>
            </div>
          </div>
          <hr className="divider" />
          <div className="flex flex-col md:mx-8 items-start ">
            {type == "bonus" ? (
              <div className="flex flex-col gap-2">
                <span className="text-[18px] font-extrabold">
                  What are bonuses?
                </span>
                <div className="-mt-2 text-[13px]">
                  Bonuses are compensation paid above and beyond an employee's
                  base salary
                </div>
                <hr className="divider -mb-1 mt-2" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddBonus,
                      }
                    )}
                    onClick={() => setShowAddBonus(!showAddBonus)}
                  >
                    <span className="text-[14px] font-extrabold">
                      How do I add a bonus?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddBonus}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddBonus,
                    })}
                  >
                    <Collapse isOpen={showAddBonus}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Click the{" "}
                          <span className="font-bold">"NEW BONUS"</span> button
                        </li>
                        <li>Select upload type (Single or multiple).</li>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For single upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>Select employee</li>
                            <li>
                              Select the month-year for which the bonus will be
                              applied to.
                            </li>
                            <li>Type description and amount</li>
                            <li>
                              Click <span className="font-bold">"SAVE"</span>{" "}
                              button
                            </li>
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For multiple upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>Download Bonus template</li>
                            <li>Fill the template</li>
                            <li>Upload template</li>
                            <li>
                              Clock <span className="font-bold">"SAVE"</span>{" "}
                              button
                            </li>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider -mb-1 -mt-1" />
                <div className="flex mt-2">
                  <div className="flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light">
                    <div className="w-[70px] pr-2 flex flex-row items-center ">
                      <img src={WarningSVG} alt="" srcSet="" />
                    </div>
                    <span className="text-[13px]">
                      Bonuses added will be automatically applied to employee's
                      payroll (advanced payroll) for the{" "}
                      <span className="font-extrabold">(month-year)</span>{" "}
                      selected, when payroll is run in the month/year.
                    </span>
                  </div>
                </div>
              </div>
            ) : type == "salary-advance" ? (
              <div className="flex flex-col gap-2">
                <span className="text-[18px] font-extrabold">
                  What are salary advances?
                </span>
                <div className="-mt-2 text-[13px]">
                  A salary advance is a loan usually given to an employee.
                </div>
                <hr className="divider -mb-1 mt-2" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddSalaryAdvance,
                      }
                    )}
                    onClick={() =>
                      setShowAdSalaryAdvance(!showAddSalaryAdvance)
                    }
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I add a new salary advance?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddSalaryAdvance}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddSalaryAdvance,
                    })}
                  >
                    <Collapse isOpen={showAddSalaryAdvance}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Click the{" "}
                          <span className="font-bold">
                            "NEW ADVNACED PAYROLL"
                          </span>{" "}
                          button
                        </li>
                        <li>Select upload type (Single or multiple).</li>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For single upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>Select employee.</li>
                            <li>
                              Select the month-year for which the salary advance
                              will be deducted.
                            </li>
                            <li>Type description and amount.</li>
                            <li>
                              Click <span className="font-bold">"SAVE"</span>{" "}
                              button
                            </li>
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For multiple upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>Download Salary Advance template.</li>
                            <li>Fill the template.</li>
                            <li>Upload template.</li>
                            <li>
                              Click <span className="font-bold">"SAVE"</span>{" "}
                              button
                            </li>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider -mb-1 -mt-1" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showApproveSalaryAdvance,
                      }
                    )}
                    onClick={() =>
                      setShowApproveSalaryAdvance(!showApproveSalaryAdvance)
                    }
                  >
                    <span className="text-[15px] font-extrabold mt-2">
                      How do I approve and pay salary advance?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showApproveSalaryAdvance}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showApproveSalaryAdvance,
                    })}
                  >
                    <Collapse isOpen={showApproveSalaryAdvance}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Click on the{" "}
                          <span className="font-bold">"APPLICATIONS"</span>{" "}
                          button to view all applications.
                        </li>
                        <li>
                          Click on action and select approve (to approve only)
                          or approve and pay (To approve and pay directly from
                          the ropay platform).
                        </li>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider mb-0 -mt-1" />
                <div className="flex mt-2">
                  <div className="flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light">
                    <div className="w-[70px] pr-2 flex flex-row items-center ">
                      <img src={WarningSVG} alt="" srcSet="" />
                    </div>
                    <span className="text-[13px]">
                      Salary Advances added or approved will be automatically
                      applied to employee's payroll (advanced payroll) for the{" "}
                      <span className="font-extrabold">(month-year)</span>{" "}
                      selected, when payroll is run in the month/year.
                    </span>
                  </div>
                </div>
              </div>
            ) : type == "reimbursement" ? (
              <div className="flex flex-col gap-2">
                <span className="text-[18px] font-extrabold">
                  What are Reimbursements?
                </span>
                <div className="-mt-2 text-[13px]">
                  An expense reimbursement is the payment made from your
                  business to an employee for business-related expenses they
                  have incurred personally
                </div>
                <hr className="divider -mb-1 mt-2" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddReimbursement,
                      }
                    )}
                    onClick={() =>
                      setShowAdReimbursement(!showAddReimbursement)
                    }
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I add a new reimbursement?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddReimbursement}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddReimbursement,
                    })}
                  >
                    <Collapse isOpen={showAddReimbursement}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Click the{" "}
                          <span className="font-bold">"NEW REIMBURSEMENT"</span>{" "}
                          button
                        </li>
                        <li>Select upload type (Single or multiple).</li>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For single upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>Select employee.</li>
                            <li>
                              Select the month-year for which the salary advance
                              will be added for payroll purpose.
                            </li>
                            <li>
                              Type expenses description and expensesamount.
                            </li>
                            <li>
                              Click <span className="font-bold">"SAVE"</span>{" "}
                              button
                            </li>
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For multiple upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>Download Reimbursement template.</li>
                            <li>Fill the template.</li>
                            <li>Upload template.</li>
                            <li>
                              Click <span className="font-bold">"SAVE"</span>{" "}
                              button
                            </li>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider -mb-1 -mt-1" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showApproveReimbursement,
                      }
                    )}
                    onClick={() =>
                      setShowApproveReimbursement(!showApproveReimbursement)
                    }
                  >
                    <span className="text-[15px] font-extrabold mt-2">
                      How do I approve and pay expense reimbursement?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showApproveReimbursement}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showApproveReimbursement,
                    })}
                  >
                    <Collapse isOpen={showApproveReimbursement}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Click on the{" "}
                          <span className="font-bold">"APPLICATIONS"</span>{" "}
                          button to view all applications.
                        </li>
                        <li>
                          Click on action and select approve (to approve only)
                          or approve and pay (To approve and pay directly from
                          the ropay platform).
                        </li>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider mb-0 -mt-1" />
                <div className="flex mt-2">
                  <div className="flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light">
                    <div className="w-[70px] pr-2 flex flex-row items-center ">
                      <img src={WarningSVG} alt="" srcSet="" />
                    </div>
                    <span className="text-[13px]">
                      Reimbursements added or approved (but not paid) will be
                      automatically applied to employee's payroll (advanced
                      payroll) for the{" "}
                      <span className="font-extrabold">(month-year)</span>{" "}
                      selected, when payroll is run in the month/year.
                    </span>
                  </div>
                </div>
              </div>
            ) : type == "salary" ? (
              <div className="flex flex-col gap-2">
                <span className="text-[18px] font-extrabold">
                  What is Compensation?
                </span>
                <div className="-mt-2 text-[13px]">
                  Compensation describes the cash rewards paid to employees in
                  exchange for the services they provide. It may include base
                  salary, wages, incentives and/or commission. Total
                  compensation includes cash rewards as well as any other
                  company benefits.
                </div>
                <hr className="divider -mb-1 mt-2" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddSalary,
                      }
                    )}
                    onClick={() => setShowAddSalary(!showAddSalary)}
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I add a new employee salary?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddSalary}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddSalary,
                    })}
                  >
                    <Collapse isOpen={showAddSalary}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Ensure that you have set up your payroll
                          settings&nbsp;
                          <span
                            className="font-extrabold cursor-pointer hover:underline duration-300 text-[15px]"
                            onClick={() => {
                              history.push("/settings?payroll-settings=true");
                            }}
                          >
                            here
                          </span>
                        </li>
                        <li>
                          Click the{" "}
                          <span className="font-bold">"NEW SALARY"</span> button
                        </li>
                        <li>
                          Select upload type (Single or multiple) and click on
                          the <span className="font-bold">"NEXT"</span> button.
                        </li>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For single upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>
                              Choose the Net pay, Gross Pay or All information,
                              based on the level of information that you have.
                            </li>
                            <li>If you selected Net pay or Gross pay.</li>
                            <li>
                              Select the employee and enter the salary amount
                            </li>
                            <li>Input any additional payroll costs</li>
                            <li>
                              Choose settings type. Company payroll setting to
                              use company payroll settings. Payroll group
                              settings for employees that belong to different
                              payroll groups to get your payroll breakdown.
                            </li>
                            <li>
                              Click on{" "}
                              <span className="font-bold">
                                "VIEW BREAKDOWN"
                              </span>{" "}
                              button. This will trigger reverse payroll feature
                              to calculate the breakdown.
                            </li>
                            <li>
                              Review the information and click on{" "}
                              <span className="font-bold">"SAVE"</span> button.
                            </li>
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For multiple upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>Download Salary template.</li>
                            <li>Fill the template.</li>
                            <li>Upload template.</li>
                            <li>Save Employee Salaries.</li>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider -mb-1 -mt-1" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showPaySalary,
                      }
                    )}
                    onClick={() => setShowPaySalary(!showPaySalary)}
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I payroll for employees?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showPaySalary}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showPaySalary,
                    })}
                  >
                    <Collapse isOpen={showPaySalary}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Ensure that you have set up your payroll
                          settings&nbsp;
                          <span
                            className="font-extrabold cursor-pointer hover:underline duration-300 text-[15px]"
                            onClick={() => {
                              history.push("/settings?payroll-settings=true");
                            }}
                          >
                            here
                          </span>
                        </li>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            Pay selected employees
                          </span>
                          <div className="pl-4 mt-1">
                            <li>
                              Check the employees you want to pay from the
                              salaries table.
                            </li>
                            <li>
                              Click pay employees from the bulk action drop down
                            </li>
                            <li>
                              Fill the required information and click on
                              continue
                            </li>
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            Pay Payroll Group
                          </span>
                          <div className="pl-4 mt-1">
                            <li>Go to the payroll group tab</li>
                            <li>View the payroll group you intend to pay</li>
                            <li>
                              In the view payroll group page. Click on run
                              advanced payroll button.
                            </li>
                            <li>
                              Fill the required information and click on
                              continue
                            </li>
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            Pay All Employees
                          </span>
                          <div className="pl-4 mt-1">
                            <li>
                              Go to the payroll page&nbsp;
                              <span
                                className="font-extrabold cursor-pointer hover:underline duration-300 text-[15px]"
                                onClick={() => {
                                  history.push("/payroll");
                                }}
                              >
                                here
                              </span>
                            </li>
                            <li>
                              Click on the run payroll button (advanced payroll)
                              on the payroll page
                            </li>

                            <li>
                              Fill the required information and click on
                              continue
                            </li>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider -mb-1 -mt-1" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showReviewSalary,
                      }
                    )}
                    onClick={() => setShowReviewSalary(!showReviewSalary)}
                  >
                    <span className="text-[15px] font-extrabold mt-2">
                      How do I review employee salaries?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showReviewSalary}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showReviewSalary,
                    })}
                  >
                    <Collapse isOpen={showReviewSalary}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Ensure that you have set up your payroll
                          settings&nbsp;
                          <span
                            className="font-extrabold cursor-pointer hover:underline duration-300 text-[15px]"
                            onClick={() => {
                              history.push("/settings?payroll-settings=true");
                            }}
                          >
                            here
                          </span>
                        </li>
                        <li>
                          Click the Click on{" "}
                          <span className="font-bold">"REVIEW SALARY"</span>{" "}
                          button
                        </li>
                        <li>Select review type (Single or multiple).</li>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For single upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>
                              Choose the Net pay, Gross Pay or All information,
                              based on the level of information that you have.
                            </li>
                            <li>If you selected Net pay or Gross pay.</li>
                            <li>
                              Select the employee and enter the salary amount
                            </li>
                            <li>Input any additional payroll costs</li>
                            <li>
                              Choose settings type. Company payroll setting to
                              use company payroll settings. Payroll group
                              settings for employees that belong to different
                              payroll groups to get your payroll breakdown.
                            </li>
                            <li>
                              Click on{" "}
                              <span className="font-bold">
                                "VIEW BREAKDOWN"
                              </span>{" "}
                              button. This will trigger reverse payroll feature
                              to calculate the breakdown.
                            </li>
                            <li>
                              Review the information and click on{" "}
                              <span className="font-bold">"SAVE"</span> button.
                            </li>
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For multiple upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>Download Salary review template.</li>
                            <li>Fill the template.</li>
                            <li>Upload template.</li>
                            <li>Save Employee Salaries.</li>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider mb-0 -mt-1" />
                <div className="flex mt-2">
                  <div className="flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light">
                    <div className="w-[70px] pr-2 flex flex-row items-center ">
                      <img src={WarningSVG} alt="" srcSet="" />
                    </div>
                    <span className="text-[13px]">
                      Please note that only active employees whose salary have
                      been added to the platform can be paid from the platform.
                    </span>
                  </div>
                </div>
              </div>
            ) : type == "employee" ? (
              <div className="flex flex-col gap-2">
                <span className="text-[18px] font-extrabold">
                  About the employee page
                </span>
                <div className="-mt-2 text-[13px]">
                  This page is for managing your employees.
                </div>
                <hr className="divider -mb-1 mt-2" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddEmployee,
                      }
                    )}
                    onClick={() => setShowAddEmployee(!showAddEmployee)}
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I add a new employees?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddEmployee}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddEmployee,
                    })}
                  >
                    <Collapse isOpen={showAddEmployee}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Ensure that you have set up your payroll
                          settings&nbsp;
                          <span
                            className="font-extrabold cursor-pointer hover:underline duration-300 text-[15px]"
                            onClick={() => {
                              history.push("/settings?payroll-settings=true");
                            }}
                          >
                            here
                          </span>
                        </li>
                        <li>
                          Click the{" "}
                          <span className="font-bold">"NEW EMPLOYEE"</span>{" "}
                          button
                        </li>
                        <li>Select upload type (Single or multiple).</li>
                        <li>
                          Select the type from the popup modal. invite employees
                          to fill their details (For employees that have access
                          to a laptop or mobile phones) OR fill the complete
                          information of your employees.{" "}
                        </li>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For single employee upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>
                              Fill in the required employee information and save
                            </li>
                            <li>
                              You can start the employee onboarding by assigning
                              an onboarding workflow to the employee. You can
                              also request for guarators at this stage. This is
                              optional{" "}
                            </li>
                            <li>
                              Assign employee to a payroll group. This is
                              optional{" "}
                            </li>
                            <li>
                              Add Employee salary information. Follow steps in
                              salary tab
                            </li>
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold">
                            For multiple employee upload
                          </span>
                          <div className="pl-4 mt-1">
                            <li>
                              Select the type from the popup modal. invite
                              employees to fill their details (For employees
                              that have access to a laptop or mobile phones) OR
                              fill the complete information of your employees.{" "}
                            </li>
                            <li>Download Salary template.</li>
                            <li>Fill the template.</li>
                            <li>Upload template.</li>
                            <li>Save Employees.</li>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider -mb-1 -mt-1" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showEmployeeStatus,
                      }
                    )}
                    onClick={() => setShowEmployeeStatus(!showEmployeeStatus)}
                  >
                    <span className="text-[15px] font-extrabold">
                      What do statuses mean?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showEmployeeStatus}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showEmployeeStatus,
                    })}
                  >
                    <Collapse isOpen={showEmployeeStatus}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold text-red-500">
                            Inactive
                          </span>
                          <div className="pl-4 mt-1">
                            Inactive employees are employees that have left an
                            organisation and no longer have access to access
                            their account in the employee self service
                            portal(web and mobile)
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold text-hr-primary-1">
                            Active
                          </span>
                          <div className="pl-4 mt-1">
                            Active employees are employees that are presently
                            with your organisation and have access to their
                            information on mobile and web.
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold text-orange-500">
                            Pending
                          </span>
                          <div className="pl-4 mt-1">
                            These are employees that have been invited but are
                            yet to complete the information as requested. Please
                            do not activate employees at this stage
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold text-orange-500">
                            Awaiting Activation
                          </span>
                          <div className="pl-4 mt-1">
                            This status is for employees who have been invited
                            and have successfully provided the information
                            requested. You can activate new employees with this
                            status
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold text-orange-500">
                            Pre Registered
                          </span>
                          <div className="pl-4 mt-1">
                            This status is for employees that added their
                            information using the link that you provided to
                            invite them to the platform. They will get the
                            mobile/web app registration email upon activation.
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider mb-0 -mt-1" />
                <div className="flex mt-2">
                  <div className="flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light">
                    <div className="w-[70px] pr-2 flex flex-row items-center ">
                      <img src={WarningSVG} alt="" srcSet="" />
                    </div>

                    <div className=" flex flex-col text-[13px] text-justify">
                      <div className="pl-4 mt-1 border p-2 bg-gray-50 rounded-md">
                        <span className="text-[13px] font-bold text-black">
                          Inviting Employees
                        </span>
                        <div className="pl-4 mt-1">
                          Ropay allows you to invite employees to provide their
                          information, using their self-service web portal or
                          mobile app.
                        </div>
                      </div>
                      <div className="pl-4 mt-1 border p-2 bg-gray-50 rounded-md">
                        <span className="text-[13px] font-bold text-black">
                          Complete Upload
                        </span>
                        <div className="pl-4 mt-1">
                          You can also complete the entire form on behalf of the
                          employee. This is mostly useful for employees that do
                          not have access to smart phones or the internet.
                        </div>
                      </div>
                      <div className="pl-4 mt-1 border p-2 bg-gray-50 rounded-md">
                        <span className="text-[13px] font-bold text-black">
                          Paying Employees
                        </span>
                        <div className="pl-4 mt-1">
                          Please note that only{" "}
                          <span className="font-semibold">
                            active employees
                          </span>{" "}
                          whose salary have been added to the platform can be
                          paid from the platform. Only active employees will
                          have access to the company on the mobile and web
                          self-service portal
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : type == "payroll-group" ? (
              <div className="flex flex-col gap-2">
                <span className="text-[18px] font-extrabold">
                  About Payroll Groups
                </span>
                <div className="-mt-2 text-[13px] text-justify">
                  <p className="mb-2">
                    <span className="font-semibold">Payroll groups</span> are
                    made specifically for companies that have distinct employee
                    groups and adopt different payroll settings for these
                    different groups.
                  </p>
                  <p className="mb-2">
                    <span className="font-semibold">For example</span>, a
                    company may have interns that the pay taxes and pensions for
                    and another that they pay only taxes for.
                  </p>
                  <p>
                    The admin can split the employees into these groups to
                    easily compute payroll for them using their specific payroll
                    settings.
                  </p>
                </div>
                <hr className="divider -mb-1 mt-2" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddPayrollGroup,
                      }
                    )}
                    onClick={() => setShowAddPayrollGroup(!showAddPayrollGroup)}
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I add a payroll group?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddPayrollGroup}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddPayrollGroup,
                    })}
                  >
                    <Collapse isOpen={showAddPayrollGroup}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Click the{" "}
                          <span className="font-bold">"NEW PAYROLL GROUP"</span>{" "}
                          button
                        </li>
                        <li>
                          Type the name and description of the payroll group
                        </li>
                        <li>
                          Add the payroll settings for the group. You can use
                          the company payroll settings for the group, by
                          clicking the use company payroll settings
                        </li>
                        <li>
                          Click on <span className="font-bold">"SAVE"</span>{" "}
                          button
                        </li>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider -mb-1 -mt-1" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddEmployeeToPayrollGroup,
                      }
                    )}
                    onClick={() =>
                      setShowAddEmployeeToPayrollGroup(
                        !showAddEmployeeToPayrollGroup
                      )
                    }
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I add employee to payroll group?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddEmployeeToPayrollGroup}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddEmployeeToPayrollGroup,
                    })}
                  >
                    <Collapse isOpen={showAddEmployeeToPayrollGroup}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold ">
                            Adding employees the group page
                          </span>
                          <div className="pl-4 mt-1">
                            <li>
                              Identiffy the payroll group from the table. Click
                              on the action icon and click view
                            </li>
                            <li>
                              In the view group page, click on the{" "}
                              <span className="font-bold">
                                "ADD EMPLOYEE TO GROUP"
                              </span>{" "}
                              button
                            </li>
                            <li>
                              Select the employee(S) you want to add to the
                              payroll group and save
                            </li>
                          </div>
                        </div>
                        <div className="pl-4 mt-1 border p-2 bg-gray-100 rounded-md">
                          <span className="text-[13px] font-bold ">
                            Adding employees during onboarding
                          </span>
                          <div className="pl-4 mt-1">
                            Follow the instructions in the onboarding process to
                            add employee to group during onboarding
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider -mb-1 -mt-1" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showRunPayrollByPayrollGroup,
                      }
                    )}
                    onClick={() =>
                      setShowRunPayrollByPayrollGroup(
                        !showRunPayrollByPayrollGroup
                      )
                    }
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I add employee to payroll group?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showRunPayrollByPayrollGroup}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showRunPayrollByPayrollGroup,
                    })}
                  >
                    <Collapse isOpen={showRunPayrollByPayrollGroup}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Identiffy the payroll group from the table. Click on
                          the action icon and click view
                        </li>
                        <li>
                          In the view group page, click on the{" "}
                          <span className="font-bold">"RUN PAYROLL"</span>{" "}
                          button
                        </li>
                        <li>
                          Fill the information in the form provided and click
                          the <span className="font-bold">"CONTINUE"</span>{" "}
                          button
                        </li>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider mb-0 -mt-1" />
                <div className="flex mt-2">
                  <div className="flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light">
                    <div className="w-[70px] pr-2 flex flex-row items-center ">
                      <img src={WarningSVG} alt="" srcSet="" />
                    </div>
                    <span className="text-[13px] text-justify">
                      Payroll group settings will only be used if when running
                      payroll,{" "}
                      <span className="font-bold">
                        "Use Payroll Group Settings"
                      </span>{" "}
                      is selected. Else, the company settings will be used.
                    </span>
                  </div>
                </div>
              </div>
            ) : type == "advanced-payroll" ? (
              <div className="flex flex-col gap-2">
                <div className="-mt-2 text-[13px] text-justify">
                  <p className="mb-0">
                    <span className="font-semibold text-[14px]">
                      Running payroll:
                    </span>
                  </p>
                  <p className="mb-2 text-[12px]">
                    Gives you the power to compute your payroll on the Ropay
                    platform seamlessly. It takes into account all the extras in
                    your payroll including:
                    <span className="">
                      Bonuses, Overtime, Loans and Advances, Other deductions,
                      Reimbursements, Suspensions.
                    </span>
                  </p>
                  <p className="mb-0">
                    <span className="font-semibold text-[14px]">Payslips:</span>
                  </p>
                  <p className="mb-2 text-[12px]">
                    Upon running your payroll, your can send payslips easily by
                    clicking send payslips in the payroll preview page or
                    clicking the action button on the payroll summary page and
                    clicking "Send Payslip"
                  </p>
                  <p className="mb-0">
                    <span className="font-semibold text-[14px]">
                      Payroll Payment:
                    </span>
                  </p>
                  <p className="text-[12px]">
                    Upon running your payroll. Click the action button and then
                    click "Continue". On the payroll preview page, ensure that
                    the numbers are accurate and then click "PAY NOW" and follow
                    the instructions to disburse to all employee bank accounts.
                  </p>
                </div>
                <hr className="divider -mb-1 mt-2" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-highlight hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddPayrollGroup,
                      }
                    )}
                    onClick={() => setShowAddPayrollGroup(!showAddPayrollGroup)}
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I run payroll for all active employee?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddPayrollGroup}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddPayrollGroup,
                    })}
                  >
                    <Collapse isOpen={showAddPayrollGroup}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Click on payroll on the side bar. Ensure that all
                          payroll adjustments have been entered before this step
                          (Do this on the employees page)
                        </li>
                        <li>
                          Click on "RUN PAYROLL" button. Fill in all the
                          required information and click continue to view the
                          fees and breakdown. Click continue again to commence
                          to carry out the payroll computation.
                        </li>
                        <li>
                          When our system completes the computation. Click
                          refetch till you see in progress instead of computing.
                        </li>
                        <li>
                          Click the action on the payroll item and then click
                          continue to preview the payroll before sending
                          payslips or making payments
                        </li>
                        <li>
                          Click on "PAY NOW" on the preview page and input your
                          transaction PIN to complete the payment.
                        </li>
                        <li>
                          Navigate to the "Transaction History" tab on the
                          payroll page to view the status of each transaction
                        </li>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider -mb-1 -mt-1" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-highlight hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddEmployeeToPayrollGroup,
                      }
                    )}
                    onClick={() =>
                      setShowAddEmployeeToPayrollGroup(
                        !showAddEmployeeToPayrollGroup
                      )
                    }
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I run payroll for select active employee?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddEmployeeToPayrollGroup}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddEmployeeToPayrollGroup,
                    })}
                  >
                    <Collapse isOpen={showAddEmployeeToPayrollGroup}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Click on Employees on the side bar and navigate to the
                          salaries tab. Ensure that all payroll adjustments have
                          been entered before this step (Do this on the
                          employees page)
                        </li>
                        <li>
                          Select the employees you want to run payroll for. The
                          click the bulk action dropdown and select run payroll
                        </li>
                        <li>
                          Fill in all the required information and click
                          continue to view the fees and breakdown. Click
                          continue again to commence to carry out the payroll
                          computation. You will be redirected to the payroll
                          page.
                        </li>
                        <li>
                          When our system completes the computation. Click
                          refetch till you see in progress instead of computing.
                        </li>
                        <li>
                          Click the action on the payroll item and then click
                          continue to preview the payroll before sending
                          payslips or making payments
                        </li>
                        <li>
                          Click on "PAY NOW" on the preview page and input your
                          transaction PIN to complete the payment.
                        </li>
                        <li>
                          Navigate to the "Transaction History" tab on the
                          payroll page to view the status of each transaction
                        </li>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider mb-0 -mt-1" />
              </div>
            ) : type == "employee-payments" ? (
              <div className="flex flex-col gap-2 -mt-2">
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-highlight hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddPayrollGroup,
                      }
                    )}
                    onClick={() => setShowAddPayrollGroup(!showAddPayrollGroup)}
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I pay for all active employee?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddPayrollGroup}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddPayrollGroup,
                    })}
                  >
                    <Collapse isOpen={showAddPayrollGroup}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Click on payroll on the side bar, navigate to the
                          "Employee Payment Summary" tab.
                        </li>
                        <li>
                          Click on "MAKE EMPLOYEE PAYMENT" button. Fill in all
                          the required information and click continue to preview
                          the payments.
                        </li>
                        <li>
                          Click on "PAY NOW" on the preview page and input your
                          transaction PIN to complete the payment.
                        </li>
                        <li>
                          Navigate to the "Transaction History" tab on the
                          payroll page to view the status of each transaction
                        </li>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider -mb-1 -mt-1" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-highlight hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddEmployeeToPayrollGroup,
                      }
                    )}
                    onClick={() =>
                      setShowAddEmployeeToPayrollGroup(
                        !showAddEmployeeToPayrollGroup
                      )
                    }
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I pay select active employee?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddEmployeeToPayrollGroup}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddEmployeeToPayrollGroup,
                    })}
                  >
                    <Collapse isOpen={showAddEmployeeToPayrollGroup}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Click on Employees on the side bar and navigate to the
                          salaries tab.
                        </li>
                        <li>
                          Select the employees you want to run payroll for. The
                          click the bulk action dropdown and select "Make
                          Payment"
                        </li>
                        <li>
                          Fill in all the required information and click
                          continue to preview the payment.
                        </li>
                        <li>
                          Click on "PAY NOW" on the preview page and input your
                          transaction PIN to complete the payment.
                        </li>
                        <li>
                          Navigate to the "Transaction History" tab on the
                          payroll page to view the status of each transaction
                        </li>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider mb-0 -mt-1" />
                <div className="flex mt-2">
                  <div className="flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light">
                    <div className="w-[70px] pr-2 flex flex-row items-center ">
                      <img src={WarningSVG} alt="" srcSet="" />
                    </div>
                    <span className="text-[13px] text-justify">
                      Please note that employee payments made through this
                      method does not compute the payroll. You are simply making
                      payments to employees. No payslip is generated for your
                      employees and you cannot make statutory payments also.
                    </span>
                  </div>
                </div>
              </div>
            ) : type == "paye-tax" ? (
              <div className="flex flex-col gap-2 -mt-2">
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-highlight hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddPayrollGroup,
                      }
                    )}
                    onClick={() => setShowAddPayrollGroup(!showAddPayrollGroup)}
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I pay taxes?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddPayrollGroup}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddPayrollGroup,
                    })}
                  >
                    <Collapse isOpen={showAddPayrollGroup}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Upon running your payroll, the payroll is
                          automatically updated on this page.
                        </li>
                        <li>For first time </li>
                        <li>
                          Click on "PAY NOW" on the preview page and input your
                          transaction PIN to complete the payment.
                        </li>
                        <li>
                          Navigate to the "Transaction History" tab on the
                          payroll page to view the status of each transaction
                        </li>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider -mb-1 -mt-1" />
                <>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer hover:bg-highlight hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                      {
                        "pt-3 pb-3": showAddEmployeeToPayrollGroup,
                      }
                    )}
                    onClick={() =>
                      setShowAddEmployeeToPayrollGroup(
                        !showAddEmployeeToPayrollGroup
                      )
                    }
                  >
                    <span className="text-[15px] font-extrabold">
                      How do I pay select active employee?
                    </span>
                    <div className="pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAddEmployeeToPayrollGroup}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("flex flex-col ", {
                      hidden: !showAddEmployeeToPayrollGroup,
                    })}
                  >
                    <Collapse isOpen={showAddEmployeeToPayrollGroup}>
                      <div className="-mt-1 text-[13px] border p-2 bg-gray-50 rounded-md">
                        <li>
                          Click on Employees on the side bar and navigate to the
                          salaries tab.
                        </li>
                        <li>
                          Select the employees you want to run payroll for. The
                          click the bulk action dropdown and select "Make
                          Payment"
                        </li>
                        <li>
                          Fill in all the required information and click
                          continue to preview the payment.
                        </li>
                        <li>
                          Click on "PAY NOW" on the preview page and input your
                          transaction PIN to complete the payment.
                        </li>
                        <li>
                          Navigate to the "Transaction History" tab on the
                          payroll page to view the status of each transaction
                        </li>
                      </div>
                    </Collapse>
                  </div>
                </>
                <hr className="divider mb-0 -mt-1" />
                <div className="flex mt-2">
                  <div className="flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light">
                    <div className="w-[70px] pr-2 flex flex-row items-center ">
                      <img src={WarningSVG} alt="" srcSet="" />
                    </div>
                    <span className="text-[13px] text-justify">
                      Please note that employee payments made through this
                      method does not compute the payroll. You are simply making
                      payments to employees. No payslip is generated for your
                      employees and you cannot make statutory payments also.
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="w-full mt-[20px]">
              <Button text="OKAY!" type="submit" onClick={() => closeModal()} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default InfoModal;
