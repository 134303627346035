import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormFileInput from "../../../components/form-file-input";
import SalaryReviewLayout from "../../../components/layouts/salary-review";
import OnboardingDataTable from "../../../components/onboard-datatable";
import { useRouteQuery } from "../../../hooks/useRouteQuery";
import {
  useFullPayReverseBulk,
  useGrossPayReverseBulk,
  useNetPayReverseBulk,
} from "../../../redux/employees/hook";
import { useAddBulkSalary } from "../../../redux/employees/hook/salaries";
import { formValidation, formatNumber } from "../../../utils/functions";

function ReviewBulkEmployeePay() {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {}, []);

  const history = useHistory();
  const query = useRouteQuery();

  const [payType, setPayType] = useState("");
  const [fromSalary, setFromSalary] = useState(false);
  const [data, setData] = useState("");

  const { mutateAsync: netPayReverseBulk, isLoading: netLoading } =
    useNetPayReverseBulk();
  const { mutateAsync: grossPayReverseBulk, isLoading: grossLoading } =
    useGrossPayReverseBulk();
  const { mutateAsync: fullPayBulk, isLoading: fullLoading } =
    useFullPayReverseBulk();
  const { mutateAsync: saveBulkCompensation, isLoading: saveLoading } =
    useAddBulkSalary();

  const tableConfig = {
    title: "Compensation Breakdown",
    headers: [
      "Employee Name",
      "Basic",
      "  Housing",
      "Transport",
      "Others",
      "Tax",
      "Pension",
      "Net Pay",
    ],
    keys: [
      "employeeName",
      "basic_salary",
      "housing_allowance",
      "transportation_allowance",
      "other_allowances",
      "tax",
      "employee_pension",
      "net_pay",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Net Pay",
        key: "net_pay",
      },
    },
  };

  const [breakdown, setBreakdown] = useState(false);
  const [showBreakdown, setShowBreakdown] = useState(false);

  useEffect(() => {
    if (query) {
      setPayType(query.get("type"));
      setFromSalary(query.get("salary"));
    }
  }, [query]);

  const submitForm = async (data) => {
    const formData = new FormData();
    formData.append("file", data?.file[0]);

    if (payType === "net-pay") {
      formData.append("setting", "group");
      await netPayReverseBulk(formData).then((result) => {
        if (result.status === "success") {
          setData(JSON.stringify(result.salary_breakdown));
          result.salary_breakdown.forEach((employee) => {
            employee.employeeName = `${employee.first_name} ${employee.last_name}`;
            employee.other_allowances =
              employee.benefit_in_kind +
              employee.leave_allowance +
              employee.lunch_allowance +
              employee.other_allowance +
              employee.utility_allowance;
            employee.other_allowances = formatNumber(
              employee.other_allowances,
              2
            );
            employee.basic_salary = formatNumber(employee.basic_salary, 2);
            employee.housing_allowance = formatNumber(
              employee.housing_allowance,
              2
            );
            employee.transportation_allowance = formatNumber(
              employee.transportation_allowance,
              2
            );
            employee.gross_pay = formatNumber(employee.gross_pay, 2);
            employee.tax = formatNumber(employee.tax, 2);
            employee.employee_pension = formatNumber(
              employee.employee_pension,
              2
            );
            employee.net_pay = formatNumber(employee.net_pay, 2);
          });
        }
        setBreakdown({
          data: result.salary_breakdown,
        });
        setShowBreakdown(true);
      });
    } else if (payType === "gross-pay") {
      formData.append("setting", "group");
      await grossPayReverseBulk(formData).then((result) => {
        if (result.status === "success") {
          setData(JSON.stringify(result.salary_breakdown));
          result.salary_breakdown.forEach((employee) => {
            employee.employeeName = `${employee.first_name} ${employee.last_name}`;
            employee.other_allowances =
              employee.benefit_in_kind +
              employee.leave_allowance +
              employee.lunch_allowance +
              employee.other_allowance +
              employee.utility_allowance;
            employee.other_allowances = formatNumber(
              employee.other_allowances,
              2
            );
            employee.basic_salary = formatNumber(employee.basic_salary, 2);
            employee.housing_allowance = formatNumber(
              employee.housing_allowance,
              2
            );
            employee.transportation_allowance = formatNumber(
              employee.transportation_allowance,
              2
            );
            employee.gross_pay = formatNumber(employee.gross_pay, 2);
            employee.tax = formatNumber(employee.tax, 2);
            employee.employee_pension = formatNumber(
              employee.employee_pension,
              2
            );
            employee.net_pay = formatNumber(employee.net_pay, 2);
          });
        }
        setBreakdown({
          data: result.salary_breakdown,
        });
        setShowBreakdown(true);
      });
    } else {
      formData.append("setting", "group");
      await fullPayBulk(formData).then((result) => {
        if (result.status === "success") {
          history.push("/employees");
        }
      });
    }
  };

  const saveCompensation = async () => {
    let payload = {
      json_data: data,
    };
    await saveBulkCompensation(payload).then((result) => {
      if (result.status === "success") {
        history.push("/employees");
      }
    });
  };

  return (
    <SalaryReviewLayout step={2} title={"A Compensation"}>
      {showBreakdown ? (
        <div
          className={classNames("flex flex-col", {
            hidden: !showBreakdown,
          })}
        >
          <div className="w-full mb-[20px] md:mb-[0px]">
            <Button
              text="SAVE AND COMPLETE"
              type="submit"
              loading={saveLoading}
              onClick={() => saveCompensation()}
            />
          </div>
          <OnboardingDataTable
            data={breakdown}
            tableConfig={tableConfig}
            noCheck={true}
          />
          <hr className="divider" />
          <div
            className="flex justify-center w-full cursor-pointer hover:underline"
            onClick={() => setShowBreakdown(false)}
          >
            <span className="p2-medium text-color-black mt-3">Back</span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full h-fit">
          <span className="header-4">
            {" "}
            {payType === "net-pay"
              ? "I know the Net Pay only"
              : payType === "gross-pay"
              ? "I know the Gross Pay only"
              : "I Know The Complete Compensation"}
          </span>
          <hr />
          <form className="form " onSubmit={handleSubmit(submitForm)}>
            <div className="p-8 pt-4 border border-secondary-2-extralight rounded-md h-fit flex flex-col px-[60px]">
              <div className="flex flex-col text-center justify-center items-center">
                <span className="header-4-medium">
                  Upload employees{" "}
                  {payType === "net-pay"
                    ? "Net Pay "
                    : payType === "gross-pay"
                    ? "Gross Pay "
                    : "Salaries "}
                  from XLSX file
                </span>
                <button className="mt-9 h-10 border-[1.5px] border-secondary-2 rounded w-[175px]">
                  {payType === "net-pay" ? (
                    <a
                      href="https://ropay-excel-temporary-prod.s3.amazonaws.com/ropay_bulk_salary_template_net.xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download template
                    </a>
                  ) : payType === "gross-pay" ? (
                    <a
                      href="https://ropay-excel-temporary-prod.s3.amazonaws.com/ropay_bulk_salary_template_gross.xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download template
                    </a>
                  ) : (
                    <a
                      href="https://ropay-excel-temporary-prod.s3.amazonaws.com/ropay_bulk_salary_template_full.xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download template
                    </a>
                  )}
                </button>

                <FormFileInput
                  multiSource={true}
                  inputRef={register(formValidation("file", true))}
                  accept=".xlsx"
                />
              </div>
            </div>

            <div className="w-full mt-[20px]">
              <Button
                text={
                  payType === "net-pay" || payType === "gross-pay"
                    ? "Show Breakdown"
                    : "Save Compensation"
                }
                type="submit"
                loading={netLoading || grossLoading || fullLoading}
              />
            </div>
            <div
              className="flex justify-center w-full cursor-pointer"
              onClick={() => history.goBack()}
            >
              <span className="p2-medium text-color-black mt-3">Go Back</span>
            </div>
          </form>
        </div>
      )}
    </SalaryReviewLayout>
  );
}

export default ReviewBulkEmployeePay;
