import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../components/layouts/dashboard";
import { useHistory, useParams } from "react-router-dom";
import LoadingBar from "../../../../components/loader-bar";
import Button from "../../../../components/button";
import { Initials } from "../../../../utils/functions";
import { StatusText } from "../../../../components/fragments/status-text";
import { queryStatusConfig } from "../../../../utils/constants";
import {
  useChangeQueryStatus,
  useGetQuery,
} from "../../../../redux/human-resources/hook/queries";
import { QueryNotes } from "../../../../components/modal/hr-section/queries/query-notes";

const ViewQuery = () => {
  useEffect(() => {}, []);

  const { id } = useParams();

  const { data: query, isLoading } = useGetQuery(id);

  const { mutateAsync: resolve, isLoading: resolveLoading } =
    useChangeQueryStatus();

  const changeQueryStatus = async () => {
    let payload = {
      id: id,
      data: { status: query?.status == 1 ? 0 : 1 },
    };
    await resolve(payload);
  };

  return (
    <DashboardLayout title="View Employee Query">
      '
      <div className="flex flex-col gap-4 -mt-8">
        <div className="flex flex-row items-center box-shadow h-[104px]">
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-row px-[55px] justify-between  w-full">
              <div className="flex flex-row items-center">
                <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200">
                  {Initials(query?.first_name, query?.last_name)}
                </div>
                <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                  <span className="p1-bold">
                    {query?.first_name} {query?.last_name}
                  </span>
                  <span className="p4 text-color-gray">{query?.email}</span>
                </div>
              </div>
              {query ? (
                <div className="flex flex-row items-center">
                  <StatusText
                    statusConfig={queryStatusConfig}
                    code={query.status}
                    className={"py-2 rounded p1-bold px-3 p2-bold text-[13px]"}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="flex flex-col bg-white min-h-fit">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] mt-3">
            <div className="p3-bold header-4">Query Details</div>
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:items-center">
              {/* <div className='md:w-fit w-[100%]'>
                                <Button
                                text='Delete Query'
                                type='button'
                                className='flex gap-2 !h-[35px] w-fit p-2 '
                                onClick={() => {
                                    deleteEmployeeQuery()
                                }}
                                loading={resolveLoading}
                                textClass={'!text-[11px]'}
                                />
                            </div> */}
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Mark as resolved"
                  type="button"
                  leftIcon={"check"}
                  className="flex gap-2 !h-[35px] w-fit p-2 "
                  onClick={() => {
                    changeQueryStatus();
                  }}
                  loading={resolveLoading}
                  textClass={"!text-[11px]"}
                />
              </div>
            </div>
          </div>
          <hr className="divider" />
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-col md:flex-row h-full p-4 pt-1">
              <div className="flex flex-col gap-2 w-[100%]">
                <div className="flex md:flex-row flex-col gap-4 w-[100%] h-fit">
                  <QueryNotes />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewQuery;
