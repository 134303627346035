import { Axios } from "../axios";

const evaluatePerformance = async (payload) => {
  const { data } = await Axios.post("/performance-evaluations", payload);
  return data;
};

const advancedEvaluatePerformance = async (payload) => {
  const { data } = await Axios.post(
    "/performance-evaluations/advanced",
    payload
  );
  return data;
};

const advancedEvaluatePerformanceBySource = async (payload) => {
  const { data } = await Axios.post(
    "/performance-evaluations/advanced-by-source",
    payload
  );
  return data;
};

const markPerformanceReviewed = async (payload) => {
  const { data } = await Axios.post(
    `/performance-evaluations/review/${payload}`
  );
  return data;
};

const getPerformances = async (payload) => {
  let search = payload.search ? "&search=" + payload.search : "";
  let filter =
    payload.statusFilter && payload.statusFilter != -1
      ? "&filter=" + payload.statusFilter
      : "";
  const { data } = await Axios.get(
    `/performance-evaluations?limit=${payload.limit}&page=${payload.page}${filter}${search}`
  );
  return data?.data;
};

const getPerformanceSummary = async () => {
  const { data } = await Axios.post("/performances/summary");
  return data?.data;
};

const gradeBasicPerformance = async (payload) => {
  const { data } = await Axios.post(
    "/performance-evaluations/grade-basic/" + payload.id,
    payload.data
  );
  return data;
};

const updateTrainingRequirements = async (payload) => {
  const { data } = await Axios.post(
    "/performance-evaluations/update-training-requirement/" + payload.id,
    payload.data
  );
  return data;
};

const updateReviewerComment = async (payload) => {
  const { data } = await Axios.post(
    "/performance-evaluations/update-reviewer-comment/" + payload.id,
    payload.data
  );
  return data;
};

const getPerformanceWorkflows = async (payload) => {
  let search = payload.search ? "&search=" + payload.search : "";
  const { data } = await Axios.get(
    `/performance-evaluation/workflows?limit=${payload.limit}&page=${payload.page}${search}`
  );
  return data?.data;
};

const getPerformanceWorkflow = async (payload) => {
  const { data } = await Axios.get(
    "/performance-evaluation/workflows/" + payload
  );
  return data?.data;
};

const addPerformanceWorkflow = async (payload) => {
  const { data } = await Axios.post(
    "/performance-evaluation/workflows",
    payload
  );
  return data;
};

const updatePerformanceWorkflow = async (payload) => {
  const { data } = await Axios.put(
    "/performance-evaluation/workflows/" + payload.id,
    payload.data
  );
  return data;
};

const getPerformance = async (payload) => {
  const { data } = await Axios.get("/performance-evaluations/" + payload);
  return data?.data;
};

const getPerformancesByEmployee = async (payload) => {
  let start_date =
    payload.start_date != -1 ? "&start_date=" + payload.start_date : "";
  let end_date = payload.end_date != -1 ? "&end_date=" + payload.end_date : "";
  let search = payload.search ? "&search=" + payload.search : "";
  const { data } = await Axios.get(
    `/performance-evaluations/by-employee/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${search}${start_date}${end_date}`
  );
  return data?.data;
};

const deletePerformance = async (payload) => {
  const { data } = await Axios.delete(`/performance-evaluations/${payload}`);
  return data;
};

//OKRS
const getPerformanceOkrs = async (payload) => {
  let search = payload.search ? "&search=" + payload.search : "";
  const { data } = await Axios.get(
    `/performance-okrs?limit=${payload.limit}&page=${payload.page}${search}`
  );
  return data?.data;
};

const addPerformanceOKR = async (payload) => {
  const { data } = await Axios.post("/performance-okrs", payload);
  return data;
};

const updatePerformanceOKR = async (payload) => {
  const { data } = await Axios.put(
    "/performance-okrs/" + payload.id,
    payload.data
  );
  return data;
};

const deletePerformanceOKR = async (payload) => {
  const { data } = await Axios.delete(`/performance-okrs/${payload}`);
  return data;
};

//Smart Goals
const getPerformanceSmartGoals = async (payload) => {
  let search = payload.search ? "&search=" + payload.search : "";
  const { data } = await Axios.get(
    `/performance-smart-goals?limit=${payload.limit}&page=${payload.page}${search}`
  );
  return data?.data;
};

const addPerformanceSmartGoals = async (payload) => {
  const { data } = await Axios.post("/performance-smart-goals", payload);
  return data;
};

const updatePerformanceSmartGoals = async (payload) => {
  const { data } = await Axios.put(
    "/performance-smart-goals/" + payload.id,
    payload.data
  );
  return data;
};

const gradeSmartGoals = async (payload) => {
  const { data } = await Axios.post(
    "/performance-evaluations/grade-smart-goals",
    payload
  );
  return data;
};

const deletePerformanceSmartGoals = async (payload) => {
  const { data } = await Axios.delete(`/performance-smart-goals/${payload}`);
  return data;
};

//Technical Appraisals
const getTechnicalAppraisals = async (payload) => {
  let search = payload.search ? "&search=" + payload.search : "";
  const { data } = await Axios.get(
    `/technical-appraisals?limit=${payload.limit}&page=${payload.page}${search}`
  );
  return data?.data;
};

const addTechnicalAppraisal = async (payload) => {
  const { data } = await Axios.post("/technical-appraisals", payload);
  return data;
};

const updateTechnicalAppraisal = async (payload) => {
  const { data } = await Axios.put(
    "/technical-appraisals/" + payload.id,
    payload.data
  );
  return data;
};

const gradeTechnicalAppraisal = async (payload) => {
  const { data } = await Axios.post(
    "/performance-evaluations/grade-technical-appraisal",
    payload
  );
  return data;
};

const deleteTechnicalAppraisal = async (payload) => {
  const { data } = await Axios.delete(`/technical-appraisals/${payload}`);
  return data;
};

//Organisational Appraisals
const getPerformanceAppraisalIndicators = async (payload) => {
  const { data } = await Axios.get(
    `/performance-indicators?type=${payload.type}`
  );
  return data?.data;
};

const addPerformanceAppraisalIndicators = async (payload) => {
  const { data } = await Axios.post("/performance-indicators", payload);
  return data;
};

const updatePerformanceAppraisalIndicators = async (payload) => {
  const { data } = await Axios.put(
    "/performance-indicators/" + payload.id,
    payload.data
  );
  return data;
};

const gradeOrganisationalIndicators = async (payload) => {
  const { data } = await Axios.post(
    "/performance-evaluations/grade-appraisal",
    payload
  );
  return data;
};

const deletePerformanceAppraisalIndicators = async (payload) => {
  const { data } = await Axios.delete(`/performance-indicators/${payload}`);
  return data;
};

const comparePerformanceEvaluation = async (payload) => {
  const { data } = await Axios.get(`/performance-evaluations/compare"}`);
  console.log(data);
  return data;
};

const assignPerformanceEvaluation = async (payload) => {
  const { data } = await Axios.post(`/performance-evaluations/assign`, payload);
  return data;
};

//Performance Balanced Scorecard
const getPerformanceBalancedScorecards = async (payload) => {
  let search = payload.search ? "&search=" + payload.search : "";
  const { data } = await Axios.get(
    `/performance-balanced-scorecards?limit=${payload.limit}&page=${payload.page}${search}`
  );
  return data?.data;
};

const addPerformanceBalancedScorecard = async (payload) => {
  const { data } = await Axios.post(
    "/performance-balanced-scorecards",
    payload
  );
  return data;
};

const updatePerformanceBalancedScorecard = async (payload) => {
  const { data } = await Axios.put(
    "/performance-balanced-scorecards/" + payload.id,
    payload.data
  );
  return data;
};

const deletePerformanceBalancedScorecard = async (payload) => {
  const { data } = await Axios.delete(
    `/performance-balanced-scorecards/${payload}`
  );
  return data;
};

export const performances = {
  evaluatePerformance,
  advancedEvaluatePerformanceBySource,
  advancedEvaluatePerformance,
  getPerformances,
  getPerformanceSummary,
  gradeBasicPerformance,
  getPerformance,
  deletePerformance,
  getPerformancesByEmployee,
  getPerformanceWorkflows,
  getPerformanceWorkflow,
  addPerformanceWorkflow,
  updatePerformanceWorkflow,
  markPerformanceReviewed,

  updateTrainingRequirements,
  updateReviewerComment,

  getPerformanceOkrs,
  addPerformanceOKR,
  updatePerformanceOKR,
  deletePerformanceOKR,

  getPerformanceSmartGoals,
  addPerformanceSmartGoals,
  updatePerformanceSmartGoals,
  gradeSmartGoals,
  deletePerformanceSmartGoals,

  getTechnicalAppraisals,
  addTechnicalAppraisal,
  updateTechnicalAppraisal,
  gradeTechnicalAppraisal,
  deleteTechnicalAppraisal,

  getPerformanceAppraisalIndicators,
  addPerformanceAppraisalIndicators,
  updatePerformanceAppraisalIndicators,
  gradeOrganisationalIndicators,
  deletePerformanceAppraisalIndicators,

  comparePerformanceEvaluation,
  assignPerformanceEvaluation,

  getPerformanceBalancedScorecards,
  addPerformanceBalancedScorecard,
  updatePerformanceBalancedScorecard,
  deletePerformanceBalancedScorecard,
};
