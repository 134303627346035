import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import TabView from "../../../components/tabview";
import CandidateListTab from "../../../components/hr-section/recruitment/view-recruitment/candidate-list";
import Button from "../../../components/button";
import RecruitLogoSVG from "../../../assets/svg/recruit-logo.svg";
import RecruitmentDetailsModal from "../../../components/modal/hr-section/recruitment/view-recruitment-details";
import RecruitmentQuestionsTab from "../../../components/hr-section/recruitment/view-recruitment/recruitment-questions";
import { useGetRecruitment } from "../../../redux/human-resources/hook/recruitment";
import RejectedListTab from "../../../components/hr-section/recruitment/view-recruitment/rejected";
import FilteredListTab from "../../../components/hr-section/recruitment/view-recruitment/filtered";
import UnauthorizedPage from "../../errors/unauthorized";
import { useSelector } from "react-redux";
import CurrentStageTab from "../../../components/hr-section/recruitment/view-recruitment/recruitment-stages";

const ViewRecruitmentPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.recruitment_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [showRecrutimentDetails, setShowRecruitmentDetails] = useState(false);

  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const { data: recruitment } = useGetRecruitment(id);

  //Employee View Tabs
  const EmployeeTabs = [
    {
      title: "All Candidates",
      Content: CandidateListTab,
    },
    {
      title: "Auto Filtered",
      Content: FilteredListTab,
    },
    {
      title: "Rejected",
      Content: RejectedListTab,
    },
    {
      title: "Questions",
      Content: RecruitmentQuestionsTab,
    },
    {
      title: "Stages",
      Content: CurrentStageTab,
    },
  ];
  return (
    <DashboardLayout title="Recruitment Page">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {showRecrutimentDetails ? (
            <RecruitmentDetailsModal
              isOpen={showRecrutimentDetails}
              closeModal={() => setShowRecruitmentDetails(false)}
              recruitmentData={recruitment}
              title="Recruitment Details"
            />
          ) : null}
          <div className="flex flex-col">
            <div className="flex flex-row items-center box-shadow h-[104px]">
              <div className="flex flex-row px-[20px] justify-between  w-full">
                <div className="flex flex-row items-center">
                  <img
                    src={RecruitLogoSVG}
                    alt={"recruit"}
                    className={"h-[58px] w-[58px] rounded-[58px] "}
                  />
                  <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                    <span className="p1-bold">{recruitment?.role}</span>
                    <span className="p4 text-color-gray">
                      {recruitment?.location_type} - ({recruitment?.job_type})
                    </span>
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <Button
                    text="View Recruitment Details"
                    type="submit"
                    className="flex gap-2 !h-[35px] w-fit p-2 "
                    textClass="!text-[12px] "
                    theme="primary"
                    onClick={() => setShowRecruitmentDetails(true)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8">
              <TabView
                Tabs={EmployeeTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default ViewRecruitmentPage;
