import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import TabView from "../../components/tabview";
import { useGetEmployee } from "../../redux/employees/hook";
import { StatusText } from "../../components/fragments/status-text";
import { employeeStatusConfig } from "../../utils/constants";
import EmployeeProfile from "../../components/employees/view/profile";
import EmployeeCompensation from "../../components/employees/view/compensation";
import SingleEmployeeSalaryAdvanceTab from "../../components/employees/view/salary-advance";
import { SingleEmployeeBonusTab } from "../../components/employees/view/bonus";
import SingleEmployeeSuspensionsTab from "../../components/employees/view/suspension";
import SingleEmployeeReimbursementTab from "../../components/employees/view/reimbursements";
import EmployeeBankInformation from "../../components/employees/view/bank-information";
import { useSelector } from "react-redux";
import UnauthorizedPage from "../errors/unauthorized";
import { Initials } from "../../utils/functions";
import EmployeeQueriesTab from "../../components/employees/view/queries";
import EmployeePerformanceTab from "../../components/employees/view/performance";
import SingleEmployeeGuarantorTab from "../../components/employees/view/guarantors";

const ViewEmployeePage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  const { section } = useSelector((state) => state.sectionSlice);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.payroll_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (section && user) {
      if (section.name === "core hr") {
        setEmployeeTabs([
          {
            title: "Profile",
            Content:
              user.permissions.employee_permission.employee_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? EmployeeProfile
                : UnauthorizedPage,
          },
          {
            title: "Salary",
            Content:
              user.permissions.employee_permission.salary_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? EmployeeCompensation
                : UnauthorizedPage,
          },
          {
            title: "Bank Info",
            Content:
              user.permissions.employee_permission.employee_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? EmployeeBankInformation
                : UnauthorizedPage,
          },
          {
            title: "Performance",
            Content:
              user.permissions.company_permission.performance_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? EmployeePerformanceTab
                : UnauthorizedPage,
          },
          {
            title: "Queries",
            Content:
              user.permissions.employee_permission.query_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? EmployeeQueriesTab
                : UnauthorizedPage,
          },
          {
            title: "Guarantors",
            Content:
              user.permissions.employee_permission.guarantor_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? SingleEmployeeGuarantorTab
                : UnauthorizedPage,
          },
          {
            title: "Suspension",
            Content:
              user.permissions.employee_permission.suspension_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? SingleEmployeeSuspensionsTab
                : UnauthorizedPage,
          },
        ]);
      } else {
        setEmployeeTabs([
          {
            title: "Profile",
            Content:
              user.permissions.employee_permission.employee_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? EmployeeProfile
                : UnauthorizedPage,
          },
          {
            title: "Salary",
            Content:
              user.permissions.employee_permission.salary_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? EmployeeCompensation
                : UnauthorizedPage,
          },
          {
            title: "Bank info",
            Content:
              user.permissions.employee_permission.employee_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? EmployeeBankInformation
                : UnauthorizedPage,
          },
          {
            title: "Salary Advance",
            Content:
              user.permissions.employee_permission.advance_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? SingleEmployeeSalaryAdvanceTab
                : UnauthorizedPage,
          },
          {
            title: "Bonus",
            Content:
              user.permissions.employee_permission.bonus_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? SingleEmployeeBonusTab
                : UnauthorizedPage,
          },

          {
            title: "Reimbursables",
            Content:
              user.permissions.employee_permission.guarantor_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? SingleEmployeeReimbursementTab
                : UnauthorizedPage,
          },
          {
            title: "Suspension",
            Content:
              user.permissions.employee_permission.suspension_read === 1 ||
              user.permissions.company_permission.role === "Owner"
                ? SingleEmployeeSuspensionsTab
                : UnauthorizedPage,
          },
        ]);
      }
    }
  }, [section, user]);

  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const { data: employee } = useGetEmployee(id);

  //Employee View Tabs
  const [EmployeeTabs, setEmployeeTabs] = useState();

  return (
    <DashboardLayout title="View Employee">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col -mt-2">
            <div className="flex flex-row items-center box-shadow h-[104px]">
              <div className="flex flex-row px-4 justify-between  w-full">
                <div className="flex flex-row items-center justify-between w-full">
                  <div className="flex flex-row items-center">
                    <div className="text-[18px] flex font-bold justify-center gap-4 items-center h-[58px] w-[58px] rounded border-[3px] header-3 border-secondary-2 bg-green-200 relative hover:scale-150 hover:m-2 duration-500">
                      {employee?.profile_photo_url ? (
                        <div>
                          <img
                            src={employee?.profile_photo_url}
                            alt="email_icon"
                            className="object-fit min-w-[48px] min-h-[48px] max-w-[48px] max-h-[48px] rounded"
                          />
                        </div>
                      ) : (
                        <>
                          {Initials(employee?.first_name, employee?.last_name)}
                        </>
                      )}
                    </div>
                    <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                      <span className="p1-bold">
                        {employee?.first_name} {employee?.last_name}
                      </span>
                      <span className="p4 text-color-gray">
                        {employee?.job_position}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <StatusText
                      statusConfig={employeeStatusConfig}
                      code={employee?.status}
                      className={"rounded px-3 !text-[13px]"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              {EmployeeTabs ? (
                <TabView
                  Tabs={EmployeeTabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default ViewEmployeePage;
