import { Axios } from '../axios';

const addCompanyToHMO = async () => {
  const { data } = await Axios.get('/hmo/add-company');
  return data;
};

const addCompanyHMOSettings = async (payload) => {
  const { data } = await Axios.post('/hmo/add-settings', payload);
  return data;
};

const getHMOBeneficiaries = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/hmo/fetch-beneficiaries?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );
  return data?.data;
};

const getHMOBeneficiary = async (payload) => {
  const { data } = await Axios.get('/hmo/fetch-beneficiaries/' + payload);
  return data?.data;
};

const addHMOBeneficiaries = async (payload) => {
  const { data } = await Axios.post('/hmo/employee-add', payload);
  return data;
};

const getHMOSettings = async () => {
  const { data } = await Axios.get('/hmo/fetch-settings');
  return data?.data;
};

const updateHMOSettings = async (payload) => {
  const { data } = await Axios.put(`/hmo/update-company-settings`, payload);
  return data;
};

const subscribeHMO = async (payload) => {
  const { data } = await Axios.post(
    '/hmo/subscribe/' + payload,
  );
  return data;
};

const changeHMOStatus = async (payload) => {
  const { data } = await Axios.post(
    '/hmo/change-status/' + payload,
  );
  return data;
};

const getHMOs = async () => {

  const { data } = await Axios.get(
    `/hmo/fetch-hmos`,
  );
  return data?.data;
};

const getHMOPlans = async (payload) => {

  const { data } = await Axios.get(
    `/hmo/fetch-hmo-plans/${payload}`,
  );
  return data?.data;
};

export const hmo = {
  addCompanyToHMO,
  addCompanyHMOSettings,
  getHMOBeneficiaries,
  getHMOBeneficiary,
  addHMOBeneficiaries,
  getHMOSettings,
  updateHMOSettings,
  subscribeHMO,
  changeHMOStatus,
  getHMOs,
  getHMOPlans
};