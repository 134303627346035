import { Axios } from '../axios';

const getInvoicesReceived = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get( `/invoice-received?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  return data?.data;
};

const getInvoicesReceivedByVendor = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get( `/invoice-received/by-customer/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  return data?.data;
};

const updateInvoiceReceived = async (payload) => {

  const { data } = await Axios.post( '/invoice-received/' + payload.id, payload.data,
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const getInvoiceReceived = async (payload) => {
  const { data } = await Axios.get('/invoice-received/' + payload);
  return data?.data;
};

const createSingleInvoiceReceived = async (payload) => {
  const { data } = await Axios.post('/invoice-received', 
  payload,
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const markInvoiceReceivedPaid = async (payload) => {
  const { data } = await Axios.post(`/invoice-received/${payload.id}/mark-as-paid`, payload.data);
  return data;
};

const deleteInvoiceReceived = async (payload) => {
  const { data } = await Axios.delete('invoice-received/'+payload);
  return data;
};

const getInvoicesReceivedPayments = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get( `/invoice-received/payments/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  return data?.data;
};

const initiateInvoicePayment = async (payload) => {
  const { data } = await Axios.post(`/invoice-received/payment/initiate/${payload.id}`, payload.data);
  return data;
};

const disburseInvoicePayment = async (payload) => {
  const { data } = await Axios.post(`/invoice-received/payment/disburse/${payload.id}`, payload.data);
  return data;
};

const declineInvoicePayment = async (payload) => {
  const { data } = await Axios.post(`/invoice-received/payment/decline/${payload.id}`, payload.data);
  return data;
};

export const invoices_received = {
  getInvoicesReceived,
  getInvoicesReceivedByVendor,
  updateInvoiceReceived,
  getInvoiceReceived,
  createSingleInvoiceReceived,
  markInvoiceReceivedPaid,
  deleteInvoiceReceived,
  getInvoicesReceivedPayments,

  initiateInvoicePayment,
  disburseInvoicePayment,
  declineInvoicePayment
};