import React, { useEffect, useRef, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormSelect from '../../../form-select';
import {
  useSendApplicantsEmail,
} from '../../../../redux/human-resources/hook/recruitment';
import { useGetOnboardingRecruitmentEmails } from '../../../../redux/email-templates/hook/recruitment';
import AddSmallSVG from '../../../../assets/svg/add-icon-small.svg';
import AddRecruitmentEmailTemplate from './add-recruitment-email-template';
import Modal from '../../modal';

function SendApplicantsEmail({ isOpen, closeModal, applicantIds }) {
  const [emailId, setEmailId] = useState();

  const { data: recruitmentEmails } = useGetOnboardingRecruitmentEmails();

  const { mutateAsync: sendEmail, isLoading } = useSendApplicantsEmail();
  const [showAddEmailTemplate, setShowAddEmailTemplate] = useState(false);

  const submitForm = async () => {
    let payload = {
      applicant_ids: applicantIds,
      email_template_id: emailId,
    };
    await sendEmail(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        {showAddEmailTemplate ? (
          <AddRecruitmentEmailTemplate
            isOpen={showAddEmailTemplate}
            closeModal={() => setShowAddEmailTemplate(false)}
            templateData={null}
            buttonText={'Save'}
            title='Add Email Template'
          />
        ) : null}
        <form className='form pb-2'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Send Email</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
            <span className='p3 text-[14px] text-gray-500'>
              Send Emails to Applicants
            </span>
          </div>
          <hr className='divider' />
          <div className='flex flex-col p-4 pt-1'>
            <div>
              {recruitmentEmails ? (
                <FormSelect
                  value={emailId}
                  options={recruitmentEmails}
                  readOnly={recruitmentEmails ? false : true}
                  onChange={(selected) => {
                    setEmailId(selected);
                  }}
                  label='Select Email Template'
                />
              ) : (
                <div className='p4-medium text-color-gray'>
                  No Email Template Found. Click link below to add a new Email
                  Template.
                </div>
              )}
              <hr className='divider' />
              <div className='flex flex-row items-center gap-2 justify-left w-full cursor-pointer'>
                <img
                  src={AddSmallSVG}
                  alt={'add email template'}
                  className={'h-[15px] w-[15px]'}
                />
                <span
                  className='p4-medium hover:underline hover:italic hover:font-bold text-primary-3 duration-75'
                  onClick={() => setShowAddEmailTemplate(true)}
                >
                  Add Email Template
                </span>
              </div>
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text={'Send Email'}
                theme={'third'}
                type='button'
                onClick={() => {
                  submitForm();
                }}
                loading={isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default SendApplicantsEmail;
