import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import {
  useAddLeaveType,
  useEditLeaveType,
} from '../../../../redux/human-resources/hook/leave';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormTextArea from '../../../form-textarea';
import Modal from '../../modal';

function AddLeaveType({
  isOpen,
  closeModal,
  refresh,
  leaveTypeData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (leaveTypeData) {
      setLeaveName(leaveTypeData.name);
      setMaxAnnualDays(leaveTypeData.max_annual_days);
      setMaxApplyDays(leaveTypeData.max_appplication_days);
      setDescription(leaveTypeData.description);
      setLeaveTypeId(leaveTypeData.id);
      setLeaveType('edit');
    } else {
      setLeaveType('add');
    }
  }, [leaveTypeData]);

  const [leaveTypeId, setLeaveTypeId] = useState(null);

  const [leaveName, setLeaveName] = useState('');
  const [maxAnnualDays, setMaxAnnualDays] = useState();
  const [maxApplyDays, setMaxApplyDays] = useState();
  const [description, setDescription] = useState('');

  const [leaveType, setLeaveType] = useState('add');

  const { mutateAsync: addLeaveType, isLoading: addLeaveTypeloading } =
    useAddLeaveType();

  const { mutateAsync: editLeaveType, isLoading: editLeaveTypeloading } =
    useEditLeaveType();

  const submitForm = async (data) => {
    const name = DOMPurify.sanitize(data?.leave_name);
    const max_annual_days = DOMPurify.sanitize(data?.max_annual_days);
    const max_appplication_days = DOMPurify.sanitize(
      data?.max_appplication_days,
    );
    const description = DOMPurify.sanitize(data?.description);

    let payload = {
      name,
      max_annual_days,
      max_appplication_days,
      description,
    };

    if (leaveType === 'add') {
      addLeaveType(payload).then(() => {
        closeModal();
      });
    } else {
      let editPayload = {
        id: leaveTypeId,
        data: payload,
      };
      editLeaveType(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div>
              <FormInput
                label='Leave Type Title'
                name='leave_name'
                type='text'
                placeholder={'Enter leave type'}
                defaultValue={leaveName}
                onInput={(e) => {
                  setLeaveName(e.target.value);
                }}
                inputRef={register(formValidation('text', true))}
                error={errors.leave_name}
                errorMessage={errors.leave_name && errors.leave_name.message}
              />
              <div className='flex flex-row md:flex-col w-full justify-between -mb-1'>
                <div className='w-1/2 pr-4'>
                  <FormInput
                    label='Maximum Annual Days'
                    name='max_annual_days'
                    type='number'
                    placeholder={'Enter annual available days'}
                    defaultValue={maxAnnualDays}
                    onInput={(e) => {
                      setMaxAnnualDays(e.target.value);
                    }}
                    inputRef={register(formValidation('number', true))}
                    error={errors.max_annual_days}
                    errorMessage={
                      errors.max_annual_days && errors.max_annual_days.message
                    }
                  />
                </div>
                <div className='w-1/2 pl-4'>
                  <FormInput
                    label='Maximum Application Days'
                    name='max_appplication_days'
                    type='number'
                    placeholder={'Enter single application max. days'}
                    defaultValue={maxApplyDays}
                    onInput={(e) => {
                      setMaxApplyDays(e.target.value);
                    }}
                    inputRef={register(formValidation('number', true))}
                    error={errors.max_appplication_days}
                    errorMessage={
                      errors.max_appplication_days &&
                      errors.max_appplication_days.message
                    }
                  />
                </div>
              </div>
              <FormTextArea
                label='Description'
                name='description'
                type='text'
                placeholder={'Enter leave description'}
                defaultValue={description}
                onInput={(e) => {
                  setDescription(e.target.value);
                }}
                inputRef={register(formValidation('text', true))}
                error={errors.description}
                errorMessage={errors.description && errors.description.message}
              />
            </div>
            <div className='w-full mt-2 pb-4'>
              <Button
                text={buttonText}
                type='submit'
                disabled={!isValid}
                loading={addLeaveTypeloading || editLeaveTypeloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddLeaveType;
