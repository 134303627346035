import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import LoadingBar from "../../../../components/loader-bar";
import Button from "../../../../components/button";
import { Initials } from "../../../../utils/functions";
import {
  PAGINATION_DEFAULT,
  trainingStatusConfig,
} from "../../../../utils/constants";
import {
  useGetSingleTraining,
  useGetTrainingEmployees,
  useMarkTrainingComplete,
  useRemoveEmployeesFromTraining,
} from "../../../../redux/employees/hook/training";
import { StatusText } from "../../../../components/fragments/status-text";
import DataTable from "../../../../components/datatable";
import AddEmployeeToTraining from "../../../../components/modal/hr-section/employee-training/add-employee-to-training";
import TrainedEmployee from "../../../../components/modal/hr-section/employee-training/trained-employee";

const ViewTraining = () => {
  useEffect(() => {}, []);

  const { id } = useParams();

  const { data: training, isLoading } = useGetSingleTraining(id);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const [showAddEmployeeToTraining, setShowAddEmployeeToTraining] =
    useState(false);
  const [showTrainedEmployee, setShowTrainedEmployee] = useState(false);
  const [trainedEmployeeData, setTrainedEmployeeData] = useState(null);

  const { data: training_employees, trainingEmployeeStatusLoading } =
    useGetTrainingEmployees(
      id,
      pagination?.limit,
      pagination?.page,
      pagination?.search
    );

  const [tableType, setTableType] = useState("table");

  const tableConfig = {
    headers: ["Employee Name", "Email", "Mobile"],
    keys: ["employeeName", "email", "mobile"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "mobile",
      },
    },
  };
  const { mutateAsync: removeEmployee } = useRemoveEmployeesFromTraining();
  const { mutateAsync: markTraining, isLoading: markTrainingloading } =
    useMarkTrainingComplete();

  const markTrainingStatus = async () => {
    let payload = {
      id: id,
      data: { status: training?.status == 1 ? 0 : 1 },
    };
    await markTraining(payload);
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = training_employees.data.findIndex((item) => item.id === id);
        setTrainedEmployeeData(training_employees.data[index]);
        setShowTrainedEmployee(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = training_employees.data.findIndex((item) => item.id === id);
        removeEmployee(training_employees.data[index].id);
        // history.push('/training/view/' + training_employees.data[index].id);
      },
    },
  ];

  return (
    <DashboardLayout title="View Training">
      <div className="flex flex-col gap-4">
        {showAddEmployeeToTraining ? (
          <AddEmployeeToTraining
            isOpen={showAddEmployeeToTraining}
            trainingId={id}
            closeModal={() => setShowAddEmployeeToTraining(false)}
          />
        ) : null}
        {showTrainedEmployee ? (
          <TrainedEmployee
            isOpen={showTrainedEmployee}
            trainedEmployeeData={trainedEmployeeData}
            closeModal={() => setShowTrainedEmployee(false)}
          />
        ) : null}

        <div className="flex flex-row items-center box-shadow h-[104px]">
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-row px-[55px] justify-between  w-full">
              <div className="flex flex-row items-center">
                <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200">
                  {Initials(training?.training_name)}
                </div>
                <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                  <span className="p1-bold">{training?.training_name}</span>
                  <span className="p4 text-color-gray">
                    {training?.trainer_name}
                  </span>
                </div>
              </div>
              {training ? (
                <div className="flex flex-row items-center">
                  <StatusText
                    statusConfig={trainingStatusConfig}
                    code={training?.status}
                    className={"py-2 rounded p1-bold px-3 p2-bold text-[13px]"}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="flex flex-col bg-white min-h-fit">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] mt-3">
            <div className="p3-bold header-4">Training Employees</div>
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:items-center">
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Add Employees"
                  type="button"
                  theme={"primary"}
                  leftIcon={"add"}
                  className="flex gap-2 !h-[35px] w-fit p-2 "
                  onClick={() => {
                    setShowAddEmployeeToTraining(true);
                  }}
                  textClass="!text-[11px] !text-hr-primary-1"
                />
              </div>
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Mark Training Completed"
                  type="button"
                  className="flex gap-2 !h-[35px] px-3"
                  onClick={() => {
                    markTrainingStatus();
                  }}
                  leftIcon={"check"}
                  loading={markTrainingloading}
                  textClass={"!text-[11px]"}
                />
              </div>
            </div>
          </div>
          <hr className="divider" />
          {trainingEmployeeStatusLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={trainingEmployeeStatusLoading} />
            </div>
          ) : (
            <DataTable
              data={training_employees}
              tableConfig={tableConfig}
              updatePagination={(data) => setPagination(data)}
              filterByStatus={false}
              actionConfig={actionConfig}
              computedActions={true}
              tableType={tableType}
              layoutSwitch={false}
              searchData={true}
              noCheck={true}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewTraining;
