import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../utils/functions";
import Button from "../../button";
import CloseSVG from "../../../assets/svg/close.svg";
import FormFileInputWithOnchange from "../../form-file-input-with-onchange";
import { useHistory } from "react-router-dom";
import { useCompanyUploadProfilePicture } from "../../../redux/company/hook";
import Modal from "../modal";

function UploadCompanyLogo({ isOpen, closeModal, refresh }) {
  useEffect(() => {}, []);
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { mutateAsync: uploadCompanyLogo, isLoading } =
    useCompanyUploadProfilePicture();

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const history = useHistory();

  const submitForm = async (data) => {
    const formData = new FormData();
    formData.append("file", data?.file[0]);

    await uploadCompanyLogo(formData).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className="!max-w-[700px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col pb-4 overflow-auto">
        <div className="pt-4 px-8">
          <div className="flex flex-row justify-between items-center">
            <span className="header-4">Upload Company Logo</span>
            <div className="cursor-pointer" onClick={() => closeModal()}>
              <img src={CloseSVG} alt="close" />
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="flex flex-col justify-center items-center w-full">
          <form
            className="form flex flex-col justify-center items-center"
            onSubmit={handleSubmit(submitForm)}
          >
            <div className="flex flex-col justify-center items-center">
              <span className="header-5 text-left">(Max. size 2MB)</span>
              <div className="flex flex-row gap-2 w-full">
                <FormFileInputWithOnchange
                  multiSource={false}
                  inputRef={register(formValidation("file", true))}
                  accept=".jpg,.jpeg,.png"
                  name={"file"}
                  error={errors.file}
                  onChange={handleChange}
                  errorMessage={errors.file && errors.file.message}
                />
                {file ? (
                  <div className="flex items-center justify-center mt-2 border border-gray-200 p-2 h-[65px] w-[65px] rounded duration-500">
                    <img
                      src={file}
                      alt={"profile_pix"}
                      className="object-contain max-w-[55px] max-h-[55px]"
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="w-full mt-[10px]">
              <Button
                text="Upload"
                type="submit"
                loading={isLoading}
                // disabled={!isValid}
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default UploadCompanyLogo;
