import React from "react";

import { Add } from "./add";
import { AddSmaller } from "./add-smaller";
import { Arrow } from "./arrow";
import { Change } from "./change";
import { Checkmark } from "./checkmark";
import { ChevronCircle } from "./chevron-circle";
import { Chevron } from "./chevron";
import { Close } from "./close";
import { Delete } from "./delete";
import { Empty } from "./empty";
import { GoogleIcon } from "./google-icon";
import { InfoSquare } from "./info-square";
import { MoreDots } from "./more-dots";
import { MoreSquare } from "./more-square";
import { Notification } from "./notification";
import { QuestionMark } from "./question-mark";
import { Search } from "./search";
import { TimeCircle } from "./time-circle";
import { Email } from "./carbon-email-new";
import { SuspendIcon } from "./suspend-icon-new";
import { Purchase } from "./purchase-button-new";
import { Export } from "./export-button-new";
import { UploadFile } from "./upload-file-new";
import { Picture } from "./pix";
import { Refresh } from "./refresh-btn-icon";
import { EyeIcon } from "./eye";
import { CheckMark } from "./check-white";
import { Evaluate } from "./evaluation-button-new";
import { ForwardArrow } from "./forward-arrow-button";

const Icon = ({ name }) => {
  switch (name) {
    case "add":
      return <Add />;
    case "add-small":
      return <AddSmaller />;
    case "arrow":
      return <Arrow />;
    case "change":
      return <Change />;
    case "checkmark":
      return <Checkmark />;
    case "chevronCircle":
      return <ChevronCircle />;
    case "chevron":
      return <Chevron />;
    case "close":
      return <Close />;
    case "delete":
      return <Delete />;
    case "empty":
      return <Empty />;
    case "googleIcon":
      return <GoogleIcon />;
    case "infoSquare":
      return <InfoSquare />;
    case "moreSquare":
      return <MoreSquare />;
    case "moreDots":
      return <MoreDots />;
    case "notification":
      return <Notification />;
    case "questionMark":
      return <QuestionMark />;
    case "search":
      return <Search />;
    case "timeCircle":
      return <TimeCircle />;
    case "email":
      return <Email />;
    case "suspension":
      return <SuspendIcon />;
    case "purchase":
      return <Purchase />;
    case "export":
      return <Export />;
    case "upload":
      return <UploadFile />;
    case "picture":
      return <Picture />;
    case "refresh":
      return <Refresh />;
    case "view":
      return <EyeIcon />;
    case "check":
      return <CheckMark />;
    case "evaluate":
      return <Evaluate />;
    case "forward":
      return <ForwardArrow />;
    default:
      return <Add />;
  }
};

export default Icon;
