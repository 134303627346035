import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { branches } from '../../../api/hr/branches';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetBranchSummary() {
  return useQuery(['branch-summary'], () => {
    return branches.getBranchSummary();
  });
} 

export function useGetBranches(limit, page, search) {
    return useQuery(
      ['branches', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return branches.getBranches({
          limit,
          page,
          search,
        });
      },
      {
        select: (data) => {
          data?.data?.forEach((branch) => {
            branch.created = moment(branch.created).format('MMMM D, YYYY');
            branch.computedActions = [0, 1, 2, 3, 4, 5]
            branch.branch_manager = branch.manager_setting?.manager ? branch.manager_setting?.manager: ''
            if (branch && branch.description && branch.description.length >= 30) {
              branch.description = branch.description.substring(0, 30) + '....';
              
            }
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetOnboardingBranches(limit, page, statusFilter, search) {
  return useQuery(
    ['onboarding-branches', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return branches.getBranches({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Branch Selected' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
    
  );
}

export function useAddBranch() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return branches.addBranch(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('departments');
          store.dispatch(setAlert(true, 'success', response.message));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useEditBranch() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return branches.updateBranch(payload);
      },
      {
        onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('branches');
          queryClient.invalidateQueries('branch-settings');
          store.dispatch(setAlert(true, 'success', response.message));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useGetBranch(id) {
    return useQuery(
        ['branch', id],
        () => {
            return branches.getBranch( id );
        },
        {
          enabled: id !== null,
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
        },
    );
}

export function useDeleteBranch() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return branches.deleteBranch(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('teams');
        queryClient.invalidateQueries('team-settings');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

//Branch Employees
export function useGetBranchEmployees(id, limit, page, statusFilter, search) {
    return useQuery(
      ['branch-employees', { id, limit, page, statusFilter, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return branches.getBranchEmployees({
          id,
          limit,
          page,
          statusFilter,
          search,
        });
      },
      {
        enabled: id !== null,
        select: (data) => {
          if (data?.employees) {
            data.employees.name = data?.name;
            data.employees.id = data?.id;
          }
          if (data?.employees?.data) {
            data.employees.data.forEach((employee) => {
              employee.created = moment(employee.created).format('MMMM DD, YYYY')
              employee.computedActions = [0]

              employee.employeeName = `${employee.employee_first_name} ${employee.employee_last_name}`;
            });
          }
          return data?.employees;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        
      },
    );
}

export function useAddEmployeesToBranch() {
    return useMutation(
      (payload) => {
        return branches.addEmployeesToBranch(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('branches');
          queryClient.invalidateQueries('branch-employees');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useRemoveEmployeesFromBranch() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return branches.removeEmployeesFromBranch(payload);
        },
        {
            onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('branch-employees');
            store.dispatch(setAlert(true, 'success', response.message));
            },
            onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useMoveEmployeesToBranch() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return branches.moveEmployeesToBranch(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('department-employees');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

//Branch Teams
export function useGetBranchTeams(id, limit, page, search) {
  return useQuery(
    ['branch-teams', { id, limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return branches.getBranchTeams({
        id,
        limit,
        page,
        search,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((item) => {
          item.created = item.created.split('T')[0]
          item.computedActions = [0]
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      
    },
  );
}

export function useAddTeamsToBranch() {
  return useMutation(
    (payload) => {
      return branches.addTeamsToBranch(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('branches');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useRemoveTeamsFromBranch() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return branches.removeTeamsFromBranch(payload);
    },
    {
      onSuccess: (response, variables, context) => {
      queryClient.invalidateQueries('branch-teams');
      store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
      store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMoveTeamToBranch() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return branches.moveTeamToBranch(payload);
        },
        {
            onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('branch-teams');
            store.dispatch(setAlert(true, 'success', response.message));
            },
            onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
            },
        },
    );
}

//Branch Departments
export function useGetBranchDepartments(id, limit, page, search) {
    return useQuery(
      ['branch-departments', { id, limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return branches.getBranchDepartments({
          id,
          limit,
          page,
          search,
        });
      },
      {
        
        enabled: id !== null,
        select: (data) => {
          data?.data?.forEach((item) => {
            item.created = moment(item.created).format('MMMM D, YYYY');
            item.computedActions = [0]
          });
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        
      },
    );
}

export function useAddDepartmentToBranch() {
    return useMutation(
        (payload) => {
            return branches.addDepartmentsToBranch(payload);
        },
        {
            onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('branches');
            queryClient.invalidateQueries('branch-departments');
            store.dispatch(setAlert(true, 'success', response.message));
            },
        },
    );
}

export function useRemoveDepartmentFromBranch() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return branches.removeDepartmentFromBranch(payload);
        },
        {
            onSuccess: (response, variables, context) => {
                queryClient.invalidateQueries('branches');
                queryClient.invalidateQueries('branch-departments');
                store.dispatch(setAlert(true, 'success', response.message));
            },
            onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useMoveDepartmentToBranch() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return branches.moveDepartmentToBranch(payload);
        },
        {
            onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('branch-departments');
            store.dispatch(setAlert(true, 'success', response.message));
            },
            onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
            },
        },
    );
}

//Branch Settings
export function useGetBranchSettings(id) {
    return useQuery(
      ['branch-settings', { id }],
      () => {
        store.dispatch(setIsLoading(true));
        return branches.getBranchSettings({
          id
        });
      },
      {
        enabled: id !== null,
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        
      },
    );
}

export function useGenerateBranchAccountDetails() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return branches.generateBranchAccountDetails(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('branch');
        store.dispatch(setAlert(true, 'success', 'Account generated for branch successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useFundBranch() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return branches.fundBranch(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('branches');
        queryClient.invalidateQueries('branch');
        store.dispatch(setAlert(true, 'success', 'Branch Funded successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDebitBranch() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return branches.debitBranch(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('branches');
        queryClient.invalidateQueries('branch');
        store.dispatch(setAlert(true, 'success', 'Branch debited successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}