import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layouts/dashboard";
import { useSelector } from "react-redux";
import UnauthorizedPage from "../errors/unauthorized";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import TransporatationBenefitsPage from "../../components/employee-benefits/transportation";
import EmployeeHMOPage from "../../components/employee-benefits/hmo";

const EmployeeBenefitsPage = () => {
  const { user } = useSelector((state) => state.userSlice);

  const [unauthorized, setUnauthorized] = useState(false);
  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.hmo_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [currentLocation, setCurrentLocation] = useState("hmo");

  const location = useLocation();

  useEffect(() => {
    if (location) {
      setCurrentLocation(location.pathname.split("/")[2]);
    }
  }, [location]);

  return (
    <DashboardLayout title="Employee Benefits">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col">
          {currentLocation === "hmo" ? (
            <>
              <EmployeeHMOPage />
            </>
          ) : currentLocation === "transportation" ? (
            <>
              <TransporatationBenefitsPage />
            </>
          ) : null}
        </div>
      )}
    </DashboardLayout>
  );
};

export default EmployeeBenefitsPage;
