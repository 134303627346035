import React, { useEffect, useState } from 'react';
import {
  useBulkDeleteSalaryReview,
  useDeleteSalaryReview,
} from '../../redux/employees/hook/salary-review';
import { PAGINATION_DEFAULT, salaryReviewStatusConfig } from '../../utils/constants';
import DataTable from '../datatable';
import FormInput from '../form-input';
import ForwardArrowVG from '../../assets/svg/forward-arrow.svg';
import Button from '../button';
import { useGetSalaryReviewsByEmployee } from '../../redux/employees/hook/salary-review';
import { formatNumber } from '../../utils/functions';

const SalaryReviewFolder = ({employeeId}) => {

    const [pagination, setPagination] = useState({
        limit: PAGINATION_DEFAULT.limit,
        page: PAGINATION_DEFAULT.page,
        search: '',
        start_date: '',
        end_date: '',
    });
    
    useEffect(() => {
        setShowSingleReview(false);
      }, []);
    
      const { data: reviews } = useGetSalaryReviewsByEmployee(
        employeeId,
        pagination?.limit,
        pagination?.page,
        pagination?.start_date,
        pagination?.end_date,
        pagination?.search,
      );
    
      const [salaryReviewData, setSalaryReviewData] = useState();
    
      const [showSingleReview, setShowSingleReview] = useState(false);
    
      const { mutateAsync: deleteBulkReviews } = useBulkDeleteSalaryReview();
      const { mutateAsync: deleteReview } = useDeleteSalaryReview();
    
      const tableConfig = {
        //title: 'Basic Payroll Summary',
        headers: ['Date Added', 'Employee Name', 'Mobile Number', 'Effective From', 'Status'],
        keys: ['created', 'employeeName', 'mobile', 'review_start_date', 'status'],
        mobileHeader: {
          left: {
            title: 'Employee Name',
            key: 'employeeName',
          },
          right: {
            title: 'Effective From',
            key: 'review_start_date',
          },
        },
      };
    
      const actionConfig = [
        {
          name: 'View',
          color: 'green',
          action: (id) => {
            let index = reviews.data.findIndex((item) => item.id === id);
            setSalaryReviewData(reviews.data[index]);
            setShowSingleReview(true);
          },
        },
        {
          name: 'Delete',
          color: 'red',
          action: (id) => {
            let index = reviews.data.findIndex((item) => item.id === id);
            deleteReview(reviews.data[index].id);
          },
        },
      ];
    
    const bulkActionConfig = [
        {
            label: 'Delete',
            value: 'delete',
            action: (data) => {
            let newData = data.split(',');
            let result = [];

            newData.forEach((id) => {
                let index = reviews.data.findIndex((item) => item.id == id);
                if (index != -1) {
                result.push(reviews.data[index].id);
                }
            });
            deleteBulkReviews({ ids: result.join(',') });
            },
        },
    ];

  return (
    <div className='flex flex-col'>
        {showSingleReview ? (
          <div className='flex flex-col !min-h-fit md:justify-around  justify-center'>
            <div className=' flex flex-col justify-start gap-4 pr-8 pl-8 w-full'>
              <label className='text-[15px] leading-[23px] font-normal text-color-gray'>
                Employee Name
              </label>
              <span className='header-4'>
                {salaryReviewData?.first_name +
                  ' ' +
                  salaryReviewData?.last_name}
              </span>
            </div>
            <hr className='divider' />
            <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Basic'
                  name='basic'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_basic,2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Basic'
                  name='basic'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_basic,2)}
                  className='!w-[150px]'
                />
              </div>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Housing'
                  name='housing'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_housing,2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Housing'
                  name='housing'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_housing,2)}
                  className='!w-[150px]'
                />
              </div>
            </div>
            <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Transport'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_transport,2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Transportion'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_transport,2)}
                  className='!w-[150px]'
                />
              </div>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Leave'
                  name='leave'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_leave,2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Leave'
                  name='leave'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_leave,2)}
                  className='!w-[150px]'
                />
              </div>
            </div>
            <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Lunch'
                  name='lunch'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_lunch,2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='Old Lunch'
                  name='lunch'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_lunch,2)}
                  className='!w-[150px]'
                />
              </div>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='New Benefit in kind'
                  name='housing'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_benefit_in_kind,2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Benefit in kind'
                  name='housing'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_benefit_in_kind,2)}
                  className='!w-[150px]'
                />
              </div>
            </div>
            <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Utility'
                  name='utility'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_utility,2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Utility'
                  name='utility'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_utility,2)}
                  className='!w-[150px]'
                />
              </div>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Other allowance'
                  name='other'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_other_allowance,2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} alt='to' />
                <FormInput
                  label='New Other allowance'
                  name='other'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_other_allowance,2)}
                  className='!w-[150px]'
                />
              </div>
            </div>
            <div className='flex flex-col md:flex-row justify-start pr-8 pl-8'>
              <div className='flex flex-row md:w-1/2 items-center gap-2'>
                <FormInput
                  label='Old Net Pay'
                  name='net_pay'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.old_net_pay,2)}
                  className='!w-[150px]'
                />
                <img src={ForwardArrowVG} width={15} height={15} alt='to' />
                <FormInput
                  label='New Net Pay'
                  name='net_pay'
                  type='text'
                  readOnly
                  value={formatNumber(salaryReviewData?.new_net_pay,2)}
                />
              </div>
            </div>
            <div className='w-full mt-[20px] pr-8 pl-8'>
              <Button text='Delete Review' type='submit' />
            </div>
            <div
              className='flex justify-center w-full cursor-pointer pb-[20px]'
              onClick={() => setShowSingleReview(false)}
            >
              <span className='p2-medium text-color-black mt-3'>
                Back To Table
              </span>
            </div>
          </div>
        ) : (
          <DataTable
            data={reviews}
            tableConfig={tableConfig}
            actionConfig={actionConfig}
            statusConfig={salaryReviewStatusConfig}
            bulkActionConfig={bulkActionConfig}
            updatePagination={(data) => setPagination(data)}
            computedActions={true}
            searchData={true}
          />
        )}
    </div>
  );
};

export default SalaryReviewFolder;
