import React from 'react';
import CustomersPage from '../../pages/spend-management/customers';
import ProtectedRoute from '../protected';
import InvoicePage from '../../pages/spend-management/invoices/index-theme-one.js';
import ViewCustomerPage from '../../pages/spend-management/customers/view-customer';

export const CustomerRoutes = [
  <ProtectedRoute exact path='/customers' component={CustomersPage} />,
  <ProtectedRoute exact path='/customers/invoice' component={InvoicePage} />,
  <ProtectedRoute exact path='/customers/view/:id' component={ViewCustomerPage} />
] 