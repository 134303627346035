import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import FormTextArea from '../../../form-textarea';
import FormSelectEmployee from '../../../employees/form-select-employee';
import {
  useAddPerformanceEvaluation,
  useGetPerformanceOnboardingWorkflows,
  useGradeBasicPerformance,
} from '../../../../redux/human-resources/hook/performance';
import AddPerformanceWorkflow from './add-workflow';
import LoadingBar from '../../../loader-bar';
import Modal from '../../modal';

function EvaluatePerformance({
  isOpen,
  closeModal,
  title,
  performanceData,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [evaluationType, setEvaluationType] = useState('add');
  const [evaluationId, setEvaluationId] = useState();

  const [workflowId, setWorkflowId] = useState();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [reviewerComment, setReviewerComment] = useState();
  const [employeeComment, setEmployeeComment] = useState();

  // const [workflow, setWorkflow] = useState();
  const [showAddWorkflow, setShowAddWorkflow] = useState();

  const { data: workflowOptions, isLoading: performanceIsLoading } =
    useGetPerformanceOnboardingWorkflows(1000, 1, '');

  useEffect(() => {
    if (performanceData) {
      setEvaluationType('edit');
      setWorkflowId(performanceData.workflow_id);
      setEmployee(performanceData.employee_id);
      setEmployeeName(
        performanceData.first_name + ' ' + performanceData.last_name,
      );
      setEvaluationId(performanceData.id);
      setStartDate(performanceData.evaluation_start);
      setEndDate(performanceData.evaluation_end);
      setSubWorkflow(performanceData.results ? performanceData.results : []);
      setReviewerComment(performanceData.reviewer_comment);
      setEmployeeComment(performanceData.employee_comment);
    } else {
      setEvaluationType('add');
    }
  }, [performanceData]);

  useEffect(() => {
    if (!workflowOptions && !performanceIsLoading) {
      setShowAddWorkflow(true);
    }
  }, [workflowOptions, performanceIsLoading]);

  const {
    mutateAsync: addPerformanceEvaluation,
    isLoading: addPerformanceEvaluationLoading,
  } = useAddPerformanceEvaluation();

  const {
    mutateAsync: editPerformanceEvaluation,
    isLoading: editPerformanceEvaluationLoading,
  } = useGradeBasicPerformance();

  const [subWorkflow, setSubWorkflow] = useState([]);

  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();

  const changeCriteria = (value) => {
    let index = workflowOptions.findIndex((item) => item.value == value);
    if (index !== -1) {
      setWorkflowId(workflowOptions[index].id);
      let subData = [];
      if (
        workflowOptions[index].criteria &&
        workflowOptions[index].criteria.length > 0
      ) {
        workflowOptions[index].criteria.forEach((item) => {
          let newCriteria = {
            name: item.name,
            max_grade: item.max_grade,
            grade: '',
          };
          subData.push(newCriteria);
        });
        setSubWorkflow(subData);
      }
    }
  };

  const submitForm = async () => {
    let payload = {
      employee_id: employee,
      workflow_id: workflowId,
      evaluation_start: startDate,
      evaluation_end: endDate,
      reviewer_comment: reviewerComment,
      result: subWorkflow,
    };
    if (evaluationType === 'add') {
      addPerformanceEvaluation(payload).then(() => {
        closeModal();
      });
    } else {
      let editPayload = {
        id: evaluationId,
        data: payload,
      };
      editPerformanceEvaluation(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <>
      {showAddWorkflow ? (
        <AddPerformanceWorkflow
          isOpen={showAddWorkflow}
          closeModal={() => setShowAddWorkflow(false)}
          groupData={null}
          title='Create Workflow'
          buttonText='SAVE WORKFLOW'
        />
      ) : null}
      <div className='flex flex-col overflow-auto'>
        <form className='form'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>

          <LoadingBar loading={performanceIsLoading} />

          <hr className='divider' />

          {!showAddWorkflow && !performanceIsLoading ? (
            <div className='flex flex-col mx-8 -mt-2'>
              <div>
                <FormSelectEmployee
                  label={'Employee'}
                  name='employee'
                  placeholder='Type to select Employee'
                  value={employee}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
                <span className='text-color-gray'>Evaluation Period</span>
                <div className='flex flex-row md:flex-col w-full justify-between'>
                  <div className='w-full md:w-1/2'>
                    <FormInput
                      name='start_date'
                      type='date'
                      value={startDate}
                      onInput={(e) => {
                        setStartDate(e.target.value);
                      }}
                      inputRef={register(formValidation('date', true))}
                      error={errors.start_date}
                      errorMessage={
                        errors.start_date && errors.start_date.message
                      }
                    />
                  </div>
                  <div className='w-full md:w-1/2 pl-2'>
                    <FormInput
                      name='end_date'
                      type='date'
                      value={endDate}
                      onInput={(e) => {
                        setEndDate(e.target.value);
                      }}
                      inputRef={register(formValidation('date', true))}
                      error={errors.end_date}
                      errorMessage={errors.end_date && errors.end_date.message}
                    />
                  </div>
                </div>
                <hr className='divider mb-2 mt-2' />
                {workflowOptions ? (
                  <div className='w-full '>
                    <FormSelect
                      value={workflowId}
                      options={workflowOptions}
                      onChange={(selected) => {
                        changeCriteria(selected);
                        setWorkflowId(selected);
                      }}
                      label='Select Performance Workflow'
                    />
                  </div>
                ) : null}

                <hr className='divider mb-2 mt-2' />
                <div className='w-full flex flex-row justify-center text-[#0d5225] mb-3'>
                  Evaluation Criteria
                </div>
                {subWorkflow && subWorkflow.length > 0 ? (
                  <div className='flex flex-col w-full text-[13px] '>
                    {subWorkflow.map((item, index) => (
                      <div
                        className='flex flex-row w-full items-center gap-3 justify-between -mt-2'
                        key={index}
                      >
                        <div className='flex flex-col w-[60%]'>
                          <span className='text-[12px] text-color-gray'>
                            Criteria
                          </span>
                          <FormInput
                            placeholder='Review Name'
                            name={index + '_title'}
                            type='text'
                            value={item.name}
                            readOnly
                            className={'text-[13px] max-h-[30px]'}
                            inputRef={register(formValidation('date', true))}
                            error={errors.end_date}
                            errorMessage={
                              errors.end_date && errors.end_date.message
                            }
                          />
                        </div>
                        <div className='flex flex-col w-[20%]'>
                          <span className='text-[14px] text-color-gray'>
                            Max. Grade
                          </span>
                          <FormInput
                            placeholder='Max. Grade'
                            name={index + '_max_grade'}
                            type='number'
                            readOnly
                            value={item.max_grade}
                            inputRef={register(formValidation('number', true))}
                            error={errors.max_grade}
                            className={'text-[13px] max-h-[30px]'}
                            errorMessage={
                              errors.max_grade && errors.max_grade.message
                            }
                          />
                        </div>
                        <div className='w-[20%]'>
                          <span className='text-[14px] text-color-gray'>
                            Grade
                          </span>
                          <FormInput
                            placeholder='Grade'
                            name={index + '_grade'}
                            type='number'
                            className={'text-[13px] max-h-[30px]'}
                            value={item.grade}
                            onInput={(e) => {
                              if (e.target.value > item.max_grade) {
                                let newArray = JSON.parse(
                                  JSON.stringify(subWorkflow),
                                );

                                newArray[index].grade = '';
                                setSubWorkflow(newArray);
                              } else {
                                let newArray = JSON.parse(
                                  JSON.stringify(subWorkflow),
                                );

                                newArray[index].grade = e.target.value;
                                setSubWorkflow(newArray);
                              }
                            }}
                            inputRef={register(
                              formValidation('number', true, 0),
                            )}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span className='w-full italic'>No workflow selected</span>
                )}
                <hr className='divider mt-2' />
                <div className='text-[#0d5225] '>Reviewer Comment</div>
                <FormTextArea
                  name='review_comment'
                  placeholder={'Enter Evaluation Comment'}
                  type='text'
                  value={reviewerComment}
                  onInput={(e) => {
                    setReviewerComment(e.target.value);
                  }}
                  inputRef={register(formValidation('text', true))}
                  error={errors.review_comment}
                  errorMessage={
                    errors.review_comment && errors.review_comment.message
                  }
                />

                {evaluationType !== 'add' ? (
                  <>
                    <div className='text-[#0d5225] '>Employee Comment</div>
                    <pre className='border-1 p-3 rounded !max-h-[400px] overflow-auto'>
                      {employeeComment
                        ? employeeComment
                        : 'No comment posted yet'}
                    </pre>
                  </>
                ) : null}
              </div>
              <div className='w-full mt-[20px] pb-4 '>
                <Button
                  loading={
                    addPerformanceEvaluationLoading ||
                    editPerformanceEvaluationLoading
                  }
                  disabled={!isValid}
                  onClick={() => submitForm()}
                  text={buttonText}
                  type='button'
                />
              </div>
            </div>
          ) : null}
        </form>
      </div>
    </>
  );
}

export default EvaluatePerformance;
