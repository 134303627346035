import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetCabinetEmployee } from "../../redux/employee-folder/hook";
import LoadingBar from "../loader-bar";
import EmptyTableSVG from "../../assets/svg/empty-table.svg";
import moment from "moment";
import { formatNumber } from "../../utils/functions";

const ProfileFolder = ({ employeeId }) => {
  const { user } = useSelector((state) => state.userSlice);

  const { data: employeeData, isLoading } = useGetCabinetEmployee(employeeId);

  return (
    <div className="p-4">
      {isLoading ? (
        <LoadingBar loading={isLoading} />
      ) : (
        <>
          {employeeData ? (
            <div className="flex flex-col gap-4">
              <div className="flex md:flex-row flex-col mt-1 gap-4">
                <div className="flex flex-col md:w-1/2 gap-3">
                  <div className="flex flex-col p-3 border rounded-md">
                    <span className="text-[14px] font-extrabold mb-3 p-2 text-white rounded-[8px] bg-hr-primary-1 max-w-fit">
                      Personal Information
                    </span>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-3 w-full">
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          First Name
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.first_name}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Last name
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.last_name}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Phone Number
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.mobile}
                        </span>
                      </div>

                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Email
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.email}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Date of birth
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {moment(employeeData?.dob).format("MMMM DD, YYYY")}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Gender
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.gender}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Country
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.country}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          State
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.state}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          City
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.city}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Street Address
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.street_address}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col p-3 border rounded-md">
                    <span className="text-[14px] font-extrabold mb-3 p-2 text-white rounded-[8px] bg-hr-primary-1 max-w-fit">
                      Salary Payment information
                    </span>
                    {/* <hr className='divider mt-2' /> */}
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-3 w-full">
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Bank Name
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.bank}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Account Number
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.account_number}
                        </span>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-1  grid-cols-1 w-full mt-4">
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Account Name
                        </span>
                        <span className="text-color-black font-semibold">
                          {employeeData?.account_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:w-1/2 gap-4">
                  <div className="flex flex-col p-3 border rounded-md">
                    <span className="text-[14px] font-extrabold mb-3 p-2 text-white rounded-[8px] bg-hr-primary-1 max-w-fit">
                      Employment information
                    </span>
                    {/* <hr className='divider mt-2' /> */}
                    <div className="flex flex-col">
                      <span className="text-[14px] font-extrabold text-color-gray">
                        Staff Number
                      </span>
                      <span className="text-[13px] text-color-black font-bold">
                        {employeeData?.staff_number}
                      </span>
                    </div>
                    <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full mt-3">
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Date Of Employment
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {moment(employeeData?.date_joined).format(
                            "MMMM DD, YYYY"
                          )}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Job Position
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.job_position
                            ? employeeData?.job_position
                            : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Pension Fund Administrator
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.pfa_name
                            ? employeeData.pfa_name
                            : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Pension R.S.A PIN
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.rsa_pin
                            ? employeeData.rsa_pin
                            : "Not Set"}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Tax ID
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {employeeData?.tax_number
                            ? employeeData.tax_number
                            : "Not Set"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col p-3 border rounded-md">
                    <span className="text-[14px] font-extrabold mb-3 p-2 text-white rounded-[8px] bg-hr-primary-1 max-w-fit">
                      Compensation
                    </span>
                    {/* <hr className='divider mt-2' /> */}
                    <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Basic Salary
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {formatNumber(
                            employeeData.compensation?.basic_salary,
                            2
                          )}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Housing Allowance
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {formatNumber(
                            employeeData.compensation?.housing_allowance,
                            2
                          )}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Transportation Allowance
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {formatNumber(
                            employeeData.compensation?.transportation_allowance,
                            2
                          )}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Lunch Allowances
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {formatNumber(
                            employeeData.compensation?.lunch_allowance,
                            2
                          )}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Leave Allowances
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {formatNumber(
                            employeeData.compensation?.leave_allowance,
                            2
                          )}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Utility Allowances
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {formatNumber(
                            employeeData.compensation?.utility_allowance,
                            2
                          )}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[14px] font-extrabold text-color-gray">
                          Other Allowances
                        </span>
                        <span className="text-[13px] text-color-black font-bold">
                          {formatNumber(
                            employeeData.compensation?.other_allowance,
                            2
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center min-w-full mt-4">
              <img
                src={EmptyTableSVG}
                alt="No Data Fetched"
                width={"40%"}
                height={"400px"}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProfileFolder;
