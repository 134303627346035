import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { nhfApis } from './../../../api/payroll/statutory-compliance/nhf';
import moment from 'moment';
import { formatNumber } from '../../../utils/functions';

export function useGetNhfHistory(limit, page, statusFilter, search) {
  return useQuery(
    ['nhf', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return nhfApis.getNHF({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((nhf) => {
          nhf.created = moment(nhf.created).format('MMMM DD, YYYY');
          nhf.month = moment(nhf.month).format('MMMM, YYYY');
          nhf.status = parseFloat(nhf.nhf_status)
          if (nhf.status === 2) {
            nhf.status = 0
          }
          switch (nhf.status) {
            case 0:
              nhf.computedActions = [0];
              break;
            case 1:
              nhf.computedActions = [1];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useUploadNHF() {
  return useMutation(
    (payload) => {
      return nhfApis.bulkNHF(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('nhf');
        store.dispatch(setAlert(true, 'success', 'Upload Successful'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRefNHF(refId, limit, page, search) {
  return useQuery(
    ['nhf-by-ref', { refId, limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return nhfApis.fetchNHFByRef({refId, limit, page, search});
    },
    {
      select: (data) => {
        
        data?.data?.forEach((item) => {
          item.computedActions = [0]
          item.employeeName = `${item.first_name} ${item.last_name}`;
          if (!item.status) {
            item.status = 0
          }
          item.table_nhf_amount = formatNumber(parseFloat(item.amount.toFixed(2)), 2);
        });
        if (data) {
          data.formatted_total_amount = formatNumber(parseFloat(data?.total_amount.toFixed(2)), 2);
          data.formatted_total_trans_cost = formatNumber(parseFloat(data?.total_trans_cost.toFixed(2)), 2);
          data.formatted_total_payable = formatNumber(parseFloat(data?.total_payable.toFixed(2)), 2);
        }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}