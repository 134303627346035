import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import {
  useDeleteLeaveType,
  useGetLeaveTypes,
} from "../../../redux/human-resources/hook/leave";
import AddLeaveType from "../../modal/hr-section/leave/add-leave-type";
import Button from "../../button";

const HRLeaveTypeTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: leave_types } = useGetLeaveTypes(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const history = useHistory();
  const [leavetypeData, setLeaveTypeData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const [tableType, setTableType] = useState("leave-type");

  const switchFunction = () => {
    if (tableType === "leave-type") {
      setTableType("table");
    } else {
      setTableType("leave-type");
    }
  };
  const [showAddLeaveType, setShowAddLeaveType] = useState();

  const { mutate: deleteLeaveType, isLoading } = useDeleteLeaveType();

  const tableConfig = {
    headers: ["Leave Name", "Annual Days", "Max Application Days"],
    keys: ["name", "max_annual_days", "max_appplication_days"],
    mobileHeader: {
      left: {
        title: "Leave Name",
        key: "employeeName",
      },
      right: {
        title: "Annual Days",
        key: "annual_days",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "green",
      action: (id) => {
        let index = leave_types.data.findIndex((item) => item.id === id);
        setTitle("Update Leave Type");
        setButtonText("Update Leave Type");
        setLeaveTypeData(leave_types.data[index]);
        setShowAddLeaveType(true);
      },
    },
    {
      name: "Remove",
      color: "orange",
      action: (id) => {
        deleteLeaveType(id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddLeaveType ? (
        <AddLeaveType
          isOpen={showAddLeaveType}
          closeModal={() => setShowAddLeaveType(false)}
          leaveTypeData={leavetypeData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Leave Types
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Leave Type"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setLeaveTypeData(null);
                setTitle("Add Leave Type");
                setButtonText("Save Leave Type");
                setShowAddLeaveType(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={leave_types}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        tableType={tableType}
        switchFunction={switchFunction}
        layoutSwitch={true}
        searchData={true}
        noCheck={true}
      />
    </div>
  );
};

export default HRLeaveTypeTab;
