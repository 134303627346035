import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import FormInput from '../../form-input';
import Modal from '../modal';
import { formValidation } from '../../../utils/functions';
import { useAddFeedback, useAddTicket } from '../../../redux/contact-us/hook';
import DOMPurify from 'dompurify';
import { Editor } from '@tinymce/tinymce-react';

function FeedbackModal({ isOpen, closeModal, refresh }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
  const [body, setBody] = useState();

  const { mutateAsync: sendFeedback, isLoading: sendFeedbackLoading } =
    useAddFeedback();

  const submitForm = async (data) => {
    let payload = {
      subject: DOMPurify.sanitize(data?.subject),
      feedback: body.toString(),
    };

    await sendFeedback(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col pb-4 overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>Send Customer Feedback</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col mx-8'>
          <form className='form' onSubmit={handleSubmit(submitForm)}>
            <FormInput
              label='Subject'
              name='subject'
              type='text'
              inputRef={register(formValidation('text', true))}
              placeholder={'Enter support ticket subject'}
              error={errors.subject}
              errorMessage={errors.subject && errors.subject.message}
            />
            <div className='flex md:flex-row flex-col w-full justify-between mb-2'>
              <div className='w-full'>
                <span className='text-[13px] text-gray-500'>Message</span>
                <Editor
                  apiKey={import.meta.env.VITE_APP_TINYMCE_API_KEY}
                  onEditorChange={(editor) => {
                    setBody(editor);
                  }}
                  placeholder='Enter Email body here'
                  value={body}
                  init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
                  }}
                />
              </div>
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text={'SUBMIT TICKET'}
                type='submit'
                loading={sendFeedbackLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default FeedbackModal;
