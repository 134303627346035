import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../components/button";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useCheckTransactionStatus } from "../../../redux/payroll/hook";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { useSelector } from "react-redux";
import {
  isLoading,
  setIsLoading,
} from "../../../redux/components/components-slice";
import VendorPayDetailedView from "../../../components/modal/spend-management/vendor/vendor-pay-detailed-view";
import {
  useRefVendorPayView,
  useRevertVendor,
} from "../../../redux/spend-management/vendor/hook";
import ExportVendorStub from "../../../components/modal/spend-management/vendor/payment-advice";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import Logo from "../../../components/logo";
import moment from "moment";
import { store } from "../../../redux";

const VendorPayViewPage = () => {
  useEffect(() => {}, []);

  const loading = useSelector(isLoading);
  const { company } = useSelector((state) => state.companySlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const history = useHistory();

  const { mutateAsync: revertVendor } = useRevertVendor();

  const [showVendorDetailedView, setShowVendorDetailedView] = useState(false);
  const [showVendorPayStub, setShowVendorPayStub] = useState(false);

  const [previewData, setPreviewData] = useState();

  const { refId } = useParams();
  const { data: vendorPay } = useRefVendorPayView(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  useEffect(() => {
    if (vendorPay) {
      if (loading === false && vendorPay.data.length < 1) {
        revertVendor().then(() => {
          history.push("/vendors", { tab: 2 });
        });
      }
    }
  }, []);

  const { mutateAsync: checkTransactionStatus } = useCheckTransactionStatus();

  const tableConfig = {
    headers: ["Vendor Name", "Bank", "Amount", "Status"],
    keys: ["vendor_name", "bank_name", "net_pay", "status"],
    mobileHeader: {
      left: {
        title: "Vendor Name",
        key: "vendor_name",
      },
      right: {
        title: "Amount",
        key: "net_pay",
      },
    },
  };

  const actionConfig = [
    {
      name: "Check Status",
      color: "orange",
      action: async (id) => {
        let index = vendorPay.data.findIndex((item) => item.id === id);
        await checkTransactionStatus(vendorPay.data[index].id);
      },
    },
    {
      name: "Details",
      color: "green",
      action: (id) => {
        let index = vendorPay.data.findIndex((item) => item.id === id);
        setPreviewData(vendorPay.data[index]);
        setShowVendorDetailedView(true);
      },
    },
    {
      name: "Download Receipt",
      color: "orange",
      action: (id) => {
        let index = vendorPay.data.findIndex((item) => item.id === id);
        if (index !== -1) {
          printHandler(index);
        }
      },
    },
  ];

  const statusConfig = [
    {
      label: "Pending",
      color: "orange",
      value: 0,
    },
    {
      label: "Successful",
      color: "green",
      value: 1,
    },
    {
      label: "Failed",
      color: "red",
      value: 1,
    },
  ];

  const pdfJSX = (index) => {
    return (
      <div className="flex flex-col p-8 pb-4 rounded justify-center items-center bg-gray-100 h-fit w-full">
        <div className="rounded p-4 px-2 bg-white min-w-[100%] h-full">
          <div>
            {/* PDF CONTENT */}
            <div className="flex flex-col w-full items-center px-5">
              <div className="flex flex-row items-center justify-center gap-2 mb-2">
                <a
                  href="https://www.ropay.africa"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Logo />
                </a>
              </div>
              <div className="flex flex-row justify-between w-[100%] h-fit mb-4">
                <div className="flex flex-col !max-w-[50%]">
                  <div className="flex flex-col !max-w-fit">
                    <div className="header-6">FROM:</div>
                    <div className="header-4">{company?.company_name}</div>
                  </div>
                  <div className="flex flex-col text-[14px] text-color-gray mb-1 mt-2">
                    {company?.address}, {company?.city},<br />
                    {company?.state},<br />
                    {company?.country}.
                  </div>
                  <div className="flex flex-col text-[14px] text-gray-500">
                    {company?.mobile}
                  </div>
                </div>
                <div className="flex flex-col !max-w-[50%]">
                  <div className="flex flex-col !max-w-fit">
                    <div className="header-6">PAYMENT ADVICE</div>
                    <div className="header-6 mb-3 text-color-gray">
                      Vendor Payment
                    </div>
                    <div className="header-6">To:</div>
                    <div className="header-4">
                      {vendorPay.data[index]?.vendor_name}
                    </div>
                  </div>
                  <div className="flex flex-col text-[13px] text-color-gray mb-1 mt-2">
                    {vendorPay.data[index]?.employee_email}
                  </div>
                  <div className="flex flex-col text-[14px] text-gray-500">
                    {vendorPay.data[index]?.employee_phone}
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-end w-[100%] h-fit rounded">
                <div className="flex flex-row items-center justify-end !min-w-[500px]">
                  <div className="!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600">
                    Processed Date:
                  </div>
                  <div className="!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600">
                    {moment(vendorPay.data[index]?.processed_date).format(
                      "MMMM DD, YYYY"
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end !min-w-[500px]">
                  <div className="!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600">
                    Reference:
                  </div>
                  <div className="!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600">
                    {vendorPay.data[index]?.ref}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end !min-w-[500px]">
                  <div className="!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600">
                    Account number:
                  </div>
                  <div className="!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600">
                    {vendorPay.data[index]?.account_number}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end !min-w-[500px]">
                  <div className="!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600">
                    Beneficiary Bank:
                  </div>
                  <div className="!min-w-[200px] header-6 p-2 bg-gray-100 pr-4 pl-4 border border-gray-600">
                    {vendorPay.data[index]?.bank_name}
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-end w-full h-fit mt-8 rounded">
                <div className="flex flex-row w-full min-h-[40px]">
                  <div className="w-1/5 header-6 p-2 bg-gray-100 pr-4 pl-4 pb-3">
                    Date
                  </div>
                  <div className="w-2/5 header-6 p-2 bg-gray-100 pr-4 pl-4 pb-3">
                    description
                  </div>
                  <div className="w-1/5 header-6 p-2 bg-gray-100 pr-4 pl-4 pb-3">
                    Amount
                  </div>
                  <div className="w-1/5 header-6 p-2 bg-gray-100 pr-4 pl-4"></div>
                </div>
                <div className="flex flex-row w-full border border-gray-300 min-h-[40px]">
                  <div className="w-1/5 header-6 p-2 bg-white pr-4 pl-4 pb-3">
                    {moment(vendorPay.data[index]?.processed_date).format(
                      "MMMM DD, YYYY"
                    )}
                  </div>
                  <div className="w-2/5 header-6 p-2 bg-white pr-4 pl-4 pb-3">
                    {vendorPay.data[index]?.description}
                  </div>
                  <div className="w-1/5 header-6 p-2 bg-white pr-4 pl-4 pb-3">
                    {vendorPay.data[index]?.total}
                  </div>
                  {vendorPay.data[index]?.status == 1 ? (
                    <div className="w-1/5 header-6 text-[13px] p-2 bg-white pr-4 pl-4 text-green-500 pb-3">
                      Successful
                    </div>
                  ) : vendorPay.data[index]?.status == 2 ? (
                    <div className="w-1/5 header-6 text-[13px] p-2 bg-white pr-4 pl-4 text-red-600 pb-3">
                      Failed
                    </div>
                  ) : vendorPay.data[index]?.status == 0 ? (
                    <div className="w-1/5 header-6 text-[13px] p-2 bg-white pr-4 pl-4 text-orange-500 pb-3">
                      Pending
                    </div>
                  ) : (
                    <div className="w-1/5 header-6 text-[13px] p-2 bg-white pr-4 pl-4 text-red-600 pb-3">
                      Retried:failed
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center w-full h-fit mt-4 italic text-primary-2 text-[13px]">
                <a
                  href="https://www.ropay.africa"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.ropay.africa
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const printHandler = (index) => {
    store.dispatch(setIsLoading(true));
    const printElement = ReactDOMServer.renderToString(pdfJSX(index));
    var opt = {
      margin: 0,
      filename: vendorPay.data[index]?.ref + " - payment-advice.pdf",
      image: { type: "", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(opt).from(printElement).save();
    store.dispatch(setIsLoading(false));
  };

  return (
    <DashboardLayout title="Vendor Payment View">
      {previewData && showVendorDetailedView ? (
        <VendorPayDetailedView
          isOpen={showVendorDetailedView}
          closeModal={() => setShowVendorDetailedView(false)}
          viewData={previewData}
        />
      ) : null}
      {previewData && showVendorPayStub ? (
        <ExportVendorStub
          isOpen={showVendorPayStub}
          closeModal={() => setShowVendorPayStub(false)}
          viewData={previewData}
        />
      ) : null}

      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <span className="h4 text-color-black">
              Vendor Payment Breakdown
            </span>
            <span className="p3 text-color-gray">
              View processed Vendor Payment
            </span>
          </div>

          <div className="w-full md:w-[220px] mt-3 md:mt-0 h-[56px]">
            <Button
              text="Export"
              type="submit"
              loading={false}
              disabled={false}
              className="h-[56px]"
              onClick={() => setShowVendorPayStub(true)}
            />
          </div>
        </div>

        <div className="mt-9">
          <DataTable
            data={vendorPay}
            tableConfig={tableConfig}
            actionConfig={actionConfig}
            updatePagination={(data) => setPagination(data)}
            statusConfig={statusConfig}
            computedActions={true}
            filterByStatus={true}
            searchData={true}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default VendorPayViewPage;
