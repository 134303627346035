import DOMPurify from 'dompurify';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import DragSVG from '../../../../assets/svg/reorder.svg';
import AddSmallSVG from '../../../../assets/svg/add-icon-small.svg';
import { store } from '../../../../redux';
import { setAlert } from '../../../../redux/components/components-slice';
import FormSwitch from '../../../form-switch';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RemoveSVG from '../../../../assets/svg/remove.svg';
import {
  useAddOnboardingSubWorkflow,
  useUpdateOnboardingSubWorkflow,
} from '../../../../redux/employees/hook/onboarding';
import FormSelect from '../../../form-select';
import FormFileInput from '../../../form-file-input';
import Modal from '../../modal';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  paddingRight: 16,
  paddingLeft: 16,
  margin: `0 0 12px 0`,
  // borderRadius: '5px',
  // border: '1px solid #E5E4E2',

  // change background colour if dragging
  background: 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: 'whitesmoke',
  padding: grid,
  width: '100%',
});

function AddOnboardingSubWorkflow({
  isOpen,
  closeModal,
  workflowData,
  subWorkflowData,
  title,
  buttonText,
}) {
  //Questions
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState();
  const [btnState, setBtnState] = useState('');

  const [askQuestions, setAskQuestions] = useState(false);

  const [questions, setQuestions] = useState([
    [
      {
        id: `item-${1}`,
        question: '',
        question_type: 'multiple_choice',
        options: [{ option: 'Option 1' }, { option: 'Option 2' }],
      },
    ],
  ]);

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(questions[sInd], source.index, destination.index);
      const newState = [...questions];
      newState[sInd] = items;
      setQuestions(newState);
    } else {
      const result = move(
        questions[sInd],
        questions[dInd],
        source,
        destination,
      );
      const newState = [...questions];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setQuestions(newState.filter((group) => group.length));
    }
  }

  const QuestionTypes = [
    { label: 'Multiple Choice', value: 'multiple_choice' },
    { label: 'Open Ended', value: 'open_ended' },
  ];

  const [maxId, setMaxId] = useState(1);

  const defaultQuestion = [
    [
      {
        id: `item-${maxId + 1}`,
        question: '',
        question_type: 'multiple_choice',
        options: [{ option: 'Option 1' }, { option: 'Option 2' }],
      },
    ],
  ];

  const addQuestion = () => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    if (newArray.length < 10) {
      newArray = [
        ...newArray,
        ...[
          {
            id: `item-${maxId + 1}`,
            question: '',
            question_type: 'multiple_choice',
            options: [{ option: 'Option 1' }, { option: 'Option 2' }],
          },
        ],
      ];
      setMaxId(maxId + 1);
      setQuestions([newArray]);
    }
  };

  const addOption = (index) => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    let data = [...newArray];
    if (data[index].options.length < 4) {
      // only splice array when item is found
      data[index].options.push({ option: '' }); // 2nd parameter means remove one item only
      setQuestions([[...data]]);
    }
  };

  const removeQuestion = (subIndex) => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    let data = [...newArray];
    if (subIndex > -1) {
      // only splice array when item is found
      data.splice(subIndex, 1); // 2nd parameter means remove one item only
      setQuestions([[...data]]);
    }
  };

  const removeOption = (subIndex, optionIndex) => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    let data = [...newArray];
    if (data[subIndex].options.length > 2) {
      if (subIndex > -1 && optionIndex > -1) {
        // only splice array when item is found
        data[subIndex].options.splice(optionIndex, 1); // 2nd parameter means remove one item only
        setQuestions([[...data]]);
      }
    } else {
      store.dispatch(
        setAlert(true, 'error', 'There must be at least 2 options'),
      );
    }
  };

  const [workflowType, setWorkflowType] = useState('add');

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const defaultSubWorkflow = {
    workflow_id: workflowData?.id,
    description: '',
    name: '',
    upload_file: false,
    filename: '',
    collect_document: false,
    document_name: '',
  };

  const [subWorkflow, setSubWorkflow] = useState(defaultSubWorkflow);

  useEffect(() => {
    if (subWorkflowData) {
      let newsubflow = {
        workflow_id: subWorkflowData.workflow_id,
        description: subWorkflowData.description,
        name: subWorkflowData.name,
        upload_file: subWorkflowData.upload_file == 1 ? true : false,
        filename: subWorkflowData.filename,
        collect_document: subWorkflowData.collect_document == 1 ? true : false,
        document_name: subWorkflowData.document_name,
      };
      setSubWorkflow(newsubflow);
      setWorkflowType('edit');
    }
  }, [subWorkflowData]);

  const { mutateAsync: addSubWorkflow, isLoading: addWorkflowloading } =
    useAddOnboardingSubWorkflow();
  const { mutateAsync: editSubWorkflow, isLoading: editWorkflowloading } =
    useUpdateOnboardingSubWorkflow();

  const submitForm = async (data) => {

    if (workflowType === 'add') {
      const formData = new FormData();
      if (data && data.file) {
        formData.append('file', data?.file[0]);
      }
      formData.append('workflow_id', subWorkflow?.workflow_id);
      formData.append('name', subWorkflow?.name);
      formData.append('description', subWorkflow?.description);
      formData.append('upload_file', subWorkflow?.upload_file ? 1 : 0);
      formData.append('filename', subWorkflow?.filename);
      formData.append(
        'collect_document',
        subWorkflow?.collect_document ? 1 : 0,
      );
      formData.append('document_name', subWorkflow?.document_name);
      if (askQuestions) {
        let newQuestions = [];
        questions[0].forEach((item, index) => {
          if (item.question !== null && item.question !== '') {
            if (item.question_type === 'open_ended') {
              item.options = [];
              newQuestions.push(item);
            } else {
              if (item.options.length > 1) {
                newQuestions.push(item);
              }
            }
          }
        });
        if (newQuestions.length > 0) {
          formData.append('questions', JSON.stringify(newQuestions));
        } else {
          formData.append('questions', JSON.stringify([]));
        }
      } else {
        formData.append('questions', JSON.stringify([]));
      }
      await addSubWorkflow(formData).then(() => {
        if (btnState === 'another') {
          setQuestions(defaultQuestion);
          setSubWorkflow(defaultSubWorkflow);
        } else {
          closeModal();
        }
      });
    } else if (workflowType === 'edit') {
      const formData = new FormData();

      if (data) {
        formData.append('file', data?.file[0]);
      }
      formData.append('workflow_id', subWorkflow?.workflow_id);
      formData.append('name', subWorkflow?.name);
      formData.append('description', subWorkflow?.description);
      formData.append('upload_file', subWorkflow?.upload_file);
      formData.append('filename', subWorkflow?.upload_file);
      formData.append('collect_document', subWorkflow?.collect_document);
      formData.append('document_name', subWorkflow?.document_name);
      if (askQuestions) {
        let newQuestions = [];
        questions[0].forEach((item, index) => {
          if (item.question !== null && item.question !== '') {
            if (item.question_type === 'open_ended') {
              item.options = [];
              newQuestions.push(item);
            } else {
              if (item.options.length > 1) {
                newQuestions.push(item);
              }
            }
          }
        });
        if (newQuestions.length > 0) {
          formData.append('questions', JSON.stringify(newQuestions));
        } else {
          formData.append('questions', JSON.stringify([]));
        }
      } else {
        formData.append('questions', JSON.stringify([]));
      }

      let editPayload = {
        subworkflow_id: subWorkflowData.id,
        data: formData,
      };
      await editSubWorkflow(editPayload).then(() => {
        if (btnState === 'another') {
          setQuestions(defaultQuestion);
          setSubWorkflow(defaultSubWorkflow);
        } else {
          closeModal();
        }
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-6' onSubmit={handleSubmit(submitForm)}>
          <div className='flex flex-col justify-center pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>

              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
            <span className='p3 text-[13px] text-gray-500'>
              Add Onboarding Stages. Drag and Drop to change order.
            </span>
          </div>
          <hr className='divider mb-2 mt-2' />
          <div className='flex flex-col max-h-fit pr-8 pl-8'>
            <div className='flex flex-col p-3 border bg-highlight rounded-md'>
              <div className='header-4 text-color-gray'>Workflow Name</div>
              <div className='text-[14px] font-semibold'>{workflowData?.name}</div>
            </div>
            <hr className='divider mt-2 mb-2' />
            <div className='flex flex-col p-3 border bg-highlight rounded-md'>
              <div className='header-4  text-color-gray'>
                Onboarding Stage Information
              </div>
              <div className='flex rounded-[5px]'>
                <div className='w-full'>
                  <div className='flex flex-col justify-around flex-wrap'>
                    <FormInput
                      name={'stage_name'}
                      placeholder={'Stage Name'}
                      type='text'
                      readOnly={false}
                      value={subWorkflow.name}
                      onInput={(e) => {
                        let newArray = JSON.parse(JSON.stringify(subWorkflow));

                        newArray.name = e.target.value;
                        setSubWorkflow(newArray);
                      }}
                      className='!h-[30px] mr-4 text-[13px] w-[60%] bg-white'
                    />
                    <FormInput
                      name={'stage_description'}
                      placeholder={'Stage Description'}
                      type='text'
                      readOnly={false}
                      value={subWorkflow.description}
                      onInput={(e) => {
                        let newArray = JSON.parse(JSON.stringify(subWorkflow));

                        newArray.description = e.target.value;
                        setSubWorkflow(newArray);
                      }}
                      className='!h-[30px] mr-4 text-[13px] bg-white'
                    />
                    <hr className='divider mt-2 ' />
                    <div className='flex flex-row  justify-between items-center md:w-[100%] w-full'>
                      <div className='w-full md:w-[100%] text-[14px] -mb-3'>
                        <FormSwitch
                          label={
                            'Do you want to upload document? (Company Handbook, leave ploicy...)'
                          }
                          name={'upload_file'}
                          checked={subWorkflow.upload_file}
                          onClick={() => {
                            let newArray = JSON.parse(
                              JSON.stringify(subWorkflow),
                            );

                            newArray.upload_file = !subWorkflow.upload_file;
                            setSubWorkflow(newArray);
                          }}
                        />
                      </div>
                    </div>
                    {subWorkflow.upload_file ? (
                      <>
                        <FormInput
                          name={'file_name'}
                          placeholder={'Enter File Name'}
                          type='text'
                          readOnly={false}
                          value={subWorkflow.filename}
                          onInput={(e) => {
                            let newArray = JSON.parse(
                              JSON.stringify(subWorkflow),
                            );

                            newArray.filename = e.target.value;
                            setSubWorkflow(newArray);
                          }}
                          className='!h-[30px] mr-4 text-[13px] w-[100%] bg-white'
                        />
                        <FormFileInput
                          multiSource={false}
                          name={'file'}
                          inputRef={register(formValidation('file', true))}
                          accept='.pdf,.jpg,.png,.jpeg'
                        />
                      </>
                    ) : null}
                    <hr className='divider mt-2 ' />
                    <div className='flex flex-row  justify-between items-center md:w-[100%] w-full'>
                      <div className='w-full md:w-[100%] text-[15px] -mb-3'>
                        <FormSwitch
                          label={'Do you want to collect document from employee?'}
                          name={'collect_document'}
                          checked={subWorkflow.collect_document}
                          onClick={() => {
                            let newArray = JSON.parse(
                              JSON.stringify(subWorkflow),
                            );

                            newArray.collect_document =
                              !subWorkflow.collect_document;
                            setSubWorkflow(newArray);
                          }}
                        />
                      </div>
                    </div>
                    {subWorkflow.collect_document ? (
                      <>
                        <FormInput
                          name={'document_name'}
                          placeholder={'Enter File Name'}
                          type='text'
                          readOnly={false}
                          value={subWorkflow.document_name}
                          onInput={(e) => {
                            let newArray = JSON.parse(
                              JSON.stringify(subWorkflow),
                            );

                            newArray.document_name = e.target.value;
                            setSubWorkflow(newArray);
                          }}
                          className='!h-[30px] mr-4 text-[13px] w-[60%] bg-white'
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <hr className='divider mt-2 mb-4' />
            <div className='flex flex-row  justify-between items-center md:w-[100%] w-full'>
              <div className='w-full md:w-[100%] text-[14px] -mb-3'>
                <FormSwitch
                  label={'Do you want to ask employee assessment questions?'}
                  name={'ask_question'}
                  checked={askQuestions}
                  onClick={() => {
                    setAskQuestions(!askQuestions);
                  }}
                />
              </div>
            </div>
            {askQuestions ? (
              <div className='flex flex-col'>
                <div className='flex rounded-[5px]'>
                  <DragDropContext onDragEnd={onDragEnd}>
                    {questions?.map((el, ind) => (
                      <Droppable key={ind} droppableId={`${ind}`}>
                        {(provided, snapshot) => (
                          <div
                            className='p-4 '
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                          >
                            {el.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className='border-r-4 border-r-primary-3 rounded-[8px] pt-2'
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                    )}
                                  >
                                    <div className='flex flex-row justify-between items-center'>
                                      <span className='font-bold italic text-primary-3'>
                                        Question {index + 1}
                                      </span>
                                      <div
                                        className='text-red-500 text-[12px] hover:font-semibold cursor-pointer hover:italic'
                                        onClick={() => {
                                          removeQuestion(index);
                                        }}
                                      >
                                        Remove
                                      </div>
                                    </div>

                                    <div className='flex md:flex-row flex-col justify-between md:items-center !w-[100%]'>
                                      <div className='flex flex-row items-center justify-start gap-3 md:w-2/3 w-full'>
                                        <FormInput
                                          name={index + '_question'}
                                          placeholder={'Enter Question?'}
                                          type='text'
                                          value={item.question}
                                          onInput={(e) => {
                                            let newArray = JSON.parse(
                                              JSON.stringify(questions[ind]),
                                            );

                                            newArray[index].question =
                                              e.target.value;
                                            setQuestions([newArray]);
                                          }}
                                          className='!h-[30px] md:w-[120%] w-[150%] !text-[13px] border-1 border-gray-300'
                                        />
                                      </div>
                                      <div className='flex flex-row md:justify-end items-center gap-3 md:w-1/3 w-full'>
                                        <FormSelect
                                          value={item.question_type}
                                          options={QuestionTypes}
                                          name={item.question_type}
                                          onChange={(selected) => {
                                            let newArray = JSON.parse(
                                              JSON.stringify(questions[ind]),
                                            );
                                            newArray[index].question_type =
                                              selected;
                                            setQuestions([newArray]);
                                          }}
                                          className='!min-h-[32px] border-1 mt-1 !min-w-[100%] rounded text-[13px]'
                                        />
                                      </div>
                                    </div>
                                    <div className='flex flex-col items-start justify-start w-full'>
                                      {item.question_type ===
                                      'multiple_choice' ? (
                                        <div className='flex flex-col w-full'>
                                          {item.options &&
                                          item.options.length ? (
                                            <span className='italic text-hr-primary-1 mb-3 text-[12px]'>
                                              Options
                                            </span>
                                          ) : null}
                                          {item.options &&
                                            item.options.length > 0 &&
                                            item.options.map(
                                              (subItem, subIndex) => (
                                                <div
                                                  className='flex flex-row justify-start items-center'
                                                  key={subIndex}
                                                >
                                                  <div className='flex flex-row items-center -mt-4'>
                                                    <span className='italic mr-4 text-[13px] font-bold'>
                                                      {subIndex + 1}.
                                                    </span>
                                                    <FormInput
                                                      name={
                                                        subIndex + '_option'
                                                      }
                                                      placeholder={
                                                        'Enter Option'
                                                      }
                                                      type='text'
                                                      defaultValue={
                                                        subItem.option
                                                      }
                                                      onInput={(e) => {
                                                        let newArray =
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              questions[0],
                                                            ),
                                                          );

                                                        newArray[index].options[
                                                          subIndex
                                                        ].option =
                                                          e.target.value;
                                                        setQuestions([
                                                          newArray,
                                                        ]);
                                                      }}
                                                      className='!h-[30px] w-[250px] !text-[13px] border-1 border-gray-300'
                                                    />
                                                    <img
                                                      src={RemoveSVG}
                                                      alt={'remove'}
                                                      className={
                                                        'h-[20px] w-[20px] ml-3 cursor-pointer'
                                                      }
                                                      onClick={() => {
                                                        removeOption(
                                                          index,
                                                          subIndex,
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              ),
                                            )}
                                          {item.options.length < 4 ? (
                                            <span
                                              className='text-[13px] italic text-[#0d5225] cursor-pointer hover:underline hover:text-color-gray duration-75 mb-2'
                                              onClick={() => addOption(index)}
                                            >
                                              Add Option (Max. 4)
                                            </span>
                                          ) : null}
                                        </div>
                                      ) : (
                                        <div className='mb-2'></div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    ))}
                  </DragDropContext>
                </div>
                <div className='flex flex-row items-center gap-2 mt-3 justify-left w-full cursor-pointer'>
                  <img
                    src={AddSmallSVG}
                    alt={'add_question'}
                    className={'h-[15px] w-[15px]'}
                  />
                  <span
                    className='p4-medium hover:underline hover:italic hover:font-bold text-primary-3 duration-75'
                    onClick={() => addQuestion()}
                  >
                    Add New Question (Max. 10)
                  </span>
                </div>
              </div>
            ) : null}

            <div className=' flex md:flex-row flex-col w-full mt-4 gap-4'>
              <Button
                text={'SAVE AND ADD ANOTHER'}
                type='submit'
                theme='primary'
                className='!h-[45px] px-3 !w-[fit]'
                onClick={() => setBtnState('another')}
                textClass={'!text-[11px]'}
                loading={addWorkflowloading || editWorkflowloading}
              />

              <Button
                text={'SAVE AND COMPLETE'}
                type='submit'
                theme='primary'
                className='!h-[45px] px-3 !w-[fit]'
                onClick={() => setBtnState('complete')}
                textClass={'!text-[11px]'}
                loading={addWorkflowloading || editWorkflowloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddOnboardingSubWorkflow;
