import React from 'react';
import Logout from '../../pages/logout';
import DocumentsPage from '../../pages/documents';
import SettingsPage from '../../pages/settings';
import WalletPage from '../../pages/wallet';
import ProtectedRoute from '../protected';
import ActivityLogPage from '../../pages/logs';
import RochatPage from '../../pages/ro-chat';
import ReferralsPage from '../../pages/referrals';
import EmployeeBenefitsPage from '../../pages/employee-benefits';
import ViewHMOGroupPage from '../../pages/employee-benefits/view-hmo-group';
import AddHMOSettings from '../../pages/employee-benefits/hmo-settings';

export const NonSectionRoutes = [
    <ProtectedRoute exact path='/wallet' component={WalletPage} />,
    <ProtectedRoute exact path='/document' component={DocumentsPage} />,
    <ProtectedRoute exact path='/employee-benefits/hmo/group/view/:id' component={ViewHMOGroupPage} />,
    <ProtectedRoute exact path='/employee-benefits/hmo/add-settings' component={AddHMOSettings} />,
    <ProtectedRoute exact path='/employee-benefits/hmo' component={EmployeeBenefitsPage} />,
    <ProtectedRoute exact path='/employee-benefits/transportation' component={EmployeeBenefitsPage} />,
    <ProtectedRoute exact path='/employee-benefits/settings' component={EmployeeBenefitsPage} />,
    <ProtectedRoute exact path='/settings/admin-management' component={SettingsPage} />,
    <ProtectedRoute exact path='/settings/company-information' component={SettingsPage} />,
    <ProtectedRoute exact path='/settings/payroll-settings' component={SettingsPage} />,
    <ProtectedRoute exact path='/settings/approval-settings' component={SettingsPage} />,
    <ProtectedRoute exact path='/settings/plans-billings' component={SettingsPage} />,
    <ProtectedRoute exact path='/settings/integrations' component={SettingsPage} />,
    <ProtectedRoute exact path='/referrals' component={ReferralsPage} />,
    <ProtectedRoute exact path='/settings/integrations:integrationtype' component={SettingsPage} />,
    <ProtectedRoute exact path='/ro-chat/chats' component={RochatPage} />,
    <ProtectedRoute exact path='/ro-chat/settings' component={RochatPage} />,
    <ProtectedRoute exact path='/logs' component={ActivityLogPage} />,
    <ProtectedRoute exact path='/logout' component={Logout} />,
]