import React from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import Modal from '../modal';
import { formatNumber } from '../../../utils/functions';
import { useGetSalaryAdvanceReport } from '../../../redux/employees/hook/salary-advance';
import LoadingBar from '../../loader-bar';

function SalaryAdvanceSummary({
  isOpen,
  closeModal,
}) {
    const { data: salary_advance, isLoading } = useGetSalaryAdvanceReport();
  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] selection: h-full !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col item overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>Quick Summary</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        {isLoading?
        <LoadingBar loading={isLoading} />
        : salary_advance ?
        <div className='flex flex-col mx-8 pb-4'>
          <div className='flex flex-col'>
            <div className='flex flex-row items-center justify-between pt-22'>
              <span className='stat-card-header '>
                TOTAL SALARY ADVANCES
              </span>
              <span className='p4-medium text-color-black font-bold'>
                {formatNumber((
                  parseFloat(salary_advance?.total_advance).toFixed(2)
                ), 2)}
              </span>
            </div>
            <hr className='divider mt-4 mb-4' />
            <div className='flex flex-row items-center justify-between pt-2'>
              <span className='stat-card-header pb-1'>TOTAL SALARY ADVANCES (REPAID)</span>
              <span className='p4-medium text-color-black font-bold'>
                {formatNumber((
                    parseFloat(salary_advance?.advance_deducted).toFixed(2)
                ), 2)}
              </span>
            </div>
            <hr className='divider mt-4 mb-4' />
            <div className='flex flex-row items-center justify-between pt-2 p-2 border bg-secondary-2-extralight rounded-md'>
              <span className='text-[15px] pb-1'>TOTAL SALARY ADVANCES (BALANCE)</span>
              <span className='text-[15px] text-color-black font-bold'>
                {formatNumber((
                    parseFloat(salary_advance?.advance_balance.toFixed(2))
                ), 2)}
              </span>
            </div>
          </div>
        </div>
        :
        <div className='w-full h-[80px] flex items center justify-center text-[13px] mt-4 font-semibold'>
          No data fetched
        </div>
        }
      </div>
    </Modal>
  );
}

export default SalaryAdvanceSummary;
