import React from 'react';
import Button from '../../../button';
import FormInput from '../../../form-input';
import ForwardArrowVG from '../../../../assets/svg/forward-arrow.svg'
import { useGetSalaryReview } from '../../../../redux/employees/hook/salary-review';
import moment from 'moment';
import { formatNumber } from '../../../../utils/functions';

export const ViewPromotionSalaryReview = ({reviewId=null}) => {

    const { data: salaryReviewData, isLoading } = useGetSalaryReview(reviewId);

    return (
        <div
        className={`h-fit flex flex-col gap-2 justify-start bg-white rounded-[5px] border border-[#dbdce3]`}
        >
            <div className='flex flex-row justify-between items-center bg-blue-100 p-3'>
                <div className='font-medium text-[14px] text-color-black flex flex-col'>
                    Promotion Salary Review
                </div>
            </div>
            {salaryReviewData?
            <div className='flex flex-col !min-h-fit md:justify-around  justify-center'>
                <div className=' flex flex-col justify-start gap-1 px-8 w-full mt-3'>
                    <label className='text-[15px] leading-[23px] font-normal text-color-gray fornt-bold'>
                        Effective from
                    </label>
                    <span className='text-[15px] font-bold'>{moment(salaryReviewData?.effective_date).format('MMMM DD, YYYY')}</span>
                </div>
                <hr className='divider' />
                <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
                    <div className='flex flex-row md:w-1/2 items-center gap-2'>
                    <FormInput
                        label='Old Basic'
                        name='basic'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.old_basic, 2)}
                        className='!w-[150px]'
                    />
                    <img src={ForwardArrowVG} width={15} alt='to'/>
                    <FormInput
                        label='New Basic'
                        name='basic'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.new_basic, 2)}
                        className='!w-[150px]'
                    />
                    </div>
                    <div className='flex flex-row md:w-1/2 items-center gap-2'>
                    <FormInput
                        label='Old Housing'
                        name='housing'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.old_housing, 2)}
                        className='!w-[150px]'
                    />
                    <img src={ForwardArrowVG} width={15} alt='to'/>
                    <FormInput
                        label='New Housing'
                        name='housing'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.new_housing, 2)}
                        className='!w-[150px]'
                    />
                    </div>
                </div>
                <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
                    <div className='flex flex-row md:w-1/2 items-center gap-2'>
                    <FormInput
                        label='Old Transport'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.old_transport, 2)}
                        className='!w-[150px]'
                    />
                    <img src={ForwardArrowVG} width={15} alt='to'/>
                    <FormInput
                        label='New Transportion'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.new_transport, 2)}
                        className='!w-[150px]'
                    />
                    </div>
                    <div className='flex flex-row md:w-1/2 items-center gap-2'>
                    <FormInput
                        label='Old Leave'
                        name='leave'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.old_leave, 2)}
                        className='!w-[150px]'
                    />
                    <img src={ForwardArrowVG} width={15} alt='to'/>
                    <FormInput
                        label='New Leave'
                        name='leave'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.new_leave, 2)}
                        className='!w-[150px]'
                    />
                    </div>
                </div>
                <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
                    <div className='flex flex-row md:w-1/2 items-center gap-2'>
                    <FormInput
                        label='Old Lunch'
                        name='lunch'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.old_lunch, 2)}
                        className='!w-[150px]'
                    />
                    <img src={ForwardArrowVG} width={15} alt='to'/>
                    <FormInput
                        label='Old Lunch'
                        name='lunch'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.new_lunch, 2)}
                        className='!w-[150px]'
                    />
                    </div>
                    <div className='flex flex-row md:w-1/2 items-center gap-2'>
                    <FormInput
                        label='New Benefit in kind'
                        name='housing'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.old_benefit_in_kind, 2)}
                        className='!w-[150px]'
                    />
                    <img src={ForwardArrowVG} width={15} alt='to'/>
                    <FormInput
                        label='New Benefit in kind'
                        name='housing'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.new_benefit_in_kind, 2)}
                        className='!w-[150px]'
                    />
                    </div>
                </div>
                <div className='flex md:flex-row flex-col md:gap-4 justify-center pr-8 pl-8'>
                    <div className='flex flex-row md:w-1/2 items-center gap-2'>
                    <FormInput
                        label='Old Utility'
                        name='utility'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.old_utility, 2)}
                        className='!w-[150px]'
                    />
                    <img src={ForwardArrowVG} width={15} alt='to'/>
                    <FormInput
                        label='New Utility'
                        name='utility'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.new_utility, 2)}
                        className='!w-[150px]'
                    />
                    </div>
                    <div className='flex flex-row md:w-1/2 items-center gap-2'>
                    <FormInput
                        label='Old Other allowance'
                        name='other'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.old_other_allowance, 2)}
                        className='!w-[150px]'
                    />
                    <img src={ForwardArrowVG} width={15} alt='to'/>
                    <FormInput
                        label='New Other allowance'
                        name='other'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.new_other_allowance, 2)}
                        className='!w-[150px]'
                    />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row justify-start pr-8 pl-8'>
                    <div className='flex flex-row md:w-1/2 items-center gap-2'>
                        <FormInput
                        label='Old Net Pay'
                        name='net_pay'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.old_net_pay, 2)}
                        className='!w-[150px]'
                        />
                        <img src={ForwardArrowVG} width={15} height={15} alt='to'/>
                        <FormInput
                        label='New Net Pay'
                        name='net_pay'
                        type='text'
                        readOnly
                        value={formatNumber(salaryReviewData?.new_net_pay, 2)}
                        className='!w-[150px]'
                        />
                    </div>
                </div>
            </div>
            :
            <div className='p-2 flex flex-row items-center justify-center'>No salary review for this promotion</div>
            }
            
        </div>
    );
};