import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FormSelect from "../../components/form-select";
import FormSwitch from "../../components/form-switch";
import Button from "../../components/button";
import OnboardingLayout from "../../components/layouts/onboarding";
import { useForm } from "react-hook-form";
import { formValidation } from "../../utils/functions";
import { useAddSalarySettings } from "../../redux/company/hook";
import classNames from "classnames";
import FormInput from "../../components/form-input";
import CollapsingIcon from "../../components/collapsing-icon";
import { Collapse } from "reactstrap";
import PlusSVG from "../../assets/svg/plus-icon.svg";

const RateSettings = () => {
  useEffect(() => {}, []);

  const {
    register,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const history = useHistory();

  const [dayCount, setDayCount] = useState("seven_days");
  const { mutate: salarySettings, isLoading: loading } = useAddSalarySettings();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(true);
  const [totalAllowance, setTotalAllowance] = useState(0);

  const [formData, setFormData] = useState({
    tax: true,
    pension: true,
    nhf: false,
    nhis: false,
    nstf: true,
    itf: false,
    basic_salary: 40,
    housing_allowance: 10,
    transport_allowance: 15,
    lunch_allowance: 10,
    leave_allowance: 10,
    utility_allowance: 10,
    benefit_in_kind: 0,
    other_allowance: 5,
    pension_calculator: "bht",
    employee_pension: 8,
    employer_pension: 10,
    nhf_percentage: 2.5,
    employee_nhis: 5,
    employer_nhis: 10,
  });

  const [additionalAllowance, setAdditionalAllowance] = useState([]);

  const [allowanceTitle, setAllowanceTitle] = useState();

  const [addingAllowance, setAddingAllowance] = useState(false);

  const addAdditionalAllowance = () => {
    let newArray = JSON.parse(JSON.stringify(additionalAllowance));

    let name = allowanceTitle.toLowerCase();
    newArray.push({
      title: allowanceTitle,
      name: name.split(" ").join("_"),
      rate: 0,
    });
    setAdditionalAllowance(newArray);
    setAllowanceTitle();
    setAddingAllowance(false);
  };

  const rateSettings = [
    {
      title: "Calculate PAYE tax",
      name: "tax",
    },
    {
      title: "Calculate Pension",
      name: "pension",
    },
    {
      title: "Calculate National Housing Fund (NHF) ",
      name: "nhf",
    },
    {
      title: "Calculate National Health Insurance Scheme (NHIS)",
      name: "nhis",
    },
  ];

  const AdvanceSalarySettings = [
    {
      title: "Basic (%)",
      name: "basic_salary",
    },
    {
      title: "Housing (%)",
      name: "housing_allowance",
    },
    {
      title: "Transportation (%)",
      name: "transport_allowance",
    },
    {
      title: "Benefit-in-kind (%)",
      name: "benefit_in_kind",
    },
    {
      title: "Leave allowance (%)",
      name: "leave_allowance",
    },
    {
      title: "Lunch allowance (%)",
      name: "lunch_allowance",
    },
    {
      title: "Utility allowance (%)",
      name: "utility_allowance",
    },
    {
      title: "Other allowance (%)",
      name: "other_allowance",
    },
  ];

  useEffect(() => {
    let additional_allowance = 0;
    if (additionalAllowance.length > 0) {
      additionalAllowance.forEach((element) => {
        additional_allowance += parseInt(element.rate);
      });
    }
    let computeTotalAllowance =
      parseInt(formData.basic_salary) +
      parseInt(formData.housing_allowance) +
      parseInt(formData.transport_allowance) +
      parseInt(formData.benefit_in_kind) +
      parseInt(formData.leave_allowance) +
      parseInt(formData.lunch_allowance) +
      parseInt(formData.utility_allowance) +
      parseInt(formData.other_allowance) +
      parseInt(additional_allowance);
    setTotalAllowance(computeTotalAllowance || 0);
  }, [formData, additionalAllowance]);

  const statutorySettings = [
    {
      title: "Employee Pension (%)",
      name: "employee_pension",
    },
    {
      title: "Employer Pension (%)",
      name: "employer_pension",
    },
    {
      title: "Employer NHIS (%)",
      name: "employer_nhis",
    },
    {
      title: "Employee NHIS (%)",
      name: "employee_nhis",
    },
    {
      title: "NHF (%)",
      name: "nhf_percentage",
    },
  ];

  const dayCountOptions = [
    { value: "seven_days", label: "Calendar Days" },
    { value: "week_days", label: "Working Days" },
  ];

  const pensionCalculatorOptions = [
    { value: "bht", label: "Basic Salary, Housing and Transport Allowances" },
    { value: "gross", label: "Gross Payment" },
  ];

  const submitForm = () => {
    let payload = {
      ...formData,
      ...{ days_count: dayCount },
      ...{ additional_allowances: JSON.stringify(additionalAllowance) },
    };
    salarySettings(payload);
  };

  async function skipStep() {
    let payload = {
      tax: true,
      pension: true,
      nhf: false,
      nhis: false,
      nstf: true,
      itf: true,
      basic_salary: 40,
      housing_allowance: 10,
      transport_allowance: 15,
      lunch_allowance: 10,
      leave_allowance: 10,
      utility_allowance: 10,
      benefit_in_kind: 0,
      other_allowance: 5,
      pension_calculator: "bht",
      employee_pension: 8,
      employer_pension: 10,
      nhf_percentage: 2.5,
      employee_nhis: 5,
      employer_nhis: 10,
      days_count: "seven_days",
      additional_allowances: JSON.stringify(additionalAllowance),
    };
    salarySettings(payload);
  }

  return (
    <OnboardingLayout step={2}>
      {/* Salary Settings */}
      <div className="md:flex flex-col hidden header-4">
        <span className="header-3">Payroll Breakdown Settings</span>
        <hr className="divider mt-2" />
      </div>
      <div action="px-8">
        <div className="flex flex-col bg-highlight p-3 rounded-md">
          <button
            className="flex justify-end mb-4 mt-2"
            onClick={() => skipStep()}
          >
            <span className="text-[15px] underline hover:font-extrabold hover:scale-110 text-color-black duration-300 font-semibold">
              Skip and add later
            </span>
          </button>
          {rateSettings.map((item, index) => (
            <div className="mb-[30px]">
              <FormSwitch
                label={item.title}
                name={item.name}
                readOnly={loading}
                checked={formData[item.name]}
                onClick={() =>
                  setFormData({
                    ...formData,
                    [item.name]: !formData[item.name],
                  })
                }
                value={formData[item.name]}
              />
            </div>
          ))}
          <div
            className={classNames(
              "flex flex-row justify-between items-center cursor-pointer hover:bg-secondary-2-extralight hover:rounded-md hover:px-2 duration-300 -mt-4",
              {
                "pt-3 pb-3 px-2 font-extrabold bg-secondary-2-extralight rounded-md mb-2":
                  showAdvancedSettings,
              }
            )}
            onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
          >
            <span>Advance Payroll Settings</span>
            <div className="pr-4">
              <CollapsingIcon
                defaultPosition="left"
                isOpen={showAdvancedSettings}
              />
            </div>
          </div>
          {/* Advanced Salary Settings */}
          <div
            className={classNames(
              "flex flex-col border p-3 bg-gray-50 rounded-md",
              {
                hidden: !showAdvancedSettings,
              }
            )}
          >
            <div className="flex justify-end">
              <span
                className={classNames(
                  "font-extrabold",
                  {
                    "text-green-700": totalAllowance === 100,
                  },
                  {
                    "text-color-error": totalAllowance !== 100,
                  }
                )}
              >
                Total Gross Allowance ({totalAllowance}%)
              </span>
            </div>
            <Collapse isOpen={showAdvancedSettings}>
              <div className="mt-2 mb-2 text-color-black text-left !text-[13px]">
                Total Gross Allowance must be equal to 100%
              </div>
              <div className="grid md:grid-cols-3 grid-cols-2 gap-2 w-full">
                {AdvanceSalarySettings.map((item) => (
                  <FormInput
                    label={item.title}
                    name={item.name}
                    type="number"
                    inputRef={register(formValidation("non-empty", true))}
                    onInput={(e) =>
                      setFormData({
                        ...formData,
                        [item.name]: e.target.value,
                      })
                    }
                    readOnly={loading}
                    error={errors[item.name]}
                    errorMessage={
                      errors[item.name] && errors[item.name].message
                    }
                    value={formData[item.name]}
                  />
                ))}
              </div>
              <hr className="divider mt-2 mb-2" />
              <div className="text-[15px] font-extrabold mb-2">
                Additional Allowances
              </div>

              {addingAllowance ? (
                <form className="form p-2 border bg-gray-100 rounded-md">
                  <div className="flex flex-col md:flex-row justify-between gap-3 text-[14px]">
                    <div className="w-full">
                      <FormInput
                        label="Allowance Name"
                        name="allowance_title"
                        value={allowanceTitle}
                        placeholder={"Enter Allowance Name"}
                        onInput={(e) => {
                          setAllowanceTitle(e.target.value);
                        }}
                        className="text-[14px]"
                        type="text"
                        error={errors.allowance_title}
                        errorMessage={
                          errors.allowance_title &&
                          errors.allowance_title.message
                        }
                      />
                    </div>
                  </div>
                  <div className="flex md:flex-row justify-end mt-[10px] gap-4">
                    <Button
                      text="Cancel"
                      type="button"
                      onClick={() => {
                        setAddingAllowance(!addingAllowance);
                        setAllowanceTitle(null);
                      }}
                      textClass={"!text-[11px]"}
                      className={"h-[35px] w-fit px-2"}
                      theme={"secondary"}
                    />
                    <Button
                      text={"Add Allowance"}
                      type="button"
                      disabled={!allowanceTitle}
                      onClick={() => {
                        addAdditionalAllowance();
                      }}
                      textClass={"!text-[11px]"}
                      className={"h-[35px] w-fit px-2"}
                    />
                  </div>
                </form>
              ) : (
                <>
                  {additionalAllowance.length > 0 ? (
                    <>
                      <div className="grid md:grid-cols-3 grid-cols-2 gap-2 w-full">
                        {additionalAllowance.map((item, index) => (
                          <FormInput
                            label={item.title + " (%)"}
                            name={item.name}
                            type="number"
                            inputRef={register(
                              formValidation("non-empty", true)
                            )}
                            onInput={(e) => {
                              let newArray = JSON.parse(
                                JSON.stringify(additionalAllowance)
                              );
                              newArray[index].rate = e.target.value;
                              setAdditionalAllowance(newArray);
                            }}
                            readOnly={loading}
                            error={errors[item.name]}
                            errorMessage={
                              errors[item.name] && errors[item.name].message
                            }
                            value={item.rate}
                          />
                        ))}
                      </div>
                      <div
                        className="flex items-end justify-start gap-2 p-2 border bg-gray-100 w-fit rounded-md hover:bg-secondary-2-extralight cursor-pointer"
                        onClick={() => {
                          setAddingAllowance(true);
                        }}
                      >
                        <img src={PlusSVG} alt="" width={"18px"} />
                        <span className="text-[14px] font-semibold">
                          Another Allowance
                        </span>
                      </div>
                    </>
                  ) : (
                    <div
                      className="flex items-end justify-start gap-2 p-2 border bg-gray-100 w-fit rounded-md hover:bg-secondary-2-extralight cursor-pointer"
                      onClick={() => {
                        setAddingAllowance(true);
                      }}
                    >
                      <img src={PlusSVG} alt="" width={"18px"} />
                      <span className="text-[14px] font-semibold">
                        Another Allowance
                      </span>
                    </div>
                  )}
                </>
              )}
              <hr className="divider mt-2" />
              <div className="grid md:grid-cols-3 grid-cols-1 gap-2 w-full">
                <div className="col-span-2">
                  <FormSelect
                    value={formData["pension_calculator"]}
                    options={pensionCalculatorOptions}
                    onChange={(selected) => {
                      setFormData({
                        ...formData,
                        pension_calculator: selected,
                      });
                    }}
                    label="Pension Calculator"
                  />
                </div>
                <div className="col-span-1">
                  <FormSelect
                    value={dayCount}
                    options={dayCountOptions}
                    onChange={(selected) => {
                      setDayCount(selected);
                    }}
                    label="Day Count"
                  />
                </div>
              </div>

              {formData.pension || formData.nhis || formData.nhf ? (
                <>
                  <hr className="divider mt-2" />
                  <div className="mb-2 text-color-black font-semibold text-left">
                    Statutory Rates (%)
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-2 w-full">
                    {statutorySettings.map((item) => (
                      <>
                        {!formData.pension &&
                        (item.name == "employer_pension" ||
                          item.name ==
                            "employee_pension") ? null : !formData.nhis &&
                          (item.name == "employer_nhis" ||
                            item.name ==
                              "employee_nhis") ? null : !formData.nhf &&
                          item.name == "nhf_percentage" ? null : (
                          <FormInput
                            label={item.title}
                            name={item.name}
                            type="text"
                            readOnly={
                              item.name === "employer_pension" ||
                              item.name === "employee_pension"
                                ? false
                                : true
                            }
                            min={
                              item.name === "employer_pension"
                                ? 10
                                : item.name === "employee_pension"
                                ? 8
                                : 0
                            }
                            onInput={(e) =>
                              setFormData({
                                ...formData,
                                [item.name]: parseFloat(e.target.value),
                              })
                            }
                            value={formData[item.name]}
                          />
                        )}
                      </>
                    ))}
                  </div>
                </>
              ) : null}
            </Collapse>
          </div>
          <div className="flex justify-end w-full mt-[20px]">
            <Button
              text="Save and Complete"
              className={"w-[180px] h-[42px]"}
              textClass={"text-[13px]"}
              type="button"
              loading={loading}
              onClick={() => submitForm()}
            />
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default RateSettings;
