import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Filler,
} from 'chart.js';

const SpendChart = ({ data }) => {
  ChartJS.register(
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Filler,
  );

  return (
    <div className='flex'>
      {data ? (
        <Line
          datasetIdKey='spend-line-chart'
          data={data.data}
          options={data.options}
          height={'140px'}
        />
      ) : null}
    </div>
  );
};

export default SpendChart;
