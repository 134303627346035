import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { guarantors } from '../../../api/hr/guarantor';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetEmployeeWithGuarantors(limit, page, search) {
  return useQuery(
    ['guarantors', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return guarantors.getEmployeeWithGuarantor({
        limit,
        page,
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((guarantor) => {
          guarantor.computedActions = [0];
          guarantor.created = guarantor.created.split('T')[0]
          guarantor.employeeName = `${guarantor.first_name} ${guarantor.last_name}`;
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetGuarantors(limit, page, search) {
  return useQuery(
    ['guarantors', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return guarantors.getGuarantors({
        limit,
        page,
        search,
      });
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddGuarantor() {
  return useMutation(
    (payload) => {
      return guarantors.addGuarantor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('guarantors');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useGetGuarantor(id) {
  return useQuery(
    ['guarantor', { id }],
    () => {
      return guarantors.getGuarantor({ id });
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRequestEmployeeGuarantors() {
  return useMutation(
    (payload) => {
      return guarantors.requestGuarantor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('guarantors');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useEditGuarantor() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return guarantors.updateGuarantor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('guarantors');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleGetEmployeeGuarantors(id) {
  return useQuery(
    ['single-employee-guarantors', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return guarantors.getEmployeeGurantors({ id });
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useDeleteGuarantor() {
  return useMutation(
    (payload) => {
      return guarantors.deleteGuarantor(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('single-employee-guarantors');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useGuarantorOnboarding() {
  return useMutation(
    (payload) => {
      return guarantors.guarantorOnboarding(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}
