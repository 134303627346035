import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { setInvoice} from './reducer';
import { invoices } from '../../../api/spend/invoice-management';
import { formatNumber } from '../../../utils/functions';
import history from '../../../routes/history';


export function useGetInvoices(limit, page, statusFilter, search) {
  return useQuery(
    ['invoices', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return invoices.getInvoices({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((invoice) => {
          //compute action items
          invoice.created = moment(invoice.created).format('MMMM D, YYYY');
          invoice.invoice_date = moment(invoice.invoice_date).format('MMMM D, YYYY');
          invoice.invoice_due_date = moment(invoice.invoice_due_date).format('MMMM D, YYYY');
          invoice.formatted_total_amount = formatNumber(invoice.formatted_total_amount, 2);
          if (invoice.status !== 1) {
            if (moment(invoice.invoice_date).diff(moment(invoice.invoice_due_date), 'days' ) > 0) {
              invoice.due_status = 0
            } else if (moment(invoice.invoice_date).diff(moment(invoice.invoice_due_date), 'days' ) == 0) {
              invoice.due_status = 2
            } else {
              invoice.due_status = 3
            }
          } else {
            invoice.due_status = '1'
          }
        
          switch (invoice.status) {
            case 0:
                invoice.computedActions = [0, 1, 2];
              break;
            case 1:
                invoice.computedActions = [0, 1, 3];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetInvoicesByCustomer(id, limit, page, statusFilter, search) {
  return useQuery(
    ['invoice-by-customer', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return invoices.getInvoicesByCustomer({ id, limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((invoice) => {
          //compute action items
          invoice.created = moment(invoice.created).format('MMMM D, YYYY');
          invoice.invoice_date = moment(invoice.invoice_date).format('MMMM D, YYYY');
          invoice.invoice_due_date = moment(invoice.invoice_due_date).format('MMMM D, YYYY');
          if (invoice.status !== 1) {
            if (moment(invoice.invoice_due_date).diff(moment(invoice.invoice_date), 'days' ) > 0) {
              invoice.due_status = 0
            } else if (moment(invoice.invoice_due_date).diff(moment(invoice.invoice_date), 'days' ) == 0) {
              invoice.due_status = 2
            } else {
              invoice.due_status = 3
            }
          } else {
            invoice.due_status = '1'
          }
          switch (invoice.status) {
            case 0:
                invoice.computedActions = [0, 1, 2];
              break;
            case 1:
                invoice.computedActions = [0, 1, 3];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetInvoice(id) {
  return useQuery(
    ['invoice', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return invoices.getInvoice(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setInvoice(response));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateInvoice() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices.updateInvoice(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('invoices');
        queryClient.invalidateQueries('invoice');
        store.dispatch(setAlert(true, 'success',  'Invoice updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSendInvoice() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices.sendInvoice(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success',  'Invoice sent successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCreateSingleInvoice() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices.createSingleInvoice(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setInvoice(response.data));
        queryClient.invalidateQueries('invoices');
        store.dispatch(setAlert(true, 'success',  'Invoice added successfully'));
        history.push('/customers/invoice?invoiceId=' + response.data.id)
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}


export function useBulkActivateInvoices() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices.bulkActivateOrDeactivateInvoices(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('invoice');
        store.dispatch(setAlert(true, 'success', 'Invoice status change successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMarkInvoicePaid() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices.markInvoicePaid(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('invoice');
        queryClient.invalidateQueries('invoices');
        queryClient.invalidateQueries('invoice-by-customer');
        store.dispatch(setAlert(true, 'success', 'Invoice status changed successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMarkInvoiceUnpaid() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices.markInvoiceUnpaid(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('invoices');
        store.dispatch(setAlert(true, 'success', 'Invoice status change successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetInvoicePaymentHistory(
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date,
) {
  return useQuery(
    [
      'customer-history',
      { limit, page, statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return invoices.getInvoicePaymentHistory({
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((transaction) => {
          transaction.created = transaction.created.split('.')[0].replace('T', ' ')
          transaction.status = parseFloat(transaction.status);
          switch (transaction.status) {
            case 0:
              transaction.computedActions = [0];
              break;
            case 2:
              transaction.computedActions = [1];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useDeleteInvoice() {
  return useMutation(
    (payload) => {
      return invoices.deleteInvoice(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('invoices');
        store.dispatch(setAlert(true, 'success', 'Invoice deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

