import React, { useState } from 'react';
import noSubSVG from '../../assets/svg/subscription.svg';
import Button from '../button';
import Modal from './modal';
import FormOtpInput from '../form-otp-input';
import { useAddTransactionPIN } from '../../redux/user/hook';
import { useHistory } from 'react-router-dom';

function AddPINModal({ isOpen, closeModal }) {

  const history = useHistory()
  const [pin, setPIN] = useState('');

  const { mutateAsync: addTransactionPIN, isLoading: addPINLoading } =
    useAddTransactionPIN();

  const submitPIN = async () => {
    let payload = {
      pin
    }
    await addTransactionPIN(payload).then(() => {
      closeModal()
    })
  }

  return (
    <Modal
      scrollable={true}
      className='!max-w-[500px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='p-4 overflow-auto'>
        <div className='flex flex-col overflow-auto justify-center'>
          <div className='flex flex-col md:mx-4 items-center justify-center'>
            <div
              className={`flex items-center justify-center w-[80px] h-[80px] rounded-[80px] bg-[#D34829]`}
            >
              <img
                src={noSubSVG}
                alt='no_subscription'
                style={{ width: '40px', height: '40px' }}
              />
            </div>
            <span className='my-2 header-3'>Transaction PIN Notification</span>
            <span className='my-2 text-[13px] text-center'>
              Due to our recent security update. You are now expected to set transaction PIN to authenticate all your transactions. This helps to create an added layer of security for all your transactions.
            </span>
            <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4 mt-2'>
              <FormOtpInput
                value={pin}
                valueLength={4}
                onChange={(value) => setPIN(value)}
                label={'Enter your 4 digit PIN'}
              />
            </div>
            
            <div className=' flex flex-row w-full gap-4'>
              <Button
                text='ADD TRANSACTION PIN'
                type='button'
                disabled={!pin || pin.length !== 4}
                loading={addPINLoading}
                onClick={() => submitPIN()}
              />
            </div>
            <div
              className='flex justify-center w-full cursor-pointer hover:underline duration-300 mt-2'
              onClick={() => history.push('/logout')}
            >
              <span className='p2-medium text-color-black mt-3'>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddPINModal;
