import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import TabView from "../../../components/tabview";
import BranchOverviewTab from "../../../components/hr-section/branch/view/branch-profile";
import BranchSettings from "../../../components/hr-section/branch/view/branch-settings";
import BranchScheduleTab from "../../../components/hr-section/branch/view/branch-schedule";
import BranchDepartmentTab from "../../../components/hr-section/branch/view/branch-departments";
import BranchTeamTab from "../../../components/hr-section/branch/view/branch-teams";
import {
  useGenerateBranchAccountDetails,
  useGetBranch,
} from "../../../redux/human-resources/hook/branches";
import { Initials, formatNumber } from "../../../utils/functions";
import UnauthorizedPage from "../../errors/unauthorized";
import { useDispatch, useSelector } from "react-redux";
import { BranchAdvancedPayrollTab } from "../../../components/hr-section/branch/view/branch-payroll";
import BranchWalletPage from "../../../components/hr-section/branch/view/branch-transactions";
import CopyToClipboard from "react-copy-to-clipboard";
import CopySVG from "../../../assets/svg/copy.svg";
import Button from "../../../components/button";
import BranchEmployeeSalariesTab from "../../../components/hr-section/branch/view/branch-employee-salaries";
import { setAlert } from "../../../redux/components/components-slice";
import LoadingBar from "../../../components/loader-bar";

const ViewBranchPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.branch_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const { data: branch, isLoading: branchLoading } = useGetBranch(id);

  const BranchTabs = [
    {
      title: "Employees",
      Content: BranchOverviewTab,
    },
    {
      title: "Salaries",
      Content: BranchEmployeeSalariesTab,
    },
    {
      title: "Payroll Summary",
      Content: BranchAdvancedPayrollTab,
    },
    {
      title: "Teams",
      Content: BranchTeamTab,
    },
    {
      title: "Departments",
      Content: BranchDepartmentTab,
    },
    {
      title: "Schedule",
      Content: BranchScheduleTab,
    },
    {
      title: "Settings",
      Content: BranchSettings,
    },
    {
      title: "Transaction History",
      Content: BranchWalletPage,
    },
  ];

  const {
    mutateAsync: generateBranchAccountDetails,
    isLoading: generateBranchAccountDetailsLoading,
  } = useGenerateBranchAccountDetails();

  const generateDetails = () => {
    generateBranchAccountDetails(id);
  };

  return (
    <DashboardLayout title="View Branch">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-row items-center box-shadow md:h-[104px] h-fit py-4">
            {branchLoading ? (
              <LoadingBar loading={branchLoading} />
            ) : (
              <div className="flex md:flex-row flex-col px-8 justify-between w-full gap-4">
                <div className="flex flex-row items-center md:w-1/2 w-full">
                  <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-hr-primary-1 bg-secondary-2-extralight">
                    {branch?.name ? Initials(branch?.name) : null}
                  </div>
                  <div className="flex flex-col pl-4 items-start justify-center">
                    <span className="header-4">{branch?.name}</span>
                    <span className="p4">{branch?.description}</span>
                  </div>
                </div>
                {branch?.wallet_id ? (
                  <div className="flex flex-col items-end justify-center md:w-1/2 w-full">
                    <div className="p-2 px-4 border bg-gray-100 rounded-md">
                      <span className="text-[14px] mb-1 text-hr-primary-1">
                        Wallet Balance -{" "}
                        {branch?.balance
                          ? `${formatNumber(branch.balance, 2)}`
                          : 0.0}
                      </span>

                      <CopyToClipboard
                        text={branch?.account_number}
                        onCopy={() => {
                          dispatch(
                            setAlert(true, "success", "Copied successfully")
                          );
                        }}
                      >
                        <button className="text-[13px] flex flex-row items-center justify-end w-full duration-200 hover:scale-110 hover:font-bold hover:pr-3 mt-2">
                          <div className="md:w-[100%] w-full flex flex-row gap-1 text-hr-primary-1 justify-end">
                            <span className="header-4 text-hr-primary-1">
                              VFD -&nbsp;
                            </span>
                            <span className="header-4 text-hr-primary-1">
                              {branch?.account_number}
                            </span>
                          </div>
                          <img
                            src={CopySVG}
                            width={15}
                            className="ml-2 hover:scale-125 duration-300"
                            alt="copy"
                          />
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
                    <div className="md:w-fit w-[100%]">
                      <Button
                        text="Generate Branch Account"
                        type="button"
                        theme="secondary"
                        loading={generateBranchAccountDetailsLoading}
                        className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                        textClass={"!text-[11px]"}
                        onClick={() => generateDetails()}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="mt-8">
            <TabView
              Tabs={BranchTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default ViewBranchPage;
