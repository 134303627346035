import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CheckShieldSVG from "../../assets/svg/check-shield.svg";
import CheckMarkSVG from "../../assets/svg/checkmark.svg";
import { useGetPeoplePlans } from "../../redux/human-resources/hook/subscription";
import LoadingBar from "../loader-bar";
import SelectSubscriptionPlan from "../modal/hr-section/subscription/select-subscription";
import { useGetEmployeeSummary } from "../../redux/employees/hook";
import Button from "../button";

const PeoplePlans = () => {
  useEffect(() => {}, []);
  const { section } = useSelector((state) => state.sectionSlice);

  const { data: people_plans, isLoading } = useGetPeoplePlans();
  const { data: getEmployeeSummary } = useGetEmployeeSummary();

  const [showSelectSubscriptionPlan, setShowSelectSubscriptionPlan] =
    useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);

  const features = [
    "Recruitment Applicants",
    "Recruitments",
    "Recruitment Keywords",
    "Recruitment Video Assessment",
    "Maximum teams",
    "Maximum Departments",
    "Maximum Branches",
    "Employee Cabinet",
    "Time Tracking",
    "Leave Management",
    "Performance Management",
    "Employee Mobile/Web App",
    "Guarantors",
    "Employee Onboarding",
    "Employee Exit",
    "Geo Tagging",
  ];

  const AllPlans = [
    {
      name: "Basic Plan",
      price: "10,000",
      activeEmployeeCount: "1-10 Employees",
      features: [
        {
          data: people_plans
            ? `${
                people_plans[0].max_recruitment_applicants === -1
                  ? "Unlimited"
                  : people_plans[0].max_recruitment_applicants
              }/Role`
            : null,
          check: false,
        },
        {
          data: people_plans
            ? `${
                people_plans[0].max_recruitments === -1
                  ? "Unlimited"
                  : people_plans[0].max_recruitments
              } Roles`
            : null,
          check: false,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[0].recruitment_keywords === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: false,
        },
        {
          data: people_plans
            ? `${
                people_plans[0].max_teams === -1
                  ? "Unlimited"
                  : people_plans[0].max_teams
              }`
            : null,
          check: false,
        },
        {
          data: people_plans
            ? `${
                people_plans[0].max_departments === -1
                  ? "Unlimited"
                  : people_plans[0].max_departments
              }`
            : null,
          check: false,
        },
        {
          data: people_plans
            ? `${
                people_plans[0].max_branches === -1
                  ? "Unlimited"
                  : people_plans[0].max_branches
              }`
            : null,
          check: false,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[0].guarantors === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[0].employee_onboarding === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[0].employee_exit === 1
              ? true
              : false
            : null,
        },

        {
          data: "",
          check: people_plans
            ? people_plans[0].geo_tagging === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[0].employee_verification === 1
              ? true
              : false
            : null,
        },
      ],
      action: () => {
        setSubscriptionData(people_plans[0]);
        setShowSelectSubscriptionPlan(true);
      },
    },
    {
      name: "Standard Plan",
      price: "20,000",
      activeEmployeeCount: "11-25 Employees",
      features: [
        {
          data: people_plans
            ? `${
                people_plans[1].max_recruitment_applicants === -1
                  ? "Unlimited"
                  : people_plans[1].max_recruitment_applicants
              }/Role`
            : null,
          check: false,
        },
        {
          data: people_plans
            ? `${
                people_plans[1].max_recruitments === -1
                  ? "Unlimited"
                  : people_plans[1].max_recruitments
              } Roles`
            : null,
          check: false,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[1].recruitment_keywords === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: true,
        },
        {
          data: people_plans
            ? `${
                people_plans[1].max_teams === -1
                  ? "Unlimited"
                  : people_plans[1].max_teams
              }`
            : null,
          check: false,
        },
        {
          data: people_plans
            ? `${
                people_plans[1].max_departments === -1
                  ? "Unlimited"
                  : people_plans[1].max_departments
              }`
            : null,
          check: false,
        },
        {
          data: people_plans
            ? `${
                people_plans[1].max_branches === -1
                  ? "Unlimited"
                  : people_plans[1].max_branches
              }`
            : null,
          check: false,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[1].guarantors === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[1].employee_onboarding === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[1].employee_exit === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[1].recruitment_keywords === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[1].geo_tagging === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[1].employee_verification === 1
              ? true
              : false
            : null,
        },
      ],
      action: () => {
        setSubscriptionData(people_plans[1]);
        setShowSelectSubscriptionPlan(true);
      },
    },
    {
      name: "Premium Plan",
      price: "50,000",
      activeEmployeeCount: "26-50 Employees ",
      features: [
        {
          data: people_plans
            ? `${
                people_plans[2].max_recruitment_applicants === -1
                  ? "Unlimited"
                  : people_plans[2].max_recruitment_applicants
              }/Role`
            : null,
          check: false,
        },
        {
          data: people_plans
            ? `${
                people_plans[2].max_recruitments === -1
                  ? "Unlimited"
                  : people_plans[2].max_recruitments
              } Roles`
            : null,
          check: false,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[2].recruitment_keywords === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: true,
        },
        {
          data: people_plans
            ? `${
                people_plans[2].max_teams === -1
                  ? "Unlimited"
                  : people_plans[2].max_teams
              }`
            : null,
          check: false,
        },
        {
          data: people_plans
            ? `${
                people_plans[2].max_departments === -1
                  ? "Unlimited"
                  : people_plans[2].max_departments
              }`
            : null,
          check: false,
        },
        {
          data: people_plans
            ? `${
                people_plans[2].max_branches === -1
                  ? "Unlimited"
                  : people_plans[2].max_branches
              }`
            : null,
          check: false,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[2].guarantors === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[2].employee_onboarding === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[2].employee_exit === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[2].recruitment_keywords === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[2].geo_tagging === 1
              ? true
              : false
            : null,
        },
        {
          data: "",
          check: people_plans
            ? people_plans[2].employee_verification === 1
              ? true
              : false
            : null,
        },
      ],
      action: () => {
        setSubscriptionData(people_plans[2]);
        setShowSelectSubscriptionPlan(true);
      },
    },
  ];

  const AllPlanFeatures = [
    {
      features: [
        {
          data: "500/Recruitment",
          check: false,
        },
        {
          data: "50/Recruitments",
          check: false,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "50",
          check: false,
        },
        {
          data: "20",
          check: false,
        },
        {
          data: "10",
          check: false,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
        {
          data: "",
          check: true,
        },
      ],
      action: () => {
        setSubscriptionData(people_plans[2]);
        setShowSelectSubscriptionPlan(true);
      },
    },
  ];

  return (
    <div className="flex flex-col border bg-gray-50 p-4 rounded-md -mt-3">
      {showSelectSubscriptionPlan ? (
        <SelectSubscriptionPlan
          isOpen={showSelectSubscriptionPlan}
          closeModal={() => setShowSelectSubscriptionPlan(false)}
          subscriptionData={subscriptionData}
        />
      ) : null}
      {/* Mobile view */}
      {isLoading ? (
        <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
          <LoadingBar loading={isLoading} />
        </div>
      ) : (
        <>
          <div className="flex md:flex-row flex-col bg-white gap-6 justify-between items-center w-full md:hidden rounded">
            {AllPlans.map((plan, index) => (
              <div
                className={classNames(
                  "flex p-4 flex-col md:w-1/3 w-full justify-center items-center !h-fit rounded-xl border-1 ",
                  {
                    "hover:border-hr-primary-1":
                      section && section.name === "core hr",
                    "hover:border-secondary-2":
                      section && section.name === "payroll",
                    "hover:border-spend-primary-1":
                      section && section.name === "spend management",
                  }
                )}
              >
                <div className="header-3 p-3 rounded-2xl">{plan.name}</div>
                <div className="flex flex-col p-2">
                  <div className="flex flex-row items-center justify-center w-full p-2 pb-0">
                    <span className="header-3">{plan.price}</span>
                    <span className="italic text-[11px] ml-2">monthly</span>
                  </div>
                  <div className="flex flex-row items-center justify-center w-full">
                    <span className="text-[12px] mt-2 text-color-black-3">
                      {plan.activeEmployeeCount}
                    </span>
                  </div>
                </div>
                <Button
                  text="Select Plan"
                  type="button"
                  theme={"third"}
                  disabled={
                    getEmployeeSummary?.active < 11 &&
                    plan.name === "Standard Plan"
                      ? true
                      : getEmployeeSummary?.active < 26 &&
                        plan.name === "Premium Plan"
                      ? true
                      : false
                  }
                  className="flex gap-2 !h-[42px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[11px]"}
                  onClick={() => {
                    plan.action();
                  }}
                />
              </div>
            ))}
          </div>

          {/* Desktop View */}
          <table className="hidden md:table">
            <thead>
              <th className="h-[150px]">
                <div className="flex flex-col h-full justify-end pb-3">
                  <img
                    src={CheckShieldSVG}
                    alt=""
                    width={"20px"}
                    height={"20px"}
                    srcSet=""
                  />

                  <span className="header-5 mt-3">
                    30-day money-back <br /> guarantee
                  </span>
                </div>
              </th>

              {AllPlans.map((plan) => (
                <th className="h-[180px]">
                  <div className="flex flex-col gap-2 text-center items-center hover:bg-highlight duration-300 rounded-[40px] py-3 px-3 hover-shadow-md">
                    <span className="p1-bold text-inherit">{plan.name}</span>
                    <div className="flex flex-row text-center items-center text-inherit">
                      <span className="header-4 text-inherit">
                        {plan.price}
                      </span>{" "}
                      <span className="ml-1 text-[13px] p4-medium">
                        {" "}
                        / Monthly
                      </span>
                    </div>
                    <span className="p4-medium mt-3 text-inherit italic">
                      {plan.activeEmployeeCount}
                    </span>

                    <Button
                      text="Select Plan"
                      type="button"
                      theme={"third"}
                      disabled={
                        getEmployeeSummary?.active < 11 &&
                        plan.name === "Standard Plan"
                          ? true
                          : getEmployeeSummary?.active < 26 &&
                            plan.name === "Premium Plan"
                          ? true
                          : false
                      }
                      className="flex gap-2 !h-[42px] md:w-fit w-full px-2 items-center"
                      textClass={"!text-[11px]"}
                      onClick={() => {
                        plan.action();
                      }}
                    />
                    {/* <button 
                    className='w-[100px] h-[40px] border-[1px] border-primary-3 rounded-md mt-4 bg-white'
                    onClick={() => {
                      plan.action()
                    }}
                  >
                    <span className='header-6 text-primary-3'>
                      {' '}
                      Select Plan{' '}
                    </span>
                  </button> */}
                  </div>
                </th>
              ))}
            </thead>

            <div className="mt-6"></div>

            <tbody>
              <tr>
                <td>
                  <span className="header-4 text-primary-3">Features</span>
                </td>
              </tr>

              {features.map((feature, index) => (
                <tr className="py-5">
                  <td className="py-2">
                    <span className="p4-medium italic">{feature} </span>
                  </td>

                  {AllPlanFeatures.map((plan, index2) => (
                    <td className="text-center">
                      {plan.features[index].check ? (
                        <div className="flex flex-row justify-center">
                          <img src={CheckMarkSVG} alt="" />
                        </div>
                      ) : (
                        <span className="text-center p4-medium">
                          {plan.features[index].data}{" "}
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default PeoplePlans;
