import moment from "moment";
import React, { useEffect, useState } from "react";
import { periodOptions } from "../../../../utils/constants/date";
import FormSelect from "../../../form-select";
import LeaveSVG from "../../../../assets/svg/hr/hr-leave.svg";
import SalaryAdvanceSVG from "../../../../assets/svg/hr/hr-salary-advance.svg";
import ReimbursementsSVG from "../../../../assets/svg/hr/hr-reimbursements.svg";
import OtherSVG from "../../../../assets/svg/hr/hr-others.svg";
import { useHistory } from "react-router-dom";

export const HRRequests = ({ requests }) => {
  const [period, setPeriod] = useState("this_week");

  const history = useHistory();
  return (
    <div
      className={`flex flex-col w-[100%] h-[250px] align-center bg-white rounded-[5px]`}
    >
      <div className="flex flex-row justify-between items-center pr-4 pl-4 pt-3">
        <div className="text-[15px] font-extrabold text-color-black h-full pt-1">
          Requests
        </div>
        {/* <div className='text-[13px] text-color-black'>
                    <FormSelect
                        value={period}
                        readonly
                        options={periodOptions}
                        onChange={(selected) => {
                            setPeriod(selected);
                        }}
                        className={'pl-2 pr-2 border-1 rounded'}
                    />
                </div> */}
      </div>
      <div className="flex flex-col pr-4 pl-4 ">
        <div
          className="flex flex-row items-center justify-between header-5 h-[50px] hover:bg-gray-100 cursor-pointer hover:p-2 duration-300"
          onClick={() => {
            history.push("/employees", 1);
          }}
        >
          <div className="flex flex-row gap-2 items-center justify-center">
            <div>
              <img src={SalaryAdvanceSVG} alt="" />
            </div>
            Salary Advance
          </div>
          <div className="flex flex-col items-center justify-center gap-0">
            <div className="-mb-1">{requests.advance_request}</div>
            <div className="text-[9px] text-color-gray">Requests</div>
          </div>
        </div>
        <div
          className="flex flex-row items-center justify-between header-6 h-[50px] hover:bg-gray-100 cursor-pointer hover:p-2 duration-300"
          onClick={() => {
            history.push("/leave", 1);
          }}
        >
          <div className="flex flex-row gap-2 items-center justify-center">
            <div>
              <img src={LeaveSVG} alt="" />
            </div>
            Leave
          </div>
          <div className="flex flex-col items-center justify-center gap-0">
            <div className="-mb-1">{requests.reimbursement_requests}</div>
            <div className="text-[9px] text-color-gray">Requests</div>
          </div>
        </div>
        <div
          className="flex flex-row items-center justify-between header-6 h-[50px] hover:bg-gray-100 cursor-pointer hover:p-2 duration-300"
          onClick={() => {
            history.push("/employees", 1);
          }}
        >
          <div className="flex flex-row gap-2 items-center justify-center">
            <div>
              <img src={ReimbursementsSVG} alt="" />
            </div>
            Reimbursements
          </div>
          <div className="flex flex-col items-center justify-center gap-0">
            <div className="-mb-1">{requests.leave_requests}</div>
            <div className="text-[9px] text-color-gray">Requests</div>
          </div>
        </div>
        <div
          className="flex flex-row items-center justify-between header-6 h-[50px] hover:bg-gray-100 cursor-pointer hover:p-2 duration-300"
          // onClick={() => {
          //     history.push('/requests', 1)
          // }}
        >
          <div className="flex flex-row gap-2 items-center justify-center">
            <div>
              <img src={OtherSVG} alt="" />
            </div>
            Others
          </div>
          <div className="flex flex-col items-center justify-center gap-0">
            <div className="-mb-1">{requests.others}</div>
            <div className="text-[9px] text-color-gray">Requests</div>
          </div>
        </div>
      </div>
    </div>
  );
};
