import React, { useState } from 'react';
import Button from '../button';
import FormInput from '../form-input';
import { useUpdateMobile } from '../../redux/user/hook';
import DOMPurify from 'dompurify';
import { useForm } from 'react-hook-form';
import { formValidation } from '../../utils/functions';
import Modal from './modal';
import { useHistory } from 'react-router-dom';

function UpdateUserMobileModal({ isOpen, closeModal }) {
    const {
        register,
        handleSubmit,
        formState: { isValid },
        errors,
      } = useForm({
        mode: 'onChange',
    });

    const history = useHistory();

    const { mutateAsync: updateUserMobile, isLoading: updateUserMobileLoading } = useUpdateMobile();

    const submitForm = async (data) => {
        const mobile = DOMPurify.sanitize(data?.mobile);
        let payload = {
            mobile,
        };
        await updateUserMobile(payload).then(() => {
            closeModal()
        });
    };

    return (
        <Modal
            scrollable={false}
            className='!max-w-[600px] !rounded-none'
            isOpen={isOpen}
            centered={true}
        >
            <div className='p-4 overflow-auto'>
                <form className='form' onSubmit={handleSubmit(submitForm)}>
                    <div className='flex flex-col overflow-auto justify-center'>
                    <div className='flex flex-col mx-8 items-center justify-center gap-3'>
                        <span className='mt-2 header-2'>Mobile Update</span>
                        <span className='my-2 p3 text-center'>
                            We notice that you are yet to add your mobile number. Kindly add your mobile number to continue.           
                        </span>
                        <div className='w-full'>
                        <FormInput
                            placeholder={'Enter your mobile number'}
                            name='mobile'
                            type='text'
                            inputRef={register(formValidation('text', true))}
                            error={errors.mobile}
                            errorMessage={ errors.mobile && errors.mobile.message }
                        />
                        </div>
                        <div className=' flex flex-row w-full'>
                            <Button
                                text='UPDATE MOBILE'
                                type='submit'
                                loading={updateUserMobileLoading}
                            />
                        </div>
                        <div
                            className='flex justify-center w-full cursor-pointer mt-3 hover:underline duration-300'
                            onClick={() => {
                                history.push('/logout');
                            }}
                        >
                            <span className='text-[18px] font-bold text-color-black'>Logout</span>
                        </div>
                    </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
}

export default UpdateUserMobileModal;
