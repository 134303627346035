import { Axios } from './axios';

const joinWaitList = async (payload) => {
  const { data } = await Axios.post('/add-to-waitlist', payload);
  return data;
};

const verifyEmail = async (payload) => {
  const { data } = await Axios.post('mobile/verify-email', payload);
  return data;
};

export const mobile = {
  joinWaitList,
  verifyEmail
};
