import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "../../button";
import AddGuarantor from "../../modal/hr-section/guarantors/add-guarantor";
import {
  useSingleGetEmployeeGuarantors,
  useDeleteGuarantor,
} from "../../../redux/human-resources/hook/guarantors";
import DeleteSVG from "../../../assets/svg/delete.svg";
import LoadingBar from "../../loader-bar";

const SingleEmployeeGuarantorTab = () => {
  useEffect(() => {}, []);

  const { id } = useParams();

  const { data: guarantors, isLoading } = useSingleGetEmployeeGuarantors(id);

  const { mutate: deleteGuarantor } = useDeleteGuarantor();

  const [showAddGuarantor, setShowAddGuarantor] = useState(false);
  const [guarantorData, setGuarantorData] = useState(null);
  const [canRequestGuarantor, setCanRequestGuarantor] = useState(true);

  const [title, setTitle] = useState("Add Guarantor");

  const [buttonText, setButtonText] = useState("ADD GUARANTOR");

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddGuarantor ? (
        <AddGuarantor
          isOpen={showAddGuarantor}
          guarantorData={guarantorData}
          closeModal={() => {
            setShowAddGuarantor(false);
          }}
          canRequestGuarantor={canRequestGuarantor}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Employee Guarantors
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Guarantor"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setTitle("Add Guarantor");
                setButtonText("ADD GUARANTOR");
                setCanRequestGuarantor(true);
                setGuarantorData(null);
                setShowAddGuarantor(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-2" />
      {isLoading ? (
        <LoadingBar loading={isLoading} />
      ) : (
        <div className="flex md:flex-row flex:col my-4 mt-2 justify-center items-center">
          {guarantors && guarantors.data ? (
            <>
              {guarantors.data.map((guarantor, index) => (
                <div
                  key={index}
                  // onClick={() => setSelectedPlanIndex(0)}
                  className="flex flex-row flex-wrap-reverse gap-3 justify-between border border-gray-200 rounded px-4 mx-2 p-3 md:w-1/2 w-full"
                >
                  <div className=" flex flex-col gap-[2px] ">
                    <div className="flex flex-row items-center justify-between ">
                      <span className="text-[13px] font-semibold text-hr-primary-1 mr-2">
                        Name:
                      </span>
                      <span className="text-[12px]">{guarantor?.name}</span>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <span className="text-[13px] font-semibold text-hr-primary-1 mr-2">
                        Phone Number:
                      </span>
                      <span className="text-[12px]">{guarantor?.mobile}</span>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <span className="text-[13px] font-semibold text-hr-primary-1 mr-2">
                        Email:
                      </span>
                      <span className="text-[12px]">{guarantor?.email}</span>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <span className="text-[13px] font-semibold text-hr-primary-1 mr-2">
                        Occupation:
                      </span>
                      <span className="text-[12px]">
                        {guarantor?.occupation}
                      </span>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <span className="text-[13px] font-semibold text-hr-primary-1 mr-2">
                        Gender
                      </span>
                      <span className="text-[12px]">{guarantor?.gender}</span>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <span className="text-[13px] font-semibold text-hr-primary-1 mr-2">
                        Company Name
                      </span>
                      <span className="text-[12px]">
                        {guarantor?.guarantor_company_name}
                      </span>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <span className="text-[13px] font-semibold text-hr-primary-1 mr-2">
                        Company Address
                      </span>
                      <span className="text-[12px]">
                        {guarantor?.work_address}
                      </span>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <span className="text-[13px] font-semibold text-hr-primary-1 mr-2">
                        ID Number
                      </span>
                      <span className="text-[12px]">
                        {guarantor?.id_number}
                      </span>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <span className="text-[13px] font-semibold text-hr-primary-1 mr-2">
                        Means of ID
                      </span>
                      <a
                        href={guarantor?.means_of_id_url}
                        target="_blank"
                        rel="noreferrer"
                        className="italic hover:underline text-hr-primary-1 text-[12px]"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-row gap-3 justify-end items-end !w-full ">
                    {/* <button 
                    onClick={() => {
                      setTitle('Update Guarantor')
                      setButtonText('UPDATE GUARANTOR')
                      setGuarantorData(guarantor)
                      setCanRequestGuarantor(false)
                      setShowAddGuarantor(true)
                    }} 
                    className='cursor-pointer'
                    >
                    <img src={EditSVG} alt='' srcSet='' width={'20px'} />
                  </button> */}
                    <button
                      onClick={() => deleteGuarantor(guarantor.id)}
                      className="cursor-pointer"
                    >
                      <img src={DeleteSVG} alt="" srcSet="" width={"20px"} />
                    </button>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <span className="italic text-hr-primary-1 font-bold">
              No Guarantor Added Yet
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default SingleEmployeeGuarantorTab;
