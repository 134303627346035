import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import { useGetVendorsToPay } from '../../../../redux/spend-management/vendor/hook';
import { useGetBanks, useResolveBankAccount, } from '../../../../redux/bank/hook';
import FormDatalist from '../../../form-datalist';
import Modal from '../../modal';
import FormFileInput from '../../../form-file-input';
import { useCreateSingleInvoiceReceived, useUpdateInvoiceReceived } from '../../../../redux/spend-management/invoice-received/hook';
import { useGetSelectPurchaseOrderByVendor } from '../../../../redux/spend-management/purchase-order/hook';
import FormSelect from '../../../form-select';

function AddInvoiceReceived({ isOpen, closeModal, invoiceReceivedData, title, buttonText, purchaseOrderData }) {
    const {
        register,
        handleSubmit,
        formState: { isValid },
        errors,
      } = useForm({
        mode: 'onChange',
      });

  const { data: vendors } = useGetVendorsToPay(100000, 1, -1, '');
  

//   const history = useHistory();

const [invoiceSaveType, setInvoiceSaveType] = useState('add')

  const { mutateAsync: addInvoiceReceived, isLoading: addInvoiceLoading } = useCreateSingleInvoiceReceived();
  const { mutateAsync: updateInvoiceReceived, isLoading: updateLoading } = useUpdateInvoiceReceived();

  const [invoiceId, setInvoiceId] = useState(null);
  const [defaultText, setDefaultText] = useState()

  const [formData, setFormData] = useState(
    {
        vendor_id: null,
        vendor_name: '',
        vendor_email: '',
        vendor_mobile: '',
        vendor_address: '',
        contact_person: '',
        invoice_date: '',
        invoice_due_date: '',
        invoice_number: '',
        purchase_order_id: null,
        total_amount: 0,
        bank_name: '',
        account_number: '',
        account_name: '',
        status: 0
    }
)

useEffect(() => {
    if (invoiceReceivedData) {
        setInvoiceSaveType('edit')
        setInvoiceId(invoiceReceivedData.id)
        setDefaultText(invoiceReceivedData.vendor_name + ' | ' + invoiceReceivedData.vendor_mobile)
        setFormData(
            {
                vendor_id: invoiceReceivedData.vendor_id,
                vendor_name: invoiceReceivedData.vendor_name,
                vendor_email: invoiceReceivedData.vendor_email,
                vendor_mobile: invoiceReceivedData.vendor_mobile,
                vendor_address: invoiceReceivedData.vendor_address,
                contact_person: invoiceReceivedData.contact_person,
                invoice_date: invoiceReceivedData.invoice_date,
                invoice_due_date: invoiceReceivedData.invoice_due_date,
                invoice_number: invoiceReceivedData.invoice_number,
                purchase_order_id:  invoiceReceivedData.purchase_order_id,
                total_amount: invoiceReceivedData.total_amount,
                bank_name: invoiceReceivedData.bank_name,
                account_number: invoiceReceivedData.account_number,
                account_name: invoiceReceivedData.account_name,
                status: invoiceReceivedData.status
            } 
        )
    }
}, [invoiceReceivedData])

useEffect(() => {
    if (purchaseOrderData) {
        setInvoiceSaveType('add')
        setDefaultText(purchaseOrderData.vendor_name + ' | ' + purchaseOrderData.vendor_mobile)
        setFormData(
            {
                vendor_id: purchaseOrderData.vendor_id,
                vendor_name: purchaseOrderData.vendor_name,
                vendor_email: purchaseOrderData.vendor_email,
                vendor_mobile: purchaseOrderData.vendor_mobile,
                vendor_address: purchaseOrderData.vendor_address,
                contact_person: purchaseOrderData.contact_person,
                invoice_date: purchaseOrderData.purchase_order_date,
                invoice_due_date: purchaseOrderData.purchase_order_due_date,
                invoice_number: '',
                purchase_order_id:  purchaseOrderData.id,
                total_amount: purchaseOrderData.total_amount,
                bank_name: purchaseOrderData.bank_name,
                account_number: purchaseOrderData.account_number,
                account_name: purchaseOrderData.account_name,
                status: purchaseOrderData.status
            } 
        )
    }
}, [purchaseOrderData])

const { data: purchase_orders } = useGetSelectPurchaseOrderByVendor(formData?.vendor_id, 100000, 1, -1, '');

  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } =
    useResolveBankAccount();
  const { data: banks } = useGetBanks();

  const submitForm = async (data) => {
    const submitData = new FormData();
    if (data?.file[0]) {
        submitData.append('file', data?.file[0]);
    }
    submitData.append('vendor_id', formData.vendor_id);
    submitData.append('vendor_name', formData.vendor_name);
    submitData.append('contact_person', formData.contact_person);
    submitData.append('vendor_mobile', formData.vendor_mobile);
    submitData.append('vendor_email', formData.vendor_email);
    submitData.append('vendor_address', formData.vendor_address);
    submitData.append('invoice_date', formData.invoice_date);
    submitData.append('invoice_due_date', formData.invoice_due_date);
    submitData.append('invoice_number', formData.invoice_number);
    submitData.append('purchase_order_id', formData.purchase_order_id);
    submitData.append('total_amount', formData.total_amount);
    submitData.append('bank_name', formData.bank_name);
    submitData.append('account_number', formData.account_number);
    submitData.append('account_name', formData.account_name);

    if (invoiceSaveType === 'add') {
        await addInvoiceReceived(submitData).then((response) => {
            closeModal()
          });
    } else {
        await updateInvoiceReceived({
            id: invoiceId,
            data: submitData
        }).then((response) => {
            closeModal()
        });
    }
    
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col min-h-[550px] overflow-auto'>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8 mt-2 '>
            <div className='w-full'>
                <div className='flex flex-col md:flex-row w-full justify-between'>
                    <div className='w-full md:w-3/5 md:pr-2'>
                        <FormDatalist
                            label='Select Existing Vendor'
                            data={vendors ? vendors : []}
                            defaultText={defaultText}
                            onSelect={(value) => {
                                let index = vendors.findIndex(
                                (item) => item.value === value,
                                );
                                if (index !== -1) {
                                    let newArray = JSON.parse(JSON.stringify(formData))

                                    newArray.vendor_id = vendors[index].value
                                    newArray.vendor_name = vendors[index].vendor_name
                                    newArray.vendor_email = vendors[index].email
                                    newArray.vendor_mobile = vendors[index].mobile
                                    newArray.vendor_address = `${vendors[index].address}, ${vendors[index].city}, ${vendors[index].state}, ${vendors[index].country}.`
                                    newArray.contact_person = vendors[index].contact_person
                                    newArray.bank_name = vendors[index].bank
                                    newArray.account_number = vendors[index].account_number
                                    newArray.account_name = vendors[index].account_name
                                    setFormData(newArray)
                                }
                            }}
                        />
                    </div>
                    <div className='w-full md:w-2/5 md:pl-2'>
                        <FormInput
                        label='Contact Person'
                        name='contact_person'
                        type='text'
                        placeholder={'Enter contact person'}
                        value={formData.contact_person}
                        onChange={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData))
                            newArray.contact_person = e.target.value
                            setFormData(newArray)
                        }}
                        />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row w-full justify-between'>
                    <div className='w-full md:w-3/5 md:pr-2'>
                        <FormInput
                        label='Email'
                        name='email'
                        type='email'
                        placeholder={'Enter vendor email'}
                        value={formData.vendor_email}
                        onChange={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData))
                            newArray.vendor_email = e.target.value
                            setFormData(newArray)
                        }}
                        />
                    </div>
                    <div className='w-full md:w-2/5 md:pl-2'>
                        <FormInput
                        label='Mobile'
                        name='mobile'
                        type='text'
                        placeholder={'Enter vendor mobile'}
                        value={formData.vendor_mobile}
                        onChange={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData))
                            newArray.vendor_mobile = e.target.value
                            setFormData(newArray)
                        }}
                        />
                    </div>
                </div>
                
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-col md:flex-row w-full justify-between'>
                    <div className='w-full md:w-3/5 md:pr-2'>
                        <FormDatalist
                            label='Bank Name'
                            data={banks ? banks : []}
                            value={formData.bank_name}
                            onSelect={(value) => {
                                let newArray = JSON.parse(JSON.stringify(formData))
                                if (formData.account_number && formData.account_number.length >= 10 && value !== "") {
                                    let payload = {
                                        bankname: value,
                                        account_number: formData.account_number,
                                    };
                                        resolveAccount(payload).then((response) => {
                                            let newArray = JSON.parse(JSON.stringify(formData))
                                            newArray.account_name = response.data.accountname
                                            setFormData(newArray)
                                        });
                                }
                                newArray.bank_name = value
                                setFormData(newArray)
                            }}
                        />
                    </div>
                    
                    <div className='w-full md:w-1/2 md:pl-2'>
                        <FormInput
                            label={'Account Number'}
                            name='account_number'
                            type='text'
                            value={formData.account_number}
                            onInput={(e) => {
                                let newArray = JSON.parse(JSON.stringify(formData))
                                if (formData.bank_name  && (e.target.value).length === 10) {
                                    let payload = {
                                        bankname: formData.bank_name,
                                        account_number: e.target.value,
                                    };
                                        resolveAccount(payload).then((response) => {
                                            let newArray = JSON.parse(JSON.stringify(formData))
                                            newArray.account_name = response.data.accountname
                                            setFormData(newArray)
                                        });
                                }
                                newArray.account_number = e.target.value
                                setFormData(newArray)
                            }}
                        />
                    </div>
                </div>
                <FormInput
                    label='Bank Account Name (Auto-Generated)'
                    name='account_name'
                    type='text'
                    placeholder={resolveAccountLoading ? "Fetching Account Name ...." : "Auto-generated field"}
                    readOnly
                    value={formData.account_name}
                />
                {purchase_orders ? (
                    <div className='w-full'>
                        <hr className='divider mt-2 mb-2' />
                        <FormSelect
                            value={formData.purchase_order_id}
                            options={purchase_orders}
                            onChange={(selected) => {
                                let newArray = JSON.parse(JSON.stringify(formData))
                                newArray.purchase_order_id = e.target.value
                                setFormData(newArray)
                            }}
                            label='Select Purchase Order - (Optional)'
                        />
                    </div>
                ) : null}
                <hr className='divider mt-2 mb-2' />
                <FormInput
                    label='Invoice Number'
                    name='invoice_number'
                    type='text'
                    placeholder={'Enter invoice number'}
                    value={formData.invoice_number}
                    onInput={(e) => {
                        let newArray = JSON.parse(JSON.stringify(formData))
                        newArray.invoice_number = e.target.value
                        setFormData(newArray)
                    }}
                />
                <div className='flex flex-col md:flex-row w-full justify-between'>
                    <div className='w-full md:w-3/5 md:pr-2'>
                        <FormInput
                            label='Invoice Date'
                            name='invoice_date'
                            type='date'
                            value={formData.invoice_date}
                            onInput={(e) => {
                                let newArray = JSON.parse(JSON.stringify(formData))
                                newArray.invoice_date = e.target.value
                                setFormData(newArray)
                            }}
                        />
                    </div>
                    <div className='w-full md:w-2/5 md:pl-2'>
                        <FormInput
                            label='Invoice Due Date'
                            name='invoice_due_date'
                            type='date'
                            value={formData.invoice_due_date}
                            onInput={(e) => {
                                let newArray = JSON.parse(JSON.stringify(formData))
                                newArray.invoice_due_date = e.target.value
                                setFormData(newArray)
                            }}
                        />
                    </div>
                </div>
                <hr className='divider mt-2 mb-2' />
                <FormInput
                    label='Invoice Amount'
                    name='total_amount'
                    placeholder={'Enter invoice amount'}
                    type='number'
                    value={formData.total_amount}
                    onInput={(e) => {
                        let newArray = JSON.parse(JSON.stringify(formData))
                        newArray.total_amount = e.target.value
                        setFormData(newArray)
                    }}
                />
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-col text-start justify-center items-start w-full'>
                    <span className='text-[13px] text-left'>Select invoice (Max. 3mb)</span>
                    <FormFileInput
                        multiSource={false}
                        name={'file'}
                        inputRef={register(formValidation('file', false))}
                        accept='.png,.jpg,.jpeg,.pdf,.docx,.xlsx'
                    />
                </div>
            </div>
            <div className='w-full'>
              <Button
                text={buttonText}
                type='submit'
                loading={addInvoiceLoading || updateLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddInvoiceReceived;
