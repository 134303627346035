import React, { useEffect, useState } from "react";
import CloseSVG from "../../../../assets/svg/close.svg";
import Button from "../../../button";
import FormInput from "../../../form-input";
import FormSelect from "../../../form-select";
import { store } from "../../../../redux";
import { setAlert } from "../../../../redux/components/components-slice";
import RemoveSVG from "../../../../assets/svg/remove.svg";
import { useUpdateExitQuestion } from "../../../../redux/employees/hook/exit";
import Modal from "../../modal";

function UpdateExitQuestions({
  isOpen,
  closeModal,
  refresh,
  questionData,
  title,
}) {
  useEffect(() => {}, []);

  const { mutateAsync: updateExitQuestions, isLoading: addQuestionloading } =
    useUpdateExitQuestion();

  useEffect(() => {
    if (questionData) {
      setQuestion(questionData.question);
      setQuestionType(questionData.question_type);
      setOptions(questionData.options ? questionData.options : []);
    }
  }, [questionData]);

  const [question, setQuestion] = useState();
  const [questionType, setQuestionType] = useState();
  const [options, setOptions] = useState([]);

  const QuestionTypes = [
    { label: "Multiple Choice", value: "multiple_choice" },
    { label: "Open Ended", value: "open_ended" },
  ];

  const addOption = () => {
    let data = options;
    if (options.length < 4) {
      // only splice array when item is found
      data.push({ option: "" }); // 2nd parameter means remove one item only
      setOptions([...data]);
    }
  };

  const removeOption = (optionIndex) => {
    let data = options;
    if (options.length > 2) {
      if (optionIndex > -1 && optionIndex > -1) {
        // only splice array when item is found
        data.splice(optionIndex, 1); // 2nd parameter means remove one item only
        setOptions([...data]);
      }
    } else {
      store.dispatch(
        setAlert(true, "error", "There must be at least 2 options")
      );
    }
  };

  const submitForm = async () => {
    let newQuestions = null;
    if (question !== null && question !== "") {
      if (questionType === "open_ended") {
        newQuestions = {
          question: question,
          question_type: questionType,
          options: [],
        };
      } else {
        if (options.length > 1) {
          newQuestions = {
            question: question,
            question_type: questionType,
            options: options,
          };
        }
      }
    }
    if (newQuestions) {
      await updateExitQuestions({
        question_id: questionData?.id,
        data: newQuestions,
      }).then(() => {
        closeModal();
      });
    } else {
      store.dispatch(
        setAlert(
          true,
          "error",
          "You have not added any question. You have to add at least one question."
        )
      );
    }
  };

  return (
    <Modal
      scrollable={true}
      className="!max-w-[600px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col overflow-auto pb-8">
        <div className="pl-8 pr-8 pt-4">
          <div className="flex flex-row justify-between items-center">
            <span className="header-4">{title}</span>
            <div className="cursor-pointer" onClick={() => closeModal()}>
              <img src={CloseSVG} alt="close" />
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="flex flex-col mx-4">
          <div className="border-r-4 border-r-primary-3 border-y border-l rounded-[8px] p-2">
            <div className="flex flex-row justify-between items-center">
              <span className="font-bold italic text-primary-3">Question</span>
            </div>

            <div className="flex md:flex-row flex-col justify-between md:items-center !w-[100%]">
              <div className="flex flex-row items-center justify-start gap-3 md:w-2/3 w-full">
                <FormInput
                  name={"question"}
                  placeholder={"Enter Question?"}
                  type="text"
                  value={question}
                  onInput={(e) => {
                    setQuestion(e.target.value);
                  }}
                  className="!h-[30px] text-[13px] border-1 border-gray-300"
                />
              </div>
              <div className="flex flex-row md:justify-end items-center gap-3 md:w-1/3 w-full -mt-1">
                <FormSelect
                  value={questionType}
                  options={QuestionTypes}
                  name="question_type"
                  onChange={(selected) => {
                    setQuestionType(selected);
                  }}
                  className="!min-h-[32px] border-1 mt-1 rounded text-[13px]"
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              {questionType === "multiple_choice" ? (
                <div className="flex flex-col w-full">
                  {options && options.length > 0 ? (
                    <span className="italic text-hr-primary-1 mb-3 text-[12px]">
                      Options
                    </span>
                  ) : null}
                  {options &&
                    options.length > 0 &&
                    options.map((subItem, subIndex) => (
                      <div
                        className="flex flex-row justify-start items-center"
                        key={subIndex}
                      >
                        <div className="flex flex-row items-center -mt-4">
                          <span className="italic mr-4 text-[15px] font-bold">
                            {subIndex + 1}.
                          </span>
                          <FormInput
                            name={subIndex + "_option"}
                            placeholder={"Enter Option"}
                            type="text"
                            defaultValue={subItem.option}
                            onInput={(e) => {
                              let newArray = JSON.parse(
                                JSON.stringify(options)
                              );

                              newArray[subIndex].option = e.target.value;
                              setOptions(newArray);
                            }}
                            className="!h-[30px] w-[250px] text-[13px] border-1 border-gray-300"
                          />
                          <img
                            src={RemoveSVG}
                            alt={"remove"}
                            className={"h-[20px] w-[20px] ml-3 cursor-pointer"}
                            onClick={() => {
                              removeOption(subIndex);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  {options.length < 4 ? (
                    <span
                      className="text-[13px] italic text-[#0d5225] cursor-pointer hover:underline hover:font-semibold duration-300 mb-2"
                      onClick={() => {
                        addOption();
                      }}
                    >
                      Add Option (Max. 4)
                    </span>
                  ) : null}
                </div>
              ) : (
                <div className="mb-2"></div>
              )}
            </div>
          </div>
          <div className="w-full mt-[20px]">
            <Button
              text="Update Question"
              type="button"
              loading={addQuestionloading}
              className="!h-[62px]"
              onClick={() => submitForm()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UpdateExitQuestions;
