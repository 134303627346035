import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  useAddReimbursement,
  useEditReimbursement,
} from '../../../redux/employees/hook/reimbursements';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormSelectEmployee from '../../employees/form-select-employee';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import FormTextArea from '../../form-textarea';
import Modal from '../modal';
import WarningSVG from '../../../assets/svg/warning.svg';
import moment from 'moment';

function AddReImbursement({
  isOpen,
  closeModal,
  completed,
  refresh,
  reimbData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync: addReimbursement, isLoading: addReimbloading } =
    useAddReimbursement();
  const { mutateAsync: editReimbursement, isLoading: editReimbloading } =
    useEditReimbursement();

  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY-'));
  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [amountEdit, setAmountEdit] = useState();
  const [purposeEdit, setPurposeEdit] = useState();
  const [reimbId, setReimbId] = useState();
  const [reimbType, setReimbType] = useState();
  const [type, setType] = useState(1);

  useEffect(() => {
    if (reimbData) {
      setReimbType('edit');
      setMonth(reimbData.month ? reimbData.month.split('-')[1].toString() : '');
      setYear(
        reimbData.month ? reimbData.month.split('-')[0].toString() + '-' : '',
      );
      setAmountEdit(parseFloat(reimbData.expenses_amount));
      setPurposeEdit(reimbData.expenses_description);
      setEmployee(reimbData.employee);
      setEmployeeName(reimbData.first_name + ' ' + reimbData.last_name);
      setReimbId(reimbData.id);
      setType(reimbData.type);
    } else {
      setReimbType('add');
    }
  }, [reimbData]);

  const submitForm = async (data) => {

    if (reimbType === 'add') {
      let payload = {
        employee,
        expenses_amount: amountEdit,
        expenses_description: purposeEdit,
        month: year + month,
        type,
      };
      await addReimbursement(payload).then(() => {
        completed();
      });
    } else if (reimbType === 'edit') {
      let editPayload = {
        id: reimbId,
        body: {
          employee,
          expenses_amount: amountEdit,
          expenses_description: purposeEdit,
          month: year + month,
          type,
        },
      };
      await editReimbursement(editPayload).then(() => {
        completed();
      });
    }
  };

  const typeOptions = [
    {
      value: 1,
      label: 'One Time',
    },
    {
      value: 2,
      label: 'recurring',
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto '>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='flex flex-row justify-between pl-8 pr-8 pt-4 items-center'>
            <span className='header-4'>{title}</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col mx-8'>
            <div>
              <div className='border bg-highlight p-2 rounded-md mb-2'>
                <FormSelectEmployee
                  label={'Employee'}
                  name='employee'
                  placeholder='Type to Select Employee'
                  value={employee}
                  readOnly={reimbType === 'edit' ? true : false}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
              <hr className='divider mb-2 mt-2' />
              <div className='text-[13px] text-color-gray -mb-2'>
                Select month And year this reimbursement will be added
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormSelect
                    value={month}
                    options={monthOptions}
                    onChange={(selected) => {
                      setMonth(selected);
                    }}
                    // label='Select Month'
                  />
                </div>

                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormSelect
                    value={year}
                    options={yearOptions}
                    onChange={(selected) => {
                      setYear(selected);
                    }}
                    // label='Select Year'
                  />
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-2/3 md:pr-2'>
                  <FormInput
                    label='Expenses Description'
                    name='expenses_description'
                    placeholder='Enter expense purpose'
                    type='text'
                    inputRef={register(formValidation('text', true))}
                    readOnly={addReimbloading || editReimbloading}
                    value={purposeEdit}
                    onInput={(e) => {
                      setPurposeEdit(e.target.value);
                    }}
                    error={errors.expenses_description}
                    errorMessage={
                      errors.expenses_description &&
                      errors.expenses_description.message
                    }
                  />
                </div>

                <div className='w-full md:w-1/3 md:pl-2'>
                  <FormInput
                    label='Expenses Amount'
                    name='amount'
                    type='number'
                    placeholder='Enter amount'
                    readOnly={addReimbloading || editReimbloading}
                    value={amountEdit}
                    onInput={(e) => {
                      setAmountEdit(e.target.value);
                    }}
                    error={errors.amount}
                    errorMessage={errors.amount && errors.amount.message}
                  />
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormSelect
                    defaultValue={type}
                    options={typeOptions}
                    onChange={(selected) => {
                      setType(selected);
                    }}
                    label='Payment Status'
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-col justify-center'>
              <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light !text-[14px]'>
                <div className='w-[70px] pr-2 flex flex-row items-center '>
                  <img src={WarningSVG} alt='' srcSet='' />
                </div>
                <div className='text-[12px]'>
                  Please note that the Reimbursements with be automatically added to the payroll for the <span className='font-extrabold'>month</span> and <span className='font-extrabold'>year</span> selected for one time payments. While recurring payments will be rolled over over time.
                </div>
              </div>
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                disabled={
                  reimbType === 'edit'
                    ? false
                    : !isValid || !employee
                    ? true
                    : false
                }
                loading={addReimbloading || editReimbloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddReImbursement;
