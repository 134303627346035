import React from "react";
import classNames from "classnames";

export const CardHr = ({ title, subtitle, icon, className }) => {
  return (
    <div
      className={`flex flex-row justify-between items-center gap-2 h-[80px] md:w-[100%] w-full p-3 pt-2 pb-2 bg-white rounded-md  border ${className}`}
    >
      <div className="flex flex-col items-start justify-end">
        <div className="flex w-full justify-end font-medium text-[12px] leading-[12px] text-color-gray text-right">
          {title}
        </div>
        <div className="mt-1">
          <span className="font-bold text-[18px]">{subtitle}</span>
        </div>
      </div>
      <div
        className={classNames("flex justify-center items-center min-w-[40px] ")}
      >
        <img src={icon} alt="" height={"40px"} width={"40px"} />
      </div>
    </div>
  );
};
