import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { otherStatutoryAllowances } from '../../../api/employees/other-statutory-allowances';
import { formatNumber } from '../../../utils/functions';

export function useGetOtherStatutoryAllowances(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-other-statutory-allowances', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return otherStatutoryAllowances.getOtherStatutoryAllowances({
        limit,
        page,
        statusFilter, 
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((other_statutory_allowances) => {
          other_statutory_allowances.amount = parseFloat(other_statutory_allowances.amount);
          other_statutory_allowances.formatted_amount = formatNumber(parseFloat(other_statutory_allowances.amount), 2);
          other_statutory_allowances.created = moment(other_statutory_allowances.created).format('MMMM D, YYYY');
          other_statutory_allowances.formatted_month = moment(other_statutory_allowances.month).format('MMMM, YYYY');
          other_statutory_allowances.employeeName = `${other_statutory_allowances.first_name} ${other_statutory_allowances.last_name}`;

          switch (other_statutory_allowances.status) {
            case 1:
              other_statutory_allowances.computedActions = [0];
              break;
            case 2:
              other_statutory_allowances.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddOtherStatutoryAllowance() {
  return useMutation(
    (payload) => {
      return otherStatutoryAllowances.addOtherStatutoryAllowance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-other-statutory-allowances');
        queryClient.invalidateQueries('single-employee-other-statutory-allowances');
        store.dispatch(setAlert(true, 'success', 'Other Statutory Allowance Added successfully'));
      },
    },
  );
}

export function useEditOtherStatutoryAllowances() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return otherStatutoryAllowances.editOtherStatutoryAllowance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-other-statutory-allowances');
        queryClient.invalidateQueries('single-employee-other-statutory-allowances');
        store.dispatch(setAlert(true, 'success', 'Other Statutory Allowances updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useChangeOtherStatutoryAllowanceStatus() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return otherStatutoryAllowances.markOtherStatutoryAllowance(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('employee-other-statutory-allowances');
          queryClient.invalidateQueries('single-employee-other-statutory-allowances');
          store.dispatch(setAlert(true, 'success', 'Other Statutory Allowances updated successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
  }

export function useDeleteOtherStatutoryAllowance() {
  return useMutation(
    (payload) => {
      return otherStatutoryAllowances.deleteStatutoryAllowance(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee-other-statutory-allowances');
        queryClient.invalidateQueries('single-employee-other-statutory-allowances');
        store.dispatch(setAlert(true, 'success', 'Other Statutory Allowance deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleGetEmployeeOtherStatutoryAllowances(id, limit, page, statusFilter) {
  return useQuery(
    ['single-employee-other-statutory-allowances', { id, limit, page, statusFilter }],
    () => {
      store.dispatch(setIsLoading(true));
      return otherStatutoryAllowances.getSingleEmployeeOtherStatutoryAllowances({ id, limit, page, statusFilter });
    },
    {
      select(data) {
        data?.data?.forEach((other_statutory_allowances) => {
          other_statutory_allowances.amount = parseFloat(other_statutory_allowances.amount);
          other_statutory_allowances.created = moment(other_statutory_allowances.created).format('MMMM D, YYYY');
          other_statutory_allowances.formatted_month = other_statutory_allowances.month? moment(other_statutory_allowances.month).format('MMMM, YYYY') : '-';
          other_statutory_allowances.employeeName = `${other_statutory_allowances.first_name} ${other_statutory_allowances.last_name}`;
          if (other_statutory_allowances.frequency == 'monthly') {
            other_statutory_allowances.status = 3
          }

          switch (other_statutory_allowances.status) {
            case 1:
              other_statutory_allowances.computedActions = [];
              break;
            case 2:
              other_statutory_allowances.computedActions = [0, 1];
              break;
            case 3:
              other_statutory_allowances.computedActions = [0, 1];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}
