import React, { useEffect, useState } from 'react';
import TymsSVG from '../../assets/svg/the_tyms_color_logo_main.svg';
import Button from '../button';
import { useIntegrateTyms } from '../../redux/integration/hook';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ComingSoon from '../coming-soon';

const IntegrationPending = () => {
  useEffect(() => {}, []);

  const { company } = useSelector((state) => state.companySlice);

  let currentDate = moment();
  let [dateDiff, setDateDiff] = useState(0);

  useEffect(() => {
    if (company) {
      let diff = currentDate.diff(company.tyms_token_expiry, 'hours');
      setDateDiff(diff);
    }
  }, [company]);

  const { mutateAsync: integrateTyms, isLoading: integrationLoading } =
    useIntegrateTyms();

  const InitiateTymsIntegration = async () => {
    await integrateTyms().then((response) => {
      window.location.assign(response.data.data);
    });
  };

  return (
    <div className='w-full bg-white border rounded flex flex-col'>
      <div className='flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3'>
        <div className='md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full'>
          Integrations
        </div>
      </div>
      <hr className='divider mt-2' />
      <div className='w-full bg-white flex p-4 pt-2 items-center justify-start'>
        <div className='md:w-[250px] h-[250px] flex flex-col items-center justify-center gap-4 border border-gray-300 rounded-lg hover:shadow-xl bg-highlight duration-300'>
          <img
            src={TymsSVG}
            alt={'tyms_logo'}
            className={'pl-4 pr-[15px] w-[200px] max-h-[200px]'}
          />
          <Button
            text={
              company.tyms_integration && dateDiff < 0
                ? 'INTEGRATION COMPLETED'
                : dateDiff > 0
                ? 'RE-AUTHENTICATE'
                : 'INTEGRATE'
            }
            type='button'
            disabled={company.tyms_integration && dateDiff < 0 ? true : false}
            className='flex gap-2 !h-[35px] w-fit p-2'
            textClass={'!text-[13px]'}
            loading={integrationLoading}
            onClick={() => {
              InitiateTymsIntegration();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Integration = () => {
  return (
    <div className='w-full bg-white border rounded flex items-center justify-start p-4 flex-col'>
      <ComingSoon />
    </div>
  );
};

export default Integration;
