import React from 'react';
import PensionPreviewPage from '../../pages/payroll-section/statutory-payments/pension-preview';
import StatutoryPaymentPage from '../../pages/payroll-section/statutory-payments/index';
import TaxPreviewPage from '../../pages/payroll-section/statutory-payments/tax-preview';
import NSITFPreviewPage from '../../pages/payroll-section/statutory-payments/nsitf-preview';
import NHFPreviewPage from '../../pages/payroll-section/statutory-payments/nhf-preview';
import NHISPreviewPage from '../../pages/payroll-section/statutory-payments/nhis-preview';
import ITFPreviewPage from '../../pages/payroll-section/statutory-payments/itf-preview';
import ProtectedRoute from '../protected';
import TaxViewPage from '../../pages/payroll-section/statutory-payments/tax-view';
import PensionViewPage from '../../pages/payroll-section/statutory-payments/pension-view';

export const StatutoryRoutes = [
  <ProtectedRoute exact path='/statutory-payments/taxes' component={StatutoryPaymentPage} />,
  <ProtectedRoute exact path='/statutory-payments/pensions' component={StatutoryPaymentPage} />,
  <ProtectedRoute exact path='/statutory-payments/nhf' component={StatutoryPaymentPage} />,
  <ProtectedRoute exact path='/statutory-payments/nhis' component={StatutoryPaymentPage} />,
  <ProtectedRoute exact path='/statutory-payments/nsitf' component={StatutoryPaymentPage} />,
  <ProtectedRoute exact path='/statutory-payments/itf' component={StatutoryPaymentPage} />,
  <ProtectedRoute
    exact
    path='/statutory-payments/pensions/preview/:refId'
    component={PensionPreviewPage}
  />,
  <ProtectedRoute
    exact
    path='/statutory-payments/taxes/preview/:refId'
    component={TaxPreviewPage}
  />,
  <ProtectedRoute
    exact
    path='/statutory-payments/nhf/preview/:refId'
    component={NHFPreviewPage}
  />,
  <ProtectedRoute
    exact
    path='/statutory-payments/nhis/preview/:refId'
    component={NHISPreviewPage}
  />,
  <ProtectedRoute
    exact
    path='/statutory-payments/nsitf/preview/:refId'
    component={NSITFPreviewPage}
  />,
  <ProtectedRoute
    exact
    path='/statutory-payments/itf/preview/:refId'
    component={ITFPreviewPage}
  />,

  <ProtectedRoute
    exact
    path='/statutory-payments/tax/view/:refId'
    component={TaxViewPage}
  />,
  <ProtectedRoute
    exact
    path='/statutory-payments/pensions/view/:refId'
    component={PensionViewPage}
  />,
]