import React, { useEffect } from "react";
import DataTable from "../../components/datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT, airtimeStatusConfig } from "../../utils/constants";
import { useGetAirtimeHistory } from "../../redux/airtime/hook";

const AirtimeHistoryTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    start_date: "",
    end_date: "",
  });

  const { data: airtimeHistory } = useGetAirtimeHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.start_date,
    pagination?.end_date
  );

  const tableConfig = {
    headers: ["Date", "Name", "Description", "Ref. Number", "Amount", "Status"],
    keys: ["created", "name", "info", "ref", "amount", "status"],
    mobileHeader: {
      left: {
        title: "Date",
        key: "date",
      },
      right: {
        title: "Description",
        key: "info",
      },
    },
  };

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00",
  };

  const statusConfig = [
    {
      label: "Processing",
      color: "orange",
      value: 0,
    },
    {
      label: "Successful",
      color: "green",
      value: 1,
    },
    {
      label: "Failed",
      color: "red",
      value: 2,
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Airtime Purchase History
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={airtimeHistory}
        noCheck={true}
        tableConfig={tableConfig}
        statusConfig={statusConfig}
        filterByDate={dateConfig}
        filterByStatus={airtimeStatusConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={false}
      />
    </div>
  );
};

export default AirtimeHistoryTab;
