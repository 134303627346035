import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useAddApplicationNote,
  useDeleteApplicationNote,
  useEditApplicationNote,
  useGetApplicationNotes,
} from "../../../../redux/human-resources/hook/recruitment";
import { formValidation } from "../../../../utils/functions";
import Button from "../../../button";
import FormInput from "../../../form-input";

export const ApplicationAnswerView = ({ applicant }) => {
  const [result, setResult] = useState([]);

  useEffect(() => {
    if (applicant && applicant.recruitment_answers) {
      let newResults = [];
      applicant.recruitment_answers.forEach((item) => {
        let newResult = {
          question_id: item.id,
          question: item.question,
          answer: item.answer,
          question_type: item.question_type,
          options: item.options,
        };
        newResults.push(newResult);
      });
      setResult(newResults);
    }
  }, [applicant]);

  return (
    <div
      className={`flex flex-col gap-2 justify-start bg-white rounded-[5px] border border-[#dbdce3]`}
    >
      <div className="flex flex-row justify-between items-center bg-blue-100 p-3">
        <div className="font-medium text-[14px] text-color-black flex flex-col">
          Applicant Recruitment Answers
        </div>
      </div>
      <div className="flex flex-col max-h-[400px] min-h-[120px] overflow-auto">
        <form className="form ">
          <div className=" flex flex-col mt-3 p-4 pt-1">
            {result?.map((item, index) => (
              <div className="flex flex-col" key={index}>
                <div className="flex flex-col mb-2">
                  <span className="header-5">Question {index + 1}</span>
                  <span className="p4-medium">{item.question}</span>
                </div>
                {item.question_type === "multiple_choice" ? (
                  <div className="flex flex-col !min-w-[100%]">
                    <span className="header-5 mb-2 underline ">Options:</span>
                    {item.options.map((optionItem, optionIndex) => (
                      <div
                        className={
                          optionItem.option_index == item.answer
                            ? "flex flex-row items-center mb-2 p-2 bg-hr-primary-1  cursor-pointer duration-500 border-1 rounded"
                            : "flex flex-row items-center cursor-pointer duration-500 border-1 rounded mb-2 p-2"
                        }
                      >
                        <div
                          className={
                            optionItem.option_index == item.answer
                              ? "header-5 pr-2 !min-w-[20%] !text-white duration-500"
                              : "header-5 pr-2 !min-w-[20%] duration-500"
                          }
                        >
                          Option {optionIndex + 1}:
                        </div>
                        <div
                          className={
                            optionItem.option_index == item.answer
                              ? "header-5 pr-2 !min-w-[20%] !text-white duration-500"
                              : "header-5 pr-2 !min-w-[20%] duration-500"
                          }
                        >
                          {optionItem.option}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <pre className="border-1 p-3 rounded !max-h-[400px] overflow-auto">
                    {item.answer}
                  </pre>
                )}
              </div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};
