import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { employeeOvertime } from '../../../api/employees/overtime';
import { formatNumber } from '../../../utils/functions';

export function useGetEmployeeOvertimes(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-overtimes', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeOvertime.getEmployeeOvertime({
        limit,
        page,
        statusFilter, 
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((overtime) => {
          overtime.amount = parseFloat(overtime.amount);
          overtime.formatted_amount = formatNumber(parseFloat(overtime.amount).toFixed(2), 2);
          overtime.created = moment(overtime.created).format('MMMM D, YYYY');
          overtime.formatted_month = moment(overtime.month).format('MMMM, YYYY');
          overtime.employeeName = `${overtime.first_name} ${overtime.last_name}`;

          switch (overtime.status) {
            case 1:
              overtime.computedActions = [0];
              break;
            case 2:
              overtime.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetOvertimeReport(start_date, end_date) {
  return useQuery(
    ['overtime-report', { start_date, end_date }],
    () => {
      return employeeOvertime.getOvertimeReport({ start_date, end_date });
    },
    {
      select: (data) => {
        let formatted_months = []
        data?.months?.forEach(element => {
          formatted_months.push(moment(element+'-01').format('MMM YY'));
        });
        data.formatted_months = formatted_months;
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
    
  );
}


export function useAddOvertime() {
  return useMutation(
    (payload) => {
      return employeeOvertime.addOvertime(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-overtimes');
        store.dispatch(setAlert(true, 'success', 'Employee Overtime Added successfully'));
      },
    },
  );
}

export function useEditOvertime() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeOvertime.editOvertime(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-overtimes');
        store.dispatch(setAlert(true, 'success', 'Employee Overtime updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkAddOvertime() {
  return useMutation(
    (payload) => {
      return employeeOvertime.bulkAddOvertime(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('employee-overtimes');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useDeleteOvertime() {
  return useMutation(
    (payload) => {
      return employeeOvertime.deleteOvertime(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee-overtimes');
        store.dispatch(setAlert(true, 'success', 'Employee Overtimes deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleGetemployeeOvertime(id, limit, page, statusFilter) {
  return useQuery(
    ['single-employee-overtimes', { id, limit, page, statusFilter }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeOvertime.getSingleEmployeeOvertime({ id, limit, page, statusFilter });
    },
    {
      select(data) {
        data?.data?.forEach((overtime) => {
          overtime.amount = parseFloat(overtime.amount);
          overtime.formatted_amount = formatNumber(parseFloat(overtime.amount).toFixed(2), 2);
          overtime.created = moment(overtime.created).format('MMMM D, YYYY');
          overtime.formatted_month = moment(overtime.month).format('MMMM, YYYY');
          overtime.employeeName = `${overtime.first_name} ${overtime.last_name}`;

          switch (overtime.status) {
            case 1:
              overtime.computedActions = [0];
              break;
            case 2:
              overtime.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

//Overtime applications
export function useGetOvertimeApplications(limit, page, statusFilter, search) {
  return useQuery(
    ['overtime-applications', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeOvertime.getOvertimeApplications({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((application) => {
          application.created = moment(application.created).format('MMMM DD, YYYY');
          application.amount = parseFloat(
            application.amount,
          );
          application.employeeName = `${application.first_name} ${application.last_name}`;

          //compute action items
          switch (application.status) {
            case 0:
              application.computedActions = [0, 1];
              break;
            default:
              break;
          }
        });

        return data;
      },

      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useSingleGetEmployeeOvertimeApplications(id, limit, page, statusFilter, search) {
  return useQuery(
    ['single-employee-overtime-applications', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeOvertime.getSingleEmployeeReimbursementApplications({ id, limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((application) => {
          application.expenses_amount = parseFloat(
            application.expenses_amount,
          );
          application.employeeName = `${application.first_name} ${application.last_name}`;

          //compute action items
          switch (application.status) {
            case 0:
              application.computedActions = [0, 2, 3];
              break;
            case 3:
              application.computedActions = [1, 2, 3, 4];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useApproveOvertime() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeOvertime.approveOvertime(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('overtime-applications');
        queryClient.invalidateQueries('employee-overtimes');
        queryClient.invalidateQueries('single-employee-overtime-applications');
        store.dispatch(setAlert(true, 'success', 'Overtime approved successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineOvertimeApplication() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeOvertime.declineOvertimeApplication(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('overtime-applications');
        queryClient.invalidateQueries('employee-overtimes');
        queryClient.invalidateQueries('single-employee-overtime-applications');
        store.dispatch(setAlert(true, 'success', 'Overtime application declined successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
