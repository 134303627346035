import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import AddAdminPermissions from './add-admin-permissions';
import Modal from '../modal';

function AddAdminInfo({ isOpen, closeModal, forwardModal, setEditMode }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [showAddPermissions, setShowAddPermissions] = useState(false);

  const [payloadData, setPayloadData] = useState(null);

  const submitForm = async (data) => {
    const firstname = DOMPurify.sanitize(data?.first_name);
    const lastname = DOMPurify.sanitize(data?.last_name);
    const email = DOMPurify.sanitize(data?.email);
    const mobile = DOMPurify.sanitize(data?.mobile);

    let payload = {
      firstname,
      lastname,
      email,
      mobile,
    };

    setPayloadData(payload);
    setShowAddPermissions(true);
  };

  return (
    <>
      {showAddPermissions ? (
        <AddAdminPermissions
          isOpen={showAddPermissions}
          payloadData={payloadData}
          closeModal={() => setShowAddPermissions(false)}
          onCompleted={() => {
            closeModal();
            setEditMode(false);
          }}
          source='add'
        />
      ) : (
        <Modal
          scrollable={true}
          className='!max-w-[700px] !rounded-none'
          isOpen={isOpen}
          centered={true}
        >
          <div className='flex flex-col overflow-auto'>
            <form className='form' onSubmit={handleSubmit(submitForm)}>
              <div className='pl-8 pr-8 pt-4'>
                <div className='flex flex-row justify-between items-center'>
                  <span className='header-4'>Add New Admin</span>
                  <div className='cursor-pointer' onClick={() => closeModal()}>
                    <img src={CloseSVG} alt='close' />
                  </div>
                </div>
              </div>
              <hr className='divider' />
              <div className='flex flex-col mx-8'>
                <div className='flex flex-col md:flex-col w-full justify-between'>
                  <FormInput
                    label='First Name'
                    name='first_name'
                    type='text'
                    placeholder={'Enter first name'}
                    inputRef={register(formValidation('text', true))}
                    error={errors.first_name}
                    errorMessage={
                      errors.first_name && errors.first_name.message
                    }
                  />
                  <FormInput
                    label=' Last Name'
                    name='last_name'
                    type='text'
                    placeholder={'Enter last name'}
                    inputRef={register(formValidation('text', true))}
                    error={errors.last_name}
                    errorMessage={errors.last_name && errors.last_name.message}
                  />
                  <FormInput
                    label='Email'
                    name='email'
                    type='email'
                    placeholder={'Enter email address'}
                    inputRef={register(formValidation('email', true))}
                    error={errors.email}
                    errorMessage={errors.email && errors.email.message}
                  />

                  <FormInput
                    label='Phone Number'
                    name='mobile'
                    type='text'
                    placeholder={'Enter mobile number'}
                    inputRef={register(formValidation('text'))}
                    error={errors.mobile}
                    errorMessage={errors.mobile && errors.mobile.message}
                  />
                </div>

                <div className='w-full mt-3 pb-3'>
                  <Button 
                    text='NEXT' 
                    type='submit' 
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
}

export default AddAdminInfo;
