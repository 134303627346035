import { Axios } from '../axios';

const getCustomers = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get( `/customers?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  return data?.data;
};

const updateCustomer = async (payload) => {

  const { data } = await Axios.put( '/customers/' + payload.customerId, payload.data, );
  return data;
};

const getCustomer= async (payload) => {
  const { data } = await Axios.get('/customers/' + payload);
  return data.data;
};

const createSingleCustomer = async (payload) => {
  const { data } = await Axios.post('/customers', payload);
  return data;
};

const createBulkCustomer = async (payload) => {
  const { data } = await Axios.post('/customers/bulk', payload,
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const bulkActivateOrDeactivateCustomers = async (payload) => {
  const computedPayload = {vendor_ids: payload.data};
  const { data } = await Axios.post( `/customers/bulk-activation/${payload.type}`, computedPayload);
  return data;
};

const activateOrDeactivateCustomer = async (payload) => {
  const { data } = await Axios.put(`/customers/activation/${payload.id}`);
  return data;
};

const getCustomerPaymentHistory = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';

  const { data } = await Axios.get( `/customers/transactions/history?limit=${payload.limit}&page=${payload.page}${search}${filter}${start_date}${end_date}`,);

  return data?.data;
};

const sendCustomerInvoice = async (payload) => {
    const { data } = await Axios.get(`/customers/send-invoice/${payload}`, );
    return data;
};

const deleteCustomer = async (payload) => {
    const { data } = await Axios.delete('customers/'+payload);
    return data;
};

export const customers = {
    getCustomers,
    updateCustomer,
    getCustomer,
    createSingleCustomer,
    createBulkCustomer,
    bulkActivateOrDeactivateCustomers,
    activateOrDeactivateCustomer,
    getCustomerPaymentHistory,
    sendCustomerInvoice,
    deleteCustomer,
};