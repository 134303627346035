import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CloseSVG from "../../../../assets/svg/close.svg";
import Button from "../../../button";
import FormInput from "../../../form-input";
import FormSelect from "../../../form-select";
import AddSmallSVG from "../../../../assets/svg/add-icon-small.svg";
import FormSwitch from "../../../form-switch";
import Modal from "../../modal";
import AsyncSelect from "react-select/async";
import {
  useAddMultipleEmployeeOnboarding,
  useGetOnboardingWorkflow,
  useGetSelectOnboardingWorkflows,
} from "../../../../redux/employees/hook/onboarding";
import AddOnboardingWorkflow from "./add-workflow";
import { useDispatch } from "react-redux";
import { employees as employeeAPI } from "../../../../api/employees";
import { setAlert } from "../../../../redux/components/components-slice";

function AddBulkEmployeeOnboarding({
  isOpen,
  closeModal,
  exitType = "resignation",
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const dispatch = useDispatch();

  const [employees, setEmployees] = useState([]);

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 10,
          statusFilter: -1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const [workflowId, setWorkflowId] = useState(null);

  const [requestGuarantor, setRequestGuarantor] = useState(true);
  const { data: workflows, isLoading: workflowLoading } =
    useGetSelectOnboardingWorkflows(1000, 1, "");

  const { data: workflow_info } = useGetOnboardingWorkflow(workflowId);

  const { mutateAsync: addOnboarding, isLoading: addOnboardingloading } =
    useAddMultipleEmployeeOnboarding();

  const [showAddWorkFlow, setShowAddWorkflow] = useState(false);

  const submitForm = async (data) => {
    if (employees.length === 0) {
      dispatch(setAlert(true, "info", "Select at least one employee"));
      return;
    }
    let employeeArrays = [];

    employees.forEach((item) => {
      employeeArrays.push(item.value);
    });

    let employee_ids = employeeArrays.join();
    let payload = {
      workflow_id: workflowId,
      employee_ids: employee_ids,
      request_guarantor: requestGuarantor,
    };
    addOnboarding(payload).then(() => {
      closeModal();
    });
  };
  return (
    <>
      {(!workflows && !workflowLoading) || showAddWorkFlow ? (
        <AddOnboardingWorkflow
          title={"Add Onboarding Workflow"}
          isOpen={showAddWorkFlow}
          buttonText="Save"
          closeModal={() => setShowAddWorkflow(false)}
        />
      ) : null}
      <Modal
        scrollable={true}
        className="!max-w-[600px] !rounded-none"
        isOpen={isOpen}
        centered={true}
      >
        <div className="flex flex-col overflow-auto">
          <div className="pl-8 pr-8 pt-4">
            <div className="flex flex-row justify-between items-center">
              <span className="header-4">Onboard Multiple Employees</span>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <img src={CloseSVG} alt="close" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2" />
          <form
            className="form rounded-md h-fit flex flex-col px-4"
            onSubmit={handleSubmit(submitForm)}
          >
            <div className="p-3 rounded-md bg-highlight">
              <label className="!text-[13px] leading-[23px] font-normal text-color-gray mb-1">
                Select Employees
              </label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                className="text-[13px]"
                placeholder={"Type to select multiple employees"}
                loadOptions={loadOptions}
                onChange={(options) => setEmployees(options)}
                classNamePrefix="mySelect"
              />
            </div>
            <hr className="divider mt-0 mb-2" />
            <div className="flex flex-row justify-between items-center md:w-[100%] w-full">
              <div className="w-full md:w-[60%] mt-2 text-[15px]">
                <FormSwitch
                  label={"Request Guarantor?"}
                  name={"switch-edit-mode"}
                  checked={requestGuarantor == 1 ? true : false}
                  onClick={() => {
                    setRequestGuarantor(requestGuarantor == 1 ? 0 : 1);
                  }}
                />
              </div>
            </div>
            <hr className="divider -mt-2" />
            <>
              <div className="flex flex-col w-full justify-start items-start">
                {workflows ? (
                  <div className="w-full">
                    <FormSelect
                      value={workflowId}
                      options={workflows}
                      onChange={(selected) => {
                        setWorkflowId(selected);
                      }}
                      label="Exit Workflows"
                    />
                  </div>
                ) : (
                  <div className="p4-medium text-[15px] mt-2 text-gray-500 font-semibold">
                    No Onboarding workflow found. Click link below to add
                    workflow.
                    <hr className="divider" />
                  </div>
                )}
                <div className="flex gap-2 items-center justify-center w-full">
                  <img
                    src={AddSmallSVG}
                    alt={"add_workflow"}
                    className={"h-[15px] w-[15px]"}
                  />
                  <span
                    className="p2-medium text-[#0d5225] cursor-pointer hover:underline duration-75 text-[14px] italic"
                    onClick={() => setShowAddWorkflow(true)}
                  >
                    Add New Workflow
                  </span>
                </div>
              </div>
              <hr className="divider mb-2" />
              {workflowId && workflow_info ? (
                <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full border-2 p-2 rounded mt-2 min-h-[400px] !max-h-[400px] overflow-auto ">
                  <div className="flex flex-col">
                    {workflow_info?.sub_workflows.map((item, index) => (
                      <div className="flex flex-col max-h-fit p-2">
                        <div className="flex flex-col rounded-[5px border border-gray-300 p-4 pt-2 pb-2 bg-gray-50 rounded">
                          <span className="header-4 ">Step {index + 1}</span>
                          <div className="w-full">
                            <div className="flex flex-col justify-around flex-wrap">
                              <FormInput
                                name={"stage_name"}
                                placeholder={"Stage Name"}
                                type="text"
                                readOnly={true}
                                value={item.name}
                                className="!h-[30px] mr-4 text-[13px] w-[60%] border-1 border-gray-300"
                              />
                              <FormInput
                                name={"stage_description"}
                                placeholder={"Stage Description"}
                                type="text"
                                readOnly={true}
                                value={item.description}
                                className="!h-[30px] mr-4 text-[13px] border-1 border-gray-300"
                              />
                              <hr className="divider mt-2 " />
                              <div className="flex flex-row  justify-between items-center md:w-[100%] w-full">
                                <div className="w-full md:w-[100%] text-[14px] -mb-3">
                                  <FormSwitch
                                    label={
                                      "Do you want to upload any file? (Company Handbook, leave ploicy...)"
                                    }
                                    name={"upload_file"}
                                    readOnly
                                    checked={item.upload_file}
                                  />
                                </div>
                              </div>
                              {item.upload_file ? (
                                <>
                                  <FormInput
                                    name={"file_name"}
                                    placeholder={"Enter File Name"}
                                    type="text"
                                    readOnly
                                    value={item.filename}
                                    className="!h-[30px] mr-4 text-[13px] w-[100%] border-1 border-gray-300"
                                  />
                                </>
                              ) : null}
                              <hr className="divider mt-2 " />
                              <div className="flex flex-row  justify-between items-center md:w-[100%] w-full">
                                <div className="w-full md:w-[100%] text-[15px] -mb-3">
                                  <FormSwitch
                                    label={
                                      "Do you want to collect files from employee?"
                                    }
                                    name={"collect_document"}
                                    readOnly
                                    checked={item.collect_document}
                                  />
                                </div>
                              </div>
                              {item.collect_document ? (
                                <>
                                  <FormInput
                                    name={"document_name"}
                                    placeholder={"Enter File Name"}
                                    type="text"
                                    readOnly={true}
                                    value={item.document_name}
                                    className="!h-[30px] mr-4 text-[13px] w-[60%] border-1 border-gray-300"
                                  />
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="italic text-center text-color-gray p-2">
                  Onboarding stages not loaded
                </div>
              )}
            </>
            <div className="w-full mt-[10px] pb-4 ">
              <Button
                text={"Upload"}
                type="submit"
                loading={addOnboardingloading}
                disabled={false}
              />
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddBulkEmployeeOnboarding;
