import { Axios } from '../axios';

const getEmployeeBonuses = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/bonuses?limit=${payload.limit}&page=${payload.page}${search}${filter}`,
  );
  return data?.data;
};

const getBonusReport = async (payload) => {
  const { data } = await Axios.get(`/bonuses/report/${payload.start_date}/${payload.end_date}`);
  return data?.data;
};

const addBonus = async (payload) => {
  const { data } = await Axios.post('bonuses', payload);
  return data;
};
const editBonus = async (payload) => {
  const { data } = await Axios.put('bonuses/'+payload.id, payload.body);
  return data;
};

const deleteBonus = async (payload) => {
  const { data } = await Axios.delete('bonuses/'+payload);
  return data;
};

const bulkAddBonus = async (payload) => {
  const { data } = await Axios.post(
    '/bonuses/bulk', 
    payload,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0
    }
  );
  return data;
};

const getSingleEmployeeBonuses = async (payload) => {
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(`/bonuses/employee/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}`,
  );

  return data?.data;
};

export const employeeBonuses = {
  getEmployeeBonuses,
  getBonusReport,
  addBonus,
  bulkAddBonus,
  getSingleEmployeeBonuses,
  editBonus,
  deleteBonus
};
