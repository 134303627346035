import React, { useEffect } from "react";
import Button from "../../../../components/button";
import DataTable from "../../../../components/datatable";
import { useState } from "react";
import {
  PAGINATION_DEFAULT,
  payrollHistoryStatusConfig,
} from "../../../../utils/constants";
import {
  useExportTransactionsHistory,
  useGetBranchWalletHistory,
} from "../../../../redux/wallet/hook";
import { useDispatch, useSelector } from "react-redux";

import Excel from "exceljs";
import { saveAs } from "file-saver";
import {
  useCheckTransactionStatus,
  useRetryTransaction,
} from "../../../../redux/payroll/hook";
import { useParams } from "react-router-dom";

const BranchWalletPage = () => {
  const workbook = new Excel.Workbook();

  const columns = [
    { header: "Date", key: "created" },
    { header: "Name", key: "name" },
    { header: "Description", key: "info" },
    { header: "Ref", key: "internalRef" },
    { header: "Payment Group", key: "g_type" },
    { header: "Type", key: "type" },
    { header: "Payments Status", key: "status" },
    { header: "Outflow", key: "debit" },
    { header: "Inflow", key: "credit" },
  ];

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.role !== "Owner") {
        if (user.permissions.company_permission.wallet_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const { mutateAsync: checkTransactionStatus } = useCheckTransactionStatus();

  const { mutateAsync: retryTransaction } = useRetryTransaction();

  const [exportLoading, setSetExportLoading] = useState(false);

  const { id } = useParams();
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
    start_date: "",
    end_date: "",
    type: "",
  });

  const { data: walletHistory } = useGetBranchWalletHistory(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date,
    pagination?.type
  );

  const { mutateAsync: exportHistory } = useExportTransactionsHistory();

  const exportWalletHistory = async () => {
    setSetExportLoading(true);
    let payload = {
      limit: 100000,
      page: 1,
      statusFilter: pagination?.statusFilter,
      search: pagination?.search,
      start_date: pagination?.start_date,
      end_date: pagination?.end_date,
      type: pagination?.type,
    };
    await exportHistory(payload).then(async (response) => {
      let data = response.data;
      const workSheetName = "wallet-history";
      const workBookName = `Wallet History from ${pagination?.start_date} - ${pagination?.end_date}`;
      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column) => {
          column.width = column.header.length + 5;
          column.alignment = { horizontal: "center" };
        });

        // loop through data and add each one to worksheet
        data.forEach((singleData) => {
          if (singleData.status === 1) {
            singleData.status = "Successful";
          } else if (singleData.status === 2) {
            singleData.status = "Failed";
          } else if (singleData.status === 0) {
            singleData.status = "Pending";
          }
          if (singleData.type === "wallet") {
            singleData.credit = singleData.amount;
            singleData.g_type = "deposit";
          } else {
            singleData.debit = singleData.amount;
          }
          worksheet.addRow(singleData);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        // console.error('<<<ERRROR>>>', error);
        // console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  const tableConfig = {
    // title: 'Transactions History',
    headers: [
      "Date",
      "Name",
      "Description",
      "Type",
      "Fees",
      "Inflow",
      "Outflow",
      "Status",
    ],
    keys: [
      "created",
      "name",
      "info",
      "type",
      "fees",
      "credit",
      "debit",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00",
  };

  const actionConfig = [
    {
      name: "Check Status",
      color: "green",
      action: async (id) => {
        let index = walletHistory?.data.findIndex((item) => item.id === id);
        await checkTransactionStatus(walletHistory?.data[index].id);
      },
    },
    {
      name: "Retry",
      color: "red",
      action: (id) => {
        let index = walletHistory?.data.findIndex((item) => item.id === id);
        retryTransaction(walletHistory?.data[index].id);
      },
    },
  ];

  const typeConfig = [
    {
      label: "Deposit",
      color: "green",
      value: "wallet",
    },
    {
      label: "Disbursements",
      color: "orange",
      value: "disburse",
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Transaction History
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text={`Export`}
              type="submit"
              loading={exportLoading}
              className="flex gap-2 !h-[35px] w-fit p-2"
              textClass={"!text-[11px]"}
              leftIcon={"export"}
              onClick={() => exportWalletHistory()}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-2" />
      <DataTable
        data={walletHistory}
        tableConfig={tableConfig}
        noCheck={true}
        actionConfig={actionConfig}
        filterByStatus={true}
        filterByType={true}
        typeConfig={typeConfig}
        computedActions={true}
        filterByDate={dateConfig}
        statusConfig={payrollHistoryStatusConfig}
        updatePagination={(data) => setPagination(data)}
      />
    </div>
  );
};

export default BranchWalletPage;
