import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { taxApis } from './../../../api/payroll/statutory-compliance/tax';
import moment from 'moment';
import { formatNumber } from '../../../utils/functions';

export function useGetTaxHistory(limit, page, statusFilter, search) {
  return useQuery(
    ['tax', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return taxApis.getTaxes({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((tax) => {
          tax.created = moment(tax.created).format('MMMM DD, YYYY');
          tax.month = moment(tax.month).format('MMMM, YYYY');
          tax.status = parseFloat(tax.tax_status)
          if (tax.status === 2) {
            tax.status = 0
          }
          if  (!tax.receipt_url) {
            switch (tax.status) {
              case 0:
                tax.computedActions = [0, 3];
                break;
              case 1:
                tax.computedActions = [1, 3];
                break;
              default:
                break;
            }
          } else {
            switch (tax.status) {
              case 0:
                tax.computedActions = [0, 3];
                break;
              case 1:
                tax.computedActions = [1, 2, 3];
                break;
              default:
                break;
            }
          }
          
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useUpdateEmployeeTaxNumber() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(false));
      return taxApis.updateTax(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('tax');
        store.dispatch(setAlert(true, 'success', 'Employee tax number updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUploadTax() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(false));
      return taxApis.bulkTaxes(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('tax');
        store.dispatch(setAlert(true, 'success', 'Tax Uploaded successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGenerateTaxOtp() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(false));
      return taxApis.generateTaxOtp(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('tax');
        queryClient.invalidateQueries('company');
        store.dispatch(setAlert(true, 'success', 'Consent OTP Generated successfully. Please check your email'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useConfirmTaxConsent() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(false));
      return taxApis.confirmConsent(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('tax');
        queryClient.invalidateQueries('company');
        store.dispatch(setAlert(true, 'success', 'Consent OTP Verified successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSaveTaxPayroll() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return taxApis.saveTaxPayroll(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('tax');
        store.dispatch(setAlert(true, 'success', 'Payroll uploaded successfully for bill generation. Proceed to make payment'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRefTax(refId, limit, page, search) {
  return useQuery(
    ['tax-by-ref', {refId, limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return taxApis.fetchTaxByRef({refId, limit, page, search });
    },
    {
      select: (data) => {

        data?.data?.forEach((item) => {
          item.computedActions = [0];
          item.employeeName = `${item.first_name} ${item.last_name}`;
          // item.table_tax_amount = formatNumber(parseFloat(item.fees.toFixed(2)), 2);
          item.payroll_month = moment(item.schedule_year + item.schedule_month).format('MMMM, YYYY');
          item.table_tax_amount = formatNumber(parseFloat(item.tax_amount).toFixed(2), 2);

          item.total = formatNumber((
            parseFloat(item.fees) + parseFloat(item.tax_amount)
          ).toFixed(2), 2);
          
          if (!item.status) {
            item.status = 0;
          }
          item.table_fees = formatNumber(item.fees, 2)
          !item.fees
            ? (item.fees = 0)
            : (item.trans_cost = item.fees);
        });
        if (data) {
          data.formatted_total_trans_cost = formatNumber(parseFloat(data?.total_trans_cost).toFixed(2), 2);
          data.formatted_total_payable = formatNumber(parseFloat(data?.total_payable).toFixed(2), 2);
          data.formatted_total_tax = formatNumber(parseFloat(data?.total_amount).toFixed(2), 2);
        }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useExportTax() {
  return useMutation(
    (payload) => {
      store.dispatch(setAlert(true, 'success', 'Fetching Export Data...'));
      return taxApis.fetchTaxByRef(payload);
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {
          item.computedActions = [0];
          item.employeeName = `${item.first_name} ${item.last_name}`;
          // item.table_tax_amount = formatNumber(parseFloat(item.fees.toFixed(2)), 2);
          item.payroll_month = moment(item.schedule_year + item.schedule_month).format('MMMM, YYYY');
          item.table_tax_amount = formatNumber(parseFloat(item.tax_amount).toFixed(2), 2);

          item.total = formatNumber((
            parseFloat(item.fees) + parseFloat(item.tax_amount)
          ).toFixed(2), 2);
          
          if (!item.status) {
            item.status = 0;
          }
          item.table_fees = formatNumber(item.fees, 2)
          !item.fees
            ? (item.fees = 0)
            : (item.trans_cost = item.fees);
        });
        if (data) {
          data.formatted_total_trans_cost = formatNumber(parseFloat(data?.total_trans_cost).toFixed(2), 2);
          data.formatted_total_payable = formatNumber(parseFloat(data?.total_payable).toFixed(2), 2);
          data.formatted_total_tax = formatNumber(parseFloat(data?.total_amount).toFixed(2), 2);
        }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDisburseTax() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return taxApis.disburseTax(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('tax');
        store.dispatch(setAlert(true, 'success', 'Tax disbursement and filing successful'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRevertTax() {
  return useMutation(
    () => {
      store.dispatch(setIsLoading(true));
      return {
        status: 'success',
        message: 'No Tax With This Reference Number',
      };
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
