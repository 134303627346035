import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import FormSelectEmployee from '../../../employees/form-select-employee';
import { useUpdateExit } from '../../../../redux/employees/hook/exit';

import AddSmallSVG from '../../../../assets/svg/add-icon-small.svg';
import FormSwitch from '../../../form-switch';
import {
  useAddEmployeeOnboarding,
  useGetOnboardingWorkflow,
  useGetSelectOnboardingWorkflows,
} from '../../../../redux/employees/hook/onboarding';
import AddOnboardingWorkflow from './add-workflow';
import Modal from '../../modal';

function AddEmployeeOnboarding({
  isOpen,
  closeModal,
  refresh,
  onboardingData,
  title,
  buttonText,
}) {
  const {
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const [onboardingId, setOnboardingId] = useState(null);
  const [workflowId, setWorkflowId] = useState(null);
  const [onboardingChangeType, setOnboardingChangeType] = useState('add');
  const [requestGuarantor, setRequestGuarantor] = useState(true);
  const { data: workflows, isLoading: workflowLoading } =
    useGetSelectOnboardingWorkflows(1000, 1, '');

  const { data: workflow_info } = useGetOnboardingWorkflow(workflowId);

  const { mutateAsync: addOnboarding, isLoading: addOnboardingloading } =
    useAddEmployeeOnboarding();

  const { mutateAsync: editOnboarding, isLoading: editOnboardingloading } =
    useUpdateExit();

  const [showAddWorkFlow, setShowAddWorkflow] = useState(false);

  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();

  useEffect(() => {
    if (onboardingData) {
      setOnboardingId(onboardingData.id);
      setOnboardingChangeType('edit');
      setEmployee(onboardingData.employee_id);
      setEmployeeName(
        `${onboardingData.first_name} ${onboardingData.last_name}`,
      );
    } else {
      setOnboardingChangeType('add');
    }
  }, [onboardingData]);

  const submitForm = async () => {
    if (onboardingChangeType === 'add') {
      let payload = {
        workflow_id: workflowId,
        employee_id: employee,
        request_guarantor: requestGuarantor,
      };
      addOnboarding(payload).then(() => {
        closeModal();
      });
    } else {
      let editPayload = {
        onboarding_id: onboardingId,
        data: {
          workflow_id: workflowId,
          employee_id: employee,
          request_guarantor: requestGuarantor,
        },
      };
      editOnboarding(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          {(!workflows && !workflowLoading) || showAddWorkFlow ? (
            <AddOnboardingWorkflow
              title={'Add Onboarding Workflow'}
              isOpen={showAddWorkFlow}
              buttonText='Save and Continue'
              closeModal={() => setShowAddWorkflow(false)}
            />
          ) : null}
          <form className='form'>
            <div className='pl-8 pr-8 pt-4'>
              <div className='flex flex-row justify-between items-center'>
                <span className='header-4'>{title}</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='close' />
                </div>
              </div>
            </div>
            <hr className='divider mb-2' />
            <div className='flex flex-col mx-8'>
              <div>
                <div className='border bg-highlight p-2 rounded-md'>
                  <FormSelectEmployee
                    label={'Employee'}
                    name='employee'
                    placeholder='Type to select Employee'
                    value={employee}
                    readOnly={onboardingChangeType == 'add' ? false : true}
                    empName={employeeName}
                    setEmployee={setEmployee}
                  />
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-row justify-between items-center md:w-[100%] w-full'>
                  <div className='w-full md:w-[100%] mt-2 text-[15px]'>
                    <FormSwitch
                      label={'Do You Want To Request Guarantor Information From Employee?'}
                      name={'switch-edit-mode'}
                      checked={requestGuarantor == 1 ? true : false}
                      onClick={() => {
                        setRequestGuarantor(requestGuarantor == 1 ? 0 : 1);
                      }}
                    />
                  </div>
                </div>
                <hr className='divider -mt-2' />
                {onboardingChangeType == 'add' ? (
                  <>
                    <div className='flex flex-col w-full justify-start items-start'>
                      {workflows ? (
                        <div className='w-full'>
                          <FormSelect
                            value={workflowId}
                            options={workflows}
                            onChange={(selected) => {
                              setWorkflowId(selected);
                            }}
                            label='Exit Workflows'
                          />
                        </div>
                      ) : (
                        <div className='p4-medium text-[15px] mt-2 text-gray-500 font-semibold'>
                          No Onboarding workflow found. Click link below to add
                          workflow.
                          <hr className='divider' />
                        </div>
                      )}
                      <div className='flex gap-2 items-center justify-start w-full'>
                        <img
                          src={AddSmallSVG}
                          alt={'add_workflow'}
                          className={'h-[15px] w-[15px]'}
                        />
                        <span
                          className='p2-medium text-[#0d5225] cursor-pointer hover:underline duration-75 text-[14px] italic'
                          onClick={() => setShowAddWorkflow(true)}
                        >
                          Add New Workflow
                        </span>
                      </div>
                    </div>
                    <hr className='divider mb-2' />
                    {workflowId && workflow_info ? (
                      <div className='grid md:grid-cols-1 grid-cols-1 gap-4 w-full border-2 p-2 rounded mt-2 min-h-[400px] !max-h-[400px] overflow-auto '>
                        <div className='flex flex-col'>
                          {workflow_info?.sub_workflows.map((item, index) => (
                            <div className='flex flex-col max-h-fit p-2'>
                              <div className='flex flex-col rounded-[5px border border-gray-300 p-4 pt-2 pb-2 bg-gray-50 rounded'>
                                <span className='header-4 '>
                                  Step {index + 1}
                                </span>
                                <div className='w-full'>
                                  <div className='flex flex-col justify-around flex-wrap'>
                                    <FormInput
                                      name={'stage_name'}
                                      placeholder={'Stage Name'}
                                      type='text'
                                      readOnly={true}
                                      value={item.name}
                                      className='!h-[30px] mr-4 text-[13px] w-[60%] border-1 border-gray-300'
                                    />
                                    <FormInput
                                      name={'stage_description'}
                                      placeholder={'Stage Description'}
                                      type='text'
                                      readOnly={true}
                                      value={item.description}
                                      className='!h-[30px] mr-4 text-[13px] border-1 border-gray-300'
                                    />
                                    <hr className='divider mt-2 ' />
                                    <div className='flex flex-row  justify-between items-center md:w-[100%] w-full'>
                                      <div className='w-full md:w-[100%] text-[14px] -mb-3'>
                                        <FormSwitch
                                          label={
                                            'Do you want to upload any file? (Company Handbook, leave ploicy...)'
                                          }
                                          name={'upload_file'}
                                          readOnly
                                          checked={item.upload_file}
                                        />
                                      </div>
                                    </div>
                                    {item.upload_file ? (
                                      <>
                                        <FormInput
                                          name={'file_name'}
                                          placeholder={'Enter File Name'}
                                          type='text'
                                          readOnly
                                          value={item.filename}
                                          className='!h-[30px] mr-4 text-[13px] w-[100%] border-1 border-gray-300'
                                        />
                                      </>
                                    ) : null}
                                    <hr className='divider mt-2 ' />
                                    <div className='flex flex-row  justify-between items-center md:w-[100%] w-full'>
                                      <div className='w-full md:w-[100%] text-[15px] -mb-3'>
                                        <FormSwitch
                                          label={
                                            'Do you want to collect files from employee?'
                                          }
                                          name={'collect_document'}
                                          readOnly
                                          checked={item.collect_document}
                                        />
                                      </div>
                                    </div>
                                    {item.collect_document ? (
                                      <>
                                        <FormInput
                                          name={'document_name'}
                                          placeholder={'Enter File Name'}
                                          type='text'
                                          readOnly={true}
                                          value={item.document_name}
                                          className='!h-[30px] mr-4 text-[13px] w-[60%] border-1 border-gray-300'
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className='italic text-center text-color-gray p-2'>
                        Onboarding stages not loaded
                      </div>
                    )}
                  </>
                ) : null}
              </div>
              <div className='w-full mt-[20px] pb-4 '>
                <Button
                  text={buttonText}
                  type='button'
                  onClick={() => submitForm()}
                  loading={addOnboardingloading || editOnboardingloading}
                  disabled={
                    onboardingChangeType === 'edit'
                      ? false
                      : !isValid
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddEmployeeOnboarding;
