import React, { useState } from 'react';
import Button from '../../button';
import ButtonSelect from '../../button-select';
import CloseSVG from '../../../assets/svg/close.svg';
import BulkAddSalaryAdvance from './bulk-add-salary-advance';
import AddSalaryAdvance from './add-salary-advance';
import Modal from '../modal';

function SelectSalaryAdvanceOption({ isOpen, closeModal, refresh }) {
  const [option, setOption] = useState('single');
  const [showAddSalaryAdvance, setShowAddSalaryAdvance] = useState(false);
  const [showAddBulkSalaryAdvance, setShowAddBulkSalaryAdvance] =
    useState(false);

  function submit() {
    if (option === 'single') {
      setShowAddSalaryAdvance(true);
    } else if (option === 'bulk') {
      setShowAddBulkSalaryAdvance(true);
    }
  }

  return (
    <>
      {showAddSalaryAdvance ? (
        <AddSalaryAdvance
          isOpen={showAddSalaryAdvance}
          completed={() => {
            setShowAddSalaryAdvance(false);
            closeModal();
          }}
          closeModal={() => {
            setShowAddSalaryAdvance(false);
          }}
          saladData={null}
          title='Add Salary Advance'
          buttonText='ADD'
        />
      ) : null}
      {showAddBulkSalaryAdvance ? (
      <BulkAddSalaryAdvance
        isOpen={showAddBulkSalaryAdvance}
        closeModal={() => setShowAddBulkSalaryAdvance(false)}
        completed={() => {
          setShowAddBulkSalaryAdvance(false);
          closeModal();
        }}
      />
      ) : null}
      

      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Select advance/loan upload option</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col items-center w-full gap-3'>
            <span className='text-[15px] -mb-6'>
              Select your desired option to add salary advance
            </span>
            <div className='flex flex-col justify-center h-full min-h-[400px] max-w-[85%] w-full'>
              <ButtonSelect
                title={'Add Single Deduction'}
                subtitle={'Add Salary Deductions one after the other'}
                isActive={option === 'single'}
                onClick={() => setOption('single')}
              />

              <ButtonSelect
                title={'Import Salary Deductions'}
                subtitle={
                  'Import Salary Deductions using a pre-defined XLSX template'
                }
                isActive={option === 'bulk'}
                onClick={() => setOption('bulk')}
                className='mt-8'
              />

              <div className='w-full mt-[30px] mb-[20px]'>
                <Button text='NEXT' type='submit' onClick={() => submit()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SelectSalaryAdvanceOption;
