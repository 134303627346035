import { Axios } from '../axios';

const getEmployeeReimbursements = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/reimbursements?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const AddReImbursement = async (payload) => {
  const { data } = await Axios.post('/reimbursements', payload);
  return data;
};

const editReImbursement = async (payload) => {
  const { data } = await Axios.put('/reimbursements/'+payload.id, payload.body);
  return data;
};
const deleteReImbursement = async (payload) => {
  const { data } = await Axios.delete('reimbursements/'+payload);
  return data;
};

const bulkAddReimbursement = async (payload) => {
  const { data } = await Axios.post('/reimbursements/bulk', payload, 
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  }
  );
  return data;
};

const getSingleEmployeeReimbursements = async (payload) => {
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(
    `/reimbursements/employee/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}`,
  );

  return data?.data;
};

const getReimbursementApplications = async (payload) => {

  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/reimbursement/applications?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};
//New APIs
const getSingleEmployeeReimbursementApplications = async (payload) => {
  const { data } = await Axios.get(
    `/reimbursement/applications/by-employee/${payload.id}?limit=${payload.limit}&page=${payload.page}`,
  );

  return data?.data;
};

const approveReimbursement = async (payload) => {
  const { data } = await Axios.post(`/reimbursement/application/approve`, payload);
  return data;
};

const disburseReimbursement = async (payload) => {
  const { data } = await Axios.post(`/reimbursement/disburse/approve`, payload,
  {
    timeout: 0
  });
  return data;
};

const disburseApplication = async (payload) => {
  const { data } = await Axios.get(`/reimbursement/application/disbursement/approve`, payload,
  {
    timeout: 0
  });
  return data;
};

const initiateReimbursement = async (payload) => {
  const { data } = await Axios.post(`/reimbursement/disburse/initiate`, payload,
  {
    timeout: 0
  });
  return data;
};

const approvePayReimbursement = async (payload) => {
  const { data } = await Axios.post(`/reimbursement/application/approve-pay`, payload, );
  return data;
};

const markReimbursementPaid = async (payload) => {
  const { data } = await Axios.post(`/reimbursement/mark-as-paid`, payload );
  return data;
};

const declineReImbursementDisbursement = async (payload) => {
  const { data } = await Axios.get('/reimbursement/disburse/decline/'+payload);
  return data;
};

const declineReImbursementApplication = async (payload) => {
  const { data } = await Axios.get('/reimbursement/application/decline/'+payload);
  return data;
};

const declineApplicationDisbursement = async (payload) => {
  const { data } = await Axios.get('/reimbursement/application/disbursement/decline/'+payload);
  return data;
};

const getReimbursementReport = async (payload) => {
  const { data } = await Axios.get(`/reimbursements/report/${payload.start_date}/${payload.end_date}`);
  return data?.data;
};

export const employeeReimbursement = {
  getEmployeeReimbursements,
  AddReImbursement,
  bulkAddReimbursement,
  getSingleEmployeeReimbursements,
  editReImbursement,
  deleteReImbursement,
  getReimbursementApplications,
  getSingleEmployeeReimbursementApplications,
  disburseReimbursement,
  disburseApplication,
  initiateReimbursement,
  approvePayReimbursement,
  approveReimbursement,
  markReimbursementPaid,
  declineReImbursementDisbursement,
  declineReImbursementApplication,
  declineApplicationDisbursement,
  getReimbursementReport,
};
