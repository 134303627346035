import React from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import Modal from '../modal';
import moment from 'moment';
import { formatNumber } from '../../../utils/functions';

function TotalPayrollCosts({
  isOpen,
  closeModal,
  viewData,
}) {
  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] selection: h-full !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col item overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-3'>View All Payable</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col mx-8 pb-2'>
            <div className='flex flex-col'>
                <div className='flex flex-col items-center -mt-1'>
                    <span className='stat-card-header pb-1'>PAYROLL MONTH</span>
                    <span className='p4-medium text-color-black font-bold'>
                        {moment(viewData?.month).format('MMMM, YYYY')}
                    </span>
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-row items-center justify-between pt-22'>
                    <span className='stat-card-header '>
                        TOTAL NET PAY
                    </span>
                    <span className='p4-medium text-color-black font-bold'>
                        {formatNumber((
                            parseFloat(viewData?.total_cost_net_pay.toFixed(2))
                        ), 2)}
                    </span>
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-row items-center justify-between pt-2'>
                    <span className='stat-card-header pb-1'>TOTAL TAXES (PAYE)</span>
                    <span className='p4-medium text-color-black font-bold'>
                        {formatNumber((
                            parseFloat(viewData?.total_cost_paye_taxes.toFixed(2))
                        ), 2)}
                    </span>
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-row items-center justify-between pt-2'>
                    <span className='stat-card-header pb-1'>TOTAL EMPLOYEE PENSION</span>
                    <span className='p4-medium text-color-black font-bold'>
                        {formatNumber((
                            parseFloat(viewData?.total_cost_pension.toFixed(2))
                        ), 2)}
                    </span>
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-row items-center justify-between pt-2'>
                    <span className='stat-card-header pb-1'>TOTAL EMPLOYER PENSION</span>
                    <span className='p4-medium text-color-black font-bold'>
                        {formatNumber((
                            parseFloat(viewData?.total_cost_employer_pension.toFixed(2))
                        ), 2)}
                    </span>
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-row items-center justify-between pt-2'>
                    <span className='stat-card-header pb-1'>TOTAL NHF</span>
                    <span className='p4-medium text-color-black font-bold'>
                        {formatNumber((
                            parseFloat(viewData?.total_cost_nhf.toFixed(2))
                        ), 2)}
                    </span>
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-row items-center justify-between pt-2'>
                    <span className='stat-card-header pb-1'>TOTAL NHIS</span>
                    <span className='p4-medium text-color-black font-bold'>
                        {formatNumber((
                            parseFloat(viewData?.total_cost_nhis.toFixed(2))
                        ), 2)}
                    </span>
                </div>
                <hr className='divider mt-2 mb-3' />
                <div className='flex flex-row items-center justify-between mb-2 pt-2 border bg-secondary-2-extralight rounded-md px-2'>
                    <span className='text-[15px] pb-1 font-extrabold'>TOTAL PAYROLL PAYABLE</span>
                    <span className='p4-medium text-color-black font-bold'>
                        {formatNumber((
                            parseFloat(viewData?.total_cost_total_payable.toFixed(2))
                        ), 2)}
                    </span>
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-row items-center justify-between pt-2'>
                    <span className='stat-card-header pb-1'>TOTAL ITF (Non Payroll)</span>
                    <span className='p4-medium text-color-black font-bold'>
                        {formatNumber((
                            parseFloat(viewData?.total_cost_itf.toFixed(2))
                        ), 2)}
                    </span>
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-row items-center justify-between pt-2'>
                    <span className='stat-card-header pb-1'>TOTAL NSITF (Non Payroll)</span>
                    <span className='p4-medium text-color-black font-bold'>
                        {formatNumber((
                            parseFloat(viewData?.total_cost_nsitf.toFixed(2))
                        ), 2)}
                    </span>
                </div>
                <hr className='divider mt-2 mb-3' />
                <div className='flex flex-row items-center justify-between mb-2 pt-2 border bg-secondary-2-extralight rounded-md px-2'>
                    <span className='text-[15px] pb-1 font-extrabold'>TOTAL ITF AND NSITF</span>
                    <span className='p4-medium text-color-black font-bold'>
                    {formatNumber(viewData?.total_cost_itf_nsitf, 2)}
                    </span>
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-row items-center justify-between pt-2'>
                    <span className='stat-card-header pb-1'>TOTAL TAXES (WHT)</span>
                    <span className='p4-medium text-color-black font-bold'>
                        {formatNumber((
                            parseFloat(viewData?.total_cost_wht_taxes.toFixed(2))
                        ), 2)}
                    </span>
                </div>
            </div>
        </div>
      </div>
    </Modal>
  );
}

export default TotalPayrollCosts;
