import { useSelector } from 'react-redux';
import { EmployeeRoute } from './payroll/employee';
import { PayrollRoutes } from './payroll/payroll';
import { HRDashboardRoutes } from './hr/index';
import { StatutoryRoutes } from './payroll/statutory-compliance';
import { VendorRoutes } from './spend/vendor';
import { AirtimeRoutes } from './payroll/airtime';
import { HREmployeeRoutes } from './hr/employees';
import { HRAttendanceRoutes } from './hr/attendance';
import { HRBranchesRoutes } from './hr/branches';
import { HRLeaveRoutes } from './hr/leaves';
import { HRPerformanceRoutes } from './hr/performance';
import { PayrollDashboardRoutes } from './payroll/payroll-dashboard';
import { CompanyBoardingRoutes } from './non-section/company-onboarding';
import { NonSectionRoutes } from './non-section';
import { CustomerRoutes } from './spend/customers';
import { CardRoutes } from './spend/cards';
import { SpendDashboardRoutes } from './spend';
import { HRRecruitmentRoutes } from './hr/recruitment';
import { HRTeamsRoutes } from './hr/teams';
import { HRDepartmentsRoutes } from './hr/departments';
import { HRRequestRoutes } from './non-section/requests';
import { ContactUsRoutes } from './non-section/contact-us';

export const AdminRoutes = () => {
  const { user } = useSelector((state) => state.userSlice);

  if ((user && !user.section) || (user && user.section === 'payroll')) {
    return [
      ...CompanyBoardingRoutes,
      ...PayrollDashboardRoutes,
      ...NonSectionRoutes,
      ...EmployeeRoute,
      ...NonSectionRoutes,
      ...PayrollRoutes,
      ...StatutoryRoutes,
      ...AirtimeRoutes,
      ...HRRequestRoutes,
      ...ContactUsRoutes,
    ];
  }

  if (user && user.section === 'core hr') {
    return [
      ...PayrollRoutes,
      ...CompanyBoardingRoutes,
      ...NonSectionRoutes,
      ...HRDashboardRoutes,
      ...HREmployeeRoutes,
      ...HRAttendanceRoutes,
      ...HRBranchesRoutes,
      ...HRLeaveRoutes,
      ...HRPerformanceRoutes,
      ...HRRecruitmentRoutes,
      ...HRTeamsRoutes,
      ...HRDepartmentsRoutes,
      ...HRRequestRoutes,
      ...ContactUsRoutes,
    ];
  }

  return [
    ...CompanyBoardingRoutes,
    ...NonSectionRoutes,
    ...VendorRoutes,
    ...CardRoutes,
    ...CustomerRoutes,
    ...SpendDashboardRoutes,
    ...AirtimeRoutes,
    ...ContactUsRoutes,
  ];
};
