import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Collapse } from 'reactstrap';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import CollapsingIcon from '../../../collapsing-icon';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import FormSwitch from '../../../form-switch';
import FormTextArea from '../../../form-textarea';
import FormSelectEmployee from '../../../employees/form-select-employee';
import {
  useAddDepartment,
  useAddEmployeesToDepartment,
  useEditDepartment,
} from '../../../../redux/human-resources/hook/departments';
import Modal from '../../modal';
import { useGetAttendanceSettings } from '../../../../redux/human-resources/hook/attendance';
import { employees as employeeAPI } from '../../../../api/employees';
import { useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';
import LoadingBar from '../../../loader-bar';

function AddDepartment({
  isOpen,
  closeModal,
  refresh,
  departmentData,
  title,
  buttonText,
}) {
  
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync: addEmployeeToDepartment, isLoading: addEmployeeLoading } =
    useAddEmployeesToDepartment();

  const [employees, setEmployees] = useState([]);
  const dispatch = useDispatch();

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 10,
          statusFilter: 1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
  });

  const [addEmployee, setAddEmployee] = useState(false)

  const [dayCount, setDayCount] = useState('seven_days');

  const dayCountOptions = [
    { value: 'seven_days', label: 'Calendar Days' },
    { value: 'week_days', label: 'Working Days' },
  ];

  const [workDays, setWorkdays] = useState([
    {
      day: 'Sunday',
      tag: 'SUN',
      work: 0,
    },
    {
      day: 'Monday',
      tag: 'MON',
      work: 1,
    },
    {
      day: 'Tuesday',
      tag: 'TUE',
      work: 1,
    },
    {
      day: 'Wednesday',
      tag: 'WED',
      work: 1,
    },
    {
      day: 'Thursday',
      tag: 'THU',
      work: 1,
    },
    {
      day: 'Friday',
      tag: 'FRI',
      work: 1,
    },
    {
      day: 'Saturday',
      tag: 'SAT',
      work: 0,
    },
  ]);

  const [autoConfirm, setAutoConfirm] = useState(true);

  const [approveLeave, setApproveLeave] = useState(true);
  const [evaluatePerformance, setEvaluatePerformance] = useState(true);

  const [clockIn, setClockIn] = useState('09:00');
  const [clockOut, setClockOut] = useState('17:00');
  const [manager, setManager] = useState();
  const [managerName, setManagerName] = useState();

  const { data: attendance_setting, isLoading } = useGetAttendanceSettings('company');

  const [applyToPayroll, setApplyToPayroll] = useState(false);
  const [clockinWithLink, setClockinWithLink] = useState(false);

  const [showDepartmentManagerSettings, setShowDepartmentManagerSettings] =
    useState(true);
  const [showDepartmentLeaveSettings, setShowDepartmentLeaveSettings] =
    useState(true);
  const [
    showDepartmentAttendanceSettings,
    setShowDepartmentAttendanceSettings,
  ] = useState(true);

  useEffect(() => {
    if (departmentData) {
      setDepartmentId(departmentData.id);
      setDepartmentType('edit');
      setManager(departmentData.manager_setting?.manager_id);
      setEvaluatePerformance(departmentData.manager_setting?.evaluate_performance == 1 ? true : false)
      setManagerName(departmentData.manager_setting?.manager);
      setDayCount(departmentData.leave_settings?.days_count? departmentData.leave_settings?.days_count : 'seven_days' );
      setApplicationStart(departmentData.leave_settings?.application_start ? departmentData.leave_settings?.application_start: 60);
      setWorkdays([
        {
          day: 'Sunday',
          tag: 'SUN',
          work: departmentData.attendance_setting?.workdays?.sun === 1 ? 1 : 0,
        },
        {
          day: 'Monday',
          tag: 'MON',
          work: departmentData.attendance_setting?.workdays.mon === 1 ? 1 : 0,
        },
        {
          day: 'Tuesday',
          tag: 'TUE',
          work: departmentData.attendance_setting?.workdays.tue === 1 ? 1 : 0,
        },
        {
          day: 'Wednesday',
          tag: 'WED',
          work: departmentData.attendance_setting?.workdays.wed === 1 ? 1 : 0,
        },
        {
          day: 'Thursday',
          tag: 'THU',
          work: departmentData.attendance_setting?.workdays.thu === 1 ? 1 : 0,
        },
        {
          day: 'Friday',
          tag: 'FRI',
          work: departmentData.attendance_setting?.workdays.fri === 1 ? 1 : 0,
        },
        {
          day: 'Saturday',
          tag: 'SAT',
          work: departmentData.attendance_setting?.workdays.sat === 1 ? 1 : 0,
        },
      ]);
      setApplyToPayroll(departmentData.attendance_setting?.apply_to_payroll === 1 ? true : false);
      setClockinWithLink(departmentData.attendance_setting?.clockin_with_link === 1 ? true : false);
      setAutoConfirm(departmentData.attendance_setting?.auto_confirm === 1 ? true : false);
      setName(departmentData.name);
      setDescription(departmentData.description);
      setClockIn(departmentData.attendance_setting?.clockin_time ? departmentData.attendance_setting?.clockin_time: '09:00');
      setClockOut(departmentData.attendance_setting?.clockout_time ? departmentData.attendance_setting?.clockout_time: '17:00');
    } else if (attendance_setting) {
      setWorkdays([
        {
          day: 'Sunday',
          tag: 'SUN',
          work: attendance_setting.workdays.sun,
        },
        {
          day: 'Monday',
          tag: 'MON',
          work: attendance_setting.workdays?.mon === 1 ? 1 : 0,
        },
        {
          day: 'Tuesday',
          tag: 'TUE',
          work: attendance_setting.workdays.tue === 1 ? 1 : 0,
        },
        {
          day: 'Wednesday',
          tag: 'WED',
          work: attendance_setting.workdays.wed === 1 ? 1 : 0,
        },
        {
          day: 'Thursday',
          tag: 'THU',
          work: attendance_setting.workdays.thu === 1 ? 1 : 0,
        },
        {
          day: 'Friday',
          tag: 'FRI',
          work: attendance_setting.workdays.fri === 1 ? 1 : 0,
        },
        {
          day: 'Saturday',
          tag: 'SAT',
          work: attendance_setting.workdays.sat === 1 ? 1 : 0,
        },
      ]);
      setApplyToPayroll(attendance_setting.apply_to_payroll == 1 ? true : false);
      setClockinWithLink(attendance_setting.clockin_with_link == 1 ? true : false);
      setAutoConfirm(attendance_setting.auto_confirm == 1 ? true : false);
      setClockIn(attendance_setting.clockin_time ? attendance_setting?.clockin_time: '09:00');
      setClockOut(attendance_setting.clockout_time ? attendance_setting?.clockout_time: '17:00');
      setDepartmentType('add');
    }
  }, [departmentData, attendance_setting]);

  const [applicationStart, setApplicationStart] = useState(60);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);

  const [departmentType, setDepartmentType] = useState();
  const [departmentId, setDepartmentId] = useState(null);

  const { mutateAsync: addDepartment, isLoading: addDepartmentloading } =
    useAddDepartment();
  const { mutateAsync: editDepartment, isLoading: editDepartmentloading } =
    useEditDepartment();

  const submitForm = async (data) => {
    const name = DOMPurify.sanitize(data?.name);
    const description = DOMPurify.sanitize(data?.description);
    const application_start = DOMPurify.sanitize(data?.application_start);
    const clock_in = DOMPurify.sanitize(data?.clock_in);
    const clock_out = DOMPurify.sanitize(data?.clock_out);

    let payload = {
      name,
      description,
      manager_id: manager,
      approve_leave: approveLeave,
      evaluate_performance: evaluatePerformance? 1 : 0,
      application_start: application_start,
      days_count: dayCount,
      clockin_time: clock_in,
      clockout_time: clock_out,
      apply_to_payroll: applyToPayroll? 1 : 0,
      clockin_with_link: clockinWithLink? 1 : 0,
      auto_confirm: autoConfirm,
      sun: workDays[0].work,
      mon: workDays[1].work,
      tue: workDays[2].work,
      wed: workDays[3].work,
      thu: workDays[4].work,
      fri: workDays[5].work,
      sat: workDays[6].work,
    };
    if (departmentType === 'add') {
      await addDepartment(payload).then((result) => {
        if (addEmployee) {
          if (result?.status == 'success' && result?.data) {
            if (employees.length < 1) {
              closeModal();
            }
            let employeeArrays = [];
        
            employees.forEach((item) => {
              employeeArrays.push(item.value);
            });
        
            let employee_ids = employeeArrays.join();
        
            let payload = {
              department_id: result.data.id,
              employee_ids: employee_ids,
            };
        
            addEmployeeToDepartment(payload).then(() => {
              closeModal();
            });
          } else {
            closeModal();
          }
        } else {
          closeModal();
        }
      });
    } else if (departmentType === 'edit') {
      let editPayload = {
        id: departmentId,
        data: payload,
      };
      await editDepartment(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          {isLoading?
          <div className='flex flex-col mx-8'>
            <LoadingBar loading={isLoading} />
          </div>
          :
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col border bg-highlight p-2 rounded-md'>
              <FormInput
                label='Department Name'
                name='name'
                type='text'
                placeholder={'Enter department name'}
                defaultValue={name}
                onInput={(e) => {
                  setName(e.target.value);
                }}
                inputRef={register(formValidation('text', true))}
                error={errors.name}
                errorMessage={errors.name && errors.name.message}
                className={'bg-white'}
              />

              <FormTextArea
                label={'Department Description'}
                name='description'
                placeholder={'Department Description'}
                defaultValue={description}
                onInput={(e) => {
                  setDescription(e.target.value);
                }}
                inputRef={register(formValidation('text', true))}
                error={errors.description}
                errorMessage={errors.description && errors.description.message}
                className={'bg-white'}
              />
            </div>
            <hr className='divider mt-2' />
            <div>
              <span className='header-4'>Department Settings</span>
            </div>
            <hr className='divider mb-1' />
            <>
              <div
                className={classNames('flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300', {
                  'pt-3 pb-3 bg-secondary-2-extralight rounded-md mb-2 px-2': showDepartmentManagerSettings,
                })}
                onClick={() =>
                  setShowDepartmentManagerSettings(
                    !showDepartmentManagerSettings,
                  )
                }
              >
                <span>Manager Settings</span>
                <div className='pr-4'>
                  <CollapsingIcon
                    defaultPosition='left'
                    isOpen={showDepartmentManagerSettings}
                  />
                </div>
              </div>
              <div
                className={classNames('flex flex-col', {
                  hidden: !showDepartmentManagerSettings,
                })}
              >
                <Collapse isOpen={showDepartmentManagerSettings}>
                  <div className='grid md:grid-cols-1 grid-cols-1 gap-2 w-full p-3 border bg-gray-50 rounded-md'>
                    <FormSelectEmployee
                      label={'Department Lead'}
                      name='employee'
                      placeholder='Type to select department lead'
                      value={manager}
                      empName={managerName}
                      setEmployee={setManager}
                    />
                    <div className={'flex w-[100%] flex-col'}>
                      <div className='w-full'>
                        <FormSwitch
                          label={'Can Approve Leave?'}
                          name={'approve_leave'}
                          checked={approveLeave}
                          onClick={() => {
                            setApproveLeave(!approveLeave);
                          }}
                        />
                      </div>
                      <div className='w-full'>
                        <FormSwitch
                          label={'Can Evaluate Performance?'}
                          name={'evaluate_performance'}
                          checked={evaluatePerformance}
                          onClick={() => {
                            setEvaluatePerformance(!evaluatePerformance);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </>
            <hr className='divider mt-1 mb-1' />
            <>
              <div
                className={classNames('flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300', {
                  'pt-3 pb-3 bg-secondary-2-extralight rounded-md mb-2 px-2': showDepartmentLeaveSettings,
                })}
                onClick={() =>
                  setShowDepartmentLeaveSettings(!showDepartmentLeaveSettings)
                }
              >
                <span>Leave Settings</span>
                <div className='pr-4'>
                  <CollapsingIcon
                    defaultPosition='left'
                    isOpen={showDepartmentLeaveSettings}
                  />
                </div>
              </div>
              <div
                className={classNames('flex flex-col', {
                  hidden: !showDepartmentLeaveSettings,
                })}
              >
                <Collapse isOpen={showDepartmentLeaveSettings}>
                  <div className='grid md:grid-cols-1 grid-cols-1 gap-2 w-full p-3 border bg-gray-50 rounded-md'>
                    <div className='flex flex-row items-center'>
                      <div className='w-full'>
                        <FormInput
                          label='Application Start (From Date Of Employment)'
                          name='application_start'
                          type='number'
                          value={applicationStart}
                          onInput={(e) => {
                            setApplicationStart(e.target.value);
                          }}
                          inputRef={register(formValidation('number', true))}
                          error={errors.application_start}
                          errorMessage={
                            errors.application_start &&
                            errors.application_start.message
                          }
                        />
                      </div>
                      <span className='pt-1 italic text-[14px] pl-2 text-[#0d5225]'>
                        Days
                      </span>
                    </div>
                    <div className='flex flex-row items-center pb-4'>
                      <div className='w-full'>
                        <FormSelect
                          value={dayCount}
                          options={dayCountOptions}
                          onChange={(selected) => {
                            setDayCount(selected);
                          }}
                          label='Day Count'
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </>
            <hr className='divider mt-1 mb-1' />
            <>
              <div
                className={classNames('flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300', {
                  'pt-3 pb-3 bg-secondary-2-extralight rounded-md mb-2 px-2': showDepartmentAttendanceSettings,
                })}
                onClick={() =>
                  setShowDepartmentAttendanceSettings(
                    !showDepartmentAttendanceSettings,
                  )
                }
              >
                <span>Attendance Settings</span>
                <div className='pr-4'>
                  <CollapsingIcon
                    defaultPosition='left'
                    isOpen={showDepartmentAttendanceSettings}
                  />
                </div>
              </div>
              <div
                className={classNames('flex flex-col', {
                  hidden: !showDepartmentAttendanceSettings,
                })}
              >
                <Collapse isOpen={showDepartmentAttendanceSettings}>
                  <div className='grid md:grid-cols-1 grid-cols-1 gap-2 w-full p-3 border bg-gray-50 rounded-md'>
                    <div className='flex flex-row md:flex-col w-full justify-between'>
                      <div className='w-full md:w-1/2 pr-2'>
                        <FormInput
                          label='Resumption Time'
                          name='clock_in'
                          type='time'
                          value={clockIn}
                          onInput={(e) => {
                            setClockIn(e.target.value);
                          }}
                          inputRef={register(formValidation('time', true))}
                          error={errors.clock_in}
                          errorMessage={
                            errors.clock_in && errors.clock_in.message
                          }
                        />
                      </div>
                      <div className='w-full md:w-1/2 pl-2'>
                        <FormInput
                          label='Closing Time'
                          name='clock_out'
                          type='time'
                          value={clockOut}
                          onInput={(e) => {
                            setClockOut(e.target.value);
                          }}
                          inputRef={register(formValidation('time', true))}
                          error={errors.clock_out}
                          errorMessage={
                            errors.clock_out && errors.clock_out.message
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={
                        'flex w-[100%] flex-col items-center gap-3 mt-1 '
                      }
                    >
                      <div
                        className={
                          'flex w-full items-start justify-between gap-3 flex-col -mt-1'
                        }
                      >
                        <div className='text-color-gray text-[15px] '>
                          Work Days
                        </div>
                        <div className='flex flex-row items-center justify-between w-full p-[12px] border-1 border-gray-300 rounded -mt-4'>
                          {workDays.map((item, index) => (
                            <button
                              type='button'
                              className={
                                !item.work || item.work === 0
                                  ? 'flex flex-row items-center !h-[24px] px-2 border-1 rounded border-[#0d5225] text-[#0d5225] text-[12px] cursor-pointer'
                                  : 'flex flex-row items-center !h-[24px] px-2 border-1 rounded border-[#0d5225] bg-[#0d5225] text-color-white text-[12px] cursor-pointer'
                              }
                              onClick={() => {
                                let newArray = JSON.parse(
                                  JSON.stringify(workDays),
                                );
                                newArray[index].work =
                                  !item.work || item.work === 0 ? 1 : 0;
                                setWorkdays(newArray);
                              }}
                            >
                              {item.tag}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className='w-full mt-2'>
                        <FormSwitch
                          label={'Apply Attendance To Payroll?'}
                          name={'apply_to_payroll'}
                          checked={applyToPayroll}
                          onClick={() =>{
                            setApplyToPayroll(!applyToPayroll)
                          }}
                        />
                      </div>
                      <div className='w-full'>
                        <FormSwitch
                          label={'Employee Clockin With Link?'}
                          name={'clockin_with_link'}
                          checked={clockinWithLink}
                          onClick={() =>{
                            setClockinWithLink(!clockinWithLink)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </>
            {departmentType === 'add'?
            <>
              <hr className='divider mt-3 mb-3' />
              <FormSwitch
                label={'Do you want to add employees?'}
                name={'add_employee'}
                onClick={() => {
                  setAddEmployee(!addEmployee)
                }}
                checked={addEmployee}
              />
              <hr className='divider -mt-1 mb-2' />
              {addEmployee?
              <div className='border rounded-md bg-secondary-2-extralight p-2'>
                <label className='text-[13px] leading-[23px] font-normal text-color-gray mb-1'>
                  Select Multiple Employees
                </label>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  defaultOptions
                  className='text-[13px]'
                  placeholder={'Type to select multiple Employees'}
                  loadOptions={loadOptions}
                  onChange={(options) => setEmployees(options)}
                  classNamePrefix='mySelect'
                />
              </div>
              :
              null}
            </>
            :null}
            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                loading={addDepartmentloading || editDepartmentloading || addEmployeeLoading}
              />
            </div>
          </div>
          }
        </form>
      </div>
    </Modal>
  );
}

export default AddDepartment;
