import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useHistory } from "react-router-dom";
import DataTable from "../../../components/datatable";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import AddDepartment from "../../../components/modal/hr-section/departments/add-department";
import {
  useDeleteDepartment,
  useGetDepartments,
  useGetDepartmentSummary,
} from "../../../redux/human-resources/hook/departments";
import DepartmentSVG from "../../../assets/svg/department-widget-icon.svg";
import UnauthorizedPage from "../../errors/unauthorized";
import { useSelector } from "react-redux";
import DoYouWantToModal from "../../../components/modal/do-you-want-to";
import AddEmployeeToDepartment from "../../../components/modal/hr-section/departments/add-employee-to-department";
import AddTeamsToDepartment from "../../../components/modal/hr-section/departments/add-team-to-department";
// import { CardHr } from '../../../components/modal/hr-section/dashboard/cardHR';

const HRDepartmentPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.department_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: departments } = useGetDepartments(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const { mutateAsync: deleteDepartment } = useDeleteDepartment();
  const { data: getDepartmentSummary } = useGetDepartmentSummary();

  const [showAddDepartment, setShowAddDepartment] = useState(false);
  const [departmentData, setDepartmentData] = useState();

  const [tableType, setTableType] = useState("department");

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const deleteSingleDepartment = async (id) => {
    let index = departments.data.findIndex((item) => item.id === id);
    await deleteDepartment(departments.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const switchFunction = () => {
    if (tableType === "department") {
      setTableType("table");
    } else {
      setTableType("department");
    }
  };

  const history = useHistory();

  const tableConfig = {
    headers: ["Name", "Employees", "Teams", "Manager"],
    keys: ["name", "employee_count", "team_count", "department_manager"],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Department name",
        key: "name",
      },
    },
  };
  const [showAddEmployeeToDepartment, setShowAddEmployeeToDepartment] =
    useState(false);
  const [showAddTeamToDepartment, setShowAddTeamToDepartment] = useState(false);
  const [departmentAddData, setDepartmentAddData] = useState(false);

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = departments.data.findIndex((item) => item.id === id);
        history.push(`/department/view/${departments.data[index].id}`);
      },
    },
    {
      name: "Add Employees",
      color: "orange",
      action: (id) => {
        let index = departments.data.findIndex((item) => item.id === id);
        setDepartmentAddData(departments.data[index].id);
        setShowAddEmployeeToDepartment(true);
      },
    },
    {
      name: "Add Teams",
      color: "orange",
      action: (id) => {
        let index = departments.data.findIndex((item) => item.id === id);
        setDepartmentAddData(departments.data[index].id);
        setShowAddTeamToDepartment(true);
      },
    },
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = departments.data.findIndex((item) => item.id === id);
        setTitle("Update Department");
        setButtonText("Update Department");
        setDepartmentData(departments.data[index]);
        setShowAddDepartment(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = departments.data.findIndex((item) => item.id === id);
        setDeleteId(departments.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];
  const [departmentSummary, setDepartmentSummary] = useState();
  const [title, setTitle] = useState("Add Department");
  const [buttonText, setButtonText] = useState("ADD");

  useEffect(() => {
    if (getDepartmentSummary) {
      setDepartmentSummary([
        {
          title: "TOTAL DEPARTMENTS",
          value: getDepartmentSummary.department_count,
          icon: DepartmentSVG,
        },
      ]);
    } else {
      setDepartmentSummary([
        {
          title: "TOTAL DEPARTMENTS",
          value: 0,
          icon: DepartmentSVG,
        },
      ]);
    }
  }, [getDepartmentSummary]);

  return (
    <DashboardLayout title="Department Management">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {showAddEmployeeToDepartment ? (
            <AddEmployeeToDepartment
              isOpen={showAddEmployeeToDepartment}
              departmentData={departmentAddData}
              closeModal={() => setShowAddEmployeeToDepartment(false)}
            />
          ) : null}
          {showAddTeamToDepartment ? (
            <>
              <AddTeamsToDepartment
                isOpen={showAddTeamToDepartment}
                departmentData={departmentAddData}
                closeModal={() => setShowAddTeamToDepartment(false)}
              />
            </>
          ) : null}
          {showDoYouWant ? (
            <DoYouWantToModal
              isOpen={showDoYouWant}
              closeModal={() => setShowDoYouWant(false)}
              id={deleteId}
              title={"Are you sure you want to delete this department?"}
              buttonText={"DELETE DEPARTMENT"}
              btnFunction={deleteSingleDepartment}
            />
          ) : null}
          {showAddDepartment ? (
            <AddDepartment
              isOpen={showAddDepartment}
              setShowAddTeam
              closeModal={() => setShowAddDepartment(false)}
              departmentData={departmentData}
              title={title}
              buttonText={buttonText}
            />
          ) : null}

          <div className="flex flex-col -mt-2">
            <div className="flex flex-col bg-white mt-[10px] rounded-md">
              <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
                <div className="flex flex-col md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
                  Departments
                </div>
                <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
                  <div className="md:w-fit w-[100%]">
                    <Button
                      text="New Department"
                      type="button"
                      theme="primary"
                      className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                      textClass={"!text-[11px]"}
                      leftIcon={"add"}
                      onClick={() => {
                        setTitle("Add Department");
                        setButtonText("Save Department");
                        setDepartmentData(null);
                        setShowAddDepartment(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className="divider mt-2 mb-2" />
              <DataTable
                data={departments}
                tableConfig={tableConfig}
                actionConfig={actionConfig}
                updatePagination={(data) => setPagination(data)}
                computedActions={true}
                tableType={tableType}
                switchFunction={switchFunction}
                layoutSwitch={true}
                searchData={true}
                noCheck={true}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default HRDepartmentPage;
