import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseSVG from '../../../assets/svg/close.svg';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import WarningSVG from '../../../assets/svg/warning.svg';
import { useApprovePayReimbursementApplication, useApproveReimbursement, useDisburseApplication, } from '../../../redux/employees/hook/reimbursements';
import Modal from '../modal';
import { formatNumber } from '../../../utils/functions';
import FormOtpInput from '../../form-otp-input';
import { useGetCompanyAccountDetails } from '../../../redux/wallet/hook';
import moment from 'moment';

function ApproveReimbursement({
  isOpen,
  closeModal,
  reimbursement,
  applicationPay = false,
}) {
  useEffect(() => {
    if (applicationPay) {
      setPayType('approve_and_pay');
    }
  }, [applicationPay]);

  const [pin, setPIN] = useState('');

  const { user } = useSelector((state) => state.userSlice);

  const { data: companyAccountDetails  } = useGetCompanyAccountDetails();

  const [approveChecked, setApproveChecked] = useState(true);
  const [approvePayChecked, setApprovePayChecked] = useState(false);

  const [payType, setPayType] = useState('approve');
  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY-'));

  const { mutateAsync: disburseApplication, isLoading: disburseLoading } =
    useDisburseApplication();

  const {
    mutateAsync: approveAndPayReimbursement,
    isLoading: approveAndPayLoading,
  } = useApprovePayReimbursementApplication();

  const { mutateAsync: approveReimbursemenet, isLoading: approveLoading } =
    useApproveReimbursement();

  const submitForm = async () => {
    if (payType === 'approve') {
      let requestData = {
        application_id: reimbursement?.id,
        month: year + month,
      };
      await approveReimbursemenet(requestData).then(() => {
        closeModal();
      });
    } else if (payType === 'approve_and_pay') {
      if (
        user?.permissions.approval_permission?.initiator === 1 &&
        !applicationPay
      ) {
        const requestData = {
          transaction_pin: pin,
          application_id: reimbursement?.id,
        };
        await approveAndPayReimbursement(requestData).then(() => {
          closeModal();
        });
      } else if (
        user?.permissions.approval_permission?.approver === 1 &&
        applicationPay
      ) {
        const requestData = {
          transaction_pin: pin,
          application_id: reimbursement?.id,
        };
        await disburseApplication(requestData).then(() => {
          closeModal();
        });
      } else if (
        user?.permissions.approval_permission?.initiator === 1 &&
        user?.permissions.approval_permission?.approver === 1
      ) {
        const requestData = {
          transaction_pin: pin,
          application_id: reimbursement?.id,
        };
        await approveAndPayReimbursement(requestData).then(() => {
          closeModal();
        });
      }
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form'>
          <div className='flex flex-row justify-between pl-6 pr-6 pt-4 items-center'>
            <span className='header-3'>Approve Reimbursement</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col p-4 pt-0'>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-2 w-full'>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Employee Name</span>
                <span className='text-color-black text-[13px] font-semibold'>
                  {reimbursement?.first_name} {reimbursement?.last_name}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Mobile</span>
                <span className='text-color-black text-[13px] font-semibold'>
                  {reimbursement?.mobile}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>
                  Expenses Amount
                </span>
                <span className='text-color-black text-[13px] font-semibold'>
                  {formatNumber(reimbursement?.expenses_amount, 2)}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>
                  Expenses Description
                </span>
                <span className='text-color-black text-[13px] font-semibold'>
                  {reimbursement?.expenses_description}
                </span>
              </div>
            </div>
            <hr className='divider' />
            {!applicationPay ? (
              <div>
                <div className='flex md:flex-row flex-col justify-around md:items-center !w-[100%] text-[14px]'>
                  <div className='flex flex-row items-center justify-start gap-3 md:w-1/2 w-full font-bold'>
                    <FormInput
                      label='Approve only (Run with payroll)'
                      name='approval_type'
                      type='radio'
                      checked={approveChecked}
                      onClick={() => {
                        setApprovePayChecked(false);
                        if (!approveChecked) {
                          setApproveChecked(true);
                          setPayType('approve');
                        }
                      }}
                    />
                  </div>
                  <div className='flex flex-row items-center justify-start gap-3 md:w-1/2 w-full font-bold'>
                    <FormInput
                      label='Approve and pay now'
                      name='approval_type'
                      type='radio'
                      checked={approvePayChecked}
                      onClick={() => {
                        setApproveChecked(false);
                        if (!approvePayChecked) {
                          setApprovePayChecked(!approvePayChecked);
                          setPayType('approve_and_pay');
                        }
                      }}
                    />
                  </div>
                </div>
                <hr className='divider mt-1' />
                {payType === 'approve' ? (
                  <>
                    <span className='text-color-gray text-[13px]'>
                      Select Payroll Month/Year To Be Applied
                    </span>
                    <div className='flex flex-row md:flex-col w-full justify-between'>
                      <div className='w-full md:w-1/2'>
                        <FormSelect
                          defaultValue={month}
                          options={monthOptions}
                          onChange={(selected) => {
                            setMonth(selected);
                          }}
                        />
                      </div>
                      <div className='w-full md:w-1/2 pl-2'>
                        <FormSelect
                          defaultValue={year}
                          options={yearOptions}
                          onChange={(selected) => {
                            setYear(selected);
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}

            <div className='w-full mt-1'>
              {payType === 'approve' ? (
                <div className='w-full mb-0'>
                  <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-3'>
                    <FormOtpInput
                      value={pin}
                      valueLength={4}
                      onChange={(value) => setPIN(value)}
                      label={'Enter your new 4 digit PIN'}
                    />
                  </div>
                  <Button
                    text='APPROVE'
                    type='button'
                    onClick={() => {
                      submitForm();
                    }}
                    loading={approveLoading}
                  />
                </div>
              ) : (
                <>
                  <div className='flex flex-col justify-center mb-4 -mt-2'>
                    <div className='flex flex-row border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light'>
                      <div className='w-[70px] pr-4 flex flex-row items-center '>
                        <img src={WarningSVG} alt='' srcSet='' />
                      </div>
                      <span className='text-justify text-[13px]'>
                        Ropay will debit <span className="font-semibold">N{formatNumber(reimbursement?.expenses_amount)}</span> from
                        your account. You will be charged a fee of <span className="font-semibold">N44.99</span> for
                        this transaction. Please Make sure the funds are
                        available.
                      </span>
                    </div>
                  </div>
                  
                    <div className='w-full mb-2'>
                      <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4'>
                        <FormOtpInput
                          value={pin}
                          valueLength={4}
                          onChange={(value) => setPIN(value)}
                          label={'Enter your new 4 digit PIN'}
                        />
                      </div>
                      {applicationPay && user?.permissions.approval_permission?.approver === 1 ? (
                      <Button
                        text={'APPROVE PAYMENT'}
                        type='button'
                        disabled={false}
                        loading={approveAndPayLoading || disburseLoading}
                        onClick={() => submitForm()}
                      />
                      ) : !applicationPay && user?.permissions.approval_permission?.initiator === 1 ? (
                      <Button
                        text={
                          user?.permissions.approval_permission?.initiator ===
                            1 &&
                          user?.permissions.approval_permission?.approver === 1
                            ? 'APPROVE AND PAY'
                            : 'APPROVE AND INITIATE PAYMENT'
                        }
                        type='button'
                        disabled={false}
                        loading={approveAndPayLoading || disburseLoading}
                        onClick={() => {
                          submitForm();
                        }}
                      />
                      ) : !applicationPay &&
                        user?.permissions.approval_permission?.approver === 1 ? (
                        <div className='text-center w-full mb-[20px]'>
                          You are not authorised to Initiate payments <br /> Please
                          Contact Your Administrator.
                        </div>
                      ) : (
                        <div className='text-center w-full mb-[20px]'>
                          You are not Authorised to Approve payment <br /> Please
                          Contact Your Administrator.
                        </div>
                      )}
                    </div>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ApproveReimbursement;
