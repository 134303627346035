import React from 'react';
import DropDown from './dropdown';
import DropDownSVG from '../assets/svg/dropdown.svg';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Initials } from '../utils/functions';

const ProfileMenu = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.userSlice);

  const menus = [
    {
      name: 'Contact Us',
      icon: '',
      route: '/contact-us',
    },
    {
      name: 'Logout',
      icon: '',
      route: '/logout',
    },
  ];

  return (
    <div className='flex flex-row items-center'>
      {/* Profile Image placeholder */}
      <div className='flex font-bold justify-center items-center h-[40px] w-[40px] rounded-[40px] header-4 border-2 border-secondary-2 bg-green-200'>
        {Initials(user?.first_name, user?.last_name)}
      </div>
      <div className='ml-2 flex flex-col rounded'>
        <DropDown
          toggler={
            <div className='d-flex flex-row'>
              <div className='flex flex-col text-start'>
                <span className='text-[14px]'>
                  {' '}
                  {user?.first_name + ' ' + user?.last_name}{' '}
                </span>
                <span className='text-color-gray text-[12px] leading-[12px]'>
                  Admin
                </span>
              </div>
              <img src={DropDownSVG} alt='' className='ml-2' />
            </div>
          }
        >
          {menus.map((item, index) => (
            <button
              key={index}
              className='flex flex-row w-full text-start py-[8px] px-[12px] text-[13px] bg-color-white cursor-pointer hover:bg-gray-100 hover:font-bold outline-none duration-300'
              onClick={() => {
                history.push(item.route);
              }}
            >
              {item.name}
            </button>
          ))}
        </DropDown>
        <div></div>
      </div>
    </div>
  );
};

export default ProfileMenu;
