import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import Button from "../../button";
import AddPerformanceOKR from "../../modal/hr-section/performance/add-okr";
import {
  useDeleteOKR,
  useGetPerformanceOKRS,
} from "../../../redux/human-resources/hook/performance";

const PerformanceOKRTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const [okrData, setOkrData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [showAddOkr, setShowAddOkr] = useState(null);
  const [type, setType] = useState(null);

  const { mutateAsync: deleteOKR } = useDeleteOKR();

  const { data: okrs } = useGetPerformanceOKRS(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const tableConfig = {
    headers: [
      "Title",
      "Source",
      "Source Name",
      // 'Evaluation Frequency',
      // 'Auto-evaluate'
    ],
    keys: [
      "okr_title",
      "source",
      //source_id
      "source_name",
      // 'evaluation_frequency',
      // 'auto_evaluate_converted',
    ],
    mobileHeader: {
      left: {
        title: "Title",
        key: "okr_title",
      },
      right: {
        title: "Period Covered",
        key: "period",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Update",
      color: "green",
      action: (id) => {
        let index = okrs.data.findIndex((item) => item.id === id);
        setTitle("Update Objectives and Key results");
        setButtonText("Update");
        setType("edit");
        setOkrData(okrs.data[index]);
        setShowAddOkr(true);
      },
    },
    {
      name: "Duplicate",
      color: "orange",
      action: (id) => {
        let index = okrs.data.findIndex((item) => item.id === id);
        setTitle("Duplicate Objectives and Key results");
        setType("duplicate");
        setButtonText("Duplicate");
        setOkrData(okrs.data[index]);
        setShowAddOkr(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = okrs.data.findIndex((item) => item.id === id);
        deleteOKR(okrs.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col gap-4 -mt-3">
      {showAddOkr ? (
        <AddPerformanceOKR
          isOpen={showAddOkr}
          closeModal={() => setShowAddOkr(false)}
          okrData={okrData}
          type={type}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col">
        <div className=" bg-white border rounded-md text-[12px] p-2 text-gray-600">
          <span className="font-bold text-[14px]">
            Objectives and Key Results (OKR)
          </span>{" "}
          is a collaborative goal-setting methodology used by teams and
          individuals to set challenging, ambitious goals with measurable
          results. OKRs are how you track progress, create alignment, and
          encourage engagement around measurable goals.
        </div>
      </div>
      <div className="flex flex-col bg-white rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Objectives and Key Results (OKRs) List
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Performamce OKR"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => {
                  setShowAddOkr(true);
                  setOkrData(null);
                  setTitle("Add Objectives and Key results");
                  setButtonText("Save");
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={okrs}
          tableConfig={tableConfig}
          updatePagination={(data) => setPagination(data)}
          actionConfig={actionConfig}
          searchData={true}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default PerformanceOKRTab;
