import React from 'react';
import ProtectedRoute from '../protected';
import AboutPage from '../../pages/onboarding/about-company';
import RateSettings from '../../pages/onboarding/rate-settings';

export const CompanyBoardingRoutes = [
  <ProtectedRoute
    exact
    path='/onboarding/about-company'
    component={AboutPage}
  />,
  <ProtectedRoute
    exact
    path='/onboarding/rate-settings'
    component={RateSettings}
  />,
];
