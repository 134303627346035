import React, { useState } from 'react';
import Button from '../../button';
import ButtonSelect from '../../button-select';
import CloseSVG from '../../../assets/svg/close.svg';
import SingleAirtime from './single-airtime';
import BulkPurchaseAirtime from './bulk-airtime';
import Modal from '../modal';

function SelectAirtimeOption({ isOpen, closeModal, refresh }) {
  const [option, setOption] = useState('single');
  const [showPurchaseairtime, setShowPurchaseAirtime] = useState(false);
  const [showPurchaseBulkBonus, setShowPurchaseBulkBonus] = useState(false);

  function submit() {
    if (option === 'single') {
      setShowPurchaseAirtime(true);
    } else if (option === 'bulk') {
      setShowPurchaseBulkBonus(true);
    }
  }

  return (
    <>
      {showPurchaseairtime ? (
        <SingleAirtime
          isOpen={showPurchaseairtime}
          closeModal={() => {
            setShowPurchaseAirtime(false);
            closeModal();
          }}
        />
      ) : null}
      <BulkPurchaseAirtime
        isOpen={showPurchaseBulkBonus}
        closeModal={() => setShowPurchaseBulkBonus(false)}
      />

      <Modal
        scrollable={true}
        className='!max-w-[700px] !rounded-none h-full overflow-auto'
        isOpen={isOpen}
        centered={true}
      >
        <div className='overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Select Airtime Option</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col items-center w-full pb-3'>
            <span className='text-[15px] -mb-3'>
              Select your desired option to purchase Airtime
            </span>
            <div className='flex flex-col justify-center min-h-[400px] max-w-[85%] w-full'>
              <ButtonSelect
                title={'Purchase Single Airtime'}
                subtitle={'Send Airtime to a single person'}
                isActive={option === 'single'}
                onClick={() => setOption('single')}
              />

              <ButtonSelect
                title={'Purchase Bulk Airtime'}
                subtitle={'Purchase Airtime using a XLSX file'}
                isActive={option === 'bulk'}
                onClick={() => setOption('bulk')}
                className='mt-8'
              />

              <div className='w-full mt-[20px] '>
                <Button text='NEXT' type='submit' onClick={() => submit()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SelectAirtimeOption;
