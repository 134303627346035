import React from 'react';
import HRAttendancePage from '../../pages/hr/attendance-management';
import AttendanceScheduleViewPage from '../../pages/hr/attendance-management/view-attendance-schedule';
import ProtectedRoute from '../protected';

export const HRAttendanceRoutes = [
  <ProtectedRoute exact path='/attendance/summary' component={HRAttendancePage} />,
  <ProtectedRoute exact path='/attendance/analysis' component={HRAttendancePage} />,
  <ProtectedRoute exact path='/attendance/schedule' component={HRAttendancePage} />,
  <ProtectedRoute exact path='/attendance/settings' component={HRAttendancePage} />,
  <ProtectedRoute exact path='/attendance/schedule/view/:ref' component={AttendanceScheduleViewPage} />,
];