import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import Button from "../../button";
import DataTable from "../../datatable";
import {
  useGetDepartmentTeams,
  useRemoveTeamsFromDepartment,
} from "../../../redux/human-resources/hook/departments";
import AddTeamsToDepartment from "../../modal/hr-section/departments/add-team-to-department";

const DepartmentTeamTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });
  const { id } = useParams();

  const { data: department_teams } = useGetDepartmentTeams(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const [showAddTeamToDepartment, setShowAddTeamToDepartment] = useState(false);
  const { mutateAsync: removeFromDepartment } = useRemoveTeamsFromDepartment();

  const removeTeamsFromDepartment = (id) => {
    let index = department_teams.data.findIndex((item) => item.id == id);
    let payload = {
      branch: department_teams.id,
      team_ids: department_teams.data[index].id,
    };
    removeFromDepartment(payload);
  };

  const tableConfig = {
    headers: ["Date Added", "Team Name"],
    keys: ["created", "team_name"],
    mobileHeader: {
      left: {
        title: "Date Added",
        key: "created",
      },
      right: {
        title: "Team Name",
        key: "team_name",
      },
    },
  };

  const actionConfig = [
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        removeTeamsFromDepartment(id);
      },
    },
  ];

  // const bulkActionConfig = [
  //   {
  //     label: 'Remove',
  //     value: 'active',
  //     action: () => {},
  //   },
  // ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddTeamToDepartment ? (
        <>
          <AddTeamsToDepartment
            isOpen={showAddTeamToDepartment}
            departmentData={id}
            closeModal={() => setShowAddTeamToDepartment(false)}
          />
        </>
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Teams
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Add Teams To Department"
              type="submit"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              onClick={() => {
                setShowAddTeamToDepartment(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2" />

      <DataTable
        data={department_teams}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
      />
    </div>
  );
};

export default DepartmentTeamTab;
