import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../button";
import {
  useGetPerformanceByEmployee,
  useGetPerformances,
} from "../../../redux/human-resources/hook/performance";
import EvaluatePerformance from "../../modal/hr-section/performance/evaluate-performance";

const EmployeePerformanceTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    source: "employee",
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
    start_date: "",
    end_date: "",
  });

  const { id } = useParams();

  const [showEvaluatePerformance, setShowEvaluatePerformance] = useState(false);
  const [performanceData, setPerformanceData] = useState(false);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const { data: performances } = useGetPerformanceByEmployee(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date
  );

  const history = useHistory();

  const tableConfig = {
    headers: ["Date Created", "Employee Name", "Mobile", "Period Covered"],
    keys: ["created", "employeeName", "mobile", "period"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Period Covered",
        key: "period",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "green",
      action: (id) => {
        let index = performances.data.findIndex((item) => item.id === id);
        setPerformanceData(performances.data[index]);
        setTitle("Update Performance Evaluation");
        setButtonText("UPDATE");
        setShowEvaluatePerformance(true);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showEvaluatePerformance ? (
        <EvaluatePerformance
          isOpen={showEvaluatePerformance}
          closeModal={() => setShowEvaluatePerformance(false)}
          performanceData={performanceData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Performance Evaluations
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Evaluate Performance"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              onClick={() => {
                setTitle("Evaluate Performance");
                setButtonText("EVALUATE");
                setPerformanceData(null);
                setShowEvaluatePerformance(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={performances}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        filterByDate={true}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        noCheck={true}
      />
    </div>
  );
};

export default EmployeePerformanceTab;
