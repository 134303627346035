import React, { useEffect } from "react";
import { useState } from "react";
import classNames from "classnames";
import AddAdminInfo from "../modal/settings/add-admin-info";
import AddAdminPermissions from "../modal/settings/add-admin-permissions";
import TransferOwnership from "../modal/settings/transfer-ownership";
import { useSelector } from "react-redux";
import {
  useGetCompanyAdmins,
  useRemoveCompanyAdmin,
} from "../../redux/company/hook";
import ChangePassword from "../modal/settings/change-password";
import Button from "../button";
import LoadingBar from "../loader-bar";
import { useDisable2Fa, useUpdateProfile } from "../../redux/user/hook";
import WarningSVG from "../../assets/svg/warning.svg";
import EnableTwoFactorAuthentication from "../modal/settings/enable-2fa";
import UpdatePINModal from "../modal/update-pin";

const AdminManagement = () => {
  useEffect(() => {}, []);

  const { mutateAsync: removeCompanyAdmin, isLoading: removeLoading } =
    useRemoveCompanyAdmin();

  const { section } = useSelector((state) => state.sectionSlice);

  const [selectedAdminIndex, setSelectedAdminIndex] = useState(0);

  const { data: companyAdmins, isLoading } = useGetCompanyAdmins();
  const { mutateAsync: disableTwoFa, isLoading: disableTwoFaLoading } =
    useDisable2Fa();
  const { user } = useSelector((state) => state.userSlice);

  const [showAddPermissions, setShowAddPermissions] = useState(false);

  const [payloadData, setPayloadData] = useState(null);

  const [showAdminInfo, setShowAdminInfo] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [showChangePIN, setShowChangePIN] = useState(false);

  const [showTransferOwnership, setShowTransferOwnership] = useState(false);

  const [title, setTitle] = useState("Edit Admin Permissions");
  const [source, setSource] = useState("edit");
  const [sourceData, setSourceData] = useState(null);
  const [showEnable2Fa, setShowEnable2Fa] = useState(null);

  const [userPermissions, setUserPermissions] = useState(null);

  const { mutateAsync: updateCompanyAdmin, isLoading: updateLoading } =
    useUpdateProfile();

  useEffect(() => {
    if (companyAdmins) {
      setSourceData(companyAdmins.data[selectedAdminIndex]);
    }
  }, [companyAdmins, selectedAdminIndex]);

  useEffect(() => {
    if (user && companyAdmins) {
      for (let i = 0; i < companyAdmins.data.length; i++) {
        if (companyAdmins.data[i].id === user?.id) {
          setUserPermissions(companyAdmins.data[i].permissions);
          break;
        }
      }
    }
  }, [companyAdmins, user]);

  const showAdminPermissionModal = async () => {
    let payload = {
      firstname: companyAdmins.data[selectedAdminIndex].first_name,
      lastname: companyAdmins.data[selectedAdminIndex].last_name,
      email: companyAdmins.data[selectedAdminIndex].email,
      mobile: companyAdmins.data[selectedAdminIndex].mobile,
    };
    setSourceData(companyAdmins.data[selectedAdminIndex]);
    setPayloadData(payload);
    setShowAddPermissions(true);
  };

  const removeAdmin = async () => {
    const adminRemoved = companyAdmins.data[selectedAdminIndex].id;

    await removeCompanyAdmin(adminRemoved).then((result) => {
      window.location.reload();
    });
  };

  return (
    <>
      {showChangePassword ? (
        <ChangePassword
          isOpen={showChangePassword}
          closeModal={() => setShowChangePassword(false)}
        />
      ) : null}

      {showChangePIN ? (
        <UpdatePINModal
          isOpen={showChangePIN}
          closeModal={() => setShowChangePIN(false)}
        />
      ) : null}

      {showAdminInfo ? (
        <AddAdminInfo
          isOpen={showAdminInfo}
          closeModal={() => setShowAdminInfo(false)}
          // setEditMode={setEditMode}
        />
      ) : null}

      {showAddPermissions ? (
        <AddAdminPermissions
          isOpen={showAddPermissions}
          payloadData={payloadData}
          closeModal={() => setShowAddPermissions(false)}
          onCompleted={() => {
            setShowAddPermissions(false);
            // setEditMode(false)
          }}
          source={source}
          sourceData={sourceData}
          title={title}
        />
      ) : null}

      {showTransferOwnership ? (
        <TransferOwnership
          isOpen={showTransferOwnership}
          closeModal={() => setShowTransferOwnership(false)}
        />
      ) : null}
      {showEnable2Fa ? (
        <EnableTwoFactorAuthentication
          isOpen={showEnable2Fa}
          closeModal={() => setShowEnable2Fa(false)}
        />
      ) : null}
      <div className="flex flex-col bg-white w-full box-shadowh-full min-h-fit rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Admin Management
          </div>

          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            {user?.role === "Owner" ? (
              <div className="md:w-fit w-[100%]">
                <Button
                  text="New Admin"
                  type="button"
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[12px] font-medium"}
                  onClick={() => setShowAdminInfo(true)}
                  leftIcon={"add"}
                />
              </div>
            ) : null}
            {user.enable_two_fa === 1 ? (
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Disable (2FA)"
                  type="button"
                  theme="primary"
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center !bg-red-700"
                  textClass={"!text-[12px] font-medium"}
                  loading={disableTwoFaLoading}
                  onClick={() => {
                    disableTwoFa();
                  }}
                />
              </div>
            ) : (
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Enable (2FA)"
                  type="button"
                  theme="primary"
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center !bg-hr-secondary-1"
                  textClass={"!text-[12px] font-medium"}
                  onClick={() => {
                    setShowEnable2Fa(true);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <hr className="divider mt-2 mb-2" />
        {isLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px] pr-8">
            <LoadingBar loading={isLoading} />
          </div>
        ) : (
          <>
            {companyAdmins ? (
              <div className="flex flex-col md:flex-row h-full pl-6 pt-3 pb-4 pr-6">
                <div className="flex flex-col md:max-w-[450px] w-full ">
                  <div className="flex justify-end mb-2 w-full md:mt-0 !h-[35px] gap-2">
                    <Button
                      text="Change Transaction PIN"
                      type="button"
                      className="h-[25px] w-fit p-2"
                      onClick={() => setShowChangePIN(true)}
                      textClass={"!text-[12px] font-medium"}
                    />
                    <Button
                      text="Change Password"
                      type="button"
                      className="h-[25px] w-fit p-2"
                      onClick={() => setShowChangePassword(true)}
                      textClass={"!text-[12px] font-medium"}
                    />
                  </div>
                  {companyAdmins.data.map((item, index) => (
                    <div
                      className={classNames(
                        "w-full max-w-[100%] md:max-w-[450px] h-[80px] flex flex-row items-center px-3 justify-between cursor-pointer border border-gray-300 rounded-md mb-3 hover:bg-gray-50 duration-300",
                        {
                          "bg-[#eff2fe]": index === selectedAdminIndex,
                        }
                      )}
                      onClick={() => {
                        setSelectedAdminIndex(index);
                        // setEditMode(false)
                      }}
                      key={index}
                    >
                      <div className="flex flex-col">
                        <span className="header-5">
                          {item.first_name} {item.last_name}
                        </span>
                        <span className="p4-regular">{item.email}</span>
                      </div>
                      <div className="flex justify-end w-[60px]">
                        <button className="bg-secondary-2-extralight w-[80px] h-[40px] rounded">
                          <span
                            className={
                              section.name !== "core hr"
                                ? "header-6 text-secondary-2"
                                : "header-6 text-[#0d5225]"
                            }
                          >
                            {item.permissions.company_permission?.role}
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="w-[100%] md:ml-4">
                  <div className="border border-color-line rounded-md pr-4 pl-4 pt-4 pb-4 flex flex-col bg-highlight">
                    <div className="flex flex-row justify-end gap-[20px]">
                      <div className="flex justify-between items-center w-full">
                        {userPermissions?.company_permission.setting_write ===
                          1 &&
                        user?.role === "Owner" &&
                        user?.id !==
                          companyAdmins?.data[selectedAdminIndex]?.id ? (
                          <div className="flex justify-end w-full md:mt-0 !h-[35px]">
                            <Button
                              text="Remove Admin"
                              type="button"
                              className="h-[25px] w-fit p-2 !bg-red-600"
                              onClick={() => removeAdmin()}
                              textClass={"!text-[12px] font-medium"}
                              loading={removeLoading}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <>
                      <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full p4">
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            First name
                          </span>
                          <span className="mt-1 text-color-black font-semibold">
                            {companyAdmins?.data[selectedAdminIndex].first_name}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            Last name
                          </span>
                          <span className="mt-1 text-color-black font-semibold">
                            {companyAdmins?.data[selectedAdminIndex].last_name}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            Email Address
                          </span>
                          <span className="mt-1 text-color-black font-semibold">
                            {companyAdmins?.data[selectedAdminIndex].email}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="header-5 text-color-gray">
                            Phone Number
                          </span>
                          <span className="mt-1 text-color-black font-semibold">
                            {companyAdmins?.data[selectedAdminIndex].mobile}
                          </span>
                        </div>
                      </div>
                      <div className="grid md:grid-cols-1  grid-cols-1 gap-4 w-full p4">
                        {userPermissions?.company_permission.setting_write ===
                          1 &&
                        user?.id !==
                          companyAdmins.data[selectedAdminIndex]?.id ? (
                          <div className="flex justify-end cursor-pointer mt-3 w-[100%]">
                            <Button
                              text="View/Update Permissions"
                              type="button"
                              theme="secondary"
                              className="h-[25px] w-fit p-2"
                              onClick={() => {
                                setTitle("Edit Admin Permissions");
                                setSource("edit");
                                showAdminPermissionModal();
                              }}
                              textClass={"!text-[12px] font-medium"}
                            />
                          </div>
                        ) : (
                          <div className="flex justify-end w-full cursor-pointer mt-3">
                            <Button
                              text="View Permissions"
                              type="button"
                              theme="secondary"
                              className="h-[25px] w-fit p-2"
                              onClick={() => {
                                setTitle("View Permissions Page");
                                setSource("view");
                                showAdminPermissionModal();
                              }}
                              textClass={"!text-[11px]"}
                            />
                          </div>
                        )}
                      </div>

                      {user?.role === "Owner" &&
                      companyAdmins?.data.length > 1 &&
                      user?.id ===
                        companyAdmins.data[selectedAdminIndex]?.id ? (
                        <div className="flex flex-col">
                          <hr className="divider mb-2" />
                          <span className="text-[15px] mb-1 font-semibold">
                            Owner Privilege:
                          </span>
                          <Button
                            text="Transfer Account Ownership"
                            type="button"
                            theme="secondary"
                            className="h-[25px] w-fit p-2"
                            onClick={() => {
                              setShowTransferOwnership(true);
                            }}
                            textClass={"!text-[12px] font-medium"}
                          />
                        </div>
                      ) : null}
                    </>
                    {user?.id == companyAdmins.data[selectedAdminIndex]?.id ? (
                      <div className="flex flex-col justify-center mt-4 duration-500">
                        <div className="flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light !text-[13px]">
                          <div className="w-[70px] pr-2 flex flex-row items-center ">
                            <img src={WarningSVG} alt="" srcSet="" />
                          </div>
                          <div>
                            If you wish to update your personal information,
                            kindly reach out to{" "}
                            <span className="font-extrabold !text-[13px]">
                              hello@ropay.ng
                            </span>{" "}
                            for assistance.
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* // )} */}
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default AdminManagement;
