import { Axios } from '../axios';

const getDashboard = async () => {

  const { data } = await Axios.get('/hr/dashboard',
  {
    timeout: 30000
  });
  return data?.data;
};

export const hrDashboard = {
  getDashboard,
};
