import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import LoadingBar from "../../../../components/loader-bar";
import Button from "../../../../components/button";
import classNames from "classnames";
import TickSVG from "../../../../assets/svg/tick.svg";
import { useGetOnboardingWorkflow } from "../../../../redux/employees/hook/onboarding";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RemoveSVG from "../../../../assets/svg/remove.svg";
import AddSmallSVG from "../../../../assets/svg/add-icon-small.svg";
import { useUpdateOnboardingSubWorkflow } from "../../../../redux/employees/hook/onboarding";
import ViewFile from "../../../../components/modal/hr-section/employee-onboarding/view-file";
import OnboardingQuestions from "../../../../components/modal/hr-section/employee-onboarding/stage-questions";
import AddOnboardingSubWorkflow from "../../../../components/modal/hr-section/employee-onboarding/add-subworkflow";
import FormInput from "../../../../components/form-input";
import { store } from "../../../../redux";
import { setAlert } from "../../../../redux/components/components-slice";
import { formValidation } from "../../../../utils/functions";
import FormFileInput from "../../../../components/form-file-input";
import { useForm } from "react-hook-form";
import FormSelect from "../../../../components/form-select";
import FormSwitch from "../../../../components/form-switch";
import EmptyTableSVG from "../../../../assets/svg/empty-table.svg";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  paddingRight: 16,
  paddingLeft: 16,
  margin: `0 0 12px 0`,
  // borderRadius: '5px',
  // border: '1px solid #E5E4E2',

  // change background colour if dragging
  background: "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: "whitesmoke",
  padding: grid,
  width: "100%",
});

const ViewEmployeeOnboardingWorkflow = () => {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  //Questions
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState();

  const [askQuestions, setAskQuestions] = useState(false);

  const [questions, setQuestions] = useState([
    [
      {
        id: `item-${1}`,
        question: "",
        question_type: "multiple_choice",
        options: [{ option: "Option 1" }, { option: "Option 2" }],
      },
    ],
  ]);

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(questions[sInd], source.index, destination.index);
      const newState = [...questions];
      newState[sInd] = items;
      setQuestions(newState);
    } else {
      const result = move(
        questions[sInd],
        questions[dInd],
        source,
        destination
      );
      const newState = [...questions];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setQuestions(newState.filter((group) => group.length));
    }
  }

  const QuestionTypes = [
    { label: "Multiple Choice", value: "multiple_choice" },
    { label: "Open Ended", value: "open_ended" },
  ];

  const [maxId, setMaxId] = useState(1);

  const addQuestion = () => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    if (newArray.length < 10) {
      newArray = [
        ...newArray,
        ...[
          {
            id: `item-${maxId + 1}`,
            question: "",
            question_type: "multiple_choice",
            options: [{ option: "Option 1" }, { option: "Option 2" }],
          },
        ],
      ];
      setMaxId(maxId + 1);
      setQuestions([newArray]);
    }
  };

  const addOption = (index) => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    let data = [...newArray];
    if (data[index].options.length < 4) {
      // only splice array when item is found
      data[index].options.push({ option: "" }); // 2nd parameter means remove one item only
      setQuestions([[...data]]);
    }
  };

  const removeQuestion = (subIndex) => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    let data = [...newArray];
    if (subIndex > -1) {
      // only splice array when item is found
      data.splice(subIndex, 1); // 2nd parameter means remove one item only
      setQuestions([[...data]]);
    }
  };

  const removeOption = (subIndex, optionIndex) => {
    let newArray = JSON.parse(JSON.stringify(questions[0]));

    let data = [...newArray];
    if (data[subIndex].options.length > 2) {
      if (subIndex > -1 && optionIndex > -1) {
        // only splice array when item is found
        data[subIndex].options.splice(optionIndex, 1); // 2nd parameter means remove one item only
        setQuestions([[...data]]);
      }
    } else {
      store.dispatch(
        setAlert(true, "error", "There must be at least 2 options")
      );
    }
  };

  const { id } = useParams();

  const { data: onboarding_workflow, isLoading } = useGetOnboardingWorkflow(id);

  const [subworkflows, setSubworkflows] = useState(null);

  const [showFile, setshowFile] = useState(false);
  const [showViewQuestions, setshowViewQuestions] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const [showAddWorkflow, setShowAddWorkflow] = useState(null);

  const [workflowDataForSub, setWorkflowDataForSub] = useState(null);

  const [showAddSubWorkFlow, setShowAddSubWorkflow] = useState(false);

  const [currentData, setCurrentData] = useState(null);
  const [onboardingId, setOnboardingId] = useState(null);

  const { mutateAsync: editSubWorkflow, isLoading: editWorkflowloading } =
    useUpdateOnboardingSubWorkflow();

  useEffect(() => {
    if (currentIndex) {
      if (onboarding_workflow.sub_workflows) {
        setCurrentData(onboarding_workflow.sub_workflows[currentIndex - 1]);
      }
    }
  }, [currentIndex]);

  useEffect(() => {
    if (onboarding_workflow) {
      setOnboardingId(onboarding_workflow.id);
      if (onboarding_workflow.sub_workflows) {
        setCurrentIndex(1);
        setSubworkflows(onboarding_workflow.sub_workflows);
      }
    }
  }, [onboarding_workflow]);

  useEffect(() => {
    if (currentData) {
      if (currentData.questions && currentData.questions.length > 0) {
        setAskQuestions(true);
        let newQuestions = [];
        let maxId = 1;
        currentData.questions.forEach((item) => {
          let question = {
            id: `item-${maxId}`,
            question: item.question,
            question_type: item.question_type,
            options: item.options ? item.options : [],
          };
          maxId++;
          setMaxId(maxId);
          newQuestions.push(question);
        });
        setQuestions([newQuestions]);
      } else {
        setMaxId(1);
        setQuestions([
          [
            {
              id: `item-${1}`,
              question: "",
              question_type: "multiple_choice",
              options: [{ option: "Option 1" }, { option: "Option 2" }],
            },
          ],
        ]);
        setAskQuestions(false);
      }
    }
  }, [currentData]);

  const submitForm = async (data) => {
    const formData = new FormData();
    if (data?.file) {
      formData.append("file", data?.file[0]);
    }
    formData.append("workflow_id", currentData?.workflow_id);
    formData.append("name", currentData?.name);
    formData.append("description", currentData?.description);
    formData.append("upload_file", currentData?.upload_file);
    formData.append("filename", currentData?.filename);
    formData.append("collect_document", currentData?.collect_document);
    formData.append("document_name", currentData?.document_name);
    if (askQuestions) {
      let newQuestions = [];
      questions[0].forEach((item, index) => {
        if (item.question !== null && item.question !== "") {
          if (item.question_type === "open_ended") {
            item.options = [];
            newQuestions.push(item);
          } else {
            if (item.options.length > 1) {
              newQuestions.push(item);
            }
          }
        }
      });
      if (newQuestions.length > 0) {
        formData.append("questions", JSON.stringify(newQuestions));
      } else {
        formData.append("questions", JSON.stringify([]));
      }
    } else {
      formData.append("questions", JSON.stringify([]));
    }

    let editPayload = {
      subworkflow_id: currentData?.id,
      data: formData,
    };
    await editSubWorkflow(editPayload).then(() => {
      let newArray = JSON.parse(JSON.stringify(currentData));

      newArray.file_path = null;
      setCurrentData(newArray);
    });
  };

  return (
    <DashboardLayout title="Employee Onboarding Page">
      '
      {showAddSubWorkFlow ? (
        <AddOnboardingSubWorkflow
          title={"Add Sub Workflows"}
          isOpen={showAddSubWorkFlow}
          workflowData={workflowDataForSub}
          buttonText="Save and Complete"
          closeModal={() => setShowAddSubWorkflow(false)}
        />
      ) : null}
      {showViewQuestions ? (
        <OnboardingQuestions
          isOpen={showViewQuestions}
          closeModal={() => setshowViewQuestions(false)}
          stage={onboarding_workflow?.workflow?.sub_workflows[currentIndex - 1]}
        />
      ) : null}
      {showFile ? (
        <ViewFile
          isOpen={showFile}
          closeModal={() => setshowFile(false)}
          stage={onboarding_workflow?.workflow?.sub_workflows[currentIndex - 1]}
        />
      ) : null}
      <div className="flex flex-col gap-4 -mt-8">
        <div className="flex flex-col bg-white min-h-fit">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
            <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
              {onboarding_workflow?.name}
            </div>
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
              <div className="md:w-fit w-[100%]">
                <Button
                  text="New Subworkflow"
                  type="button"
                  leftIcon={"add"}
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  onClick={() => {
                    setTitle("Add Subworkflow");
                    setButtonText("Save");
                    setWorkflowDataForSub(onboarding_workflow);
                    setShowAddSubWorkflow(true);
                  }}
                  textClass="!text-[12px]"
                />
              </div>
            </div>
          </div>
          <hr className="divider" />
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <>
              {onboarding_workflow ? (
                <>
                  <div className="flex md:flex-row flex-col">
                    <div className=" flex flex-col gap-4 w-full">
                      <div>
                        {isLoading ? (
                          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                            <LoadingBar loading={isLoading} />
                          </div>
                        ) : (
                          <>
                            {subworkflows && subworkflows.length > 0 ? (
                              <div className="flex flex-col md:flex-row h-full p-4 pt-1">
                                <div className="flex flex-col md:max-w-[400px] w-full p-4 pt-3 pb-3 border border-gray-400 rounded">
                                  <span className="header-4 text-color-gray mb-2">
                                    Onboarding Stages
                                  </span>
                                  {subworkflows?.map((item, index) => (
                                    <div
                                      className={classNames(
                                        "w-full max-w-[100%] md:max-w-[450px] h-[80px] rounded-md flex flex-row items-center px-3 justify-between cursor-pointer  border-1 mb-3 ",
                                        {
                                          "bg-green-50 cursor-default":
                                            index === currentIndex - 1,
                                          "hover:bg-gray-50":
                                            index !== currentIndex - 1,
                                        }
                                      )}
                                      onClick={() => {
                                        if (index !== currentIndex - 1) {
                                          setCurrentIndex(index + 1);
                                        }
                                      }}
                                      key={index}
                                    >
                                      <div className="flex flex-col">
                                        <span className="h6">{item.name}</span>
                                        <span className="p5">
                                          {item.description}
                                        </span>
                                      </div>
                                      <div className="flex flex-col">
                                        <div
                                          className={`flex items-center justify-center w-[30px] h-[30px] rounded-[30px] p-2 ${
                                            item.employer_status === 1
                                              ? "bg-[#0d5225]"
                                              : "border border-color-black"
                                          }`}
                                        >
                                          {item.employer_status === 1 ? (
                                            <img
                                              src={TickSVG}
                                              alt="tick"
                                              style={{
                                                width: "25px",
                                                height: "325px",
                                              }}
                                            />
                                          ) : (
                                            <div className="header-4">
                                              {index + 1}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div className="flex flex-col gap-2 w-[100%] md:ml-4 duration-500">
                                  <div className="border border-color-line rounded pr-4 pl-4 pt-4 pb-2 flex flex-col">
                                    {currentData ? (
                                      <form
                                        className="form pb-6"
                                        onSubmit={handleSubmit(submitForm)}
                                      >
                                        <div className="header-5 text-color-gray">
                                          Onboarding Stage Information
                                        </div>
                                        <div className="flex rounded-[5px]">
                                          <div className="w-full">
                                            <div className="flex flex-col justify-around flex-wrap">
                                              <FormInput
                                                label={"Onboarding stage title"}
                                                name={"stage_name"}
                                                placeholder={"Stage Name"}
                                                type="text"
                                                readOnly={false}
                                                value={currentData.name}
                                                onInput={(e) => {
                                                  let newArray = JSON.parse(
                                                    JSON.stringify(currentData)
                                                  );

                                                  newArray.name =
                                                    e.target.value;
                                                  setCurrentData(newArray);
                                                }}
                                                className="!h-[30px] mr-4 text-[13px] w-[60%] border-1 border-gray-300"
                                              />
                                              <FormInput
                                                label={
                                                  "Onboarding stage description"
                                                }
                                                name={"stage_description"}
                                                placeholder={
                                                  "Stage Description"
                                                }
                                                type="text"
                                                readOnly={false}
                                                value={currentData.description}
                                                onInput={(e) => {
                                                  let newArray = JSON.parse(
                                                    JSON.stringify(currentData)
                                                  );

                                                  newArray.description =
                                                    e.target.value;
                                                  setCurrentData(newArray);
                                                }}
                                                className="!h-[30px] mr-4 text-[13px] border-1 border-gray-300"
                                              />
                                              <hr className="divider mt-2 " />
                                              <div className="flex flex-row  justify-between items-center md:w-[100%] w-full">
                                                <div className="w-full md:w-[100%] text-[14px] -mb-3">
                                                  <FormSwitch
                                                    label={
                                                      "Do you want to upload document? (Company Handbook, leave ploicy...)"
                                                    }
                                                    name={"upload_file"}
                                                    checked={
                                                      currentData.upload_file ===
                                                      1
                                                        ? true
                                                        : false
                                                    }
                                                    onClick={() => {
                                                      let newArray = JSON.parse(
                                                        JSON.stringify(
                                                          currentData
                                                        )
                                                      );

                                                      newArray.upload_file =
                                                        currentData.upload_file ==
                                                        1
                                                          ? 0
                                                          : 1;
                                                      setCurrentData(newArray);
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              {currentData.upload_file ? (
                                                <>
                                                  <FormInput
                                                    label={"File name"}
                                                    name={"file_name"}
                                                    placeholder={
                                                      "Enter File Name"
                                                    }
                                                    type="text"
                                                    readOnly={false}
                                                    value={currentData.filename}
                                                    onInput={(e) => {
                                                      let newArray = JSON.parse(
                                                        JSON.stringify(
                                                          currentData
                                                        )
                                                      );

                                                      newArray.filename =
                                                        e.target.value;
                                                      setCurrentData(newArray);
                                                    }}
                                                    className="!h-[30px] mr-4 text-[13px] w-[100%] border-1 border-gray-300"
                                                  />
                                                  <FormFileInput
                                                    placeholder={
                                                      currentData.file_path
                                                    }
                                                    multiSource={false}
                                                    inputRef={register(
                                                      formValidation(
                                                        "file",
                                                        false
                                                      )
                                                    )}
                                                    accept=".pdf, .jpg, .png, .jpeg"
                                                  />
                                                </>
                                              ) : null}
                                              <hr className="divider mt-2 " />
                                              <div className="flex flex-row  justify-between items-center md:w-[100%] w-full">
                                                <div className="w-full md:w-[100%] text-[15px] -mb-3">
                                                  <FormSwitch
                                                    label={
                                                      "Do you want to collect document from employee?"
                                                    }
                                                    name={"collect_document"}
                                                    checked={
                                                      currentData.collect_document ==
                                                      1
                                                        ? true
                                                        : false
                                                    }
                                                    onClick={() => {
                                                      let newArray = JSON.parse(
                                                        JSON.stringify(
                                                          currentData
                                                        )
                                                      );

                                                      newArray.collect_document =
                                                        currentData.collect_document ==
                                                        1
                                                          ? 0
                                                          : 1;
                                                      setCurrentData(newArray);
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              {currentData.collect_document ? (
                                                <>
                                                  <FormInput
                                                    label={"Document Name"}
                                                    name={"document_name"}
                                                    placeholder={
                                                      "Enter File Name"
                                                    }
                                                    type="text"
                                                    readOnly={false}
                                                    value={
                                                      currentData.document_name
                                                    }
                                                    onInput={(e) => {
                                                      let newArray = JSON.parse(
                                                        JSON.stringify(
                                                          currentData
                                                        )
                                                      );

                                                      newArray.document_name =
                                                        e.target.value;
                                                      setCurrentData(newArray);
                                                    }}
                                                    className="!h-[30px] mr-4 text-[13px] w-[60%] border-1 border-gray-300"
                                                  />
                                                </>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                        <hr className="divider" />
                                        <div className="flex flex-row  justify-between items-center md:w-[100%] w-full">
                                          <div className="w-full md:w-[100%] text-[14px] -mb-3">
                                            <FormSwitch
                                              label={
                                                "Do you want to ask employee assessment questions?"
                                              }
                                              name={"ask_question"}
                                              checked={askQuestions}
                                              onClick={() => {
                                                setAskQuestions(!askQuestions);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        {askQuestions ? (
                                          <div className="flex flex-col">
                                            <div className="flex rounded-[5px]">
                                              <DragDropContext
                                                onDragEnd={onDragEnd}
                                              >
                                                {questions?.map((el, ind) => (
                                                  <Droppable
                                                    key={ind}
                                                    droppableId={`${ind}`}
                                                  >
                                                    {(provided, snapshot) => (
                                                      <div
                                                        className="p-4 "
                                                        ref={provided.innerRef}
                                                        style={getListStyle(
                                                          snapshot.isDraggingOver
                                                        )}
                                                        {...provided.droppableProps}
                                                      >
                                                        {el.map(
                                                          (item, index) => (
                                                            <Draggable
                                                              key={item.id}
                                                              draggableId={
                                                                item.id
                                                              }
                                                              index={index}
                                                            >
                                                              {(
                                                                provided,
                                                                snapshot
                                                              ) => (
                                                                <div
                                                                  className="border-r-4 border-r-primary-3 rounded-[8px] pt-2"
                                                                  ref={
                                                                    provided.innerRef
                                                                  }
                                                                  {...provided.draggableProps}
                                                                  {...provided.dragHandleProps}
                                                                  style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided
                                                                      .draggableProps
                                                                      .style
                                                                  )}
                                                                >
                                                                  <div className="flex flex-row justify-between items-center">
                                                                    <span className="font-bold italic text-primary-3">
                                                                      Question{" "}
                                                                      {index +
                                                                        1}
                                                                    </span>
                                                                    <div
                                                                      className="text-red-500 text-[12px] hover:font-semibold cursor-pointer hover:italic"
                                                                      onClick={() => {
                                                                        removeQuestion(
                                                                          index
                                                                        );
                                                                      }}
                                                                    >
                                                                      Remove
                                                                    </div>
                                                                  </div>

                                                                  <div className="flex md:flex-row flex-col justify-between md:items-center !w-[100%]">
                                                                    <div className="flex flex-row items-center justify-start gap-3 md:w-2/3 w-full">
                                                                      <FormInput
                                                                        name={
                                                                          index +
                                                                          "_question"
                                                                        }
                                                                        placeholder={
                                                                          "Enter Question?"
                                                                        }
                                                                        type="text"
                                                                        inputRef={register(
                                                                          formValidation(
                                                                            "text",
                                                                            true,
                                                                            0
                                                                          )
                                                                        )}
                                                                        value={
                                                                          item.question
                                                                        }
                                                                        onInput={(
                                                                          e
                                                                        ) => {
                                                                          let newArray =
                                                                            JSON.parse(
                                                                              JSON.stringify(
                                                                                questions[
                                                                                  ind
                                                                                ]
                                                                              )
                                                                            );

                                                                          newArray[
                                                                            index
                                                                          ].question =
                                                                            e.target.value;
                                                                          setQuestions(
                                                                            [
                                                                              newArray,
                                                                            ]
                                                                          );
                                                                        }}
                                                                        className="!h-[30px] md:w-[120%] w-[150%] text-[13px] border-1 border-gray-300"
                                                                      />
                                                                    </div>
                                                                    <div className="flex flex-row md:justify-end items-center gap-3 md:w-1/3 w-full">
                                                                      <FormSelect
                                                                        value={
                                                                          item.question_type
                                                                        }
                                                                        options={
                                                                          QuestionTypes
                                                                        }
                                                                        name={
                                                                          item.question_type
                                                                        }
                                                                        onChange={(
                                                                          selected
                                                                        ) => {
                                                                          let newArray =
                                                                            JSON.parse(
                                                                              JSON.stringify(
                                                                                questions[
                                                                                  ind
                                                                                ]
                                                                              )
                                                                            );

                                                                          newArray[
                                                                            index
                                                                          ].question_type =
                                                                            selected;
                                                                          setQuestions(
                                                                            [
                                                                              newArray,
                                                                            ]
                                                                          );
                                                                        }}
                                                                        className="!min-h-[32px] border-1 mt-1 !min-w-[100%] rounded text-[13px]"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div className="flex flex-col items-start justify-start w-full">
                                                                    {item.question_type ===
                                                                    "multiple_choice" ? (
                                                                      <div className="flex flex-col w-full">
                                                                        {item.options &&
                                                                        item
                                                                          .options
                                                                          .length ? (
                                                                          <span className="italic text-hr-primary-1 mb-3 text-[12px]">
                                                                            Options
                                                                          </span>
                                                                        ) : null}
                                                                        {item.options &&
                                                                          item
                                                                            .options
                                                                            .length >
                                                                            0 &&
                                                                          item.options.map(
                                                                            (
                                                                              subItem,
                                                                              subIndex
                                                                            ) => (
                                                                              <div
                                                                                className="flex flex-row justify-start items-center"
                                                                                key={
                                                                                  subIndex
                                                                                }
                                                                              >
                                                                                <div className="flex flex-row items-center -mt-4">
                                                                                  <span className="italic mr-4 text-[15px] font-bold">
                                                                                    {subIndex +
                                                                                      1}
                                                                                    .
                                                                                  </span>
                                                                                  <FormInput
                                                                                    name={
                                                                                      subIndex +
                                                                                      "_option"
                                                                                    }
                                                                                    placeholder={
                                                                                      "Enter Option"
                                                                                    }
                                                                                    type="text"
                                                                                    defaultValue={
                                                                                      subItem.option
                                                                                    }
                                                                                    onInput={(
                                                                                      e
                                                                                    ) => {
                                                                                      let newArray =
                                                                                        JSON.parse(
                                                                                          JSON.stringify(
                                                                                            questions
                                                                                          )
                                                                                        );

                                                                                      newArray[
                                                                                        index
                                                                                      ].options[
                                                                                        subIndex
                                                                                      ].option =
                                                                                        e.target.value;
                                                                                      setQuestions(
                                                                                        newArray
                                                                                      );
                                                                                    }}
                                                                                    className="!h-[30px] w-[250px] text-[13px] border-1 border-gray-300"
                                                                                  />
                                                                                  <img
                                                                                    src={
                                                                                      RemoveSVG
                                                                                    }
                                                                                    alt={
                                                                                      "remove"
                                                                                    }
                                                                                    className={
                                                                                      "h-[20px] w-[20px] ml-3 cursor-pointer"
                                                                                    }
                                                                                    onClick={() => {
                                                                                      removeOption(
                                                                                        index,
                                                                                        subIndex
                                                                                      );
                                                                                    }}
                                                                                  />
                                                                                </div>
                                                                              </div>
                                                                            )
                                                                          )}
                                                                        {item
                                                                          .options
                                                                          .length <
                                                                        4 ? (
                                                                          <span
                                                                            className="text-[13px] italic text-[#0d5225] cursor-pointer hover:underline hover:text-color-gray duration-75 mb-2"
                                                                            onClick={() =>
                                                                              addOption(
                                                                                index
                                                                              )
                                                                            }
                                                                          >
                                                                            Add
                                                                            Option
                                                                            (Max.
                                                                            4)
                                                                          </span>
                                                                        ) : null}
                                                                      </div>
                                                                    ) : (
                                                                      <div className="mb-2"></div>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </Draggable>
                                                          )
                                                        )}
                                                        {provided.placeholder}
                                                      </div>
                                                    )}
                                                  </Droppable>
                                                ))}
                                              </DragDropContext>
                                            </div>
                                            <div className="flex flex-row items-center gap-2 mt-3 justify-left w-full cursor-pointer">
                                              <img
                                                src={AddSmallSVG}
                                                alt={"add_question"}
                                                className={"h-[15px] w-[15px]"}
                                              />
                                              <span
                                                className="p4-medium hover:underline hover:italic hover:font-bold text-primary-3 duration-75"
                                                onClick={() => addQuestion()}
                                              >
                                                Add New Question (Max. 10)
                                              </span>
                                            </div>
                                          </div>
                                        ) : null}
                                        <hr className="divider" />
                                        <div className="flex flex-row gap-3 items-center justify-end">
                                          <Button
                                            text="Update Subworkflow"
                                            type="submit"
                                            className="flex gap-2 !h-[35px] w-fit p-2 "
                                            textClass={"!text-[11px]"}
                                            loading={editWorkflowloading}
                                          />
                                        </div>
                                      </form>
                                    ) : (
                                      <LoadingBar loading={isLoading} />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="flex flex-col max-h-fit min-h-[60px] overflow-auto items-center justify-center italic text-hr-secondary-1 mt-2">
                                No onboarding Subworkflow added
                                <div className="flex items-center justify-center w-[100%] select-none -mt-8">
                                  <img
                                    src={EmptyTableSVG}
                                    alt="No Data Fetched"
                                    width={"40%"}
                                    height={"100px"}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex flex-col max-h-[60px] min-h-[60px] overflow-auto items-center justify-center italic text-hr-secondary-1">
                  No Onboarding Workflow found
                  <div className="flex items-center justify-center w-[100%] mt-4 select-none">
                    <img
                      src={EmptyTableSVG}
                      alt="No Data Fetched"
                      width={"40%"}
                      height={"100px"}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewEmployeeOnboardingWorkflow;
