import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../components/button";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import {
  useCheckTransactionStatus,
  useRefAdvancedPayroll,
  useRevertPayroll,
  useSendSinglePayslip,
} from "../../../redux/payroll/hook";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import AdvancedPayrollDetailedView from "../../../components/modal/payroll/payroll-full-breakdown-view";
import { useSelector } from "react-redux";
import { isLoading } from "../../../redux/components/components-slice";
import UnauthorizedPage from "../../errors/unauthorized";

const AttendanceScheduleViewPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (
          user.permissions.company_permission.payroll_read !== 1 ||
          user.permissions.payroll_permission?.advanced_payroll_read !== 1
        ) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const loading = useSelector(isLoading);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const history = useHistory();

  const { mutateAsync: revertPayroll } = useRevertPayroll();

  const [showAdvancedPayrollDetailedView, setShowAdvancedPayrollDetailedView] =
    useState(false);
  const [previewData, setPreviewData] = useState();

  const { mutateAsync: sendPayslip } = useSendSinglePayslip();

  const { refId } = useParams();
  const { data: advancedPayroll } = useRefAdvancedPayroll(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  useEffect(() => {
    if (advancedPayroll) {
      if (loading === false && advancedPayroll.data.length < 1) {
        revertPayroll().then(() => {
          history.push("/payroll", { tab: 1 });
        });
      }
    }
  }, []);

  const { mutateAsync: checkTransactionStatus } = useCheckTransactionStatus();

  const tableConfig = {
    headers: [
      "Employee Name",
      "Bank Info",
      "Basic",
      "Transport",
      "Housing",
      "Gross Pay",
      "Tax",
      "Pension",
      "Net Pay",
      "Status",
    ],
    keys: [
      "employeeName",
      "bank_info",
      "basic_salary",
      "transport_allowance",
      "housing_allowance",
      "gross_pay",
      "tax",
      "pension",
      "net_pay",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "  Net Pay",
        key: "net_pay",
      },
    },
  };

  const actionConfig = [
    {
      name: "Check Status",
      color: "orange",
      action: async (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        await checkTransactionStatus(advancedPayroll.data[index].id);
      },
    },
    {
      name: "Details",
      color: "green",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        setPreviewData(advancedPayroll.data[index]);
        setShowAdvancedPayrollDetailedView(true);
      },
    },
    {
      name: "Send Payslip",
      color: "red",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        sendPayslip({ id: advancedPayroll.data[index].id });
      },
    },
    {
      name: "Retry",
      color: "red",
      action: () => {},
    },
  ];

  const statusConfig = [
    {
      label: "Pending",
      color: "orange",
      value: 0,
    },
    {
      label: "Successful",
      color: "green",
      value: 1,
    },
    {
      label: "Failed",
      color: "red",
      value: 1,
    },
  ];

  return (
    <DashboardLayout title="Recruitment Stage View">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {previewData && showAdvancedPayrollDetailedView ? (
            <AdvancedPayrollDetailedView
              isOpen={showAdvancedPayrollDetailedView}
              closeModal={() => setShowAdvancedPayrollDetailedView(false)}
              viewData={previewData}
            />
          ) : null}
          <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col">
                <span className="h4 text-color-black">
                  Attendance Schedule View
                </span>
                <span className="text-color-gray text-[13px]">
                  View And Update Attendance Schedule
                </span>
              </div>
              {user?.permissions.payroll_permission?.advanced_payroll_write ===
                1 || user?.permissions.company_permission.role === "Owner" ? (
                <div className="w-full md:w-[220px] h-[56px]">
                  <Button
                    text="Export"
                    type="submit"
                    loading={false}
                    disabled={false}
                    className="h-[56px]"
                    //   onClick={() => {setShowRecruit(true)}}
                  />
                </div>
              ) : null}
            </div>
            <div className="mt-8">
              <DataTable
                data={advancedPayroll}
                tableConfig={tableConfig}
                noCheck={true}
                actionConfig={actionConfig}
                updatePagination={(data) => setPagination(data)}
                statusConfig={statusConfig}
                computedActions={true}
                filterByStatus={true}
                searchData={true}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default AttendanceScheduleViewPage;
