import React, { useState } from "react";
import { useEffect } from "react";
import Button from "../../components/button";
import FormSelect from "../../components/form-select";
import HMOSettingsLayout from "../../components/layouts/hmo-settings";
import {
  useAddCompanyHMOSettings,
  useGetHMOPlans,
  useGetHMOSettings,
  useGetHMOs,
  useGetOnboardingHMOPlans,
} from "../../redux/hmo/hook";
import FormSwitch from "../../components/form-switch";
import FormInput from "../../components/form-input";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loader-bar";
import { formatNumber } from "../../utils/functions";

function AddHMOSettings() {
  const [canSelectHMO, setCanSelectHMO] = useState(false);
  const [hmoName, setHmoName] = useState("");
  const [hmoId, setHmoId] = useState(null);
  const [hmoPlan, setHmoPlan] = useState("");
  const [planId, setPlanId] = useState(null);
  const [hmoBudget, setHmoBudget] = useState("");
  const [maxSubscriptionMonth, setMaxSubscriptionMonth] = useState(0);
  const [canAddDependants, setCanAddDependants] = useState(0);
  const [maxDependants, setMaxDependants] = useState(0);
  const [autoRenew, setAutoRenew] = useState(0);

  const history = useHistory();

  const [CurrentHrPlan, setCurrentPlan] = useState(null);

  const { data: hmos } = useGetHMOs();

  const { data: hmoPlansCompleteInformation, isLoading: completePlansLoading } =
    useGetHMOPlans(hmoId);

  const { data: hmoPlans, isLoading: plansLoading } =
    useGetOnboardingHMOPlans(hmoId);

  const [hmoData, setHmoData] = useState([
    { value: "", label: "No HMO Selected", hmo_name: "" },
  ]);

  useEffect(() => {
    if (planId && hmoPlansCompleteInformation) {
      let index = hmoPlansCompleteInformation?.findIndex(
        (item) => item.id == planId
      );
      if (index != -1) {
        setCurrentPlan(hmoPlansCompleteInformation[index]);
        setHmoBudget(hmoPlansCompleteInformation[index].price);
        setMaxSubscriptionMonth(
          hmoPlansCompleteInformation[index].payment_duration == "0"
            ? 1
            : hmoPlansCompleteInformation[index].payment_duration
        );
      }
    }
  }, [planId, hmoPlansCompleteInformation]);

  useEffect(() => {
    if (hmoId) {
      setCurrentPlan(null);
      setHmoBudget(null);
    }
  }, [hmoId]);

  const [hmoPlanData, setHmoPlanData] = useState([
    { value: "", label: "No Plan Selected", plan_name: "" },
  ]);

  useEffect(() => {
    if (hmos) {
      setHmoData(hmos);
    }
  }, [hmos]);

  useEffect(() => {
    if (hmoPlans) {
      setHmoPlanData(hmoPlans);
    }
  }, [hmoPlans]);

  const {
    mutateAsync: addCompanyHMOSettings,
    isLoading: addCompanyHMOSettingsLoading,
  } = useAddCompanyHMOSettings();
  const { data: hmoSettings, isLoading } = useGetHMOSettings();

  const submitForm = async () => {
    let payload = {
      can_select_hmo: canSelectHMO ? 1 : 0,
      hmo_id: hmoId,
      hmo_plan_id: planId,
      hmo_budget: hmoBudget,
      max_subscription_months: maxSubscriptionMonth,
      can_add_dependant: canAddDependants ? 1 : 0,
      max_dependants: maxDependants,
      auto_renew: autoRenew,
    };
    await addCompanyHMOSettings(payload).then(() => {
      history.push("/employee-hmo");
    });
  };

  return (
    <HMOSettingsLayout step={1} title={"Add HMO Settings"}>
      <div className="flex flex-col overflow-auto">
        <form className="form">
          <div className="pl-4">
            <div className="flex flex-row justify-between">
              <span className="header-3">Add Company HMO Settings</span>
            </div>
          </div>
          <hr className="divider" />
          <div className="flex flex-col max-h-fit pr-8 pl-8">
            <div className="flex flex-col mb-4 ">
              <div className={"flex w-full flex-col font-bold "}>
                <FormSwitch
                  label={"Employee Can Select HMO"}
                  name={"can_select_hmo"}
                  readOnly={addCompanyHMOSettingsLoading}
                  checked={canSelectHMO}
                  onClick={() => {
                    setHmoBudget(0);
                    setMaxSubscriptionMonth(1);
                    setPlanId(null);
                    setCanSelectHMO(!canSelectHMO);
                  }}
                />
              </div>
              <div className="flex flex-col p-4 border rounded">
                {!canSelectHMO ? (
                  <>
                    <div className="flex md:flex-row flex-col w-full justify-between gap-2">
                      <div className="md:w-1/2 w-full md:pr-2">
                        <FormSelect
                          value={hmoId}
                          options={hmoData}
                          onChange={(selected) => {
                            setHmoId(selected);
                          }}
                          label="Select HMO"
                        />
                      </div>
                      {hmoPlans ? (
                        <div className="md:w-1/2 w-full md:pl-2">
                          <FormSelect
                            value={planId}
                            options={hmoPlanData}
                            onChange={(selected) => {
                              setPlanId(selected);
                            }}
                            label="Select HMO Plan"
                          />
                        </div>
                      ) : plansLoading && hmoId ? (
                        <div className="flex flex-col w-full text-center align-center justify-center">
                          <LoadingBar loading={plansLoading && hmoId} />
                        </div>
                      ) : (
                        <div className="flex flex-col md:w-1/2 w-full text-center align-center justify-center">
                          No plan fetched
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col p-2 border rounded mb-2 w-full">
                      {CurrentHrPlan ? (
                        <>
                          <div className="text-gray-500 text-[15px]">
                            Plan Name
                          </div>
                          <div className="text-[13px] font-extrabold">
                            {CurrentHrPlan?.name}
                          </div>
                          <hr className="divider" />
                          <div className="font-bold text-[16px] mb-2">
                            Plan Coverage
                          </div>
                          <div className="grid md:grid-cols-3 grid-cols-3 gap-4 w-full pt-2 duration-500">
                            <div className="flex flex-col">
                              <div className="text-gray-500 text-[14px]">
                                Plan Type
                              </div>
                              <div className="text-[13px]">
                                {CurrentHrPlan?.Plan_type}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="text-gray-500 text-[14px]">
                                An Pn Childbirth
                              </div>
                              <div className="text-[13px]">
                                {CurrentHrPlan?.an_pn_childbirth}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="text-gray-500 text-[14px]">
                                Dental
                              </div>
                              <div className="text-[13px]">
                                {CurrentHrPlan?.dental}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="text-gray-500 text-[14px]">
                                Drugs
                              </div>
                              <div className="text-[13px]">
                                {CurrentHrPlan?.drugs}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="text-gray-500 text-[14px]">
                                Emergency
                              </div>
                              <div className="text-[13px]">
                                {CurrentHrPlan?.emergency}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="text-gray-500 text-[14px]">
                                Gym
                              </div>
                              <div className="text-[13px]">
                                {CurrentHrPlan?.gym}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="text-gray-500 text-[14px]">
                                Gynecology
                              </div>
                              <div className="text-[13px]">
                                {CurrentHrPlan?.gynecology}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="text-gray-500 text-[14px]">
                                Optical care
                              </div>
                              <div className="text-[13px]">
                                {CurrentHrPlan?.optical_care}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="text-gray-500 text-[14px]">
                                Surgery Coverage
                              </div>
                              <div className="text-[13px]">
                                {formatNumber(
                                  CurrentHrPlan?.surgery_coverage,
                                  2
                                )}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="text-gray-500 text-[14px]">
                                Ward Type
                              </div>
                              <div className="text-[13px]">
                                {CurrentHrPlan?.ward_type}
                              </div>
                            </div>
                            <div className="flex flex-col justify-center">
                              <div className="text-gray-500 text-[14px] hover:underline hover:italic">
                                <a
                                  href={CurrentHrPlan?.benefit_detail_link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View Full Benefits
                                </a>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {completePlansLoading && planId ? (
                            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                              <LoadingBar loading={plansLoading} />
                            </div>
                          ) : (
                            <div className="flex p-2 justify-center items-center w-full">
                              No HMO Plan selected
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </>
                ) : null}
                <div className="flex flex-col md:flex-row w-full justify-between gap-2 md:gap-4">
                  <div className="md:w-1/2 w-full md:pr-2">
                    <FormInput
                      label={
                        canSelectHMO
                          ? "HMO Budget (Monthly) - Editable"
                          : "HMO Plan Price - (ReadOnly)"
                      }
                      placeholder={"Enter budget"}
                      name={"hmo_budget"}
                      type="number"
                      onInput={(e) => setHmoBudget(e.target.value)}
                      readOnly={canSelectHMO ? false : true}
                      value={hmoBudget}
                    />
                  </div>
                  <div className="md:w-1/2 w-full md:pl-2">
                    <FormInput
                      label={
                        canSelectHMO
                          ? "Subscription Month - (Editable)"
                          : "Subscription Month - (ReadOnly)"
                      }
                      name={"max_subscription_months"}
                      type="number"
                      onInput={(e) => setMaxSubscriptionMonth(e.target.value)}
                      readOnly={canSelectHMO ? false : true}
                      value={maxSubscriptionMonth}
                    />
                  </div>
                </div>
              </div>
              <hr className="divider" />
              <div className={"flex w-full flex-col"}>
                <div className="flex flex-col md:flex-row w-full justify-between md:gap-4 items-center">
                  <div className="w-full md:w-2/4 !text-[13px] mt-3">
                    <FormSwitch
                      label={"Employee Can Add Dependants"}
                      name={"can_add_dependants"}
                      readOnly={addCompanyHMOSettingsLoading}
                      checked={canAddDependants}
                      onClick={() => setCanAddDependants(!canAddDependants)}
                    />
                  </div>
                  <div className="w-full md:w-1/4 text-[13px]">
                    <FormInput
                      label={"How many dependants?"}
                      placeholder={"Max. Dependants"}
                      name="max_dependants"
                      className={"max-h-[35px]"}
                      type="number"
                      value={maxDependants}
                      onInput={(e) => setMaxDependants(e.target.value)}
                      readOnly={addCompanyHMOSettingsLoading}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <FormSwitch
                    label={"Auto Renew HMO"}
                    name={"auto_renew"}
                    readOnly={addCompanyHMOSettingsLoading}
                    checked={autoRenew}
                    onClick={() => setAutoRenew(!autoRenew)}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <Button
                text={"save settings"}
                type="button"
                loading={addCompanyHMOSettingsLoading}
                onClick={() => {
                  submitForm();
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </HMOSettingsLayout>
  );
}

export default AddHMOSettings;
