import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import AsyncSelect from 'react-select/async';
import { useAddEmployeesToGroup } from '../../../redux/employees/hook/groups';
import { setAlert } from '../../../redux/components/components-slice';
import Modal from '../modal';
import { employeeGroups } from '../../../api/employees/groups';

function AddEmployeeToPayrollGroup({ isOpen, closeModal, employee, refresh }) {
  const [payrollGroup, setPayrollGroup] = useState(null);

  const { mutateAsync: addToGroup, isLoading } = useAddEmployeesToGroup();
  const dispatch = useDispatch();

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeGroups
        .getEmployeeGroups({
          limit: 20,
          statusFilter: -1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: item.name,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const submit = () => {
    if (!payrollGroup) {
      dispatch(setAlert(true, 'info', 'Select a payroll group'));
      return;
    }

    let payload = {
      group: payrollGroup.value,
      employees: employee.id.toString(),
    };

    addToGroup(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none relative'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col pb-8'>
        <div className='flex flex-row justify-between pl-8 pr-8 pt-4 items-center'>
          <span className='header-4'>Add Employee To Payroll Group</span>
          <div className='cursor-pointer' onClick={() => closeModal()}>
            <img src={CloseSVG} alt='close' />
          </div>
        </div>
        <hr className='divider mb-2' />
        {employee ? (
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col mt-'>
              <div className='flex flex-col border rounded-md p-2 bg-highlight'>
                <label className='text-[15px] leading-[23px] font-semibold text-color-gray'>
                  Employee Name
                </label>
                <span className='text-[14px]'>
                  {employee?.first_name} {employee?.last_name}
                </span>
              </div>
              <hr className='divider mt-2' />
              <div style={{ position: 'relative' }}>
                <label className='text-[13px] leading-[23px] font-normal text-color-gray mb-1'>
                  Select Payroll Group
                </label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  className='text-[13px]'
                  loadOptions={loadOptions}
                  onChange={(option) => setPayrollGroup(option)}
                  classNamePrefix='mySelect'
                />
              </div>
            </div>
            <hr className='divider' />
            <div className='w-full mt-4'>
              <Button
                text='ADD TO GROUP'
                type='submit'
                loading={isLoading}
                onClick={() => submit()}
              />
            </div>
          </div>
        ) : (
          <div className='flex !h-[35px] w-full justify-center items-center'>
            No Employee selected
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AddEmployeeToPayrollGroup;
