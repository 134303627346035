import { Axios } from '../axios';

const getEmployeePromotions = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(
      `/promotions?limit=${payload.limit}&page=${payload.page}${search}`,
    );
  
    return data?.data;
};

const getEmployeePromotion = async (payload) => {
    const { data } = await Axios.get('/promotions/' + payload);
    return data?.data;
};

const addEmployeePromotion = async (payload) => {
    const { data } = await Axios.post('/promotions', payload,
    {
      timeout: 0
    });
    return data;
};

const updateEmployeePromotion = async (payload) => {
    const { data } = await Axios.put(
      '/promotions/' + payload.id,
      payload.data,
    );
    return data;
};

const addPromotionSalaryReview = async (payload) => {
    const { data } = await Axios.post('/promotions/review-salary', payload);
    return data;
};

const deletePromotion = async (payload) => {
    const { data } = await Axios.delete(
      '/promotions/' + payload
    );
    return data;
};

export const employeePromotions = {
    getEmployeePromotions,
    getEmployeePromotion,
    addEmployeePromotion,
    updateEmployeePromotion,
    addPromotionSalaryReview,
    deletePromotion,
  };