import { Axios } from '../axios';

const getVendors = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter && payload.statusFilter != -1
      ? '&filter=' + payload.statusFilter
      : '';
  const { data } = await Axios.get(
    `/vendors?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );
  return data?.data;
};

const getVendorPaymentsSummary = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter && payload.statusFilter != -1
      ? '&filter=' + payload.statusFilter
      : '';

  const { data } = await Axios.get(
    `/vendor-payments?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );
  return data?.data;
};

const fetchVendorPayByRef = async (payload) => {
  const { data } = await Axios.get(
    `/vendor-payments/by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}`,
  );
  if (data.data) {
    data.data.payments.total_net_pay = data?.data.total_amount;
    data.data.payments.total_payable = data?.data.total_payable;
    data.data.payments.total_trans_cost = data?.data.total_fees;
  }
  return data?.data.payments;
};

const updateVendor = async (payload) => {
  const { data } = await Axios.put(
    '/vendors/' + payload.vendorId,
    payload.data,
  );
  return data;
};

const getVendor = async (payload) => {
  const { data } = await Axios.get('/vendors/' + payload);
  return data.data;
};

const createSingleVendor = async (payload) => {
  const { data } = await Axios.post('/vendors', payload);
  return data;
};

const createBulkVendor = async (payload) => {
  const { data } = await Axios.post('/vendors/bulk', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0,
  });
  return data;
};

const bulkActivateOrDeactivateVendors = async (payload) => {
  const computedPayload = { vendor_ids: payload.data };
  const { data } = await Axios.post(
    `/vendors/bulk-activation/${payload.type}`,
    computedPayload,
  );
  return data;
};

const activateOrDeactivateVendor = async (payload) => {
  const { data } = await Axios.put(`/vendors/${payload.type}/${payload.id}`);
  return data;
};

const getVendorHistory = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter && payload.statusFilter != -1
      ? '&filter=' + payload.statusFilter
      : '';
  let start_date =
    payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';

  const { data } = await Axios.get(
    `/vendors/transactions/history?limit=${payload.limit}&page=${payload.page}${search}${filter}${start_date}${end_date}`,
  );

  return data?.data || [];
};

const getHistoryByVendor = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter =
    payload.statusFilter && payload.statusFilter != -1
      ? '&filter=' + payload.statusFilter
      : '';
  let start_date =
    payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';

  const { data } = await Axios.get(
    `/vendors/transactions-history/by-vendor/${payload.id}?limit=${payload.limit}&page=${payload.page}${search}${filter}${start_date}${end_date}`,
  );

  return data?.data || [];
};

const runVendorPayment = async (payload) => {
  const { data } = await Axios.post('/vendor-payment/run', payload);
  return data;
};

const runVendorPaymentExcel = async (payload) => {
  const { data } = await Axios.post('/vendor-payment/run-multiple', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0,
  });
  return data;
};

const disburseVendorPayment = async (payload) => {
  const { data } = await Axios.post('/vendor-payment/disburse', payload, {
    timeout: 0,
  });
  return data;
};

const initiateVendorPayment = async (payload) => {
  const { data } = await Axios.post(
    `/vendor-payment/initiate-payment`,
    payload,
    {
      timeout: 0,
    },
  );
  return data;
};

const declineVendorPayroll = async (payload) => {
  const { data } = await Axios.get(
    `/vendor-payment/decline-payment/${payload}`,
  );
  return data;
};

const removeVendorPaymentByRef = async (payload) => {
  const { data } = await Axios.get(`/vendor-pay/remove-vendor/${payload}`);
  return data;
};

const editVendorPaymentById = async (payload) => {
  const { data } = await Axios.put(
    `/vendor-pay/edit-vendor/${payload.id}`,
    payload.data,
  );
  return data;
};

const sendVendorPaymentAdvice = async (payload) => {
  const { data } = await Axios.get(
    `/vendor-payment/send-payment-advice/${payload}`,
  );
  return data;
};

const deleteVendor = async (payload) => {
  const { data } = await Axios.delete('vendors/' + payload);
  return data;
};

const deleteVendorPayment = async (payload) => {
  const { data } = await Axios.delete(`/vendor-payment/${payload}`);
  return data;
};

const removeVendorPaymentById = async (payload) => {
  const { data } = await Axios.get(`/vendor-payment/remove-payment/${payload}`);
  return data;
};

export const vendors = {
  getVendors,
  getVendorPaymentsSummary,
  fetchVendorPayByRef,
  updateVendor,
  getVendor,
  createBulkVendor,
  createSingleVendor,
  bulkActivateOrDeactivateVendors,
  activateOrDeactivateVendor,
  getVendorHistory,
  getHistoryByVendor,
  runVendorPayment,
  runVendorPaymentExcel,
  disburseVendorPayment,
  initiateVendorPayment,
  removeVendorPaymentByRef,
  editVendorPaymentById,
  declineVendorPayroll,
  sendVendorPaymentAdvice,
  deleteVendor,
  deleteVendorPayment,
  removeVendorPaymentById,
};
