import { Axios } from '../axios';

const addBranch = async (payload) => {
  const { data } = await Axios.post('/branches', payload);
  return data;
};

const fundBranch = async (payload) => {
  const { data } = await Axios.post(`/branch/fund-branch-account/${payload.id}`, payload);
  return data;
};

const debitBranch = async (payload) => {
  const { data } = await Axios.post(`/branch/debit-branch-account/${payload.id}`, payload);
  return data;
};

const getBranches = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(
    `/branches?limit=${payload.limit}&page=${payload.page}${search}`,
    {
      timeout: 0
    }
  );
  return data?.data;
};

const getBranchSummary = async () => {
  const { data } = await Axios.post('/branches/summary');
  return data?.data;
};

const updateBranch = async (payload) => {
  const { data } = await Axios.put('/branches/' + payload.id, payload.data);
  return data;
};

const getBranch = async (payload) => {
  const { data } = await Axios.get('/branches/' + payload);
  return data?.data;
};

const deleteBranch = async (payload) => {
  const { data } = await Axios.delete(`/branches/${payload}`);
  return data;
};

const getBranchEmployees = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(`/branches/${payload.id}?limit=${payload.limit}&page=${payload.page}${search}${filter}`,
  {
    timeout: 0
  });
  return data?.data;
};

const addEmployeesToBranch = async (payload) => {
  const { data } = await Axios.post('/branch/add-multiple-employees', payload);
  return data;
};

const removeEmployeesFromBranch = async (payload) => {
  const { data } = await Axios.post(
    '/branch/remove-multiple-employees',
    payload,
  );
  return data;
};

const moveEmployeesToBranch = async (payload) => {
  const { data } = await Axios.post('/branch/move-multiple-employees', payload);
  return data;
};

const getBranchTeams = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(`/branch/teams/${payload.id}?limit=${payload.limit}&page=${payload.page}${search}`,
  {
    timeout: 0
  });
  return data?.data;
};

const addTeamsToBranch = async (payload) => {
  const { data } = await Axios.post('/branch/add-multiple-teams', payload);
  return data;
};

const removeTeamsFromBranch = async (payload) => {
  const { data } = await Axios.post('/branch/remove-multiple-team', payload);
  return data;
};

const moveTeamToBranch = async (payload) => {
  const { data } = await Axios.post('/branch/move-multiple-team', payload);
  return data;
};

const getBranchDepartments = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(`/branch/departments/${payload.id}?limit=${payload.limit}&page=${payload.page}${search}`,
  {
    timeout: 0
  });
  return data?.data;
};

const addDepartmentsToBranch = async (payload) => {
  const { data } = await Axios.post(
    '/branch/add-multiple-departments',
    payload,
  );
  return data;
};

const removeDepartmentFromBranch = async (payload) => {
  const { data } = await Axios.post(
    '/branch/remove-multiple-departments',
    payload,
  );
  return data;
};

const moveDepartmentToBranch = async (payload) => {
  const { data } = await Axios.post(
    '/branch/move-multiple-department',
    payload,
  );
  return data;
};

const getBranchSettings = async (payload) => {
  const { data } = await Axios.get('/branches/' + payload.id);
  return data?.data;
};

const generateBranchAccountDetails = async (payload) => {
  const { data } = await Axios.post(
    `/branch/generate-bank-account/${payload}`,
  );
  return data;
};

export const branches = {
  addBranch,
  fundBranch,
  debitBranch,
  getBranches,
  getBranchSummary,
  updateBranch,
  getBranch,
  deleteBranch,
  getBranchEmployees,
  addEmployeesToBranch,
  removeEmployeesFromBranch,
  moveEmployeesToBranch,
  getBranchTeams,
  addTeamsToBranch,
  removeTeamsFromBranch,
  moveTeamToBranch,
  getBranchDepartments,
  addDepartmentsToBranch,
  removeDepartmentFromBranch,
  moveDepartmentToBranch,
  getBranchSettings,
  generateBranchAccountDetails
};
