import React, { useState } from "react";
import Button from "../../../button";
import { useResendOfferLetter } from "../../../../redux/human-resources/hook/recruitment";
import ViewOfferDoc from "./view-offer-letter-doc";

export const OfferLetter = ({
  offerLetter,
  signedOfferLetter,
  type = "recruitment",
}) => {
  const [docToView, setDocToView] = useState(null);
  const [showOfferLetterDoc, setShowOfferLetterDoc] = useState(false);
  const {
    mutateAsync: resendOfferLetter,
    isLoading: resendOfferLetterLoading,
  } = useResendOfferLetter();

  return (
    <div
      className={`min-h-[250px] flex flex-col  gap-2 justify-start bg-white rounded-[5px] border border-[#dbdce3] w-full`}
    >
      {showOfferLetterDoc ? (
        <ViewOfferDoc
          isOpen={showOfferLetterDoc}
          closeModal={() => setShowOfferLetterDoc(false)}
          title=" Move Applicant Stage"
          applicant={docToView}
          buttonText="Move Applicant"
        />
      ) : null}
      <div className="flex flex-row justify-between items-center bg-blue-100 p-3">
        <div className="font-medium text-[14px] text-color-black flex flex-col">
          Offer Letter Sent
        </div>
      </div>
      {offerLetter ? (
        <div className="p-4 pt-0">
          <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
            <div className="flex flex-row items-center gap-8">
              <span className="header-5 text-color-gray">Email Subject</span>
              <div className="flex flex-row gap-3 items-center">
                <span className="italic text-hr-primary-1 text-[14px]">
                  {offerLetter?.subject}
                </span>
              </div>
            </div>
          </div>
          <hr className="divider mt-2 mb-2" />
          <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
            <div className="flex flex-row items-center gap-8">
              <span className="header-5 text-color-gray">Salutation</span>
              <div className="flex flex-row gap-3 items-center">
                <span className="italic text-hr-primary-1 text-[14px]">
                  {offerLetter?.salutation}
                </span>
              </div>
            </div>
          </div>
          <hr className="divider mt-2 mb-2" />
          <div className="grid md:grid-cols-1  grid-cols-1 gap-4 w-full p4 mt-[20px]">
            <div className="border-1 p-3 !max-h-[600px] rounded-md overflow-auto">
              <span className="header-5 text-color-gray mb-2">Email Body</span>
              <td dangerouslySetInnerHTML={{ __html: offerLetter?.body }} />
            </div>
          </div>
          <hr className="divider mt-2 mb-2" />
          <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
            <div className="flex flex-row items-center gap-8">
              <span className="header-5 text-color-gray">Closing Remark</span>
              <div className="flex flex-row gap-3 items-center">
                <span className="italic text-hr-primary-1 text-[14px]">
                  {offerLetter?.closing_remark}
                </span>
              </div>
            </div>
          </div>
          <hr className="divider mt-2 mb-2" />
          <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
            <div className="flex flex-row items-center gap-8">
              <span className="header-5 text-color-gray">Offer Letter:</span>
              <div className="flex flex-row gap-3 items-center">
                <Button
                  text="View Offer Letter"
                  type="submit"
                  className="flex gap-2 !h-[35px] w-fit p-2"
                  textClass={"!text-[11px]"}
                  onClick={() => {
                    setDocToView(offerLetter);
                    setShowOfferLetterDoc(true);
                  }}
                />
                <a
                  className="italic text-hr-primary-1 text-[14px]"
                  href={offerLetter?.file_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Download
                </a>
              </div>
            </div>
          </div>
          {signedOfferLetter ? (
            <>
              <hr className="divider mt-2 mb-2" />
              <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mt-3">
                <div className="flex flex-row items-center gap-8">
                  <span className="header-5 text-color-gray">
                    Offer Letter:
                  </span>
                  <div className="flex flex-row gap-3 items-center">
                    <Button
                      text="View Signed Offer Letter"
                      type="submit"
                      className="flex gap-2 !h-[35px] w-fit p-2 "
                      textClass={"!text-[11px]"}
                      onClick={() => {
                        setDocToView(signedOfferLetter);
                        setShowOfferLetterDoc(true);
                      }}
                    />
                    <a
                      className="italic text-hr-primary-1 text-[14px]"
                      href={signedOfferLetter?.file_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <hr className="divider mt-2 mb-4" />
              <div className="flex flex-row gap-3 items-center w-full justify-end">
                <Button
                  text="Resend Offer Letter"
                  type="submit"
                  className="flex gap-2 !h-[35px] w-fit p-2 "
                  textClass={"!text-[11px]"}
                  loading={resendOfferLetterLoading}
                  onClick={() => {
                    resendOfferLetter({ type: type, id: offerLetter?.id });
                  }}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center p-4 font-bold">
          No Offer Letter sent yet
        </div>
      )}
    </div>
  );
};
