import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import AsyncSelect from 'react-select/async';
import { teams as teamAPI } from '../../../../api/hr/team-management';
import { setAlert } from '../../../../redux/components/components-slice';
import {
  useAddTeamsToDepartment,
  useGetDepartment,
} from '../../../../redux/human-resources/hook/departments';
import Modal from '../../modal';

function AddTeamsToDepartment({ isOpen, closeModal, departmentData, refresh }) {
  const { data: department } = useGetDepartment(departmentData);
  const [teams, setTeams] = useState([]);
  const { mutateAsync: addTeamsToDepartment, isLoading } =
    useAddTeamsToDepartment();

  const dispatch = useDispatch();

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      teamAPI
        .getTeams({ limit: 10, search: inputValue, page: 1 })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({ label: `${item.name}`, value: item.id });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const submit = () => {
    if (teams.length === 0) {
      dispatch(setAlert(true, 'info', 'Select at least one team'));
      return;
    }
    let teamArrays = [];

    teams.forEach((item) => {
      teamArrays.push(item.value);
    });

    let team_ids = teamArrays.join();

    let payload = {
      department_id: departmentData,
      team_ids: team_ids,
    };

    addTeamsToDepartment(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      {departmentData ? (
        <div className='flex flex-col pb-8 overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Add teams to departments</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col'>
            `<div className='flex flex-col p-3 border bg-highlight rounded-md'>
                <div className='text-[14px] font-extrabold text-color-gray'>Department Name</div>
                <div className='text-[13px] font-semibold'>{department?.name}</div>
              </div>
              <hr className='divider mt-2' />
              <div>
                <label className='text-[13px] leading-[23px] font-normal text-color-gray mb-1'>
                  Select Teams
                </label>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  defaultOptions
                  className='text-[13px]'
                  placeholder={'Type to select'}
                  loadOptions={loadOptions}
                  onChange={(options) => setTeams(options)}
                  classNamePrefix='mySelect'
                />
              </div>
            </div>
            <div className='w-full mt-4'>
              <Button
                text='ADD TO DEPARTMENT'
                type='submit'
                loading={isLoading}
                onClick={() => submit()}
              />
            </div>
          </div>
        </div>
      ) : null}
    </Modal>
  );
}

export default AddTeamsToDepartment;
