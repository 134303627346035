import React, { useEffect } from "react";
import {
  useChangeInvitationLinkStatus,
  useGetRegistrationLink,
  useRefreshInvitationLink,
} from "../../../redux/employees/hook";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/components/components-slice";
import CopySVG from "../../../assets/svg/copy.svg";
import WarningSVG from "../../../assets/svg/warning.svg";
import Button from "../../../components/button";
import LoadingBar from "../../../components/loader-bar";
import FormSwitch from "../../../components/form-switch";
import RefreshSVG from "../../../assets/svg/refresh.svg";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import classNames from "classnames";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function InvitationLinkPage() {
  useEffect(() => {}, []);

  const dispatch = useDispatch();
  const { data: link, isLoading } = useGetRegistrationLink();

  const { mutateAsync: refreshInvite, isLoading: refreshloading } =
    useRefreshInvitationLink();
  const { mutateAsync: changeStatus, isLoading: changeStatusloading } =
    useChangeInvitationLinkStatus();

  const history = useHistory();

  return (
    <EmployeeOnboardingLayout step={2} title={"Get Employee Invitation Link"}>
      {isLoading ? (
        <LoadingBar loading={isLoading} />
      ) : (
        <div className="flex flex-col justify-center items-start w-full gap-2 px-8 pb-4 md:mt-6 bg-highlight rounded-md border">
          <div className="flex flex-col p3 items-center justify-center">
            <span className="text-color-black pt-2 text-[16px] font-extrabold">
              Employee Registration Link:
            </span>
            <span className=" text-secondary-2 pt-1 !text-center text-[14px]">
              {link?.link}{" "}
            </span>
            <CopyToClipboard
              text={link?.link}
              onCopy={() => {
                dispatch(setAlert(true, "success", "Copied successfully"));
              }}
            >
              <button className="flex flex-row mt-2 items-center text-center">
                <span className="text-color-black pt-2 text-[14px] text-hr-primary-1 hover:font-bold duration-300">
                  Copy to clipboard{" "}
                </span>
                <img
                  src={CopySVG}
                  width={15}
                  className="ml-2 mt-2"
                  alt="copy"
                />
              </button>
            </CopyToClipboard>
          </div>
          <div className="flex flex-col justify-center mb-2">
            <div className="flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 mt-2 bg-color-warning-light text-[13px] items-center">
              <div className="w-[150px] pr-2 flex flex-row items-center ">
                <img src={WarningSVG} alt="" srcSet="" />
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-justify !text-[12px]">
                  You can collect employee information by sharing this link to
                  your employees to fill in all their information before
                  reviewing and activating them on the platform. This saves you
                  a lot of time onboarding one by one or using excel (through
                  multiple upload).
                </span>
                <span className="text-justify !text-[12px] font-semibold p-2 border border-secondary-4 bg-white rounded-md">
                  Ensure that accessibilty status is turned on before sharing
                  link with employees. Otherwise they will not be able to access
                  the invitation link.
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center w-full gap-2 mt-2">
            <div>
              <FormSwitch
                label={"Accessibility Status"}
                name={"accessibility_status"}
                checked={link.status}
                onClick={() => changeStatus()}
              />
            </div>
            <div
              className="flex flex-row items-center justify-center text-secondary-2 text-[14px] cursor-pointer hover:underline duration-500 gap-2"
              onClick={() => {
                refreshInvite();
              }}
            >
              <img
                src={RefreshSVG}
                alt={"Refresh"}
                className={classNames(
                  "rounded-[20px] !h-[20px] w-[20px] animate-none",
                  {
                    "animate-spin": refreshloading,
                  }
                )}
              />{" "}
              <span className="text-secondary-2 text-[13px] font-semibold">
                Refresh Link
              </span>
            </div>
          </div>
          <div className="flex justify-end w-full gap-3 mt-4">
            <Button
              text={"Go Back"}
              type="button"
              className={"flex gap-2 !h-[35px] w-fit p-2 min-w-[100px]"}
              textClass={"!text-[11px]"}
              disabled={refreshloading || changeStatusloading}
              onClick={() => history.goBack()}
            />
            <Button
              text={"Close"}
              type="button"
              className={"flex gap-2 !h-[35px] w-fit p-2 min-w-[100px]"}
              textClass={"!text-[11px]"}
              disabled={refreshloading || changeStatusloading}
              onClick={() => {
                history.push("/employees");
              }}
            />
          </div>
        </div>
      )}
    </EmployeeOnboardingLayout>
  );
}

export default InvitationLinkPage;
