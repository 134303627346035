import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormDatalist from "../../../components/form-datalist";
import FormInput from "../../../components/form-input";
import FormSelect from "../../../components/form-select";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import LoadingBar from "../../../components/loader-bar";
import {
  useGetBanks,
  useGetPFA,
  useResolveBankAccount,
} from "../../../redux/bank/hook";
import { useCreateSingleEmployees } from "../../../redux/employees/hook";
import { formValidation } from "../../../utils/functions";
import { countriesAndState, AllCountries } from "../../../constants/countries";
import { useSelector } from "react-redux";

function CreateSingleEmployee() {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { mutateAsync, isLoading: loading } = useCreateSingleEmployees();

  const history = useHistory();
  const [gender, setGender] = useState("Male");
  const [country, setCountry] = useState("Nigeria");
  const [state, setState] = useState("Lagos");
  const [stateOptions, setStateOptions] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState("single");
  const [pfa_name, setPFAName] = useState("");
  const [bankname, setBankName] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [account_name, setAccountName] = useState("");

  const { user } = useSelector((state) => state.userSlice);

  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } =
    useResolveBankAccount();
  const { data: banks } = useGetBanks();
  const { data: PFAs } = useGetPFA();

  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const maritalOptions = [
    { value: "single", label: "Single" },
    { value: "married", label: "Married" },
  ];

  useEffect(() => {
    if (PFAs) {
      setPFAName(PFAs[0].value);
    }
  }, [PFAs]);

  useEffect(() => {
    if (account_number.length >= 10 && bankname !== "") {
      let payload = {
        bankname,
        account_number,
      };
      setAccountName("");
      resolveAccount(payload)
        .then((response) => {
          setAccountName(response.data.accountname);
        })
        .catch(() => {
          setAccountName("Not Resolved");
        });
    }
  }, [account_number, bankname]);

  useEffect(() => {
    if (country) {
      let getStates = countriesAndState.find((a) => a.name === country);

      if (getStates) {
        let computedStates = [];

        getStates.states.map((item, index) => {
          computedStates.push({ label: item, value: item });
        });

        setStateOptions(computedStates);
      }
    }
  }, [country]);

  const submitForm = async (data) => {
    const first_name = DOMPurify.sanitize(data?.first_name);
    const middle_name = DOMPurify.sanitize(data?.middle_name);
    const last_name = DOMPurify.sanitize(data?.last_name);
    const email = DOMPurify.sanitize(data?.email);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const date_joined = DOMPurify.sanitize(data?.date_joined);
    const dob = DOMPurify.sanitize(data?.dob);
    const address = DOMPurify.sanitize(data?.address);
    const city = DOMPurify.sanitize(data?.city);
    const account_number = DOMPurify.sanitize(data?.account_number);
    const account_name = DOMPurify.sanitize(data?.account_name);
    const rsa_pin = DOMPurify.sanitize(data?.rsa_pin);
    const tax_number = DOMPurify.sanitize(data?.tax_number);
    const job_position = DOMPurify.sanitize(data?.job_position);
    const staff_number = DOMPurify.sanitize(data?.staff_number);
    const next_of_kin_name = DOMPurify.sanitize(data?.next_of_kin_name);
    const next_of_kin_mobile = DOMPurify.sanitize(data?.next_of_kin_mobile);
    const next_of_kin_email = DOMPurify.sanitize(data?.next_of_kin_email);
    const next_of_kin_relationship = DOMPurify.sanitize(
      data?.next_of_kin_relationship
    );
    const next_of_kin_address = DOMPurify.sanitize(data?.next_of_kin_address);

    const requestData = {
      first_name,
      last_name,
      middle_name,
      email,
      mobile,
      gender,
      staff_number,
      dob,
      job_position,
      marital_status: maritalStatus,
      country: country,
      address,
      city,
      state: state,
      bank: bankname,
      account_number,
      account_name: account_name ? account_name : "Not Resolved",
      pfa_name,
      rsa_pin,
      tax_number,
      date_joined,
      next_of_kin_name,
      next_of_kin_mobile,
      next_of_kin_email,
      next_of_kin_relationship,
      next_of_kin_address,
    };

    await mutateAsync(requestData).then((result) => {
      if (
        user?.hr_subscription?.status !== 1 &&
        user?.hr_subscription?.status !== 2
      ) {
        history.push("/employees/onboarding/add-employee-to-group");
      } else {
        history.push(`/employees/onboarding/assign-onboarding-workflow`);
      }
    });
  };

  return (
    <EmployeeOnboardingLayout step={2} title={"Add Employee Details"}>
      <div className="flex flex-col w-full  h-full">
        <span className="hidden md:flex text-[20px] font-extrabold">
          Add Single Employee - Fill in your employee’s details
        </span>
        <hr className="divider mb-2 md:mt-2 -mt-12" />
        <div className="mt-2">
          <form className="form " onSubmit={handleSubmit(submitForm)}>
            <div className="p-2 rounded-md border bg-highlight">
              <div className="header-4 mb-2 text-color-gray">
                Employee Personal Information
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormInput
                    label="First name"
                    name="first_name"
                    type="text"
                    className={"!bg-white"}
                    placeholder={"Enter First Name"}
                    inputRef={register(formValidation("text", true))}
                    readOnly={loading}
                    error={errors.first_name}
                    errorMessage={
                      errors.first_name && errors.first_name.message
                    }
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    label="Last name"
                    name="last_name"
                    type="text"
                    className={"!bg-white"}
                    placeholder={"Enter Last Name"}
                    inputRef={register(formValidation("text", true))}
                    readOnly={loading}
                    error={errors.last_name}
                    errorMessage={errors.last_name && errors.last_name.message}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormInput
                    label="Middle Name - (Optional)"
                    name="middle_name"
                    type="text"
                    className={"!bg-white"}
                    placeholder={"Enter Middle Name"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                    error={errors.middle_name}
                    errorMessage={
                      errors.middle_name && errors.middle_name.message
                    }
                  />
                </div>

                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    label="Phone Number"
                    name="mobile"
                    type="text"
                    className={"!bg-white"}
                    placeholder={"Enter Phone Number"}
                    inputRef={register(formValidation("text", true))}
                    readOnly={loading}
                    error={errors.mobile}
                    errorMessage={errors.mobile && errors.mobile.message}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-2/3 md:pr-2">
                  <FormInput
                    label="Email"
                    name="email"
                    type="text"
                    className={"!bg-white"}
                    placeholder={"Enter Email"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                    error={errors.email}
                    errorMessage={errors.email && errors.email.message}
                  />
                </div>

                <div className="w-full md:w-1/3 md:pl-2">
                  <FormInput
                    label="Job Position - (Optional)"
                    name="job_position"
                    type="text"
                    className={"!bg-white"}
                    placeholder={"Enter Employee Role"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                    error={errors.job_position}
                    errorMessage={
                      errors.job_position && errors.job_position.message
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormSelect
                    defaultValue={gender}
                    options={genderOptions}
                    onChange={(selected) => {
                      setGender(selected);
                    }}
                    label="Gender"
                    extraClass={"!bg-white"}
                  />
                </div>

                <div className="w-full md:w-1/2 md:pl-2">
                  <FormSelect
                    defaultValue={maritalStatus}
                    options={maritalOptions}
                    onChange={(selected) => {
                      setMaritalStatus(selected);
                    }}
                    label="Marital Status"
                    extraClass={"!bg-white"}
                  />
                </div>
              </div>
              <div className="flex md:flex-row flex-col w-full justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormInput
                    label="Date Of Birth"
                    name="dob"
                    type="date"
                    inputRef={register(formValidation("date", true))}
                    error={errors.dob}
                    errorMessage={errors.dob && errors.dob.message}
                    className={"!bg-white"}
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-2">
                  <FormSelect
                    defaultValue={country}
                    options={AllCountries}
                    onChange={(selected) => {
                      setCountry(selected);
                    }}
                    label="Country"
                    extraClass={"!bg-white"}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  {country == "Nigeria" ? (
                    <FormSelect
                      defaultValue={state}
                      options={stateOptions}
                      onChange={(selected) => {
                        setState(selected);
                      }}
                      label="State"
                      extraClass={"!bg-white"}
                    />
                  ) : (
                    <FormInput
                      label="State"
                      name="state"
                      type="text"
                      placeholder={"Enter State"}
                      value={state}
                      onInput={(e) => setState(e.target.value)}
                      error={errors.state}
                      errorMessage={errors.state && errors.state.message}
                      className={"!bg-white"}
                    />
                  )}
                </div>
                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    label="City"
                    name="city"
                    type="text"
                    placeholder={"Enter City"}
                    inputRef={register(formValidation("text", true))}
                    readOnly={loading}
                    error={errors.city}
                    errorMessage={errors.city && errors.city.message}
                    className={"!bg-white"}
                  />
                </div>
              </div>
              <FormInput
                label="Stress Address"
                name="address"
                type="text"
                placeholder={"Enter Street Address"}
                inputRef={register(formValidation("text", true))}
                readOnly={loading}
                error={errors.address}
                errorMessage={errors.address && errors.address.message}
                className={"!bg-white"}
              />
            </div>
            <hr className="divider mb-2 mt-2" />
            <div className="p-2 rounded-md border bg-highlight">
              <div className="header-4 mb-2 text-color-gray">
                Payroll Information
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormInput
                    label="Staff Number - (Optional)"
                    name="staff_number"
                    type="text"
                    placeholder={"Enter Staff Number"}
                    inputRef={register(formValidation("date", false))}
                    readOnly={loading}
                    className={"!bg-white"}
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    label="Date Of Employment"
                    name="date_joined"
                    type="date"
                    inputRef={register(formValidation("date", false))}
                    readOnly={loading}
                    error={errors.date_joined}
                    errorMessage={
                      errors.date_joined && errors.date_joined.message
                    }
                    className={"!bg-white"}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormDatalist
                    label="Bank Name"
                    data={banks ? banks : []}
                    value={bankname}
                    onSelect={(value) => {
                      setAccountNumber("");
                      setBankName(value);
                    }}
                  />
                </div>

                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    label="Bank Account Number"
                    placeholder={"Enter Account Number"}
                    name="account_number"
                    type="text"
                    value={account_number}
                    inputRef={register(formValidation("text", true))}
                    error={errors.account_number}
                    errorMessage={
                      errors.account_number && errors.account_number.message
                    }
                    onInput={(e) => setAccountNumber(e.target.value)}
                    className={"!bg-white"}
                  />
                </div>
              </div>
              <div className="w-full">
                <FormInput
                  label="Account Name (Auto-Generated)"
                  name="account_name"
                  type="text"
                  placeholder={
                    resolveAccountLoading
                      ? "Fetching Account Name..."
                      : "Auto Generated"
                  }
                  readOnly
                  inputRef={register(formValidation("text", true))}
                  value={account_name}
                  error={errors.account_name}
                  errorMessage={
                    errors.account_name && errors.account_name.message
                  }
                  className={"!bg-white"}
                />
              </div>
              <hr className="divider mt-2 mb-2" />
              {PFAs ? (
                <FormSelect
                  value={pfa_name}
                  options={PFAs}
                  onChange={(selected) => {
                    setPFAName(selected);
                  }}
                  label="Pension Fund Administrator (PFA) - (Optional)"
                  extraClass={"!bg-white"}
                />
              ) : (
                <LoadingBar loading={true} />
              )}
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormInput
                    label="RSA PIN - (Optional)"
                    name="rsa_pin"
                    type="text"
                    placeholder={"Enter RSA Pin"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                    className={"!bg-white"}
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    label="Tax id - (Optional)"
                    name="tax_number"
                    type="text"
                    placeholder={"Enter Tax Number"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                    className={"!bg-white"}
                  />
                </div>
              </div>
            </div>
            <hr className="divider mb-2 mt-2" />
            <div className="p-2 rounded-md border bg-highlight">
              <div className="header-4 mb-2 text-color-gray">
                Next Of Kin Information - (Optional)
              </div>
              <FormInput
                label="Next Of Kin Name - (Optional)"
                name="next_of_kin_name"
                type="text"
                placeholder={"Enter Full Name"}
                inputRef={register(formValidation("text", false))}
                readOnly={loading}
                error={errors.next_of_kin_name}
                errorMessage={
                  errors.next_of_kin_name && errors.next_of_kin_name.message
                }
                className={"!bg-white"}
              />
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormInput
                    label="Next Of Kin Mobile Number - (Optional)"
                    name="next_of_kin_mobile"
                    type="text"
                    placeholder={"Enter Next Of Kin Mobile"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                    error={errors.next_of_kin_mobile}
                    errorMessage={
                      errors.next_of_kin_mobile &&
                      errors.next_of_kin_mobile.message
                    }
                    className={"!bg-white"}
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    label="Next Of Kin Email - (Optional)"
                    name="next_of_kin_email"
                    type="text"
                    placeholder={"Enter Next Of Kin Email"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                    error={errors.next_of_kin_email}
                    errorMessage={
                      errors.next_of_kin_email &&
                      errors.next_of_kin_email.message
                    }
                    className={"!bg-white"}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 md:pr-2">
                  <FormInput
                    label="Relationship - (Optional)"
                    name="next_of_kin_relationship"
                    type="text"
                    placeholder={"What is your relationship?"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                    error={errors.next_of_kin_relationship}
                    errorMessage={
                      errors.next_of_kin_relationship &&
                      errors.next_of_kin_relationship.message
                    }
                    className={"!bg-white"}
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-2">
                  <FormInput
                    label="Next Of Kin Address - (Optional)"
                    name="next_of_kin_address"
                    type="text"
                    placeholder={"Enter Address of Next Of Kin"}
                    inputRef={register(formValidation("text", false))}
                    readOnly={loading}
                    error={errors.next_of_kin_address}
                    errorMessage={
                      errors.next_of_kin_address &&
                      errors.next_of_kin_address.message
                    }
                    className={"!bg-white"}
                  />
                </div>
              </div>
            </div>

            <div className="w-full mt-[20px]">
              <Button text="Add and Continue" type="submit" loading={loading} />
            </div>
            <div
              className="flex justify-center w-full cursor-pointer hover:underline hover:scale-110 duration-300 mt-2"
              onClick={() => history.goBack()}
            >
              <span className="p2-medium text-color-black mt-3">Go Back</span>
            </div>
          </form>
        </div>
      </div>
    </EmployeeOnboardingLayout>
  );
}

export default CreateSingleEmployee;
