import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import FormInput from "../../components/form-input";
import Button from "../../components/button";
import { useLogin } from "../../redux/user/hook";
import { formValidation } from "../../utils/functions";
import AuthLayout from "../../components/layouts/auth";
import { useSelector } from "react-redux";
import { setAlert } from "../../redux/components/components-slice";
import { store } from "../../redux";

const SignInPage = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });
  const [loginSection, setloginSection] = useState("payroll");

  const { section } = useSelector((state) => state.sectionSlice);

  useEffect(() => {
    if (section) {
      if (section.name == "core hr") {
        setloginSection("core hr");
      } else if (section.name == "spend management") {
        setloginSection("spend management");
      }
    }
  }, [section]);

  const { mutate: signIn, isLoading: loading } = useLogin();
  const { webToken } = useSelector((state) => state.userSlice);

  useEffect(() => {}, []);

  const submitForm = async (data) => {
    if (loginSection) {
      const email = DOMPurify.sanitize(data?.email);
      const password = DOMPurify.sanitize(data?.password);
      const source = "web";
      const web_token = webToken ? webToken : "no_token";

      const requestData = {
        email,
        password,
        section: loginSection,
        source,
        web_token,
      };
      signIn(requestData);
    } else {
      store.dispatch(setAlert(true, "info", "Select a module to log in"));
    }
  };

  return (
    <AuthLayout>
      <>
        <form
          className="form md:px-4 min-h-full flex-col justify-center items-center"
          onSubmit={handleSubmit(submitForm)}
        >
          <div className="w-full text-center flex flex-col pb-3 gap-2">
            <span className={"text-[25px] font-medium text-black"}>LOGIN</span>
            <Button
              text="Sign in with Google"
              leftIcon={"googleIcon"}
              className={"gap-4"}
              textClass={"!capitalize"}
              type="button"
              disabled={loading}
              onClick={() => {
                window.location.assign(
                  `https://production.ropay.africa/google_auth`
                );
              }}
            />
            <div className="flex justify-center items-center flex-row mt-3 font-bold text-[15px]">
              <div className="bg-color-border w-[100%] h-[1px] mr-[6px]"></div>
              <p className="!text-[15px]">OR</p>
              <div className="bg-color-border w-[100%] h-[1px] ml-[6px]"></div>
            </div>
          </div>
          <FormInput
            label=""
            placeholder={"Email Address"}
            name="email"
            type="text"
            inputRef={register(formValidation("email", true))}
            readOnly={loading}
            error={errors.email}
            errorMessage={errors.email && errors.email.message}
          />
          <FormInput
            label=""
            placeholder={"Password"}
            name="password"
            type="password"
            inputRef={register(formValidation("password", true))}
            readOnly={loading}
            error={errors.password}
            errorMessage={errors.password && errors.password.message}
          />
          <div className="flex justify-between pb-[3px] pt-[3px] text-[13px] w-full">
            <FormInput
              label="Remember me"
              name="remember"
              type="checkbox"
              inputRef={register(formValidation("checkbox"))}
              className={"!text-[13px]"}
              readOnly={loading}
              error={errors.remember}
              errorMessage={errors.remember && errors.remember.message}
            />
            <div className="flex justify-end cursor-pointer hover:underline text-[14px] font-extrabold w-1/2">
              <Link to="/forgot">Forgot Password?</Link>
            </div>
          </div>
          <Button
            text="Sign in"
            type="submit"
            loading={loading}
            disabled={loading}
          />
        </form>
        <span className="flex justify-center text-[15px] text-color-gray mt-3">
          Don’t have an account?&ensp;
          <Link to="/register" className="hover:underline duration-300">
            <span className="text-[15px] font-extrabold">Sign Up</span>
          </Link>
        </span>
      </>
    </AuthLayout>
  );
};

export default SignInPage;
