import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  PAGINATION_DEFAULT,
  salaryAdvanceApplicationStatusConfig,
} from '../../../utils/constants';
import DataTable from '../../datatable';
import ApproveSalaryAdvance from './approve-salary-advance';
import {
  useDeclineSalaryAdvanceApplication,
  useGetSalaryAdvanceApplications,
} from '../../../redux/employees/hook/salary-advance';
import Modal from '../modal';
import Button from '../../button';
import AddSalaryAdvanceApplication from './add-salary-advance-application';

function SalaryAdvanceApplications({ isOpen, closeModal }) {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: '',
  });

  const [salaryAdvanceData, setsalaryAdvanceData] = useState();
  const [showAddSalaryAdvanceApplication, setShowAddSalaryAdvanceApplication] = useState(false); 

  const [showApprove, setShowApprove] = useState(false);

  const [approvalType, setApprovalType] = useState('approve');

  const [applicationPay, setApplicationPay] = useState(false);

  const { data: salary_advance } = useGetSalaryAdvanceApplications(
    pagination?.limit,
    pagination?.page,
    pagination?.search,
  );

  const { mutateAsync: declineApplication } =
    useDeclineSalaryAdvanceApplication();

  const tableConfig = {
    headers: [
      'Date Created',
      'Employee Name',
      'Mobile',
      'Repayment Start',
      'Repayment End',
      'Amount',
      'Status',
    ],
    keys: [
      'created',
      'employeeName',
      'mobile',
      'formatted_repayment_start',
      'formatted_repayment_end',
      'amount',
      'status',
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Status',
        key: 'status',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Approve',
      color: 'green',
      action: (id) => {
        let index = salary_advance.data.findIndex((item) => item.id === id);
        setsalaryAdvanceData(salary_advance.data[index]);
        setApprovalType('approve');
        setApplicationPay(false);
        setShowApprove(true);
      },
    },
    {
      name: 'Pay Now',
      color: 'green',
      action: (id) => {
        let index = salary_advance.data.findIndex((item) => item.id === id);
        setsalaryAdvanceData(salary_advance.data[index]);
        setApprovalType('pay');
        setApplicationPay(true);
        setShowApprove(true);
      },
    },
    {
      name: 'Decline',
      color: 'red',
      action: (id) => {
        let index = salary_advance.data.findIndex((item) => item.id === id);
        declineApplication(salary_advance.data[index].id);
      },
    },
    {
      name: 'Decline Payment',
      color: 'red',
      action: (id) => {
        let index = salary_advance.data.findIndex((item) => item.id === id);
        declineApplication(salary_advance.data[index].id);
      },
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='!min-h-screen !min-w-[100%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      {showApprove ? (
        <ApproveSalaryAdvance
          isOpen={showApprove}
          closeModal={() => setShowApprove(false)}
          approvalType={approvalType}
          applicationPay={applicationPay}
          salaryAdvance={salaryAdvanceData}
        />
      ) : null}
      {showAddSalaryAdvanceApplication?
          <AddSalaryAdvanceApplication
            isOpen={showAddSalaryAdvanceApplication}
            closeModal={() => setShowAddSalaryAdvanceApplication(false)}
          />
        :
        null} 
      <div className='flex flex-col !min-h-full overflow-auto'>
        <div className='pt-3 pr-8 pl-8'>
          <div className='text-center flex flex-col'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Salary Advance Applications</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
        </div>
        <hr className='divider -mb-2 mt-2' />
        <div className='flex flex-row justify-end items-center px-4 gap-[10px] my-2 mt-3'>
          <div className='flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full'>
            <div className='md:w-fit w-[100%]'>
              <Button
              text='New Application'
              type='button'
              theme='primary'
              className='flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center'
              textClass={'!text-[11px]'}
              leftIcon={'add'}
              onClick={() => {
                setShowAddSalaryAdvanceApplication(true)
              }}
              />
            </div>
          </div>
        </div>
        <hr className='divider mt-0 mb-1' />
        <DataTable
          data={salary_advance}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={salaryAdvanceApplicationStatusConfig}
          updatePagination={(data) => setPagination(data)}
          computedActions={true}
          searchData={true}
          noCheck={true}
        />
      </div>
    </Modal>
  );
}

export default SalaryAdvanceApplications;
