import { Axios } from '../axios';

const getDashboard = async () => {

  const { data } = await Axios.get('/spend/dashboard',
  {
    timeout: 30000
  });
  return data?.data;
};

export const spendDashboard = {
    getDashboard,
};
