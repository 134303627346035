import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import HRAttendanceOverviewTab from "../../../components/hr-section/attendance/attendance-overview";
import { CardHr } from "../../../components/modal/hr-section/dashboard/cardHR";
import EmployeeLeaveSVG from "../../../assets/svg/employee-leave.svg";
import EmployeePresentSVG from "../../../assets/svg/employee-present.svg";
import EmployeeAbsentSVG from "../../../assets/svg/employee-absent.svg";
import AttendanceSettings from "../../../components/hr-section/attendance/attendance-settings";
import AttendanceScheduleTab from "../../../components/hr-section/attendance/attendance-schedule";
import { useGetAttendanceSummary } from "../../../redux/human-resources/hook/attendance";
import LoadingBar from "../../../components/loader-bar";
import UnauthorizedPage from "../../errors/unauthorized";
import { useSelector } from "react-redux";
import HRAttendanceAnalyticsTab from "../../../components/hr-section/attendance/attendance-analytics";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const HRAttendancePage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.attendance_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);
  const { data: getAttendanceSummary, isLoading: summaryLoading } =
    useGetAttendanceSummary();
  const [attendanceSummary, setAttendanceSummary] = useState([]);

  useEffect(() => {
    if (getAttendanceSummary) {
      setAttendanceSummary([
        {
          title: "EMPLOYEES PRESENT",
          value: getAttendanceSummary.employees_present,
          icon: EmployeePresentSVG,
        },
        {
          title: "EMPLOYEES ON LEAVE",
          value: getAttendanceSummary.employees_on_leave,
          icon: EmployeeLeaveSVG,
        },
        {
          title: "EMPLOYEES ABSENT",
          value: getAttendanceSummary.employees_absent,
          icon: EmployeeAbsentSVG,
        },
      ]);
    } else {
      setAttendanceSummary([
        {
          title: "EMPLOYEES PRESENT",
          value: 0,
          icon: EmployeePresentSVG,
        },
        {
          title: "EMPLOYEES ON LEAVE",
          value: 0,
          icon: EmployeeLeaveSVG,
        },
        {
          title: "EMPLOYEES ABSENT",
          value: 0,
          icon: EmployeeAbsentSVG,
        },
      ]);
    }
  }, [getAttendanceSummary]);

  const [currentLocation, setCurrentLocation] = useState("summary");

  const location = useLocation();

  useEffect(() => {
    if (location) {
      setCurrentLocation(location.pathname.split("/")[2]);
    }
  }, [location]);

  return (
    <DashboardLayout title="Attendance Management">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row justify-between -mt-4">
            {!summaryLoading && currentLocation !== "settings" ? (
              <div className="h-fit p-3 flex flex-col justify-between w-full">
                <div className="grid md:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-2 w-[100%] ">
                  {attendanceSummary?.map((item) => (
                    <CardHr
                      title={item.title}
                      subtitle={item.value}
                      icon={item.icon}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row justify-between items-center w-[100%] p-2 pt-4">
                <div className="h-fit pb-3 flex flex-col justify-between w-full">
                  <div className="grid grid-cols-1 gap-4">
                    <LoadingBar loading={summaryLoading} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col">
            {currentLocation === "summary" ? (
              <>
                {user?.permissions.attendance_permission.attendance_read ===
                  1 || user?.permissions.company_permission.role === "Owner" ? (
                  <HRAttendanceOverviewTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "analysis" ? (
              <>
                {user?.permissions.attendance_permission.attendance_read ===
                  1 || user?.permissions.company_permission.role === "Owner" ? (
                  <HRAttendanceAnalyticsTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "schedule" ? (
              <>
                {user?.permissions.attendance_permission
                  .attendance_schedule_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <AttendanceScheduleTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "settings" ? (
              <>
                {user?.permissions.attendance_permission
                  .attendance_settings_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <AttendanceSettings />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : null}
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default HRAttendancePage;
