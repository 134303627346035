import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import FormInput from '../../form-input';
import Modal from '../modal';
import moment from 'moment';
import { formatNumber } from '../../../utils/functions';

function AdvancedPayrollDetailedView({ isOpen, closeModal, viewData }) {
  const [payloadData, setPayloadData] = useState(null);

  useEffect(() => {
    if (viewData) {
      setPayloadData(viewData);
    }
  }, [viewData]);

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <div className='form'>
          <div className='px-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-3'>Payslip Detailed</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8 gap-2'>
            {payloadData ? (
              
            <div className='flex flex-col md:flex-col w-full justify-between gap-2'>
              <div className='flex flex-col p-2 border rounded-md bg-secondary-2-extralight'>
                <div className='flex flex-col border bg-white rounded-md p-2 mb-2 gap-2'>
                  <span className='text-[15px] text-color-gray'>
                    Employee name
                  </span>
                  <span className='text-[16px] font-bold text-color-black-2 -mt-2'>
                    {payloadData.employeeName} | {payloadData.employee_phone}
                  </span>
                </div>
                <span className='text-[15px] text-color-gray pb-2'>
                  Payroll Information:
                </span>
                <span className='text-[14px] font-bold text-color-black-2 -mt-2'>
                  <span className='font-bold'>Month: </span>{moment(payloadData.month).format('MMMM, YYYY')} <br />
                  <span className='font-bold'>Bank Name: </span>{payloadData.bank_name} <br />
                  <span className='font-bold'>Account Number: </span>{payloadData.account_number} <br />
                  <span className='font-bold'>Account Name: </span>{payloadData.account_name}
                </span>
              </div>
              <hr className='divider mt-2 mb-2' />
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Basic'
                    name='basic'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.basic_salary, 2)}
                  />
                </div>

                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormInput
                    label='Housing'
                    name='housing'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.housing_allowance, 2)}
                  />
                </div>
              </div>

              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Transportation'
                    name='basic'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.transport_allowance, 2)}
                  />
                </div>
                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormInput
                    label='Leave allowance'
                    name='leave'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.leave_allowance, 2)}
                  />
                </div>
              </div>
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Lunch allowance'
                    name='lunch'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.lunch_allowance, 2)}
                  />
                </div>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Utility allowance'
                    name='utility'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.utility_allowance, 2)}
                  />
                </div>
              </div>
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Overtime'
                    name='overtime'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.overtime, 2)}
                  />
                </div>
                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormInput
                    label='Other allowance'
                    name='other'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.other_allowance, 2)}
                  />
                </div>
              </div>
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Bonus'
                    name='bonus'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.bonus, 2)}
                  />
                </div>
              </div>
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Gross Pay'
                    name='gross_pay'
                    type='text'
                    className={'!bg-gray-100'}
                    readOnly
                    value={formatNumber(payloadData?.gross_pay, 2)}
                  />
                </div>
              </div>
              <hr className='divider' />
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Employee Pension'
                    name='lunch'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.pension, 2)}
                  />
                </div>
                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormInput
                    label='Tax (PAYE)'
                    name='tax'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.tax, 2)}
                  />
                </div>
              </div>
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='NHIS'
                    name='nhis'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.nhis, 2)}
                  />
                </div>
                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormInput
                    label='NHF'
                    name='tax'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.nhf, 2)}
                  />
                </div>
              </div>
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Salary Advance (Deduction)'
                    name='nhis'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.salary_advance, 2)}
                  />
                </div>
                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormInput
                    label='Other Deductions'
                    name='other_deductions'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.other_deductions, 2)}
                  />
                </div>
               
              </div>
              <div className='flex flex-col md:flex-row justify-between'>
              <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Reimbursement (Addition)'
                    name='tax'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.reimburse, 2)}
                  />
                </div>
                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormInput
                    label='Net Pay (Take Home)'
                    name='net_pay'
                    type='text'
                    className={'border-lime-600 bg-lime-200 font-bold border-b-4'}
                    readOnly
                    value={formatNumber(payloadData?.net_pay, 2)}
                  />
                </div>
              </div>
              <hr className='divider' />
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Employer Pension'
                    name='lunch'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.employer_pension, 2)}
                  />
                </div>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Employer NHIS'
                    name='lunch'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.employer_nhis, 2)}
                  />
                </div>
              </div>
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='NSITF'
                    name='tax'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.nsitf, 2)}
                  />
                </div>
                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormInput
                    label='ITF'
                    name='tax'
                    type='text'
                    readOnly
                    value={formatNumber(payloadData?.itf, 2)}
                  />
                </div>
              </div>
            </div>
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AdvancedPayrollDetailedView;
