import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import FormInput from "../../components/form-input";
import Button from "../../components/button";
import { useSetPassword } from "../../redux/user/hook";
import { formValidation } from "../../utils/functions";
import AuthLayout from "../../components/layouts/auth";
import { useSelector } from "react-redux";

const SetPassword = () => {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });
  const { section } = useSelector((state) => state.sectionSlice);

  const history = useHistory();
  const { mutateAsync: setAdminPassword, isLoading: setPasswordLoading } =
    useSetPassword();

  const [Password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { token } = useParams();

  const submitForm = async (data) => {
    const password = DOMPurify.sanitize(data?.password);
    const password_confirmation = DOMPurify.sanitize(data?.confirm_password);

    const requestData = {
      token,
      password_confirmation,
      password,
    };

    await setAdminPassword(requestData).then(() => {
      history.push(`/dashboard`);
    });
  };

  return (
    <AuthLayout>
      <>
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <div className="w-full text-center flex flex-col pb-[25px]">
            <span
              className={
                section && section.name === "core hr"
                  ? "header-3-regular md:header-2 text-hr-primary-1 pb-1"
                  : "header-3-regular md:header-2 text-secondary-2 pb-1"
              }
            >
              Welcome
            </span>
            <span className="text-[15px] text-gray-500">
              Enter Your New Password
            </span>
          </div>

          <FormInput
            label="Password"
            name="password"
            type="password"
            inputRef={register(formValidation("password", true))}
            readOnly={setPasswordLoading}
            error={errors.password}
            errorMessage={errors.password && errors.password.message}
            onInput={(e) => setPassword(e.target.value)}
            infos={[
              "Password should contain minimum of 8 characters, 1 caps letter and 1 number",
            ]}
          />

          <FormInput
            label="Confirm Password"
            name="confirm_password"
            type="password"
            inputRef={register(formValidation("password", true))}
            onInput={(e) => setConfirmPassword(e.target.value)}
            readOnly={setPasswordLoading}
            error={confirmPassword !== Password && confirmPassword.trim()}
            errorMessage={"Password does not match"}
          />
          <Button
            text="SET PASSWORD"
            type="submit"
            loading={setPasswordLoading}
            disabled={!isValid}
          />
        </form>
        <span className="flex justify-center text-[14px] text-color-gray mt-3">
          Already have an account?&ensp;
          <Link to="/" className="hover:underline duration-300">
            <span className="text-[14px] font-extrabold">Sign In</span>
          </Link>
        </span>
      </>
    </AuthLayout>
  );
};

export default SetPassword;
