import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../components/layouts/dashboard";
import TabView from "../../../../components/tabview";
import { useHistory } from "react-router-dom";
import { CardHr } from "../../../../components/modal/hr-section/dashboard/cardHR";
import RecruitmentSVG from "../../../../assets/svg/recruit-logo.svg";
import EmployeeOnboardingSVG from "../../../../assets/svg/employee-awaiting-approval.svg";
import EmployeeReviewedSVG from "../../../../assets/svg/employee-green.svg";
import WorkflowSVG from "../../../../assets/svg/workflow-widget-icon.svg";
import { useDispatch } from "react-redux";
import OnboardingOverviewTab from "../../../../components/employee-onboarding/onboardings";
import OnboardingWorkflowTab from "../../../../components/employee-onboarding/onboarding-workflows";
import { useGetEmployeeOnboardingSummary } from "../../../../redux/employees/hook/onboarding";
import UnauthorizedPage from "../../../errors/unauthorized";
import { useSelector } from "react-redux";

const EmployeeOnboardingPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (
          user.permissions.company_permission.employee_onboarding_read !== 1
        ) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);
  const { data: getOnboardingSummary } = useGetEmployeeOnboardingSummary();
  const [onboardingSummary, setOnboardingSummary] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (getOnboardingSummary) {
      setOnboardingSummary([
        {
          title: "TOTAL ONGOING",
          value: getOnboardingSummary.ongoing,
          icon: EmployeeOnboardingSVG,
        },
        {
          title: "TOTAL REVIEWED",
          value: getOnboardingSummary.completed,
          icon: EmployeeReviewedSVG,
        },
        {
          title: "TOTAL WORKFLOWS",
          value: getOnboardingSummary.workflows,
          icon: WorkflowSVG,
        },
      ]);
    } else {
      setOnboardingSummary([
        {
          title: "TOTAL ONGOING",
          value: 0,
          icon: RecruitmentSVG,
        },
        {
          title: "TOTAL COMPLETED",
          value: 0,
          icon: RecruitmentSVG,
        },
        {
          title: "TOTAL ONBOARDING WORKFLOWS",
          value: 0,
          icon: WorkflowSVG,
        },
      ]);
    }
  }, [getOnboardingSummary]);

  const exitTabs = [
    {
      title: "Onboarding",
      Content:
        user?.permissions.employee_onboarding_permission
          .employee_onboarding_read === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? OnboardingOverviewTab
          : UnauthorizedPage,
    },
    {
      title: "Workflows",
      Content:
        user?.permissions.employee_onboarding_permission.workflow_read === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? OnboardingWorkflowTab
          : UnauthorizedPage,
    },
  ];

  return (
    <DashboardLayout title="Employee Onboarding">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row justify-between items-center -mt-4">
              <div className="flex flex-col md:flex-row justify-between items-center !w-[100%]">
                <div className="h-fit p-3 flex flex-col justify-between w-full">
                  <div className="grid md:grid-cols-3 lg:grid-cols-3 grid-cols-2 gap-2">
                    {onboardingSummary?.map((item) => (
                      <CardHr
                        title={item.title}
                        subtitle={item.value}
                        icon={item.icon}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <TabView
                Tabs={exitTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default EmployeeOnboardingPage;
