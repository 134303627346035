import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormSelectEmployee from '../../employees/form-select-employee';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import Modal from '../modal';
import { useAddOtherStatutoryAllowance, useEditOtherStatutoryAllowances } from '../../../redux/employees/hook/other-statutory-allowances';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

function AddStatutoryAllowance({
  isOpen,
  closeModal,
  refresh,
  statutoryAllowanceData,
  title,
  buttonText,
  statEmployeeName=''
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const {id} = useParams()
  const { mutateAsync: addOtherStatutoryAllowance, isLoading: addOtherAllowanceloading } = useAddOtherStatutoryAllowance();
  const { mutateAsync: editOtherStatutoryAllowance, isLoading: editOtherAllowanceloading } = useEditOtherStatutoryAllowances();
  const [employee, setEmployee] = useState(id);
  const [employeeName, setEmployeeName] = useState(statEmployeeName);
  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY-'));
  const [amount, setAmount] = useState();
  const [allowanceName, setAllowanceName] = useState();

  const [statutoryAllowanceId, setStatutoryAllowanceId] = useState();
  const [statutoryAllowanceType, setStatutoryAllowanceType] = useState();

  const [otherStatutoryAllowanceId, setOtherStatutoryAllowanceId] = useState();
  const [otherStatutoryAllowanceType, setOtherStatutoryAllowanceType] = useState();

  const [frequency, setFrequency] = useState('monthly')
  const frequencyOptions = [
    {label:'One Time', value: 'one_time'},
    {label:'Monthly', value: 'monthly'}
  ]

  const [allowanceType, setAllowanceType] = useState('life_assurance_premium')
  const allowanceTypeOptions = [
    {label:'Life Assurance Premium', value: 'life_assurance_premium'},
    {label:'Gratuity', value: 'gratuity'}
  ]

  useEffect(() => {
    if (statutoryAllowanceData) {
      setMonth(statutoryAllowanceData.month? statutoryAllowanceData.month.split('-')[1].toString(): moment().format('MM'));
      setYear(statutoryAllowanceData.month? statutoryAllowanceData.month.split('-')[0].toString() + '-': moment().format('YYYY-'));
      setAllowanceName(statutoryAllowanceData.allowance_name)
      setAmount(parseFloat(statutoryAllowanceData.amount));
      setOtherStatutoryAllowanceId(statutoryAllowanceData.id);
      setFrequency(statutoryAllowanceData.frequency)
      setAllowanceType(statutoryAllowanceData.type)
      setOtherStatutoryAllowanceType('edit');
      
    } else {
      setOtherStatutoryAllowanceType('add');
    }
  }, [statutoryAllowanceData]);

  const submitForm = async (data) => {
    const allowance_name = DOMPurify.sanitize(data?.allowance_name);
    const amount = DOMPurify.sanitize(data?.amount);

    if (otherStatutoryAllowanceType === 'add') {
      let payload = {
        employee_id: id,
        allowance_name,
        frequency: frequency,
        type: allowanceType,
        month: frequency == 'one_time' ? year + month: '',
        amount,
        
      };
      await addOtherStatutoryAllowance(payload).then(() => {
        closeModal();
      });
    } else if (otherStatutoryAllowanceType === 'edit') {
      let editPayload = {
        id: otherStatutoryAllowanceId,
        body: {
            employee_id: id,
            allowance_name,
            amount: amount,
            frequency: frequency,
            type: allowanceType,
            month: frequency == 'one_time' ? year + month: '',
        },
      };
      await editOtherStatutoryAllowance(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='flex flex-row justify-between pl-8 pr-8 pt-4 items-center'>
            <span className='header-3'>{title}</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='border bg-secondary-2-extralight p-2 rounded-md mb-2'>
              <FormSelectEmployee
                label={'Employee'}
                name='employee'
                placeholder='Type to select Employee'
                value={employee}
                readOnly={statutoryAllowanceType === 'edit' ? true : false}
                empName={employeeName}
                setEmployee={setEmployee}
              />
            </div>
            <FormInput
                label='Statutory Allowance Name'
                name='allowance_name'
                type='text'
                placeholder={'Enter allowance name'}
                inputRef={register(formValidation('text', true))}
                readOnly={addOtherAllowanceloading || editOtherAllowanceloading}
                defaultValue={allowanceName}
                onInput={(e) => {
                  setAmount(e.target.value);
                }}
                error={errors.amount}
                errorMessage={errors.amount && errors.amount.message}
            />
            <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormSelect
                    defaultValue={allowanceType}
                    options={allowanceTypeOptions}
                    onChange={(selected) => {
                        setAllowanceType(selected);
                    }}
                    label='Statutory Allowance Type'
                  />
                </div>
                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormSelect
                    defaultValue={frequency}
                    options={frequencyOptions}
                    onChange={(selected) => {
                      setFrequency(selected);
                    }}
                    label='Select Frequency'
                  />
                </div>
            </div>
            {frequency === 'one_time'?
            <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                <FormSelect
                    defaultValue={month}
                    options={monthOptions}
                    onChange={(selected) => {
                    setMonth(selected);
                    }}
                    label='Select Month'
                />
                </div>
                <div className='w-full md:w-1/2 md:pl-2'>
                <FormSelect
                    defaultValue={year}
                    options={yearOptions}
                    onChange={(selected) => {
                    setYear(selected);
                    }}
                    label='Select Year'
                />
                </div>
            </div>
            :
            null}
            
            <FormInput
                label='Amount'
                name='amount'
                type='number'
                placeholder={'Enter amount'}
                inputRef={register(formValidation('number', true))}
                readOnly={addOtherAllowanceloading || editOtherAllowanceloading}
                defaultValue={amount}
                onInput={(e) => {
                  setAmount(e.target.value);
                }}
                error={errors.amount}
                errorMessage={errors.amount && errors.amount.message}
            />
            {/* <div className='flex flex-col justify-center'>
              <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light !text-[14px]'>
                <div className='w-[70px] pr-2 flex flex-row items-center '>
                  <img src={WarningSVG} alt='' srcSet='' />
                </div>
                <div>
                  Please note that the Bonus with be automatically applied on the payroll for the <span className='font-extrabold'>month</span> and <span className='font-extrabold'>year</span> selected
                </div>
              </div>
            </div> */}
            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                disabled={!isValid}
                loading={addOtherAllowanceloading || editOtherAllowanceloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddStatutoryAllowance;
