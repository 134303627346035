import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { documents } from '../../../api/documents';

export function useGetCompanyDocuments (limit, page, search) {
    return useQuery(
      ['company-documents', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return documents.getCompanyDocuments({
          limit,
          page,
          search,
        });
      },
      {
        select: (data) => {
          data?.data?.forEach((group) => {
            group.computedActions = [0, 1];
            group.created = moment(group?.created).format('MMMM D, YYYY');
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useAddCompanyDocument() {
  return useMutation(
    (payload) => {
      return documents.uploadCompanyDocument(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-documents');
        store.dispatch(setAlert(true, 'success', 'Added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteCompanyDocument() {
return useMutation(
  (payload) => {
    return documents.deleteCompanyDocument(payload);
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries('employee-documents');
      store.dispatch(setAlert(true, 'success', 'Document deleted successfully'));
    },
    onSettled: (data, error, variables, context) => {
      store.dispatch(setIsLoading(false));
    },
  },
);
}