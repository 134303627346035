import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import CloseSVG from '../../../assets/svg/close.svg';
import { useEditBasicPayrollById } from '../../../redux/payroll/hook';
import Button from '../../button';

import FormInput from '../../form-input';
import Modal from '../modal';
import { formatNumber } from '../../../utils/functions';

function BasicPayrollDetailedView({
  isOpen,
  closeModal,
  viewData,
  previewType,
}) {
  const [payloadData, setPayloadData] = useState(null);
  const [type, setType] = useState(null);
  const [netPay, setNetPay] = useState(null);
  const [id, setId] = useState(null);

  const { mutateAsync: editBasicPayroll, isLoading: editBasicLoading } =
    useEditBasicPayrollById();

  useEffect(() => {
    if (viewData) {
      setPayloadData(viewData);
      setType(previewType);
      setNetPay(viewData.net_pay);
      setId(viewData.id);
    }
  }, [viewData]);

  const submitForm = async () => {
    let payload = {
      id: id,
      data: {
        net_pay: netPay,
      },
    };
    await editBasicPayroll(payload).then((result) => {
      closeModal();
    });
  };
  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <div className='form pb-3'>
          <div className='px-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-3'>
                {type === 'view'
                  ? 'Basic Payroll Detailed'
                  : 'Edit Basic Payroll'}
              </span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col md:flex-col w-full justify-between'>
              {payloadData ? (
                <>
                  <div className='flex flex-col border bg-highlight p-2 rounded-md mb-2'>
                    <span className='text-[15px] font-extrabold text-color-gray'>
                      Employee name
                    </span>
                    <span className='text-[14px] text-color-black-2'>
                      {payloadData.employee_first_name}{' '}
                      {payloadData.employee_last_name}
                    </span>
                    <span className='text-[15px] font-extrabold text-color-gray mt-2'>
                      Bank Information
                    </span>
                    <span className='text-[14px] text-color-black-2'>
                      Bank Name: {payloadData.bank_name} <br />
                      Account Number: {payloadData.account_number}
                      <br />
                      Account Name: {payloadData.account_name}
                    </span>
                  </div>
                  <hr className='divider mt-0' />
                  <div className='flex flex-col md:flex-row justify-between'>
                    <div className='w-full md:w-1/2 md:pr-2'>
                      <FormInput
                        label={'Amount'}
                        name='amount'
                        type='text'
                        readOnly={type === 'view' ? true : false}
                        value={netPay}
                        onInput={(e) => {
                          setNetPay(parseFloat(e.target.value));
                        }}
                      />
                    </div>
                    <div className='w-full md:w-1/2 md:pr-2'>
                      <FormInput
                        label='Fee'
                        name='net_pay'
                        type='text'
                        readOnly
                        value={formatNumber(payloadData?.trans_cost, 2)}
                      />
                    </div>
                  </div>
                  {type === 'edit' ? (
                    <div className='w-full mb-[20px]'>
                      <Button
                        text='UPDATE'
                        type='submit'
                        disabled={type === 'view' ? true : false}
                        loading={editBasicLoading}
                        onClick={() => submitForm()}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default BasicPayrollDetailedView;
