import React, { useEffect, useRef, useState } from 'react';
import TickSVG from '../../assets/svg/tick-primary.svg';
import { useHistory } from 'react-router-dom';
import Logo from '../logo';
import { useDispatch } from 'react-redux';
import { updateModalStatus } from '../../redux/components/components-slice';
import { Initials } from '../../utils/functions';
import { store } from '../../redux';
import { setSection } from '../../redux/section/reducer';

const EmployeeRegistrationLayout = ({ children, step, title, company_name, logo, }) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateModalStatus(false));
  }, [])

  useEffect(() => {
    store.dispatch(setSection({
        entryRoute: '/payroll',
        name: 'core hr',
        sidebarItems: []
    }))
  }, [])

  const [steps, setSteps] = useState([
    {
      title: 'Profile Information',
      subtitle: 'Add your basic personal information',
      completed: false,
    },
    {
      title: 'Employment Information',
      subtitle: 'Add your employment information',
      completed: false,
    },
    {
      title: 'Add Bank information',
      subtitle: 'Select your preferred bank account for payroll and other payments',
      completed: false,
    },
    {
      title: 'Add Profile Picture',
      subtitle: 'Upload your profile picture for easy identification',
      completed: false,
    },
    
    {
      title: 'Verify and Submit',
      subtitle: 'Verify your information and submit for final activation',
      completed: false,
    },
  ]);

  useEffect(() => {
    if (step) {
      setCurrentStep(step);
    }
  }, [step, currentStep]);

  return (
    <div
      className='flex flex-col h-screen min-h-screen
    max-h-screen overflow-auto
    '
    >
      <div className='flex flex-col lg:bg-secondary-2'>
        {/* Desktop Sidebar */}
        <div className='flex flex-col justify-center lg:justify-start lg:px-0'>
          <div>
            <div className='flex flex-col'>
              {/* Mobile Stepper */}
              <div className='flex lg:hidden justify-center w-full mb-[10px]'>
                <div className='flex flex-row w-[85%] my-3'>
                  {steps.map((item, index) => (
                    <div
                      key={index}
                      className={`${
                        index + 1 !== steps.length
                          ? 'flex flex-row w-full items-center'
                          : ''
                      }`}
                    >
                      <div>
                        <div
                          className={`flex items-center justify-center w-[30px] h-[30px] rounded-[30px] ${
                            index + 1 <= currentStep
                              ? 'bg-secondary-2'
                              : 'border border-color-black'
                          }  ${
                            index + 1 === currentStep
                              ? 'opacity-100'
                              : 'opacity-40'
                          }`}
                        >
                          {index < currentStep - 1 ? (
                            <img
                              src={TickSVG}
                              alt='tick'
                              style={{ width: '16px', height: '16px' }}
                            />
                          ) : (
                            <span
                              className={`text-[16px] font-bold ${
                                index + 1 <= currentStep
                                  ? 'text-white'
                                  : 'text-color-black'
                              }`}
                            >
                              {index + 1}
                            </span>
                          )}
                        </div>
                      </div>

                      {index + 1 !== steps.length ? (
                        <div className='bg-color-border w-full h-[1px]'></div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
              <div className='flex flex-col lg:flex-row w-full'>
                <div className='w-full lg:w-[35%] flex flex-col lg:justify-start justify-center !max-h-screen overflow-auto ml-[5%]'>
                  <div className='w-full lg:w-[78%] flex flex-col'>
                    {/* Header */}
                    <span className='header-3'>{title}</span>
                  </div>

                  {/* Desktop Stepper */}
                  <div className='hidden lg:flex flex-col justify-between w-full mt-4'>
                    <div className='flex flex-col w-[100%]'>
                      <div className='flex flex-row items-center gap-4'>
                        <div className='text-[25px] flex justify-center items-center h-[60px] w-[60px] border-[3px] header-3 rounded-[60px] relative hover:scale-150 hover:m-2 hover:opacity-25 duration-500 text-white font-bold'>
                          {logo ? (
                            <div>
                              <img
                                src={logo}
                                alt='email_icon'
                                className='object-fit min-w-[52px] min-h-[52px] max-w-[52px] max-h-[52px]'
                              />
                            </div>
                          ) : (
                            <span className='text-white font-bold text-[30px]'>{Initials(company_name)}</span>
                          )}
                        </div>

                        <button
                          className='hidden lg:flex text-color-white header-3 text-[25px]'
                          onClick={() => history.push('/dashboard')}
                        >
                          {company_name}
                        </button>
                      </div>
                      <div className='mt-3 mb-5 flex flex-col'>
                        <span className='text-white text-[15px] font-extrabold'>
                          Welcome to the employee registration portal. 
                        </span>
                        <span className='text-white text-[15px] p3 mt-1'>
                        Complete your employee profiling in 5 easy steps.
                        </span>
                      </div>
                      {steps.map((item, index) => (
                        <div 
                          key={index}
                          className='flex flex-row mb-[50px]'>
                          <div className='flex items-center w-[15%]'>
                            <div
                              className={`flex items-center justify-center  w-[50px] h-[50px] rounded-[50px] ${
                                index + 1 <= currentStep
                                  ? 'bg-white'
                                  : 'border border-color-black bg-white'
                              }  ${
                                index + 1 == currentStep
                                  ? 'opacity-100'
                                  : 'opacity-40 '
                              }`}
                            >
                              {index < currentStep - 1 ? (
                                <img
                                  src={TickSVG}
                                  alt='tick'
                                  style={{ width: '20px', height: '20px' }}
                                />
                              ) : (
                                <span
                                  className={`header-3 ${
                                    index + 1 <= currentStep
                                      ? 'text-secondary-2'
                                      : 'text-secondary-2'
                                  }`}
                                >
                                  {index + 1}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='flex flex-col w-[85%] justify-center text-white pl-3'>
                            <span
                              className={`text-[18px] font-bold text-white leading-[23px] ${
                                index + 1 == currentStep
                                  ? 'text-opacity-100'
                                  : 'text-opacity-50'
                              }`}
                            >
                              {item.title}
                            </span>
                            <div className='w-[100%] mt-[4px]'>
                              <span
                                className={`text-[13px] text-justify leading-[20px] text-white ${
                                  index + 1 === currentStep
                                    ? 'text-opacity-80'
                                    : 'text-opacity-40'
                                } `}
                              >
                                {item.subtitle}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      className={
                        'flex items-center justify-center text-color-white gap-2  -ml-[20%]'
                      }
                    >
                      Powered By:{' '}
                      <a
                        href='https://www.ropay.africa'
                        target={'_blank'}
                        rel='noreferrer'
                        className='mt-2'
                      >
                        {<Logo width='70px' height='35px' white />}
                      </a>
                    </div>
                  </div>
                </div>

                <div className='w-full lg:w-[65%] h-screen'>
                  <div className='w-full bg-white h-full'>
                    {/* Page components */}
                    <div className='lg:max-h-[100vh] overflow-auto h-full'>
                      <div className='w-full flex items-center justify-center flex-col'>
                        <div className='w-full lg:max-w-[600px] flex flex-col lg:flex-row '>
                          <div className='w-full pb-[30px] !h-full'>
                            {children}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeRegistrationLayout;
