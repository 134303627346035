import React, { useEffect } from "react";
import DataTable from "../../../datatable";
import { useState } from "react";
import {
  employeeStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../../utils/constants";
import { useHistory } from "react-router-dom";
import { useGetBranchEmployeeSalaries } from "../../../../redux/employees/hook/salaries";
import SalaryReviews from "../../../modal/employees/salary-reviews";
import { useSelector } from "react-redux";
import HrHelpSVG from "../../../../assets/svg/hr-help.svg";
import InfoModal from "../../../modal/info-modal";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useGetBranchEmployees } from "../../../../redux/human-resources/hook/branches";
import Button from "../../../button";

const BranchEmployeeSalariesTab = () => {
  useEffect(() => {}, []);

  const { id } = useParams();

  const { section } = useSelector((state) => state.sectionSlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: employeeSalaries } = useGetBranchEmployeeSalaries(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { data: branch_employees } = useGetBranchEmployees(id, 5, 1, -1, "");

  const [showInfoModal, setShowInfoModal] = useState(false);

  const [showPayEmployees, setShowPayEmployees] = useState(false);
  const [showRunPayroll, setShowRunPayroll] = useState(false);
  const [showSalaryReviews, setShowSalaryReviews] = useState(false);
  const [payrollData, setPayrollData] = useState("");

  const history = useHistory();

  const tableConfig = {
    // title: 'Salary Management',
    headers: [
      "Employee Name",
      "Mobile",
      "Basic",
      "Transportation",
      "Housing",
      "Others",
      "Gross pay",
      "Status",
    ],
    keys: [
      "employeeName",
      "phone",
      "formatted_basic_salary",
      "formatted_transportation_allowance",
      "formatted_housing_allowance",
      "formatted_other_accum_allowance",
      "formatted_gross_pay",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "phone",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = employeeSalaries.data.findIndex((item) => item.id === id);

        if (index != -1) {
          history.push(
            `/employees/view/${employeeSalaries.data[index].employee}`
          );
        }
      },
    },
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = employeeSalaries.data.findIndex((item) => item.id === id);
        if (index != -1) {
          history.push(
            `/employees/onboarding/add-salary-information?type=single&salary=true`
          );
        }
      },
    },
  ];

  function openPayroll(data) {
    let newData = data.split(",");
    let result = [];

    newData.forEach((id) => {
      let index = employeeSalaries.data.findIndex((item) => item.id == id);
      if (index != -1) {
        result.push(employeeSalaries.data[index].employee);
      }
    });

    setPayrollData(result.join(","));
    setShowPayrollOptions(true);
  }

  const bulkActionConfig = [
    {
      label: "Pay Employee(s)",
      value: "pay-employees",
      action: (data) => {
        openPayroll(data);
      },
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-end w-full -mt-5">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={HrHelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      <div className="flex flex-col bg-white rounded">
        {showSalaryReviews ? (
          <SalaryReviews
            isOpen={showSalaryReviews}
            closeModal={() => setShowSalaryReviews(false)}
          />
        ) : null}
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"salary"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}
        {showPayEmployees ? (
          <RunBasicPayroll
            isOpen={showPayEmployees}
            source="selective"
            sourceData={payrollData}
            closeModal={() => setShowPayEmployees(false)}
          />
        ) : null}
        {showRunPayroll ? (
          <RunAdvancedPayroll
            isOpen={showRunPayroll}
            source="selective"
            sourceData={payrollData}
            closeModal={() => setShowRunPayroll(false)}
          />
        ) : null}
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Branch Salaries
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="View Salary Reviews"
                type="button"
                theme="primary"
                leftIcon={"view"}
                disabled={!employeeSalaries}
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                onClick={() => setShowSalaryReviews(true)}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-2" />
        <DataTable
          data={employeeSalaries}
          tableConfig={tableConfig}
          statusConfig={employeeStatusConfig}
          actionConfig={actionConfig}
          bulkActionConfig={
            section.name === "core hr" ? false : bulkActionConfig
          }
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          filterByStatus={true}
          computedActions={true}
        />
      </div>
    </div>
  );
};

export default BranchEmployeeSalariesTab;
