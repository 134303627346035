import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import TabView from "../../../components/tabview";
import { Initials } from "../../../utils/functions";
import TeamOverviewTab from "../../../components/employees/view-team/team-info";
import TeamScheduleTab from "../../../components/employees/view-team/team-schedule";
import TeamSettings from "../../../components/employees/view-team/team-settings";
import { useGetTeam } from "../../../redux/human-resources/hook/teams";
import UnauthorizedPage from "../../errors/unauthorized";
import { useSelector } from "react-redux";

const ViewTeamPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.team_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const { id } = useParams();

  const [activeTab, setActiveTab] = useState(1);

  const { data: team } = useGetTeam(id);

  const TeamTabs = [
    {
      title: "Employees",
      Content: TeamOverviewTab,
    },
    {
      title: "Attendance Schedule",
      Content: TeamScheduleTab,
    },
    {
      title: "Settings",
      Content: TeamSettings,
    },
  ];

  return (
    <DashboardLayout title="View Teams">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-row items-center box-shadow h-[104px]">
            <div className="flex flex-row px-[55px] justify-between  w-full">
              <div className="flex flex-row items-center">
                <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-[#FFC0CB]">
                  {team?.name ? Initials(team?.name) : null}
                </div>
                <div className="flex flex-col pl-4 items-start justify-center">
                  <span className="header-4">{team?.name}</span>
                  <span className="p4">{team?.description}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <TabView
              Tabs={TeamTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default ViewTeamPage;
