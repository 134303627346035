import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import { useGetSubscriptionHistory } from "../../redux/human-resources/hook/subscription";

const SubscriptionHistory = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  // const [showAddQuery, setShowAddQuery] = useState(false);
  // const [queryData, setQueryData] = useState();

  // const {mutateAsync: deleteQuery} = useDeleteQuery();

  const { data: history } = useGetSubscriptionHistory(
    pagination?.limit,
    pagination?.page
  );

  const tableConfig = {
    headers: [
      "Date Added",
      "Subscription Plan",
      "Start Date",
      "End Date",
      "Billing Cycle",
      "Amount Paid",
    ],
    keys: [
      "created",
      "subscription_plan",
      "start_date",
      "end_date",
      "billing_cycle",
      "amount_paid",
    ],
    mobileHeader: {
      left: {
        title: "Date Added",
        key: "date_created",
      },
      right: {
        title: "Employee Name",
        key: "employeeName",
      },
    },
  };

  // const actionConfig = [
  //   {
  //     name: 'View/Edit',
  //     color: 'green',
  //     action: (id) => {
  //       let index = queries.data.findIndex((item) => item.id === id);
  //       setQueryData(queries.data[index])
  //       setShowAddQuery(true)
  //     },
  //   },
  //   {
  //     name: 'Delete',
  //     color: 'red',
  //     action: (id) => {
  //       let index = queries.data.findIndex((item) => item.id === id);
  //       deleteQuery(queries.data[index].id)
  //     },
  //   },
  // ];

  return (
    <div className="flex flex-col bg-white rounded">
      <DataTable
        data={history}
        tableConfig={tableConfig}
        updatePagination={(data) => setPagination(data)}
        noCheck={true}
      />
    </div>
  );
};

export default SubscriptionHistory;
