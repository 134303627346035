import { Axios } from '../../axios';

const getTaxes = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/statutory-payments/taxes?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const bulkTaxes = async (payload) => {
  const { data } = await Axios.post('/statutory-payments/taxes/bulk', payload);
  return data;
};

const updateTax = async (payload) => {
  const { data } = await Axios.put('/statutory-payments/taxes/'+ payload.id, payload.body);
  return data;
};

const generateTaxOtp = async () => {
  const { data } = await Axios.post('/taxpal/generate-pin');
  return data;
};

const confirmConsent = async (payload) => {
  const { data } = await Axios.post('/taxpal/verify-consent', payload);
  return data;
};

const saveTaxPayroll = async (payload) => {
  const { data } = await Axios.post('/taxpal/save-payroll', payload,
  {
    timeout: 0
  });
  return data?.data.taxes;
};

const fetchTaxByRef = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(`/statutory-payments/taxes/by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}${search}`);
  if (data?.data) {
    data.data.taxes.total_amount = data?.data.total_amount
    data.data.taxes.total_payable = data?.data.total_payable
    data.data.taxes.total_trans_cost = data?.data.total_fees
  }
  return data?.data.taxes;
};

const disburseTax = async (payload) => {
  const { data } = await Axios.post('/taxpal/generate-bill', payload, 
  {
    timeout: 0
  });
  return data;
};

export const taxApis = {
    getTaxes,
    bulkTaxes,
    fetchTaxByRef,
    generateTaxOtp,
    saveTaxPayroll,
    confirmConsent,
    disburseTax,
    updateTax
};