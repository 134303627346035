import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRouteQuery } from "../../../hooks/useRouteQuery";
import { Initials, formatNumber, toDataUrl } from "../../../utils/functions";
import FormInput from "../../../components/form-input";
import FormDatalist from "../../../components/form-datalist";
import moment from "moment";
import Button from "../../../components/button";
import FormTextArea from "../../../components/form-textarea";
import FormSwitch from "../../../components/form-switch";
import { useGetCustomersToInvoice } from "../../../redux/spend-management/customer/hook";
import {
  useCreateSingleInvoice,
  useGetInvoice,
  useMarkInvoicePaid,
  useSendInvoice,
  useUpdateInvoice,
} from "../../../redux/spend-management/invoice/hook";
import { store } from "../../../redux";
import { setAlert } from "../../../redux/components/components-slice";
import { StatusText } from "../../../components/fragments/status-text";
import { invoiceStatusConfig } from "../../../utils/constants";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import classNames from "classnames";
import FormSelect from "../../../components/form-select";
import {
  useCreateSingleRecurringInvoice,
  useGetRecurringInvoice,
  useMarkRecurringInvoice,
  useUpdateRecurringInvoice,
} from "../../../redux/spend-management/recurring-invoices/hook";
import AddSmallSVG from "../../../assets/svg/add-icon-smal-spend.svg";
import SelectCustomerOption from "../../../components/modal/spend-management/customer/select-customer-option";
import { useGetBanks, useResolveBankAccount } from "../../../redux/bank/hook";
import LoadingBar from "../../../components/loader-bar";

const InvoicePage = () => {
  useEffect(() => {}, []);

  const history = useHistory();
  const { company } = useSelector((state) => state.companySlice);
  const [exportLoading, setExportLoading] = useState(false);

  const [useRopayAccount, setUseRopayAccount] = useState(true);

  function genRandomString(length) {
    var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var charLength = chars.length;
    var result = "";
    for (var i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
  }
  const { data: banks } = useGetBanks();
  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } =
    useResolveBankAccount();

  const [duplicate, setDuplicate] = useState(false);

  const [invoiceId, setInvoiceId] = useState(null);

  const [templateId, setTemplateId] = useState(null);

  const { data: invoice } = useGetInvoice(invoiceId);
  const { data: recurring_invoice } = useGetRecurringInvoice(templateId);

  const { data: customers } = useGetCustomersToInvoice(100000, 1, -1, "");

  const [showSelectAddCustomerOption, setShowSelectAddCustomerOption] =
    useState(false);

  const { mutate: addInvoice, isLoading: addInvoiceLoading } =
    useCreateSingleInvoice();
  const { mutate: updateInvoice, isLoading: updateInvoiceLoading } =
    useUpdateInvoice();

  const { mutate: addRecurringInvoice, isLoading: addRecurringInvoiceLoading } =
    useCreateSingleRecurringInvoice();
  const {
    mutate: updateRecurringInvoice,
    isLoading: updateRecurringInvoiceLoading,
  } = useUpdateRecurringInvoice();

  const { mutate: sendInvoice, isLoading: sendInvoiceLoading } =
    useSendInvoice();
  const { mutate: markInvoicePaid, isLoading: markInvoicePaidLoading } =
    useMarkInvoicePaid();
  const {
    mutate: markRecurringInvoiceCompleted,
    isLoading: markRecurringInvoiceCompletedLoading,
  } = useMarkRecurringInvoice();

  const [defaultText, setDefaultText] = useState();
  const [templateView, setTemplateView] = useState(false);

  const [invoiceSaveType, setInvoiceSaveType] = useState("add");

  const [formData, setFormData] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);

  const query = useRouteQuery();
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  useEffect(() => {
    if (accountNumber.length >= 10 && bankName !== "") {
      let payload = {
        bankname: bankName,
        account_number: accountNumber,
      };

      resolveAccount(payload)
        .then((response) => {
          let newArray = JSON.parse(JSON.stringify(formData));
          newArray.account_name = response.data.accountname;
          setFormData(newArray);
        })
        .catch(() => {
          let newArray = JSON.parse(JSON.stringify(formData));
          newArray.account_name = "Not Resolved";
          setFormData(newArray);
        });
    }
  }, [accountNumber, bankName]);

  useEffect(() => {
    if (query) {
      if (query.get("duplicate")) {
        setDuplicate(true);
        setEditMode(true);
      }
      if (query.get("invoiceId")) {
        setInvoiceId(query.get("invoiceId"));
      }
      if (query.get("templateId")) {
        setTemplateId(query.get("templateId"));
      }
      if (query.get("recurring")) {
        setTemplateView(true);
      }
    }
  }, [query]);

  useEffect(() => {
    if (invoice) {
      setInvoiceSaveType("edit");
      setDefaultText(invoice.customer_name + " | " + invoice.customer_mobile);
      setFormData({
        customer_id: invoice.customer_id,
        customer_name: invoice.customer_name,
        customer_email: invoice.customer_email,
        customer_mobile: invoice.customer_mobile,
        customer_address: invoice.customer_address,
        contact_person: invoice.contact_person,
        invoice_date: invoice.invoice_date,
        invoice_due_date: invoice.invoice_due_date,
        invoice_number: invoice.invoice_number,
        billing_customer_name: invoice.billing_customer_name,
        billing_contact_person: invoice.billing_contact_person,
        billing_address: invoice.billing_address,
        billing_email: invoice.billing_email,
        billing_phone: invoice.billing_phone,
        invoice_items: invoice.invoice_items,
        invoice_comments: invoice.invoice_comments,
        invoice_tax_rate: invoice.invoice_tax_rate,
        invoice_sub_total: invoice.invoice_sub_total,
        invoice_tax_amount: invoice.invoice_tax_amount,
        shipping_cost: invoice.shipping_cost,
        total_amount: invoice.total_amount,
        bank_name: invoice.bank_name,
        account_number: invoice.account_number,
        account_name: invoice.account_name,
        template_id: invoice.template_id,
        status: invoice.status,
        invoice_template_data: invoice.invoice_template_data,
      });
      if (!duplicate) {
        setEditMode(false);
      }
    } else {
      setFormData({
        customer_id: null,
        customer_name: "",
        customer_email: "",
        customer_mobile: "",
        customer_address: "",
        contact_person: "",
        invoice_date: moment().format("YYYY-MM-DD"),
        invoice_due_date: moment().add(7, "days").format("YYYY-MM-DD"),
        invoice_number:
          moment().format("YYYY-MM-DD") + "-" + genRandomString(5),
        billing_customer_name: "",
        billing_contact_person: "",
        billing_address: "",
        billing_email: "",
        billing_phone: "",
        invoice_items: [
          {
            item_name: "",
            description: "",
            quantity: 1,
            unit_price: 0,
            total: 0,
          },
        ],
        invoice_comments: "",
        invoice_tax_rate: 7.5,
        invoice_sub_total: 0,
        invoice_tax_amount: 0,
        shipping_cost: 0,
        total_amount: 0,
        bank_name: "VFD microfinance bank",
        account_number: company?.account_number,
        account_name: company?.company_name,
        status: 0,
        template_id: null,
        invoice_template_data: {
          template_name: "",
          schedule_type: "scheduled",
          interval: "monthly",
          due_after: 1,
          date_type: "day",
          day: "last",
          interval_count: 1,
          start_date: moment().format("YYYY-MM-DD"),
          end_type: "none",
          occurrences: 1,
          end_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
      });
    }
  }, [invoice]);

  useEffect(() => {
    if (recurring_invoice) {
      setInvoiceSaveType("edit");
      setDefaultText(
        recurring_invoice.customer_name +
          " | " +
          recurring_invoice.customer_mobile
      );
      setFormData({
        customer_id: recurring_invoice.customer_id,
        customer_name: recurring_invoice.customer_name,
        customer_email: recurring_invoice.customer_email,
        customer_mobile: recurring_invoice.customer_mobile,
        customer_address: recurring_invoice.customer_address,
        contact_person: recurring_invoice.contact_person,
        invoice_date: recurring_invoice.invoice_date,
        invoice_due_date: recurring_invoice.invoice_due_date,
        invoice_number: recurring_invoice.invoice_number,
        billing_customer_name: recurring_invoice.billing_customer_name,
        billing_contact_person: recurring_invoice.billing_contact_person,
        billing_address: recurring_invoice.billing_address,
        billing_email: recurring_invoice.billing_email,
        billing_phone: recurring_invoice.billing_phone,
        invoice_items: recurring_invoice.invoice_items,
        invoice_comments: recurring_invoice.invoice_comments,
        invoice_tax_rate: recurring_invoice.invoice_tax_rate,
        invoice_sub_total: recurring_invoice.invoice_sub_total,
        invoice_tax_amount: recurring_invoice.invoice_tax_amount,
        shipping_cost: recurring_invoice.shipping_cost,
        total_amount: recurring_invoice.total_amount,
        bank_name: recurring_invoice.bank_name,
        account_number: recurring_invoice.account_number,
        account_name: recurring_invoice.account_name,
        template_id: recurring_invoice.template_id,
        status: recurring_invoice.status,
        invoice_template_data: recurring_invoice.invoice_template,
      });
      setTemplateView(true);
      if (!duplicate) {
        setEditMode(false);
      }
    } else {
      setFormData({
        customer_id: null,
        customer_name: "",
        customer_email: "",
        customer_mobile: "",
        customer_address: "",
        contact_person: "",
        invoice_date: moment().format("YYYY-MM-DD"),
        invoice_due_date: moment().add(7, "days").format("YYYY-MM-DD"),
        invoice_number:
          moment().format("YYYY-MM-DD") + "-" + genRandomString(5),
        billing_customer_name: "",
        billing_contact_person: "",
        billing_address: "",
        billing_email: "",
        billing_phone: "",
        invoice_items: [
          {
            item_name: "",
            description: "",
            quantity: 1,
            unit_price: 0,
            total: 0,
          },
        ],
        invoice_comments: "",
        invoice_tax_rate: 7.5,
        invoice_sub_total: 0,
        invoice_tax_amount: 0,
        shipping_cost: 0,
        total_amount: 0,
        bank_name: "VFD microfinance bank",
        account_number: company?.account_number,
        account_name: company?.company_name,
        status: 0,
        template_id: null,
        invoice_template_data: {
          template_name: "",
          schedule_type: "scheduled",
          interval: "monthly",
          due_after: 1,
          date_type: "day",
          day: "last",
          interval_count: 1,
          start_date: moment().format("YYYY-MM-DD"),
          end_type: "none",
          occurrences: 1,
          end_date: moment().add(1, "days").format("YYYY-MM-DD"),
        },
      });
    }
  }, [recurring_invoice]);

  const changeImage = async (imageLink) => {
    let urlBase64 = await toDataUrl(imageLink);
    setImageBlob(urlBase64);
  };

  useEffect(() => {
    if (company) {
      changeImage(company.logo);
    }
    return setImageBlob(null);
  }, [company]);

  const scheduleTypeOptions = [
    { label: "Schedule (Create Only)", value: "schedule" },
    { label: "Schedule (Create and send)", value: "schedule_and_send" },
  ];

  const intervalOptions = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
    { label: "Yearly", value: "yearly" },
  ];

  const [dateTypeOptions, setDateTypeOptions] = useState([
    { label: "Day", value: "day" },
    { label: "First", value: "first" },
    { label: "Second", value: "second" },
    { label: "Third", value: "third" },
    { label: "Fourth", value: "fourth" },
    { label: "Last", value: "last" },
  ]);

  const [dayOptions, setDayOptions] = useState([
    { label: "1st", value: "01" },
    { label: "2nd", value: "02" },
    { label: "3rd", value: "03" },
    { label: "4th", value: "04" },
    { label: "5th", value: "05" },
    { label: "6th", value: "06" },
    { label: "7th", value: "07" },
    { label: "8th", value: "08" },
    { label: "9th", value: "09" },
    { label: "10th", value: "10" },
    { label: "11th", value: "11" },
    { label: "12th", value: "12" },
    { label: "13th", value: "13" },
    { label: "14th", value: "14" },
    { label: "15th", value: "15" },
    { label: "16th", value: "16" },
    { label: "17th", value: "17" },
    { label: "18th", value: "18" },
    { label: "19th", value: "19" },
    { label: "20th", value: "20" },
    { label: "21st", value: "21" },
    { label: "22nd", value: "22" },
    { label: "23rd", value: "23" },
    { label: "24th", value: "24" },
    { label: "25th", value: "25" },
    { label: "26th", value: "26" },
    { label: "27th", value: "27" },
    { label: "28th", value: "28" },
    { label: "Last", value: "last" },
  ]);

  const endTypeOptions = [
    { label: "None", value: "none" },
    { label: "By", value: "by" },
    { label: "After", value: "after" },
  ];

  const [editMode, setEditMode] = useState(true);

  const pdfJSX = () => {
    return (
      <div className="flex flex-col gap-4 min-h-fit max-w-full bg-white p-4">
        <div className="flex gap-2 justify-end  items-center">
          <span className="text-primary-1 text-[30px] ">INVOICE</span>
          {templateId ? (
            <span className="text-color-gray pt-2">(Recurring template)</span>
          ) : (
            <span
              className={classNames(
                "flex items-center justify-center text-[12px] !min-w-fit text-center pb-[12px] mt-3",
                {
                  "text-color-green-medium px-2 bg-color-green-light rounded-lg":
                    invoiceStatusConfig[formData?.status].color === "green",
                },
                {
                  "text-color-orange px-2 bg-color-orange-light rounded-lg":
                    invoiceStatusConfig[formData?.status].color === "orange",
                },
                {
                  "text-color-error px-2 bg-color-error-light rounded-lg":
                    invoiceStatusConfig[formData?.status].color === "red",
                }
              )}
            >
              {invoiceStatusConfig[formData?.status].label}
            </span>
          )}
        </div>
        <div className="flex items-center justify-between bg-primary-1 p-3 text-color-white rounded-md">
          <div className="w-1/2 flex flex-col items-start gap-1">
            <div className="flex gap-2 items-center">
              <div className="text-[18px] flex font-bold justify-center items-center h-[55px] w-[100px] header-3 relative hover:scale-110 hover:m-2 duration-500 rounded-xl bg-white">
                {company?.logo ? (
                  <div>
                    <img
                      src={imageBlob}
                      alt="company_logo"
                      className="object-fit min-w-[70px] min-h-[45px] max-w-[70px] max-h-[45px] "
                    />
                  </div>
                ) : (
                  <span className="text-primary-1">
                    {Initials(company?.company_name)}
                  </span>
                )}
              </div>
              <span className="text-color-white text-[25px] pb-4">
                {company?.company_name}
              </span>
            </div>
            <div className="flex flex-col justify-start gap-2 font-normal">
              <div className="w-full flex justify-start text-[14px] text-color-white">
                {company?.address}, {company?.city}, {company?.state},{" "}
                {company?.country}.
              </div>
              <div className="w-full flex justify-start text-[13px] font-semibold text-color-white">
                {company?.email} | {company?.mobile}
              </div>
            </div>
          </div>
          {templateId ? null : (
            <div className="w-1/2 flex justify-end text-primary-1 items-center">
              <div className="flex flex-col justify-end items-end font-normal w-full gap-0">
                <div className="flex justify-end px-2 items-center h-[42px] gap-2 w-full text-[14px] text-color-white">
                  <span className="text-[13px] w-1/2 text-right font-semibold text-color-white">
                    Invoice Date:
                  </span>
                  <div className="w-1/2 text-right text-color-white text-[13px]">
                    {moment(formData?.invoice_date).format("MMMM DD, YYYY")}
                  </div>
                </div>
                <div className="flex justify-end px-2 items-center h-[42px] gap-2 w-full text-[14px] text-color-white -mt-4">
                  <span className="text-[13px] w-1/2 text-right font-semibold text-color-white">
                    Due Date:
                  </span>
                  <div className="w-1/2 text-right text-color-white text-[13px]">
                    {moment(formData?.invoice_due_date).format("MMMM DD, YYYY")}
                  </div>
                </div>
                <div className="flex justify-start px-2 items-center h-[42px] gap-2 w-full text-[14px] text-color-white -mt-4">
                  <span className="text-[13px] w-1/2 text-right font-semibold text-color-white">
                    Invoice #:
                  </span>
                  <div className="w-1/2 text-right text-color-white text-[13px]">
                    {formData?.invoice_number}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex gap-3 justify-between font-normal">
          <div className=" w-[50%] flex flex-col justify-start min-h-[200px] border rounded-md ">
            <div className="flex items-center px-4 bg-secondary-2-extralight pb-3 w-full mb-1 text-[14px] text-primary-1 font-extrabold border-b-2 border-b-primary-1">
              CUSTOMER:
            </div>
            {formData?.customer_id ? (
              <div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5">Customer name:</span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData?.customer_name}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5">Contact Person:</span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData?.contact_person}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5">Address:</span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData?.customer_address}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5">Email:</span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData?.customer_email}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5">Phone:</span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData?.customer_mobile}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-center h-6 items-center">
                No customer selected
              </div>
            )}
          </div>
          <div className="w-[50%] flex flex-col justify-start min-h-[200px] border rounded-md pb-2">
            <div className="flex items-center px-4 bg-secondary-2-extralight pb-3 w-full mb-1 border-b-2 border-b-primary-1">
              <span className="text-[14px] text-primary-1 font-extrabold">
                BILLING INFORMATION:
              </span>
            </div>
            <div className="flex justify-start px-4 items-center h-[42px] gap-2">
              <span className="text-[13px] w-2/5">Contact Person:</span>
              <div className="w-3/4 items-center justify-center text-[13px]">
                {formData?.billing_contact_person}
              </div>
            </div>
            <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
              <span className="text-[13px] w-2/5">Billing Address:</span>
              <div className="w-3/4 items-center justify-center text-[13px]">
                {formData?.billing_address}
              </div>
            </div>
            <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
              <span className="text-[13px] w-2/5">Email:</span>
              <div className="w-3/4 items-center justify-center text-[13px]">
                {formData?.billing_email}
              </div>
            </div>
            <div className="flex justify-start px-4 items-center h-[42px] gap-2">
              <span className="text-[14px] w-2/5">Phone:</span>
              <div className="w-3/4 items-center justify-center text-[13px]">
                {formData?.billing_phone}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col font-normal border-b  border rounded-md mb-2">
          <div className="flex pb-3 bg-secondary-2-extralight border-b-2 border-b-primary-1">
            <div className="flex w-2/6 items-start justify-center flex-col px-3">
              <div className="text-primary-1 !text-[13px] font-bold">
                ITEM NAME
              </div>
            </div>
            <div className="flex w-2/6 items-start justify-center flex-col px-3">
              <div className="text-primary-1 !text-[13px] font-bold text-center">
                DESCRIPTION
              </div>
            </div>
            <div className="flex w-1/6 items-start justify-center flex-col px-3">
              <div className="text-primary-1 !text-[13px] font-bold">QTY</div>
            </div>
            <div className="flex w-1/5 items-start justify-center flex-col px-3">
              <div className="text-primary-1 !text-[13px] font-bold">
                UNIT PRICE
              </div>
            </div>
            <div className="flex w-1/5 items-start justify-center flex-col px-3">
              <div className="text-primary-1 !text-[13px] font-bold">TOTAL</div>
            </div>
          </div>
          {formData?.invoice_items.length > 0 &&
            formData?.invoice_items.map((item) => (
              <div className="flex h-[60px] hover:bg-gray-100 duration-300 gap-2">
                <div className="flex w-2/6 items-center justify-start px-2 !text-[13px] !h-[60px]">
                  {item.item_name}
                </div>
                <div className="flex w-2/6 items-center justify-start px-2 !text-[13px] !h-[60px]">
                  {item.description}
                </div>
                <div className="flex w-1/6 items-center justify-end px-2 !text-[13px] !h-[60px]">
                  {formatNumber(item.quantity, 2)}
                </div>
                <div className="flex w-1/5 items-center justify-end px-2 !text-[13px] !h-[60px]">
                  {formatNumber(item.unit_price, 2)}
                </div>
                <div className="flex w-1/5 items-center justify-end pr-4 bg-secondary-2-extralight !text-[13px] !h-[60px]">
                  <div className="text-primary-1 !text-[13px] font-bold">
                    {formatNumber(item.total, 2)}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="flex h-[180px] items-start gap-4 w-full">
          <div className="w-4/6 ">
            <div className="w-[80%] flex flex-col justify-start min-h-[120px]">
              <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full border-b-2 border-b-primary-1">
                <span className="text-[14px] text-primary-1 font-extrabold">
                  ADDITIONAL COMMENTS AND INSTRUCTIONS
                </span>
              </div>
              <div className="text-[13px] border-1 border-gray-300 !bg-white w-full font-normal !rounded-t-none p-4 mt-0">
                {formData?.invoice_comments}
              </div>
            </div>
          </div>
          <div className="flex w-2/6 justify-end pt-0">
            <div className="flex flex-col w-full h-full justify-center pt-2">
              <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                <div className=" flex items-center justify-start !w-1/2 pb-2">
                  Sub Total
                </div>
                <div className="flex justify-end text-primary-1 px-2 !w-1/2 bg-secondary-2-extralight h-full items-center pb-2">
                  {formatNumber(formData?.invoice_sub_total, 2)}
                </div>
              </div>
              <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                <div className=" flex items-center justify-start !w-1/2">
                  <span className="flex items-center !font-extrabold text-[16px]">
                    Tax&nbsp;
                  </span>
                  ({formatNumber(formData?.invoice_tax_rate, 2)}
                  <span className="flex items-center !font-extrabold text-[16px]">
                    %
                  </span>
                  )
                </div>
                <div className="flex justify-end text-primary-1 px-2 !w-1/2 bg-secondary-2-extralight h-full items-center">
                  {formatNumber(formData?.invoice_tax_amount, 2)}
                </div>
              </div>
              <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                <div className=" flex items-center justify-start !w-1/2 pb-2">
                  Shipping cost
                </div>
                <div className="flex justify-end text-primary-1 px-2 !w-1/2 bg-secondary-2-extralight h-full items-center pb-2">
                  {formatNumber(formData?.shipping_cost, 2)}
                </div>
              </div>
              <div className="flex justify-between !font-extrabold text-[16px] gap-2 items-center !h-[60px]">
                <div className=" flex items-center justify-start !w-1/2 pb-3">
                  Grand Total
                </div>
                <div className="flex justify-end text-white px-2 !w-1/2 bg-secondary-2-extralight h-full items-center bg-primary-1 text-[20px] font-extrabold pb-3">
                  {formatNumber(formData?.total_amount, 2)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex  w-full bg-gray-50 !min-h-16 p-4 border mt-6 rounded-md">
          <div className="flex flex-col  w-3/4">
            <div className="text-primary-1 font-bold">PAYMENT INSTRUCTION</div>
            <div className="flex justify-start items-center h-[42px] gap-2">
              <span className="text-[14px] w-2/5 font-bold">Bank Name:</span>
              <div className="w-3/4 items-center justify-center text-[14px]">
                {formData?.bank_name}
              </div>
            </div>
            <div className="flex justify-start items-center h-[42px] gap-2">
              <span className="text-[14px] w-2/5 font-bold">
                Account Number:
              </span>
              <div className="w-3/4 items-center justify-center text-[14px]">
                {formData?.account_number}
              </div>
            </div>
            <div className="flex justify-start items-center h-[42px] gap-2">
              <span className="text-[14px] w-2/5 font-bold">Account Name:</span>
              <div className="w-3/4 items-center justify-center text-[14px]">
                {formData?.account_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const printHandler = async () => {
    setExportLoading(true);

    const printElement = ReactDOMServer.renderToString(pdfJSX());
    var opt = {
      margin: 0,
      filename: formData?.invoice_number + " - invoice.pdf",
      image: { type: "", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(opt).from(printElement).save();
    setExportLoading(false);
  };

  const save = () => {
    let payload = { ...formData };
    if (invoiceSaveType === "add" || duplicate === true) {
      if (formData?.customer_id) {
        if (formData?.invoice_items.length > 0) {
          let $error = false;
          let invoiceItem = formData?.invoice_items;
          invoiceItem.forEach((element, index) => {
            if (
              !element.quantity ||
              !element.unit_price ||
              !element.total ||
              !element.item_name
            ) {
              store.dispatch(
                setAlert(
                  true,
                  "info",
                  "There is an error in row " +
                    (index + 1).toString() +
                    ". Please check"
                )
              );
              $error = true;
            }
          });
          if ($error === false) {
            if (templateView) {
              addRecurringInvoice(payload).then(() => {
                setDuplicate(false);
              });
            } else {
              addInvoice(payload).then(() => {
                setDuplicate(false);
              });
            }
          }
        } else {
          store.dispatch(
            setAlert(
              true,
              "info",
              "You must have at least on invoice line item to save this invoice"
            )
          );
          return;
        }
      } else {
        store.dispatch(setAlert(true, "info", "You must select a customer"));
        return;
      }
    } else if (invoiceSaveType === "edit" && (invoiceId || templateId)) {
      if (formData?.customer_id) {
        if (formData?.invoice_items.length > 0) {
          let $error = false;
          let invoiceItem = formData?.invoice_items;
          invoiceItem.forEach((element, index) => {
            if (
              !element.quantity ||
              !element.unit_price ||
              !element.total ||
              !element.item_name
            ) {
              store.dispatch(
                setAlert(
                  true,
                  "info",
                  "There is an error in row " +
                    (index + 1).toString() +
                    ". Please check"
                )
              );
              $error = true;
            }
          });
          if ($error === false) {
            if (templateView) {
              updateRecurringInvoice({
                id: templateId,
                data: payload,
              });
            } else {
              updateInvoice({
                id: invoiceId,
                data: payload,
              });
            }
          }
        } else {
          store.dispatch(
            setAlert(
              true,
              "info",
              "You must have at least on invoice line item to save this invoice"
            )
          );
          return;
        }
      } else {
        store.dispatch(setAlert(true, "info", "You must select a customer"));
        return;
      }
    } else {
      store.dispatch(
        setAlert(true, "info", "Please provide an invoice or template ID")
      );
    }
  };

  return (
    <DashboardLayout title="Invoice">
      {showSelectAddCustomerOption ? (
        <SelectCustomerOption
          isOpen={showSelectAddCustomerOption}
          closeModal={() => setShowSelectAddCustomerOption(false)}
        />
      ) : null}
      <div className="flex md:flex-row flex-col md:justify-between justify-end md:items-center md:gap-4 md:-mt-6 mb-2">
        {!editMode ? (
          <div className="flex md:flex-row flex-col md:w-1/2 w-full gap-2 min-h-fit md:justify-start md:items-center items-center">
            {!templateView ? (
              <Button
                text="Send to customer"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] w-fit p-2 "
                textClass={"!text-[11px]"}
                leftIcon={"email"}
                loading={sendInvoiceLoading}
                onClick={() => {
                  sendInvoice(invoiceId);
                }}
              />
            ) : null}

            <Button
              text="DOWNLOAD"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] w-fit p-2 "
              textClass={"!text-[11px]"}
              leftIcon={"export"}
              loading={exportLoading}
              onClick={() => {
                printHandler();
              }}
            />
            <Button
              text={templateView ? "MARK AS COMPLETED" : "MARK AS PAID"}
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] w-fit p-2 "
              textClass={"!text-[11px]"}
              leftIcon={"check"}
              loading={
                markInvoicePaidLoading || markRecurringInvoiceCompletedLoading
              }
              onClick={() => {
                if (templateView) {
                  markRecurringInvoiceCompleted(templateId);
                } else {
                  markInvoicePaid(invoiceId);
                }
              }}
            />
          </div>
        ) : !invoiceId && !templateId ? (
          <div className="flex md:flex-row flex-col md:w-1/2 w-full gap-2 min-h-fit md:justify-start md:items-center items-center">
            <Button
              text={templateView ? "CANCEL RECURRING" : "MAKE RECURRING"}
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] w-fit p-2 "
              textClass={"!text-[11px]"}
              onClick={() => {
                setTemplateView(!templateView);
              }}
            />
          </div>
        ) : null}
        <div
          className={classNames("md:w-1/2 w-full flex justify-end mt-4", {
            "!w-full": editMode,
          })}
        >
          <FormSwitch
            label={"Edit Invoice"}
            name={"switch-edit-mode"}
            checked={editMode}
            readOnly={!invoiceId || !templateId}
            classNames={
              "!text-[15px] font-semibold hover:scale-110 duration-300"
            }
            onClick={() => {
              if (!editMode) {
                if (invoiceId || templateId) {
                  setEditMode(!editMode);
                }
              } else {
                if (!duplicate) {
                  if (invoiceId || templateId) {
                    setEditMode(!editMode);
                  }
                }
                if (invoice) {
                  setFormData({
                    customer_id: invoice.customer_id,
                    customer_name: invoice.customer_name,
                    customer_email: invoice.customer_email,
                    customer_mobile: invoice.customer_mobile,
                    customer_address: invoice.customer_address,
                    contact_person: invoice.contact_person,
                    invoice_date: invoice.invoice_date,
                    invoice_due_date: invoice.invoice_due_date,
                    invoice_number: invoice.invoice_number,
                    billing_customer_name: invoice.billing_customer_name,
                    billing_contact_person: invoice.billing_contact_person,
                    billing_address: invoice.billing_address,
                    billing_email: invoice.billing_email,
                    billing_phone: invoice.billing_phone,
                    invoice_items: invoice.invoice_items,
                    invoice_comments: invoice.invoice_comments,
                    invoice_tax_rate: invoice.invoice_tax_rate,
                    invoice_sub_total: invoice.invoice_sub_total,
                    invoice_tax_amount: invoice.invoice_tax_amount,
                    shipping_cost: invoice.shipping_cost,
                    total_amount: invoice.total_amount,
                    bank_name: invoice.bank_name,
                    account_number: invoice.account_number,
                    account_name: invoice.account_name,
                    template_id: invoice.template_id,
                    status: invoice.status,
                    invoice_template_data: invoice.invoice_template_data,
                  });
                } else if (recurring_invoice) {
                  setFormData({
                    customer_id: recurring_invoice.customer_id,
                    customer_name: recurring_invoice.customer_name,
                    customer_email: recurring_invoice.customer_email,
                    customer_mobile: recurring_invoice.customer_mobile,
                    customer_address: recurring_invoice.customer_address,
                    contact_person: recurring_invoice.contact_person,
                    invoice_date: recurring_invoice.invoice_date,
                    invoice_due_date: recurring_invoice.invoice_due_date,
                    invoice_number: recurring_invoice.invoice_number,
                    billing_customer_name:
                      recurring_invoice.billing_customer_name,
                    billing_contact_person:
                      recurring_invoice.billing_contact_person,
                    billing_address: recurring_invoice.billing_address,
                    billing_email: recurring_invoice.billing_email,
                    billing_phone: recurring_invoice.billing_phone,
                    invoice_items: recurring_invoice.invoice_items,
                    invoice_comments: recurring_invoice.invoice_comments,
                    invoice_tax_rate: recurring_invoice.invoice_tax_rate,
                    invoice_sub_total: recurring_invoice.invoice_sub_total,
                    invoice_tax_amount: recurring_invoice.invoice_tax_amount,
                    shipping_cost: recurring_invoice.shipping_cost,
                    total_amount: recurring_invoice.total_amount,
                    bank_name: recurring_invoice.bank_name,
                    account_number: recurring_invoice.account_number,
                    account_name: recurring_invoice.account_name,
                    template_id: recurring_invoice.template_id,
                    status: recurring_invoice.status,
                    invoice_template_data: recurring_invoice.invoice_template,
                  });
                }
              }
            }}
            value={editMode}
          />
        </div>
      </div>
      {templateView ? (
        <div className="flex flex-col gap-2 mt-2 mb-3 p-3 py-2 border rounded-md bg-white">
          <div className="text-[23px] font-normal">
            Recurring Invoice Information
          </div>
          <form>
            <div className="flex md:flex-row flex-col md:items-center md:gap-2 w-full">
              <div className="md:w-1/3 w-full">
                <FormInput
                  label={"Invoice Template name"}
                  name="template_name"
                  type="text"
                  readOnly={!editMode}
                  placeholder={"Enter template name"}
                  className={"!md:max-w-[250px] !md:min-w-[250px]"}
                  value={formData?.invoice_template_data.template_name}
                  onInput={(e) => {
                    let newArray = JSON.parse(JSON.stringify(formData));
                    newArray.invoice_template_data.template_name =
                      e.target.value;
                    setFormData(newArray);
                  }}
                />
              </div>
              <div className="md:w-1/4 w-full -mt-2">
                <FormSelect
                  name={"schedule_type"}
                  value={formData?.invoice_template_data.schedule_type}
                  readOnly={!editMode}
                  options={scheduleTypeOptions}
                  onChange={(selected) => {
                    let newArray = JSON.parse(JSON.stringify(formData));
                    newArray.invoice_template_data.schedule_type = selected;
                    setFormData(newArray);
                  }}
                  extraClass={
                    "!md:max-w-[230px] !md:min-w-[230px] !text-[13px] !pl-2 !w-full"
                  }
                  label="Type"
                />
              </div>
              <div className="flex flex-col justify-center gap-2 items-start w-fit text-[13px]">
                <span className="min-w-fit -mb-[14px] text-gray-400">
                  Invoice due in{" "}
                </span>
                <div className="flex items-center gap-2">
                  <FormInput
                    // label={'Invoice due in'}
                    name="due_after"
                    type="number"
                    className={"!max-w-[60px]"}
                    readOnly={!editMode}
                    value={formData?.invoice_template_data.due_after}
                    onInput={(e) => {
                      let newArray = JSON.parse(JSON.stringify(formData));
                      newArray.invoice_template_data.due_after = e.target.value;
                      setFormData(newArray);
                    }}
                  />
                  <span className="min-w-fit pb-2">day(s) after creation </span>
                </div>
              </div>
            </div>
            <div className="flex justify-start items-center !w-full border rounded-md p-2 md:gap-3 bg-gray-50">
              <div className="flex gap-2 justify-start items-center text-[13px] mr-4 flex-wrap">
                <FormSelect
                  options={intervalOptions}
                  value={formData?.invoice_template_data.interval}
                  readOnly={!editMode}
                  onChange={(selected) => {
                    let newArray = JSON.parse(JSON.stringify(formData));
                    newArray.invoice_template_data.interval = selected;
                    if (selected == "weekly") {
                      newArray.invoice_template_data.date_type = "monday";
                      setDateTypeOptions([
                        { label: "Monday", value: "monday" },
                        { label: "Tuesday", value: "tuesday" },
                        { label: "Wednesday", value: "wednesday" },
                        { label: "Thursday", value: "thursday" },
                        { label: "Friday", value: "friday" },
                        { label: "Saturday", value: "saturday" },
                        { label: "Sunday", value: "sunday" },
                      ]);
                    } else if (selected == "monthly") {
                      newArray.invoice_template_data.date_type = "day";
                      newArray.invoice_template_data.day = 1;
                      setDateTypeOptions([
                        { label: "Day", value: "day" },
                        { label: "First", value: "first" },
                        { label: "Second", value: "second" },
                        { label: "Third", value: "third" },
                        { label: "Fourth", value: "fourth" },
                        { label: "Last", value: "last" },
                      ]);
                      setDayOptions([
                        { label: "1st", value: "01" },
                        { label: "2nd", value: "02" },
                        { label: "3rd", value: "03" },
                        { label: "4th", value: "04" },
                        { label: "5th", value: "05" },
                        { label: "6th", value: "06" },
                        { label: "7th", value: "07" },
                        { label: "8th", value: "08" },
                        { label: "9th", value: "09" },
                        { label: "10th", value: "10" },
                        { label: "11th", value: "11" },
                        { label: "12th", value: "12" },
                        { label: "13th", value: "13" },
                        { label: "14th", value: "14" },
                        { label: "15th", value: "15" },
                        { label: "16th", value: "16" },
                        { label: "17th", value: "17" },
                        { label: "18th", value: "18" },
                        { label: "19th", value: "19" },
                        { label: "20th", value: "20" },
                        { label: "21st", value: "21" },
                        { label: "22nd", value: "22" },
                        { label: "23rd", value: "23" },
                        { label: "24th", value: "24" },
                        { label: "25th", value: "25" },
                        { label: "26th", value: "26" },
                        { label: "27th", value: "27" },
                        { label: "28th", value: "28" },
                        { label: "Last", value: "last" },
                      ]);
                    } else if (selected == "yearly") {
                      newArray.invoice_template_data.date_type = "january";
                      newArray.invoice_template_data.day = 1;
                      setDateTypeOptions([
                        { label: "January", value: "january" },
                        { label: "February", value: "february" },
                        { label: "March", value: "march" },
                        { label: "April", value: "april" },
                        { label: "May", value: "may" },
                        { label: "June", value: "june" },
                        { label: "July", value: "july" },
                        { label: "August", value: "august" },
                        { label: "September", value: "september" },
                        { label: "October", value: "october" },
                        { label: "November", value: "november" },
                        { label: "December", value: "december" },
                      ]);
                      setDayOptions([
                        { label: "1st", value: "01" },
                        { label: "2nd", value: "02" },
                        { label: "3rd", value: "03" },
                        { label: "4th", value: "04" },
                        { label: "5th", value: "05" },
                        { label: "6th", value: "06" },
                        { label: "7th", value: "07" },
                        { label: "8th", value: "08" },
                        { label: "9th", value: "09" },
                        { label: "10th", value: "10" },
                        { label: "11th", value: "11" },
                        { label: "12th", value: "12" },
                        { label: "13th", value: "13" },
                        { label: "14th", value: "14" },
                        { label: "15th", value: "15" },
                        { label: "16th", value: "16" },
                        { label: "17th", value: "17" },
                        { label: "18th", value: "18" },
                        { label: "19th", value: "19" },
                        { label: "20th", value: "20" },
                        { label: "21st", value: "21" },
                        { label: "22nd", value: "22" },
                        { label: "23rd", value: "23" },
                        { label: "24th", value: "24" },
                        { label: "25th", value: "25" },
                        { label: "26th", value: "26" },
                        { label: "27th", value: "27" },
                        { label: "28th", value: "28" },
                        { label: "Last", value: "last" },
                      ]);
                    }
                    setFormData(newArray);
                  }}
                  label="Interval"
                  extraClass={
                    "!max-w-[120px] !min-w-[120px] !text-[13px] !pl-2"
                  }
                />
                {formData?.invoice_template_data.interval !== "daily" ? (
                  <>
                    <span className="min-w-fit pb-1">on</span>
                    <FormSelect
                      options={dateTypeOptions}
                      value={formData?.invoice_template_data.date_type}
                      readOnly={!editMode}
                      onChange={(selected) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.invoice_template_data.date_type = selected;
                        if (
                          formData?.invoice_template_data.interval ==
                            "monthly" &&
                          selected !== "day"
                        ) {
                          newArray.invoice_template_data.day = "monday";
                          setDayOptions([
                            { label: "Monday", value: "monday" },
                            { label: "Tuesday", value: "tuesday" },
                            { label: "Wednesday", value: "wednesday" },
                            { label: "Thursday", value: "thursday" },
                            { label: "Friday", value: "friday" },
                            { label: "Saturday", value: "saturday" },
                            { label: "Sunday", value: "sunday" },
                          ]);
                        } else {
                          newArray.invoice_template_data.day = 1;
                          newArray.invoice_template_data.day = 1;
                          setDayOptions([
                            { label: "1st", value: "01" },
                            { label: "2nd", value: "02" },
                            { label: "3rd", value: "03" },
                            { label: "4th", value: "04" },
                            { label: "5th", value: "05" },
                            { label: "6th", value: "06" },
                            { label: "7th", value: "07" },
                            { label: "8th", value: "08" },
                            { label: "9th", value: "09" },
                            { label: "10th", value: "10" },
                            { label: "11th", value: "11" },
                            { label: "12th", value: "12" },
                            { label: "13th", value: "13" },
                            { label: "14th", value: "14" },
                            { label: "15th", value: "15" },
                            { label: "16th", value: "16" },
                            { label: "17th", value: "17" },
                            { label: "18th", value: "18" },
                            { label: "19th", value: "19" },
                            { label: "20th", value: "20" },
                            { label: "21st", value: "21" },
                            { label: "22nd", value: "22" },
                            { label: "23rd", value: "23" },
                            { label: "24th", value: "24" },
                            { label: "25th", value: "25" },
                            { label: "26th", value: "26" },
                            { label: "27th", value: "27" },
                            { label: "28th", value: "28" },
                            { label: "Last", value: "last" },
                          ]);
                        }
                        setFormData(newArray);
                      }}
                      extraClass={
                        "!max-w-[100px] !min-w-[100px] !text-[13px] !pl-2"
                      }
                    />
                    {formData?.invoice_template_data.interval !== "weekly" ? (
                      <FormSelect
                        options={dayOptions}
                        value={formData?.invoice_template_data.day}
                        readOnly={!editMode}
                        onChange={(selected) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.invoice_template_data.day = selected;
                          setFormData(newArray);
                        }}
                        extraClass={
                          "!max-w-[100px] !min-w-[100px] !text-[13px] !pl-2"
                        }
                      />
                    ) : null}
                  </>
                ) : null}
                <span className="max-w-fit min-w-fit pb-1">every</span>
                <div>
                  <FormInput
                    name="interval_count"
                    type="number"
                    className={"!max-w-[40px]"}
                    readOnly={!editMode}
                    value={formData?.invoice_template_data.interval_count}
                    onInput={(e) => {
                      let newArray = JSON.parse(JSON.stringify(formData));
                      newArray.invoice_template_data.interval_count =
                        e.target.value;
                      setFormData(newArray);
                    }}
                  />
                </div>
                {formData?.invoice_template_data.interval == "daily" ? (
                  <span className="min-w-fit pb-1">day(s), </span>
                ) : formData?.invoice_template_data.interval == "weekly" ? (
                  <span className="min-w-fit pb-1">week(s), </span>
                ) : formData?.invoice_template_data.interval == "monthly" ? (
                  <span className="min-w-fit pb-1">month(s), </span>
                ) : formData?.invoice_template_data.interval == "yearly" ? (
                  <span className="min-w-fit pb-1">year(s), </span>
                ) : null}
                <span className="min-w-fit pb-1">starts on</span>
                <div>
                  <FormInput
                    name="start_date"
                    type="date"
                    readOnly={!editMode}
                    className={
                      "!max-w-[150px] !min-w-[150px] !pb-[15px] !text-[13px]"
                    }
                    value={formData?.invoice_template_data.start_date}
                    onInput={(e) => {
                      let newArray = JSON.parse(JSON.stringify(formData));
                      newArray.invoice_template_data.start_date =
                        e.target.value;
                      setFormData(newArray);
                    }}
                  />
                </div>
                <span className="min-w-fit pb-1">ends</span>
                <FormSelect
                  options={endTypeOptions}
                  value={formData?.invoice_template_data.end_type}
                  readOnly={!editMode}
                  onChange={(selected) => {
                    let newArray = JSON.parse(JSON.stringify(formData));
                    newArray.invoice_template_data.end_type = selected;
                    setFormData(newArray);
                  }}
                  extraClass={"!max-w-[80px] !min-w-[80px] !text-[13px] !pl-2"}
                />
                {formData?.invoice_template_data.end_type !== "none" && (
                  <>
                    {formData?.invoice_template_data.end_type == "by" ? (
                      <FormInput
                        name="end_date"
                        type="date"
                        value={formData?.invoice_template_data.end_date}
                        readOnly={!editMode}
                        onInput={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.invoice_template_data.end_date =
                            e.target.value;
                          setFormData(newArray);
                        }}
                        className={
                          "!max-w-[150px] !min-w-[150px] !pb-[15px] !text-[13px]"
                        }
                      />
                    ) : (
                      <>
                        <FormInput
                          name="occurrences"
                          type="text"
                          className={"!max-w-[50px] !pl-2"}
                          readOnly={!editMode}
                          value={formData?.invoice_template_data.occurrences}
                          onInput={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.invoice_template_data.occurrences =
                              e.target.value;
                            setFormData(newArray);
                          }}
                        />
                        <span className="min-w-fit pb-1">occurrences</span>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : null}
      <div className="flex flex-col bg-white rounded-md p-4 px-16 font-semibold min-h-fit border">
        {editMode ? (
          <div className="flex flex-col gap-4 min-h-fit">
            <div className="flex md:flex-row flex-col items-start justify-between">
              <div className="md:w-1/2 w-full flex flex-col items-start gap-2 md:pb-0 pb-3">
                <div className="flex gap-2 items-center justify-center">
                  <div className="text-[13px] flex font-bold justify-center items-center duration-500 border rounded-xl">
                    {company?.logo ? (
                      <img
                        src={company?.logo}
                        alt="logo"
                        className="object-fit min-w-[55px] min-h-[30px] max-w-[55px] max-h-[30px] "
                      />
                    ) : (
                      <>{Initials(company?.company_name)}</>
                    )}
                  </div>
                  <span className=" text-primary-1 text-[20px] ">
                    {company?.company_name}
                  </span>
                </div>
                <div className="md:w-1/2 flex flex-col justify-start gap-2 font-normal">
                  <div className="w-full flex justify-start text-[14px]">
                    {company?.address}, {company?.city}, {company?.state},{" "}
                    {company?.country}
                  </div>
                  <div className="w-full flex justify-start text-[14px]">
                    {company?.email} | {company?.mobile}
                  </div>
                  {/* <div className='w-full flex justify-start text-[14px]'>
                                                Phone: 
                                            </div> */}
                </div>
              </div>
              <div className="md:w-1/2 w-full flex md:justify-end justify-center text-2xl text-primary-1 md:border-t-0 border-t-2 border-t-primary-1 md:pt-0 md:border-b-0 border-b-2 border-b-primary-1 md:pb-0 pb-3">
                <div className="w-full flex flex-col items-start justify-end gap-2">
                  <div className="flex text-primary-1 text-[25px] gap-1 md:justify-end w-full">
                    INVOICE{" "}
                    <span className="text-color-gray text-[14px]">(Draft)</span>
                  </div>
                  {!templateView ? (
                    <div className="flex flex-col md:justify-end md:items-end font-normal w-full">
                      <div className="flex justify-start md:px-4 items-center h-[42px] gap-2 w-full">
                        <span className="text-[14px] w-2/5">Invoice Date:</span>
                        <div className="w-3/4 items-center justify-center">
                          <FormInput
                            name={"invoice_date"}
                            type="date"
                            value={formData?.invoice_date}
                            onChange={(e) => {
                              let newArray = JSON.parse(
                                JSON.stringify(formData)
                              );
                              newArray.invoice_date = e.target.value;
                              setFormData(newArray);
                            }}
                            className="!h-[25px] text-[12px] border-1 border-gray-300 w-full font-normal"
                          />
                        </div>
                      </div>
                      <div className="flex justify-start md:px-4 items-center h-[42px] gap-2 w-full">
                        <span className="text-[14px] w-2/5">Due Date:</span>
                        <div className="w-3/4 items-center justify-center">
                          <FormInput
                            name={"invoice_due_date"}
                            type="date"
                            value={formData?.invoice_due_date}
                            onChange={(e) => {
                              let newArray = JSON.parse(
                                JSON.stringify(formData)
                              );
                              newArray.invoice_due_date = e.target.value;
                              setFormData(newArray);
                            }}
                            className="!h-[25px] text-[12px] border-1 border-gray-300 w-full font-normal"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col gap-3 justify-between font-normal -mt-3">
              <div className="md:w-[40%] w-full flex flex-col justify-start min-h-[200px] border rounded-md ">
                <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full mb-1 border-b-2 border-b-primary-1">
                  <span className="text-[13px] text-primary-1 font-extrabold">
                    CUSTOMER
                  </span>
                </div>
                <div className="flex gap-3 px-4 items-center w-full">
                  <div className="min-w-[90%]">
                    <FormDatalist
                      className={"w-full"}
                      placeholder="Type to select customer"
                      data={customers ? customers : []}
                      defaultText={defaultText}
                      onSelect={(value) => {
                        let index = customers.findIndex(
                          (item) => item.value === value
                        );
                        if (index !== -1) {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.customer_id = customers[index].value;
                          newArray.customer_name =
                            customers[index].customer_name;
                          newArray.customer_email = customers[index].email;
                          newArray.customer_mobile = customers[index].mobile;
                          newArray.customer_address = `${customers[index].address}, ${customers[index].city}, ${customers[index].state}, ${customers[index].country}.`;
                          newArray.contact_person =
                            customers[index].contact_person;
                          newArray.billing_customer_name =
                            customers[index].customer_name;
                          newArray.billing_contact_person =
                            customers[index].contact_person;
                          newArray.billing_address = `${customers[index].address}, ${customers[index].city}, ${customers[index].state}, ${customers[index].country}.`;
                          newArray.billing_email = customers[index].email;
                          newArray.billing_phone = customers[index].mobile;
                          setFormData(newArray);
                        }
                      }}
                    />
                  </div>
                  <img
                    src={AddSmallSVG}
                    alt={"add_question"}
                    className={"h-[25px] w-[25px] mb-1 cursor-pointer"}
                    onClick={() => {
                      setShowSelectAddCustomerOption(true);
                    }}
                  />
                </div>

                <hr className="divider mt-0 mb-2" />
                {formData?.customer_id ? (
                  <div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Customer name:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData?.customer_name}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Contact Person:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData?.contact_person}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Address:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData?.customer_address}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Email:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData?.customer_email}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Phone:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData?.customer_mobile}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center h-6 items-center">
                    No customer selected
                  </div>
                )}
              </div>
              <div className="md:w-[50%] w-full flex flex-col justify-start min-h-[200px] border rounded-md pb-2">
                <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full mb-1 border-b-2 border-b-primary-1">
                  <span className="text-[13px] text-primary-1 font-extrabold">
                    BILLING INFORMATION
                  </span>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Contact Person:
                  </span>
                  <div className="w-3/4 items-center justify-center">
                    <FormInput
                      name={"shipping_contact_person"}
                      placeholder={"Enter shipping contact person"}
                      type="text"
                      value={formData?.billing_contact_person}
                      onChange={(e) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.billing_contact_person = e.target.value;
                        setFormData(newArray);
                      }}
                      className="!h-[25px] !text-[13px] border-1 border-gray-300 w-full font-normal"
                    />
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Billing Address:
                  </span>
                  <div className="w-3/4 items-center justify-center">
                    <FormInput
                      name={"billing_address"}
                      placeholder={"Enter shipping address"}
                      type="text"
                      value={formData?.billing_address}
                      onChange={(e) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.billing_address = e.target.value;
                        setFormData(newArray);
                      }}
                      className="!h-[25px] !text-[13px] border-1 border-gray-300 w-full font-normal"
                    />
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Email:
                  </span>
                  <div className="w-3/4 items-center justify-center">
                    <FormInput
                      name={"shipping_email"}
                      placeholder={"Enter contact email"}
                      type="text"
                      value={formData?.billing_email}
                      onChange={(e) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.billing_email = e.target.value;
                        setFormData(newArray);
                      }}
                      className="!h-[25px] !text-[13px] border-1 border-gray-300 !w-full font-normal"
                    />
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Phone:
                  </span>
                  <div className="w-3/4 items-center justify-center">
                    <FormInput
                      name={"shipping_phone"}
                      placeholder={"Enter contact phone"}
                      type="text"
                      value={formData?.billing_phone}
                      onChange={(e) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.billing_phone = e.target.value;
                        setFormData(newArray);
                      }}
                      className="!h-[25px] !text-[13px] border-1 border-gray-300 w-full font-normal"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:flex flex-col font-normal border-b ">
              <div className="flex h-[50px] bg-primary-1  border-b-2 border-b-primary-1">
                <div className="flex w-2/6 items-center justify-center flex-col">
                  <div className="text-color-white font-bold text-[13px]">
                    ITEM NAME
                  </div>
                </div>
                <div className="flex w-2/6 items-center justify-center flex-col text-center">
                  <div className="text-color-white font-bold text-center text-[13px]">
                    DESCRIPTION
                  </div>
                </div>
                <div className="flex w-1/6 items-center justify-center flex-col">
                  <div className="text-color-white font-bold text-[13px]">
                    QTY
                  </div>
                </div>
                <div className="flex w-1/6 items-center justify-center flex-col">
                  <div className="text-color-white font-bold text-[13px]">
                    UNIT PRICE
                  </div>
                </div>
                <div className="flex w-1/6 items-center justify-center flex-col">
                  <div className="text-white font-bold text-[13px]">TOTAL</div>
                </div>
              </div>
              {formData &&
                formData?.invoice_items.length > 0 &&
                formData?.invoice_items.map((item, index) => (
                  <div className="flex h-[60px] hover:bg-gray-100 duration-300 gap-2">
                    <div className="flex w-2/10 items-center justify-center px-2 pt-1">
                      <Button
                        text="Remove"
                        type="submit"
                        theme="secondary"
                        className="flex gap-2 h-[25px] !min-w-fit p-2 !bg-white border-none !shadow-none "
                        textClass={"!text-[9px] !text-red-500"}
                        onClick={() => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          let subArray = newArray.invoice_items;
                          let data = [...subArray];
                          data.splice(index, 1); // 2nd parameter means remove one item only
                          newArray.invoice_items = [...data];
                          setFormData(newArray);
                        }}
                      />
                    </div>
                    <div className="w-2/6 items-center justify-center px-2 pt-1">
                      <FormInput
                        name={"item_name"}
                        placeholder={"Item name"}
                        type="text"
                        value={item.item_name}
                        onInput={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.invoice_items[index].item_name =
                            e.target.value;
                          setFormData(newArray);
                        }}
                        className="!h-[30px] text-[13px] border-1 border-gray-300 w-full"
                      />
                    </div>
                    <div className="w-2/6 items-center justify-center px-2 pt-1">
                      <FormInput
                        name={"description"}
                        placeholder={"Enter desciption"}
                        type="text"
                        value={item.description}
                        onInput={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.invoice_items[index].description =
                            e.target.value;
                          setFormData(newArray);
                        }}
                        className="!h-[30px] text-[13px] border-1 border-gray-300 !min-w-[100%]"
                      />
                    </div>
                    <div className="w-1/6 items-center justify-center px-2 pt-1">
                      <FormInput
                        name={"quantity"}
                        placeholder={"Enter quantity"}
                        type="number"
                        value={item.quantity}
                        onChange={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.invoice_items[index].quantity = parseFloat(
                            e.target.value
                          );
                          if (
                            e.target.value &&
                            newArray.invoice_items[index].quantity
                          ) {
                            newArray.invoice_items[index].total =
                              e.target.value *
                              parseFloat(
                                newArray.invoice_items[index].unit_price
                              );
                          } else {
                            newArray.invoice_items[index].total = 0;
                          }
                          let sub_total = 0;
                          if (
                            newArray.invoice_items &&
                            newArray.invoice_items.length > 0
                          ) {
                            newArray.invoice_items.forEach((subItem) => {
                              sub_total += subItem.total;
                            });
                          }
                          newArray.invoice_sub_total = sub_total;
                          newArray.invoice_tax_amount =
                            Math.round(
                              sub_total *
                                (newArray.invoice_tax_rate / 100) *
                                100
                            ) / 100;
                          newArray.total_amount =
                            newArray.invoice_sub_total +
                            newArray.invoice_tax_amount +
                            newArray.shipping_cost;
                          setFormData(newArray);
                        }}
                        className="!h-[30px] text-[13px] border-1 border-gray-300 w-full text-end"
                      />
                    </div>
                    <div className="w-1/6 items-center justify-center px-2 pt-1">
                      <FormInput
                        name={"unit_price"}
                        placeholder={"Enter unit price"}
                        type="number"
                        value={item.unit_price}
                        required
                        onChange={(e) => {
                          let new_value = parseFloat(e.target.value);
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.invoice_items[index].unit_price = new_value;
                          if (
                            new_value &&
                            newArray.invoice_items[index].quantity
                          ) {
                            newArray.invoice_items[index].total =
                              new_value *
                              parseFloat(
                                newArray.invoice_items[index].quantity
                              );
                          } else {
                            newArray.invoice_items[index].total = 0;
                          }
                          let sub_total = 0;
                          if (
                            newArray.invoice_items &&
                            newArray.invoice_items.length > 0
                          ) {
                            newArray.invoice_items.forEach((subItem) => {
                              sub_total += subItem.total;
                            });
                          }
                          newArray.invoice_sub_total = sub_total;
                          newArray.invoice_tax_amount =
                            Math.round(
                              sub_total *
                                (newArray.invoice_tax_rate / 100) *
                                100
                            ) / 100;
                          newArray.total_amount =
                            parseFloat(newArray.invoice_sub_total) +
                            parseFloat(newArray.invoice_tax_amount) +
                            parseFloat(newArray.shipping_cost);
                          setFormData(newArray);
                        }}
                        className="!h-[30px] text-[13px] border-1 border-gray-300 !min-w-full text-end"
                      />
                    </div>
                    <div className="flex w-1/6 items-center justify-end pr-4 bg-secondary-2-extralight pt-1">
                      <div className="text-primary-1 text-[14px] font-bold">
                        {formatNumber(item.total, 2)}
                      </div>
                    </div>
                  </div>
                ))}
              <div className="flex h-[50px] duration-300 gap-2">
                <div className="w-2/6 items-center justify-center pt-2">
                  <div className="md:w-fit w-[100%]">
                    <Button
                      text="New Line Item"
                      type="submit"
                      theme="primary"
                      className="flex gap-2 h-[25px] !min-w-fit p-2"
                      textClass={"!text-[10px]"}
                      leftIcon={"add"}
                      onClick={() => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.invoice_items.push({
                          item_name: "",
                          description: "",
                          quantity: 1,
                          unit_price: 0,
                          total: 0,
                        });
                        setFormData(newArray);
                      }}
                    />
                  </div>
                </div>
                <div className="w-2/6 items-center justify-center px-2"></div>
                <div className="w-1/6 items-center justify-center px-2"></div>
                <div className="w-1/6 items-center justify-center px-2"></div>
                <div className="w-1/6"></div>
              </div>
            </div>
            <div className="md:hidden flex flex-col font-normal border-b  min-h-fit bg-secondary-2-extralight px-2 py-2 rounded-md">
              <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[40px]">
                <div className="text-[17px] font-extrabold">Invoice Items</div>
              </div>
              {formData &&
                formData?.invoice_items.length > 0 &&
                formData?.invoice_items.map((item, index) => (
                  <div className="flex flex-col h-fit hover:bg-gray-100 duration-300 gap-2 px-2 border rounded-md bg-white mb-2">
                    <div className="flex md:w-2/10 w-full items-center justify-start px-2 mt-2">
                      <Button
                        text="Remove"
                        type="submit"
                        theme="secondary"
                        className="flex gap-2 h-[25px] w-fit p-2 !bg-white border-none !shadow-none "
                        textClass={"!text-[12px] !text-red-500"}
                        onClick={() => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          let subArray = newArray.invoice_items;
                          let data = [...subArray];
                          data.splice(index, 1); // 2nd parameter means remove one item only
                          newArray.invoice_items = [...data];
                          setFormData(newArray);
                        }}
                      />
                    </div>
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="text-[15px] font-extrabold">
                        Item Name:
                      </div>
                      <div className="text-[14px]">
                        <FormInput
                          name={"item_name"}
                          placeholder={"Item name"}
                          type="text"
                          value={item.item_name}
                          onInput={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.invoice_items[index].item_name =
                              e.target.value;
                            setFormData(newArray);
                          }}
                          className="!h-[30px] text-[13px] border-1 border-gray-300 w-full"
                        />
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-1" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="flex justify-betweentext-[15px] font-extrabold">
                        Description:
                      </div>
                      <div className="text-[14px]">
                        <FormInput
                          name={"description"}
                          placeholder={"Enter desciption"}
                          type="text"
                          value={item.description}
                          onInput={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.invoice_items[index].description =
                              e.target.value;
                            setFormData(newArray);
                          }}
                          className="!h-[30px] text-[13px] border-1 border-gray-300 !min-w-[100%]"
                        />
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-1" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="text-[15px] font-extrabold">
                        Quantity:
                      </div>
                      <div className="text-[14px]">
                        <FormInput
                          name={"quantity"}
                          placeholder={"Enter quantity"}
                          type="number"
                          value={item.quantity}
                          onChange={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.invoice_items[index].quantity = parseFloat(
                              e.target.value
                            );
                            if (
                              e.target.value &&
                              newArray.invoice_items[index].quantity
                            ) {
                              newArray.invoice_items[index].total =
                                e.target.value *
                                parseFloat(
                                  newArray.invoice_items[index].unit_price
                                );
                            } else {
                              newArray.invoice_items[index].total = 0;
                            }
                            let sub_total = 0;
                            if (
                              newArray.invoice_items &&
                              newArray.invoice_items.length > 0
                            ) {
                              newArray.invoice_items.forEach((subItem) => {
                                sub_total += subItem.total;
                              });
                            }
                            newArray.invoice_sub_total = sub_total;
                            newArray.invoice_tax_amount =
                              Math.round(
                                sub_total *
                                  (newArray.invoice_tax_rate / 100) *
                                  100
                              ) / 100;
                            newArray.total_amount =
                              newArray.invoice_sub_total +
                              newArray.invoice_tax_amount +
                              newArray.shipping_cost;
                            setFormData(newArray);
                          }}
                          className="!h-[30px] text-[13px] border-1 border-gray-300 w-full text-end"
                        />
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-1" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="text-[15px] font-extrabold">
                        Unit Price:
                      </div>
                      <div className="text-[14px]">
                        <FormInput
                          name={"unit_price"}
                          placeholder={"Enter unit price"}
                          type="number"
                          value={item.unit_price}
                          required
                          onChange={(e) => {
                            let new_value = parseFloat(e.target.value);
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.invoice_items[index].unit_price =
                              new_value;
                            if (
                              new_value &&
                              newArray.invoice_items[index].quantity
                            ) {
                              newArray.invoice_items[index].total =
                                new_value *
                                parseFloat(
                                  newArray.invoice_items[index].quantity
                                );
                            } else {
                              newArray.invoice_items[index].total = 0;
                            }
                            let sub_total = 0;
                            if (
                              newArray.invoice_items &&
                              newArray.invoice_items.length > 0
                            ) {
                              newArray.invoice_items.forEach((subItem) => {
                                sub_total += subItem.total;
                              });
                            }
                            newArray.invoice_sub_total = sub_total;
                            newArray.invoice_tax_amount =
                              Math.round(
                                sub_total *
                                  (newArray.invoice_tax_rate / 100) *
                                  100
                              ) / 100;
                            newArray.total_amount =
                              parseFloat(newArray.invoice_sub_total) +
                              parseFloat(newArray.invoice_tax_amount) +
                              parseFloat(newArray.shipping_cost);
                            setFormData(newArray);
                          }}
                          className="!h-[30px] text-[13px] border-1 border-gray-300 !min-w-full text-end"
                        />
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-1" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[40px]">
                      <div className="text-[15px] font-extrabold">Total:</div>
                      <div className="text-[18px] font-extrabold">
                        {formatNumber(item.total, 2)}
                      </div>
                      {/* <div className='text-primary-1 text-[14px] font-bold'></div> */}
                    </div>
                    <hr className="divider mt-0" />
                  </div>
                ))}
              <div className="w-full items-center justify-center pt-3">
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="New Line Item"
                    type="submit"
                    theme="primary"
                    className="flex gap-2 h-[25px] !max-w-fit p-2"
                    textClass={"!text-[10px]"}
                    leftIcon={"add"}
                    onClick={() => {
                      let newArray = JSON.parse(JSON.stringify(formData));
                      newArray.invoice_items.push({
                        item_name: "",
                        description: "",
                        quantity: 1,
                        unit_price: 0,
                        total: 0,
                      });
                      setFormData(newArray);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col md:h-[180px] items-center gap-3 w-full -mt-2 rounded-md">
              <div className="md:hidden flex md:w-2/6 w-full justify-end md:pt-0 pt-4">
                <div className="flex flex-col w-full h-full justify-center pt-2">
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2 text-[14px]">
                      Sub Total
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData?.invoice_sub_total, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        Tax&nbsp;
                      </span>
                      <FormInput
                        name={"invoice_tax_rate"}
                        type="number"
                        value={formData?.invoice_tax_rate}
                        onChange={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.invoice_tax_rate = e.target.value;
                          let sub_total = 0;
                          if (
                            newArray.invoice_items &&
                            newArray.invoice_items.length > 0
                          ) {
                            newArray.invoice_items.forEach((subItem) => {
                              let single_total = parseFloat(subItem.total);
                              sub_total += single_total;
                            });
                          }
                          newArray.invoice_sub_total = sub_total;
                          newArray.invoice_tax_amount =
                            Math.round(
                              sub_total *
                                (newArray.invoice_tax_rate / 100) *
                                100
                            ) / 100;
                          newArray.total_amount =
                            parseFloat(newArray.invoice_sub_total) +
                            parseFloat(newArray.invoice_tax_amount) +
                            parseFloat(newArray.shipping_cost);
                          setFormData(newArray);
                        }}
                        className="!h-[30px] !w-[80px] text-[14px]"
                      />
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        &nbsp;%
                      </span>
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData?.invoice_tax_amount, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2 text-[14px]">
                      Shipping cost
                    </div>
                    <div className="flex justify-end text-primary-1 px-2 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      <FormInput
                        name={"shipping_cost"}
                        type="number"
                        value={formData?.shipping_cost}
                        onChange={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.shipping_cost = e.target.value;
                          newArray.total_amount =
                            parseFloat(newArray.invoice_sub_total) +
                            parseFloat(newArray.invoice_tax_amount) +
                            parseFloat(newArray.shipping_cost);
                          setFormData(newArray);
                        }}
                        className="!h-[30px] !text-[14px] border-1 border-gray-300 w-full !text-right !px-1 !-pr-1"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[16px] gap-2 items-center !h-[60px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Grand Total
                    </div>
                    <div className="flex justify-end text-white px-4 !w-1/2 bg-secondary-2-extralight h-full items-center bg-primary-1 text-[14px] font-extrabold">
                      {formatNumber(formData?.total_amount, 2)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-4/6 w-full md:mt-0 mt-3">
                <div className="md:w-[80%] w-full flex flex-col justify-start min-h-[120px]">
                  <div className="flex items-center px-2 bg-secondary-2-extralight h-[45px] w-full border-b-2 border-b-primary-1">
                    <span className="md:text-[15px] text-[13px] text-primary-1 font-extrabold">
                      ADDITIONAL COMMENTS AND INSTRUCTIONS
                    </span>
                  </div>
                  <FormTextArea
                    name={"invoice_comments"}
                    placeholder={"Enter Additional comments and instructions"}
                    type="text"
                    value={formData?.invoice_comments}
                    onChange={(e) => {
                      let newArray = JSON.parse(JSON.stringify(formData));
                      newArray.invoice_comments = e.target.value;
                      setFormData(newArray);
                    }}
                    className="text-[13px] border-1 border-gray-300 w-full font-normal -mt-[1px] !rounded-t-none"
                  />
                </div>
              </div>
              <div className="hidden md:flex md:w-2/6 w-full justify-end md:pt-0 pt-4">
                <div className="flex flex-col w-full h-full justify-center pt-2">
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Sub Total
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData?.invoice_sub_total, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        Tax&nbsp;
                      </span>
                      <FormInput
                        name={"invoice_tax_rate"}
                        type="number"
                        value={formData?.invoice_tax_rate}
                        onChange={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.invoice_tax_rate = e.target.value;
                          let sub_total = 0;
                          if (
                            newArray.invoice_items &&
                            newArray.invoice_items.length > 0
                          ) {
                            newArray.invoice_items.forEach((subItem) => {
                              let single_total = parseFloat(subItem.total);
                              sub_total += single_total;
                            });
                          }
                          newArray.invoice_sub_total = sub_total;
                          newArray.invoice_tax_amount =
                            Math.round(
                              sub_total *
                                (newArray.invoice_tax_rate / 100) *
                                100
                            ) / 100;
                          newArray.total_amount =
                            parseFloat(newArray.invoice_sub_total) +
                            parseFloat(newArray.invoice_tax_amount) +
                            parseFloat(newArray.shipping_cost);
                          setFormData(newArray);
                        }}
                        className="!h-[30px] !w-[80px] text-[14px]"
                      />
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        &nbsp;%
                      </span>
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData?.invoice_tax_amount, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Shipping cost
                    </div>
                    <div className="flex justify-end text-primary-1 px-2 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      <FormInput
                        name={"shipping_cost"}
                        type="number"
                        value={formData?.shipping_cost}
                        onChange={(e) => {
                          let new_value = e.target.value;
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.shipping_cost = parseFloat(new_value);
                          newArray.total_amount =
                            parseFloat(newArray.invoice_sub_total) +
                            parseFloat(newArray.invoice_tax_amount) +
                            parseFloat(newArray.shipping_cost);
                          setFormData(newArray);
                        }}
                        className="!h-[30px] !text-[14px] border-1 border-gray-300 w-full !text-right !px-1 !-pr-1"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[16px] gap-2 items-center !h-[60px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Grand Total
                    </div>
                    <div className="flex justify-end text-white px-4 !w-1/2 bg-secondary-2-extralight h-full items-center bg-primary-1 text-[16px] font-extrabold">
                      {formatNumber(formData?.total_amount, 2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full bg-gray-50 !min-h-16 p-4 py-2 border md:mt-6 rounded-md gap-3">
              <div className="text-[15px] text-primary-1 font-extrabold">
                PAYMENT INSTRUCTION
              </div>
              <div className="flex gap-2 items-center -mb-6">
                <FormSwitch
                  label={"Do you want to use your Ropay bank details?"}
                  name={"use_ropay_account"}
                  checked={useRopayAccount}
                  onClick={() => {
                    setUseRopayAccount(!useRopayAccount);
                  }}
                />
              </div>
              {useRopayAccount ? (
                <div className="flex flex-col md:w-1/2 w-full p-2 rounded-md border bg-white">
                  <div className="flex justify-start items-center h-[42px] gap-2">
                    <span className="text-[13px] w-2/5">Bank Name:</span>
                    <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                      {formData?.bank_name}
                    </div>
                  </div>
                  <div className="flex justify-start items-center h-[42px] gap-2">
                    <span className="text-[13px] w-2/5">Account Number:</span>
                    <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                      {formData?.account_number}
                    </div>
                  </div>
                  <div className="flex justify-start items-center h-[42px] gap-2">
                    <span className="text-[13px] w-2/5">Account Name:</span>
                    <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                      {formData?.account_name}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col md:w-1/2 w-full gap-2 p-2 rounded-md border bg-white">
                  <div className="flex justify-start items-center h-[42px] gap-2 w-full">
                    <span className="text-[13px] w-3/5">
                      Bank Name (Type to select):
                    </span>
                    <FormDatalist
                      // label='Bank Name'
                      data={banks ? banks : []}
                      value={formData?.bank_name}
                      onSelect={(value) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.bank_name = value;
                        newArray.account_number = "";
                        setFormData(newArray);
                        setBankName(value);
                      }}
                      className="!h-[30px] !text-[14px] border-1 border-gray-300 w-3/5 !text-right !px-1"
                    />
                  </div>
                  <div className="flex justify-start items-center h-[42px] gap-2">
                    <span className="text-[13px] w-3/5">Account Number:</span>
                    <FormInput
                      name={"account_number"}
                      type="text"
                      placeholder={"Enter account number"}
                      value={formData?.account_number}
                      onChange={(e) => {
                        let new_value = e.target.value;
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.account_number = new_value;
                        setFormData(newArray);
                        setAccountNumber(e.target.value);
                      }}
                      className="!h-[30px] !text-[14px] border-1 border-gray-300 w-full !text-right !px-1"
                    />
                  </div>
                  <div className="flex justify-between items-center h-[42px] gap-2 w-full">
                    {resolveAccountLoading ? (
                      <>
                        <span className="text-[13px] w-3/5">Account Name:</span>
                        <LoadingBar loading={resolveAccountLoading} />
                      </>
                    ) : (
                      <>
                        <span className="text-[13px] w-2/5">Account Name:</span>
                        <div className="items-center justify-center text-[13px] font-extrabold">
                          {formData?.account_name}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col md:flex-row items-end gap-[10px] md:items-center w-full justify-end">
              <div className="md:w-fit">
                <Button
                  text={
                    invoiceSaveType === "add"
                      ? "SAVE INVOICE"
                      : "UPDATE INVOICE"
                  }
                  type="button"
                  theme="primary"
                  className="flex gap-2 !h-[35px] w-fit p-2"
                  textClass={"!text-[11px]"}
                  loading={
                    addInvoiceLoading ||
                    updateInvoiceLoading ||
                    addRecurringInvoiceLoading ||
                    updateRecurringInvoiceLoading
                  }
                  onClick={() => {
                    save();
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4 min-h-fit">
            <div className="flex gap-2 md:justify-end w-full  items-center">
              <span className="text-primary-1 text-[30px] ">INVOICE</span>
              {templateId ? (
                <span className="text-color-gray -pt-2">
                  (Recurring template)
                </span>
              ) : (
                <span className="text-color-gray -pt-2">
                  <StatusText
                    code={formData?.status}
                    className={"text-[16px]"}
                    statusConfig={invoiceStatusConfig}
                  />
                </span>
              )}
            </div>
            <div className="flex md:flex-row flex-col items-center justify-between bg-primary-1 p-3 text-color-white rounded-md">
              <div className="md:w-1/2 w-full flex flex-col items-start gap-3">
                <div className="flex gap-2 items-center">
                  <div className="text-[20px] flex font-bold justify-center items-center h-[55px] w-[100px] header-3 relative hover:scale-110 hover:m-2 duration-500 rounded-xl bg-white">
                    {company?.logo ? (
                      <div>
                        <img
                          src={company?.logo}
                          alt="email_icon"
                          className="object-fit min-w-[70px] min-h-[45px] max-w-[85px] max-h-[45px] "
                        />
                      </div>
                    ) : (
                      <span className="text-primary-1">
                        {Initials(company?.company_name)}
                      </span>
                    )}
                  </div>
                  <span className="text-color-white text-[25px] ">
                    {company?.company_name}
                  </span>
                </div>
                <div className="md:w-1/2 flex flex-col justify-start gap-2 font-normal">
                  <div className="w-full flex justify-start text-[14px] text-color-white">
                    {company?.address}, {company?.city}, {company?.state},{" "}
                    {company?.country}.
                  </div>
                  <div className="w-full flex justify-start text-[14px] font-semibold text-color-white">
                    {company?.email} | {company?.mobile}
                  </div>
                </div>
              </div>
              {templateId ? null : (
                <div className="md:w-1/2 w-full flex md:justify-end justify-center text-2xl text-primary-1 md:mt-3 mt-3 md:border-t-0 border-t-2 border-t-white md:pt-0 pt-3">
                  <div className="w-full flex flex-col items-start">
                    <div className="flex flex-col md:justify-end md:items-end justify-start items-start font-normal w-full md:gap-0 gap-3">
                      <div className="flex md:justify-end md:px-4 items-center h-[42px] gap-2 w-full text-[14px] text-color-white">
                        <span className="md:text-[14px] text-[12px] md:w-2/3 w-1/2 md:text-right text-left font-semibold text-color-white">
                          Invoice Date:
                        </span>
                        <div className="w-1/2 md:text-right text-left text-color-white text-[13px] md:text-[14px]">
                          {formData?.invoice_date}
                        </div>
                      </div>
                      <div className="flex md:justify-end md:px-4 items-center h-[42px] gap-2 w-full text-[14px] text-color-white -mt-4">
                        <span className="md:text-[14px] text-[12px] md:w-2/3 w-1/2 md:text-right text-left font-semibold text-color-white">
                          Due Date:
                        </span>
                        <div className="w-1/2 md:text-right text-left text-color-white text-[13px] md:text-[14px]">
                          {formData?.invoice_due_date}
                        </div>
                      </div>
                      <div className="flex justify-start md:px-4 items-center h-[42px] gap-2 w-full text-[14px] -mt-4">
                        <span className="md:text-[14px] text-[12px] md:w-2/3 w-1/2 md:text-right text-left font-semibold text-color-white">
                          Invoice #:
                        </span>
                        <div className="w-1/2 md:text-right text-left text-color-white text-[13px] md:text-[14px]">
                          {formData?.invoice_number}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex md:flex-row flex-col gap-3 justify-between font-normal">
              <div className="md:w-[40%] w-full flex flex-col justify-start min-h-[200px] border rounded-md ">
                <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full mb-1 border-b-2 border-b-primary-1">
                  <span className="text-[14px] text-primary-1 font-extrabold">
                    CUSTOMER
                  </span>
                </div>
                {formData?.customer_id ? (
                  <div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Customer name:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData?.customer_name}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Contact Person:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData?.contact_person}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Address:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData?.customer_address}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Email:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData?.customer_email}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Phone:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData?.customer_mobile}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center h-6 items-center">
                    No customer selected
                  </div>
                )}
              </div>
              <div className="md:w-[50%] w-full flex flex-col justify-start min-h-[200px] border rounded-md pb-2">
                <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full mb-1 border-b-2 border-b-primary-1">
                  <span className="text-[14px] text-primary-1 font-extrabold">
                    BILLING INFORMATION
                  </span>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Contact Person:
                  </span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData?.billing_contact_person}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Billing Address:
                  </span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData?.billing_address}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Email:
                  </span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData?.billing_email}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Phone:
                  </span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData?.billing_phone}
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:flex flex-col font-normal border-b ">
              <div className="flex h-[50px] bg-secondary-2-extralight">
                <div className="flex w-2/6 items-start justify-center flex-col px-3">
                  <div className="text-primary-1 font-bold text-[14px]">
                    ITEM NAME
                  </div>
                </div>
                <div className="flex w-2/6 items-start justify-center flex-col px-3">
                  <div className="text-primary-1 font-bold text-center text-[14px]">
                    DESCRIPTION
                  </div>
                </div>
                <div className="flex w-1/6 items-start justify-center flex-col px-3">
                  <div className="text-primary-1 font-bold text-[14px]">
                    QTY
                  </div>
                </div>
                <div className="flex w-1/6 items-start justify-center flex-col px-3">
                  <div className="text-primary-1 font-bold text-[14px]">
                    UNIT PRICE
                  </div>
                </div>
                <div className="flex w-1/6 items-start justify-center flex-col px-3">
                  <div className="text-primary-1 font-bold text-[14px]">
                    TOTAL
                  </div>
                </div>
              </div>
              {formData &&
                formData?.invoice_items.length > 0 &&
                formData?.invoice_items.map((item) => (
                  <div className="flex h-[60px] hover:bg-gray-100 duration-300 gap-2">
                    <div className="flex w-2/6 items-center justify-start px-2 text-[14px] !h-[60px]">
                      {item.item_name}
                    </div>
                    <div className="flex w-2/6 items-center justify-start px-2 text-[14px] !h-[60px]">
                      {item.description}
                    </div>
                    <div className="flex w-1/6 items-center justify-end px-2 text-[14px] !h-[60px]">
                      {formatNumber(item.quantity, 2)}
                    </div>
                    <div className="flex w-1/6 items-center justify-end px-2 text-[14px] !h-[60px]">
                      {formatNumber(item.unit_price, 2)}
                    </div>
                    <div className="flex w-1/6 items-center justify-end pr-4 bg-secondary-2-extralight text-[14px] !h-[60px]">
                      <div className="text-primary-1 text-[14px] font-bold">
                        {formatNumber(item.total, 2)}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="md:hidden flex flex-col font-normal border-b  min-h-fit bg-secondary-2-extralight px-2 py-2 rounded-md gap-3">
              <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[40px] -mb-3">
                <div className="text-[15px] font-extrabold">INVOICE ITEMS</div>
              </div>
              {formData &&
                formData?.invoice_items.length > 0 &&
                formData?.invoice_items.map((item) => (
                  <div className="flex flex-col h-fit hover:bg-gray-100 duration-300 gap-2 px-2 border rounded-md bg-white">
                    <div className="flex w-full items-center justify-between px-2 text-[13px] !h-[30px] pt-2 mt-1 mb-1">
                      <div className="text-[14px] font-extrabold">
                        Item Name:
                      </div>
                      <div className="text-[13px]">{item.item_name}</div>
                    </div>
                    <hr className="divider mt-0 mb-0" />
                    <div className="flex w-full items-center justify-between px-2 text-[13px] !h-[30px]">
                      <div className="flex justify-betweentext-[15px] font-extrabold">
                        Description:
                      </div>
                      <div className="text-[13px]">{item.description}</div>
                    </div>
                    <hr className="divider mt-0 mb-0" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="text-[14px] font-extrabold">
                        Quantity:
                      </div>
                      <div className="text-[13px]">
                        {formatNumber(item.quantity, 2)}
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-0" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="text-[14px] font-extrabold">
                        Unit Price:
                      </div>
                      <div className="text-[13px]">
                        {formatNumber(item.unit_price, 2)}
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-0" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[40px]">
                      <div className="text-[16px] font-extrabold">Total:</div>
                      <div className="text-[15px] font-extrabold">
                        {formatNumber(item.total, 2)}
                      </div>
                      {/* <div className='text-primary-1 text-[14px] font-bold'></div> */}
                    </div>
                    <hr className="divider mt-0" />
                  </div>
                ))}
            </div>
            <div className="flex md:flex-row flex-col md:h-[180px] items-center gap-4 w-full">
              <div className="md:hidden flex md:w-2/6 w-full justify-end md:pt-0 pt-4">
                <div className="flex flex-col w-full h-full justify-center pt-2">
                  <div className="flex justify-between !font-extrabold text-[16px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2 text-[14px]">
                      Sub Total
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center text-[14px]">
                      {formatNumber(formData?.invoice_sub_total, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[16px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        Tax&nbsp;
                      </span>
                      ({formatNumber(formData?.invoice_tax_rate, 2)}
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        %
                      </span>
                      )
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData?.invoice_tax_amount, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Shipping cost
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData?.shipping_cost, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[60px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Grand Total
                    </div>
                    <div className="flex justify-end text-white px-4 !w-1/2 bg-secondary-2-extralight h-full items-center bg-primary-1 text-[20px] font-extrabold">
                      {formatNumber(formData?.total_amount, 2)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-4/6 w-full md:mt-0 mt-3">
                <div className="md:w-[80%] w-full flex flex-col justify-start min-h-[120px] rounded-md">
                  <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full border-b-2 border-b-primary-1">
                    <span className="text-[15px] text-primary-1 font-extrabold">
                      ADDITIONAL COMMENTS AND INSTRUCTIONS
                    </span>
                  </div>
                  <div className="text-[13px] border-1 border-gray-300 !bg-white w-full font-normal !rounded-t-none p-4 mt-0">
                    {formData?.invoice_comments}
                  </div>
                </div>
              </div>
              <div className="hidden md:flex md:w-2/6 w-full justify-end md:pt-0 pt-4">
                <div className="flex flex-col w-full h-full justify-center pt-2">
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Sub Total
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData?.invoice_sub_total, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        Tax&nbsp;
                      </span>
                      ({formatNumber(formData?.invoice_tax_rate, 2)}
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        %
                      </span>
                      )
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData?.invoice_tax_amount, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Shipping cost
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData?.shipping_cost, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[60px]">
                    <div className=" flex items-center justify-start !w-1/2 text-[16px]">
                      Grand Total
                    </div>
                    <div className="flex justify-end text-white px-4 !w-1/2 bg-secondary-2-extralight h-full items-center bg-primary-1 text-[16px] font-extrabold">
                      {formatNumber(formData?.total_amount, 2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col w-full bg-gray-50 !min-h-16 p-4 py-2 border md:mt-6 rounded-md">
              <div className="flex flex-col md:w-1/2 w-full">
                <div className="text-[15px] text-primary-1 font-extrabold">
                  PAYMENT INSTRUCTION
                </div>
                <div className="flex justify-start items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Bank Name:
                  </span>
                  <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                    {formData?.bank_name}
                  </div>
                </div>
                <div className="flex justify-start items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Account Number:
                  </span>
                  <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                    {formData?.account_number}
                  </div>
                </div>
                <div className="flex justify-start items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Account Name:
                  </span>
                  <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                    {formData?.account_name}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default InvoicePage;
