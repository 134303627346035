import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import CloseSVG from "../../../../assets/svg/close.svg";
import { formValidation } from "../../../../utils/functions";
import Button from "../../../button";
import FormInput from "../../../form-input";
import FormSelectEmployee from "../../../employees/form-select-employee";
import { useAddAttendance } from "../../../../redux/human-resources/hook/attendance";
import Modal from "../../modal";

function AddAttendance({
  isOpen,
  closeModal,
  refresh,
  attendanceData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { mutateAsync: addAttendance, isLoading: addAttendanceloading } =
    useAddAttendance();

  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();

  const submitForm = async (data) => {
    const clockin_time = DOMPurify.sanitize(data?.clock_in);
    const clockout_time = DOMPurify.sanitize(data?.clock_out);
    const attendance_date = DOMPurify.sanitize(data?.attendance_date);

    let payload = {
      employee_id: employee,
      clockin_time: clockin_time,
      clockout_time: clockout_time,
      attendance_date: attendance_date,
      status: 1,
    };
    addAttendance(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className="!max-w-[600px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col pb-4 overflow-auto">
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <div className="pl-8 pr-8 pt-4">
            <div className="flex flex-row justify-between items-center">
              <span className="header-4">{title}</span>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <img src={CloseSVG} alt="close" />
              </div>
            </div>
            <span className="text-color-gray text-[14px]">
              Attendance Added Here is marked Present Automatically.
            </span>
          </div>
          <hr className="divider" />
          <div className="flex flex-col mx-8">
            <div>
              <div className="flex flex-col p-2 py-4 pb-2 border rounded-md mb-4 bg-highlight">
                <FormSelectEmployee
                  label={"Employee"}
                  name="employee"
                  placeholder="Type to select Employee"
                  value={employee}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
              <div className="w-full">
                <FormInput
                  label="Attendance Date"
                  name="attendance_date"
                  type="date"
                  inputRef={register(formValidation("date", true))}
                  error={errors.attendance_date}
                  errorMessage={
                    errors.attendance_date && errors.attendance_date.message
                  }
                />
              </div>
              <div className="flex flex-row md:flex-col w-full justify-between">
                <div className="w-full md:w-1/2 pr-2">
                  <FormInput
                    label="Resumption Time"
                    name="clock_in"
                    type="time"
                    inputRef={register(formValidation("time", true))}
                    error={errors.clock_in}
                    errorMessage={errors.clock_in && errors.clock_in.message}
                  />
                </div>
                <div className="w-full md:w-1/2 pl-2">
                  <FormInput
                    label="Closing Time"
                    name="clock_out"
                    type="time"
                    inputRef={register(formValidation("time", true))}
                    error={errors.clock_out}
                    errorMessage={errors.clock_out && errors.clock_out.message}
                  />
                </div>
              </div>
            </div>
            <div className="w-full mt-[20px]">
              <Button
                text={buttonText}
                type="submit"
                loading={addAttendanceloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddAttendance;
