import React from "react";
import DataTable from "../../datatable";
import { useState, useEffect } from "react";
import {
  bonusesStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../utils/constants";
import {
  useDeleteBonus,
  useSingleGetEmployeeBonuses,
} from "../../../redux/employees/hook/bonuses";
import { useParams } from "react-router-dom";
import AddBonus from "../../modal/employees/add-bonus";
import Button from "../../button";

export const SingleEmployeeBonusTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });
  const { id } = useParams();

  const { refetch, data: Bonuses } = useSingleGetEmployeeBonuses(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: deleteBonus } = useDeleteBonus();

  const [showAddBonus, setShowAddBonus] = useState(false);
  const [bonusData, setBonusData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const tableConfig = {
    // title: 'Bonus Management',
    headers: [
      "Employee Name",
      "Phone Number",
      "Purpose",
      "Month",
      "Amount",
      "Status",
    ],
    keys: [
      "employeeName",
      "phone",
      "purpose",
      "formatted_month",
      "formatted_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Amount",
        key: "formatted_amount",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "orange",
      action: (id) => {
        let index = Bonuses.data.findIndex((item) => item.id === id);
        setBonusData(Bonuses.data[index]);
        setShowAddBonus(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = Bonuses.data.findIndex((item) => item.id === id);
        deleteBonus(Bonuses.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddBonus ? (
        <AddBonus
          isOpen={showAddBonus}
          closeModal={() => setShowAddBonus(false)}
          bonusData={bonusData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Bonuses
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Bonus"
              type="submit"
              theme="primary"
              disabled={!employees}
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setBonusData(null);
                setButtonText("SAVE");
                setTitle("Add Bonus");
                setShowAddBonus(true);
              }}
            />
          </div>
          <div className="md:w-fit w-[100%]">
            <Button
              text="Refetch"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"change"}
              onClick={() => {
                refetch();
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={Bonuses}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        statusConfig={bonusesStatusConfig}
        updatePagination={(data) => setPagination(data)}
        noCheck={true}
        computedActions={true}
      />
    </div>
  );
};
