import React from "react";
import Logo from "../logo";
import HRAuthLogo from "../../assets/svg/login-4.svg";
import OtherAuthLogo from "../../assets/svg/other_auth_image.svg";
import ForgotLogo from "../../assets/svg/forgot-password.svg";
import ForgotSpLogo from "../../assets/svg/forgot-password-ps.svg";
import pppLogo from "../../assets/img/ppp-white.png";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Typewriter from "../fragments/typewriter-text";

const AuthLayout = ({ children }) => {
  const { section } = useSelector((state) => state.sectionSlice);
  const location = useLocation();

  return (
    <div
      className={
        "flex flex-col h-screen w-full radial-gradient max-h-screen md:p-3 items-center justify-center gap-3 p-4"
      }
    >
      <div className="flex flex-col justify-start align-center md:w-[480px] w-full bg-white py-3 px-4 md:px-2 overflow-y-auto min-h-fit">
        <div className="justify-center items-center">
          <Logo width={"130px"} height={"56px"} />
          <div className="flex flex-col max-h-fit">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
