import { Axios } from '../axios';

const getInvoices = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get( `/invoices?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  return data?.data;
};

const getInvoicesByCustomer = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get( `/invoices/by-customer/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
  return data?.data;
};

const updateInvoice = async (payload) => {

  const { data } = await Axios.put( '/invoices/' + payload.id, payload.data, );
  return data;
};

const getInvoice= async (payload) => {
  const { data } = await Axios.get('/invoices/' + payload);
  return data?.data;
};

const createSingleInvoice = async (payload) => {
  const { data } = await Axios.post('/invoices', payload);
  return data;
};

const markInvoicePaid = async (payload) => {
  const { data } = await Axios.put(`/invoices/mark-as-paid/${payload}`);
  return data;
};

const markInvoiceUnpaid = async (payload) => {
  const { data } = await Axios.post(`/invoices/mark-as-unpaid/${payload}`);
  return data;
};

const getInvoicePaymentHistory = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';

  const { data } = await Axios.get( `/Invoices/transactions/history?limit=${payload.limit}&page=${payload.page}${search}${filter}${start_date}${end_date}`,);

  return data?.data;
};

const sendInvoice = async (payload) => {
    const { data } = await Axios.post(`/Invoices/send-invoice/${payload}`, );
    return data;
};

const deleteInvoice = async (payload) => {
    const { data } = await Axios.delete('invoices/'+payload);
    return data;
};

export const invoices = {
    getInvoices,
    getInvoicesByCustomer,
    updateInvoice,
    getInvoice,
    createSingleInvoice,
    markInvoicePaid,
    markInvoiceUnpaid,
    getInvoicePaymentHistory,
    sendInvoice,
    deleteInvoice,
};