import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../../components/datatable';
import { PAGINATION_DEFAULT } from '../../utils/constants';
// import { useSelector } from 'react-redux';
import { useGetCabinetTrainings } from '../../redux/employee-folder/hook';
import TrainedEmployee from '../modal/hr-section/employee-training/trained-employee';

const TrainingFolder = ({employeeId}) => {

    // const { user } = useSelector((state) => state.userSlice);

    const [pagination, setPagination] = useState({
        limit: PAGINATION_DEFAULT.limit,
        page: PAGINATION_DEFAULT.page,
        statusFilter: PAGINATION_DEFAULT.statusFilter,
        search: '',
    });

    const [showTrainedEmployee, setShowTrainedEmployee] = useState(false);
    const [trainedEmployeeData, setTrainedEmployeeData] = useState(null);

    const { data: trainings } = useGetCabinetTrainings(
        employeeId,
        pagination?.limit,
        pagination?.page,
        pagination?.statusFilter,
        pagination?.search,
    );


    const tableConfig = {
        headers: [
        'Training Name',
        'Trainer',
        'Start Date',
        'End Date',
        'Type',
        'Location'

        ],
        keys: ['training_name', 'trainer_name', 'training_start', 'training_end','training_type', 'training_location'],
        mobileHeader: {
        left: {
            title: 'Training Name',
            key: 'training_name',
        },
        right: {
            title: 'Mobile',
            key: 'mobile',
        },
        },
    };

    const actionConfig = [
        {
            name: 'View',
            color: 'green',
            action: (id) => {
                let index = trainings.data.findIndex((item) => item.id === id);
                setTrainedEmployeeData(trainings.data[index])
                setShowTrainedEmployee(true)
            }
        },
    ];

    return (
        <div className='flex flex-col'>
            {showTrainedEmployee ? (
                <TrainedEmployee
                    isOpen={showTrainedEmployee}
                    trainedEmployeeData={trainedEmployeeData}
                    closeModal={() => setShowTrainedEmployee(false)}
                />
            ) : null}
            <div className='mt-9'>
                <div className='flex md:flex-row gap-4 flex-wrap-reverse'>
                    <div className='w-[100%]'>
                    <DataTable
                        data={trainings}
                        tableConfig={tableConfig}
                        updatePagination={(data) => setPagination(data)}
                        filterByStatus={false}
                        actionConfig={actionConfig}
                        computedActions={true} 
                        layoutSwitch={false}
                        noCheck={true}
                    />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainingFolder;