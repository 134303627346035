import React, { useEffect } from "react";
import DashboardLayout from "../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import LoadingBar from "../../components/loader-bar";
import { Initials } from "../../utils/functions";
import { StatusText } from "../../components/fragments/status-text";
import { TicketStatusConfig } from "../../utils/constants";
import { useGetSupportTicket } from "../../redux/contact-us/hook";
import { TicketMessages } from "../../components/modal/contact-us/ticket-comments";
import moment from "moment";

const ViewTicketPage = () => {
  useEffect(() => {}, []);

  const { id } = useParams();

  const { data: ticket, isLoading } = useGetSupportTicket(id);

  return (
    <DashboardLayout title="View Support Ticket">
      '
      <div className="flex flex-col gap-4 -mt-8">
        <div className="flex flex-row items-center box-shadow h-[104px]">
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-row px-[55px] justify-between  w-full">
              <div className="flex flex-row items-center">
                <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200">
                  {Initials(ticket?.subject)}
                </div>
                <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                  <span className="p1-bold">{ticket?.subject}</span>
                  {/* <span className='p4 text-color-gray'>
                            {query?.email}
                        </span> */}
                </div>
              </div>
              {ticket ? (
                <div className="flex flex-row items-center">
                  <StatusText
                    statusConfig={TicketStatusConfig}
                    code={ticket.status}
                    className={"py-2 rounded p1-bold px-3 p2-bold text-[13px]"}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="flex flex-col bg-white min-h-fit rounded">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] mt-3">
            <div className="p3-bold header-4">Support Ticket Details</div>
          </div>
          <hr className="divider" />
          <div className="flex flex-col md:flex-row h-full p-4 pt-1 gap-4">
            <div className="flex flex-col gap-2 w-[100%] md:w-[50%]">
              <div className="border border-color-line pr-4 pl-4 pt-4 pb-2 flex flex-col rounded-md">
                {ticket ? (
                  <>
                    <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full p4">
                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">
                          Subject
                        </span>
                        <span className="mt-1 text-color-black font-bold">
                          {ticket?.subject}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="header-5 text-color-gray">
                          Date Added
                        </span>
                        <span className="mt-1 text-color-black font-bold">
                          {moment(ticket?.created).format("MMMM D, YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-1  grid-cols-1 gap-4 w-full p4 mt-[20px]">
                      <div className="border-1 p-3 !max-h-[600px] rounded-md overflow-auto">
                        <span className="header-5 text-color-gray mb-2">
                          Complaint
                        </span>
                        <td
                          dangerouslySetInnerHTML={{ __html: ticket?.message }}
                        />
                      </div>
                    </div>
                    {ticket?.upload_file ? (
                      <>
                        <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full mb-2 mt-3 border rounded p-2 bg-blue-50">
                          <div className="flex flex-col items-start gap-1">
                            <span className="header-5 text-color-gray">
                              Uploaded File:
                            </span>
                            <div className="flex flex-row items-center">
                              <span className="text-[14px] text-color-black font-extrabold">
                                {ticket.filename} -&nbsp;
                              </span>
                              <a
                                className="italic  text-[14px] hover:underline"
                                href={ticket.file_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : (
                  <LoadingBar loading={isLoading} />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2 w-[100%] md:w-[50%]">
              <div className="w-[100%] h-fit">
                <TicketMessages ticket_status={ticket?.status} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewTicketPage;
