import React from "react";
import { useHistory } from "react-router-dom";
import noSubSVG from "../../../../assets/svg/subscription.svg";
import Button from "../../../button";
import Modal from "../../modal";

function NoSubscriptionModal({ isOpen, setShowNoSubModal }) {
  const history = useHistory();
  const btnFunction = () => {
    setShowNoSubModal(false);
    history.push("/settings/plans-billings?select-plan=true");
  };
  return (
    <Modal
      scrollable={true}
      className="!max-w-[500px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="p-4 mb-8 overflow-auto">
        <div className="flex flex-col overflow-auto justify-center">
          <div className="flex flex-col md:mx-8 items-center justify-center">
            <div
              className={`flex items-center justify-center w-[110px] h-[110px] rounded-[130px] bg-[#D34829]`}
            >
              <img
                src={noSubSVG}
                alt="no_subscription"
                style={{ width: "60px", height: "60px" }}
              />
            </div>
            <span className="my-4 header-3 text-center">
              You do not have an active People subscription. Click the button
              below to choose a subscription plan.
            </span>
            {/* <span className="my-2 p3 text-center">
              
            </span> */}
            <div className=" flex flex-row w-full mt-[20px] gap-4">
              <Button
                text="Select Plan"
                type="submit"
                onClick={() => btnFunction()}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NoSubscriptionModal;
