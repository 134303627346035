import DOMPurify from "dompurify";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EmptyTableSVG from "../../../assets/svg/empty-table.svg";
import Logo from "../../../components/logo";
import SingleEmployeeExitLayout from "../../../components/layouts/employee-exit";
import FormTextArea from "../../../components/form-textarea";
import { Initials } from "../../../utils/functions";
import { StatusText } from "../../../components/fragments/status-text";
import { ExitEmployeeStatusConfig } from "../../../utils/constants";
import Button from "../../../components/button";
import {
  useEmployeeMarkExitComplete,
  useSaveAnswer,
} from "../../../redux/employees/hook/exit";
import { setAlert } from "../../../redux/components/components-slice";
import { store } from "../../../redux";
import { setEmployeeExit } from "../../../redux/employees/reducer";

function ExitQuestions() {
  useEffect(() => {}, []);

  const { employeeExit } = useSelector((state) => state.employeeSlice);
  const dispatch = useDispatch();

  const [result, setResult] = useState([]);

  const { token } = useParams();

  const { mutateAsync: saveAnswer, isLoading: saveAnswerloading } =
    useSaveAnswer();
  const { mutateAsync: markExit, isLoading: markExitloading } =
    useEmployeeMarkExitComplete();
  const history = useHistory();

  useEffect(() => {
    if (employeeExit && employeeExit.questions) {
      let newResults = [];
      employeeExit.questions.forEach((item) => {
        let newResult = {
          question_id: item.id,
          question: item.question,
          answer: "",
          question_type: item.question_type,
          options: item.options,
        };
        newResults.push(newResult);
      });
      setResult(newResults);
    }
  }, [employeeExit]);

  const markemployeeExit = async () => {
    let payload = {
      employee_id: employeeExit?.employee_id,
      token: token,
      onboarding_id: employeeExit?.id,
      employee_status: employeeExit?.employee_status == 1 ? 0 : 1,
    };
    await markExit(payload).then((result) => {
      let newArray = JSON.parse(JSON.stringify(employeeExit));

      newArray.answers = result;
      store.dispatch(setEmployeeExit(newArray));
    });
  };

  const submitForm = async () => {
    if (result && result.length > 0) {
      let missedQuestions = [];
      result.forEach((item, index) => {
        if (item.answer == "" || item.answer == null) {
          missedQuestions.push(index + 1);
        }
      });
      if (missedQuestions.length < 1) {
        let payload = {
          employee_id: employeeExit?.employee_id,
          token: token,
          exit_id: employeeExit?.id,
          answers: JSON.stringify(result),
        };
        await saveAnswer(payload).then(() => {
          if (result.status == "success") {
            if (result?.data?.workflow) {
              history.push("/employees/exit/verified/stages/" + token);
            } else {
              store.dispatch(
                setAlert(
                  true,
                  "success",
                  "No Question of workflow added. Exit completed."
                )
              );
            }
          }
        });
      } else {
        dispatch(
          setAlert(
            true,
            "info",
            "You have to answer all questions. No answers found for question (" +
              missedQuestions.join(", ") +
              ")"
          )
        );
      }
    }
  };

  return (
    <SingleEmployeeExitLayout
      step="2"
      company_name={employeeExit?.company_name}
      logo={employeeExit?.logo}
    >
      <div className="flex flex-col justify-between w-full pt-2 !min-h-screen overflow-auto p-4">
        <div>
          {employeeExit && employeeExit.status == 0 ? (
            <div className="flex flex-col gap-4">
              <div className="flex flex-row items-center box-shadow h-[104px] w-full mt-4 border border-gray-300 rounded">
                <div className="flex flex-row md:px-[55px] p-4 justify-between items-center w-full ">
                  <div className="flex flex-row items-center">
                    <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200">
                      {Initials(
                        employeeExit?.first_name,
                        employeeExit?.last_name
                      )}
                    </div>
                    <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                      <span className="p1-bold">
                        {employeeExit?.first_name} {employeeExit?.last_name}
                      </span>
                      <span className="p4 text-color-gray">
                        {employeeExit?.email}
                      </span>
                    </div>
                  </div>
                  {employeeExit?.employee_status == 1 ? (
                    <div className="flex flex-col gap-1 items-center">
                      <StatusText
                        statusConfig={ExitEmployeeStatusConfig}
                        code={employeeExit?.employee_status}
                        className={
                          "py-2 rounded p1-bold px-3 p2-bold text-[15px]"
                        }
                      />
                      <span className="p4 text-color-gray">
                        {employeeExit?.status == 1
                          ? "Reviewed By Admin"
                          : "Not Reviewed By Admin"}
                      </span>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center justify-end">
                      <Button
                        text="Mark Exit Complete"
                        type="submit"
                        className="!max-h-[35px] px-3"
                        leftIcon={"check"}
                        textClass="!text-[12px] !text-hr-primary-1"
                        onClick={() => markemployeeExit()}
                        loading={markExitloading}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className=" flex flex-col text-justify">
                <span className="header-4">Instruction: </span>
                <div className="text-[14px] text-gray-500 text-justify">
                  Complete the exit questions as your company has requested.
                </div>
              </div>
              {result && employeeExit.answers.length < 1 ? (
                <div className="flex flex-col items-center justify-center gap-1 border p-4 border-gray-400 rounded bg-white">
                  {result?.map((item, index) => (
                    <div className="flex flex-col w-[100%]" key={index}>
                      <div className="flex flex-col mb-2">
                        <span className="header-4">Question {index + 1}</span>
                        <span className="p4-medium">{item.question}</span>
                      </div>
                      {item.question_type === "multiple_choice" ? (
                        <div className="flex flex-col !min-w-[100%]">
                          <span className="header-5 mb-2 underline ">
                            Options:
                          </span>
                          {item?.options?.map((optionItem, optionIndex) => (
                            <div
                              className={
                                optionItem.selected
                                  ? "flex flex-row items-center mb-2 p-2 bg-secondary-2  cursor-pointer duration-500 border-1 rounded"
                                  : "flex flex-row items-center cursor-pointer duration-500 border-1 rounded mb-2 p-2 hover:bg-gray-200"
                              }
                              onClick={() => {
                                let newArray = JSON.parse(
                                  JSON.stringify(result)
                                );
                                newArray[index].options.forEach((item) => {
                                  item.selected = false;
                                });
                                newArray[index].options[
                                  optionIndex
                                ].selected = true;
                                newArray[index].answer =
                                  newArray[index].options[
                                    optionIndex
                                  ].option_index;
                                setResult(newArray);
                              }}
                            >
                              <div
                                className={
                                  optionItem.selected
                                    ? "header-5 pr-2 !min-w-[20%] !text-white duration-500"
                                    : "header-5 pr-2 !min-w-[20%] duration-500"
                                }
                              >
                                Option {optionIndex + 1}:
                              </div>
                              <div
                                className={
                                  optionItem.selected
                                    ? "header-5 pr-2 !min-w-[20%] !text-white duration-500"
                                    : "header-5 pr-2 !min-w-[20%] duration-500"
                                }
                              >
                                {optionItem.option}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          <FormTextArea
                            label={`Answer`}
                            defaultValue={item.answer}
                            onInput={(e) => {
                              let newArray = JSON.parse(JSON.stringify(result));

                              newArray[index].answer = e.target.value;
                              setResult(newArray);
                            }}
                            name={`answer_${index + 1}`}
                            type="text"
                          />
                        </div>
                      )}
                      <hr className="divider" />
                    </div>
                  ))}
                  <Button
                    text="Submit"
                    type="button"
                    className={"w-[130px] !h-[35px] !bg-hr-primary-1"}
                    textClass={"!text-[11px]"}
                    loading={saveAnswerloading}
                    // disabled={!firstName || !lastName || !mobile || !email || !educationLevel || !yearsOfExperience || expired}
                    onClick={() => {
                      submitForm();
                    }}
                  />
                </div>
              ) : null}
            </div>
          ) : !employeeExit ? (
            <div className="flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-[200px]">
              <img
                src={EmptyTableSVG}
                alt="no_data_fetched"
                width={"80%"}
                height={"200px"}
              />
              <span className="header-4 mt-3">You Are not verified</span>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-[200px]">
              <img
                src={EmptyTableSVG}
                alt="no_data_fetched"
                width={"80%"}
                height={"200px"}
              />
              <span className="header-4 mt-3">
                Onboarding has already been reviewed. You can no longer update.
              </span>
            </div>
          )}
        </div>
        <div
          className={
            "md:hidden flex items-center justify-center text-color-black gap-2"
          }
        >
          Powered By:{" "}
          <a href="https://www.ropay.africa" target={"_blank"} rel="noreferrer">
            {<Logo width="80px" height="40px" blue />}
          </a>
        </div>
      </div>
    </SingleEmployeeExitLayout>
  );
}

export default ExitQuestions;
