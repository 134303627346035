import React from "react";

export const AttendanceCard = ({ attendanceData }) => {
  return (
    <div
      className={`h-[120px] p-3  pt-2 flex flex-col  gap-2 justify-start bg-white rounded-[5px]`}
    >
      <div className="flex-col">
        <span className="font-medium text-[15px] text-color-black flex flex-col">
          {attendanceData.title}
        </span>
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col border-r-2 items-center justify-center pr-3 w-[80px] mr-3">
          <div className="flex items-center justify-center w-[50px] h-[50px] text-center bg-[#0d5225] text-color-white rounded-full font-bold">
            {attendanceData.date}
          </div>
          <div className="text-center text-[13px]">{attendanceData.day}</div>
        </div>
        <div className="flex flex-col items-center justify-center pr-3 w-[100px]">
          <div className="text-center text-[13px]">
            {attendanceData.present.value}
          </div>
          <div className="flex items-center text-[14px] justify-center text-center text-green-600">
            {attendanceData.present.name}
          </div>
        </div>
        <div className="flex flex-col items-center text-[14px] justify-center pr-3 w-[100px]">
          <div className="text-center text-[13px]">
            {attendanceData.late.value}
          </div>
          <div className="flex items-center justify-center text-center text-yellow-600">
            {attendanceData.late.name}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center pr-3 w-[100px]">
          <div className="text-center text-[13px]">
            {attendanceData.absent.value}
          </div>
          <div className="flex items-center text-[14px] justify-center text-center text-red-600">
            {attendanceData.absent.name}
          </div>
        </div>
      </div>
    </div>
  );
};
