import React from 'react';
import Logo from '../logo';
import MobileLogo from "../../assets/svg/mobile-waitlist.svg"

const WaitlistLayout = ({ children }) => {

    return (
        <div className='flex flex-row' >
        <div className='hidden md:flex md:w-[45%] position-fixed'>
            <div
            className='hidden md:flex h-screen inset-0 w-full bg-white justify-center items-center max-h-screen'
            >
            <div className='flex justify-center align-center flex-col w-[80%] max-w-[400px]'>
                <div className='mb-4'>
                    <Logo />
                </div>
                <div className='w-full text-center flex flex-col pb-[25px]'>
                    <span className='header-3-regular md:header-2 text-black'>
                        Ropay Mobile App
                    </span>
                    <span className='text-[15px] text-color-black pt-1'>
                        Coming Soon! 
                    </span>
                </div>
                <div>
                    <img 
                        src={MobileLogo}
                        alt='Mobile logo'
                    />
                </div>
            </div>
            </div>
        </div>
        {/* Page Components */}
        <div
            className='md:w-[100%] w-[100%] flex items-center 
            justify-center
            min-h-screen
            md:ml-[45%]
            pb-4
            pt-4'
        >
            <div className='w-[78%] max-w-[480px]'>
                <div className='flex flex-col'>
                    <div className='md:hidden flex justify-center'>
                    <Logo width={'170px'} height={'76px'} />
                    </div>
                    {children}
                </div>
            </div>
        </div>
        </div>
    );
};

export default WaitlistLayout;
