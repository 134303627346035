import { Axios } from '../axios';

const getEmployeeOvertime = async (payload) => {

  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(
    `/overtime?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const addOvertime = async (payload) => {
  const { data } = await Axios.post('/overtime', payload);
  return data;
};

const editOvertime = async (payload) => {
  const { data } = await Axios.put('/overtime/'+payload.id, payload.body);
  return data;
};

const bulkAddOvertime = async (payload) => {
  const { data } = await Axios.post('/overtime/bulk', payload, 
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const deleteOvertime = async (payload) => {
  const { data } = await Axios.delete('overtime/'+payload);
  return data;
};

const getSingleEmployeeOvertime = async (payload) => {
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(
    `/overtime/employee/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}`,
  );
  return data?.data;
};

const getOvertimeReport = async (payload) => {
  const { data } = await Axios.get(`/overtime/report/${payload.start_date}/${payload.end_date}`);
  return data?.data;
};

const getOvertimeApplications = async (payload) => {

  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/overtime-applicaions?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};
//New APIs
const getSingleEmployeeOvertimeApplications = async (payload) => {
  const { data } = await Axios.get(
    `/overtime-applicaions/by-employee/${payload.id}?limit=${payload.limit}&page=${payload.page}`,
  );

  return data?.data;
};

const approveOvertime = async (payload) => {
  const { data } = await Axios.post(`/overtime-applicaions/approve`, payload);
  return data;
};

const declineOvertimeApplication = async (payload) => {
  const { data } = await Axios.get('/overtime-applicaions/decline/'+payload);
  return data;
};

export const employeeOvertime = {
    getEmployeeOvertime,
    addOvertime,
    editOvertime,
    bulkAddOvertime,
    deleteOvertime,
    getSingleEmployeeOvertime,
    getOvertimeReport,

    getOvertimeApplications,
    getSingleEmployeeOvertimeApplications,
    approveOvertime,
    declineOvertimeApplication,
};
