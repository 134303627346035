import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../../button';
import AddSmallSVG from '../../../../assets/svg/add-icon-small.svg';
import FormTextArea from '../../../form-textarea';
import { useAddProbationComment, useDeleteProbationComment, useEditProbationComment, useGetProbationComments } from '../../../../redux/employees/hook/probation';

export const ProbationComments = ({probation}) => {

    const {
        formState: { isValid },
        errors,
        } = useForm({
        mode: 'onChange',
    });

    const { id } = useParams();

    const [type, setType] = useState('add');
    const [commentId, setCommentId] = useState();

    const { data: probation_comments} = useGetProbationComments(id);
    const { user } = useSelector((state) => state.userSlice);

    const { mutateAsync: addProbationComment, isLoading: addProbationCommentloading } = useAddProbationComment();

    const { mutateAsync: editProbationComment, isLoading: editProbationCommentloading } = useEditProbationComment();
    const { mutateAsync: deleteProbationComment } = useDeleteProbationComment();

    const [comment, setComment] = useState()

    const [addComment, setAddComment] = useState(false);

    useEffect(() => {
        if (probation_comments) {
            setType('edit')
            setProbationComments(probation_comments)
        } else {
            setType('add') 
        }
    }, [probation_comments])

    const [probationComments, setProbationComments] = useState([])

    const submitForm = async () => {
        let payload = {
            comment,
            probation_id: id
        }
        if (type === 'add') {
            await addProbationComment(payload).then(()=>{
                setCommentId('')
                setType('add')
                setComment('')
                setAddComment(false)
            })
        } else {
            let editPayload = {
                id: commentId,
                data: payload,
            }
            await editProbationComment(editPayload).then(()=>{
                setCommentId('')
                setType('add')
                setComment('')
                setAddComment(false)
            })
        }
       
    }
    return (
        <div
        className={`h-[120px]flex flex-col  gap-2 justify-start bg-white rounded-[5px] border border-[#dbdce3]`}
        >
            <div className='flex flex-row justify-between items-center bg-blue-100 p-3'>
                <div className='font-medium text-[14px] text-color-black flex flex-col'>
                    Probation Comments
                </div>
            </div>
            {addComment?
            <form className='form p-8 pt-2'>
                <div className='flex flex-col md:flex-row justify-between gap-3 text-[13px]'>
                    <div className='w-full'>
                        <FormTextArea
                            label='Note'
                            name='comment'
                            value={comment}
                            placeholder={'Enter Review Comment'}
                            onInput={(e) => {
                                setComment(e.target.value)
                            }}
                            className='text-[14px]'
                            type='text'
                            error={errors.comment}
                            errorMessage={errors.comment && errors.comment.message}
                        />
                    </div>
                </div>
                <div className='flex md:flex-row justify-end mt-2 gap-4'>
                    <Button
                        text='Cancel' 
                        type='button' 
                        onClick={() => {
                            setCommentId('')
                            setType('add')
                            setComment('')
                            setAddComment(false)
                        }}
                        textClass={'!text-[11px]'}
                        className={'h-[35px] w-[100px]'}
                        theme={'third'} 
                    />
                    <Button
                        text={type==='add'?'Add': 'Update'}
                        type='button' 
                        disabled={!comment || !id}
                        onClick={() => {
                            submitForm()
                        }}
                        textClass={'!text-[11px]'}
                        className={'h-[35px] w-[100px]'}
                        loading={addProbationCommentloading || editProbationCommentloading} 
                    />
                </div>
            </form>:
            <>
                {probationComments && probationComments.length > 0?
                
                <div className='flex flex-col pt-3 !max-h-[250px] overflow-y-auto'>
                    {probation?.status !== 1 && probation?.status !== 2?
                    <div className='flex flex-row justify-end items-center gap-1 text-right pr-8 italic text-hr-primary-1 text-[14px] mb-2'>
                        <img src={AddSmallSVG} alt={'add_note'} className={'h-[15px] w-[15px]'} />
                        <span 
                            className='italic text-hr-primary-1 text-[13px] hover:underline hover:text-[14px] duration-300 cursor-pointer font-bold' 
                            onClick={()=> {
                                setCommentId('')
                                setType('add')
                                setComment('')
                                setAddComment(true)
                            }}
                        >
                            Add Comment
                        </span>
                    </div>
                    :null}
                    {probationComments?.map((item,index) => (
                        <div>
                            <div className='flex md:flex-row  items-end pl-8 pr-8 pb-3 justify-between'>
                                <div className='flex flex-col  text-gray-500'>
                                    <div className='text-[11px] text-gray-500'>{item.created.split('T').join(' ').split('.')[0]}</div>
                                    <div className='header-5 text-gray-700 pb-1'>{item.user_id === user?.id ? 'You': item.user} added a comment</div>
                                    <div className='text-[12px] text-gray-600'>{item.comment}</div>
                                    
                                </div>
                                {probation?.status !== 1 && probation?.status !== 2?
                                <>
                                    {item.user_id === user?.id?
                                    <div className='flex flex-row gap-2 text-[13px] italic'>
                                        <div 
                                            className='text-hr-primary-1 hover:underline cursor-pointer duration-300'
                                            onClick={() => {
                                                setCommentId(item.id)
                                                setType('edit')
                                                setComment(item.comment)
                                                setAddComment(true)
                                            }}
                                        >
                                            Update
                                        </div>
                                        <div 
                                            className='text-rose-600 hover:underline cursor-pointer duration-300'
                                            onClick={() => {
                                                deleteProbationComment(item.id)
                                            }}
                                        >
                                            Delete
                                        </div>
                                    </div>
                                    :null}
                                </>: null}
                            </div>
                            {index !== probationComments.length - 1?
                            <hr className='divider -mt-3 mb-1' />
                            :
                            null
                            }
                        </div>
                    ))}
                </div>
                :
                <>
                {probation?.status !== 1 && probation?.status !== 2?
                <div className='text-center w-[100%] p-3 italic text-hr-primary-1 text-[14px]'>
                    <span 
                    className='text-center w-[100%] p-3 italic text-hr-primary-1 text-[14px] hover:underline hover:text-[16px] duration-300 cursor-pointer' 
                    onClick={()=> {
                        setCommentId('')
                        setType('add')
                        setComment('')
                        setAddComment(true)
                    }}
                    >
                        Add Comment
                    </span>
                </div>
                : 
                <div className='text-center w-[100%] p-3 italic text-hr-primary-1 text-[14px]'>
                    <span 
                        className='italic text-hr-primary-1 text-[13px] select-none font-bold' 
                    >
                        No Comments Added
                    </span>
                </div>}
                </>
                }
            </>}
        </div>
    );
};