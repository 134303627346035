import { Axios } from '../../axios';

const getPension = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(`/statutory-payments/pensions?limit=${payload.limit}&page=${payload.page}${search}`);
  return data?.data;
};

const bulkPension = async (payload) => {
  const { data } = await Axios.post('/statutory-payments/pensions/bulk', payload);
  return data;
};

const fetchPensionByRef = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(`/statutory-payments/pensions/by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}${search}`);
  if (data?.data) {
    data.data.pensions.total_amount = data?.data.total_amount
    data.data.pensions.total_payable = data?.data.total_payable

    data.data.pensions.pension_remitted = data?.data.pension_remitted
    data.data.pensions.pension_balance = data?.data.pension_balance
    
    data.data.pensions.total_trans_cost = data?.data.total_fees
    data.data.pensions.employerContribution = data?.data.employerContribution
    data.data.pensions.employeeContribution = data?.data.employeeContribution
  }
  return data?.data.pensions;
};

const editPensionData = async (payload) => {
  const { data } = await Axios.put('/statutory-payments/pensions/' + payload.id, payload.data);
  return data?.data.pensions;
};

const disbursePension = async (payload) => {
  const { data } = await Axios.post(`/statutory-payments/pensions/pay`, payload,
  {
    timeout: 0
  });
  return data;
};

export const pensionApis = {
  getPension,
  bulkPension,
  fetchPensionByRef,
  editPensionData,
  disbursePension,
};