import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { setRecurringInvoice } from './reducer';
import { recurring_invoices } from '../../../api/spend/recurring-invoice';
import { formatNumber } from '../../../utils/functions';
import history from '../../../routes/history';


export function useGetRecurringInvoices(limit, page, statusFilter, search) {
  return useQuery(
    ['recurring-invoices', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return recurring_invoices.getRecurringInvoices({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((invoice) => {
          invoice.created = moment(invoice.created).format('MMMM D, YYYY');
          invoice.start_date = moment(invoice.start_date).format('MMMM D, YYYY');
          switch (invoice.status) {
            case 0:
              invoice.computedActions = [0, 1];
              break;
            case 1:
              invoice.computedActions = [0];
              break;
            case 2:
              invoice.computedActions = [0];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetRecurringInvoicesByCustomer(id, limit, page, statusFilter, search) {
  return useQuery(
    ['recurring-invoice-by-customer', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return recurring_invoices.getRecurringInvoicesByCustomer({ id, limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((invoice) => {
          invoice.created = moment(invoice.created).format('MMMM D, YYYY');
          invoice.start_date = moment(invoice.start_date).format('MMMM D, YYYY');
          switch (invoice.status) {
            case 0:
              invoice.computedActions = [0, 1];
              break;
            case 1:
              invoice.computedActions = [0];
              break;
            case 2:
              invoice.computedActions = [0];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetRecurringInvoice(id) {
  return useQuery(
    ['recurringInvoice', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return recurring_invoices.getRecurringInvoice(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setRecurringInvoice(response));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateRecurringInvoice() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return recurring_invoices.updateRecurringInvoice(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recurring-invoice');
        queryClient.invalidateQueries('recurring-invoices');
        queryClient.invalidateQueries('recurring-invoice-by-customer');
        store.dispatch(setAlert(true, 'success',  'Recurring invoice updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCreateSingleRecurringInvoice() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return recurring_invoices.createSingleRecurringInvoice(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setRecurringInvoice(response.data));
        queryClient.invalidateQueries('recurring-invoice');
        queryClient.invalidateQueries('recurring-invoices');
        queryClient.invalidateQueries('recurring-invoice-by-customer');
        store.dispatch(setAlert(true, 'success',  'Recurring invoice added successfully'));
        history.push('/customers/invoice?templateId=' + response.data.id)
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMarkRecurringInvoice() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return recurring_invoices.markRecurringInvoice(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recurring-invoice');
        queryClient.invalidateQueries('recurring-invoices');
        queryClient.invalidateQueries('recurring-invoice-by-customer');
        store.dispatch(setAlert(true, 'success', 'Recurring invoice status changed successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteRecurringInvoice() {
  return useMutation(
    (payload) => {
      return recurring_invoices.deleteRecurringInvoice(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('recurring-invoice');
        queryClient.invalidateQueries('recurring-invoices');
        queryClient.invalidateQueries('recurring-invoice-by-customer');
        store.dispatch(setAlert(true, 'success', 'Recurring invoice deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

