import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import {
  PAGINATION_DEFAULT,
  onboardingStatusConfig,
} from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "../button";
import {
  useDeleteOnboarding,
  useGetOnboardings,
  useMarkOnboardingComplete,
} from "../../redux/employees/hook/onboarding";
import AddEmployeeOnboarding from "../modal/hr-section/employee-onboarding/add-onboarding";
import { useGetEmployees } from "../../redux/employees/hook";
import SelectOnboardingOption from "../modal/hr-section/employee-onboarding/select-onboarding-option";

const OnboardingOverviewTab = () => {
  useEffect(() => {}, []);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    satusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const dispatch = useDispatch();

  const [tableType, setTableType] = useState("employee-onboarding");

  const [showAddOnboarding, setShowAddOnboarding] = useState(false);
  const [showAddOnboardingOptions, setShowAddOnboardingOptions] =
    useState(null);
  const [onboardingData, setOnboardingData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const switchFunction = () => {
    if (tableType === "employee-onboarding") {
      setTableType("table");
    } else {
      setTableType("employee-onboarding");
    }
  };

  const { mutateAsync: deleteOnboarding } = useDeleteOnboarding();

  const { mutateAsync: markOnboarding } = useMarkOnboardingComplete();

  const { data: onboardings } = useGetOnboardings(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const history = useHistory();

  const tableConfig = {
    headers: ["Role", "Job Type", "Location Type", "Application Close"],
    keys: ["role", "job_type", "location_type", "application_end"],
    mobileHeader: {
      left: {
        title: "Role",
        key: "role",
      },
      right: {
        title: "Application Close",
        key: "application_end",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = onboardings.data.findIndex((item) => item.id === id);
        history.push("/onboarding/view/" + onboardings.data[index].id);
      },
    },
    {
      name: "Mark As Reviewed",
      color: "green",
      action: async (id) => {
        let index = onboardings.data.findIndex((item) => item.id === id);
        await markOnboarding({
          onboarding_id: onboardings.data[index].id,
          status: onboardings.data[index].status == 1 ? 0 : 1,
        });
      },
    },
    {
      name: "Remove",
      color: "red",
      action: async (id) => {
        let index = onboardings.data.findIndex((item) => item.id === id);
        await deleteOnboarding(onboardings.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddOnboardingOptions ? (
        <SelectOnboardingOption
          isOpen={showAddOnboardingOptions}
          closeModal={() => setShowAddOnboardingOptions(false)}
        />
      ) : null}

      {showAddOnboarding ? (
        <AddEmployeeOnboarding
          isOpen={showAddOnboarding}
          closeModal={() => setShowAddOnboarding(false)}
          onboardingData={onboardingData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Employee Onboarding
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Onboarding"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              disabled={!employees}
              onClick={() => {
                setTitle("Add Employee To Onboarding");
                setButtonText("Save");
                setOnboardingData(null);
                setShowAddOnboardingOptions(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={onboardings}
        tableConfig={tableConfig}
        statusConfig={onboardingStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        computedActions={true}
        tableType={tableType}
        switchFunction={switchFunction}
        layoutSwitch={true}
        searchData={true}
        noCheck={true}
      />
    </div>
  );
};

export default OnboardingOverviewTab;
