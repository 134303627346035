import { useMutation, useQuery } from '@tanstack/react-query';
import { bank } from '../../api';
// import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetBanks() {
  return useQuery(
    ['banks'],
    () => {
      //   store.dispatch(setIsLoading(true));
      return bank.getBanks();
    },
    {
      select: (data) => {
        let newData = [];

        data?.data?.forEach((item) => {
          newData.push({ value: item.name, label: item.name });
        });

        return newData;
      },
      cacheTime: 432000000, //5 days
      staleTime: 432000000, //5 days
    },
  );
}

export function useResolveBankAccount() {
  return useMutation((payload) => {
    //      store.dispatch(setIsLoading(true));
    return bank.resolveBankAccount(payload);
  });
}


export function useGetPFA() {
  return useQuery(
    ['pfa'],
    () => {
      return bank.getPFAs();
    },
    {
      select: (data) => {
        let newData = [];

        newData.push({ value: '', label: 'No PFA Selected' });
        data?.forEach((item) => {
          newData.push({ value: item, label: item });
        });

        return newData;
      },
      cacheTime: 432000000, //5 days
      staleTime: 432000000, //5 days
    },
  );
}
