import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../../components/datatable';
import { PAGINATION_DEFAULT, performanceSatusConfig } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { useGetCabinetPerformanceAppraisals } from '../../redux/employee-folder/hook';

const PerformanceFolder = ({employeeId}) => {

    const { user } = useSelector((state) => state.userSlice);

    const [pagination, setPagination] = useState({
        limit: PAGINATION_DEFAULT.limit,
        page: PAGINATION_DEFAULT.page,
        statusFilter: PAGINATION_DEFAULT.statusFilter,
        search: '',
    });

    const { data: performances } = useGetCabinetPerformanceAppraisals(
        employeeId,
        pagination?.limit,
        pagination?.page,
        pagination?.statusFilter,
        pagination?.search,
    );


    const tableConfig = {
        headers: [
          'Date Created',
          'Employee Name',
          'Mobile',
          'Period Covered',
        ],
        keys: ['created', 'employeeName', 'mobile', 'period'],
        mobileHeader: {
          left: {
            title: 'Employee Name',
            key: 'employeeName',
          },
          right: {
            title: 'Period Covered',
            key: 'period',
          },
        },
    };

    return (
        <div className='flex flex-col'>
            <div className='mt-9'>
                <div className='flex md:flex-row gap-4 flex-wrap-reverse'>
                    <div className='w-[100%]'>
                        <DataTable
                        data={performances}
                        tableConfig={tableConfig}
                        statusConfig={performanceSatusConfig}
                        noCheck={true}
                        updatePagination={(data) => setPagination(data)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PerformanceFolder;