import React, { useEffect, useRef, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormSelect from '../../../form-select';
import { useMarkApplicantReviewed } from '../../../../redux/human-resources/hook/recruitment';
import { useParams } from 'react-router-dom';
import FormSwitch from '../../../form-switch';
import { useGetOnboardingRecruitmentEmails } from '../../../../redux/email-templates/hook/recruitment';
import AddSmallSVG from '../../../../assets/svg/add-icon-small.svg';
import AddRecruitmentEmailTemplate from './add-recruitment-email-template';
import Modal from '../../modal';

function ReviewStatus({ isOpen, closeModal, applicant, title, buttonText }) {
  const [applicantId, setApplicantId] = useState();
  const [status, setStatus] = useState();

  const [emailId, setEmailId] = useState();

  const [sendEmails, setSendEmails] = useState(false);

  const [showAddEmailTemplate, setShowAddEmailTemplate] = useState(false);

  const statusTypes = [
    { label: 'Not Reviewed', value: 0 },
    { label: 'Accepted', value: 1 },
    { label: 'Rejected', value: 2 },
    { label: 'Pending Acceptance', value: 3 },
    { label: 'Auto Filtered', value: 4 },
  ];

  useEffect(() => {
    if (applicant) {
      setStatus(applicant.review_status);
      setApplicantId(applicant.id);
    }
  }, [applicant]);

  const { data: recruitmentEmails } = useGetOnboardingRecruitmentEmails();

  const { mutateAsync: markReviewed, isLoading } = useMarkApplicantReviewed();

  const submitForm = async () => {
    let payload = {
      application_id: applicantId,
      review_status: status,
      send_email: sendEmails ? 1 : 0,
      email_template_id: emailId,
    };
    await markReviewed(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        {showAddEmailTemplate ? (
          <AddRecruitmentEmailTemplate
            isOpen={showAddEmailTemplate}
            closeModal={() => setShowAddEmailTemplate(false)}
            templateData={null}
            buttonText={'Save'}
            title='Update Email Template'
          />
        ) : null}
        <form className='form pb-2'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
            <span className='p3 text-[12px] text-gray-500'>
              Review Applicant Status
            </span>
          </div>
          <hr className='divider' />
          <div className='flex flex-col p-4 pt-2'>
            <div>
              <FormSelect
                options={statusTypes}
                value={status}
                readOnly={status ? false : true}
                onChange={(selected) => {
                  if (selected === 0 || selected === 3) {
                    setSendEmails(false);
                    setEmailId();
                  }
                  setStatus(selected);
                }}
                label='Select Status'
              />
              {status != 3 && status != 0 ? (
                <>
                  <div className='mt-3'>
                    <FormSwitch
                      label={'Do you wish to send an email?'}
                      name={'switch-send-mode'}
                      checked={sendEmails}
                      disabled={recruitmentEmails ? false : true}
                      onClick={() => setSendEmails(!sendEmails)}
                    />
                    {sendEmails && recruitmentEmails ? (
                      <FormSelect
                        value={emailId}
                        options={recruitmentEmails}
                        readOnly={recruitmentEmails ? false : true}
                        onChange={(selected) => {
                          setEmailId(selected);
                        }}
                        label='Select Email Template'
                      />
                    ) : (
                      <div className='p4-medium text-color-gray'>
                        No Email Template Found. Click link below to add a new
                        Email Template.
                      </div>
                    )}
                  </div>
                </>
              ) : null}
              <hr className='divider' />
              <div className='flex flex-row items-center gap-2 justify-left w-full cursor-pointer'>
                <img
                  src={AddSmallSVG}
                  alt={'add email template'}
                  className={'h-[15px] w-[15px]'}
                />
                <span
                  className='p4-medium hover:underline hover:italic hover:font-bold text-primary-3 duration-75'
                  onClick={() => setShowAddEmailTemplate(true)}
                >
                  Add Email Template
                </span>
              </div>
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text={'Change Status'}
                theme={'third'}
                type='button'
                onClick={() => {
                  submitForm();
                }}
                loading={isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ReviewStatus;
