import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormFileInput from "../../../components/form-file-input";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import OnboardingDataTable from "../../../components/onboard-datatable";
import { useRouteQuery } from "../../../hooks/useRouteQuery";
import {
  useExportSalaryTemplate,
  useFullPayReverseBulk,
  useGrossPayReverseBulk,
  useNetPayReverseBulk,
} from "../../../redux/employees/hook";
import { useAddBulkSalary } from "../../../redux/employees/hook/salaries";
import { formValidation, formatNumber } from "../../../utils/functions";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import WarningSVG from "../../../assets/svg/warning.svg";

function AddBulkEmployeePay() {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const workbook = new Excel.Workbook();

  const netPayColumns = [
    { header: "Employee's First Name", key: "first_name" },
    { header: "Employee's Last Name", key: "last_name" },
    { header: "Phone Number", key: "mobile" },
    { header: "Net Pay (Number Only)", key: "net_pay" },
    { header: "Staff Number", key: "staff_number" },
    { header: "Status", key: "status" },
  ];

  const grossPayColumns = [
    { header: "Employee's First Name", key: "first_name" },
    { header: "Employee's Last Name", key: "last_name" },
    { header: "Phone Number", key: "mobile" },
    { header: "Gross Pay (Number Only)", key: "gross_pay" },
    { header: "Staff Number", key: "staff_number" },
    { header: "Status", key: "status" },
  ];

  const fullPayColumns = [
    { header: "Employee's First Name", key: "first_name" },
    { header: "Employee's Last Name", key: "last_name" },
    { header: "Phone Number", key: "mobile" },
    { header: "Basic Salary (Number Only)", key: "basic" },
    { header: "Housing Allowance (Number Only)", key: "housing" },
    { header: "Transport Allowance (Number Only)", key: "transport" },
    { header: "Leave Allowance (Number Only)", key: "leave" },
    { header: "Utility Allowance (Number Only)", key: "utility" },
    { header: "Lunch Allowance (Number Only)", key: "lunch" },
    { header: "Other Allowance (Number Only)", key: "others" },
    { header: "Net Pay (Number Only)", key: "net_pay" },
    { header: "Staff Number", key: "staff_number" },
    { header: "Status", key: "status" },
  ];

  useEffect(() => {}, []);

  const history = useHistory();
  const query = useRouteQuery();

  const [payType, setPayType] = useState("");
  const [fromSalary, setFromSalary] = useState(false);
  const [data, setData] = useState("");

  const { mutateAsync: netPayReverseBulk, isLoading: netLoading } =
    useNetPayReverseBulk();
  const { mutateAsync: grossPayReverseBulk, isLoading: grossLoading } =
    useGrossPayReverseBulk();
  const { mutateAsync: fullPayBulk, isLoading: fullLoading } =
    useFullPayReverseBulk();
  const { mutateAsync: saveBulkCompensation, isLoading: saveLoading } =
    useAddBulkSalary();

  const tableConfig = {
    title: "Compensation Breakdown",
    headers: [
      "Employee Name",
      "Basic",
      "Housing",
      "Transport",
      "Others",
      "Tax",
      "Pension",
      "Net Pay",
    ],
    keys: [
      "employeeName",
      "basic_salary",
      "housing_allowance",
      "transportation_allowance",
      "other_allowances",
      "tax",
      "employee_pension",
      "net_pay",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Net Pay",
        key: "net_pay",
      },
    },
  };

  const [breakdown, setBreakdown] = useState(false);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (query) {
      setPayType(query.get("type"));
      setFromSalary(query.get("salary"));
      setUpdate(query.get("update"));
    }
  }, [query]);

  const submitForm = async (data) => {
    const formData = new FormData();
    formData.append("file", data?.file[0]);

    if (payType === "net-pay") {
      formData.append("setting", "group");
      await netPayReverseBulk(formData).then((result) => {
        if (
          result.status === "success" &&
          result.message == "Compensation upload initiated"
        ) {
          history.push("/employees");
        } else if (result.status === "success") {
          setData(JSON.stringify(result.salary_breakdown));
          result.salary_breakdown.forEach((employee) => {
            employee.employeeName = `${employee.first_name} ${employee.last_name}`;
            employee.other_allowances =
              employee.benefit_in_kind +
              employee.leave_allowance +
              employee.lunch_allowance +
              employee.other_allowance +
              employee.utility_allowance;
            employee.other_allowances = formatNumber(
              employee.other_allowances,
              2
            );
            employee.basic_salary = formatNumber(employee.basic_salary, 2);
            employee.housing_allowance = formatNumber(
              employee.housing_allowance,
              2
            );
            employee.transportation_allowance = formatNumber(
              employee.transportation_allowance,
              2
            );
            employee.gross_pay = formatNumber(employee.gross_pay, 2);
            employee.tax = formatNumber(employee.tax, 2);
            employee.employee_pension = formatNumber(
              employee.employee_pension,
              2
            );
            employee.net_pay = formatNumber(employee.net_pay, 2);
          });
          setBreakdown({
            data: result.salary_breakdown,
          });
          setShowBreakdown(true);
        }
      });
    } else if (payType === "gross-pay") {
      formData.append("setting", "group");
      await grossPayReverseBulk(formData).then((result) => {
        if (
          result.status === "success" &&
          result.message == "Compensation upload initiated"
        ) {
          history.push("/employees");
        } else if (result.status === "success") {
          setData(JSON.stringify(result.salary_breakdown));
          result.salary_breakdown.forEach((employee) => {
            employee.employeeName = `${employee.first_name} ${employee.last_name}`;
            employee.other_allowances =
              employee.benefit_in_kind +
              employee.leave_allowance +
              employee.lunch_allowance +
              employee.other_allowance +
              employee.utility_allowance;
            employee.other_allowances = formatNumber(
              employee.other_allowances,
              2
            );
            employee.basic_salary = formatNumber(employee.basic_salary, 2);
            employee.housing_allowance = formatNumber(
              employee.housing_allowance,
              2
            );
            employee.transportation_allowance = formatNumber(
              employee.transportation_allowance,
              2
            );
            employee.gross_pay = formatNumber(employee.gross_pay, 2);
            employee.tax = formatNumber(employee.tax, 2);
            employee.employee_pension = formatNumber(
              employee.employee_pension,
              2
            );
            employee.net_pay = formatNumber(employee.net_pay, 2);
          });
          setBreakdown({
            data: result.salary_breakdown,
          });
          setShowBreakdown(true);
        }
      });
    } else {
      formData.append("setting", "group");
      await fullPayBulk(formData).then((result) => {
        if (result.status === "success") {
          history.push("/employees");
        }
      });
    }
  };

  const saveCompensation = async () => {
    let payload = {
      json_data: data,
    };
    await saveBulkCompensation(payload).then((result) => {
      if (result.status === "success") {
        history.push("/employees");
      }
    });
  };

  const [exportLoading, setSetExportLoading] = useState(false);

  const { mutateAsync: exportSalaryTemplate } = useExportSalaryTemplate();

  const exportTemplate = async () => {
    setSetExportLoading(true);
    await exportSalaryTemplate().then(async (response) => {
      let data = response?.data;
      const workSheetName = "active_employee_data";
      const workBookName = `Salary upload template (pre-filled)`;
      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns =
          payType == "net-pay"
            ? netPayColumns
            : payType == "gross-pay"
            ? grossPayColumns
            : fullPayColumns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column) => {
          if (column._number > 3) {
            column.width = column.header.length;
            column.style.alignment = { horizontal: "right" };
            column.numFmt = "0.00";
          } else {
            column.width = column.header.length + 2;
            column.style.alignment = { horizontal: "left" };
          }
        });

        if (payType == "net-pay") {
          data?.forEach((singleData) => {
            singleData.net_pay = "";
            if (singleData.status == 0) {
              singleData.status = "Inactive";
            } else if (singleData.status == 1) {
              singleData.status = "Active";
            } else if (singleData.status == 2) {
              singleData.status = "Pending";
            } else if (singleData.status == 3) {
              singleData.status = "Deleted";
            } else if (singleData.status == 2) {
              singleData.status = "Awaiting Activation";
            } else {
              singleData.status = "Pre-registered";
            }
            worksheet.addRow(singleData);
          });
        } else if (payType == "gross-pay") {
          data?.forEach((singleData) => {
            singleData.gross_pay = "";
            if (singleData.status == 0) {
              singleData.status = "Inactive";
            } else if (singleData.status == 1) {
              singleData.status = "Active";
            } else if (singleData.status == 2) {
              singleData.status = "Pending";
            } else if (singleData.status == 3) {
              singleData.status = "Deleted";
            } else if (singleData.status == 2) {
              singleData.status = "Awaiting Activation";
            } else {
              singleData.status = "Pre-registered";
            }
            worksheet.addRow(singleData);
          });
        } else {
          data?.forEach((singleData) => {
            singleData.basic = "";
            singleData.housing = "";
            singleData.transport = "";
            singleData.leave = "";
            singleData.utility = "";
            singleData.lunch = "";
            singleData.others = "";
            singleData.net_pay = "";
            if (singleData.status == 0) {
              singleData.status = "Inactive";
            } else if (singleData.status == 1) {
              singleData.status = "Active";
            } else if (singleData.status == 2) {
              singleData.status = "Pending";
            } else if (singleData.status == 3) {
              singleData.status = "Deleted";
            } else if (singleData.status == 2) {
              singleData.status = "Awaiting Activation";
            } else {
              singleData.status = "Pre-registered";
            }
            worksheet.addRow(singleData);
          });
        }

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: true }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;
            if (payType == "net-pay" || payType == "gross-pay") {
              if (
                singleCell._column._number == 6 &&
                singleCell._row._number > 1
              ) {
                if (singleCell._value.model.value == "Inactive") {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "D34829" },
                  };
                } else if (singleCell._value.model.value == "Active") {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "0d5225" },
                  };
                } else if (singleCell._value.model.value == "Pending") {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "FFA500" },
                  };
                } else if (singleCell._value.model.value == "Deleted") {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "D34829" },
                  };
                } else if (
                  singleCell._value.model.value == "Awaiting Activation"
                ) {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "FFA500" },
                  };
                } else {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "FFA500" },
                  };
                }
              }
            } else {
              if (
                singleCell._column._number == 13 &&
                singleCell._row._number > 1
              ) {
                if (singleCell._value.model.value == "Inactive") {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "D34829" },
                  };
                } else if (singleCell._value.model.value == "Active") {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "0d5225" },
                  };
                } else if (singleCell._value.model.value == "Pending") {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "FFA500" },
                  };
                } else if (singleCell._value.model.value == "Deleted") {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "D34829" },
                  };
                } else if (
                  singleCell._value.model.value == "Awaiting Activation"
                ) {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "FFA500" },
                  };
                } else {
                  worksheet.getCell(cellAddress).font = {
                    color: { argb: "FFA500" },
                  };
                }
              }
            }

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: "none" },
              left: { style: "none" },
              bottom: { style: "none" },
              right: { style: "none" },
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        // console.error('<<<ERRROR>>>', error);
        // console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  return (
    <EmployeeOnboardingLayout
      step={fromSalary ? 2 : 4}
      title={update ? "Update Compensation" : "Add Compensation"}
    >
      {showBreakdown ? (
        <div
          className={classNames("flex flex-col -mt-4", {
            hidden: !showBreakdown,
          })}
        >
          <OnboardingDataTable
            data={breakdown}
            tableConfig={tableConfig}
            noCheck={true}
          />
          <hr className="divider" />
          <div className="w-full mb-[20px] md:mb-[0px]">
            <Button
              text="Save and Complete"
              type="submit"
              loading={saveLoading}
              onClick={() => saveCompensation()}
            />
          </div>
          <div
            className="flex justify-center w-full cursor-pointer hover:underline hover:scale-110 duration-300"
            onClick={() => setShowBreakdown(false)}
          >
            <span className="p2-medium text-color-black mt-3">Go Back</span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full h-fit">
          <span className="header-4">
            {" "}
            {payType === "net-pay"
              ? "I know the Net Pay only"
              : payType === "gross-pay"
              ? "I know the Gross Pay only"
              : "I Know The Complete Compensation"}
          </span>
          <hr className="divider mt-2" />
          <form className="form " onSubmit={handleSubmit(submitForm)}>
            <div className="p-8 pt-4 border border-secondary-2-extralight rounded-md h-fit flex flex-col px-4">
              <div className="flex flex-col text-center justify-center items-center">
                <span className="text-[16px] font-extrabold">
                  Upload employees{" "}
                  {payType === "net-pay"
                    ? "Net Pay "
                    : payType === "gross-pay"
                    ? "Gross Pay "
                    : "Salaries "}
                  from XLSX file
                </span>
                <div className="border p-4 rounded-md bg-secondary-2-extralight mt-3 -mb-1">
                  <div className="mt-3">
                    <Button
                      text="Download template (Pre-filled)"
                      type="button"
                      theme="primary"
                      loading={exportLoading}
                      className="flex gap-2 !h-[35px] w-full p-2 "
                      textClass={"!text-[11px]"}
                      onClick={() => {
                        exportTemplate();
                      }}
                    />
                  </div>
                </div>
                <FormFileInput
                  multiSource={true}
                  inputRef={register(formValidation("file", true))}
                  accept=".xlsx"
                />
              </div>
              <div className="flex flex-row px-4 p-2 border-secondary-4 border-[1.2px] rounded-lg bg-color-warning-light mt-3">
                <div className="w-[100px] pr-2 flex flex-row items-center ">
                  <img src={WarningSVG} alt="" srcSet="" />
                </div>
                <div className=" flex flex-col text-[13px] text-justify gap-2">
                  <span className="mt-1 text-[12px]">
                    Please note that if your employee count is larger than{" "}
                    <span className="font-semibold">30</span>, compensation will
                    be added directly without showing you the breakdown,
                  </span>
                </div>
              </div>
            </div>

            <div className="w-full mt-[20px]">
              <Button
                text={
                  payType === "net-pay" || payType === "gross-pay"
                    ? "CONTINUE"
                    : "Save Compensation"
                }
                type="submit"
                loading={netLoading || grossLoading || fullLoading}
              />
            </div>
            <div
              className="flex justify-center w-full cursor-pointer hover:underline hover:scale-110 duration-300"
              onClick={() => history.goBack()}
            >
              <span className="p2-medium text-color-black mt-3 ">Go Back</span>
            </div>
          </form>
        </div>
      )}
    </EmployeeOnboardingLayout>
  );
}

export default AddBulkEmployeePay;
