import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { queries } from '../../../api/hr/query';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetQueries(limit, page, search) {
  return useQuery(
    ['queries', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return queries.getQueries({
        limit,
        page,
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((query) => {
          query.date_created = moment(query.date_created).format('MMMM D, YYYY');
          query.computedActions = [0,1,2]
          query.employeeName = `${query.first_name} ${query.last_name}`;
          query.status = query.status? query.status: 0
          switch (query.status) {
            case 0:
                query.computedActions = [0, 1, 2];
              break;
            case 1:
                query.computedActions = [0];
              break;
            case 2:
                query.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetQuery(id) {
    return useQuery(
        ['query', { id }],
        () => {
            return queries.getQuery({ id });
        },
        {
            enabled: id !== null,
            select(data) {
              data.status = data?.status? data.status: 0
              return data;
            },
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useAddQuery() {
  return useMutation(
    (payload) => {
      return queries.addQuery(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('queries');
        store.dispatch(setAlert(true, 'success', 'Query added successfully'));
      },
    },
  );
}

export function useEditQuery() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return queries.updateQuery(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('queries');
        store.dispatch(setAlert(true, 'success',  'Query updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteQuery() {
  return useMutation(
    (payload) => {
      return queries.deleteQuery(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('queries');
        store.dispatch(setAlert(true, 'success',  'Query deleted successfully'));
      },
    },
  );
}

export function useSingleGetEmployeeQueries(employee_id, limit, page, search) {
  return useQuery(
    ['single-employee-queries', { employee_id, limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return queries.getEmployeeQueries({ employee_id, limit, page, search });
    },
    {
      select(data) {
        data?.data?.forEach((query) => {
          query.date_created = query.created.split('T')[0]
          query.computedActions = [0,1,2]
          query.employeeName = `${query.first_name} ${query.last_name}`;
          query.status = query.status? query.status: 0
          switch (query.status) {
            case 0:
                query.computedActions = [0, 1, 2];
              break;
            case 1:
                query.computedActions = [0];
              break;
            case 2:
                query.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Employee queries fetched successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useChangeQueryStatus() {
  return useMutation(
      (payload) => {
      store.dispatch(setIsLoading(true));
      return queries.changeQueryStatus(payload);
      },
      {
      onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('queries');
          queryClient.invalidateQueries('query');
          store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
      },
      },
  );
}

export function useAddQueryNote() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return queries.addQueryNote(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('application-notes');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetQueryNotes(id) {
  return useQuery(
    ['query-notes', id],
    () => {
      store.dispatch(setIsLoading(true));
      return queries.getQueryNotesByQuery(id);
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.query_notes?.forEach((query) => {
          query.created = query.created.split('T').join(' ').split('.')[0]
        });
        data?.employee_notes?.forEach((query) => {
          query.created = query.created.split('T').join(' ').split('.')[0]
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditQueryNote() {
  return useMutation(
    (payload) => {
      return queries.updateQueryNote(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('query-notes');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteQueryNote() {
  return useMutation(
    (payload) => {
      return queries.deleteQueryNote(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('query-notes');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}
