import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useHistory } from "react-router-dom";
import DataTable from "../../../components/datatable";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import AddTeam from "../../../components/modal/hr-section/teams/add-team";
import {
  useDeleteTeam,
  useGetTeams,
} from "../../../redux/human-resources/hook/teams";
import AddEmployeeToTeam from "../../../components/modal/hr-section/teams/add-employee-to-team";
import UnauthorizedPage from "../../errors/unauthorized";
import { useSelector } from "react-redux";
import DoYouWantToModal from "../../../components/modal/do-you-want-to";
// import { CardHr } from '../../../components/modal/hr-section/dashboard/cardHR';
// import teamSVG from '../../../assets/svg/team-widget-icon.svg';

const HRTeamPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.team_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: teams } = useGetTeams(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const [showAddEmployeeToTeam, setShowAddEmployeeToTeam] = useState(false);

  // const { data: getTeamSummary } = useGetTeamSummary();

  const { mutateAsync: deleteTeam } = useDeleteTeam();

  const [tableType, setTableType] = useState("team");

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const switchFunction = () => {
    if (tableType === "team") {
      setTableType("table");
    } else {
      setTableType("team");
    }
  };

  const [showAddTeam, setShowAddTeam] = useState(false);
  const [teamData, setTeamData] = useState();

  const history = useHistory();

  const tableConfig = {
    headers: ["Name", "Members", "Lead"],
    keys: ["name", "employee_count", "team_manager"],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Team name",
        key: "name",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = teams.data.findIndex((item) => item.id === id);
        history.push("/team/view/" + teams.data[index].id);
      },
    },
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = teams.data.findIndex((item) => item.id === id);
        setTitle("Update Team");
        setButtonText("Update Team");
        setTeamData(teams.data[index]);
        setShowAddTeam(true);
      },
    },
    {
      name: "Add Employees",
      color: "orange",
      action: (id) => {
        let index = teams.data.findIndex((item) => item.id === id);
        setTeamData(teams.data[index]);
        setShowAddEmployeeToTeam(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = teams.data.findIndex((item) => item.id === id);
        setDeleteId(teams.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  const deleteSingleTeam = async (id) => {
    let index = teams.data.findIndex((item) => item.id === id);
    await deleteTeam(teams.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };
  // const [teamSummary, setTeamSummary] = useState()
  const [title, setTitle] = useState("Add Team");
  const [buttonText, setButtonText] = useState("ADD");

  return (
    <DashboardLayout title="Teams Management">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {showDoYouWant ? (
            <DoYouWantToModal
              isOpen={showDoYouWant}
              closeModal={() => setShowDoYouWant(false)}
              id={deleteId}
              title={"Are you sure you want to delete this team?"}
              buttonText={"DELETE TEAM"}
              btnFunction={deleteSingleTeam}
            />
          ) : null}
          {showAddEmployeeToTeam ? (
            <AddEmployeeToTeam
              isOpen={showAddEmployeeToTeam}
              teamData={teamData.id}
              closeModal={() => setShowAddEmployeeToTeam(false)}
            />
          ) : null}
          {showAddTeam ? (
            <AddTeam
              isOpen={showAddTeam}
              setShowAddTeam
              closeModal={() => setShowAddTeam(false)}
              teamData={teamData}
              title={title}
              buttonText={buttonText}
            />
          ) : null}
          <div className="flex flex-col -mt-2">
            <div className="flex flex-col bg-white mt-[10px] rounded-md">
              <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
                <div className="flex flex-col md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
                  Teams
                </div>
                <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
                  <div className="md:w-fit w-[100%]">
                    <Button
                      text="New Team"
                      type="button"
                      theme="primary"
                      className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                      textClass={"!text-[11px]"}
                      leftIcon={"add"}
                      onClick={() => {
                        setTitle("Add Team");
                        setButtonText("Save Team");
                        setTeamData(null);
                        setShowAddTeam(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className="divider mt-2 mb-2" />
              <DataTable
                data={teams}
                tableConfig={tableConfig}
                actionConfig={actionConfig}
                updatePagination={(data) => setPagination(data)}
                computedActions={true}
                tableType={tableType}
                switchFunction={switchFunction}
                layoutSwitch={true}
                searchData={true}
                noCheck={true}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default HRTeamPage;
