import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import AddSalaryAdvance from "../../modal/employees/add-salary-advance";

import { useGetEmployeeSalaryAdvance } from "../../../redux/employees/hook/salary-advance";

const SalaryAdvanceRequestTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: employeeAdvance } = useGetEmployeeSalaryAdvance(
    pagination?.limit,
    pagination?.page
  );

  const [saladData, setSaladData] = useState();
  const [showAddSalaryAdvance, setShowAddSalaryAdvance] = useState(false);

  const tableConfig = {
    headers: [
      "Date Added",
      "Employee Name",
      "Phone number",
      "Purpose",
      "Month",
      "Amount",
    ],
    keys: ["created", "employeeName", "phone", "purpose", "month", "amount"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };

  const actionConfig = [
    {
      name: "Edit/View",
      color: "green",
      action: (id) => {
        let index = employeeAdvance.data.findIndex((item) => item.id === id);
        setSaladData(employeeAdvance.data[index]);
        setShowAddSalaryAdvance(true);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white">
      {showAddSalaryAdvance && saladData ? (
        <AddSalaryAdvance
          isOpen={showAddSalaryAdvance}
          closeModal={() => setShowAddSalaryAdvance(false)}
          saladData={saladData}
          title="Edit Salary Deduction"
          buttonText="UPDATE"
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="p3-bold header-4">Salary Advances</div>
      </div>
      <hr className="divider mt-2 mb-2" />
      <DataTable
        data={employeeAdvance}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        // bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default SalaryAdvanceRequestTab;
