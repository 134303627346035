import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import ButtonSelect from "../../../components/button-select";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import { useRouteQuery } from "../../../hooks/useRouteQuery";

function AddSalaryInfo() {
  useEffect(() => {}, []);

  const history = useHistory();
  const [salaryInfoOption, setSalaryInfoOption] = useState("net-pay");
  const [createType, setCreateType] = useState("single");
  const [fromSalary, setFromSalary] = useState(false);
  const [update, setUpdate] = useState(false);

  const query = useRouteQuery();

  const skipAddSalary = () => {
    history.push("/employees");
  };

  useEffect(() => {
    if (query) {
      setCreateType(query.get("type"));
      setFromSalary(query.get("salary"));
      setUpdate(query.get("update"));
    }
  }, [query]);

  const submit = () => {
    if (update) {
      if (salaryInfoOption === "net-pay" && createType === "single") {
        let route = `/employees/onboarding/add-single-salary-information/?type=net-pay&update=true${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "net-pay" && createType === "bulk") {
        let route = `/employees/onboarding/add-bulk-salary-information/?type=net-pay&update=true${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "gross-pay" && createType === "single") {
        let route = `/employees/onboarding/add-single-salary-information/?type=gross-pay&update=true${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "gross-pay" && createType === "bulk") {
        let route = `/employees/onboarding/add-bulk-salary-information/?type=gross-pay&update=true${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "fill-info" && createType === "single") {
        let route = `/employees/onboarding/complete-single-salary-information?update=true${
          fromSalary ? "/?salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "fill-info" && createType === "bulk") {
        let route = `/employees/onboarding/add-bulk-salary-information/?type=fill-info&update=true${
          fromSalary ? "/?salary=true" : ""
        }`;
        history.push(route);
      }
    } else {
      if (salaryInfoOption === "net-pay" && createType === "single") {
        let route = `/employees/onboarding/add-single-salary-information/?type=net-pay&update=true${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "net-pay" && createType === "bulk") {
        let route = `/employees/onboarding/add-bulk-salary-information/?type=net-pay${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "gross-pay" && createType === "single") {
        let route = `/employees/onboarding/add-single-salary-information/?type=gross-pay${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "gross-pay" && createType === "bulk") {
        let route = `/employees/onboarding/add-bulk-salary-information/?type=gross-pay${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "fill-info" && createType === "single") {
        let route = `/employees/onboarding/complete-single-salary-information${
          fromSalary ? "/?salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "fill-info" && createType === "bulk") {
        let route = `/employees/onboarding/add-bulk-salary-information/?type=fill-info${
          fromSalary ? "/?salary=true" : ""
        }`;
        history.push(route);
      }
    }
  };

  return (
    <EmployeeOnboardingLayout
      step={fromSalary ? 2 : 5}
      title={update ? "Update Compensation" : "Add Compensation"}
    >
      <div className="flex flex-col justify-center h-full min-h-fit">
        <span className="hidden md:flex text-[20px] font-extrabold md:mt-2">
          Select Salary Upload Type
        </span>
        <hr className="divider mb-2 md:mt-2 -mt-12" />
        {!fromSalary ? (
          <div
            className="flex justify-end w-full cursor-pointer"
            onClick={() => skipAddSalary()}
          >
            <span className="text-[14px] font-semibold text-black cursor-pointer hover:underline hover:scale-110 duration-300 mb-2">
              Skip this step
            </span>
          </div>
        ) : null}
        <ButtonSelect
          title={"I know my employee Monthly take home (Net pay) only"}
          subtitle={"Help me break it down"}
          isActive={salaryInfoOption === "net-pay"}
          onClick={() => setSalaryInfoOption("net-pay")}
        />

        <ButtonSelect
          title={"I know the Gross pay only"}
          subtitle={"Help me break it down"}
          isActive={salaryInfoOption === "gross-pay"}
          onClick={() => setSalaryInfoOption("gross-pay")}
          className="mt-8"
        />

        <ButtonSelect
          title={"I know the complete salary information"}
          subtitle={"I have all the necessary salary details for my employee"}
          isActive={salaryInfoOption === "fill-info"}
          onClick={() => setSalaryInfoOption("fill-info")}
          className="mt-8"
        />

        <div className="w-full mt-[20px]">
          <Button text="Next" type="submit" onClick={() => submit()} />
        </div>
        {fromSalary ? (
          <div
            className="flex justify-center w-full cursor-pointer hover:underline hover:scale-110 duration-300"
            onClick={() => history.goBack()}
          >
            <span className="p2-medium text-color-black mt-3">Go Back</span>
          </div>
        ) : null}
      </div>
    </EmployeeOnboardingLayout>
  );
}

export default AddSalaryInfo;
