import React, { useEffect } from "react";
import DataTable from "../../components/datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import { useGetReferredCompanies } from "../../redux/referrals/hook";

const referredCompaniesPage = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
  });

  const { data: referredCompanies } = useGetReferredCompanies(
    pagination?.limit,
    pagination?.page
  );

  const tableConfig = {
    headers: [
      "Date Created",
      "Expiration Date",
      "Company Name",
      "Code",
      "Percentage",
      "Transaction Amt.",
      "Referral Commission",
    ],
    keys: [
      "created",
      "expiry_date",
      "referred_company",
      "referral_code",
      "percentage",
      "total_amount_paid",
      "total_commission",
    ],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Company Name",
        key: "referred_company",
      },
    },
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col bg-white rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Companies Referred
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={referredCompanies}
          tableConfig={tableConfig}
          actionConfig={null}
          filterByStatus={false}
          updatePagination={(data) => setPagination(data)}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default referredCompaniesPage;
