import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import {
  PAGINATION_DEFAULT,
  PerformanceEvaluationStatusConfig,
} from "../../../utils/constants";
import {
  useDeletePerformance,
  useGetPerformances,
} from "../../../redux/human-resources/hook/performance";
import Button from "../../button";
import { useHistory } from "react-router-dom";
import AssignEvaluation from "../../modal/hr-section/performance/assign-evaluation";

const HRPerformanceOverviewTab = () => {
  useEffect(() => {}, []);
  const history = useHistory();
  const [tableType, setTableType] = useState("performance");

  const switchFunction = () => {
    if (tableType === "performance") {
      setTableType("table");
    } else {
      setTableType("performance");
    }
  };

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: performances } = useGetPerformances(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: deletePerformanceEvaluation } = useDeletePerformance();
  const [showAssignEvaluation, setShowAssignEvaluation] = useState(null);
  const [performanceData, setPerformanceData] = useState(null);

  const tableConfig = {
    headers: [
      "Date Created",
      "Employee Name",
      "Mobile",
      "Period Covered",
      "Status",
    ],
    keys: ["created", "employeeName", "mobile", "period", "status"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Period Covered",
        key: "period",
      },
    },
  };
  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = performances.data.findIndex((item) => item.id === id);
        if (index !== -1) {
          history.push("/performance/view/" + performances.data[index].id);
        }
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = performances.data.findIndex((item) => item.id === id);
        deletePerformanceEvaluation(performances.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded -mt-2">
      {showAssignEvaluation ? (
        <AssignEvaluation
          isOpen={showAssignEvaluation}
          closeModal={() => setShowAssignEvaluation(false)}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Performance Evaluations
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Evaluate Performance"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"evaluate"}
              onClick={() => {
                history.push("/performance/select-option");
              }}
            />
          </div>
          <div className="md:w-fit w-[100%]">
            <Button
              text="Assign Evaluation"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              onClick={() => {
                setShowAssignEvaluation(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={performances}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        statusConfig={PerformanceEvaluationStatusConfig}
        filterByStatus={true}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        tableType={tableType}
        switchFunction={switchFunction}
        layoutSwitch={true}
        searchData={true}
      />
    </div>
  );
};

export default HRPerformanceOverviewTab;
