import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import CloseSVG from "../../../../assets/svg/close.svg";
import { formValidation } from "../../../../utils/functions";
import Button from "../../../button";
import FormInput from "../../../form-input";
import FormSelect from "../../../form-select";
import {
  useAddBulkEmployeeExit,
  useGetExitWorkflow,
  useGetSelectExitWorkflows,
} from "../../../../redux/employees/hook/exit";

import AddSmallSVG from "../../../../assets/svg/add-icon-small.svg";
import FormSwitch from "../../../form-switch";
import Modal from "../../modal";

import Excel from "exceljs";
import { saveAs } from "file-saver";
import FormFileInput from "../../../form-file-input";
import { employees as employeeAPI } from "../../../../api/employees";
import AsyncSelect from "react-select/async";
import { useExportSalaryTemplate } from "../../../../redux/employees/hook";

function AddBulkEmployeeExit({ isOpen, closeModal, exitType = "resignation" }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const [exportType, setexportType] = useState("all");

  const typeOptions = [
    {
      value: "all",
      label: "All employees",
    },
    {
      value: "select",
      label: "Selected Employees",
    },
  ];

  const [employees, setEmployees] = useState([]);

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 10,
          statusFilter: -1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];
          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const workbook = new Excel.Workbook();

  const columns = [
    { header: "Employee's First Name", key: "first_name" },
    { header: "Employee's Last Name", key: "last_name" },
    { header: "Phone Number", key: "mobile" },
    { header: "Staff Number", key: "staff_number" },
    { header: "Notice date (YYYY-MM-DD)", key: "notice_date" },
    { header: "Final date (YYYY-MM-DD)", key: "final_date" },
    { header: "Reason", key: "reason" },
    { header: "Staff Number", key: "staff_number" },
    { header: "Status", key: "status" },
  ];

  const [exportLoading, setSetExportLoading] = useState(false);

  const { mutateAsync: exportSalaryTemplate } = useExportSalaryTemplate();

  const exportTemplate = async () => {
    setSetExportLoading(true);
    await exportSalaryTemplate().then(async (response) => {
      let data = response?.data;
      const workSheetName = "active_employee_data";
      const workBookName = `Employee exit template`;
      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column) => {
          if (column._number > 5) {
            column.width = 18;
            column.style.alignment = { horizontal: "right" };
            column.numFmt = "0.00";
          } else if (column._number > 6) {
          } else {
            column.width = column.header.length + 2;
            column.style.alignment = { horizontal: "left" };
          }
        });
        if (exportType == "all") {
          data?.forEach((singleData) => {
            singleData.month = "";
            singleData.description = "";
            singleData.amount = "";
            if (singleData.status == 0) {
              singleData.status = "Inactive";
            } else if (singleData.status == 1) {
              singleData.status = "Active";
            } else if (singleData.status == 2) {
              singleData.status = "Pending";
            } else if (singleData.status == 3) {
              singleData.status = "Deleted";
            } else if (singleData.status == 2) {
              singleData.status = "Awaiting Activation";
            } else {
              singleData.status = "Pre-registered";
            }
            worksheet.addRow(singleData);
          });
        } else if (exportType == "select" && employees.length > 0) {
          employees.forEach((employee) => {
            data?.forEach((singleData) => {
              if (employee.value == singleData.id) {
                singleData.month = "";
                singleData.description = "";
                singleData.amount = "";
                if (singleData.status == 0) {
                  singleData.status = "Inactive";
                } else if (singleData.status == 1) {
                  singleData.status = "Active";
                } else if (singleData.status == 2) {
                  singleData.status = "Pending";
                } else if (singleData.status == 3) {
                  singleData.status = "Deleted";
                } else if (singleData.status == 2) {
                  singleData.status = "Awaiting Activation";
                } else {
                  singleData.status = "Pre-registered";
                }
                worksheet.addRow(singleData);
              }
            });
          });
        }

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: true }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;
            if (singleCell._column._number == 8) {
            }
            if (
              singleCell._column._number == 9 &&
              singleCell._row._number > 1
            ) {
              if (singleCell._value.model.value == "Inactive") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "D34829" },
                };
              } else if (singleCell._value.model.value == "Active") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "0d5225" },
                };
              } else if (singleCell._value.model.value == "Pending") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "FFA500" },
                };
              } else if (singleCell._value.model.value == "Deleted") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "D34829" },
                };
              } else if (
                singleCell._value.model.value == "Awaiting Activation"
              ) {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "FFA500" },
                };
              } else {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "FFA500" },
                };
              }
            }
            worksheet.getCell(cellAddress).border = {
              top: { style: "none" },
              left: { style: "none" },
              bottom: { style: "none" },
              right: { style: "none" },
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        // console.error('<<<ERRROR>>>', error);
        // console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  const [workflowId, setWorkflowId] = useState(null);
  const { data: workflows, isLoading: workflowLoading } =
    useGetSelectExitWorkflows(1000, 1, "");

  const { data: workflow_info } = useGetExitWorkflow(workflowId);

  const { mutateAsync: addBulkExit, isLoading: addBulkExitloading } =
    useAddBulkEmployeeExit();

  const [showAddWorkFlow, setShowAddWorkflow] = useState(false);

  const questionOptions = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];

  const [exitPortal, setExitPortal] = useState(false);
  const [assignworkflow, setAssignWorkflow] = useState(false);
  const [exitQuestion, setExitQuestion] = useState(0);
  const [daysToComplete, setDaysToComplete] = useState(1);

  const submitForm = async (data) => {
    const formData = new FormData();
    formData.append("file", data?.file[0]);
    formData.append("workflow_id", workflowId ? workflowId : 0);
    formData.append("employee_portal", exitPortal ? 1 : 0);
    formData.append("days_to_complete", daysToComplete ? daysToComplete : 1);
    formData.append("exit_question", exitQuestion);
    formData.append("type", exitType);

    addBulkExit(formData).then(() => {
      closeModal();
    });
  };

  return (
    <>
      {(!workflows && !workflowLoading) || showAddWorkFlow ? (
        <addBulkExitWorkflow
          title={"Add Exit Workflow"}
          isOpen={showAddWorkFlow}
          buttonText="Save"
          closeModal={() => setShowAddWorkflow(false)}
        />
      ) : null}
      <Modal
        scrollable={true}
        className="!max-w-[600px] !rounded-none"
        isOpen={isOpen}
        centered={true}
      >
        <div className="flex flex-col overflow-auto">
          <div className="pl-8 pr-8 pt-4">
            <div className="flex flex-row justify-between items-center">
              <span className="header-4">Import Employee Exits</span>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <img src={CloseSVG} alt="close" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2" />
          <form
            className="form px-8 rounded-md h-fit flex flex-col pb-4"
            onSubmit={handleSubmit(submitForm)}
          >
            <div className="flex flex-col border bg-highlight p-2 rounded-md">
              <FormSelect
                defaultValue={exportType}
                options={typeOptions}
                onChange={(selected) => {
                  setexportType(selected);
                }}
                label="Select Template Export Type"
                extraClass={"bg-white"}
              />
              <div>
                {exportType === "select" ? (
                  <div>
                    <label className="!text-[13px] leading-[23px] font-normal text-color-gray mb-1">
                      Select Employees
                    </label>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      className="text-[13px]"
                      placeholder={"Type to select multiple employees"}
                      loadOptions={loadOptions}
                      onChange={(options) => setEmployees(options)}
                      classNamePrefix="mySelect"
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <hr className="divider mt-2" />
            {exportType == "all" || employees.length > 0 ? (
              <>
                <div className="flex flex-col text-center justify-center items-center">
                  <span className="text-[16px] font-extrabold mb-2">
                    Upload reimbursement (Expense claims) from XLSX file
                  </span>
                  <div className=" flex flex-col border p-4 rounded-md bg-highlight mt-2 mb-3 justify-center items-center w-full">
                    <Button
                      text="Download template"
                      type="button"
                      theme="primary"
                      loading={exportLoading}
                      className="flex gap-2 !h-[35px] w-[220px] p-2"
                      textClass={"!text-[11px]"}
                      onClick={() => {
                        exportTemplate();
                      }}
                    />
                  </div>
                  <FormFileInput
                    multiSource={false}
                    inputRef={register(formValidation("file", true))}
                    accept=".xlsx"
                  />
                </div>
                <hr className="divider mt-2 mb-2 " />
                <div className="flex flex-row justify-between items-center md:w-[100%] w-full">
                  <div className="w-full md:w-[100%] text-[14px]">
                    <FormSwitch
                      label={
                        "Do you want to grant employee access to exit portal? (This cannot be reversed)"
                      }
                      name={"exit_portal"}
                      checked={exitPortal}
                      onClick={() => {
                        if (!exitPortal === true) {
                          setAssignWorkflow(true);
                        }
                        setExitPortal(!exitPortal);
                      }}
                    />
                  </div>
                </div>
                <hr className="divider -mt-3 mb-2" />
                {exitPortal ? (
                  <>
                    <div className="flex flex-col p-2 bg-highlight border rounded-md">
                      <div className="flex flex-row md:flex-col w-full justify-between duration-500">
                        <div className="w-full md:w-1/2 md:pr-2">
                          <FormSelect
                            value={exitQuestion}
                            options={questionOptions}
                            onChange={(selected) => {
                              setExitQuestion(selected);
                            }}
                            label="Assign Exit Questions?"
                          />
                        </div>
                        <div className="w-full md:w-1/2 md:pl-2">
                          <FormInput
                            label="Days to complete"
                            name="days_to_complete"
                            type="number"
                            value={daysToComplete}
                            onInput={(e) => {
                              setDaysToComplete(e.target.value);
                            }}
                            error={errors.days_to_complete}
                            errorMessage={
                              errors.days_to_complete &&
                              errors.days_to_complete.message
                            }
                          />
                        </div>
                      </div>
                      <div className="p-1 px-3 border border-gray-300 bg-white rounded text-justify">
                        <span className="font-bold">Info: </span>
                        <span className="text-[13px] text-justify">
                          You have to assign an exit workflow and select a
                          workflow to grant portal access to employee
                        </span>
                      </div>
                    </div>
                    <hr className="divider mt-2 mb-2" />
                  </>
                ) : null}

                <div className="flex flex-row justify-between items-center md:w-[100%] w-full">
                  <div className="w-full md:w-[100%] text-[14px] -mb-3">
                    <FormSwitch
                      label={"Do you want to assign an exit workflow?"}
                      name={"assign_workflow"}
                      checked={assignworkflow}
                      onClick={() => {
                        if (!assignworkflow === false) {
                          setWorkflowId(null);
                        }
                        setAssignWorkflow(!assignworkflow);
                      }}
                    />
                  </div>
                </div>
                <hr className="divider mt-2 " />
                {assignworkflow ? (
                  <div className="flex flex-col p-2 bg-highlight border rounded-md">
                    {workflows ? (
                      <div className="w-full">
                        <FormSelect
                          value={workflowId}
                          options={workflows}
                          onChange={(selected) => {
                            setWorkflowId(selected);
                          }}
                          label="Exit Workflows - (optional)"
                        />
                      </div>
                    ) : (
                      <div className="p4-medium text-[14px] mt-2 text-gray-500 font-semibold text-justify">
                        No Exit workflow found. Click link below to add
                        workflow.
                        <hr className="divider" />
                      </div>
                    )}
                    <div className="flex gap-2 items-center justify-center w-full">
                      <img
                        src={AddSmallSVG}
                        alt={"add_workflow"}
                        className={"h-[15px] w-[15px]"}
                      />
                      <span
                        className="p2-medium text-[#0d5225] cursor-pointer hover:underline duration-75 text-[14px] italic"
                        onClick={() => setShowAddWorkflow(true)}
                      >
                        Add New Workflow
                      </span>
                    </div>
                    <hr className="divider" />

                    {workflowId && workflow_info ? (
                      <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full border-2 p-2 rounded mt-2">
                        <div className="flex flex-col">
                          {workflow_info?.sub_workflows.map((item, index) => (
                            <>
                              <div className="flex flex-row justify-around flex-wrap">
                                <div className="flex flex-row items-center justify-between md:w-1/2 w-full">
                                  <div className="flex flex-row gap-2 items-center">
                                    <FormInput
                                      name={index + "_stage_name"}
                                      label={"Exit stage " + (index + 1)}
                                      placeholder={"Stage Name"}
                                      type="text"
                                      readOnly
                                      value={item.name}
                                      className="!h-[30px] md:w-[200px] w-[225px] text-[13px] border-1 border-gray-300"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-row gap-2 items-center md:w-1/2">
                                  <FormInput
                                    name={index + "_stage_description"}
                                    label={"Stage Description"}
                                    placeholder={"Stage Description"}
                                    type="text"
                                    readOnly
                                    value={item.description}
                                    className="!h-[30px] md:w-[200px] w-[225px] text-[13px] border-1 border-gray-300"
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col justify-around flex-wrap -mt-2">
                                <div className="flex flex-row  justify-between items-center md:w-[100%] w-full">
                                  <div className="w-full md:w-[60%] mt-2 text-[13px]">
                                    <FormSwitch
                                      label={"Employee Can Remark?"}
                                      name={"switch-edit-mode"}
                                      readOnly
                                      checked={
                                        item.employee_can_remark == 1
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr className="divider -mt-2" />
                            </>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="italic text-center text-gray-500 p-4 flex items-center justify-center border bg-white w-full rounded-md text-[14px]">
                        Exit stages not loaded
                      </div>
                    )}
                  </div>
                ) : null}
                <div className="w-full mt-[10px] pb-4 ">
                  <Button
                    text={"Upload"}
                    type="submit"
                    loading={addBulkExitloading}
                    disabled={false}
                  />
                </div>
              </>
            ) : (
              <div className="flex flex-col border bg-highlight p-2 rounded-md items-center justify-center text-[14px]">
                Select at least one employee to download template
              </div>
            )}
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddBulkEmployeeExit;
