import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { workflows } from '../../../api/hr/workflow';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useGetAllWorkflows() {
  return useQuery(
    ['all-workflows'],
    () => {
      return workflows.getWorkflows();
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Workflow' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetOnboardingWorkflows(
  type,
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    ['onboarding-workflows', { type, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return workflows.getWorkflows({
        type,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Workflow Selected' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetWorkflows(type, limit, page, statusFilter, search) {
  return useQuery(
    ['workflows', { type, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return workflows.getWorkflows({
        type,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddWorkflow() {
  return useMutation(
    (payload) => {
      return workflows.addWorkflow(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('workflows');
        store.dispatch(setAlert(true, 'success', 'Workflow Added Successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditWorkflow() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return workflows.updateWorkflow(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('workflows');
        store.dispatch(setAlert(true, 'success', 'Workflow Updated Successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteWorkflow() {
  return useMutation(
    (payload) => {
      return workflows.deleteWorkflow(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('workflows');
        store.dispatch(setAlert(true, 'success', 'Workflow Deleted Successfully'));
      },
    },
  );
}

export function useGetWorkflow(id) {
  return useQuery(
    ['workflow', { id }],
    () => {
      return workflows.getWorkflow({ id });
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
