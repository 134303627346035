import React, { useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import AsyncSelect from 'react-select/async';
import Modal from '../../modal';
import { performances as performanceAPIs } from '../../../../api/hr/performance';
import FormSelectEmployee from '../../../employees/form-select-employee';
import moment from 'moment';
import { useAssignPerformanceEvaluation } from '../../../../redux/human-resources/hook/performance';
import { store } from '../../../../redux';
import { setAlert } from '../../../../redux/components/components-slice';

function AssignEvaluation({
  isOpen,
  closeModal,
}) {
    const [assigneeName, setAssigneeName] = useState(null);

    const [employees, setEmployees] = useState([]);
    const [assignee, setAssignee] = useState([]);

    const loadOptions = (inputValue) =>
    new Promise((resolve) => {
        performanceAPIs
        .getPerformances({
          limit: 10,
          statusFilter: 0,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${moment(item.evaluation_start).format('MMMM DD, YYYY')} - ${moment(item.evaluation_end).format('MMMM DD, YYYY')}`,
              value: item.id,
              employee_id: item.employee_id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
  });

  const { mutateAsync: assignEvaluation, isLoading: assignEvaluationLoading } = useAssignPerformanceEvaluation();

  const submitForm = async () => {
    if (employees.length === 0) {
      store.dispatch(
        setAlert(
          true,
          'info',
          'There must be at least one Performance selected for assignment',
        ),
      );
    return;
    }
    let employeeArrays = [];
    let employeeIdArrays = [];

    employees.forEach((item) => {
      employeeArrays.push(item.value);
      employeeIdArrays.push(item.employee_id);
    });

    let performance_ids = employeeArrays.join();
    if (employeeIdArrays.includes(parseInt(assignee))) {
      store.dispatch(
        setAlert(
          true,
          'info',
          'You cannot assign a performance evalaution to the same employee being evaluated.',
        ),
      );
    } else {
      let payload = {
        performance_ids,
        assignee,
      }
      await assignEvaluation(payload).then(() => {
        closeModal();
      });
    }
  };
  
  return (
    <Modal
      scrollable={true}
      className='!max-w-[800px] !min-w-[800px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-6'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Assign Evaluation</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col max-h-fit mx-8'>
            <div className='w-full p-2 bg-highlight rounded-md border'>
              <label className='!text-[13px] leading-[23px] font-normal text-color-gray'>
                Select performance evaluation to assign
              </label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                className='text-[13px]'
                placeholder={'Type to select multiple employee performances'}
                loadOptions={loadOptions}
                onChange={(options) => setEmployees(options)}
                classNamePrefix='mySelect'
              />
            </div>
            <hr className='divider mb-2 mt-2' />
            <FormSelectEmployee
              label={'Select Assessment Assignee'}
              name='employee'
              placeholder='Type to select assignee'
              value={assignee}
              empName={assigneeName}
              setSourceName={setAssigneeName}
              setEmployee={setAssignee}
            />
            <div className='w-full'>
              <Button
                text={'Assign Performance Evaluation'}
                theme={'primary'}
                type='button'
                onClick={() => {
                  submitForm()
                }}
                loading={assignEvaluationLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AssignEvaluation;
