import React, { useEffect } from "react";
import { useState } from "react";
import classNames from "classnames";
import FormSelect from "../../form-select";
import CollapsingIcon from "../../collapsing-icon";
import { Collapse } from "reactstrap";
import FormInput from "../../form-input";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../utils/functions";
import {
  useEditEmployeeGroup,
  useGetGroupSettings,
} from "../../../redux/employees/hook/groups";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { useParams } from "react-router-dom";
import FormSwitch from "../../form-switch";
import Button from "../../button";
import LoadingBar from "../../loader-bar";
import SettingsSVG from "../../../assets/svg/settings-image.svg";
import DOMPurify from "dompurify";

const GroupSettings = () => {
  useEffect(() => {}, []);
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });
  const { id } = useParams();

  const { data: groupSettings, isLoading } = useGetGroupSettings(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [dayCount, setDayCount] = useState("seven_days");
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(true);
  const [totalAllowance, setTotalAllowance] = useState();
  const [groupId, setGroupId] = useState();
  const { mutateAsync: editGroup, isLoading: loading } = useEditEmployeeGroup();

  const [additionalAllowance, setAdditionalAllowance] = useState([]);

  const {
    register,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    use_global_settings: false,
    use_fixed_rate: false,
    calc_pension: true,
    calc_tax: true,
    calc_nhf: false,
    calc_nsitf: true,
    calc_itf: true,
    calc_nhis: false,
    can_apply_for_advance: true,
    can_apply_for_reimbursements: true,
    can_apply_for_overtime: false,
    overtime_hourly_rate: 1000,
    max_overtime_hours_monthly: 10,
    basic_salary: 40,
    housing_allowance: 10,
    transport_allowance: 15,
    leave_allowance: 10,
    lunch_allowance: 10,
    utility_allowance: 10,
    other_allowance: 5,
    pension_calculator: "gross",
    employer_pension: 8,
    employee_pension: 10,
    employee_nhis: 5,
    employer_nhis: 10,
    nhf_percentage: 2.5,
    benefit_in_kind: 0,
    fixed_rate: 0,
  });

  useEffect(() => {
    if (groupSettings) {
      setFormData({
        name: groupSettings.group_name,
        description: groupSettings.description,
        calc_tax: groupSettings.calc_tax,
        calc_pension: groupSettings.calc_pension,
        calc_nhf: groupSettings.calc_nhf,
        calc_nhis: groupSettings.calc_nhis,
        calc_nstf: groupSettings.calc_nstf,
        calc_itf: groupSettings.calc_itf,
        can_apply_for_reimbursements:
          groupSettings?.can_apply_for_reimbursements,
        can_apply_for_advance: groupSettings?.can_apply_for_advance,
        can_apply_for_overtime:
          groupSettings?.can_apply_for_overtime === 1 ? true : false,
        overtime_hourly_rate: groupSettings?.overtime_hourly_rate,
        max_overtime_hours_monthly: groupSettings?.max_overtime_hours_monthly,
        basic_salary: groupSettings.basic_salary,
        transport_allowance: groupSettings.transport_allowance,
        housing_allowance: groupSettings.housing_allowance,
        leave_allowance: groupSettings.leave_allowance,
        lunch_allowance: groupSettings.lunch_allowance,
        utility_allowance: groupSettings.utility_allowance,
        other_allowance: groupSettings.other_allowance,
        pension_calculator: groupSettings.pension_calculator,
        employer_pension: groupSettings.employer_pension,
        employee_pension: groupSettings.employee_pension,
        employee_nhis: groupSettings.employee_nhis,
        employer_nhis: groupSettings.employer_nhis,
        nhf_percentage: groupSettings.nhf_percentage,
        use_global_settings: groupSettings.use_global_settings,
        use_fixed_rate: groupSettings.use_fixed_rate,
        benefit_in_kind: 0,
        fixed_rate: groupSettings.fixed_rate,
      });
      setDayCount(groupSettings.days_count);
      setGroupId(groupSettings.id);
      setAdditionalAllowance(
        groupSettings?.additional_allowances
          ? JSON.parse(groupSettings?.additional_allowances)
          : []
      );
    }
  }, [groupSettings]);

  useEffect(() => {
    let computeTotalAllowance =
      parseInt(formData.basic_salary) +
      parseInt(formData.housing_allowance) +
      parseInt(formData.transport_allowance) +
      parseInt(formData.benefit_in_kind) +
      parseInt(formData.leave_allowance) +
      parseInt(formData.lunch_allowance) +
      parseInt(formData.utility_allowance) +
      parseInt(formData.other_allowance);
    setTotalAllowance(computeTotalAllowance || 0);
  }, [formData]);

  const rateSettings = [
    {
      title: "Do you want to use company payroll settings?",
      name: "use_global_settings",
    },
    {
      title: "Do you want to use fixed tax rate (WHT)?",
      name: "use_fixed_rate",
    },
    {
      title: "Calculate PAYE tax",
      name: "calc_tax",
    },
    {
      title: "Calculate Pension",
      name: "calc_pension",
    },
    {
      title: "Calculate National Housing Fund (NHF) ",
      name: "calc_nhf",
    },
    {
      title: "Calculate National Health Insurance Scheme (NHIS)",
      name: "calc_nhis",
    },
  ];

  const applicationSettings = [
    {
      title: "Can employees apply for salary advances?",
      name: "can_apply_for_advance",
    },
    {
      title: "Can employees apply for reimbursements?",
      name: "can_apply_for_reimbursements",
    },
    {
      title: "Can employees apply for Overtime?",
      name: "can_apply_for_overtime",
    },
  ];

  const AdvanceSalarySettings = [
    {
      title: "Basic (%)",
      name: "basic_salary",
    },
    {
      title: "Housing (%)",
      name: "housing_allowance",
    },
    {
      title: "Transportation (%)",
      name: "transport_allowance",
    },
    {
      title: "Leave allowance (%)",
      name: "leave_allowance",
    },
    {
      title: "Lunch allowance (%)",
      name: "lunch_allowance",
    },
    {
      title: "Utility allowance (%)",
      name: "utility_allowance",
    },
    {
      title: "Other allowance (%)",
      name: "other_allowance",
    },
  ];

  useEffect(() => {
    let additional_allowance = 0;
    if (additionalAllowance.length > 0) {
      additionalAllowance.forEach((element) => {
        additional_allowance += parseInt(element.rate);
      });
    }
    let computeTotalAllowance =
      parseInt(formData.basic_salary) +
      parseInt(formData.housing_allowance) +
      parseInt(formData.transport_allowance) +
      parseInt(formData.benefit_in_kind) +
      parseInt(formData.leave_allowance) +
      parseInt(formData.lunch_allowance) +
      parseInt(formData.utility_allowance) +
      parseInt(formData.other_allowance) +
      parseInt(additional_allowance);
    setTotalAllowance(computeTotalAllowance || 0);
  }, [formData, additionalAllowance]);

  const statutorySettings = [
    {
      title: "Employer Pension - ReadOnly",
      name: "employer_pension",
    },
    {
      title: "Employee Pension - ReadOnly",
      name: "employee_pension",
    },
    {
      title: "Employer NHIS - ReadOnly",
      name: "employer_nhis",
    },
    {
      title: "Employee NHIS - ReadOnly",
      name: "employee_nhis",
    },
    {
      title: "NHF - ReadOnly",
      name: "nhf_percentage",
    },
  ];

  const dayCountOptions = [
    { value: "seven_days", label: "Calendar Days" },
    { value: "week_days", label: "Working Days" },
  ];

  const pensionCalculatorOptions = [
    { value: "bht", label: "Basic, Housing and Transport" },
    { value: "gross", label: "Gross Pay" },
  ];

  const submit = async () => {
    let payload = {
      ...formData,
      days_count: DOMPurify.sanitize(dayCount),
      additional_allowances: JSON.stringify(additionalAllowance),
    };

    let editPayload = {
      id: id,
      body: payload,
    };
    await editGroup(editPayload).then(() => {});
  };

  return (
    <>
      <div className="flex flex-col bg-white w-full rounded py-3 min-h-fit">
        <div className="flex flex-col gap-0 px-4">
          <span className="p1-bold">Rate Settings</span>
          <span className="text-color-gray pt-1 text-[13px]">
            Edit and update this group's payroll settings
          </span>
        </div>

        <hr className="divider" />
        {isLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
            <LoadingBar loading={isLoading} />
          </div>
        ) : (
          <div className="flex flex-row w-full">
            <div className="flex flex-col md:w-1/2 w-full">
              <div className="flex flex-col md:flex-row">
                <div className="flex w-full max-w-[100%] px-4 md:px-0 flex-col">
                  <div className={"flex w-full flex-col "}>
                    {rateSettings.map((item, index) => (
                      <>
                        {!formData.use_global_settings ||
                        (formData.use_global_settings &&
                          item.name === "use_global_settings") ? (
                          <>
                            {!formData.use_fixed_rate ||
                            (formData.use_fixed_rate &&
                              item.name === "use_fixed_rate") ||
                            item.name === "use_global_settings" ? (
                              <div className="mb-[15px] bg-highlight border pt-4 px-3 rounded">
                                <FormSwitch
                                  label={item.title}
                                  name={item.name}
                                  readOnly={loading}
                                  checked={formData[item.name]}
                                  onClick={() => {
                                    if (item.name === "use_fixed_rate") {
                                      if (!formData[item.name] == true) {
                                        setFormData({
                                          ...formData,
                                          [item.name]: !formData[item.name],
                                          calc_tax: true,
                                        });
                                      } else {
                                        setFormData({
                                          ...formData,
                                          [item.name]: !formData[item.name],
                                        });
                                      }
                                    } else {
                                      setFormData({
                                        ...formData,
                                        [item.name]: !formData[item.name],
                                      });
                                    }
                                  }}
                                />
                              </div>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    ))}
                  </div>
                  <div
                    className={
                      "flex w-full flex-col mb-3 px-3 border bg-highlight rounded-md pt-4 -pb-2"
                    }
                  >
                    {applicationSettings.map((item, index) => (
                      <div className="flex flex-col gap-2 w-full">
                        <FormSwitch
                          label={item.title}
                          name={item.name}
                          checked={formData[item.name]}
                          onClick={() =>
                            setFormData({
                              ...formData,
                              [item.name]: !formData[item.name],
                            })
                          }
                          value={formData[item.name]}
                        />
                        {item.name === "can_apply_for_overtime" &&
                        formData[item.name] ? (
                          <div className="grid md:grid-cols-2 grid-cols-1 gap-2 p-2 border rounded-md w-full -mt-4 mb-2 bg-gray-50">
                            <FormInput
                              label={"Overtime Hourly Rate (NGN)"}
                              name={"overtime_hourly_rate"}
                              type="number"
                              className={"!bg-white text-[14px]"}
                              value={formData["overtime_hourly_rate"]}
                              onInput={(e) =>
                                setFormData({
                                  ...formData,
                                  overtime_hourly_rate: e.target.value,
                                })
                              }
                            />
                            <FormInput
                              label={"Total Available Overtime Hours"}
                              name={"max_overtime_hours_monthly"}
                              type="number"
                              className={"!bg-white text-[14px]"}
                              value={formData["max_overtime_hours_monthly"]}
                              onInput={(e) =>
                                setFormData({
                                  ...formData,
                                  max_overtime_hours_monthly: e.target.value,
                                })
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                  {!formData.use_global_settings ? (
                    <>
                      <div
                        className={classNames(
                          "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                          {
                            "pt-3 pb-3 px-2 font-extrabold bg-secondary-2-extralight rounded-md mb-2":
                              showAdvancedSettings,
                          }
                        )}
                        onClick={() =>
                          setShowAdvancedSettings(!showAdvancedSettings)
                        }
                      >
                        <span>Advance Payroll Settings</span>
                        <div className="pr-4">
                          <CollapsingIcon
                            defaultPosition="left"
                            isOpen={showAdvancedSettings}
                          />
                        </div>
                      </div>
                      {!formData.use_fixed_rate ? (
                        <div
                          className={classNames(
                            "flex flex-col border p-3 bg-gray-50 rounded-md",
                            {
                              hidden: !showAdvancedSettings,
                            }
                          )}
                        >
                          <div className="flex justify-end mt-2">
                            <span
                              className={classNames(
                                "font-extrabold",
                                {
                                  "text-green-700 ": totalAllowance === 100,
                                },
                                {
                                  "text-color-error": totalAllowance !== 100,
                                }
                              )}
                            >
                              Total Allowance ({totalAllowance}%)
                            </span>
                          </div>
                          <Collapse isOpen={showAdvancedSettings}>
                            <div className="mt-2 mb-2 text-color-black text-left text-[13px]">
                              Total Gross Allowance must be equal to 100%
                            </div>
                            <div className="grid md:grid-cols-3 grid-cols-2 gap-2 w-full">
                              {AdvanceSalarySettings.map((item) => (
                                <FormInput
                                  label={item.title}
                                  name={item.name}
                                  type="text"
                                  inputRef={register(
                                    formValidation("non-empty", false)
                                  )}
                                  onInput={(e) =>
                                    setFormData({
                                      ...formData,
                                      [item.name]: e.target.value,
                                    })
                                  }
                                  readOnly={loading}
                                  error={errors[item.name]}
                                  errorMessage={
                                    errors[item.name] &&
                                    errors[item.name].message
                                  }
                                  value={formData[item.name]}
                                />
                              ))}
                            </div>
                            <hr className="divider mt-2" />
                            {additionalAllowance.length > 0 ? (
                              <>
                                <div className="grid md:grid-cols-3 grid-cols-2 gap-2 w-full">
                                  {additionalAllowance.map((item, index) => (
                                    <FormInput
                                      label={item.title + " (%)"}
                                      name={item.name}
                                      type="number"
                                      onInput={(e) => {
                                        let newArray = JSON.parse(
                                          JSON.stringify(additionalAllowance)
                                        );
                                        newArray[index].rate = e.target.value;
                                        setAdditionalAllowance(newArray);
                                      }}
                                      // readOnly={loading}
                                      value={item.rate}
                                    />
                                  ))}
                                </div>
                              </>
                            ) : null}
                            <hr className="divider mt-2" />
                            <div className="grid md:grid-cols-3 grid-cols-1 gap-2 w-full">
                              {formData.calc_pension ? (
                                <div className="col-span-2">
                                  <FormSelect
                                    value={formData["pension_calculator"]}
                                    options={pensionCalculatorOptions}
                                    onChange={(selected) => {
                                      setFormData({
                                        ...formData,
                                        pension_calculator: selected,
                                      });
                                    }}
                                    label="Pension Calculator"
                                  />
                                </div>
                              ) : null}
                              <div className="col-span-1">
                                <FormSelect
                                  value={dayCount}
                                  options={dayCountOptions}
                                  onChange={(selected) => {
                                    setDayCount(selected);
                                  }}
                                  label="Day Count"
                                />
                              </div>
                            </div>
                            {formData.calc_pension ||
                            formData.calc_nhis ||
                            formData.calc_nhf ? (
                              <>
                                <hr className="divider mt-2" />
                                <div className="mb-2 text-color-black font-semibold text-left">
                                  Statutory Rates (%)
                                </div>
                                <div className="grid md:grid-cols-2 grid-cols-1 gap-2 w-full">
                                  {statutorySettings.map((item) => (
                                    <>
                                      {!formData.calc_pension &&
                                      (item.name == "employer_pension" ||
                                        item.name ==
                                          "employee_pension") ? null : !formData.calc_nhis &&
                                        (item.name == "employer_nhis" ||
                                          item.name ==
                                            "employee_nhis") ? null : !formData.calc_nhf &&
                                        item.name == "nhf_percentage" ? null : (
                                        <FormInput
                                          label={item.title}
                                          name={item.name}
                                          type="text"
                                          readOnly={
                                            item.name === "employer_pension" ||
                                            item.name === "employee_pension"
                                              ? false
                                              : true
                                          }
                                          onInput={(e) =>
                                            setFormData({
                                              ...formData,
                                              [item.name]: parseFloat(
                                                e.target.value
                                              ),
                                            })
                                          }
                                          value={formData[item.name]}
                                        />
                                      )}
                                    </>
                                  ))}
                                </div>
                              </>
                            ) : null}
                          </Collapse>
                        </div>
                      ) : (
                        <div
                          className={classNames(
                            "flex flex-col border p-3 bg-gray-50 rounded-md",
                            {
                              hidden: !showAdvancedSettings,
                            }
                          )}
                        >
                          <Collapse isOpen={showAdvancedSettings}>
                            <div className="grid md:grid-cols-2 grid-cols-1 gap-2 w-full">
                              <FormInput
                                label={"Fixed Tax Rate (%)"}
                                name={"fixed_rate"}
                                type="number"
                                inputRef={register(
                                  formValidation("number", false)
                                )}
                                onInput={(e) =>
                                  setFormData({
                                    ...formData,
                                    fixed_rate: parseFloat(e.target.value),
                                  })
                                }
                                readOnly={loading}
                                error={errors.fixed_rate}
                                errorMessage={
                                  errors.fixed_rate && errors.fixed_rate.message
                                }
                                value={formData["fixed_rate"]}
                              />
                            </div>
                          </Collapse>
                        </div>
                      )}
                    </>
                  ) : null}
                  <div className="flex flex-row justify-end w-[100%] mt-4 mb-4">
                    <Button
                      text="UPDATE GROUP SETTINGS"
                      type="button"
                      loading={loading}
                      onClick={() => submit()}
                      className="flex gap-2 !h-[35px] w-fit p-2 "
                      textClass={"!text-[11px]"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:flex flex-row md:w-1/2 justify-center">
              <img
                src={SettingsSVG}
                alt={"Refresh"}
                className={"h-[300px] w-[80%]"}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GroupSettings;
