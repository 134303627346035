import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import PayeTaxTab from "../../../components/payroll-section/statutory-complaince/paye-tax";
import PensionTab from "../../../components/payroll-section/statutory-complaince/pension";
import NsitfTab from "../../../components/payroll-section/statutory-complaince/nsitf";
import NhisTab from "../../../components/payroll-section/statutory-complaince/nhis";
import NhfTab from "../../../components/payroll-section/statutory-complaince/nhf";
import ItfTab from "../../../components/payroll-section/statutory-complaince/itf";
import UploadPension from "../../../components/modal/statutory-compliance/upload-pension";
import UploadTax from "../../../components/modal/statutory-compliance/upload-tax";
import UploadNHF from "../../../components/modal/statutory-compliance/upload-nhf";
import UploadNHIS from "../../../components/modal/statutory-compliance/upload-nhis";
import UploadNSITF from "../../../components/modal/statutory-compliance/upload-nsitf";
import UploadITF from "../../../components/modal/statutory-compliance/upload-itf";
import { useSelector } from "react-redux";
import UnauthorizedPage from "../../errors/unauthorized";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const StatutoryPaymentPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  const [currentLocation, setCurrentLocation] = useState("taxes");

  const location = useLocation();

  useEffect(() => {
    if (location) {
      setCurrentLocation(location.pathname.split("/")[2]);
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.statutory_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [showUploadPension, setShowUploadPension] = useState(false);
  const [showUploadTax, setShowUploadTax] = useState(false);
  const [showUploadNHF, setshowUploadNHF] = useState(false);
  const [showUploadNHIS, setShowUploadNHIS] = useState(false);
  const [showUploadNSITF, setShowUploadNSITF] = useState(false);
  const [showUploadITF, setShowUploadITF] = useState(false);

  const statutoryTabButton = [
    {
      title: "Pension Upload",
      show:
        user?.permissions.employee_permission.pension_write === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? true
          : false,
      action: () => {
        // setShowUploadPension(true)
      },
    },
    {
      title: "Tax Upload",
      show:
        user?.permissions.employee_permission.taxes_write === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? true
          : false,
      action: () => {
        // setShowUploadTax(true)
      },
    },
    {
      title: "NHF Upload",
      show:
        user?.permissions.employee_permission.nhf_write === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? true
          : false,
      action: () => {
        // setshowUploadNHF(true)
      },
    },
    {
      title: "NHIS Upload",
      show:
        user?.permissions.employee_permission.nhis_write === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? true
          : false,
      action: () => {
        // setShowUploadNHIS(true)
      },
    },
    {
      title: "NSITF Upload",
      show:
        user?.permissions.employee_permission.nsitf_write === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? true
          : false,
      action: () => {
        // setShowUploadNSITF(true)
      },
    },
    {
      title: "ITF Upload",
      show:
        user?.permissions.employee_permission.itf_write === 1 ||
        user?.permissions.company_permission.role === "Owner"
          ? true
          : false,
      action: () => {
        // setShowUploadITF(true)
      },
    },
  ];

  return (
    <DashboardLayout title="Statutory Compliance">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <UploadPension
            isOpen={showUploadPension}
            closeModal={() => setShowUploadPension(false)}
          />
          <UploadTax
            isOpen={showUploadTax}
            closeModal={() => setShowUploadTax(false)}
          />

          <UploadNHF
            isOpen={showUploadNHF}
            closeModal={() => setshowUploadNHF(false)}
          />
          <UploadNHIS
            isOpen={showUploadNHIS}
            closeModal={() => setShowUploadNHIS(false)}
          />

          <UploadNSITF
            isOpen={showUploadNSITF}
            closeModal={() => setShowUploadNSITF(false)}
          />
          <UploadITF
            isOpen={showUploadITF}
            closeModal={() => setShowUploadITF(false)}
          />

          <div className="flex flex-col">
            <div id="statutory-payments">
              {currentLocation === "taxes" ? (
                <>
                  {user?.permissions.statutory_permission.taxes_read === 1 ||
                  user?.permissions.company_permission.role === "Owner" ? (
                    <PayeTaxTab />
                  ) : (
                    <UnauthorizedPage />
                  )}
                </>
              ) : currentLocation === "pensions" ? (
                <>
                  {user?.permissions.statutory_permission.pension_read === 1 ||
                  user?.permissions.company_permission.role === "Owner" ? (
                    <PensionTab />
                  ) : (
                    <UnauthorizedPage />
                  )}
                </>
              ) : currentLocation === "nhf" ? (
                <>
                  {user?.permissions.statutory_permission.nhf_read === 1 ||
                  user?.permissions.company_permission.role === "Owner" ? (
                    <NhfTab />
                  ) : (
                    <UnauthorizedPage />
                  )}
                </>
              ) : currentLocation === "nhis" ? (
                <>
                  {user?.permissions.statutory_permission.nhis_read === 1 ||
                  user?.permissions.company_permission.role === "Owner" ? (
                    <NhisTab />
                  ) : (
                    <UnauthorizedPage />
                  )}
                </>
              ) : currentLocation === "nsitf" ? (
                <>
                  {user?.permissions.statutory_permission.nsitf_read === 1 ||
                  user?.permissions.company_permission.role === "Owner" ? (
                    <NsitfTab />
                  ) : (
                    <UnauthorizedPage />
                  )}
                </>
              ) : currentLocation === "itf" ? (
                <>
                  {user?.permissions.statutory_permission.itf_read === 1 ||
                  user?.permissions.company_permission.role === "Owner" ? (
                    <ItfTab />
                  ) : (
                    <UnauthorizedPage />
                  )}
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default StatutoryPaymentPage;
