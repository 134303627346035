import { Axios } from '../axios';

const getOnboardingSummary = async () => {
    const { data } = await Axios.get('/onboarding/summary');
    return data.data;
};

const getEmployeeOnboardings = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/onboard-employees?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const getEmployeeOnboarding = async (payload) => {
    const { data } = await Axios.get('/onboard-employees/' + payload);
    return data?.data;
};

const addEmployeeOnboarding = async (payload) => {
    const { data } = await Axios.post('/onboard-employees', payload);
    return data;
};

const addMultipleEmployeeOnboarding = async (payload) => {
  const { data } = await Axios.post('/onboard-employee/bulk', payload,
  {
    timeout: 0
  });
  return data;
};

const updateEmployeeOnboarding = async (payload) => {
    const { data } = await Axios.put(
      '/onboard-employees/' + payload.onboarding_id,
      payload.data,
    );
    return data;
};

const deleteEmployeeOnboarding = async (payload) => {
    const { data } = await Axios.delete(
      '/onboard-employees/' + payload
    );
    return data;
};

const addOnboardingWorkflow = async (payload) => {
    const { data } = await Axios.post('/onboard-employee/workflows', payload,
    {
      timeout: 0
    });
    return data;
};

const addOnboardingSubWorkflow = async (payload) => {
    const { data } = await Axios.post('/onboard-employee/subworkflows', payload,
    {
      timeout: 0
    });
    return data;
};

const getOnboardingWorkflows = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(
      `/onboard-employee/workflows?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
    );
  
    return data?.data;
};

const getOnboardingWorkflow = async (payload) => {
    const { data } = await Axios.get('/onboard-employee/workflows/' + payload);
    return data?.data;
};

const getOnboardingSubWorkflow = async (payload) => {
  const { data } = await Axios.get('/onboard-employee/subworkflows/' + payload);
  return data.data;
};

const updateOnboardingWorkflow = async (payload) => {
  const { data } = await Axios.put(
    '/onboard-employee/workflows/' + payload.workflow_id,
    payload?.data,
  );
  return data;
};

const markOnboardingStageComplete = async (payload) => {
  const { data } = await Axios.post(
    '/onboard-employee/sub_workflow', payload,
    payload.data,
  );
  return data;
};

const markOnboardingComplete = async (payload) => {
  const { data } = await Axios.post(
    '/onboard-employee/change-status', payload,
  );
  return data;
};

const employeeMarkStageComplete = async (payload) => {
  const { data } = await Axios.post(
    '/employee-onboarding/subworkflow/status',payload
  );
  return data?.data;
};

const employeeMarkOnboardingComplete = async (payload) => {
  const { data } = await Axios.post(
    '/employee-onboarding/status', payload,
  );
  return data?.data;
};

const updateOnboardingSubWorkflow = async (payload) => {
    const { data } = await Axios.post(
      '/onboard-employee/subworkflows/' + payload.subworkflow_id,
      payload.data,
    );
    return data;
};

const deleteOnboardingWorkflow = async (payload) => {
    const { data } = await Axios.delete(
      '/onboard-employee/workflows/' + payload
    );
    return data;
};

const deleteOnboardingSubWorkflow = async (payload) => {
    const { data } = await Axios.delete(
      '/onboard-employee/subworkflows/' + payload,
    );
    return data;
};

//Employee Action
const generateEmployeeOtp = async (payload) => {
  const { data } = await Axios.get('/employee-onboarding/generate-otp/'+  payload);
  return data;
};

const confirmEmployeeOtp = async (payload) => {
  const { data } = await Axios.post('/employee-onboarding/confirm-otp', payload);
  return data;
};

const uploadRequestedFile = async (payload) => {
  const { data } = await Axios.post('/employee-onboarding/upload-requested-file', payload,
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const employeeSaveAnswer = async (payload) => {
  const { data } = await Axios.post('/employee-onboarding/answer', payload);
  return data;
};

export const employeeOnboardings = {
  getOnboardingSummary,
  getEmployeeOnboardings,
  getEmployeeOnboarding,
  addEmployeeOnboarding,
  addMultipleEmployeeOnboarding,
  updateEmployeeOnboarding,
  deleteEmployeeOnboarding,
  addOnboardingWorkflow,
  addOnboardingSubWorkflow,
  getOnboardingWorkflows,
  getOnboardingWorkflow,
  getOnboardingSubWorkflow,
  updateOnboardingWorkflow,
  updateOnboardingSubWorkflow,
  deleteOnboardingWorkflow,
  deleteOnboardingSubWorkflow,

  markOnboardingStageComplete,
  markOnboardingComplete,

  generateEmployeeOtp,
  confirmEmployeeOtp,
  employeeMarkStageComplete,
  employeeMarkOnboardingComplete,
  uploadRequestedFile,
  employeeSaveAnswer,
};