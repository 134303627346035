import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../components/layouts/dashboard";
import { useHistory, useParams } from "react-router-dom";
import LoadingBar from "../../../../components/loader-bar";
import Button from "../../../../components/button";
import { Initials } from "../../../../utils/functions";
import { StatusText } from "../../../../components/fragments/status-text";
import {
  useGetPIP,
  useGetPerformanceByPIP,
} from "../../../../redux/employees/hook/pip";
import { PIPComments } from "../../../../components/modal/hr-section/employee-pip/pip-comments";
import {
  PAGINATION_DEFAULT,
  PerformanceEvaluationStatusConfig,
  pipStatusConfig,
} from "../../../../utils/constants";
import moment from "moment";
import ResolveTerminatePIP from "../../../../components/modal/hr-section/employee-pip/resolve-terminate";
import ResolveReinstatePIP from "../../../../components/modal/hr-section/employee-pip/resolve-reinstate";
import ExtendPIP from "../../../../components/modal/hr-section/employee-pip/extend-pip";
import DataTable from "../../../../components/datatable";
import {
  useDeletePerformance,
  useGetPerformances,
} from "../../../../redux/human-resources/hook/performance";

const ViewPIP = () => {
  useEffect(() => {}, []);

  const { id } = useParams();

  const { data: pip, isLoading } = useGetPIP(id);

  const [showReinstate, setShowReinstate] = useState(false);
  const [showTerminate, setShowTerminate] = useState(false);
  const [showExtend, setShowExtend] = useState(false);

  const history = useHistory();
  const [tableType, setTableType] = useState("table");

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: performances } = useGetPerformanceByPIP(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: deletePerformanceEvaluation } = useDeletePerformance();

  const tableConfig = {
    headers: ["Date Created", "Period Covered", "Status"],
    keys: ["created", "period", "status"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Period Covered",
        key: "period",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = performances.data.findIndex((item) => item.id === id);
        if (index !== -1) {
          history.push("/performance/view/" + performances.data[index].id);
        }
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = performances.data.findIndex((item) => item.id === id);
        deletePerformanceEvaluation(performances.data[index].id);
      },
    },
  ];

  return (
    <DashboardLayout title="View Employee PIP">
      {showTerminate ? (
        <ResolveTerminatePIP
          isOpen={showTerminate}
          closeModal={() => setShowTerminate(false)}
          pip={pip}
        />
      ) : null}
      {showReinstate ? (
        <ResolveReinstatePIP
          isOpen={showReinstate}
          closeModal={() => setShowReinstate(false)}
          pip={pip}
        />
      ) : null}
      {showExtend ? (
        <ExtendPIP
          isOpen={showExtend}
          closeModal={() => setShowExtend(false)}
          pip={pip}
        />
      ) : null}

      <div className="flex flex-col gap-4 -mt-8">
        <div className="flex flex-row items-center box-shadow h-[104px]">
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-row px-[55px] justify-between  w-full">
              <div className="flex flex-row items-center">
                <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200">
                  {Initials(pip?.first_name, pip?.last_name)}
                </div>
                <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                  <span className="p1-bold">
                    {pip?.first_name} {pip?.last_name}
                  </span>
                  <span className="text-[13px] text-color-gray">
                    {pip?.email}
                  </span>
                </div>
              </div>
              {pip ? (
                <div className="flex flex-row items-center">
                  <StatusText
                    statusConfig={pipStatusConfig}
                    code={pip?.status}
                    className={"py-1 rounded p1-bold px-3 p2-bold text-[13px]"}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="flex flex-col bg-white min-h-fit">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
            <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
              Performance Improvement Plan Details
            </div>
            {pip?.status !== 1 && pip?.status !== 3 ? (
              <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="RESOLVE AND RE-INSTATE"
                    type="button"
                    leftIcon={"check"}
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    onClick={() => {
                      setShowReinstate(true);
                    }}
                    textClass={"!text-[11px]"}
                  />
                </div>
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="RESOLVE AND TERMINATE"
                    type="button"
                    leftIcon={"delete"}
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    onClick={() => {
                      setShowTerminate(true);
                    }}
                    textClass={"!text-[11px]"}
                  />
                </div>
              </div>
            ) : pip?.status === 3 ? (
              <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="VIEW EXIT INFORMATION"
                    type="button"
                    leftIcon={"view"}
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    onClick={() => {
                      history.push("/exit/view/" + pip.exit_id);
                    }}
                    textClass={"!text-[11px]"}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <hr className="divider" />
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-col md:flex-row h-full p-4 pt-1 gap-3">
              <div className="flex flex-col w-[100%] md:w-[45%] gap-3">
                <div className="gap-2 flex flex-col border rounded-md ">
                  <div className="flex flex-row justify-between items-center bg-blue-100 p-3">
                    <div className="font-medium text-[14px] text-color-black flex flex-col">
                      Performance Improvement Plan Information
                    </div>
                  </div>
                  <div className="flex flex-col px-3 pb-3 gap-3">
                    <div className="flex flex-col">
                      <div className="text-[14px] font-extrabold">
                        Performance Improvement Plan Period
                      </div>
                      <div className="text-[13px] font-normal">
                        {moment(pip?.start_date).format("MMMM DD, YYYY")} -{" "}
                        {moment(pip?.end_date).format("MMMM DD, YYYY")}
                      </div>
                    </div>
                    {pip?.status !== 1 && pip?.status !== 3 ? (
                      <div className="flex justify-end w-[100%] gap-3">
                        <Button
                          text="EXTEND PIP"
                          type="button"
                          className="flex gap-2 !h-[35px] w-fit px-2 items-center"
                          onClick={() => {
                            setShowExtend(true);
                          }}
                          textClass={"!text-[11px]"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="w-[100%] h-fit">
                  <PIPComments pip={pip} />
                </div>
              </div>
              <div className="flex flex-col gap-2 w-[100%] md:w-[55%]">
                <div className="flex flex-col border rounded-md">
                  <div className="font-medium text-[14px] text-color-black flex flex-row justify-between items-center bg-blue-100 p-3">
                    <div>Performance Evaluations</div>
                    {pip?.status !== 1 && pip?.status !== 3 ? (
                      <div className="flex justify-end md:w-[50%]  gap-3">
                        <Button
                          text="EVALUATE"
                          type="button"
                          leftIcon={"evaluate"}
                          className="flex gap-2 !h-[35px] w-fit px-2 items-center"
                          onClick={() => {
                            history.push(
                              `/performance/select-option?pip_id=${pip?.id}&start_date=${pip?.start_date}&end_date=${pip?.end_date}&employee_id=${pip?.employee_id}&first_name=${pip?.first_name}&last_name=${pip?.last_name}&mobile=${pip?.mobile}&email=${pip?.email}`
                            );
                          }}
                          textClass={"!text-[11px]"}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-3">
                    <DataTable
                      data={performances}
                      tableConfig={tableConfig}
                      actionConfig={actionConfig}
                      statusConfig={PerformanceEvaluationStatusConfig}
                      updatePagination={(data) => setPagination(data)}
                      computedActions={true}
                      tableType={tableType}
                      noCheck={true}
                      layoutSwitch={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewPIP;
