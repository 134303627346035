import React, { useState } from 'react';
import {
  Collapse,
} from 'reactstrap';
import DropDownSVG from '../assets/svg/chevron-down.svg';
import { useEffect } from 'react';
import LoadingBar from './loader-bar';
import { useSelector } from 'react-redux';
import { isLoading } from '../redux/components/components-slice';

const OnboardingDataTable = ({
  tableConfig,
  data,
}) => {
  const loading = useSelector(isLoading);

  const [mobileCollapseView, setMobileCollapseView] = useState([]);
  const toggleMobileCollapseView = (index) => {
    setMobileCollapseView((current) =>
      current.map((obj, i) => {
        if (index === i) {
          obj = !mobileCollapseView[index];
        }
        return obj;
      }),
    );
  };

  useEffect(() => {
    //Set Mobile Collapse State Set
    if (data && data.data && data.data.length > 0) {
      let mobileCollapseViewList = [];

      data.data.forEach((element) => {
        mobileCollapseViewList.push(false);
      });
      setMobileCollapseView(mobileCollapseViewList);
    }
  }, [data]);

  return (
    <>
      {tableConfig ? (
        <div className='bg-white w-full min-h-[100px] flex flex-col'>
          <div className='flex flex-col w-[100%] flex-wrap gap-2 justify-start md:flex-row pr-4 md:justify-space-evenly items-left md:items-start h-full'>
            {tableConfig.title ? (
              <div className='pr-2 md:flex h-[50px] md:mt-2 text-[16px] font-extrabold items-center min-w-fit'>
                <span className=''>{tableConfig.title}</span>
              </div>
            ) : null}
          </div>

          <LoadingBar loading={loading} />
          {/* Mobile list View */}
          <div className='flex flex-col'>
            <div className='h-[60px] flex flex-row bg-secondary-2-extralight px-3 items-center'>
              <div className='flex flex-row items-center justify-start w-[50%]'>
                <div>
                  <span className='flex p4-medium text-color-black-3'>
                    {tableConfig.mobileHeader.left.title}
                  </span>
                </div>
              </div>
              <div className='ml-2'>
                <span className='flex p4-medium text-color-black-3'>
                  {tableConfig.mobileHeader.right.title}
                </span>
              </div>
            </div>

            {data && data.data ? (
              <>
                {/* Mobile View */}
                <div className='flex flex-col'>
                  {data.data.map((dataRow, index) => (
                    <>
                      <div
                        key={index}
                        onClick={() => toggleMobileCollapseView(index)}
                        className='h-[55px] flex justify-center hover:cursor-pointer hover:bg-gray-100 hover:px-4 duration-300'
                      >
                        <button className='flex flex-row items-center w-full justify-start'>
                          <div className='w-1/2 flex'>
                            <span className='p4-medium text-color-black-3'>
                              {dataRow[tableConfig.mobileHeader.left.key]}
                            </span>
                          </div>
                          <div className='flex flex-row items-center justify-between w-1/2'>
                            <span className='p4-bold text-color-black-3 pr-3'>
                            {dataRow[tableConfig.mobileHeader.right.key]}
                            </span>
                            <img src={DropDownSVG} alt='' width={'14px'} />
                          </div>
                        </button>
                      </div>
                      <Collapse isOpen={mobileCollapseView[index]}>
                        <div className='flex flex-col w-full'>
                          {tableConfig.keys.map((key, index) => (
                            <div className='flex flex-row justify-between py-2 text-[14px]'>
                              <span className='text-color-black-3 capitalize font-semibold'>
                                {tableConfig.headers[index]}
                              </span>
                              {dataRow[key] !== 'employeeName'?
                              <span > {dataRow[key]}</span>
                              :
                              <span > {dataRow[key]}</span>
                              }
                            </div>
                          ))}
                        </div>
                      </Collapse>
                    </>
                  ))}
                </div>
              </>
            ) :
            null
            }
          </div>
        </div>
      ) : null}
    </>
  );
};

export default OnboardingDataTable;
