import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { setInvoice} from './reducer';
import { invoices_received } from '../../../api/spend/invoice-received';
import { formatNumber } from '../../../utils/functions';


export function useGetInvoicesReceived(limit, page, statusFilter, search) {
  return useQuery(
    ['invoices-received', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return invoices_received.getInvoicesReceived({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((invoice_received) => {
          //compute action items
          invoice_received.created = moment(invoice_received.created).format('MMMM D, YYYY');
          invoice_received.formatted_invoice_date = moment(invoice_received.invoice_date).format('MMMM D, YYYY');
          invoice_received.formatted_invoice_due_date = moment(invoice_received.invoice_due_date).format('MMMM D, YYYY');
          invoice_received.formatted_total_amount = formatNumber(invoice_received.total_amount, 2);
          if (invoice_received.status !== 1) {
            if (moment(invoice_received.invoice_due_date).diff(moment(invoice_received.invoice_date), 'days' ) > 0) {
              invoice_received.due_status = 0
            } else if (moment(invoice_received.invoice_due_date).diff(moment(invoice_received.invoice_date), 'days' ) == 0) {
              invoice_received.due_status = 2
            } else {
              invoice_received.due_status = 3
            }
          } else {
            invoice_received.due_status = '1'
          }
          
          switch (invoice_received.status) {
            case 0:
              invoice_received.computedActions = [0, 1, 2, 3, 4, 5];
              break;
            case 1:
              invoice_received.computedActions = [2, 4];
              break;
            case 2:
              invoice_received.computedActions = [0, 1, 2, 3, 4];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetInvoicesReceivedByVendor(id, limit, page, statusFilter, search) {
  return useQuery(
    ['invoice-by-vendor', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return invoices_received.getInvoicesReceivedByVendor({ id, limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((invoice_received) => {
          //compute action items
          invoice_received.created = moment(invoice_received.created).format('MMMM D, YYYY');
          invoice_received.invoice_date = moment(invoice_received.invoice_date).format('MMMM D, YYYY');
          invoice_received.invoice_due_date = moment(invoice_received.invoice_due_date).format('MMMM D, YYYY');
          invoice_received.formatted_total_amount = formatNumber(invoice_received.total_amount, 2);
          if (invoice_received.status !== 1) {
            if (moment(invoice_received.invoice_due_date).diff(moment(invoice_received.invoice_date), 'days' ) > 0) {
              invoice_received.due_status = 0
            } else if (moment(invoice_received.invoice_due_date).diff(moment(invoice_received.invoice_date), 'days' ) == 0) {
              invoice_received.due_status = 2
            } else {
              invoice_received.due_status = 3
            }
          } else {
            invoice_received.due_status = '1'
          }
          switch (invoice_received.status) {
            case 0:
                invoice_received.computedActions = [0, 1, 2];
              break;
            case 1:
                invoice_received.computedActions = [0, 1, 3];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetInvoiceReceivedPayments(id, limit, page, statusFilter, search) {
  return useQuery(
    ['invoice-received-payments', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return invoices_received.getInvoicesReceivedPayments({ id, limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((invoice_received) => {
          //compute action items
          invoice_received.created = moment(invoice_received.created).format('MMMM D, YYYY');
          invoice_received.invoice_date = moment(invoice_received.invoice_date).format('MMMM D, YYYY');
          invoice_received.invoice_due_date = moment(invoice_received.invoice_due_date).format('MMMM D, YYYY');
          invoice_received.formatted_total_amount = formatNumber(invoice_received.total_amount, 2);
          if (invoice_received.status !== 1) {
            if (moment(invoice_received.invoice_due_date).diff(moment(invoice_received.invoice_date), 'days' ) > 0) {
              invoice_received.due_status = 0
            } else if (moment(invoice_received.invoice_due_date).diff(moment(invoice_received.invoice_date), 'days' ) == 0) {
              invoice_received.due_status = 2
            } else {
              invoice_received.due_status = 3
            }
          } else {
            invoice_received.due_status = '1'
          }
          switch (invoice_received.status) {
            case 3:
                invoice_received.computedActions = [0, 1];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetInvoiceReceived(id) {
  return useQuery(
    ['invoice-received', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return invoices_received.getInvoiceReceived(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setInvoice(response));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateInvoiceReceived() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices_received.updateInvoiceReceived(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('invoices-received');
        queryClient.invalidateQueries('invoice-received-by-purchase-order');
        queryClient.invalidateQueries('invoice-received');
        
        store.dispatch(setAlert(true, 'success',  'Invoice received updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCreateSingleInvoiceReceived() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices_received.createSingleInvoiceReceived(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setInvoice(response.data));
        queryClient.invalidateQueries('invoices-received');
        queryClient.invalidateQueries('invoice-received-by-purchase-order');
        queryClient.invalidateQueries('invoice-received');
        store.dispatch(setAlert(true, 'success',  'Invoice received added successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMarkInvoiceReceivedPaid() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices_received.markInvoiceReceivedPaid(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('invoices-received');
        queryClient.invalidateQueries('invoice-received-by-purchase-order');
        queryClient.invalidateQueries('invoice-received');
        store.dispatch(setAlert(true, 'success', 'Invoice received status change successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMarkInvoiceUnpaid() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices_received.markInvoiceUnpaid(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('invoices-received');
        queryClient.invalidateQueries('invoice-received-by-purchase-order');
        queryClient.invalidateQueries('invoice-received');
        store.dispatch(setAlert(true, 'success', 'Invoice received status change successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteInvoiceReceived() {
  return useMutation(
    (payload) => {
      return invoices_received.deleteInvoiceReceived(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('invoices-received');
        queryClient.invalidateQueries('invoice-received-by-purchase-order');
        queryClient.invalidateQueries('invoice-received');
        queryClient.invalidateQueries('invoice-received-payments');
        store.dispatch(setAlert(true, 'success', 'Invoice received deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInitiateInvoiceReceivedPayment() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices_received.initiateInvoicePayment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('invoices-received');
        queryClient.invalidateQueries('invoice-received-by-purchase-order');
        queryClient.invalidateQueries('invoice-received');
        queryClient.invalidateQueries('invoice-received-payments');
        store.dispatch(setAlert(true, 'success', 'Payment initiated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDisburseInvoiceReceivedPayment() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices_received.disburseInvoicePayment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('invoices-received');
        queryClient.invalidateQueries('invoice-received-by-purchase-order');
        queryClient.invalidateQueries('invoice-received');
        queryClient.invalidateQueries('invoice-received-payments');
        store.dispatch(setAlert(true, 'success', 'Payment disbursed successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineInvoiceReceivedPayment() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return invoices_received.declineInvoicePayment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('invoices-received');
        queryClient.invalidateQueries('invoice-received-by-purchase-order');
        queryClient.invalidateQueries('invoice-received');
        queryClient.invalidateQueries('invoice-received-payments');
        store.dispatch(setAlert(true, 'success', 'Payment declined successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

