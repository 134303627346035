import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { attendances } from '../../../api/hr/attendance-management';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';

export function useGetAttendanceSummary() {
  return useQuery(['attendance-summary'], () => {
    return attendances.getAttendanceSummary();
  });
}

export function useGetAttendanceWorkSchedules(
  id,
  start_date,
  end_date,
) {
  return useQuery(
    ['attendance-work-schedule', {id, start_date, end_date }],
    () => {
      return attendances.getAttendanceWorkSchedules({
        id,
        start_date,
        end_date,
      });
    },
    
    {
      enabled: id !== null,
      select(data) {
        data?.forEach((schedule) => {
          schedule.start = moment(schedule.start).toDate();
          schedule.end = moment(schedule.end).toDate();
          if (schedule.title == 'Present') {
            schedule.color = '#1bb21b'
          } else if (schedule.title == 'Off Day') {
            schedule.color = '#00d2ff'
          } else if (schedule.title == 'Work day') {
            schedule.color = '#d2f8d2'
          } else {
            schedule.color = '#f1807e'
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetAttendances(
  limit,
  page,
  statusFilter,
  search,
  start_date,
  end_date,
) {
  return useQuery(
    [
      'attendances',
      { limit, page, statusFilter, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendances({
        limit,
        page,
        statusFilter,
        search,
        start_date,
        end_date,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((attendance) => {
          attendance.employeeName = `${attendance.first_name} ${attendance.last_name}`;
          attendance.created = attendance.created.split('T')[0];
          attendance.computedActions = [0, 1, 2];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetCurrentAttendanceInformation(
  limit,
  page,
  statusFilter,
  search,
  attendance_date,
) {
  return useQuery(
    [
      'current-attendance-data',
      { limit, page, statusFilter, search, attendance_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getCurrentAttendances({
        limit,
        page,
        statusFilter,
        search,
        attendance_date,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((attendance) => {
          attendance.employeeName = `${attendance.first_name} ${attendance.last_name}`;
          attendance.created = attendance.created.split('T')[0];
          attendance.attendance_date = attendance.attendance_date.split('T')[0];
          attendance.status = attendance.punctuality_status;
          attendance.employee_name = attendance.first_name + ' ' + attendance.last_name;
          attendance.computedActions = [0, 1, 2];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddAttendance() {
  return useMutation(
    (payload) => {
      return attendances.addAttendance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('attendance-work-schedule');
        queryClient.invalidateQueries('attendances');
        store.dispatch(setAlert(true, 'success', 'Attendance added successfully'));
      },
    },
  );
}

export function useBulkAddAttendance() {
  return useMutation(
    (payload) => {
      return attendances.bulkAddBAttendance(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('attendance-work-schedule');
        queryClient.invalidateQueries('attendances');
        store.dispatch(setAlert(true, 'success', 'Attendance information imported successfully'));
      },
    },
  );
}



export function useGetAttendance(id) {
  return useQuery(
    ['attendance', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendance(id);
    },
    {
      enabled: id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useExportAttendanceHistory() {
  return useMutation(
    (payload) => {
      store.dispatch(setAlert(true, 'success', 'Fetching Export Data...'));
      return attendances.getAttendanceByEmployee(payload);
    },
    {
      onSuccess: (data) => {
        data?.data?.forEach((attendance) => {
        })
        store.dispatch(setAlert(true, 'success', 'Attendance data fetched Successfully'));
        return data;
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAttendanceByEmployee(
  employee_id,
  limit,
  page,
  statusFilter,
  start_date,
  end_date,
) {
  return useQuery(
    [
      'attendance-by-employee',
      { employee_id, limit, page,statusFilter, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendanceByEmployee({
        employee_id,
        limit,
        page,
        statusFilter,
        start_date,
        end_date,
      });
    },
    {
      enabled: employee_id !== null ? true : false,
      select(data) {
        data?.data?.forEach((attendances) => {
          attendances.employeeName = attendances.first_name + ' ' + attendances.last_name
          attendances.computedActions = [0, 1];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useUpdateAttendance() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return attendances.updateAttendance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('attendance-work-schedule');
        queryClient.invalidateQueries('attendances');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMarkAttendance() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return attendances.markAttendance(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('attendance-work-schedule');
        queryClient.invalidateQueries('attendances');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAttendanceSettings(source, source_id = null) {
  return useQuery(
    ['attendance-settings', { source, source_id }],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendanceSettings({
        source,
        source_id,
      });
    },
    {
      enabled: source !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateAttendanceSettings() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return attendances.updateAttendanceSettings(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('attendance-work-schedule');
        queryClient.invalidateQueries('attendance-settings');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAttendanceSchedules(
  limit,
  page,
  search,
  start_date,
  end_date,
) {
  return useQuery(
    ['attendance-schedules', { limit, page, search, start_date, end_date }],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendanceSchedules({
        limit,
        page,
        search,
        start_date,
        end_date,
      });
    },
    {
      select(data) {
        data?.data?.forEach((schedule) => {
          if (!schedule.clockin_time) {
            schedule.clockin_time = '-'
          }
          if (!schedule.clockout_time) {
            schedule.clockout_time = '-'
          }

          // schedule.working_day = schedule.working_day == 'yes'?'Yes':'No'
          schedule.computedActions = [0, 1];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddBulkAttendanceSchedules() {
  return useMutation(
    (payload) => {
      return attendances.bulkAddBulkAttendanceSchedules(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('attendance-work-schedule');
        queryClient.invalidateQueries('attendance-schedules');
        store.dispatch(
          setAlert(true, 'success', 'Attendance Schedule successfully'),
        );
      },
    },
  );
}

export function useGetAttendanceScheduleBySource(
  source_id,
  source,
  limit,
  page,
  search,
  start_date,
  end_date,
) {
  return useQuery(
    [
      'attendance-schedules-by-source',
      { source_id, source, limit, page, search, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendanceScheduleBySource({
        source_id,
        source,
        limit,
        page,
        search,
        start_date,
        end_date,
      });
    },
    {
      enabled: source_id !== null ? true : false,
      select(data) {
        data?.data?.forEach((schedule) => {
          if (!schedule.clockin_time) {
            schedule.clockin_time = '-'
          }
          if (!schedule.clockout_time) {
            schedule.clockout_time = '-'
          }

          // schedule.working_day = schedule.working_day == 'yes'?'Yes':'No'
          schedule.computedActions = [0, 1];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddAttendanceSchedule() {
  return useMutation(
    (payload) => {
      return attendances.addAttendanceSchedule(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('attendance-work-schedule');
        queryClient.invalidateQueries('attendance-schedules');
        queryClient.invalidateQueries('attendance-schedules-by-source');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useUpdateAttendanceSchedule() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return attendances.updateAttendanceSchedule(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('attendance-work-schedule');
        queryClient.invalidateQueries('attendance-schedules');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteAttendanceSchedule() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return attendances.deleteAttendanceSchedule(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('attendance-work-schedule');
        queryClient.invalidateQueries('attendance-schedules');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetAttendanceSchedule(id) {
  return useQuery(
    ['attendance-schedule', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getAttendanceSchedule(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetEmployeeClockInLink() {
  return useQuery(
    ['employee-clockin-link'],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.getEmployeeClockInLink();
    },
    {
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRefreshEmployeeClockInLink() {
  return useMutation(
    () => {
      return attendances.refreshClockInLink();
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-clockin-link');
        store.dispatch(setAlert(true, 'success', 'Link refreshed successfully'));
      },
    },
  );
}

export function useUpdateApprovedIps() {
  return useMutation(
    (payload) => {
      return attendances.updateApprovedIps(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-clockin-link');
        store.dispatch(setAlert(true, 'success', 'Link approved IPs updated successfully'));
      },
    },
  );
}

export function useChangeEmployeeClockInLinkStatus() {
  return useMutation(
    () => {
      return attendances.changeClockInLinkSatus();
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-clockin-link');
        store.dispatch(setAlert(true, 'success', 'Clockin link accessibility status changed successfully'));
      },
    },
  );
}

export function useFetchCompanyAttendanceInfo(token) {
  return useQuery(
    ['company-attendance-fetch', { token }],
    () => {
      store.dispatch(setIsLoading(true));
      return attendances.fetchCompanyAttendanceInfo( token );
    },
    {
      enabled: token !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Company data fetched successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}



export function useFetchAttendanceDataOnSite() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return attendances.fetchAttendanceDataOnSite(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Attendance Data fetched successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useClockInOutOnSite() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return attendances.clockInOutOnSite(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Attendance updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

    
