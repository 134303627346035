import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../components/button";
import { useGetRecruitmentByToken } from "../../../redux/human-resources/hook/recruitment";
import EmptyTableSVG from "../../../assets/svg/empty-table.svg";
import ApplicantConsent from "../../../components/modal/employees/applicant-consent";
import SingleRecruitmentApplicationLayout from "../../../components/layouts/recruitment-application";
import Logo from "../../../components/logo";
import moment from "moment";
import { Initials, formatNumber } from "../../../utils/functions";
import WarningSVG from "../../../assets/svg/warning.svg";

function ApplicantBriefPage() {
  useEffect(() => {}, []);

  const { token } = useParams();

  const { data: recruitmentBrief, isLoading } = useGetRecruitmentByToken(token);
  const [showConsent, setShowConsent] = useState(true);

  const [expired, setExpired] = useState(true);

  const history = useHistory();

  useEffect(() => {
    setShowConsent(false);
    if (recruitmentBrief) {
      const dateB = moment();
      const dateC = moment(recruitmentBrief?.application_end);
      if (dateC.diff(dateB, "days") < 0) {
        setShowConsent(false);
        setExpired(true);
      } else {
        setShowConsent(true);
        setExpired(false);
      }
    }
  }, [recruitmentBrief]);

  const submitForm = () => {
    history.push(`/recruitment/apply/${token}`, recruitmentBrief);
  };

  return (
    <SingleRecruitmentApplicationLayout
      step={1}
      company_name={recruitmentBrief?.company_name}
      logo={recruitmentBrief?.logo}
      expired={expired}
    >
      {!isLoading ? (
        <>
          {expired ? (
            <div className="flex flex-col items-center justify-center p1-bold text-secondary-2 !min-h-screen">
              <div className="text-[25px] flex justify-center items-center h-fit w-full border-[3px] header-3 rounded-[60px] relative duration-500 text-white font-bold p-2 gap-2 mb-4">
                <div className="text-[25px] flex justify-center items-center h-[60px] w-[60px] border-[3px] header-3 rounded-[60px] relative hover:m-2 duration-500 text-white font-bold gap-2 p-2">
                  {recruitmentBrief?.logo ? (
                    <div>
                      <img
                        src={recruitmentBrief?.logo}
                        alt="email_icon"
                        className="object-fit min-w-[52px] min-h-[52px] max-w-[52px] max-h-[52px]"
                      />
                    </div>
                  ) : (
                    <span className="text-hr-primary-1 font-bold text-[30px]">
                      {Initials(recruitmentBrief?.company_name)}
                    </span>
                  )}
                </div>
                <span className="text-hr-primary-1 header-2 mb-2 text-[25px]">
                  {recruitmentBrief?.company_name}
                </span>
              </div>
              Application for this role has closed!!!
            </div>
          ) : (
            <>
              {recruitmentBrief ? (
                <div className="flex justify-center w-full md:pt-2 h-max !min-w-[80%]">
                  {showConsent ? (
                    <ApplicantConsent
                      isOpen={showConsent}
                      closeModal={() => setShowConsent(false)}
                    />
                  ) : null}
                  {!showConsent ? (
                    <div
                      className={
                        "flex flex-col justify-between gap-4 !min-w-[80%]"
                      }
                    >
                      <div className="flex flex-col w-full px-4 md:px-0 overflow-auto h-max">
                        <div className="w-full text-center flex flex-col pb-[25px] md:pt-[20px] pt-2">
                          <span className="header-2-regular text-hr-primary-1">
                            Recruitment Brief
                          </span>
                          <span className="text-[14px] font-normal text-hr-primary-1 mt-1">
                            Check the job information to see that you are a fit
                            for this role.
                          </span>
                        </div>
                        <hr className="divider -mt-2  md:-mb-2" />
                        <div className="md:hidden flex flex-col bg-highlight rounded-md p-2">
                          <div className="flex flex-col">
                            <span className="text-[15px] font-bold text-color-gray">
                              Company Name
                            </span>
                            <span className="text-[14px] font-semibold text-hr-primary-1">
                              {recruitmentBrief?.company_name}
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col mt-4 md:mt-1">
                          <span className="header-4 mb-2">Job Information</span>
                          <div className="flex flex-col mt-1 bg-highlight rounded-md p-3">
                            <div className="grid grid-cols-2 gap-3 w-full">
                              <div className="flex flex-col">
                                <span className="text-[14px] text-color-gray">
                                  Role
                                </span>
                                <span className="mt-1 text-color-black text-[13px] font-bold">
                                  {recruitmentBrief?.role}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[14px] text-color-gray">
                                  Job Type
                                </span>
                                <span className="mt-1 text-color-black text-[13px] font-bold">
                                  {recruitmentBrief?.job_type}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[14px] text-color-gray">
                                  Location Type
                                </span>
                                <span className="mt-1 text-color-black text-[13px] font-bold">
                                  {recruitmentBrief?.location_type}
                                </span>
                              </div>

                              <div className="flex flex-col">
                                <span className="text-[14px] text-color-gray">
                                  Minimum Education
                                </span>
                                <span className="mt-1 text-color-black text-[13px] font-bold">
                                  {recruitmentBrief?.min_education}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[14px] text-color-gray">
                                  Required Experience
                                </span>
                                <span className="mt-1 text-color-black text-[13px] font-bold">
                                  {recruitmentBrief?.years_of_experience}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[14px] text-color-gray">
                                  Experience Level
                                </span>
                                <span className="mt-1 text-color-black text-[13px] font-bold">
                                  {recruitmentBrief?.experience_level}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[14px] text-color-gray">
                                  Salary Type
                                </span>
                                <span className="mt-1 text-color-black text-[13px] font-bold">
                                  {recruitmentBrief?.salary_type}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[14px] text-color-gray">
                                  Salary Range (Monthly)
                                </span>
                                <span className="mt-1 text-color-black text-[13px] font-bold">
                                  {recruitmentBrief?.min_salary &&
                                  recruitmentBrief?.max_salary ? (
                                    <>
                                      {formatNumber(
                                        parseFloat(
                                          recruitmentBrief?.min_salary
                                        ),
                                        2
                                      )}{" "}
                                      -{" "}
                                      {formatNumber(
                                        parseFloat(
                                          recruitmentBrief?.max_salary
                                        ),
                                        2
                                      )}
                                    </>
                                  ) : recruitmentBrief?.min_salary ? (
                                    <>
                                      {formatNumber(
                                        parseFloat(
                                          recruitmentBrief?.min_salary
                                        ),
                                        2
                                      )}
                                    </>
                                  ) : recruitmentBrief?.max_salary ? (
                                    <>
                                      {formatNumber(
                                        parseFloat(
                                          recruitmentBrief?.max_salary
                                        ),
                                        2
                                      )}
                                    </>
                                  ) : (
                                    <div>-</div>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="divider" />
                        <div className="flex flex-col">
                          <div className="flex flex-col">
                            <span className="header-4 pb-2">
                              Job Description
                            </span>
                            <td
                              className="border-1 p-3 rounded !max-h-[600px] overflow-auto !text-[13px] leading-[28px]"
                              dangerouslySetInnerHTML={{
                                __html: recruitmentBrief?.job_description,
                              }}
                            />
                          </div>
                        </div>
                        {recruitmentBrief?.use_keywords ? (
                          <div className="flex flex-col justify-center mt-3">
                            <div className="flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light !text-[14px]">
                              <div className="w-[70px] pr-2 flex flex-row items-center ">
                                <img src={WarningSVG} alt="" srcSet="" />
                              </div>
                              <span className="md:text-[12px] text-[11px] text-justify">
                                Recruitment keyword is enabled for this
                                recruitment. Please ensure that your CV is text
                                readable before uploading.
                              </span>
                            </div>
                          </div>
                        ) : null}
                        <div className="w-full mt-[20px] pb-2">
                          <Button
                            text="Start Application"
                            type="button"
                            className={"!bg-hr-primary-1"}
                            disabled={expired}
                            onClick={() => submitForm()}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          "md:hidden flex items-center justify-center text-color-black gap-1"
                        }
                      >
                        <div className="text-[13px] -mt-2">Powered By: </div>
                        <a
                          href="https://www.ropay.africa"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <Logo width="80px" height="40px" blue />
                        </a>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-screen">
                  <img
                    src={EmptyTableSVG}
                    alt="No Data Fetched"
                    width={"80%"}
                    height={"200px"}
                  />
                  <span className="header-4 mt-3">
                    Invalid Application Token
                  </span>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="flex flex-row w-[100%] items-center justify-center animate-pulse p1-bold text-primary-2 !min-h-screen">
          Loading...
        </div>
      )}
    </SingleRecruitmentApplicationLayout>
  );
}

export default ApplicantBriefPage;
