import React, { useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Modal from '../../modal';
import Button from '../../../button';
import { useResendVideoAssessment } from '../../../../redux/human-resources/hook/recruitment';

function ApplicantAssessmentVideo ({
    isOpen,
    closeModal,
    applicant,
}) {
    const { mutateAsync: videoAssessment, isLoading: videoAssessmentLoading } = useResendVideoAssessment();
    
    return (
        <Modal
            scrollable={true}
            className='!min-w-[400px] max-w-[90%] !rounded-none'
            isOpen={isOpen}
            centered={true}
        >
            <div className='mb-10 overflow-auto'>
                <div className='flex flex-col overflow-auto justify-center'>
                    <div className='pl-8 pr-8 pt-4'>
                        <div className='flex flex-row justify-between items-center'>
                        <span className='header-4'>Applicant Video</span>
                        <div className='cursor-pointer' onClick={() => closeModal()}>
                            <img src={CloseSVG} alt='close' />
                        </div>
                        </div>
                    </div>
                    <hr className='divider mb-3' />
                    <div className='flex flex-col mx-8 items-start'>
                        {applicant?.video_assessment?.video_url ?
                        <div className='flex items-center justify-center w-full border-2 border-hr-primary-1 rounded-md p-2'>
                            <video src={applicant?.video_assessment?.video_url} controls>

                            </video>
                        </div>
                        :
                        <div className='flex flex-col gap-2 items-center justify-center w-full'>
                            <span className='text-[14px]'>No video uploaded yet by applicant</span>
                            <div className='flex md:flex-row flex-col gap-3 w-full mt-[20px] pb-4 '>
                                <Button
                                    loading={videoAssessmentLoading}
                                    disabled={videoAssessmentLoading}
                                    onClick={ async () => {
                                        await videoAssessment(applicant?.video_assessment?.id).then(() => {
                                            closeModal()
                                        })
                                    }}
                                    text={'RESEND ASSESSMENT MAIL'}
                                    type='button'
                                />
                            </div>
                        </div>  
                        }
                        
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ApplicantAssessmentVideo;
