import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../../components/button";
import DashboardLayout from "../../../components/layouts/dashboard";
import { updateCurrentCompany } from "../../../redux/company/hook";
import {
  useRefBasicPayroll,
  useRemoveBasicPayrollById,
  useRevertPayroll,
} from "../../../redux/payroll/hook";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import PayrollSummary from "../../../components/modal/payroll/payroll-summary";
import BasicPayrollDetailedView from "../../../components/modal/payroll/basic-payroll-full-view";
import { useSelector } from "react-redux";
import UnauthorizedPage from "../../errors/unauthorized";
import { useRouteQuery } from "../../../hooks/useRouteQuery";
import EmployeePaymentDataTable from "../../../components/employee-payment-datatable";
import { useGetCompanyAccountDetails } from "../../../redux/wallet/hook";

const BasicPayrollPreviewPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);
  const { data: companyAccountDetails } = useGetCompanyAccountDetails();

  const query = useRouteQuery();

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (
          user.permissions.company_permission.payroll_read !== 1 ||
          user.permissions.payroll_permission?.basic_payroll_write !== 1
        ) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });
  const [payrollStatus, setpayrollStatus] = useState("");
  const [showPayrollSummary, setShowPayrollSummary] = useState(false);
  const [approve, setApprove] = useState("");
  const [previewData, setPreviewData] = useState();
  const [previewType, setPreviewType] = useState();
  const [showBasicPayrollDetailedView, setShowBasicPayrollDetailedView] =
    useState(false);

  const history = useHistory();

  useEffect(() => {
    if (query) {
      if (query.get("approve")) {
        setpayrollStatus("approve");
        setShowPayrollSummary(true);
      }
    }
  }, [query]);

  const { mutateAsync: revertPayroll } = useRevertPayroll();
  const { mutateAsync: removePayrollEntry } = useRemoveBasicPayrollById();

  const { refId } = useParams();
  const {
    refetch,
    data: basicPayroll,
    isLoading: loading,
  } = useRefBasicPayroll(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  // useEffect(() => {
  //   if (basicPayroll) {
  //     if (loading === false && basicPayroll.data.length < 1){
  //       revertPayroll().then(() => {
  //         history.push('/payroll', {tab: 2})
  //       })
  //     }
  //   }
  // }, [basicPayroll, loading]);

  useEffect(() => {
    updateCurrentCompany();
  }, []);

  const tableConfig = {
    headers: ["Employee Name", "Amount", "Fee", "Total"],
    keys: ["employeeName", "net_pay", "trans_cost", "total"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Amount",
        key: "net_pay",
      },
    },
  };

  const actionConfig = [
    {
      name: "Detailed Info",
      color: "orange",
      action: (id) => {
        let index = basicPayroll.data.findIndex((item) => item.id === id);
        setPreviewData(basicPayroll.data[index]);
        setPreviewType("view");
        setShowBasicPayrollDetailedView(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: async (id) => {
        let index = basicPayroll.data.findIndex((item) => item.id === id);
        await removePayrollEntry(basicPayroll.data[index].id);
      },
    },
  ];

  return (
    <DashboardLayout title="Basic Payroll Preview">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {previewData && showBasicPayrollDetailedView ? (
            <BasicPayrollDetailedView
              isOpen={showBasicPayrollDetailedView}
              closeModal={() => setShowBasicPayrollDetailedView(false)}
              viewData={previewData}
              previewType={previewType}
            />
          ) : null}
          <div className="flex flex-col">
            <div className="flex md:flex-row flex-col justify-between items-center  gap-4">
              <div className="flex flex-col">
                <span className="h4 text-color-black">
                  A Quick Preview Before You Submit
                </span>
                <span className="text-[13px] text-color-gray -mt-2">
                  Please spend a brief moment reviewing these numbers before you
                  submit for payment
                </span>
              </div>
              {user?.permissions?.payroll_permission?.basic_payroll_write ===
                1 || user?.permissions?.company_permission?.role === "Owner" ? (
                <div className="w-full md:w-[220px] h-[56px]">
                  <Button
                    text={
                      payrollStatus == "approve" ? "Approve Payment" : "Pay Now"
                    }
                    type="submit"
                    loading={false}
                    disabled={false}
                    className="h-[56px]"
                    onClick={() => setShowPayrollSummary(true)}
                  />
                </div>
              ) : null}
            </div>
            <div className="mt-8">
              <>
                {showPayrollSummary ? (
                  <PayrollSummary
                    refValue={refId}
                    type="basic"
                    isOpen={showPayrollSummary}
                    closeModal={() => setShowPayrollSummary(false)}
                    takeHome={basicPayroll?.total_net_pay}
                    transCost={basicPayroll?.total_trans_cost}
                    totalAmount={basicPayroll?.total_payable}
                    payrollStatus={payrollStatus}
                  />
                ) : null}

                <div className="flex md:flex-row gap-2 flex-wrap-reverse justify-between w-full">
                  <div className="w-[100%] md:w-[65%] bg-white rounded">
                    <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
                      <div className="p3-bold header-4">
                        Employee Payment Breakdown
                      </div>
                    </div>
                    <hr className="divider mt-2 mb-2" />
                    <div className="w-[100%]">
                      <EmployeePaymentDataTable
                        data={basicPayroll}
                        tableConfig={tableConfig}
                        actionConfig={actionConfig}
                        noCheck={true}
                        refId={refId}
                        searchData={true}
                        refetch={refetch}
                        computedActions={true}
                        updatePagination={(data) => setPagination(data)}
                      />
                    </div>
                  </div>
                  <div className="w-[100%] md:w-[34%] max-w-[500px] flex flex-col md:pl-8">
                    <div className="bg-white rounded flex flex-col w-full p-3 ">
                      <div className="mb-2"></div>
                      <div className="mb-2">
                        <div className="flex flex-row justify-between">
                          <span className="p4-medium text-secondary-2">
                            Total Amount
                          </span>
                          <span className="p4-medium  text-color-black">
                            {basicPayroll?.formatted_total_net_pay}
                          </span>
                        </div>
                        <hr className="divider" />
                      </div>
                      <div className="mb-2">
                        <div className="flex flex-row justify-between">
                          <span className="p4-medium text-secondary-2">
                            Transaction cost
                          </span>
                          <span className="p4-medium  text-color-black">
                            {basicPayroll?.formatted_total_trans_cost}
                          </span>
                        </div>
                        <hr className="divider" />
                      </div>
                      <div className="mb-2">
                        <div className="flex flex-row justify-between">
                          <span className="p4-medium text-secondary-2">
                            Total Payable
                          </span>
                          <span className="p4-medium  text-color-black">
                            {basicPayroll?.formatted_total_payable}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default BasicPayrollPreviewPage;
