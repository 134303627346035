import React, { useState } from 'react';
import Button from '../../button';
import ButtonSelect from '../../button-select';
import CloseSVG from '../../../assets/svg/close.svg';
import Modal from '../modal';
import AddOvertimeModal from './add-overtime';
import BulkAddOvertime from './bulk-add-overtime';

function SelectOvertimeOption({ isOpen, closeModal, refresh }) {
  const [option, setOption] = useState('single');
  const [showAddOvertime, setShowAddOvertime] = useState(false);
  const [showAddBulkOvertime, setShowAddBulkOvertime] =
    useState(false);

  function submit() {
    if (option === 'single') {
      setShowAddOvertime(true);
    } else if (option === 'bulk') {
      setShowAddBulkOvertime(true);
    }
  }

  return (
    <>
      {showAddOvertime ? (
        <AddOvertimeModal
          isOpen={showAddOvertime}
          completed={() => {
            setShowAddOvertime(false);
            closeModal();
          }}
          closeModal={() => {
            setShowAddOvertime(false);
          }}  
          overtimeData={null}
          title='Add Employee Overtime'
          buttonText='SAVE OVERTIME'
        />
      ) : null}
      
      {showAddBulkOvertime ? (
      <BulkAddOvertime
        isOpen={showAddBulkOvertime}
        closeModal={() => setShowAddBulkOvertime(false)}
        completed={() => {
          setShowAddBulkOvertime(false);
          closeModal();
        }}
      />
      ) : null}
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between'>
              <span className='header-4'>Select overtime upload option</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col items-center w-full gap-3'>
            <span className='text-[15px] -mb-6'>
              Select your desired option to add Employee Overtime
            </span>
            <div className='flex flex-col justify-center h-full min-h-[400px] max-w-[85%] w-full'>
              <ButtonSelect
                title={'Add Single Employee Overtime'}
                subtitle={'Add Overtime one after the other'}
                isActive={option === 'single'}
                onClick={() => setOption('single')}
              />

              <ButtonSelect
                title={'Import Employee Overtime'}
                subtitle={
                  'Import Overtimes using a pre-defined XLSX template'
                }
                isActive={option === 'bulk'}
                onClick={() => setOption('bulk')}
                className='mt-8'
              />

              <div className='w-full mt-[30px] mb-[20px]'>
                <Button text='CONTINUE' type='submit' onClick={() => submit()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SelectOvertimeOption;
