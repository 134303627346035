import React, { useState } from 'react';
import Button from '../../../button';
import ButtonSelect from '../../../button-select';
import AddVendor from './add-vendor';
import CloseSVG from '../../../../assets/svg/close.svg';
import BulkAddVendors from './add-bulk-vendor';
import Modal from '../../modal';

function SelectVendorOption({ isOpen, closeModal, refresh }) {
  const [option, setOption] = useState('single');
  const [showAddVendor, setShowAddVendor] = useState(false);
  const [showAddBulkVendors, setShowAddBulkVendors] = useState(false);

  function submit() {
    if (option === 'single') {
      setShowAddVendor(true);
    } else if (option === 'bulk') {
      setShowAddBulkVendors(true);
    }
  }
  return (
    <>
      {showAddVendor ? (
        <AddVendor
          isOpen={showAddVendor}
          closeModal={() => {
            setShowAddVendor(false);
          }}
          completed={() => {
            setShowAddVendor(false);
            closeModal();
          }}
          vendorData={null}
          title='Add Vendor'
          buttonText='ADD'
        />
      ) : null}

      <BulkAddVendors
        isOpen={showAddBulkVendors}
        closeModal={() => setShowAddBulkVendors(false)}
        completed={() => {
          setShowAddBulkVendors(false);
          closeModal();
        }}
      />

      <Modal
        scrollable={true}
        className='!md:max-w-[500px] max-w-full !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <div className='px-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Add Vendors</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col items-center w-full'>
            <span className='text-[15px] -mb-3'>
              Select your desired option to add Vendors
            </span>
            <div className='flex flex-col justify-center h-full min-h-[400px] max-w-[90%] w-full'>
              <ButtonSelect
                title={'Add Single Vendor'}
                subtitle={'Add your Vendors one after the other'}
                isActive={option === 'single'}
                onClick={() => setOption('single')}
              />

              <ButtonSelect
                title={'Import Vendor information'}
                subtitle={
                  'Import Vendors using a pre-defined XLSX template'
                }
                isActive={option === 'bulk'}
                onClick={() => setOption('bulk')}
                className='mt-8'
              />

              <div className='w-full mt-[20px]'>
                <Button text='NEXT' type='submit' onClick={() => submit()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SelectVendorOption;
