import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import WarningSVG from "../../assets/svg/warning.svg";
import {
  useGetAdvancedPayrollSettings,
  useUpdateAdvancedPayrollSettings,
} from "../../redux/company/hook";
import Button from "../button";
import FormSwitch from "../form-switch";
import LoadingBar from "../loader-bar";
import { formatNumber } from "../../utils/functions";

const PayrollPlans = () => {
  useEffect(() => {}, []);

  const { section } = useSelector((state) => state.sectionSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { data: advanced_payroll, isLoading: advancedLoading } =
    useGetAdvancedPayrollSettings();

  const [taxPension, setTaxPension] = useState(false);
  const [others, setOthers] = useState(false);

  const [taxPensionAmount, setTaxPensionAmount] = useState(500);
  const [othersAmount, setOthersAmount] = useState(0);

  useEffect(() => {
    if (advanced_payroll) {
      setTaxPension(advanced_payroll.data?.tax_pension === 1 ? true : false);
      setOthers(advanced_payroll.data?.other_payments === 1 ? true : false);
    }
  }, [advanced_payroll]);

  const computationPrice =
    company?.payroll_discount > 0
      ? 500 - (500 * company?.payroll_discount) / 100
      : 500;

  useEffect(() => {
    if (taxPension) {
      setTaxPensionAmount(500);
    } else {
      setTaxPensionAmount(0);
    }
    if (others) {
      setOthersAmount(500);
    } else {
      setOthersAmount(0);
    }
  }, [taxPension, others]);

  const { mutateAsync: update_settings, isLoading: updateAdvancedLoading } =
    useUpdateAdvancedPayrollSettings();

  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);
  const submitForm = async () => {
    let payload = {
      tax_pension: taxPension ? 1 : 0,
      other_payments: others ? 1 : 0,
    };
    await update_settings(payload);
  };

  return (
    <div className="flex flex-col md:flex-row mt-4 h-full">
      <div className="flex flex-col md:max-w-[40%] w-full ">
        {/* Advanced Plan */}
        <div
          onClick={() => setSelectedPlanIndex(0)}
          className={classNames(
            "mb-6 max-w-[500px] h-[120px] flex flex-row justify-between items-center rounded px-4 cursor-pointer duration-300",
            {
              "bg-secondary-2 text-color-white cursor-default":
                selectedPlanIndex === 0 && section.name === "payroll",
              "bg-hr-primary-1 text-color-white cursor-default":
                selectedPlanIndex === 0 && section.name === "core hr",
              "bg-spend-primary-1 text-color-white cursor-default":
                selectedPlanIndex === 0 && section.name === "spend management",
            },
            {
              "text-color-black border border-secondary-2":
                selectedPlanIndex !== 0,
            }
          )}
        >
          <div className="flex flex-row w-full max-w-[65%] text-inherit">
            <div className="flex flex-col text-inherit">
              <span className=" p4-bold text-inherit">RUNNING PAYROLL</span>
              <span className=" p5 mt-1 text-inherit text-[12px]">
                Compute, generate payslips and Pay Your Employee(s). Pay and
                file your statutory compliances.
              </span>
            </div>
          </div>
          <div className="flex flex-col text-end text-inherit">
            <span className="header-6 text-inherit italic">Starts From</span>
            <span className="header-6 text-inherit">
              500
              <span className="header-5-regular text-inherit">/Employee</span>
            </span>
            {selectedPlanIndex === 0 ? null : (
              <span className="text-inherit p4-medium-2 italic">
                View Pricing
              </span>
            )}
          </div>
        </div>

        {/* Basic Plan */}
        <div
          onClick={() => setSelectedPlanIndex(1)}
          className={classNames(
            "max-w-[500px] h-[120px] flex flex-row justify-between items-center rounded px-4 cursor-pointer duration-300",
            {
              "bg-secondary-2 text-color-white cursor-default":
                selectedPlanIndex === 1 && section.name === "payroll",
              "bg-hr-primary-1 text-color-white cursor-default":
                selectedPlanIndex === 1 && section.name === "core hr",
              "bg-spend-primary-1 text-color-white cursor-default":
                selectedPlanIndex === 1 && section.name === "spend management",
            },
            {
              "text-color-black border border-secondary-2":
                selectedPlanIndex !== 1,
            }
          )}
        >
          <div className="flex flex-row w-full max-w-[65%] text-inherit">
            <div className="flex flex-col text-inherit">
              <span className=" p4-bold text-inherit">EMPLOYEE PAYMENT</span>
              <span className=" p5 mt-1 text-inherit text-[12px]">
                Make payments to your employees. No Computation, just payment
              </span>
            </div>
          </div>
          <div className="flex flex-col text-end text-inherit">
            <span className="header-6 text-inherit">
              N100
              <span className="header-5-regular text-inherit">/Employee</span>
            </span>
            {selectedPlanIndex === 1 ? null : (
              <span className="text-inherit p4-medium-2 italic">
                View Pricing
              </span>
            )}
          </div>
        </div>
      </div>
      {selectedPlanIndex === 0 ? (
        <div className="w-[100%] md:ml-4">
          <div className="border border-color-line rounded p-4 flex flex-col bg-white">
            {!advancedLoading ? (
              <div className="w-full md:w-[100%]">
                <div className="flex flex-col p-2 px-3 border bg-white rounded-md">
                  <span className="text-[14px] font-bold text-color-gray">
                    Product Name
                  </span>
                  <span className="mt-1 text-color-black font-bold text-[16px]">
                    Payroll
                  </span>
                </div>
                <hr className="divider mb-2 mt-3" />
                <div className="flex flex-col gap-2 ">
                  <span className="header-5 text-color-gray">
                    Pricing Breakdown
                  </span>
                  <div className="flex flex-col p-2 px-3 gap-2 border bg-white rounded-md">
                    <div className="flex flex-row justify-between text-[12px] font-medium border bg-highlight p-2 rounded-md  text-color-gray h-[45px] items-center">
                      <span>
                        Base Price (Computation, Payslip, Payment Only)
                      </span>
                      <span className="text-[13px] font-extrabold text-secondary-2 ">
                        NGN{" "}
                        {company?.payroll_discount > 0
                          ? 500 - (500 * company?.payroll_discount) / 100
                          : 500}
                        /Employee{" "}
                        {company?.payroll_discount > 1 ? (
                          <span className="text-[12px]">
                            (
                            {company?.payroll_discount < 1
                              ? ""
                              : company.payroll_discount}
                            % off)
                          </span>
                        ) : null}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between  text-[12px] font-medium border bg-highlight p-2 rounded-md text-color-gray items-center h-[45px]">
                      <div className="w-[70%] mt-3">
                        <FormSwitch
                          label={"Tax and Pension (Payment and Filing)"}
                          name={"tax_and_pension"}
                          checked={taxPension}
                          onClick={() => setTaxPension(!taxPension)}
                          value={taxPension}
                        />
                      </div>
                      <div className="text-[13px] font-extrabold text-secondary-2">
                        NGN 500/Employee
                      </div>
                    </div>
                    <div className="flex flex-row justify-between text-[12px] font-medium border bg-highlight p-2 rounded-md text-color-gray items-center h-[45px]">
                      <div className="w-[70%] mt-3">
                        <FormSwitch
                          label={"Other statutory payments and filing"}
                          name={"other_statutory"}
                          checked={others}
                          onClick={() => setOthers(!others)}
                          value={others}
                        />
                      </div>
                      <div className="text-[13px] font-extrabold text-secondary-2">
                        NGN 500/Employee
                      </div>
                    </div>
                  </div>
                  <hr className="divider mb-2 -mt-3" />
                  <div className="flex flex-row justify-between header-5 text-color-gray border bg-highlight p-2 rounded-md items-center">
                    <span className="header-4">Total Fees (Per Employee)</span>
                    <span className="header-3 text-primary-3">
                      NGN{" "}
                      {formatNumber(
                        computationPrice + taxPensionAmount + othersAmount,
                        2
                      )}
                    </span>
                  </div>
                  <hr className="divider mb-2 mt-2" />
                  <div className="flex justify-end w-full md:mt-0">
                    <Button
                      text="Update pricing"
                      type="button"
                      loading={updateAdvancedLoading}
                      disabled={false}
                      className="flex gap-2 !h-[35px] w-fit p-2 "
                      textClass={"!text-[12px]"}
                      onClick={() => {
                        submitForm();
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                <LoadingBar loading={advancedLoading} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-[100%] md:ml-4">
          <div className="border border-color-line rounded p-4 flex flex-col">
            <div className="w-full md:w-[100%]">
              <div className="flex flex-col p-2 px-3 border bg-white rounded-md">
                <span className="text-[14px] font-bold text-color-gray">
                  Product Name
                </span>
                <span className="mt-1 text-color-black font-bold text-[16px]">
                  Payroll
                </span>
              </div>
              <hr className="divider mb-2 mt-3" />
              <div className="flex flex-col">
                <span className="header-5 text-color-gray">
                  Transaction Fees
                </span>
                <span className="mt-1 text-color-black text-[13px]">
                  N100/employee/Payment
                </span>
              </div>
              <div className="flex mb-2 mt-3">
                <div className="flex flex-row px-1 w-[100%] border-secondary-4 border-[1.2px] rounded-lg p-5 pt-3 pb-3 mt-2 bg-color-warning-light">
                  <div className="w-[70px] pr-2 flex flex-row items-center ">
                    <img src={WarningSVG} alt="" srcSet="" />
                  </div>

                  <div className="p4-regular">
                    <span className="p4-bold">What Does this Fee Cover?</span>
                    <li>
                      Payment To Employees (No payroll computation or payslips
                      generation (Just payments))
                    </li>
                    <li>Free to use Payroll platform</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PayrollPlans;
