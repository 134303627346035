import { Axios } from '../axios';

const addTeam = async (payload) => {
    const { data } = await Axios.post('/teams', payload);
    return data;
};

const getTeams = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.get( `/teams?limit=${payload.limit}&page=${payload.page}${search}`,);
    return data?.data;
};

const getTeamSummary = async () => {
    const { data } = await Axios.post('/teams/summary');
    return data?.data;
};

const updateTeam = async (payload) => {
    const { data } = await Axios.put('/teams/' + payload.id, payload.data);
    return data;
};
  
const getTeam = async (payload) => {
    const { data } = await Axios.get('/teams/' + payload.id, payload);
    return data?.data;
};

const deleteTeam = async (payload) => {
    const { data } = await Axios.delete(`/teams/${payload}`,);
    return data;
};

const getTeamEmployees = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get(`/teams/${payload.id}?limit=${payload.limit}&page=${payload.page}${search}${filter}`);
    return data?.data;
};

const addEmployeeToTeam = async (payload) => {
    const { data } = await Axios.post('/team/add-employee', payload);
    return data;
};

const addEmployeesToTeam = async (payload) => {
    const { data } = await Axios.post('/team/add-multiple-employees', payload);
    return data;
};
  
const removeEmployeeFromTeam = async (payload) => {
    const { data } = await Axios.delete(
      '/team/remove-employee' + payload,
    );
    return data;
};

const removeEmployeesFromTeam = async (payload) => {
    const { data } = await Axios.post(
      '/team/remove-multiple-employees',
      payload,
    );
    return data;
};

const moveEmployeeToTeam = async (payload) => {
    const { data } = await Axios.post(
      '/team/move-employee',
      payload,
    );
    return data;
};

const moveEmployeesToTeam = async (payload) => {
    const { data } = await Axios.post(
      '/team/move-multiple-employees',
      payload,
    );
    return data;
};

const getTeamSettings = async (payload) => {
    const { data } = await Axios.get('/teams/' + payload.id);
    return data?.data;
};

export const teams = {
    addTeam,
    getTeams,
    getTeamSummary,
    updateTeam,
    getTeam,
    deleteTeam,
    getTeamEmployees,
    addEmployeeToTeam,
    addEmployeesToTeam,
    removeEmployeeFromTeam,
    removeEmployeesFromTeam,
    moveEmployeeToTeam,
    moveEmployeesToTeam,
    getTeamSettings
};

