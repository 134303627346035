import React from 'react';
import VendorPage from '../../pages/spend-management/vendor/index';
import VendorPayPreviewPage from '../../pages/spend-management/vendor/vendor-run-preview';
import VendorPayViewPage from '../../pages/spend-management/vendor/vendor-run-view';
import ProtectedRoute from '../protected';
import ViewVendorPage from '../../pages/spend-management/vendor/view-vendor';
import PurchaseOrderPage from '../../pages/spend-management/purchase-order.js';

export const VendorRoutes = [
  <ProtectedRoute exact path='/vendors' component={VendorPage} />,
  <ProtectedRoute exact path='/vendors/purchase-order' component={PurchaseOrderPage} />,
  <ProtectedRoute exact path='/vendors/view/:id' component={ViewVendorPage} />,
  <ProtectedRoute exact path='/vendor/pay/preview/:refId' component={VendorPayPreviewPage} />,
  <ProtectedRoute exact path='/vendor/pay/view/:refId' component={VendorPayViewPage} />,
] 