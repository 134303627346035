import React, { useEffect, useState } from "react";

import Button from "../../../components/button";

import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import FormFileInput from "../../../components/form-file-input";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../utils/functions";
import { useInviteBulkEmployees } from "../../../redux/employees/hook";
import { Link, useHistory } from "react-router-dom";
import Excel from "exceljs";
import { saveAs } from "file-saver";

function InviteBulkEmployee() {
  useEffect(() => {}, []);
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });
  const history = useHistory();

  const columns = [
    { header: "Employee's First Name", key: "first_name" },
    { header: "Employee's Middle Name", key: "middle_name" },
    { header: "Employee's Last Name", key: "last_name" },
    { header: "Phone Number", key: "mobile" },
    { header: "Email Address", key: "email" },
    { header: "Date Joined (YYYY-MM-DD)", key: "date_joined" },
    { header: "Staff Number", key: "staff_number" },
    { header: "Role", key: "job_role" },
    { header: "Payroll Group", key: "payroll_group" },
  ];

  const [exportLoading, setSetExportLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const exportTemplate = async () => {
    setSetExportLoading(true);
    const workSheetName = "active_employee_data";
    const workBookName = `Employee upload data (pre-filled)`;
    try {
      const fileName = workBookName;

      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      worksheet.columns.forEach((column) => {
        if (column._number == 6) {
          column.width = column.header.length;
          column.numFmt = "YYYY-MM-DD";
        } else {
          column.width = 25;
        }
      });

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };
      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: true }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;
          worksheet.getCell(cellAddress).border = {
            top: { style: "none" },
            left: { style: "none" },
            bottom: { style: "none" },
            right: { style: "none" },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      // console.error('<<<ERRROR>>>', error);
      // console.error('Something Went Wrong', error.message);
    } finally {
      workbook.removeWorksheet(workSheetName);
    }
    setSetExportLoading(false);
  };

  const { mutateAsync, isLoading } = useInviteBulkEmployees();

  const submitForm = async (data) => {
    const formData = new FormData();
    formData.append("file", data?.file[0]);

    await mutateAsync(formData).then((result) => {
      history.push("/employees/onboarding/add-salary-information/?type=bulk");
    });
  };

  return (
    <EmployeeOnboardingLayout step={2} title={"Add Employee Details"}>
      <div className="flex flex-col w-full  h-full">
        <span className="header-4">
          Add Bulk Employees - invite employees to fill biodata
        </span>
        <hr className="divider" />

        <form className="form " onSubmit={handleSubmit(submitForm)}>
          <>
            <div className="flex flex-col text-center justify-center items-center">
              <span className="text-[16px] font-extrabold mb-2">
                Import employee information from XLSX file
              </span>
              <div className=" flex flex-col border p-4 rounded-md bg-highlight mt-2 mb-3 justify-center items-center w-full">
                <Button
                  text="Download template"
                  type="button"
                  theme="primary"
                  loading={exportLoading}
                  className="flex gap-2 !h-[35px] w-[220px] p-2 "
                  textClass={"!text-[11px]"}
                  onClick={() => {
                    exportTemplate();
                  }}
                />
              </div>

              <FormFileInput
                multiSource={false}
                inputRef={register(formValidation("file", true))}
                accept=".xlsx"
              />
            </div>
            <div className="w-full mt-3">
              <Button
                text="Invite Employees"
                type="submit"
                loading={isLoading}
              />
            </div>
            <div
              className="flex justify-center w-full cursor-pointer hover:underline hover:scale-110 duration-300 mt-2"
              onClick={() => history.goBack()}
            >
              <span className="p2-medium text-color-black mt-3">Go Back</span>
            </div>
          </>
        </form>
      </div>
    </EmployeeOnboardingLayout>
  );
}

export default InviteBulkEmployee;
