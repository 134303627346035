import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import DOMPurify from "dompurify";
import FormInput from "../../components/form-input";
import Button from "../../components/button";
import { useRecoveryPassword } from "../../redux/user/hook";
import { formValidation } from "../../utils/functions";
import AuthLayout from "../../components/layouts/auth";
import { useSelector } from "react-redux";

const ForgotPasswordPage = () => {
  useEffect(() => {}, []);

  const { section } = useSelector((state) => state.sectionSlice);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const history = useHistory();
  const { mutateAsync: recoverPassword, isLoading: loading } =
    useRecoveryPassword();

  const submitForm = async (data) => {
    const email = DOMPurify.sanitize(data?.email);
    const requestData = {
      email,
    };

    recoverPassword(requestData).then((result) => {
      history.push(`/check-email`);
    });
  };

  return (
    <AuthLayout>
      <>
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <div className="w-full text-center flex flex-col pb-[25px]">
            <span
              className={
                section && section.name === "core hr"
                  ? "header-3-regular md:header-2 text-hr-primary-1 pb-1"
                  : "header-3-regular md:header-2 text-secondary-2 pb-1"
              }
            >
              Forgot your Password?
            </span>
            <span className="text-[15px] text-gray-500">
              Enter your email address below to enable us send your reset
              password link.
            </span>
          </div>
          <FormInput
            label="Email"
            name="email"
            placeholder="Enter Your Email"
            type="text"
            inputRef={register(formValidation("email", true))}
            readOnly={loading}
            error={errors.email}
            errorMessage={errors.email && errors.email.message}
          />
          <Button
            text="Send Recovery Email"
            type="submit"
            loading={loading}
            disabled={!isValid}
          />
          <div className="flex justify-end d_flex remember_section mb-3 mt-3">
            <p className="forgot_password text-[15px] text-gray-500">
              Remember now?&nbsp;
              <Link to="/" className="hover:underline duration-300">
                <span className="text-[15px] font-extrabold">Sign In</span>
              </Link>
            </p>
          </div>
        </form>
      </>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
