import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormTextArea from '../../../form-textarea';
import FormSelectEmployee from '../../../employees/form-select-employee';
import {
  useAddQuery,
  useEditQuery,
} from '../../../../redux/human-resources/hook/queries';
import Modal from '../../modal';

function AddQuery({
  isOpen,
  closeModal,
  refresh,
  queryData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [queryId, setQueryId] = useState(null);
  const [queryType, setQueryType] = useState('add');

  useEffect(() => {
    if (queryData) {
      setQueryType('edit');
      setQueryId(queryData.id);
      setQuery(queryData.query);
      setEmployee(queryData.employee_id);
      setEmployeeName(queryData.first_name + ' ' + queryData.last_name);
    } else {
      setQueryType('add');
    }
  }, [queryData]);

  const [query, setQuery] = useState('');
  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();

  const { mutateAsync: addQuery, isLoading: addQueryloading } = useAddQuery();
  const { mutateAsync: editQuery, isLoading: editQueryloading } =
    useEditQuery();

  const submitForm = async (data) => {
    const query = DOMPurify.sanitize(data?.query);
    let payload = {
      employee_id: employee,
      query,
    };

    if (queryType === 'add') {
      await addQuery(payload).then(() => {
        closeModal();
      });
    } else if (queryType === 'edit') {
      let editPayload = {
        id: queryId,
        data: payload,
      };
      await editQuery(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div>
              <div className='border bg-highlight p-2 rounded-md mb-2'>
                <FormSelectEmployee
                  label={'Employee'}
                  name='employee'
                  placeholder='Type to select employee'
                  value={employee}
                  readOnly={queryType === 'edit' ? true : false}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
              <FormTextArea
                label={'Query'}
                name='query'
                type='text'
                defaultValue={query}
                onInput={(e) => {
                  setQuery(e.target.value);
                }}
                placeholder={'Enter the query here'}
                inputRef={register(formValidation('text', true))}
                readOnly={editQueryloading || addQueryloading}
                error={errors.query}
                errorMessage={errors.query && errors.query.message}
              />
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                disabled={
                  queryType === 'edit' ? false : !isValid ? true : false
                }
                loading={editQueryloading || addQueryloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddQuery;
