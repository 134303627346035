import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import {
  leaveStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../utils/constants";
import {
  useApproveLeave,
  useDeclineLeave,
  useDeleteLeave,
  useGetLeaves,
} from "../../../redux/human-resources/hook/leave";
import AddLeave from "../../modal/hr-section/leave/add-leave";
import Button from "../../button";
import { useGetEmployees } from "../../../redux/employees/hook";

const HRLeaveOverviewTab = () => {
  useEffect(() => {}, []);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { mutateAsync: removeLeave } = useDeleteLeave();
  const { mutateAsync: approveLeave } = useApproveLeave();
  const { mutateAsync: declineLeave } = useDeclineLeave();

  const [leaveData, setLeaveData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const [tableType, setTableType] = useState("leave");

  const switchFunction = () => {
    if (tableType === "leave") {
      setTableType("table");
    } else {
      setTableType("leave");
    }
  };

  const [showAddLeave, setShowAddLeave] = useState(null);

  const { data: leaves } = useGetLeaves(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const tableConfig = {
    headers: [
      "Date",
      "Employee Name",
      "Mobile",
      "Type",
      "Period",
      "Days",
      "Status",
    ],
    keys: [
      "date_created",
      "employeeName",
      "mobile",
      "leave_type",
      "period",
      "number_of_days",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Period",
        key: "leave_period",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "green",
      action: (id) => {
        let index = leaves.data.findIndex((item) => item.id === id);
        setTitle("Update Leave");
        setButtonText("Update Leave");
        setLeaveData(leaves.data[index]);
        setShowAddLeave(true);
      },
    },
    {
      name: "Approve",
      color: "green",
      action: (id) => {
        let index = leaves.data.findIndex((item) => item.id === id);
        approveLeave(leaves.data[index].id);
      },
    },
    {
      name: "Decline",
      color: "red",
      action: (id) => {
        let index = leaves.data.findIndex((item) => item.id === id);
        declineLeave(leaves.data[index].id);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = leaves.data.findIndex((item) => item.id === id);
        removeLeave(leaves.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddLeave ? (
        <AddLeave
          isOpen={showAddLeave}
          closeModal={() => setShowAddLeave(false)}
          leaveData={leaveData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Leave Applications
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Employee Leave"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              disabled={!employees}
              onClick={() => {
                setLeaveData(null);
                setTitle("Add Employee Leave");
                setButtonText("Add Leave");
                setShowAddLeave(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={leaves}
        tableConfig={tableConfig}
        statusConfig={leaveStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        computedActions={true}
        tableType={tableType}
        switchFunction={switchFunction}
        layoutSwitch={true}
        searchData={true}
        noCheck={true}
      />
    </div>
  );
};

export default HRLeaveOverviewTab;
