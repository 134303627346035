import React from 'react';
import { useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import { useHistory } from 'react-router-dom';
import Button from '../../button';
import ButtonSelect from '../../button-select';
import Modal from '../modal';

function AddEmployee({ isOpen, closeModal, type }) {
  const [employeeDetailOption, setEmployeeDetailOption] = useState('invite');
  const history = useHistory();

  function submit() {
    if (employeeDetailOption === 'invite' && type === 'single') {
      history.push('/employees/onboarding/add-single-employee/invite');
    } else if (employeeDetailOption === 'create' && type === 'single') {
      history.push('/employees/onboarding/add-single-employee/create');
    } else if (employeeDetailOption === 'invite' && type === 'bulk') {
      history.push('/employees/onboarding/add-bulk-employee/invite');
    } else if (employeeDetailOption === 'create' && type === 'bulk') {
      history.push('/employees/onboarding/add-bulk-employee/create');
    }
  }

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto pb-4'>
        <div className='flex flex-row justify-between pl-8 pr-8 pt-4 items-center'>
          <span className='header-4'>Add Employee Details</span>
          <div className='cursor-pointer' onClick={() => closeModal()}>
            <img src={CloseSVG} alt='close' />
          </div>
        </div>
        <hr className='divider' />
        <div className='flex items-center flex-col'>
          <span className='mb-6 p3 font-semibold'>
            Select your desired option to add Employee Details
          </span>
          <div className='flex flex-col items-center max-w-[85%] min-w-[85%]'>
            <ButtonSelect
              title={'Invite Employee to fill in their details'}
              subtitle={
                'Fill in basic employee information to invite (via email) them to fill their Biodata. Employees complete all other personal information on mobile app or the web application.'
              }
              isActive={employeeDetailOption === 'invite'}
              onClick={() => setEmployeeDetailOption('invite')}
              className='min-w-full'
            />

            <ButtonSelect
              title={'Add all employee details'}
              subtitle={'Fill all employee details yourself. This is most useful when employees do not have access to smart phones or the internet.'}
              isActive={employeeDetailOption === 'create'}
              onClick={() => setEmployeeDetailOption('create')}
              className='mt-8 min-w-full'
            />

            <div className='w-full my-[20px]'>
              <Button text='NEXT' type='submit' onClick={() => submit()} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddEmployee;
