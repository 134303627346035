import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../../components/datatable';
import { PAGINATION_DEFAULT, payrollHistoryStatusConfig } from '../../utils/constants';
import AdvancedPayrollDetailedView from '../modal/payroll/payroll-full-breakdown-view';
import { useGetCabinetPayroll } from '../../redux/employee-folder/hook';

const PayrollFolder = ({employeeId}) => {

//   const { user } = useSelector((state) => state.userSlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
    start_date: '',
    end_date: '',
  });

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00"
  };

  const [previewData , setPreviewData] = useState()
  const [previewType , setPreviewType] = useState()
  const [showPayrollDetailedView, setShowPayrollDetailedView] = useState(false);

  const { data: payroll } = useGetCabinetPayroll(
    employeeId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date,
  );

  const tableConfig = {
    headers: ['Employee Name', 'Mobile', 'Month', 'Net Pay', 'status'],
    keys: ['employeeName', 'employee_phone', 'formatted_month', 'net_pay', 'status'],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Net Pay',
        key: 'net_pay',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Details',
      color: 'orange',
      action: (id) => {
        let index = payroll.data.findIndex((item) => item.id === id);
        setPreviewData(payroll.data[index])
        setPreviewType('view')
        setShowPayrollDetailedView(true)
      },
    },
  ];

  return (
    <div>
        {previewData && showPayrollDetailedView?
          <AdvancedPayrollDetailedView
            isOpen={showPayrollDetailedView}
            closeModal={() => setShowPayrollDetailedView(false)}
            viewData={previewData}
            previewType={previewType}
          />
        :
          null
        }
        <div className='flex flex-col'>
          <div className='mt-9'>
            <div className='flex md:flex-row gap-4 flex-wrap-reverse'>
                <div className='w-[100%]'>
                    <DataTable
                    data={payroll}
                    searchData={true}
                    filterByDate={dateConfig}
                    tableConfig={tableConfig}
                    actionConfig={actionConfig}
                    statusConfig={payrollHistoryStatusConfig}
                    noCheck={true}
                    computedActions={true}
                    updatePagination={(data) => setPagination(data)}
                    />
                </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default PayrollFolder;
