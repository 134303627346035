import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import { useGetRecruitmentWorkflows } from "../../../redux/human-resources/hook/recruitment";
import AddRecruitmentWorkflow from "../../modal/hr-section/recruitment/add-workflow";
import Button from "../../button";
import { useDeleteWorkflow } from "../../../redux/human-resources/hook/workflow";

const RecruitmentWorkflowTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const [showAddWorkflow, setShowAddWorkflow] = useState(false);
  const [workflowData, setWorkflowData] = useState();
  const [tableType, setTableType] = useState("workflow");

  const { mutateAsync: deleteWorkflow } = useDeleteWorkflow();

  const [title, setTitle] = useState("Add Email Template");
  const [buttonText, setButtonText] = useState(null);

  const switchFunction = () => {
    if (tableType === "workflow") {
      setTableType("table");
    } else {
      setTableType("workflow");
    }
  };

  const { data: workflows } = useGetRecruitmentWorkflows(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const history = useHistory();

  const tableConfig = {
    headers: ["Date created", "Workflow Name", "Description"],
    keys: ["created", "name", "description"],
    mobileHeader: {
      left: {
        title: "Workflow Name",
        key: "name",
      },
      right: {
        title: "Description",
        key: "description",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "green",
      action: (id) => {
        let index = workflows.data.findIndex((item) => item.id === id);
        setTitle("Update Workflow");
        setButtonText("Update Workflow");
        setWorkflowData(workflows.data[index]);
        setShowAddWorkflow(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = workflows.data.findIndex((item) => item.id === id);
        deleteWorkflow(workflows.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddWorkflow ? (
        <AddRecruitmentWorkflow
          isOpen={showAddWorkflow}
          closeModal={() => setShowAddWorkflow(false)}
          workflowData={workflowData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Recruitment Workflows
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Workflow"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setWorkflowData(null);
                setTitle("Add Workflow");
                setButtonText("Save Workflow");
                setShowAddWorkflow(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={workflows}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        tableType={tableType}
        switchFunction={switchFunction}
        layoutSwitch={true}
        searchData={true}
        noCheck={true}
      />
    </div>
  );
};

export default RecruitmentWorkflowTab;
