import { Axios } from '../axios';

const addLeave = async (payload) => {
  const { data } = await Axios.post('/leaves', payload);
  return data;
};

const getLeaves = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(
    `/leaves?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );
  return data?.data;
};

const getLeaveByEmployee = async (payload) => {
  let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(`/leaves/by-employee/${payload.employee_id}?limit=${payload.limit}&page=${payload.page}${search}${start_date}${end_date}`,);
  return data?.data;
};

const addLeaveType = async (payload) => {
  const { data } = await Axios.post('/leave-types', payload);
  return data;
};

const getLeaveTypes = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(
    `/leave-types?limit=${payload.limit}&page=${payload.page}${search}`,
  );
  return data?.data;
};

const deleteLeaveType = async (payload) => {
  const { data } = await Axios.delete(`/leave-types/${payload}`);
  return data;
};

const updateLeaveType = async (payload) => {
  const { data } = await Axios.put('/leave-types/' + payload.id, payload.data);
  return data;
};

const getLeaveSummary = async () => {
  const { data } = await Axios.post('/leaves/summary');
  return data?.data;
};

const updateLeave = async (payload) => {
  const { data } = await Axios.put('/leaves/' + payload.id, payload.data);
  return data;
};

const getLeave = async (payload) => {
  const { data } = await Axios.get('/leave/' + payload);
  return data?.data;
};

const deleteLeave = async (payload) => {
  const { data } = await Axios.delete(`/leaves/${payload}`);
  return data;
};
const approveLeave = async (payload) => {
  const { data } = await Axios.get(`/leave/${payload}/approve`);
  return data;
};
const declineLeave = async (payload) => {
  const { data } = await Axios.get(`/leave/${payload}/decline`);
  return data;
};

const getLeaveSettings = async (payload) => {
  const { data } = await Axios.get('/leave-settings', payload);
  return data?.data;
};

const updateLeaveSettings = async (payload) => {
  const { data } = await Axios.post('/leave-settings', payload);
  return data;
};

export const leaves = {
  addLeave,
  getLeaves,
  getLeaveByEmployee,
  getLeaveSummary,
  updateLeave,
  getLeave,
  deleteLeave,
  approveLeave,
  declineLeave,
  getLeaveSettings,
  addLeaveType,
  getLeaveTypes,
  deleteLeaveType,
  updateLeaveType,
  updateLeaveSettings,
};
