import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloseSVG from '../../../assets/svg/close.svg';
import { useUpdateCompany } from '../../../redux/company/hook';
import {
  useConfirmTaxConsent,
  useGenerateTaxOtp,
  useSaveTaxPayroll,
} from '../../../redux/statutory-compliance/hook/tax';
import Button from '../../button';
import FormInput from '../../form-input';
import FormOtpInput from '../../form-otp-input';
import Modal from '../modal';

function CompanyTaxIdVerification({ isOpen, closeModal, refresh, taxRef }) {
  const [step, setStep] = useState('tax_id');
  const [otp, setOtp] = useState('');
  const [state, setState] = useState('');
  const history = useHistory();
  const { company } = useSelector((state) => state.companySlice);
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyName, setCompanyName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [url, setUrl] = useState('https://etax.lirs.net/register');

  const { mutateAsync: createOtp, isLoading: otpLoading } = useGenerateTaxOtp();
  const { mutateAsync: confirmConsent, isLoading: consentLoading } =
    useConfirmTaxConsent();
  const { mutateAsync: updateCompany, isLoading: updateCompanyLoading } =
    useUpdateCompany();
  const { mutateAsync: savePayroll, isLoading: saveLoading } =
    useSaveTaxPayroll();

  useEffect(() => {
    if (company) {
      setCompanyTaxId(company.tax_id);
      setState(company.state);
      setCompanyName(company.company_name);
      setEmail(company.email);
      setMobile(company.mobile);
    }
  }, [company]);

  useEffect(() => {
    if (!state || state === 'Lagos') {
      setUrl('https://etax.lirs.net/register');
    } else if (state === 'Rivers') {
      setUrl('https://rivtamis.riversbirs.gov.ng');
    } else if (state === 'FCT - Abuja') {
      setUrl(
        'https://fcttaxportal.fctirs.gov.ng/selfservice/Account/NewRegistration',
      );
    } else if (state === 'Edo') {
      setUrl('https://eras.eirs.gov.ng/Login/Company');
    } else if (state === 'Ogun') {
      setUrl('https://portal.ogetax.ogunstate.gov.ng/registration');
    } else if (state === 'Oyo') {
      setUrl('https://selfservice.oyostatebir.com/');
    } else if (state === 'Delta') {
      setUrl('http://www.deltabir.com/DeltaRevenue/Default.aspx');
    } else if (state === 'Enugu') {
      setUrl('https://irs.en.gov.ng/obtain-esbn');
    } else if (state === 'Anambra') {
      setUrl('https://selfportal.anambra.tax/Auth/login');
    } else if (state === 'Kano') {
      setUrl('https://taxdrive.kigra.gov.ng/selfservice');
    }
  }, [state]);

  const generateOTP = async () => {
    let updatePayload = {
      company_name: companyName,
      email: email,
      mobile: mobile,
      tax_id: companyTaxId,
    };

    await updateCompany(updatePayload).then(async () => {
      if (!company.state || company.state === 'Lagos') {
        await createOtp().then(() => {
          setStep('otp');
        });
      } else {
        history.push(`/statutory-payments/taxes/preview/${taxRef}`);
      }
    });
  };

  const createTaxConsent = async () => {
    let payload = {
      pin: otp,
    };
    await confirmConsent(payload).then(async (result) => {
      let payload = {
        ref: taxRef,
      };
      if (result.status === 'success') {
        await savePayroll(payload).then(() => {
          history.push(`/statutory-payments/taxes/preview/${taxRef}`);
        });
      }
    });
  };

  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[500px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Tax Payment Authorization</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          {step === 'tax_id' ? (
            <div className='flex flex-col mx-8 '>
              <div className='flex flex-row justify-between items-center'>
                <div className='w-[60%]'>
                  <FormInput
                    label='Company Tax ID - (For Authorization)'
                    name='tax_id'
                    className={'rounded-br-none rounded-tr-none'}
                    type='text'
                    value={companyTaxId}
                    onInput={(e) => {
                      setCompanyTaxId(e.target.value);
                    }}
                  />
                </div>
                <div className='w-[40%] mt-[10px]'>
                  <Button
                    text={'GENERATE TAX ID'}
                    type='submit'
                    disabled={false}
                    textClass='!text-[12px]'
                    theme='primary'
                    className={`h-[35px] pl-1 pr-1 rounded-bl-none rounded-tl-none`}
                    onClick={() => {
                      window.open(url, '_blank');
                    }}
                  />
                </div>
              </div>
              <div className='w-full mt-[10px] pb-[20px]'>
                <Button
                  text={
                    !state || state === 'Lagos'
                      ? 'GENERATE TAX OTP'
                      : 'CONTINUE'
                  }
                  type='submit'
                  disabled={companyTaxId ? false : true}
                  loading={otpLoading || updateCompanyLoading}
                  onClick={() => {
                    generateOTP();
                  }}
                />
              </div>
            </div>
          ) : (
            <div className='p-8 flex flex-col px-[30px]'>
              <div className='flex w-[100%] justify-center'>
                <FormOtpInput
                  value={otp}
                  valueLength={6}
                  onChange={(value) => setOtp(value)}
                  label='Enter OTP'
                />
              </div>
              <div className='w-full mt-[40px]'>
                <Button
                  text='VERIFY TAX CONSENT'
                  type='button'
                  disabled={otp ? false : true}
                  loading={consentLoading || saveLoading}
                  onClick={() => {
                    createTaxConsent();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default CompanyTaxIdVerification;
