import React, { useEffect, useState } from 'react';
import Button from '../../../components/button';
import FormInput from '../../../components/form-input';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import EmployeeRecruitmentLayout from '../../../components/layouts/employee-recruitment';
import FormSelect from '../../../components/form-select';
import { useDispatch, useSelector } from 'react-redux';
import { setAssignWorkflow } from '../../../redux/human-resources/reducers/recruitment';
import FormSwitch from '../../../components/form-switch';
import UnauthorizedPage from '../../errors/unauthorized';
import { Editor } from '@tinymce/tinymce-react';

function RecruitmentJobDescriptionPage() {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);
  const [unauthorized, setUnauthorized] = useState(false);

  const [subPlan, setSubPlan] = useState();

  useEffect(() => {
    if (user) {
      setSubPlan(user?.hr_subscription?.subscription_plan);
      if (user.permissions.company_permission.role !== 'Owner') {
        if (user.permissions.company_permission.recruitment_write !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);
  const [payLoad, setPayload] = useState({});

  const { assignWorkflowData } = useSelector((state) => state.recruitmentSlice);

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
  const [jobDescription, setJobDescription] = useState();

  const [useKeywordFilter, setUseKeywordFilter] = useState(true);

  const [acceptanceRate, setAcceptanceRate] = useState('any');

  const [keywords, setKeywords] = useState([]);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (assignWorkflowData) {
      setPayload(JSON.parse(JSON.stringify(assignWorkflowData)));
      setJobDescription(assignWorkflowData.job_description);
      setUseKeywordFilter(
        assignWorkflowData.use_keywords
          ? assignWorkflowData.use_keywords
          : true,
      );
      setAcceptanceRate(assignWorkflowData.acceptance_rate);
      setKeywords(
        JSON.parse(
          assignWorkflowData.keywords ? assignWorkflowData.keywords : '[]',
        ),
      );
    }
  }, [assignWorkflowData]);

  const loadSample = () => {
    setJobDescription(
      `<p><strong>Job Title:</strong> ${assignWorkflowData?.role}</p>
      <p><strong>Company:</strong> ${company?.company_name}</p>
      <p><strong>Location:</strong> ${company?.address} ${company?.city} ${company?.state} ${company?.country}</p>
      <strong>Job Description:</strong>
      <p>We are seeking a highly motivated and skilled individual to join our team at ${company?.company_name}. As ${assignWorkflowData?.job_role}, you will be responsible for [overview of the role and key responsibilities. You will work closely with [specific teams or departments] to [specific tasks or projects].</p>
      <p><strong>Responsibilities:</strong></p>
      <ul>
      <li>[Key responsibility 1]</li>

      <li>[Additional responsibilities as needed]</li>
      </ul>
      <p><strong>Requirements:</strong></p>
      <ul>
      <li>[Required qualification 1]</li>

      </ul>
      <p><strong>Skills and Qualifications:</strong></p>
      <ul>
      <li>[Skill 1]</li>
      </ul>
      <p><strong>Preferred Qualifications:</strong></p>
      <ul>
      <li>[Preferred qualification 1]</li>
      </ul>
      <p><strong>Benefits:</strong></p>
      <ul>
      <li>[List of company benefits, such as health insurance, vacation days, etc.]</li>
      </ul>
      `,
    );
  };

  const acceptanceRateOptions = [
    { value: 'any', label: 'Accept on finding any keyword' },
    { value: 'all', label: 'Accept on finding all keywords (only)' },
  ];

  const submitForm = async () => {
    const requestData = {
      job_description: jobDescription.toString(),
      use_keywords: useKeywordFilter ? 1 : 0,
      acceptance_rate: acceptanceRate,
      keywords: JSON.stringify(keywords),
    };
    let newData = { ...payLoad, ...requestData };

    dispatch(setAssignWorkflow(newData));

    history.push('/recruitment/assign-workflow');
  };

  return (
    <EmployeeRecruitmentLayout step={2} title={'Add Job description'}>
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className='flex flex-col w-full h-full'>
          <span className='md:flex hidden text-[20px] font-extrabold'>
            Job description and Recrutiment keywords
          </span>
          <span className='md:flex hidden text-[12px] text-color-gray'>
            Enter the recruitment job description and add recruitment keywords
            to automate applicant filtering
          </span>
          <hr className='divider md:mt-2 -mt-10' />
          <form className='form ' onSubmit={handleSubmit(submitForm)}>
            <div className='flex flex-col md:flex-row justify-between mb-2 '>
              <div className='w-full'>
                <div className='flex gap-2 mb-2 items-center w-full justify-between'>
                  <span className='text-color-gray text-[14px]'>
                    Job Description
                  </span>
                  <Button
                    text='Load Sample Job Description'
                    type='button'
                    theme='primary'
                    leftIcon={'refresh'}
                    className='flex gap-2 !h-[28px] w-fit p-2 '
                    textClass={'!text-[11px]'}
                    onClick={() => {
                      loadSample();
                    }}
                  />
                </div>
                <Editor
                  apiKey={import.meta.env.VITE_APP_TINYMCE_API_KEY}
                  placeholder={'Enter Job Description'}
                  onEditorChange={(editor) => {
                    setJobDescription(editor);
                  }}
                  value={jobDescription}
                  init={{
                    height: 400,
                    menubar: false,
                    placeholder: 'Enter Job description here.',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
                  }}
                />
              </div>
            </div>
            <hr className='divider' />
            <div
              className={
                'flex w-[100%] flex-col items-center border rounded p-2'
              }
            >
              <div className='w-full !font-semibold'>
                <FormSwitch
                  label={'Auto filter applicants by keywords?'}
                  name={'filter_by_keyword'}
                  checked={useKeywordFilter}
                  onClick={() => {
                    setUseKeywordFilter(!useKeywordFilter);
                  }}
                />
              </div>
              <div className='w-full -mt-2'>
                {useKeywordFilter ? (
                  <>
                    <span className='text-color-gray text-[13px]'>
                      Keywords
                    </span>
                    <div className='flex flex-row gap-2 !min-h-fit border rounded p-2 flex-wrap duration-300'>
                      {keywords.length > 0 ? (
                        <>
                          {keywords.map((item, index) => (
                            <div className='flex flex-row border'>
                              <div className='flex items-center justify-center border-r font-bold !max-w-fit rounder-tl rounder-tl p-2 !max-h-[25px] !text-[14px] !text-color-black'>
                                {keywords[index]}
                              </div>
                              <button
                                onClick={() => {
                                  let newArray = JSON.parse(
                                    JSON.stringify(keywords),
                                  );
                                  newArray.splice(index, 1);
                                  setKeywords(newArray);
                                }}
                                type='button'
                                className={
                                  'flex items-center justify-center !max-w-fit rounder-br rounder-tr p-2 !max-h-[25px] !text-[13px] !text-color-black hover:bg-highlight font-bold text-red-700'
                                }
                              >
                                X
                              </button>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className='text-[15px] flex items-center !w-full justify-center text-color-gray'>
                          No keyword added
                        </div>
                      )}
                    </div>
                    <div className='w-full mt-2'>
                      <div className='flex flex-row justify-between items-center'>
                        <div className='w-[70%]'>
                          <FormInput
                            placeholder='Enter keyword here'
                            name='keyword'
                            className={
                              '!h-[35px] rounded-br-none rounded-tr-none'
                            }
                            type='text'
                            value={keyword}
                            onInput={(e) => {
                              setKeyword(e.target.value);
                            }}
                          />
                        </div>
                        <div className='w-[30%] pt-[9px]'>
                          <Button
                            text={'ADD'}
                            type='button'
                            disabled={false}
                            textClass='!header-5'
                            theme='primary'
                            className={`!h-[36px] pl-1 pr-1 rounded-bl-none rounded-tl-none border-l-none -mt-3`}
                            onClick={() => {
                              let newArray = JSON.parse(
                                JSON.stringify(keywords),
                              );
                              newArray.push(keyword);
                              setKeywords(newArray);
                              setKeyword('');
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <FormSelect
                      defaultValue={acceptanceRate}
                      options={acceptanceRateOptions}
                      onChange={(selected) => {
                        setAcceptanceRate(selected);
                      }}
                      label='Acceptance Rate'
                      extraClass={'!w-full !md:w-[50%]'}
                    />
                  </>
                ) : null}
              </div>
            </div>
            <div className='w-full mt-2'>
              <Button text='Save and Continue' type='submit' theme={'third'} />
            </div>
            <div
              className='flex justify-center w-full cursor-pointer'
              onClick={() => history.goBack()}
            >
              <span className='p2-medium text-color-black mt-3'>Go Back</span>
            </div>
          </form>
        </div>
      )}
    </EmployeeRecruitmentLayout>
  );
}

export default RecruitmentJobDescriptionPage;
