import { Axios } from './axios';

const create = async (payload) => {
  const { data } = await Axios.post('/companies', payload);
  return data;
};

const update = async (payload) => {
  const { data } = await Axios.put('/company', payload);
  return data;
};

const updateCurrency = async (payload) => {
  const { data } = await Axios.get('/update-currency', payload);
  return data?.data;
};

const updateBalanceLimit = async (payload) => {
  const { data } = await Axios.put('/update-balance-limit', payload);
  return data?.data;
};

const addSalarySettings = async (payload) => {
  const { data } = await Axios.post('/salary-settings', payload);
  return data;
};

const updateSalarySettings = async (payload) => {
  const { data } = await Axios.put('/salary-settings', payload);
  return data;
};

const completeFundWallet = async () => {
  const { data } = await Axios.get('/company/mark-as-funded');
  return data;
};

const getUserCompanies = async () => {
  const { data } = await Axios.get('/companies');
  return data?.data;
};

const getCompany = async (payload) => {
  const { data } = await Axios.get(`/companies/${payload}`);
  return data;
};

const getAdvancedPayrollSettings = async () => {
  const { data } = await Axios.get(`/advanced-payroll/setting`);
  return data;
};

const updateAdvancedPayrollSettings = async (payload) => {
  const { data } = await Axios.put(`/advanced-payroll/setting`, payload);
  return data;
};

const getSalarySettings = async () => {
  const { data } = await Axios.get('/salary-settings');
  return data?.data;
};

const switchCompany = async (payload) => {
  const { data } = await Axios.get(`/switch-company/${payload}`);
  return data;
};

const addCompanyAdmin = async (payload) => {
  const { data } = await Axios.post('/admins', payload);
  return data;
};

const updateCompanyAdmin = async (payload) => {
  const { data } = await Axios.put(`/admins/${payload.id}`, payload.data);
  return data;
};

const updateApprover = async (payload) => {
  const { data } = await Axios.put(
    `/update-approval-permissions/${payload.id}`,
    payload.data,
  );
  return data;
};

const removeCompanyAdmin = async (payload) => {
  const { data } = await Axios.delete(`/admins/${payload}`);
  return data;
};

const getCompanyAdmins = async () => {
  const { data } = await Axios.get('/admins');
  return data;
};

const helpMeOnboard = async () => {
  const { data } = await Axios.get('/help-me-onboard');
  return data;
};

const getierVerification = async () => {
  const { data } = await Axios.get('/verification-status');
  return data?.data;
};

const transferAccountOwnership = async (payload) => {
  const { data } = await Axios.post('/transfer-owner', payload);
  return data;
};

const companyUploadProfilePicture = async (payload) => {
  const { data } = await Axios.post('/company/upload-logo', payload);
  return data;
};

const bvnVerification = async (payload) => {
  const { data } = await Axios.post('/bvn-verification', payload,
  {
    timeout: 0
  });
  return data;
};

const checkCompanyKYC = async () => {
  const { data } = await Axios.get('/company-kyc');
  return data;
};

const uploadCompanyKYCDocument = async (payload) => {
  const { data } = await Axios.post('/company-kyc', payload,
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const updateCompanyKYCDocument = async (payload) => {
  const { data } = await Axios.post(`/company-kyc/${payload.id}`, payload,
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const deleteCompanyKYCDocument = async (payload) => {
  const { data } = await Axios.delete(`/company-kyc/${payload}`);
  return data;
};

const integrateTyms = async () => {
  const { data } = await Axios.get(`/tyms/integration-link`);
  return data;
};


export const companies = {
  create,
  update,
  updateCurrency,
  updateBalanceLimit,
  addSalarySettings,
  updateSalarySettings,
  completeFundWallet,
  getUserCompanies,
  getCompany,
  getAdvancedPayrollSettings,
  updateAdvancedPayrollSettings,
  switchCompany,
  getSalarySettings,
  addCompanyAdmin,
  getCompanyAdmins,
  updateCompanyAdmin,
  getierVerification,
  removeCompanyAdmin,
  transferAccountOwnership,
  updateApprover,
  helpMeOnboard,
  companyUploadProfilePicture,
  bvnVerification,
  checkCompanyKYC,
  uploadCompanyKYCDocument,
  updateCompanyKYCDocument,
  deleteCompanyKYCDocument,
  integrateTyms,
};
