import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WarningSVG from '../../../assets/svg/warning.svg';
import ConfirmationModal from '../confirmation-modal';
import { useDisbursePension } from '../../../redux/statutory-compliance/hook/pension';
import { useDisburseTax } from '../../../redux/statutory-compliance/hook/tax';
import Modal from '../modal';
import { formatNumber } from '../../../utils/functions';
import FormOtpInput from '../../form-otp-input';

function StatutorySummary({
  isOpen,
  closeModal,
  takeHome,
  transCost,
  totalAmount,
  refValue,
  type,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [pin, setPIN] = useState('');
  const history = useHistory();
  const { company } = useSelector((state) => state.companySlice);
  const { user } = useSelector((state) => state.userSlice);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { mutateAsync: disbursePension, isLoading: pensionLoading } =
    useDisbursePension();
  const { mutateAsync: disburseTax, isLoading: taxLoading } = useDisburseTax();

  const submitForm = async (data) => {
    const requestData = {
      transaction_pin: pin,
      ref: data,
    };

    if (type === 'pension') {
      await disbursePension(requestData).then(() => {
        setShowConfirmation(true);
      });
    } else if (type === 'tax') {
      await disburseTax(requestData).then(() => {
        setShowConfirmation(true);
      });
    }
  };

  const confirmationNext = () => {
    history.push('/statutory-payments');
  };
  const subTitle = `Time to kick back and relax. Ropay will debit ${totalAmount} from your account. Please Make sure the funds are available.`;

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmation}
        closeModal={() => setShowConfirmation(false)}
        btnFunction={confirmationNext}
        title={'You did it, ' + user?.first_name}
        subtitle={subTitle}
      />
      <Modal
        scrollable={true}
        className='!max-w-[600px] selection: h-fit !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col min-h-fit item overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Pay and File</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col'>
              <div className='flex flex-col'>
                <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-highlight'>
                  <span className='stat-card-header'>
                    COMPANY WALLET BALANCE{' '}
                    <span className='!text-[13px] text-color-black font-bold'>
                      (VFD - {company?.account_number} )
                    </span>
                  </span>
                  <span className='!text-[16px] text-color-black font-bold'>
                    {company?.total_balance?.toLocaleString()}
                  </span>
                </div>
                <hr className='divider mt-2' />
                <div className='flex flex-col items-center mt-[2%]'>
                  <span className='stat-card-header font-semibold'>TOTAL PAYABLE</span>
                  <span className='p4-medium  text-color-black'>{takeHome}</span>
                </div>
                <hr className='divider' />

                <div className='flex flex-col items-center mt-[2%]'>
                  <span className='stat-card-header font-semibold'>TOTAL FEES</span>
                  <span className='p4-medium  text-color-black'>
                    {transCost}
                  </span>
                </div>
              </div>
            </div>
            <hr className='divider' />

            <div className='flex mb-8'>
              <div className='flex flex-row px-4 py-2 border-secondary-4 border-[1.2px] rounded-lg bg-color-warning-light !text-[14px]'>
                <div className='w-[80px] pr-2 flex flex-row items-center'>
                  <img src={WarningSVG} alt='' srcSet='' />
                </div>
                  <span className='text-[13px] text-justify'>
                    Ropay will debit <span className='font-semibold'>{totalAmount} </span>
                    from your account for this {type}. Please Make sure the funds are available in your account.
                  </span>
              </div>
            </div>
            <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4'>
              <FormOtpInput
                value={pin}
                valueLength={4}
                onChange={(value) => setPIN(value)}
                label={'Enter your new 4 digit PIN'}
              />
            </div>
            <div className='w-full mb-[20px]'>
              <Button
                text='PAY'
                type='submit'
                disabled={false}
                loading={pensionLoading || taxLoading}
                onClick={() => submitForm(refValue)}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default StatutorySummary;
