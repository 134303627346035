import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import FormSwitch from '../../../form-switch';
import Modal from '../../modal';
import {
  countriesAndState,
  AllCountries,
} from '../../../../constants/countries';
import { useCreateSingleCustomer, useUpdateCustomer } from '../../../../redux/spend-management/customer/hook';

function AddCustomer({
  isOpen,
  closeModal,
  refresh,
  customerData,
  title,
  buttonText,
  completed,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync: addCustomer, isLoading: addCustomerloading } =
    useCreateSingleCustomer();
  const { mutateAsync: editCustomer, isLoading: editCustomerLoading } =
    useUpdateCustomer();

  const [country, setCountry] = useState('Nigeria');
  const [state, setState] = useState('Lagos');
  const [stateOptions, setStateOptions] = useState([]);
  const [customer, setCustomer] = useState();

  const [customerName, setCustomerName] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [status, setStatus] = useState(true);
  const [customerType, setCustomerType] = useState();

  useEffect(() => {
    if (customerData) {
      setCustomer(customerData.id);
      setCustomerName(customerData.customer_name);
      setContactPerson(customerData.contact_person);
      setMobile(customerData.mobile);
      setEmail(customerData.email);
      setStreetAddress(customerData.street_address);
      setCity(customerData.city);
      setStatus(customerData.status === 1 ? true : false);
      setCustomerType('edit');
    } else {
      setCustomerType('add');
    }
  }, [customerData]);

  useEffect(() => {
    if (country) {
      let getStates = countriesAndState.find((a) => a.name === country);

      if (getStates) {
        let computedStates = [];

        getStates.states.map((item, index) => {
          computedStates.push({ label: item, value: item });
        });

        setStateOptions(computedStates);
      }
    }
  }, [country]);

  const submitForm = async (data) => {
    let customer_name = DOMPurify.sanitize(data?.customer_name);
    let contact_person = DOMPurify.sanitize(data?.contact_person);
    let mobile = DOMPurify.sanitize(data?.mobile);
    let email = DOMPurify.sanitize(data?.email);
    let street_address = DOMPurify.sanitize(data?.address);
    let city = DOMPurify.sanitize(data?.city);
    if (customerType === 'add') {
      let payload = {
        customer_name,
        contact_person,
        mobile,
        email,
        street_address,
        city,
        state,
        country,
        status: 1,
      };
      await addCustomer(payload).then(() => {
        completed();
      });
    } else if (customerType === 'edit') {
      let editPayload = {
        customerId: customer,
        data: {
          customer_name,
          contact_person,
          mobile,
          email,
          street_address,
          city,
          state,
          country,
          status: status === true ? 1 : 0,
        },
      };
      await editCustomer(editPayload).then(() => {
        completed();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !md:min-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='px-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => completed()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col md:flex-row w-full justify-between'>
              <div className='w-full md:w-1/2 md:pr-2'>
                <FormInput
                  label='Customer Name'
                  name='customer_name'
                  type='text'
                  placeholder={'Enter customer name'}
                  defaultValue={customerName}
                  onChange={(e) => {
                    setCustomerName(e.target.value);
                  }}
                  inputRef={register(formValidation('text', true))}
                  readOnly={addCustomerloading || editCustomerLoading}
                  error={errors.customer_name}
                  errorMessage={
                    errors.customer_name && errors.customer_name.message
                  }
                />
              </div>

              <div className='w-full md:w-1/2 md:pl-2'>
                <FormInput
                  label='Contact Person'
                  name='contact_person'
                  placeholder={'Enter contact person'}
                  type='text'
                  defaultValue={contactPerson}
                  onChange={(e) => {
                    setContactPerson(e.target.value);
                  }}
                  inputRef={register(formValidation('text', true))}
                  readOnly={addCustomerloading || editCustomerLoading}
                  error={errors.contact_person}
                  errorMessage={
                    errors.contact_person && errors.contact_person.message
                  }
                />
              </div>
            </div>
            <div>
              <div className='flex flex-col md:flex-row w-full justify-between'>
                <div className='w-full md:w-3/5 md:pr-2'>
                  <FormInput
                    label='Email (Optional)'
                    name='email'
                    type='email'
                    defaultValue={email}
                    placeholder={'Enter email address'}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    inputRef={register(formValidation('email', false))}
                    readOnly={addCustomerloading || editCustomerLoading}
                    error={errors.email}
                    errorMessage={errors.email && errors.email.message}
                  />
                </div>

                <div className='w-full md:w-2/5 md:pl-2'>
                  <FormInput
                    label='Mobile'
                    name='mobile'
                    type='text'
                    defaultValue={mobile}
                    placeholder={'Enter mobile number'}
                    onChange={(e) => {
                      setMobile(e.target.value);
                    }}
                    inputRef={register(formValidation('text', true))}
                    readOnly={addCustomerloading || editCustomerLoading}
                    error={errors.mobile}
                    errorMessage={errors.mobile && errors.mobile.message}
                  />
                </div>
              </div>
              <div className='flex flex-col md:flex-row w-full justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormSelect
                    defaultValue={country}
                    options={AllCountries}
                    onChange={(selected) => {
                      setCountry(selected);
                    }}
                    label='Country (Optional)'
                  />
                </div>

                <div className='w-full md:w-1/2 md:pl-2'>
                  {country == 'Nigeria' ? (
                    <FormSelect
                      defaultValue={state}
                      options={stateOptions}
                      onChange={(selected) => {
                        setState(selected);
                      }}
                      label='State (Optional)'
                    />
                  ) : (
                    <FormInput
                      label='State (Optional)'
                      name='state'
                      type='text'
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      error={errors.state}
                      errorMessage={errors.state && errors.state.message}
                    />
                  )}
                </div>
              </div>

              <div className='flex flex-col md:flex-row justify-between'>
                <div className='w-full md:w-2/5 md:pr-2'>
                  <FormInput
                    label='City (Optional)'
                    name='city'
                    type='text'
                    defaultValue={city}

                    inputRef={register(formValidation('text', false))}
                    readOnly={addCustomerloading || editCustomerLoading}
                    error={errors.city}
                    errorMessage={errors.city && errors.city.message}
                  />
                </div>
                <div className='w-full md:w-3/5 md:pl-2'>
                  <FormInput
                    label='Stress Address (Optional)'
                    name='address'
                    type='text'
                    defaultValue={streetAddress}
                    inputRef={register(formValidation('text', false))}
                    readOnly={addCustomerloading || editCustomerLoading}
                    error={errors.address}
                    errorMessage={errors.address && errors.address.message}
                  />
                </div>
              </div>
              {customerType === 'edit' ? (
                <>
                    <hr className='divider' />
                    <FormSwitch
                    label={'Status'}
                    name={'status'}
                    readOnly={addCustomerloading || editCustomerLoading}
                    checked={status}
                    onClick={() => {
                        setStatus(!status);
                    }}
                    value={status}
                    />
                </>
              ) : null}
            </div>

            <div className='w-full mt-4'>
              <Button
                text={buttonText}
                type='submit'
                disabled={false}
                loading={addCustomerloading || editCustomerLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddCustomer;
