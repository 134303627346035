import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { employeeGroups } from '../../../api/employees/groups';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';

export function useGetEmployeeGroups(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-groups', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.getEmployeeGroups({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((group) => {
          group.computedActions = [0, 1, 2, 3];
          group.created = moment(group?.created).format('MMMM D, YYYY');
          if (group && group.description && group.description.length >= 30) {
            group.description = group.description.substring(0, 30) + '....';
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddEmployeeGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.addEmployeeGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-groups');
        store.dispatch(setAlert(true, 'success', 'Payroll group added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditEmployeeGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.editEmployeeGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('group-settings');
        store.dispatch(setAlert(true, 'success', 'Payroll group updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetOnboardingEmployeeGroups(
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    ['employee-onboarding-groups', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.getEmployeeGroups({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Payroll Group Selected' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetEmployeeGroup(id) {
  return useQuery(
    ['employee-group', { id }],
    () => {
      return employeeGroups.getEmployeeGroup({
        id,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data.status = 1;
        data?.employees?.data?.forEach((employee) => {
          employee.employeeName = `${employee.employee_first_name} ${employee.employee_last_name}`;
        });

        return data;
      },
      onSettled: () => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetGroupEmployees(id, limit, page, statusFilter, search) {
  return useQuery(
    ['group-employees', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.getGroupEmployees({
        id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        if (data?.employees) {
          data.employees.name = data?.name;
          data.employees.id = data?.id;
        }
        data?.employees?.data?.forEach((employee) => {
          employee.created = moment(employee?.created).format('MMMM D, YYYY');
          employee.computedActions = [0];
          employee.status = employee.employee_status;
          employee.employeeName = `${employee.employee_first_name} ${employee.employee_last_name}`;
        });
        return data?.employees;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetGroupSettings(id) {
  return useQuery(
    ['group-settings', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.getGroupSettings({
        id,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data.setting.description = data?.description;
        data.setting.id = data?.id;
        data.setting.group_name = data?.name;
        return data?.setting;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddEmployeesToGroup() {
  return useMutation(
    (payload) => {
      return employeeGroups.addEmployeesToGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-groups');
        store.dispatch(setAlert(true, 'success', 'Employee(s) added to payroll group successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRemoveEmployeesFromGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.removeEmployeesFromGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('group-employees');
        store.dispatch(setAlert(true, 'success', 'Employee removed from payroll group successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMoveEmployeesToGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeGroups.moveEmployeesToGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('group-employees');
        store.dispatch(setAlert(true, 'success', 'Employee moved successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeletePayrollGroup() {
  return useMutation(
    (payload) => {
      return employeeGroups.deletePayrollGroup(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee-groups');
        store.dispatch(setAlert(true, 'success', 'Payroll Group deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
