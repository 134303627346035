import classNames from "classnames";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Collapse } from "reactstrap";
import CloseSVG from "../../../../assets/svg/close.svg";
import { formValidation } from "../../../../utils/functions";
import Button from "../../../button";
import CollapsingIcon from "../../../collapsing-icon";
import FormInput from "../../../form-input";
import FormSelect from "../../../form-select";
import FormSwitch from "../../../form-switch";
import FormTextArea from "../../../form-textarea";
import FormSelectEmployee from "../../../employees/form-select-employee";
import {
  countriesAndState,
  AllCountries,
} from "../../../../constants/countries";
import {
  useAddBranch,
  useEditBranch,
} from "../../../../redux/human-resources/hook/branches";
import Modal from "../../modal";
import { useGetAttendanceSettings } from "../../../../redux/human-resources/hook/attendance";
import AsyncSelect from "react-select/async";
import { employees as employeeAPI } from "../../../../api/employees";
import { setAlert } from "../../../../redux/components/components-slice";
import { useAddEmployeesToBranch } from "../../../../redux/human-resources/hook/branches";
import { useDispatch } from "react-redux";
import LoadingBar from "../../../loader-bar";

function AddBranch({
  isOpen,
  closeModal,
  refresh,
  branchData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { mutateAsync: addEmployeesToBranch, isLoading: addEmployeeLoading } =
    useAddEmployeesToBranch();

  const [employees, setEmployees] = useState([]);
  const dispatch = useDispatch();

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 10,
          statusFilter: 1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const [workDays, setWorkdays] = useState([
    {
      day: "Sunday",
      tag: "SUN",
      work: 0,
    },
    {
      day: "Monday",
      tag: "MON",
      work: 1,
    },
    {
      day: "Tuesday",
      tag: "TUE",
      work: 1,
    },
    {
      day: "Wednesday",
      tag: "WED",
      work: 1,
    },
    {
      day: "Thursday",
      tag: "THU",
      work: 1,
    },
    {
      day: "Friday",
      tag: "FRI",
      work: 1,
    },
    {
      day: "Saturday",
      tag: "SAT",
      work: 0,
    },
  ]);

  const [branchId, setBranchId] = useState(null);
  const [branchType, setBranchType] = useState("add");

  const [applyToPayroll, setApplyToPayroll] = useState(false);
  const [clockinWithLink, setClockinWithLink] = useState(false);

  const [dayCount, setDayCount] = useState("seven_days");
  const dayCountOptions = [
    { value: "seven_days", label: "Calendar Days" },
    { value: "week_days", label: "Working Days" },
  ];

  const { data: attendance_setting, isLoading } =
    useGetAttendanceSettings("company");

  const [branchLocation, setBranchLocation] = useState("");

  const [branchLocationType, setBranchLocationType] = useState("local");
  const branchLocationTypeOptions = [
    { value: "local", label: "Local" },
    { value: "foreign", label: "Foreign" },
  ];

  const [autoConfirm, setAutoConfirm] = useState(true);

  const [approveLeave, setApproveLeave] = useState(true);
  const [evaluatePerformance, setEvaluatePerformance] = useState(true);

  const [canMakePayments, setCanMakePayments] = useState(false);
  const [singlePaymentLimit, setSinglePaymentLimit] = useState(0);
  const [canInitiatePayment, setCanInitiatePayment] = useState(false);
  const [canReadPayroll, setCanReadPayroll] = useState(false);
  const [canWritePayroll, setCanWritePayroll] = useState(false);
  const [canViewTransactions, setCanViewTransactions] = useState(true);

  const [clockIn, setClockIn] = useState("09:00");
  const [clockOut, setClockOut] = useState("17:00");

  const [manager, setManager] = useState();
  const [managerName, setManagerName] = useState();

  const [applicationStart, setApplicationStart] = useState(60);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [country, setCountry] = useState("Nigeria");
  const [state, setState] = useState("Lagos");
  const [city, setCity] = useState("");
  const [stateOptions, setStateOptions] = useState([]);

  const [addEmployee, setAddEmployee] = useState(false);

  useEffect(() => {
    if (branchData) {
      setAddEmployee(false);
      setBranchId(branchData.id);
      setBranchType("edit");
      setManager(branchData.manager_setting?.manager_id);
      setManagerName(branchData.manager_setting?.manager);
      setEvaluatePerformance(
        branchData.manager_setting?.evaluate_performance == 1 ? true : false
      );
      setCanMakePayments(
        branchData.manager_setting?.can_make_payments == 1 ? true : false
      );
      setSinglePaymentLimit(
        branchData.manager_setting?.single_payment_limit
          ? branchData.manager_setting.single_payment_limit
          : 0
      );
      setCanInitiatePayment(
        branchData.manager_setting?.can_initiate_payment == 1 ? true : false
      );
      setCanReadPayroll(
        branchData.manager_setting?.can_read_payroll == 1 ? true : false
      );
      setCanWritePayroll(
        branchData.manager_setting?.can_write_payroll == 1 ? true : false
      );
      setCanViewTransactions(
        branchData.manager_setting?.can_read_transaction_history == 1
          ? true
          : false
      );
      setDayCount(
        branchData.leave_settings?.days_count
          ? branchData.leave_settings?.days_count
          : "seven_days"
      );
      setBranchLocation(branchData.location);
      setBranchLocationType(branchData.branch_type);
      setCountry(branchData.country);
      setState(branchData.state);
      setCity(branchData.city);
      setApplicationStart(
        branchData.leave_settings?.application_start
          ? branchData.leave_settings?.application_start
          : 60
      );
      setWorkdays([
        {
          day: "Sunday",
          tag: "SUN",
          work: branchData.attendance_setting?.workdays?.sun === 1 ? 1 : 0,
        },
        {
          day: "Monday",
          tag: "MON",
          work: branchData.attendance_setting?.workdays?.mon === 1 ? 1 : 0,
        },
        {
          day: "Tuesday",
          tag: "TUE",
          work: branchData.attendance_setting?.workdays?.tue === 1 ? 1 : 0,
        },
        {
          day: "Wednesday",
          tag: "WED",
          work: branchData.attendance_setting?.workdays?.wed === 1 ? 1 : 0,
        },
        {
          day: "Thursday",
          tag: "THU",
          work: branchData.attendance_setting?.workdays?.thu === 1 ? 1 : 0,
        },
        {
          day: "Friday",
          tag: "FRI",
          work: branchData.attendance_setting?.workdays?.fri === 1 ? 1 : 0,
        },
        {
          day: "Saturday",
          tag: "SAT",
          work: branchData.attendance_setting?.workdays?.sat === 1 ? 1 : 0,
        },
      ]);
      setApplyToPayroll(
        branchData.attendance_setting?.apply_to_payroll === 1 ? true : false
      );
      setClockinWithLink(
        branchData.attendance_setting?.clockin_with_link === 1 ? true : false
      );
      setAutoConfirm(
        branchData.attendance_setting?.auto_confirm === 1 ? true : false
      );
      setName(branchData.name);
      setDescription(branchData.description);
      setClockIn(
        branchData.attendance_setting?.clockin_time
          ? branchData.attendance_setting?.clockin_time
          : "09:00"
      );
      setClockOut(
        branchData.attendance_setting?.clockout_time
          ? branchData.attendance_setting?.clockout_time
          : "17:00"
      );
    } else if (attendance_setting) {
      setWorkdays([
        {
          day: "Sunday",
          tag: "SUN",
          work: attendance_setting?.workdays?.sun === 1 ? 1 : 0,
        },
        {
          day: "Monday",
          tag: "MON",
          work: attendance_setting?.workdays?.mon === 1 ? 1 : 0,
        },
        {
          day: "Tuesday",
          tag: "TUE",
          work: attendance_setting?.workdays?.tue === 1 ? 1 : 0,
        },
        {
          day: "Wednesday",
          tag: "WED",
          work: attendance_setting?.workdays?.wed === 1 ? 1 : 0,
        },
        {
          day: "Thursday",
          tag: "THU",
          work: attendance_setting?.workdays?.thu === 1 ? 1 : 0,
        },
        {
          day: "Friday",
          tag: "FRI",
          work: attendance_setting?.workdays.fri === 1 ? 1 : 0,
        },
        {
          day: "Saturday",
          tag: "SAT",
          work: attendance_setting?.workdays?.sat === 1 ? 1 : 0,
        },
      ]);
      setApplyToPayroll(
        attendance_setting?.apply_to_payroll == 1 ? true : false
      );
      setClockinWithLink(
        attendance_setting?.clockin_with_link == 1 ? true : false
      );
      setAutoConfirm(attendance_setting?.auto_confirm);
      setClockIn(
        attendance_setting?.clockin_time
          ? attendance_setting?.clockin_time
          : "09:00"
      );
      setClockOut(
        attendance_setting?.clockout_time
          ? attendance_setting?.clockout_time
          : "17:00"
      );
      setBranchType("add");
    }
  }, [branchData, attendance_setting]);

  const [showBranchManagerSettings, setShowBranchManagerSettings] =
    useState(true);
  const [showBranchLeaveSettings, setShowBranchLeaveSettings] = useState(true);
  const [showBranchAttendanceSettings, setShowBranchAttendanceSettings] =
    useState(true);

  const { mutateAsync: addBranch, isLoading: addBranchloading } =
    useAddBranch();
  const { mutateAsync: editBranch, isLoading: editBranchloading } =
    useEditBranch();

  useEffect(() => {
    if (country) {
      let getStates = countriesAndState.find((a) => a.name === country);

      if (getStates) {
        let computedStates = [];

        getStates.states.map((item, index) => {
          computedStates.push({ label: item, value: item });
        });

        setStateOptions(computedStates);
      }
    }
  }, [country]);

  const submitForm = async (data) => {
    if (manager) {
      const name = DOMPurify.sanitize(data?.name);
      const application_start = DOMPurify.sanitize(data?.application_start);
      const clock_in = DOMPurify.sanitize(data?.clock_in);
      const clock_out = DOMPurify.sanitize(data?.clock_out);

      let payload = {
        name,
        description,
        branch_location: branchLocation,
        branch_type: branchLocationType,
        manager_id: manager,
        approve_leave: approveLeave,
        evaluate_performance: evaluatePerformance ? 1 : 0,
        application_start: application_start,
        apply_to_payroll: applyToPayroll ? 1 : 0,
        clockin_with_link: clockinWithLink ? 1 : 0,
        days_count: dayCount,
        clockin_time: clock_in,
        clockout_time: clock_out,
        country: country,
        city,
        can_make_payments: canMakePayments ? 1 : 0,
        single_payment_limit: singlePaymentLimit,
        can_initiate_payment: canInitiatePayment ? 1 : 0,
        can_read_payroll: canReadPayroll ? 1 : 0,
        can_write_payroll: canWritePayroll ? 1 : 0,
        can_read_transaction_history: canViewTransactions ? 1 : 0,
        state: state,
        auto_confirm: autoConfirm,
        sun: workDays[0].work,
        mon: workDays[1].work,
        tue: workDays[2].work,
        wed: workDays[3].work,
        thu: workDays[4].work,
        fri: workDays[5].work,
        sat: workDays[6].work,
      };

      if (branchType === "add") {
        await addBranch(payload).then((result) => {
          if (addEmployee) {
            if (result?.status == "success" && result?.data) {
              if (employees.length < 1) {
                closeModal();
              }
              let employeeArrays = [];

              employees.forEach((item) => {
                employeeArrays.push(item.value);
              });

              let employee_ids = employeeArrays.join();

              let payload = {
                branch_id: result.data.id,
                employee_ids: employee_ids,
              };

              addEmployeesToBranch(payload).then(() => {
                closeModal();
              });
            } else {
              closeModal();
            }
          } else {
            closeModal();
          }
        });
      } else if (branchType === "edit") {
        let editPayload = {
          id: branchId,
          data: payload,
        };
        await editBranch(editPayload).then(() => {
          closeModal();
        });
      }
    } else {
      dispatch(
        setAlert(true, "info", "Please select a manager for this branch")
      );
      return;
    }
  };

  return (
    <Modal
      scrollable={true}
      className="!max-w-[600px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col pb-4 overflow-auto">
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <div className="pl-8 pr-8 pt-4">
            <div className="flex flex-row justify-between items-center">
              <span className="header-4">{title}</span>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <img src={CloseSVG} alt="close" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2" />
          {isLoading ? (
            <div className="flex flex-col mx-8">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-col mx-8">
              <div>
                <div className="flex flex-col border bg-highlight p-2 rounded-md">
                  <FormInput
                    label="Branch Name"
                    placeholder={"Enter branch name"}
                    name="name"
                    type="text"
                    defaultValue={name}
                    onInput={(e) => {
                      setName(e.target.value);
                    }}
                    inputRef={register(formValidation("text", true))}
                    error={errors.name}
                    errorMessage={errors.name && errors.name.message}
                    className={"bg-white"}
                  />
                  <FormTextArea
                    label={"Branch Description"}
                    name="description"
                    placeholder={"Brief Description"}
                    value={description}
                    onInput={(e) => {
                      setDescription(e.target.value);
                    }}
                    className={"bg-white"}
                  />
                </div>
                <hr className="divider mt-2 mb-2" />
                <div className="flex md:flex-row flex-col w-full justify-between">
                  <div className="w-full md:w-1/2 md:pr-2">
                    <FormSelect
                      value={branchLocationType}
                      options={branchLocationTypeOptions}
                      onChange={(selected) => {
                        setBranchLocationType(selected);
                      }}
                      label="Branch Type"
                    />
                  </div>
                  <div className="w-full md:w-1/2 md:pl-2">
                    <FormSelect
                      defaultValue={country}
                      options={AllCountries}
                      onChange={(selected) => {
                        setCountry(selected);
                      }}
                      label="Country"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row justify-between">
                  <div className="w-full md:w-1/2 md:pr-2">
                    {country == "Nigeria" ? (
                      <FormSelect
                        defaultValue={state}
                        options={stateOptions}
                        onChange={(selected) => {
                          setState(selected);
                        }}
                        label="State"
                      />
                    ) : (
                      <FormInput
                        label="State"
                        name="state"
                        placeholder={"Enter branch state"}
                        type="text"
                        value={state}
                        onInput={(e) => setState(e.target.value)}
                        error={errors.state}
                        errorMessage={errors.state && errors.state.message}
                      />
                    )}
                  </div>
                  <div className="w-full md:w-1/2 md:pl-2">
                    <FormInput
                      label="City"
                      placeholder={"Enter branch city"}
                      name="city"
                      type="text"
                      value={city}
                      onInput={(e) => setCity(e.target.value)}
                      inputRef={register(formValidation("text", true))}
                      error={errors.city}
                      errorMessage={errors.city && errors.city.message}
                    />
                  </div>
                </div>
                <FormInput
                  label="Address"
                  name="location"
                  type="text"
                  placeholder={"Enter branch address"}
                  defaultValue={branchLocation}
                  onInput={(e) => {
                    setBranchLocation(e.target.value);
                  }}
                  inputRef={register(formValidation("text", true))}
                  error={errors.branch_location}
                  errorMessage={
                    errors.branch_location && errors.branch_location.message
                  }
                />
              </div>
              <hr className="divider mt-0" />
              <div>
                <span className="header-4">Branch Settings</span>
              </div>
              <hr className="divider mb-1" />
              <>
                <div
                  className={classNames(
                    "flex flex-row justify-between items-center cursor-pointer hover:bg-secondary-2-extralight hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                    {
                      "pt-3 pb-3 bg-secondary-2-extralight rounded-md mb-2 px-2":
                        showBranchManagerSettings,
                    }
                  )}
                  onClick={() =>
                    setShowBranchManagerSettings(!showBranchManagerSettings)
                  }
                >
                  <span>Manager Settings</span>
                  <div className="pr-4">
                    <CollapsingIcon
                      defaultPosition="left"
                      isOpen={showBranchManagerSettings}
                    />
                  </div>
                </div>
                <div
                  className={classNames("flex flex-col", {
                    hidden: !showBranchManagerSettings,
                  })}
                >
                  <Collapse isOpen={showBranchManagerSettings}>
                    <div className="grid md:grid-cols-1 grid-cols-1 gap-2 w-full p-3 border bg-gray-50 rounded-md">
                      <FormSelectEmployee
                        label={"Branch Manager"}
                        name="manager"
                        placeholder="Type to select manager"
                        value={manager}
                        empName={managerName}
                        setEmployee={setManager}
                      />
                      <div className={"flex w-[100%] flex-col"}>
                        <div className="w-full">
                          <FormSwitch
                            label={"Can Approve Branch Leave?"}
                            name={"approve_leave"}
                            checked={approveLeave}
                            onClick={() => {
                              setApproveLeave(!approveLeave);
                            }}
                          />
                        </div>

                        <div className="w-full">
                          <FormSwitch
                            label={"Can Evaluate Branch Performance?"}
                            name={"evaluate_performance"}
                            checked={evaluatePerformance}
                            onClick={() => {
                              setEvaluatePerformance(!evaluatePerformance);
                            }}
                          />
                        </div>

                        <div className="w-full">
                          <FormSwitch
                            label={"Can Initiate Branch Payment?"}
                            name={"can_initiate_payment"}
                            checked={canInitiatePayment}
                            onClick={() => {
                              setCanInitiatePayment(!canInitiatePayment);
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <FormSwitch
                            label={"Can View Branch Transactions?"}
                            name={"can_read_transaction_history"}
                            checked={canViewTransactions}
                            onClick={() => {
                              setCanViewTransactions(!canViewTransactions);
                            }}
                          />
                        </div>
                        <div className="w-full -mb-2">
                          <FormSwitch
                            label={"Can Approve Branch Payments?"}
                            name={"can_make_payemnts"}
                            checked={canMakePayments}
                            onClick={() => {
                              setCanMakePayments(!canMakePayments);
                            }}
                          />
                        </div>
                        {canMakePayments ? (
                          <div className="p-3 pt-1 pb-1 border bg-white rounded-md">
                            <FormInput
                              label="Branch Payment Limit (0 means no limit)"
                              name="single_payment_limit"
                              placeholder={"Enter Amount"}
                              type="number"
                              value={singlePaymentLimit}
                              onInput={(e) =>
                                setSinglePaymentLimit(e.target.value)
                              }
                              error={errors.single_payment_limit}
                              errorMessage={
                                errors.single_payment_limit &&
                                errors.single_payment_limit.message
                              }
                            />
                          </div>
                        ) : null}

                        <hr className="divider mt-2" />
                        <div className="p-3 pt-3 pb-0 border bg-white rounded-md">
                          <FormSwitch
                            label={"Can View Branch Payroll?"}
                            name={"can_read_payroll"}
                            checked={canReadPayroll}
                            onClick={() => {
                              if (!canReadPayroll === false) {
                                setCanWritePayroll(!canReadPayroll);
                              }
                              setCanReadPayroll(!canReadPayroll);
                            }}
                          />
                          {canReadPayroll ? (
                            <FormSwitch
                              label={"Can Run Branch Payroll?"}
                              name={"can_write_payrol"}
                              checked={canWritePayroll}
                              onClick={() => {
                                setCanWritePayroll(!canWritePayroll);
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </>
              <hr className="divider mt-1 mb-1" />
              <>
                <div
                  className={classNames(
                    "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                    {
                      "pt-3 pb-3 bg-secondary-2-extralight rounded-md mb-2 px-2":
                        showBranchLeaveSettings,
                    }
                  )}
                  onClick={() =>
                    setShowBranchLeaveSettings(!showBranchLeaveSettings)
                  }
                >
                  <span>Leave Settings</span>

                  <div className="pr-4">
                    <CollapsingIcon
                      defaultPosition="left"
                      isOpen={showBranchLeaveSettings}
                    />
                  </div>
                </div>
                <div
                  className={classNames("flex flex-col", {
                    hidden: !showBranchLeaveSettings,
                  })}
                >
                  <Collapse isOpen={showBranchLeaveSettings}>
                    <div className="grid md:grid-cols-1 grid-cols-1 gap-2 w-full p-3 border bg-gray-50 rounded-md">
                      <div className="flex flex-row items-center">
                        <div className="w-full">
                          <FormInput
                            label="Application Start (From Date Of Employment)"
                            name="application_start"
                            type="number"
                            defaultValue={applicationStart}
                            onInput={(e) => {
                              setApplicationStart(e.target.value);
                            }}
                            inputRef={register(formValidation("number", true))}
                            error={errors.application_start}
                            errorMessage={
                              errors.application_start &&
                              errors.application_start.message
                            }
                          />
                        </div>
                        <span className="pt-1 italic text-[14px] pl-2 text-[#0d5225]">
                          Days
                        </span>
                      </div>
                      <div className="flex flex-row items-center pb-4">
                        <div className="w-full">
                          <FormSelect
                            defaultValue={dayCount}
                            options={dayCountOptions}
                            onChange={(selected) => {
                              setDayCount(selected);
                            }}
                            label="Day Count"
                          />
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </>
              <hr className="divider mt-1 mb-1" />
              <>
                <div
                  className={classNames(
                    "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                    {
                      "pt-3 pb-3 bg-secondary-2-extralight rounded-md mb-2 px-2":
                        showBranchAttendanceSettings,
                    }
                  )}
                  onClick={() =>
                    setShowBranchAttendanceSettings(
                      !showBranchAttendanceSettings
                    )
                  }
                >
                  <span>Attendance Settings</span>
                  <div className="pr-4">
                    <CollapsingIcon
                      defaultPosition="left"
                      isOpen={showBranchAttendanceSettings}
                    />
                  </div>
                </div>
                <div
                  className={classNames("flex flex-col", {
                    hidden: !showBranchAttendanceSettings,
                  })}
                >
                  <Collapse isOpen={showBranchAttendanceSettings}>
                    <div className="grid md:grid-cols-1 grid-cols-1 gap-2 w-full p-3 border bg-gray-50 rounded-md">
                      <div className="flex flex-row md:flex-col w-full justify-between">
                        <div className="w-full md:w-1/2 pr-2">
                          <FormInput
                            label="Resumption Time"
                            name="clock_in"
                            type="time"
                            defaultValue={clockIn}
                            onInput={(e) => {
                              setClockIn(e.target.value);
                            }}
                            inputRef={register(formValidation("time", true))}
                            error={errors.clock_in}
                            errorMessage={
                              errors.clock_in && errors.clock_in.message
                            }
                          />
                        </div>
                        <div className="w-full md:w-1/2 pl-2">
                          <FormInput
                            label="Closing Time"
                            name="clock_out"
                            type="time"
                            defaultValue={clockOut}
                            onInput={(e) => {
                              setClockOut(e.target.value);
                            }}
                            inputRef={register(formValidation("time", true))}
                            error={errors.clock_out}
                            errorMessage={
                              errors.clock_out && errors.clock_out.message
                            }
                          />
                        </div>
                      </div>
                      <div
                        className={
                          "flex w-[100%] flex-col items-center gap-3 mt-1 "
                        }
                      >
                        <div
                          className={
                            "flex w-full items-start justify-between gap-3 flex-col -mt-1"
                          }
                        >
                          <div className="text-color-gray text-[15px] ">
                            Work Days
                          </div>
                          <div className="flex flex-row items-center justify-between w-full p-[12px] border-1 border-gray-300 rounded -mt-4">
                            {workDays.map((item, index) => (
                              <button
                                type="button"
                                className={
                                  !item.work || item.work === 0
                                    ? "flex flex-row items-center !h-[24px] px-2 border-1 rounded border-[#0d5225] text-[#0d5225] text-[12px] cursor-pointer"
                                    : "flex flex-row items-center !h-[24px] px-2 border-1 rounded border-[#0d5225] bg-[#0d5225] text-color-white text-[12px] cursor-pointer"
                                }
                                onClick={() => {
                                  let newArray = JSON.parse(
                                    JSON.stringify(workDays)
                                  );

                                  newArray[index].work =
                                    !item.work || item.work === 0 ? 1 : 0;
                                  setWorkdays(newArray);
                                }}
                              >
                                {item.tag}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="w-full mt-2">
                        <FormSwitch
                          label={"Apply Attendance To Payroll?"}
                          name={"apply_to_payroll"}
                          checked={applyToPayroll}
                          onClick={() => {
                            setApplyToPayroll(!applyToPayroll);
                          }}
                        />
                      </div>
                      <div className="w-full">
                        <FormSwitch
                          label={"Employee Clockin With Link?"}
                          name={"clockin_with_link"}
                          checked={clockinWithLink}
                          onClick={() => {
                            setClockinWithLink(!clockinWithLink);
                          }}
                        />
                      </div>
                    </div>
                  </Collapse>
                </div>
              </>
              {branchType === "add" ? (
                <>
                  <hr className="divider mt-3 mb-3" />
                  <FormSwitch
                    label={"Do you want to add employees?"}
                    name={"add_employee"}
                    onClick={() => {
                      setAddEmployee(!addEmployee);
                    }}
                    checked={addEmployee}
                  />
                  <hr className="divider -mt-1 mb-2" />
                  {addEmployee ? (
                    <div className="border rounded-md bg-secondary-2-extralight p-2">
                      <label className="text-[13px] leading-[23px] font-normal text-color-gray mb-1">
                        Select Multiple Employees
                      </label>
                      <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        className="text-[13px]"
                        placeholder={"Type to select multiple Employees"}
                        loadOptions={loadOptions}
                        onChange={(options) => setEmployees(options)}
                        classNamePrefix="mySelect"
                      />
                    </div>
                  ) : null}
                </>
              ) : null}

              <div className="w-full mt-[20px]">
                <Button
                  text={buttonText}
                  type="submit"
                  disabled={false}
                  loading={
                    addBranchloading || editBranchloading || addEmployeeLoading
                  }
                />
              </div>
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
}

export default AddBranch;
