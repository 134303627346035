import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import FormFileInput from "../../../components/form-file-input";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import { useCreateBulkEmployees } from "../../../redux/employees/hook";
import { formValidation } from "../../../utils/functions";
import Excel from "exceljs";
import { saveAs } from "file-saver";

function CreateBulkEmployee() {
  useEffect(() => {}, []);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const columns = [
    { header: "Employee's First Name", key: "first_name" },
    { header: "Employee's Middle Name", key: "middle_name" },
    { header: "Employee's Last Name", key: "last_name" },
    { header: "Phone Number", key: "mobile" },
    { header: "Account Number", key: "account_number" },
    { header: "Bank Name", key: "bank_name" },
    { header: "Email Address", key: "email" },
    { header: "Employee Payroll Group", key: "payroll_group" },
    { header: "Address", key: "address" },
    { header: "City", key: "city" },
    { header: "State", key: "state" },
    { header: "Country", key: "country" },
    { header: "Pension Number", key: "pension_number" },
    { header: "Pension Fund Administrator (PFA)", key: "pfa" },
    { header: "Tax Number", key: "tax_number" },
    { header: "Date Of Birth (YYYY-MM-DD)", key: "date_of_birth" },
    { header: "Date Joined (YYYY-MM-DD)", key: "date_joined" },
    { header: "Staff Number", key: "staff_number" },
    { header: "Role", key: "job_role" },
    { header: "Gender (Male/Female)", key: "gender" },
    { header: "Marital Status (Single/Married)", key: "marital_satatus" },
    { header: "Next Of Kin Name", key: "next_of_kin_name" },
    { header: "Next Of Kin Mobile Number", key: "next_of_kin_mobile" },
    { header: "Next Of Kin Email", key: "next_of_kin_email" },
    { header: "Next Of Kin Relationship", key: "next_of_kin_relationship" },
    { header: "Next Of Kin Address", key: "next_of_kin_address" },
    { header: "", key: "none" },
    { header: "Banks", key: "bank_name" },
    { header: "Pension Fund Administrators", key: "pfa_name" },
  ];

  const [exportLoading, setSetExportLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const exportTemplate = async () => {
    setSetExportLoading(true);
    const workSheetName = "active_employee_data";
    const workBookName = `Employee upload data`;
    try {
      const fileName = workBookName;

      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      worksheet.columns.forEach((column) => {
        if (column._number == 16 || column._number == 17) {
          column.width = column.header.length;
          column.numFmt = "YYYY-MM-DD";
        } else {
          column.width = 25;
        }
      });

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };
      // loop through all of the rows and set the outline style.
      let data = [
        { bank_name: "Access Bank", pfa_name: "AIICO Pensions" },
        { bank_name: "Access Bank (Diamond)", pfa_name: "APT Pensions" },
        { bank_name: "AccessMobile", pfa_name: "ARM Pensions" },
        {
          bank_name: "Accion Microfinance Bank",
          pfa_name: "AXA Mansard (Tangerine Pensions)",
        },
        { bank_name: "ALAT by WEMA", pfa_name: "CrusaderSterling Pensions" },
        {
          bank_name: "ASO Savings and Loans",
          pfa_name: "FCMB Pensions (Legacy Pension Managers)",
        },
        { bank_name: "Cellulant", pfa_name: "Fidelity Pensions" },
        { bank_name: "Citi Bank", pfa_name: "First Guarantee Pensions" },
        { bank_name: "Carbon", pfa_name: "IEI-Anchor Pensions" },
        { bank_name: "eTranzact", pfa_name: "Investment One Pensions" },
        { bank_name: "Ecobank Nigeria", pfa_name: "Leadway Pensure PFA" },
        { bank_name: "Ecobank Xpress Account", pfa_name: "NPF Pensions" },
        { bank_name: "Eyowo MFB", pfa_name: "NLPC Pensions" },
        { bank_name: "EcoMobile", pfa_name: "OAK Pensions" },
        {
          bank_name: "Enterprise Bank",
          pfa_name: "Pensions Alliance (PAL Pensions)",
        },
        { bank_name: "FBN Mortgages Limited", pfa_name: "Premium Pensions" },
        { bank_name: "FIRSTMONIE", pfa_name: "Radix Pensions" },
        { bank_name: "FBNQuest Merchant Bank", pfa_name: "Sigma Pensions" },
        {
          bank_name: "First City Monument Bank",
          pfa_name: "Stanbic IBTC Pensions",
        },
        { bank_name: "FCMB Easy Account", pfa_name: "Trustfund Pensions" },
        { bank_name: "Fidelity Bank", pfa_name: "Veritas Glanvills Pensions" },
        { bank_name: "Fidelity Mobile", pfa_name: "" },
        { bank_name: "First Bank PLC", pfa_name: "" },
        { bank_name: "Fortis Microfinance Bank", pfa_name: "" },
        { bank_name: "FORTIS MOBILE", pfa_name: "" },
        { bank_name: "FSDH Merchant Bank", pfa_name: "" },
        { bank_name: "Flutterwave Technology Solutions Limited", pfa_name: "" },
        { bank_name: "Globus Bank", pfa_name: "" },
        { bank_name: "GTMobile", pfa_name: "" },
        { bank_name: "Guaranty Trust Bank" },
        { bank_name: "Heritage Bank" },
        { bank_name: "HOPE PAYMENT SERVICE BANK" },
        { bank_name: "Jaiz Bank" },
        { bank_name: "Keystone Bank" },
        { bank_name: "Kuda Bank" },
        { bank_name: "MIMO (Mkudi)" },
        { bank_name: "MoneyBox" },
        { bank_name: "MONIEPOINT MICROFINANCE BANK" },
        { bank_name: "Momo Payment Service Bank" },
        { bank_name: "New Prudential Bank" },
        { bank_name: "One Finance" },
        { bank_name: "Opay Digital Services/Paycom" },
        { bank_name: "Paga" },
        { bank_name: "Page Financials" },
        { bank_name: "PALMPAY" },
        { bank_name: "Parralex Microfinance bank" },
        { bank_name: "Parallex Bank" },
        { bank_name: "Parkway-ReadyCash" },
        { bank_name: "PARKWAY MICROFINANCE BANK" },
        { bank_name: "PayAttitude Online" },
        { bank_name: "Polaris Bank" },
        { bank_name: "PremiumTrust Bank" },
        { bank_name: "Providus Bank" },
        { bank_name: "PURPLEMONEY MICROFINANCE BANK" },
        { bank_name: "Reliance Microfinance Bank" },
        { bank_name: "RenMoney Microfinance Bank" },
        { bank_name: "Rubies Microfinance Bank" },
        { bank_name: "Sparkle Microfinance Bank" },
        { bank_name: "Stanbic Ease Wallet" },
        { bank_name: "Stanbic IBTC Bank" },
        { bank_name: "Standard Chartered Bank" },
        { bank_name: "Sterling Bank" },
        { bank_name: "Suntrust Bank" },
        { bank_name: "TAJ Bank" },
        { bank_name: "Tangerine Bank" },
        { bank_name: "TagPay" },
        { bank_name: "Titan Trust Bank" },
        { bank_name: "Trident Microfinance Bank" },
        { bank_name: "Union Bank PLC" },
        { bank_name: "United Bank For Africa" },
        { bank_name: "Unity Bank" },
        { bank_name: "VFD Micro Finance Bank" },
        { bank_name: "Wema Bank" },
        { bank_name: "Zenith Bank" },
        { bank_name: "ZENITH EAZY WALLET" },
        { bank_name: "ZenithMobile" },
      ];
      data?.forEach((singleData) => {
        worksheet.addRow(singleData);
      });

      worksheet.eachRow({ includeEmpty: true }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;
          worksheet.getCell(cellAddress).border = {
            top: { style: "none" },
            left: { style: "none" },
            bottom: { style: "none" },
            right: { style: "none" },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      // console.error('<<<ERRROR>>>', error);
      // console.error('Something Went Wrong', error.message);
    } finally {
      workbook.removeWorksheet(workSheetName);
    }
    setSetExportLoading(false);
  };

  const { mutateAsync, isLoading } = useCreateBulkEmployees();

  const submitForm = async (data) => {
    const formData = new FormData();
    formData.append("file", data?.file[0]);

    await mutateAsync(formData).then(() => {
      history.push("/employees/onboarding/add-salary-information/?type=bulk");
    });
  };
  return (
    <EmployeeOnboardingLayout step={2} title={"Add Employee Details"}>
      <div className="flex flex-col w-full">
        <span className="header-4">
          Add Bulk Employees - Fill in your employee’s details
        </span>
        <hr className="divider mt-2 mb-8" />
        <form className="form " onSubmit={handleSubmit(submitForm)}>
          <>
            <div className="flex flex-col text-center justify-center items-center">
              <span className="text-[16px] font-extrabold mb-2">
                Import employee information from XLSX file
              </span>
              <div className=" flex flex-col border p-4 rounded-md bg-highlight mt-2 mb-3 justify-center items-center w-full">
                <Button
                  text="Download template"
                  type="button"
                  theme="primary"
                  loading={exportLoading}
                  className="flex gap-2 !h-[35px] w-[220px] p-2 "
                  textClass={"!text-[11px]"}
                  onClick={() => {
                    exportTemplate();
                  }}
                />
              </div>

              <FormFileInput
                multiSource={false}
                name="file"
                inputRef={register(formValidation("file", true))}
                accept=".xlsx"
              />
            </div>
            <div className="w-full mt-3">
              <Button
                text="Add and Continue"
                type="submit"
                loading={isLoading}
              />
            </div>
            <div
              className="flex justify-center w-full cursor-pointer hover:underline hover:scale-110 duration-300 mt-2"
              onClick={() => history.goBack()}
            >
              <span className="p2-medium text-color-black mt-3">Go Back</span>
            </div>
          </>
        </form>
      </div>
    </EmployeeOnboardingLayout>
  );
}

export default CreateBulkEmployee;
