import React, { useEffect, useState } from "react";
import { toggleSidebar } from "../../../redux/components/components-slice";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../../components/layouts/dashboard";
import Button from "../../../components/button";
// import { useHistory } from 'react-router-dom';
import FormSelect from "../../../components/form-select";
import EmployeeSVG from "../../../assets/svg/employee-green.svg";
import EmployeeLeaveSVG from "../../../assets/svg/employee-leave.svg";
import EmployeePresentSVG from "../../../assets/svg/employee-present.svg";
import EmployeeSuspendedSVG from "../../../assets/svg/employee-suspension.svg";
import { useGetHRDashboard } from "../../../redux/dashboard/hook";
import LoadingBar from "../../../components/loader-bar";
import { CardHr } from "../../../components/modal/hr-section/dashboard/cardHR";
import { AttendanceCard } from "../../../components/modal/hr-section/dashboard/attendance-card";
import { HRRequests } from "../../../components/modal/hr-section/dashboard/hr-requests";
import HROverviewChart from "../../../components/modal/hr-section/dashboard/HROverviewChart";
import HRChart from "../../../components/modal/hr-section/dashboard/hr-chart";
import { useHelpMeOnboard } from "../../../redux/company/hook";
import { formatNumber } from "../../../utils/functions";
import DemoVideoModal from "../../../components/modal/demo-videos";

const HRDashboardPage = () => {
  useEffect(() => {}, []);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userSlice);
  const { data: dashboard, isLoading } = useGetHRDashboard();
  const [dashboardCards, setDashboardCards] = useState([]);
  const [donutData, setDonutData] = useState(null);
  const [hrStat, setHrStats] = useState(null);

  const [showOnboardingVideo, setShowOnboardingVideo] = useState(false);

  const [attendanceData, setAttendanceData] = useState();

  const today = useState(new Date().toString().split(" ")[2]);
  const todayDate = useState(new Date().toString().split(" ")[0]);

  useEffect(() => {
    if (dashboard) {
      setAttendanceData({
        title: "Daily Attendance",
        day: today,
        date: todayDate,
        present: {
          name: "PRESENT",
          value: dashboard ? dashboard.employees_present : 0,
        },
        late: {
          name: "LATE",
          value: dashboard ? dashboard.employees_late : 0,
        },
        absent: {
          name: "ABSENT",
          value: dashboard ? dashboard.employees_absent : 0,
        },
      });
      updateHROverview();
      const DashboardCards = [
        {
          title: "EMPLOYEE STRENGTH",
          value: dashboard ? dashboard.employee_strength : 0,
          icon: EmployeeSVG,
        },
        {
          title: "EMPLOYEES PRESENT",
          value: dashboard ? dashboard.employees_present : 0,
          icon: EmployeePresentSVG,
        },
        {
          title: "EMPLOYEES ON LEAVE",
          value: dashboard ? dashboard.employees_on_leave : 0,
          icon: EmployeeLeaveSVG,
        },
        {
          title: "EMPLOYEES SUSPENDED",
          value: dashboard ? dashboard.employees_suspended : 0,
          icon: EmployeeSuspendedSVG,
        },
      ];
      setDashboardCards(DashboardCards);
      updateHRStats();
    } else {
      updateHRStats();
      updateHROverview();
      setAttendanceData({
        title: "Daily Attendance",
        day: today,
        date: todayDate,
        present: {
          name: "PRESENT",
          value: 0,
        },
        late: {
          name: "LATE",
          value: 0,
        },
        absent: {
          name: "ABSENT",
          value: 0,
        },
      });
      const DashboardCards = [
        {
          title: "EMPLOYEE STRENGTH",
          value: 0,
          icon: EmployeeSVG,
        },
        {
          title: "EMPLOYEES PRESENT",
          value: 0,
          icon: EmployeePresentSVG,
        },
        {
          title: "EMPLOYEES ON LEAVE",
          value: 0,
          icon: EmployeeLeaveSVG,
        },
        {
          title: "EMPLOYEE SUSPENDED",
          value: 0,
          icon: EmployeeSuspendedSVG,
        },
      ];
      setDashboardCards(DashboardCards);
    }
  }, [dashboard]);

  useEffect(() => {
    if (window.innerWidth < 851) {
      dispatch(toggleSidebar());
    }
  }, []);

  const [sortOption, setSortOption] = useState("Last 6 months");

  const sortOptions = [{ value: "Last 6 months", label: "Last 6 months" }];

  const updateHROverview = () => {
    let data = {
      data: {
        labels: ["Male", "Female"],
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
        datasets: [
          {
            cutout: "70%",
            label: "# of Votes",
            data: [
              dashboard ? dashboard.male_employees : 0,
              dashboard ? dashboard.female_employees : 0,
            ],
            backgroundColor: ["#001984", "rgba(239, 161, 11, 1)"],
          },
        ],
      },
      legends: [
        {
          title: "Male",
          color: "bg-secondary-2",
        },
        {
          title: "Female",
          color: "bg-[#f79421]",
        },
      ],
      total: parseInt(
        dashboard ? dashboard.male_employees + dashboard.female_employees : 0
      ),
    };

    setDonutData(data);
  };

  const updateHRStats = () => {
    let data = {
      data: {
        labels: ["", "Sep", "Oct", "Nov", "Dec", "Jan", "Mar"],
        datasets: [
          {
            label: "Count",
            data: [],
            fill: true,
            borderWidth: 2,
            backgroundColor: "rgb(236,243,251)",
            borderColor: "rgb(0, 25, 132)",
            tension: 0.5,
            pointRadius: 0.5,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 3,
            pointBorderWidth: 0,
            pointBorderColor: "rgb(0, 25, 132)",
            pointHoverBorderColor: "rgb(239, 160, 11)",
          },
        ],
      },
      options: {
        options: {
          plugins: {
            tooltip: {
              backgroundColor: "rgb(236,243,251)",
              borderColor: "rgb(236,243,251)",
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 500000,
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return formatNumber(parseInt(value), 2);
              },
            },
          },
        },
      },
    };

    dashboard?.lastSixMonthPayroll?.reverse();

    let amounts = [];
    let months = [];

    dashboard?.lastSixMonthPayroll?.forEach((element) => {
      amounts.push(element.amount);
      months.push(element.month[1]);
    });

    data.data.labels = months;
    data.data.datasets[0].data = amounts;

    setHrStats(data);
  };

  const recentActivity = {
    data: [
      // {
      //   created: '10:40 AM, Fri 22 Mar 2022',
      //   title: 'You posted a new recruitment',
      //   description: 'Kindly check the requirements and terms of work and make sure everything is right.'
      // },
      // {
      //   created: '10:40 AM, Sat 22 Mar 2022',
      //   title: 'You onboarded a new employee',
      //   description: 'Kindly check the requirements and terms of work and make sure everything is right.'
      // },
      // {
      //   created: '10:40 AM, Fri 22 Mar 2022',
      //   title: 'You posted a new recruitment',
      //   description: 'Kindly check the requirements and terms of work and make sure everything is right.'
      // }
    ],
  };
  const requests = {
    advance_request: dashboard ? dashboard.requests.advance_requests : 0,
    reimbursement_requests: dashboard
      ? dashboard.requests.reimbursement_requests
      : 0,
    leave_requests: dashboard ? dashboard.requests.leave_requests : 0,
    others: dashboard ? dashboard.requests.others : 0,
  };

  // const onboard = () => {
  //   history.push('/employees/onboarding/select-option')
  // }
  const { mutateAsync: helpMeOnboard, isLoading: onboardLoading } =
    useHelpMeOnboard();

  const onboard = () => {
    helpMeOnboard();
  };

  return (
    <DashboardLayout title="People Dashboard">
      {showOnboardingVideo ? (
        <DemoVideoModal
          isOpen={showOnboardingVideo}
          closeModal={() => setShowOnboardingVideo(false)}
        />
      ) : null}
      <div className="flex flex-col h-full">
        <div className="flex flex-col md:flex-row justify-between items-center gap-3 -mt-2">
          <div className="flex flex-col">
            <span className="header-3"> Welcome {user?.first_name},</span>
            <span className="text-[13px] text-gray-500">
              Quick Guide? &nbsp;
              <span
                onClick={() => onboard()}
                className="text-[12px] mt-2 text-secondary-2 font-semibold hover:font-extrabold duration-300 cursor-pointer hover:scale-105"
              >
                Click Here
              </span>
            </span>
          </div>
          <div className="w-full md:w-fit md:mt-0">
            <Button
              text="QUICK GUIDE VIDEOS"
              type="button"
              className={"px-2 gap-2 h-[42px]"}
              leftIcon={"view"}
              textClass={"text-[13px] font-extrabold"}
              // loading={onboardLoading}
              // disabled={onboardLoading}
              onClick={() => {
                setShowOnboardingVideo(true);
              }}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[15px]">
            <LoadingBar loading={isLoading} />
          </div>
        ) : (
          <>
            <div className="flex md:flex-row flex-col gap-4 mt-4">
              <div className="md:w-[40%] flex flex-col">
                <div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-2 gap-3 w-full">
                  {dashboardCards?.map((item) => (
                    <CardHr
                      title={item.title}
                      subtitle={item.value}
                      icon={item.icon}
                    />
                  ))}
                </div>
                <div className="w-[100%] h-fit mt-3">
                  {attendanceData ? (
                    <AttendanceCard attendanceData={attendanceData} />
                  ) : null}
                </div>
                {/* <div className='w-[100%] h-fit mt-3'>
                <RecentActivity recentActivity={recentActivity} />
              </div> */}
              </div>
              <div className="md:w-[60%] bg-color-white flex flex-col py-[15px] overflow-auto border rounded-md">
                <div className="flex flex-col justify-between">
                  {/* HR Stat */}
                  <div className="w-[100%] flex flex-col mb-2">
                    <div className="flex flex-row justify-between items-center px-4 pt-2">
                      <span className="p2">Performance Stat</span>
                      <div className="hidden md:flex flex-row items-center gap-2">
                        <div className="bg-hr-primary-1 w-[10px] h-[10px] rounded-[10px] pr-2"></div>
                        <span className="p4">People Management</span>
                      </div>
                      <div className="flex items-center h-[27px]">
                        <FormSelect
                          defaultValue={sortOption}
                          options={sortOptions}
                          onChange={(selected) => {
                            setSortOption(selected);
                          }}
                          type="default"
                          extraClass="h-[27px] w-[140px] !text-[12px]"
                        />
                      </div>
                    </div>
                    <hr className="divider" />
                    <div className="h-full w-[100%] px-4">
                      {hrStat ? <HRChart data={hrStat} /> : null}
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col items-center justify-center gap-4 p-4">
                    <div className="w-[100%] h-fit bg-color-white">
                      <HRRequests requests={requests} />
                    </div>
                    <div className="w-[100%] h-fit bg-color-white mt-4 rounded-md">
                      <HROverviewChart data={donutData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default HRDashboardPage;
