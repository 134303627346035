import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormFileInput from '../../form-file-input';
import CloseSVG from '../../../assets/svg/close.svg';
import FormInput from '../../form-input';
import Modal from '../modal';
import { useAddCompanyDocument } from '../../../redux/document/hook/company';

function UploadDocument({ isOpen, closeModal, refresh }) {
  
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync: uploadFile, isLoading } = useAddCompanyDocument();

  const [fileName, setFileName] = useState()

  async function submitForm(data) {

    const formData = new FormData();
    formData.append('file', data?.file[0]);
    formData.append('name', fileName);

    await uploadFile(formData).then(() => {
      closeModal();
    });
  }

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <form className='form overflow-auto' onSubmit={handleSubmit(submitForm)}>
        <div className='flex flex-col'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Upload Document</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='p-8 pt-1 flex flex-col px-8'>
          <div className='w-full '>
            <FormInput
              label='File Name'
              name='file_name'
              type='text'
              placeholder={'Enter file name'}
              onInput={(e) => {
                setFileName(e.target.value)
              }}
              error={errors.name}
              errorMessage={errors.name && errors.name.message}
            />
          </div>
          <div className='flex flex-col text-center justify-center items-center mt-4'>
            <span className='text-[13px] -mb-1 text-color-gray'>Select Document (Max. 3mb)</span>
            <FormFileInput
              multiSource={false}
              name={'file'}
              inputRef={register(formValidation('file', true))}
              accept='.png,.jpg,.jpeg,.pdf,.docx,.xlsx'
            />
          </div>

          <div className='w-full mt-[20px]'>
            <Button text='UPLOAD' type='submit' loading={isLoading} />
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default UploadDocument;
