import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from "reactstrap";
import classnames from "classnames";
import DropDownSVG from "../assets/svg/chevron-down.svg";
import { useSelector } from "react-redux";

const TabView = ({ Tabs, activeTab, setActiveTab }) => {
  // const [activeTab, setActiveTab] = useState(1);
  const [collapse, setCollapse] = useState(false);
  const { section } = useSelector((state) => state.sectionSlice);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    if (activeTab > Tabs.length) {
      setActiveTab(1);
    }
  }, []);

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  function switchTab(index) {
    setActiveTab(index);
    toggleCollapse();
  }

  return (
    <>
      {/* Desktop Tab */}
      <div className="hidden md:flex tab-view-container select-none">
        <Nav
          tabs
          className="tab-view !rounded !flex-nowrap w-full p-0 overflow-y-hidden"
        >
          {activeTab < Tabs.length + 1 &&
            Tabs.map((item, index) => (
              <NavItem
                className="flex flex-row justify-center items-center !max-w-[200px] !h-[100%] p-0"
                key={index}
              >
                <NavLink
                  className={classnames(
                    "flex flex-col justify-start items-start tab-view-item !w-full !rounded-none  p-0",
                    {
                      "duration-300 border-b-2": activeTab === index + 1,
                    }
                  )}
                  onClick={() => {
                    toggle(index + 1);
                  }}
                >
                  <div
                    className={classnames(
                      "flex flex-row justify-center items-start tab-view-item !rounded-none min-w-[115px] min-h-[100%]",
                      {
                        "text-secondary-2 text-[13px] duration-300 border-b-[4px] pb-[14px] pt-[18px] border-secondary-2 cursor-default font-extrabold":
                          activeTab === index + 1 && section.name === "payroll",
                        "text-[#0d5225] text-[13px] duration-300 border-b-[4px] pb-[14px] pt-[18px] border-[#0d5225] cursor-default font-extrabold":
                          activeTab === index + 1 && section.name === "core hr",
                        "text-spend-primary-1 text-[13px] duration-300 border-b-[4px] pb-[14px] pt-[18px] border-spend-primary-1 cursor-default font-extrabold":
                          activeTab === index + 1 &&
                          section.name === "spend management",
                        "text-[12px] text-color-gray hover:text-gray-500 duration-300 pb-[14px] pt-[18px] hover:border-b-[4px] hover:text-[14px] hover:border-gray-300":
                          activeTab !== index + 1,
                      }
                    )}
                  >
                    {item.title}
                  </div>
                </NavLink>
              </NavItem>
            ))}
        </Nav>
      </div>

      <div className="flex md:hidden flex-col bg-white  rounded">
        <div
          onClick={toggleCollapse}
          className="h-[60px] flex justify-center w-full cursor-pointer px-4 bg-secondary-2-light border"
        >
          <button className="flex justify-between flex-row items-center w-full ">
            <span
              className={classnames(" text-[15px] duration-300 font-semibold", {
                "text-secondary-2": section.name === "payroll",
                "text-[#0d5225]": section.name === "core hr",
                "text-spend-primary-1": section.name === "spend managment",
              })}
            >
              {Tabs[activeTab - 1]?.title}
            </span>
            <img src={DropDownSVG} alt="" width={"12px"} />
          </button>
        </div>

        <Collapse isOpen={collapse}>
          <div className="flex flex-col">
            {activeTab < Tabs.length + 1 &&
              Tabs.map((tab, index) => (
                <React.Fragment key={index}>
                  {index + 1 !== activeTab ? (
                    <div
                      onClick={() => switchTab(index + 1)}
                      className="w-full pl-6 hover:bg-secondary-2-extralight p4-bold text-color-gray cursor-pointer font-normal hover:font-semibold duration-300 border-t"
                    >
                      <button
                        className={classnames(
                          "text-[15px] duration-100 h-[60px] text-start",
                          {
                            "text-secondary-2": section.name === "payroll",
                            "text-[#0d5225]": section.name === "core hr",
                            "text-spend-primary-1":
                              section.name === "spend managment",
                          }
                        )}
                        key={index}
                      >
                        {tab.title}
                      </button>
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
          </div>
        </Collapse>
      </div>

      <TabContent activeTab={activeTab} className="mt-[25px]">
        {activeTab < Tabs.length + 1 &&
          Tabs.map(({ title, Content }, index) => (
            <TabPane tabId={index + 1} key={index}>
              <>
                {activeTab - 1 === index ? (
                  <>{Content ? <Content /> : null}</>
                ) : null}
              </>
            </TabPane>
          ))}
      </TabContent>
    </>
  );
};

export default TabView;
