import React, { useEffect, lazt, Suspense } from 'react';
import { useState } from 'react';
import DashboardLayout from '../../../components/layouts/dashboard';
import RunAdvancedPayroll from '../../../components/modal/payroll/run-advanced-payroll';
import { AdvancedPayrollTab } from '../../../components/payroll-section/payroll/advanced-payroll-tab';
import { BasicPayrollTab } from '../../../components/payroll-section/payroll/basic-payroll-tab';
import PayrollHistoryTab from '../../../components/payroll-section/payroll/payroll-history-tab';
import TabView from '../../../components/tabview';
import UnauthorizedPage from '../../errors/unauthorized';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RunBasicPayroll from '../../../components/modal/payroll/run-basic-payroll';
import LoadingBar from '../../../components/loader-bar';

const PayrollPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== 'Owner') {
        if (user.permissions.company_permission.payroll_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);
  const [showBasicPayroll, setShowBasicPayroll] = useState(false);
  const [showAdvancedPayroll, setShowAdvancedPayroll] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location && location.state) {
      setActiveTab(location.state.tab);
    }
  }, [location]);

  const PayrollTabs = [
    {
      title: 'Payroll Summary',
      Content:
        user?.permissions.payroll_permission?.advanced_payroll_read === 1 ||
        user?.permissions.company_permission.role === 'Owner'
          ? AdvancedPayrollTab
          : UnauthorizedPage,
    },
    {
      title: 'Employee Payment Summary',
      Content:
        user?.permissions.payroll_permission?.basic_payroll_read === 1 ||
        user?.permissions.company_permission.role === 'Owner'
          ? BasicPayrollTab
          : UnauthorizedPage,
    },
    {
      title: 'Transaction History',
      Content:
        user?.permissions.payroll_permission?.history_read === 1 ||
        user?.permissions.company_permission.role === 'Owner'
          ? PayrollHistoryTab
          : UnauthorizedPage,
    },
  ];

  return (
    <DashboardLayout title='Payroll Management'>
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className='flex flex-col'>
          {showAdvancedPayroll?
          <RunAdvancedPayroll
            isOpen={showAdvancedPayroll}
            source='default'
            closeModal={() => setShowAdvancedPayroll(false)}
          />
          : null}
          {showBasicPayroll?
          <RunBasicPayroll
            isOpen={showBasicPayroll}
            source='default'
            closeModal={() => setShowBasicPayroll(false)}
          />
          :null}
          <div className='-mt-1'>
            <TabView
              Tabs={PayrollTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default PayrollPage;
