import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { updateUser } from '../user/hook';
import { useMutation } from '@tanstack/react-query';
import { integrations } from '../../api/integrations';
import { updateCurrentCompany } from '../company/hook';

export function useIntegrateTyms() {
  return useMutation(
    () => {
      return integrations.integrateTyms();
    },
    {
      onSuccess: () => {
        store.dispatch(setAlert(true, 'success', 'Integration Commenced'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGenerateTymsIntegrationToken() {
  return useMutation(
    (payload) => {
      return integrations.generateAccessToken(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        updateCurrentCompany();
        updateUser({ reload: false });
        store.dispatch(setAlert(true, 'success', 'Tyms integration complete.'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
