import React, { useState } from "react";
import DashboardLayout from "../../components/layouts/dashboard";
import classNames from "classnames";
import EmployeeReport from "../../components/reports/payroll-reports/employee-report";
import CollapsingIcon from "../../components/collapsing-icon";
import { Collapse } from "reactstrap";
import BonusReport from "../../components/reports/payroll-reports/bonus-report";
import SaladReport from "../../components/reports/payroll-reports/salary-advance-report";
import OtherDeductionReport from "../../components/reports/payroll-reports/other-deductions-report";
import OvertimeReport from "../../components/reports/payroll-reports/overtime-report";
import ReimbursementReport from "../../components/reports/payroll-reports/reimbursement-report";

const PayrollReports = () => {
  const [source, setSource] = useState("employee");

  const [showReportList, setShowReportList] = useState(false);

  return (
    <DashboardLayout title="Payroll Reports">
      <div className="flex flex-col -mt-2">
        <div className="flex flex-col bg-white h-fit rounded-md">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
            <div className="p3-bold header-4">Reports</div>
          </div>
          <hr className="divider mt-2 mb-0" />
          <div className="flex md:flex-row flex-col">
            <div className="hidden md:flex flex-col md:w-1/4 w-full border-r">
              <div
                className={classNames(
                  "flex items-center px-3 min-h-[65px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                  {
                    "text-[15px] font-semibold bg-highlight cursor-default":
                      source == "employee",
                  }
                )}
                onClick={() => setSource("employee")}
              >
                Employee Reports
              </div>
              <div
                className={classNames(
                  "flex items-center px-3 min-h-[65px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                  {
                    "text-[15px] font-semibold bg-highlight cursor-default":
                      source == "bonus",
                  }
                )}
                onClick={() => setSource("bonus")}
              >
                Bonus Reports
              </div>
              <div
                className={classNames(
                  "flex items-center px-3 min-h-[65px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                  {
                    "text-[15px] font-semibold bg-highlight cursor-default":
                      source == "salary_advance",
                  }
                )}
                onClick={() => setSource("salary_advance")}
              >
                Salary Advance Reports
              </div>
              <div
                className={classNames(
                  "flex items-center px-3 min-h-[65px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                  {
                    "text-[15px] font-semibold bg-highlight cursor-default":
                      source == "other_deduction",
                  }
                )}
                onClick={() => setSource("other_deduction")}
              >
                Other Deduction Reports
              </div>
              <div
                className={classNames(
                  "flex items-center px-3 min-h-[65px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                  {
                    "text-[15px] font-semibold bg-highlight cursor-default":
                      source == "overtime",
                  }
                )}
                onClick={() => setSource("overtime")}
              >
                Overtime Reports
              </div>
              <div
                className={classNames(
                  "flex items-center px-3 min-h-[65px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                  {
                    "text-[15px] font-semibold bg-highlight cursor-default":
                      source == "reimbursement",
                  }
                )}
                onClick={() => setSource("reimbursement")}
              >
                Reimbursement Reports
              </div>
            </div>
            <div className="md:hidden flex flex-col md:w-1/4 w-full border-r">
              <div
                className={classNames(
                  "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300 px-3 border-b select-none font-semibold",
                  {
                    "pt-3 pb-3 bg-highlight rounded-md mb-2 px-2":
                      showReportList,
                  }
                )}
                onClick={() => setShowReportList(!showReportList)}
              >
                <span className="text-[14px]">Report List (Select Report)</span>
                <div className="pr-4">
                  <CollapsingIcon
                    defaultPosition="left"
                    isOpen={showReportList}
                  />
                </div>
              </div>
              <div
                className={classNames("flex flex-col", {
                  hidden: !showReportList,
                })}
              >
                <Collapse isOpen={showReportList}>
                  <div
                    className={classNames(
                      "flex items-center px-3 min-h-[56px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                      {
                        "text-[15px] font-semibold bg-highlight cursor-default":
                          source == "employee",
                      }
                    )}
                    onClick={() => setSource("employee")}
                  >
                    Employee Reports
                  </div>
                  <div
                    className={classNames(
                      "flex items-center px-3 min-h-[56px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                      { "text-[15px] font-semibold": source == "compensation" }
                    )}
                    onClick={() => setSource("compensation")}
                  >
                    Compensation Reports
                  </div>
                  <div
                    className={classNames(
                      "flex items-center px-3 min-h-[56px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                      { "text-[15px] font-semibold": source == "payroll" }
                    )}
                    onClick={() => setSource("payroll")}
                  >
                    Payroll Reports
                  </div>
                  <div
                    className={classNames(
                      "flex items-center px-3 min-h-[56px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                      {
                        "text-[15px] font-semibold":
                          source == "statutory_compliance",
                      }
                    )}
                    onClick={() => setSource("statutory_compliance")}
                  >
                    Statutory Compliance Reports
                  </div>
                  <div
                    className={classNames(
                      "flex items-center px-3 min-h-[56px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                      { "text-[15px] font-semibold": source == "bonus" }
                    )}
                    onClick={() => setSource("bonus")}
                  >
                    Bonus Reports
                  </div>
                  <div
                    className={classNames(
                      "flex items-center px-3 min-h-[56px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                      {
                        "text-[15px] font-semibold": source == "salary_advance",
                      }
                    )}
                    onClick={() => setSource("salary_advance")}
                  >
                    Salary Advance Reports
                  </div>
                  <div
                    className={classNames(
                      "flex items-center px-3 min-h-[56px] border-b duration-300 hover:bg-highlight cursor-pointer text-[14px] hover:text-[15px] hover:font-semibold",
                      {
                        "text-[15px] font-semibold":
                          source == "other_deduction",
                      }
                    )}
                    onClick={() => setSource("other_deduction")}
                  >
                    Other Deduction Reports
                  </div>
                </Collapse>
              </div>
            </div>
            {source == "employee" ? (
              <div className="flex flex-col md:w-3/4 w-full">
                <EmployeeReport />
              </div>
            ) : source == "bonus" ? (
              <div className="flex flex-col md:w-3/4 w-full">
                <BonusReport />
              </div>
            ) : source == "salary_advance" ? (
              <div className="flex flex-col md:w-3/4 w-full">
                <SaladReport />
              </div>
            ) : source == "other_deduction" ? (
              <div className="flex flex-col md:w-3/4 w-full">
                <OtherDeductionReport />
              </div>
            ) : source == "overtime" ? (
              <div className="flex flex-col md:w-3/4 w-full">
                <OvertimeReport />
              </div>
            ) : source == "reimbursement" ? (
              <div className="flex flex-col md:w-3/4 w-full">
                <ReimbursementReport />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PayrollReports;
