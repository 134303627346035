import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import LoadingBar from "../../../components/loader-bar";
import EmptyTableSVG from "../../../assets/svg/empty-table.svg";
import {
  useExportAttendanceHistory,
  useGetAttendanceWorkSchedules,
} from "../../../redux/human-resources/hook/attendance";
import FormSelectEmployee from "../../employees/form-select-employee";
import AddAttendance from "../../modal/hr-section/attendance/add-attendance";
import ViewAttendance from "../../modal/hr-section/attendance/view-attendance";
import SelectScheduleOption from "../../modal/hr-section/attendance/schedule-select-option";
import { setAlert } from "../../../redux/components/components-slice";
import { store } from "../../../redux";
import ViewSchedule from "../../modal/hr-section/attendance/view-schedule";
import SelectAttendanceOption from "../../modal/hr-section/attendance/select-attendance-option";
import { useGetEmployees } from "../../../redux/employees/hook";
import Excel from "exceljs";
import { saveAs } from "file-saver";

const localizer = momentLocalizer(moment);

// Custom styles
const eventStyleGetter = (event, start, end, isSelected) => {
  const backgroundColor = event.color;
  const style = {
    backgroundColor: backgroundColor,
    borderRadius: "8px",
    opacity: 0.8,
    color: "white",
    border: "0px",
    display: "block",
    padding: "2px 5px",
    fontSize: "13px",
    fontWeight: "bolder",
    fontFamily: "sans-serif",
  };
  return {
    style: style,
  };
};

const calendarStyle = {
  height: 500,
  fontFamily: "sans-serif",
  color: "#333",
  fontSize: "15px",
  borderRadius: 8,
};

const HRAttendanceOverviewTab = () => {
  useEffect(() => {}, []);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [showSelectAttendanceOptions, setShowSelectAttendanceOptions] =
    useState(false);

  const firstdate = moment().startOf("month").format("YYYY-MM-DD");
  const lastdate = moment().endOf("month").format("YYYY-MM-DD");
  const [range, setRange] = useState({
    start: firstdate,
    end: lastdate,
  });

  const [employee, setEmployee] = useState(null);
  const [employeeName, setEmployeeName] = useState();

  const [showAddAttendance, setShowAddAttendance] = useState(false);
  const [attendanceData, setAttendanceData] = useState();
  const [showViewAttendance, setShowViewAttendance] = useState(false);

  const [showViewSchedule, setShowViewSchedule] = useState(false);
  const [scheduleData, setScheduleData] = useState();

  const [title, setTitle] = useState("Add Attendance");
  const [buttonText, setButtonText] = useState("SAVE");

  const [showAttendanceOptions, setShowAttendanceOptions] = useState(false);

  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const { data: attendance_schedule, isLoading: attendanceScheduleLoading } =
    useGetAttendanceWorkSchedules(employee, startDate, endDate);
  const [view, setView] = useState("month");

  const [attendanceInfo, setAttendanceInfo] = useState();

  const onSelectEvent = (calEvent) => {
    if (calEvent?.type == "attendance") {
      setAttendanceData(calEvent);
      setShowViewAttendance(true);
    } else if (calEvent?.type == "schedule") {
      setScheduleData(calEvent);
      setShowViewSchedule(true);
    } else {
      store.dispatch(
        setAlert(true, "success", "This is your off day. No data to show")
      );
    }
  };

  useEffect(() => {
    if (attendance_schedule) {
      setAttendanceInfo(attendance_schedule);
    }
  }, [attendance_schedule]);

  const onRangeChange = (range) => {
    if (range.start) {
      setStartDate(moment(range.start).format("YYYY-MM-DD"));
      setEndDate(moment(range.end).format("YYYY-MM-DD"));
      setView("month");
    } else if (range.length > 1) {
      setStartDate(moment(range[0]).format("YYYY-MM-DD"));
      setEndDate(moment(range[range.length - 1]).format("YYYY-MM-DD"));
      setView("week");
    } else if (range.length == 1) {
      setStartDate(moment(range[0]).format("YYYY-MM-DD"));
      setEndDate(moment(range[0]).format("YYYY-MM-DD"));
      setView("day");
    }
  };

  const handleNavigate = (newDate, view, action) => {
    const newRange = {
      start: moment(newDate).startOf(view),
      end: moment(newDate).endOf(view),
    };
    setRange(newRange);
  };

  let columns = [
    { header: "Date", key: "formatted_attendance_date" },
    { header: "First Name", key: "first_name" },
    { header: "Last Name", key: "last_name" },
    { header: "Mobile", key: "mobile" },
    { header: "Location", key: "location" },
    { header: "Expected Clockin", key: "expected_clockin" },
    { header: "Expected Clockout", key: "expected_clockout" },
    { header: "Clockin Location", key: "clockin_location" },
    { header: "Clockin Date", key: "clockin_date" },
    { header: "Clockin Time", key: "clockin_time" },
    { header: "Clockout Location", key: "clockout_location" },
    { header: "Clockout Date", key: "clockout_date" },
    { header: "Clockout Time", key: "clockout_time" },
    { header: "Punctuality Status", key: "punctuality_status" },
    { header: "Status", key: "status" },
  ];

  const workbook = new Excel.Workbook();

  const [exportLoading, setSetExportLoading] = useState(false);

  const { mutateAsync: exportAttencanceHistory } = useExportAttendanceHistory();

  const exportAttendance = async () => {
    setSetExportLoading(true);
    let payload = {
      employee_id: employee,
      limit: 100,
      page: 1,
      start_date: startDate,
      end_date: endDate,
    };

    await exportAttencanceHistory(payload).then(async (response) => {
      if (response) {
        let data = response?.data;
        const workSheetName = "attendance-history";
        const workBookName = `Attendance`;

        try {
          const fileName = workBookName;

          const worksheet = workbook.addWorksheet(workSheetName);

          // add worksheet columns
          // each columns contains header and its mapping key from data
          worksheet.columns = columns;

          // updated the font for first row.
          worksheet.getRow(1).font = { bold: true };

          // loop through all of the columns and set the alignment with width.
          worksheet.columns.forEach((column) => {
            column.width = column.header.length + 2;
            column.style.alignment = { horizontal: "left" };
          });

          // loop through data and add each one to worksheet
          data?.forEach((singleData) => {
            singleData.formatted_attendance_date = moment(
              singleData.attendance_date
            ).format("MMMM DD, YYYY");

            if (singleData.status === 0) {
              singleData.status = "Absent";
            } else if (singleData.status === 1) {
              singleData.status = "Present";
            }
            if (singleData.punctuality_status === 0) {
              singleData.punctuality_status = "Absent";
            } else if (singleData.punctuality_status === 1) {
              singleData.punctuality_status = "On Time";
            } else if (singleData.punctuality_status === 2) {
              singleData.punctuality_status = "Early";
            } else if (singleData.punctuality_status === 3) {
              singleData.punctuality_status = "Late";
            }
            worksheet.addRow(singleData);
          });

          // loop through all of the rows and set the outline style.
          worksheet.eachRow({ includeEmpty: false }, (row) => {
            // store each cell to currentCell
            const currentCell = row._cells;
          });

          // write the content using writeBuffer
          const buf = await workbook.xlsx.writeBuffer();

          // download the processed file
          saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
          // console.error('<<<ERRROR>>>', error);
          // console.error('Something Went Wrong', error.message);
        } finally {
          workbook.removeWorksheet(workSheetName);
        }
      } else {
        store.dispatch(
          setAlert(true, "info", "No attendance fetched for export")
        );
      }
    });
    store.dispatch(setAlert(true, "info", "Attendance exported successfully"));
    setSetExportLoading(false);
  };

  return (
    <div className="flex flex-col">
      {showViewSchedule ? (
        <ViewSchedule
          isOpen={showViewSchedule}
          scheduleData={scheduleData}
          buttonText={buttonText}
          title={title}
          closeModal={() => setShowViewSchedule(false)}
        />
      ) : null}
      {showAddAttendance ? (
        <AddAttendance
          isOpen={showAddAttendance}
          attendanceData={attendanceData}
          buttonText={buttonText}
          title={title}
          closeModal={() => setShowAddAttendance(false)}
        />
      ) : null}
      {showSelectAttendanceOptions ? (
        <SelectAttendanceOption
          isOpen={showSelectAttendanceOptions}
          attendanceData={attendanceData}
          buttonText={buttonText}
          title={title}
          closeModal={() => setShowSelectAttendanceOptions(false)}
        />
      ) : null}
      {showViewAttendance ? (
        <ViewAttendance
          isOpen={showViewAttendance}
          attendanceData={attendanceData}
          buttonText={buttonText}
          title={title}
          closeModal={() => setShowViewAttendance(false)}
        />
      ) : null}
      {showAttendanceOptions ? (
        <SelectScheduleOption
          isOpen={showAttendanceOptions}
          closeModal={() => setShowAttendanceOptions(false)}
        />
      ) : null}
      <div className="flex flex-col bg-white mt-[10px] rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Attendance Summary
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Attendance"
                type="submit"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                disabled={!employees}
                onClick={() => setShowSelectAttendanceOptions(true)}
              />
            </div>
            {attendance_schedule ? (
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Export"
                  type="submit"
                  theme="primary"
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[11px]"}
                  leftIcon={"export"}
                  loading={exportLoading}
                  onClick={() => {
                    exportAttendance();
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
        <hr className="divider mt-2 mb-2" />
        {employees ? (
          <>
            <div className="p-2 border bg-highlight rounded-md mx-3">
              <div className="md:w-1/2 w-full pt-1">
                <FormSelectEmployee
                  label={"Select Employee to display attendance information"}
                  name="employee"
                  placeholder="Type to select employee"
                  value={employee}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
            </div>
            <hr className="divider mt-2 mb-2" />
            <div className="p-4 pt-2">
              {attendanceScheduleLoading && employee !== null ? (
                <LoadingBar loading={attendanceScheduleLoading} />
              ) : attendanceInfo ? (
                <Calendar
                  localizer={localizer}
                  style={calendarStyle}
                  events={attendanceInfo}
                  onRangeChange={onRangeChange}
                  startAccessor="start"
                  onNavigate={handleNavigate}
                  onSelectEvent={onSelectEvent}
                  endAccessor="end"
                  defaultDate={range.start}
                  view={view}
                  views={["month", "week", "day"]}
                  eventPropGetter={eventStyleGetter}
                />
              ) : (
                <div className="flex items-center justify-center min-w-full mt-4">
                  <img
                    src={EmptyTableSVG}
                    alt="No Data Fetched"
                    width={"40%"}
                    height={"400px"}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center font-extrabold h-20">
            No Employee Added Yet
          </div>
        )}
      </div>
    </div>
  );
};

export default HRAttendanceOverviewTab;
