import React, { useEffect, useState } from "react";
import CloseSVG from "../../../assets/svg/close.svg";
import Button from "../../button";
import { useSelector } from "react-redux";
import Modal from "../modal";
import {
  useCheckBVNVerification,
  useGenerateWalletDetails,
  useVerifyBVN,
} from "../../../redux/wallet/hook";
import FormSelect from "../../form-select";
import LoadingBar from "../../loader-bar";
import FormInput from "../../form-input";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../utils/functions";

export function GenerateAccountDetailsModal({ isOpen, closeModal }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { company } = useSelector((state) => state.companySlice);
  const { user } = useSelector((state) => state.userSlice);
  const [verified, setVerified] = useState(false);
  const [country, setCountry] = useState("Nigeria");
  const [bvn, setBvn] = useState("");
  const [dob, setDob] = useState("");

  const [rc_number, setRcNumber] = useState("");
  const countryOptions = [
    {
      label: "Nigeria",
      value: "Nigeria",
    },
  ];

  const { mutateAsync: checkBVNVerification, isLoading: loading } =
    useCheckBVNVerification();
  const { mutateAsync: verifyBVN, isLoading: bvnLoading } = useVerifyBVN();
  const {
    mutateAsync: generateWalletDetails,
    isLoading: walletDetailsGenerationLoading,
  } = useGenerateWalletDetails();

  const isBVNVerified = async () => {
    await checkBVNVerification().then((response) => {
      if (response == 1) {
        setVerified(true);
      } else {
        setVerified(false);
      }
    });
  };

  useEffect(() => {
    // if (company && company.wallet_id) {
    //   closeModal();
    // }
    if (company) {
      setRcNumber(company.reg_no);
    }
    isBVNVerified();
  }, [company]);

  const submitBVNVerification = async () => {
    let payload = {
      country,
      bvn: bvn,
      verify_number: bvn,
      dob,
    };
    await verifyBVN(payload).then((response) => {
      setVerified(true);
    });
  };

  const submitGenerateWalletDetails = async () => {
    await generateWalletDetails({ rc_number: rc_number }).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className="!max-w-[500px] selection: h-full !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col min-h-fit overflow-auto">
        <div className="pl-8 pr-8 pt-4">
          <div className="flex flex-row justify-between items-center">
            <span className="header-4">
              {verified ? "Generate Account Details" : "Verify Your Account"}
            </span>
            <div className="cursor-pointer" onClick={() => closeModal()}>
              <img src={CloseSVG} alt="close" />
            </div>
          </div>
        </div>
        <hr className="divider" />
        {loading ? (
          <LoadingBar loading={loading} />
        ) : (
          <div className="flex flex-col px-4">
            <div className="flex flex-col bg-secondary-2-extralight py-4">
              <div className="flex flex-col items-center">
                <span className="header-3 font-medium">
                  {company?.company_name}
                </span>
                <span className="header-5 font-medium">
                  ({user?.first_name} {user?.last_name})
                </span>
              </div>
            </div>
            {verified ? (
              <div className="flex flex-col">
                <div className="flex flex-col items-center my-3">
                  <span className="stat-card-header text-center">
                    {"Generate your company wallet details"}
                  </span>
                </div>
                <div className="w-full">
                  <FormInput
                    label="Company Registration Number (To be verified)"
                    name="rc_number"
                    type="text"
                    onInput={(e) => {
                      setRcNumber(e.target.value);
                    }}
                    value={rc_number}
                    placeholder={"Enter company Registration Number"}
                    inputRef={register(formValidation("text", true))}
                    readOnly={walletDetailsGenerationLoading}
                    error={errors.rc_number}
                    errorMessage={errors.rc_number && errors.rc_number.message}
                  />
                </div>
                <div className="w-full mb-[20px] mt-2">
                  <Button
                    text={"GENERATE AND CLOSE"}
                    type="button"
                    loading={walletDetailsGenerationLoading}
                    onClick={() => submitGenerateWalletDetails()}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col">
                <div className="flex flex-col items-center my-3">
                  <span className="stat-card-header text-center">
                    {
                      "Quickly verify your account before we generate your wallet information"
                    }
                  </span>
                </div>
                <FormSelect
                  value={country}
                  options={countryOptions}
                  onChange={(selected) => {
                    setCountry(selected);
                  }}
                  label={"Select Country"}
                  type="default"
                  extraClass={"font-bold !h-[42px] !text-[14px] !border-none"}
                />
                <div className="flex flex-col md:flex-row justify-between">
                  <div className="w-full md:w-1/2 md:pr-2">
                    <FormInput
                      label="BVN Number"
                      name="bvn"
                      type="text"
                      onInput={(e) => {
                        setBvn(e.target.value);
                      }}
                      placeholder={"Please enter your BVN"}
                      inputRef={register(formValidation("text", true))}
                      readOnly={bvnLoading}
                      error={errors.bvn}
                      errorMessage={errors.bvn && errors.bvn.message}
                    />
                  </div>
                  <div className="w-full md:w-1/2 md:pl-2">
                    <FormInput
                      label="Date Of Birth"
                      name="dob"
                      type="date"
                      onInput={(e) => {
                        setDob(e.target.value);
                      }}
                      inputRef={register(formValidation("date", true))}
                      readOnly={bvnLoading}
                      error={errors.dob}
                      errorMessage={errors.dob && errors.dob.message}
                    />
                  </div>
                </div>
                <div className="w-full mb-[20px] mt-2">
                  <Button
                    text={"VERIFY AND CONTINUE"}
                    type="button"
                    loading={bvnLoading || walletDetailsGenerationLoading}
                    onClick={() => submitBVNVerification()}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
