import React, { useEffect, useState } from "react";
import CloseSVG from "../../../../assets/svg/close.svg";
import Button from "../../../button";
import FormInput from "../../../form-input";
import {
  useAddPerformanceBalancedScorecard,
  useEditPerformanceBalancedScorecard,
} from "../../../../redux/human-resources/hook/performance";
import FormSelectBranch from "../../../employees/form-select-branch";
import FormSelectDepartment from "../../../employees/form-select-department";
import FormSelectEmployee from "../../../employees/form-select-employee";
import FormSelectTeam from "../../../employees/form-select-team";
import Modal from "../../modal";
import FormSelect from "../../../form-select";
import CustomerGrowthSVG from "../../../../assets/svg/employee-growth.svg";
import FInancialGrowthSVG from "../../../../assets/svg/financial-growth.svg";
import BusinessProcessesSVG from "../../../../assets/svg/business-processes.svg";
import LeaningGrowthSVG from "../../../../assets/svg/learning-growth.svg";
// import FormSwitch from '../../../form-switch';
import moment from "moment";

function AddPerformanceBalancedScorecard({
  isOpen,
  closeModal,
  balanceScorecardData,
  title,
  buttonText,
  type = "edit",
}) {
  const [source, setSource] = useState("employee");
  const [sourceId, setSourceId] = useState(null);
  const [sourceName, setSourceName] = useState(null);

  const sourceOptions = [
    { label: "Employee", value: "employee" },
    { label: "Team", value: "team" },
    { label: "Department", value: "department" },
    { label: "Branch", value: "branch" },
  ];

  const [balanceScorecardId, setBalanceScorecardId] = useState(null);
  const [totalWeight, setTotalWeight] = useState(100);
  const [formData, setFormData] = useState({
    title: "",
    source: "",
    source_id: null,
    source_name: "",
    finance_weight: 25,
    customer_weight: 25,
    business_process_weight: 25,
    learning_growth_weight: 25,
    evaluation_frequency: "monthly",
    auto_evaluate: false,
    date_type: "day",
    day: "last",
    interval_count: 1,
    auto_evaluation_start_date: moment().format("YYYY-MM-DD"),
    end_type: "none",
    occurrences: 100,
    auto_evaluation_end_date: moment().add(1, "days").format("YYYY-MM-DD"),
    quadrants: {
      finance: {
        data: [
          {
            id: null,
            balance_scorecard_id: null,
            quadrant: "finance",
            title: "",
            weight: 100,
          },
        ],
        total_weight: 100,
      },
      customer: {
        data: [
          {
            id: null,
            balance_scorecard_id: null,
            quadrant: "customer",
            title: "",
            weight: 100,
          },
        ],
        total_weight: 100,
      },
      business_process: {
        data: [
          {
            id: null,
            balance_scorecard_id: null,
            quadrant: "business_process",
            title: "",
            weight: 100,
          },
        ],
        total_weight: 100,
      },
      learning_growth: {
        data: [
          {
            id: null,
            balance_scorecard_id: null,
            quadrant: "learning_growth",
            title: "",
            weight: 100,
          },
        ],
        total_weight: 100,
      },
    },
  });

  useEffect(() => {
    let totalWeight =
      formData.finance_weight +
      formData.customer_weight +
      formData.business_process_weight +
      formData.learning_growth_weight;
    setTotalWeight(totalWeight);
  }, [formData]);

  const handleChange = (name, value, index, subname) => {
    let newArray = JSON.parse(JSON.stringify(formData));
    newArray.quadrants[name].data[index][subname] = value;
    if (subname === "weight") {
      let total = 0;
      let totalArray = newArray.quadrants[name].data;
      if (totalArray.length > 0) {
        for (let i = 0; i < totalArray.length; i++) {
          total += parseFloat(totalArray[i].weight);
        }
      }
      newArray.quadrants[name].total_weight = total;
    }

    setFormData(newArray);
  };
  // const [evaluation_frequency, setEvaluationFrequency] = useState('monthly');

  // const [dateTypeOptions, setDateTypeOptions] = useState(
  //     [
  //         {label: 'Day', value:'day'},
  //         {label: 'First', value:'first'},
  //         {label: 'Second', value:'second'},
  //         {label: 'Third', value:'third'},
  //         {label: 'Fourth', value:'fourth'},
  //         {label: 'Last', value:'last'},
  //     ]
  // )

  // const [dayOptions, setDayOptions] = useState([
  //     {label: '1st', value: '01'},
  //     {label: '2nd', value: '02'},
  //     {label: '3rd', value: '03'},
  //     {label: '4th', value: '04'},
  //     {label: '5th', value: '05'},
  //     {label: '6th', value: '06'},
  //     {label: '7th', value: '07'},
  //     {label: '8th', value: '08'},
  //     {label: '9th', value: '09'},
  //     {label: '10th', value: '10'},
  //     {label: '11th', value: '11'},
  //     {label: '12th', value: '12'},
  //     {label: '13th', value: '13'},
  //     {label: '14th', value: '14'},
  //     {label: '15th', value: '15'},
  //     {label: '16th', value: '16'},
  //     {label: '17th', value: '17'},
  //     {label: '18th', value: '18'},
  //     {label: '19th', value: '19'},
  //     {label: '20th', value: '20'},
  //     {label: '21st', value: '21'},
  //     {label: '22nd', value: '22'},
  //     {label: '23rd', value: '23'},
  //     {label: '24th', value: '24'},
  //     {label: '25th', value: '25'},
  //     {label: '26th', value: '26'},
  //     {label: '27th', value: '27'},
  //     {label: '28th', value: '28'},
  //     {label: 'Last', value: 'last'},
  // ])

  // const endTypeOptions = [
  //     {label: 'None', value:'none'},
  //     {label: 'By', value:'by'},
  //     {label: 'After', value:'after'},
  // ]

  // const evaluationFrequencyOptions = [
  //   {
  //     label: 'Daily',
  //     value: 'daily'
  //   },
  //   {
  //     label: 'Weekly',
  //     value: 'weekly'
  //   },
  //   {
  //     label: 'Monthly',
  //     value: 'monthly'
  //   },
  //   {
  //     label: 'Quarterly',
  //     value: 'quarterly'
  //   },
  //   {
  //     label: 'Semi-annually',
  //     value: 'semi-annually'
  //   },
  //   {
  //     label: 'Annually',
  //     value: 'annually'
  //   },
  // ]

  useEffect(() => {
    if (balanceScorecardData) {
      if (type === "edit") {
        setSource(balanceScorecardData.source);
        setSourceId(balanceScorecardData.source_id);
        setSourceName(balanceScorecardData.source_name);
      }
      setBalanceScorecardId(balanceScorecardData.id);
      setFormData(balanceScorecardData);
    }
  }, [balanceScorecardData]);

  const {
    mutateAsync: addBalancedScorecard,
    isLoading: addBalancedScorecardLoading,
  } = useAddPerformanceBalancedScorecard();
  const {
    mutateAsync: editBalancedScorecard,
    isLoading: editBalancedScorecardLoading,
  } = useEditPerformanceBalancedScorecard();

  const addObjective = (name) => {
    let newArray = JSON.parse(JSON.stringify(formData));
    let newObject = {
      id: null,
      balance_scorecard_id: null,
      quadrant: name,
      title: "",
      weight: 0,
    };
    newArray.quadrants[name].data.push(newObject);
    setFormData(newArray);
  };

  const removeObjective = (name, index) => {
    let newArray = JSON.parse(JSON.stringify(formData));

    newArray.quadrants[name].data.splice(index, 1);
    setFormData(newArray);
  };

  const submitForm = async () => {
    if (type === "edit") {
      let payload = {
        id: balanceScorecardId,
        data: {
          ...formData,
          source: source,
          source_id: sourceId,
          source_name: sourceName,
        },
      };
      await editBalancedScorecard(payload).then(() => {
        closeModal();
      });
    } else {
      let payload = {
        ...formData,
        source: source,
        source_id: sourceId,
        source_name: sourceName,
      };
      await addBalancedScorecard(payload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className="md:min-w-[1000px] min-w-[100%] min-h-screen !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col overflow-auto">
        <form className="form pb-6">
          <div className="pl-6 pr-6 pt-4">
            <div className="flex flex-row justify-between items-center">
              <span className="header-4">{title}</span>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <img src={CloseSVG} alt="close" />
              </div>
            </div>
          </div>
          <hr className="divider mb-2" />
          <div className="flex flex-col min-h-fit mx-4 gap-2">
            <div className="flex flex-col md:flex-row w-full justify-between rounded-md p-2 border bg-highlight pt-2 pb-0">
              <div className="w-full md:w-2/4 md:pr-2">
                <FormInput
                  label="Title"
                  placeholder="Enter Balanced Scorecard Title"
                  name="balanced_scorecard_title"
                  type="text"
                  value={formData.title}
                  onInput={(e) => {
                    setFormData({ ...formData, title: e.target.value });
                  }}
                  readOnly={
                    addBalancedScorecardLoading || editBalancedScorecardLoading
                  }
                  className="!h-[35px] text-[13px] w-[100%] "
                />
              </div>
              <div className="w-full md:w-1/4 md:pl-2">
                <FormSelect
                  defaultValue={source}
                  options={sourceOptions}
                  onChange={(selected) => {
                    setSource(selected);
                    setSourceId(null);
                  }}
                  label="Balanced Scorecard For:"
                  extraClass={"bg-white"}
                />
              </div>

              <div className="w-full md:w-1/4 md:pl-2">
                {!source || source === "company" ? null : source ===
                  "employee" ? (
                  <FormSelectEmployee
                    label={"Employee"}
                    name="employee"
                    placeholder="Select Employee"
                    value={sourceId}
                    empName={sourceName}
                    setSourceName={setSourceName}
                    setEmployee={setSourceId}
                  />
                ) : source === "team" ? (
                  <FormSelectTeam
                    label={"Team"}
                    name="team"
                    placeholder="Select Team"
                    value={sourceId}
                    teamName={sourceName}
                    setSourceName={setSourceName}
                    setTeam={setSourceId}
                  />
                ) : source === "department" ? (
                  <FormSelectDepartment
                    label={"Department"}
                    name="department"
                    placeholder="Select Department"
                    value={sourceId}
                    departmentName={sourceName}
                    setSourceName={setSourceName}
                    setDepartment={setSourceId}
                  />
                ) : (
                  <FormSelectBranch
                    label={"Branch"}
                    name="branch"
                    placeholder="Select Branch"
                    value={sourceId}
                    branchName={sourceName}
                    setSourceName={setSourceName}
                    setBranch={setSourceId}
                  />
                )}
              </div>
            </div>
            <hr className="divider mb-1 mt-2" />
            {totalWeight === 100 ? null : (
              <div className="w-full gap-3 text-[14px] font-semibold text-color-error">
                Total Weight must be equal to (100%)
              </div>
            )}
            <div className={"grid md:grid-cols-2 grid-cols-1 w-full gap-3"}>
              <div className="w-full border rounded overflow-x-hidden">
                <div className="flex gap-2 bg-hr-primary-1 px-2 h-[50px] items-center justify-start font-extrabold text-white">
                  <img
                    src={FInancialGrowthSVG}
                    alt=""
                    height={"20px"}
                    width={"20px"}
                  />
                  <span className="text-white">Financial</span>
                  <div className="pt-1">
                    <FormInput
                      placeholder="Enter objective weight"
                      name="finance_objective_weight"
                      type="number"
                      value={formData.finance_weight}
                      onInput={(e) => {
                        setFormData({
                          ...formData,
                          finance_weight: parseFloat(e.target.value),
                        });
                      }}
                      readOnly={
                        addBalancedScorecardLoading ||
                        editBalancedScorecardLoading
                      }
                      labelClass={"!text-[12px]"}
                      className="!h-[27px] text-[13px] w-[50px] !py-2"
                    />
                  </div>
                  <span className="text-white">%</span>
                </div>
                <div className="w-full h-[250px] overflow-x-hidden">
                  <div className="flex justify-between items-center w-full mt-2 px-2">
                    {formData?.quadrants?.finance.total_weight !== 100 ? (
                      <div className="text-[13px] text-error">
                        Total must be equal to{" "}
                        <span className="text-[13px] text-error font-semibold">
                          ({100})
                        </span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {formData?.quadrants?.finance?.data.length > 0 ? (
                    <div className=" flex flex-col gap-2 p-2">
                      {formData?.quadrants?.finance.data.map((item, index) => (
                        <div
                          className="flex flex-row w-full justify-between rounded-md p-1 border bg-highlight"
                          key={index}
                        >
                          <div className="w-full md:w-2/3 md:pr-2 -pb-2">
                            <FormInput
                              label="Objective"
                              placeholder="Enter Objective"
                              name="finance_objective"
                              type="text"
                              value={item.title}
                              onInput={(e) => {
                                handleChange(
                                  "finance",
                                  e.target.value,
                                  index,
                                  "title"
                                );
                              }}
                              readOnly={
                                addBalancedScorecardLoading ||
                                editBalancedScorecardLoading
                              }
                              labelClass={"!text-[12px]"}
                              className="!h-[33px] text-[13px] w-[100%]"
                            />
                          </div>
                          <div className="w-full md:w-1/3 md:pr-2">
                            <FormInput
                              label="Weight"
                              placeholder="Enter objective weight"
                              name="finance_objective_weight"
                              type="number"
                              value={item.weight}
                              onInput={(e) => {
                                handleChange(
                                  "finance",
                                  e.target.value,
                                  index,
                                  "weight"
                                );
                              }}
                              readOnly={
                                addBalancedScorecardLoading ||
                                editBalancedScorecardLoading
                              }
                              labelClass={"!text-[12px]"}
                              className="!h-[33px] text-[13px] w-[100%]"
                            />
                          </div>
                          {formData?.quadrants?.finance?.data.length > 1 ? (
                            <div
                              className="flex justify-end text-[11px] text-error italic cursor-pointer hover:underline duration-300"
                              onClick={() => {
                                removeObjective("finance", index);
                              }}
                            >
                              Remove
                            </div>
                          ) : null}
                        </div>
                      ))}
                      <div className="flex justify-end items-center w-full my-2 px-2">
                        <Button
                          text={"New Objective"}
                          theme={"third"}
                          type="button"
                          leftIcon={"add"}
                          className={
                            "flex gap-2 w-fit px-3 h-[25px] !bg-hr-primary-1"
                          }
                          textClass={"text-[10px]"}
                          onClick={() => addObjective("finance")}
                          loading={
                            addBalancedScorecardLoading ||
                            editBalancedScorecardLoading
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="p-2">
                      <div className="flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8">
                        No Finance Objective Added Yet
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full border rounded overflow-x-hidden">
                <div className="flex gap-2 bg-spend-primary-1 px-2 h-[50px] items-center justify-start font-extrabold text-white">
                  <img
                    src={CustomerGrowthSVG}
                    alt=""
                    height={"20px"}
                    width={"20px"}
                  />
                  <span className="text-white">Customers</span>
                  <div className="pt-1">
                    <FormInput
                      placeholder="Enter objective weight"
                      name="finance_objective_weight"
                      type="number"
                      value={formData.customer_weight}
                      onInput={(e) => {
                        setFormData({
                          ...formData,
                          customer_weight: parseFloat(e.target.value),
                        });
                      }}
                      readOnly={
                        addBalancedScorecardLoading ||
                        editBalancedScorecardLoading
                      }
                      labelClass={"!text-[12px]"}
                      className="!h-[27px] text-[13px] w-[50px] !py-2"
                    />
                  </div>
                  <span className="text-white">%</span>
                </div>
                <div className="w-full h-[250px] overflow-x-hidden">
                  <div className="flex justify-between items-center w-full mt-2 px-2">
                    {formData?.quadrants?.customer.total_weight !== 100 ? (
                      <div className="text-[13px] text-error">
                        Total must be equal to{" "}
                        <span className="text-[13px] text-error font-semibold">
                          ({100})
                        </span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {formData?.quadrants?.customer.data?.length > 0 ? (
                    <div className=" flex flex-col gap-2 p-2">
                      {formData?.quadrants?.customer.data.map((item, index) => (
                        <div
                          className="flex flex-row w-full justify-between rounded-md p-1 border bg-highlight"
                          key={index}
                        >
                          <div className="w-full md:w-2/3 md:pr-2 -pb-2">
                            <FormInput
                              label="Objective"
                              placeholder="Enter Objective"
                              name="customer_objective"
                              type="text"
                              value={item.title}
                              onInput={(e) => {
                                handleChange(
                                  "customer",
                                  e.target.value,
                                  index,
                                  "title"
                                );
                              }}
                              readOnly={
                                addBalancedScorecardLoading ||
                                editBalancedScorecardLoading
                              }
                              labelClass={"!text-[12px]"}
                              className="!h-[33px] text-[13px] w-[100%]"
                            />
                          </div>
                          <div className="w-full md:w-1/3 md:pr-2">
                            <FormInput
                              label="Weight"
                              placeholder="Enter objective weight"
                              name="customer_objective_weight"
                              type="number"
                              value={item.weight}
                              onInput={(e) => {
                                handleChange(
                                  "customer",
                                  e.target.value,
                                  index,
                                  "weight"
                                );
                              }}
                              readOnly={
                                addBalancedScorecardLoading ||
                                editBalancedScorecardLoading
                              }
                              labelClass={"!text-[12px]"}
                              className="!h-[33px] text-[13px] w-[100%]"
                            />
                          </div>
                          {formData?.quadrants?.customer?.data.length > 1 ? (
                            <div
                              className="flex justify-end text-[11px] text-error italic cursor-pointer hover:underline duration-300"
                              onClick={() => {
                                removeObjective("customer", index);
                              }}
                            >
                              Remove
                            </div>
                          ) : null}
                        </div>
                      ))}
                      <div className="flex justify-end items-center w-full my-2 px-2">
                        <Button
                          text={"New Objective"}
                          theme={"third"}
                          type="button"
                          leftIcon={"add"}
                          className={
                            "flex gap-2 w-fit px-3 h-[25px] !bg-spend-primary-1"
                          }
                          textClass={"text-[10px]"}
                          onClick={() => addObjective("customer")}
                          loading={
                            addBalancedScorecardLoading ||
                            editBalancedScorecardLoading
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="p-2">
                      <div className="flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8">
                        No Customer Objective Added Yet
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full border rounded overflow-x-hidden">
                <div className="flex gap-2 bg-secondary-2 px-2 h-[50px] items-center justify-start font-extrabold text-white">
                  <img
                    src={BusinessProcessesSVG}
                    alt=""
                    height={"20px"}
                    width={"20px"}
                  />
                  <span className="text-white">Business Processes</span>
                  <div className="pt-1">
                    <FormInput
                      placeholder="Enter objective weight"
                      name="business_process_objective_weight"
                      type="number"
                      value={formData.business_process_weight}
                      onInput={(e) => {
                        setFormData({
                          ...formData,
                          business_process_weight: parseFloat(e.target.value),
                        });
                      }}
                      readOnly={
                        addBalancedScorecardLoading ||
                        editBalancedScorecardLoading
                      }
                      labelClass={"!text-[12px]"}
                      className="!h-[27px] text-[13px] w-[50px] !py-2"
                    />
                  </div>
                  <span className="text-white">%</span>
                </div>
                <div className="w-full h-[250px] overflow-x-hidden">
                  <div className="flex justify-between items-center w-full mt-2 px-2">
                    {formData?.quadrants?.business_process.total_weight !==
                    100 ? (
                      <div className="text-[13px] text-error">
                        Total must be equal to{" "}
                        <span className="text-[13px] text-error font-semibold">
                          ({100})
                        </span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {formData?.quadrants?.business_process?.data.length > 0 ? (
                    <div className=" flex flex-col gap-2 p-2">
                      {formData?.quadrants?.business_process?.data.map(
                        (item, index) => (
                          <div
                            className="flex flex-row w-full justify-between rounded-md p-1 border bg-highlight"
                            key={index}
                          >
                            <div className="w-full md:w-2/3 md:pr-2 -pb-2">
                              <FormInput
                                label="Objective"
                                placeholder="Enter Objective"
                                name="business_process_objective"
                                type="text"
                                value={item.title}
                                onInput={(e) => {
                                  handleChange(
                                    "business_process",
                                    e.target.value,
                                    index,
                                    "title"
                                  );
                                }}
                                readOnly={
                                  addBalancedScorecardLoading ||
                                  editBalancedScorecardLoading
                                }
                                labelClass={"!text-[12px]"}
                                className="!h-[33px] text-[13px] w-[100%]"
                              />
                            </div>
                            <div className="w-full md:w-1/3 md:pr-2">
                              <FormInput
                                label="Weight"
                                placeholder="Enter objective weight"
                                name="business_process_objective_weight"
                                type="number"
                                value={item.weight}
                                onInput={(e) => {
                                  handleChange(
                                    "business_process",
                                    e.target.value,
                                    index,
                                    "weight"
                                  );
                                }}
                                readOnly={
                                  addBalancedScorecardLoading ||
                                  editBalancedScorecardLoading
                                }
                                labelClass={"!text-[12px]"}
                                className="!h-[33px] text-[13px] w-[100%]"
                              />
                            </div>
                            {formData?.quadrants?.business_process?.data
                              .length > 1 ? (
                              <div
                                className="flex justify-end text-[11px] text-error italic cursor-pointer hover:underline duration-300"
                                onClick={() => {
                                  removeObjective("business_process", index);
                                }}
                              >
                                Remove
                              </div>
                            ) : null}
                          </div>
                        )
                      )}
                      <div className="flex justify-end items-center w-full my-2 px-2">
                        <Button
                          text={"New Objective"}
                          theme={"third"}
                          type="button"
                          leftIcon={"add"}
                          className={
                            "flex gap-2 w-fit px-3 h-[25px] !bg-secondary-2"
                          }
                          textClass={"text-[10px]"}
                          onClick={() => addObjective("business_process")}
                          loading={
                            addBalancedScorecardLoading ||
                            editBalancedScorecardLoading
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="p-2">
                      <div className="flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8">
                        No Business Processes Objective Added Yet
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full border rounded overflow-x-hidden">
                <div className="flex gap-2 bg-error px-2 h-[50px] items-center justify-start font-extrabold text-white">
                  <img
                    src={LeaningGrowthSVG}
                    alt=""
                    height={"20px"}
                    width={"20px"}
                  />
                  <span className="text-white">Learning and Growth</span>
                  <div className="pt-1">
                    <FormInput
                      placeholder="Enter objective weight"
                      name="learning_growth_objective_weight"
                      type="number"
                      value={formData.learning_growth_weight}
                      onInput={(e) => {
                        setFormData({
                          ...formData,
                          learning_growth_weight: parseFloat(e.target.value),
                        });
                      }}
                      readOnly={
                        addBalancedScorecardLoading ||
                        editBalancedScorecardLoading
                      }
                      labelClass={"!text-[12px]"}
                      className="!h-[27px] text-[13px] w-[50px] !py-2"
                    />
                  </div>
                  <span className="text-white">%</span>
                </div>
                <div className="w-full h-[250px] overflow-x-hidden">
                  <div className="flex justify-between items-center w-full mt-2 px-2">
                    {formData?.quadrants?.learning_growth.total_weight !==
                    100 ? (
                      <div className="text-[13px] text-error">
                        Total must be equal to{" "}
                        <span className="text-[13px] text-error font-semibold">
                          ({100})
                        </span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {formData?.quadrants?.learning_growth?.data.length > 0 ? (
                    <div className=" flex flex-col gap-2 p-2">
                      {formData?.quadrants?.learning_growth?.data.map(
                        (item, index) => (
                          <div
                            className="flex flex-row w-full justify-between rounded-md p-1 border bg-highlight"
                            key={index}
                          >
                            <div className="w-full md:w-2/3 md:pr-2 -pb-2">
                              <FormInput
                                label="Objective"
                                placeholder="Enter Objective"
                                name="learning_growth_objective"
                                type="text"
                                value={item.title}
                                onInput={(e) => {
                                  handleChange(
                                    "learning_growth",
                                    e.target.value,
                                    index,
                                    "title"
                                  );
                                }}
                                readOnly={
                                  addBalancedScorecardLoading ||
                                  editBalancedScorecardLoading
                                }
                                labelClass={"!text-[12px]"}
                                className="!h-[33px] text-[13px] w-[100%]"
                              />
                            </div>
                            <div className="w-full md:w-1/3 md:pr-2">
                              <FormInput
                                label="Weight"
                                placeholder="Enter objective weight"
                                name="learning_growth_objective_weight"
                                type="number"
                                value={item.weight}
                                onInput={(e) => {
                                  handleChange(
                                    "learning_growth",
                                    e.target.value,
                                    index,
                                    "weight"
                                  );
                                }}
                                readOnly={
                                  addBalancedScorecardLoading ||
                                  editBalancedScorecardLoading
                                }
                                labelClass={"!text-[12px]"}
                                className="!h-[33px] text-[13px] w-[100%]"
                              />
                            </div>
                            {formData?.quadrants?.learning_growth?.data.length >
                            1 ? (
                              <div
                                className="flex justify-end text-[11px] text-error italic cursor-pointer hover:underline duration-300"
                                onClick={() => {
                                  removeObjective("learning_growth", index);
                                }}
                              >
                                Remove
                              </div>
                            ) : null}
                          </div>
                        )
                      )}
                      <div className="flex justify-end items-center w-full my-2 px-2">
                        <Button
                          text={"New Objective"}
                          theme={"third"}
                          type="button"
                          leftIcon={"add"}
                          className={"flex gap-2 w-fit px-3 h-[25px] !bg-error"}
                          textClass={"text-[10px]"}
                          onClick={() => addObjective("learning_growth")}
                          loading={
                            addBalancedScorecardLoading ||
                            editBalancedScorecardLoading
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="p-2">
                      <div className="flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8">
                        No Learning and Growth Objective Added Yet
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className='mt-3'>
              <FormSwitch
                label={'Do you want evaluation to be done automatically?'}
                name={'auto_evaluate'}
                checked={formData.auto_evaluate}
                onClick={() =>
                  setFormData({...formData, auto_evaluate: !formData.auto_evaluate})
                }
                value={formData.auto_evaluate}
              />
            </div> */}
            {/* {formData.auto_evaluate?
            <div className='flex flex-col gap-2 -mt-3 mb-4 !w-full border rounded-md p-2 md:gap-3 bg-gray-50 '>
              <div className='text-[23px] font-normal'>Auto-evaluation information</div>
              <div className='flex md:flex-row flex-col gap-2 justify-start items-center text-[13px] mr-4 flex-wrap'>
                <FormSelect
                  name={'evaluation_frequency'}
                  value={formData.evaluation_frequency}
                  options={evaluationFrequencyOptions}
                  onChange={(selected) => {
                      setFormData({...formData, evaluation_frequency: selected})
                      setEvaluationFrequency(selected)
                      if (selected == 'weekly') {
                        setFormData({...formData, date_type: 'monday'})
                        setDateTypeOptions([
                          {label: 'Monday', value:'monday'},
                          {label: 'Tuesday', value:'tuesday'},
                          {label: 'Wednesday', value:'wednesday'},
                          {label: 'Thursday', value:'thursday'},
                          {label: 'Friday', value:'friday'},
                          {label: 'Saturday', value:'saturday'},
                          {label: 'Sunday', value:'sunday'},
                        ])
                      } else if (selected == 'monthly') {
                        setFormData({...formData, date_type: 'day'})
                        setFormData({...formData, day: 1})
                        setDateTypeOptions([
                            {label: 'Day', value:'day'},
                            {label: 'First', value:'first'},
                            {label: 'Second', value:'second'},
                            {label: 'Third', value:'third'},
                            {label: 'Fourth', value:'fourth'},
                            {label: 'Last', value:'last'},
                        ])
                        setDayOptions(
                            [
                                {label: '1st', value: '01'},
                                {label: '2nd', value: '02'},
                                {label: '3rd', value: '03'},
                                {label: '4th', value: '04'},
                                {label: '5th', value: '05'},
                                {label: '6th', value: '06'},
                                {label: '7th', value: '07'},
                                {label: '8th', value: '08'},
                                {label: '9th', value: '09'},
                                {label: '10th', value: '10'},
                                {label: '11th', value: '11'},
                                {label: '12th', value: '12'},
                                {label: '13th', value: '13'},
                                {label: '14th', value: '14'},
                                {label: '15th', value: '15'},
                                {label: '16th', value: '16'},
                                {label: '17th', value: '17'},
                                {label: '18th', value: '18'},
                                {label: '19th', value: '19'},
                                {label: '20th', value: '20'},
                                {label: '21st', value: '21'},
                                {label: '22nd', value: '22'},
                                {label: '23rd', value: '23'},
                                {label: '24th', value: '24'},
                                {label: '25th', value: '25'},
                                {label: '26th', value: '26'},
                                {label: '27th', value: '27'},
                                {label: '28th', value: '28'},
                                {label: 'Last', value: 'last'},
                            ]
                        )
                      } else if (selected == 'yearly') {
                        setFormData({...formData, date_type: 'january'})
                        setFormData({...formData, day: 1})
                        setDateTypeOptions([
                            {label: 'January', value:'january'},
                            {label: 'February', value:'february'},
                            {label: 'March', value:'march'},
                            {label: 'April', value:'april'},
                            {label: 'May', value:'may'},
                            {label: 'June', value:'june'},
                            {label: 'July', value:'july'},
                            {label: 'August', value:'august'},
                            {label: 'September', value:'september'},
                            {label: 'October', value:'october'},
                            {label: 'November', value:'november'},
                            {label: 'December', value:'december'},
                        ])
                        setDayOptions(
                            [
                                {label: '1st', value: '01'},
                                {label: '2nd', value: '02'},
                                {label: '3rd', value: '03'},
                                {label: '4th', value: '04'},
                                {label: '5th', value: '05'},
                                {label: '6th', value: '06'},
                                {label: '7th', value: '07'},
                                {label: '8th', value: '08'},
                                {label: '9th', value: '09'},
                                {label: '10th', value: '10'},
                                {label: '11th', value: '11'},
                                {label: '12th', value: '12'},
                                {label: '13th', value: '13'},
                                {label: '14th', value: '14'},
                                {label: '15th', value: '15'},
                                {label: '16th', value: '16'},
                                {label: '17th', value: '17'},
                                {label: '18th', value: '18'},
                                {label: '19th', value: '19'},
                                {label: '20th', value: '20'},
                                {label: '21st', value: '21'},
                                {label: '22nd', value: '22'},
                                {label: '23rd', value: '23'},
                                {label: '24th', value: '24'},
                                {label: '25th', value: '25'},
                                {label: '26th', value: '26'},
                                {label: '27th', value: '27'},
                                {label: '28th', value: '28'},
                                {label: 'Last', value: 'last'},
                            ]
                        )
                      }
                  }}
                  label='How Often?'
                  extraClass={'!max-w-[120px] !min-w-[120px] !text-[12px] !pl-2'}
                />
                <div className={'flex md:mt-3 gap-2 items-center'}>
                  {evaluation_frequency !== 'daily' ?
                  <>
                      <span className='min-w-fit pb-1'>on</span>
                      <FormSelect
                          options={dateTypeOptions}
                          value={formData.date_type}
                          
                          onChange={(selected) => {
                            setFormData({...formData, date_type: selected})
                            if (evaluation_frequency == 'monthly' && selected !== 'day') {
                              setFormData({...formData, day: 'monday'})
                              setDayOptions(
                                  [
                                      {label: 'Monday', value:'monday'},
                                      {label: 'Tuesday', value:'tuesday'},
                                      {label: 'Wednesday', value:'wednesday'},
                                      {label: 'Thursday', value:'thursday'},
                                      {label: 'Friday', value:'friday'},
                                      {label: 'Saturday', value:'saturday'},
                                      {label: 'Sunday', value:'sunday'},
                                  ],
                                )
                              } else {
                                setFormData({...formData, day: 1})
                                setDayOptions(
                                    [
                                        {label: '1st', value: '01'},
                                        {label: '2nd', value: '02'},
                                        {label: '3rd', value: '03'},
                                        {label: '4th', value: '04'},
                                        {label: '5th', value: '05'},
                                        {label: '6th', value: '06'},
                                        {label: '7th', value: '07'},
                                        {label: '8th', value: '08'},
                                        {label: '9th', value: '09'},
                                        {label: '10th', value: '10'},
                                        {label: '11th', value: '11'},
                                        {label: '12th', value: '12'},
                                        {label: '13th', value: '13'},
                                        {label: '14th', value: '14'},
                                        {label: '15th', value: '15'},
                                        {label: '16th', value: '16'},
                                        {label: '17th', value: '17'},
                                        {label: '18th', value: '18'},
                                        {label: '19th', value: '19'},
                                        {label: '20th', value: '20'},
                                        {label: '21st', value: '21'},
                                        {label: '22nd', value: '22'},
                                        {label: '23rd', value: '23'},
                                        {label: '24th', value: '24'},
                                        {label: '25th', value: '25'},
                                        {label: '26th', value: '26'},
                                        {label: '27th', value: '27'},
                                        {label: '28th', value: '28'},
                                        {label: 'Last', value: 'last'},
                                    ]
                                )
                              }
                          }}
                          extraClass={'!max-w-[100px] !min-w-[100px] !text-[13px] !pl-2'}
                      />
                      {evaluation_frequency !== 'weekly' ?
                      <FormSelect
                        options={dayOptions}
                        value={formData.day}
                        onChange={(selected) => {
                          setFormData({...formData, day: selected})
                        }}
                        extraClass={'!max-w-[100px] !min-w-[100px] !text-[13px]'}
                      />
                      :
                      null}
                  </> :
                  null
                  }
                  <span className='max-w-fit min-w-fit pb-1'>every</span>
                  <div>
                    <FormInput
                      name='interval_count'
                      type='number'
                      className={'!max-w-[40px]'}
                      value={formData.interval_count}
                      onInput={(e) => {
                        setFormData({...formData, interval_count: e.target.value})
                      }}
                    />
                  </div>
                  {evaluation_frequency == 'daily' ?
                  <span className='min-w-fit pb-1'>day(s), </span>
                  :evaluation_frequency == 'weekly' ?
                  <span className='min-w-fit pb-1'>week(s), </span>
                  :evaluation_frequency == 'monthly' ?
                  <span className='min-w-fit pb-1'>month(s), </span>
                  :evaluation_frequency == 'yearly' ?
                  <span className='min-w-fit pb-1'>year(s), </span>
                  :
                  null}
                  <span className='min-w-fit pb-1'>starts on</span>
                  <div>
                    <FormInput
                      name='start_date'
                      type='date'
                      
                      className={'!max-w-[150px] !min-w-[150px] !pb-[15px] !text-[13px]'}
                      value={formData.auto_evaluation_start_date}
                      onInput={(e) => {
                        setFormData({...formData, auto_evaluation_start_date: e.target.value})
                      }}
                    />
                  </div>
                </div>
                <div className={'flex md:mt-3 gap-2 items-center'}>
                    <span className='min-w-fit pb-1'>ends</span>
                    <FormSelect
                      options={endTypeOptions}
                      value={formData.end_type}
                      
                      onChange={(selected) => {
                        setFormData({...formData, end_type: selected})
                      }}
                      extraClass={'!max-w-[80px] !min-w-[80px] !text-[13px] !pl-2'}
                    />
                    {formData.end_type !== 'none' && (
                      <>
                        {formData.end_type == 'by' ?
                        <FormInput
                          name='end_date'
                          type='date'
                          value={formData.auto_evaluation_end_date}
                          onInput={(e) => {
                            setFormData({...formData, auto_evaluation_end_date: e.target.value})
                          }}
                          className={'!max-w-[150px] !min-w-[150px] !pb-[15px] !text-[13px]'}
                        />
                        :
                        <div className='flex gap-2 items-center'>
                          <FormInput
                            name='occurrences'
                            type='text'
                            className={'!max-w-[50px] !pl-2'}
                            
                            value={formData.occurrences}
                            onInput={(e) => {
                              setFormData({...formData, occurrences: e.target.value})
                            }}
                          />
                          <span className='min-w-fit pb-1'>occurrences</span>
                        </div>
                        }
                      </>
                    )}
                </div>
              </div>
            </div>
            :
            null} */}
            <div className="flex justify-end items-end w-full mt-2">
              <Button
                text={buttonText}
                theme={"third"}
                type="button"
                className={"w-fit px-3 h-[35px]"}
                textClass={"text-[13px]"}
                onClick={() => submitForm()}
                loading={
                  addBalancedScorecardLoading || editBalancedScorecardLoading
                }
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddPerformanceBalancedScorecard;
