import { Axios } from '../axios';

const getExitSummary = async () => {
    const { data } = await Axios.get('/exit/summary');
    return data?.data;
};

const getEmployeeResignations = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/exit/resignations?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const getEmployeeTerminations = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(
      `/exit/terminations?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
    );
  
    return data?.data;
  };

const getSingleEmployeeExit = async (payload) => {
    const { data } = await Axios.get('/exits/' + payload);
    return data?.data;
};

const getSingleEmployeeExitAnswers = async (payload) => {
  const { data } = await Axios.get('/exits/answers/' + payload);
  return data?.data;
};

const addEmployeeExit = async (payload) => {
  const { data } = await Axios.post('/exits', payload);
  return data;
};

const addBulkEmployeeExit = async (payload) => {
  const { data } = await Axios.post('/exits/bulk', payload,
  {
    timeout: 0
  });
  return data;
};

const updateEmployeeExit = async (payload) => {
    const { data } = await Axios.put(
      '/exits/' + payload.exit_id,
      payload.data,
    );
    return data;
};

const deleteEmployeeExit = async (payload) => {
    const { data } = await Axios.delete(
      '/exits/' + payload
    );
    return data;
};

const extendExitDays = async (payload) => {
    const { data } = await Axios.post(
      '/exit/extend-exit-days/' + payload.exit_id,
      payload.data,
    );
    return data;
};

const changeExitStageStatus = async (payload) => {
    const { data } = await Axios.post(
      '/exit/change-step-status/' + payload.stage_id,
      payload.data,
    );
    return data;
};


const changeExitStatus = async (payload) => {
    const { data } = await Axios.post(
      '/exit/change-status/' + payload.exit_id,
      payload.data,
    );
    return data;
};

const changeExitStageEmployeeStatus = async (payload) => {
    const { data } = await Axios.post(
      '/exit/change-employee-step-status/' + payload.exit_id,
      payload.data,
    );
    return data;
};

const changeExitEmployeeStatus = async (payload) => {
    const { data } = await Axios.post(
      '/exit/change-employee-status/' + payload.exit_id,
      payload.data,
    );
    return data;
};

const getExitWorkflows = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
  
    const { data } = await Axios.get(
      `/exit/workflows?limit=${payload.limit}&page=${payload.page}${search}`,
    );
  
    return data?.data;
};

const addExitWorkflow = async (payload) => {
    const { data } = await Axios.post('/exit/workflows', payload,
    {
      timeout: 0
    });
    return data;
};

const getExitWorkflow = async (payload) => {
    const { data } = await Axios.get('/exit/workflows/' + payload);
    return data?.data;
};

const updateExitWorkflow = async (payload) => {
    const { data } = await Axios.put(
      '/workflows/' + payload.workflow_id,
      payload.data,
    );
    return data;
};


const deleteExitWorkflow = async (payload) => {
    const { data } = await Axios.delete(
      '/workflows/' + payload
    );
    return data;
};

const getExitQuestions = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
  
    const { data } = await Axios.get(
      `/exit/questions?limit=${payload.limit}&page=${payload.page}${search}`,
    );
  
    return data?.data;
};

const addExitQuestions = async (payload) => {
    const { data } = await Axios.post('/exit/questions', payload,
    {
      timeout: 0
    });
    return data;
};

const updateExitQuestion = async (payload) => {
    const { data } = await Axios.put(
      '/exit/questions/' + payload.question_id,
      payload.data,
    );
    return data;
};

const deleteExitQuestion = async (payload) => {
    const { data } = await Axios.delete(
      '/exit/questions/' + payload
    );
    return data;
};

//Employee Action
const generateEmployeeOtp = async (payload) => {
    const { data } = await Axios.get('/employee/exit/generate-pin/'+payload  );
    return data;
  };
  
const confirmEmployeeOtp = async (payload) => {
    const { data } = await Axios.post('/employee/exit/confirm-pin', payload);
    return data;
};

const getEmployeeExitByToken = async (payload) => {
    const { data } = await Axios.get('/employee/exit/fetch-by-token', payload);
    return data?.data;
};

const saveExitAnswers = async (payload) => {
    const { data } = await Axios.post(
      '/employee/exit/save-answer', payload
    );
    return data;
};

const saveExitStage = async (payload) => {
    const { data } = await Axios.post(
      '/employee/exit/save-stage/' + payload.id,
      payload.data,
    );
    return data;
};

const markExitComplete = async (payload) => {
    const { data } = await Axios.post(
      '/employee/exit/mark-exit-complete/' + payload.id,
      payload?.data,
    );
    return data;
};

export const employeeExits = {
    getExitSummary,
    getEmployeeResignations,
    getEmployeeTerminations,
    getSingleEmployeeExit,
    getSingleEmployeeExitAnswers,
    addEmployeeExit,
    addBulkEmployeeExit,
    updateEmployeeExit,
    deleteEmployeeExit,
    extendExitDays,
    changeExitStatus,
    changeExitStageStatus,
    changeExitEmployeeStatus,
    changeExitStageEmployeeStatus,
    getExitWorkflows,
    addExitWorkflow,
    getExitWorkflow,
    updateExitWorkflow,
    deleteExitWorkflow,
    getExitQuestions,
    addExitQuestions,
    updateExitQuestion,
    deleteExitQuestion,

    //Employee Action
    generateEmployeeOtp,
    confirmEmployeeOtp,
    getEmployeeExitByToken,
    saveExitAnswers,
    saveExitStage,
    markExitComplete
  };