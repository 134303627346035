import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import PerformanceWorkflowTab from "../../../components/hr-section/performance/performance-workflows";
import EvaluatePerformance from "../../../components/modal/hr-section/performance/evaluate-performance";
import HRPerformanceOverviewTab from "../../../components/hr-section/performance/performance-overview";
import AddPerformanceWorkflow from "../../../components/modal/hr-section/performance/add-workflow";
// import PerformanceSVG from '../../../assets/svg/performance-widget-icon.svg';
// import WorkflowSVG from '../../../assets/svg/workflow-widget-icon.svg';
// import { useGetPerformanceSummary } from '../../../redux/human-resources/hook/performance';
import UnauthorizedPage from "../../errors/unauthorized";
import { useSelector } from "react-redux";
import PerformanceRatingsTab from "../../../components/hr-section/performance/performance-ratings";
import PerformanceOKRTab from "../../../components/hr-section/performance/okr";
import PerformanceSmartGoalsTab from "../../../components/hr-section/performance/smart-goals";
import PerformanceTechnicalAppraisalsTab from "../../../components/hr-section/performance/technical-appraisal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PerformanceBalancedScorecardTab from "../../../components/hr-section/performance/balanced-scorecard";

const HRPerformancePage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.performance_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);
  const [showAddWorkflow, setShowAddWorkflow] = useState(false);
  const [showEvaluatePerformance, setShowEvaluatePerformance] = useState(false);
  // const { data: getPerformanceSummary, isLoading: summaryLoading } = useGetPerformanceSummary();
  // const [performanceSummary, setPerformanceSummary] = useState([]);

  const [currentLocation, setCurrentLocation] = useState("evaluation");

  const location = useLocation();

  useEffect(() => {
    if (location) {
      setCurrentLocation(location.pathname.split("/")[2]);
    }
  }, [location]);

  // useEffect(() => {
  //   if (getPerformanceSummary) {
  //     setPerformanceSummary([
  //       {
  //         title: 'TOTAL EVALUATION',
  //         value: getPerformanceSummary.performance_evaluations,
  //         icon: PerformanceSVG,
  //       },
  //       {
  //         title: 'TOTAL WORKFLOW',
  //         value: getPerformanceSummary.workflows,
  //         icon: WorkflowSVG,
  //       },
  //     ]);
  //   } else {
  //     setPerformanceSummary([
  //       {
  //         title: 'TOTAL EVALUATION',
  //         value: 0,
  //         icon: PerformanceSVG,
  //       },
  //       {
  //         title: 'TOTAL WORKFLOW',
  //         value: 0,
  //         icon: WorkflowSVG,
  //       },
  //     ]);
  //   }
  // }, [getPerformanceSummary]);

  return (
    <DashboardLayout title="Performance Management">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {showEvaluatePerformance ? (
            <EvaluatePerformance
              isOpen={showEvaluatePerformance}
              closeModal={() => setShowEvaluatePerformance(false)}
              performanceData={null}
              title=" Evaluate Performance"
              buttonText="EVALUATE"
            />
          ) : null}

          {showAddWorkflow ? (
            <AddPerformanceWorkflow
              isOpen={showAddWorkflow}
              closeModal={() => setShowAddWorkflow(false)}
              groupData={null}
              title="Create Workflow"
              buttonText="Save Workflow"
            />
          ) : null}
          <div className="flex flex-col">
            {currentLocation === "evaluations" ? (
              <>
                {user?.permissions.performance_permission.performance_read ===
                  1 || user?.permissions.company_permission.role === "Owner" ? (
                  <HRPerformanceOverviewTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "basic-performance-criteria" ? (
              <>
                {user?.permissions.performance_permission.indicators_read ===
                  1 || user?.permissions.company_permission.role === "Owner" ? (
                  <PerformanceWorkflowTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "organisational-criteria" ? (
              <>
                {user?.permissions.performance_permission.workflow_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <PerformanceRatingsTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "okrs" ? (
              <>
                {user?.permissions.performance_permission.okr_read === 1 ||
                user?.permissions.company_permission.role === "Owner" ? (
                  <PerformanceOKRTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "smart-goals" ? (
              <>
                {user?.permissions.performance_permission.smart_goals_read ===
                  1 || user?.permissions.company_permission.role === "Owner" ? (
                  <PerformanceSmartGoalsTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "balanced-scorecard" ? (
              <>
                {user?.permissions.performance_permission.smart_goals_read ===
                  1 || user?.permissions.company_permission.role === "Owner" ? (
                  <PerformanceBalancedScorecardTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "technical-criteria" ? (
              <>
                {user?.permissions.performance_permission.technical_read ===
                  1 || user?.permissions.company_permission.role === "Owner" ? (
                  <PerformanceTechnicalAppraisalsTab />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : null}
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default HRPerformancePage;
