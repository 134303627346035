import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import FormSelectEmployee from '../../../employees/form-select-employee';
import {
  useGetExitWorkflow,
  useGetSelectExitWorkflows,
} from '../../../../redux/employees/hook/exit';
import AddSmallSVG from '../../../../assets/svg/add-icon-small.svg';
import FormSwitch from '../../../form-switch';
import Modal from '../../modal';
import { useTerminateProbationEmployee } from '../../../../redux/employees/hook/probation';
import AddExitWorkflow from '../employee-exit/add-workflow';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';

function ResolveTerminateProbation({ isOpen, closeModal, refresh, probation }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [workflowId, setWorkflowId] = useState(null);
  const { data: workflows, isLoading: workflowLoading } =
    useGetSelectExitWorkflows(1000, 1, '');

  const { data: workflow_info } = useGetExitWorkflow(workflowId);

  const {
    mutateAsync: terminateProbation,
    isLoading: terminateProbationLoading,
  } = useTerminateProbationEmployee();

  const [reason, setReason] = useState();

  const [showAddWorkFlow, setShowAddWorkflow] = useState(false);

  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();

  const [sendEmail, setSendEmail] = useState(false);
  const [body, setBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');

  useEffect(() => {
    if (probation) {
      setEmployeeName(probation.first_name + ' ' + probation.last_name);
      setEmployee(probation.employee_id);
    }
  }, [probation]);

  const questionOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];

  const [exitPortal, setExitPortal] = useState(false);
  const [assignworkflow, setAssignWorkflow] = useState(false);
  const [exitQuestion, setExitQuestion] = useState(0);
  const [leftDate, setLeftDate] = useState();
  const [noticeDate, setNoticeDate] = useState();
  const [daysToComplete, setDaysToComplete] = useState(1);

  const submitForm = async () => {
    let payload = {
      probation_id: probation?.id,
      workflow_id: workflowId ? workflowId : 0,
      employee_id: employee,
      notice_date: noticeDate,
      left_date: leftDate,
      employee_portal: exitPortal ? 1 : 0,
      reason: reason,
      days_to_complete: daysToComplete ? daysToComplete : 1,
      exit_question: exitQuestion,
      type: 'termination',
      send_email: sendEmail ? 1 : 0,
      email_subject: emailSubject,
      email_body: body.toString(),
    };
    terminateProbation(payload).then(() => {
      closeModal();
    });
  };

  return (
    <>
      {(!workflows && !workflowLoading) || showAddWorkFlow ? (
        <AddExitWorkflow
          title={'Add Exit Workfloww'}
          isOpen={showAddWorkFlow}
          buttonText='Save'
          closeModal={() => setShowAddWorkflow(false)}
        />
      ) : null}
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form' onSubmit={handleSubmit(submitForm)}>
            <div className='pl-8 pr-8 pt-4'>
              <div className='flex flex-row justify-between items-center'>
                <span className='header-4'>Terminate Probation Employee</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='close' />
                </div>
              </div>
            </div>
            <hr className='divider mb-2' />
            <div className='flex flex-col mx-8'>
              <div className='border bg-highlight p-2 rounded-md'>
                <FormSelectEmployee
                  label={'Employee - (ReadOnly)'}
                  name='employee'
                  placeholder='Type to select Employee'
                  value={employee}
                  readOnly={true}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
              <hr className='divider mt-2 mb-2' />
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:pr-2 md:w-1/2'>
                  <FormInput
                    label='Notice Date'
                    name='notice_date'
                    type='date'
                    value={noticeDate}
                    onInput={(e) => {
                      if (moment(leftDate).isBefore(e.target.value)) {
                        setLeftDate(
                          moment(e.target.value, 'YYYY-MM-DD').format(
                            'YYYY-MM-DD',
                          ),
                        );
                      }
                      setNoticeDate(e.target.value);
                    }}
                    inputRef={register(formValidation('date', true))}
                    error={errors.notice_date}
                    errorMessage={
                      errors.notice_date && errors.notice_date.message
                    }
                  />
                </div>
                <div className='w-full md:pl-2 md:w-1/2'>
                  <FormInput
                    label='Resignation Date'
                    name='resignation_date'
                    type='date'
                    min={moment(noticeDate).format('YYYY-MM-DD')}
                    value={leftDate}
                    onInput={(e) => {
                      setLeftDate(e.target.value);
                    }}
                    inputRef={register(formValidation('date', true))}
                    error={errors.resignation_date}
                    errorMessage={
                      errors.resignation_date && errors.resignation_date.message
                    }
                  />
                </div>
              </div>
              <FormInput
                label={'Termination Reason'}
                placeholder='Enter reason'
                name='reason'
                type='text'
                value={reason}
                onInput={(e) => {
                  setReason(e.target.value);
                }}
                error={errors.description}
                errorMessage={errors.description && errors.description.message}
              />
              <hr className='divider mt-2 ' />
              <>
                <div className='flex flex-row justify-between items-center md:w-[100%] w-full'>
                  <div className='w-full md:w-[100%] text-[14px] -mb-3'>
                    <FormSwitch
                      label={
                        'Do you want to grant employee access to exit portal? (This cannot be reversed)'
                      }
                      name={'exit_portal'}
                      checked={exitPortal}
                      onClick={() => {
                        if (!exitPortal === true) {
                          setAssignWorkflow(true);
                        }
                        setExitPortal(!exitPortal);
                      }}
                    />
                  </div>
                </div>
                <hr className='divider mt-2 ' />
                {exitPortal ? (
                  <div className='flex flex-col'>
                    <div className='flex flex-row md:flex-col w-full justify-between duration-500'>
                      <div className='w-full md:w-1/2 md:pr-2'>
                        <FormSelect
                          value={exitQuestion}
                          options={questionOptions}
                          onChange={(selected) => {
                            setExitQuestion(selected);
                          }}
                          label='Assign Exit Questions?'
                        />
                      </div>

                      <div className='w-full md:w-1/2 md:pl-2'>
                        <FormInput
                          label='Days to complete'
                          name='days_to_complete'
                          type='number'
                          value={daysToComplete}
                          onInput={(e) => {
                            setDaysToComplete(e.target.value);
                          }}
                          error={errors.days_to_complete}
                          errorMessage={
                            errors.days_to_complete &&
                            errors.days_to_complete.message
                          }
                        />
                      </div>
                    </div>
                    <div className='p-2 px-3 border border-gray-300 bg-gray-100 rounded text-justify'>
                      <span className='font-bold'>Info: </span>
                      <span className='text-[13px] text-justify'>
                        You have to assign an exit workflow and select a
                        workflow to grant portal access to employee
                      </span>
                    </div>
                    <hr className='divider' />
                  </div>
                ) : null}
                <div className='flex flex-row justify-between items-center md:w-[100%] w-full'>
                  <div className='w-full md:w-[100%] text-[14px] -mb-3'>
                    <FormSwitch
                      label={'Do you want to assign an exit workflow?'}
                      name={'assign_workflow'}
                      checked={assignworkflow}
                      onClick={() => {
                        if (!assignworkflow === false) {
                          setWorkflowId(null);
                        }
                        setAssignWorkflow(!assignworkflow);
                      }}
                    />
                  </div>
                </div>
                <hr className='divider mt-2 ' />
                {assignworkflow ? (
                  <div className='flex flex-col md:flex-col w-full justify-center items-center'>
                    {workflows ? (
                      <div className='w-full'>
                        <FormSelect
                          value={workflowId}
                          options={workflows}
                          onChange={(selected) => {
                            setWorkflowId(selected);
                          }}
                          label='Exit Workflows - (optional)'
                        />
                      </div>
                    ) : (
                      <div className='p4-medium text-[14px] mt-2 text-gray-500 font-semibold'>
                        No Exit workflow found. Click link below to add
                        workflow.
                        <hr className='divider' />
                      </div>
                    )}
                    <div className='flex gap-2 items-center justify-center w-full'>
                      <img
                        src={AddSmallSVG}
                        alt={'add_workflow'}
                        className={'h-[15px] w-[15px]'}
                      />
                      <span
                        className='p2-medium text-[#0d5225] cursor-pointer hover:underline duration-75 text-[14px] italic'
                        onClick={() => setShowAddWorkflow(true)}
                      >
                        Add New Workflow
                      </span>
                    </div>
                    <hr className='divider' />

                    {workflowId && workflow_info ? (
                      <div className='grid md:grid-cols-1 grid-cols-1 gap-4 w-full border-2 p-2 rounded mt-2'>
                        <div className='flex flex-col'>
                          {workflow_info?.sub_workflows.map((item, index) => (
                            <>
                              <div className='flex flex-row justify-around flex-wrap'>
                                <div className='flex flex-row items-center justify-between md:w-1/2 w-full'>
                                  <div className='flex flex-row gap-2 items-center'>
                                    <FormInput
                                      name={index + '_stage_name'}
                                      label={'Exit stage ' + (index + 1)}
                                      placeholder={'Stage Name'}
                                      type='text'
                                      readOnly
                                      value={item.name}
                                      className='!h-[30px] md:w-[200px] w-[225px] text-[13px] border-1 border-gray-300'
                                    />
                                  </div>
                                </div>
                                <div className='flex flex-row gap-2 items-center md:w-1/2'>
                                  <FormInput
                                    name={index + '_stage_description'}
                                    label={'Stage Description'}
                                    placeholder={'Stage Description'}
                                    type='text'
                                    readOnly
                                    value={item.description}
                                    className='!h-[30px] md:w-[200px] w-[225px] text-[13px] border-1 border-gray-300'
                                  />
                                </div>
                              </div>
                              <div className='flex flex-col justify-around flex-wrap -mt-2'>
                                <div className='flex flex-row  justify-between items-center md:w-[100%] w-full'>
                                  <div className='w-full md:w-[60%] mt-2 text-[13px]'>
                                    <FormSwitch
                                      label={'Employee Can Remark?'}
                                      name={'switch-edit-mode'}
                                      readOnly
                                      checked={
                                        item.employee_can_remark == 1
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr className='divider -mt-2' />
                            </>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className='italic text-center text-gray-500 p-4 flex items-center justify-center border bg-secondary-2-extralight w-full rounded-md text-[14px]'>
                        Exit stages not loaded
                      </div>
                    )}
                  </div>
                ) : null}
              </>
              <hr className='divider mt-2 mb-2' />
              <div
                className={
                  'flex w-full flex-col gap-2 px-3 border bg-highlight rounded-md pt-4'
                }
              >
                <div className=''>
                  <FormSwitch
                    label={
                      'Do you want to send Employment termination notification email to employee and team leads?'
                    }
                    name={'send_email'}
                    checked={sendEmail}
                    onClick={() => setSendEmail(!sendEmail)}
                    value={sendEmail}
                  />
                </div>
                {sendEmail ? (
                  <div className='flex flex-col'>
                    <hr className='divider -mt-2 mb-1' />
                    <FormInput
                      label='Subject'
                      name='subject'
                      type='text'
                      value={emailSubject}
                      placeholder={'Email Subject'}
                      onInput={(e) => {
                        setEmailSubject(e.target.value);
                      }}
                    />
                    <div className='flex md:flex-row flex-col w-full justify-between mb-2'>
                      <div className='w-full'>
                        <span className='text-[13px] text-gray-500'>
                          PIP Email Body
                        </span>
                        <Editor
                          apiKey={import.meta.env.VITE_APP_TINYMCE_API_KEY}
                          onEditorChange={(editor) => {
                            setBody(editor);
                          }}
                          value={body}
                          init={{
                            height: 300,
                            menubar: false,
                            placeholder: 'Enter Email body here',
                            selector: 'textarea',
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | ' +
                              'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help',
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='w-full mt-[10px] pb-4 '>
                <Button
                  text={'save and close'}
                  type='submit'
                  loading={terminateProbationLoading}
                  disabled={false}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default ResolveTerminateProbation;
