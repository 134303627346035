import React, { useEffect, useState } from "react";
import { useGetCurrentAttendanceInformation } from "../../../redux/human-resources/hook/attendance";
import {
  PAGINATION_DEFAULT,
  punctualityStatusConfig,
} from "../../../utils/constants";
import DataTable from "../../datatable";

const HRAttendanceAnalyticsTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.page,
    search: "",
    attendance_date: "",
  });
  const { data: currentAttendance, isLoading: currentAttendanceLoading } =
    useGetCurrentAttendanceInformation(
      pagination?.limit,
      pagination?.page,
      pagination?.statusFilter,
      pagination?.search,
      pagination?.attendance_date
    );

  const tableConfig = {
    headers: [
      "Employee Name",
      "Mobile",
      "Clockin Location",
      "Clock In",
      "Clock Out",
      "Punctuality Status",
    ],
    keys: [
      "employee_name",
      "mobile",
      "clockin_location",
      "clockin_time",
      "clockout_time",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employee_name",
      },
      right: {
        title: "Punctuality Status",
        key: "status",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "green",
      action: (id) => {
        let index = attendance_schedules.data.findIndex(
          (item) => item.id === id
        );
        setScheduleData(attendance_schedules.data[index]);
        setTitle("Edit Attendance Schedule");
        setShowAddAttendanceSchedule(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = attendance_schedules.data.findIndex(
          (item) => item.id === id
        );
        deleteSchedule(attendance_schedules.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="flex flex-col bg-white mt-[10px] rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Daily Attendance
          </div>
        </div>
        <hr className="divider mt-2 mb-2" />
        <DataTable
          data={currentAttendance}
          loading={currentAttendanceLoading}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={punctualityStatusConfig}
          updatePagination={(data) => setPagination(data)}
          computedActions={true}
          attendance={true}
          searchData={true}
          filterBySingleDate={true}
          // filterByDate={true}
        />
      </div>
    </div>
  );
};

export default HRAttendanceAnalyticsTab;
