import { useQuery } from '@tanstack/react-query';
import { hrDashboard } from '../../api/hr/dashboard';
import { dashboard } from '../../api/payroll/dashboard';
import { spendDashboard } from '../../api/spend/dashboard';

export function useGetDashboard() {
  return useQuery(['dashboard'], () => {
    return dashboard.getDashboard();
  });
}

export function useGetHRDashboard() {
  return useQuery(['hr-dashboard'], () => {
    return hrDashboard.getDashboard();
  });
}

export function useGetSpendDashboard() {
  return useQuery(['spend-dashboard'], () => {
    return spendDashboard.getDashboard();
  });
}
