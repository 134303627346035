import { Axios } from "../axios";

const getEmployees = async (payload) => {
  let search = payload.search ? "&search=" + payload.search : "";
  let filter =
    payload.statusFilter && payload.statusFilter != -1
      ? "&filter=" + payload.statusFilter
      : "";

  const { data } = await Axios.get(
    `/employees?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
    {
      timeout: 0,
    }
  );

  return data?.data;
};

const getEmployeeForSalary = async () => {
  const { data } = await Axios.get(`/employees/for-salary`, {
    timeout: 0,
  });

  return data?.data;
};

const updateEmployee = async (payload) => {
  const { data } = await Axios.put(
    "/employees/" + payload.employeeId,
    payload.data
  );
  return data;
};

const updateEmployeeBank = async (payload) => {
  const { data } = await Axios.put(
    "/employees/update-bank/" + payload.employeeId,
    payload.data
  );
  return data;
};

const updateInvitedEmployee = async (payload) => {
  const { data } = await Axios.put(
    "/employee/update-employee/" + payload.employeeId,
    payload.data
  );
  return data;
};

const employeeUploadProfilePicture = async (payload) => {
  const { data } = await Axios.post("/employee/upload-profile-photo", payload);
  return data;
};

const uploadProfilePicture = async (payload) => {
  const { data } = await Axios.post("/employees/profile-photo", payload);
  return data;
};

const getEmployee = async (payload) => {
  const { data } = await Axios.get("/employees/" + payload);
  return data?.data;
};

const getEmployeeRegistrationLink = async () => {
  const { data } = await Axios.get("/employees/get-pre-registration-link");
  return data?.data;
};

const getEmployeeSummary = async () => {
  const { data } = await Axios.get("/employees/summary");
  return data?.data;
};

const getEmployeeReport = async (payload) => {
  const { data } = await Axios.get(
    `/employees/report/${payload.start_date}/${payload.end_date}`
  );
  return data?.data;
};

const inviteSingleEmployee = async (payload) => {
  const { data } = await Axios.post("/employees/invite", payload, {
    timeout: 0,
  });
  return data;
};

const inviteBulkEmployee = async (payload) => {
  const { data } = await Axios.post("employees/inivite/bulk", payload, {
    headers: { "Content-Type": "multipart/form-data" },
    timeout: 0,
  });
  return data;
};

const createSingleEmployee = async (payload) => {
  const { data } = await Axios.post("/employees", payload, {
    timeout: 0,
  });
  return data;
};

const preRegisterEmployee = async (payload) => {
  const { data } = await Axios.post(
    "/employee/pre-register-employee",
    payload,
    {
      timeout: 0,
    }
  );
  return data;
};

const submitOfferLetter = async (payload) => {
  const { data } = await Axios.post("/upload-signed-offer-letter", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
    timeout: 0,
  });
  return data;
};

const createBulkEmployee = async (payload) => {
  const { data } = await Axios.post("/employees/bulk", payload, {
    headers: {
      "Content-Type": `multipart/form-data'; boundary=${payload._boundary}`,
    },
    timeout: 6000000,
  });
  return data;
};

const addEmployeeGroup = async (payload) => {
  const { data } = await Axios.post("/employee-groups", payload);
  return data;
};

const refreshInvitationLink = async () => {
  const { data } = await Axios.post("/employees/refresh-pre-registration-link");
  return data;
};

const changeInvitationLinkSatus = async (payload) => {
  const { data } = await Axios.post(
    "/employees/update-pre-invitation-link-status"
  );
  return data;
};

const netPayReverse = async (payload) => {
  const { data } = await Axios.post("/salaries/net-pay-reverse", payload, {
    timeout: 0,
  });
  return data;
};

const fullPayReverseBulk = async (payload) => {
  const { data } = await Axios.post("/salaries/bulk", payload, {
    headers: { "Content-Type": "multipart/form-data" },
    timeout: 0,
  });
  return data;
};

const netPayReverseBulk = async (payload) => {
  const { data } = await Axios.post("/salaries/net-pay-reverse/bulk", payload, {
    headers: { "Content-Type": "multipart/form-data" },
    timeout: 0,
  });
  return data;
};

const grossPayReverse = async (payload) => {
  const { data } = await Axios.post("/salaries/gross-pay-reverse", payload, {
    timeout: 0,
  });
  return data;
};

const grossPayReverseBulk = async (payload) => {
  const { data } = await Axios.post(
    "/salaries/gross-pay-reverse/bulk",
    payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 0,
    }
  );
  return data;
};

const bulkActivateEmployees = async (payload) => {
  const computedPayload = {
    employee_ids: payload,
  };

  const { data } = await Axios.post(
    "/employees/bulk-activation/activate",
    computedPayload
  );
  return data;
};

const bulkDeleteEmployee = async (payload) => {
  const { data } = await Axios.post("/employees/delete", payload);
  return data;
};

const deleteEmployee = async (payload) => {
  const { data } = await Axios.delete(`/employees/${payload}`);
  return data;
};

const activateOrDeactivatePendingEmployee = async (payload) => {
  const { data } = await Axios.post(
    `employees/pending/${payload.id}/${payload.type}`
  );
  return data;
};

const activateOrDeactivatePreEmployee = async (payload) => {
  const { data } = await Axios.post(
    `/employees/activate-pre-registered-employee/${payload.id}/${payload.type}`
  );
  return data;
};

const activateOrDeactivateEmployee = async (payload) => {
  const { data } = await Axios.post("/employees/activation/" + payload);
  return data;
};

const bulkDeactivateEmployees = async (payload) => {
  const computedPayload = {
    employee_ids: payload,
  };

  const { data } = await Axios.post(
    "/employees/bulk-activation/deactivate",
    computedPayload
  );
  return data;
};

const generateEmployeeOtp = async (payload) => {
  const { data } = await Axios.post("/employee/generate-pin", payload);
  return data;
};

const confirmEmployeeOtp = async (payload) => {
  const { data } = await Axios.post("/employee/confirm-pin", payload);
  return data;
};

const resendInviteEmail = async (payload) => {
  const { data } = await Axios.get(`employee/${payload}/resend-invitation`);
  return data;
};

const resendMobileRegistrationInviteEmail = async (payload) => {
  const { data } = await Axios.get(
    `/employees/${payload}/resend-mobile-invitation`
  );
  return data;
};

export const employees = {
  getEmployees,
  updateEmployee,
  updateEmployeeBank,
  getEmployeeSummary,
  getEmployeeReport,
  getEmployee,
  getEmployeeForSalary,
  getEmployeeRegistrationLink,
  inviteSingleEmployee,
  createBulkEmployee,
  inviteBulkEmployee,
  createSingleEmployee,
  preRegisterEmployee,
  updateInvitedEmployee,
  addEmployeeGroup,
  refreshInvitationLink,
  changeInvitationLinkSatus,
  netPayReverse,
  fullPayReverseBulk,
  netPayReverseBulk,
  grossPayReverse,
  grossPayReverseBulk,
  bulkActivateEmployees,
  activateOrDeactivateEmployee,
  bulkDeactivateEmployees,
  generateEmployeeOtp,
  confirmEmployeeOtp,
  activateOrDeactivatePendingEmployee,
  activateOrDeactivatePreEmployee,
  resendInviteEmail,
  bulkDeleteEmployee,
  deleteEmployee,
  uploadProfilePicture,
  employeeUploadProfilePicture,
  resendMobileRegistrationInviteEmail,
  submitOfferLetter,
};
