import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import Button from "../../button";
import {
  useDeleteBalancedScorecard,
  useGetPerformanceBalancedScorecards,
} from "../../../redux/human-resources/hook/performance";
import AddPerformanceBalancedScorecard from "../../modal/hr-section/performance/add-balanced-scorecard";

const PerformanceBalancedScorecardTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const [balanceScorecardData, setBalancedScorecardData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [showAddBalancedScorecard, setShowAddBalancedScorecard] =
    useState(null);
  const [type, setType] = useState(null);

  const { mutateAsync: deleteBalanceScorecard } = useDeleteBalancedScorecard();

  const { data: balanceScorecards } = useGetPerformanceBalancedScorecards(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const tableConfig = {
    headers: [
      "Title",
      "Source",
      "Source Name",
      // 'Evaluation Frequency',
      // 'Auto-evaluate'
    ],
    keys: [
      "title",
      "source",
      "source_name",
      // 'evaluation_frequency',
      // 'auto_evaluate_converted',
    ],
    mobileHeader: {
      left: {
        title: "Title",
        key: "title",
      },
      right: {
        title: "Source Name",
        key: "source_name",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Update",
      color: "green",
      action: (id) => {
        let index = balanceScorecards.data.findIndex((item) => item.id === id);
        setTitle("Update Balanced Scorecard");
        setButtonText("Update & Close");
        setType("edit");
        setBalancedScorecardData(balanceScorecards.data[index]);
        setShowAddBalancedScorecard(true);
      },
    },
    {
      name: "Duplicate",
      color: "orange",
      action: (id) => {
        let index = balanceScorecards.data.findIndex((item) => item.id === id);
        setTitle("Duplicate Balanced Scorecard");
        setType("duplicate");
        setButtonText("Duplicate & Close");
        setBalancedScorecardData(balanceScorecards.data[index]);
        setShowAddBalancedScorecard(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = balanceScorecards.data.findIndex((item) => item.id === id);
        deleteBalanceScorecard(balanceScorecards.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col gap-4 -mt-3">
      {showAddBalancedScorecard ? (
        <AddPerformanceBalancedScorecard
          isOpen={showAddBalancedScorecard}
          closeModal={() => setShowAddBalancedScorecard(false)}
          balanceScorecardData={balanceScorecardData}
          type={type}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col">
        <div className=" bg-white border rounded-md text-[12px] p-2 text-gray-600">
          <span className="font-bold text-[14px]">Balanced Scorecard</span> is a
          strategic management framework developed by Robert S. Kaplan and David
          P. Norton in the early 1990s. It's designed to translate an
          organization's mission and vision into specific objectives and
          performance measures across four key perspectives: financial,
          customer, internal business processes, and learning and growth.
          Balanced Scorecard is assessed by employee and unit manager. It is
          then reviewd and approved by the HR manager.
        </div>
      </div>
      <div className="flex flex-col bg-white rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Balanced Scorecards List
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Balanced Scorecard"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => {
                  setShowAddBalancedScorecard(true);
                  setBalancedScorecardData(null);
                  setTitle("Add Balanced Scorecard");
                  setButtonText("Save & Close");
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={balanceScorecards}
          tableConfig={tableConfig}
          updatePagination={(data) => setPagination(data)}
          actionConfig={actionConfig}
          computedActions={true}
          searchData={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default PerformanceBalancedScorecardTab;
