import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import {
  useAddAttendanceSchedule,
  useUpdateAttendanceSchedule,
} from '../../../../redux/human-resources/hook/attendance';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormSelectBranch from '../../../employees/form-select-branch';
import FormSelectDepartment from '../../../employees/form-select-department';
import FormSelectEmployee from '../../../employees/form-select-employee';
import FormSelectTeam from '../../../employees/form-select-team';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import Modal from '../../modal';

function AddAttendanceSchedule({
  isOpen,
  closeModal,
  title,
  scheduleData,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
  const [sourceName, setSourceName] = useState(null);
  const [source, setSource] = useState('employee');
  const [sourceId, setSourceId] = useState(null);

  const [scheduleName, setScheduleName] = useState('');
  const [clockIn, setClockIn] = useState('');
  const [clockOut, setClockOut] = useState('');
  const [scheduleDate, setScheduleDate] = useState('');
  const [workingDay, setWorkingDay] = useState('yes');

  const workingDayOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];

  const [scheduleType, setScheduleType] = useState('add');

  useEffect(() => {
    if (scheduleData) {
      // setClockType(scheduleData.clock_type)
      setSource(scheduleData.source);
      setSourceId(scheduleData.source_id);
      if (source === 'employee') {
        setEmployee(scheduleData.source_id);
      } else if (source === 'team') {
        setTeam(scheduleData.source_id);
      } else if (source === 'department') {
        setDepartment(scheduleData.source_id);
      } else if (source === 'branch') {
        setBranch(scheduleData.source_id);
      }
      setScheduleName(scheduleData.schedule_name);
      setClockIn(scheduleData.clockin_time);
      setClockOut(scheduleData.clockout_time);
      setScheduleDate(scheduleData.schedule_date);
      setScheduleType('edit');
      setWorkingDay(scheduleData.working_day);
    } else {
      setScheduleType('add');
    }
  }, [scheduleData]);

  const sourceOptions = [
    { label: 'Employee', value: 'employee' },
    { label: 'Team', value: 'team' },
    { label: 'Department', value: 'department' },
    { label: 'Branch', value: 'branch' },
    { label: 'Company', value: 'company' },
  ];

  useEffect(() => {
    if (workingDay !== 'yes') {
      setClockIn('');
      setClockOut('');
    }
  }, [workingDay]);

  const [employee, setEmployee] = useState();
  const [team, setTeam] = useState();
  const [department, setDepartment] = useState();
  const [branch, setBranch] = useState();

  const { mutateAsync: addSchedule, isLoading: addScheduleloading } =
    useAddAttendanceSchedule();
  const { mutateAsync: editSchedule, isLoading: editScheduleloading } =
    useUpdateAttendanceSchedule();

  const submitForm = async (data) => {
    const schedule_name = DOMPurify.sanitize(data?.schedule_name);
    let source_id = null;
    if (source === 'employee') {
      source_id = employee;
    } else if (source === 'team') {
      source_id = team;
    } else if (source === 'department') {
      source_id = department;
    } else if (source === 'branch') {
      source_id = branch;
    }

    const working_day = workingDay;
    const clockin_time = DOMPurify.sanitize(data?.clock_in);
    const clockout_time = DOMPurify.sanitize(data?.clock_out);

    const schedule_date = DOMPurify.sanitize(data?.schedule_date);

    let payoad = {
      schedule_name,
      source,
      source_id,
      working_day,
      clockin_time,
      clockout_time,
      schedule_date,
    };

    if (scheduleType === 'add') {
      addSchedule(payoad).then(() => {
        closeModal();
      });
    } else {
      let editRequest = {
        schedule_id: scheduleData?.id,
        data: payoad,
      };
      editSchedule(editRequest).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <div className='flex flex-col gap-1'>
                <div className='header-4'>{title}</div>
                <div className='text-color-gray text-[13px]'>
                  Add Attendance Schedule to override the default attendance
                  settings
                </div>
              </div>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
            
          </div>
          <hr className='divider mb-2 mt-2' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-row md:flex-col w-full justify-between p-2 border rounded-md bg-highlight'>
              <div className='w-full md:w-1/2 pr-2'>
                <FormSelect
                  defaultValue={source}
                  options={sourceOptions}
                  onChange={(selected) => {
                    setSource(selected);
                    setSourceId(null);
                  }}
                  label='Schedule For:'
                  extraClass={'bg-white'}
                />
              </div>
              <div className='w-full md:w-1/2 pl-2'>
              {!source || source === 'company' ? null : source === 'employee' ? (
              <FormSelectEmployee
                  label={'Employee'}
                  name='employee'
                  placeholder='Select Employee'
                  value={employee}
                  empName={sourceName}
                  setSourceName={setSourceName}
                  setEmployee={setSourceId}
              />
              ) : source === 'team' ? (
              <FormSelectTeam
                  label={'Select Team'}
                  name='team'
                  placeholder='Select Team'
                  value={team}
                  teamName={sourceName}
                  setSourceName={setSourceName}
                  setTeam={setSourceId}
              />
              ) : source === 'department' ? (
              <FormSelectDepartment
                  label={'Department'}
                  name='department'
                  placeholder='Select Department'
                  value={department}
                  departmentName={sourceName}
                  setSourceName={setSourceName}
                  setDepartment={setSourceId}
              />
              ) : (
              <FormSelectBranch
                  label={'Branch'}
                  name='branch'
                  placeholder='Select Branch'
                  value={branch}
                  branchName={sourceName}
                  setSourceName={setSourceName}
                  setBranch={setSourceId}
              />
              )}
              </div>
            </div>
            <hr className='divider mb-2 mt-2' />
            <div>
              <div className='w-full'>
                <FormInput
                  label='Schedule Name'
                  name='schedule_name'
                  placeholder='Enter the schedule name'
                  type='text'
                  defaultValue={scheduleName}
                  onInput={(e) => {
                    setScheduleName(e.target.value);
                  }}
                  inputRef={register(formValidation('text', true))}
                  error={errors.schedule_name}
                  errorMessage={
                    errors.schedule_name && errors.schedule_name.message
                  }
                />
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 pr-2'>
                  <FormInput
                    label='Schedule Date'
                    name='schedule_date'
                    type='date'
                    defaultValue={scheduleDate}
                    onInput={(e) => {
                      setScheduleDate(e.target.value);
                    }}
                    inputRef={register(formValidation('date', true))}
                    error={errors.schedule_name}
                    errorMessage={
                      errors.schedule_name && errors.schedule_name.message
                    }
                  />
                </div>
                <div className='w-full md:w-1/2 pl-2 '>
                  <FormSelect
                    defaultValue={workingDay}
                    options={workingDayOptions}
                    onChange={(selected) => {
                      setWorkingDay(selected);
                    }}
                    label='Working Day?'
                  />
                </div>
              </div>
              {workingDay === 'yes' ? (
                <div className='flex flex-row md:flex-col w-full justify-between'>
                  <div className='w-full md:w-1/2 pr-2'>
                    <FormInput
                      label='Resumption Time'
                      name='clock_in'
                      type='time'
                      defaultValue={clockIn}
                      onInput={(e) => {
                        setClockIn(e.target.value);
                      }}
                      inputRef={register(formValidation('number', true))}
                      error={errors.clock_in}
                      errorMessage={errors.clock_in && errors.clock_in.message}
                    />
                  </div>
                  <div className='w-full md:w-1/2 pl-2'>
                    <FormInput
                      label='Closing Time'
                      name='clock_out'
                      type='time'
                      defaultValue={clockOut}
                      onInput={(e) => {
                        setClockOut(e.target.value);
                      }}
                      inputRef={register(formValidation('time', true))}
                      error={errors.clock_out}
                      errorMessage={errors.amount && errors.clock_out.message}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className='w-full mt-[15px]'>
              <Button
                text={buttonText}
                type='submit'
                loading={addScheduleloading || editScheduleloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddAttendanceSchedule;
