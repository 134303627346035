import { Axios } from '../axios';

const addDepartment = async (payload) => {
  const { data } = await Axios.post('/departments', payload);
  return data;
};

const getDepartments = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  const { data } = await Axios.get(
    `/departments?limit=${payload.limit}&page=${payload.page}${search}`,
  );
  return data;
};

const getDepartmentSummary = async () => {
  const { data } = await Axios.post('/departments/summary');
  return data?.data;
};

const updateDepartment = async (payload) => {
  const { data } = await Axios.put('/departments/' + payload.id, payload.data);
  return data;
};

const getDepartment = async (payload) => {
  const { data } = await Axios.get('/departments/' + payload.id, payload);
  return data?.data;
};

const deleteDepartment = async (payload) => {
  const { data } = await Axios.delete(`/departments/${payload}`);
  return data;
};

const getDepartmentEmployees = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(`/departments/${payload.id}?limit=${payload.limit}&page=${payload.page}${search}${filter}`);
  return data?.data;
};

const addEmployeesToDepartment = async (payload) => {
  const { data } = await Axios.post(
    '/department/add-multiple-employees',
    payload,
  );
  return data;
};

const removeEmployeesFromDepartment = async (payload) => {
  const { data } = await Axios.post(
    '/department/remove-multiple-employees',
    payload,
  );
  return data;
};

const moveEmployeesToDepartment = async (payload) => {
  const { data } = await Axios.post('/department/move-employee', payload);
  return data;
};

const getDepartmentTeams = async (payload) => {
  const { data } = await Axios.get(`/department/teams/${payload.id}?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`);
  return data?.data;
};

const addTeamsToDepartment = async (payload) => {
  const { data } = await Axios.post('/department/add-multiple-teams', payload);
  return data;
};

const removeTeamsFromDepartment = async (payload) => {
  const { data } = await Axios.post(
    '/department/remove-multiple-teams',
    payload,
  );
  return data;
};

const moveTeamsToDepartment = async (payload) => {
  const { data } = await Axios.post('/department/move-multiple-teams', payload);
  return data;
};

const getDepartmentSettings = async (payload) => {
  const { data } = await Axios.get('/departments/' + payload);
  return data?.data;
};

export const departments = {
  addDepartment,
  getDepartments,
  getDepartmentSummary,
  updateDepartment,
  getDepartment,
  deleteDepartment,
  getDepartmentEmployees,
  addEmployeesToDepartment,
  removeEmployeesFromDepartment,
  moveEmployeesToDepartment,
  getDepartmentTeams,
  addTeamsToDepartment,
  removeTeamsFromDepartment,
  moveTeamsToDepartment,
  getDepartmentSettings,
};
