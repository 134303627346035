import React, { useState, useEffect } from "react";
import DataTable from "../../datatable";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { useParams } from "react-router-dom";
import {
  useDeleteSuspension,
  useSingleGetEmployeeSuspensions,
} from "../../../redux/employees/hook/suspensions";
import AddSuspension from "../../modal/employees/add-suspension";
import Button from "../../button";

const SingleEmployeeSuspensionsTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
  });

  const { id } = useParams();

  const { data: employeeSuspensions } = useSingleGetEmployeeSuspensions(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const [showAddSuspension, setShowAddSuspension] = useState(false);
  const [suspData, setSuspData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const { mutateAsync: deleteSuspension } = useDeleteSuspension();

  const tableConfig = {
    // title: 'Suspension Management',
    headers: [
      "Employee Name",
      "Purpose",
      "Start Date",
      "End Date",
      "Payment Status",
    ],
    keys: [
      "employeeName",
      "purpose",
      "formatted_start_date",
      "formatted_end_date",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Payment Status",
        key: "status",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "green",
      action: (id) => {
        let index = employeeSuspensions.data.findIndex(
          (item) => item.id === id
        );
        setSuspData(employeeSuspensions.data[index]);
        setButtonText("Update");
        setTitle("Update Suspension");
        setShowAddSuspension(true);
      },
    },
    {
      name: "Delete",
      color: "orange",
      action: (id) => {
        let index = employeeSuspensions.data.findIndex(
          (item) => item.id === id
        );
        deleteSuspension(employeeSuspensions.data[index].id);
      },
    },
  ];

  const statusConfig = [
    {
      label: "Without Pay",
      value: 0,
      color: "orange",
    },
    {
      label: "With Pay",
      value: 1,
      color: "green",
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddSuspension ? (
        <AddSuspension
          isOpen={showAddSuspension}
          suspData={suspData}
          closeModal={() => {
            setShowAddSuspension(false);
          }}
          title={title}
          buttonText={buttonText}
        />
      ) : null}

      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Employee Suspensions
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Suspend Employee"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"suspension"}
              onClick={() => {
                setSuspData(null);
                setButtonText("Save");
                setTitle("Suspend Employee");
                setShowAddSuspension(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-2" />
      <DataTable
        data={employeeSuspensions}
        tableConfig={tableConfig}
        statusConfig={statusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        computedActions={true}
        noCheck={true}
      />
    </div>
  );
};

export default SingleEmployeeSuspensionsTab;
