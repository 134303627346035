import DOMPurify from "dompurify";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../components/button";
import FormFileInput from "../../../components/form-file-input";
import FormInput from "../../../components/form-input";
import FormSelect from "../../../components/form-select";
import FormTextArea from "../../../components/form-textarea";
import SingleRecruitmentApplicationLayout from "../../../components/layouts/recruitment-application";
import ConfirmationModal from "../../../components/modal/confirmation-modal";
import { useAddRecruitmentApplication } from "../../../redux/human-resources/hook/recruitment";
import { formValidation, Initials } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import CollapsingIcon from "../../../components/collapsing-icon";
import classNames from "classnames";
import { Collapse } from "reactstrap";
import EmptyTableSVG from "../../../assets/svg/empty-table.svg";
import Logo from "../../../components/logo";
import moment from "moment";
import { setAlert } from "../../../redux/components/components-slice";

function ApplicationPage() {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const dispatch = useDispatch();
  const { token } = useParams();

  // const {data: recruitmentBrief, isLoading} = useGetRecruitmentByToken(token)
  const { recruitmentBrief } = useSelector((state) => state.recruitmentSlice);

  const [expired, setExpired] = useState(true);

  useEffect(() => {
    if (recruitmentBrief) {
      const dateB = moment();
      const dateC = moment(recruitmentBrief?.application_end);
      if (dateC.diff(dateB, "days") < 0) {
        setExpired(true);
      } else {
        setExpired(false);
      }
    }
  }, [recruitmentBrief]);

  useEffect(() => {
    if (recruitmentBrief && recruitmentBrief.questions) {
      let newResults = [];
      recruitmentBrief.questions.forEach((item) => {
        let newResult = {
          question_id: item.id,
          question: item.question,
          answer: "",
          question_type: item.question_type,
          options: item.options,
        };
        newResults.push(newResult);
      });
      setResult(newResults);
    }
  }, [recruitmentBrief]);

  const [result, setResult] = useState([]);

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
  const [gender, setGender] = useState("Male");
  const [maritalStatus, setMaritalStatus] = useState("married");
  const [yearsOfExperience, setYearsOfExperience] = useState(1);
  const [linkedin, setLinkedIn] = useState();
  const [twitter, setTwitter] = useState();
  const [facebook, setFacebook] = useState();
  const [portfolioUrl, setPortfolioUrl] = useState();
  const [personalWebsite, setPersonalWebsite] = useState();
  // const [closed, setClosed] = useState(false)

  const [showRecruitmentResults, setShowRecruitmentResults] = useState();

  const [display, setDisplay] = useState("apply");
  const [mainDisplay, setMainDisplay] = useState("application");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { mutateAsync: addApplication, isLoading: addApplicationLoading } =
    useAddRecruitmentApplication();

  const history = useHistory();

  const genderOptions = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
  ];

  const maritalOptions = [
    {
      label: "Single",
      value: "single",
    },
    {
      label: "Married",
      value: "married",
    },
  ];

  const [educationLevel, setEducationLevel] = useState("high_school");

  const educationLevelOptions = [
    { value: "High School", label: "High School" },
    { value: "Diploma", label: "Diploma" },
    { value: "Degree", label: "Degree" },
    { value: "MSc.", label: "MSc." },
    { value: "MBA", label: "MBA" },
    { value: "PhD.", label: "PhD." },
  ];

  const [experienceLevel, setExperienceLevel] = useState("Entry");

  const experienceLevelOptions = [
    { value: "Entry", label: "Entry" },
    { value: "Intern", label: "Intern" },
    { value: "Mid-Level", label: "Mid-Level" },
    { value: "Senior-Level", label: "Senior-Level" },
    { value: "Management", label: "Management" },
  ];

  const submitForm = async (data) => {
    const formData = new FormData();
    if (data?.file[0].size / (1024 * 1024) < 2) {
      const first_name = DOMPurify.sanitize(data?.first_name);
      const last_name = DOMPurify.sanitize(data?.last_name);
      const email = DOMPurify.sanitize(data?.email);
      const mobile = DOMPurify.sanitize(data?.mobile);
      const years_of_experience = DOMPurify.sanitize(data?.years_of_experience);
      const linkedin = DOMPurify.sanitize(data?.linkedin);
      const twitter = DOMPurify.sanitize(data?.twitter);
      const facebook = DOMPurify.sanitize(data?.facebook);
      const portfolio_url = DOMPurify.sanitize(data?.portfolio_url);
      const personal_website = DOMPurify.sanitize(data?.personal_website);

      formData.append("file", data?.file[0]);
      formData.append("token", token);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("gender", gender);
      formData.append("marital_status", maritalStatus);
      formData.append("min_education", educationLevel);
      formData.append("years_of_experience", years_of_experience);
      formData.append("experience_level", experienceLevel);
      formData.append("linkedin", linkedin);
      formData.append("twitter", twitter);
      formData.append("facebook", facebook);
      formData.append("portfolio_url", portfolio_url);
      formData.append("personal_website", personal_website);
      formData.append("answers", JSON.stringify(result));

      await addApplication(formData).then((result) => {
        if (result && result.status === "success") {
          setShowConfirmationModal(true);
        }
      });
    } else {
      dispatch(
        setAlert(
          true,
          "warning",
          "File too large. File should not be larger than 2mb"
        )
      );
    }
  };

  const goToHomePage = () => {
    window.location.replace("https://www.ropay.africa");
  };

  return (
    <SingleRecruitmentApplicationLayout
      step={mainDisplay === "summary" ? 4 : display === "apply" ? 2 : 3}
      company_name={recruitmentBrief?.company_name}
      logo={recruitmentBrief?.logo}
      expired={expired}
    >
      <ConfirmationModal
        isOpen={showConfirmationModal}
        title="Well Done !"
        subtitle={"Your application has been submitted successfully"}
        btnFunction={goToHomePage}
        closeModal={goToHomePage}
      />
      <div className="flex flex-col justify-center w-full md:pt-2 !min-h-screen">
        <>
          {expired ? (
            <div className="flex flex-col w-[100%] items-center justify-center p1-bold text-hr-primary-1 !min-h-screen">
              <div className="text-[25px] flex justify-center items-center h-fit w-full border-[3px] header-3 rounded-[60px] relative duration-500 text-white font-bold p-2 gap-2 mb-4">
                <div className="text-[25px] flex justify-center items-center h-[60px] w-[60px] border-[3px] header-3 rounded-[60px] relative hover:m-2 duration-500 text-white font-bold gap-2 p-2">
                  {recruitmentBrief?.logo ? (
                    <div>
                      <img
                        src={recruitmentBrief?.logo}
                        alt="email_icon"
                        className="object-fit min-w-[52px] min-h-[52px] max-w-[52px] max-h-[52px]"
                      />
                    </div>
                  ) : (
                    <span className="text-hr-primary-1 font-bold text-[30px]">
                      {Initials(recruitmentBrief?.company_name)}
                    </span>
                  )}
                </div>
                <span className="text-hr-primary-1 header-2 mb-2">
                  {recruitmentBrief?.company_name}
                </span>
              </div>
              Application for this role has closed!!!
            </div>
          ) : (
            <>
              <div>
                {recruitmentBrief ? (
                  <form
                    className="form !min-h-fit !min-w-full p-4 pl-4 pr-4"
                    onSubmit={handleSubmit(submitForm)}
                  >
                    <div className="w-full text-center flex flex-col ">
                      <span className="header-2-regular text-hr-primary-1">
                        {display === "apply" && mainDisplay !== "summary"
                          ? "Application Form"
                          : display === "question" && mainDisplay !== "summary"
                          ? "Application Questions"
                          : "Application Summary"}
                      </span>
                      <span className="header-4-regular text-hr-primary-1">
                        {display === "apply" && mainDisplay !== "summary"
                          ? "Fill the form to enter the recruitment process."
                          : display === "question" && mainDisplay !== "summary"
                          ? "Please answer some questions from you recruiter"
                          : "Review your application before submitting"}
                      </span>
                    </div>
                    <hr className="divider " />
                    <div className="flex flex-col min-h-fit mb-[10px] px-2">
                      <div
                        className={
                          mainDisplay !== "summary" && display === "apply"
                            ? "flex flex-col"
                            : "hidden"
                        }
                      >
                        <div className="flex md:flex-row flex-col w-full justify-between">
                          <div className="w-full md:w-1/2 md:pr-2">
                            <FormInput
                              label="First name"
                              name="first_name"
                              type="text"
                              defaultValue={firstName}
                              onInput={(e) => {
                                setFirstName(e.target.value);
                              }}
                              inputRef={register(formValidation("text", true))}
                              error={errors.first_name}
                              errorMessage={
                                errors.first_name && errors.first_name.message
                              }
                            />
                          </div>
                          <div className="w-full md:w-1/2 md:pl-2">
                            <FormInput
                              label="Last name"
                              name="last_name"
                              type="text"
                              defaultValue={lastName}
                              onInput={(e) => {
                                setLastName(e.target.value);
                              }}
                              inputRef={register(formValidation("text", true))}
                              error={errors.last_name}
                              errorMessage={
                                errors.last_name && errors.last_name.message
                              }
                            />
                          </div>
                        </div>
                        <div className="flex md:flex-row flex-col w-full justify-between">
                          <div className="w-full md:w-2/3 md:pr-2">
                            <FormInput
                              label="Email"
                              name="email"
                              type="email"
                              defaultValue={email}
                              onInput={(e) => {
                                setEmail(e.target.value);
                              }}
                              inputRef={register(formValidation("email", true))}
                              error={errors.email}
                              errorMessage={
                                errors.email && errors.email.message
                              }
                            />
                          </div>
                          <div className="w-full md:w-1/3 md:pl-2">
                            <FormInput
                              label="Phone number"
                              name="mobile"
                              type="text"
                              defaultValue={mobile}
                              onInput={(e) => {
                                setMobile(e.target.value);
                              }}
                              inputRef={register(formValidation("text", true))}
                              error={errors.mobile}
                              errorMessage={
                                errors.mobile && errors.mobile.message
                              }
                            />
                          </div>
                        </div>
                        <div className="flex md:flex-row flex-col w-full justify-between">
                          <div className="w-full md:w-1/2 md:pr-2">
                            <FormSelect
                              value={gender}
                              options={genderOptions}
                              onChange={(selected) => {
                                setGender(selected);
                              }}
                              label="Gender"
                            />
                          </div>
                          <div className="w-full md:w-1/2 md:pl-2">
                            <FormSelect
                              value={maritalStatus}
                              options={maritalOptions}
                              onChange={(selected) => {
                                setMaritalStatus(selected);
                              }}
                              label="Marital Status"
                            />
                          </div>
                        </div>
                        <div className="flex md:flex-row flex-col w-full justify-between">
                          <div className="w-full md:w-1/2 md:pr-2">
                            <FormInput
                              label="Years of experience"
                              name="years_of_experience"
                              type="number"
                              value={yearsOfExperience}
                              onInput={(e) => {
                                setYearsOfExperience(e.target.value);
                              }}
                              inputRef={register(
                                formValidation("number", true, 0)
                              )}
                              error={errors.years_of_experience}
                              errorMessage={
                                errors.years_of_experience &&
                                errors.years_of_experience.message
                              }
                            />
                          </div>
                          <div className="w-full md:w-1/2 md:pl-2">
                            <FormSelect
                              defaultValue={educationLevel}
                              options={educationLevelOptions}
                              onChange={(selected) => {
                                setEducationLevel(selected);
                              }}
                              label="Minimum Education"
                            />
                          </div>
                        </div>
                        <div className="flex md:flex-row flex-col w-full justify-between">
                          <div className="w-full md:w-1/3 md:pr-2">
                            <FormSelect
                              defaultValue={experienceLevel}
                              options={experienceLevelOptions}
                              onChange={(selected) => {
                                setExperienceLevel(selected);
                              }}
                              label="Level Of Experience"
                            />
                          </div>
                          <div className="w-full md:w-2/3 md:pl-2">
                            <FormInput
                              label="Personal Website - (Optional)"
                              name="personal_website"
                              placeholder="Enter Your Personal Website url"
                              type="text"
                              defaultValue={personalWebsite}
                              onInput={(e) => {
                                setPersonalWebsite(e.target.value);
                              }}
                              inputRef={register(formValidation("text", false))}
                            />
                          </div>
                        </div>
                        <div className="w-full">
                          <FormInput
                            label="Portfolio URL - (Optional)"
                            name="portfolio_url"
                            placeholder="Enter Your portfolio url"
                            type="text"
                            defaultValue={portfolioUrl}
                            onInput={(e) => {
                              setPortfolioUrl(e.target.value);
                            }}
                            inputRef={register(formValidation("text", false))}
                          />
                        </div>
                        <div className="w-full">
                          <FormInput
                            label="LinkedIn - (Optional)"
                            name="linkedin"
                            placeholder="Enter Your LinkedIn Page url"
                            type="text"
                            defaultValue={linkedin}
                            onInput={(e) => {
                              setLinkedIn(e.target.value);
                            }}
                            inputRef={register(formValidation("text", false))}
                          />
                        </div>
                        <div className="w-full">
                          <FormInput
                            label="Twitter - (Optional)"
                            name="twitter"
                            placeholder="Enter Your Twitter Page url"
                            type="text"
                            defaultValue={twitter}
                            onInput={(e) => {
                              setTwitter(e.target.value);
                            }}
                            inputRef={register(formValidation("text", false))}
                          />
                        </div>
                        <div className="w-full">
                          <FormInput
                            label="Facebook - (Optional)"
                            name="facebook"
                            placeholder="Enter Your Facebook Page url"
                            type="text"
                            defaultValue={facebook}
                            onInput={(e) => {
                              setFacebook(e.target.value);
                            }}
                            inputRef={register(formValidation("text", false))}
                          />
                        </div>
                        <div className="flex flex-col text-center justify-center items-center border-1 p-3 rounded mt-3">
                          <span className="header-5 -mb-4">
                            Upload Resume from pdf file only (Max Size: 2MB)
                          </span>
                          <FormFileInput
                            multiSource={true}
                            inputRef={register(formValidation("file", true))}
                            accept=".pdf"
                          />
                        </div>
                      </div>
                      <div
                        className={
                          mainDisplay !== "summary" && display === "question"
                            ? "flex flex-col"
                            : "hidden"
                        }
                      >
                        {display === "question" && result ? (
                          <>
                            {result?.map((item, index) => (
                              <div className="flex flex-col" key={index}>
                                <div className="flex flex-col mb-2">
                                  <span className="header-4">
                                    Question {index + 1}
                                  </span>
                                  <span className="p4-medium">
                                    {item.question}
                                  </span>
                                </div>
                                {item.question_type === "multiple_choice" ? (
                                  <div className="flex flex-col !min-w-[100%]">
                                    <span className="header-5 mb-2 underline ">
                                      Options:
                                    </span>
                                    {item.options.map(
                                      (optionItem, optionIndex) => (
                                        <div
                                          className={
                                            optionItem.selected
                                              ? "flex flex-row items-center mb-2 p-2 bg-hr-primary-1  cursor-pointer duration-500 border-1 rounded"
                                              : "flex flex-row items-center cursor-pointer duration-500 border-1 rounded mb-2 p-2 hover:bg-gray-200"
                                          }
                                          onClick={() => {
                                            let newArray = JSON.parse(
                                              JSON.stringify(result)
                                            );
                                            newArray[index].options.forEach(
                                              (item) => {
                                                item.selected = false;
                                              }
                                            );
                                            newArray[index].options[
                                              optionIndex
                                            ].selected = true;
                                            newArray[index].answer =
                                              newArray[index].options[
                                                optionIndex
                                              ].option_index;
                                            setResult(newArray);
                                          }}
                                        >
                                          <div
                                            className={
                                              optionItem.selected
                                                ? "header-5 pr-2 !min-w-[20%] !text-white duration-500"
                                                : "header-5 pr-2 !min-w-[20%] duration-500"
                                            }
                                          >
                                            Option {optionIndex + 1}:
                                          </div>
                                          <div
                                            className={
                                              optionItem.selected
                                                ? "header-5 pr-2 !min-w-[20%] !text-white duration-500"
                                                : "header-5 pr-2 !min-w-[20%] duration-500"
                                            }
                                          >
                                            {optionItem.option}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    <FormTextArea
                                      label={`Answer`}
                                      placeholder={"Enter your answer here"}
                                      value={item.answer}
                                      onInput={(e) => {
                                        let newArray = JSON.parse(
                                          JSON.stringify(result)
                                        );

                                        newArray[index].answer = e.target.value;
                                        setResult(newArray);
                                      }}
                                      name={`answer_${index + 1}`}
                                      type="text"
                                    />
                                  </div>
                                )}
                                <hr className="divider" />
                              </div>
                            ))}
                          </>
                        ) : null}
                      </div>
                      <div
                        className={
                          mainDisplay === "summary" ? "flex flex-col" : "hidden"
                        }
                      >
                        <div className="mt-2 grid md:grid-cols-2 grid-cols-1 gap-4 w-full">
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              First Name
                            </span>
                            <span className="mt-1 text-color-black text-[14px] font-bold">
                              {firstName}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Last Name
                            </span>
                            <span className="mt-1 text-color-black text-[14px] font-bold">
                              {lastName}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Mobile
                            </span>
                            <span className="mt-1 text-color-black text-[14px]">
                              {mobile}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Email
                            </span>
                            <span className="mt-1 text-color-black text-[14px]">
                              {email}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Years Of Experience
                            </span>
                            <span className="mt-1 text-color-black text-[14px]">
                              {yearsOfExperience}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Education Level
                            </span>
                            <span className="mt-1 text-color-black text-[14px]">
                              {educationLevel}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Personal Website
                            </span>
                            <span className="mt-1 text-color-black text-[14px]">
                              {personalWebsite}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Linkedin Page
                            </span>
                            <span className="mt-1 text-color-black text-[14px]">
                              {linkedin}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Twitter Page
                            </span>
                            <span className="mt-1 text-color-black text-[14px]">
                              {twitter}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Facebook Page
                            </span>
                            <span className="mt-1 text-color-black text-[14px]">
                              {facebook}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="header-5 text-color-gray">
                              Portfolio URL
                            </span>
                            <span className="mt-1 text-color-black text-[14px]">
                              {portfolioUrl}
                            </span>
                          </div>
                        </div>
                        {result && result.length > 0 ? (
                          <>
                            <hr className="divider mt-3 mb-1" />
                            {/* Recruitment Answers */}
                            <div
                              className={classNames(
                                "flex flex-row justify-between items-center cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300",
                                {
                                  "pt-3 pb-3": showRecruitmentResults,
                                }
                              )}
                              onClick={() =>
                                setShowRecruitmentResults(
                                  !showRecruitmentResults
                                )
                              }
                            >
                              <span>Your Answer Summary</span>
                              <div className="pr-2">
                                <CollapsingIcon
                                  defaultPosition="left"
                                  isOpen={showRecruitmentResults}
                                />
                              </div>
                            </div>
                            <div
                              className={classNames("flex flex-col", {
                                hidden: !showRecruitmentResults,
                              })}
                            >
                              <Collapse isOpen={showRecruitmentResults}>
                                {result?.map((item, index) => (
                                  <div className="flex flex-col" key={index}>
                                    <div className="flex flex-col mb-2">
                                      <span className="header-4">
                                        Question {index + 1}
                                      </span>
                                      <span className="p4-medium">
                                        {item.question}
                                      </span>
                                    </div>
                                    {item.question_type ===
                                    "multiple_choice" ? (
                                      <div className="flex flex-col !min-w-[100%]">
                                        <span className="header-5 mb-2 underline ">
                                          Options:
                                        </span>
                                        {item.options.map(
                                          (optionItem, optionIndex) => (
                                            <div
                                              className={
                                                optionItem.selected
                                                  ? "flex flex-row items-center mb-2 p-2 bg-hr-primary-1  cursor-pointer duration-500 border-1 rounded"
                                                  : "flex flex-row items-center cursor-pointer duration-500 border-1 rounded mb-2 p-2 hover:bg-gray-200"
                                              }
                                            >
                                              <div
                                                className={
                                                  optionItem.selected
                                                    ? "header-5 pr-2 !min-w-[20%] !text-white duration-500"
                                                    : "header-5 pr-2 !min-w-[20%] duration-500"
                                                }
                                              >
                                                Option {optionIndex + 1}:
                                              </div>
                                              <div
                                                className={
                                                  optionItem.selected
                                                    ? "header-5 pr-2 !min-w-[20%] !text-white duration-500"
                                                    : "header-5 pr-2 !min-w-[20%] duration-500"
                                                }
                                              >
                                                {optionItem.option}
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <pre className="border-2 p-3 rounded hover:bg-gray-100">
                                        {item.answer}
                                      </pre>
                                    )}
                                    <hr className="divider" />
                                  </div>
                                ))}
                              </Collapse>
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="pt-4">
                        {display === "apply" && mainDisplay !== "summary" ? (
                          <>
                            <Button
                              text="Next"
                              type="button"
                              disabled={
                                !firstName ||
                                !lastName ||
                                !mobile ||
                                !email ||
                                !educationLevel ||
                                !yearsOfExperience ||
                                expired
                              }
                              onClick={() => {
                                if (result && result.length > 0) {
                                  setDisplay("question");
                                } else {
                                  setMainDisplay("summary");
                                }
                              }}
                            />
                            <div
                              className="flex justify-center w-full cursor-pointer hover:underline mt-2"
                              onClick={() =>
                                history.push(
                                  `/recruitment/application-brief/${token}`
                                )
                              }
                            >
                              <span className="p2-medium text-color-black mt-1">
                                Back
                              </span>
                            </div>
                          </>
                        ) : display === "question" &&
                          mainDisplay !== "summary" ? (
                          <>
                            <Button
                              text="View Summary"
                              type="button"
                              disabled={expired}
                              onClick={() => {
                                setMainDisplay("summary");
                              }}
                            />
                            <div
                              className="flex justify-center w-full cursor-pointer hover:underline mt-2"
                              onClick={() => {
                                setDisplay("apply");
                              }}
                            >
                              <span className="p2-medium text-color-black mt-1">
                                Back
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <Button
                              text="Submit Application"
                              type="button"
                              loading={addApplicationLoading}
                              disabled={
                                !firstName ||
                                !lastName ||
                                !mobile ||
                                !email ||
                                !educationLevel ||
                                !yearsOfExperience ||
                                expired
                              }
                              onClick={handleSubmit(submitForm)}
                            />
                            <div
                              className="flex justify-center w-full cursor-pointer hover:underline mt-2"
                              onClick={() => {
                                setMainDisplay("application");
                              }}
                            >
                              <span className="p2-medium text-color-black mt-1">
                                Back
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-screen">
                    <img
                      src={EmptyTableSVG}
                      alt="No Data Fetched"
                      width={"80%"}
                      height={"200px"}
                    />
                    <span className="header-4 mt-3">
                      Invalid Application Token
                    </span>
                  </div>
                )}
              </div>
              <div
                className={
                  "md:hidden flex items-center justify-center text-color-black gap-2"
                }
              >
                Powered By:{" "}
                <a
                  href="https://www.ropay.africa"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {<Logo width="80px" height="40px" blue />}
                </a>
              </div>
            </>
          )}
        </>
      </div>
    </SingleRecruitmentApplicationLayout>
  );
}

export default ApplicationPage;
