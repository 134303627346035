import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  useAddSalaryAdvance,
  useEditSalaryAdvance,
} from '../../../redux/employees/hook/salary-advance';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormSelectEmployee from '../../employees/form-select-employee';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import WarningSVG from '../../../assets/svg/warning.svg';
import Modal from '../modal';
import moment from 'moment';

function AddSalaryAdvance({
  isOpen,
  closeModal,
  completed,
  refresh,
  saladData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { mutateAsync: addSalaryAdvance, isLoading: addSalaryAdvanceloading } =
    useAddSalaryAdvance();
  const {
    mutateAsync: editSalaryAdvamnce,
    isLoading: editSalaryAdvanceloading,
  } = useEditSalaryAdvance();
  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY-'));
  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [amountEdit, setAmountEdit] = useState();
  const [purposeEdit, setPurposeEdit] = useState();
  const [salaryId, setSalaryId] = useState();
  const [saladType, setSaladType] = useState();

  useEffect(() => {
    if (saladData) {
      setMonth(saladData.month.split('-')[1].toString());
      setYear(saladData.month.split('-')[0].toString() + '-');
      setAmountEdit(parseFloat(saladData.amount));
      setPurposeEdit(saladData.purpose);
      setEmployee(saladData.employee);
      setEmployeeName(saladData.first_name + ' ' + saladData.last_name);
      setSalaryId(saladData.id);
      setSaladType('edit');
    } else {
      setSaladType('add');
    }
  }, [saladData]);

  const submitForm = async (data) => {
    const amount = DOMPurify.sanitize(data?.amount);
    const purpose = DOMPurify.sanitize(data?.purpose);

    if (saladType === 'add') {
      let payload = {
        employee,
        amount,
        purpose,
        month: year + month,
      };
      await addSalaryAdvance(payload).then(() => {
        completed();
      });
    } else if (saladType === 'edit') {
      let editPayload = {
        id: salaryId,
        body: {
          employee,
          amount: amountEdit,
          purpose: purposeEdit,
          month: year + month,
          status: 2,
        },
      };
      await editSalaryAdvamnce(editPayload).then(() => {
        completed();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto '>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='flex flex-row justify-between pl-8 pr-8 pt-4 items-center'>
            <span className='header-3'>{title}</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col mx-8'>
            <div>
              <div className='border bg-secondary-2-extralight p-2 rounded-md mb-2'>
                <FormSelectEmployee
                  label={'Employee'}
                  name='employee'
                  placeholder='Type to select Employee'
                  value={employee}
                  readOnly={saladType === 'edit' ? true : false}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
              <hr className='divider mb-2 mt-2' />
              <div className='text-[13px] text-color-gray -mb-2'>
                Select month And year this salary advance will be deducted
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormSelect
                    value={month}
                    options={monthOptions}
                    onChange={(selected) => {
                      setMonth(selected);
                    }}
                  />
                </div>

                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormSelect
                    value={year}
                    options={yearOptions}
                    onChange={(selected) => {
                      setYear(selected);
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-2/3 md:pr-2'>
                  <FormInput
                    label='Purpose'
                    name='purpose'
                    type='text'
                    placeholder='Enter salary advance/loan purpose'
                    defaultValue={purposeEdit}
                    inputRef={register(formValidation('text', true))}
                    readOnly={addSalaryAdvanceloading || editSalaryAdvanceloading}
                    onInput={(e) => {
                      setPurposeEdit(e.target.value);
                    }}
                    error={errors.purpose}
                    errorMessage={errors.purpose && errors.purpose.message}
                  />
                </div>

                <div className='w-full md:w-1/3 md:pl-2'>
                  <FormInput
                    label='Amount'
                    name='amount'
                    type='number'
                    placeholder='Enter amount'
                    defaultValue={amountEdit}
                    inputRef={register(formValidation('number', true))}
                    readOnly={addSalaryAdvanceloading || editSalaryAdvanceloading}
                    onInput={(e) => {
                      setAmountEdit(e.target.value);
                    }}
                    error={errors.amount}
                    errorMessage={errors.amount && errors.amount.message}
                  />
                </div>
              </div>
              
            </div>
            <div className='flex flex-col justify-center'>
              <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light !text-[13px]'>
                <div className='w-[70px] pr-2 flex flex-row items-center '>
                  <img src={WarningSVG} alt='' srcSet='' />
                </div>
                <div className='text-[12px]'>
                  Please note that the Advances/Loans with be automatically applied on the payroll for the <span className='font-extrabold'>month</span> and <span className='font-extrabold'>year</span> selected
                </div>
              </div>
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                disabled={
                  saladType === 'edit'
                    ? false
                    : !isValid || !employee
                    ? true
                    : false
                }
                loading={addSalaryAdvanceloading || editSalaryAdvanceloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddSalaryAdvance;
