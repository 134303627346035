import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { store } from "../../../../redux";
import {
  useDeclineAdvancedPayroll,
  useExportAdvancedPayroll,
  useGetBranchAdvancedPayroll,
  useRecalculateAdvancedPayroll,
  useSendPayslip,
} from "../../../../redux/payroll/hook";
import { setAdvancedPayroll } from "../../../../redux/payroll/reducer";
import { PAGINATION_DEFAULT } from "../../../../utils/constants";
import DataTable from "../../../datatable";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import Button from "../../../button";
import { useGetEmployeeSalaries } from "../../../../redux/employees/hook/salaries";
import RunAdvancedPayroll from "../../../modal/payroll/run-advanced-payroll";
import { setAlert } from "../../../../redux/components/components-slice";

export const BranchAdvancedPayrollTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { id } = useParams();
  const { data: advancedPayroll } = useGetBranchAdvancedPayroll(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const workbook = new Excel.Workbook();

  const columns = [
    { header: "Date", key: "created" },
    { header: "First Name", key: "employee_first_name" },
    { header: "Last Name", key: "employee_last_name" },
    { header: "Mobile", key: "employee_phone" },
    { header: "Bank Name", key: "bank_name" },
    { header: "Account Number", key: "account_number" },
    { header: "Basic Salary", key: "basic_salary" },
    { header: "Housing Allowance", key: "housing_allowance" },
    { header: "Transport Allowance", key: "transport_allowance" },
    { header: "Leave Allowance", key: "leave_allowance" },
    { header: "Lunch Allowance", key: "lunch_allowance" },
    { header: "Benefit In Kind", key: "benefit_in_kind" },
    { header: "Utility Allowance", key: "utility_allowance" },
    { header: "Other Allowance", key: "other_allowance" },
    { header: "Bonus", key: "bonus" },
    { header: "Gross Pay", key: "gross_pay" },
    { header: "Employee Pension", key: "pension" },
    { header: "PAYE Tax", key: "tax" },
    { header: "National Housing Fund", key: "nhf" },
    { header: "National Health Insurance Scheme", key: "nhis" },
    { header: "Salary Advance", key: "salary_advance" },
    { header: "Reimbursement", key: "reimburse" },
    { header: "Net Pay", key: "net_pay" },
    { header: "Payments Status", key: "status" },
    { header: "Employer Pension", key: "employer_pension" },
    { header: "Industrial Training Fund", key: "itf" },
    { header: "Nigeria Social Insurance Trust Fund", key: "nsitf" },
  ];
  const [showAdvancedPayrollOptions, setShowAdvancedPayrollOptions] =
    useState(false);
  const { data: employeeSalaries } = useGetEmployeeSalaries(2, 1, 1, "");

  const [exportLoading, setSetExportLoading] = useState(false);

  const { mutateAsync: rerunAdvancedPayroll } = useRecalculateAdvancedPayroll();
  const { mutateAsync: declinePayment } = useDeclineAdvancedPayroll();

  const { mutateAsync: sendPayslip } = useSendPayslip();

  const history = useHistory();

  async function Continue(id, type) {
    let index = advancedPayroll.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === "preview") {
        history.push(
          `/payroll/advanced/preview/${advancedPayroll.data[index].ref}`
        );
      } else if (type === "view") {
        history.push(
          `/payroll/advanced/view/${advancedPayroll.data[index].id}`
        );
      } else if (type === "recalculate") {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        await rerunAdvancedPayroll(advancedPayroll.data[index].ref).then(() => {
          history.push(
            `/payroll/advanced/preview/${advancedPayroll.data[index].ref}`
          );
        });
      } else if (type === "approve") {
        store.dispatch(setAdvancedPayroll(advancedPayroll.data[index]));
        history.push(
          `/payroll/advanced/preview/${advancedPayroll.data[index].ref}`,
          "approve"
        );
      } else if (type === "send_payslip") {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        sendPayslip({ ref: advancedPayroll.data[index].ref });
      } else if (type === "decline") {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        declinePayment(advancedPayroll.data[index].ref);
      }
    }
  }

  const statusConfig = [
    {
      label: "In Progress",
      color: "orange",
      value: 0,
    },
    {
      label: "Successful",
      color: "green",
      value: 1,
    },
    {
      label: "Awaiting Approval",
      color: "orange",
      value: 2,
    },
    {
      label: "Declined",
      color: "red",
      value: 3,
    },
  ];

  const tableConfig = {
    // title: 'Advanced Payroll Summary',
    headers: [
      "Date Created",
      "Description",
      "Ref. Number",
      "Month",
      "% Paid",
      "% Initiated",
      "Total Amount",
      "Status",
    ],
    keys: [
      "created",
      "desc",
      "ref",
      "month",
      "percentage_paid",
      "initiated_percentage",
      "total_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Description",
        key: "desc",
      },
    },
  };

  const { mutateAsync: exportPayroll } = useExportAdvancedPayroll();

  const exportAdvancedPayroll = async (refId) => {
    setSetExportLoading(true);
    let payload = {
      refId,
      limit: 100000,
      page: 1,
      statusFilter: -1,
      search: "",
    };
    await exportPayroll(payload).then(async (response) => {
      let data = response.data;
      const workSheetName = "advanced-payroll-history";
      const workBookName = `Advanced Payroll - ${refId}`;
      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column) => {
          column.width = column.header.length + 5;
          column.alignment = { horizontal: "center" };
        });

        // loop through data and add each one to worksheet
        data.forEach((singleData) => {
          if (singleData.status === 1) {
            singleData.status = "Successful";
          } else if (singleData.status === 2) {
            singleData.status = "Failed";
          } else if (singleData.status === 0) {
            singleData.status = "Pending";
          }
          worksheet.addRow(singleData);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        // console.error('<<<ERRROR>>>', error);
        // console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  const actionConfig = [
    {
      name: "Continue",
      color: "green",
      action: (id) => {
        Continue(id, "preview");
      },
    },
    {
      name: "View",
      color: "green",
      action: (id) => {
        Continue(id, "view");
      },
    },
    {
      name: "Send Payslips",
      color: "green",
      action: (id) => {
        Continue(id, "send_payslip");
      },
    },
    {
      name: "Recalculate",
      color: "orange",
      action: (id) => {
        Continue(id, "recalculate");
      },
    },
    {
      name: "Approve",
      color: "green",
      action: (id) => {
        Continue(id, "approve");
      },
    },
    {
      name: "Decline",
      color: "red",
      action: (id) => {
        Continue(id, "decline");
      },
    },
    {
      name: "Export",
      color: "orange",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        exportAdvancedPayroll(advancedPayroll.data[index].ref);
      },
    },
  ];

  return (
    <div className="flex flex-col gap-4 -mt-3">
      {showAdvancedPayrollOptions ? (
        <RunAdvancedPayroll
          isOpen={showAdvancedPayrollOptions}
          source="branch"
          sourceData={id}
          closeModal={() => setShowAdvancedPayrollOptions(false)}
        />
      ) : null}
      <div className="flex flex-col bg-white rounded">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Branch Payroll Summary
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="Run Advanced Payroll"
                type="submit"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                theme="primary"
                onClick={() => {
                  if (employeeSalaries?.data.length > 0) {
                    setShowAdvancedPayrollOptions(true);
                  } else {
                    store.dispatch(
                      setAlert(
                        true,
                        "info",
                        "No Active Employee Compensation to run"
                      )
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-2" />
        <DataTable
          data={advancedPayroll}
          tableConfig={tableConfig}
          noCheck={true}
          statusConfig={statusConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          filterByStatus={true}
          searchData={true}
          computedActions={true}
        />
      </div>
    </div>
  );
};
