import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import RemoveSVG from '../../../../assets/svg/remove.svg';
import { useParams } from 'react-router-dom';
import { useAddRecruitmentQuestions } from '../../../../redux/human-resources/hook/recruitment';
import Modal from '../../modal';

function AddQuestions({ isOpen, closeModal, questionCount, title }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [questions, setQuestions] = useState([
    { question: '', question_type: 'multiple_choice', options: [] },
  ]);

  const { id } = useParams();

  const QuestionTypes = [
    { label: 'Multiple Choice', value: 'multiple_choice' },
    { label: 'Open Ended', value: 'open_ended' },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const addQuestion = () => {
    if (questions.length < 10) {
      setQuestions([
        ...questions,
        ...[{ question: '', question_type: 'multiple_choice', options: [] }],
      ]);
    }
  };

  const addOption = (index) => {
    if (questions[index].options.length < 4) {
      let newArray = JSON.parse(JSON.stringify(questions));

      newArray[index].options.push({ option: '' });
      setQuestions(newArray);
    }
  };

  const removeQuestion = (index) => {
    let data = JSON.parse(JSON.stringify(questions));
    if (index > -1) {
      // only splice array when item is found
      data.splice(index, 1); // 2nd parameter means remove one item only
      setQuestions(data);
    }
  };

  const removeOption = (index, subIndex) => {
    let data = JSON.parse(JSON.stringify(questions));
    if (index > -1) {
      // only splice array when item is found
      data[index].options.splice(subIndex, 1); // 2nd parameter means remove one item only
      setQuestions(data);
    }
  };

  const { mutateAsync: addQuestions, isLoading: addQuestionsloading } =
    useAddRecruitmentQuestions();

  const submitForm = async () => {
    let payload = {
      recruitment_id: id,
      questions: questions,
    };
    await addQuestions(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[80%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-2'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='mt-3 p-8 pt-2 pb-2'>
            {questions &&
              questions?.map((item, index) => (
                <>
                  {index !== 0 ? <hr className='divider -mt-1' /> : null}
                  <div
                    className='w-[100%] flex md:flex-row justify-between items-center -mt-3 text-[13px] hover:shadow-2xl overflow-hidden'
                    onMouseOver={() => setActiveIndex(index)}
                    key={index}
                  >
                    <div
                      className={
                        activeIndex === index
                          ? 'flex flex-col items-start justify-center !w-[100%] hover:shadow-2xl shadow-green-500 p-3 rounded-xl border-1 bg-gray-100 overflow-clip'
                          : 'flex flex-col items-start justify-center !w-[100%] border-0'
                      }
                    >
                      <span className='font-bold italic text-black underline'>
                        Question {index + 1}
                      </span>
                      <div className='flex md:flex-row flex-col justify-between md:items-center !w-[100%]'>
                        <div className='flex flex-row items-center justify-start gap-3 md:w-2/3 w-full'>
                          <FormInput
                            name={index + '_question'}
                            placeholder={'Enter Question?'}
                            type='text'
                            inputRef={register(formValidation('text', true))}
                            defaultValue={item.question}
                            onInput={(e) => {
                              let newArray = JSON.parse(
                                JSON.stringify(questions),
                              );

                              newArray[index].question = e.target.value;
                              setQuestions(newArray);
                            }}
                            className='!h-[30px] md:w-[100%] w-[200%] text-[13px] border-1 border-gray-300'
                          />
                        </div>
                        <div className='flex flex-row md:justify-end items-center gap-3 md:w-1/3 w-full'>
                          <FormSelect
                            defaultValue={item.question_type}
                            options={QuestionTypes}
                            name={item.question_type}
                            onChange={(selected) => {
                              let newArray = JSON.parse(
                                JSON.stringify(questions),
                              );

                              newArray[index].question_type = selected;
                              setQuestions(newArray);
                            }}
                            className='!min-h-[32px] border-1 mt-1 !min-w-[100%] rounded text-[13px]'
                          />
                          <img
                            src={RemoveSVG}
                            alt={'remove'}
                            className={'h-[20px] w-[15px] cursor-pointer'}
                            onClick={() => {
                              removeQuestion(index);
                            }}
                          />
                        </div>
                      </div>
                      <div className='flex flex-col items-start justify-start w-full'>
                        {item.question_type === 'multiple_choice' ? (
                          <div className='flex flex-col w-full'>
                            {item.options && item.options.length ? (
                              <span className='italic text-hr-primary-1 underline mb-3'>
                                Options
                              </span>
                            ) : null}
                            {item.options &&
                              item.options.length > 0 &&
                              item.options.map((subItem, subIndex) => (
                                <div
                                  className='flex flex-row justify-start items-center'
                                  key={subIndex}
                                >
                                  <div className='flex flex-row items-center -mt-4'>
                                    <span className='italic mr-4 text-[15px] font-bold'>
                                      {subIndex + 1}.
                                    </span>
                                    <FormInput
                                      name={subIndex + '_option'}
                                      placeholder={'Enter Option'}
                                      type='text'
                                      inputRef={register(
                                        formValidation('text', true, 0),
                                      )}
                                      defaultValue={subItem.option}
                                      onInput={(e) => {
                                        let newArray = JSON.parse(
                                          JSON.stringify(questions),
                                        );

                                        newArray[index].options[
                                          subIndex
                                        ].option = e.target.value;
                                        setQuestions(newArray);
                                      }}
                                      className='!h-[30px] w-[250px] text-[13px] border-1 border-gray-300'
                                    />
                                    <img
                                      src={RemoveSVG}
                                      alt={'remove'}
                                      className={
                                        'h-[20px] w-[20px] ml-3 cursor-pointer'
                                      }
                                      onClick={() => {
                                        removeOption(index, subIndex);
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                            {item.options.length < 4 ? (
                              <span
                                className='text-[13px] italic text-[#0d5225] cursor-pointer hover:underline hover:text-color-gray duration-75 mb-2'
                                onClick={() => addOption(index)}
                              >
                                Add Option (Max. 4)
                              </span>
                            ) : null}
                          </div>
                        ) : (
                          <div className='mb-2'></div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            <hr className='divider mt-3 ' />
            {!questionCount || questions.length + questionCount < 10 ? (
              <div className='flex justify-left w-full'>
                <span
                  className='font-bold text-[14px] italic text-[#0d5225] cursor-pointer hover:underline hover:text-color-gray duration-75'
                  onClick={() => addQuestion()}
                >
                  Add New Question (Max. 10)
                </span>
              </div>
            ) : null}
            <div className='w-full mt-[20px]'>
              <Button
                text='ADD QUESTIONS'
                disabled={!id}
                type='button'
                loading={addQuestionsloading}
                className='!h-[62px]'
                onClick={() => {
                  submitForm();
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddQuestions;
