import React from 'react';
import { useSelector } from 'react-redux';

const PerformanceRanking = () => {

    const { user } = useSelector((state) => state.userSlice);

    return (
        <div className='flex flex-col'>
            <div className='mt-9'>
                <div className='flex md:flex-row gap-4 flex-wrap-reverse'>
                    <div className='w-[100%]'>
                        {/* <DataTable
                        data={leaves}
                        tableConfig={tableConfig}
                        statusConfig={attendanceStatusConfig}
                        noCheck={true}
                        updatePagination={(data) => setPagination(data)}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PerformanceRanking;