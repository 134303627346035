import React, { useState } from 'react';
import CloseSVG from '../../assets/svg/close.svg';
import Modal from './modal';
import FormSelect from '../form-select';

function DemoVideoModal ({
    isOpen,
    closeModal,
    type,
}) {
    const [currentVideo, setCurrentVideo] = useState('quick_overview')

    const videoOptions = [
        {label: 'Quick Overview', value: 'quick_overview'},
        {label: 'Getting started', value: 'getting_started'},
        {label: 'How do I add employees?', value: 'add_employee'},
        {label: 'How do I run payroll?', value: 'run_payroll'},
        {label: 'How do I make statutory payments?', value: 'pay_statutory'},
        {label: 'How do I recruit?', value: 'recruitment'},
        {label: 'How do I evaluate performance?', value: 'evaluate_performance'},
        {label: 'How do I automate onboarding?', value: 'onboarding'},
    ];
    return (
        <Modal
            scrollable={true}
            className='!min-w-[400px] max-w-[90%] !rounded-none'
            isOpen={isOpen}
            centered={true}
        >
            <div className='mb-10 overflow-auto'>
                <div className='flex flex-col overflow-auto justify-center'>
                    <div className='pl-8 pr-8 pt-4'>
                        <div className='flex flex-row justify-between items-center'>
                        <span className='header-4'>How to videos</span>
                        <div className='cursor-pointer' onClick={() => closeModal()}>
                            <img src={CloseSVG} alt='close' />
                        </div>
                        </div>
                    </div>
                    <hr className='divider mb-3' />
                    <div className='flex flex-col px-8 items-start'>
                        <div className='border bg-secondary-2-extralight p-2 rounded-md mb-3 w-full'>
                            <FormSelect
                                value={currentVideo}
                                options={videoOptions}
                                onChange={(selected) => {
                                    setCurrentVideo(selected);
                                }}
                            />
                        </div>
                        <div className='flex items-center justify-center w-full'>
                            {currentVideo == 'quick_overview'?
                            <iframe 
                                width="560" 
                                height="315"
                                src="https://www.youtube.com/embed/8XQpnaFY844" 
                                title="Quick overview" 
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen
                            >
                            </iframe>
                            : currentVideo == 'getting_started'?
                            <iframe 
                                width="560" 
                                height="315"
                                src="https://www.youtube.com/embed/8XQpnaFY844"
                                title="Getting started" 
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen
                            >
                            </iframe>
                            : currentVideo == 'add_employee'?
                            <iframe 
                                width="560" 
                                height="315"
                                src="https://www.youtube.com/embed/8XQpnaFY844" 
                                title="How to videos" 
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen
                            >
                            </iframe>
                            : currentVideo == 'run_payroll'?
                            <iframe 
                                width="560" 
                                height="315"
                                src="https://www.youtube.com/embed/8XQpnaFY844" 
                                title="How to videos" 
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen
                            >
                            </iframe>
                            : currentVideo == 'pay_statutory'?
                            <iframe 
                                width="560" 
                                height="315"
                                src="https://www.youtube.com/embed/8XQpnaFY844" 
                                title="How to videos" 
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen
                            >
                            </iframe>
                            :
                            null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default DemoVideoModal;
