import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layouts/dashboard";
import TabView from "../../components/tabview";
import ReferredUsersPage from "./referred-users";
import ReferralTransactionsPage from "./referral-transactions";
import referredCompaniesPage from "./referred-companies";
import { useGetReferralData } from "../../redux/referrals/hook";
import Button from "../../components/button";
import GenerateReferralCode from "../../components/modal/settings/generate-referral";
import CopySVG from "../../assets/svg/copy.svg";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoadingBar from "../../components/loader-bar";
import { formatAmount } from "../../utils/functions";

const ReferralsPage = () => {
  useEffect(() => {}, []);

  const [referralGenerated, setReferralGenerated] = useState(false);
  const [showReferralDetails, setShowReferralDetails] = useState(false);
  const [generateReferralDetails, setShowGenerateReferralDetails] =
    useState(false);

  const [activeTab, setActiveTab] = useState(1);
  const { data: referral, isLoading: generateReferalDataLoading } =
    useGetReferralData();
  const dispatch = useDispatch();

  useEffect(() => {
    if (referral) {
      setReferralGenerated(true);
    }
  }, [referral]);

  const referralTabs = [
    {
      title: "Users Referred",
      Content: ReferredUsersPage,
    },
    {
      title: "Companies Referred",
      Content: referredCompaniesPage,
    },
    {
      title: "Referral Transactions",
      Content: ReferralTransactionsPage,
    },
  ];

  return (
    <DashboardLayout title="Referral">
      {generateReferralDetails ? (
        <GenerateReferralCode
          isOpen={generateReferralDetails}
          closeModal={() => setShowGenerateReferralDetails(false)}
        />
      ) : null}
      {showReferralDetails ? (
        <GenerateReferralCode
          isOpen={showReferralDetails}
          closeModal={() => setShowReferralDetails(false)}
        />
      ) : null}
      <div className="flex flex-col">
        {generateReferalDataLoading ? (
          <LoadingBar loading={generateReferalDataLoading} />
        ) : referralGenerated ? (
          <div className="flex flex-col gap-2">
            <div className="flex md:flex-row flex-col justify-between items-center gap-4 w-full">
              {referral ? (
                <>
                  <div className="flex flex-col justify-center gap-2 p-2 bg-white rounded-md md:w-2/5 w-full min-h-[90px] border">
                    <div className="flex flex-row gap-2 items-center justify-start">
                      <span className="text-[13px] text-secondary-2 font-semibold">
                        Referral Code:
                      </span>
                      <span className="text-[15px] font-semibold">
                        {referral?.referral_code}
                      </span>
                    </div>
                    <div className="flex flex-row gap-2 items-center justify-start ">
                      <span className="text-[15px] text-secondary-2 font-semibold">
                        Referral Balance:
                      </span>
                      <span className="text-[15px] font-semibold">
                        {formatAmount(referral?.balance)}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center gap-0 items-end p-2 bg-white rounded-md md:w-2/5 w-full min-h-[90px] border">
                    <div className="flex gap-2 items-center">
                      <span className="text-[13px] text-secondary-2 font-semibold">
                        Referral Link:
                      </span>
                      <span className="text-[11px] text-color-gray">
                        https://www.app.ropay.africa/register?referral_code=
                        {referral?.referral_code}
                      </span>
                    </div>
                    <CopyToClipboard
                      text={`https://www.app.ropay.africa/register?referral_code=${referral?.referral_code}`}
                      onCopy={() => {
                        dispatch(
                          setAlert(true, "success", "Copied successfully")
                        );
                      }}
                    >
                      <button className="flex flex-row items-center text-center">
                        <span className="text-color-black pt-2 text-[13px] text-black hover:font-bold duration-300">
                          Copy to clipboard{" "}
                        </span>
                        <img
                          src={CopySVG}
                          width={15}
                          className="ml-2 mt-2"
                          alt="copy"
                        />
                      </button>
                    </CopyToClipboard>
                  </div>
                </>
              ) : null}
            </div>
            <div className="mt-4">
              <TabView
                Tabs={referralTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
        ) : (
          <div
            className={
              "flex flex-col gap-2 justify-center items-center bg-white w-full min-h-[500px] rounded-md"
            }
          >
            <span>Click the button below to start earning from Ropay</span>
            <div className="w-fit">
              <Button
                text="GENERATE REFERRAL LINK"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[60px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[15px]"}
                onClick={() => {
                  setShowGenerateReferralDetails(true);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ReferralsPage;
