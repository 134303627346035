import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormSelectEmployee from '../../../employees/form-select-employee';
import FormInput from '../../../form-input';
import Modal from '../../modal';
import FormDateRangePicker from '../../../form-date-range-picker';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import FormSwitch from '../../../form-switch';
import {
  useAddProbation,
  useEditProbation,
} from '../../../../redux/employees/hook/probation';

function AddProbationModal({
  isOpen,
  closeModal,
  refresh,
  probationData,
  title,
  buttonText,
}) {
  const { mutateAsync: addProbation, isLoading: addProbationLoading } =
    useAddProbation();
  const { mutateAsync: editProbation, isLoading: editProbationLoading } =
    useEditProbation();
  const [start_date, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [end_date, setEndDate] = useState(
    moment().add(3, 'month').format('YYYY-MM-DD'),
  );
  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [sendEmail, setSendEmail] = useState(false);
  const [body, setBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [purpose, setPurpose] = useState();
  const [probationId, setProbationId] = useState();
  const [probationType, setprobationType] = useState();

  useEffect(() => {
    if (probationData) {
      setPurpose(probationData.purpose);
      setEmployee(probationData.employee_id);
      setEmployeeName(probationData.first_name + ' ' + probationData.last_name);
      setProbationId(probationData.id);
      setprobationType('edit');
    } else {
      setprobationType('add');
    }
  }, [probationData]);

  const submitForm = async () => {
    if (probationType === 'add') {
      let payload = {
        employee_id: employee,
        purpose,
        start_date,
        end_date,
        send_email: sendEmail ? 1 : 0,
        email_subject: emailSubject,
        email_body: body.toString(),
      };
      await addProbation(payload).then(() => {
        closeModal();
      });
    } else if (probationType === 'edit') {
      let editPayload = {
        id: probationId,
        body: {
          employee_id: employee,
          purpose,
          start_date,
          end_date,
          send_email: 0,
        },
      };
      await editProbation(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-4'>
          <div className='flex flex-row justify-between pl-8 pr-8 pt-4 items-center'>
            <span className='header-4'>{title}</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col mx-8'>
            <div>
              <div className='border bg-highlight p-2 rounded-md mb-2'>
                <FormSelectEmployee
                  label={'Employee'}
                  name='employee'
                  placeholder='Type to select Employee'
                  value={employee}
                  readOnly={probationType === 'edit' ? true : false}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
              <hr className='divider mb-2 mt-2' />
              <div className='flex flex-row md:flex-col w-full justify-between z-[1] h-full'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Start Date'
                    name='start_date'
                    type='date'
                    value={start_date}
                    onInput={(e) => {
                      if (moment(end_date).isBefore(e.target.value)) {
                        setEndDate(
                          moment(e.target.value, 'YYYY-MM-DD').format(
                            'YYYY-MM-DD',
                          ),
                        );
                      }
                      setStartDate(e.target.value);
                    }}
                  />
                </div>
                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormInput
                    label='End Date'
                    name='end_date'
                    type='date'
                    min={moment(start_date, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                    value={end_date}
                    onInput={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full'>
                  <FormInput
                    label='Purpose'
                    name='purpose'
                    placeholder='Enter probation purpose'
                    type='text'
                    readOnly={addProbationLoading || editProbationLoading}
                    value={purpose}
                    onInput={(e) => {
                      setPurpose(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <hr className='divider mb-2 mt-2' />
            <div
              className={
                'flex w-full flex-col gap-2 px-3 border bg-highlight rounded-md pt-4'
              }
            >
              <div className=''>
                <FormSwitch
                  label={
                    'Do you want to send probation notification email to employee?'
                  }
                  name={'send_email'}
                  checked={sendEmail}
                  onClick={() => setSendEmail(!sendEmail)}
                  value={sendEmail}
                />
              </div>
              {sendEmail ? (
                <div className='flex flex-col'>
                  <hr className='divider -mt-2 mb-1' />
                  <FormInput
                    label='Subject'
                    name='subject'
                    type='text'
                    value={emailSubject}
                    className={'bg-white'}
                    placeholder={'Email Subject'}
                    onInput={(e) => {
                      setEmailSubject(e.target.value);
                    }}
                  />
                  <div className='flex md:flex-row flex-col w-full justify-between mb-2'>
                    <div className='w-full'>
                      <span className='text-[13px] text-gray-500'>
                        Probation Email Body
                      </span>
                      <Editor
                        apiKey={import.meta.env.VITE_APP_TINYMCE_API_KEY}
                        onEditorChange={(editor) => {
                          setBody(editor);
                        }}
                        value={body}
                        init={{
                          height: 300,
                          menubar: false,
                          placeholder: 'Enter Email body here',
                          selector: 'textarea',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          toolbar:
                            'undo redo | formatselect | ' +
                            'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                          content_style:
                            'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className='w-full mt-2'>
              <Button
                text={buttonText}
                type='button'
                disabled={!employee}
                loading={addProbationLoading || editProbationLoading}
                onClick={() => {
                  submitForm();
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddProbationModal;
