import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import ButtonSelect from "../../../components/button-select";
import EmployeeOnboardingLayout from "../../../components/layouts/onboard-employee";
import AddEmployee from "../../../components/modal/employees/add-employee";
import { useHistory } from "react-router-dom";
import { useRouteQuery } from "../../../hooks/useRouteQuery";

function SelectOptions() {
  useEffect(() => {}, []);

  const [employeeOption, setEmployeeOptions] = useState("invite");
  const [showAddEmployeeDetails, setShowEmployeeDetails] = useState(false);
  const [fromSalary, setFromSalary] = useState(false);
  const history = useHistory();

  const query = useRouteQuery();

  useEffect(() => {
    if (query) {
      setFromSalary(query.get("salary"));
    }
  }, [query]);

  const submit = () => {
    if (fromSalary) {
      if (employeeOption === "single") {
        history.push(
          "/employees/onboarding/add-salary-information?type=single&salary=true"
        );
      } else if (employeeOption === "bulk") {
        history.push(
          "/employees/onboarding/add-salary-information?type=bulk&salary=true"
        );
      }
    } else if (employeeOption == "invite") {
      history.push("/employees/onboarding/add-single-employee/invitation-link");
    } else {
      setShowEmployeeDetails(true);
    }
  };

  return (
    <EmployeeOnboardingLayout
      step={1}
      title={fromSalary ? "Add Employee Compensation" : "Onboard employees"}
    >
      <AddEmployee
        isOpen={showAddEmployeeDetails}
        closeModal={() => setShowEmployeeDetails(false)}
        type={employeeOption}
      />
      <div className="flex flex-col justify-center h-full min-h-[480px] md:mt-5 -mt-10">
        {fromSalary ? null : (
          <ButtonSelect
            title={"Onboard Employees using Link"}
            subtitle={
              "Add employees by sending them a link to to enable them provide all their information"
            }
            isActive={employeeOption === "invite"}
            onClick={() => setEmployeeOptions("invite")}
          />
        )}
        <ButtonSelect
          title={fromSalary ? "Add Single Salary" : "Add Single Employee"}
          subtitle={
            fromSalary ? "Onboard Single Salary" : "Onboard Single Employee"
          }
          isActive={employeeOption === "single"}
          onClick={() => setEmployeeOptions("single")}
          className="mt-8"
        />
        <ButtonSelect
          title={
            fromSalary
              ? "Import Salary Information"
              : "Import Employees Information"
          }
          subtitle={
            fromSalary
              ? "Import employees salaries using a pre-defined XLSX template"
              : "Import employees information using a pre-defined XLSX template"
          }
          isActive={employeeOption === "bulk"}
          onClick={() => setEmployeeOptions("bulk")}
          className="mt-8"
        />
        <div className="w-full mt-[20px]">
          <Button text="Next" type="submit" onClick={() => submit()} />
        </div>
        <div
          className="flex justify-center w-full cursor-pointer hover:underline hover:scale-110 duration-300 mt-2"
          onClick={() => {
            if (fromSalary) {
              history.push("/salaries");
            } else {
              history.push("/employees");
            }
          }}
        >
          <span className="p2-medium text-color-black mt-3">
            Back to Dashboard
          </span>
        </div>
      </div>
    </EmployeeOnboardingLayout>
  );
}

export default SelectOptions;
