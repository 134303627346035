import React, { useEffect, useState } from "react";
import Button from "../../../../components/button";
import DashboardLayout from "../../../../components/layouts/dashboard";
import { useHistory } from "react-router-dom";
import DataTable from "../../../../components/datatable";
import { PAGINATION_DEFAULT } from "../../../../utils/constants";
import {
  useDeletePromotion,
  useGetEmployeePromotions,
} from "../../../../redux/employees/hook/promotion";
import AddPromotion from "../../../../components/modal/hr-section/employee-promotion/add-promotion";
import UnauthorizedPage from "../../../errors/unauthorized";
import { useSelector } from "react-redux";

const HRPromotionPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.promotion_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: promotions } = useGetEmployeePromotions(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const { mutateAsync: deletePromotion } = useDeletePromotion();

  const [tableType, setTableType] = useState("promotion");

  const switchFunction = () => {
    if (tableType === "promotion") {
      setTableType("table");
    } else {
      setTableType("promotion");
    }
  };

  const [showAddPromotion, setShowAddPromotion] = useState(false);
  const [promotionData, setPromotionData] = useState();

  const history = useHistory();

  const tableConfig = {
    headers: [
      "Employee Name",
      "Mobile",
      "Promoted From",
      "Promoted To",
      "Effective From",
    ],
    keys: [
      "employeeName",
      "mobile",
      "promotion_from",
      "promotion_to",
      "formatted_effective_date",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "New Role",
        key: "promotion_to",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = promotions.data.findIndex((item) => item.id === id);
        history.push("/promotion/view/" + promotions.data[index].id);
      },
    },
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = promotions.data.findIndex((item) => item.id === id);
        setTitle("Update Promotion");
        setButtonText("Update");
        setPromotionData(promotions.data[index]);
        setShowAddPromotion(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = promotions.data.findIndex((item) => item.id === id);
        deletePromotion(promotions.data[index].id);
      },
    },
  ];
  const [title, setTitle] = useState("Add Promotion");
  const [buttonText, setButtonText] = useState("Add");

  return (
    <DashboardLayout title="Promotions">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {showAddPromotion ? (
            <AddPromotion
              isOpen={showAddPromotion}
              setShowAddPromotion
              closeModal={() => setShowAddPromotion(false)}
              promotionData={promotionData}
              title={title}
              buttonText={buttonText}
            />
          ) : null}
          <div className="flex flex-col">
            <div className="flex flex-col bg-white ">
              <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
                <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
                  Promotions
                </div>
                <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
                  <div className="md:w-fit w-[100%]">
                    <Button
                      text="New Promotion"
                      type="button"
                      theme="primary"
                      className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                      textClass={"!text-[11px]"}
                      leftIcon={"add"}
                      onClick={() => {
                        setTitle("Add Promotion");
                        setButtonText("Save");
                        setPromotionData(null);
                        setShowAddPromotion(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className="divider mt-2 mb-2" />
              <DataTable
                data={promotions}
                tableConfig={tableConfig}
                actionConfig={actionConfig}
                updatePagination={(data) => setPagination(data)}
                computedActions={true}
                tableType={tableType}
                switchFunction={switchFunction}
                layoutSwitch={true}
                searchData={true}
                noCheck={true}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default HRPromotionPage;
