import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import { employees as employeeAPI } from '../../../../api/employees';
import {
  useGetSelectTrainingTrainers,
} from '../../../../redux/employees/hook/trainers';
import AsyncSelect from 'react-select/async';
import {
  useAddEmployeeTraining,
  useUpdateEmployeeTraining,
} from '../../../../redux/employees/hook/training';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../redux/components/components-slice';
import Modal from '../../modal';
import AddTrainer from './add-trainer';

function AddTraining({
  isOpen,
  closeModal,
  refresh,
  trainingData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [trainingId, setTrainingId] = useState(null);
  const [trainerChangeType, setTrainerChangeType] = useState('add');
  const dispatch = useDispatch();

  const { data: trainers, isLoading: trainerLoading } =
    useGetSelectTrainingTrainers(1000, 1, '');

  const [trainingCost, setTrainingCost] = useState(null);
  const [trainingLocation, setTrainingLocation] = useState(null);
  const [description, setDescription] = useState(null);
  const [trainingName, setTrainingName] = useState(null);
  const [trainerId, setTrainerId] = useState(null);

  useEffect(() => {
    if (trainingData) {
      setTrainingId(trainingData.id);
      setTrainerChangeType('edit');
      setDescription(trainingData.description);
      setTrainerId(trainingData.trainer_id);
      setTrainingName(trainingData.training_name);
      setTrainingCost(trainingData.training_cost);
      setTrainingLocation(trainingData.training_location);
      setTrainingType(trainingData.training_type);
      setTrainingStart(trainingData.training_start);
      setTrainingEnd(trainingData.training_end);
    } else {
      setTrainerChangeType('add');
    }
  }, [trainingData]);

  const { mutateAsync: addTraining, isLoading: addTrainingloading } =
    useAddEmployeeTraining();

  const { mutateAsync: editTraining, isLoading: editTrainingloading } =
    useUpdateEmployeeTraining();

  useEffect(() => {
    if (!trainers) {
      setShowaddTraining(true);
    }
  }, [trainers]);

  const [trainingType, setTrainingType] = useState('internal');

  const [trainingStart, setTrainingStart] = useState();
  const [trainingEnd, setTrainingEnd] = useState();

  const trainingTypeOptions = [
    { value: 'internal', label: 'Internal' },
    { value: 'external', label: 'External' },
  ];
  const [showaddTraining, setShowaddTraining] = useState(false);

  const [employees, setEmployees] = useState([]);

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 10,
          statusFilter: 1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const submitForm = async (data) => {
    if (trainerChangeType === 'add') {
      if (employees.length === 0) {
        dispatch(setAlert(true, 'info', 'Select at least one employee'));
        return;
      } else {
        let employeeArrays = [];

        employees.forEach((item) => {
          employeeArrays.push(item.value);
        });

        let employee_ids = employeeArrays.join();
        let payload = {
          employee_ids: employee_ids,
          trainer_id: trainerId,
          training_type: trainingType,
          training_name: trainingName,
          training_start: trainingStart,
          training_end: trainingEnd,
          training_location: trainingLocation,
          description: description,
          training_cost: trainingCost,
        };
        addTraining(payload).then(() => {
          closeModal();
        });
      }
    } else {
      let payload = {
        trainer_id: trainerId,
        training_type: trainingType,
        training_name: trainingName,
        training_start: trainingStart,
        training_end: trainingEnd,
        training_location: trainingLocation,
        description: description,
        training_cost: trainingCost,
      };
      let editPayload = {
        id: trainingId,
        data: payload,
      };
      editTraining(editPayload).then(() => {
        closeModal();
      });
    }
  };
  return (
    <>
      
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          {!trainers && !trainerLoading && showaddTraining ? (
            <AddTrainer
              title={'Add Trainer'}
              isOpen={showaddTraining}
              buttonText='Save'
              closeModal={() => setShowaddTraining(false)}
            />
          ) : null}
          <form className='form' onSubmit={handleSubmit(submitForm)}>
            <div className='pl-8 pr-8 pt-4'>
              <div className='flex flex-row justify-between items-center'>
                <span className='header-4'>{title}</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='close' />
                </div>
              </div>
            </div>
            <hr className='divider mb-2' />
            <div className='flex flex-col mx-8'>
              {trainerChangeType === 'add' ? (
                <>
                  <div className='border bg-highlight p-2 rounded-md'>
                    <label className='text-[13px] leading-[23px] font-normal text-color-gray mb-1'>
                      Select Multiple Employees
                    </label>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      className='text-[13px]'
                      loadOptions={loadOptions}
                      onChange={(options) => setEmployees(options)}
                      classNamePrefix='mySelect'
                    />
                  </div>
                  <hr className='divider mt-3 mb-0' />
                </>
              ) : null}
              
              <div className='flex flex-row md:flex-col w-full justify-between mt-2'>
                <div className='w-full'>
                  <FormInput
                    label='Training Name'
                    name='training_name'
                    type='text'
                    placeholder={'Enter training title'}
                    value={trainingName}
                    onInput={(e) => {
                      setTrainingName(e.target.value);
                    }}
                    error={errors.training_name}
                    errorMessage={
                      errors.training_name && errors.training_name.message
                    }
                  />
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                {trainers ? (
                  <div className='w-full md:pr-2 md:w-1/2'>
                    <div className='w-full'>
                      <FormSelect
                        value={trainerId}
                        options={trainers}
                        onChange={(selected) => {
                          setTrainerId(selected);
                        }}
                        label='Select Trainer'
                      />
                    </div>
                  </div>
                ) : null}
                <div className='w-full md:pl-2 md:w-1/2'>
                  <div className='w-full'>
                    <FormSelect
                      value={trainingType}
                      options={trainingTypeOptions}
                      onChange={(selected) => {
                        setTrainingType(selected);
                      }}
                      label='Select Training Type'
                    />
                  </div>
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:pr-2 md:w-1/2'>
                  <FormInput
                    label='Training Start'
                    name='training_start'
                    type='date'
                    value={trainingStart}
                    onInput={(e) => {
                      setTrainingStart(e.target.value);
                    }}
                    error={errors.start_date}
                    errorMessage={
                      errors.start_date && errors.start_date.message
                    }
                  />
                </div>
                <div className='w-full md:pl-2 md:w-1/2'>
                  <FormInput
                    label='Training End'
                    name='training_date'
                    type='date'
                    value={trainingEnd}
                    onInput={(e) => {
                      setTrainingEnd(e.target.value);
                    }}
                    error={errors.end_date}
                    errorMessage={errors.end_date && errors.end_date.message}
                  />
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:pr-2 md:w-2/3'>
                  <FormInput
                    label='Training Location'
                    name='training_loaction'
                    type='text'
                    placeholder={'Enter training location'}
                    value={trainingLocation}
                    onInput={(e) => {
                      setTrainingLocation(e.target.value);
                    }}
                    error={errors.training_loaction}
                    errorMessage={
                      errors.start_date && errors.training_loaction.message
                    }
                  />
                </div>
                <div className='w-full md:pl-2 md:w-1/3'>
                  <FormInput
                    label='Training Cost'
                    name='training_cost'
                    type='number'
                    placeholder={'Enter training cost'}
                    value={trainingCost}
                    onInput={(e) => {
                      setTrainingCost(e.target.value);
                    }}
                    error={errors.training_cost}
                    errorMessage={
                      errors.training_cost && errors.training_cost.message
                    }
                  />
                </div>
              </div>
              <FormInput
                label='Description'
                name='description'
                type='text'
                placeholder={'Enter training description'}
                value={description}
                onInput={(e) => {
                  setDescription(e.target.value);
                }}
                error={errors.description}
                errorMessage={errors.description && errors.description.message}
              />
              <div className='w-full mt-[20px] pb-4 '>
                <Button
                  text={buttonText}
                  type='submit'
                  loading={addTrainingloading || editTrainingloading}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddTraining;
