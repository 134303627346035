import { Axios } from '../axios';

const getAirtimes = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get( `/airtimes?limit=${payload.limit}&page=${payload.page}${filter}${search}`, );

  return data?.data;
};

const getAirtimeHistory = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';

  const { data } = await Axios.get(
    `/airtime/transactions-history?limit=${payload.limit}&page=${payload.page}${search}${filter}${start_date}${end_date}`,
  );

  return data?.data;
};

const singleAirtime = async (payload) => {
  const { data } = await Axios.post('/airtime/run', payload);
  return data;
};

const bulkAirtime = async (payload) => {
  const { data } = await Axios.post('/airtime/run/bulk', payload,
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const fetchAirtimeByRef = async (payload) => {

  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(`/airtime/by-ref/${payload.refId}?limit=${payload.limit}&page=${payload.page}${filter}${search}`, );
  if (data.data) {
    data.data.airtimes.total_amount = data?.data.total_amount
    data.data.airtimes.total_payable = data?.data.total_amount
    data.data.airtimes.total_trans_cost = 0
  }
  return data?.data.airtimes;
};

const removeAirtimeById = async (payload) => {
  const { data } = await Axios.delete(`/airtime/remove-item/${payload}`);
  return data;
};

const initiateAirtime = async (payload) => {
  const { data } = await Axios.post(`/airtime/initiate`, payload );
  return data;
};

const declineAirtime = async (payload) => {
  const { data } = await Axios.get(`/airtime/decline/${payload}`, );
  return data;
};

const updateAirtimePurchase = async (payload) => {
  const { data } = await Axios.put(`/airtime/update/${payload.id}`, payload.data );
  return data;
};

const disburseAirtime = async (payload) => {
  const { data } = await Axios.post('/airtime/disburse', payload,
  {
    timeout: 0
  });
  return data;
};

export const airtimeInformation = {
  getAirtimes,
  getAirtimeHistory,
  singleAirtime,
  bulkAirtime,
  fetchAirtimeByRef,
  removeAirtimeById,
  initiateAirtime,
  declineAirtime,
  disburseAirtime,
  updateAirtimePurchase
};