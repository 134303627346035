import React, { useEffect, useRef, useState } from 'react';
import TickSVG from '../../assets/svg/tick-primary.svg';
import { useHistory } from 'react-router-dom';
import Logo from '../logo';
import { Initials } from '../../utils/functions';
import { setSection } from '../../redux/section/reducer';
import { store } from '../../redux';

const SingleRecruitmentApplicationLayout = ({
  children,
  step,
  title,
  company_name,
  logo,
  expired = true,
}) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    {
      title: 'Recruitment Brief',
      subtitle: 'Quickly review information about this job to see if it is a good fit before you apply.',
      completed: false,
    },
    {
      title: 'Application Page',
      subtitle: 'Enter your information to apply for this job',
      completed: false,
    },
    {
      title: 'Recruitment Questions',
      subtitle: 'There are some questions that your recruiter wants you to answer for this job.',
      completed: false,
    },
    {
      title: 'Application Summary',
      subtitle: 'Final review before you submit.',
      completed: false,
    },
  ];

  useEffect(() => {
    if (step) {
      setCurrentStep(step);
    }
  }, [step, currentStep]);

  useEffect(() => {
    store.dispatch(setSection({
        entryRoute: '/dashboard',
        name: 'core hr',
        sidebarItems: []
    }))
  }, [])

  return (
    <div
      className='flex flex-col min-h-screen max-h-screen overflow-auto'
    >
      <div className='flex flex-col lg:bg-hr-primary-1'>
        {/* Desktop Sidebar */}
        <div className='flex flex-col justify-center lg:justify-start lg:px-0'>
          <div className='flex flex-col mt-3'>
            {/* Mobile Stepper */}
            <div className='flex lg:hidden justify-center w-full'>
              <div className='flex flex-row w-[85%] my-3'>
                {steps.map((item, index) => (
                  <>
                    <div
                      className={`${
                        index + 1 !== steps.length
                          ? 'flex flex-row w-full items-center'
                          : ''
                      }`}
                    >
                      <div>
                        <div
                          className={`flex items-center justify-center w-[30px] h-[30px] rounded-[30px] ${
                            index < currentStep - 1
                              ? 'bg-secondary-2'
                              : 'border border-color-black'
                          }  ${
                            index < currentStep - 1
                              ? 'opacity-100'
                              : 'opacity-40'
                          }`}
                        >
                          {index < currentStep - 1 ? (
                            <img
                              src={TickSVG}
                              alt='tick'
                              style={{ width: '16px', height: '16px' }}
                            />
                          ) : (
                            <span
                              className={`text-[16px] font-bold ${
                                index + 1 <= currentStep
                                  ? 'text-white'
                                  : 'text-color-black'
                              }`}
                            >
                              {index + 1}
                            </span>
                          )}
                        </div>
                      </div>

                      {index + 1 !== steps.length ? (
                        <div className='bg-color-border w-full h-[1px]'></div>
                      ) : null}
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className='flex flex-col lg:flex-row w-full'>
              <div className='w-full lg:w-[35%] flex flex-col lg:justify-start justify-center pr-4 pt-4'>
                <div className='w-full lg:w-[78%] flex flex-col'>
                  {/* Header */}
                  <span className='header-3'>{title}</span>
                </div>
                {/* Desktop Stepper */}
                <div className='hidden lg:flex w-full flex-col justify-between h-full pb-2'>
                  <div className='flex flex-col w-[85%] ml-[12%]'>
                    <div className='flex flex-row items-center gap-4'>
                      <div className='text-[25px] flex justify-center items-center h-[60px] w-[60px] border-[3px] header-3 rounded-[60px] relative hover:scale-110 hover:m-2 hover:opacity-25 duration-500 text-white font-bold'>
                        {logo ? (
                          <div>
                            <img
                              src={logo}
                              alt='email_icon'
                              className='object-fit min-w-[52px] min-h-[52px] max-w-[52px] max-h-[52px] rounded-[52px]'
                            />
                          </div>
                        ) : (
                          <span className='text-white font-bold text-[30px]'>{Initials(company_name)}</span>
                        )}
                      </div>

                      <button
                        className='hidden lg:flex text-color-white header-3 text-[25px]'
                      >
                        {company_name}
                      </button>
                    </div>

                    <div className='mt-3 flex flex-col p4 mb-4'>
                      <span className='text-white text-left'>
                        Welcome to the Recruitment Application page. View the
                        job information and complete the application form to
                        begin the recruitment process.
                      </span>
                    </div>
                    {expired ? null : (
                      <>
                        {steps.map((item, index) => (
                          <div className='flex flex-row mb-[50px]'>
                            <div className='flex items-center w-[15%]'>
                              <div
                                className={`flex items-center justify-center  w-[50px] h-[50px] rounded-[50px] ${
                                  index + 1 <= currentStep
                                    ? 'bg-white'
                                    : 'border border-color-black bg-white'
                                }  ${
                                  index + 1 == currentStep
                                    ? 'opacity-100'
                                    : 'opacity-40 '
                                }`}
                              >
                                {index < currentStep - 1 ? (
                                  <img
                                    src={TickSVG}
                                    alt='tick'
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                ) : (
                                  <span
                                    className={`header-3 ${
                                      index + 1 <= currentStep
                                        ? 'text-secondary-2'
                                        : 'text-secondary-2'
                                    }`}
                                  >
                                    {index + 1}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='flex flex-col w-[85%] justify-center pl-3'>
                              <span
                                className={`text-[18px] font-bold text-white leading-[23px] ${
                                  index + 1 == currentStep
                                    ? 'text-opacity-100'
                                    : 'text-opacity-50'
                                }`}
                              >
                                {item.title}
                              </span>
                              <div className='w-[80%] mt-[4px] text-justify'>
                                <span
                                  className={`text-[13px] leading-[20px] text-white text-justify ${
                                    index + 1 === currentStep
                                      ? 'text-opacity-80'
                                      : 'text-opacity-40'
                                  } `}
                                >
                                  {item.subtitle}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <div
                    className={
                      'flex items-center justify-center text-color-white gap-2'
                    }
                  >
                    Powered By:{' '}
                    <a
                      href='https://www.ropay.africa'
                      target={'_blank'}
                      rel='noreferrer'
                      className='mt-2'
                    >
                      {<Logo width='70px' height='35px' white />}
                    </a>
                  </div>
                </div>
              </div>

              <div className='flex md:w-[65%] w-full -mt-6 min-h-screen'>
                <div className='w-full bg-white h-full'>
                  {/* Page components */}
                  <div className='overflow-auto h-full'>
                    <div className='w-full flex items-center justify-center flex-col'>
                      <div className='w-full lg:max-w-[700px] flex flex-col lg:flex-row '>
                        <div className='w-full p-2 pb-[30px] h-full'>
                          {children}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleRecruitmentApplicationLayout;
