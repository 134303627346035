import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '..';
import { walletInformation } from '../../api/wallet';
import { setAlert, setIsLoading } from '../components/components-slice';
import moment from 'moment';
import { updateUser } from '../user/hook';
import { queryClient } from '../..';

export function useGetWalletHistory(limit, page, statusFilter, search, start_date, end_date,  type ) {
  return useQuery(
    ['wallet-history', { limit, page, statusFilter, search, start_date, end_date,  type }],
    () => {
      store.dispatch(setIsLoading(true));
      return walletInformation.getWalletHistory({
        limit,
        page,
        statusFilter, 
        search, 
        start_date,
        end_date,
        type
      });
    },

    {
      select(data) {
        data?.data?.forEach((data) => {
          data.created = moment(data.created).format('MMMM DD, YYYY h:m:s')
          if (data.type === 'wallet') {
            data.status = !data.status || data.status == 0 ? 1: data.status
            data.credit = parseFloat(data.amount);
            data.debit = ''
            data.name = "Wallet Deposit"
            data.fees = ''
          } else {
            data.status = !data.status? 0: data.status
            data.credit = ''
            data.debit = parseFloat(data.amount);
          }
          switch (data.status) {
            case 0:
              data.computedActions = [0];
              break;
            case 2:
              data.computedActions = [1, 2];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetBranchWalletHistory(id, limit, page, statusFilter, search, start_date, end_date,  type ) {
  return useQuery(
    ['branch-wallet-history', { id, limit, page, statusFilter, search, start_date, end_date,  type }],
    () => {
      store.dispatch(setIsLoading(true));
      return walletInformation.getBranchWalletHistory({
        id,
        limit,
        page,
        statusFilter, 
        search, 
        start_date,
        end_date,
        type
      });
    },

    {
      select(data) {
        data?.data?.forEach((data) => {
          data.created = data.created.split('.')[0].replace('T', ' ')
          if (data.type === 'wallet') {
            data.status = !data.status || data.status == 0 ? 1: data.status
            data.credit = parseFloat(data.amount);
            data.debit = ''
            data.name = "Wallet Deposit"
            data.fees = ''
          } else {
            data.status = !data.status? 0: data.status
            data.credit = ''
            data.debit = parseFloat(data.amount);
          }
          switch (data.status) {
            case 0:
              data.computedActions = [0];
              break;
            case 2:
              data.computedActions = [1];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useExportTransactionsHistory() {

  return useMutation(
    (payload) => {
      store.dispatch(setAlert(true, 'success', 'Fetching Export Data...'));
      return walletInformation.getWalletHistory(payload);
    },
    {
      onSuccess: (data) => {
        data?.data?.forEach((data) => {
          if (data.type === 'wallet') {
            data.status = !data.status || data.status == 0 ? 1: data.status
            data.credit = data.amount
            data.debit = ''
            data.name = "Wallet Deposit"
            data.fees = ''
          } else {
            data.status = !data.status? 0: data.status
            data.credit = ''
            data.debit = data.amount
          }
        });
        store.dispatch(setAlert(true, 'success', 'Transactions fetched Successfully'));
        return data;
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetCompanyAccountDetails() {
  return useQuery(
    ['company-wallet-details'],
    () => {
      return walletInformation.getAccountDetails();
    },
    {
      onSuccess: (response, variables, context) => {
        updateUser({reload: false})
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRetryToAnotherBank() {
  return useMutation(
    (payload) => {
      return walletInformation.retryTransactionToAnotherBank(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('payroll-history');
        queryClient.invalidateQueries('vendor-history');
        queryClient.invalidateQueries('wallet-history');
        store.dispatch(setAlert(true, 'success', 'Transaction retried successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCheckBVNVerification() {
  return useMutation(
    () => {
      return walletInformation.checkBVNVerification();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Wallet account information generated successfully.'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useVerifyBVN() {
  return useMutation(
    (payload) => {
      return walletInformation.verifyBVN(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Your BVN has been verified successfully'));
        updateUser({ reload: false });
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGenerateWalletDetails() {
  return useMutation(
    (payload) => {
      return walletInformation.generateWalletDetails(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Wallet account information generated successfully.'));
        updateUser({ reload: false });
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
