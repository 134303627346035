import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'invoice',
  initialState: {
    invoice: null,
  },
  reducers: {
    setInvoice: (state, { payload }) => {
      state.invoice = payload;
    },
  },
});

// Actions
export const { setInvoice } = slice.actions;

export default slice.reducer;