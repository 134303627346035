import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useSelector } from "react-redux";
import FormSelect from "../../../components/form-select";
import ComparePerformance from "../../../components/hr-section/performance/performance-analysis/compare-performance";
import PerformanceRanking from "../../../components/hr-section/performance/performance-analysis/performance-ranking";
import EmptyTableSVG from "../../../assets/svg/empty-table.svg";

const PerformanceAnalysis = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [source, setSource] = useState(null);
  const sourceOptions = [
    { label: "Select Analysis Type", value: null },
    { label: "Compare", value: "compare" },
    { label: "Ranking", value: "ranking" },
  ];

  return (
    <DashboardLayout title="Performance Analysis">
      <div className="flex flex-col -mt-4">
        <div className="flex flex-col bg-white mt-[10px] rounded-md">
          <div className="px-4">
            <div className="flex flex-col md:flex-row justify-between items-center px-4 md:gap-4 my-1 mt-3 border bg-highlight rounded-md pt-1">
              <div className="w-full md:w-1/3">
                <FormSelect
                  defaultValue={source}
                  options={sourceOptions}
                  onChange={(selected) => {
                    setSource(selected);
                  }}
                  label="Select Analysis Type"
                />
              </div>
            </div>
          </div>
          <hr className="divider mt-2 mb-2" />
          {source == "compare" ? (
            <ComparePerformance />
          ) : source == "ranking" ? (
            <PerformanceRanking />
          ) : (
            <div className="flex flex-col items-center justify-center w-full min-h-[80px] p-4">
              <span className="text-hr-primary-1 font-bold">
                No performance analysis type selected
              </span>
              <div className="flex items-center justify-center mt-2 select-none">
                <img
                  src={EmptyTableSVG}
                  alt="no_analysis"
                  width={"70%"}
                  height={"150px"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PerformanceAnalysis;
