import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import HRLeaveOverviewTab from "../../../components/hr-section/leave/overview";
import AddLeave from "../../../components/modal/hr-section/leave/add-leave";
import LeaveSettings from "../../../components/hr-section/leave/leave-policy";
import HRLeaveTypeTab from "../../../components/hr-section/leave/leave-type";
import AddLeaveType from "../../../components/modal/hr-section/leave/add-leave-type";
import EmployeeLeaveSVG from "../../../assets/svg/employee-leave.svg";
import EmployeeLeavePendingSVG from "../../../assets/svg/employee-leave-pending.svg";
import EmployeeLeaveUpcomingSVG from "../../../assets/svg/employee-leave-active.svg";
import { CardHr } from "../../../components/modal/hr-section/dashboard/cardHR";
import { useGetLeaveSummary } from "../../../redux/human-resources/hook/leave";
import LoadingBar from "../../../components/loader-bar";
import UnauthorizedPage from "../../errors/unauthorized";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const HRLeavePage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  const [currentLocation, setCurrentLocation] = useState("applications");

  const location = useLocation();

  useEffect(() => {
    if (location) {
      setCurrentLocation(location.pathname.split("/")[2]);
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.leave_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [showAddLeave, setShowAddLeave] = useState(false);
  const [showAddLeaveType, setShowAddLeaveType] = useState(false);
  const { data: getLeaveSummary, isLoading: summaryLoading } =
    useGetLeaveSummary();
  const [leaveSummary, setLeaveSummary] = useState([]);

  useEffect(() => {
    if (getLeaveSummary) {
      setLeaveSummary([
        {
          title: "ACTIVE LEAVE",
          value: getLeaveSummary.active_leave,
          icon: EmployeeLeaveSVG,
        },
        {
          title: "AWAITING APPROVAL",
          value: getLeaveSummary.pending_leave,
          icon: EmployeeLeavePendingSVG,
        },
        {
          title: "UPCOMING LEAVE",
          value: getLeaveSummary.upcoming_leave,
          icon: EmployeeLeaveUpcomingSVG,
        },
      ]);
    }
  }, [getLeaveSummary]);

  return (
    <DashboardLayout title="Leave Management">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {showAddLeaveType ? (
            <AddLeaveType
              isOpen={showAddLeaveType}
              closeModal={() => setShowAddLeaveType(false)}
              leaveTypeData={null}
              title="Add Leave Type"
              buttonText="ADD"
            />
          ) : null}
          {showAddLeave ? (
            <AddLeave
              isOpen={showAddLeave}
              closeModal={() => setShowAddLeave(false)}
              leaveData={null}
              title="Add Leave"
              buttonText="ADD"
            />
          ) : null}
          <div className="flex flex-col -mt-4">
            <div className="flex flex-col md:flex-row justify-between items-center">
              {!summaryLoading && currentLocation !== "settings" ? (
                <div className="flex flex-col md:flex-row justify-between items-center !w-[100%]">
                  <div className="h-fit p-3 flex flex-col justify-between w-full">
                    <div className="grid md:grid-cols-3 lg:grid-cols-3 grid-cols-2 gap-2">
                      {leaveSummary?.map((item) => (
                        <CardHr
                          title={item.title}
                          subtitle={item.value}
                          icon={item.icon}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col md:flex-row justify-between items-center w-[100%] p-2 pt-4">
                  <div className="h-fit pb-3 flex flex-col justify-between w-full">
                    <div className="grid grid-cols-1 gap-4">
                      <LoadingBar loading={summaryLoading} />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              {currentLocation === "applications" ? (
                <>
                  {user?.permissions.leave_permission.leave_read === 1 ||
                  user?.permissions.company_permission.role === "Owner" ? (
                    <HRLeaveOverviewTab />
                  ) : (
                    <UnauthorizedPage />
                  )}
                </>
              ) : currentLocation === "types" ? (
                <>
                  {user?.permissions.leave_permission.leave_type_read === 1 ||
                  user?.permissions.company_permission.role === "Owner" ? (
                    <HRLeaveTypeTab />
                  ) : (
                    <UnauthorizedPage />
                  )}
                </>
              ) : currentLocation === "settings" ? (
                <>
                  {user?.permissions.leave_permission.leave_type_read === 1 ||
                  user?.permissions.company_permission.role === "Owner" ? (
                    <LeaveSettings />
                  ) : (
                    <UnauthorizedPage />
                  )}
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default HRLeavePage;
