import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import AsyncSelect from 'react-select/async';
import { employees as employeeAPI } from '../../../../api/employees';
import { setAlert } from '../../../../redux/components/components-slice';
import {
  useAddEmployeesToTraining,
  useGetSingleTraining,
} from '../../../../redux/employees/hook/training';
import Modal from '../../modal';

function AddEmployeeToTraining({ isOpen, closeModal, trainingId, refresh }) {
  const { mutateAsync: addEmployeeToTraining, isLoading } =
    useAddEmployeesToTraining();

  const { data: training } = useGetSingleTraining(trainingId);

  const dispatch = useDispatch();

  const [employees, setEmployees] = useState([]);

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 10,
          statusFilter: -1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const submit = () => {
    if (employees.length === 0) {
      dispatch(setAlert(true, 'info', 'Select at least one employee'));
      return;
    }
    let employeeArrays = [];

    employees.forEach((item) => {
      employeeArrays.push(item.value);
    });

    let employee_ids = employeeArrays.join();

    let payload = {
      training_id: trainingId,
      employee_ids: employee_ids,
    };

    addEmployeeToTraining(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      {trainingId ? (
        <div className='flex flex-col pb-8 overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Add Employee To Training</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col mt-3'>
              <label className='text-[15px] leading-[23px] font-normal text-color-gray mb-2'>
                Training Name
              </label>
              <span className='header-4'>{training?.training_name}</span>
              <hr className='divider' />
              <div>
                <label className='text-[13px] leading-[23px] font-normal text-color-gray mb-2'>
                  Select Employees
                </label>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  defaultOptions
                  className='text-[13px]'
                  placeholder={'Type to select multiple employees'}
                  loadOptions={loadOptions}
                  onChange={(options) => setEmployees(options)}
                  classNamePrefix='mySelect'
                />
              </div>
            </div>
            <hr className='divider' />
            <div className='w-full'>
              <Button
                text='Add'
                type='button'
                loading={isLoading}
                onClick={() => submit()}
              />
            </div>
          </div>
        </div>
      ) : null}
    </Modal>
  );
}

export default AddEmployeeToTraining;
