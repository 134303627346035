import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { updateUser } from '../user/hook';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../..';
import { hmo } from '../../api/hmo/beneficiaries';
import { hmoGroups } from '../../api/hmo/hmo-groups';
import moment from 'moment';
import { formatNumber } from '../../utils/functions';

export function useAddCompanyToHMO() {
  return useMutation(
    () => {
      return hmo.addCompanyToHMO();
    },
    {
      onSuccess: (response, variables, context) => {
        updateUser({ reload: false });
        store.dispatch(setAlert(true, 'success', 'Company added to HMO successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddCompanyHMOSettings() {
  return useMutation(
    (payload) => {
      return hmo.addCompanyHMOSettings(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-settings');
        store.dispatch(setAlert(true, 'success', 'Company HMO settings added successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetHMOBeneficiaries(limit, page, statusFilter, search) {
    return useQuery(
      ['hmo-beneficiaries', { limit, page, statusFilter, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return hmo.getHMOBeneficiaries({ limit, page, statusFilter, search });
      },
      {
        select: (data) => {
          data?.data?.forEach((hmo) => {
            hmo.employeeName = hmo.first_name + ' ' + hmo.last_name
            hmo.created = moment(hmo.created).format('MMMM DD, YYYY');
            hmo.status = hmo.status? parseFloat(hmo.status) : 0;
            hmo.formatted_hmo_cost = formatNumber(hmo.hmo_cost, 2)
            switch (hmo.status) {
              case 0:
                hmo.computedActions = [0, 1];
                break;
              case 1:
                hmo.computedActions = [0];
                break;
              case 2:
                hmo.computedActions = [0, 1];
                break;
              case 3:
                hmo.computedActions = [0, 1];
                break;
              default:
                break;
            }
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetBeneficiary(id) {
  return useQuery(
    ['hmo-beneficiary', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return hmo.getHMOBeneficiary(id);
    },
    {
      enabled: id !== null,
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'HMO beneficiary fetched successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetHMOSettings() {
    return useQuery(
      ['hmo-settings'],
      () => {
        return hmo.getHMOSettings();
      },
      {
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useUpdateHMOSettings() {
  return useMutation(
    (payload) => {
      return hmo.updateHMOSettings(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-settings');
        store.dispatch(setAlert(true, 'success', 'HMO settings updated successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddBeneficiaries() {
  return useMutation(
    (payload) => {
      return hmo.addHMOBeneficiaries(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-beneficiaries');
        store.dispatch(setAlert(true, 'success', 'HMO beneficiary added successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSubscribeToHMO() {
  return useMutation(
    (payload) => {
      return hmo.subscribeHMO(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-beneficiaries');
        store.dispatch(setAlert(true, 'success', 'HMO subscription added successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateHMOStatus() {
  return useMutation(
    (payload) => {
      return hmo.changeHMOStatus(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-beneficiaries');
        store.dispatch(setAlert(true, 'success', 'HMO status updated successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

//HMO Group
export function useGetHMOGroups(limit, page, statusFilter, search) {
  return useQuery(
    ['hmo-groups', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return hmoGroups.getHMOGroups({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((group) => {
          group.computedActions = [0, 1];
          group.created = moment(group?.created).format('MMMM D, YYYY');
          if (group && group.description && group.description.length >= 30) {
            group.description = group.description.substring(0, 30) + '....';
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddHMOGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return hmoGroups.addHMOGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-groups');
        store.dispatch(setAlert(true, 'success', 'HMO group added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditHMOGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return hmoGroups.editHMOGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-groups');
        queryClient.invalidateQueries('hmo-settings');
        store.dispatch(setAlert(true, 'success', 'HMO group updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetOnboardingHMOGroups(
  limit,
  page,
  statusFilter,
  search,
) {
  return useQuery(
    ['hmo-onboarding-groups', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return hmoGroups.getHMOGroups({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No HMO Group Selected' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetHMOGroup(id) {
  return useQuery(
    ['hmo-group', { id }],
    () => {
      return hmoGroups.getHMOGroup({
        id,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        if (data) {
          data.status = 1;
        }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetHMOGroupEmployees(id, limit, page, statusFilter, search) {
  return useQuery(
    ['hmo-group-employees', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return hmoGroups.getHMOGroupEmployees({
        id,
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        data.employees.name = data?.name;
        data.employees.id = data?.id;
        data?.employees?.data?.forEach((employee) => {
          employee.created = moment(employee?.created).format('MMMM D, YYYY');
          employee.computedActions = [0];
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
        });
        return data?.employees;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetHMOGroupSettings(id) {
  return useQuery(
    ['hmo-group-settings', { id }],
    () => {
      store.dispatch(setIsLoading(true));
      return hmoGroups.getHMOGroupSettings({
        id,
      });
    },
    {
      enabled: id !== null,
      select: (data) => {
        if (data){
          data.setting.description = data?.description;
          data.setting.id = data?.id;
          data.setting.group_name = data?.name;
        }
        
        return data?.setting;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddEmployeesToHMOGroup() {
  return useMutation(
    (payload) => {
      return hmoGroups.addEmployeesToHMOGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-groups');
        store.dispatch(setAlert(true, 'success', 'Employe adedd to HMO group successfully'));
      },
    },
  );
}

export function useRemoveEmployeesFromHMOGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return hmoGroups.removeEmployeesFromHMOGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-groups');
        queryClient.invalidateQueries('group-employees');
        store.dispatch(setAlert(true, 'success', 'Employe removed from HMO group successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMoveEmployeesToHMOGroup() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return hmoGroups.moveEmployeesToHMOGroup(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('hmo-groups');
        queryClient.invalidateQueries('group-employees');
        store.dispatch(setAlert(true, 'success', 'Employe moved to HMO group successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

//HMO Group
export function useGetHMOs() {
  return useQuery(
    ['hmos', {}],
    () => {
      store.dispatch(setIsLoading(true));
      return hmo.getHMOs();
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No HMO Selected', hmo_name: '' });
        data?.forEach((item) => {
          newData.push({ value: item._id, label: item.name, hmo_name: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetOnboardingHMOPlans(hmo_id) {
  return useQuery(
    ['onboarding-hmo-plans',  hmo_id ],
    () => {
      return hmo.getHMOPlans(hmo_id);
    },
    {
      enabled: hmo_id !== null,
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No HMO Group Selected', plan_name: '' });
        data?.forEach((item) => {
          newData.push({ value: item.id, label: item.name, plan_name: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetHMOPlans(hmo_id) {
  return useQuery(
    ['hmo-plans',  hmo_id ],
    () => {
      store.dispatch(setIsLoading(true));
      return hmo.getHMOPlans(hmo_id);
    },
    {
      enabled: hmo_id !== null,
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
