import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import PerformanceSVG from "../../../assets/svg/performance-widget-icon.svg";
import { StatusText } from "../../../components/fragments/status-text";
import { performanceSatusConfig } from "../../../utils/constants";
import UnauthorizedPage from "../../errors/unauthorized";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetPerformance,
  useGradeBasicPerformance,
  useGradeOrganisationalIndicators,
  useGradeSmartGoals,
  useGradeTechnicalAppraisals,
  useMarkPerformanceReviewed,
  useUpdateReviewerComment,
  useUpdateTrainingRequirements,
} from "../../../redux/human-resources/hook/performance";
import Button from "../../../components/button";
import CollapsingIcon from "../../../components/collapsing-icon";
import { Collapse } from "reactstrap";
import classNames from "classnames";
import moment from "moment";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import FormInput from "../../../components/form-input";
import LoadingBar from "../../../components/loader-bar";
import { setAlert } from "../../../redux/components/components-slice";
import FormTextArea from "../../../components/form-textarea";
import { performanceJSX, toDataUrl } from "../../../utils/functions";
import CustomerGrowthSVG from "../../../assets/svg/employee-growth.svg";
import FInancialGrowthSVG from "../../../assets/svg/financial-growth.svg";
import BusinessProcessesSVG from "../../../assets/svg/business-processes.svg";
import LeaningGrowthSVG from "../../../assets/svg/learning-growth.svg";

const ViewPerformanceEvaluation = () => {
  useEffect(() => {}, []);
  const { id } = useParams();

  const { user } = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);
  const [unauthorized, setUnauthorized] = useState(false);
  const [showOkr, setShowOkr] = useState(false);
  const [showSmartGoals, setShowSmartGoals] = useState(false);
  const [showTechnicalAppraisals, setShowTechnicalAppraisals] = useState(false);
  const [showBalancedScorecards, setShowBalancedScorecards] = useState(false);
  const [showOganisationalAssessment, setShowOganisationalAssessment] =
    useState(false);

  const [okrFinalResult, setOkrFinalResult] = useState(0);
  const [smartGoalFinalResult, setSmartGoalFinalResult] = useState(0);
  const [technicalAppraisalFinalResult, setTechnicalAppraisalFinalResult] =
    useState(0);

  const [exportPerformanceLoading, setExportPerformanceLoading] =
    useState(false);

  const dispatch = useDispatch();

  const [performanceData, setPerformanceData] = useState(null);
  const { data: performance, isLoading } = useGetPerformance(id);

  const [trainingRequirement, setTrainingRequirement] = useState("");
  const [reviewerComment, setReviewerComment] = useState();

  const { mutateAsync: gradeSmartGoals, isLoading: gradeSmartGoalLoading } =
    useGradeSmartGoals();

  const {
    mutateAsync: gradeTechnicalAppraisals,
    isLoading: gradeTechnicalAppraisalsLoading,
  } = useGradeTechnicalAppraisals();

  const {
    mutateAsync: gradePerformanceIndicator,
    isLoading: gradeIndicatorLoading,
  } = useGradeOrganisationalIndicators();

  const { mutateAsync: markPerformance, isLoading: markPerformanceLoading } =
    useMarkPerformanceReviewed();

  const { mutateAsync: gradeBasic, isLoading: gradeBasicLoading } =
    useGradeBasicPerformance();

  const { mutateAsync: updateComment, isLoading: updateCommentLoading } =
    useUpdateReviewerComment();

  const { mutateAsync: updateTraining, isLoading: updateTrainingLoading } =
    useUpdateTrainingRequirements();

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.performance_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);
  useEffect(() => {
    if (performance) {
      setTrainingRequirement(performance.training_requirements);
      setReviewerComment(performance.reviewer_comment);
      setPerformanceData(performance);
      if (performance.okr) {
        let okr_final_score = 0;
        performance.okr.objectives.forEach((objective) => {
          let key_result_score = 0;
          let key_result_count = 0;
          objective.key_results.forEach((result) => {
            key_result_score +=
              (result.employee_grade / result.weight +
                result.team_lead_grade / result.weight) *
              100;
            key_result_count += 1;
          });
          okr_final_score += Math.round(key_result_score / key_result_count, 2);
        });
        setOkrFinalResult(okr_final_score);
      }
      if (performance.smart_goal) {
        let smart_goal_final_score = 0;
        let smart_goal_result_score = 0;
        let smart_goal_result_count = 0;
        performance.smart_goal?.goals?.forEach((goal) => {
          smart_goal_result_score +=
            (goal.employee_grade / goal.weight +
              goal.team_lead_grade / goal.weight) *
            100;
          smart_goal_result_count += 1;
        });
        smart_goal_final_score += Math.round(
          smart_goal_result_score / smart_goal_result_count,
          2
        );
        setSmartGoalFinalResult(smart_goal_final_score);
      }
      if (performance.technical_appraisal) {
        let technical_appraisal_final_score = 0;
        let technical_appraisal_result_score = 0;
        let technical_appraisal_result_count = 0;
        performance.technical_appraisal?.appraisals?.forEach((appraisal) => {
          technical_appraisal_result_score +=
            (appraisal.employee_grade / appraisal.weight +
              appraisal.team_lead_grade / appraisal.weight) *
            100;
          technical_appraisal_result_count += 1;
        });
        technical_appraisal_final_score += Math.round(
          technical_appraisal_result_score / technical_appraisal_result_count,
          2
        );
        setTechnicalAppraisalFinalResult(technical_appraisal_final_score);
      }
    }
  }, [performance]);

  const markPerformanceReviewed = async () => {
    markPerformance(id);
  };

  const gradeOrganisationalPerformance = async () => {
    let payload = {
      performance_id: id,
      indicators: performanceData?.organisational_assessments,
    };
    await gradePerformanceIndicator(payload);
  };

  const [imageBlob, setImageBlob] = useState(null);

  const changeImage = async (imageLink) => {
    let urlBase64 = await toDataUrl(imageLink);
    setImageBlob(urlBase64);
  };

  useEffect(() => {
    if (company) {
      changeImage(company.logo);
    }
    return setImageBlob(null);
  }, [company]);

  const printHandler = () => {
    setExportPerformanceLoading(true);
    const printElement = ReactDOMServer.renderToString(
      performanceJSX(company, performanceData, imageBlob)
    );
    var opt = {
      margin: 0,
      filename: performanceData?.first_name + "-performance.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      pagebreak: { mode: "div", before: "#page1el" },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(opt).from(printElement).save();
    setExportPerformanceLoading(false);
  };

  const gradeSmartGoalsFn = async () => {
    let payload = {
      performance_id: id,
      hr_comment: performanceData?.smart_goal?.hr_comment,
      goal_id: performanceData?.smart_goal?.id,
      goals_list: performanceData?.smart_goal?.goals,
    };
    await gradeSmartGoals(payload);
  };

  const gradeTechnicalAppraisalsFn = async () => {
    let payload = {
      performance_id: id,
      hr_comment: performanceData?.technical_appraisal?.hr_comment,
      technical_appraisal_id: performanceData?.technical_appraisal?.id,
      technical_appraisal_list:
        performanceData?.technical_appraisal?.appraisals,
    };
    await gradeTechnicalAppraisals(payload);
  };

  const gradeBasicPerformance = async () => {
    let payload = {
      id: id,
      data: {
        result: performanceData?.results,
      },
    };
    await gradeBasic(payload);
  };

  const updateTrainingRequirement = async () => {
    let payload = {
      id: id,
      data: {
        training_requirements: trainingRequirement,
      },
    };
    await updateTraining(payload);
  };

  const updateReviewerComment = async () => {
    let payload = {
      id: id,
      data: {
        reviewer_comment: reviewerComment,
      },
    };
    await updateComment(payload);
  };

  return (
    <DashboardLayout title="Recruitment Applicant Page">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col -mt-2">
          {isLoading ? (
            <LoadingBar loading={isLoading} />
          ) : (
            <>
              <div className="flex flex-row items-center box-shadow h-[80px] rounded">
                <div className="flex flex-row md:px-[55px] justify-between  w-full">
                  <div className="flex flex-row items-center">
                    <div className="flex font-bold justify-center items-center md:h-[58px] md:w-[58px] md:rounded-[58px] h-[45px] w-[45px] rounded-[45px] border-[3px] header-3 border-[#0d5225] ">
                      <img
                        src={PerformanceSVG}
                        alt={"performance"}
                        className={"md:h-[35px] md:w-[35px] h-[30px] w-[30px]"}
                      />
                    </div>
                    <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                      <span className="text-[15px] font-extrabold">
                        {performanceData?.first_name +
                          " " +
                          performanceData?.last_name}
                      </span>
                      <span className="text-[13px] text-color-gray">
                        {performanceData?.mobile}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <StatusText
                      statusConfig={performanceSatusConfig}
                      code={performanceData?.review_status}
                      className={"rounded px-3 p2-bold text-[13px]"}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col bg-white mt-4 rounded">
                <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] p-2 pt-3">
                  <div className="header-4">Performance Review</div>
                  <div className="flex flex-col md:flex-row md:w-1/2 w-full items-start gap-[10px] md:items-center justify-end">
                    <div className="md:w-fit w-[100%]">
                      <Button
                        text={
                          performanceData?.review_status === 1
                            ? "Mark not reviewed"
                            : "Mark as reviewed"
                        }
                        type="button"
                        theme="primary"
                        className={
                          performanceData?.review_status === 1
                            ? "flex gap-2 !h-[35px] md:w-fit w-full px-2 bg-error"
                            : "flex gap-2 !h-[35px] md:w-fit w-full px-2 "
                        }
                        loading={markPerformanceLoading}
                        textClass={"!text-[11px]"}
                        onClick={() => {
                          markPerformanceReviewed();
                        }}
                      />
                    </div>
                    {performanceData?.review_status === 1 ? (
                      <div className="md:w-fit w-[100%]">
                        <Button
                          text="Export as PDF"
                          type="button"
                          theme="primary"
                          leftIcon={"export"}
                          className="flex gap-2 !h-[35px] md:w-fit w-full px-2 "
                          loading={exportPerformanceLoading}
                          textClass={"!text-[11px]"}
                          onClick={() => {
                            printHandler();
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <hr className="divider mt-2 mb-2" />
                <div className="flex flex-col gap-3 pr-8 pl-8">
                  {performanceData?.type === "basic" ? (
                    <>
                      <div className="flex flex-col p-4 border gap-3 rounded-md">
                        <div className="grid md:grid-cols-2  grid-cols-2 gap-4 w-full mb-2">
                          <div className="flex gap-2 col-span-1 items-center">
                            <div className="font-bold text-[15px]">
                              Performance Review Period:
                            </div>
                            <div className="text-[14px]">
                              {moment(performanceData?.evaluation_start).format(
                                "MMMM D, YYYY"
                              )}{" "}
                              -{" "}
                              {moment(performanceData?.evaluation_end).format(
                                "MMMM D, YYYY"
                              )}
                            </div>
                          </div>
                        </div>
                        {performanceData?.results?.map((item, index) => (
                          <div className="flex flex-col p-4 border rounded hover:bg-gray-50 duration-500">
                            <div className="grid md:grid-cols-3 grid-cols-3 gap-4 w-full">
                              <div className="flex gap-2 col-span-1 items-center">
                                <div className="font-bold text-[15px]">
                                  Result {index + 1}:
                                </div>
                                <div className="text-[14px]">{item.name}</div>
                              </div>
                              <div className="flex gap-2 items-center">
                                <div className="font-bold text-[13px]">
                                  HR Grade:
                                </div>
                                <div className="flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1">
                                  <FormInput
                                    name="grade"
                                    type="number"
                                    value={item.grade}
                                    className="!h-[30px] !w-[80px]"
                                    onChange={(e) => {
                                      if (e.target.value <= item.max_grade) {
                                        let newArray = JSON.parse(
                                          JSON.stringify(performanceData)
                                        );
                                        newArray.results[index].grade =
                                          e.target.value;
                                        setPerformanceData(newArray);
                                      } else {
                                        let newArray = JSON.parse(
                                          JSON.stringify(performanceData)
                                        );
                                        newArray.results[index].grade = 0;
                                        setPerformanceData(newArray);
                                        dispatch(
                                          setAlert(
                                            true,
                                            "info",
                                            "Grade cannot be greater than total weight"
                                          )
                                        );
                                      }
                                    }}
                                  />
                                  /{item.max_grade}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {performanceData?.results?.length > 0 ? (
                          <div className="flex justify-end w-full mt-[10px]">
                            <Button
                              className={"!w-fit !h-[35px] p-2"}
                              loading={gradeBasicLoading}
                              textClass={"!text-[11px]"}
                              onClick={() => gradeBasicPerformance()}
                              text={"UPDATE GRADES"}
                              type="button"
                            />
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col p-2 border gap-3 rounded-md">
                      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full mb-2 p-2 py-4 bg-highlight rounded-md border">
                        <div className="flex gap-2 col-span-1 items-center">
                          <div className="font-bold text-[14px]">
                            Performance Review Period:
                          </div>
                          <div className="text-[13px]">
                            (
                            {moment(performanceData?.evaluation_start).format(
                              "MMMM D, YYYY"
                            )}{" "}
                            -{" "}
                            {moment(performanceData?.evaluation_end).format(
                              "MMMM D, YYYY"
                            )}
                            )
                          </div>
                        </div>
                      </div>
                      <hr className="divider -mt-4 mb-2" />
                      {performanceData?.organisational_assessments &&
                      performanceData?.organisational_assessments.length > 0 ? (
                        <>
                          <div
                            className={classNames(
                              "flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300 select-none",
                              {
                                "pt-2 pb-2": showOganisationalAssessment,
                              }
                            )}
                            onClick={() => {
                              setShowOganisationalAssessment(
                                !showOganisationalAssessment
                              );
                            }}
                          >
                            <span>
                              General Organisational Assessment (Behaviour and
                              Technical)
                            </span>
                            <div className="pr-4">
                              <CollapsingIcon
                                defaultPosition="left"
                                isOpen={showOganisationalAssessment}
                              />
                            </div>
                          </div>
                          <div
                            className={classNames("flex flex-col", {
                              hidden: !showOganisationalAssessment,
                            })}
                          >
                            <Collapse isOpen={showOganisationalAssessment}>
                              <div className="flex flex-col p-4 border gap-3 rounded-md">
                                {performanceData?.organisational_assessments
                                  ?.length > 0 &&
                                  performanceData?.organisational_assessments?.map(
                                    (item, index) => (
                                      <div className="flex flex-col p-2 px-4 border rounded bg-white duration-500 gap-1">
                                        <div className="grid md:grid-cols-3 grid-cols-1 gap-2 w-full">
                                          <div className="flex gap-2 col-span-1 items-center">
                                            <div className="font-bold text-[14px]">
                                              Assessment Indicator:{" "}
                                              <span className="text-[14px] font-normal">
                                                {item.indicator}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="flex gap-2 col-span-1 items-center">
                                            <div className="font-bold text-[13px]">
                                              Indicator Type:
                                            </div>
                                            <div className="text-[12px]">
                                              {item.type}
                                            </div>
                                          </div>
                                        </div>
                                        {performanceData?.review_status ===
                                        1 ? (
                                          <div className="grid md:grid-cols-4 grid-cols-1 w-full gap-2 p-2 border rounded-md bg-gray-50">
                                            <div className="flex gap-2 items-center">
                                              <div className="font-bold text-[12px]">
                                                Team Lead Grade:
                                              </div>
                                              <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                                {item.team_lead_grade}/
                                                {item.max_grade}
                                              </div>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                              <div className="font-bold text-[12px]">
                                                Employee Grade:
                                              </div>
                                              <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                                {item.employee_grade}/
                                                {item.max_grade}
                                              </div>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                              <div className="font-bold text-[13px]">
                                                HR Grade:
                                              </div>
                                              <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                                {item.hr_grade}/{item.max_grade}
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="grid md:grid-cols-4 grid-cols-1 w-full gap-2 p-2 border rounded-md">
                                            <div className="flex gap-2 items-center">
                                              <div className="font-bold text-[13px]">
                                                HR Grade:
                                              </div>
                                              <div className="flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1 -mb-2">
                                                <FormInput
                                                  name="hr_grade"
                                                  type="number"
                                                  value={item.hr_grade}
                                                  className="!h-[25px] !w-[60px] bg-white"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.value <=
                                                      item.max_grade
                                                    ) {
                                                      let newArray = JSON.parse(
                                                        JSON.stringify(
                                                          performanceData
                                                        )
                                                      );
                                                      newArray.organisational_assessments[
                                                        index
                                                      ].hr_grade =
                                                        e.target.value;
                                                      setPerformanceData(
                                                        newArray
                                                      );
                                                    } else {
                                                      let newArray = JSON.parse(
                                                        JSON.stringify(
                                                          performanceData
                                                        )
                                                      );
                                                      newArray.organisational_assessments[
                                                        index
                                                      ].hr_grade = 0;
                                                      setPerformanceData(
                                                        newArray
                                                      );
                                                      dispatch(
                                                        setAlert(
                                                          true,
                                                          "info",
                                                          "Grade cannot be greater than total weight"
                                                        )
                                                      );
                                                    }
                                                  }}
                                                />
                                                /{item.max_grade}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )}
                                {performanceData?.review_status !== 1 ? (
                                  <>
                                    {performanceData?.organisational_assessments
                                      ?.length > 0 ? (
                                      <div className="flex justify-end w-full mt-[10px]">
                                        <Button
                                          className={"!w-fit !h-[35px] p-2"}
                                          loading={gradeIndicatorLoading}
                                          textClass={"!text-[11px]"}
                                          onClick={() =>
                                            gradeOrganisationalPerformance()
                                          }
                                          text={"SUBMIT"}
                                          type="button"
                                        />
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}
                              </div>
                            </Collapse>
                          </div>
                          <hr className="divider -mt-3 -mb-3" />
                        </>
                      ) : null}
                      {performanceData?.okr ? (
                        <>
                          <div
                            className={classNames(
                              "flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300 select-none",
                              {
                                "pt-2 pb-2": showOkr,
                              }
                            )}
                            onClick={() => {
                              setShowOkr(!showOkr);
                            }}
                          >
                            <div className="flex flex-col">
                              <span>Objectives and Key Results (OKRs)</span>
                              <span className="text-gray-400 text-[13px]">
                                {performanceData?.review_status === 1
                                  ? `Final score: (${okrFinalResult}/100)`
                                  : null}
                              </span>
                            </div>

                            <div className="pr-4">
                              <CollapsingIcon
                                defaultPosition="left"
                                isOpen={showOkr}
                              />
                            </div>
                          </div>
                          <div
                            className={classNames("flex flex-col", {
                              hidden: !showOkr,
                            })}
                          >
                            <Collapse isOpen={showOkr}>
                              <div className="flex flex-col p-4 border rounded-md gap-3">
                                <div className="grid md:grid-cols-3  grid-cols-1 gap-4 w-full mb-2  p-2 bg-white rounded-md border">
                                  <div className="flex gap-2 col-span-1 items-center">
                                    <div className="font-extrabold text-[16px]">
                                      OKR Title:
                                    </div>
                                    <div className="text-[15px]">
                                      {performanceData?.okr?.okr_title}
                                    </div>
                                  </div>
                                </div>
                                {performanceData?.okr?.objectives?.map(
                                  (item, index) => (
                                    <div className="flex flex-col p-3 border rounded hover:bg-gray-50 duration-500">
                                      <div className="grid grid-cols-2 gap-4 w-full">
                                        <div className="flex gap-2 col-span-1 items-center">
                                          <div className="font-bold text-[15px]">
                                            Objective {index + 1}:
                                          </div>
                                          <div className="text-[14px]">
                                            {item.objective}
                                          </div>
                                        </div>
                                        {/* <div className='flex gap-2 col-span-2 items-center'>
                                                            <div className='font-bold text-[15px]'>
                                                                Completion Period:
                                                            </div>
                                                            <div className='text-[14px]'>
                                                                {moment(item.start_date).format('MMMM D, YYYY')} - {moment(item.end_date).format('MMMM D, YYYY')}
                                                            </div>
                                                        </div> */}
                                        <div className="flex gap-4 -mt-2 items-center">
                                          <div className="font-bold text-[15px]">
                                            Max Grade:
                                          </div>
                                          <div className="text-[14px] text-hr-secondary-1 font-extrabold">
                                            {item.max_grade}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex flex-col gap-2 mt-2">
                                        {item.key_results?.map(
                                          (subItem, subIndex) => (
                                            <div className="p-2 px-2 border rounded-md bg-white">
                                              <div className="grid md:grid-cols-2 grid-cols-1 gap-2 w-full justify-items-stretch">
                                                <div className="flex gap-2 items-center">
                                                  <div className="font-bold text-[13px]">
                                                    Key Result {subIndex + 1}:
                                                  </div>
                                                  <div className="text-[12px]">
                                                    {subItem.key_result}
                                                  </div>
                                                </div>
                                                {/* <div className='flex gap-2 items-center'>
                                                                        <div className='font-bold text-[15px]'>
                                                                            Completion Period:
                                                                        </div>
                                                                        <div className='text-[14px]'>
                                                                            {moment(subItem.start_date).format('MMMM D, YYYY')} - {moment(subItem.end_date).format('MMMM D, YYYY')}
                                                                        </div>
                                                                    </div> */}
                                              </div>
                                              <div className="grid md:grid-cols-3 grid-cols-1 w-full mt-2 p-2 border rounded-md bg-gray-50">
                                                <div className="flex gap-2 items-center">
                                                  <div className="font-bold text-[12px]">
                                                    Team Lead Grade:
                                                  </div>
                                                  <div className="text-[11px] font-extrabold">
                                                    {subItem.team_lead_grade}/
                                                    {subItem.weight}
                                                  </div>
                                                </div>
                                                <div className="flex gap-2 items-center">
                                                  <div className="font-bold text-[12px]">
                                                    Employee Grade:
                                                  </div>
                                                  <div className="text-[11px] font-extrabold">
                                                    {subItem.employee_grade}/
                                                    {subItem.weight}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                        {performanceData?.review_status !==
                                        1 ? null : (
                                          <div className="flex flex-col gap-2 mt-3">
                                            <div className="flex flex-col p-4 py-2 border rounded w-full bg-highlight">
                                              <span className="text-color-gray text-[13px]">
                                                Employee Comments:
                                              </span>
                                              <div className="flex text-black text-[13px] h-fit">
                                                {
                                                  performanceData?.okr
                                                    ?.employee_comment
                                                }
                                              </div>
                                            </div>
                                            <div className="flex flex-col p-4 py-2 border rounded w-full bg-highlight">
                                              <span className="text-color-gray text-[13px]">
                                                Team Lead Comments:
                                              </span>
                                              <div className="flex text-black text-[13px] h-fit">
                                                {
                                                  performanceData?.okr
                                                    ?.team_lead_comment
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </Collapse>
                          </div>
                          <hr className="divider -mt-3 -mb-3" />
                        </>
                      ) : null}
                      {performanceData?.smart_goal ? (
                        <>
                          <div
                            className={classNames(
                              "flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300 select-none",
                              {
                                "pt-2 pb-2": showSmartGoals,
                              }
                            )}
                            onClick={() => {
                              setShowSmartGoals(!showSmartGoals);
                            }}
                          >
                            <div className="flex flex-col">
                              <span>SMART Goals assessed</span>
                              <span className="text-gray-400 text-[13px]">
                                {performanceData?.review_status === 1
                                  ? `Final score: (${smartGoalFinalResult}/100)`
                                  : null}
                              </span>
                            </div>

                            <div className="pr-4">
                              <CollapsingIcon
                                defaultPosition="left"
                                isOpen={showSmartGoals}
                              />
                            </div>
                          </div>
                          <div
                            className={classNames("flex flex-col", {
                              hidden: !showSmartGoals,
                            })}
                          >
                            <Collapse isOpen={showSmartGoals}>
                              <div className="flex flex-col p-2 py-3 border rounded-md gap-2">
                                <div className="grid md:grid-cols-2 grid-cols-1 gap-2 w-full mb-2 -mt-2">
                                  <div className="flex gap-2 col-span-1 items-center">
                                    <div className="font-extrabold text-[14px]">
                                      SMART Goal Title:
                                    </div>
                                    <div className="text-[13px]">
                                      {
                                        performanceData?.smart_goal
                                          ?.smart_goal_title
                                      }
                                    </div>
                                  </div>
                                </div>
                                {performanceData?.smart_goal?.goals?.map(
                                  (item, index) => (
                                    <div className="flex flex-col  p-2 border rounded bg-white duration-500">
                                      <div className="grid md:grid-cols-3 grid-cols-1 gap-4 w-full">
                                        <div className="flex gap-2 col-span-1 items-center">
                                          <div className="font-bold text-[13px]">
                                            Goal {index + 1}:
                                          </div>
                                          <div className="text-[12px]">
                                            {item.smart_goal}
                                          </div>
                                        </div>
                                      </div>
                                      {performanceData?.review_status === 1 ? (
                                        <div className="grid md:grid-cols-3 grid-cols-1 w-full mt-1 p-2 border rounded">
                                          <div className="flex gap-2 items-center">
                                            <div className="font-bold text-[12px]">
                                              Team Lead Grade:
                                            </div>
                                            <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                              {item.team_lead_grade}/
                                              {item.weight}
                                            </div>
                                          </div>
                                          <div className="flex gap-2 items-center">
                                            <div className="font-bold text-[12px]">
                                              Employee Grade:
                                            </div>
                                            <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                              {item.employee_grade}/
                                              {item.weight}
                                            </div>
                                          </div>
                                          <div className="flex gap-2 items-center">
                                            <div className="font-bold text-[12px]">
                                              HR Grade:
                                            </div>
                                            <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                              {item.hr_grade}/{item.weight}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="grid md:grid-cols-3 grid-cols-1 w-full mt-3">
                                          <div className="flex gap-2 items-center -mb-2">
                                            <div className="font-bold text-[13px]">
                                              HR Grade:
                                            </div>
                                            <div className="flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1">
                                              <FormInput
                                                name="hr_grade"
                                                type="number"
                                                value={item.hr_grade}
                                                className="!h-[25px] !w-[60px] bg-white"
                                                onChange={(e) => {
                                                  if (
                                                    e.target.value <=
                                                    item.weight
                                                  ) {
                                                    let newArray = JSON.parse(
                                                      JSON.stringify(
                                                        performanceData
                                                      )
                                                    );
                                                    newArray.smart_goal.goals[
                                                      index
                                                    ].hr_grade = e.target.value;
                                                    setPerformanceData(
                                                      newArray
                                                    );
                                                  } else {
                                                    let newArray = JSON.parse(
                                                      JSON.stringify(
                                                        performanceData
                                                      )
                                                    );
                                                    newArray.smart_goal.goals[
                                                      index
                                                    ].hr_grade = 0;
                                                    setPerformanceData(
                                                      newArray
                                                    );
                                                    dispatch(
                                                      setAlert(
                                                        true,
                                                        "info",
                                                        "Grade cannot be greater than total weight"
                                                      )
                                                    );
                                                  }
                                                }}
                                              />
                                              /{item.weight}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )
                                )}
                                {performanceData?.review_status !== 1 ? (
                                  <div className="flex flex-col items-start gap-3 md:items-start justify-end w-full">
                                    <div className="flex w-[100%]">
                                      <FormTextArea
                                        label={"HR Comment - (Optional)"}
                                        type="text"
                                        name="description"
                                        className={
                                          "md:min-w-[600px] text-[13px]"
                                        }
                                        readOnly={
                                          performanceData?.review_status === 1
                                            ? true
                                            : false
                                        }
                                        placeholder={"Enter evaluation comment"}
                                        value={
                                          performanceData?.smart_goal
                                            ?.hr_comment
                                        }
                                        onChange={(e) => {
                                          let newArray = JSON.parse(
                                            JSON.stringify(performanceData)
                                          );
                                          newArray.smart_goal.hr_comment =
                                            e.target.value;
                                          setPerformanceData(newArray);
                                        }}
                                      />
                                    </div>
                                    <div className="flex w-full justify-end">
                                      <Button
                                        text={"Submit"}
                                        type="button"
                                        theme="primary"
                                        className="flex gap-2 h-[35px] w-fit px-3"
                                        loading={gradeSmartGoalLoading}
                                        textClass={"!text-[12px]"}
                                        onClick={() => {
                                          gradeSmartGoalsFn();
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex flex-col gap-2">
                                    <div className="flex flex-col p-4 py-2 border rounded w-full mt-2 bg-highlight">
                                      <span className="text-color-gray text-[13px]">
                                        HR Comments:
                                      </span>
                                      <div className="flex text-black text-[13px] h-fit">
                                        {
                                          performanceData?.smart_goal
                                            ?.hr_comment
                                        }
                                      </div>
                                    </div>
                                    <div className="flex flex-col p-4 py-2 border rounded w-full bg-highlight">
                                      <span className="text-color-gray text-[13px]">
                                        Employee Comments:
                                      </span>
                                      <div className="flex text-black text-[13px] h-fit">
                                        {
                                          performanceData?.smart_goal
                                            ?.employee_comment
                                        }
                                      </div>
                                    </div>
                                    <div className="flex flex-col p-4 py-2 border rounded w-full bg-highlight">
                                      <span className="text-color-gray text-[13px]">
                                        Team Lead Comments:
                                      </span>
                                      <div className="flex text-black text-[13px] h-fit">
                                        {
                                          performanceData?.smart_goal
                                            ?.team_lead_comment
                                        }
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Collapse>
                          </div>
                          <hr className="divider -mt-3 mb-2" />
                        </>
                      ) : null}
                      {performanceData?.technical_appraisal ? (
                        <>
                          <div
                            className={classNames(
                              "flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300 select-none",
                              {
                                "pt-2 pb-2": showTechnicalAppraisals,
                              }
                            )}
                            onClick={() => {
                              setShowTechnicalAppraisals(
                                !showTechnicalAppraisals
                              );
                            }}
                          >
                            <div className="flex flex-col">
                              <span>Technical Appraisals assessed</span>
                              <span className="text-gray-400 text-[13px]">
                                {performanceData?.review_status === 1
                                  ? `Final score: (${technicalAppraisalFinalResult}/100)`
                                  : null}
                              </span>
                            </div>

                            <div className="pr-4">
                              <CollapsingIcon
                                defaultPosition="left"
                                isOpen={showTechnicalAppraisals}
                              />
                            </div>
                          </div>
                          <div
                            className={classNames("flex flex-col", {
                              hidden: !showTechnicalAppraisals,
                            })}
                          >
                            <Collapse isOpen={showTechnicalAppraisals}>
                              <div className="flex flex-col p-4 border rounded-md gap-3">
                                <div className="grid md:grid-cols-1  grid-cols-1 gap-4 w-full -mb-2">
                                  <div className="flex gap-2 col-span-1 items-center">
                                    <div className="font-extrabold text-[16px]">
                                      Appraisal Title:
                                    </div>
                                    <div className="text-[15px]">
                                      {
                                        performanceData?.technical_appraisal
                                          ?.technical_appraisal_title
                                      }
                                    </div>
                                  </div>
                                </div>
                                {performanceData?.technical_appraisal?.appraisals?.map(
                                  (item, index) => (
                                    <div className="flex flex-col p-2 border rounded-md bg-secondary-2-extralight duration-500">
                                      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full">
                                        <div className="flex gap-2 col-span-1 items-center">
                                          <div className="font-bold text-[15px]">
                                            Appraisal {index + 1}:
                                          </div>
                                          <div className="text-[14px]">
                                            {item.appraisal}
                                          </div>
                                        </div>
                                      </div>

                                      {performanceData?.review_status === 1 ? (
                                        <div className="grid md:grid-cols-3 grid-cols-1 w-full mt-3">
                                          <div className="flex gap-2 items-center">
                                            <div className="font-bold text-[13px]">
                                              Team Lead Grade:
                                            </div>
                                            <div className="text-[13px] text-hr-secondary-1 font-extrabold">
                                              {item.team_lead_grade}/
                                              {item.weight}
                                            </div>
                                          </div>
                                          <div className="flex gap-2 items-center">
                                            <div className="font-bold text-[13px]">
                                              Employee Grade:
                                            </div>
                                            <div className="text-[13px] text-hr-secondary-1 font-extrabold">
                                              {item.employee_grade}/
                                              {item.weight}
                                            </div>
                                          </div>
                                          <div className="flex gap-2 items-center">
                                            <div className="font-bold text-[13px]">
                                              HR Grade:
                                            </div>
                                            <div className="text-[13px] text-hr-secondary-1 font-extrabold">
                                              {item.hr_grade}/{item.weight}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="flex gap-2 items-center -mb-2">
                                          <div className="font-bold text-[13px]">
                                            HR Grade:
                                          </div>
                                          <div className="flex items-center text-[13px] text-hr-secondary-1 font-extrabold gap-1">
                                            <FormInput
                                              name="hr_grade"
                                              type="number"
                                              value={item.hr_grade}
                                              className="!h-[25px] !w-[60px] bg-white"
                                              onChange={(e) => {
                                                if (
                                                  e.target.value <= item.weight
                                                ) {
                                                  let newArray = JSON.parse(
                                                    JSON.stringify(
                                                      performanceData
                                                    )
                                                  );
                                                  newArray.technical_appraisal.appraisals[
                                                    index
                                                  ].hr_grade = e.target.value;
                                                  setPerformanceData(newArray);
                                                } else {
                                                  let newArray = JSON.parse(
                                                    JSON.stringify(
                                                      performanceData
                                                    )
                                                  );
                                                  newArray.technical_appraisal.appraisals[
                                                    index
                                                  ].hr_grade = 0;
                                                  setPerformanceData(newArray);
                                                  dispatch(
                                                    setAlert(
                                                      true,
                                                      "info",
                                                      "Grade cannot be greater than total weight"
                                                    )
                                                  );
                                                }
                                              }}
                                            />
                                            /{item.weight}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )
                                )}
                                {performanceData?.review_status !== 1 ? (
                                  <div className="flex flex-col items-start gap-3 md:items-start justify-end w-full">
                                    <div className="flex w-[100%]">
                                      <FormTextArea
                                        label={"Employee Comment - (Optional)"}
                                        type="text"
                                        name="description"
                                        className={
                                          "md:min-w-[600px] text-[13px]"
                                        }
                                        readOnly={
                                          performanceData?.review_status === 1
                                            ? true
                                            : false
                                        }
                                        placeholder={"Enter evaluation comment"}
                                        value={
                                          performanceData?.technical_appraisal
                                            ?.hr_comment
                                        }
                                        onChange={(e) => {
                                          let newArray = JSON.parse(
                                            JSON.stringify(performanceData)
                                          );
                                          newArray.technical_appraisal.hr_comment =
                                            e.target.value;
                                          setPerformanceData(newArray);
                                        }}
                                      />
                                    </div>
                                    <div className="flex w-full justify-end">
                                      <Button
                                        text={"Submit"}
                                        type="button"
                                        theme="primary"
                                        className="flex gap-2 h-[35px] w-fit px-3"
                                        loading={
                                          gradeTechnicalAppraisalsLoading
                                        }
                                        textClass={"!text-[13px]"}
                                        onClick={() =>
                                          gradeTechnicalAppraisalsFn()
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex flex-col gap-2">
                                    <div className="flex flex-col p-4 py-2 border rounded w-full mt-2 bg-highlight">
                                      <span className="text-color-gray text-[13px]">
                                        HR Comments:
                                      </span>
                                      <div className="flex text-black text-[13px] h-fit">
                                        {
                                          performanceData?.technical_appraisal
                                            ?.hr_comment
                                        }
                                      </div>
                                    </div>
                                    <div className="flex flex-col p-4 py-2 border rounded w-full bg-highlight">
                                      <span className="text-color-gray text-[13px]">
                                        Employee Comments:
                                      </span>
                                      <div className="flex text-black text-[13px] h-fit">
                                        {
                                          performanceData?.technical_appraisal
                                            ?.employee_comment
                                        }
                                      </div>
                                    </div>
                                    <div className="flex flex-col p-4 py-2 border rounded w-full bg-highlight">
                                      <span className="text-color-gray text-[13px]">
                                        Team Lead Comments:
                                      </span>
                                      <div className="flex text-black text-[13px] h-fit">
                                        {
                                          performanceData?.technical_appraisal
                                            ?.team_lead_comment
                                        }
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Collapse>
                          </div>
                          <hr className="divider -mt-3 -mb-4" />
                        </>
                      ) : null}
                      {performanceData?.balanced_scorecard ? (
                        <>
                          <div
                            className={classNames(
                              "flex flex-row justify-between items-center text-[15px] font-bold cursor-pointer hover:bg-[#eff2fe] hover:rounded-md hover:pl-2 hover:pr-2 duration-300 select-none -mt-5",
                              {
                                "pt-2 pb-2": showBalancedScorecards,
                              }
                            )}
                            onClick={() => {
                              setShowBalancedScorecards(
                                !showBalancedScorecards
                              );
                            }}
                          >
                            <div className="flex flex-col">
                              <span>Balanced scorecards assessed</span>
                              <span className="text-gray-400 text-[13px]">{`Final score: (${performanceData?.balanced_scorecard.total_score}/100)`}</span>
                            </div>

                            <div className="pr-4">
                              <CollapsingIcon
                                defaultPosition="left"
                                isOpen={showBalancedScorecards}
                              />
                            </div>
                          </div>
                          <div
                            className={classNames("flex flex-col -mt-2", {
                              hidden: !showBalancedScorecards,
                            })}
                          >
                            <Collapse isOpen={showBalancedScorecards}>
                              <div className="flex flex-col p-3 border rounded-md gap-3">
                                <div className="grid md:grid-cols-1 grid-cols-1 gap-4 w-full -mb-2">
                                  <div className="flex gap-2 col-span-1 items-center">
                                    <div className="font-extrabold text-[15px]">
                                      Balanced Scorecard Title:
                                    </div>
                                    <div className="text-[13px]">
                                      {
                                        performanceData?.balanced_scorecard
                                          .title
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    "grid md:grid-cols-2 grid-cols-1 w-full gap-3"
                                  }
                                >
                                  <div className="w-full border rounded overflow-x-hidden">
                                    <div className="flex gap-2 bg-hr-primary-1 px-2 h-[50px] items-center justify-start font-extrabold text-white">
                                      <img
                                        src={FInancialGrowthSVG}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                      <span className="text-white">
                                        Financial
                                      </span>
                                      <span className="text-white text-[13px]">
                                        (
                                        {
                                          performanceData?.balanced_scorecard
                                            .total_finance_score
                                        }
                                        /
                                        {
                                          performanceData?.balanced_scorecard
                                            .finance_weight
                                        }
                                        %)
                                      </span>
                                    </div>
                                    <div className="w-full h-[350px] overflow-x-hidden p-2">
                                      {performanceData?.balanced_scorecard
                                        ?.quadrants?.finance?.data.length >
                                      0 ? (
                                        <div className=" flex flex-col gap-2">
                                          {performanceData?.balanced_scorecard?.quadrants?.finance.data.map(
                                            (item, index) => (
                                              <div
                                                className="flex flex-col p-2 gap-1 border rounded-md bg-secondary-2-extralight duration-500"
                                                key={index}
                                              >
                                                <div className="grid grid-cols-1 gap-2 w-full">
                                                  <div className="flex gap-2 col-span-1 items-center">
                                                    <div className="font-bold text-[13px]">
                                                      Objective {index + 1}:
                                                    </div>
                                                    <div className="text-[12px]">
                                                      {item.title}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="grid grid-cols-2 w-full mt-1 p-2 rounded border bg-white">
                                                  <div className="flex gap-2 items-center">
                                                    <div className="font-bold text-[12px]">
                                                      Team Lead Grade:
                                                    </div>
                                                    <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                                      {item.team_lead_score}/
                                                      {item.weight}
                                                    </div>
                                                  </div>
                                                  <div className="flex gap-2 items-center">
                                                    <div className="font-bold text-[12px]">
                                                      Employee Grade:
                                                    </div>
                                                    <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                                      {item.employee_score}/
                                                      {item.weight}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="flex flex-col gap-2 mt-2">
                                                  <div className="flex flex-col p-2 border rounded w-full bg-white">
                                                    <span className="text-color-gray text-[12px]">
                                                      Employee Comments:
                                                    </span>
                                                    <div className="flex text-black text-[11px] h-fit">
                                                      {item.employee_comment}
                                                    </div>
                                                  </div>
                                                  <div className="flex flex-col p-2 border rounded w-full bg-white">
                                                    <span className="text-color-gray text-[12px]">
                                                      Team Lead Comments:
                                                    </span>
                                                    <div className="flex text-black text-[11px] h-fit">
                                                      {item.team_lead_comment}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        <div className="p-2">
                                          <div className="flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8">
                                            No Finance Objective
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-full border rounded overflow-x-hidden">
                                    <div className="flex gap-2 bg-spend-primary-1 px-2 h-[50px] items-center justify-start font-extrabold text-white">
                                      <img
                                        src={CustomerGrowthSVG}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                      <span className="text-white">
                                        Customers
                                      </span>
                                      <span className="text-white text-[13px]">
                                        (
                                        {
                                          performanceData?.balanced_scorecard
                                            .total_customer_score
                                        }
                                        /
                                        {
                                          performanceData?.balanced_scorecard
                                            .customer_weight
                                        }
                                        %)
                                      </span>
                                    </div>
                                    <div className="w-full h-[350px] overflow-x-hidden p-2">
                                      {performanceData?.balanced_scorecard
                                        ?.quadrants?.customer.data?.length >
                                      0 ? (
                                        <div className=" flex flex-col gap-2">
                                          {performanceData?.balanced_scorecard?.quadrants?.customer.data.map(
                                            (item, index) => (
                                              <div
                                                className="flex flex-col p-2 gap-1 border rounded-md bg-secondary-2-extralight duration-500"
                                                key={index}
                                              >
                                                <div className="grid grid-cols-1 gap-4 w-full">
                                                  <div className="flex gap-2 col-span-1 items-center">
                                                    <div className="font-bold text-[13px]">
                                                      Objective {index + 1}:
                                                    </div>
                                                    <div className="text-[12px]">
                                                      {item.title}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="grid grid-cols-2 w-full mt-1 p-2 rounded border bg-white">
                                                  <div className="flex gap-2 items-center">
                                                    <div className="font-bold text-[12px]">
                                                      Team Lead Grade:
                                                    </div>
                                                    <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                                      {item.team_lead_score}/
                                                      {item.weight}
                                                    </div>
                                                  </div>
                                                  <div className="flex gap-2 items-center">
                                                    <div className="font-bold text-[12px]">
                                                      Employee Grade:
                                                    </div>
                                                    <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                                      {item.employee_score}/
                                                      {item.weight}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="flex flex-col gap-2 mt-2">
                                                  <div className="flex flex-col p-2 border rounded w-full bg-white">
                                                    <span className="text-color-gray text-[12px]">
                                                      Employee Comments:
                                                    </span>
                                                    <div className="flex text-black text-[11px] h-fit">
                                                      {item.employee_comment}
                                                    </div>
                                                  </div>
                                                  <div className="flex flex-col p-2 border rounded w-full bg-white">
                                                    <span className="text-color-gray text-[12px]">
                                                      Team Lead Comments:
                                                    </span>
                                                    <div className="flex text-black text-[11px] h-fit">
                                                      {item.team_lead_comment}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        <div className="p-2">
                                          <div className="flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8">
                                            No Customer Objective
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-full border rounded overflow-x-hidden">
                                    <div className="flex gap-2 bg-secondary-2 px-2 h-[50px] items-center justify-start font-extrabold text-white">
                                      <img
                                        src={BusinessProcessesSVG}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                      <span className="text-white">
                                        Business Processes
                                      </span>
                                      <span className="text-white text-[13px]">
                                        (
                                        {
                                          performanceData?.balanced_scorecard
                                            .total_business_process_score
                                        }
                                        /
                                        {
                                          performanceData?.balanced_scorecard
                                            .business_process_weight
                                        }
                                        %)
                                      </span>
                                    </div>
                                    <div className="w-full h-[350px] overflow-x-hidden p-2">
                                      {performanceData?.balanced_scorecard
                                        ?.quadrants?.business_process?.data
                                        .length > 0 ? (
                                        <div className=" flex flex-col gap-2">
                                          {performanceData?.balanced_scorecard?.quadrants?.business_process?.data.map(
                                            (item, index) => (
                                              <div
                                                className="flex flex-col p-2 gap-1 border rounded-md bg-secondary-2-extralight duration-500"
                                                key={index}
                                              >
                                                <div className="grid gap-2 w-full">
                                                  <div className="flex gap-2 items-center w-full">
                                                    <div className="font-bold text-[13px]">
                                                      Objective {index + 1}:
                                                    </div>
                                                    <div className="text-[12px]">
                                                      {item.title}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="grid grid-cols-2 w-full mt-1 p-2 rounded border bg-white">
                                                  <div className="flex gap-2 items-center">
                                                    <div className="font-bold text-[12px]">
                                                      Team Lead Grade:
                                                    </div>
                                                    <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                                      {item.team_lead_score}/
                                                      {item.weight}
                                                    </div>
                                                  </div>
                                                  <div className="flex gap-2 items-center">
                                                    <div className="font-bold text-[12px]">
                                                      Employee Grade:
                                                    </div>
                                                    <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                                      {item.employee_score}/
                                                      {item.weight}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="flex flex-col gap-2 mt-2">
                                                  <div className="flex flex-col p-2 border rounded w-full bg-white">
                                                    <span className="text-color-gray text-[12px]">
                                                      Employee Comments:
                                                    </span>
                                                    <div className="flex text-black text-[11px] h-fit">
                                                      {item.employee_comment}
                                                    </div>
                                                  </div>
                                                  <div className="flex flex-col p-2 border rounded w-full bg-white">
                                                    <span className="text-color-gray text-[12px]">
                                                      Team Lead Comments:
                                                    </span>
                                                    <div className="flex text-black text-[11px] h-fit">
                                                      {item.team_lead_comment}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        <div className="p-2">
                                          <div className="flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8">
                                            No Business Processes Objective
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-full border rounded overflow-x-hidden">
                                    <div className="flex gap-2 bg-error px-2 h-[50px] items-center justify-start font-extrabold text-white">
                                      <img
                                        src={LeaningGrowthSVG}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                      <span className="text-white">
                                        Learning and Growth
                                      </span>
                                      <span className="text-white text-[13px]">
                                        (
                                        {
                                          performanceData?.balanced_scorecard
                                            .total_learning_growth_score
                                        }
                                        /
                                        {
                                          performanceData?.balanced_scorecard
                                            .learning_growth_weight
                                        }
                                        %)
                                      </span>
                                    </div>
                                    <div className="w-full h-[350px] overflow-x-hidden p-2">
                                      {performanceData?.balanced_scorecard
                                        ?.quadrants?.learning_growth?.data
                                        .length > 0 ? (
                                        <div className=" flex flex-col gap-2">
                                          {performanceData?.balanced_scorecard?.quadrants?.learning_growth?.data.map(
                                            (item, index) => (
                                              <div
                                                className="flex flex-col p-2 gap-1 border rounded-md bg-secondary-2-extralight duration-500"
                                                key={index}
                                              >
                                                <div className="grid grid-cols-1 gap-4 w-full">
                                                  <div className="flex gap-2 items-center">
                                                    <div className="font-bold text-[13px]">
                                                      Objective {index + 1}:
                                                    </div>
                                                    <div className="text-[12px]">
                                                      {item.title}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="grid grid-cols-2 w-full mt-1 p-2 rounded border bg-white">
                                                  <div className="flex gap-2 items-center">
                                                    <div className="font-bold text-[12px]">
                                                      Team Lead Grade:
                                                    </div>
                                                    <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                                      {item.team_lead_score}/
                                                      {item.weight}
                                                    </div>
                                                  </div>
                                                  <div className="flex gap-2 items-center">
                                                    <div className="font-bold text-[12px]">
                                                      Employee Grade:
                                                    </div>
                                                    <div className="text-[11px] text-hr-secondary-1 font-extrabold">
                                                      {item.employee_score}/
                                                      {item.weight}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="flex flex-col gap-2 mt-2">
                                                  <div className="flex flex-col p-2 border rounded w-full bg-white">
                                                    <span className="text-color-gray text-[12px]">
                                                      Employee Comments:
                                                    </span>
                                                    <div className="flex text-black text-[11px] h-fit">
                                                      {item.employee_comment}
                                                    </div>
                                                  </div>
                                                  <div className="flex flex-col p-2 border rounded w-full bg-white">
                                                    <span className="text-color-gray text-[12px]">
                                                      Team Lead Comments:
                                                    </span>
                                                    <div className="flex text-black text-[11px] h-fit">
                                                      {item.team_lead_comment}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        <div className="p-2">
                                          <div className="flex flex-row w-full text-[13px] justify-center items-center h-[60px] rounded-md border bg-highlight text-black mt-8">
                                            No Learning and Growth Objective
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        </>
                      ) : null}
                    </div>
                  )}
                  <div className="p-2 border rounded w-full -mt-2">
                    <FormTextArea
                      label={"Training Comment"}
                      type="text"
                      name="training_requirement"
                      readOnly={
                        performanceData?.review_status === 1 ? true : false
                      }
                      placeholder={"Enter Training requirement"}
                      value={trainingRequirement}
                      onInput={(e) => setTrainingRequirement(e.target.value)}
                    />
                    {performanceData?.review_status !== 1 ? (
                      <div className="flex gap-[10px] justify-end min-w-full">
                        <div className="w-full">
                          <Button
                            text={"SUBMIT COMMENT"}
                            type="button"
                            theme="primary"
                            readOnly={
                              performanceData?.review_status === 1
                                ? true
                                : false
                            }
                            className="flex gap-2 !h-[35px] w-fit px-3 "
                            loading={updateTrainingLoading}
                            textClass={"!text-[11px]"}
                            onClick={() => {
                              updateTrainingRequirement();
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="p-2 border rounded w-full">
                    <FormTextArea
                      label={"Reviewer Comment"}
                      type="text"
                      name="reveiwer_comment"
                      readOnly={
                        performanceData?.review_status === 1 ? true : false
                      }
                      placeholder={"Enter Reviewer Comment"}
                      value={reviewerComment}
                      onInput={(e) => setReviewerComment(e.target.value)}
                    />
                    {performanceData?.review_status !== 1 ? (
                      <div className="flex gap-[10px] justify-end min-w-full">
                        <div className="min-w-full">
                          <Button
                            text={"SUBMIT COMMENT"}
                            type="button"
                            theme="primary"
                            readOnly={
                              performanceData?.review_status === 1
                                ? true
                                : false
                            }
                            className="flex gap-2 !h-[35px] w-fit px-3"
                            loading={updateCommentLoading}
                            textClass={"!text-[11px]"}
                            onClick={() => {
                              updateReviewerComment();
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </DashboardLayout>
  );
};

export default ViewPerformanceEvaluation;
