import React from 'react';
import { useForm } from 'react-hook-form';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormFileInput from '../../form-file-input';
import CloseSVG from '../../../assets/svg/close.svg';
import { useBulkAddBonus } from '../../../redux/employees/hook/bonuses';
import Modal from '../modal';

function UploadNSITF({ isOpen, closeModal, refresh }) {
  const { mutateAsync, isLoading } = useBulkAddBonus();
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  async function submitForm(data) {
    const formData = new FormData();
    formData.append('file', data?.file[0]);

    await mutateAsync(formData).then(() => {
      closeModal();
    });
  }

  return (
    <Modal
      scrollable={true}
      className='!max-w-[500px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <form className='form overflow-auto' onSubmit={handleSubmit(submitForm)}>
        <div className='flex flex-col'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Upload NSITF</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='p-8 flex flex-col'>
          <div className='flex flex-col text-center justify-center items-center'>
            <span className='header-4-medium'>Upload Tax from XLSX file</span>
            <button className='mt-7 h-10 border-[1.5px] border-secondary-2 rounded w-[175px]'>
              <span className='p4 text-secondary-2'>Download template</span>
            </button>

            <FormFileInput
              multiSource={true}
              inputRef={register(formValidation('file', true))}
              accept='.xlsx'
            />
          </div>
          <div className='w-full mt-[20px]'>
            <Button text='UPLOAD' type='submit' loading={isLoading} />
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default UploadNSITF;
