import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import WarningSVG from '../../../../assets/svg/warning.svg';
import Modal from '../../modal';
import { formatNumber } from '../../../../utils/functions';
import { useDisburseInvoiceReceivedPayment, useGetInvoiceReceivedPayments, useInitiateInvoiceReceivedPayment } from '../../../../redux/spend-management/invoice-received/hook';
import FormInput from '../../../form-input';
import { store } from '../../../../redux';
import { setAlert } from '../../../../redux/components/components-slice';


function PayInvoiceRecieved({
  isOpen,
  closeModal,
  invoiceReceivedData,
  invoicePaymentData,
}) {
    const [invoiceId, setInvoiceId] = useState(null)
    const [totalPaid, setTotalPaid] = useState(0)
    const { user } = useSelector((state) => state.userSlice);
    const { company } = useSelector((state) => state.companySlice);

    const { data: invoice_payments } = useGetInvoiceReceivedPayments(
        invoiceId,
        1000,
        1,
        1,
        '',
      );

    const { mutateAsync: disburseInvoicePayment, isLoading: disburseLoading } = useDisburseInvoiceReceivedPayment();

    const { mutateAsync: initiateInvoicePayment, isLoading: approveAndPayLoading } = useInitiateInvoiceReceivedPayment();
    const [paymentData, setPaymentData] = useState({
        vendor_name: '',
        vendor_mobile: '',
        bank_name: '',
        account_number: '',
        account_name: '',
        invoice_amount: 0,
        payment_amount: 0,

    })

    const [invoicePay, setInvoicePay] = useState(false)

    useEffect(() => {
        if (invoice_payments?.data) {
            let newAmount = 0;
            let data = invoice_payments.data
            data.forEach(element => {
                newAmount += parseFloat(element.amount_paid)
            });
            setTotalPaid(newAmount)
        }
    }, [invoice_payments])

    useEffect(() => {
        if (invoiceReceivedData) {
            setInvoiceId(invoiceReceivedData.id)
            setInvoicePay(false)
            setPaymentData(
                {
                    vendor_name:  invoiceReceivedData.vendor_name,
                    vendor_mobile:  invoiceReceivedData.vendor_mobile,
                    bank_name:  invoiceReceivedData.bank_name,
                    account_number:  invoiceReceivedData.account_number,
                    account_name:  invoiceReceivedData.account_name,
                    invoice_amount:  invoiceReceivedData.total_amount,
                }
            )
        }
    }, [invoiceReceivedData])

    useEffect(() => {
        if (invoicePaymentData) {
            setInvoiceId(invoiceReceivedData.invoice_received_id)
            setInvoicePay(true)
            setPaymentData(
                {
                    vendor_name:  invoicePaymentData.vendor,
                    vendor_mobile:  invoicePaymentData.vendor_mobile,
                    bank_name:  invoicePaymentData.bank,
                    account_number:  invoicePaymentData.account_number,
                    account_name:  invoicePaymentData.account_name,
                    invoice_amount:  invoicePaymentData.total_amount,
                }
            )
        }
    }, [invoicePaymentData])

    const submitForm = async () => {
        if (invoiceReceivedData?.id || invoicePaymentData?.id) {
            if (paymentData.payment_amount && paymentData.payment_amount > 0) {
                if (paymentData.payment_amount <= paymentData.invoice_amount - totalPaid) {
                    if (
                        user?.permissions.approval_permission?.initiator === 1 &&
                        !invoicePay
                    ) {
                            let payload = {
                                id: invoiceReceivedData?.id,
                                data: {
                                    amount: paymentData.payment_amount
                                } 
                            }
                            await initiateInvoicePayment(payload).then(() => {
                                closeModal();
                            });
                    } else if (
                        user?.permissions.approval_permission?.approver === 1 &&
                        invoicePay
                    ) {
                            let payload = {
                                id:invoicePaymentData?.id,
                                data: {
                                    amount: paymentData.payment_amount
                                }
                            }
                            await disburseInvoicePayment(payload).then(() => {
                                closeModal();
                            });
                    } else if (
                        user?.permissions.approval_permission?.initiator === 1 &&
                        user?.permissions.approval_permission?.approver === 1
                    ) {
                            let payload = {
                                id:invoiceReceivedData?.id,
                                data: {
                                    amount: paymentData.payment_amount
                                }
                            }
                            await initiateInvoicePayment(payload).then(() => {
                                closeModal();
                            });
                    }
                } else {
                    store.dispatch(setAlert(true, 'warning', 'Payment amount must be less than balance to pay'))
                } 
            } else {
                store.dispatch(setAlert(true, 'warning', 'Payment amount must be greater than 0'))
            }
        } else {
            store.dispatch(setAlert(true, 'warning', 'Provide a valid payment ID.'))
        }
        
    };

    return (
        <Modal
            scrollable={true}
            className='!max-w-[600px] !rounded-none'
            isOpen={isOpen}
            centered={true}
        >
        <div className='flex flex-col overflow-auto'>
            <form className='form'>
            <div className='pl-6 pr-6 pt-4'>
                <div className='flex flex-row justify-between items-center'>
                    <span className='header-4'>Pay Invoice Received</span>
                    <div className='cursor-pointer' onClick={() => closeModal()}>
                        <img src={CloseSVG} alt='close' />
                    </div>
                </div>
            </div>
            <hr className='divider mb-2' />
            <div className='flex flex-col p-4 pt-0'>
                <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-secondary-2-extralight'>
                    <span className='stat-card-header'>
                    COMPANY WALLET BALANCE{' '}
                    <span className='!text-[13px] text-color-black font-bold'>
                        (VFD - {company?.account_number} )
                    </span>
                    </span>
                    <span className='!text-[16px] text-color-black font-bold'>
                    {company?.total_balance?.toLocaleString()}
                    </span>
                </div>
                <hr className='divider mb-2' />
                <div className='mt-2 grid md:grid-cols-2 grid-cols-2 gap-4 w-full'>
                <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>Vendor Name</span>
                    <span className='mt-1 text-color-black text-[14px]'>
                    {paymentData?.vendor_name}
                    </span>
                </div>
                <div className='flex flex-col'>
                    <span className='header-5 text-color-gray'>Mobile</span>
                    <span className='mt-1 text-color-black text-[14px]'>
                    {paymentData?.vendor_mobile}
                    </span>
                </div>
                </div>
                <hr className='divider mt-2 mb-2' />
                <FormInput
                    label='Total Paid To Date'
                    name='total_paid'
                    type='number'
                    value={totalPaid}
                    readOnly={true}
                />
                <div className='mt-2 grid md:grid-cols-2 grid-cols-1 gap-4 w-full'>
                    <FormInput
                        label='Total Invoice Amount'
                        name='Invoice_amount'
                        type='text'
                        value={paymentData.invoice_amount}
                        readOnly={true}
                    />
                    <FormInput
                        label='Payment Amount'
                        name='payment_amount'
                        type='number'
                        placeholder={'Enter payment amount'}
                        value={paymentData.payment_amount}
                        onInput={(e) => {
                            let newArray = JSON.parse(JSON.stringify(paymentData))
                            newArray.payment_amount = e.target.value
                            setPaymentData(newArray)
                        }}
                    />
                </div>
                <hr className='divider mt-2 mb-2' />
                <div className='flex flex-col md:flex-row w-full justify-between'>
                    <div className='w-full md:w-1/2 md:pr-2'>
                        <FormInput
                            label={'Bank Name'}
                            name='bank_name'
                            type='text'
                            value={paymentData.bank_name}
                            readOnly
                        />
                    </div>
                    <div className='w-full md:w-1/2 md:pl-2'>
                        <FormInput
                            label={'Account Number'}
                            name='account_number'
                            type='text'
                            value={paymentData.account_number}
                            readOnly
                        />
                    </div>
                </div>
                <FormInput
                    label='Bank Account Name (Auto-Generated)'
                    name='account_name'
                    type='text'
                    readOnly
                    value={paymentData.account_name}
                />
                
                <hr className='divider mt-2 mb-3' />
                <div className='flex flex-col justify-center mb-4'>
                <div className='flex flex-row border-secondary-4 border-[2px] rounded-lg p-4 bg-color-warning-light'>
                    <div className='w-[70px] pr-4 flex flex-row items-center '>
                    <img src={WarningSVG} alt='' srcSet='' />
                    </div>
                    <div className='text-justify text-[13px]'>
                        Ropay will debit <span className='font-extrabold'>{formatNumber(paymentData?.amount)}</span> from your
                        account. You will be charged a fee of <span className='font-extrabold'>44.99</span> for this
                        transaction. Please Make sure the funds are available.
                    </div>
                </div>
                </div>
                {invoicePay &&
                user?.permissions.approval_permission?.approver === 1 ? (
                <div className='w-full mb-[20px]'>
                    <Button
                    text={'APPROVE INVOICE PAYMENT'}
                    type='button'
                    disabled={false}
                    loading={approveAndPayLoading || disburseLoading}
                    onClick={() => submitForm()}
                    />
                </div>
                ) : !invoicePay &&
                user?.permissions.approval_permission?.initiator === 1 ? (
                <div className='w-full mb-[20px]'>
                    <Button
                    text={
                        user?.permissions.approval_permission?.initiator === 1 &&
                        user?.permissions.approval_permission?.approver === 1
                        ? 'PAY INVOICE'
                        : 'INITIATE INVOICE PAYMENT'
                    }
                    type='button'
                    disabled={false}
                    loading={approveAndPayLoading || disburseLoading}
                    onClick={() => submitForm()}
                    />
                </div>
                ) : !invoicePay &&
                user?.permissions.approval_permission?.approver === 1 ? (
                <div className='text-center w-full mb-[20px]'>
                    You are not authorised to Initiate payments <br /> Please
                    Contact Your Administrator.
                </div>
                ) : (
                <div className='text-center w-full mb-[20px]'>
                    You are not Authorised to Approve payment <br /> Please Contact
                    Your Administrator.
                </div>
                )}
            </div>
            </form>
        </div>
        </Modal>
    );
}

export default PayInvoiceRecieved;
