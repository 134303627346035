import React, { useEffect } from "react";
import { useState } from "react";
import classNames from "classnames";
import FormInput from "../../form-input";
import Button from "../../button";
import LoadingBar from "../../loader-bar";
import AddSmallSVG from "../../../assets/svg/add-icon-small.svg";
import FormSwitch from "../../form-switch";
import {
  useAddPerformanceIndicator,
  useDeletePerformanceIndicator,
  useEditPerformanceIndicator,
  useGetPerformanceBehaviouralAppraisal,
  useGetPerformanceTechnicalAppraisal,
} from "../../../redux/human-resources/hook/performance";

const PerformanceRatingsTab = () => {
  const [selectedAccountIndex, setSelectedAccountIndex] = useState(0);

  const { data: technicalAppraisals, isLoading: technicalLoading } =
    useGetPerformanceTechnicalAppraisal("technical");
  const { data: behaviouralAppraisals, isLoading: behaviouralLoading } =
    useGetPerformanceBehaviouralAppraisal("behavioural");

  const [behaviouralIndicators, setBehaviouralIndicators] = useState([]);
  const [technicalIndicators, setTechnicalIndicators] = useState([]);

  const [type, setType] = useState("behavioural");

  const [addingIndicator, setAddingIndicator] = useState(false);

  const [indicator, setIndicator] = useState("");
  const [maxGrade, setMaxGrade] = useState(100);

  useEffect(() => {
    if (technicalAppraisals && technicalAppraisals.length > 0) {
      setTechnicalIndicators(JSON.parse(JSON.stringify(technicalAppraisals)));
    }
    if (behaviouralAppraisals && behaviouralAppraisals.length > 0) {
      setBehaviouralIndicators(
        JSON.parse(JSON.stringify(behaviouralAppraisals))
      );
    }
  }, [technicalAppraisals, behaviouralAppraisals]);

  const { mutateAsync: addIndicator, isLoading: addIndicatorLoading } =
    useAddPerformanceIndicator();
  const { mutateAsync: deleteIndicator, isLoading: deleteLoading } =
    useDeletePerformanceIndicator();
  const { mutateAsync: updateIndicator, isLoading: updateLoading } =
    useEditPerformanceIndicator();

  const accounts = [
    {
      name: "Technical Appraisal",
      title: "Based on company wide technical skills and competences assessed",
    },
    {
      name: "Behavioural Appraisal",
      title: "Company wide work-ethic, attitude and behaviour assessments",
    },
  ];

  const addPerformanceIndicator = async () => {
    let payload = {
      indicator,
      max_grade: maxGrade,
      type,
    };
    await addIndicator(payload).then(() => {
      setIndicator("");
    });
  };

  const updatePerformanceIndicator = async (
    id,
    indicator,
    max_grade,
    status,
    type
  ) => {
    let payload = {
      indicator,
      max_grade,
      status,
      type,
    };
    let editPayload = {
      id: id,
      data: payload,
    };
    await updateIndicator(editPayload);
  };

  return (
    <>
      <div className="flex flex-col -mt-2">
        <div className=" bg-white border rounded-md text-[12px] p-2 text-gray-600">
          <span className="font-bold text-[14px]">
            What are Organisational Performance Ratings?
          </span>{" "}
          These are company wide behavioural and technical indicators that
          employees will be assessed on when carrying out performance
          evaluation. When evaluating employees, we ask if you want to asses
          this or not during the evaluation.
        </div>
      </div>
      <div className="flex flex-col bg-white w-full rounded pl-8 py-3 h-fit pr-8 mt-4">
        {technicalLoading || behaviouralLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
            <LoadingBar loading={technicalLoading || behaviouralLoading} />
          </div>
        ) : (
          <div className="flex flex-col md:flex-row mt-3 h-full">
            <div className="flex flex-col max-w-[450px] w-[100%] gap-3 ">
              {accounts.map((item, index) => (
                <div
                  className={classNames(
                    "w-full max-w-[450px] h-[80px] rounded flex px-3 flex-row items-center justify-between cursor-pointer border border-gray-300",
                    {
                      "bg-[#e1fbe6]": index === selectedAccountIndex,
                    }
                  )}
                  onClick={() => {
                    setAddingIndicator(false);
                    setSelectedAccountIndex(index);
                    if (index == 0) {
                      setType("technical");
                    } else {
                      setType("behavioural");
                    }
                  }}
                  key={index}
                >
                  <div className="flex flex-col">
                    <span className="p4-bold">{item.name}</span>
                    <span className="p4-regular text-gray-500">
                      {item.title}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-[100%] md:ml-4">
              <div className="border border-color-line rounded p-4 flex flex-col">
                <div className="w-full md:w-[100%]">
                  {selectedAccountIndex === 0 ? (
                    <form className="form ">
                      <div className={"flex flex-col w-full md:w-[100%]"}>
                        <div className="flex flex-col justify-between">
                          <div className="header-4 pb-2">Indicators</div>

                          <div className="w-full md:w-[100%] mr-2">
                            {technicalIndicators.map((item, index) => (
                              <div className="flex justify-between">
                                <FormSwitch
                                  label={item.indicator}
                                  name={"item.indicator"}
                                  classNames={"min-w-[70%]"}
                                  checked={item.status == 1 ? true : false}
                                  onClick={() => {
                                    let newArray = JSON.parse(
                                      JSON.stringify(behaviouralIndicators)
                                    );
                                    newArray[index].status =
                                      newArray[index].status === 0 ? 1 : 0;
                                    setBehaviouralIndicators(newArray);
                                  }}
                                />
                                <div className={"flex gap-2"}>
                                  <div
                                    className={
                                      "text-hr-primary-1 italic text-[12px] cursor-pointer hover:underline hover:font-semibold duration-500"
                                    }
                                    onClick={() => {
                                      updatePerformanceIndicator(
                                        item.id,
                                        item.indicator,
                                        item.max_grade,
                                        item.status,
                                        item.type
                                      );
                                    }}
                                  >
                                    Update
                                  </div>
                                  <div
                                    className={
                                      "text-red-600 italic text-[12px] cursor-pointer hover:underline hover:font-semibold duration-500"
                                    }
                                    onClick={() => {
                                      deleteIndicator(item.id);
                                    }}
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            ))}
                            <hr className="divider -mt-2" />
                            <div className="flex flex-row items-center gap-2 justify-left w-full cursor-pointer">
                              {addingIndicator ? (
                                <div className="flex flex-row items-center justify-between w-full">
                                  <div className="flex flex-row md:flex-col w-full justify-between">
                                    <FormInput
                                      label="Indicator"
                                      name="indicator"
                                      type="text"
                                      value={indicator}
                                      onInput={(e) =>
                                        setIndicator(e.target.value)
                                      }
                                      readOnly={updateLoading || deleteLoading}
                                      className="!h-[30px] text-[13px] min-w-[200px]"
                                    />
                                  </div>
                                  <div className="flex md:flex-row justify-end mt-[10px] gap-4">
                                    <Button
                                      text="Cancel"
                                      type="button"
                                      onClick={() => {
                                        setIndicator("");
                                        setAddingIndicator(false);
                                      }}
                                      textClass={"!text-[11px]"}
                                      className={"h-[35px] w-[100px]"}
                                      theme={"third"}
                                    />
                                    <Button
                                      text={"ADD"}
                                      type="button"
                                      onClick={() => {
                                        setType("technical");
                                        addPerformanceIndicator();
                                      }}
                                      textClass={"!text-[11px]"}
                                      className={"h-[35px] w-[100px]"}
                                      loading={addIndicatorLoading}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <img
                                    src={AddSmallSVG}
                                    alt={"add_stage"}
                                    className={"h-[15px] w-[15px]"}
                                  />
                                  <span
                                    className="p4-medium hover:underline hover:italic hover:font-bold text-primary-3 duration-75"
                                    onClick={() => setAddingIndicator(true)}
                                  >
                                    Add New Indicator
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form className="form ">
                      <div className={"flex flex-col w-full md:w-[100%]"}>
                        <div className="header-4 pb-2">Indicators</div>
                        {behaviouralIndicators.map((item, index) => (
                          <div className="flex justify-between">
                            <FormSwitch
                              label={item.indicator}
                              name={item.indicator}
                              classNames={"min-w-[70%]"}
                              checked={item.status == 1 ? true : false}
                              onClick={() => {
                                let newArray = JSON.parse(
                                  JSON.stringify(behaviouralIndicators)
                                );

                                newArray[index].status =
                                  item.status === 0 ? 1 : 0;
                                setBehaviouralIndicators(newArray);
                              }}
                            />
                            <div className={"flex gap-2"}>
                              <div
                                className={
                                  "text-hr-primary-1 italic text-[13px] cursor-pointer hover:underline hover:font-semibold duration-500"
                                }
                                onClick={() => {
                                  updatePerformanceIndicator(
                                    item.id,
                                    item.indicator,
                                    item.max_grade,
                                    item.status,
                                    item.type
                                  );
                                }}
                              >
                                Update
                              </div>
                              <div
                                className={
                                  "text-red-600 italic text-[13px] cursor-pointer hover:underline hover:font-semibold duration-500"
                                }
                                onClick={() => {
                                  deleteIndicator(item.id);
                                }}
                              >
                                Remove
                              </div>
                            </div>
                          </div>
                        ))}
                        <hr className="divider -mt-2" />
                        <div className="flex flex-row items-center gap-2 justify-left w-full cursor-pointer">
                          {addingIndicator ? (
                            <div className="flex flex-row items-center justify-between w-full">
                              <div className="flex flex-row md:flex-col w-full justify-between">
                                <FormInput
                                  label="Indicator"
                                  name="indicator"
                                  type="text"
                                  value={indicator}
                                  onInput={(e) => setIndicator(e.target.value)}
                                  readOnly={updateLoading || deleteLoading}
                                  className="!h-[30px] text-[13px] min-w-[300px] "
                                />
                              </div>
                              <div className="flex md:flex-row justify-end mt-[10px] gap-4">
                                <Button
                                  text="Cancel"
                                  type="button"
                                  onClick={() => {
                                    setIndicator("");
                                    setAddingIndicator(false);
                                  }}
                                  textClass={"!text-[11px]"}
                                  className={"h-[35px] w-[100px]"}
                                  theme={"third"}
                                />
                                <Button
                                  text={"ADD"}
                                  type="button"
                                  onClick={() => {
                                    setType("behavioural");
                                    addPerformanceIndicator();
                                  }}
                                  textClass={"!text-[11px]"}
                                  className={"h-[35px] w-[100px]"}
                                  loading={addIndicatorLoading}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <img
                                src={AddSmallSVG}
                                alt={"add_stage"}
                                className={"h-[15px] w-[15px]"}
                              />
                              <span
                                className="text-[14px] hover:underline hover:italic hover:font-bold text-primary-3 duration-75"
                                onClick={() => setAddingIndicator(true)}
                              >
                                Add New Indicator
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PerformanceRatingsTab;
