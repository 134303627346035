import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'recruitment',
  initialState: {
    assignWorkflowData: null,
    recruitmentBrief: null
  },
  reducers: {
    setAssignWorkflow: (state, { payload }) => {
      state.assignWorkflowData = payload;
    },
    setRecruitmentBrief: (state, { payload }) => {
      state.recruitmentBrief = payload;
    },
  },
});

// Actions
export const { setAssignWorkflow, setRecruitmentBrief } = slice.actions;

export default slice.reducer;
