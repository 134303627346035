import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

const SpendOverviewChart = ({ data }) => {
  ChartJS.register(ArcElement, Tooltip);

  return (
    <>
      {data ? (
        <div className='flex w-full h-full flex-col justify-center items-center'>
          <div className='md:w-[50%] w-[40%] !max-h-fit !min-h-fit relative'>
            <Doughnut data={data.data} />
            <div
              className='absolute w-full top-[57%] left-0 text-center -mt-[20px] line'
            >
              <span className='text-[13px] font-extrabold'>{data.total}</span>
            </div>
          </div>
          <div className='mt-2 flex flex-row md:justify-center items-center !max-h-[20%] !min-h-[20%] pb-2'>
            {data.legends.map((item) => (
              <div className='flex flex-row items-center px-1 py-1 md:py-0'>
                <div
                  className={`w-[12px] h-[12px] rounded-[12px] ${item.color}`}
                ></div>
                <span className='pl-1 p4'> {item.title}</span>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SpendOverviewChart;
