import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import {
  customerStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import Button from "../../button";
import AddCustomer from "../../modal/spend-management/customer/add-customer";
import SelectCustomerOption from "../../modal/spend-management/customer/select-customer-option";
import {
  useActivateOrDeactivateCustomer,
  useGetCustomers,
} from "../../../redux/spend-management/customer/hook";
import DoYouWantToModal from "../../modal/do-you-want-to";

const CustomerListTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: customers } = useGetCustomers(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const deleteSingleCustomer = async (id) => {
    let index = customers.data.findIndex((item) => item.id === id);
    await deleteEmployee(customers.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  const { mutateAsync: ActivateOrDeactivate } =
    useActivateOrDeactivateCustomer();

  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showSelectAddCustomerOption, setShowSelectAddCustomerOption] =
    useState(false);
  const [customerData, setCustomerData] = useState({});

  const history = useHistory();

  const tableConfig = {
    headers: [
      "Date Created",
      "Customer Name",
      "Contact Person",
      "Email",
      "Mobile Number",
      "Status",
    ],
    keys: [
      "created",
      "customer_name",
      "contact_person",
      "email",
      "mobile",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Customer Name",
        key: "customer_name",
      },
      right: {
        title: "Status",
        key: "status",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = customers.data.findIndex((item) => item.id === id);
        history.push(`/customers/view/${customers.data[index].id}`);
      },
    },
    {
      name: "Edit",
      color: "orange",
      action: (id) => {
        let index = customers.data.findIndex((item) => item.id === id);
        setCustomerData(customers.data[index]);
        setShowAddCustomer(true);
      },
    },
    {
      name: "Activate",
      color: "green",
      action: (id) => {
        let index = customers.data.findIndex((item) => item.id === id);
        ActivateOrDeactivate({
          type: "activate",
          id: customers.data[index].id,
        });
      },
    },
    {
      name: "Deactivate",
      color: "red",
      action: (id) => {
        let index = customers.data.findIndex((item) => item.id === id);
        ActivateOrDeactivate({
          type: "deactivate",
          id: customers.data[index].id,
        });
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = customers.data.findIndex((item) => item.id === id);
        setDeleteId(customers.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showDoYouWant ? (
        <DoYouWantToModal
          isOpen={showDoYouWant}
          closeModal={() => setShowDoYouWant(false)}
          id={deleteId}
          title={"Are you sure you want to delete this customer?"}
          buttonText={"DELETE"}
          btnFunction={deleteSingleCustomer}
        />
      ) : null}
      {showAddCustomer ? (
        <AddCustomer
          isOpen={showAddCustomer}
          closeModal={() => {
            setShowAddCustomer(false);
          }}
          completed={() => {
            setShowAddCustomer(false);
          }}
          customerData={customerData}
          title="Edit Customer"
          buttonText="UPDATE"
        />
      ) : null}
      {showSelectAddCustomerOption ? (
        <SelectCustomerOption
          isOpen={showSelectAddCustomerOption}
          closeModal={() => setShowSelectAddCustomerOption(false)}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Customers
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Customer"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setShowSelectAddCustomerOption(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />

      <DataTable
        data={customers}
        tableConfig={tableConfig}
        statusConfig={customerStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        computedActions={true}
        searchData={true}
      />
    </div>
  );
};

export default CustomerListTab;
