import { Axios } from '../axios';

const getProbations = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/probations?limit=${payload.limit}&page=${payload.page}${search}${filter}`,
  );
  return data?.data;
};

const getProbation = async (payload) => {
  const { data } = await Axios.get('/probations/' + payload.id);
  return data?.data;
};

const getProbationReport = async (payload) => {
  const { data } = await Axios.get(`/probations/report/${payload.start_date}/${payload.end_date}`);
  return data?.data;
};

const addProbation = async (payload) => {
  const { data } = await Axios.post('probations', payload);
  return data;
};
const editProbation = async (payload) => {
  const { data } = await Axios.put('probations/'+payload.id, payload.body);
  return data;
};

const deleteProbation = async (payload) => {
  const { data } = await Axios.delete('probations/'+payload);
  return data;
};

const bulkAddProbation = async (payload) => {
  const { data } = await Axios.post(
    '/probations/bulk', 
    payload,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0
    }
  );
  return data;
};

const getSingleEmployeeProbations = async (payload) => {
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(`/probations/employee/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}`,
  );

  return data?.data;
};

const extendProbation = async (payload) => {
    const { data } = await Axios.post('probations/extend/'+payload.probation_id, payload);
    return data;
};

const confirmEmployeeProbation = async (payload) => {
    const { data } = await Axios.post('probations/confirm/'+payload.probation_id, payload);
    return data;
};

const terminateEmployeeProbation = async (payload) => {
    const { data } = await Axios.post('probations/terminate/'+payload.probation_id, payload);
    return data;
};

const getProbationEvaluations = async (payload) => {
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(`/probations/performance-evaluations/${payload.probation_id}?limit=${payload.limit}&page=${payload.page}${filter}`,
  );

  return data?.data;
};

const getProbationCommentsByProbation = async (payload) => {
  const { data } = await Axios.get( `/probation-comments/get-by-probation/${payload}`,);
  return data?.data;
};

const addProbationComment = async (payload) => {
  const { data } = await Axios.post('/probation-comments', payload);
  return data;
};

const updateProbationComment = async (payload) => {
  const { data } = await Axios.put('/probation-comments/' + payload.id, payload.data);
  return data;
};

const deleteProbationComment = async (payload) => {
  const { data } = await Axios.delete(`/probation-comments/${payload}`,);
  return data;
};

export const probations = {
    getProbations,
    getProbationReport,
    getProbation,
    addProbation,
    editProbation,
    deleteProbation,
    bulkAddProbation,
    getSingleEmployeeProbations,
    extendProbation,
    confirmEmployeeProbation,
    terminateEmployeeProbation,
    getProbationEvaluations,

    addProbationComment,
    getProbationCommentsByProbation,
    updateProbationComment,
    deleteProbationComment
};
