import React from 'react';
import AirtimePage from '../../pages/airtime';
import AirtimePreviewPage from '../../pages/airtime/airtime-preview';
import AirtimeViewPage from '../../pages/airtime/airtime-view';
import ProtectedRoute from '../protected';

export const AirtimeRoutes = [
    <ProtectedRoute exact path='/airtime' component={AirtimePage} />,
    <ProtectedRoute
      exact
      path='/airtime/preview/:ref'
      component={AirtimePreviewPage}
    />,
    <ProtectedRoute
      exact
      path='/airtime/view/:ref'
      component={AirtimeViewPage}
    />
  ]