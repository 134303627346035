import React, { useState } from 'react';
import TickSVG from '../../../../assets/svg/tick.svg';
import Button from '../../../button';
import FacebookSVG from '../../../../assets/svg/facebook.svg';
import TwitterSVG from '../../../../assets/svg/twitter.svg';
import LinkedinSVG from '../../../../assets/svg/linkedin.svg';
import { useHistory } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../redux/components/components-slice';
import CopySVG from '../../../../assets/svg/copy.svg';
import Modal from '../../modal';

function ShareRecruitmentModal({
  isOpen,
  url = '',
  recruitmentId,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Modal
      scrollable={true}
      className='!max-w-[500px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='p-4 overflow-auto'>
        <div className='flex flex-col justify-center'>
          <div className='flex flex-col md:mx-8 items-center'>
            <div
              className={`flex items-center justify-center w-[110px] h-[110px] rounded-[130px] bg-[#0d5225]`}
            >
              <img
                src={TickSVG}
                alt='tick'
                style={{ width: '50px', height: '50px' }}
              />
            </div>
            <span className='font-bold my-2 text-center header-4'>
              Your new recruitment has been created
            </span>
            <span className='my-2 p4 mt-1 text-center'>
              Copy and share the recruitment link on your various platforms to
              optimize this process and get the best talents for your
              organization
            </span>
            <div className='p-3 py-1 flex flex-col p3 items-center justify-center'>
              <span className='text-color-black pt-2 text-[14px]'>
                Candidate application link:
              </span>
              <span className=' text-secondary-2 pt-1 text-center'>{url} </span>

              <CopyToClipboard
                text={url}
                onCopy={() => {
                  dispatch(setAlert(true, 'success', 'Copied successfully'));
                }}
              >
                <button className='flex flex-row mt-2 items-center text-center '>
                  <span className='text-color-black pt-2 text-[14px] text-hr-primary-1'>
                    Copy to clipboard{' '}
                  </span>
                  <img
                    src={CopySVG}
                    width={15}
                    className='ml-2 mt-2'
                    alt='copy'
                  />
                </button>
              </CopyToClipboard>
            </div>
            <span className='p-2 text-[14px]'>Share via:</span>
            <div className='flex flex-row gap-4'>
              <a
                href='https://www.facebook.com'
                target='_blank'
                rel='noreferrer'
              >
                <img src={FacebookSVG} alt='facebook' />
              </a>
              <a
                href='https://www.twitter.com'
                target='_blank'
                rel='noreferrer'
              >
                <img src={TwitterSVG} alt='twitter' />
              </a>
              <a
                href='https://www.linkedin.com'
                target='_blank'
                rel='noreferrer'
              >
                <img src={LinkedinSVG} alt='linkedin' />
              </a>
            </div>

            <div className='w-full mt-[20px]'>
              <Button
                text='VIEW RECRUITMENT'
                type='submit'
                onClick={() => {
                  history.push(`/recruitment/view/${recruitmentId}`);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ShareRecruitmentModal;
