import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PAGINATION_DEFAULT } from "../../../../utils/constants";
import Button from "../../../button";
import DataTable from "../../../datatable";
import AddAttendanceSchedule from "../../../modal/hr-section/attendance/add-attendance-schedule";
import {
  useDeleteAttendanceSchedule,
  useGetAttendanceScheduleBySource,
} from "../../../../redux/human-resources/hook/attendance";

const BranchScheduleTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    source: "branch",
    search: "",
    start_date: "",
    end_date: "",
  });
  const { id } = useParams();

  const { data: attendance_schedules } = useGetAttendanceScheduleBySource(
    id,
    "branch",
    pagination?.limit,
    pagination?.page,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date
  );

  const { mutateAsync: deleteSchedule } = useDeleteAttendanceSchedule();

  const [showAddAttendanceSchedule, setShowAddAttendanceSchedule] =
    useState(false);
  const [scheduleData, setScheduleData] = useState();
  const [title, setTitle] = useState("Add Attendance Schedule");
  const [buttonText, setButtonText] = useState("ADD");

  const tableConfig = {
    headers: [
      "Schedule Date",
      "Schedule Title",
      "Working Day",
      "Resumption",
      "Closing",
    ],
    keys: [
      "schedule_date",
      "schedule_name",
      "working_day",
      "clockin_time",
      "clockout_time",
    ],
    mobileHeader: {
      left: {
        title: "Schedule Date",
        key: "schedule_date",
      },
      right: {
        title: "Schedule Title",
        key: "schedule_name",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "orange",
      action: (id) => {
        let index = attendance_schedules.data.findIndex(
          (item) => item.id === id
        );
        setButtonText("UPDATE");
        setTitle("Edit Attendance Schedule");
        setScheduleData(attendance_schedules.data[index]);
        setShowAddAttendanceSchedule(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = attendance_schedules.data.findIndex(
          (item) => item.id === id
        );
        deleteSchedule(attendance_schedules.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddAttendanceSchedule ? (
        <AddAttendanceSchedule
          isOpen={showAddAttendanceSchedule}
          scheduleData={scheduleData}
          buttonText={buttonText}
          title={title}
          closeModal={() => setShowAddAttendanceSchedule(false)}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Attendance Schedule
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Schedule"
              type="submit"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setButtonText("ADD");
                setTitle("Add Attendance Schedule");
                setScheduleData(null);
                setShowAddAttendanceSchedule(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2" />
      <DataTable
        data={attendance_schedules}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        searchData={true}
        attendance={true}
        filterByDate={true}
      />
    </div>
  );
};

export default BranchScheduleTab;
