import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRouteQuery } from "../../../hooks/useRouteQuery";
import { Initials, formatNumber, toDataUrl } from "../../../utils/functions";
import FormInput from "../../../components/form-input";
import FormDatalist from "../../../components/form-datalist";
import { useGetVendorsToPay } from "../../../redux/spend-management/vendor/hook";
import { useGetBanks, useResolveBankAccount } from "../../../redux/bank/hook";
import moment from "moment";
import Button from "../../../components/button";
import FormTextArea from "../../../components/form-textarea";
import {
  useCreateSinglePurchaseOrder,
  useGetInvoiceReceivedByPurchaseOrder,
  useGetPurchaseOrder,
  useSendPurchaseOrder,
  useUpdatePurchaseOrder,
} from "../../../redux/spend-management/purchase-order/hook";
import FormSwitch from "../../../components/form-switch";
import {
  PAGINATION_DEFAULT,
  invoiceReceivedStatus,
  purchaseOrderStatusConfig,
} from "../../../utils/constants";
import { StatusText } from "../../../components/fragments/status-text";
import { store } from "../../../redux";
import { setAlert } from "../../../redux/components/components-slice";
import AddInvoiceReceived from "../../../components/modal/spend-management/vendor/add-invoice-received";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import classNames from "classnames";
import DataTable from "../../../components/datatable";
import PayInvoiceRecieved from "../../../components/modal/spend-management/vendor/pay-invoice";
import InvoiceReceivedPayments from "../../../components/modal/spend-management/vendor/invoice-received-payments";
import MarkInvoiceReceivedModal from "../../../components/modal/spend-management/vendor/mark-invoice-recieved";
import AddSmallSVG from "../../../assets/svg/add-icon-smal-spend.svg";
import SelectVendorOption from "../../../components/modal/spend-management/vendor/select-vendor-option";

const PurchaseOrderPage = () => {
  useEffect(() => {}, []);

  const history = useHistory();
  const { company } = useSelector((state) => state.companySlice);

  function genRandomString(length) {
    var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var charLength = chars.length;
    var result = "";
    for (var i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
  }

  const [purchaseOrderId, setPurchaseOrderId] = useState(null);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const [totalPaid, setTotalPaid] = useState(0);

  const [showMarkInvoiceReceived, setShowMarkInvoiceReceived] = useState(false);
  const [showPayInvoiceReceived, setShowPayInvoiceReceived] = useState(false);
  const [showInvoiceReceivedPayments, setShowInvoiceReceivedPayments] =
    useState(false);

  const [showSelectAddVendorOption, setShowSelectAddVendorOption] =
    useState(false);

  const [invoiceReceivedData, setInvoiceReceivedData] = useState();
  const [invoiceId, setInvoiceId] = useState();
  const [imageBlob, setImageBlob] = useState(null);

  const { data: invoices_received } = useGetInvoiceReceivedByPurchaseOrder(
    purchaseOrderId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const changeImage = async (imageLink) => {
    let urlBase64 = await toDataUrl(imageLink);
    setImageBlob(urlBase64);
  };

  useEffect(() => {
    if (company) {
      changeImage(company.logo);
    }
    return setImageBlob(null);
  }, [company]);

  useEffect(() => {
    if (invoices_received) {
      let amountPaid = 0;
      invoices_received?.data?.forEach((element) => {
        amountPaid += parseFloat(element.total_amount);
      });
      setTotalPaid(amountPaid);
    }
  }, [invoices_received]);

  const tableConfig = {
    // title: 'Bonus Management',
    headers: [
      "Invoice Date",
      "Vendor Name",
      "Mobile",
      "Due Date",
      "Invoice #",
      "Total Amount",
      "Status",
      "Due Status",
    ],
    keys: [
      "formatted_invoice_date",
      "vendor_name",
      "vendor_mobile",
      "formatted_invoice_due_date",
      "invoice_number",
      "formatted_total_amount",
      "status",
      "due_status",
    ],
    mobileHeader: {
      left: {
        title: "Vendor Name",
        key: "vendor_name",
      },
      right: {
        title: "Total Amount",
        key: "total_amount",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "orange",
      action: (id) => {
        let index = invoices_received.data.findIndex((item) => item.id === id);
        setInvoiceReceivedData(invoices_received.data[index]);
        setButtonText("UPDATE");
        setTitle("Update Invoice Received");
        setShowAddInvoiceReceived(true);
      },
    },
    {
      name: "Pay Invoice",
      color: "green",
      action: (id) => {
        let index = invoices_received.data.findIndex((item) => item.id === id);
        setInvoiceReceivedData(invoices_received.data[index]);
        setShowPayInvoiceReceived(true);
      },
    },
    {
      name: "View Payments",
      color: "orange",
      action: (id) => {
        let index = invoices_received.data.findIndex((item) => item.id === id);
        setInvoiceId(invoices_received.data[index].id);
        setShowInvoiceReceivedPayments(true);
      },
    },
    {
      name: "Mark as paid",
      color: "green",
      action: (id) => {
        let index = invoices_received.data.findIndex((item) => item.id === id);
        setInvoiceReceivedData(invoices_received.data[index]);
        setShowMarkInvoiceReceived(true);
      },
    },
    {
      name: "Download",
      color: "red",
      action: (id) => {
        let index = invoices_received.data.findIndex((item) => item.id === id);
        window.open(invoices_received.data[index].purchase_order_url, "_blank");
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = invoices_received.data.findIndex((item) => item.id === id);
        deleteInvoiceReceived(invoices_received.data[index].id);
      },
    },
  ];

  const [exportLoading, setExportLoading] = useState(false);

  const [showAddInvoiceReceived, setShowAddInvoiceReceived] = useState(false);
  const [purchaseOrderData, setPurchaseOrderData] = useState();

  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const { data: purchase_order } = useGetPurchaseOrder(purchaseOrderId);

  const { data: vendors } = useGetVendorsToPay(100000, 1, -1, "");
  const { mutate: addPurchaseOrder, isLoading: addPurchaseOrderLoading } =
    useCreateSinglePurchaseOrder();
  const { mutate: updatePurchaseOrder, isLoading: updatePurchaseOrderLoading } =
    useUpdatePurchaseOrder();
  const { mutate: sendPurchaseOrder, isLoading: sendPurchaseOrderLoading } =
    useSendPurchaseOrder();

  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } =
    useResolveBankAccount();
  const { data: banks } = useGetBanks();

  const [formData, setFormData] = useState({
    vendor_id: null,
    vendor_name: "",
    vendor_email: "",
    vendor_mobile: "",
    vendor_address: "",
    contact_person: "",
    purchase_order_date: moment().format("YYYY-MM-DD"),
    purchase_order_due_date: moment().add(7, "days").format("YYYY-MM-DD"),
    purchase_order_number:
      moment().format("YYYY-MM-DD") + "-" + genRandomString(5),
    shipping_customer_name: "",
    shipping_contact_person: "",
    shipping_address: "",
    shipping_email: "",
    shipping_phone: "",
    purchase_order_items: [
      {
        item_number: "",
        item_name: "",
        description: "",
        quantity: 1,
        unit_price: 0,
        total: 0,
      },
    ],
    shipping_terms: "",
    shipping_method: "",
    shipping_date: moment().format("YYYY-MM-DD"),
    purchase_order_comments: "",
    purchase_order_tax_rate: 7.5,
    purchase_order_sub_total: 0,
    purchase_order_tax_amount: 0,
    shipping_cost: 0,
    total_amount: 0,
    bank_name: "",
    account_number: "",
    account_name: "",
    status: 0,
  });

  useEffect(() => {
    if (purchase_order) {
      setPurchaseOrderSaveType("edit");
      setDefaultText(
        purchase_order.vendor_name + " | " + purchase_order.vendor_mobile
      );
      setFormData({
        vendor_id: purchase_order.vendor_id,
        vendor_name: purchase_order.vendor_name,
        vendor_email: purchase_order.vendor_email,
        vendor_mobile: purchase_order.vendor_mobile,
        vendor_address: purchase_order.vendor_address,
        contact_person: purchase_order.contact_person,
        purchase_order_date: purchase_order.purchase_order_date,
        purchase_order_due_date: purchase_order.purchase_order_due_date,
        purchase_order_number: purchase_order.purchase_order_number,
        shipping_customer_name: purchase_order.shipping_customer_name,
        shipping_contact_person: purchase_order.shipping_contact_person,
        shipping_address: purchase_order.shipping_address,
        shipping_email: purchase_order.shipping_email,
        shipping_phone: purchase_order.shipping_phone,
        shipping_terms: purchase_order.shipping_terms,
        shipping_method: purchase_order.shipping_method,
        shipping_date: purchase_order.shipping_date,
        purchase_order_items: purchase_order.purchase_order_items,
        purchase_order_comments: purchase_order.purchase_order_comments,
        purchase_order_tax_rate: purchase_order.purchase_order_tax_rate,
        purchase_order_sub_total: purchase_order.purchase_order_sub_total,
        purchase_order_tax_amount: purchase_order.purchase_order_tax_amount,
        shipping_cost: purchase_order.shipping_cost,
        total_amount: purchase_order.total_amount,
        bank_name: purchase_order.bank_name,
        account_number: purchase_order.account_number,
        account_name: purchase_order.account_name,
        status: purchase_order.status,
      });
      setEditMode(false);
    }
  }, [purchase_order]);

  const [defaultText, setDefaultText] = useState();

  const [purchaseOrderSaveType, setPurchaseOrderSaveType] = useState("add");

  const [editMode, setEditMode] = useState(true);

  const query = useRouteQuery();

  const pdfJSX = () => {
    return (
      <div className="flex flex-col gap-4 min-h-fit max-w-full bg-white p-4">
        <div className="flex gap-2 justify-end  items-center">
          <span className="text-primary-1 text-[30px] ">PURCHASE ORDER</span>
          <span
            className={classNames(
              "flex items-center justify-center text-[12px] !min-w-fit text-center pb-[12px] mt-2",
              {
                "text-color-green-medium px-2 bg-color-green-light rounded-lg":
                  purchaseOrderStatusConfig[purchase_order?.status].color ===
                  "green",
              },
              {
                "text-color-orange px-2 bg-color-orange-light rounded-lg":
                  purchaseOrderStatusConfig[purchase_order?.status].color ===
                  "orange",
              },
              {
                "text-color-error px-2 bg-color-error-light rounded-lg":
                  purchaseOrderStatusConfig[purchase_order?.status].color ===
                  "red",
              }
            )}
          >
            {purchaseOrderStatusConfig[purchase_order?.status].label}
          </span>
        </div>
        <div className="flex  items-center justify-between bg-primary-1 p-3 text-color-white rounded-md">
          <div className="w-1/2 flex flex-col items-start gap-1">
            <div className="flex gap-2 items-center">
              <div className="text-[20px] flex font-bold justify-center items-center h-[55px] w-[100px] header-3 relative rounded-xl bg-white">
                {company?.logo ? (
                  <div>
                    <img
                      src={imageBlob}
                      alt="company_logo"
                      className="object-fit min-w-[70px] min-h-[45px] max-w-[70px] max-h-[45px] "
                    />
                  </div>
                ) : (
                  <span className="text-primary-1">
                    {Initials(company?.company_name)}
                  </span>
                )}
              </div>
              <span className="text-color-white text-[25px] pb-4">
                {company?.company_name}
              </span>
            </div>
            <div className="flex flex-col justify-start gap-2 font-normal">
              <div className="w-full flex justify-start text-[13px] text-color-white">
                {company?.address}, {company?.city}, {company?.state},{" "}
                {company?.country}.
              </div>
              <div className="w-full flex justify-start text-[13px] font-semibold text-color-white">
                {company?.email} | {company?.mobile}
              </div>
            </div>
          </div>
          <div className="w-1/2 flex justify-end text-2xl text-primary-1  mt-3 pt-0">
            <div className="w-full flex flex-col items-start">
              <div className="flex flex-col  justify-end items-end font-normal w-full gap-0">
                <div className="flex justify-end px-2 items-center h-[42px] gap-2 w-full text-[14px] text-color-white">
                  <span className="text-[14px] w-2/3 text-right font-semibold text-color-white">
                    Puchase Order Date:
                  </span>
                  <div className="w-1/2 text-right text-color-white text-[13px]">
                    {moment(purchase_order.purchase_order_date).format(
                      "MMMM DD, YYYY"
                    )}
                  </div>
                </div>
                <div className="flex justify-end px-2 items-center h-[42px] gap-2 w-full text-[14px] text-color-white -mt-4">
                  <span className="text-[14px] w-2/3 text-right font-semibold text-color-white">
                    Due Date:
                  </span>
                  <div className="w-1/2 text-right text-color-white text-[14px]">
                    {moment(purchase_order.purchase_order_due_date).format(
                      "MMMM DD, YYYY"
                    )}
                  </div>
                </div>
                <div className="flex justify-start px-2 items-center h-[42px] gap-2 w-full text-[14px] text-color-white -mt-4">
                  <span className="text-[14px] w-2/3 text-right font-semibold text-color-white">
                    Purchase Order #:
                  </span>
                  <div className="w-1/2 text-right text-color-white text-[14px]">
                    {purchase_order.purchase_order_number}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-3 justify-between font-normal">
          <div className=" w-[50%] flex flex-col justify-start min-h-[200px] border rounded-md ">
            <div className="flex items-center px-4 bg-secondary-2-extralight pb-3 w-full mb-1 text-[14px] text-primary-1 font-extrabold border-b-2 border-b-primary-1">
              VENDOR:
            </div>
            {purchase_order.vendor_id ? (
              <div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5">Vendor name:</span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {purchase_order.vendor_name}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5">Contact Person:</span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {purchase_order.contact_person}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5">Address:</span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {purchase_order.vendor_address}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5">Email:</span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {purchase_order.vendor_email}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5">Phone:</span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {purchase_order.vendor_mobile}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-center h-6 items-center">
                No vendor selected
              </div>
            )}
          </div>
          <div className="w-[50%] flex flex-col justify-start min-h-[200px] border rounded-md pb-2">
            <div className="flex items-center px-4 bg-secondary-2-extralight pb-3 w-full mb-1 border-b-2 border-b-primary-1">
              <span className="text-[14px] text-primary-1 font-extrabold">
                SHIP TO:
              </span>
            </div>
            <div className="flex justify-start px-4 items-center h-[42px] gap-2">
              <span className="text-[13px] w-2/5">Contact Person:</span>
              <div className="w-3/4 items-center justify-center text-[13px]">
                {purchase_order.shipping_contact_person}
              </div>
            </div>
            <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
              <span className="text-[13px] w-2/5">Shipping Address:</span>
              <div className="w-3/4 items-center justify-center text-[13px]">
                {purchase_order.shipping_address}
              </div>
            </div>
            <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
              <span className="text-[13px] w-2/5">Email:</span>
              <div className="w-3/4 items-center justify-center text-[13px]">
                {purchase_order.shipping_email}
              </div>
            </div>
            <div className="flex justify-start px-4 items-center h-[42px] gap-2">
              <span className="text-[13px] w-2/5">Phone:</span>
              <div className="w-3/4 items-center justify-center text-[13px]">
                {purchase_order.shipping_phone}
              </div>
            </div>
          </div>
        </div>
        <div className="flex pb-3 bg-secondary-2-extralight border rounded-md">
          <div className="flex w-1/3 items-center justify-center flex-col ">
            <div className="text-primary-1 font-extrabold text-[15px]">
              SHIPPING METHOD
            </div>
            <div className="!font-normal text-[13px]">
              {purchase_order.shipping_method}
            </div>
          </div>
          <div className="flex w-1/3 items-center justify-center flex-col">
            <div className="text-primary-1 font-extrabold text-[15px]">
              SHIPPING TERMS
            </div>
            <div className="!font-normal text-[13px]">
              {purchase_order.shipping_terms}
            </div>
          </div>
          <div className="flex w-1/3 items-center justify-center flex-col">
            <div className="text-primary-1 font-extrabold text-[15px]">
              DELIVERY DATE
            </div>
            <div className="!font-normal text-[13px]">
              {moment(purchase_order.shipping_date).format("MMMM DD, YYYY")}
            </div>
          </div>
        </div>

        <div className="flex flex-col font-normal border-b ">
          <div className="flex pb-3 bg-secondary-2-extralight border-b-2 border-b-primary-1">
            <div className="flex w-1/6 items-start justify-center flex-col px-3">
              <div className="text-primary-1 !text-[13px] font-bold">
                ITEM #
              </div>
            </div>
            <div className="flex w-2/6 items-start justify-center flex-col px-3">
              <div className="text-primary-1 !text-[13px] font-bold">
                ITEM NAME
              </div>
            </div>
            <div className="flex w-2/6 items-start justify-center flex-col px-3">
              <div className="text-primary-1 !text-[13px] font-bold text-center">
                DESCRIPTION
              </div>
            </div>
            <div className="flex w-1/6 items-start justify-center flex-col px-3">
              <div className="text-primary-1 !text-[13px] font-bold">QTY</div>
            </div>
            <div className="flex w-1/5 items-start justify-center flex-col px-3">
              <div className="text-primary-1 !text-[12px] font-bold">
                UNIT PRICE
              </div>
            </div>
            <div className="flex w-1/5 items-start justify-center flex-col px-3">
              <div className="text-primary-1 !text-[12px] font-bold">TOTAL</div>
            </div>
          </div>
          {purchase_order &&
            purchase_order.purchase_order_items.length > 0 &&
            purchase_order.purchase_order_items.map((item) => (
              <div className="flex h-[60px] hover:bg-gray-100 duration-300 gap-2">
                <div className="flex w-1/6 items-center justify-start px-2 !text-[13px] !h-[60px]">
                  {item.item_number}
                </div>
                <div className="flex w-2/6 items-center justify-start px-2 !text-[13px] !h-[60px]">
                  {item.item_name}
                </div>
                <div className="flex w-2/6 items-center justify-start px-2 !text-[13px] !h-[60px]">
                  {item.description}
                </div>
                <div className="flex w-1/6 items-center justify-end px-2 !text-[13px] !h-[60px]">
                  {formatNumber(item.quantity, 2)}
                </div>
                <div className="flex w-1/5 items-center justify-end px-2 !text-[13px] !h-[60px]">
                  {formatNumber(item.unit_price, 2)}
                </div>
                <div className="flex w-1/5 items-center justify-end pr-4 bg-secondary-2-extralight !text-[13px] !h-[60px]">
                  <div className="text-primary-1 !text-[13px] font-bold">
                    {formatNumber(item.total, 2)}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="flex h-[180px] items-center gap-4 w-full">
          <div className="w-4/6 ">
            <div className="w-[80%] flex flex-col justify-start min-h-[120px] rounded-md">
              <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full border-b-2 border-b-primary-1">
                <span className="text-[14px] text-primary-1 font-extrabold">
                  ADDITIONAL COMMENTS AND INSTRUCTIONS
                </span>
              </div>
              <div className="text-[13px] border-1 border-gray-300 !bg-white w-full font-normal !rounded-t-none p-4 mt-0 pt-2">
                {purchase_order.purchase_order_comments}
              </div>
            </div>
          </div>
          <div className="flex w-2/6 justify-end pt-0">
            <div className="flex flex-col w-full h-full justify-center pt-2">
              <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                <div className=" flex items-center justify-start !w-1/2 pb-2">
                  Sub Total
                </div>
                <div className="flex justify-end text-primary-1 px-2 !w-1/2 bg-secondary-2-extralight h-full items-center pb-2">
                  {formatNumber(purchase_order.purchase_order_sub_total, 2)}
                </div>
              </div>
              <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                <div className=" flex items-center justify-start !w-1/2">
                  <span className="flex items-center !font-extrabold text-[16px]">
                    Tax&nbsp;
                  </span>
                  ({purchase_order.purchase_order_tax_rate}
                  <span className="flex items-center !font-extrabold text-[16px]">
                    %
                  </span>
                  )
                </div>
                <div className="flex justify-end text-primary-1 px-2 !w-1/2 bg-secondary-2-extralight h-full items-center">
                  {formatNumber(purchase_order.purchase_order_tax_amount, 2)}
                </div>
              </div>
              <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                <div className=" flex items-center justify-start !w-1/2 pb-2">
                  Shipping cost
                </div>
                <div className="flex justify-end text-primary-1 px-2 !w-1/2 bg-secondary-2-extralight h-full items-center pb-2">
                  {formatNumber(purchase_order.shipping_cost)}
                </div>
              </div>
              <div className="flex justify-between !font-extrabold text-[16px] gap-2 items-center !h-[60px]">
                <div className=" flex items-center justify-start !w-1/2 pb-3">
                  Grand Total
                </div>
                <div className="flex justify-end text-white px-2 !w-1/2 bg-secondary-2-extralight h-full items-center bg-primary-1 text-[20px] font-extrabold pb-3">
                  {formatNumber(purchase_order.total_amount, 2)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col w-full bg-gray-50 !min-h-16 p-4 py-2 border md:mt-6 rounded-md">
          <div className="flex flex-col md:w-1/2 w-full">
            <div className="text-[15px] text-primary-1 font-extrabold">
              PAYMENT INSTRUCTION
            </div>
            <div className="flex justify-start items-center h-[42px] gap-2">
              <span className="text-[13px] w-2/5 font-semibold">
                Bank Name:
              </span>
              <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                {purchase_order.bank_name}
              </div>
            </div>
            <div className="flex justify-start items-center h-[42px] gap-2">
              <span className="text-[13px] w-2/5 font-semibold">
                Account Number:
              </span>
              <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                {purchase_order.account_number}
              </div>
            </div>
            <div className="flex justify-start items-center h-[42px] gap-2">
              <span className="text-[13px] w-2/5 font-semibold">
                Account Name:
              </span>
              <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                {purchase_order.account_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const printHandler = () => {
    setExportLoading(true);
    const printElement = ReactDOMServer.renderToString(pdfJSX());
    var opt = {
      margin: 0,
      filename: purchase_order?.purchase_order_number + " - purchase_order.pdf",
      image: { type: "", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(opt).from(printElement).save();
    setExportLoading(false);
  };

  useEffect(() => {
    if (query) {
      if (query.get("purchaseOrderId")) {
        setPurchaseOrderId(query.get("purchaseOrderId"));
      }
    }
  }, [query]);

  const save = () => {
    let payload = { ...formData };
    if (purchaseOrderSaveType === "add") {
      if (formData.vendor_id) {
        if (formData.purchase_order_items.length > 0) {
          let $error = false;
          let purchaseOrderItem = formData.purchase_order_items;
          purchaseOrderItem.forEach((element, index) => {
            if (
              !element.quantity ||
              !element.unit_price ||
              !element.total ||
              !element.item_name
            ) {
              store.dispatch(
                setAlert(
                  true,
                  "info",
                  "There is an error in row " +
                    (index + 1).toString() +
                    ". Please check"
                )
              );
              $error = true;
            }
          });
          if ($error === false) {
            addPurchaseOrder(payload);
          }
        } else {
          store.dispatch(
            setAlert(
              true,
              "info",
              "You must have at least one purchase order line item to save this purchase order"
            )
          );
          return;
        }
      } else {
        store.dispatch(setAlert(true, "info", "You must select a vendor"));
        return;
      }
    } else if (purchaseOrderSaveType === "edit" && purchaseOrderId) {
      if (formData.vendor_id) {
        if (formData.purchase_order_items.length > 0) {
          let $error = false;
          let purchaseOrderItem = formData.purchase_order_items;
          purchaseOrderItem.forEach((element, index) => {
            if (
              !element.quantity ||
              !element.unit_price ||
              !element.total ||
              !element.item_name
            ) {
              store.dispatch(
                setAlert(
                  true,
                  "info",
                  "There is an error in row " +
                    (index + 1).toString() +
                    ". Please check"
                )
              );
              $error = true;
            }
          });
          if ($error === false) {
            updatePurchaseOrder({
              id: purchaseOrderId,
              data: payload,
            });
          }
        } else {
          store.dispatch(
            setAlert(
              true,
              "info",
              "You must have at least one purchase order line item to save this purchase order"
            )
          );
          return;
        }
      } else {
        store.dispatch(setAlert(true, "info", "You must select a vendor"));
        return;
      }
    } else {
      store.dispatch(
        setAlert(true, "info", "Please provide an purchase order ID")
      );
    }
  };

  return (
    <DashboardLayout
      title={
        purchaseOrderId && !editMode
          ? "View Purchase Order"
          : purchaseOrderId && editMode
          ? "Update Purchase Order"
          : "New Purchase Order"
      }
    >
      {showMarkInvoiceReceived ? (
        <MarkInvoiceReceivedModal
          isOpen={showMarkInvoiceReceived}
          closeModal={() => setShowMarkInvoiceReceived(false)}
          invoiceReceivedData={invoiceReceivedData}
        />
      ) : null}
      {showAddInvoiceReceived ? (
        <AddInvoiceReceived
          isOpen={showAddInvoiceReceived}
          closeModal={() => setShowAddInvoiceReceived(false)}
          purchaseOrderData={purchaseOrderData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      {showPayInvoiceReceived ? (
        <PayInvoiceRecieved
          isOpen={showPayInvoiceReceived}
          closeModal={() => setShowPayInvoiceReceived(false)}
          invoiceReceivedData={invoiceReceivedData}
        />
      ) : null}
      {showInvoiceReceivedPayments ? (
        <InvoiceReceivedPayments
          isOpen={showInvoiceReceivedPayments}
          closeModal={() => setShowInvoiceReceivedPayments(false)}
          invoiceId={invoiceId}
        />
      ) : null}
      {showSelectAddVendorOption ? (
        <SelectVendorOption
          isOpen={showSelectAddVendorOption}
          closeModal={() => setShowSelectAddVendorOption(false)}
        />
      ) : null}
      <div className="flex md:flex-row flex-col md:justify-between justify-end md:items-center gap-2 -mt-8">
        {!editMode ? (
          <div className="flex md:flex-row flex-col md:w-1/2 w-full gap-2 min-h-fit md:justify-start md:items-center items-center">
            <Button
              text="Send to vendor"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] w-fit p-2 "
              textClass={"!text-[11px]"}
              leftIcon={"email"}
              loading={sendPurchaseOrderLoading}
              onClick={() => {
                sendPurchaseOrder(purchaseOrderId);
              }}
            />
            <Button
              text="DOWNLOAD"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] w-fit p-2 "
              textClass={"!text-[11px]"}
              leftIcon={"export"}
              loading={exportLoading}
              onClick={() => {
                printHandler();
              }}
            />
          </div>
        ) : null}
        <div
          className={classNames("md:w-1/2 w-full flex justify-end mt-4", {
            "!w-full": editMode,
          })}
        >
          <FormSwitch
            label={"Edit Purchase Order"}
            name={"switch-edit-mode"}
            checked={editMode}
            readOnly={!purchaseOrderId}
            classNames={
              "!text-[15px] font-semibold hover:scale-110 duration-300"
            }
            onClick={() => {
              if (purchaseOrderId) {
                setEditMode(!editMode);
              }
            }}
            value={editMode}
          />
        </div>
      </div>
      <div className="flex flex-col bg-white rounded-md p-4 px-8 font-semibold border">
        {editMode ? (
          <div className="flex flex-col  min-h-fit">
            <div className="flex md:flex-row flex-col items-start justify-between">
              <div className="md:w-1/2 w-full flex flex-col items-start gap-2 md:pb-0 pb-3">
                <div className="flex gap-2 items-center justify-center">
                  <div className="text-[13px] flex font-bold justify-center items-center duration-500 border rounded-xl">
                    {company?.logo ? (
                      <img
                        src={company?.logo}
                        alt="logo"
                        className="object-fit min-w-[55px] min-h-[30px] max-w-[55px] max-h-[30px] "
                      />
                    ) : (
                      <>{Initials(company?.company_name)}</>
                    )}
                  </div>
                  <span className=" text-primary-1 text-[20px] ">
                    {company?.company_name}
                  </span>
                </div>
                <div className="md:w-1/2 flex flex-col justify-start gap-2 font-normal">
                  <div className="w-full flex justify-start text-[14px]">
                    {company?.address}, {company?.city}, {company?.state},{" "}
                    {company?.country}
                  </div>
                  <div className="w-full flex justify-start text-[14px]">
                    {company?.email} | {company?.mobile}
                  </div>
                  {/* <div className='w-full flex justify-start text-[14px]'>
                                    Phone: 
                                </div> */}
                </div>
              </div>
              <div className="md:w-1/2 w-full flex md:justify-end justify-center text-2xl text-primary-1 md:border-t-0 border-t-2 border-t-primary-1 md:pt-0 md:border-b-0 border-b-2 border-b-primary-1 md:pb-0 pb-3">
                <div className="w-full flex flex-col items-start justify-end gap-2">
                  <div className="flex text-primary-1 text-[25px] gap-1 md:justify-end w-full">
                    PURCHASE ORDER{" "}
                    <span className="text-color-gray text-[14px]">(Draft)</span>
                  </div>
                  <div className="flex flex-col md:justify-end md:items-end font-normal w-full">
                    <div className="flex justify-start md:px-4 items-center h-[42px] gap-2 w-full">
                      <span className="text-[13px] w-2/5">
                        Purchase Order Date:
                      </span>
                      <div className="w-3/4 items-center justify-center">
                        <FormInput
                          name={"purchase_order_date"}
                          type="date"
                          value={formData.purchase_order_date}
                          onChange={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.purchase_order_date = e.target.value;
                            setFormData(newArray);
                          }}
                          className="!h-[25px] text-[12px] border-1 border-gray-300 w-full font-normal"
                        />
                      </div>
                    </div>
                    <div className="flex justify-start md:px-4 items-center h-[42px] gap-2 w-full">
                      <span className="text-[13px] w-2/5">Due Date:</span>
                      <div className="w-3/4 items-center justify-center">
                        <FormInput
                          name={"purchase_order_due_date"}
                          type="date"
                          value={formData.purchase_order_due_date}
                          onChange={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.purchase_order_due_date = e.target.value;
                            setFormData(newArray);
                          }}
                          className="!h-[25px] text-[12px] border-1 border-gray-300 w-full font-normal"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col gap-3 justify-between font-normal md:mt-0  mt-3">
              <div className="md:w-[40%] w-full flex flex-col justify-start min-h-[200px] border rounded-md ">
                <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full mb-1 border-b-2 border-b-primary-1">
                  <span className="md:text-[15px] text-[13px] text-primary-1 font-extrabold">
                    VENDOR
                  </span>
                </div>
                <div className="flex gap-3 px-4 items-center w-full">
                  <div className="min-w-[90%]">
                    <FormDatalist
                      placeholder="Type to select vendor"
                      defaultText={defaultText}
                      data={vendors ? vendors : []}
                      onSelect={(value) => {
                        let index = vendors.findIndex(
                          (item) => item.value === value
                        );
                        if (index !== -1) {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.vendor_id = vendors[index].value;
                          newArray.vendor_name = vendors[index].vendor_name;
                          newArray.vendor_email = vendors[index].email;
                          newArray.vendor_mobile = vendors[index].mobile;
                          newArray.vendor_address = `${vendors[index].address}, ${vendors[index].city}, ${vendors[index].state}, ${vendors[index].country}.`;
                          newArray.contact_person =
                            vendors[index].contact_person;
                          newArray.shipping_customer_name =
                            vendors[index].vendor_name;
                          newArray.shipping_contact_person =
                            vendors[index].contact_person;
                          newArray.shipping_address = `${vendors[index].address}, ${vendors[index].city}, ${vendors[index].state}, ${vendors[index].country}.`;
                          newArray.shipping_email = vendors[index].email;
                          newArray.shipping_phone = vendors[index].mobile;
                          newArray.bank_name = vendors[index].bank;
                          newArray.account_number =
                            vendors[index].account_number;
                          newArray.account_name = vendors[index].account_name;
                          setFormData(newArray);
                        }
                      }}
                    />
                  </div>
                  <img
                    src={AddSmallSVG}
                    alt={"add_question"}
                    className={"h-[25px] w-[25px] mb-1 cursor-pointer"}
                    onClick={() => {
                      setShowSelectAddVendorOption(true);
                    }}
                  />
                </div>
                <hr className="divider mt-0 mb-2" />
                {formData.vendor_id ? (
                  <div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Vendor name:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData.vendor_name}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2 mb-1 -mt-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Contact Person:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData.contact_person}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2 mb-1">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Address:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData.vendor_address}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Email:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData.vendor_email}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2 -mt-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Phone:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData.vendor_mobile}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center h-6 items-center">
                    No vendor selected
                  </div>
                )}
              </div>
              <div className="md:w-[50%] w-full flex flex-col justify-start min-h-[200px] border rounded-md pb-2">
                <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full mb-1 border-b-2 border-b-primary-1">
                  <span className="md:text-[15px] text-[13px] text-primary-1 font-extrabold">
                    SHIP TO
                  </span>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Contact Person:
                  </span>
                  <div className="w-3/4 items-center justify-center">
                    <FormInput
                      name={"shipping_contact_person"}
                      placeholder={"Enter shipping contact person"}
                      type="text"
                      value={formData.shipping_contact_person}
                      onChange={(e) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.shipping_contact_person = e.target.value;
                        setFormData(newArray);
                      }}
                      className="!h-[25px] text-[13px] border-1 border-gray-300 w-full font-normal"
                    />
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Address:
                  </span>
                  <div className="w-3/4 items-center justify-center">
                    <FormInput
                      name={"shipping_address"}
                      placeholder={"Enter shipping address"}
                      type="text"
                      value={formData.shipping_address}
                      onChange={(e) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.shipping_address = e.target.value;
                        setFormData(newArray);
                      }}
                      className="!h-[25px] text-[13px] border-1 border-gray-300 w-full font-normal"
                    />
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Email:
                  </span>
                  <div className="w-3/4 items-center justify-center">
                    <FormInput
                      name={"shipping_email"}
                      placeholder={"Enter contact email"}
                      type="text"
                      value={formData.shipping_email}
                      onChange={(e) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.shipping_email = e.target.value;
                        setFormData(newArray);
                      }}
                      className="!h-[25px] text-[13px] border-1 border-gray-300 !w-full font-normal"
                    />
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Phone:
                  </span>
                  <div className="w-3/4 items-center justify-center">
                    <FormInput
                      name={"shipping_phone"}
                      placeholder={"Enter shipping contact phone"}
                      type="text"
                      value={formData.shipping_phone}
                      onChange={(e) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.shipping_phone = e.target.value;
                        setFormData(newArray);
                      }}
                      className="!h-[25px] text-[13px] border-1 border-gray-300 w-full font-normal"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col h-fit bg-secondary-2-extralight border rounded-md pt-2 px-2 gap-2 mt-3">
              <div className="md:w-1/4 w-full items-center justify-center flex-col">
                <div className="text-primary-1 -mb-2">SHIPPING METHOD</div>
                <FormInput
                  name={"shipping_method"}
                  placeholder={"Enter shipping method"}
                  type="text"
                  value={formData.shipping_method}
                  onChange={(e) => {
                    let newArray = JSON.parse(JSON.stringify(formData));
                    newArray.shipping_method = e.target.value;
                    setFormData(newArray);
                  }}
                  className="!h-[30px] text-[13px] border-1 border-gray-300 w-full font-normal"
                />
              </div>
              <div className="md:w-2/4 w-full items-center justify-center flex-col">
                <div className="!text-primary-1 -mb-2">SHIPPING TERMS</div>
                <FormInput
                  name={"shipping_terms"}
                  placeholder={"Enter shipping terms"}
                  type="text"
                  value={formData.shipping_terms}
                  onChange={(e) => {
                    let newArray = JSON.parse(JSON.stringify(formData));
                    newArray.shipping_terms = e.target.value;
                    setFormData(newArray);
                  }}
                  className="!h-[30px] text-[13px] border-1 border-gray-300 w-full font-normal"
                />
              </div>
              <div className="md:w-1/4 w-full items-center justify-center flex-col">
                <div className="text-primary-1 -mb-2">
                  EXPECTED DELIVERY DATE
                </div>
                <FormInput
                  name={"shipping_date"}
                  placeholder={"Enter shipping method"}
                  type="date"
                  value={formData.shipping_date}
                  onChange={(e) => {
                    let newArray = JSON.parse(JSON.stringify(formData));
                    newArray.shipping_date = e.target.value;
                    setFormData(newArray);
                  }}
                  className="!h-[30px] text-[13px] border-1 border-gray-300 w-full font-normal"
                />
              </div>
            </div>
            <div className="hidden md:flex flex-col font-normal border-b mt-3">
              <div className="flex h-[50px] bg-primary-1 color-white">
                <div className="flex w-2/10 items-center justify-center px-2 min-w-[40px]">
                  <div className="text-color-white font-bold min-w-full"></div>
                </div>
                <div className="flex w-1/6 items-center justify-center flex-col">
                  <div className="text-color-white font-bold text-[14px]">
                    ITEM #
                  </div>
                </div>
                <div className="flex w-2/6 items-center justify-center flex-col">
                  <div className="text-color-white font-bold text-[14px]">
                    ITEM NAME
                  </div>
                </div>
                <div className="flex w-2/6 items-center justify-center flex-col">
                  <div className="text-color-white font-bold text-[14px]">
                    DESCRIPTION
                  </div>
                </div>
                <div className="flex w-1/6 items-center justify-center flex-col">
                  <div className="text-color-white font-bold text-[14px]">
                    QTY
                  </div>
                </div>
                <div className="flex w-1/6 items-center justify-center flex-col">
                  <div className="text-color-white font-bold text-[14px]">
                    UNIT PRICE
                  </div>
                </div>
                <div className="flex w-1/6 items-center justify-center flex-col">
                  <div className="text-white font-bold text-[14px]">TOTAL</div>
                </div>
              </div>
              {formData &&
                formData.purchase_order_items.length > 0 &&
                formData.purchase_order_items.map((item, index) => (
                  <div className="flex h-[60px] hover:bg-gray-100 duration-300 gap-2 pt-2">
                    <div className="flex w-2/10 items-center justify-center min-w-[40px] ml-4">
                      {formData.purchase_order_items.length > 1 ? (
                        <Button
                          text="Remove"
                          type="submit"
                          theme="secondary"
                          disabled={formData.purchase_order_items.length < 2}
                          className="flex gap-2 h-[25px] !min-w-[55px] !max-w-[60px] p-2 !bg-white border-none !shadow-none "
                          textClass={"!text-[8px] !text-red-500"}
                          onClick={() => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            let subArray = newArray.purchase_order_items;
                            let data = [...subArray];
                            data.splice(index, 1); // 2nd parameter means remove one item only
                            newArray.purchase_order_items = [...data];
                            setFormData(newArray);
                          }}
                        />
                      ) : null}
                    </div>
                    <div className="w-1/6 items-center justify-center px-2">
                      <FormInput
                        name={"item_number"}
                        placeholder={"Item number"}
                        type="text"
                        value={item.item_number}
                        onInput={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.purchase_order_items[index].item_number =
                            e.target.value;
                          setFormData(newArray);
                        }}
                        className="!h-[30px] text-[13px] border-1 border-gray-300 w-full"
                      />
                    </div>
                    <div className="w-2/6 items-center justify-center px-2">
                      <FormInput
                        name={"item_name"}
                        placeholder={"Item name"}
                        type="text"
                        value={item.item_name}
                        onInput={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.purchase_order_items[index].item_name =
                            e.target.value;
                          setFormData(newArray);
                        }}
                        className="!h-[30px] text-[13px] border-1 border-gray-300 w-full"
                      />
                    </div>
                    <div className="w-2/6 items-center justify-center px-2">
                      <FormInput
                        name={"description"}
                        placeholder={"Enter desciption"}
                        type="text"
                        value={item.description}
                        onInput={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.purchase_order_items[index].description =
                            e.target.value;
                          setFormData(newArray);
                        }}
                        className="!h-[30px] text-[13px] border-1 border-gray-300 !min-w-[100%]"
                      />
                    </div>
                    <div className="w-1/6 items-center justify-center px-2">
                      <FormInput
                        name={"quantity"}
                        placeholder={"Enter quantity"}
                        type="number"
                        value={item.quantity}
                        onChange={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.purchase_order_items[index].quantity =
                            parseFloat(e.target.value);
                          if (
                            e.target.value &&
                            newArray.purchase_order_items[index].quantity
                          ) {
                            newArray.purchase_order_items[index].total =
                              e.target.value *
                              parseFloat(
                                newArray.purchase_order_items[index].unit_price
                              );
                          } else {
                            newArray.purchase_order_items[index].total = 0;
                          }
                          let sub_total = 0;
                          if (
                            newArray.purchase_order_items &&
                            newArray.purchase_order_items.length > 0
                          ) {
                            newArray.purchase_order_items.forEach((subItem) => {
                              sub_total += subItem.total;
                            });
                          }
                          newArray.purchase_order_sub_total = sub_total;
                          newArray.purchase_order_tax_amount =
                            Math.round(
                              sub_total *
                                (newArray.purchase_order_tax_rate / 100) *
                                100
                            ) / 100;
                          newArray.total_amount =
                            newArray.purchase_order_sub_total +
                            newArray.purchase_order_tax_amount +
                            newArray.shipping_cost;
                          setFormData(newArray);
                        }}
                        className="!h-[30px] text-[13px] border-1 border-gray-300 w-full text-end"
                      />
                    </div>
                    <div className="w-1/6 items-center justify-center px-2">
                      <FormInput
                        name={"unit_price"}
                        placeholder={"Enter unit price"}
                        type="number"
                        value={item.unit_price}
                        required
                        onChange={(e) => {
                          let new_value = parseFloat(e.target.value);
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.purchase_order_items[index].unit_price =
                            e.target.value;
                          if (
                            new_value &&
                            newArray.purchase_order_items[index].quantity
                          ) {
                            newArray.purchase_order_items[index].total =
                              new_value *
                              parseFloat(
                                newArray.purchase_order_items[index].quantity
                              );
                          } else {
                            newArray.purchase_order_items[index].total = 0;
                          }
                          let sub_total = 0;
                          if (
                            newArray.purchase_order_items &&
                            newArray.purchase_order_items.length > 0
                          ) {
                            newArray.purchase_order_items.forEach((subItem) => {
                              sub_total += subItem.total;
                            });
                          }
                          newArray.purchase_order_sub_total = sub_total;
                          newArray.purchase_order_tax_amount =
                            Math.round(
                              sub_total *
                                (newArray.purchase_order_tax_rate / 100) *
                                100
                            ) / 100;
                          newArray.total_amount =
                            parseFloat(newArray.purchase_order_sub_total) +
                            parseFloat(newArray.purchase_order_tax_amount) +
                            parseFloat(newArray.shipping_cost);
                          setFormData(newArray);
                        }}
                        className="!h-[30px] text-[13px] border-1 border-gray-300 !min-w-full text-end"
                      />
                    </div>
                    <div className="flex w-1/6 items-center justify-end pr-4 bg-secondary-2-extralight">
                      <div className="text-primary-1 text-[14px] font-bold">
                        {item.total}
                      </div>
                    </div>
                  </div>
                ))}
              <div className="flex h-[50px] duration-300 gap-2">
                <div className="w-2/6 items-center justify-center mt-2">
                  <div className="md:w-fit w-[100%]">
                    <Button
                      text="New Line Item"
                      type="submit"
                      theme="primary"
                      className="flex gap-2 h-[25px] !min-w-fit p-2"
                      textClass={"!text-[10px]"}
                      leftIcon={"add"}
                      onClick={() => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.purchase_order_items.push({
                          item_number: "",
                          item_name: "",
                          description: "",
                          quantity: 1,
                          unit_price: 0,
                          total: 0,
                        });
                        setFormData(newArray);
                      }}
                    />
                  </div>
                </div>
                <div className="w-1/6 items-center justify-center pt-2"></div>
                <div className="w-2/6 items-center justify-center px-2"></div>
                <div className="w-1/6 items-center justify-center px-2"></div>
                <div className="w-1/6 items-center justify-center px-2"></div>
                <div className="w-1/6"></div>
              </div>
            </div>
            <div className="md:hidden flex flex-col font-normal border-b  min-h-fit bg-secondary-2-extralight px-2 py-2 rounded-md mt-3">
              <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[40px]">
                <div className="text-[17px] font-extrabold">
                  Purchase Order Items
                </div>
              </div>
              {formData &&
                formData.purchase_order_items.length > 0 &&
                formData.purchase_order_items.map((item, index) => (
                  <div className="flex flex-col h-fit hover:bg-gray-100 duration-300 gap-2 px-2 border rounded-md bg-white mb-2">
                    <div className="flex md:w-2/10 w-full items-center justify-start px-2 mt-2">
                      <Button
                        text="Remove"
                        type="submit"
                        theme="secondary"
                        className="flex gap-2 h-[25px] !min-w-fit p-2 !bg-white border-none !shadow-none "
                        textClass={"!text-[12px] !text-red-500"}
                        onClick={() => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          let subArray = newArray.purchase_order_items;
                          let data = [...subArray];
                          data.splice(index, 1); // 2nd parameter means remove one item only
                          newArray.purchase_order_items = [...data];
                          setFormData(newArray);
                        }}
                      />
                    </div>
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[40px] pt-2">
                      <div className="text-[15px] font-extrabold">
                        Item Number:
                      </div>
                      <div className="text-[14px]">
                        <FormInput
                          name={"item_number"}
                          placeholder={"Item number"}
                          type="text"
                          value={item.item_number}
                          onInput={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.purchase_order_items[index].item_number =
                              e.target.value;
                            setFormData(newArray);
                          }}
                          className="!h-[30px] text-[13px] border-1 border-gray-300 w-full"
                        />
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-0" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="text-[15px] font-extrabold">
                        Item Name:
                      </div>
                      <div className="text-[14px]">
                        <FormInput
                          name={"item_name"}
                          placeholder={"Item name"}
                          type="text"
                          value={item.item_name}
                          onInput={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.purchase_order_items[index].item_name =
                              e.target.value;
                            setFormData(newArray);
                          }}
                          className="!h-[30px] text-[13px] border-1 border-gray-300 w-full"
                        />
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-1" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="flex justify-betweentext-[15px] font-extrabold">
                        Description:
                      </div>
                      <div className="text-[14px]">
                        <FormInput
                          name={"description"}
                          placeholder={"Enter desciption"}
                          type="text"
                          value={item.description}
                          onInput={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.purchase_order_items[index].description =
                              e.target.value;
                            setFormData(newArray);
                          }}
                          className="!h-[30px] text-[13px] border-1 border-gray-300 !min-w-[100%]"
                        />
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-1" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="text-[15px] font-extrabold">
                        Quantity:
                      </div>
                      <div className="text-[14px]">
                        <FormInput
                          name={"quantity"}
                          placeholder={"Enter quantity"}
                          type="number"
                          value={item.quantity}
                          onChange={(e) => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.purchase_order_items[index].quantity =
                              parseFloat(e.target.value);
                            if (
                              e.target.value &&
                              newArray.purchase_order_items[index].quantity
                            ) {
                              newArray.purchase_order_items[index].total =
                                e.target.value *
                                parseFloat(
                                  newArray.purchase_order_items[index]
                                    .unit_price
                                );
                            } else {
                              newArray.purchase_order_items[index].total = 0;
                            }
                            let sub_total = 0;
                            if (
                              newArray.purchase_order_items &&
                              newArray.purchase_order_items.length > 0
                            ) {
                              newArray.purchase_order_items.forEach(
                                (subItem) => {
                                  sub_total += subItem.total;
                                }
                              );
                            }
                            newArray.purchase_order_sub_total = sub_total;
                            newArray.purchase_order_tax_amount =
                              Math.round(
                                sub_total *
                                  (newArray.purchase_order_tax_rate / 100) *
                                  100
                              ) / 100;
                            newArray.total_amount =
                              newArray.purchase_order_sub_total +
                              newArray.purchase_order_tax_amount +
                              newArray.shipping_cost;
                            setFormData(newArray);
                          }}
                          className="!h-[30px] text-[13px] border-1 border-gray-300 w-full text-end"
                        />
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-1" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="text-[15px] font-extrabold">
                        Unit Price:
                      </div>
                      <div className="text-[14px]">
                        <FormInput
                          name={"unit_price"}
                          placeholder={"Enter unit price"}
                          type="number"
                          value={item.unit_price}
                          required
                          onChange={(e) => {
                            let new_value = parseFloat(e.target.value);
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.purchase_order_items[index].unit_price =
                              parseFloat(e.target.value);
                            if (
                              new_value &&
                              newArray.purchase_order_items[index].quantity
                            ) {
                              newArray.purchase_order_items[index].total =
                                new_value *
                                parseFloat(
                                  newArray.purchase_order_items[index].quantity
                                );
                            } else {
                              newArray.purchase_order_items[index].total = 0;
                            }
                            let sub_total = 0;
                            if (
                              newArray.purchase_order_items &&
                              newArray.purchase_order_items.length > 0
                            ) {
                              newArray.purchase_order_items.forEach(
                                (subItem) => {
                                  sub_total += subItem.total;
                                }
                              );
                            }
                            newArray.purchase_order_sub_total = sub_total;
                            newArray.purchase_order_tax_amount =
                              Math.round(
                                sub_total *
                                  (newArray.purchase_order_tax_rate / 100) *
                                  100
                              ) / 100;
                            newArray.total_amount =
                              parseFloat(newArray.purchase_order_sub_total) +
                              parseFloat(newArray.purchase_order_tax_amount) +
                              parseFloat(newArray.shipping_cost);
                            setFormData(newArray);
                          }}
                          className="!h-[30px] text-[13px] border-1 border-gray-300 !min-w-full text-end"
                        />
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-1" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[40px]">
                      <div className="text-[15px] font-extrabold">Total:</div>
                      <div className="text-[18px] font-extrabold">
                        {formatNumber(item.total, 2)}
                      </div>
                      {/* <div className='text-primary-1 text-[14px] font-bold'></div> */}
                    </div>
                    <hr className="divider mt-0" />
                  </div>
                ))}
              <div className="w-full items-center justify-center pt-3">
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="New Line Item"
                    type="submit"
                    theme="primary"
                    className="flex gap-2 h-[25px] !max-w-fit p-2"
                    textClass={"!text-[10px]"}
                    leftIcon={"add"}
                    onClick={() => {
                      let newArray = JSON.parse(JSON.stringify(formData));
                      newArray.purchase_order_items.push({
                        item_number: "",
                        item_name: "",
                        description: "",
                        quantity: 1,
                        unit_price: 0,
                        total: 0,
                      });
                      setFormData(newArray);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col md:h-[180px] items-center gap-3 w-full mb-3">
              <div className="md:hidden flex md:w-2/6 w-full justify-end md:pt-0 pt-4">
                <div className="flex flex-col w-full h-full justify-center pt-2">
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Sub Total
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData.purchase_order_sub_total, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      <span className="flex items-center !font-extrabold text-[16px] pb-1">
                        Tax&nbsp;
                      </span>
                      <FormInput
                        name={"purchase_order_tax_rate"}
                        type="number"
                        value={formData.purchase_order_tax_rate}
                        onChange={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.purchase_order_tax_rate = e.target.value;
                          let sub_total = 0;
                          if (
                            newArray.purchase_order_items &&
                            newArray.purchase_order_items.length > 0
                          ) {
                            newArray.purchase_order_items.forEach((subItem) => {
                              let single_total = parseFloat(subItem.total);
                              sub_total += single_total;
                            });
                          }
                          newArray.purchase_order_sub_total = sub_total;
                          newArray.purchase_order_tax_amount =
                            Math.round(
                              sub_total *
                                (newArray.purchase_order_tax_rate / 100) *
                                100
                            ) / 100;
                          newArray.total_amount =
                            parseFloat(newArray.purchase_order_sub_total) +
                            parseFloat(newArray.purchase_order_tax_amount) +
                            parseFloat(newArray.shipping_cost);
                          setFormData(newArray);
                        }}
                        className="!h-[30px] !w-[80px] text-[14px]"
                      />
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        &nbsp;%
                      </span>
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData.purchase_order_tax_amount, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Shipping cost
                    </div>
                    <div className="flex justify-end text-primary-1 px-2 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      <FormInput
                        name={"shipping_cost"}
                        type="number"
                        value={formData.shipping_cost}
                        onChange={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.shipping_cost = parseFloat(e.target.value);
                          newArray.total_amount =
                            parseFloat(newArray.purchase_order_sub_total) +
                            parseFloat(newArray.purchase_order_tax_amount) +
                            parseFloat(newArray.shipping_cost);
                          setFormData(newArray);
                        }}
                        className="!h-[30px] !text-[14px] border-1 border-gray-300 w-full !text-right !px-1 !-pr-1"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[60px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Grand Total
                    </div>
                    <div className="flex justify-end text-white px-4 !w-1/2 bg-secondary-2-extralight h-full items-center bg-primary-1 text-[16px] font-extrabold">
                      {formatNumber(formData.total_amount, 2)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-4/6 w-full md:mt-0 mt-3">
                <div className="md:w-[80%] w-full flex flex-col justify-start min-h-[120px]">
                  <div className="flex items-center px-2 bg-secondary-2-extralight h-[45px] w-full border-b-2 border-b-primary-1">
                    <span className="md:text-[15px] text-[13px] text-primary-1 font-extrabold">
                      ADDITIONAL COMMENTS AND INSTRUCTIONS
                    </span>
                  </div>
                  <FormTextArea
                    name={"purchase_order_comments"}
                    placeholder={"Enter Additional comments and instructions"}
                    type="text"
                    value={formData.purchase_order_comments}
                    onChange={(e) => {
                      let newArray = JSON.parse(JSON.stringify(formData));
                      newArray.purchase_order_comments = e.target.value;
                      setFormData(newArray);
                    }}
                    className="text-[13px] border-1 border-gray-300 w-full font-normal mt-0 !rounded-t-none"
                  />
                </div>
              </div>
              <div className="hidden md:flex md:w-2/6 w-full justify-end md:pt-0 pt-4">
                <div className="flex flex-col w-full h-full justify-center pt-2">
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Sub Total
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData.purchase_order_sub_total, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2 gap-2">
                      <span className="flex items-center !font-extrabold text-[16px] pb-1">
                        Tax
                      </span>
                      <FormInput
                        name={"purchase_order_tax_rate"}
                        type="number"
                        value={formData.purchase_order_tax_rate}
                        onChange={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.purchase_order_tax_rate = e.target.value;
                          let sub_total = 0;
                          if (
                            newArray.purchase_order_items &&
                            newArray.purchase_order_items.length > 0
                          ) {
                            newArray.purchase_order_items.forEach((subItem) => {
                              let single_total = parseFloat(subItem.total);
                              sub_total += single_total;
                            });
                          }
                          newArray.purchase_order_sub_total = sub_total;
                          newArray.purchase_order_tax_amount =
                            Math.round(
                              sub_total *
                                (newArray.purchase_order_tax_rate / 100) *
                                100
                            ) / 100;
                          newArray.total_amount =
                            parseFloat(newArray.purchase_order_sub_total) +
                            parseFloat(newArray.purchase_order_tax_amount) +
                            parseFloat(newArray.shipping_cost);
                          setFormData(newArray);
                        }}
                        className="!h-[30px] !w-[80px] text-[13px]"
                      />
                      <span className="flex items-center !font-extrabold text-[13px] pb-1">
                        %
                      </span>
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData.purchase_order_tax_amount, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Shipping cost
                    </div>
                    <div className="flex justify-end text-primary-1 px-2 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      <FormInput
                        name={"shipping_cost"}
                        type="number"
                        value={formData.shipping_cost}
                        onChange={(e) => {
                          let newArray = JSON.parse(JSON.stringify(formData));
                          newArray.shipping_cost = e.target.value;
                          newArray.total_amount =
                            parseFloat(newArray.purchase_order_sub_total) +
                            parseFloat(newArray.purchase_order_tax_amount) +
                            parseFloat(newArray.shipping_cost);
                          setFormData(newArray);
                        }}
                        className="!h-[30px] !text-[14px] border-1 border-gray-300 w-full !text-right !px-1 !-pr-1"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[16px] gap-2 items-center !h-[60px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Grand Total
                    </div>
                    <div className="flex justify-end text-white px-4 !w-1/2 bg-secondary-2-extralight h-full items-center bg-primary-1 text-[18px] font-extrabold">
                      {formatNumber(formData.total_amount, 2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col w-full bg-gray-50 p-4 py-2 border rounded-md mt-8">
              <div className="flex flex-col md:w-1/2 w-full">
                <div className="text-primary-1 mb-3 font-bold">
                  PAYMENT INSTRUCTION
                </div>
                <div className="flex justify-start items-center h-[42px] gap-2">
                  <span className="text-[14px] w-2/5">Bank Name:</span>
                  <div className="w-3/4 items-center justify-center">
                    <FormDatalist
                      data={banks ? banks : []}
                      defaultText={formData.bank_name}
                      value={formData.bank_name}
                      onSelect={(value) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        newArray.bank_name = value;
                        newArray.account_number = "";
                        newArray.account_name = "";
                        setFormData(newArray);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-start items-center h-[42px] gap-2">
                  <span className="text-[14px] w-2/5">Account Number:</span>
                  <div className="w-3/4 items-center justify-center">
                    <FormInput
                      name="account_number"
                      type="text"
                      value={formData.account_number}
                      onInput={(e) => {
                        let newArray = JSON.parse(JSON.stringify(formData));
                        if (
                          formData.bank_name &&
                          e.target.value.length === 10
                        ) {
                          newArray.account_name = "";
                          setFormData(newArray);
                          let payload = {
                            bankname: formData.bank_name,
                            account_number: e.target.value,
                          };
                          resolveAccount(payload).then((response) => {
                            let newArray = JSON.parse(JSON.stringify(formData));
                            newArray.account_name = response.data.accountname;
                            setFormData(newArray);
                          });
                        }
                        newArray.account_number = e.target.value;
                        setFormData(newArray);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-start items-center h-[42px] gap-2">
                  <span className="text-[14px] w-2/5">Account Name:</span>
                  <div className="w-3/4 items-center justify-center">
                    <FormInput
                      name="account_name"
                      type="text"
                      placeholder={
                        resolveAccountLoading
                          ? "Fetching Account Name ...."
                          : "Auto-generated field"
                      }
                      readOnly
                      value={formData.account_name}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-end gap-[10px] md:items-center w-full justify-end mt-3">
              <div className="md:w-fit">
                <Button
                  text={
                    purchaseOrderSaveType === "add"
                      ? "SAVE PURCHASE ORDER"
                      : "UPDATE PURCHASE ORDER"
                  }
                  type="button"
                  theme="primary"
                  className="flex gap-2 !h-[35px] w-fit p-2 "
                  textClass={"!text-[11px]"}
                  loading={
                    addPurchaseOrderLoading || updatePurchaseOrderLoading
                  }
                  onClick={() => {
                    save();
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col gap-4 min-h-fit max-w-full mt-3"
            id={"purchase_order_report"}
          >
            <div className="flex gap-2 md:justify-end w-full  items-center">
              <span className="text-primary-1 text-[30px] ">
                PURCHASE ORDER
              </span>
              <span className="text-color-gray">
                <StatusText
                  code={formData.status}
                  className={"text-[13px]"}
                  statusConfig={purchaseOrderStatusConfig}
                />
              </span>
            </div>
            <div className="flex md:flex-row flex-col items-center justify-between bg-primary-1 p-3 text-color-white rounded-md">
              <div className="md:w-1/2 w-full flex flex-col items-start gap-3">
                <div className="flex gap-2 items-center">
                  <div className="text-[20px] flex font-bold justify-center items-center h-[55px] w-[100px] header-3 relative hover:scale-110 hover:m-2 duration-500 rounded-xl bg-white">
                    {company?.logo ? (
                      <div>
                        <img
                          src={company?.logo}
                          alt="email_icon"
                          className="object-fit min-w-[70px] min-h-[45px] max-w-[85px] max-h-[45px] "
                        />
                      </div>
                    ) : (
                      <span className="text-primary-1">
                        {Initials(company?.company_name)}
                      </span>
                    )}
                  </div>
                  <span className="text-color-white text-[25px] ">
                    {company?.company_name}
                  </span>
                </div>
                <div className="md:w-1/2 flex flex-col justify-start gap-2 font-normal">
                  <div className="w-full flex justify-start text-[14px] text-color-white">
                    {company?.address}, {company?.city}, {company?.state},{" "}
                    {company?.country}.
                  </div>
                  <div className="w-full flex justify-start text-[14px] font-semibold text-color-white">
                    {company?.email} | {company?.mobile}
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 w-full flex md:justify-end justify-center text-2xl text-primary-1 md:mt-3 mt-3 md:border-t-0 border-t-2 border-t-white md:pt-0 pt-3">
                <div className="w-full flex flex-col items-start">
                  <div className="flex flex-col md:justify-end md:items-end justify-start items-start font-normal w-full md:gap-0 gap-3">
                    <div className="flex md:justify-end items-center h-[42px] gap-2 w-full text-[14px] text-color-white">
                      <span className="md:text-[14px] text-[12px] md:w-2/3 w-1/2 md:text-right text-left font-semibold text-color-white">
                        Puchase Order Date:
                      </span>
                      <div className="w-1/2 md:text-right text-left text-color-white text-[13px] md:text-[14px]">
                        {moment(purchase_order.purchase_order_date).format(
                          "MMMM DD, YYYY"
                        )}
                      </div>
                    </div>
                    <div className="flex md:justify-end items-center h-[42px] gap-2 w-full text-[14px] text-color-white -mt-4">
                      <span className="md:text-[14px] text-[12px] md:w-2/3 w-1/2 md:text-right text-left font-semibold text-color-white">
                        Due Date:
                      </span>
                      <div className="w-1/2 md:text-right text-left text-color-white text-[13px] md:text-[14px]">
                        {moment(purchase_order.purchase_order_due_date).format(
                          "MMMM DD, YYYY"
                        )}
                      </div>
                    </div>
                    <div className="flex justify-start items-center h-[42px] gap-2 w-full text-[14px] -mt-4">
                      <span className="md:text-[14px] text-[12px] md:w-2/3 w-1/2 md:text-right text-left font-semibold text-color-white">
                        Purchase Order #:
                      </span>
                      <div className="w-1/2 md:text-right text-left text-color-white text-[13px] md:text-[14px]">
                        {formData.purchase_order_number}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col gap-3 justify-between font-normal">
              <div className="md:w-[40%] w-full flex flex-col justify-start min-h-[200px] border rounded-md ">
                <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full mb-1 border-b-2 border-b-primary-1">
                  <span className="text-[14px] text-primary-1 font-extrabold">
                    VENDOR:
                  </span>
                </div>
                {formData.vendor_id ? (
                  <div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Vendor name:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData.vendor_name}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Contact Person:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData.contact_person}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Address:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData.vendor_address}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Email:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData.vendor_email}
                      </div>
                    </div>
                    <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                      <span className="text-[13px] w-2/5 font-semibold">
                        Phone:
                      </span>
                      <div className="w-3/4 items-center justify-center text-[13px]">
                        {formData.vendor_mobile}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center h-6 items-center">
                    No vendor selected
                  </div>
                )}
              </div>
              <div className="md:w-[50%] w-full flex flex-col justify-start min-h-[200px] border rounded-md pb-2">
                <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full mb-1 border-b-2 border-b-primary-1">
                  <span className="text-[14px] text-primary-1 font-extrabold">
                    SHIP TO:
                  </span>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Contact Person:
                  </span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData.shipping_contact_person}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Shipping Address:
                  </span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData.shipping_address}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2 w-full">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Email:
                  </span>
                  <div className="w-3/4 items-center justify-center text-[14px]">
                    {formData.shipping_email}
                  </div>
                </div>
                <div className="flex justify-start px-4 items-center h-[42px] gap-2">
                  <span className="text-[14px] w-2/5 font-semibold">
                    Phone:
                  </span>
                  <div className="w-3/4 items-center justify-center text-[13px]">
                    {formData.shipping_phone}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col  md:h-[70px] h-fit bg-secondary-2-extralight border rounded-md">
              <div className="flex md:w-1/3 w-full items-center justify-center flex-col border-b-2 md:border-b-0 border-b-primary-1 md:py-0 py-2">
                <div className="text-secondary-2 text-[15px]">
                  SHIPPING METHOD
                </div>
                <div className="text-primary-1 !font-normal text-[13px]">
                  {formData.shipping_method}
                </div>
              </div>
              <div className="flex md:w-1/3 w-full items-center justify-center flex-col border-b-2 md:border-b-0 border-b-primary-1 md:py-0 py-2">
                <div className="text-secondary-2 text-[15px]">
                  SHIPPING TERMS
                </div>
                <div className="text-primary-1 !font-normal text-[13px]">
                  {formData.shipping_terms}
                </div>
              </div>
              <div className="flex md:w-1/3 w-full items-center justify-center flex-col md:py-0 py-2">
                <div className="text-secondary-2 text-[15px]">
                  DELIVERY DATE
                </div>
                <div className="text-primary-1 !font-normal text-[13px]">
                  {moment(purchase_order.shipping_date).format("MMMM DD, YYYY")}
                </div>
              </div>
            </div>
            <div className="hidden md:flex flex-col font-normal border-b ">
              <div className="flex h-[50px] bg-secondary-2-extralight border-b-2 border-b-primary-1">
                <div className="flex w-1/6 items-start justify-center flex-col px-3">
                  <div className="text-primary-1 font-bold text-[14px]">
                    ITEM #
                  </div>
                </div>
                <div className="flex w-2/6 items-start justify-center flex-col px-3">
                  <div className="text-primary-1 font-bold text-[14px]">
                    ITEM NAME
                  </div>
                </div>
                <div className="flex w-2/6 items-start justify-center flex-col px-3">
                  <div className="text-primary-1 font-bold text-center text-[14px]">
                    DESCRIPTION
                  </div>
                </div>
                <div className="flex w-1/6 items-start justify-center flex-col px-3">
                  <div className="text-primary-1 font-bold text-[14px]">
                    QTY
                  </div>
                </div>
                <div className="flex w-1/6 items-start justify-center flex-col px-3">
                  <div className="text-primary-1 font-bold text-[14px]">
                    UNIT PRICE
                  </div>
                </div>
                <div className="flex w-1/6 items-start justify-center flex-col px-3">
                  <div className="text-primary-1 font-bold text-[14px]">
                    TOTAL
                  </div>
                </div>
              </div>
              {formData &&
                formData.purchase_order_items.length > 0 &&
                formData.purchase_order_items.map((item) => (
                  <div className="flex h-[60px] hover:bg-gray-100 duration-300 gap-2">
                    <div className="flex w-1/6 items-center justify-start px-2 text-[14px] !h-[60px]">
                      {item.item_number}
                    </div>
                    <div className="flex w-2/6 items-center justify-start px-2 text-[14px] !h-[60px]">
                      {item.item_name}
                    </div>
                    <div className="flex w-2/6 items-center justify-start px-2 text-[14px] !h-[60px]">
                      {item.description}
                    </div>
                    <div className="flex w-1/6 items-center justify-end px-2 text-[14px] !h-[60px]">
                      {formatNumber(item.quantity, 2)}
                    </div>
                    <div className="flex w-1/6 items-center justify-end px-2 text-[14px] !h-[60px]">
                      {formatNumber(item.unit_price, 2)}
                    </div>
                    <div className="flex w-1/6 items-center justify-end pr-4 bg-secondary-2-extralight text-[14px] !h-[60px]">
                      <div className="text-primary-1 text-[14px] font-bold">
                        {formatNumber(item.total, 2)}
                      </div>
                    </div>
                  </div>
                ))}

              <div className="flex h-[40px] duration-300 gap-2">
                <div className="w-1/6 items-center justify-center px-2"></div>
                <div className="w-2/6 items-center justify-center px-2"></div>
                <div className="w-2/6 items-center justify-center px-2"></div>
                <div className="w-1/6 items-center justify-center px-2"></div>
                <div className="w-1/6 items-center justify-center px-2"></div>
                <div className="w-1/6 "></div>
              </div>
            </div>
            <div className="md:hidden flex flex-col font-normal border-b  min-h-fit bg-secondary-2-extralight px-2 py-2 rounded-md gap-3">
              <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[40px] -mb-3">
                <div className="text-[17px] font-extrabold">
                  PURCHASE ORDER ITEMS
                </div>
              </div>
              {formData &&
                formData.purchase_order_items.length > 0 &&
                formData.purchase_order_items.map((item) => (
                  <div className="flex flex-col h-fit hover:bg-gray-100 duration-300 gap-2 px-2 border rounded-md bg-white">
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[40px] pt-2">
                      <div className="text-[16px] font-extrabold">
                        Item Number ({item.item_number})
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-0" />
                    <div className="flex w-full items-center justify-between px-2 text-[13px] !h-[30px]">
                      <div className="flex justify-betweentext-[15px] font-extrabold">
                        Item Name:
                      </div>
                      <div className="text-[13px]">{item.item_name}</div>
                    </div>
                    <hr className="divider mt-0 mb-0" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="flex justify-betweentext-[15px] font-extrabold">
                        Description:
                      </div>
                      <div className="text-[13px]">{item.description}</div>
                    </div>
                    <hr className="divider mt-0 mb-0" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="text-[15px] font-extrabold">
                        Quantity:
                      </div>
                      <div className="text-[13px]">
                        {formatNumber(item.quantity, 2)}
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-0" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[30px]">
                      <div className="text-[15px] font-extrabold">
                        Unit Price:
                      </div>
                      <div className="text-[13px]">
                        {formatNumber(item.unit_price, 2)}
                      </div>
                    </div>
                    <hr className="divider mt-0 mb-0" />
                    <div className="flex w-full items-center justify-between px-2 text-[14px] !h-[40px]">
                      <div className="text-[15px] font-extrabold">Total:</div>
                      <div className="text-[18px] font-extrabold">
                        {formatNumber(item.total, 2)}
                      </div>
                    </div>
                    <hr className="divider mt-0" />
                  </div>
                ))}
            </div>
            <div className="flex md:flex-row flex-col md:h-[180px] items-center gap-4 w-full">
              <div className="md:hidden flex md:w-2/6 w-full justify-end md:pt-0 pt-4">
                <div className="flex flex-col w-full h-full justify-center pt-2">
                  <div className="flex justify-between !font-extrabold text-[16px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2 text-[14px]">
                      Sub Total
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center text-[14px]">
                      {formatNumber(formData.purchase_order_sub_total, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[16px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        Tax&nbsp;
                      </span>
                      ({formatNumber(formData.purchase_order_tax_rate, 2)}
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        %
                      </span>
                      )
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData.purchase_order_tax_amount, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Shipping cost
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData.shipping_cost, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[60px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Grand Total
                    </div>
                    <div className="flex justify-end text-white px-4 !w-1/2 bg-secondary-2-extralight h-full items-center bg-primary-1 text-[20px] font-extrabold">
                      {formatNumber(formData.total_amount, 2)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-4/6 w-full md:mt-0 mt-3">
                <div className="md:w-[80%] w-full flex flex-col justify-start min-h-[120px] rounded-md">
                  <div className="flex items-center px-4 bg-secondary-2-extralight h-[45px] w-full border-b-2 border-b-primary-1">
                    <span className="text-[15px] text-primary-1 font-extrabold">
                      ADDITIONAL COMMENTS AND INSTRUCTIONS
                    </span>
                  </div>
                  <div className="text-[13px] border-1 border-gray-300 !bg-white w-full font-normal !rounded-t-none p-4 mt-0">
                    {formData.purchase_order_comments}
                  </div>
                </div>
              </div>
              <div className="hidden md:flex md:w-2/6 w-full justify-end md:pt-0 pt-4">
                <div className="flex flex-col w-full h-full justify-center pt-2">
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Sub Total
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData.purchase_order_sub_total, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        Tax&nbsp;
                      </span>
                      ({formatNumber(formData.purchase_order_tax_rate, 2)}
                      <span className="flex items-center !font-extrabold text-[14px] pb-1">
                        %
                      </span>
                      )
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData.purchase_order_tax_amount, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[50px]">
                    <div className=" flex items-center justify-start !w-1/2">
                      Shipping cost
                    </div>
                    <div className="flex justify-end text-primary-1 px-4 !w-1/2 bg-secondary-2-extralight h-full items-center">
                      {formatNumber(formData.shipping_cost, 2)}
                    </div>
                  </div>
                  <div className="flex justify-between !font-extrabold text-[14px] gap-2 items-center !h-[60px]">
                    <div className=" flex items-center justify-start !w-1/2 text-[16px]">
                      Grand Total
                    </div>
                    <div className="flex justify-end text-white px-4 !w-1/2 bg-secondary-2-extralight h-full items-center bg-primary-1 text-[16px] font-extrabold">
                      {formatNumber(formData.total_amount, 2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col w-full bg-gray-50 !min-h-16 p-4 py-2 border md:mt-6 rounded-md">
              <div className="flex flex-col md:w-1/2 w-full">
                <div className="text-[15px] text-primary-1 font-extrabold">
                  PAYMENT INSTRUCTION
                </div>
                <div className="flex justify-start items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Bank Name:
                  </span>
                  <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                    {formData.bank_name}
                  </div>
                </div>
                <div className="flex justify-start items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Account Number:
                  </span>
                  <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                    {formData.account_number}
                  </div>
                </div>
                <div className="flex justify-start items-center h-[42px] gap-2">
                  <span className="text-[13px] w-2/5 font-semibold">
                    Account Name:
                  </span>
                  <div className="w-3/4 items-center justify-center font-normal text-[13px]">
                    {formData.account_name}
                  </div>
                </div>
              </div>
            </div>
            <hr className="divider -mt-2 -mb-4" />
            <div className="flex flex-col bg-white mt-[10px] rounded-md border">
              <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
                <div className="text-[16px] font-bold">Invoices Received</div>
                <div className="flex flex-col md:flex-row items-start gap-[10px] md:items-center">
                  <div className="md:w-fit w-[100%]">
                    <Button
                      text="ATTACH INVOICE RECEIVED"
                      type="button"
                      theme="primary"
                      className="flex gap-2 !h-[35px] w-fit p-2 "
                      textClass={"!text-[11px]"}
                      leftIcon={"add"}
                      loading={sendPurchaseOrderLoading}
                      onClick={() => {
                        setPurchaseOrderData(purchase_order);
                        setButtonText("SAVE INVOICE");
                        setTitle("Attach Invoice Received");
                        setShowAddInvoiceReceived(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className="divider mt-2 mb-2" />
              <DataTable
                data={invoices_received}
                tableConfig={tableConfig}
                actionConfig={actionConfig}
                statusConfig={invoiceReceivedStatus}
                updatePagination={(data) => setPagination(data)}
                noCheck={true}
                computedActions={true}
              />
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default PurchaseOrderPage;
