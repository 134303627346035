import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../components/button";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useUpdateCompany } from "../../../redux/company/hook";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { useRefNHIS } from "../../../redux/statutory-compliance/hook/nhis";
import { useSelector } from "react-redux";
import StatutorySummary from "../../../components/modal/statutory-compliance/statutory-summary";
import FormInput from "../../../components/form-input";
import { useGetCompanyAccountDetails } from "../../../redux/wallet/hook";

const NHISPreviewPage = () => {
  useEffect(() => {}, []);

  const { company } = useSelector((state) => state.companySlice);
  const { data: companyAccountDetails } = useGetCompanyAccountDetails();

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const [showPayrollSummary, setShowPayrollSummary] = useState(false);
  const [nhisCode, setNHISCode] = useState();
  const [companyName, setCompanyName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();

  const { mutateAsync: updateCompany, isLoading: loading } = useUpdateCompany();

  const { refId } = useParams();
  const { data: nhis } = useRefNHIS(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  useEffect(() => {
    if (company) {
      setNHISCode(company.nhisCode);
      setCompanyName(company.company_name);
      setEmail(company.email);
      setMobile(company.mobile);
    }
  }, [company]);

  const tableConfig = {
    headers: ["Employee Name", "Mobile", "Amount"],
    keys: ["employeeName", "employee_mobile", "table_nhis_amount"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Amount",
        key: "table_nhis_amount",
      },
    },
  };

  const companyNHISUpdate = async () => {
    let payload = {
      company_name: companyName,
      email: email,
      mobile: mobile,
      nhisCode: nhisCode,
    };

    await updateCompany(payload);
  };

  return (
    <DashboardLayout title="NHIS Preview">
      <div className="flex flex-col">
        <div className="flex flex-col md:flex-row justify-between gap-3">
          <div className="flex flex-col md:w-2/3 w-full">
            <span className="h4 text-color-black">
              A Quick Preview Before You Submit
            </span>
            <span className="text-[14px] text-color-gray -mt-2">
              Please spend a brief moment reviewing your NHIS before you submit
              for Payment
            </span>
          </div>
          <div className="flex md:justify-end justify-center md:w-1/3 w-full">
            <Button
              text="PAY AND FILE"
              type="button"
              loading={false}
              disabled={false}
              className="h-[56px] w-full md:w-[220px] "
              onClick={() => setShowPayrollSummary(true)}
            />
          </div>
        </div>

        <div className="mt-8">
          <>
            <StatutorySummary
              refValue={refId}
              type={"nhis"}
              isOpen={showPayrollSummary}
              closeModal={() => setShowPayrollSummary(false)}
              takeHome={nhis?.formatted_total_amount}
              transCost={nhis?.formatted_total_trans_cost}
              totalAmount={nhis?.formatted_total_payable}
            />
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-[100%] md:w-[75%] rounded-md overflow-auto">
                  <DataTable
                    data={nhis}
                    tableConfig={tableConfig}
                    noCheck={true}
                    searchData={true}
                    updatePagination={(data) => setPagination(data)}
                  />
                </div>
                <div className="md:w-[25%] flex flex-col md:pl-8 md:mt-0 rounded-md bg-white w-full p-3">
                  <div className="flex flex-row justify-between items-center">
                    <div className="w-[70%]">
                      <FormInput
                        label="Company NHIS Code"
                        name="nhis_code"
                        className={"rounded-br-none rounded-tr-none"}
                        type="text"
                        readOnly={false}
                        value={nhisCode}
                        onInput={(e) => {
                          setNHISCode(e.target.value);
                        }}
                      />
                    </div>
                    <div className="w-[30%] mt-[25px]">
                      <Button
                        text="UPDATE"
                        type="submit"
                        disabled={
                          companyName && email && mobile && nhisCode
                            ? false
                            : true
                        }
                        loading={loading}
                        textClass="!header-6"
                        theme="primary"
                        className={`h-[35px] pl-1 pr-1 rounded-bl-none rounded-tl-none`}
                        onClick={() => {
                          companyNHISUpdate();
                        }} // onClick={() => submitForm()}
                      />
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="mb-2">
                    <div className="flex flex-row justify-between">
                      <span className="text-[13px] text-secondary-2 font-bold">
                        Total NHIS
                      </span>
                      <span className="text-[13px] text-color-black">
                        {nhis?.formatted_total_amount}
                      </span>
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="mb-2">
                    <div className="flex flex-row justify-between">
                      <span className="text-[13px] text-secondary-2 font-bold">
                        Fees
                      </span>
                      <span className="text-[13px] text-color-black">
                        {nsitf?.formatted_total_trans_cost}
                      </span>
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="mb-2">
                    <div className="flex flex-row justify-between">
                      <span className="text-[16px] text-secondary-2 font-extrabold">
                        Total Payable
                      </span>
                      <span className="text-[15px]  text-color-black font-bold">
                        {nsitf?.formatted_total_payable}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NHISPreviewPage;
