import { Axios } from './axios';

const integrateTyms = async () => {
    const { data } = await Axios.get(`/tyms/integration-link`);
    return data;
};

const generateAccessToken = async (payload) => {
    const { data } = await Axios.post('/tyms/generate-access-token', payload);
    return data;
  };

export const integrations = {
    integrateTyms,
    generateAccessToken
};