import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import FormInput from "../../components/form-input";
import Button from "../../components/button";
import { useMobileResetPassword } from "../../redux/user/hook";
import { formValidation } from "../../utils/functions";
import AuthLayout from "../../components/layouts/auth";
import { useSelector } from "react-redux";

const MobileResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { section } = useSelector((state) => state.sectionSlice);

  useEffect(() => {}, []);

  const { mutateAsync: resetPassword, isLoading: resetLoading } =
    useMobileResetPassword();
  const history = useHistory();
  const { token } = useParams();
  const [Password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordStatus, setPasswordStatus] = useState(false);

  const submitForm = async (data) => {
    const password = DOMPurify.sanitize(data?.password);
    const confirm_password = DOMPurify.sanitize(data?.confirm_password);

    const requestData = {
      token: token,
      password_confirmation: confirm_password,
      password: password,
    };

    await resetPassword(requestData).then(() => {
      setPasswordStatus(true);
    });
  };

  return (
    <AuthLayout>
      <>
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <div className="w-full text-center flex flex-col pb-[5px]">
            <span
              className={
                section && section.name === "core hr"
                  ? "header-3-regular md:header-2 text-hr-primary-1 pb-1"
                  : "header-3-regular md:header-2 text-secondary-2 pb-1"
              }
            >
              Welcome Back
            </span>
          </div>
          {!passwordStatus ? (
            <>
              <div className="w-full text-center flex flex-col pb-[15px]">
                <span className="text-[20px] font-bold">
                  Enter Your New Password
                </span>
              </div>
              <FormInput
                label="New Password"
                name="password"
                type="password"
                inputRef={register(formValidation("password", true))}
                readOnly={resetLoading}
                error={errors.password}
                errorMessage={errors.password && errors.password.message}
                onInput={(e) => setPassword(e.target.value)}
                infos={[
                  "Password should contain minimum of 8 characters, 1 caps letter and 1 number",
                ]}
              />
              <FormInput
                label="Confirm Password"
                name="confirm_password"
                type="password"
                inputRef={register(formValidation("password", true))}
                onInput={(e) => setConfirmPassword(e.target.value)}
                readOnly={resetLoading}
                error={confirmPassword !== Password && confirmPassword.trim()}
                errorMessage={"Password does not match"}
              />
              <div className="mt-3">
                <Button
                  text="Reset"
                  type="submit"
                  loading={resetLoading}
                  disabled={!isValid}
                />
              </div>
            </>
          ) : (
            <div className="text-[15px] font-bold flex justify-center w-full mt-4 items-center min-h-[40px]">
              Password Reset successful. You can now login on your app with this
              new password
            </div>
          )}
        </form>
      </>
    </AuthLayout>
  );
};

export default MobileResetPassword;
