import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { EmployeeSuspensions } from './../../../api/employees/suspensions';

export function useGetEmployeeSuspensions(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-suspensions', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return EmployeeSuspensions.getEmployeeSuspensions({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((employee) => {
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.status = parseFloat(employee.payment_status);
          employee.formatted_start_date = moment(employee.start_date).format('MMMM D, YYYY');
          employee.formatted_end_date = moment(employee.end_date).format('MMMM D, YYYY');
          if (employee.status === 2) {
            employee.status = 0;
          }
          switch (employee.status) {
            case 0:
              employee.computedActions = [0, 1];
              break;
            case 1:
              employee.computedActions = [0, 1];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useAddSuspension() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return EmployeeSuspensions.AddSuspension(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-suspensions');
        store.dispatch(setAlert(true, 'success', 'Suspension added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditSuspension() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return EmployeeSuspensions.editSuspension(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-suspensions');
        store.dispatch(setAlert(true, 'success', 'Suspension updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteSuspension() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return EmployeeSuspensions.deleteSuspension(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-suspensions');
        store.dispatch(setAlert(true, 'success', 'Suspension removed successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleGetEmployeeSuspensions(id, limit, page, search) {
  return useQuery(
    ['single-employee-suspensions', { id, limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return EmployeeSuspensions.getSingleEmployeeSuspensions({ id, limit, page, search });
    },

    {
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.formatted_start_date = moment(employee.start_date).format('MMMM D, YYYY');
          employee.formatted_end_date = moment(employee.end_date).format('MMMM D, YYYY');
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          employee.status = parseFloat(employee.payment_status);
          if (employee.status === 2) {
            employee.status = 0;
          }
          switch (employee.status) {
            case 0:
              employee.computedActions = [0, 1];
              break;
            case 1:
              employee.computedActions = [0, 1];
              break;
            default:
              break;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}
