import React from 'react';
import ComingSoon from '../coming-soon';

const TransporatationBenefitsPage = () => {
  return (
    <div className='w-full bg-white border rounded flex items-center justify-start p-4 flex-col'>
      <ComingSoon />
    </div>
  );
};

export default TransporatationBenefitsPage;
