import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import WarningSVG from '../../../assets/svg/warning.svg';
import {
  useDisburseReimbursement,
  usePayReimbursement,
} from '../../../redux/employees/hook/reimbursements';
import { useGetEmployee } from '../../../redux/employees/hook';
import Modal from '../modal';
import { formatNumber } from '../../../utils/functions';
import { useGetCompanyAccountDetails } from '../../../redux/wallet/hook';
import FormOtpInput from '../../form-otp-input';

function PayReimbursement({
  isOpen,
  closeModal,
  reimbursement,
  applicationPay = true,
}) {
  const { user } = useSelector((state) => state.userSlice);

  const { data: companyAccountDetails  } = useGetCompanyAccountDetails();

  const { data: employee } = useGetEmployee(reimbursement?.employee);

  const [pin, setPIN] = useState('');

  const { mutateAsync: disburseReimbursement, isLoading: disburseLoading } =
    useDisburseReimbursement();

  const { mutateAsync: payReimbursement, isLoading: approveAndPayLoading } =
    usePayReimbursement();

  const submitForm = async () => {
    if (
      user?.permissions.approval_permission?.initiator === 1 &&
      !applicationPay
    ) {
      const requestData = {
        transaction_pin: pin,
        reimbursement_id: reimbursement?.id,
      };
      await payReimbursement(requestData).then(() => {
        closeModal();
      });
    } else if (
      user?.permissions.approval_permission?.approver === 1 &&
      applicationPay
    ) {
      const requestData = {
        transaction_pin: pin,
        reimbursement_id: reimbursement?.id,
      };
      await disburseReimbursement(requestData).then(() => {
        closeModal();
      });
    } else if (
      user?.permissions.approval_permission?.initiator === 1 &&
      user?.permissions.approval_permission?.approver === 1
    ) {
      const requestData = {
        transaction_pin: pin,
        reimbursement_id: reimbursement?.id,
      };
      await payReimbursement(requestData).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Pay Reimbursement</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col p-4 pt-2'>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4 w-full border bg-highlight rounded-md p-3'>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Employee Name</span>
                <span className='mt-1 text-color-black text-[14px]'>
                  {reimbursement?.first_name} {reimbursement?.last_name}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Mobile</span>
                <span className='mt-1 text-color-black text-[14px]'>
                  {reimbursement?.phone}
                </span>
              </div>

              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Bank Name</span>
                <span className='mt-1 text-color-black text-[14px]'>
                  {employee?.bank}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Account Number</span>
                <span className='mt-1 text-color-black text-[14px]'>
                  {employee?.account_number}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>Account Name</span>
                <span className='mt-1 text-color-black text-[14px]'>
                  {employee?.account_name}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>
                  Expenses Description
                </span>
                <span className='mt-1 text-color-black text-[14px]'>
                  {reimbursement?.expenses_description}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='header-5 text-color-gray'>
                  Expenses Amount
                </span>
                <span className='mt-1 text-color-black text-[14px]'>
                  {formatNumber(reimbursement?.expenses_amount)}
                </span>
              </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col justify-center mb-4 -mt-4'>
              <div className='flex flex-row border-secondary-4 border-[2px] rounded-lg p-2 bg-color-warning-light'>
                <div className='w-[70px] pr-4 flex flex-row items-center '>
                  <img src={WarningSVG} alt='' srcSet='' />
                </div>
                <span className='text-justify text-[12px]'>
                  Ropay will debit <span className='font-semibold'>{formatNumber(reimbursement?.expenses_amount)}</span> from your
                  account. You will be charged a fee of <span className='font-semibold'>44.99</span> for this
                  transaction. Please Make sure the funds are available.
                </span>
              </div>
            </div>
            {applicationPay &&
            user?.permissions.approval_permission?.approver === 1 ? (
              <div className='w-full mb-[20px]'>
                <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4'>
                  <FormOtpInput
                    value={pin}
                    valueLength={4}
                    onChange={(value) => setPIN(value)}
                    label={'Enter your new 4 digit PIN'}
                  />
                </div>
                <Button
                  text={'APPROVE PAYMENT'}
                  type='button'
                  disabled={false}
                  loading={approveAndPayLoading || disburseLoading}
                  onClick={() => submitForm()}
                />
              </div>
            ) : !applicationPay &&
              user?.permissions.approval_permission?.initiator === 1 ? (
              <div className='w-full mb-[20px]'>
                <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4'>
                  <FormOtpInput
                    value={pin}
                    valueLength={4}
                    onChange={(value) => setPIN(value)}
                    label={'Enter your new 4 digit PIN'}
                  />
                </div>
                <Button
                  text={
                    user?.permissions.approval_permission?.initiator === 1 &&
                    user?.permissions.approval_permission?.approver === 1
                      ? 'PAY'
                      : 'INITIATE PAYMENT'
                  }
                  type='button'
                  disabled={!pin || pin.length != 4}
                  loading={approveAndPayLoading || disburseLoading}
                  onClick={() => submitForm()}
                />
              </div>
            ) : !applicationPay &&
              user?.permissions.approval_permission?.approver === 1 ? (
              <div className='text-center w-full mb-[20px]'>
                You are not authorised to Initiate payments <br /> Please
                Contact Your Administrator.
              </div>
            ) : (
              <div className='text-center w-full mb-[20px]'>
                You are not Authorised to Approve payment <br /> Please Contact
                Your Administrator.
              </div>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default PayReimbursement;
