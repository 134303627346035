import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { employeeReimbursement } from './../../../api/employees/reimbursements';
import { formatNumber } from '../../../utils/functions';

export function useGetEmployeeReimbursements(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-reimbursements', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.getEmployeeReimbursements({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.expenses_amount = parseFloat(
            employee.expenses_amount,
          );
          employee.formatted_expenses_amount = formatNumber(parseFloat(employee.expenses_amount).toFixed(2), 2);
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          if (employee.paid_once == 2) {
            employee.paid_once = 0
          }
          employee.status = employee.paid_once

          if (employee.type == 0 || employee.type == 1) {
            employee.typeLabel = 'One Time';
            employee.formatted_month = moment(employee.month).format('MMMM, YYYY');
          } else if (employee.type === 2) {
            employee.typeLabel = 'Recurring';
            employee.month = '-';
            employee.formatted_month = '-';
          }
          if (employee.payment) {
            switch (employee.paid_once) {
              case 0:
                employee.computedActions = [0, 1, 4];
                break;
              case 1:
                employee.computedActions = [5];
                break;
              case 3:
                employee.computedActions = [2, 3, 4];
                break;
              default:
                break;
            }
          } else {
            switch (employee.paid_once) {
              case 0:
                employee.computedActions = [0, 1, 4];
                break;
              case 3:
                employee.computedActions = [2, 3, 4];
                break;
              default:
                break;
            }
          }
          //compute action items
          
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetReimbursementReport(start_date, end_date) {
  return useQuery(
    ['reimbursement-report', { start_date, end_date }],
    () => {
      return employeeReimbursement.getReimbursementReport({ start_date, end_date });
    },
    {
      select: (data) => {
        let formatted_months = []
        data?.months?.forEach(element => {
          formatted_months.push(moment(element+'-01').format('MMM YY'));
        });
        data.formatted_months = formatted_months;
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
    
  );
}

export function useAddReimbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.AddReImbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-reimbursements');
        store.dispatch(setAlert(true, 'success', 'Reimbursement added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteReimbursement() {
  return useMutation(
    (payload) => {
      return employeeReimbursement.deleteReImbursement(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee-reimbursements');
        store.dispatch(setAlert(true, 'success', 'Reimbursement deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditReimbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.editReImbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-reimbursements');
        store.dispatch(setAlert(true, 'success', 'Reimbursement Updated Successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkAddReimbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.bulkAddReimbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-reimbursements');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleGetEmployeeReimbursements(id, limit, page, statusFilter, search) {
  return useQuery(
    ['single-employee-reimbursements', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.getSingleEmployeeReimbursements({ id, limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.expenses_amount = parseFloat(
            employee.expenses_amount,
          );
          employee.formatted_expenses_amount = formatNumber(parseFloat(employee.expenses_amount.toFixed(2)), 2);
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          if (employee.paid_once == 2) {
            employee.paid_once = 0
          }
          employee.status = employee.paid_once

          if (employee.type == 0 || employee.type == 1) {
            employee.typeLabel = 'One Time';
            employee.formatted_month = moment(employee.month).format('MMMM, YYYY');
          } else if (employee.type === 2) {
            employee.typeLabel = 'Recurring';
            employee.month = '-';
            employee.formatted_month = '-';
          }
          //compute action items
          switch (employee.paid_once) {
            case 0:
              employee.computedActions = [0, 1, 4];
              break;
            case 3:
              employee.computedActions = [2, 3, 4];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetReimbursementApplications(limit, page, statusFilter, search) {
  return useQuery(
    ['reimbursement-applications', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.getReimbursementApplications({
        limit,
        page,
        statusFilter, 
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((application) => {
          application.created = moment(application.created).format('MMMM DD, YYYY');
          application.expenses_amount = parseFloat(
            application.expenses_amount,
          );
          application.employeeName = `${application.first_name} ${application.last_name}`;

          //compute action items
          switch (application.status) {
            case 0:
              application.computedActions = [0, 2, 3];
              break;
            case 1:
              application.computedActions = [5];
              break;
            case 3:
              application.computedActions = [1, 2, 3, 4];
              break;
            default:
              break;
          }
        });

        return data;
      },

      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

//New
export function useSingleGetEmployeeReimbursementApplications(id, limit, page, statusFilter, search) {
  return useQuery(
    ['single-employee-reimbursement-applications', { id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.getSingleEmployeeReimbursementApplications({ id, limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((application) => {
          application.expenses_amount = parseFloat(
            application.expenses_amount,
          );
          application.employeeName = `${application.first_name} ${application.last_name}`;

          //compute action items
          switch (application.status) {
            case 0:
              application.computedActions = [0, 2, 3];
              break;
            case 3:
              application.computedActions = [1, 2, 3, 4];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useInitiateReimbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.initiateReimbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', 'Reimburement payment initiated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDisburseReimbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.disburseReimbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', 'Reimburement disbursed successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDisburseApplication() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.disburseApplication(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', 'Reimburement application disbursed to employee successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useApprovePayReimbursementApplication() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.approvePayReimbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', 'Reimburement approved and initiated for payment successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function usePayReimbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.initiateReimbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', 'Reimburement payment initiated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useApproveReimbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.approveReimbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', 'Reimburement approved successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineReimbursementApplication() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.declineReImbursementApplication(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', 'Reimburement application declined successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineApplicationDisbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.declineApplicationDisbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', 'Reimburement application disbursement declined successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineReimbursementDisbursement() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeReimbursement.declineReImbursementDisbursement(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('reimbursement-applications');
        queryClient.invalidateQueries('employee-reimbursements');
        queryClient.invalidateQueries('single-employee-reimbursement-applications');
        store.dispatch(setAlert(true, 'success', 'Reimburement declined successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}





