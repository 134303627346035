import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../../components/datatable';
import { PAGINATION_DEFAULT, suspensionStatusConfig } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { useGetCabinetQueries, useGetCabinetSuspensions } from '../../redux/employee-folder/hook';

const SuspensionFolder = ({employeeId}) => {

  const { user } = useSelector((state) => state.userSlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const { data: queries } = useGetCabinetSuspensions(
    employeeId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );


  const tableConfig = {
    // title: 'Suspension Management',
    headers: [
      'Employee Name',
      'Purpose',
      'Start Date',
      'End Date',
      'Payment Status',
    ],
    keys: [
      'employeeName',
      'purpose',
      'formatted_start_date',
      'formatted_end_date',
      'status',
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Payment Status',
        key: 'status',
      },
    },
  };

  return (
    <div className='flex flex-col'>
        <div className='mt-9'>
            <div className='flex md:flex-row gap-4 flex-wrap-reverse'>
                <div className='w-[100%]'>
                    <DataTable
                    data={queries}
                    tableConfig={tableConfig}
                    statusConfig={suspensionStatusConfig}
                    noCheck={true}
                    updatePagination={(data) => setPagination(data)}
                    />
                </div>
            </div>
        </div>
    </div>
  );
};

export default SuspensionFolder;
