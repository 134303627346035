import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import TabView from "../../../components/tabview";
import RecruitLogoSVG from "../../../assets/svg/recruit-logo.svg";
import { useGetRecruitmentApplication } from "../../../redux/human-resources/hook/recruitment";
import ApplicantProfile from "../../../components/hr-section/recruitment/view-applicant/applicant-profile";
import ApplicantStages from "../../../components/hr-section/recruitment/view-applicant/applicant-stages";
import { StatusText } from "../../../components/fragments/status-text";
import { recruitmentApplicationStatusConfig } from "../../../utils/constants";
import UnauthorizedPage from "../../errors/unauthorized";
import { useSelector } from "react-redux";

const ViewRecruitmentApplication = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.recruitment_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const { data: applicant, isLoading } = useGetRecruitmentApplication(id);

  //Employee View Tabs
  const ApplicantTabs = [
    {
      title: "Applicant Profile",
      Content: ApplicantProfile,
    },
    {
      title: "Recruitment Information",
      Content: ApplicantStages,
    },
  ];

  return (
    <DashboardLayout title="Recruitment Applicant Page">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col -mt-2">
          <div className="flex flex-row items-center box-shadow h-[104px]">
            <div className="flex flex-row md:px-[20px] px-4 justify-between  w-full">
              <div className="flex flex-row items-center">
                <img
                  src={RecruitLogoSVG}
                  alt={"recruit"}
                  className={"h-[58px] w-[58px] rounded-[58px] "}
                />
                <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                  <span className="p1-bold">
                    {applicant?.first_name + " " + applicant?.last_name}
                  </span>
                  <span className="p4 text-color-gray">{applicant?.email}</span>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <StatusText
                  statusConfig={recruitmentApplicationStatusConfig}
                  code={applicant?.review_status}
                  className={"py-1 rounded px-3 text-[15px]"}
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <TabView
              Tabs={ApplicantTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default ViewRecruitmentApplication;
