import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../../components/button';
import FormInput from '../../../../components/form-input';
import FormSelect from '../../../../components/form-select';
import {useAddAdvancedEvaluatePerformanceBySource, useAddAdvancedPerformanceEvaluation,} from '../../../../redux/human-resources/hook/performance';
import PerformanceEvaluationLayout from '../../../../components/layouts/performance-evaluation';
import { employees as employeeAPI } from '../../../../api/employees';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import FormSwitch from '../../../../components/form-switch';
import WarningSVG from '../../../../assets/svg/warning.svg';
import ConfirmationModal from '../../../../components/modal/confirmation-modal';
import { useSelector } from 'react-redux';
import moment from 'moment';
import FormSelectEmployee from '../../../../components/employees/form-select-employee';
import FormSelectTeam from '../../../../components/employees/form-select-team';
import FormSelectDepartment from '../../../../components/employees/form-select-department';
import FormSelectBranch from '../../../../components/employees/form-select-branch';
import { useRouteQuery } from '../../../../hooks/useRouteQuery';
import { store } from '../../../../redux';
import { setAlert } from '../../../../redux/components/components-slice';

function AdvancedEvaluation() {
    const {
        register,
        handleSubmit,
        formState: { isValid },
        errors,
    } = useForm({
        mode: 'onChange',
    });

    const [source, setSource] = useState('team');
    const [sourceId, setSourceId] = useState(null);
    const [sourceName, setSourceName] = useState(null);

    const [pip_id, setPipId] = useState();
    const [probation_id, setProbationId] = useState();

    const sourceOptions = [
        { label: 'Employee', value: 'employee' },
        { label: 'Team', value: 'team' },
        { label: 'Department', value: 'department' },
        { label: 'Branch', value: 'branch' },
    ];

    const [employee, setEmployee] = useState();

    const [team, setTeam] = useState();

    const [department, setDepartment] = useState();

    const [branch, setBranch] = useState();

    const { user } = useSelector((state) => state.userSlice);

    const history = useHistory()

    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format("YYYY-MM-DD"));

    const [showConfirmation, setShowConfirmation] = useState(false);

    const {
    mutateAsync: addPerformanceEvaluation,
    isLoading: addPerformanceEvaluationLoading,
    } = useAddAdvancedPerformanceEvaluation();

    const {
        mutateAsync: addPerformanceEvaluationBySource,
        isLoading: addPerformanceEvaluationBySourceLoading,
        } = useAddAdvancedEvaluatePerformanceBySource();


    const [type, setType] = useState('all');
    const [useOkr, setUseOkr] = useState(false);
    const [useOrganisationalAppraisal, setUseOrganisationalAppraisal] = useState(false);
    const [useSmartGoals, setUseSmartGoals] = useState(false);
    const [useTechnicalAppraisals, setUseTechnicalAppraisals] = useState(false);
    const [useBalancedScorecard, setUseBalancedScorecard] = useState(false);

    const typeOptions = [
        {
          value: 'all',
          label: 'All employees',
        },
        {
          value: 'select',
          label: 'Selected Employees',
        },
        {
            value: 'source',
            label: 'Selected Group (Employee, Team, Department or Branch)',
          },
    ];

    const [employees, setEmployees] = useState([]);

    const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 10,
          statusFilter: 1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

    const query = useRouteQuery();
    useEffect(() => {
        if (query) {
            if (query.get('pip_id')) {
                setType('select')
                setPipId(query.get('pip_id'));
                setStartDate(query.get('start_date'));
                setEndDate(query.get('end_date'));
                setEmployees([{label: query.get('first_name')+' '+query.get('last_name')+' | '+query.get('mobile')+' | '+query.get('email'), value: query.get('employee_id')}]);
            } else if (query.get('probation_id')) {
                setType('select')
                setProbationId(query.get('probation_id'));
                setStartDate(query.get('start_date'));
                setEndDate(query.get('end_date'));
                setEmployees([{label: query.get('first_name')+' '+query.get('last_name')+' | '+query.get('mobile')+' | '+query.get('email'), value: query.get('employee_id')}]);
            }
        }
    }, [query]);
    
    const submitForm = async () => {
        if (pip_id) {
            let employee_ids = "";
            if (employees.length === 0) {
                store.dispatch(setAlert(true, 'info', 'Select at least one employee'));
                return;
            }
            let employeeArrays = [];
        
            employees.forEach((item) => {
                employeeArrays.push(item.value);
            });
        
            employee_ids = employeeArrays.join();
            let payload = {
                type,
                pip_id,
                employee_ids,
                use_okr: useOkr? 1: 0,
                use_organisational_appraisal: useOrganisationalAppraisal? 1: 0,
                use_smart_goals: useSmartGoals? 1: 0,
                use_technical_appraisals: useTechnicalAppraisals? 1: 0,
                use_balanced_scorecards: useBalancedScorecard? 1: 0,
                evaluation_start: startDate,
                evaluation_end: endDate,
            };
            addPerformanceEvaluation(payload).then(() => {
                setShowConfirmation(true);
            });
        } else if (probation_id) {
            let employee_ids = "";
            if (employees.length === 0) {
                dispatch(setAlert(true, 'info', 'Select at least one employee'));
                return;
            }
            let employeeArrays = [];
        
            employees.forEach((item) => {
                employeeArrays.push(item.value);
            });
        
            employee_ids = employeeArrays.join();
            let payload = {
                type,
                probation_id,
                employee_ids,
                use_okr: useOkr? 1: 0,
                use_organisational_appraisal: useOrganisationalAppraisal? 1: 0,
                use_smart_goals: useSmartGoals? 1: 0,
                use_technical_appraisals: useTechnicalAppraisals? 1: 0,
                use_balanced_scorecards: useBalancedScorecard? 1: 0,
                evaluation_start: startDate,
                evaluation_end: endDate,
            };
            addPerformanceEvaluation(payload).then(() => {
                setShowConfirmation(true);
            });
        } else {
            if (type == 'source') {
                if (source && sourceId) {
                    let payload = {
                        type,
                        source: source,
                        use_okr: useOkr? 1: 0,
                        source_id: sourceId,
                        use_organisational_appraisal: useOrganisationalAppraisal? 1: 0,
                        use_technical_appraisals: useTechnicalAppraisals? 1: 0,
                        use_balanced_scorecards: useBalancedScorecard? 1: 0,
                        use_smart_goals: useSmartGoals? 1: 0,
                        evaluation_start: startDate,
                        evaluation_end: endDate,
                    };
                    addPerformanceEvaluationBySource(payload).then((result) => {
                        setShowConfirmation(true);
                    });
                } else {
                    dispatch(setAlert(true, 'info', 'You must select a group to evaluate'));
                    return;
                }
                
            } else {
                let employee_ids = "";
                if (type == 'select') {
                    if (employees.length === 0) {
                        dispatch(setAlert(true, 'info', 'Select at least one employee'));
                        return;
                    }
                    let employeeArrays = [];
                
                    employees.forEach((item) => {
                        employeeArrays.push(item.value);
                    });
                
                    employee_ids = employeeArrays.join();
                } else {
                    employee_ids = "none"; 
                }
                
                let payload = {
                    type,
                    employee_ids,
                    use_okr: useOkr? 1: 0,
                    use_organisational_appraisal: useOrganisationalAppraisal? 1: 0,
                    use_smart_goals: useSmartGoals? 1: 0,
                    use_technical_appraisals: useTechnicalAppraisals? 1 : 0,
                    use_balanced_scorecards: useBalancedScorecard ? 1 : 0,
                    evaluation_start: startDate,
                    evaluation_end: endDate,
                };
                addPerformanceEvaluation(payload).then(() => {
                    setShowConfirmation(true);
                });
            }
        }
    };

    const confirmationNext = () => {
        history.push('/performance/evaluations');
    };

    return (
        <PerformanceEvaluationLayout step={2} title={'Add Evaluation Information'}>
            {showConfirmation?
            <ConfirmationModal
                isOpen={showConfirmation}
                closeModal={() => setShowConfirmation(false)}
                btnFunction={confirmationNext}
                title={'You did it, ' + user?.first_name}
                subtitle={'Performance Evalaution successfully added'}
            />
            :
            null}
            <div className='flex flex-col justify-center h-full min-h-[480px]'>
                <div className='flex flex-col overflow-auto'>
                    <form className='form'>
                        <div className='flex flex-row justify-between'>
                            <span className='hidden md:flex header-3'>Evaluate Performance (Advanced)</span>
                        </div>
                        {/* <LoadingBar loading={performanceIsLoading} /> */}
                        <hr className='hidden md:flex divider mb-2' />
                        <div className='flex flex-col'>
                            <div className='border bg-highlight p-2 rounded-md'>
                                <FormSelect
                                    label='Performance Evaluation Scope'
                                    value={type}
                                    options={typeOptions}
                                    readOnly={pip_id || probation_id}
                                    onChange={(selected) => {
                                        setType(selected);
                                    }}
                                    extraClass={'bg-white'}
                                    
                                />
                            </div>
                            <hr className='divider mt-2 mb-2' />
                            <div>
                                {type === 'select'?
                                <div>
                                    {pip_id || probation_id?
                                    <div className='border p-2 rounded-md mb-2 bg-highlight text-[13px]'>
                                        {query.get('first_name')+' '+query.get('last_name')+' | '+query.get('mobile')+' | '+query.get('email')}
                                    </div>
                                    :
                                    <div className='mb-2'>
                                        <label className='!text-[13px] leading-[23px] font-normal text-color-gray mb-1'>
                                            Select Multiple Employees
                                        </label>
                                        <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            readOnly={pip_id || probation_id}
                                            defaultValue={employees}
                                            className='text-[13px]'
                                            placeholder={'Type to select multiple employees'}
                                            loadOptions={loadOptions}
                                            onChange={(options) => setEmployees(options)}
                                            classNamePrefix='mySelect'
                                        />
                                        <hr className='divider mt-2 mb-2' />
                                    </div>
                                    }
                                    
                                </div>
                                :type === 'source'?
                                <div className='flex flex-col md:flex-row w-full justify-between'>
                                    <div className='w-full md:w-1/2 md:pr-2'>
                                        <FormSelect
                                            defaultValue={source}
                                            options={sourceOptions}
                                            onChange={(selected) => {
                                                setSource(selected);
                                                setSourceName(null)
                                                setSourceId(null);
                                            }}
                                            label='Evaluate?'
                                        />
                                    </div>
                                    <div className='w-full md:w-1/2 md:pl-2'>
                                        {source === 'employee' ? (
                                        <FormSelectEmployee
                                            label={'Employee'}
                                            name='employee'
                                            placeholder='Select Employee'
                                            value={employee}
                                            empName={sourceName}
                                            setSourceName={setSourceName}
                                            setEmployee={setSourceId}
                                        />
                                        ) : source === 'team' ? (
                                        <FormSelectTeam
                                            label={'Select Team'}
                                            name='team'
                                            placeholder='Select Team'
                                            value={team}
                                            teamName={sourceName}
                                            setSourceName={setSourceName}
                                            setTeam={setSourceId}
                                        />
                                        ) : source === 'department' ? (
                                        <FormSelectDepartment
                                            label={'Department'}
                                            name='department'
                                            placeholder='Select Department'
                                            value={department}
                                            departmentName={sourceName}
                                            setSourceName={setSourceName}
                                            setDepartment={setSourceId}
                                        />
                                        ) : (
                                        <FormSelectBranch
                                            label={'Branch'}
                                            name='branch'
                                            placeholder='Select Branch'
                                            value={branch}
                                            branchName={sourceName}
                                            setSourceName={setSourceName}
                                            setBranch={setSourceId}
                                        />
                                        )}
                                    </div>
                                </div>
                                :
                                null
                                }
                                <span className='text-color-gray text-[13px]'>Evaluation Period (Start and End date)</span>
                                <div className='flex flex-row md:flex-col w-full justify-between -mt-2'>
                                    <div className='w-full md:w-1/2'>
                                    <FormInput
                                        name='start_date'
                                        type='date'
                                        value={startDate}
                                        min={pip_id || probation_id ? query.get('start_date') : ''}
                                        max={pip_id || probation_id ? query.get('end_date') : ''}
                                        onInput={(e) => {
                                        setStartDate(e.target.value);
                                        }}
                                        inputRef={register(formValidation('date', true))}
                                        error={errors.start_date}
                                        errorMessage={
                                        errors.start_date && errors.start_date.message
                                        }
                                    />
                                    </div>
                                    <div className='w-full md:w-1/2 pl-2'>
                                    <FormInput
                                        name='end_date'
                                        type='date'
                                        value={endDate}
                                        min={startDate}
                                        max={pip_id || probation_id ? query.get('end_date') : ''}
                                        onInput={(e) => {
                                        setEndDate(e.target.value);
                                        }}
                                        inputRef={register(formValidation('date', true))}
                                        error={errors.end_date}
                                        errorMessage={
                                        errors.end_date && errors.end_date.message
                                        }
                                    />
                                    </div>
                                </div>
                                <div className='text-[18px] font-extrabold mt-2'>Choose evaluation method</div>
                                <hr className='divider mt-2 mb-3' />
                                <div className='w-full'>
                                    <FormSwitch
                                        label={'Evaluate Organisational Appraisals?'}
                                        name={'organisational_appraisals'}
                                        checked={useOrganisationalAppraisal}
                                        onClick={() => setUseOrganisationalAppraisal(!useOrganisationalAppraisal)}
                                    />
                                </div>
                                {useOrganisationalAppraisal?
                                <div className='flex flex-col justify-center'>
                                    <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 mt-2 bg-color-warning-light text-[14px] items-center'>
                                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                                            <img src={WarningSVG} alt='' srcSet='' />
                                        </div>
                                        <span className='text-[12px]'>
                                            Predefined Organisational Performance indicators will be assessed. Both behavioural and technical indicators will be assessed.
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                                }
                                
                                <hr className='divider mt-0' />
                                <div className='w-full'>
                                    <FormSwitch
                                        label={'Evaluate Objectives and Key Results (OKR)?'}
                                        name={'evaluate_okr'}
                                        checked={useOkr}
                                        onClick={() => setUseOkr(!useOkr)}
                                    />
                                </div> 
                                {useOkr?
                                <div className='flex flex-col justify-center'>
                                    <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 mt-2 bg-color-warning-light text-[14px] items-center'>
                                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                                            <img src={WarningSVG} alt='' srcSet='' />
                                        </div>
                                        <span className='text-[12px] text-justify'>
                                            Predefined OKRs will be included in this assessment. OKRs used will be OKRs set per employee. Only OKRs that fall withtin the evaluation period will be considered.
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                                }
                                <hr className='divider mt-0' />
                                <div className='w-full'>
                                    <FormSwitch
                                        label={'Evaluate SMART Goals?'}
                                        name={'smart_goals'}
                                        checked={useSmartGoals}
                                        onClick={() => setUseSmartGoals(!useSmartGoals)}
                                    />
                                </div>   
                                {useSmartGoals?
                                <div className='flex flex-col justify-center'>
                                    <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 mt-2 bg-color-warning-light text-[14px] items-center'>
                                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                                            <img src={WarningSVG} alt='' srcSet='' />
                                        </div>
                                        <span className='text-[12px] text-justify'>
                                            Predefined SMART Goals will be included in this assessment. SMART Goals set per employee will be attached to that employee. Only SMART Goals that fall withtin the evaluation period will be considered.
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                                }
                                <hr className='divider mt-0' />
                                <div className='w-full'>
                                    <FormSwitch
                                        label={'Evaluate Technical Skills?'}
                                        name={'technical_skills'}
                                        checked={useTechnicalAppraisals}
                                        onClick={() => setUseTechnicalAppraisals(!useTechnicalAppraisals)}
                                    />
                                </div>   
                                {useTechnicalAppraisals?
                                <div className='flex flex-col justify-center'>
                                    <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 mt-2 bg-color-warning-light text-[14px] items-center'>
                                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                                            <img src={WarningSVG} alt='' srcSet='' />
                                        </div>
                                        <span className='text-[12px] text-justify'>
                                            Predefined Technical Appraisals will be included in this assessment. Technical Appraisals of the group selected will be used in creating the evaluation of the employee.
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                                }
                                <hr className='divider mt-0' />
                                <div className='w-full'>
                                    <FormSwitch
                                        label={'Evaluate Balanced Scorecard?'}
                                        name={'balanced_scorecard'}
                                        checked={useBalancedScorecard}
                                        onClick={() => setUseBalancedScorecard(!useBalancedScorecard)}
                                    />
                                </div>   
                                {useBalancedScorecard?
                                <div className='flex flex-col justify-center'>
                                    <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-2 mt-2 bg-color-warning-light text-[12px] items-center'>
                                        <div className='w-[70px] pr-2 flex flex-row items-center '>
                                            <img src={WarningSVG} alt='' srcSet='' />
                                        </div>
                                        <span className='text-[12px] text-justify'>
                                            Predefined Balanced scorecards will be included in this assessment. Balanced scorecards of the group/employee selected will be used in creating the evaluation of the employee, team, department or branch.
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                                }
                            </div>
                            <div className='w-full mt-[20px] pb-4 '>
                                <Button
                                    loading={addPerformanceEvaluationLoading || addPerformanceEvaluationBySourceLoading}
                                    disabled={!isValid}
                                    onClick={() => submitForm()}
                                    text={'COMMENCE PERFORMANCE EVALUATION'}
                                    type='button'
                                />
                                <div
                                    className='flex justify-center w-full cursor-pointer'
                                    onClick={() => history.goBack()}
                                    >
                                    <span className='p2-medium text-color-black mt-3'>Go Back</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </PerformanceEvaluationLayout>
    );
}

export default AdvancedEvaluation;
