import { Axios } from '../axios';

const addRecruitment = async (payload) => {
    const { data } = await Axios.post('/recruitments', payload);
    return data;
};

const getRecruitments = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter !== -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get( `/recruitments?limit=${payload.limit}&page=${payload.page}${filter}${search}`,);
    return data?.data;
};

const getRecruitmentSummary = async () => {
    const { data } = await Axios.post('/recruitment/summary');
    return data.data;
};

const updateRecruitment = async (payload) => {
    const { data } = await Axios.put('/recruitments/' + payload.id, payload.data);
    return data;
};
  
const getRecruitment = async (payload) => {
    const { data } = await Axios.get('/recruitments/' + payload);
    return data?.data;
};

const getRecruitmentByToken = async (payload) => {
    const { data } = await Axios.get('/recruitments/by-token/' + payload);
    return data?.data;
};

const deleteRecruitment = async (payload) => {
    const { data } = await Axios.delete(`/recruitments/${payload}`,);
    return data;
};

const getRecruitmentWorkflow = async (payload) => {
    const { data } = await Axios.get('/recruitment-workflow/' + payload);
    return data?.data;
};

const getRecruitmentWorkflows = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.get( `/recruitment-workflows?limit=${payload.limit}&page=${payload.page}${search}`,);
    return data?.data;
};

const addRecruitmentWorkflow = async (payload) => {
    const { data } = await Axios.post('/recruitment-workflows', payload);
    return data;
};

const updateAssignedWorkflow = async (payload) => {
    const { data } = await Axios.put('/recruiment/assigned-workflows/'+payload.id, payload.data);
    return data;
};

const updateRecruitmentStages = async (payload) => {
    const { data } = await Axios.post('/recruitment/stages', payload);
    return data;
};

const addRecruitmentApplication = async (payload) => {
    const { data } = await Axios.post('/recruitment-applications', payload, 
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0
    });
    return data;
};

const getRecruitmentApplications = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter !== -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get( `/recruitment-applications/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`,);
    return data?.data;
};

const getRecruitmentApplication = async (payload) => {
    const { data } = await Axios.get('/recruitment-application/' + payload);
    return data?.data;
};

const getOfferByToken = async (payload) => {
    const { data } = await Axios.get(`/offer-letter-by-token/${payload}`);
    return data?.data;
};

const videoAssessment = async (payload) => {
    const { data } = await Axios.post('/recruitment-applications/video-assessment', payload, 
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0
    });
    return data;
};


const activateOrDeactivateRecruitmentApplicationStatus = async (payload) => {
    const { data } = await Axios.put('/recruitments/activation/' + payload);
    return data;
};

const moveApplicantToNextStage = async (payload) => {
    const { data } = await Axios.put( `/recruitment-move-applicant/${payload.id}`);
    return data;
};

const moveBulkApplicantsToNextStage = async (payload) => {
    const { data } = await Axios.post('/recruitment-move-applicant', payload);
    return data;
};

const getRecruitmentStages = async (payload) => {
    const { data } = await Axios.get( `/recruitment-stages/${payload}`);
    return data?.data;
};

const markApplicantReviewed = async (payload) => {
    const { data } = await Axios.post(`/recruitment-application/mark-as-reviewed`, payload);
    return data;
};

const getStageApplicants = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.post( `/recruitment-applications-by-stage?stage=${payload.stage}&limit=${payload.limit}&page=${payload.page}${search}&recruitment_id=${payload.recruitment_id}`);
    return data?.data;
};

const getRecruitmentQuestions = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    const { data } = await Axios.get( `/recruitment/${payload.id}/questions?limit=${payload.limit}&page=${payload.page}${search}`,);
    return data?.data;
};

const getRecruitmentQuestion = async (payload) => {
    const { data } = await Axios.get('/recruitment-question/' + payload);
    return data?.data;
};

const addQuestions = async (payload) => {
    const { data } = await Axios.post('/recruitment-questions', payload);
    return data;
};
const updateQuestion = async (payload) => {
    const { data } = await Axios.put(`/recruitment-questions/${payload.id}`, payload.data);
    return data;
};

const deleteQuestion = async (payload) => {
    const { data } = await Axios.delete(`/recruitment-questions/${payload}`,);
    return data;
};

const getApplicationNotes = async (payload) => {
    const { data } = await Axios.get( `/application-notes/${payload}`,);//{recruitment_application_id}
    return data?.data;
};

const addApplicationNote = async (payload) => {
    const { data } = await Axios.post('/application-notes', payload);
    return data;
};
const updateApplicationNote = async (payload) => {
    const { data } = await Axios.put(`/application-notes/${payload.id}`, payload.data);
    return data;
};

const deleteApplicationNote = async (payload) => {
    const { data } = await Axios.delete(`/application-notes/${payload}`,);
    return data;
};

const sendApplicantsEmail = async (payload) => {
    const { data } = await Axios.post('/recruitment-applications/send-mail/bulk', payload);
    return data;
};

const SendEmailsByStage = async (payload) => {
    const { data } = await Axios.post('/recruitment-applications/send-mail/stage', payload);
    return data;
};

const sendEmailsByStatus = async (payload) => {
    const { data } = await Axios.post('/recruitment-applications/send-mail/status', payload);
    return data;
};

const sendOfferLetter = async (payload) => {
    const { data } = await Axios.post('/send-offer-letter', payload, 
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0
    });
    return data;
};

const resendOfferLetter = async (payload) => {
    const { data } = await Axios.post(`/resend-offer-letter/${payload.type}/${payload.id}`);
    return data;
};

const getVideoRecruitmentRequest = async (payload) => {
    const { data } = await Axios.get( `/recruitments-applications/video-assessment-request/${payload.token}/${payload.mobile}`,);
    return data?.data;
};

const uploadVideo = async (payload) => {
    const { data } = await Axios.post(`/recruitment-applications/upload-video-assessment`, payload,
    {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 0
    });
    return data;
};

const resendVideoAssessment = async (payload) => {
    const { data } = await Axios.get(`/recruitment-applications/resend-video-assessment/${payload}`, );
    return data;
};

export const recruitments = {
    getRecruitments,
    addRecruitment,
    getRecruitmentApplications,
    getRecruitmentApplication,
    addRecruitmentApplication,
    updateRecruitment,
    getRecruitment,
    getRecruitmentByToken,
    getRecruitmentSummary,
    deleteRecruitment,
    activateOrDeactivateRecruitmentApplicationStatus,
    moveApplicantToNextStage,
    moveBulkApplicantsToNextStage,
    getRecruitmentStages,
    getStageApplicants,
    getRecruitmentQuestions,
    getRecruitmentQuestion,
    getRecruitmentWorkflows,
    addRecruitmentWorkflow,
    getRecruitmentWorkflow,
    addQuestions,
    updateQuestion,
    deleteQuestion,
    updateAssignedWorkflow,
    updateRecruitmentStages,
    markApplicantReviewed,
    getApplicationNotes,
    addApplicationNote,
    updateApplicationNote,
    deleteApplicationNote,
    sendApplicantsEmail,
    SendEmailsByStage,
    sendEmailsByStatus,
    sendOfferLetter,
    resendOfferLetter,

    getOfferByToken,
    videoAssessment,
    getVideoRecruitmentRequest,
    uploadVideo,
    resendVideoAssessment
};

