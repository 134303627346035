import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import {
  PAGINATION_DEFAULT,
  trainingStatusConfig,
} from "../../utils/constants";
import { useHistory } from "react-router-dom";
import Button from "../button";
import {
  useChangeTrainingStatus,
  useDeleteTraining,
  useGetemployeeTrainings,
} from "../../redux/employees/hook/training";
import AddTraining from "../modal/hr-section/employee-training/add-training";
import { useGetEmployees } from "../../redux/employees/hook";

const TrainingOverviewTab = () => {
  useEffect(() => {}, []);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    satusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const [tableType, setTableType] = useState("training");

  const [showAddTraining, setShowAddTraining] = useState(false);
  const [trainingData, setTrainingData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const switchFunction = () => {
    if (tableType === "training") {
      setTableType("table");
    } else {
      setTableType("training");
    }
  };

  const { mutateAsync: deleteTraining } = useDeleteTraining();

  const { mutateAsync: markTraining } = useChangeTrainingStatus();

  const { data: trainings } = useGetemployeeTrainings(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const history = useHistory();

  const tableConfig = {
    headers: ["Training Name", "Trainer", "Training Period", "Status"],
    keys: ["training_name", "trainer_name", "period", "status"],
    mobileHeader: {
      left: {
        title: "Training",
        key: "trainer_name",
      },
      right: {
        title: "Status",
        key: "status",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = trainings.data.findIndex((item) => item.id === id);
        history.push("/training/view/" + trainings.data[index].id);
      },
    },
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = trainings.data.findIndex((item) => item.id === id);
        setTitle("Update Training");
        setButtonText("Update");
        setTrainingData(trainings.data[index]);
        setShowAddTraining(true);
      },
    },
    {
      name: "Mark As Completed",
      color: "green",
      action: async (id) => {
        let index = trainings.data.findIndex((item) => item.id === id);
        await markTraining({
          id: trainings.data[index].id,
          status: trainings.data[index].status == 1 ? 0 : 1,
        });
      },
    },
    {
      name: "Remove",
      color: "red",
      action: async (id) => {
        let index = trainings.data.findIndex((item) => item.id === id);
        await deleteTraining(trainings.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddTraining ? (
        <AddTraining
          isOpen={showAddTraining}
          closeModal={() => setShowAddTraining(false)}
          trainingData={trainingData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}

      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Employee Training
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Employee Training"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              disabled={!employees}
              onClick={() => {
                setTitle("Add Training");
                setButtonText("Save");
                setTrainingData(null);
                setShowAddTraining(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={trainings}
        tableConfig={tableConfig}
        statusConfig={trainingStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        computedActions={true}
        tableType={tableType}
        switchFunction={switchFunction}
        layoutSwitch={true}
        searchData={true}
        noCheck={true}
      />
    </div>
  );
};

export default TrainingOverviewTab;
