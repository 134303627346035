import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../utils/functions";
import Button from "../../button";
import FormFileInput from "../../form-file-input";
import CloseSVG from "../../../assets/svg/close.svg";
import Modal from "../modal";
import { useBulkAddOtherDeductions } from "../../../redux/employees/hook/other-salary-deductions";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { useExportSalaryTemplate } from "../../../redux/employees/hook";
import { employees as employeeAPI } from "../../../api/employees";
import FormSelect from "../../form-select";
import AsyncSelect from "react-select/async";

function BulkAddOtherDeduction({ isOpen, closeModal, refresh, completed }) {
  const { mutateAsync, isLoading } = useBulkAddOtherDeductions();
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const [exportType, setexportType] = useState("all");

  const typeOptions = [
    {
      value: "all",
      label: "All employees",
    },
    {
      value: "select",
      label: "Selected Employees",
    },
  ];

  const [employees, setEmployees] = useState([]);

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 10,
          statusFilter: -1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];
          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
    });

  const workbook = new Excel.Workbook();

  const columns = [
    { header: "Employee's First Name", key: "first_name" },
    { header: "Employee's Last Name", key: "last_name" },
    { header: "Phone Number", key: "mobile" },
    { header: "Month (YYYY-MM)", key: "month" },
    { header: "Description (Brief)", key: "description" },
    { header: "Amount (Number Only, No Commas)", key: "amount" },
    { header: "Staff Number", key: "staff_number" },
    { header: "Status", key: "status" },
  ];

  const { mutateAsync: exportSalaryTemplate } = useExportSalaryTemplate();

  const [exportLoading, setSetExportLoading] = useState(false);

  const exportTemplate = async () => {
    setSetExportLoading(true);
    await exportSalaryTemplate().then(async (response) => {
      let data = response?.data;
      const workSheetName = "active_employee_data";
      const workBookName = `Other deductions template`;
      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column) => {
          if (column._number > 5) {
            column.width = 18;
            column.style.alignment = { horizontal: "right" };
            column.numFmt = "0.00";
          } else if (column._number == 4) {
            column.numFmt = "YYYY-MM";
          } else {
            column.width = column.header.length + 2;
            column.style.alignment = { horizontal: "left" };
          }
        });
        if (exportType == "all") {
          data?.forEach((singleData) => {
            singleData.month = "";
            singleData.description = "";
            singleData.amount = "";
            if (singleData.status == 0) {
              singleData.status = "Inactive";
            } else if (singleData.status == 1) {
              singleData.status = "Active";
            } else if (singleData.status == 2) {
              singleData.status = "Pending";
            } else if (singleData.status == 3) {
              singleData.status = "Deleted";
            } else if (singleData.status == 2) {
              singleData.status = "Awaiting Activation";
            } else {
              singleData.status = "Pre-registered";
            }
            worksheet.addRow(singleData);
          });
        } else if (exportType == "select" && employees.length > 0) {
          employees.forEach((employee) => {
            data?.forEach((singleData) => {
              if (employee.value == singleData.id) {
                singleData.month = "";
                singleData.description = "";
                singleData.amount = "";
                if (singleData.status == 0) {
                  singleData.status = "Inactive";
                } else if (singleData.status == 1) {
                  singleData.status = "Active";
                } else if (singleData.status == 2) {
                  singleData.status = "Pending";
                } else if (singleData.status == 3) {
                  singleData.status = "Deleted";
                } else if (singleData.status == 2) {
                  singleData.status = "Awaiting Activation";
                } else {
                  singleData.status = "Pre-registered";
                }
                worksheet.addRow(singleData);
              }
            });
          });
        }

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: true }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;
            if (singleCell._column._number == 8) {
            }
            if (
              singleCell._column._number == 8 &&
              singleCell._row._number > 1
            ) {
              if (singleCell._value.model.value == "Inactive") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "D34829" },
                };
              } else if (singleCell._value.model.value == "Active") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "0d5225" },
                };
              } else if (singleCell._value.model.value == "Pending") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "FFA500" },
                };
              } else if (singleCell._value.model.value == "Deleted") {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "D34829" },
                };
              } else if (
                singleCell._value.model.value == "Awaiting Activation"
              ) {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "FFA500" },
                };
              } else {
                worksheet.getCell(cellAddress).font = {
                  color: { argb: "FFA500" },
                };
              }
            }
            worksheet.getCell(cellAddress).border = {
              top: { style: "none" },
              left: { style: "none" },
              bottom: { style: "none" },
              right: { style: "none" },
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        // console.error('<<<ERRROR>>>', error);
        // console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  async function submitForm(data) {
    const formData = new FormData();
    formData.append("file", data?.file[0]);

    await mutateAsync(formData).then(() => {
      completed();
    });
  }
  return (
    <Modal
      scrollable={true}
      className="!max-w-[700px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col overflow-auto">
        <div className="flex flex-row justify-between pl-8 pr-8 pt-4 items-center">
          <span className="header-4">Add Bulk Other Deductions</span>
          <div className="cursor-pointer" onClick={() => closeModal()}>
            <img src={CloseSVG} alt="close" />
          </div>
        </div>
        <hr className="divider" />
        <form
          className="form px-8 rounded-md h-fit flex flex-col pb-4"
          onSubmit={handleSubmit(submitForm)}
        >
          <div className="flex flex-col border bg-highlight p-2 rounded-md">
            <FormSelect
              value={exportType}
              options={typeOptions}
              onChange={(selected) => {
                setexportType(selected);
              }}
              label="Select Template Export Type"
              extraClass={"bg-white"}
            />
            <div>
              {exportType === "select" ? (
                <div>
                  <label className="!text-[13px] leading-[23px] font-normal text-color-gray mb-1">
                    Select Employees
                  </label>
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    className="text-[13px]"
                    placeholder={"Type to select multiple employees"}
                    loadOptions={loadOptions}
                    onChange={(options) => setEmployees(options)}
                    classNamePrefix="mySelect"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <hr className="divider mt-2" />
          {exportType == "all" || employees.length > 0 ? (
            <>
              <div className="flex flex-col text-center justify-center items-center">
                <span className="text-[16px] font-extrabold mb-2">
                  Upload other deductions from XLSX file
                </span>
                <div className=" flex flex-col border p-4 rounded-md bg-highlight mt-2 mb-3 justify-center items-center w-full">
                  <Button
                    text="Download template"
                    type="button"
                    theme="primary"
                    loading={exportLoading}
                    className="flex gap-2 !h-[35px] w-[220px] p-2 "
                    textClass={"!text-[11px]"}
                    onClick={() => {
                      exportTemplate();
                    }}
                  />
                </div>
                <FormFileInput
                  multiSource={false}
                  inputRef={register(formValidation("file", true))}
                  accept=".xlsx"
                />
              </div>
              <div className="w-full mt-2">
                <Button text="UPLOAD" type="submit" loading={isLoading} />
              </div>
            </>
          ) : (
            <div className="flex flex-col border bg-highlight p-2 rounded-md items-center justify-center text-[14px]">
              Select at least one employee to download template
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
}

export default BulkAddOtherDeduction;
