import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetCabinetGuarantors } from '../../redux/employee-folder/hook';
import EmptyTableSVG from '../../assets/svg/empty-table.svg';
import LoadingBar from '../loader-bar';

const GuarantorFolder = ({employeeId}) => {

    const { user } = useSelector((state) => state.userSlice);

    const { data: guarantors, isLoading } = useGetCabinetGuarantors(employeeId);

    return (
        <div className='flex flex-col'>
            {isLoading?
            <LoadingBar loading={isLoading} />
            :
            <div className='flex md:flex-row flex:col my-4 justify-center items-center'>
                {guarantors ? (
                <>
                    {guarantors.map((guarantor, index) => (
                    <div
                        key={index}
                        // onClick={() => setSelectedPlanIndex(0)}
                        className='flex flex-row flex-wrap-reverse gap-3 justify-between border border-gray-200 rounded px-4 mx-2 p-3 md:w-1/2 w-full'
                    >
                        <div className=' flex flex-col gap-[2px] '>
                        <div className='flex flex-row items-center justify-between '>
                            <span className='header-5 text-hr-primary-1 mr-2'>
                            Name:
                            </span>
                            <span>{guarantor?.name}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between'>
                            <span className='header-5 text-hr-primary-1 mr-2'>
                            Phone Number:
                            </span>
                            <span>{guarantor?.mobile}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between'>
                            <span className='header-5 text-hr-primary-1 mr-2'>
                            Email:
                            </span>
                            <span>{guarantor?.email}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between'>
                            <span className='header-5 text-hr-primary-1 mr-2'>
                            Occupation:
                            </span>
                            <span>{guarantor?.occupation}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between'>
                            <span className='header-5 text-hr-primary-1 mr-2'>
                            Gender
                            </span>
                            <span>{guarantor?.gender}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between'>
                            <span className='header-5 text-hr-primary-1 mr-2'>
                            Company Name
                            </span>
                            <span>{guarantor?.guarantor_company_name}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between'>
                            <span className='header-5 text-hr-primary-1 mr-2'>
                            Company Address
                            </span>
                            <span>{guarantor?.work_address}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between'>
                            <span className='header-5 text-hr-primary-1 mr-2'>
                            ID Number
                            </span>
                            <span>{guarantor?.id_number}</span>
                        </div>
                        <div className='flex flex-row items-center justify-between'>
                            <span className='header-5 text-hr-primary-1 mr-2'>
                            Means of ID
                            </span>
                            <a href={guarantor?.means_of_id_url} target='_blank' rel="noreferrer" className='italic hover:underline text-hr-primary-1'>Download</a>
                        </div>
                        </div>
                    </div>
                    ))}
                </>
                ) : 
                <div className='flex flex-col w-full items-center justify-center'>
                    <img src={EmptyTableSVG} alt='No Data Fetched' width={'30%'} height={'60px'}/>
                    <span className='header-4 p-2 text-white rounded-[8px] bg-hr-primary-1 max-w-fit'>No Gurantor Added Yet</span>
                </div>
                }
            </div>
            }
        </div>
    );
};

export default GuarantorFolder;