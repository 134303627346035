import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import { useActivate2Fa } from '../../../redux/user/hook';
import Modal from '../modal';
import FormOtpInput from '../../form-otp-input';
import QRCode from 'react-qr-code';

function Activate2Fa({
  isOpen,
  closeModal,
  activationData,
  type,
  oldModalClose,
}) {
  const { mutateAsync: activateTwoFa, isLoading: activateTwoFaLoading } =
    useActivate2Fa();

  const [otp, setOtp] = useState('');

  const [twoFaActivated, setTwoFaActivated] = useState(false);

  const [activatedData, setActivatedData] = useState([]);

  const allModalClose = () => {
    oldModalClose();
    closeModal();
  };

  const submitForm = async () => {
    let payload = {
      otp: otp,
    };
    await activateTwoFa(payload).then((result) => {
      setActivatedData(result?.data?.backup_codes);
      setTwoFaActivated(true);
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !min-h-400 !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      {twoFaActivated ? (
        <form className='flex flex-col overflow-auto form pb-4'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-3'>
                Two-factor authentication activated successfully
              </span>
              <div className='cursor-pointer' onClick={() => allModalClose()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col overflow-auto form pb-4 px-8 items-center'>
            <span className='header-4 mb-1'>
              Two-factor authentication Backup Codes
            </span>
            <span className='text-[14px] mb-3'>
              Copy these codes to a secure location for use.
            </span>
            <div className='border bg-secondary-2-extralight grid md:grid-cols-3 grid-cols-1 gap-3 p-4 rounded-md mb-4 content-center w-[60%]'>
              {activatedData?.map((item) => (
                <span className='text-[15px] font-semibold grid content-center items-center'>
                  {item}
                </span>
              ))}
            </div>
            <div className='w-full'>
              <Button
                text='Close'
                type='button'
                onClick={() => allModalClose()}
              />
            </div>
          </div>
        </form>
      ) : (
        <form className='flex flex-col overflow-auto form pb-4'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-3'>
                Activate Two-factor Authentication
              </span>
              <div className='cursor-pointer' onClick={() => allModalClose()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col w-full justify-between items-center'>
              {type === 'authenticator' ? (
                <div className='w-100% flex flex-col items-center justify-center'>
                  <span>Scan QR code with your authenticatior app</span>

                  {activationData?.data?.qr_code_text && (
                    <div
                      style={{
                        height: 'auto',
                        margin: '0 auto',
                        maxWidth: 250,
                        width: '100%',
                      }}
                    >
                      <QRCode
                        size={256}
                        style={{
                          height: 'auto',
                          maxWidth: '100%',
                          width: '100%',
                        }}
                        value={activationData.data.qr_code_text}
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                  )}
                </div>
              ) : null}
              <div className='flex w-[100%] justify-center'>
                <FormOtpInput
                  value={otp}
                  valueLength={6}
                  onChange={(value) => setOtp(value)}
                  label={`Enter Code generated via ${
                    type == 'email' ? 'Email' : 'Authenticator App'
                  }`}
                />
              </div>
              <div className='w-full mt-[20px]'>
                <Button
                  text='ACTIVATE TWO-FACTOR'
                  type='button'
                  onClick={() => submitForm()}
                  loading={activateTwoFaLoading}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Modal>
  );
}

export default Activate2Fa;
