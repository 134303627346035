import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../';
import { queryClient } from '../..';
import { airtimeInformation } from '../../api/payroll/airtime';
import { setAlert, setIsLoading } from '../components/components-slice';

export function useGetAirtimePurchases(limit, page, statusFilter, search) {
  return useQuery(
    ['airtimes', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return airtimeInformation.getAirtimes({ limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((airtime) => {
          airtime.created = moment(airtime.created).format('MMMM DD, YYYY');
          airtime.status = airtime.status?parseFloat(airtime.status): 0;
          switch (airtime.status) {
            case 0:
              airtime.computedActions = [0];
              break;
            case 1:
              airtime.computedActions = [1];
              break;
            case 2:
              airtime.computedActions = [2,3];
              break;
            // case 3:
            //   airtime.computedActions = [1];
            //   break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetAirtimeHistory(
  limit,
  page,
  statusFilter,
  start_date,
  end_date,
) {
  return useQuery(
    [
      'airtime-history',
      { limit, page, statusFilter, start_date, end_date },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return airtimeInformation.getAirtimeHistory({
        limit,
        page,
        statusFilter,
        start_date,
        end_date,
      });
    },

    {
      select: (data) => {
        data?.data?.forEach((airtime) => {
            switch (airtime.status) {
              case 0:
                airtime.computedActions = [0, 1];
                break;
              case 1:
                airtime.computedActions = [2];
                break;
              default:
                break;
            }
          });
  
          return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function usePurchaseSingleAirtime() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return airtimeInformation.singleAirtime(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('airtimes');
        store.dispatch(setAlert(true, 'success', 'Airtime processed successfully successful'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRunAirtimeExcel() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return airtimeInformation.bulkAirtime(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('airtimes');
        store.dispatch(setAlert(true, 'success', 'Airtime run successfully. Proceed to verify and pay'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRefAirtime(refId, limit, page, statusFilter, search) {
  return useQuery(
    ['airtime-by-ref', { refId, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return airtimeInformation.fetchAirtimeByRef({ refId, limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((item) => {
          item.name = `${item.first_name} ${item.last_name}`;
          item.computedActions = [0, 1, 2]
          item.amount = item.amount.toLocaleString('en-NG', {style:'currency', currency:'NGN'});
        });
        if (data) {
          data.total_amount = data?.total_amount.toLocaleString('en-NG', {style:'currency', currency:'NGN'});
          data.total_trans_cost = data?.total_trans_cost.toLocaleString('en-NG', {style:'currency', currency:'NGN'});
          data.total_payable = data?.total_payable.toLocaleString('en-NG', {style:'currency', currency:'NGN'});
        }
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useRevertAirtime() {
  return useMutation(
    () => {
      store.dispatch(setIsLoading(true));
      return {
        status: 'success',
        message: 'No Airtime Purchase With This Reference Number',
      };
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRemoveAirtimesById() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return airtimeInformation.removeAirtimeById(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('airtime-by-ref');
        store.dispatch(setAlert(true, 'success', 'Airtime removed successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditAirtimeById() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return airtimeInformation.updateAirtimePurchase(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('airtime-by-ref');
        store.dispatch(setAlert(true, 'success', 'Airtime updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeclineAirtimePurchase() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return airtimeInformation.declineAirtime(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('airtimes');
        store.dispatch(setAlert(true, 'success', 'Airtime purchase declined successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useInitiateAirtime() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return airtimeInformation.initiateAirtime(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('airtimes');
        store.dispatch(setAlert(true, 'success', 'Airtime payment initiated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDisburseAirtime() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return airtimeInformation.disburseAirtime(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('airtimes');
        store.dispatch(setAlert(true, 'success', 'Airtime disbursed successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

