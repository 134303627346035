import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { recruitment_templates } from '../../../api/email-templates/recruitment';
import { setAlert, setIsLoading } from '../../components/components-slice';

export function useAddRecruitmentEmailTemplate() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return recruitment_templates.addRecrutimentEmailTemplate(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('application-notes');
        store.dispatch(setAlert(true, 'success', response.success));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetRecruitmentEmailTemplates(limit, page, search) {
  return useQuery(
    ['recruitment-emails', { limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return recruitment_templates.getRecrutimentEmailTemplates({
        limit,
        page,
        search,
      });
    },
    {
      select: (data) => {
        data?.data?.forEach((template) => {
          template.computedActions = [0, 1, 2];
          if (template && template.body && template.body.length >= 80) {
            template.new_body = template.body.substring(0, 80) + '....';
          } else {
            template.new_body = template.body;
          }
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useEditRecruitmentEmailTemplate() {
  return useMutation(
    (payload) => {
      return recruitment_templates.updateRecrutimentEmailTemplate(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('recruitment-emails');
        store.dispatch(setAlert(true, 'success', 'Recruitment email template updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteRecruitmentEmailTemplates() {
  return useMutation(
    (payload) => {
      return recruitment_templates.deleteRecrutimentEmailTemplate(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('recruitment-emails');
        store.dispatch(setAlert(true, 'success', 'Recruitment email template deleted successfully'));
      },
    },
  );
}

export function useGetOnboardingRecruitmentEmails() {
  return useQuery(
    ['onboarding-emails'],
    () => {
      return recruitment_templates.getRecrutimentEmailTemplates();
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Email Template Selected' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, label: item.template_name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
