import React from 'react';
import AddBulkEmployeePay from '../../pages/employees/onboarding/add-bulk-employee-pay';
import AddEmployeeToGroup from '../../pages/employees/onboarding/add-employee-to-group';
import AddSalaryInfo from '../../pages/employees/onboarding/add-salary-info';
import AddEmployeePay from '../../pages/employees/onboarding/add-single-employee-pay';
import CreateBulkEmployee from '../../pages/employees/onboarding/create-bulk-employee';
import CreateSingleEmployee from '../../pages/employees/onboarding/create-single-employee';
import InviteBulkEmployee from '../../pages/employees/onboarding/invite-bulk-employee';
import InviteSingleEmployee from '../../pages/employees/onboarding/invite-single-employee';
import SelectOptions from '../../pages/employees/onboarding/select-options';
import AddCompleteSingleSalaryInfo from '../../pages/employees/onboarding/add-complete-single-salary-info';
import ProtectedRoute from '../protected';
import ViewEmployeePage from '../../pages/employees/view-employee';
import SelectReviewOptions from '../../pages/employees/salary-review/select-review-option';
import ReviewSalaryInfo from '../../pages/employees/salary-review/review-salary-info';
import ReviewSingleEmployeePay from '../../pages/employees/salary-review/review-single-employee-pay';
import ReviewSingleCompleteSalaryInfo from '../../pages/employees/salary-review/review-complete-single-salary-info';
import ReviewBulkEmployeePay from '../../pages/employees/salary-review/review-bulk-employee-pay';
import EmployeeOnboardingPage from '../../pages/hr/employees/employee-onboarding';
import EmployeeExitPage from '../../pages/hr/employees/employee-exit';
import ViewEmployeeOnboarding from '../../pages/hr/employees/employee-onboarding/view-onboarding';
import ViewEmployeeOnboardingWorkflow from '../../pages/hr/employees/employee-onboarding/view-onboarding-workflow';
import InitiateEmployeeOnboarding from '../../pages/employees/onboarding/assign-onboarding';
import ViewEmployeeExit from '../../pages/hr/employees/employee-exit/view-exit';
import HRTrainingPage from '../../pages/hr/employees/employee-training';
import ViewTraining from '../../pages/hr/employees/employee-training/view-training';
import ViewTrainer from '../../pages/hr/employees/employee-training/view-trainer';
import HRPromotionPage from '../../pages/hr/employees/employee-promotion';
import ViewQuery from '../../pages/hr/employees/employee-query/view-query';
import ViewPromotion from '../../pages/hr/employees/employee-promotion/view-promotion';
import EmployeeFolder from '../../pages/employee-folder';
import ViewPIP from '../../pages/hr/employees/employee-pip/view-pip';
import ViewProbation from '../../pages/hr/employees/employee-probation/view-probation';
import InvitationLinkPage from '../../pages/employees/onboarding/employee-invitation-link';
import EmployeePage from '../../pages/employees';

const AddingEmployeeRoute = [
  <ProtectedRoute
    exact
    path='/employees/onboarding/select-option'
    component={SelectOptions}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-single-employee/invite'
    component={InviteSingleEmployee}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-single-employee/create'
    component={CreateSingleEmployee}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-bulk-employee/invite'
    component={InviteBulkEmployee}
  />,
  
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-bulk-employee/create'
    component={CreateBulkEmployee}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/assign-onboarding-workflow'
    component={InitiateEmployeeOnboarding}
  />,
  
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-employee-to-group'
    component={AddEmployeeToGroup}
  />,
  
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-salary-information'
    component={AddSalaryInfo}
  />,
  
  <ProtectedRoute
    exact
    path='/employees/onboarding/complete-single-salary-information'
    component={AddCompleteSingleSalaryInfo}
  />,
  
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-single-salary-information'
    component={AddEmployeePay}
  />,

  <ProtectedRoute
    exact
    path='/employees/onboarding/add-bulk-salary-information'
    component={AddBulkEmployeePay}
  />,
  <ProtectedRoute
    exact
    path='/employees/onboarding/add-single-employee/invitation-link'
    component={InvitationLinkPage}
  />,
]

const SalaryReviewRoute = [
  <ProtectedRoute
    exact
    path='/employees/review/select-option'
    component={SelectReviewOptions}
  />,
  <ProtectedRoute
    exact
    path='/employees/review/review-salary-information'
    component={ReviewSalaryInfo}
  />,
  <ProtectedRoute
    exact
    path='/employees/review/review-single-salary-information'
    component={ReviewSingleEmployeePay}
  />,
  <ProtectedRoute
  exact
  path='/employees/review/complete-single-salary-information'
  component={ReviewSingleCompleteSalaryInfo}
/>,
<ProtectedRoute
    exact
    path='/employees/review/review-bulk-salary-information'
    component={ReviewBulkEmployeePay}
  />,
]

const QueryEmployeeRoute = [
  <ProtectedRoute
    exact
    path='/employees/query/:id'
    component={ViewQuery}
  />,
  
]

const PIPEmployeeRoute = [
  <ProtectedRoute
    exact
    path='/employees/performance-improvement-plan/:id'
    component={ViewPIP}
  />,
  
]

const ProbationEmployeeRoute = [
  <ProtectedRoute
    exact
    path='/employees/probation/:id'
    component={ViewProbation}
  />,
  
]

const OnboardingEmployeeRoutes = [
  <ProtectedRoute
    exact
    path='/onboarding'
    component={EmployeeOnboardingPage}
  />,

  <ProtectedRoute
    exact
    path='/onboarding/view/:id'
    component={ViewEmployeeOnboarding}
  />,
  <ProtectedRoute
    exact
    path='/onboarding/workflow/view/:id'
    component={ViewEmployeeOnboardingWorkflow}
  />,
]

const ExitEmployeeRoutes = [
  <ProtectedRoute
    exact
    path='/exit'
    component={EmployeeExitPage}
  />,
  <ProtectedRoute
    exact
    path='/exit/view/:id'
    component={ViewEmployeeExit}
  />, 
]

const TrainingEmployeeRoutes = [
  <ProtectedRoute
    exact
    path='/training'
    component={HRTrainingPage}
  />,
  <ProtectedRoute
    exact
    path='/training/view/:id'
    component={ViewTraining}
  />, 
  <ProtectedRoute
    exact
    path='/training/trainer/view/:id'
    component={ViewTrainer}
  />,
]

const EmployeeFolderRoutes = [
  <ProtectedRoute
    exact
    path='/employee-cabinet'
    component={EmployeeFolder}
  />,
]

const PromotionEmployeeRoutes = [
  <ProtectedRoute
    exact
    path='/promotion'
    component={HRPromotionPage}
  />,
  <ProtectedRoute
    exact
    path='/promotion/view/:id'
    component={ViewPromotion}
  />, 
]

export const HREmployeeRoutes = [
  <ProtectedRoute exact path='/employees' component={EmployeePage} />,
  <ProtectedRoute exact path='/guarantors' component={EmployeePage} />,
  <ProtectedRoute exact path='/salaries' component={EmployeePage} />,
  <ProtectedRoute exact path='/employee-documents' component={EmployeePage} />,
  <ProtectedRoute exact path='/queries' component={EmployeePage} />,
  <ProtectedRoute exact path='/performance-improvement-plans' component={EmployeePage} />,
  <ProtectedRoute exact path='/probations' component={EmployeePage} />,
  <ProtectedRoute exact path='/suspensions' component={EmployeePage} />,
  <ProtectedRoute exact path='/employees/view/:id' component={ViewEmployeePage} />,
  ...PromotionEmployeeRoutes,
  ...TrainingEmployeeRoutes,
  ...ExitEmployeeRoutes,
  ...OnboardingEmployeeRoutes,
  ...AddingEmployeeRoute,
  ...SalaryReviewRoute,
  ...QueryEmployeeRoute,
  ...EmployeeFolderRoutes,
  ...PIPEmployeeRoute,
  ...ProbationEmployeeRoute
];