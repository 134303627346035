import React, { useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import { monthOptions, yearOptions } from '../../../utils/constants/date';
import Button from '../../button';
import Modal from '../modal';
import { formatAmount } from '../../../utils/functions';
import moment from 'moment';
import { useApproveOvertime } from '../../../redux/employees/hook/overtime';
import FormSelect from '../../form-select';

function ApproveOvertime({
  isOpen,
  closeModal,
  overtime,
}) {

  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY-'));

  const { mutateAsync: approveOvertime, isLoading: approveLoading } = useApproveOvertime();

  const submitForm = async () => {
    let requestData = {
      application_id: overtime?.id,
      month: year + month,
    };
    await approveOvertime(requestData).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form'>
          <div className='flex flex-row justify-between pl-6 pr-6 pt-4 items-center'>
            <span className='header-3'>Approve Overtime</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col p-4 pt-0'>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-2 w-full'>
              <div className='flex flex-col'>
                <span className='text-[13px] text-color-gray'>Employee Name</span>
                <span className='text-color-black text-[12px] font-semibold'>
                  {overtime?.first_name} {overtime?.last_name}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='text-[13px] text-color-gray'>Mobile</span>
                <span className='text-color-black text-[12px] font-semibold'>
                  {overtime?.mobile}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='text-[13px] text-color-gray'>
                  Description
                </span>
                <span className='text-color-black text-[12px] font-semibold'>
                  {overtime?.description}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='text-[13px] text-color-gray'>
                  Overtime Hours
                </span>
                <span className='text-color-black text-[12px] font-semibold'>
                  {overtime?.overtime_hours}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='text-[13px] text-color-gray'>
                  Rate Per Hour
                </span>
                <span className='text-color-black text-[12px] font-semibold'>
                  {formatAmount(overtime?.overtime_rate_per_hour, 2)}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className='text-[13px] text-color-gray'>
                  Amount
                </span>
                <span className='text-color-black text-[12px] font-semibold'>
                  {formatAmount(overtime?.amount, 2)}
                </span>
              </div>
            </div>
            <hr className='divider mt-1' />
            <span className='text-color-gray text-[13px]'>
              Select Payroll Month/Year To Add Overtime
            </span>
            <div className='flex flex-row md:flex-col w-full justify-between -mt-1'>
              <div className='w-full md:w-1/2'>
                <FormSelect
                  defaultValue={month}
                  options={monthOptions}
                  onChange={(selected) => {
                    setMonth(selected);
                  }}
                />
              </div>
              <div className='w-full md:w-1/2 pl-2'>
                <FormSelect
                  defaultValue={year}
                  options={yearOptions}
                  onChange={(selected) => {
                    setYear(selected);
                  }}
                />
              </div>
            </div>
            <Button
              text='APPROVE'
              type='button'
              onClick={() => {
                submitForm();
              }}
              loading={approveLoading}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default ApproveOvertime;
