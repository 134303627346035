import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { formValidation } from "../../../../utils/functions";
import Button from "../../../button";
import FormFileInput from "../../../form-file-input";
import CloseSVG from "../../../../assets/svg/close.svg";
import { useCreateBulkVendors } from "../../../../redux/spend-management/vendor/hook";
import Modal from "../../modal";
import Excel from "exceljs";
import { saveAs } from "file-saver";

function BulkAddVendors({ isOpen, closeModal, refresh, completed }) {
  const { mutateAsync, isLoading } = useCreateBulkVendors();
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const columns = [
    { header: "Vendor Name", key: "vendor_name" },
    { header: "Contact Person", key: "contact_person" },
    { header: "Phone Number", key: "mobile" },
    { header: "Account Number", key: "account_number" },
    { header: "Bank Name", key: "bank_name" },
    { header: "Email Address", key: "email" },
    { header: "Address", key: "street_address" },
    { header: "State", key: "state" },
    { header: "City", key: "city" },
    { header: "Country", key: "country" },
  ];
  const columns2 = [{ header: "List of Banks", key: "bank_name" }];

  const [exportLoading, setSetExportLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const exportTemplate = async () => {
    setSetExportLoading(true);
    const workSheetName = "vendor_upload_template";
    const workBookName = `Vendor upload template`;
    try {
      const fileName = workBookName;

      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      worksheet.columns.forEach((column) => {
        column.width = 25;
        column.numFmt = "@";
      });

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };
      // loop through all of the rows and set the outline style.
      let bank_data = [
        { bank_name: "Access Bank" },
        { bank_name: "Access Bank (Diamond)" },
        { bank_name: "AccessMobile" },
        { bank_name: "Accion Microfinance Bank" },
        { bank_name: "ALAT by WEMA" },
        { bank_name: "ASO Savings and Loans" },
        { bank_name: "Cellulant" },
        { bank_name: "Citi Bank" },
        { bank_name: "Carbon" },
        { bank_name: "eTranzact" },
        { bank_name: "Ecobank Nigeria" },
        { bank_name: "Ecobank Xpress Account" },
        { bank_name: "Eyowo MFB" },
        { bank_name: "EcoMobile" },
        { bank_name: "Enterprise Bank" },
        { bank_name: "FBN Mortgages Limited" },
        { bank_name: "FIRSTMONIE" },
        { bank_name: "FBNQuest Merchant Bank" },
        { bank_name: "First City Monument Bank" },
        { bank_name: "FCMB Easy Account" },
        { bank_name: "Fidelity Bank" },
        { bank_name: "Fidelity Mobile" },
        { bank_name: "First Bank PLC" },
        { bank_name: "Fortis Microfinance Bank" },
        { bank_name: "FORTIS MOBILE" },
        { bank_name: "FSDH Merchant Bank" },
        { bank_name: "Flutterwave Technology Solutions Limited" },
        { bank_name: "Globus Bank" },
        { bank_name: "GTMobile" },
        { bank_name: "Guaranty Trust Bank" },
        { bank_name: "Heritage Bank" },
        { bank_name: "HOPE PAYMENT SERVICE BANK" },
        { bank_name: "Jaiz Bank" },
        { bank_name: "Keystone Bank" },
        { bank_name: "Kuda Bank" },
        { bank_name: "MIMO (Mkudi)" },
        { bank_name: "MoneyBox" },
        { bank_name: "MONIEPOINT MICROFINANCE BANK" },
        { bank_name: "Momo Payment Service Bank" },
        { bank_name: "New Prudential Bank" },
        { bank_name: "One Finance" },
        { bank_name: "Opay Digital Services/Paycom" },
        { bank_name: "Paga" },
        { bank_name: "Page Financials" },
        { bank_name: "PALMPAY" },
        { bank_name: "Parralex Microfinance bank" },
        { bank_name: "Parallex Bank" },
        { bank_name: "Parkway-ReadyCash" },
        { bank_name: "PARKWAY MICROFINANCE BANK" },
        { bank_name: "PayAttitude Online" },
        { bank_name: "Polaris Bank" },
        { bank_name: "PremiumTrust Bank" },
        { bank_name: "Providus Bank" },
        { bank_name: "PURPLEMONEY MICROFINANCE BANK" },
        { bank_name: "Reliance Microfinance Bank" },
        { bank_name: "RenMoney Microfinance Bank" },
        { bank_name: "Rubies Microfinance Bank" },
        { bank_name: "Sparkle Microfinance Bank" },
        { bank_name: "Stanbic Ease Wallet" },
        { bank_name: "Stanbic IBTC Bank" },
        { bank_name: "Standard Chartered Bank" },
        { bank_name: "Sterling Bank" },
        { bank_name: "Suntrust Bank" },
        { bank_name: "TAJ Bank" },
        { bank_name: "Tangerine Bank" },
        { bank_name: "TagPay" },
        { bank_name: "Titan Trust Bank" },
        { bank_name: "Trident Microfinance Bank" },
        { bank_name: "Union Bank PLC" },
        { bank_name: "United Bank For Africa" },
        { bank_name: "Unity Bank" },
        { bank_name: "VFD Micro Finance Bank" },
        { bank_name: "Wema Bank" },
        { bank_name: "Zenith Bank" },
        { bank_name: "ZENITH EAZY WALLET" },
        { bank_name: "ZenithMobile" },
      ];

      const worksheet2 = workbook.addWorksheet("Banks List");
      worksheet2.columns = columns2;
      worksheet2.getRow(1).font = { bold: true };

      bank_data?.forEach((singleData) => {
        worksheet2.addRow(singleData);
      });


      worksheet.eachRow({ includeEmpty: true }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;
          worksheet.getCell(cellAddress).border = {
            top: { style: "none" },
            left: { style: "none" },
            bottom: { style: "none" },
            right: { style: "none" },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      // console.error('<<<ERRROR>>>', error);
      // console.error('Something Went Wrong', error.message);
    } finally {
      workbook.removeWorksheet(workSheetName);
    }
    setSetExportLoading(false);
  };

  async function submitForm(data) {
    const formData = new FormData();
    formData.append("file", data?.file[0]);

    await mutateAsync(formData).then(() => {
      completed();
    });
  }

  return (
    <Modal
      scrollable={true}
      className="!max-w-[700px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col overflow-auto pb-4">
        <div className="pt-4 pr-8 pl-8">
          <div className="flex flex-row justify-between items-center">
            <span className="header-4">Add Vendor(s)</span>
            <div className="cursor-pointer" onClick={() => closeModal()}>
              <img src={CloseSVG} alt="close" />
            </div>
          </div>
        </div>
        <hr className="divider" />
        <form className="form " onSubmit={handleSubmit(submitForm)}>
          <div className=" h-fit flex flex-col px-8">
            <div className="flex flex-col text-center justify-center items-center">
              <span className="text-[16px] font-extrabold mb-2">
                Import vendor information from XLSX file
              </span>
              <div className=" flex flex-col border p-4 rounded-md bg-highlight mt-2 mb-3 justify-center items-center w-full">
                <Button
                  text="Download template"
                  type="button"
                  theme="primary"
                  loading={exportLoading}
                  className="flex gap-2 !h-[35px] w-[220px] p-2 "
                  textClass={"!text-[11px]"}
                  onClick={() => {
                    exportTemplate();
                  }}
                />
              </div>

              <FormFileInput
                multiSource={false}
                name="file"
                inputRef={register(formValidation("file", true))}
                accept=".xlsx"
              />
            </div>

            <div className="w-full mt-3">
              <Button
                text="UPLOAD"
                type="submit"
                diabled={!isValid}
                loading={isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default BulkAddVendors;
