import React, { useEffect } from "react";
import Button from "../../components/button";
import { Card } from "../../components/card";
import DataTable from "../../components/datatable";
import WalletSVG from "../../assets/svg/dashboard-wallet.svg";
import DashboardLayout from "../../components/layouts/dashboard";
import CopySVG from "../../assets/svg/copy.svg";
import { useState } from "react";
import {
  PAGINATION_DEFAULT,
  payrollHistoryStatusConfig,
} from "../../utils/constants";
import {
  useExportTransactionsHistory,
  useGetCompanyAccountDetails,
  useGetWalletHistory,
} from "../../redux/wallet/hook";
import { useDispatch, useSelector } from "react-redux";
import UnauthorizedPage from "../errors/unauthorized";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import LoadingBar from "../../components/loader-bar";
import CopyToClipboard from "react-copy-to-clipboard";
import { setAlert } from "../../redux/components/components-slice";
import {
  useCheckTransactionStatus,
  useRetryTransaction,
} from "../../redux/payroll/hook";
import { formatAmount, formatNumber } from "../../utils/functions";
import { RetryToAnotherBank } from "../../components/modal/wallet/retry-to-another-bank";
import { store } from "../../redux";
import { GenerateAccountDetailsModal } from "../../components/modal/wallet/generate-account-information";

const WalletPage = () => {
  const workbook = new Excel.Workbook();

  const columns = [
    { header: "Date", key: "created" },
    { header: "Name", key: "name" },
    { header: "Description", key: "info" },
    { header: "Ref", key: "internalRef" },
    { header: "Payment Group", key: "g_type" },
    { header: "Type", key: "type" },
    { header: "Payments Status", key: "status" },
    { header: "Outflow", key: "debit" },
    { header: "Inflow", key: "credit" },
  ];

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  const { mutateAsync: checkTransactionStatus } = useCheckTransactionStatus();

  const { mutateAsync: retryTransaction } = useRetryTransaction();

  const { company } = useSelector((state) => state.companySlice);

  const [showGenerateAccount, setShowGenerateAccount] = useState(false);
  const [exportLoading, setSetExportLoading] = useState(false);
  const { data: companyAccountDetails, isLoading: loading } =
    useGetCompanyAccountDetails();

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
    start_date: "",
    end_date: "",
    type: "",
  });

  useEffect(() => {
    if (user) {
      if (user.role !== "Owner") {
        if (user.permissions.company_permission.wallet_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
    if (company && company.wallet_id == null) {
      setShowGenerateAccount(true);
    }
  }, [user, company]);

  const { data: walletHistory } = useGetWalletHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date,
    pagination?.type
  );

  const { mutateAsync: exportHistory } = useExportTransactionsHistory();

  const exportWalletHistory = async () => {
    setSetExportLoading(true);
    let payload = {
      limit: 100000,
      page: 1,
      statusFilter: pagination?.statusFilter,
      search: pagination?.search,
      start_date: pagination?.start_date,
      end_date: pagination?.end_date,
      type: pagination?.type,
    };

    await exportHistory(payload).then(async (response) => {
      if (response) {
        let data = response.data;
        const workSheetName = "wallet-history";
        const workBookName = `Wallet History from ${pagination?.start_date} - ${pagination?.end_date}`;
        try {
          const fileName = workBookName;

          const worksheet = workbook.addWorksheet(workSheetName);

          // add worksheet columns
          // each columns contains header and its mapping key from data
          worksheet.columns = columns;

          worksheet.columns.forEach((column) => {
            if (column._number > 7) {
              column.width = column.header.length + 5;
              column.style.alignment = { horizontal: "right" };
              column.numFmt = "0.00";
            } else {
              column.width = column.header.length + 2;
              column.style.alignment = { horizontal: "left" };
            }
          });

          // updated the font for first row.
          worksheet.getRow(1).font = { bold: true };

          // loop through data and add each one to worksheet
          data.forEach((singleData) => {
            if (singleData.status === 1) {
              singleData.status = "Successful";
            } else if (singleData.status === 2) {
              singleData.status = "Failed";
            } else if (singleData.status === 0) {
              singleData.status = "Pending";
            } else if (singleData.status === 3) {
              singleData.status = "Retried: Failed";
            }
            if (singleData.type === "wallet") {
              singleData.credit = singleData.amount;
              singleData.g_type = "deposit";
            } else {
              singleData.debit = singleData.amount;
            }
            worksheet.addRow(singleData);
          });

          // loop through all of the rows and set the outline style.
          worksheet.eachRow({ includeEmpty: false }, (row) => {
            // store each cell to currentCell
            const currentCell = row._cells;

            // loop through currentCell to apply border only for the non-empty cell of excel
            currentCell.forEach((singleCell) => {
              // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
              const cellAddress = singleCell._address;

              // apply border
              worksheet.getCell(cellAddress).border = {
                top: { style: "none" },
                left: { style: "none" },
                bottom: { style: "none" },
                right: { style: "none" },
              };
            });
          });

          // write the content using writeBuffer
          const buf = await workbook.xlsx.writeBuffer();

          // download the processed file
          saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
          // console.error('<<<ERRROR>>>', error);
          // console.error('Something Went Wrong', error.message);
        } finally {
          workbook.removeWorksheet(workSheetName);
        }
      } else {
        store.dispatch(setAlert(true, "info", "No transaction to export"));
      }
    });
    store.dispatch(
      setAlert(true, "info", "Transactions exported successfully")
    );
    setSetExportLoading(false);
  };

  const tableConfig = {
    // title: 'Transactions History',
    headers: [
      "Date",
      "Name",
      "Description",
      "Type",
      "Fees",
      "Source",
      "Inflow",
      "Outflow",
      "Status",
    ],
    keys: [
      "created",
      "name",
      "info",
      "type",
      "fees",
      "source",
      "credit",
      "debit",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00",
  };

  const [showRetryToAnotherBank, setShowRetryToAnotherBank] = useState(false);
  const [transactionId, setTransactionId] = useState();

  const actionConfig = [
    {
      name: "Check Status",
      color: "green",
      action: async (id) => {
        let index = walletHistory?.data.findIndex((item) => item.id === id);
        await checkTransactionStatus(walletHistory?.data[index].id);
      },
    },
    {
      name: "Retry",
      color: "red",
      action: (id) => {
        let index = walletHistory?.data.findIndex((item) => item.id === id);
        retryTransaction(walletHistory?.data[index].id);
      },
    },
    {
      name: "Retry To Another Bank",
      color: "red",
      action: (id) => {
        let index = walletHistory.data.findIndex((item) => item.id === id);
        setTransactionId(walletHistory.data[index].id);
        setShowRetryToAnotherBank(true);
      },
    },
  ];

  const typeConfig = [
    {
      label: "Deposit",
      color: "green",
      value: "wallet",
    },
    {
      label: "Disbursements",
      color: "orange",
      value: "disburse",
    },
  ];

  return (
    <DashboardLayout title="Wallet">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {showGenerateAccount ? (
            <GenerateAccountDetailsModal
              isOpen={showGenerateAccount}
              closeModal={() => setShowGenerateAccount(false)}
            />
          ) : null}
          {showRetryToAnotherBank ? (
            <RetryToAnotherBank
              isOpen={showRetryToAnotherBank}
              transaction_id={transactionId}
              closeModal={() => setShowRetryToAnotherBank(false)}
            />
          ) : null}
          <div className="flex flex-col -mt-4" id="wallet-management">
            <div className="flex md:flex-row justify-between flex-col items-center mb-4 gap-4">
              <div className="flex flex-col md:flex-row items-center justify-center md:w-fit w-full">
                <Card
                  title={"WALLET BALANCE"}
                  subtitle={
                    companyAccountDetails
                      ? `${formatAmount(
                          companyAccountDetails.total_balance,
                          2
                        )}`
                      : "0.00"
                  }
                  icon={WalletSVG}
                  className="md:mt-3 !min-w-[250px] w-full"
                />
              </div>

              <div className="p-3 pt-2 pb-2 flex flex-col gap-[5px] w-[100%] md:w-[250px] md:h-[90px] h-[130px] items-end justify-start bg-white rounded hover:bg-blue-100 duration-300">
                <div className="text-secondary-2">Wallet information:</div>
                {companyAccountDetails ? (
                  <CopyToClipboard
                    text={companyAccountDetails?.dojah?.account_number}
                    onCopy={() => {
                      dispatch(
                        setAlert(true, "success", "Copied successfully")
                      );
                    }}
                  >
                    <button className="text-[13px] flex flex-row items-center justify-end w-full duration-200 hover:scale-110 hover:font-bold hover:pr-3 mt-2">
                      <div className="md:w-[100%] w-full flex flex-row gap-1 text-hr-primary-1 justify-end">
                        <span className="header-4 text-secondary-2">
                          {company?.current_bank} -&nbsp;
                        </span>
                        <span className="header-4 text-secondary-2">
                          {companyAccountDetails?.dojah?.account_number}
                        </span>
                      </div>
                      <img
                        src={CopySVG}
                        width={15}
                        className="ml-2 hover:scale-125 duration-300"
                        alt="copy"
                      />
                    </button>
                  </CopyToClipboard>
                ) : loading ? (
                  <LoadingBar loading={loading} />
                ) : (
                  <Button
                    text={`Generate Wallet Details`}
                    type="button"
                    className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                    textClass={"!text-[11px]"}
                    onClick={() => setShowGenerateAccount(true)}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col bg-white rounded">
              <div className="flex flex-col md:flex-row md:justify-between items-center justify-center px-4 gap-[10px] my-2 mt-3">
                <div className="p3-bold header-4">Transaction History</div>
                {walletHistory ? (
                  <div className="md:w-[120px] w-full flex md:justify-end justify-center">
                    <Button
                      text={`Export`}
                      type="button"
                      loading={exportLoading}
                      className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                      textClass={"!text-[11px]"}
                      leftIcon={"export"}
                      onClick={() => exportWalletHistory()}
                    />
                  </div>
                ) : null}
              </div>
              <hr className="divider mt-2 mb-2" />
              <DataTable
                data={walletHistory}
                tableConfig={tableConfig}
                noCheck={true}
                actionConfig={actionConfig}
                filterByStatus={true}
                filterByType={true}
                typeConfig={typeConfig}
                computedActions={true}
                filterByDate={dateConfig}
                statusConfig={payrollHistoryStatusConfig}
                updatePagination={(data) => setPagination(data)}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default WalletPage;
