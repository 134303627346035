import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { employeeTrainings } from '../../../api/employees/training';

export function useGetTrainingSummary() {
    return useQuery(
      ['training-summary'],
      () => {
        return employeeTrainings.getTrainingSummary();
      }
    );
}
export function useGetemployeeTrainings(limit, page, statusFilter, search) {
    return useQuery(
      ['employee-trainings', { limit, page, statusFilter, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeeTrainings.getEmployeeTrainings({
          limit,
          page,
          statusFilter, 
          search,
        });
      },
      {
        select(data) {
          data?.data?.forEach((training) => {
              
            training.created = moment(training.created).format('MMMM D, YYYY');
            training.period = `${moment(training.training_start).format('MMMM D, YYYY')} to ${moment(training.training_end).format('MMMM D, YYYY')}`;
            training.computedActions = [0, 1, 2, 3];
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetTrainingEmployees(id, limit, page, search) {
    return useQuery(
      ['training-employees', {id,  limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeeTrainings.getEmployeeTrainingEmployees({
          id,
          limit,
          page,
          search,
        });
      },
      {
        enabled: id !== null,
        select(data) {
          data?.data?.forEach((employee) => {
            employee.employeeName = `${employee.first_name} ${employee.last_name}`;
            employee.created = moment(employee.created).format('MMMM D, YYYY');
            employee.computedActions = [0, 1];
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useAddEmployeeTraining() {
    return useMutation(
        (payload) => {
        return employeeTrainings.addEmployeeTraining(payload);
        },
        {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('employee-trainings');
            queryClient.invalidateQueries('single-training');
            queryClient.invalidateQueries('training-summary');
            store.dispatch(setAlert(true, 'success', 'Employee training added successfully'));
        },
        },
    );
}

export function useUpdateEmployeeTraining() {
    return useMutation(
        (payload) => {
        store.dispatch(setIsLoading(true));
        return employeeTrainings.updateEmployeeTraining(payload);
        },
        {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('employee-trainings');
            queryClient.invalidateQueries('single-training');
            store.dispatch(setAlert(true, 'success', 'Training updated successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        },
    );
}

export function useDeleteTraining() {
    return useMutation(
        (payload) => {
        return employeeTrainings.deleteTraining(payload);
        },
        {
        onSuccess: () => {
            queryClient.invalidateQueries('employee-trainings');
            queryClient.invalidateQueries('single-training');
            store.dispatch(setAlert(true, 'success', 'Training deleted successfully'));
        },
        },
    );
}

export function useChangeTrainingStatus() {
    return useMutation(
        (payload) => {
        store.dispatch(setIsLoading(true));
        return employeeTrainings.markTrainingComplete(payload);
        },
        {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('employee-trainings');
            queryClient.invalidateQueries('single-training');
            store.dispatch(setAlert(true, 'success', 'Training updated successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        },
    );
}

export function useGetSingleTraining(id) {
    return useQuery(
        ['single-training', { id }],
        () => {
        store.dispatch(setIsLoading(true));
        return employeeTrainings.getEmployeeTraining(id);
        },
        {
        enabled: id !== null,
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        },
    );
}

export function useMarkTrainingComplete() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return employeeTrainings.markTrainingComplete(payload);
        },
        {
            onSuccess: (response, variables, context) => {
              queryClient.invalidateQueries('employee-trainings');
              queryClient.invalidateQueries('single-training');
              store.dispatch(setAlert(true, 'success', 'Status changed successfully'));
            },
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
  }

export function useRemoveEmployeesFromTraining() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return employeeTrainings.removeEmployeesFromTraining(payload);
      },
      {
        onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('employee-trainings');
            queryClient.invalidateQueries('single-training');
            queryClient.invalidateQueries('training-employees');
            queryClient.invalidateQueries('training-summary');
            store.dispatch(setAlert(true, 'success', 'Employee removed from training successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useAddEmployeesToTraining() {
    return useMutation(
      (payload) => {
        return employeeTrainings.addEmployeesToTraining(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('employee-trainings');
          queryClient.invalidateQueries('training-summary');
          store.dispatch(setAlert(true, 'success', 'Employee added to training successfully'));
        },
      },
    );
}