import { Axios } from '../axios';

const getPIPS = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/pips?limit=${payload.limit}&page=${payload.page}${search}${filter}`,
  );
  return data?.data;
};

const getPIP = async (payload) => {
  const { data } = await Axios.get('/pips/' + payload.id);
  return data?.data;
};

const getPIPReport = async (payload) => {
  const { data } = await Axios.get(`/pips/report/${payload.start_date}/${payload.end_date}`);
  return data?.data;
};

const addPIP = async (payload) => {
  const { data } = await Axios.post('pips', payload);
  return data;
};
const editPIP = async (payload) => {
  const { data } = await Axios.put('pips/'+payload.id, payload.body);
  return data;
};

const deletePIP = async (payload) => {
  const { data } = await Axios.delete('pips/'+payload);
  return data;
};

const bulkAddPIP = async (payload) => {
  const { data } = await Axios.post(
    '/pips/bulk', 
    payload,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0
    }
  );
  return data;
};

const getSingleEmployeePIPs = async (payload) => {
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(`/pips/employee/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}`,
  );

  return data?.data;
};

const extendPIP = async (payload) => {
    const { data } = await Axios.post('pips/extend/'+payload.pip_id, payload);
    return data;
};

const confirmEmployeePIP = async (payload) => {
    const { data } = await Axios.post('pips/confirm/'+payload.pip_id, payload);
    return data;
};

const terminateEmployeePIP = async (payload) => {
    const { data } = await Axios.post('pips/terminate/'+payload.pip_id, payload);
    return data;
};

const getPIPEvaluations = async (payload) => {
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(`/pips/performance-evaluations/${payload.pip_id}?limit=${payload.limit}&page=${payload.page}${filter}`,
  );

  return data?.data;
};

const getPIPCommentsByPIP = async (payload) => {
  const { data } = await Axios.get( `/pip-comments/get-by-pip/${payload}`,);
  return data?.data;
};

const addPIPComment = async (payload) => {
  const { data } = await Axios.post('/pip-comments', payload);
  return data;
};

const updatePIPComment = async (payload) => {
  const { data } = await Axios.put('/pip-comments/' + payload.id, payload.data);
  return data;
};

const deletePIPComment = async (payload) => {
  const { data } = await Axios.delete(`/pip-comments/${payload}`,);
  return data;
};

export const pips = {
    getPIPS,
    getPIP,
    getPIPReport,
    addPIP,
    editPIP,
    deletePIP,
    bulkAddPIP,
    getSingleEmployeePIPs,
    extendPIP,
    confirmEmployeePIP,
    terminateEmployeePIP,
    getPIPEvaluations,

    getPIPCommentsByPIP,
    addPIPComment,
    updatePIPComment,
    deletePIPComment
};
