import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  useGetGroupEmployees,
  useRemoveEmployeesFromGroup,
} from "../../../redux/employees/hook/groups";
import {
  employeeStatusConfig,
  HMOstatusConfig,
  PAGINATION_DEFAULT,
} from "../../../utils/constants";
import Button from "../../button";
import DataTable from "../../datatable";
import { useSelector } from "react-redux";
import { useGetEmployees } from "../../../redux/employees/hook";
import { useGetHMOGroupEmployees } from "../../../redux/hmo/hook";
import AddEmployeeHMOGroup from "../../modal/hmo/add-hmo-group";
import AddEmployeeToHMOGroup from "../../modal/hmo/add-employee-to-group";

const HMOGroupOverviewTab = () => {
  useEffect(() => {}, []);
  const { section } = useSelector((state) => state.sectionSlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });
  const { id } = useParams();

  const { data: group_employees } = useGetHMOGroupEmployees(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { data: employees } = useGetEmployees(2, 1, 1, "");

  const [showAddEmployeeToGroup, setShowAddEmployeeToGroup] = useState(false);
  const { mutateAsync: removeFromGroup } = useRemoveEmployeesFromGroup();

  const removeEmployeesFromGroup = (id) => {
    let index = group_employees.data.findIndex((item) => item.id == id);
    let payload = {
      group: group_employees.id,
      employees: group_employees.data[index].employee,
    };
    removeFromGroup(payload);
  };

  const tableConfig = {
    headers: ["Date Added", "Employee Name", "Email", "Mobile"],
    keys: ["created", "employeeName", "email", "mobile"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "mobile",
      },
    },
  };

  const actionConfig = [
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        removeEmployeesFromGroup(id);
      },
    },
  ];

  const bulkActionConfig = [
    {
      label: "Remove",
      value: "active",
      action: (data) => {
        let newData = data.split(",");
        let result = [];

        newData.forEach((id) => {
          let index = group_employees.data.findIndex((item) => item.id == id);
          if (index != -1) {
            result.push(group_employees.data[index].employee);
          }
        });
        removeFromGroup({
          group: group_employees.id,
          employees: result.join(","),
        });
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddEmployeeToGroup ? (
        <AddEmployeeToHMOGroup
          isOpen={showAddEmployeeToGroup}
          groupData={group_employees}
          closeModal={() => setShowAddEmployeeToGroup(false)}
        />
      ) : null}

      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2">
        <div className="p3-bold header-4">Employee list</div>
        <div className="flex flex-col md:flex-row items-start gap-[15px] md:items-center">
          {employees?.data?.length > 0 ? (
            <div className="md:w-fit w-[100%]">
              <Button
                text="Add Employee To Group"
                type="submit"
                className="flex gap-2 !h-[35px] w-fit p-2 "
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => setShowAddEmployeeToGroup(true)}
              />
            </div>
          ) : null}
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={group_employees}
        tableConfig={tableConfig}
        bulkActionConfig={bulkActionConfig}
        statusConfig={HMOstatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
      />
    </div>
  );
};

export default HMOGroupOverviewTab;
