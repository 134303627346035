import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../../datatable";
import { StatutoryConfig, PAGINATION_DEFAULT } from "../../../utils/constants";

import { useGetNhfHistory } from "../../../redux/statutory-compliance/hook/nhf";

export const NhfTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: Nhf } = useGetNhfHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const history = useHistory();

  function Continue(id, type) {
    let index = Nhf.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === "pay_now") {
        history.push(`/statutory-payments/nhf/preview/${Nhf.data[index].ref}`);
      }
    }
  }

  const tableConfig = {
    headers: ["Date", "Reference Number", "Description", "Month", "Status"],
    keys: ["created", "ref", "desc", "month", "status"],
    mobileHeader: {
      left: {
        title: "Date",
        key: "created",
      },
      right: {
        title: "Description",
        key: "desc",
      },
    },
  };

  const statusConfig = [
    {
      label: "In Progress",
      color: "orange",
      value: 0,
    },
    {
      label: "Successful",
      color: "green",
      value: 1,
    },
    {
      label: "In Progress",
      color: "orange",
      value: 2,
    },
    {
      label: "Paid: Not Filed",
      color: "orange",
      value: 3,
    },
    {
      label: "Filed: Awaiting Confirmation",
      color: "orange",
      value: 4,
    },
  ];

  const actionConfig = [
    {
      name: "Preview",
      color: "orange",
      action: (id) => {
        Continue(id, "pay_now");
      },
    },
    {
      name: "View",
      color: "green",
      action: (id) => {
        Continue(id, "view");
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded-md">
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="p3-bold header-4">National Housing Fund (NHF)</div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={Nhf}
        tableConfig={tableConfig}
        statusConfig={statusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={StatutoryConfig}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default NhfTab;
