import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../components/button";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import { updateCurrentCompany } from "../../../redux/company/hook";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { useRefTax } from "../../../redux/statutory-compliance/hook/tax";
import TaxDetailedView from "../../../components/modal/statutory-compliance/tax-full-view";
import { useSelector } from "react-redux";
import StatutorySummary from "../../../components/modal/statutory-compliance/statutory-summary";
import { useGetCompanyAccountDetails } from "../../../redux/wallet/hook";

const TaxPreviewPage = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });
  const { data: companyAccountDetails } = useGetCompanyAccountDetails();

  const [showTaxSummary, setShowTaxSummary] = useState(false);
  const [showTaxDetailedView, setShowTaxDetailedView] = useState(false);
  const [viewData, setViewData] = useState(false);
  const { company } = useSelector((state) => state.companySlice);

  const { refId } = useParams();
  const { data: tax } = useRefTax(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  useEffect(() => {
    updateCurrentCompany();
  }, []);

  const tableConfig = {
    headers: ["Name", "Mobile", "Tax ID", "Tax", "Fee", "Month", "Total"],
    keys: [
      "employeeName",
      "employee_mobile",
      "employee_tax_number",
      "table_tax_amount",
      "table_fees",
      "payroll_month",
      "total",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Total",
        key: "total",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Details",
      color: "green",
      action: (id) => {
        let index = tax.data.findIndex((item) => item.id === id);
        setViewData(tax.data[index]);
        setShowTaxDetailedView(true);
      },
    },
  ];

  return (
    <DashboardLayout title="Tax Preview">
      <div className="flex flex-col">
        <div className="flex flex-col md:flex-row justify-between gap-3">
          <div className="flex flex-col md:w-2/3 w-full">
            <span className="h4 text-color-black">
              A Quick Preview Before You Submit
            </span>
            <span className="text-[14px] text-color-gray -mt-2">
              Please spend a brief moment reviewing your PAYE Tax before you
              submit for Payment
            </span>
          </div>
          <div className="flex md:justify-end justify-center md:w-1/3 w-full">
            <Button
              text="PAY AND FILE"
              type="submit"
              loading={false}
              disabled={false}
              className="h-[56px] w-full md:w-[220px]"
              onClick={() => setShowTaxSummary(true)}
            />
          </div>
        </div>

        <div className="mt-8">
          <>
            {showTaxDetailedView ? (
              <TaxDetailedView
                isOpen={showTaxDetailedView}
                closeModal={() => setShowTaxDetailedView(false)}
                viewData={viewData}
              />
            ) : null}
            <StatutorySummary
              refValue={refId}
              type={"tax"}
              isOpen={showTaxSummary}
              closeModal={() => setShowTaxSummary(false)}
              takeHome={tax?.formatted_total_tax}
              transCost={tax?.formatted_total_trans_cost}
              totalAmount={tax?.formatted_total_payable}
            />
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row gap-4">
                <div className=" flex flex-col gap-4 w-[100%] md:w-[75%] bg-white rounded-md p-3">
                  <DataTable
                    data={tax}
                    tableConfig={tableConfig}
                    actionConfig={actionConfig}
                    searchData={true}
                    updatePagination={(data) => setPagination(data)}
                    computedActions={true}
                    noCheck={true}
                  />
                </div>
                <div className="w-[100%] md:w-[25%] max-w-[480px] flex flex-col">
                  <div className=" bg-white flex flex-col w-full p-3 gap-2 rounded-md">
                    <div>
                      <div className="flex flex-row justify-between mt-2">
                        <span className="text-[13px] text-secondary-2 font-bold">
                          Company Tax ID
                        </span>
                        <span className="text-[13px]  text-color-black">
                          {company?.tax_id}
                        </span>
                      </div>
                    </div>
                    <hr className="divider" />
                    <div>
                      <div className="flex flex-row justify-between">
                        <span className="text-[13px] text-secondary-2 font-bold">
                          Total Tax
                        </span>
                        <span className="text-[13px]  text-color-black">
                          {tax?.formatted_total_tax}
                        </span>
                      </div>
                    </div>
                    <hr className="divider" />
                    <div>
                      <div className="flex flex-row justify-between">
                        <span className="text-[13px] text-secondary-2 font-bold">
                          Fees
                        </span>
                        <span className="text-[13px]  text-color-black">
                          {tax?.formatted_total_trans_cost}
                        </span>
                      </div>
                    </div>
                    <hr className="divider" />
                    <div>
                      <div className="flex flex-row justify-between">
                        <span className="text-[16px] text-secondary-2 font-extrabold">
                          Total Payable
                        </span>
                        <span className="text-[15px]  text-color-black font-bold">
                          {tax?.formatted_total_payable}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TaxPreviewPage;
