import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useDeclineVendorPayment,
  useDeleteVendorPayment,
  useGetVendorPaySummary,
} from "../../../redux/spend-management/vendor/hook";
import {
  PAGINATION_DEFAULT,
  basicStatusConfig,
} from "../../../utils/constants";
import DataTable from "../../datatable";

export const VendorPaySummaryTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: vendorPay } = useGetVendorPaySummary(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: declinePayment } = useDeclineVendorPayment();
  const { mutateAsync: deletePayment } = useDeleteVendorPayment();

  const history = useHistory();

  function Continue(id, type) {
    let index = vendorPay.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === "preview") {
        history.push(`/vendor/pay/preview/${vendorPay.data[index].ref}`);
      } else if (type === "view") {
        history.push(`/vendor/pay/view/${vendorPay.data[index].ref}`);
      } else if (type === "approve") {
        history.push(
          `/vendor/pay/preview/${vendorPay.data[index].ref}?approve=true`
        );
      } else if (type === "decline") {
        declinePayment(vendorPay.data[index].ref);
      } else if (type === "delete") {
        deletePayment(vendorPay.data[index].ref);
      }
    }
  }

  const statusConfig = [
    {
      label: "In Progress",
      color: "orange",
      value: 0,
    },
    {
      label: "Completed",
      color: "green",
      value: 1,
    },
    {
      label: "Awaiting Approval",
      color: "orange",
      value: 2,
    },
    {
      label: "Scheduled",
      color: "orange",
      value: 3,
    },
    {
      label: "Declined",
      color: "red",
      value: 4,
    },
  ];

  const tableConfig = {
    headers: [
      "Date",
      "Ref. Number",
      "Description",
      "Type",
      "Schedule Date",
      "Mode",
      "Status",
    ],
    keys: ["created", "ref", "desc", "type", "schedule_date", "mode", "status"],
    mobileHeader: {
      left: {
        title: "Date",
        key: "created",
      },
      right: {
        title: "Description",
        key: "desc",
      },
    },
  };

  const actionConfig = [
    {
      name: "Continue",
      color: "green",
      action: (id) => {
        Continue(id, "preview");
      },
    },
    {
      name: "View",
      color: "green",
      action: (id) => {
        Continue(id, "view");
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        Continue(id, "delete");
      },
    },
    {
      name: "Approve",
      color: "green",
      action: (id) => {
        Continue(id, "approve");
      },
    },
    {
      name: "Decline",
      color: "red",
      action: (id) => {
        Continue(id, "decline");
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Vendor Payment Summary
        </div>
      </div>
      <hr className="divider mt-2 mb-2" />
      <DataTable
        data={vendorPay}
        tableConfig={tableConfig}
        statusConfig={statusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={basicStatusConfig}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};
