import React from "react";
import DataTable from "../../datatable";
import { useState, useEffect } from "react";
import {
  recurringInvoiceStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../utils/constants";
import Button from "../../button";
import { useHistory } from "react-router-dom";
import {
  useDeleteRecurringInvoice,
  useGetRecurringInvoices,
} from "../../../redux/spend-management/recurring-invoices/hook";

export const RecurringInvoicesTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });
  const history = useHistory();

  const { data: invoices } = useGetRecurringInvoices(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: deleteInvoice } = useDeleteRecurringInvoice();

  const tableConfig = {
    // title: 'Bonus Management',
    headers: [
      "Date created",
      "Template name",
      "Customer name",
      "Customer Mobile",
      "Interval",
      "Status",
    ],
    keys: [
      "created",
      "template_name",
      "customer_name",
      "customer_mobile",
      "interval",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Customer Name",
        key: "customer_name",
      },
      right: {
        title: "Total Amount",
        key: "total_amount",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "orange",
      action: (id) => {
        let index = invoices.data.findIndex((item) => item.id === id);
        history.push(
          `/customers/invoice?templateId=${invoices.data[index].id}`
        );
      },
    },
    {
      name: "Duplicate",
      color: "orange",
      action: (id) => {
        let index = invoices.data.findIndex((item) => item.id === id);
        history.push(
          `/customers/invoice?templateId=${invoices.data[index].id}&duplicate=true`
        );
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = invoices.data.findIndex((item) => item.id === id);
        deleteInvoice(invoices.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded !min-h-fit">
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Recurring Invoices
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Recurring Invoice"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                history.push(`/customers/invoice?recurring=true`);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={invoices}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        statusConfig={recurringInvoiceStatusConfig}
        updatePagination={(data) => setPagination(data)}
        noCheck={true}
        computedActions={true}
      />
    </div>
  );
};
