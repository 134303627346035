import { Axios } from '../axios';

const getEmployeeTrainers = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(
      `/trainers?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
    );
  
    return data?.data;
};

const getEmployeeTrainerTrainings = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/trainers/trainings/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const getEmployeeTrainer = async (payload) => {
    const { data } = await Axios.get('/trainers/' + payload);
    return data?.data;
};

const addEmployeeTrainer = async (payload) => {
    const { data } = await Axios.post('/trainers', payload,
    {
      timeout: 0
    });
    return data;
};

const updateEmployeeTrainer = async (payload) => {
    const { data } = await Axios.put(
      '/trainers/' + payload.id,
      payload.data,
    );
    return data;
};

const changeTrainerStatus = async (payload) => {
    const { data } = await Axios.post(
      '/trainers/change-status/'+ payload.id, payload.data,
    );
    return data;
};

const deleteTrainer = async (payload) => {
    const { data } = await Axios.delete(
      '/trainers/' + payload
    );
    return data;
};

export const employeeTrainers = {
    getEmployeeTrainers,
    getEmployeeTrainerTrainings,
    getEmployeeTrainer,
    addEmployeeTrainer,
    updateEmployeeTrainer,
    changeTrainerStatus,
    deleteTrainer
  };