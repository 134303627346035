import moment from "moment";
import React, { useEffect, useState } from "react";

export const TrainingRemarks = ({ remark }) => {
  return (
    <div
      className={`min-h-[120px] flex flex-col  gap-2 justify-start bg-white rounded-[5px] border border-[#dbdce3]`}
    >
      <div className="flex flex-row justify-between items-center bg-blue-100 p-3 font-semibold text-[14px] text-color-black">
        Employee Training Comments
      </div>
      {remark && remark.length > 0 ? (
        <div className="flex flex-col pt-3 !max-h-[500px] overflow-y-auto">
          {remark?.map((item, index) => (
            <div>
              <div className="flex md:flex-row  items-end pl-8 pr-8 pb-3 justify-between gap-3">
                <div className="flex flex-col text-gray-500 p-3 border rounded-md w-full hover:bg-gray-50 duration-300">
                  <div className="flex items-center header-5 text-gray-700 pb-1">
                    Day {item.training_day} - &nbsp;{" "}
                    <span className="text-[11px] text-gray-500">
                      ({moment(item.training_date).format("MMMM DD, YYYY")})
                    </span>
                  </div>
                  <div className="text-[12px] text-gray-600">{item.remark}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center w-[100%] p-3 italic text-hr-primary-1 text-[14px]">
          <span className="text-center w-[100%] p-3 italic text-hr-primary-1 text-[14px] hover:text-[16px] duration-300 font-semibold">
            No Comment Added By Employee
          </span>
        </div>
      )}
    </div>
  );
};
