import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { probations } from '../../../api/employees/probations';

export function useGetProbations(limit, page, statusFilter, search) {
  return useQuery(
    ['probations', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return probations.getProbations({
        limit,
        page,
        statusFilter, 
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((probation) => {
          probation.created = moment(probation.created).format('MMMM D, YYYY');
          probation.formatted_start_date = moment(probation.start_date).format('MMMM D, YYYY');
          probation.formatted_end_date = moment(probation.end_date).format('MMMM D, YYYY');
          probation.employeeName = `${probation.first_name} ${probation.last_name}`;
          if (moment().isBetween(probation?.start_date, probation?.end_date) && probation.status !== 1 && probation.status !== 3) {
            probation.status = 2
          }
          switch (probation.status) {
            case 0:
              probation.computedActions = [0, 1];
              break;
            case 1:
              probation.computedActions = [0];
              break;
            case 2:
              probation.computedActions = [0, 1, 2, 3];
              break;
            case 3:
              probation.computedActions = [0];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetProbationReport(start_date, end_date) {
  return useQuery(
    ['probation-report', { start_date, end_date }],
    () => {
      return probations.getProbationReport({ start_date, end_date });
    },
    {
      select: (data) => {
        let formatted_months = []
        data?.months?.forEach(element => {
          formatted_months.push(moment(element+'-01').format('MMM YY'));
        });
        data.formatted_months = formatted_months;
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
    
  );
}

export function useGetPerformanceByProbation(
  probation_id,
  limit,
  page,
  statusFilter,
) {
  return useQuery(
    [
      'performance-by-probation',
      { probation_id, limit, page,statusFilter },
    ],
    () => {
      store.dispatch(setIsLoading(true));
      return probations.getProbationEvaluations({
        probation_id,
        limit,
        page,
        statusFilter,
      });
    },
    {
      enabled: probation_id ? true : false,
      select(data) {
        data?.data?.forEach((performance) => {
          performance.employeeName = `${performance.first_name} ${performance.last_name}`
          performance.period = `${performance.evaluation_start} to ${performance.evaluation_end}`
          performance.created = `${performance.created.split('T')[0]}`
          performance.computedActions = [0, 1, 2];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetProbation(id) {
  return useQuery(
      ['probation', { id }],
      () => {
          return probations.getProbation({ id });
      },
      {
          enabled: id !== null,
          select(data) {
            data.status = data?.status? data.status: 0
            return data;
          },
          onSettled: (data, error, variables, context) => {
              store.dispatch(setIsLoading(false));
          },
      },
  );
}

export function useAddProbation() {
  return useMutation(
    (payload) => {
      return probations.addProbation(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('probations');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useEditProbation() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return probations.editProbation(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('probations');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkAddProbation() {
  return useMutation(
    (payload) => {
      return probations.bulkAddProbation(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('probations');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useDeleteProbation() {
  return useMutation(
    (payload) => {
      return probations.deleteProbation(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('probations');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleGetEmployeeProbation(id, limit, page, statusFilter) {
  return useQuery(
    ['single-probations', { id, limit, page, statusFilter }],
    () => {
      store.dispatch(setIsLoading(true));
      return probations.getSingleEmployeeProbations({ id, limit, page, statusFilter });
    },
    {
      select(data) {
        data?.data?.forEach((probation) => {
          probation.created = moment(probation.created).format('MMMM D, YYYY');
          probation.employeeName = `${probation.first_name} ${probation.last_name}`;
          switch (probation.status) {
            case 1:
              probation.computedActions = [0];
              break;
            case 2:
              probation.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useExtendProbation() {
    return useMutation(
      (payload) => {
        return probations.extendProbation(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('probations');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useConfirmProbationEmployee() {
    return useMutation(
      (payload) => {
        return probations.confirmEmployeeProbation(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('probations');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useTerminateProbationEmployee() {
    return useMutation(
      (payload) => {
        return probations.terminateEmployeeProbation(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('probations');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useAddProbationComment() {
    return useMutation(
      (payload) => {
        return probations.addProbationComment(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('probation-comments');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useGetProbationComments(id) {
  return useQuery(
    ['probation-comments', id],
    () => {
      store.dispatch(setIsLoading(true));
      return probations.getProbationCommentsByProbation(id);
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.forEach((query) => {
          query.created = query.created.split('T').join(' ').split('.')[0]
          query.queryName = `${query.first_name} ${query.last_name}`;
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEditProbationComment() {
  return useMutation(
    (payload) => {
      return probations.updateProbationComment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('probation-comments');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteProbationComment() {
  return useMutation(
    (payload) => {
      return probations.deleteProbationComment(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('probation-comments');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

