import React, { useState } from 'react';
import Button from '../button';
import Modal from './modal';
import PeopleSwitch from "../../assets/svg/people-switch.svg"
import PayrollSwitch from "../../assets/svg/payroll-switch.svg"
import SpendManagementSwitch from "../../assets/svg/spend-management-switch.svg"
import classNames from 'classnames';
import { useSwitchSection } from '../../redux/user/hook';
import LoadingBar from '../loader-bar';
import { store } from '../../redux';
import { setAlert } from '../../redux/components/components-slice';
import TickSVG from '../../assets/svg/tick.svg';

function SwitchSectionModal({
  isOpen,
  closeModal,
  refresh,
  currentSection,
}) {

    // const pageOptions = [
  //   { value: 'payroll', label: 'Payroll Management' },
  //   { value: 'core hr', label: 'People Management' },
  //   { value: "spend management", label: 'Spend Management' },
  // ];

  const { mutateAsync: switchSection, isLoading: switchLoading } = useSwitchSection();

  const switchModule = async (section) => {
    if (section !== currentSection) {
        await switchSection(section).then(() => {
            closeModal()
        })
    } else {
        store.dispatch(
            setAlert(
              true,
              'info',
              'You are already on this module',
            ),
          );
    }
    
  }
  return (
    <Modal
      scrollable={true}
      className='min-w-[50%] !max-w-[75%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='p-4 overflow-auto'>
        <div className='flex flex-col overflow-auto justify-center'>
          <div className='flex flex-col md:mx-4 gap-3 items-center justify-center'>
            <div className='flex flex-col gap-2'>
                <span className='text-center header-2'>Switch Module</span>
                <span className='text-center text-[14px] -mt-2'>Select the module you wish to switch to</span>
            </div>
            {switchLoading?
            <LoadingBar loading={switchLoading} />
            :
            <>
                <div className='flex flex-col md:flex-row items-center md:justify-between justify-between gap-4 !min-w-full mb-2'>
                    <div 
                        className='flex flex-col justify-between relative gap-3 rounded p-2 py-3 border h-[200px] hover:bg-highlight duration-300 cursor-pointer hover:scale-105'
                        onClick={() => {
                            switchModule('payroll')
                        }}
                    >
                        <div className={classNames('flex flex-col justify-center items-center min-w-[40%]',)}>
                        <img src={PayrollSwitch} alt='' width={'120px'} height={'120px'} />
                        </div>
                        <div className='flex flex-col items-center justify-around gap-2'>
                        <span className='font-bold text-[15px] leading-[12px] text-color-gray flex flex-col text-center'>
                            <div className='md:w-fit w-[100%]' id='second-step'>
                            <Button
                                text='Payroll Management'
                                type='button'
                                theme='primary'
                                className='flex gap-2 h-[35px] px-2 w-fit min-w-[170px] !bg-secondary-2'
                                textClass={'!text-[11px]'}
                                
                            />
                            </div>
                        </span>
                        </div>
                        <div  
                            className={
                            classNames('hidden absolute top-2 left-0 w-full h-full text-center text-[13px] font-semibold -mt-2 bg-secondary-2 z-50 rounded-md bg-opacity-40 duration-300 cursor-default',
                            { '!flex items-center justify-center': currentSection == 'payroll' },
                            )}
                        >
                            <img
                            src={TickSVG}
                            alt='tick'
                            style={{ width: '60px', height: '60px' }}
                            />
                        </div>
                    </div>
                    <div 
                        className='flex flex-col justify-between relative gap-3 rounded p-2 py-3 border h-[200px] hover:bg-highlight duration-300 cursor-pointer hover:scale-105'
                        onClick={() => {
                            switchModule('core hr')
                        }}
                    >
                        <div className={classNames( 'flex flex-col justify-center items-center min-w-[40%]',)}>
                            <img src={PeopleSwitch} alt='' width={'120px'} height={'120px'} />
                        </div>
                        <div className='flex flex-col items-center justify-around gap-2'>
                            <span className='font-bold text-[15px] leading-[12px] text-color-gray flex flex-col text-center'>
                                <div className='md:w-fit w-[100%]' id='second-step'>
                                <Button
                                    text='People Management'
                                    type='button'
                                    theme='primary'
                                    className='flex gap-2 h-[35px] px-2 w-fit min-w-[170px] p-2 !bg-hr-primary-1'
                                    textClass={'!text-[11px]'}
                                    onClick={() => {
                                        
                                    }}
                                />
                                </div>
                            </span>
                        </div>
                        <div  
                            className={
                            classNames('hidden absolute top-2 left-0 w-full h-full text-center text-[13px] font-semibold -mt-2 bg-hr-primary-1 z-50 rounded-md bg-opacity-40 duration-300 cursor-default',
                            { '!flex items-center justify-center': currentSection == 'core hr' },
                            )}
                        >
                            <img
                            src={TickSVG}
                            alt='tick'
                            style={{ width: '60px', height: '60px' }}
                            />
                        </div>
                    </div>
                    <div 
                        className='flex flex-col justify-between relative gap-3 rounded p-2 py-3 border h-[200px] hover:bg-highlight duration-300 cursor-pointer hover:scale-105'
                        onClick={() => {
                            switchModule('spend management')
                        }}
                    >
                        <div className={classNames( 'flex flex-col justify-center items-center min-w-[40%]',)}>
                        <img src={SpendManagementSwitch} alt='' width={'120px'} height={'120px'} />
                        </div>
                        <div className='flex flex-col items-center justify-around gap-2'>
                            <span className='font-bold text-[15px] leading-[12px] text-color-gray flex flex-col text-center'>
                                <div className='md:w-fit w-[100%]' id='second-step'>
                                <Button
                                    text='Spend Management'
                                    type='button'
                                    theme='primary'
                                    className='flex gap-2 h-[35px] w-fit px-2 min-w-[170px] !bg-spend-primary-1'
                                    textClass={'!text-[11px]'}
                                    onClick={() => {
                                        if (path == '/add-company') {
                                            setShowAddCompany(true)
                                        } else {
                                            history.push(path);
                                        }
                                    }}
                                />
                                </div>
                            </span>
                        </div>
                        <div  
                            className={
                            classNames('hidden absolute top-2 left-0 w-full h-full text-center text-[13px] font-semibold -mt-2 bg-spend-primary-1 z-50 rounded-md bg-opacity-40 duration-300 cursor-default',
                            { '!flex items-center justify-center': currentSection == 'spend management' },
                            )}
                        >
                            <img
                            src={TickSVG}
                            alt='tick'
                            style={{ width: '60px', height: '60px' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex flex-row justify-end w-full gap-4'>
                    <Button
                        text={'Close'}
                        type='button'
                        theme={'primary'}
                        loading={switchLoading}
                        className='flex gap-2 !h-[35px] w-fit p-2 !bg-red-500'
                        textClass={'!text-[12px] text-gray-500'}
                        onClick={() => closeModal()}
                    />
                </div>
            </>
            }
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SwitchSectionModal;
