import { Axios } from '../axios';

const getEmployeeSalaryAdvance = async (payload) => {

  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(
    `/salary-advance?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const getSalaryAdvanceReport = async (payload) => {
  const { data } = await Axios.get(`/salary-advance-report`);
  return data?.data;
};

const addSalaryAdvance = async (payload) => {
  const { data } = await Axios.post('/salary-advance', payload);
  return data;
};

const addSalaryAdvanceApplication = async (payload) => {
  const { data } = await Axios.post('/salary-advances-applications-store', payload);
  return data;
};

const editSalaryAdvance = async (payload) => {
  const { data } = await Axios.put('/salary-advance/'+payload.id, payload.body);
  return data;
};

const bulkAddSalaryAdvance = async (payload) => {
  const { data } = await Axios.post('/salary-advance/bulk', payload, 
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};
const deleteSalaryAdvance = async (payload) => {
  const { data } = await Axios.delete('salary-advance/'+payload);
  return data;
};

const getSingleEmployeeSalaryAdvances = async (payload) => {
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(
    `/salary-advance/employee/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}`,
  );
  return data?.data;
};

const getSalaryAdvanceApplications = async (payload) => {

  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  const { data } = await Axios.get(
    `/salary-advances/applications?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const getSingleEmployeeSalaryAdvanceApplications = async (payload) => {
  const { data } = await Axios.get(
    `/salary-advances/applications/by-employee/${payload.id}?limit=${payload.limit}&page=${payload.page}`,
  );
  return data?.data;
};

const approveSalaryAdvance = async (payload) => {
  const { data } = await Axios.get(`/salary-advances/applications/approve/${payload}`,  );
  return data;
};

const disburseApplication = async (payload) => {
  const { data } = await Axios.post(`/salary-advances/applications/disburse`, payload,
  {
    timeout: 0
  });
  return data;
};

const approvePaySalaryAdvance = async (payload) => {
  const { data } = await Axios.post(`/salary-advances/applications/approve_pay`, payload );
  return data;
};

const declineSalaryAdvanceApplication = async (payload) => {
  const { data } = await Axios.get('/salary-advances/applications/decline/'+payload);
  return data;
};

const declineApplicationDisbursement = async (payload) => {
  const { data } = await Axios.get('/salary-advances/applications/decline-payment/'+payload);
  return data;
};

const getSalaryAdvanceFullReport = async (payload) => {
  const { data } = await Axios.get(`/salary-advances/full-report/${payload.start_date}/${payload.end_date}`);
  return data?.data;
};

export const employeeSalaryAdvance = {
  getEmployeeSalaryAdvance,
  getSalaryAdvanceReport,
  getSalaryAdvanceFullReport,
  addSalaryAdvance,
  addSalaryAdvanceApplication,
  bulkAddSalaryAdvance,
  getSingleEmployeeSalaryAdvances,
  editSalaryAdvance,
  deleteSalaryAdvance,
  getSalaryAdvanceApplications,
  getSingleEmployeeSalaryAdvanceApplications,
  approveSalaryAdvance,
  disburseApplication,
  approvePaySalaryAdvance,
  declineSalaryAdvanceApplication,
  declineApplicationDisbursement
};
