import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  useAddSuspension,
  useEditSuspension,
} from '../../../redux/employees/hook/suspensions';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormSelectEmployee from '../../employees/form-select-employee';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import Modal from '../modal';
import moment from 'moment';

function AddSuspension({
  isOpen,
  closeModal,
  refresh,
  suspData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [payment_status, setPaymentStatus] = useState(2);

  const { mutateAsync: addSuspension, isLoading: addSuspensionloading } =
    useAddSuspension();
  const { mutateAsync: editSuspension, isLoading: editSuspensionloading } =
    useEditSuspension();
  const [employee, setEmployee] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [purposeEdit, setPurposeEdit] = useState();
  const [suspId, setSuspId] = useState();
  const [suspType, setSuspType] = useState();

  useEffect(() => {
    if (suspData) {
      setStartDate(suspData.start_date);
      setEndDate(suspData.end_date);
      setPurposeEdit(suspData.purpose);
      setEmployee(suspData.employee);
      setEmployeeName(suspData.first_name + ' ' + suspData.last_name);
      setSuspId(suspData.id);
      setPaymentStatus(suspData.payment_status);
      setSuspType('edit');
    } else {
      setSuspType('add');
    }
  }, [suspData]);

  const submitForm = async (data) => {

    if (suspType === 'add') {
      let payload = {
        employee,
        purpose: purposeEdit,
        start_date: startDate,
        end_date: endDate,
        payment_status,
      };
      await addSuspension(payload).then(() => {
        closeModal();
      });
    } else if (suspType === 'edit') {
      let editPayload = {
        id: suspId,
        body: {
          employee: employee,
          purpose: purposeEdit,
          start_date: startDate,
          end_date: endDate,
          payment_status,
        },
      };
      await editSuspension(editPayload).then(() => {
        closeModal();
      });
    }
  };

  const paymentOptions = [
    {
      value: 1,
      label: 'With Payment',
    },
    {
      value: 2,
      label: 'Without Payment',
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='flex flex-row justify-between pl-8 pr-8 pt-4 items-center'>
            <span className='header-4'>{title}</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col mx-8'>
            <div>
              <div className='border bg-highlight p-2 rounded-md mb-2'>
                <FormSelectEmployee
                  label={'Employee'}
                  name='employee'
                  placeholder='Type to Select Employee'
                  value={employee}
                  readOnly={suspType === 'edit' ? true : false}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
              <hr className='divider mb-2 mt-2' />
              <FormInput
                label='Purpose'
                name='purpose'
                type='text'
                placeholder='Enter suspension reason'
                inputRef={register(formValidation('text', true))}
                readOnly={addSuspensionloading || editSuspensionloading}
                error={errors.purpose}
                errorMessage={errors.purpose && errors.purpose.message}
                value={purposeEdit}
                onInput={(e) => {
                  setPurposeEdit(e.target.value);
                }}
              />

              <div className='flex flex-row md:flex-col w-full justify-between z-[1] h-full'>
                <div className='w-full md:w-1/2 md:pr-2'>
                  <FormInput
                    label='Start Date'
                    name='start_date'
                    type='date'
                    value={startDate}
                    onInput={(e) => {
                      if (moment(endDate).isBefore(e.target.value)) {
                        setEndDate(moment(e.target.value, 'YYYY-MM-DD').format('YYYY-MM-DD'))
                      }
                      setStartDate(e.target.value);
                    }}
                    inputRef={register(formValidation('text', true))}
                    readOnly={addSuspensionloading || editSuspensionloading}
                    error={errors.start_date}
                    errorMessage={
                      errors.start_date && errors.start_date.message
                    }
                  />
                </div>

                <div className='w-full md:w-1/2 md:pl-2'>
                  <FormInput
                    label='End Date'
                    name='end_date'
                    type='date'
                    value={endDate}
                    min={moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                    onInput={(e) => {
                      setEndDate(e.target.value);
                    }}
                    inputRef={register(formValidation('text', true))}
                    readOnly={addSuspensionloading || editSuspensionloading}
                    error={errors.end_date}
                    errorMessage={errors.end_date && errors.end_date.message}
                  />
                </div>
              </div>

              <FormSelect
                value={payment_status}
                options={paymentOptions}
                onChange={(selected) => {
                  setPaymentStatus(selected);
                }}
                label='Payment Status'
              />
            </div>
            <div className='w-full mt-3'>
              <Button
                text={buttonText}
                type='submit'
                disabled={!isValid || !employee}
                loading={addSuspensionloading || editSuspensionloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddSuspension;
