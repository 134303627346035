import React, { useEffect, useRef, useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import DragSVG from '../../../../assets/svg/reorder.svg';
import RemoveSVG from '../../../../assets/svg/remove.svg';
import { useEditRecruitmentStages } from '../../../../redux/human-resources/hook/recruitment';
import { useParams } from 'react-router-dom';
import Modal from '../../modal';

function UpdateRecruitmentStages({
  isOpen,
  closeModal,
  stagesData,
  title,
  buttonText,
}) {
  const [subWorkflow, setSubWorkflow] = useState([]);

  useEffect(() => {
    if (stagesData) {
      setSubWorkflow(stagesData.sub_workflow);
      setWorkflowId(stagesData[0].workflow_id);
      setSubWorkflow(stagesData);
    }
  }, [stagesData]);

  const [remove, setRemove] = useState([]);

  const workflowTypes = [
    { label: 'Stage Type', value: '' },
    { label: 'Application', value: 'application' },
    { label: 'Interview', value: 'interview' },
    { label: 'Negotiation', value: 'negotiation' },
    { label: 'Task', value: 'task' },
    { label: 'Job Offer', value: 'acceptance' },
  ];

  const ApplicationTypes = [{ label: 'Application', value: 'application' }];

  const [workflowId, setWorkflowId] = useState(null);

  const { mutateAsync: updateStage, isLoading: updateStageLoading } =
    useEditRecruitmentStages();

  const { id } = useParams();

  const dragItem = useRef();

  const dragOverItem = useRef();
  const [dragPosition, setDragPosition] = useState(null);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    setDragPosition(position);
  };

  const addStage = () => {
    setSubWorkflow([
      ...subWorkflow,
      ...[
        {
          name: '',
          company_id: null,
          workflow_id: null,
          id: null,
          type: 'interview',
        },
      ],
    ]);
  };

  const removeStage = (index) => {
    if (subWorkflow[index].id !== null) {
      let removeData = remove;
      removeData.push(subWorkflow[index].id);
      setRemove(removeData);
    }
    let data = [...subWorkflow];
    if (index > -1) {
      // only splice array when item is found
      data.splice(index, 1); // 2nd parameter means remove one item only
      setSubWorkflow([...data]);
    }
  };

  const drop = (e) => {
    const copyListItems = [...subWorkflow];
    if (dragOverItem.current !== 0) {
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);

      setSubWorkflow(copyListItems);
    }
    dragItem.current = null;
    dragOverItem.current = null;
    setDragPosition(null);
  };

  const submitForm = async () => {
    let payload = {
      workflow_id: workflowId,
      recruitment_id: id,
      stages: subWorkflow,
    };
    await updateStage(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-6'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
            <span className='p3 text-[12px] text-gray-500'>
              Add Recruitment Stages. Drag and Drop to change order.
            </span>
          </div>
          <hr className='divider' />
          <div className='flex flex-col max-h-fit mx-8'>
            <div>
              {subWorkflow &&
                subWorkflow?.map((item, index) => (
                  <>
                    {dragPosition &&
                    dragItem.current &&
                    dragOverItem.current &&
                    dragItem.current !== dragOverItem.current &&
                    dragPosition === index ? (
                      <div className='opacity-30 duration-300'>
                        <hr className='divider -mt-1' />
                        <div
                          className='w-[100%] flex md:flex-row justify-between items-center -mt-3 pr-2 pl-2 text-[13px]'
                          key={index}
                        >
                          <div className='flex flex-row items-center !w-[60%]'>
                            {index !== 0 ? (
                              <img
                                src={DragSVG}
                                alt={'Refresh'}
                                className={
                                  'h-[18px] w-[22px] mr-3 cursor-grabbing'
                                }
                              />
                            ) : (
                              <div className='text-[25px] pr-2 pt-1 text-red-500'>
                                *
                              </div>
                            )}
                            <FormInput
                              name={dragItem.current + 'stage_name'}
                              type='text'
                              value={subWorkflow[dragItem.current].name}
                              className='!h-[30px] md:w-[250px] w-[150px] text-[13px] border-1 border-gray-300'
                            />
                          </div>
                          <div className='flex flex-row justify-end items-center !w-[50%] -mt-2'>
                            <FormSelect
                              value={subWorkflow[dragItem.current].type}
                              options={workflowTypes}
                              name={subWorkflow[dragItem.current].name}
                              className='!min-h-30px border-1 mt-1 w-[100px] rounded text-[11px] p-1 mr-2'
                            />
                            {index !== 0 ? (
                              <img
                                src={RemoveSVG}
                                alt={'Refresh'}
                                className={
                                  'h-[20px] w-[15px] pt-1 cursor-pointer'
                                }
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {index !== 0 ? <hr className='divider -mt-1' /> : null}
                    <div
                      className='w-[100%] flex md:flex-row justify-between items-center -mt-3 pr-2 pl-2 text-[13px]'
                      key={index}
                      onDragStart={(e) => dragStart(e, index)}
                      onDragEnter={(e) => dragEnter(e, index)}
                      onDragEnd={drop}
                      draggable={index !== 0 ? true : false}
                    >
                      <div className='flex flex-row items-center !w-[60%]'>
                        {index !== 0 ? (
                          <img
                            src={DragSVG}
                            alt={'Refresh'}
                            className={'h-[18px] w-[22px] mr-3 cursor-grabbing'}
                          />
                        ) : (
                          <div className='text-[25px] pr-2 pt-1 text-red-500'>
                            *
                          </div>
                        )}

                        <FormInput
                          name={index + '_stage_name'}
                          placeholder={'Stage Name'}
                          type='text'
                          //   inputRef={register(formValidation('text', true, 0))}
                          readOnly={index === 0 ? true : false}
                          value={item.name}
                          onInput={(e) => {
                            let newArray = JSON.parse(
                              JSON.stringify(subWorkflow),
                            );

                            newArray[index].name = e.target.value;
                            setSubWorkflow(newArray);
                          }}
                          className='!h-[30px] md:w-[250px] w-[150px] text-[13px] border-1 border-gray-300'
                        />
                        {index === 0 ? (
                          <div className='italic text-red-500 text-[12px] pl-1'>
                            ReadOnly
                          </div>
                        ) : null}
                      </div>
                      <div className='flex flex-row justify-end items-center !w-[50%] -mt-2'>
                        <FormSelect
                          value={item.type}
                          options={
                            index === 0 ? ApplicationTypes : workflowTypes
                          }
                          name={item.name}
                          readOnly={index === 0 ? true : false}
                          onChange={(selected) => {
                            if (index !== 0) {
                              let newArray = JSON.parse(
                                JSON.stringify(subWorkflow),
                              );

                              newArray[index].type = selected;
                              setSubWorkflow(newArray);
                            }
                          }}
                          className='!min-h-30px border-1 mt-1 w-[100px] rounded text-[11px] p-1 mr-2'
                        />
                        {index !== 0 ? (
                          <img
                            src={RemoveSVG}
                            alt={'Refresh'}
                            className={'h-[20px] w-[15px] pt-1 cursor-pointer'}
                            onClick={() => {
                              removeStage(index);
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </>
                ))}
              <hr className='divider -mt-1' />
              <div className='flex justify-left w-full mb-3'>
                <span
                  className='p2-medium cursor-pointer hover:underline text-color-black duration-75 italic text-[13px] text-[#0d5225]'
                  onClick={() => addStage()}
                >
                  Add New Stage
                </span>
              </div>
            </div>
            <div className='w-full'>
              <Button
                text={buttonText}
                theme={'third'}
                type='submit'
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
                loading={updateStageLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default UpdateRecruitmentStages;
