import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import {
  vendorStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import {
  useBulkActivateOrDeactivateVendors,
  useGetVendors,
} from "../../../redux/spend-management/vendor/hook";
import AddVendor from "../../modal/spend-management/vendor/add-vendor";
import Button from "../../button";
import { useSelector } from "react-redux";
import SelectVendorOption from "../../modal/spend-management/vendor/select-vendor-option";
import SelectVendorPayOption from "../../modal/spend-management/vendor/select-vendor-pay-option";
import PayVendor from "../../modal/spend-management/vendor/pay-single-vendor";

const VendorOverviewTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: vendors } = useGetVendors(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { user } = useSelector((state) => state.userSlice);

  const { mutateAsync: ActivateOrDeactivate } =
    useBulkActivateOrDeactivateVendors();

  const [showSelectVendorPayOption, setShowSelectVendorPayOption] =
    useState(false);
  const [showSelectAddVendorOption, setShowSelectAddVendorOption] =
    useState(false);
  const [showPayVendor, setShowPayVendor] = useState(false);

  const [showAddVendor, setShowAddVendor] = useState(false);
  const [vendorData, setVendorData] = useState({});

  const history = useHistory();

  const tableConfig = {
    headers: [
      "Date Created",
      "Vendor Name",
      "Contact Person",
      "Email",
      "Mobile Number",
      "Status",
    ],
    keys: [
      "created",
      "vendor_name",
      "contact_person",
      "email",
      "mobile",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Vendor Name",
        key: "vendor_name",
      },
      right: {
        title: "Status",
        key: "status",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = vendors.data.findIndex((item) => item.id === id);
        history.push(`/vendors/view/${vendors.data[index].id}`);
      },
    },
    {
      name: "Edit",
      color: "orange",
      action: (id) => {
        let index = vendors.data.findIndex((item) => item.id === id);
        setVendorData(vendors.data[index]);
        setShowAddVendor(true);
      },
    },
    {
      name: "Pay",
      color: "green",
      action: (id) => {
        let index = vendors.data.findIndex((item) => item.id === id);
        setVendorData(vendors.data[index]);
        setShowPayVendor(true);
      },
    },
    {
      name: "Activate",
      color: "green",
      action: (id) => {
        let index = vendors.data.findIndex((item) => item.id === id);
        ActivateOrDeactivate({ type: "activate", id: vendors.data[index].id });
      },
    },
    {
      name: "Deactivate",
      color: "red",
      action: (id) => {
        let index = vendors.data.findIndex((item) => item.id === id);
        ActivateOrDeactivate({
          type: "deactivate",
          id: vendors.data[index].id,
        });
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddVendor ? (
        <AddVendor
          isOpen={showAddVendor}
          closeModal={() => {
            setShowAddVendor(false);
          }}
          completed={() => {
            setShowAddVendor(false);
          }}
          vendorData={vendorData}
          title="Edit Vendor"
          buttonText="UPDATE"
        />
      ) : null}
      {showSelectAddVendorOption ? (
        <SelectVendorOption
          isOpen={showSelectAddVendorOption}
          closeModal={() => setShowSelectAddVendorOption(false)}
        />
      ) : null}
      {showSelectVendorPayOption ? (
        <SelectVendorPayOption
          isOpen={showSelectVendorPayOption}
          closeModal={() => setShowSelectVendorPayOption(false)}
        />
      ) : null}
      {showPayVendor ? (
        <PayVendor
          isOpen={showPayVendor}
          closeModal={() => setShowPayVendor(false)}
          completed={() => setShowPayVendor(false)}
          vendorData={vendorData}
        />
      ) : null}

      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Vendors
        </div>
        {user?.permissions?.vendor_permission?.payment_write === 1 ||
        user?.permissions?.company_permission?.role === "Owner" ? (
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="PAY VENDOR(s)"
                type="submit"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"purchase"}
                onClick={() => setShowSelectVendorPayOption(true)}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text={"New Vendor"}
                type="submit"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => setShowSelectAddVendorOption(true)}
              />
            </div>
          </div>
        ) : null}
      </div>
      <hr className="divider mt-2 mb-2" />

      <DataTable
        data={vendors}
        tableConfig={tableConfig}
        statusConfig={vendorStatusConfig}
        actionConfig={actionConfig}
        // bulkActionConfig={bulkActionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        computedActions={true}
        searchData={true}
      />
    </div>
  );
};

export default VendorOverviewTab;
