import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import ButtonSelect from "../../../components/button-select";
import SalaryReviewLayout from "../../../components/layouts/salary-review";
import { useRouteQuery } from "../../../hooks/useRouteQuery";

function ReviewSalaryInfo() {
  useEffect(() => {}, []);

  const history = useHistory();
  const [salaryInfoOption, setSalaryInfoOption] = useState("net-pay");
  const [createType, setCreateType] = useState("single");
  const [fromSalary, setFromSalary] = useState(false);
  const [promotionId, setPromotionId] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(null);

  const query = useRouteQuery();

  useEffect(() => {
    if (query) {
      setCreateType(query.get("type"));
      setFromSalary(query.get("salary"));
      setPromotionId(query.get("promotionId"));
      setEmployee(query.get("employeeId"));
      setEmployeeName(query.get("employeeName"));
      setEffectiveDate(query.get("effectiveDate"));
    }
  }, [query]);

  const submit = () => {
    if (promotionId) {
      if (salaryInfoOption === "net-pay" && createType === "single") {
        let route = `/employees/review/review-single-salary-information/?type=net-pay${
          fromSalary
            ? `&salary=true&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
            : `&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
        }`;
        history.push(route);
      } else if (salaryInfoOption === "net-pay" && createType === "bulk") {
        let route = `/employees/review/review-bulk-salary-information/?type=net-pay${
          fromSalary
            ? `&salary=true&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
            : `&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
        }`;
        history.push(route);
      } else if (salaryInfoOption === "gross-pay" && createType === "single") {
        let route = `/employees/review/review-single-salary-information/?type=gross-pay${
          fromSalary
            ? `&salary=true&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
            : `&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
        }`;
        history.push(route);
      } else if (salaryInfoOption === "gross-pay" && createType === "bulk") {
        let route = `/employees/review/review-bulk-salary-information/?type=gross-pay${
          fromSalary
            ? `&salary=true&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
            : `&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
        }`;
        history.push(route);
      } else if (salaryInfoOption === "fill-info" && createType === "single") {
        let route = `/employees/review/complete-single-salary-information${
          fromSalary
            ? `&salary=true&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
            : `&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
        }`;
        history.push(route);
      } else if (salaryInfoOption === "fill-info" && createType === "bulk") {
        let route = `/employees/review/review-bulk-salary-information/?type=fill-info${
          fromSalary
            ? `&salary=true&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
            : `&promotionId=${promotionId}&employeeId=${employee}&employeeName=${employeeName}&effectiveDate=${effectiveDate}`
        }`;
        history.push(route);
      }
    } else {
      if (salaryInfoOption === "net-pay" && createType === "single") {
        let route = `/employees/review/review-single-salary-information/?type=net-pay${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "net-pay" && createType === "bulk") {
        let route = `/employees/review/review-bulk-salary-information/?type=net-pay${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "gross-pay" && createType === "single") {
        let route = `/employees/review/review-single-salary-information/?type=gross-pay${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "gross-pay" && createType === "bulk") {
        let route = `/employees/review/review-bulk-salary-information/?type=gross-pay${
          fromSalary ? "&salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "fill-info" && createType === "single") {
        let route = `/employees/review/complete-single-salary-information${
          fromSalary ? "/?salary=true" : ""
        }`;
        history.push(route);
      } else if (salaryInfoOption === "fill-info" && createType === "bulk") {
        let route = `/employees/review/review-bulk-salary-information/?type=fill-info${
          fromSalary ? "/?salary=true" : ""
        }`;
        history.push(route);
      }
    }
  };

  return (
    <SalaryReviewLayout step={1} title="Review Employee Compensation">
      <div className="flex flex-col justify-center h-full min-h-[480px]">
        <ButtonSelect
          title={"I know my employee Monthly take home (Net pay) only"}
          subtitle={"Help me break it down"}
          isActive={salaryInfoOption === "net-pay"}
          onClick={() => setSalaryInfoOption("net-pay")}
        />

        <ButtonSelect
          title={"I know the Gross pay only"}
          subtitle={"Help me break it down"}
          isActive={salaryInfoOption === "gross-pay"}
          onClick={() => setSalaryInfoOption("gross-pay")}
          className="mt-8"
        />

        <ButtonSelect
          title={"I know the complete salary information"}
          subtitle={"I have all the necessary salary details for my employee"}
          isActive={salaryInfoOption === "fill-info"}
          onClick={() => setSalaryInfoOption("fill-info")}
          className="mt-8"
        />

        <div className="w-full mt-[20px]">
          <Button text="Next" type="submit" onClick={() => submit()} />
        </div>
        {/* {fromSalary
        ?
        <div
          className='flex justify-center w-full cursor-pointer'
          onClick={() => history.goBack()}
        >
          <span className='p2-medium text-color-black mt-3'>Go Back</span>
        </div>
        :
        null
        } */}
      </div>
    </SalaryReviewLayout>
  );
}

export default ReviewSalaryInfo;
