import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import TabView from "../../../components/tabview";
import { StatusText } from "../../../components/fragments/status-text";
import { vendorStatusConfig } from "../../../utils/constants";
import UnauthorizedPage from "../../errors/unauthorized";
import { Initials } from "../../../utils/functions";
import { useGetVendor } from "../../../redux/spend-management/vendor/hook";
import { useSelector } from "react-redux";
import { VendorPurchaseOrderTab } from "../../../components/spend-management-section/vendors/view-vendor/purchase-orders";
import { VendorInvoicesReceivedTab } from "../../../components/spend-management-section/vendors/view-vendor/invoices-received";
import VendorTransactionHistoryTab from "../../../components/spend-management-section/vendors/view-vendor/transaction-history";
import VendorProfileTab from "../../../components/spend-management-section/vendors/view-vendor/vendor-profile";

const ViewVendorPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.vendor_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const { data: vendor } = useGetVendor(id);

  //Employee View Tabs
  const vendorTabs = [
    {
      title: "Vendor information",
      Content:
        user.permissions.vendor_permission.vendor_read === 1 ||
        user.permissions.company_permission.role === "Owner"
          ? VendorProfileTab
          : UnauthorizedPage,
    },
    {
      title: "Purchase Orders",
      Content:
        user.permissions.vendor_permission.purchase_order_read === 1 ||
        user.permissions.company_permission.role === "Owner"
          ? VendorPurchaseOrderTab
          : UnauthorizedPage,
    },
    {
      title: "Invoices Received",
      Content:
        user.permissions.vendor_permission.invoice_received_read === 1 ||
        user.permissions.company_permission.role === "Owner"
          ? VendorInvoicesReceivedTab
          : UnauthorizedPage,
    },
    {
      title: "Payment History",
      Content:
        user.permissions.vendor_permission.history_read === 1 ||
        user.permissions.company_permission.role === "Owner"
          ? VendorTransactionHistoryTab
          : UnauthorizedPage,
    },
  ];

  return (
    <DashboardLayout title="View Vendor">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex flex-row items-center box-shadow h-[104px]">
              <div className="flex flex-row px-4 justify-between  w-full">
                <div className="flex flex-row items-center justify-between w-full">
                  <div className="flex flex-row items-center">
                    <div className="text-[18px] flex font-bold justify-center gap-4 items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200 relative hover:scale-150 hover:m-2 duration-500">
                      {Initials(vendor?.vendor_name)}
                    </div>
                    <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                      <span className="p1-bold">
                        {vendor?.vendor_name}{" "}
                        <span className="p4 text-color-gray">
                          ({vendor?.contact_person})
                        </span>
                      </span>
                      <span className="p4 text-color-gray">
                        {vendor?.email}
                      </span>
                      <span className="p4 text-color-gray">
                        {vendor?.mobile}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <StatusText
                      statusConfig={vendorStatusConfig}
                      code={vendor?.status}
                      className={
                        "py-2 rounded p1-bold px-3 p2-bold text-[15px]"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              {vendorTabs ? (
                <TabView
                  Tabs={vendorTabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default ViewVendorPage;
