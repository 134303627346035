import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import {
  useAddRecruitmentEmailTemplate,
  useEditRecruitmentEmailTemplate,
} from '../../../../redux/email-templates/hook/recruitment';
import Modal from '../../modal';
import { Editor } from '@tinymce/tinymce-react';

function AddRecruitmentEmailTemplate({
  isOpen,
  closeModal,
  refresh,
  templateData,
  title,
  buttonText,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (templateData) {
      setTemplateName(templateData.template_name);
      setSubject(templateData.subject);
      setBody(templateData.body);
      setSalutation(templateData.salutation);
      setClosingRemark(templateData.closing_remark);
      setTemplateId(templateData.id);
      setTemplateType('edit');
    } else {
      setTemplateType('add');
    }
  }, [templateData]);

  const [templateType, setTemplateType] = useState('add');
  const [templateId, setTemplateId] = useState(null);

  const [templateName, setTemplateName] = useState();
  const [subject, setSubject] = useState();
  const [body, setBody] = useState();
  const [closingRemark, setClosingRemark] = useState('Regards,');

  const [nameType, setNameType] = useState('first_name');
  const [salutation, setSalutation] = useState('Hello ');

  const { mutateAsync: addTemplate, isLoading: addTemplateLoading } =
    useAddRecruitmentEmailTemplate();
  const { mutateAsync: updateTemplate, isLoading: editTemplateLoading } =
    useEditRecruitmentEmailTemplate();

  const salutationOptions = [
    {
      label: 'Hello',
      value: 'Hello',
    },
    {
      label: 'Hi',
      value: 'Hi',
    },
    {
      label: 'Hey',
      value: 'Hey',
    },
    {
      label: 'Dear',
      value: 'Dear',
    },
    {
      label: 'Dear Mr.',
      value: 'Dear Mr.',
    },
    {
      label: 'Dear Mrs.',
      value: 'Dear Mrs.',
    },
    {
      label: 'Good day',
      value: 'Good day',
    },
  ];

  const nameTypeOptions = [
    { label: 'First Name', value: 'first_name' },
    { label: 'Last Name', value: 'last_name' },
    { label: 'Full Name', value: 'full_name' },
  ];

  const closingRemarkOptions = [
    {
      label: 'Kind regards',
      value: 'Kind regards,',
    },
    {
      label: 'Thank you',
      value: 'Thank you,',
    },
    {
      label: 'Looking forward to meeting you',
      value: 'Looking forward to meeting you,',
    },
    {
      label: 'Best',
      value: 'Best,',
    },
    {
      label: 'Sincerely',
      value: 'Sincerely,',
    },
    {
      label: 'Regards',
      value: 'Regards,',
    },
    {
      label: 'Best Regards',
      value: 'Best Regards,',
    },
    {
      label: 'Cheers',
      value: 'Cheers,',
    },
  ];

  const submitForm = async (data) => {
    let payload = {
      salutation: salutation,
      subject,
      template_name: templateName,
      body: body.toString(),
      name_type: nameType,
      closing_remark: closingRemark,
    };

    if (templateType === 'add') {
      await addTemplate(payload).then(() => {
        closeModal();
      });
    } else {
      let editPayload = {
        id: templateId,
        data: payload,
      };
      await updateTemplate(editPayload).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col pb-4 overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>{title}</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col mx-8'>
          <form className='form' onSubmit={handleSubmit(submitForm)}>
            <FormInput
              label='Template Name'
              value={templateName}
              name='template_name'
              placeholder={'Template Name'}
              onInput={(e) => {
                setTemplateName(e.target.value);
              }}
              type='text'
              error={errors.template_name}
              errorMessage={
                errors.template_name && errors.template_name.message
              }
            />
            <FormInput
              label='Subject'
              name='subject'
              type='text'
              value={subject}
              placeholder={'Template Subject'}
              onInput={(e) => {
                setSubject(e.target.value);
              }}
              error={errors.subject}
              errorMessage={errors.subject && errors.subject.message}
            />
            <div className='flex md:flex-row flex-col w-full justify-between mt-2'>
              <div className='w-full md:w-1/2 md:pr-2'>
                <FormSelect
                  value={salutation}
                  options={salutationOptions}
                  onChange={(selected) => {
                    setSalutation(selected);
                  }}
                  label='salutation'
                />
              </div>
              <div className='w-full md:w-1/2 pl-2'>
                <FormSelect
                  defaultValue={nameType}
                  options={nameTypeOptions}
                  onChange={(selected) => {
                    setNameType(selected);
                  }}
                  label='Applicant Name Type'
                />
              </div>
            </div>
            <div className='flex md:flex-row flex-col w-full justify-between mb-2'>
              <div className='w-full'>
                <span className='text-[13px] text-gray-500'>Body</span>
                <Editor
                  apiKey={import.meta.env.VITE_APP_TINYMCE_API_KEY}
                  onEditorChange={(editor) => {
                    setBody(editor);
                  }}
                  value={body}
                  init={{
                    height: 300,
                    menubar: false,
                    placeholder: 'Enter Email body here',
                    selector: 'textarea',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor fontsize fontfamily | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
                  }}
                />
              </div>
            </div>
            <FormSelect
              defaultValue={closingRemark}
              options={closingRemarkOptions}
              onChange={(selected) => {
                setClosingRemark(selected);
              }}
              label='Closing Remark'
            />
            <div className='w-full mt-[20px]'>
              <Button
                text={buttonText}
                type='submit'
                loading={addTemplateLoading || editTemplateLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default AddRecruitmentEmailTemplate;
