import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { store } from "../../../redux";
import {
  useDeclineAdvancedPayroll,
  useDeleteAdvancedPayroll,
  useExportAdvancedPayroll,
  useGetAdvancedPayroll,
  useRecalculateAdvancedPayroll,
  useSendPayslip,
} from "../../../redux/payroll/hook";
import { setAdvancedPayroll } from "../../../redux/payroll/reducer";
import {
  advancedPayrollStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../utils/constants";
import DataTable from "../../datatable";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import Button from "../../button";
import RunAdvancedPayroll from "../../modal/payroll/run-advanced-payroll";
import { useSelector } from "react-redux";
import { useGetEmployees } from "../../../redux/employees/hook";
import { useGetEmployeeSalaries } from "../../../redux/employees/hook/salaries";
import LoadingBar from "../../loader-bar";
import DoYouWantToModal from "../../modal/do-you-want-to";
import BulkPayrollAdjustment from "../../modal/payroll/payroll-adjustment";
import moment from "moment";

import HelpSVG from "../../../assets/svg/help.svg";
import InfoModal from "../../modal/info-modal";
import ExportAnnualReport from "../../modal/payroll/annual-report";

export const AdvancedPayrollTab = () => {
  const [dropOpen, setDropOpen] = useState(false);

  const wrapperRef = useRef(null);
  const moreButtonRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target))
        setDropOpen(false);
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      setDropOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, moreButtonRef]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const [showInfoModal, setShowInfoModal] = useState(false);

  const { refetch, data: advancedPayroll } = useGetAdvancedPayroll(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [deleteId, setDeleteId] = useState(null);

  const deleteAdvancedPayroll = async (ref) => {
    let index = advancedPayroll.data.findIndex((item) => item.ref === ref);
    await deletePayroll(advancedPayroll.data[index].ref).then(() => {
      setShowDoYouWant(false);
    });
  };

  const { mutateAsync: deletePayroll } = useDeleteAdvancedPayroll();

  const { user } = useSelector((state) => state.userSlice);

  const { data: employees } = useGetEmployees(1, 1, 1, "");

  const [showDoYouWant, setShowDoYouWant] = useState(false);

  const { data: employeeSalaries, isLoading: salariesLoading } =
    useGetEmployeeSalaries(1, 1, 1, "");

  const workbook = new Excel.Workbook();

  const [exportLoading, setSetExportLoading] = useState(false);

  const { mutateAsync: rerunAdvancedPayroll } = useRecalculateAdvancedPayroll();
  const { mutateAsync: declinePayment } = useDeclineAdvancedPayroll();

  const { mutateAsync: sendPayslip } = useSendPayslip();
  const [showAdvancedPayroll, setShowAdvancedPayroll] = useState(false);
  const [showAnnualReport, setShowAnnualReport] = useState(false);
  const [showAddPayrollAdjustment, setShowAddPayrollAdjustment] =
    useState(false);

  const history = useHistory();

  async function Continue(id, type) {
    let index = advancedPayroll.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === "preview") {
        history.push(
          `/payroll/advanced/preview/${advancedPayroll.data[index].ref}`
        );
      } else if (type === "view") {
        history.push(
          `/payroll/advanced/view/${advancedPayroll.data[index].id}`
        );
      } else if (type === "recalculate") {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        await rerunAdvancedPayroll(advancedPayroll.data[index].ref);
      } else if (type === "approve") {
        store.dispatch(setAdvancedPayroll(advancedPayroll.data[index]));
        history.push(
          `/payroll/advanced/preview/${advancedPayroll.data[index].ref}?approve=true`
        );
      } else if (type === "send_payslip") {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        sendPayslip({ ref: advancedPayroll.data[index].ref });
      } else if (type === "decline") {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        let payments = advancedPayroll.data[index].payments;
        let decline_index = payments.findIndex((item) => item.status === 2);
        declinePayment(payments[decline_index].id);
      } else if (type === "complete") {
        store.dispatch(setAdvancedPayroll(advancedPayroll.data[index]));
        history.push(
          `/payroll/advanced/preview/${advancedPayroll.data[index].ref}?complete=true`
        );
      }
    }
  }

  const tableConfig = {
    headers: [
      "Created At",
      "Payroll Month",
      "Description",
      "Ref. Number",
      "% Paid",
      "% Initiated",
      "Source",
      "Total Amount",
      "Status",
    ],
    keys: [
      "created",
      "formatted_month",
      "desc",
      "ref",
      "percentage_paid",
      "initiated_percentage",
      "source",
      "formatted_total_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Payroll Month",
        key: "month",
      },
      right: {
        title: "Description",
        key: "desc",
      },
    },
  };

  const { mutateAsync: exportPayroll } = useExportAdvancedPayroll();

  const exportAdvancedPayroll = async (refId) => {
    setSetExportLoading(true);
    let payload = {
      refId,
      limit: 100000,
      page: 1,
      statusFilter: -1,
      search: "",
    };

    await exportPayroll(payload).then(async (response) => {
      let data = response.data;
      const workSheetName = "advanced-payroll-history";
      const workBookName = `Advanced Payroll - ${refId}`;
      let columns = [
        { header: "Date", key: "formatted_date" },
        { header: "First Name", key: "employee_first_name" },
        { header: "Last Name", key: "employee_last_name" },
        { header: "Mobile", key: "employee_phone" },
        { header: "Staff Number", key: "staff_number" },
        { header: "Bank Name", key: "bank_name" },
        { header: "Account Number", key: "account_number" },
        { header: "Basic Salary", key: "formatted_basic_salary" },
        { header: "Housing Allowance", key: "formatted_housing_allowance" },
        { header: "Transport Allowance", key: "formatted_transport_allowance" },
        { header: "Leave Allowance", key: "formatted_leave_allowance" },
        { header: "Lunch Allowance", key: "formatted_lunch_allowance" },
        { header: "Benefit In Kind", key: "formatted_benefit_in_kind" },
        { header: "Utility Allowance", key: "formatted_utility_allowance" },
        { header: "Other Allowance", key: "formatted_other_allowance" },
      ];

      data.forEach((item) => {
        let newAllowanceItem = [];
        if (
          item.additional_allowances &&
          JSON.parse(item.additional_allowances.length > 0)
        ) {
          JSON.parse(item.additional_allowances).forEach((subItem) => {
            let description = subItem.title;
            let headersArray = [];
            columns.forEach((columnHeaders) => {
              headersArray.push(columnHeaders.header);
            });
            for (let i = 0; i < columns.length; i++) {
              if (!headersArray.includes(description)) {
                let singleItemList = null;
                if (newAllowanceItem.length > 0) {
                  for (let j = 0; j < newAllowanceItem.length; j++) {
                    if (newAllowanceItem[j].header == description) {
                      if (
                        description !== "Leave Allowance" ||
                        description !== "Lunch Allowance" ||
                        description !== "Benefit In Kind" ||
                        description !== "Utility Allowance" ||
                        description !== "Other Allowance"
                      ) {
                        singleItemList = null;
                        break;
                      } else {
                        description = description + "(1)";
                        if (newAllowanceItem[j].header == description) {
                          singleItemList = null;
                          break;
                        } else {
                          singleItemList = {
                            header: description,
                            key: description,
                          };
                        }
                      }
                    } else {
                      singleItemList = {
                        header: description,
                        key: description,
                      };
                    }
                  }
                } else {
                  singleItemList = { header: description, key: description };
                }
                if (singleItemList) {
                  newAllowanceItem.push(singleItemList);
                }
              }
            }
          });
          columns.push(...newAllowanceItem);
        }

        let newBonusItem = [];
        if (item.bonus && item.bonus > 0) {
          if (
            item.bonus_breakdown &&
            JSON.parse(item.bonus_breakdown.length > 0)
          ) {
            JSON.parse(item.bonus_breakdown).forEach((subItem) => {
              let description = subItem.description;
              let headersArray = [];
              columns.forEach((columnHeaders) => {
                headersArray.push(columnHeaders.header);
              });
              for (let i = 0; i < columns.length; i++) {
                if (!headersArray.includes(description)) {
                  let singleItemList = null;
                  if (newBonusItem.length > 0) {
                    for (let j = 0; j < newBonusItem.length; j++) {
                      if (newBonusItem[j].header == description) {
                        if (
                          description !== "Leave Allowance" &&
                          description !== "Lunch Allowance" &&
                          description !== "Benefit In Kind" &&
                          description !== "Utility Allowance" &&
                          description !== "Other Allowance"
                        ) {
                          singleItemList = null;
                          break;
                        } else {
                          description = description + "(1)";
                          if (newBonusItem[j].header == description) {
                            singleItemList = null;
                            break;
                          } else {
                            singleItemList = {
                              header: description,
                              key: description,
                            };
                          }
                        }
                      } else {
                        singleItemList = {
                          header: description,
                          key: description,
                        };
                      }
                    }
                  } else {
                    singleItemList = { header: description, key: description };
                  }
                  if (singleItemList) {
                    newBonusItem.push(singleItemList);
                  }
                }
              }
            });
            columns.push(...newBonusItem);
          } else {
            columns.push({
              header: "Bonus",
              key: "bonus",
            });
          }
        }
      });

      let gArray = [
        { header: "Gross Pay", key: "formatted_gross_pay" },
        { header: "Employee Pension", key: "formatted_pension" },
        { header: "PAYE Tax", key: "formatted_tax" },
        { header: "National Housing Fund", key: "formatted_nhf" },
        { header: "National Health Insurance Scheme", key: "formatted_nhis" },
        { header: "Salary Advance", key: "formatted_salary_advance" },
      ];

      columns.push(...gArray);

      data.forEach((item) => {
        let newOtherDeductionItem = [];
        if (item.other_deductions && item.other_deductions > 0) {
          if (
            item.other_deductions_breakdown &&
            JSON.parse(item.other_deductions_breakdown.length > 0)
          ) {
            JSON.parse(item.other_deductions_breakdown).forEach((subItem) => {
              let description = subItem.description;
              let headersArray = [];
              columns.forEach((columnHeaders) => {
                headersArray.push(columnHeaders.header);
              });
              for (let i = 0; i < columns.length; i++) {
                if (!headersArray.includes(description)) {
                  let singleItemList = null;
                  if (newOtherDeductionItem.length > 0) {
                    for (let j = 0; j < newOtherDeductionItem.length; j++) {
                      if (newOtherDeductionItem[j].header == description) {
                        if (
                          description !== "Leave Allowance" ||
                          description !== "Lunch Allowance" ||
                          description !== "Benefit In Kind" ||
                          description !== "Utility Allowance" ||
                          description !== "Other Allowance"
                        ) {
                          singleItemList = null;
                          break;
                        } else {
                          description = description + "(1)";
                          if (newOtherDeductionItem[j].header == description) {
                            singleItemList = null;
                            break;
                          } else {
                            singleItemList = {
                              header: description,
                              key: description,
                            };
                          }
                        }
                      } else {
                        singleItemList = {
                          header: description,
                          key: description,
                        };
                      }
                    }
                  } else {
                    singleItemList = { header: description, key: description };
                  }
                  if (singleItemList) {
                    newOtherDeductionItem.push(singleItemList);
                  }
                }
              }
            });
            columns.push(...newOtherDeductionItem);
          } else {
            columns.push({
              header: "Other Deductions",
              key: "other_deductions",
            });
          }
        }

        let newReimbursementItem = [];
        if (item.reimburse && item.reimburse > 0) {
          if (
            item.reimbursement_breakdown &&
            JSON.parse(item.reimbursement_breakdown.length > 0)
          ) {
            JSON.parse(item.reimbursement_breakdown).forEach((subItem) => {
              let description = subItem.description;
              let headersArray = [];
              columns.forEach((columnHeaders) => {
                headersArray.push(columnHeaders.header);
              });
              for (let i = 0; i < columns.length; i++) {
                if (!headersArray.includes(description)) {
                  let singleItemList = null;
                  if (newReimbursementItem.length > 0) {
                    for (let j = 0; j < newReimbursementItem.length; j++) {
                      if (newReimbursementItem[j].header == description) {
                        if (
                          description !== "Leave Allowance" ||
                          description !== "Lunch Allowance" ||
                          description !== "Benefit In Kind" ||
                          description !== "Utility Allowance" ||
                          description !== "Other Allowance"
                        ) {
                          singleItemList = null;
                          break;
                        } else {
                          description = description + "(1)";
                          if (newReimbursementItem[j].header == description) {
                            singleItemList = null;
                            break;
                          } else {
                            singleItemList = {
                              header: description,
                              key: description,
                            };
                          }
                        }
                      } else {
                        singleItemList = {
                          header: description,
                          key: description,
                        };
                      }
                    }
                  } else {
                    singleItemList = { header: description, key: description };
                  }
                  if (singleItemList) {
                    newReimbursementItem.push(singleItemList);
                  }
                }
              }
            });
            columns.push(...newReimbursementItem);
          } else {
            columns.push({
              header: "Reimbursements",
              key: "reimburse",
            });
          }
        }
      });

      let finArray = [
        { header: "Net Pay", key: "formatted_net_pay" },
        { header: "Payments Status", key: "status" },
        { header: "Employer Pension", key: "formatted_employer_pension" },
        { header: "Industrial Training Fund", key: "formatted_itf" },
        {
          header: "Nigeria Social Insurance Trust Fund",
          key: "formatted_nsitf",
        },
      ];

      columns.push(...finArray);

      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column) => {
          column.width = 23;
          if (column._number > 7) {
            column.width = 23;
            column.style.alignment = { horizontal: "right" };
            column.numFmt = "#,##0.00;[Red]-#,##0.00";
          } else {
            column.width = column.header.length + 2;
            column.style.alignment = { horizontal: "left" };
          }
        });

        // loop through data and add each one to worksheet
        data.forEach((singleData) => {
          if (
            singleData.additional_allowances &&
            JSON.parse(singleData.additional_allowances.length > 0)
          ) {
            JSON.parse(singleData.additional_allowances).forEach((item) => {
              singleData[item.title] = parseFloat(item.amount);
            });
          }

          if (singleData.bonus && singleData.bonus > 0) {
            if (
              singleData.bonus_breakdown &&
              JSON.parse(singleData.bonus_breakdown.length > 0)
            ) {
              JSON.parse(singleData.bonus_breakdown).forEach((item) => {
                singleData[item.description] = parseFloat(item.amount);
              });
            }
          }

          if (singleData.other_deductions && singleData.other_deductions > 0) {
            if (
              singleData.other_deductions_breakdown &&
              JSON.parse(singleData.other_deductions_breakdown.length > 0)
            ) {
              JSON.parse(singleData.other_deductions_breakdown).forEach(
                (item) => {
                  singleData[item.description] = parseFloat(item.amount);
                }
              );
            }
          }

          if (singleData.reimburse && singleData.reimburse > 0) {
            if (
              singleData.reimbursement_breakdown &&
              JSON.parse(singleData.reimbursement_breakdown.length > 0)
            ) {
              JSON.parse(singleData.reimbursement_breakdown).forEach((item) => {
                singleData[item.description] = parseFloat(item.amount);
              });
            }
          }
          singleData.formatted_date = moment(singleData.created).format(
            "MMMM DD, YYYY h:m:s"
          );

          if (singleData.status === 1) {
            singleData.status = "Successful";
          } else if (singleData.status === 2) {
            singleData.status = "Failed";
          } else if (singleData.status === 0) {
            singleData.status = "Pending";
          }
          worksheet.addRow(singleData);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            // const cellAddress = singleCell._address;
            // // apply border
            // worksheet.getCell(cellAddress).border = {
            //   top: { style: 'thin' },
            //   left: { style: 'thin' },
            //   bottom: { style: 'thin' },
            //   right: { style: 'thin' }
            // };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  const actionConfig = [
    {
      name: "Continue",
      color: "green",
      action: (id) => {
        Continue(id, "preview");
      },
    },
    {
      name: "Re-initiate Payment",
      color: "green",
      action: (id) => {
        Continue(id, "preview");
      },
    },
    {
      name: "View",
      color: "green",
      action: (id) => {
        Continue(id, "view");
      },
    },
    {
      name: "Send Payslips",
      color: "green",
      action: (id) => {
        Continue(id, "send_payslip");
      },
    },
    {
      name: "Recalculate",
      color: "orange",
      action: (id) => {
        Continue(id, "recalculate");
      },
    },
    {
      name: "Approve",
      color: "green",
      action: (id) => {
        Continue(id, "approve");
      },
    },
    {
      name: "Decline",
      color: "red",
      action: (id) => {
        Continue(id, "decline");
      },
    },
    {
      name: "Export Payroll",
      color: "orange",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        exportAdvancedPayroll(advancedPayroll.data[index].ref);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        setDeleteId(advancedPayroll.data[index].ref);
        setShowDoYouWant(true);
      },
    },
    {
      name: "Complete Payment",
      color: "orange",
      action: (id) => {
        Continue(id, "complete");
      },
    },
  ];

  return (
    <div className="flex flex-col gap-4 ">
      <div className="flex items-center justify-end w-full -mt-5">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={HelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      {showInfoModal ? (
        <InfoModal
          isOpen={showInfoModal}
          type={"advanced-payroll"}
          closeModal={() => setShowInfoModal(false)}
        />
      ) : null}
      {showDoYouWant ? (
        <DoYouWantToModal
          isOpen={showDoYouWant}
          closeModal={() => setShowDoYouWant(false)}
          id={deleteId}
          title={"Are you sure you want to delete this payroll?"}
          subtitle={
            "All information on this payroll will be removed from the portal. No payment refund will be made."
          }
          buttonText={"DELETE PAYROLL"}
          btnFunction={deleteAdvancedPayroll}
        />
      ) : null}
      {showAdvancedPayroll ? (
        <RunAdvancedPayroll
          isOpen={showAdvancedPayroll}
          source="default"
          closeModal={() => setShowAdvancedPayroll(false)}
        />
      ) : null}
      {showAnnualReport ? (
        <ExportAnnualReport
          isOpen={showAnnualReport}
          closeModal={() => setShowAnnualReport(false)}
        />
      ) : null}
      {showAddPayrollAdjustment ? (
        <BulkPayrollAdjustment
          isOpen={showAddPayrollAdjustment}
          source="default"
          closeModal={() => setShowAddPayrollAdjustment(false)}
        />
      ) : null}
      <div className="flex flex-col bg-white rounded -mt-6 border">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[25%] w-full">
            Payroll Summary
          </div>
          {salariesLoading || exportLoading ? (
            <LoadingBar loading={salariesLoading} />
          ) : employeeSalaries?.data.length > 0 ? (
            <>
              {user?.permissions.payroll_permission?.advanced_payroll_write ===
                1 || user?.permissions.company_permission.role === "Owner" ? (
                <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[75%] w-full">
                  <div className="md:w-fit w-[100%]" id="second-step">
                    <Button
                      text="Run Payroll"
                      type="button"
                      theme="primary"
                      className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                      textClass={"!text-[11px]"}
                      leftIcon={"purchase"}
                      disabled={!employees}
                      onClick={() => {
                        setShowAdvancedPayroll(true);
                      }}
                    />
                  </div>
                  <div className="md:w-fit w-full">
                    <Button
                      text="Refetch Data"
                      type="button"
                      theme="primary"
                      className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                      textClass={"!text-[11px]"}
                      leftIcon={"change"}
                      disabled={!employees}
                      onClick={() => {
                        refetch();
                      }}
                    />
                  </div>
                  <div
                    ref={moreButtonRef}
                    className="flex flex-col gap-2 md:w-fit w-[100%] relative"
                  >
                    <Button
                      text="More Options"
                      type="button"
                      theme="primary"
                      className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center bg-green-800"
                      textClass={"!text-[11px]"}
                      rightIcon={"forward"}
                      loading={exportLoading}
                      onClick={() => setDropOpen(!dropOpen)}
                    />
                    <div
                      ref={wrapperRef}
                      className={`${
                        dropOpen ? "flex " : "hidden "
                      } z-50 bg-white divide-y divide-gray-100 rounded-sm md:w-[200px] w-full dark:bg-gray-700 absolute top-[42px] -left-8 focus:pointer-events-auto`}
                    >
                      <ul className="flex flex-col text-[12px] text-gray-700 w-full px-0">
                        <li className="min-w-full">
                          <div
                            onClick={() => setShowAddPayrollAdjustment(true)}
                            className="block px-4 py-2 hover:bg-gray-100 w-full cursor-pointer"
                          >
                            Add Payroll Adjustments
                          </div>
                        </li>
                        <li className="min-w-full">
                          <div
                            onClick={() => setShowAnnualReport(true)}
                            className="block px-4 py-2 hover:bg-gray-100 w-full cursor-pointer"
                          >
                            Annual Report
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
              <div className="md:w-fit w-[100%]">
                <Button
                  text={
                    employees?.data?.length > 0 ? "NEW SALARY" : "NEW EMPLOYEE"
                  }
                  type="button"
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[11px]"}
                  leftIcon={"add"}
                  onClick={() => {
                    if (employees?.data?.length > 0) {
                      history.push(
                        "/employees/onboarding/add-salary-information?type=single&salary=true"
                      );
                    } else {
                      history.push("/employees/onboarding/select-option");
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={advancedPayroll}
          tableConfig={tableConfig}
          noCheck={true}
          statusConfig={advancedPayrollStatusConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          filterByStatus={true}
          searchData={true}
          computedActions={true}
        />
      </div>
    </div>
  );
};
