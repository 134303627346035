import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../../components/datatable';
import { PAGINATION_DEFAULT } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { useGetCabinetPromotions } from '../../redux/employee-folder/hook';

const PromotionFolder = ({employeeId}) => {

    const { user } = useSelector((state) => state.userSlice);

    const [pagination, setPagination] = useState({
        limit: PAGINATION_DEFAULT.limit,
        page: PAGINATION_DEFAULT.page,
        statusFilter: PAGINATION_DEFAULT.statusFilter,
        search: '',
    });

    const { data: promotions } = useGetCabinetPromotions(
        employeeId,
        pagination?.limit,
        pagination?.page,
        pagination?.statusFilter,
        pagination?.search,
    );

    const tableConfig = {
        headers: ['Employee Name', 'Mobile', 'Promoted From', 'Promoted To', 'Effective From'],
        keys: ['employeeName', 'mobile', 'promotion_from', 'promotion_to', 'formatted_effective_date'],
        mobileHeader: {
          left: {
            title: 'Employee Name',
            key: 'employeeName',
          },
          right: {
            title: 'New Role',
            key: 'promotion_to',
          },
        },
    };
    
    const actionConfig = [
        {
          name: 'View',
          color: 'green',
          action: (id) => {
            let index = promotions.data.findIndex((item) => item.id === id);
            history.push('/promotion/view/' + promotions.data[index].id);
          }
        },
    ];

    return (
        <div className='flex flex-col'>
            <div className='mt-9'>
                <div className='flex md:flex-row gap-4 flex-wrap-reverse'>
                    <div className='w-[100%]'>
                        <DataTable
                            data={promotions}
                            tableConfig={tableConfig}
                            noCheck={true}
                            actionConfig={actionConfig}
                            computedActions={true}
                            updatePagination={(data) => setPagination(data)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromotionFolder;