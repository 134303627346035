import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { employeeTrainers } from '../../../api/employees/trainers';

export function useGetEmployeeTrainers(limit, page, statusFilter, search) {
    return useQuery(
      ['employee-trainers', { limit, page, statusFilter, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeeTrainers.getEmployeeTrainers({
          limit,
          page,
          statusFilter, 
          search,
        });
      },
      {
        select(data) {
          data?.data?.forEach((trainer) => {
            trainer.created = moment(trainer.created).format('MMMM D, YYYY');
            trainer.computedActions = [0, 1, 2, 3];
          });
  
          return data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetTrainerTrainings(id, limit, page, statusFilter, search) {
  return useQuery(
    ['employee-trainer-trainings', {id,  limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeTrainers.getEmployeeTrainerTrainings({
        id,
        limit,
        page,
        statusFilter, 
        search,
      });
    },
    {
      enabled: id !== null,
      select(data) {
        data?.data?.forEach((training) => {
          training.created = moment(training.created).format('MMMM D, YYYY');
            training.period = `${moment(training.training_start).format('MMMM D, YYYY')} to ${moment(training.training_end).format('MMMM D, YYYY')}`;
            training.computedActions = [0, 1, 2, 3];
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetSelectTrainingTrainers(limit, page, search) {
    return useQuery(
      ['training-creation-trainers', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return employeeTrainers.getEmployeeTrainers({
          limit,
          page,
          search,
        });
      },
      {
        select: (data) => {
          let newData = [];
          newData.push({ value: '', label: 'No Trainer Selected' });
          data?.data?.forEach((item) => {
            newData.push({ value: item.id, label: item.trainer_name });
          });
          return newData;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useAddEmployeeTrainer() {
  return useMutation(
    (payload) => {
      return employeeTrainers.addEmployeeTrainer(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-trainers');
        queryClient.invalidateQueries('single-trainer');
        store.dispatch(setAlert(true, 'success', 'Employee trainer added successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateEmployeeTrainer() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeTrainers.updateEmployeeTrainer(payload);
    },
    {
      onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('employee-trainers');
          queryClient.invalidateQueries('single-trainer');
          store.dispatch(setAlert(true, 'success', 'Trainer updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDeleteTrainer() {
  return useMutation(
    (payload) => {
      return employeeTrainers.deleteTrainer(payload);
    },
    {
    onSuccess: () => {
        queryClient.invalidateQueries('employee-trainers');
        queryClient.invalidateQueries('single-trainer');
        store.dispatch(setAlert(true, 'success', 'Trainer deleted successfully'));
    },
    },
  );
}

export function useChangeTrainerStatus() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeTrainers.changeTrainerStatus(payload);
    },
    {
      onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('employee-trainers');
          queryClient.invalidateQueries('single-trainer');
          store.dispatch(setAlert(true, 'success', 'Trainer updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetSingleTrainer(id) {
    return useQuery(
        ['single-trainer', { id }],
        () => {
        store.dispatch(setIsLoading(true));
        return employeeTrainers.getEmployeeTrainer(id);
        },
        {
        enabled: id !== null,
        onSuccess: (response, variables, context) => {
            store.dispatch(setAlert(true, 'success', 'Trainer fetched successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        },
    );
}