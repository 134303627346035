import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import AsyncSelect from 'react-select/async';
import { employees as employeeAPI } from '../../../api/employees';
import { setAlert } from '../../../redux/components/components-slice';
import Modal from '../modal';
import { useAddEmployeesToHMOGroup } from '../../../redux/hmo/hook';
import FormSelect from '../../form-select';

function AddEmployeeToHMOGroup({ isOpen, closeModal, groupData, refresh }) {
  const [employees, setEmployees] = useState([]);
  const { mutateAsync: addToGroup, isLoading } = useAddEmployeesToHMOGroup();
  const [type, setType] = useState('select');

  const typeOptions = [
    {
        label: 'All Employees',
        value: 'all',
    },
    {
        label: 'Select Employees',
        value: 'select',
    },
];

  const dispatch = useDispatch();

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      employeeAPI
        .getEmployees({
          limit: 10,
          statusFilter: -1,
          search: inputValue,
          page: 1,
        })
        .then((response) => {
          let data = [];

          response.data.forEach((item) => {
            data.push({
              label: `${item.first_name} ${item.last_name} | ${item.mobile} | ${item.email}`,
              value: item.id,
            });
          });

          resolve(data);
        })
        .catch((err) => {
          resolve([]);
        });
  });

  const submit = () => {
    let employee_ids = '';
    if (type == 'select') {
      if (employees.length === 0) {
        dispatch(setAlert(true, 'info', 'Select at least one employee'));
        return;
      }
      let employeeArrays = [];

        employees.forEach((item) => {
        employeeArrays.push(item.value);
      });

      employee_ids = employeeArrays.join();
    } else {
      employee_ids = 'none';
    }
    
    let payload = {
        type,
        employee_ids,
        group_id: groupData?.id
    }
    addToGroup(payload).then(() => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      
        <div className='flex flex-col pb-8 overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Add Employee To HMO Group</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          {groupData ? (
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col mt-3'>
              <label className='text-[15px] leading-[23px] font-normal text-color-gray'>
                Group Name
              </label>
              <span className='header-4'>{groupData?.name}</span>
              <hr className='divider' />
              <FormSelect
                  value={type}
                  options={typeOptions}
                  onChange={(selected) => {
                  setType(selected);
                  }}
                  label='Type'
              />
              {type == 'all'?
              null:
              <div>
                <label className='text-[13px] leading-[23px] font-normal text-color-gray mb-1'>
                  Select Multiple Employees
                </label>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  defaultOptions
                  className='text-[13px]'
                  placeholder={'Type to select multiple employees'}
                  loadOptions={loadOptions}
                  onChange={(options) => setEmployees(options)}
                  classNamePrefix='mySelect'
                />
              </div>
}
            </div>
            <hr className='divider' />
            <div className='w-full'>
              <Button
                text='ADD TO GROUP'
                type='submit'
                loading={isLoading}
                onClick={() => submit()}
              />
            </div>
          </div>
          )
          :
          <div className='flex !h-[35px] w-full justify-center items-center'>
            No group data fetched
          </div>
          }
        </div>
    </Modal>
  );
}

export default AddEmployeeToHMOGroup;
