import { Axios } from '../axios';

const updateManagerSettings = async (payload) => {
    const { data } = await Axios.put('/manager-setting/' + payload.id, payload.data);
    return data;
};


export const managers = {
    updateManagerSettings,
};