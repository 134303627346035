import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import FormSelect from '../../../form-select';
import FormSwitch from '../../../form-switch';

import FormSelectEmployee from '../../../employees/form-select-employee';
import FormFileInput from '../../../form-file-input';
import {
  useAddGuarantor,
  useEditGuarantor,
  useRequestEmployeeGuarantors,
} from '../../../../redux/human-resources/hook/guarantors';
import Modal from '../../modal';
import {
  AllCountries,
  countriesAndState,
} from '../../../../constants/countries';

function AddGuarantor({
  isOpen,
  closeModal,
  refresh,
  guarantorData,
  title,
  buttonText,
  canRequestGuarantor = true,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [guarantorName, setGuarantorName] = useState();
  const [email, setEmail] = useState();
  const [companyName, setCompanyName] = useState();
  const [homeAddress, setHomeAddress] = useState();
  const [idNumber, setIdNumber] = useState();
  const [meansOfId, setMeansOfId] = useState();
  const [mobile, setMobile] = useState();
  const [occupation, setOccupation] = useState();
  const [workAddress, setWorkAddress] = useState();
  const [city, setCity] = useState();

  const [guarantorId, setGuarantorId] = useState();

  const [requestFromEmployee, setRequestFromEmployee] = useState(true);
  const [employee, setEmployee] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [country, setCountry] = useState('Nigeria');
  const [state, setState] = useState('Lagos');
  const [stateOptions, setStateOptions] = useState([]);
  const [gender, setGender] = useState('Male');
  const { mutateAsync: addGuarantor, isLoading: addGuarantorLoading } =
    useAddGuarantor();
  const { mutateAsync: editGuarantor, isLoading: editGuarantorLoading } =
    useEditGuarantor();
  const { mutateAsync: requestGuarantor, isLoading: requestGuarantorLoading } =
    useRequestEmployeeGuarantors();

  const genderOptions = [
    {
      label: 'Male',
      value: 'Male',
    },
    {
      label: 'Female',
      value: 'Female',
    },
  ];

  const [saveType, setSaveType] = useState('add');

  useEffect(() => {
    if (guarantorData) {
      setGuarantorName(guarantorData.name);
      setEmail(guarantorData.email);
      setMobile(guarantorData.mobile);
      setCompanyName(guarantorData.guarantor_company_name);
      setOccupation(guarantorData.occupation);
      setHomeAddress(guarantorData.home_address);
      setIdNumber(guarantorData.id_number);
      setWorkAddress(guarantorData.work_address);
      setCity(guarantorData.city);
      setRequestFromEmployee(guarantorData.false);
      setCountry(guarantorData.country);
      setState(guarantorData.state);
      setGender(guarantorData.gender);
      setEmployee(guarantorData.employee_id);
      setSaveType('edit');
      setMeansOfId();
      setGuarantorId(guarantorData.id);
    } else {
      setSaveType('add');
    }
  }, [guarantorData]);

  useEffect(() => {
    if (country) {
      let getStates = countriesAndState.find((a) => a.name === country);

      if (getStates) {
        let computedStates = [];

        getStates.states.map((item, index) => {
          computedStates.push({ label: item, value: item });
        });

        setStateOptions(computedStates);
      }
    }
  }, [country]);

  useEffect(() => {
    setRequestFromEmployee(canRequestGuarantor);
  }, [canRequestGuarantor]);

  const submitForm = async (data) => {
    const name = DOMPurify.sanitize(data?.guarantor_name);
    const email = DOMPurify.sanitize(data?.email);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const occupation = DOMPurify.sanitize(data?.occupation);
    const city = DOMPurify.sanitize(data?.city);
    const home_address = DOMPurify.sanitize(data?.home_address);
    const company_name = DOMPurify.sanitize(data?.company_name);
    const work_address = DOMPurify.sanitize(data?.work_address);
    const guarantor = DOMPurify.sanitize(data?.guarantor_company_name);
    const id_number = DOMPurify.sanitize(data?.id_number);

    if (saveType === 'add') {
      const formData = new FormData();

      formData.append('file', data?.file[0]);
      formData.append('employee_id', employee.toString());
      formData.append('name', name);
      formData.append('email', email);
      formData.append('mobile', mobile);
      formData.append('occupation', occupation);
      formData.append('gender', gender);
      formData.append('country', country);
      formData.append('state', state);
      formData.append('city', city);
      formData.append('country', country);
      formData.append('guarantor_company_name', guarantor);
      formData.append('home_address', home_address);
      formData.append('company_name', company_name);
      formData.append('work_address', work_address);
      formData.append('id_number', id_number);

      await addGuarantor(formData).then(() => {
        closeModal();
      });
    } else {
      const formData = new FormData();
      if (data?.file[0]) {
        formData.append('file', data?.file[0]);
        formData.append('employee_id', employee.toString());
        formData.append('name', name);
        formData.append('email', email);
        formData.append('mobile', mobile);
        formData.append('occupation', occupation);
        formData.append('gender', gender);
        formData.append('country', country);
        formData.append('state', state);
        formData.append('city', city);
        formData.append('country', country);
        formData.append('guarantor_company_name', guarantor);
        formData.append('home_address', home_address);
        formData.append('company_name', company_name);
        formData.append('work_address', work_address);
        formData.append('id_number', id_number);
      } else {
        formData.append('file', '');
        formData.append('employee_id', employee.toString());
        formData.append('name', name);
        formData.append('email', email);
        formData.append('mobile', mobile);
        formData.append('occupation', occupation);
        formData.append('gender', gender);
        formData.append('country', country);
        formData.append('state', state);
        formData.append('city', city);
        formData.append('country', country);
        formData.append('guarantor_company_name', guarantor);
        formData.append('home_address', home_address);
        formData.append('company_name', company_name);
        formData.append('work_address', work_address);
        formData.append('id_number', id_number);
      }
      let editPayload = {
        id: guarantorId,
        formData,
      };
      await editGuarantor(editPayload).then(() => {
        closeModal();
      });
    }
  };

  const submitRequestGuarantor = async () => {
    let payload = {
      employee_id: employee,
    };

    requestGuarantor(payload).then((result) => {
      closeModal();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col pb-4 overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <span className='header-4'>{title}</span>
            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col mx-8'>
          {canRequestGuarantor ? (
            <div className='w-full md:w-[50%] p4-bold'>
              <FormSwitch
                label={'Request From Employee'}
                checked={requestFromEmployee}
                onClick={() => {
                  setRequestFromEmployee(!requestFromEmployee);
                }}
              />
            </div>
          ) : null}
          <hr className='divider -mt-2 mb-2' />
          <div className='border bg-highlight p-2 rounded-md'>
            <FormSelectEmployee
              label={'Employee'}
              name='employee'
              placeholder='Type to select Employee'
              value={employee}
              empName={employeeName}
              setEmployee={setEmployee}
            />
          </div>
          <hr className='divider mt-2 mb-2' />
          {requestFromEmployee ? (
            <div className='w-full mt-[20px]'>
              <Button
                text={'Request from Employee'}
                loading={requestGuarantorLoading}
                onClick={() => {
                  submitRequestGuarantor();
                }}
              />
            </div>
          ) : (
            <form className='form' onSubmit={handleSubmit(submitForm)}>
              <FormInput
                label='Guarantor Name'
                name='guarantor_name'
                type='text'
                placeholder={'Enter guarantor name'}
                value={guarantorName}
                onInput={(e) => setGuarantorName(e.target.value)}
                inputRef={register(formValidation('text', true))}
                error={errors.guarantor_name}
                errorMessage={
                  errors.guarantor_name && errors.guarantor_name.message
                }
              />
              <FormInput
                label='Email'
                name='email'
                type='email'
                placeholder={'Enter guarantor email'}
                value={email}
                onInput={(e) => setEmail(e.target.value)}
                inputRef={register(formValidation('email', true))}
                error={errors.email}
                errorMessage={errors.email && errors.email.message}
              />
              <FormInput
                label='Mobile'
                name='mobile'
                type='text'
                placeholder={'Enter guarantor mobile'}
                value={mobile}
                onInput={(e) => setMobile(e.target.value)}
                inputRef={register(formValidation('text', true))}
                error={errors.mobile}
                errorMessage={errors.mobile && errors.mobile.message}
              />
              <FormInput
                label='Occupation'
                name='occupation'
                type='text'
                placeholder={'Enter guarantor occupation'}
                value={occupation}
                onInput={(e) => setOccupation(e.target.value)}
                inputRef={register(formValidation('text', true))}
                error={errors.occupation}
                errorMessage={errors.occupation && errors.occupation.message}
              />
              <div className='flex md:flex-row flex-col w-full justify-between'>
                <div className='w-full md:w-1/2'>
                  <FormSelect
                    value={gender}
                    options={genderOptions}
                    onChange={(selected) => {
                      setGender(selected);
                    }}
                    label='Gender'
                  />
                </div>
                <div className='w-full md:w-1/2 pl-2'>
                  <FormSelect
                    defaultValue={country}
                    options={AllCountries}
                    onChange={(selected) => {
                      setCountry(selected);
                    }}
                    label='Country'
                  />
                </div>
              </div>
              <div className='flex md:flex-row flex-col w-full justify-between'>
                <div className='w-full md:w-1/2'>
                  {country == 'Nigeria' ? (
                    <FormSelect
                      defaultValue={state}
                      options={stateOptions}
                      onChange={(selected) => {
                        setState(selected);
                      }}
                      label='State'
                    />
                  ) : (
                    <FormInput
                      label='State'
                      name='state'
                      type='text'
                      value={state}
                      placeholder={'Enter guarantor state'}
                      onInput={(e) => setState(e.target.value)}
                      error={errors.state}
                      errorMessage={errors.state && errors.state.message}
                    />
                  )}
                </div>
                <div className='w-full md:w-1/2 pl-2 pt-1'>
                  <FormInput
                    label='City'
                    name='city'
                    type='text'
                    placeholder={'Enter guarantor city'}
                    value={city}
                    onInput={(e) => setCity(e.target.value)}
                    error={errors.city}
                    errorMessage={errors.city && errors.city.message}
                  />
                </div>
              </div>
              <FormInput
                label='Home Address'
                name='home_address'
                type='text'
                placeholder={'Enter guarantor address'}
                value={homeAddress}
                onInput={(e) => setHomeAddress(e.target.value)}
                error={errors.home_address}
                errorMessage={
                  errors.home_address && errors.home_address.message
                }
              />
              <hr className='divider' />
              <FormInput
                label='Guarantor Company Name'
                name='guarantor_company_name'
                type='text'
                placeholder={'Enter guarantor company name'}
                value={companyName}
                onInput={(e) => setCompanyName(e.target.value)}
                error={errors.guarantor_company_name}
                errorMessage={
                  errors.guarantor_company_name &&
                  errors.guarantor_company_name.message
                }
              />
              <FormInput
                label='Full Work Address'
                name='work_address'
                type='text'
                placeholder={'Enter guarantor work address'}
                value={workAddress}
                onInput={(e) => setWorkAddress(e.target.value)}
                error={errors.work_address}
                errorMessage={
                  errors.work_address && errors.work_address.message
                }
              />

              <FormInput
                label='ID Number'
                name='id_number'
                type='text'
                placeholder={'Enter guarantor ID number'}
                value={idNumber}
                onInput={(e) => setIdNumber(e.target.value)}
                error={errors.id_number}
                errorMessage={errors.id_number && errors.id_number.message}
              />

              <div className='flex flex-col text-center justify-center items-center border-1 p-3 rounded mt-3'>
                <span className='header-4 -mb-4'>
                  Upload Guarantor Means of identification
                </span>
                <FormFileInput
                  file={meansOfId}
                  multiSource={true}
                  inputRef={register(
                    formValidation('file', saveType === 'add' ? true : false),
                  )}
                  accept='.pdf, .jpg, .png, .jpeg'
                />
              </div>
              <div className='w-full mt-[20px]'>
                <Button
                  text={buttonText}
                  type='submit'
                  loading={addGuarantorLoading}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default AddGuarantor;
