import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";
import TabView from "../../../components/tabview";
import { useHistory } from "react-router-dom";
import { useGetEmployeeSummary } from "../../../redux/employees/hook";
import { CardHr } from "../../../components/modal/hr-section/dashboard/cardHR";
import EmployeeSVG from "../../../assets/svg/employee-green.svg";
import EmployeeActiveSVG from "../../../assets/svg/employee-present.svg";
import EmployeeSuspendedSVG from "../../../assets/svg/employee-suspension.svg";
import SalaryAdvanceRequestTab from "../../../components/hr-section/requests/salary-advance-requests";
import LeaveRequestsTab from "../../../components/hr-section/requests/leave-requests";
import ReimbursementRequestTab from "../../../components/hr-section/requests/reimbursement-requests";

const HRRequestsPage = () => {
  useEffect(() => {}, []);

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);
  const { data: getEmployeeSummary } = useGetEmployeeSummary();
  const [employeeSummary, setEmployeeSummary] = useState([]);

  useEffect(() => {
    if (getEmployeeSummary) {
      setEmployeeSummary([
        {
          title: "SALARY ADVANCE APPLICATIONS",
          value: getEmployeeSummary.total,
          icon: EmployeeSVG,
        },
        {
          title: "REIMBURSEMENT APPICATIONS",
          value: getEmployeeSummary.inactive,
          icon: EmployeeSuspendedSVG,
        },
      ]);
    }
  }, [getEmployeeSummary]);

  const RequestTabs = [
    {
      title: "Salary Advance Requests",
      Content: SalaryAdvanceRequestTab,
    },
    // {
    //   title: 'Leave Requests',
    //   Content: LeaveRequestsTab,
    // },
    {
      title: "Reimbursement Requests",
      Content: ReimbursementRequestTab,
    },
  ];

  return (
    <DashboardLayout title="Requests Page">
      <div className="flex flex-col">
        <div className="flex flex-col justify-between !md:w-[75%]">
          <div className="h-fit p-3 flex flex-col justify-between w-full">
            <div className="grid md:grid-cols-3 lg:grid-cols-3 grid-cols-2 gap-2 w-[100%] ">
              {employeeSummary.map((item) => (
                <CardHr
                  title={item.title}
                  subtitle={item.value}
                  icon={item.icon}
                />
              ))}
            </div>
          </div>

          <div className="mt-3">
            <TabView
              Tabs={RequestTabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default HRRequestsPage;
