import React, { useEffect } from "react";
import DashboardLayout from "../../../components/layouts/dashboard";

const CardsPage = () => {
  useEffect(() => {}, []);

  return (
    <DashboardLayout title="Cards Management">
      <div>Cards</div>
    </DashboardLayout>
  );
};

export default CardsPage;
