import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  useGetEmployeeGroup,
  useGetGroupEmployees,
  useRemoveEmployeesFromGroup,
} from "../../../redux/employees/hook/groups";
import {
  employeeStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../utils/constants";
import Button from "../../button";
import DataTable from "../../datatable";
import RunAdvancedPayroll from "../../modal/payroll/run-advanced-payroll";
// import RunBasicPayroll from '../../modal/payroll/run-basic-payroll';
import AddEmployeeToGroup from "../../modal/employees/add-employee-to-group";
import { useSelector } from "react-redux";
import { useGetEmployeeSalaries } from "../../../redux/employees/hook/salaries";
import { store } from "../../../redux";
import { setAlert } from "../../../redux/components/components-slice";
import { useGetEmployees } from "../../../redux/employees/hook";

const GroupOverviewTab = () => {
  useEffect(() => {}, []);
  const { section } = useSelector((state) => state.sectionSlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: employeeSalaries } = useGetEmployeeSalaries(2, 1, 1, "");
  const { id } = useParams();

  const { data: group_employees } = useGetGroupEmployees(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { data: group } = useGetEmployeeGroup(id);

  const { data: employees } = useGetEmployees(2, 1, 1, "");

  // const [showBasicPayrollOptions, setShowBasicPayrollOptions] = useState(false);
  const [showAdvancedPayrollOptions, setShowAdvancedPayrollOptions] =
    useState(false);
  const [showAddEmployeeToGroup, setShowAddEmployeeToGroup] = useState(false);
  const { mutateAsync: removeFromGroup } = useRemoveEmployeesFromGroup();

  const removeEmployeesFromGroup = (id) => {
    let index = group_employees.data.findIndex((item) => item.id == id);
    let payload = {
      group: group_employees.id,
      employees: group_employees.data[index].employee,
    };
    removeFromGroup(payload);
  };

  const tableConfig = {
    headers: ["Date Added", "Employee Name", "Email", "Mobile", "Status"],
    keys: [
      "created",
      "employeeName",
      "employee_email",
      "employee_mobile",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "employee_mobile",
      },
    },
  };

  const actionConfig = [
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        removeEmployeesFromGroup(id);
      },
    },
  ];

  const bulkActionConfig = [
    {
      label: "Remove",
      value: "active",
      action: (data) => {
        let newData = data.split(",");
        let result = [];

        newData.forEach((id) => {
          let index = group_employees.data.findIndex((item) => item.id == id);
          if (index != -1) {
            result.push(group_employees.data[index].employee);
          }
        });
        removeFromGroup({
          group: group_employees.id,
          employees: result.join(","),
        });
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white shadow-md rounded">
      {/* {showBasicPayrollOptions ? (
            <RunBasicPayroll
            isOpen={showBasicPayrollOptions}
            source='group'
            sourceData={id}
            closeModal={() => setShowBasicPayrollOptions(false)}
          />
      ): null} */}

      {showAdvancedPayrollOptions ? (
        <RunAdvancedPayroll
          isOpen={showAdvancedPayrollOptions}
          source="group"
          sourceData={id}
          closeModal={() => setShowAdvancedPayrollOptions(false)}
        />
      ) : null}

      {showAddEmployeeToGroup ? (
        <AddEmployeeToGroup
          isOpen={showAddEmployeeToGroup}
          groupData={group}
          closeModal={() => setShowAddEmployeeToGroup(false)}
        />
      ) : null}

      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Employees list
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Run Payroll"
              type="button"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"purchase"}
              theme="primary"
              onClick={() => {
                if (employeeSalaries?.data.length > 0) {
                  setShowAdvancedPayrollOptions(true);
                } else {
                  store.dispatch(
                    setAlert(
                      true,
                      "info",
                      "No Active Employee Compensation to run"
                    )
                  );
                }
              }}
            />
          </div>
          {employees?.data?.length > 0 ? (
            <div className="md:w-fit w-[100%]">
              <Button
                text="Add Employee To Group"
                type="button"
                className="flex gap-2 !h-[35px] w-fit px-2 "
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => setShowAddEmployeeToGroup(true)}
              />
            </div>
          ) : null}
        </div>
      </div>
      <hr className="divider mt-2 mb-2" />
      <DataTable
        data={group_employees}
        tableConfig={tableConfig}
        bulkActionConfig={bulkActionConfig}
        statusConfig={employeeStatusConfig}
        searchData={true}
        filterByStatus={true}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
      />
    </div>
  );
};

export default GroupOverviewTab;
