import React, { useState } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WarningSVG from '../../../../assets/svg/warning.svg';
import ConfirmationModal from '../../confirmation-modal';
import {
  useDisburseVendorPay,
  useInitiateVendorPay,
} from '../../../../redux/spend-management/vendor/hook';
import FormSelect from '../../../form-select';
import FormInput from '../../../form-input';
import Modal from '../../modal';
import { formatNumber } from '../../../../utils/functions';
import FormOtpInput from '../../../form-otp-input';
import moment from 'moment';

function VendorPaySummary({
  isOpen,
  closeModal,
  takeHome,
  transCost,
  vendorstatus,
  totalAmount,
  refValue,
}) {
  const [pin, setPIN] = useState('');
  const history = useHistory();
  const { company } = useSelector((state) => state.companySlice);
  const { user } = useSelector((state) => state.userSlice);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(
    moment().format('YYYY-MM-DD'),
  );

  const [scheduleType, setScheduleType] = useState('instant');
  const scheduleTypeOptions = [
    { value: 'schedule', label: 'Schedule' },
    { value: 'instant', label: 'Instant' },
  ];

  const [mode, setMode] = useState('auto_pay');
  const modeOptions = [
    { value: 'auto_pay', label: 'Auto Pay' },
    { value: 'remind_me', label: 'Remind Me' },
  ];

  const { mutateAsync: disburseVendorPay, isLoading: disburseLoading } =
    useDisburseVendorPay();

  const { mutateAsync: initiateVendorPay, isLoading: initiateLoading } =
    useInitiateVendorPay();

  const submitForm = async (data) => {
    if (
      user?.permissions.approval_permission?.initiator === 1 &&
      user?.permissions.approval_permission?.approver === 1
    ) {
      if (scheduleType === 'instant') {
        let requestData = {
          transaction_pin: pin,
          ref: data,
          type: scheduleType,
        };
        await disburseVendorPay(requestData).then(() => {
          setShowConfirmation(true);
        });
      } else {
        let requestData = {
          transaction_pin: pin,
          ref: data,
          type: scheduleType,
          schedule_date: scheduleDate,
          mode: mode,
        };
        await disburseVendorPay(requestData).then(() => {
          setShowConfirmation(true);
        });
      }
    } else if (
      user?.permissions.approval_permission?.initiator === 1 &&
      vendorstatus !== 'approve'
    ) {
      let requestData = {
        transaction_pin: pin,
        ref: data,
      };
      await initiateVendorPay(requestData).then(() => {
        setShowConfirmation(true);
      });
    } else if (
      user?.permissions.approval_permission?.approver === 1 &&
      vendorstatus === 'approve'
    ) {
      if (scheduleType === 'instant') {
        let requestData = {
          transaction_pin: pin,
          ref: data,
          type: scheduleType,
        };
        await disburseVendorPay(requestData).then(() => {
          setShowConfirmation(true);
        });
      } else {
        let requestData = {
          transaction_pin: pin,
          ref: data,
          type: scheduleType,
          schedule_date: scheduleDate,
          mode: mode,
        };
        await disburseVendorPay(requestData).then(() => {
          setShowConfirmation(true);
        });
      }
    }
  };

  const subTitle =
    scheduleType === 'schedule' && mode === 'auto_pay'
      ? `Time to kick back and relax. Ropay will debit ${totalAmount} from your account on ${scheduleDate}. Please Make sure the funds are available.`
      : scheduleType === 'schedule' && mode === 'remind_me'
      ? `Time to kick back and relax. Ropay will remind you on ${scheduleDate} to make this payment. Please Make sure the funds are available.`
      : vendorstatus === 'approve'
      ? `Time to kick back and relax. Ropay will debit ${totalAmount} from your account. Please Make sure the funds are available.`
      : `Time to kick back and relax. Ropay has informed all authorised approvers for ${totalAmount} you just initiated.`;

  const confirmationNext = () => {
    if (scheduleType === 'schedule') {
      history.push('/vendors', { tab: 4 });
    } else {
      history.push('/vendors', { tab: 5 });
    }
  };

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmation}
        closeModal={() => setShowConfirmation(false)}
        btnFunction={confirmationNext}
        title={'You did it, ' + user?.first_name}
        subtitle={subTitle}
      />
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col !max-h-[600px] overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Pay or schedule</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col'>
              <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-highlight'>
                <span className='stat-card-header'>
                  COMPANY WALLET BALANCE{' '}
                  <span className='!text-[13px] text-color-black font-bold'>
                    ({company?.current_bank} - {company?.account_number} )
                  </span>
                </span>
                <span className='!text-[16px] text-color-black font-bold'>
                  {company?.total_balance?.toLocaleString()}
                </span>
              </div>
              <hr className='divider mt-2' />
              <div className='flex flex-col items-center mt-2'>
                <span className='stat-card-header'>TOTAL AMOUNT</span>
                <span className='p4-medium text-color-black font-bold'>
                  {formatNumber(takeHome)}
                </span>
              </div>
              <hr className='divider' />

              <div className='flex flex-col items-center mt-2'>
                <span className='stat-card-header'>TOTAL FEES</span>
                <span className='p4-medium text-color-black font-bold'>
                  {transCost}
                </span>
              </div>
            </div>
            <hr className='divider' />
            {vendorstatus === 'approve' &&
            user?.permissions.approval_permission?.approver === 1 ? (
              <div className='w-full mb-[20px]'>
                <div className='w-full'>
                  <FormSelect
                    value={scheduleType}
                    options={scheduleTypeOptions}
                    onChange={(selected) => {
                      setScheduleType(selected);
                    }}
                    label='Pay Type'
                  />
                </div>
                {scheduleType === 'schedule' ? (
                  <div className='flex justify-between'>
                    <div className='md:w-1/2 w-full md:pr-2'>
                      <FormInput
                        label='Schedule Date'
                        name='schedule_date'
                        type='date'
                        value={scheduleDate}
                        onInput={(e) => setScheduleDate(e.target.value)}
                      />
                    </div>
                    <div className='md:w-1/2 w-full md:pl-2'>
                      <FormSelect
                        value={mode}
                        options={modeOptions}
                        onChange={(selected) => {
                          setMode(selected);
                        }}
                        label='Schedule Type'
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {vendorstatus === 'approve' ||
                    (user?.permissions.approval_permission?.initiator === 1 &&
                      user?.permissions.approval_permission?.approver === 1) ? (
                      <div className='flex mb-4'>
                        <div className='flex flex-row border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light'>
                          <div className='w-[70px] pr-2 flex flex-row items-center '>
                            <img src={WarningSVG} alt='' srcSet='' />
                          </div>
                          <span className='text-[13px]'>
                            Ropay will debit{' '}
                            <span className='font-semibold'>
                              {formatNumber(totalAmount, 2)}
                            </span>{' '}
                            from your account. Please Make sure the funds are
                            available.
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className='flex mb-4'>
                        {vendorstatus === 'approve' ? (
                          <div className='flex flex-row px-2 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light'>
                            <div className='w-[70px] pr-2 flex flex-row items-center '>
                              <img src={WarningSVG} alt='' srcSet='' />
                            </div>
                            <span className='text-[13px]'>
                              Ropay will debit{' '}
                              <span className='font-semibold'>
                                {formatNumber(totalAmount, 2)}
                              </span>{' '}
                              from your account. Please Make sure the funds are
                              available.
                            </span>
                          </div>
                        ) : (
                          <div className='flex flex-row px-2 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light'>
                            <div className='w-[70px] pr-2 flex flex-row items-center '>
                              <img src={WarningSVG} alt='' srcSet='' />
                            </div>
                            <span className='text-[13px]'>
                              Ropay will send{' '}
                              <span className='font-semibold'>
                                {formatNumber(totalAmount, 2)}
                              </span>{' '}
                              to authorized aprrovers. Kindly ensure that you
                              have entered have the correct amount for approval.
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4 mt-3'>
                  <FormOtpInput
                    value={pin}
                    valueLength={4}
                    onChange={(value) => setPIN(value)}
                    label={'Enter your new 4 digit PIN'}
                  />
                </div>
                <Button
                  text={
                    scheduleType === 'instant' ? 'PAY NOW' : 'SCHEDULE PAYMENT'
                  }
                  type='submit'
                  disabled={
                    scheduleType === 'instant'
                      ? false
                      : scheduleType === 'schedule' && !scheduleDate && !mode
                      ? true
                      : false
                  }
                  loading={initiateLoading || disburseLoading}
                  onClick={() => submitForm(refValue)}
                />
              </div>
            ) : vendorstatus !== 'approve' &&
              user?.permissions.approval_permission?.initiator === 1 ? (
              <div className='w-full mb-[20px]'>
                {user?.permissions.approval_permission?.initiator === 1 &&
                user?.permissions.approval_permission?.approver === 1 ? (
                  <div className='w-full mb-[20px]'>
                    <div className='w-full'>
                      <FormSelect
                        value={scheduleType}
                        options={scheduleTypeOptions}
                        onChange={(selected) => {
                          setScheduleType(selected);
                        }}
                        label='Pay Type'
                      />
                    </div>
                    {scheduleType === 'schedule' ? (
                      <div className='flex justify-between'>
                        <div className='md:w-1/2 w-full md:pr-2'>
                          <FormInput
                            label='Schedule Date'
                            name='schedule_date'
                            type='date'
                            value={scheduleDate}
                            onInput={(e) => setScheduleDate(e.target.value)}
                          />
                        </div>
                        <div className='md:w-1/2 w-full md:pl-2'>
                          <FormSelect
                            value={mode}
                            options={modeOptions}
                            onChange={(selected) => {
                              setMode(selected);
                            }}
                            label='Schedule Type'
                          />
                        </div>
                      </div>
                    ) : (
                      <div className='flex mb-4'>
                        {vendorstatus === 'approve' ? (
                          <div className='flex flex-row px-2 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light'>
                            <div className='w-[70px] pr-2 flex flex-row items-center '>
                              <img src={WarningSVG} alt='' srcSet='' />
                            </div>
                            <span className='text-[13px]'>
                              Ropay will debit{' '}
                              <span className='font-semibold'>
                                {formatNumber(totalAmount, 2)}
                              </span>{' '}
                              from your account. Please Make sure the funds are
                              available.
                            </span>
                          </div>
                        ) : (
                          <div className='flex flex-row px-2 border-secondary-4 border-[1.2px] rounded-lg p-2 bg-color-warning-light'>
                            <div className='w-[70px] pr-2 flex flex-row items-center '>
                              <img src={WarningSVG} alt='' srcSet='' />
                            </div>
                            <span className='text-[13px]'>
                              Ropay will send{' '}
                              <span className='font-semibold'>
                                {formatNumber(totalAmount, 2)}
                              </span>{' '}
                              to authorized aprrovers. Kindly ensure that you
                              have entered have the correct amount for approval.
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4 mt-3'>
                      <FormOtpInput
                        value={pin}
                        valueLength={4}
                        onChange={(value) => setPIN(value)}
                        label={'Enter your new 4 digit PIN'}
                      />
                    </div>
                    <Button
                      text={
                        scheduleType === 'instant'
                          ? 'PAY NOW'
                          : 'SCHEDULE PAYMENT'
                      }
                      type='submit'
                      disabled={
                        scheduleType === 'instant'
                          ? false
                          : scheduleType === 'schedule' &&
                            !scheduleDate &&
                            !mode
                          ? true
                          : false
                      }
                      loading={initiateLoading || disburseLoading}
                      onClick={() => submitForm(refValue)}
                    />
                  </div>
                ) : (
                  <>
                    <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4 mt-3'>
                      <FormOtpInput
                        value={pin}
                        valueLength={4}
                        onChange={(value) => setPIN(value)}
                        label={'Enter your new 4 digit PIN'}
                      />
                    </div>
                    <Button
                      text={'INITIATE PAYMENT'}
                      type='submit'
                      disabled={false}
                      loading={initiateLoading || disburseLoading}
                      onClick={() => submitForm(refValue)}
                    />
                  </>
                )}
              </div>
            ) : vendorstatus !== 'approve' &&
              user?.permissions.approval_permission?.approver === 1 ? (
              <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-highlight mt-4 mb-3 text-[15px] text-center font-semibold'>
                You are not authorised to Initiate payments <br /> Please
                Contact Your Administrator.
              </div>
            ) : (
              <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-highlight mt-4 mb-3 text-[15px] text-center font-semibold'>
                You are not Authorised to Approve payment <br /> Please Contact
                Your Administrator.
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default VendorPaySummary;
