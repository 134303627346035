import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SignInPage from './pages/auth/sign-in';
import RegisterPage from './pages/auth/register';
import Alert from './components/alert';
import { AdminRoutes } from './routes/admin';
import { useEffect } from 'react';
import {
  isLoading,
  setIsLoading,
  updateModalStatus,
} from './redux/components/components-slice';
import { useDispatch } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PageNotFound from './pages/errors/page-not-found';
import ForgotPasswordPage from './pages/auth/forgot';
import ResetPassword from './pages/auth/reset-password';
import CheckEmailPassword from './pages/auth/check-email';
import SetPassword from './pages/auth/set-password';
import {
  OnboardSingleEmployeeRoutes,
  SingleExitRoutes,
  SingleOnboardingRoutes,
} from './routes/payroll/employee';
import GuestRoute from './routes/guest';
import VerifyEmail from './pages/auth/verify-email';
import NotFoundRoute from './routes/not-found';
import { RecruitmentApplicationRoutes } from './routes/hr/recruitment';
import MobileWaitlistPage from './pages/mobile/mobile-waitlist';
import { GuarantorRoutes } from './routes/hr/guarantors';
import classNames from 'classnames';
import OnboardingProvider from './components/provider/onboarding';
import history from './routes/history';
// import { getPushNotificationToken } from './utils/push-notifications/index';
import MobileVerifyEmail from './pages/auth/mobile-verify-email';
import GoogleRedirectPage from './pages/auth/google-redirect';
import MobileResetPassword from './pages/mobile/reset-password';
import PreRegisterEmployee from './pages/employees/employee-pre-registration/complete-registration-information';
import ClockInOutOnSitePage from './pages/hr/attendance-management/onsite-clock-in-out';
import Verify2Fa from './pages/auth/verify-token';
import SubmitOfferLetter from './pages/employees/employee-pre-registration/submit-signed-offer-letter';
import ApplicantVideoPage from './pages/hr/recruitment-application/applicant-video';

const App = () => {
  const { alert, isModalOpen } = useSelector((state) => state.componentsSlice);
  const loading = useSelector(isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    // getPushNotificationToken();

    //stop any interrupted loader on reload
    if (loading) {
      dispatch(setIsLoading(false));
    }
    if (isModalOpen) {
      dispatch(updateModalStatus(false));
    }
  }, []);

  return (
    <OnboardingProvider>
      <>
        <div className={classNames({ 'blur-sm': isModalOpen })}>
          <Router history={history}>
            <Switch>
              <GuestRoute exact path='/' component={SignInPage} />
              <GuestRoute exact path='/register' component={RegisterPage} />
              <GuestRoute exact path='/forgot' component={ForgotPasswordPage} />
              <GuestRoute
                exact
                path='/google-redirect'
                component={GoogleRedirectPage}
              />
              <GuestRoute
                exact
                path='/mobile/waitlist'
                component={MobileWaitlistPage}
              />
              <GuestRoute
                exact
                path='/check-email'
                component={CheckEmailPassword}
              />
              <GuestRoute
                exact
                path='/reset-password/:token'
                component={ResetPassword}
              />
              <GuestRoute
                exact
                path='/set-password/:token'
                component={SetPassword}
              />

              <GuestRoute
                exact
                path='/verify-email/:token'
                component={VerifyEmail}
              />

              <GuestRoute
                exact
                path='/mobile/reset-password/:token'
                component={MobileResetPassword}
              />
              <GuestRoute
                exact
                path='/mobile/verify-email/:token'
                component={MobileVerifyEmail}
              />
              <GuestRoute
                exact
                path='/employee-pre-registration/:token'
                component={PreRegisterEmployee}
              />
              <GuestRoute
                exact
                path='/offer-letter/upload-signed/:token'
                component={SubmitOfferLetter}
              />
              <GuestRoute
                exact
                path='/employees/clockin/on-site/:token'
                component={ClockInOutOnSitePage}
              />
              <GuestRoute
                exact
                path='/verify-2fa/:token'
                component={Verify2Fa}
              />
              <GuestRoute
                exact
                path='/recruitment/video-assessment/:token'
                component={ApplicantVideoPage}
              />
              {SingleExitRoutes()}
              {SingleOnboardingRoutes()}
              {OnboardSingleEmployeeRoutes()}
              {RecruitmentApplicationRoutes()}
              {GuarantorRoutes()}
              {AdminRoutes()}
              <NotFoundRoute path='*' component={PageNotFound} />
            </Switch>
          </Router>
        </div>

        {/* Other components */}
        {alert?.show && <Alert type={alert?.type} message={alert?.message} />}
      </>
    </OnboardingProvider>
  );
};

export default App;
