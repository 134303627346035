import React, { useState } from 'react';
import Button from '../../button';
import ButtonSelect from '../../button-select';
import AddReImbursement from './add-reimbursement';
import BulkAddReimbursement from './bulk-add-reimbursement';
import CloseSVG from '../../../assets/svg/close.svg';
import Modal from '../modal';

function SelectReImbursementOption({ isOpen, closeModal, refresh }) {
  const [option, setOption] = useState('single');
  const [showAddReImbursement, setShowAddReImbursement] = useState(false);
  const [showAddBulkReImbursement, setShowAddBulkReImbursement] =
    useState(false);

  function submit() {
    if (option === 'single') {
      setShowAddReImbursement(true);
    } else if (option === 'bulk') {
      setShowAddBulkReImbursement(true);
    }
  }

  return (
    <>
      {showAddReImbursement ? (
        <AddReImbursement
          isOpen={showAddReImbursement}
          completed={() => {
            setShowAddReImbursement(false);
            closeModal();
          }}
          closeModal={() => {
            setShowAddReImbursement(false);
          }}
          reimbData={null}
          title='Add Reimbursement'
          buttonText='ADD'
        />
      ) : null}
      {showAddBulkReImbursement ? (
      <BulkAddReimbursement
        isOpen={showAddBulkReImbursement}
        closeModal={() => setShowAddBulkReImbursement(false)}
        completed={() => {
          setShowAddBulkReImbursement(false);
          closeModal();
        }}
      />
      ) : null}
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Select reimbursements upload option</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col items-center w-full gap-3'>
            <span className='text-[15px] -mb-6'>
              Select your desired option to add reimbursement
            </span>
            <div className='flex flex-col justify-center h-full min-h-[400px] max-w-[85%] w-full'>
              <ButtonSelect
                title={'Add Single Imbursement'}
                subtitle={'Add employee reimbursement one after the other'}
                isActive={option === 'single'}
                onClick={() => setOption('single')}
              />

              <ButtonSelect
                title={'Import Reimubursement'}
                subtitle={
                  'Import employee reimbursements using a pre-defined XLSX template'
                }
                isActive={option === 'bulk'}
                onClick={() => setOption('bulk')}
                className='mt-8'
              />

              <div className='w-full mt-[20px]'>
                <Button text='NEXT' type='submit' onClick={() => submit()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SelectReImbursementOption;
