import React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useGetBanks, useResolveBankAccount } from "../../../redux/bank/hook";
import { formValidation } from "../../../utils/functions";
import Button from "../../button";
import FormDatalist from "../../form-datalist";
import FormInput from "../../form-input";
import FormSwitch from "../../form-switch";
import LoadingBar from "../../loader-bar";
import {
  useGetEmployee,
  useUpdateEmployeeBank,
} from "../../../redux/employees/hook/index";

const EmployeeBankInformation = () => {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { id } = useParams();

  const { data: employee } = useGetEmployee(id);
  const { mutateAsync: updateEmployee, isLoading: loading } =
    useUpdateEmployeeBank();
  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } =
    useResolveBankAccount();
  const { data: banks, isLoading } = useGetBanks();

  const [editMode, setEditMode] = useState(false);
  const [bankname, setBankName] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [account_name, setAccountName] = useState("");

  useEffect(() => {
    if (employee) {
      setAccountName(employee.account_name);
      setAccountNumber(employee.account_number);
      setBankName(employee.bank);
    }
  }, [employee]);

  useEffect(() => {
    if (account_number.length >= 10 && bankname !== "") {
      let payload = {
        bankname,
        account_number,
      };
      setAccountName("");
      resolveAccount(payload).then((response) => {
        setAccountName(response.data.accountname);
      });
    }
  }, [account_number, bankname]);

  function submit() {
    let payload = {
      employeeId: id,
      data: {
        account_number,
        account_name,
        bank: bankname,
      },
    };
    updateEmployee(payload).then(() => {
      setEditMode(false);
    });
  }

  return (
    <>
      <div className="bg-white rounded h-full w-full py-4 pt-3 min-h-[450px]">
        <div className="header-4 px-4">Bank Information</div>
        <hr className="divider mt-3" />
        {isLoading ? (
          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px] px-4">
            <LoadingBar loading={isLoading} />
          </div>
        ) : (
          <>
            {employee ? (
              <div className="px-4">
                <div className="w-full md:w-[20%] mt-2 !font-semibold">
                  <FormSwitch
                    label={"Edit Mode"}
                    name={"switch-edit-mode"}
                    checked={editMode}
                    onClick={() => setEditMode(!editMode)}
                    value={editMode}
                  />
                </div>
                {editMode ? (
                  <form
                    className="form border p-4 py-2 rounded-md w-full"
                    onSubmit={handleSubmit(submit)}
                  >
                    <div className={"flex flex-col w-full md:w-[100%]"}>
                      <div className="flex flex-col md:flex-row justify-between">
                        <div className="w-full md:w-[250px]">
                          <FormDatalist
                            label="Bank Name"
                            data={banks ? banks : []}
                            value={bankname}
                            onSelect={(value) => {
                              setAccountNumber("");
                              setBankName(value);
                            }}
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="Account Number"
                            name="account_number"
                            placeholder={"Enter an account number"}
                            type="text"
                            value={account_number}
                            error={errors.account_number}
                            errorMessage={
                              errors.account_number &&
                              errors.account_number.message
                            }
                            className={"!bg-white"}
                            onInput={(e) => setAccountNumber(e.target.value)}
                          />
                        </div>
                        <div className="w-full md:w-[250px]">
                          <FormInput
                            label="Account Name"
                            name="account_name"
                            type="text"
                            placeholder={
                              resolveAccountLoading
                                ? "Fetching Account Name ...."
                                : "Auto generated"
                            }
                            readOnly
                            inputRef={register(formValidation("text", true))}
                            value={account_name}
                            error={errors.account_name}
                            className={"!bg-white"}
                            errorMessage={
                              errors.account_name && errors.account_name.message
                            }
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-end w-full mt-[5px]">
                        <Button
                          text="Update Bank Information"
                          type="submit"
                          loading={loading}
                          className="flex gap-2 !h-[35px] w-fit px-2 items-center"
                          textClass={"!text-[11px]"}
                        />
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="flex flex-col md:flex-row border p-4 rounded-md -mt-2">
                    <div className="w-full md:w-[100%]">
                      <div className="grid md:grid-cols-3  grid-cols-1 gap-4 w-full">
                        <div className="flex flex-col">
                          <span className="text-[14px] font-extrabold text-color-gray">
                            Bank name
                          </span>
                          <span className="mt-1 text-color-black text-[13px]">
                            {employee.bank ? employee.bank : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-[14px] font-extrabold text-color-gray">
                            Account Number
                          </span>
                          <span className="mt-1 text-color-black text-[13px]">
                            {employee.account_number
                              ? employee.account_number
                              : "Not Set"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-[15\4px] font-extrabold text-color-gray">
                            Account Name
                          </span>
                          <span className="mt-1 text-color-black text-[13px]">
                            {employee.account_name
                              ? employee.account_name
                              : "Not Set"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default EmployeeBankInformation;
