import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import Modal from '../modal';
import TymsSVG from '../../../assets/svg/the_tyms_color_logo_main.svg';
import TickSVG from '../../../assets/svg/tick.svg';
import { useGenerateTymsIntegrationToken } from '../../../redux/integration/hook';

function TymsIntegrationModal({ isOpen, closeModal, authorizationCode, businessId, refresh }) {

  const { user } = useSelector((state) => state.userSlice);

  const { mutateAsync: generateToken, isLoading: generateTokenLoading } = useGenerateTymsIntegrationToken();

  const [initiating, setInitiating] = useState(true);

  useEffect (() => {
    if (authorizationCode && businessId) {
        generateTymsToken()
    }
  }, [authorizationCode, businessId])

  const generateTymsToken = async () => {
    let payload = {
        authorization_code: authorizationCode,
        business_id: businessId
    }
    await generateToken(payload).then((response) => {
      setInitiating(false)
    });
  };

  const closeIntegrationModal = () => {
    window.location.assign('/settings')
  }

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-4'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Tyms Integration</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='min-h-fit flex flex-col justify-center items-center gap-4 border border-gray-300 rounded-lg bg-highlight duration-300 w-full p-4'>
                <img 
                    src={TymsSVG} 
                    alt={'tyms_logo'} 
                    className={'pl-4 pr-[15px] w-[300px] max-h-[200px]'} />
                {initiating?
                <span className='font-extrabold text-secondary-2 animate-pulse p2'>
                    Finalising Integration...
                </span>
                :
                <div className='flex flex-col items-center justify-center w-full gap-2'>
                    <span className='font-extrabold text-[#248546] p2'>
                        Integration Completed
                    </span>
                    <div
                        className={`flex items-center justify-center w-[50px] h-[50px] rounded-[50px] bg-[#248546]`}
                    >
                        <img
                            src={TickSVG}
                            alt='tick'
                            style={{ width: '30px', height: '30px' }}
                        />
                    </div>
                    <div className='w-full mt-[20px]'>
                        <Button
                            text='CLOSE!'
                            type='button'
                            onClick={() => closeIntegrationModal()}

                        />
                    </div>
                </div>
                } 
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
export default TymsIntegrationModal;
