import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT, exitStatusConfig } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import Button from "../button";
import {
  useDeleteExit,
  useGetResignations,
  useMarkExitComplete,
} from "../../redux/employees/hook/exit";
import { useGetEmployees } from "../../redux/employees/hook";
import SelectExitOption from "../modal/hr-section/employee-exit/select-exit-option";
import AddEmployeeExit from "../modal/hr-section/employee-exit/add-exit";

const ResignationsTab = () => {
  useEffect(() => {}, []);

  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    satusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const [exitData, setExitData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [showAddExitOptions, setShowAddExitOptions] = useState(null);
  const [showAddExit, setShowAddExit] = useState(null);

  const [tableType, setTableType] = useState("employee-exit");

  const switchFunction = () => {
    if (tableType === "employee-exit") {
      setTableType("table");
    } else {
      setTableType("employee-exit");
    }
  };

  const { data: exits } = useGetResignations(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: deleteExit } = useDeleteExit();

  const { mutateAsync: markExit } = useMarkExitComplete();

  const history = useHistory();

  const tableConfig = {
    headers: [
      "Employee Name",
      "Mobile",
      "Notice Date",
      "Resignation Date",
      "Status",
    ],
    keys: [
      "employeeName",
      "mobile",
      "new_notice_date",
      "new_left_date",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "mobile",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = exits.data.findIndex((item) => item.id === id);

        history.push("/exit/view/" + exits.data[index].id);
      },
    },
    {
      name: " Update",
      color: "orange",
      action: (id) => {
        let index = exits.data.findIndex((item) => item.id === id);
        setTitle("Update Employee Resignation");
        setButtonText("Update Resignation");
        setExitData(exits.data[index]);
        setShowAddExit(true);
      },
    },
    {
      name: "Mark As Reviewed",
      color: "green",
      action: async (id) => {
        let index = exits.data.findIndex((item) => item.id === id);
        await markExit({
          exit_id: exits.data[index].id,
          status: exits.data[index].status == 1 ? 0 : 1,
        });
      },
    },
    {
      name: " Remove",
      color: "red",
      action: async (id) => {
        let index = exits.data.findIndex((item) => item.id === id);
        await deleteExit(exits.data[index].id);
      },
    },
    // {
    //   name: 'Remove',
    //   color: 'red',
    //   action: (id) => {
    //     let index = exits.data.findIndex((item) => item.id === id);
    //     history.push('/recruitment/view/' + exits.data[index].id);
    //   }
    // },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddExitOptions ? (
        <SelectExitOption
          isOpen={showAddExitOptions}
          closeModal={() => setShowAddExitOptions(false)}
          type={"resignation"}
        />
      ) : null}

      {showAddExit ? (
        <AddEmployeeExit
          isOpen={showAddExit}
          closeModal={() => setShowAddExit(false)}
          type={"resignation"}
          exitData={exitData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}

      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Resignations
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Resignation"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              disabled={!employees}
              onClick={() => {
                setTitle("Add Employee Resignation");
                setButtonText("Save Resignation");
                setExitData(null);
                setShowAddExitOptions(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={exits}
        tableConfig={tableConfig}
        statusConfig={exitStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        filterByStatus={true}
        computedActions={true}
        tableType={tableType}
        switchFunction={switchFunction}
        layoutSwitch={true}
        searchData={true}
        noCheck={true}
      />
    </div>
  );
};

export default ResignationsTab;
