import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import Modal from '../modal';
import { useGetBanks, useResolveBankAccount } from '../../../redux/bank/hook';
import { useGetReferralData, useUpdateAccountDetails } from '../../../redux/referrals/hook';
import FormDatalist from '../../form-datalist';

function UpdateReferralDetailsModal({
  isOpen,
  closeModal,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const { data: referral, isLoading: generateReferalDataLoading } = useGetReferralData();
  const { mutateAsync: resolveAccount, isLoading: resolveAccountLoading } = useResolveBankAccount();
  const { data: banks, isLoading } = useGetBanks();
  const [bankname, setBankName] = useState(referral?.bank ? referral.bank : '');
  const [account_number, setAccountNumber] = useState(referral?.account_number ? referral.account_number : '');
  const [account_name, setAccountName] = useState(referral?.account_name ? referral.account_name : '');

  const { mutateAsync: updateReferral, isLoading: updateReferralLoading } = useUpdateAccountDetails();
  
  useEffect(() => {
    if (account_number?.length >= 10 && bankname !== "") {
      let payload = {
        bankname,
        account_number,
      };
        setAccountName('');
        resolveAccount(payload).then((response) => {
          setAccountName(response.data.accountname);
      });
    }
  }, [account_number, bankname]);
    
  function submitForm() {
    let payload = {
        account_number,
        account_name,
        bank: bankname,
      };
    updateReferral(payload).then(() => {
      closeModal()
    });
  }

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
            <div className='flex flex-row justify-between pl-8 pr-8 pt-4 items-center'>
                <span className='header-4'>Update Referral Account Details</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
                </div>
            </div>
            <hr className='divider mb-2' />
            <div className='flex flex-col mx-8'>
            <div className='w-full'>
                <FormDatalist
                    label='Bank Name'
                    data={banks ? banks : []}
                    value={bankname}
                    onSelect={(value) => {
                        setAccountNumber('')
                        setBankName(value)}
                    }
                />
            </div>               
            <div className='w-full'>
                <FormInput
                    label='Account Number'
                    name='account_number'
                    type='text'
                    value={account_number}
                    error={errors.account_number}
                    errorMessage={
                        errors.account_number && errors.account_number.message
                    }
                    className={'!bg-white'}
                    onInput={(e)=> setAccountNumber(e.target.value)}
                />
            </div>
            <div className='w-full'>         
                <FormInput
                    label='Account Name'
                    name='account_name'
                    type='text'
                    placeholder={resolveAccountLoading ? "Fetching Account Name ...." : ""}
                    readOnly
                    inputRef={register(formValidation('text', true))}
                    value={account_name}
                    error={errors.account_name}
                    className={'!bg-white'}
                    errorMessage={errors.account_name && errors.account_name.message}
                />
            </div>
            <div className='w-full mt-[20px]'>
              <Button
                text={'Save Account Details'}
                type='submit'
                disabled={updateReferralLoading}
                loading={updateReferralLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default UpdateReferralDetailsModal;
