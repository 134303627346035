import { Axios } from './axios';

const checkReferral = async () => {
    const { data } = await Axios.get(`/code-check`);
    return data;
};

const generateReferral = async (payload) => {
    const { data } = await Axios.post('/referral-codes/generate', payload);
    return data;
};

const getReferralData = async () => {
    const { data } = await Axios.get(`/fetch-referral`);
    return data?.data;
};

const getReferredUsers = async (payload) => {
    const { data } = await Axios.get(`/fetch-referral/users?limit=${payload.limit}&page=${payload.page}`);
    return data?.data;
};

const getReferredCompanies = async (payload) => {
    const { data } = await Axios.get(`/fetch-referral/companies?limit=${payload.limit}&page=${payload.page}`);
    return data?.data;
};

const getReferredTransactions = async (payload) => {
    let type = payload.type ? '&type=' + payload.type : '';
    const { data } = await Axios.get(`/fetch-referral/transactions?limit=${payload.limit}&page=${payload.page}${type}`);
    return data?.data;
};

const updateReferralAccountDetails = async (payload) => {
    const { data } = await Axios.post('/referral-codes/update-account', payload);
    return data;
};

const withdrawReferralBalance = async () => {
    const { data } = await Axios.get('/referral-codes/withdraw');
    return data;
};

export const referrals = {
    checkReferral,
    getReferralData,
    generateReferral, 
    getReferredUsers,
    getReferredCompanies,
    getReferredTransactions,
    updateReferralAccountDetails,
    withdrawReferralBalance
};