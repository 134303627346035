import React, { useEffect, useState } from "react";
import Button from "../../components/button";
import DashboardLayout from "../../components/layouts/dashboard";
import { useSelector } from "react-redux";
import FormSelect from "../../components/form-select";
import FormSelectEmployee from "../../components/employees/form-select-employee";
import PayrollFolder from "../../components/employee-folder/payroll-folder";
import BonusFolder from "../../components/employee-folder/bonus-folder";
import folderSVG from "../../assets/svg/big-folder.svg";
import SalaryAdvanceFolder from "../../components/employee-folder/salary-advance-folder";
import ReimbursementFolder from "../../components/employee-folder/reimbursement-folder";
import QueryFolder from "../../components/employee-folder/query-folder";
import LeaveFolder from "../../components/employee-folder/leave-folder";
import DocumentFolder from "../../components/employee-folder/document-folder";
import OnboardingFolder from "../../components/employee-folder/onboarding-folder";
import PerformanceFolder from "../../components/employee-folder/performance-folder";
import TrainingFolder from "../../components/employee-folder/training-folder";
import GuarantorFolder from "../../components/employee-folder/guarantors-folder";
import SuspensionFolder from "../../components/employee-folder/suspension-folder";
import PromotionFolder from "../../components/employee-folder/promotion-folder";
import HmoFolder from "../../components/employee-folder/hmo-folder";
import AttendanceFolder from "../../components/employee-folder/attendance-folder";
import ProfileFolder from "../../components/employee-folder/pofile-folder";
import SalaryReviewFolder from "../../components/employee-folder/salary-review";

const EmployeeFolder = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [source, setSource] = useState(null);
  const sourceOptions = [
    { label: "Select folder", value: null },
    { label: "Attendance", value: "attendance" },
    { label: "Bonuses", value: "bonus" },
    { label: "Documents", value: "document" },
    { label: "Employee Profile", value: "profile" },
    { label: "Guarantors", value: "guarantor" },
    { label: "HMO", value: "hmo" },
    { label: "Leaves", value: "leave" },
    { label: "Onboarding", value: "onboarding" },
    { label: "Payrolls", value: "payroll" },
    { label: "Performance Appraisals", value: "performance" },
    { label: "Promotions", value: "promotion" },
    { label: "Queries", value: "query" },
    { label: "Reimbursements", value: "reimbursement" },
    { label: "Salary Advances", value: "salary-advance" },
    { label: "Salary Reviews", value: "salary-review" },
    { label: "Suspensions", value: "suspension" },
    { label: "Trainings", value: "training" },
    // useGetSalaryReviewsByEmployee
  ];
  const [employee, setEmployee] = useState(null);
  const [employeeName, setEmployeeName] = useState();

  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [currentSource, setCurrentSource] = useState(null);

  return (
    <DashboardLayout title="Employee Cabinet">
      <div className="flex flex-col -mt-2">
        <div className="flex flex-col bg-white mt-2 rounded-md">
          <div className="px-3">
            <div className="flex flex-col md:flex-row justify-between items-center px-4 md:gap-4 my-1 mt-3 border bg-highlight rounded-md pt-2">
              <div className="w-full md:w-1/3">
                <FormSelectEmployee
                  label={"Employee"}
                  name="employee"
                  placeholder="Type to select Employee"
                  value={employee}
                  empName={employeeName}
                  setEmployee={setEmployee}
                />
              </div>
              <div className="w-full md:w-1/3">
                <FormSelect
                  defaultValue={source}
                  options={sourceOptions}
                  onChange={(selected) => {
                    setSource(selected);
                  }}
                  label="Select Folder"
                  extraClass={"bg-white !text-[13px]"}
                />
              </div>
              <div className="flex items-center w-full md:w-1/3 md:mt-2 md:ml-3">
                <Button
                  text="FETCH DATA"
                  type="button"
                  theme="primary"
                  className="flex gap-2 !h-[35px] min-w-[150px] md:max-w-[200px] p-2 "
                  textClass={"!text-[13px]"}
                  onClick={() => {
                    setCurrentSource(source);
                    setCurrentEmployee(employee);
                  }}
                />
              </div>
            </div>
          </div>
          <hr className="divider mt-2 mb-2" />
          {currentEmployee && currentSource ? (
            <>
              {currentSource == "payroll" ? (
                <PayrollFolder employeeId={currentEmployee} />
              ) : currentSource == "bonus" ? (
                <BonusFolder employeeId={currentEmployee} />
              ) : currentSource == "salary-advance" ? (
                <SalaryAdvanceFolder employeeId={currentEmployee} />
              ) : currentSource == "reimbursement" ? (
                <ReimbursementFolder employeeId={currentEmployee} />
              ) : currentSource == "suspension" ? (
                <SuspensionFolder employeeId={currentEmployee} />
              ) : currentSource == "query" ? (
                <QueryFolder employeeId={currentEmployee} />
              ) : currentSource == "leave" ? (
                <LeaveFolder employeeId={currentEmployee} />
              ) : currentSource == "document" ? (
                <DocumentFolder employeeId={currentEmployee} />
              ) : currentSource == "onboarding" ? (
                <OnboardingFolder employeeId={currentEmployee} />
              ) : currentSource == "performance" ? (
                <PerformanceFolder employeeId={currentEmployee} />
              ) : currentSource == "training" ? (
                <TrainingFolder employeeId={currentEmployee} />
              ) : currentSource == "profile" ? (
                <ProfileFolder employeeId={currentEmployee} />
              ) : currentSource == "guarantor" ? (
                <GuarantorFolder employeeId={currentEmployee} />
              ) : currentSource == "hmo" ? (
                <HmoFolder employeeId={currentEmployee} />
              ) : currentSource == "attendance" ? (
                <AttendanceFolder employeeId={currentEmployee} />
              ) : currentSource == "hmo" ? (
                <TrainingFolder employeeId={currentEmployee} />
              ) : currentSource == "salary-review" ? (
                <SalaryReviewFolder employeeId={currentEmployee} />
              ) : currentSource == "promotion" ? (
                <PromotionFolder employeeId={currentEmployee} />
              ) : (
                <div className="flex flex-col items-center justify-center w-full min-h-[80px] p-4">
                  <span className="text-hr-primary-1 font-bold">
                    No Folder selected
                  </span>
                  <div className="flex items-center justify-center  mt-4">
                    <img
                      src={folderSVG}
                      alt="no_folder"
                      width={"60%"}
                      height={"100px"}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-col items-center justify-center w-full min-h-[80px] p-4">
              <span className="text-hr-primary-1 font-bold">
                No Employee Folder selected
              </span>
              <div className="flex items-center justify-center mt-2 select-none">
                <img
                  src={folderSVG}
                  alt="no_employee"
                  width={"70%"}
                  height={"150px"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EmployeeFolder;
