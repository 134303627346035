import React from "react";
import classNames from "classnames";

export const Card = ({ title, subtitle, icon, className }) => {
  return (
    <div
      className={`flex flex-row items-center md:justify-between justify-between gap-2 !min-w-full md:h-[80px] h-[100px] p-3 pl-0  bg-white rounded-md ${className} border`}
    >
      <div className="flex flex-col items-start justify-evenly gap-2">
        <span className="font-bold text-[13px] leading-[12px] text-color-gray flex flex-col text-center">
          {title}
        </span>
        <span className="font-bold text-[15px]">{subtitle}</span>
      </div>
      <div className={classNames("flex justify-end items-center min-w-[30%]")}>
        <img src={icon} alt="" height={"40px"} width={"40px"} />
      </div>
    </div>
  );
};
