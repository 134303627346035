import React, { useEffect } from "react";
import DataTable from "../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import AddPerformanceWorkflow from "../../modal/hr-section/performance/add-workflow";
import { useGetPerformanceWorkflows } from "../../../redux/human-resources/hook/performance";
import Button from "../../button";

const PerformanceWorkflowTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: performance_workflows } = useGetPerformanceWorkflows(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const [workflowData, setWorkflowData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const [tableType, setTableType] = useState("workflow");

  const switchFunction = () => {
    if (tableType === "workflow") {
      setTableType("table");
    } else {
      setTableType("workflow");
    }
  };

  const [showAddWorkflow, setShowAddWorkflow] = useState(null);

  const history = useHistory();

  const tableConfig = {
    headers: ["Date created", "Workflow Name", "Description"],
    keys: ["created", "name", "description"],
    mobileHeader: {
      left: {
        title: "Workflow Name",
        key: "name",
      },
      right: {
        title: "Description",
        key: "description",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "green",
      action: (id) => {
        let index = performance_workflows.data.findIndex(
          (item) => item.id === id
        );
        setTitle("Update Performance Workflow");
        setButtonText("Update Workflow");
        setWorkflowData(performance_workflows.data[index]);
        setShowAddWorkflow(true);
      },
    },
  ];

  return (
    <div className="flex flex-col -mt-2">
      <div className="flex flex-col -mt-2">
        <div className=" bg-white border rounded-md text-[13px] p-2 text-gray-600">
          <span className="font-bold text-[14px]">
            What are Basic Performance Criteria?
          </span>{" "}
          these are for small companies that want to do a quick evaluation of
          their teams, by setting quick indicators. This is the most basic
          evaluation criteria available on the roPay platform
        </div>
      </div>
      <div className="flex flex-col bg-white rounded mt-4">
        {showAddWorkflow ? (
          <AddPerformanceWorkflow
            isOpen={showAddWorkflow}
            closeModal={() => setShowAddWorkflow(false)}
            workflowData={workflowData}
            title={title}
            buttonText={buttonText}
          />
        ) : null}

        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Basic Performance Criteria
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Workflow"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                onClick={() => {
                  setTitle("Add Performance Workflow");
                  setButtonText("Save Workflow");
                  setWorkflowData(null);
                  setShowAddWorkflow(true);
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={performance_workflows}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          computedActions={true}
          tableType={tableType}
          switchFunction={switchFunction}
          layoutSwitch={true}
          searchData={true}
        />
      </div>
    </div>
  );
};

export default PerformanceWorkflowTab;
