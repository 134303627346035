import React from "react";
import ropayLogoSVG from "../assets/img/logo-new.svg";
import ropayLogoWhiteSVG from "../assets/img/logo-white.svg";

const Logo = ({ width, height, white }) => {
  return (
    <div className="logo_container">
      <img
        src={white ? ropayLogoWhiteSVG : ropayLogoSVG}
        alt="Ropay logo"
        style={{
          width: width ? width : "",
          height: height ? height : "",
        }}
      />
    </div>
  );
};

export default Logo;
