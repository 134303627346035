import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import Button from "../button";
import {
  useDeleteOnboardingWorkflow,
  useGetOnboardingWorkflows,
} from "../../redux/employees/hook/onboarding";
import AddOnboardingWorkflow from "../modal/hr-section/employee-onboarding/add-workflow";
import AddOnboardingSubWorkflow from "../modal/hr-section/employee-onboarding/add-subworkflow";

const OnboardingWorkflowTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: onboarding_workflows } = useGetOnboardingWorkflows(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );
  const { mutateAsync: deleteWorkflow } = useDeleteOnboardingWorkflow();

  const [workflowData, setWorkflowData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const [tableType, setTableType] = useState("workflow");

  const switchFunction = () => {
    if (tableType === "workflow") {
      setTableType("table");
    } else {
      setTableType("workflow");
    }
  };

  const [showAddWorkflow, setShowAddWorkflow] = useState(null);

  const [workflowDataForSub, setWorkflowDataForSub] = useState(null);

  const [showAddSubWorkFlow, setShowAddSubWorkflow] = useState(false);

  const history = useHistory();

  const tableConfig = {
    headers: ["Date created", "Workflow Name", "Description"],
    keys: ["created", "name", "description"],
    mobileHeader: {
      left: {
        title: "Workflow Name",
        key: "name",
      },
      right: {
        title: "Description",
        key: "description",
      },
    },
  };

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = onboarding_workflows.data.findIndex(
          (item) => item.id === id
        );
        history.push(
          "/onboarding/workflow/view/" + onboarding_workflows.data[index].id
        );
      },
    },
    {
      name: "Edit",
      color: "green",
      action: (id) => {
        let index = onboarding_workflows.data.findIndex(
          (item) => item.id === id
        );
        setTitle("Update Onboarding Workflow");
        setButtonText("Update Workflow");
        setWorkflowData(onboarding_workflows.data[index]);
        setShowAddWorkflow(true);
      },
    },
    {
      name: "Add Subworkflow",
      color: "green",
      action: (id) => {
        let index = onboarding_workflows.data.findIndex(
          (item) => item.id === id
        );
        setTitle("Update Onboarding Workflow");
        setButtonText("Update Workflow");
        setWorkflowDataForSub(onboarding_workflows.data[index]);
        setShowAddSubWorkflow(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = onboarding_workflows.data.findIndex(
          (item) => item.id === id
        );
        deleteWorkflow(onboarding_workflows.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddSubWorkFlow ? (
        <AddOnboardingSubWorkflow
          title={"Add Sub Workflows"}
          isOpen={showAddSubWorkFlow}
          workflowData={workflowDataForSub}
          buttonText="Save and Complete"
          closeModal={() => setShowAddSubWorkflow(false)}
        />
      ) : null}
      {showAddWorkflow ? (
        <AddOnboardingWorkflow
          isOpen={showAddWorkflow}
          closeModal={() => setShowAddWorkflow(false)}
          workflowData={workflowData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Onboarding Workflows
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Workflow"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setTitle("Add Onboarding Workflow");
                setButtonText("Save and Continue");
                setWorkflowData(null);
                setShowAddWorkflow(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={onboarding_workflows}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        tableType={tableType}
        switchFunction={switchFunction}
        layoutSwitch={true}
        searchData={true}
      />
    </div>
  );
};

export default OnboardingWorkflowTab;
