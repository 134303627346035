import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../components/layouts/dashboard";
import { useHistory, useParams } from "react-router-dom";
import LoadingBar from "../../../../components/loader-bar";
import Button from "../../../../components/button";
import { Initials } from "../../../../utils/functions";
import {
  useDeletePromotion,
  useGetSingleEmployeePromotion,
} from "../../../../redux/employees/hook/promotion";
import { ViewPromotionSalaryReview } from "../../../../components/modal/hr-section/employee-promotion/promotion-salary-review";

const ViewPromotion = () => {
  useEffect(() => {}, []);

  const { id } = useParams();

  const { data: promotion, isLoading } = useGetSingleEmployeePromotion(id);

  const history = useHistory();

  const { mutateAsync: deletePromotion, isLoading: deleteLoading } =
    useDeletePromotion();

  const deleteEmployeePromotion = () => {
    deletePromotion(id).then(() => {
      history.push("/promotion");
    });
  };

  return (
    <DashboardLayout title="View Employee Promotion">
      '
      <div className="flex flex-col gap-4 -mt-8">
        <div className="flex flex-row items-center box-shadow h-[104px]">
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-row px-[55px] justify-between  w-full">
              <div className="flex flex-row items-center">
                <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200">
                  {Initials(promotion?.first_name, promotion?.last_name)}
                </div>
                <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                  <span className="p1-bold">
                    {promotion?.first_name} {promotion?.last_name}
                  </span>
                  <span className="p4 text-color-gray">{promotion?.email}</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col bg-white min-h-fit">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] mt-3">
            <div className=" header-4">Promotion Details</div>
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:items-center">
              {promotion?.salary_review_id ? null : (
                <div className="md:w-fit w-[100%]">
                  <Button
                    text="Review Promotion Salary"
                    type="button"
                    className="flex gap-2 !h-[35px] w-fit p-2 "
                    textClass={"!text-[11px]"}
                    onClick={() => {
                      history.push(
                        `/employees/review/review-salary-information?type=single&salary=true&promotionId=${id}&employeeId=${promotion?.employee_id}&employeeName=${promotion?.first_name} ${promotion?.last_name}&effectiveDate=${promotion?.effective_date}`
                      );
                    }}
                  />
                </div>
              )}
              <div className="md:w-fit w-[100%]">
                <Button
                  text="Delete Promotion"
                  type="button"
                  theme={"primary"}
                  leftIcon={"delete"}
                  className="flex gap-2 !h-[35px] w-fit p-2 "
                  textClass={"!text-[12px] text-hr-primary-1"}
                  onClick={() => {
                    deleteEmployeePromotion();
                  }}
                  loading={deleteLoading}
                />
              </div>
            </div>
          </div>
          <hr className="divider" />
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-col md:flex-row h-full p-4 pt-1">
              <div className="flex flex-col gap-2 w-[100%]">
                <div className="w-[100%] h-fit mt-3">
                  <ViewPromotionSalaryReview
                    reviewId={promotion?.salary_review_id}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewPromotion;
