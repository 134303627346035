import React from "react";
import DataTable from "../../datatable";
import { useState, useEffect } from "react";
import {
  PAGINATION_DEFAULT,
  salaryAdvanceStatusConfig,
} from "../../../utils/constants";
import { useParams } from "react-router-dom";
import {
  useDeleteSalaryAdvance,
  useSingleGetEmployeeSalaryAdvances,
} from "../../../redux/employees/hook/salary-advance";
import AddSalaryAdvance from "../../modal/employees/add-salary-advance";
import Button from "../../button";
import EmployeeSalaryAdvanceApplications from "../../modal/employees/view-employee/employee-salary-advance-applications";

const SingleEmployeeSalaryAdvanceTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
  });

  const { id } = useParams();

  const { mutateAsync: deleteSalaryAdvance } = useDeleteSalaryAdvance();

  const { refetch, data: employeeSalaryAdvances } =
    useSingleGetEmployeeSalaryAdvances(
      id,
      pagination?.limit,
      pagination?.page,
      pagination?.statusFilter
    );

  const [showSalaryAdvanceApplications, setShowSalaryAdvanceApplications] =
    useState(false);
  const [saladData, setSaladData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState("Add Salary Advance");
  const [showAddSalaryAdvance, setShowAddSalaryAdvance] = useState(false);

  const tableConfig = {
    headers: [
      "Employee Name",
      "Phone number",
      "Purpose",
      "Month",
      "Amount",
      "Status",
    ],
    keys: [
      "employeeName",
      "phone",
      "purpose",
      "formatted_month",
      "formatted_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };

  const actionConfig = [
    {
      name: "Edit/View",
      color: "green",
      action: (id) => {
        let index = employeeSalaryAdvances.data.findIndex(
          (item) => item.id === id
        );
        setButtonText("Update");
        setTitle("Update Salary Advance");
        setSaladData(employeeSalaryAdvances.data[index]);
        setShowAddSalaryAdvance(true);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = employeeSalaryAdvances.data.findIndex(
          (item) => item.id === id
        );
        deleteSalaryAdvance(employeeSalaryAdvances.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showSalaryAdvanceApplications ? (
        <EmployeeSalaryAdvanceApplications
          isOpen={showSalaryAdvanceApplications}
          closeModal={() => setShowSalaryAdvanceApplications(false)}
        />
      ) : null}
      {showAddSalaryAdvance ? (
        <AddSalaryAdvance
          isOpen={showAddSalaryAdvance}
          closeModal={() => setShowAddSalaryAdvance(false)}
          saladData={saladData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Salary Advances/Loans
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Salary Advance"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setSaladData(null);
                setButtonText("Save");
                setTitle("Add Salary Advance");
                setShowAddSalaryAdvance(true);
              }}
            />
          </div>
          <div className="md:w-fit w-[100%]">
            <Button
              text="Applications"
              type="submit"
              theme="primary"
              leftIcon={"view"}
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              onClick={() => setShowSalaryAdvanceApplications(true)}
            />
          </div>
          <div className="md:w-fit w-[100%]">
            <Button
              text="Refetch"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"change"}
              onClick={() => {
                refetch();
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-2" />
      <DataTable
        data={employeeSalaryAdvances}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        statusConfig={salaryAdvanceStatusConfig}
        updatePagination={(data) => setPagination(data)}
        noCheck={true}
      />
    </div>
  );
};

export default SingleEmployeeSalaryAdvanceTab;
