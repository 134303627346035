import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CloseSVG from '../../../assets/svg/close.svg';
import { useGetAdmins } from '../../../redux/admin/hook';
import { useTransferAccountOwnership } from '../../../redux/company/hook';
import Button from '../../button';
import FormDatalist from '../../form-datalist';
import Modal from '../modal';

function TransferOwnership({ isOpen, closeModal, refresh }) {
  const { data: admins } = useGetAdmins();

  const { user } = useSelector((state) => state.userSlice);

  const { mutateAsync: transferAccount, isLoading: loading } =
    useTransferAccountOwnership();

  const [admin, setAdmin] = useState('');

  const submitForm = async () => {
    await transferAccount({ user_id: admin }).then(() => {
      window.location.reload();
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-4'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Transfer Account Ownership</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col md:flex-col w-full justify-between'>
              <FormDatalist
                label='Select Admin'
                data={
                  admins ? admins.filter((item) => item.value !== user?.id) : []
                }
                onSelect={(value) => setAdmin(value)}
              />
            </div>
            <div className='w-full mt-[10px]'>
              <Button
                text='Transfer Ownership'
                type='button'
                disabled={admin && admin !== user?.id ? false : true}
                loading={loading}
                onClick={() => submitForm()}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
export default TransferOwnership;
