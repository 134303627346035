import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/layouts/dashboard";
import ApprovalSettings from "../../components/settings/approval-settings";
import ManageCompany from "../../components/settings/manage-company";
import PlansAndBillings from "../../components/settings/plans-and-billings";
import SalarySettings from "../../components/settings/salary-settings";
import { useRouteQuery } from "../../hooks/useRouteQuery";
import UnauthorizedPage from "../errors/unauthorized";
import Integration from "../../components/settings/integrations";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TymsIntegrationModal from "../../components/modal/settings/tyms-integration";
import AdminManagement from "../../components/settings/admin-management";

const SettingsPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  const [authorizationCode, setAuthorizationCode] = useState();
  const [businessId, setBusinessId] = useState();
  const [showTymsIntegration, setShowTymsIntegration] = useState(false);

  const query = useRouteQuery();

  const { integrationtype } = useParams();

  const [currentLocation, setCurrentLocation] = useState("admin-management");

  const location = useLocation();

  useEffect(() => {
    if (location) {
      setCurrentLocation(location.pathname.split("/")[2]);
    }
  }, [location]);

  useEffect(() => {
    if (integrationtype) {
      if (integrationtype === "tyms-integration") {
        setCurrentLocation("integrations");
        if (query) {
          if (query.get("authorization_code") && query.get("business_id")) {
            setAuthorizationCode(query.get("authorization_code"));
            setBusinessId(query.get("business_id"));
            setShowTymsIntegration(true);
          }
        }
      }
    }
  }, [integrationtype]);

  useEffect(() => {
    if (user) {
      if (user.role !== "Owner") {
        if (user.permissions?.company_permission?.setting_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  return (
    <DashboardLayout title={"Settings"}>
      {showTymsIntegration ? (
        <TymsIntegrationModal
          isOpen={showTymsIntegration}
          closeModal={() => setShowTymsIntegration(false)}
          authorizationCode={authorizationCode}
          businessId={businessId}
        />
      ) : null}
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <div className="flex flex-col -mt-2" id="settings">
            {currentLocation === "admin-management" ? (
              <>
                {user?.permissions?.company_permission?.role === "Owner" ||
                user?.permissions?.company_permission?.setting_read === 1 ? (
                  <AdminManagement />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "company-information" ? (
              <>
                {user?.permissions?.company_permission?.role === "Owner" ? (
                  <ManageCompany />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "approval-settings" ? (
              <>
                {user?.permissions?.company_permission?.role === "Owner" ? (
                  <ApprovalSettings />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "payroll-settings" ? (
              <>
                {user?.permissions?.company_permission?.role === "Owner" ||
                user?.permissions?.company_permission?.setting_read === 1 ? (
                  <SalarySettings />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "plans-billings" ? (
              <>
                {user?.permissions?.company_permission?.role === "Owner" ? (
                  <PlansAndBillings />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : currentLocation === "integrations" ? (
              <>
                {user?.permissions?.company_permission?.role === "Owner" ? (
                  <Integration />
                ) : (
                  <UnauthorizedPage />
                )}
              </>
            ) : null}
        </div>
      )}
    </DashboardLayout>
  );
};

export default SettingsPage;
