import React, { useEffect } from "react";
import DataTable from "../../../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../../../utils/constants";
import { useParams } from "react-router-dom";
import {
  useDeleteQuestion,
  useGetRecruitmentQuestions,
} from "../../../../redux/human-resources/hook/recruitment";
import Button from "../../../button";
import AddQuestions from "../../../modal/hr-section/recruitment/add-questions";
import UpdateQuestion from "../../../modal/hr-section/recruitment/update-question";

const RecruitmentQuestionsTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });
  const { id } = useParams();

  const [questionData, setQuestionData] = useState();

  const { data: recruitment_questions } = useGetRecruitmentQuestions(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const tableConfig = {
    headers: ["Date Added", "Question", "Question Type"],
    keys: ["created", "question", "question_type"],
    mobileHeader: {
      left: {
        title: "Question",
        key: "question",
      },
      right: {
        title: "Question Type",
        key: "question_type",
      },
    },
  };

  const [showAddQuestions, setshowAddQuestions] = useState(false);
  const [showUpdateQuestions, setshowUpdateQuestions] = useState(false);

  const { mutateAsync: removeQuestion } = useDeleteQuestion();

  const actionConfig = [
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = recruitment_questions.data.findIndex(
          (item) => item.id === id
        );
        setQuestionData(recruitment_questions.data[index]);
        setshowUpdateQuestions(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = recruitment_questions.data.findIndex(
          (item) => item.id === id
        );
        removeQuestion(recruitment_questions.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddQuestions ? (
        <AddQuestions
          isOpen={showAddQuestions}
          questionCount={recruitment_questions?.data.length}
          recruitmentId={id}
          title={"Add Question(s)"}
          closeModal={() => setshowAddQuestions(false)}
        />
      ) : null}
      {showUpdateQuestions ? (
        <UpdateQuestion
          isOpen={showUpdateQuestions}
          questionData={questionData}
          title={"Update Question"}
          closeModal={() => setshowUpdateQuestions(false)}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Recruitment Questions
        </div>
        {!recruitment_questions || recruitment_questions?.data.length < 10 ? (
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Question(s)"
                type="submit"
                onClick={() => {
                  setshowAddQuestions(true);
                }}
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
              />
            </div>
          </div>
        ) : null}
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={recruitment_questions}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        noCheck={true}
      />
    </div>
  );
};

export default RecruitmentQuestionsTab;
