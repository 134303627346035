import DOMPurify from 'dompurify';
import React from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import Modal from '../modal';
import { useGenerateReferral } from '../../../redux/referrals/hook';

function GenerateReferralCode({ isOpen, closeModal}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
  const { mutateAsync: generateReferral, isLoading: generateReferralLoading } = useGenerateReferral();

  const submitForm = async (data) => {
    let payload = {
      referral_code: DOMPurify.sanitize(data?.referral_code),
    };
    await generateReferral(payload).then(() => {
      closeModal()
    })
  };

  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[700px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form' onSubmit={handleSubmit(submitForm)}>
            <div className='pl-8 pr-8 pt-4'>
              <div className='flex flex-row justify-between items-center'>
                <span className='header-4'>Generate Referral Link</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='close' />
                </div>
              </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col mx-8'>
              <div className='flex flex-col md:flex-col w-full justify-between'>
                <FormInput
                  label='Referral Code'
                  name='referral_code'
                  type='text'
                  placeholder={'Enter preferred referral code'}
                  inputRef={register(formValidation('text', true))}
                  error={errors.referral_code}
                  errorMessage={
                    errors.referral_code && errors.referral_code.message
                  }
                />
              </div>

              <div className='w-full mt-3 pb-3'>
                <Button 
                  text='Generate code' 
                  type='submit' 
                  loading={generateReferralLoading}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default GenerateReferralCode;
