import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import TabView from "../../components/tabview";
// import { StatusText } from "../../components/fragments/status-text";
// import { employeeStatusConfig } from "../../utils/constants";
import { useGetEmployeeGroup } from "../../redux/employees/hook/groups";
import GroupOverviewTab from "../../components/employees/group/group-overview-tab";
import GroupSettings from "../../components/employees/group/group-settings-tab";
import { useSelector } from "react-redux";
import UnauthorizedPage from "../errors/unauthorized";
import { Initials } from "../../utils/functions";

const ViewEmployeeGroupPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.employee_permission.group_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const { data: group } = useGetEmployeeGroup(id);

  const GroupTabs = [
    {
      title: "Payroll Group Overview",
      Content:
        user?.permissions?.employee_permission?.group_read === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? GroupOverviewTab
          : UnauthorizedPage,
    },
    {
      title: "Payroll Group Settings",
      Content:
        user?.permissions?.employee_permission?.group_write === 1 ||
        user?.permissions?.company_permission?.role === "Owner"
          ? GroupSettings
          : UnauthorizedPage,
    },
  ];

  return (
    <DashboardLayout title="View Employee Payroll Group">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex flex-row items-center box-shadow h-[104px]">
              <div className="flex flex-row px-[55px] justify-between  w-full">
                <div className="flex flex-row items-center">
                  <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-[#FFC0CB]">
                    {Initials(group?.name)}
                  </div>
                  <div className="flex flex-col pl-4 content-center justify-center">
                    <span className="p1-bold">{group?.name}</span>
                  </div>
                </div>
                {/* <div className="flex flex-row items-center">
                  <StatusText
                    statusConfig={employeeStatusConfig}
                    code={group?.status}
                    className={"py-2 rounded p4-bold"}
                  />
                </div> */}
              </div>
            </div>

            <div className="mt-8">
              <TabView
                Tabs={GroupTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default ViewEmployeeGroupPage;
