import { user } from '../../api';
import { store } from '..';
import { clearToken, clearTwoFaToken, clearUser, setToken, setTwoFaToken, setUser, setWebToken } from './user-slice';
import { Axios } from '../../api/axios';
import { queryClient } from '../..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { setCompany } from '../company/reducer';
import { updateSectionData } from '../section/hook';
import { useMutation } from '@tanstack/react-query';

export function useLogin() {
  return useMutation(
    (payload) => {
      return user.login(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        if (response?.data?.token) {
          store.dispatch(setTwoFaToken(response?.data))
        } else {
          store.dispatch(setToken(response.data.authorization.token));
          store.dispatch(setCompany(response.data?.company));
          store.dispatch(setUser(response.data));
          store.dispatch(setAlert(true, 'success', 'You have successfully logged in'));
          updateSectionData(response.data.section);
        }
        
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGoogleLogin() {
  return useMutation(
    (payload) => {
      return user.googleLogin(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        if (response?.data?.token) {
          store.dispatch(setTwoFaToken(response?.data))
        } else {
          store.dispatch(setToken(response.data.authorization.token));
          store.dispatch(setCompany(response.data?.company));
          store.dispatch(setUser(response.data));
          store.dispatch(setAlert(true, 'success', 'You have successfully logged in'));
          updateSectionData(response.data.section);
        }
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}


export function useSignUp() {
  return useMutation(
    (payload) => {
      return user.register(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        // store.dispatch(setCompany(response.data?.company));
        updateSectionData(response.data.section);
        store.dispatch(setAlert(true, 'success', 'You have successfully signed up'));
        store.dispatch(setToken(response.data.authorization.token));
        store.dispatch(setUser(response.data));
        
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRecoveryPassword() {
  return useMutation(
    (payload) => {
      return user.passwordRecover(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Password recovery email sent successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useVerifyEmail() {
  return useMutation(
    (payload) => {
      return user.verifyEmail(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Email verified successfully'));
      },
    },
  );
}

export function usePhoneVerification() {
  return useMutation(
    (payload) => {
      return user.phoneVerification(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('company-verification');
        store.dispatch(setAlert(true, 'success', 'Phone verified successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBVNVerification() {
  return useMutation(
    (payload) => {
      return user.bvnVerification(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('company-verification');
        store.dispatch(setCompany(response.data?.company));
        store.dispatch(setUser(response.data));
        store.dispatch(setAlert(true, 'success', 'BVN verified successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useCACVerification() {
  return useMutation(
    (payload) => {
      return user.cacVerification(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('company-verification');
        store.dispatch(setAlert(true, 'success', 'CAC verified successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useResendEmailVerification() {
  return useMutation(
    (payload) => {
      return user.resendEmailVerification(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Email verified successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useResetPassword() {
  return useMutation(
    (payload) => {
      return user.passwordReset(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Password reset successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useMobileResetPassword() {
  return useMutation(
    (payload) => {
      return user.mobilePasswordReset(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Password reset successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSetPassword() {
  return useMutation(
    (payload) => {
      return user.adminPasswordSet(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setToken(response.data.authorization.token));
        store.dispatch(setCompany(response.data?.company));
        store.dispatch(setUser(response.data));
        store.dispatch(setAlert(true, 'success', 'Password set successfully. You are successfully logged in'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useFetchUserWithToken() {
  return useMutation(
    (payload) => {
      return user.fetchUserWithToken(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Employee deleted successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useFetchUserWithEmailToken() {
  return useMutation(
    (payload) => {
      return user.useFetchUserWithEmailToken(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'User fetched successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function usePasswordUpdate() {
  return useMutation(
    (payload) => {
      return user.updatePassword(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setToken(response.data.authorization.token));
        store.dispatch(setAlert(true, 'success', 'Password updated successfully'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useEnable2Fa() {
  return useMutation(
    (payload) => {
      return user.enable2Fa(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Two-factor Authentication initiated successfully.'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useActivate2Fa() {
  return useMutation(
    (payload) => {
      return user.activate2fa(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        updateUser({reload: false})
        store.dispatch(setAlert(true, 'success', 'Two-factor Authentication Activated successfully.'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useDisable2Fa() {
  return useMutation(
    (payload) => {
      return user.disable2Fa(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        updateUser({reload: false})
        store.dispatch(setAlert(true, 'success', 'Two-factor Authentication disabled successfully.'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useLogin2Fa() {
  return useMutation(
    (payload) => {
      return user.login2Fa(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setToken(response.data.authorization.token));
        store.dispatch(setCompany(response.data?.company));
        store.dispatch(setUser(response.data));
        store.dispatch(clearTwoFaToken())
        updateSectionData(response.data.section);
        
        store.dispatch(setAlert(true, 'success', 'You have successfully logged in'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBackupLogin2Fa() {
  return useMutation(
    (payload) => {
      return user.backupLogin2Fa(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setToken(response.data.authorization.token));
        store.dispatch(setUser(response.data));
        store.dispatch(setCompany(response.data.company));
        store.dispatch(clearTwoFaToken())
        updateSectionData(response.data.section);
        store.dispatch(setAlert(true, 'success', 'You have successfully logged in'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export const updateUser = ({ reload = false, route = '' }) => {
  user.profile().then((response) => {
    let user = JSON.parse(JSON.stringify(response.data));

    store.dispatch(setUser(user));
    store.dispatch(setCompany(user.company));

    setTimeout(() => {
      if (reload) {
        window.location.reload(true);
      }
      if (route) {
        window.location.replace(route);
      }
    }, 2000);
  });
};

export const updateUserPassword = async (payload) => {
  // store.dispatch(setIsLoading(true));
  user
    .updatePassword(payload)
    .then((response) => {
      store.dispatch(setCompany(response.data?.company));
      store.dispatch(setUser(response.data));
    })
    .finally(() => {
      // store.dispatch(setIsLoading(false));
    });
};

export const logout = async () => {
  store.dispatch(clearUser());
  store.dispatch(clearToken());
  store.dispatch(clearTwoFaToken());
  queryClient.invalidateQueries();
  delete Axios.defaults.headers.common['Authorization'];
  store.dispatch(setAlert(true, 'warning', 'You are successfully logged out.'));
};

export function useSwitchSection() {
  return useMutation(
    (payload) => {
      return user.switchSection(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        updateSectionData(response.data.section);
        store.dispatch(
          setAlert(true, 'success', 'Module Switched Successfully'),
        );

        response.data.onboardingStatus = 4;
        store.dispatch(setUser(response.data));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateProfile() {
  return useMutation(
    (payload) => {
      return user.updateProfile(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'User Updated Successfully'));
        response.data.onboardingStatus = 4;
        store.dispatch(setUser(response.data));
        queryClient.invalidateQueries('company-admins');
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useUpdateMobile() {
  return useMutation(
    (payload) => {
      return user.updateMobile(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'User mobile updated successfully'));
        updateUser({reload: false})
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useRequestPINChangeOTP() {
  return useMutation(
    () => {
      return user.requestPINChangeOTP();
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Tovek has been sent to your email'));
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useAddTransactionPIN() {
  return useMutation(
    (payload) => {
      return user.addTransactionPIN(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Transaction PIN Added Successfully'));
        updateUser({reload: false})
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );

}

export function useUpdateTransactionPIN() {
  return useMutation(
    (payload) => {
      return user.updateTransactionPIN(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Transaction PIN Updated Successfully'));
        updateUser({reload: false})
      },
      onSettled: (response, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}


