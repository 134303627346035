import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../../datatable";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import {
  useExportTax,
  useGetTaxHistory,
  useSaveTaxPayroll,
} from "../../../redux/statutory-compliance/hook/tax";
import { useSelector } from "react-redux";
import CompanyTaxIdVerification from "../../modal/statutory-compliance/company-tax-verification";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import { formatNumber } from "../../../utils/functions";

import HelpSVG from "../../../assets/svg/help.svg";
import InfoModal from "../../modal/info-modal";

const PayeTaxTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { company } = useSelector((state) => state.companySlice);
  const [showCompanyTaxIdVerification, setShowCompanyTaxIdVerification] =
    useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const [taxRef, setTaxRef] = useState("");
  const [taxId, setTaxId] = useState("");

  const { data: Taxes } = useGetTaxHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: savePayroll } = useSaveTaxPayroll();

  const history = useHistory();

  const Continue = async (id, type) => {
    let index = Taxes.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === "pay_now") {
        if (company?.state === "Lagos" || !company.state) {
          if (company?.tax_id && company?.tax_consent === 1) {
            if (Taxes.data[index].tax_payroll_status === 1) {
              history.push(
                `/statutory-payments/taxes/preview/${Taxes.data[index].ref}`
              );
            } else {
              let payload = {
                ref: Taxes.data[index].ref,
              };
              await savePayroll(payload).then((result) => {
                history.push(
                  `/statutory-payments/taxes/preview/${Taxes.data[index].ref}`
                );
              });
            }
          } else {
            setTaxId(company.tax_id);
            setTaxRef(Taxes.data[index].ref);
            setShowCompanyTaxIdVerification(true);
          }
        } else {
          history.push(
            `/statutory-payments/taxes/preview/${Taxes.data[index].ref}`
          );
        }
      } else if (type === "view") {
        history.push(`/statutory-payments/tax/view/${Taxes.data[index].ref}`);
      } else if (type === "download") {
        window.open(Taxes.data[index].receipt_url, "_blank");
      } else if (type === "export") {
        exportPAYETax(Taxes.data[index].ref);
      }
    }
  };

  const tableConfig = {
    headers: [
      "Date Created",
      "Reference Number",
      "Description",
      "Month",
      "Status",
    ],
    keys: ["created", "ref", "desc", "month", "status"],
    mobileHeader: {
      left: {
        title: "Date",
        key: "created",
      },
      right: {
        title: "Description",
        key: "desc",
      },
    },
  };

  const statusConfig = [
    {
      label: "In Progress",
      color: "orange",
      value: 0,
    },
    {
      label: "Successful",
      color: "green",
      value: 1,
    },
    {
      label: "In Progress",
      color: "orange",
      value: 2,
    },
    {
      label: "Paid: Not Filed",
      color: "orange",
      value: 3,
    },
    {
      label: "Filed: Awaiting Confirmation",
      color: "orange",
      value: 4,
    },
  ];

  const actionConfig = [
    {
      name: "Preview",
      color: "green",
      action: (id) => {
        Continue(id, "pay_now");
      },
    },
    {
      name: "View",
      color: "orange",
      action: (id) => {
        Continue(id, "view");
      },
    },
    {
      name: "Download Receipt",
      color: "red",
      action: (id) => {
        Continue(id, "download");
      },
    },
    {
      name: "Export Report",
      color: "orange",
      action: (id) => {
        Continue(id, "export");
      },
    },
  ];

  const workbook = new Excel.Workbook();

  const columns = [
    { header: "Date", key: "created" },
    { header: "First Name", key: "first_name" },
    { header: "Last Name", key: "last_name" },
    { header: "Mobile", key: "employee_mobile" },
    { header: "Tax State", key: "tax_state" },
    { header: "Tax Number", key: "employee_tax_number" },
    { header: "Tax Type", key: "tax_type" },
    { header: "Tax Month", key: "tax_month" },
    { header: "Tax Amount", key: "formatted_tax_amount" },
  ];

  const { mutateAsync: exportPayroll } = useExportTax();
  const [exportLoading, setSetExportLoading] = useState(false);
  const exportPAYETax = async (refId) => {
    setSetExportLoading(true);
    let payload = {
      refId,
      limit: 100000,
      page: 1,
      statusFilter: -1,
      search: "",
    };

    await exportPayroll(payload).then(async (response) => {
      let data = response.data;
      const workSheetName = "payroll-tax-history";
      const workBookName = `PAYE Tax - ${refId}`;
      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column) => {
          column.width = column.header.length + 10;
          column.alignment = { horizontal: "left" };
        });

        // loop through data and add each one to worksheet
        data?.forEach((singleData) => {
          singleData.tax_month = moment(
            singleData.schedule_year + "-" + singleData.schedule_month
          ).format("MMMM, YYYY");
          singleData.formatted_tax_amount = formatNumber(singleData.tax_amount);
          worksheet.addRow(singleData);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: "none" },
              left: { style: "none" },
              bottom: { style: "none" },
              right: { style: "none" },
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        // console.error('<<<ERRROR>>>', error);
        // console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  return (
    <>
      <div className="flex items-center justify-end w-full -mt-5">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={HelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      <div className="flex flex-col bg-white rounded-md">
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"paye-tax"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}
        {showCompanyTaxIdVerification ? (
          <CompanyTaxIdVerification
            isOpen={showCompanyTaxIdVerification}
            closeModal={() => setShowCompanyTaxIdVerification(false)}
            taxRef={taxRef}
            taxId={taxId}
          />
        ) : null}

        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="p3-bold header-4">PAYE Tax</div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={Taxes}
          tableConfig={tableConfig}
          statusConfig={statusConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          filterByStatus={statusConfig}
          searchData={true}
          computedActions={true}
        />
      </div>
    </>
  );
};
export default PayeTaxTab;
