import DOMPurify from "dompurify";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../components/button";
import FormFileInput from "../../../components/form-file-input";
import { formValidation, Initials } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import EmptyTableSVG from "../../../assets/svg/empty-table.svg";
import Logo from "../../../components/logo";
import SingleEmployeeOnboardingLayout from "../../../components/layouts/employee-onboarding";
import OnboardingQuestionAnswers from "./onboarding-questions";
import ViewFile from "../../../components/modal/hr-section/employee-onboarding/view-file";
import {
  useEmployeeMarkOnboardingComplete,
  useEmployeeMarkOnboardingStageComplete,
  useUploadFile,
} from "../../../redux/employees/hook/onboarding";
import { onboardingEmployeeStatusConfig } from "../../../utils/constants";
import { StatusText } from "../../../components/fragments/status-text";
import { setAlert } from "../../../redux/components/components-slice";
import moment from "moment";

function OnboardingVerified() {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const { employeeOnboarding } = useSelector((state) => state.employeeSlice);
  const dispatch = useDispatch();

  const { token } = useParams();
  const [onboardingData, setOnboardingData] = useState({});

  const history = useHistory();

  useEffect(() => {
    if (employeeOnboarding) {
      if (employeeOnboarding.status !== 1) {
        setOnboardingData(JSON.parse(JSON.stringify(employeeOnboarding)));
      } else {
        dispatch(
          setAlert(
            true,
            "info",
            "You can no longer alter your onboarding. It has been marked as reviewed. Contact your admin"
          )
        );
      }

      if (onboardingData?.workflow?.sub_workflows?.length > 0) {
        if (
          onboardingData?.workflow?.sub_workflows[currentIndex - 1]
            .upload_file == 1
        ) {
          setStageStep(1);
        } else if (
          onboardingData?.workflow?.sub_workflows[currentIndex - 1]
            .collect_document == 1
        ) {
          setStageStep(2);
        } else if (
          onboardingData?.workflow?.sub_workflows[currentIndex - 1].questions >
          0
        ) {
          setStageStep(3);
        } else {
          setStageStep(null);
        }
      }
    }
  }, [employeeOnboarding]);

  useEffect(() => {
    if (onboardingData) {
      setStageData(onboardingData.workflow?.sub_workflows[currentIndex - 1]);
    }
  }, [onboardingData]);

  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    if (currentIndex) {
      if (
        onboardingData?.workflow?.sub_workflows[currentIndex - 1]
          .upload_file === 1
      ) {
        setStageStep(1);
      } else if (
        onboardingData?.workflow?.sub_workflows[currentIndex - 1]
          ?.collect_document == 1
      ) {
        setStageStep(2);
      } else if (
        onboardingData?.workflow?.sub_workflows[currentIndex - 1]?.questions > 0
      ) {
        setStageStep(3);
      } else {
        setStageStep(null);
      }
      setStageData(onboardingData.workflow?.sub_workflows[currentIndex - 1]);
    }
  }, [currentIndex]);

  const { mutateAsync: markStage, isLoading: markStageloading } =
    useEmployeeMarkOnboardingStageComplete();
  const { mutateAsync: markOnboarding, isLoading: markOnboardingloading } =
    useEmployeeMarkOnboardingComplete();

  const { mutateAsync: uploadFile, isLoading: uploadFileloading } =
    useUploadFile();

  const [showFile, setshowFile] = useState(false);

  const [stageData, setStageData] = useState(null);

  const [stageStep, setStageStep] = useState(1);

  // const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const uploadRequestedFile = async (data) => {
    const formData = new FormData();

    formData.append("file", data?.file[0]);
    formData.append("token", token);
    formData.append("employee_id", onboardingData?.employee_id);
    formData.append("token", token);
    formData.append("subworkflow_id", stageData?.id);
    formData.append("onboarding_id", onboardingData?.id);

    await uploadFile(formData).then((response) => {
      let newArray = JSON.parse(JSON.stringify(onboardingData));
      newArray.workflow.sub_workflows[currentIndex - 1].requested_file =
        response.data.requested_document;
      setOnboardingData(newArray);
    });
  };

  const markEmployeeStage = async () => {
    let payload = {
      employee_id: onboardingData?.employee_id,
      onboarding_id: onboardingData?.id,
      token: token,
      subworkflow_id: stageData?.id,
      employee_status: stageData?.employee_status == 1 ? 0 : 1,
    };
    await markStage(payload).then((result) => {
      let newArray = JSON.parse(JSON.stringify(onboardingData));

      newArray.workflow.sub_workflows[currentIndex - 1].employee_status =
        result.employee_status;
      setOnboardingData(newArray);
    });
  };

  const markEmployeeOnboarding = async () => {
    let payload = {
      employee_id: onboardingData?.employee_id,
      token: token,
      onboarding_id: onboardingData?.id,
      employee_status: onboardingData?.employee_status == 1 ? 0 : 1,
    };
    await markOnboarding(payload).then((result) => {
      let newArray = JSON.parse(JSON.stringify(onboardingData));

      newArray.employee_status = result.employee_status;
      setOnboardingData(newArray);
    });
  };

  const goToHomePage = () => {
    window.location.replace("https://www.ropay.africa");
  };

  return (
    <SingleEmployeeOnboardingLayout
      step="2"
      company_name={onboardingData?.company_name}
      logo={onboardingData?.logo}
    >
      {showFile ? (
        <ViewFile
          isOpen={showFile}
          closeModal={() => setshowFile(false)}
          stage={onboardingData?.workflow?.sub_workflows[currentIndex - 1]}
        />
      ) : null}
      <div className="flex flex-col justify-between w-full md:pt-2 !min-h-screen overflow-auto">
        <div>
          {onboardingData && onboardingData.status == 0 ? (
            <div className="flex flex-col gap-4">
              <div className="flex flex-row items-center box-shadow h-[104px] w-full mt-4 border border-gray-300 rounded">
                <div className="flex flex-row md:px-[55px] p-2 justify-between items-center w-full ">
                  <div className="flex flex-row items-center">
                    <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200">
                      {Initials(
                        onboardingData?.first_name,
                        onboardingData?.last_name
                      )}
                    </div>
                    <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                      <span className="p1-bold">
                        {onboardingData?.first_name} {onboardingData?.last_name}
                      </span>
                      <span className="p4 text-color-gray">
                        {onboardingData?.email}
                      </span>
                    </div>
                  </div>
                  {onboardingData?.employee_status == 1 ? (
                    <div className="flex flex-col gap-1 items-center">
                      <StatusText
                        statusConfig={onboardingEmployeeStatusConfig}
                        code={onboardingData?.employee_status}
                        className={
                          "py-2 rounded p1-bold px-3 p2-bold text-[15px]"
                        }
                      />
                      <span className="p4 text-color-gray">
                        {onboardingData?.status == 1
                          ? "Reviewed By Admin"
                          : "Not Reviewed By Admin"}
                      </span>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center justify-end">
                      <Button
                        text="Mark Onboarding Complete"
                        type="submit"
                        className="!max-h-[35px] px-3 !bg-hr-primary-1"
                        textClass={"!text-[11px]"}
                        leftIcon={"check"}
                        onClick={() => markEmployeeOnboarding()}
                        loading={markOnboardingloading}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className=" flex flex-col text-justify">
                <span className="header-4">Instruction: </span>
                <div className="text-[14px] text-gray-500 text-justify">
                  Each onboarding stage has a maximum of 3 steps{" "}
                  <span className="font-bold">
                    (view uploaded document, upload file, answer questions)
                  </span>
                  , depending on your employer's requirement. You should
                  complete all the steps before marking the stage as completed.
                  Once all stages are completed, use the button on the top right
                  to mark the onboarding as completed.
                </div>
              </div>
              {onboardingData.workflow?.sub_workflows &&
              onboardingData.workflow?.sub_workflows.length > 0 ? (
                <div className="flex flex-row items-center justify-center gap-1 border p-2 border-gray-400 rounded bg-gray-100">
                  {onboardingData.workflow?.sub_workflows.map((item, index) => (
                    <div
                      className={classNames(
                        "flex onboarding-stage w-[150px] min-h-[30px] border-2 border-hr-primary-1 text-hr-primary-1 items-center justify-center cursor-pointer rounded-tr-full rounded-br-full duration-500 text-[13px] md:text-[15px] text-center",
                        {
                          "bg-hr-primary-1 text-white hover:text-hr-primary-1":
                            index === currentIndex - 1 ||
                            item.employee_status == 1,
                          "hover:bg-gray-50": index !== currentIndex - 1,
                        }
                      )}
                      onClick={() => {
                        setStageStep(1);
                        setCurrentIndex(index + 1);
                      }}
                    >
                      Stage {index + 1}
                    </div>
                  ))}
                </div>
              ) : null}
              {stageData ? (
                <div
                  className={
                    "flex flex-col w-full border border-hr-primary-1 min-h-[350px] rounded p-4 pt-2 items-center gap-2"
                  }
                >
                  <div className="flex flex-col items-center">
                    <div className="text-[30px] font-bold text-hr-primary-1 ">
                      Stage {currentIndex} - {stageData.name}
                    </div>
                    <div className="text-[16px] text-color-gray -mt-2">
                      {stageData.description}
                    </div>
                  </div>

                  {stageData.upload_file == 1 ||
                  stageData.collect_document ||
                  stageData.questions.length > 0 ? (
                    <>
                      <div className="flex flex-row gap-1 mt-2 !max-w-[100%] overflow-y-auto p-2 border border-gray-300 rounded bg-gray-100">
                        {stageData.upload_file == 1 ? (
                          <div
                            className={classNames(
                              "flex text-[13px] md:text-[15px] min-w-fit min-h-[30px] border-2 border-hr-primary-1 text-hr-primary-1 items-center justify-center cursor-pointer rounded-tr-full rounded-br-full duration-500 pr-3 pl-3",
                              {
                                "bg-hr-primary-1 text-white": stageStep === 1,
                                "hover:bg-green-200": stageStep !== 1,
                              }
                            )}
                            onClick={() => {
                              setStageStep(1);
                            }}
                          >
                            View Uploaded Document
                          </div>
                        ) : null}
                        {stageData.collect_document == 1 ? (
                          <div
                            className={classNames(
                              "flex text-[13px] md:text-[15px] min-w-fit min-h-[30px] border-2 border-hr-primary-1 text-hr-primary-1 items-center justify-center cursor-pointer rounded-tr-full rounded-br-full duration-500 pr-3 pl-3",
                              {
                                "bg-hr-primary-1 text-white": stageStep === 2,
                                "hover:bg-green-200": stageStep !== 2,
                              }
                            )}
                            onClick={() => {
                              setStageStep(2);
                            }}
                          >
                            Upload Document
                          </div>
                        ) : null}
                        {stageData.questions.length > 0 ? (
                          <div
                            className={classNames(
                              "flex text-[13px] md:text-[15px] min-w-fit min-h-[30px] border-2 border-hr-primary-1 text-hr-primary-1 items-center justify-center cursor-pointer rounded-tr-full rounded-br-full duration-500 pr-3 pl-2 ",
                              {
                                "bg-hr-primary-1 text-white": stageStep === 3,
                                "hover:bg-green-200": stageStep !== 3,
                              }
                            )}
                            onClick={() => {
                              setStageStep(3);
                            }}
                          >
                            Answer Questions
                          </div>
                        ) : null}
                      </div>
                      <div className="flex flex-col justify-center items-center max-w-[400px] min-w-[70%]">
                        {stageStep == 1 ? (
                          <>
                            {stageData.upload_file == 1 ? (
                              <div className="flex flex-col justify-between items-center">
                                <div
                                  className={
                                    "flex flex-col items-center justify-center w-[480px] h-[220px] border border-gray-300 rounded p-4 gap-4 mt-2"
                                  }
                                >
                                  <div className="text-[15px] text-color-black font-semibold">
                                    Download and study the {stageData.filename}{" "}
                                    document
                                  </div>
                                  <div className="flex flex-row items-center">
                                    <Button
                                      text="View Document"
                                      type="button"
                                      className="!h-[35px] px-3"
                                      textClass={"!text-[11px]"}
                                      theme="secondary"
                                      onClick={() => setshowFile(true)}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center w-[100%] -mt-4 !max-h-[200px] !min-h-[200px]">
                                <span className="header-4 mt-3">
                                  No Action Required
                                </span>
                              </div>
                            )}
                          </>
                        ) : stageStep == 2 ? (
                          <>
                            {stageData.collect_document == 1 &&
                            stageData.requested_file == null ? (
                              <form
                                className="form mt-2"
                                onSubmit={handleSubmit(uploadRequestedFile)}
                              >
                                <div className="p-4 flex flex-col min-w-[500px] border border-gray-300 rounded items-center justify-center">
                                  <div className="flex flex-col text-center justify-center items-center w-[90%]">
                                    <span className="header-4-medium">
                                      Upload {stageData.document_name}
                                    </span>
                                    <FormFileInput
                                      multiSource={true}
                                      inputRef={register(
                                        formValidation("file", true)
                                      )}
                                      accept=".jpeg,.jpg,.pdf,.png"
                                    />
                                  </div>
                                  <div className="flex items-center justify-center w-full mt-[40px] !max-w-[200px] !max-h-[42px]">
                                    <Button
                                      text="Upload File"
                                      type="submit"
                                      className={
                                        "!max-h-[35px] !bg-hr-primary-1"
                                      }
                                      textClass="text-[13px]"
                                      loading={uploadFileloading}
                                    />
                                  </div>
                                </div>
                              </form>
                            ) : stageData.requested_file ? (
                              <div className="flex flex-col items-center justify-center w-[100%] mt-2 !max-h-[200px] !min-h-[200px] border rounded">
                                <span className="header-4 mt-3">
                                  File Already Submitted
                                </span>
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center w-[100%] mt-2 !max-h-[200px] !min-h-[200px]">
                                <span className="header-4 mt-3">
                                  No Action Required
                                </span>
                              </div>
                            )}
                          </>
                        ) : stageStep == 3 ? (
                          <div className="min-w-full border border-gray-300 rounded mt-2">
                            <OnboardingQuestionAnswers
                              questions={stageData.questions}
                              answers={stageData.answers}
                              onboarding={onboardingData}
                              token={token}
                              subId={stageData.id}
                              setOnboardingData={setOnboardingData}
                              currentIndex={currentIndex}
                            />
                          </div>
                        ) : null}
                        <hr className="divider" />
                        {stageData?.employee_status == 1 ? (
                          <div className="flex flex-row items-center">
                            <StatusText
                              statusConfig={onboardingEmployeeStatusConfig}
                              code={stageData?.employee_status}
                              className={
                                "py-2 rounded p1-bold px-3 p2-bold text-[15px]"
                              }
                            />
                          </div>
                        ) : (
                          <div className="flex flex-row items-center justify-end w-full">
                            <Button
                              text="Mark Stage Completed"
                              type="button"
                              className=" flex !h-[35px] px-3 w-fit !bg-hr-primary-1"
                              textClass={"!text-[11px]"}
                              leftIcon={"check"}
                              onClick={() => markEmployeeStage()}
                              loading={markStageloading}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col items-center justify-center w-[100%] -mt-4 !max-h-[200px] !min-h-[200px]">
                      <span className="header-4 mt-3">No Action Required</span>
                      <hr className="divider" />
                      {stageData?.employee_status == 1 ? (
                        <div className="flex flex-row items-center">
                          <StatusText
                            statusConfig={onboardingEmployeeStatusConfig}
                            code={stageData?.employee_status}
                            className={
                              "py-2 rounded p1-bold px-3 p2-bold text-[15px]"
                            }
                          />
                        </div>
                      ) : (
                        <div className="flex flex-row items-center justify-end w-full">
                          <Button
                            text="Mark Stage Completed"
                            type="button"
                            className=" flex !h-[35px] px-3 w-fit !bg-hr-primary-1"
                            textClass={"!text-[11px]"}
                            leftIcon={"check"}
                            onClick={() => markEmployeeStage()}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center w-[100%] -mt-4 !max-h-[200px] !min-h-[200px]">
                  <span className="header-4 mt-3">No Action Required</span>
                </div>
              )}
            </div>
          ) : !onboardingData ? (
            <div className="flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-[200px]">
              <img
                src={EmptyTableSVG}
                alt="no_data_fetched"
                width={"80%"}
                height={"200px"}
              />
              <span className="header-4 mt-3">You Are not verified</span>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center w-[100%] -mt-4 !min-h-[200px]">
              <img
                src={EmptyTableSVG}
                alt="no_data_fetched"
                width={"80%"}
                height={"200px"}
              />
              <span className="header-4 mt-3">
                Onboarding has already been reviewed. You can no longer update.
              </span>
            </div>
          )}
        </div>
        <div
          className={
            "md:hidden flex items-center justify-center text-color-black gap-2"
          }
        >
          Powered By:{" "}
          <a href="https://www.ropay.africa" target={"_blank"} rel="noreferrer">
            {<Logo width="80px" height="40px" blue />}
          </a>
        </div>
      </div>
    </SingleEmployeeOnboardingLayout>
  );
}

export default OnboardingVerified;
