import React, { useState } from 'react';
import Button from '../button';
import Modal from './modal';
import classNames from 'classnames';

function DoYouWantToModal({
  isOpen,
  closeModal,
  refresh,
  title,
  id,
  buttonText,
  subtitle,
  btnFunction,
  type='delete',
  loading=false
}) {
  return (
    <Modal
      scrollable={true}
      className='!max-w-[500px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='p-4 overflow-auto'>
        <div className='flex flex-col overflow-auto justify-center items-center'>
          <div className='flex flex-col md:mx-8 items-center justify-center'>
            <span className='text-center mt-2 header-4'>{title}</span>
            {subtitle?
            <span className='text-center mt-2 mb-2 text-[13px]'>{subtitle}</span>
            :
            null
            }
            <div className='flex flex-row justify-center w-full mt-[10px] gap-4'>
                <Button
                    text={'Cancel'}
                    type='button'
                    theme={'secondary'}
                    className='flex gap-2 !h-[35px] w-fit p-2 border-gray-400'
                    textClass={'!text-[12px] text-gray-500'}
                    onClick={() => closeModal()}
                />
                <Button
                    text={buttonText}
                    type='button'
                    loading={loading}
                    className={
                      classNames('flex gap-2 !h-[35px] w-fit p-2',
                      {
                        '!bg-red-500': type === 'delete'
                      })
                    }
                    textClass={'!text-[11px]'}
                    onClick={() => btnFunction(id)}
                />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DoYouWantToModal;
