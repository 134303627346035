import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../../components/button";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import { updateCurrentCompany } from "../../../redux/company/hook";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import WarningSVG from "../../../assets/svg/warning.svg";
import { useSelector } from "react-redux";
import { isLoading } from "../../../redux/components/components-slice";
import VendorPayDetailedView from "../../../components/modal/spend-management/vendor/vendor-pay-detailed-view";
import {
  useRefVendorPay,
  useRemoveVendorPaymentById,
  useRevertVendor,
} from "../../../redux/spend-management/vendor/hook";
import VendorPaySummary from "../../../components/modal/spend-management/vendor/vendor-pay-summary";
import { useRemoveBasicPayrollById } from "../../../redux/payroll/hook";
import { useGetCompanyAccountDetails } from "../../../redux/wallet/hook";
import { useRouteQuery } from "../../../hooks/useRouteQuery";

const VendorPayPreviewPage = () => {
  useEffect(() => {}, []);

  const { data: companyAccountDetails } = useGetCompanyAccountDetails();

  const query = useRouteQuery();

  const loading = useSelector(isLoading);
  const [previewType, setPreviewType] = useState();
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const [vendorStatus, setVendorStatus] = useState("");
  const [showVendorSummary, setShowVendorSummary] = useState(false);
  const [showVendorDetailedView, setShowVendorDetailedView] = useState(false);
  const [previewData, setPreviewData] = useState();
  const { mutateAsync: revertVendor } = useRevertVendor();
  const { mutateAsync: removeVendorEntry } = useRemoveVendorPaymentById();

  const history = useHistory();

  useEffect(() => {
    if (query) {
      if (query.get("approve")) {
        setVendorStatus("approve");
        setShowVendorSummary(true);
      }
    }
  }, [query]);

  const { refId } = useParams();
  const { data: vendorPay } = useRefVendorPay(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  useEffect(() => {
    if (vendorPay) {
      if (loading === false && vendorPay.data.length < 1) {
        revertVendor().then(() => {
          history.push("/vendors", { tab: 2 });
        });
      }
    }
  }, [vendorPay, loading]);

  useEffect(() => {
    updateCurrentCompany();
  }, []);

  const tableConfig = {
    headers: ["Vendor Name", "Amount", "Fee", "Total"],
    keys: ["vendor_name", "formatted_net_pay", "formatted_trans_cost", "total"],
    mobileHeader: {
      left: {
        title: "Vendor Name",
        key: "vendor_name",
      },
      right: {
        title: "Amount",
        key: "formatted_net_pay",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Update",
      color: "green",
      action: (id) => {
        let index = vendorPay.data.findIndex((item) => item.id === id);
        setPreviewType("edit");
        setPreviewData(vendorPay.data[index]);
        setShowVendorDetailedView(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: async (id) => {
        let index = vendorPay.data.findIndex((item) => item.id === id);
        await removeVendorEntry(vendorPay.data[index].id);
      },
    },
  ];

  return (
    <DashboardLayout title="Vendor Payment Preview">
      {previewData && showVendorDetailedView ? (
        <VendorPayDetailedView
          isOpen={showVendorDetailedView}
          previewType={previewType}
          closeModal={() => setShowVendorDetailedView(false)}
          viewData={previewData}
        />
      ) : null}
      <div className="flex flex-col">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col justify-between mb-7">
            <span className="h4 text-color-black">
              A Quick Preview Before You Submit
            </span>
            <span className="p3 text-color-gray">
              Please spend a brief moment reviewing these numbers before you
              submit payroll
            </span>
          </div>
          <div className="w-full md:w-[220px] mt-3 md:mt-0 h-[56px]">
            <Button
              text={vendorStatus == "approve" ? "Approve Payment" : "Pay Now"}
              type="button"
              loading={false}
              disabled={false}
              className="h-[56px]"
              onClick={() => setShowVendorSummary(true)}
            />
          </div>
        </div>

        <div className="mt-3">
          <>
            {showVendorSummary ? (
              <VendorPaySummary
                refValue={refId}
                vendorstatus={vendorStatus}
                isOpen={showVendorSummary}
                closeModal={() => setShowVendorSummary(false)}
                takeHome={vendorPay?.total_net_pay}
                transCost={vendorPay?.total_trans_cost}
                totalAmount={vendorPay?.total_payable}
              />
            ) : null}
            <div className="flex flex-col">
              <div className="flex md:flex-row flex-col">
                <div className="w-[100%] md:w-[70%]">
                  <DataTable
                    data={vendorPay}
                    tableConfig={tableConfig}
                    actionConfig={actionConfig}
                    updatePagination={(data) => setPagination(data)}
                    computedActions={true}
                  />
                </div>
                <div className="w-[100%] md:w-[30%] flex flex-col md:pl-8 ">
                  <div className=" bg-white flex flex-col w-full p-3 ">
                    <div className="mb-2"></div>
                    <div className="mb-2">
                      <div className="flex flex-row justify-between">
                        <span className="p4-medium text-color-gray">
                          Total Amount
                        </span>
                        <span className="p4-medium  text-color-black">
                          {vendorPay?.formatted_total_net_pay}
                        </span>
                      </div>
                      <hr className="divider" />
                    </div>
                    <div className="mb-2">
                      <div className="flex flex-row justify-between">
                        <span className="p4-medium text-color-gray">
                          Transaction cost
                        </span>
                        <span className="p4-medium  text-color-black">
                          {vendorPay?.formatted_total_trans_cost}
                        </span>
                      </div>
                      <hr className="divider" />
                    </div>
                    <div className="mb-2">
                      <div className="flex flex-row justify-between">
                        <span className="p4-medium text-secondary-2 font-semibold">
                          TOTAL
                        </span>
                        <span className="p4-medium  text-color-black">
                          {vendorPay?.formatted_total_payable}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-row px-4 border-secondary-4 border-[1.2px] rounded-lg p-4 py-2 mt-8 bg-color-warning-light">
                  <div className="w-[70px] pr-2 flex flex-row items-center ">
                    <img src={WarningSVG} alt="" srcSet="" />
                  </div>

                  <span className="text-[13px] text-justify">
                    After you review and submit this Payment, we will
                    debit&nbsp;
                    <span className="font-semibold">
                      {vendorPay?.formatted_total_payable}
                    </span>
                    . Total Amount payable is{" "}
                    <span className="font-semibold">
                      {vendorPay?.formatted_total_net_pay}&nbsp;
                    </span>
                    and transaction charge is{" "}
                    <span className="font-semibold">
                      {vendorPay?.formatted_total_trans_cost}
                    </span>
                    . Please make sure you have these funds available in your
                    account. If everything looks good, click the{" "}
                    <span className="font-semibold">'PAY NOW'</span> button.
                  </span>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default VendorPayPreviewPage;
