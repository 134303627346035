import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CloseSVG from "../../../../assets/svg/close.svg";
import Button from "../../../button";
import FormInput from "../../../form-input";
import {
  useAddPerformanceOKR,
  useEditPerformanceOKR,
} from "../../../../redux/human-resources/hook/performance";
import FormSelectBranch from "../../../employees/form-select-branch";
import FormSelectDepartment from "../../../employees/form-select-department";
import FormSelectEmployee from "../../../employees/form-select-employee";
import FormSelectTeam from "../../../employees/form-select-team";
import { store } from "../../../../redux";
import { setAlert } from "../../../../redux/components/components-slice";
import Modal from "../../modal";
import AddSmallSVG from "../../../../assets/svg/add-icon-small.svg";
import FormSelect from "../../../form-select";
// import FormSwitch from '../../../form-switch';
import moment from "moment";

function AddPerformanceOKR({
  isOpen,
  closeModal,
  okrData,
  title,
  buttonText,
  type = "edit",
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const [source, setSource] = useState("employee");
  const [sourceId, setSourceId] = useState(null);
  const [sourceName, setSourceName] = useState(null);

  const sourceOptions = [
    { label: "Employee", value: "employee" },
    { label: "Team", value: "team" },
    { label: "Department", value: "department" },
    { label: "Branch", value: "branch" },
  ];

  const [okrObjectives, setOkrObjectives] = useState([
    {
      objective: "",
      error: null,
      max_grade: 100,
      start_date: "",
      end_date: "",
      key_results: [
        {
          key_result: "",
          weight: 100,
          start_date: "",
          end_date: "",
        },
      ],
    },
  ]);
  const [okrName, setOkrName] = useState("");

  const [evaluation_frequency, setEvaluationFrequency] = useState("monthly");
  const [auto_evaluate, setAutoEvaluate] = useState(false);
  const [date_type, setDateType] = useState("day");
  const [day, setDay] = useState("last");
  const [interval_count, setIntervalCount] = useState(1);
  const [auto_evaluation_start_date, setAutoEvaluationStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [end_type, setEndType] = useState("none");
  const [occurrences, setOccurences] = useState(100);
  const [auto_evaluation_end_date, setAutoEvaluationEndDate] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );

  const [dateTypeOptions, setDateTypeOptions] = useState([
    { label: "Day", value: "day" },
    { label: "First", value: "first" },
    { label: "Second", value: "second" },
    { label: "Third", value: "third" },
    { label: "Fourth", value: "fourth" },
    { label: "Last", value: "last" },
  ]);

  const [dayOptions, setDayOptions] = useState([
    { label: "1st", value: "01" },
    { label: "2nd", value: "02" },
    { label: "3rd", value: "03" },
    { label: "4th", value: "04" },
    { label: "5th", value: "05" },
    { label: "6th", value: "06" },
    { label: "7th", value: "07" },
    { label: "8th", value: "08" },
    { label: "9th", value: "09" },
    { label: "10th", value: "10" },
    { label: "11th", value: "11" },
    { label: "12th", value: "12" },
    { label: "13th", value: "13" },
    { label: "14th", value: "14" },
    { label: "15th", value: "15" },
    { label: "16th", value: "16" },
    { label: "17th", value: "17" },
    { label: "18th", value: "18" },
    { label: "19th", value: "19" },
    { label: "20th", value: "20" },
    { label: "21st", value: "21" },
    { label: "22nd", value: "22" },
    { label: "23rd", value: "23" },
    { label: "24th", value: "24" },
    { label: "25th", value: "25" },
    { label: "26th", value: "26" },
    { label: "27th", value: "27" },
    { label: "28th", value: "28" },
    { label: "Last", value: "last" },
  ]);

  const endTypeOptions = [
    { label: "None", value: "none" },
    { label: "By", value: "by" },
    { label: "After", value: "after" },
  ];

  const evaluationFrequencyOptions = [
    {
      label: "Daily",
      value: "daily",
    },
    {
      label: "Weekly",
      value: "weekly",
    },
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Quarterly",
      value: "quarterly",
    },
    {
      label: "Semi-annually",
      value: "semi-annually",
    },
    {
      label: "Annually",
      value: "annually",
    },
  ];

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [okrId, setOkrId] = useState(null);

  useEffect(() => {
    if (okrData) {
      setOkrId(okrData.id);
      setStartDate(okrData?.start_date);
      setEndDate(okrData?.end_date);
      setOkrName(okrData.okr_title);
      setOkrObjectives(okrData.objectives);
      setSourceName(okrData.source_name);
      setSourceId(okrData.source_id);
      setSource(okrData.source);
      setEvaluationFrequency(okrData.evaluation_frequency);
      setAutoEvaluate(okrData.auto_evaluate === 1 ? true : false);
      setDateType(okrData.date_type);
      setDay(okrData.day);
      setIntervalCount(okrData.interval_count);
      setAutoEvaluationStartDate(okrData.auto_evaluation_start_date);
      setEndType(okrData.end_type);
      setOccurences(okrData.occurrences);
      setAutoEvaluationEndDate(okrData.auto_evaluation_end_date);
      let objs = [];
      okrData.objectives.forEach((obj) => {
        let krs = [];
        obj.key_results?.forEach((keyItem) => {
          if (
            keyItem.key_result &&
            keyItem.weight &&
            keyItem.weight > 0 &&
            keyItem.start_date &&
            keyItem.end_date
          ) {
            let newKrs = {
              key_result: keyItem.key_result,
              weight: keyItem.weight,
              start_date: keyItem.start_date,
              end_date: keyItem.end_date,
            };
            krs.push(newKrs);
          }
        });
        let newObj = {
          objective: obj.objective,
          error: null,
          max_grade: obj.max_grade,
          start_date: obj.start_date,
          end_date: obj.end_date,
          key_results: krs,
        };
        objs.push(newObj);
      });
      setOkrObjectives(objs);
      if (type == "edit") {
        setOkrType("edit");
      } else {
        setOkrType("duplicate");
      }
    } else {
      setOkrType("add");
    }
  }, [okrData]);

  const { mutateAsync: addOKR, isLoading: addOKRloading } =
    useAddPerformanceOKR();
  const { mutateAsync: editOKR, isLoading: editOKRloading } =
    useEditPerformanceOKR();
  const [okrType, setOkrType] = useState("add");

  const addObjective = () => {
    setOkrObjectives([
      ...okrObjectives,
      ...[
        {
          objective: "",
          error: null,
          max_grade: 100,
          start_date: "",
          end_date: "",
          key_results: [
            {
              key_result: "",
              weight: 0,
              start_date: "",
              end_date: "",
              error: null,
            },
          ],
        },
      ],
    ]);
  };

  const addKeyResult = (index) => {
    let newArray = JSON.parse(JSON.stringify(okrObjectives));

    newArray[index].key_results.push({
      key_result: "",
      weight: 0,
      start_date: newArray[index].start_date,
      end_date: newArray[index].end_date,
    });
    setOkrObjectives(newArray);
  };

  const removeObjective = (index) => {
    let data = JSON.parse(JSON.stringify(okrObjectives));
    if (index > -1) {
      data.splice(index, 1); // 2nd parameter means remove one item only
      setOkrObjectives(data);
    }
  };

  const removeKeyResult = (index, subIndex) => {
    let data = JSON.parse(JSON.stringify(okrObjectives));

    if (index > -1) {
      // only splice array when item is found
      data[index].key_results.splice(subIndex, 1); // 2nd parameter means remove one item only
      setOkrObjectives(data);
    }
  };

  const submitForm = async () => {
    const okr_title = DOMPurify.sanitize(okrName);
    const source_name = DOMPurify.sanitize(sourceName);
    const start_date = DOMPurify.sanitize(startDate);
    const end_date = DOMPurify.sanitize(endDate);

    let obj_error = [];

    let okr0bjective = [];
    let okrObjectivesData = JSON.parse(JSON.stringify(okrObjectives));
    if (okrObjectivesData.length > 0) {
      okrObjectivesData?.forEach((item, index) => {
        if (item.objective && item.max_grade) {
          let newKeyResults = [];
          item.key_results?.forEach((keyItem) => {
            if (keyItem.key_result && keyItem.weight && keyItem.weight > 0) {
              newKeyResults.push(keyItem);
            }
          });
          if (newKeyResults.length > 0) {
            let newOkr = {
              objective: item.objective,
              start_date: item.start_date,
              end_date: item.end_date,
              max_grade: item.max_grade,
              key_results: item.key_results,
            };
            okr0bjective.push(newOkr);
          } else {
            obj_error.push(
              "Please check Objective " +
                (index + 1) +
                " for errors. You must add at least one key result to add OKR"
            );
          }
        } else {
          obj_error.push(
            "Please check Objective " + (index + 1) + " for errors"
          );
        }
      });
      if (obj_error.length < 1) {
        if (okr0bjective.length > 0) {
          let payload = {
            okr_title,
            source,
            source_id: sourceId,
            source_name,
            max_grade: 100,
            start_date,
            end_date,
            evaluation_frequency,
            auto_evaluate: auto_evaluate ? 1 : 0,
            date_type,
            day,
            interval_count,
            auto_evaluation_start_date,
            end_type,
            occurrences,
            auto_evaluation_end_date,
            objectives: okr0bjective,
          };
          if (okrType === "add" || okrType === "duplicate") {
            await addOKR(payload).then(() => {
              closeModal();
            });
          } else {
            let editPayload = {
              id: okrId,
              data: payload,
            };
            await editOKR(editPayload).then(() => {
              closeModal();
            });
          }
        } else {
          obj_error?.forEach((item, index) => {
            store.dispatch(setAlert(true, "info", obj_error[index]));
          });
        }
      } else {
        obj_error?.forEach((item, index) => {
          store.dispatch(setAlert(true, "info", obj_error[index]));
        });
      }
    } else {
      store.dispatch(
        setAlert(true, "info", "There must be at least one Objective")
      );
    }
  };

  return (
    <Modal
      scrollable={true}
      className="md:min-w-[1000px] min-w-[100%] min-h-screen !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col overflow-auto">
        <form className="form pb-6">
          <div className="pl-6 pr-6 pt-4">
            <div className="flex flex-row justify-between items-center">
              <span className="header-4">{title}</span>
              <div className="cursor-pointer" onClick={() => closeModal()}>
                <img src={CloseSVG} alt="close" />
              </div>
            </div>
          </div>
          <hr className="divider" />
          <div className="flex flex-col max-h-fit mx-8">
            <div className="flex flex-col md:flex-row w-full justify-between border bg-highlight p-2 rounded-md mb-2">
              <div className="w-full md:w-1/2 md:pr-2">
                <FormSelect
                  defaultValue={source}
                  options={sourceOptions}
                  onChange={(selected) => {
                    setSource(selected);
                    setSourceId(null);
                  }}
                  label="OKR For:"
                  extraClass={"bg-white"}
                />
              </div>
              <div className="w-full md:w-1/2 md:pl-2">
                {!source || source === "company" ? null : source ===
                  "employee" ? (
                  <FormSelectEmployee
                    label={"Employee"}
                    name="employee"
                    placeholder="Select Employee"
                    value={sourceId}
                    empName={sourceName}
                    setSourceName={setSourceName}
                    setEmployee={setSourceId}
                  />
                ) : source === "team" ? (
                  <FormSelectTeam
                    label={"Team"}
                    name="team"
                    placeholder="Select Team"
                    value={sourceId}
                    teamName={sourceName}
                    setSourceName={setSourceName}
                    setTeam={setSourceId}
                  />
                ) : source === "department" ? (
                  <FormSelectDepartment
                    label={"Department"}
                    name="department"
                    placeholder="Select Department"
                    value={sourceId}
                    departmentName={sourceName}
                    setSourceName={setSourceName}
                    setDepartment={setSourceId}
                  />
                ) : (
                  <FormSelectBranch
                    label={"Branch"}
                    name="branch"
                    placeholder="Select Branch"
                    value={sourceId}
                    branchName={sourceName}
                    setSourceName={setSourceName}
                    setBranch={setSourceId}
                  />
                )}
              </div>
            </div>
            <FormInput
              label="OKR Title"
              placeholder="Enter OKR title"
              name="name"
              type="text"
              value={okrName}
              onInput={(e) => setOkrName(e.target.value)}
              readOnly={addOKRloading || editOKRloading}
              className="!h-[30px] text-[13px] w-[100%] "
            />
            <div className="flex flex-col mt-2">
              <span className="header-4">Objectives</span>
              <span className="p3 text-[12px] text-color-gray">
                Add Objectives and Key results.
              </span>
              <hr className="divider mt-2" />
            </div>
            <div className="flex flex-col gap-3">
              {okrObjectives &&
                okrObjectives?.map((item, index) => (
                  <div className="flex flex-col hover:shadow-2xl shadow-green-500 p-3 rounded-xl border-1 bg-gray-50 overflow-clip">
                    <div className="flex justify-between">
                      <div className="text-[14px] italic text-[#0d5225] font-bold">
                        Objective {index + 1}
                      </div>
                      {okrObjectives?.length > 1 ? (
                        <div
                          className="w-[30%] ml-3 italic cursor-pointer text-[10px] hover:underline text-rose-500 pr-2 font-bold text-right"
                          onClick={() => removeObjective(index)}
                        >
                          Remove Objective
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="flex flex-row items-center justify-between first-line:-mt-3 pr-2 text-[13px]"
                      key={index}
                    >
                      <div className="w-[100%]">
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-row items-center !w-[100%]">
                            <FormInput
                              placeholder="Enter Objective"
                              name={index + "_objective_name"}
                              type="text"
                              value={item.objective}
                              onInput={(e) => {
                                let newArray = JSON.parse(
                                  JSON.stringify(okrObjectives)
                                );
                                newArray[index].objective = e.target.value;
                                setOkrObjectives(newArray);
                              }}
                              className="!h-[30px] text-[13px] w-[100%] border-1 border-gray-300 md:min-w-[260px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="divider mt-1 mb-2" />
                    <div className="flex justify-end w-full text-[14px] text-red-600 font-bold italic">
                      {item.error ? item.error : null}
                    </div>
                    <div className="text-[14px] font-semibold italic text-color-gray">
                      Key results
                    </div>
                    <div className="flex flex-col gap-2 p-2 border rounded-md bg-color-off-white">
                      <div className="flex flex-col gap-2">
                        {item.key_results.length > 0 &&
                          item.key_results?.map((subItem, subIndex) => (
                            <div className="border bg-white p-2 rounded-md">
                              <div className="flex justify-between">
                                <div className="text-[13px] font-extrabold italic text-color-gray">
                                  Key Result {subIndex + 1}
                                </div>
                                {item?.key_results?.length > 1 ? (
                                  <div
                                    className="w-[30%] italic cursor-pointer text-[10px] hover:underline text-rose-500 pr-2 font-bold text-right"
                                    onClick={() =>
                                      removeKeyResult(index, subIndex)
                                    }
                                  >
                                    Remove
                                  </div>
                                ) : null}
                              </div>
                              <div className="flex flex-row gap-3 items-center justify-between -mb-3">
                                <div className="flex flex-row items-center !w-full">
                                  <FormInput
                                    name={index + "_key_result_name"}
                                    placeholder="Enter key result"
                                    type="text"
                                    value={subItem.key_result}
                                    onInput={(e) => {
                                      let newArray = JSON.parse(
                                        JSON.stringify(okrObjectives)
                                      );
                                      newArray[index].key_results[
                                        subIndex
                                      ].key_result = e.target.value;
                                      setOkrObjectives(newArray);
                                    }}
                                    className="!h-[30px] text-[13px] w-full border-1 border-gray-300 md:min-w-[260px]"
                                  />
                                </div>
                                <div className="flex flex-row justify-end items-center !w-[50%] ">
                                  <FormInput
                                    type="number"
                                    placeholder="Weight"
                                    value={subItem.weight}
                                    onInput={(e) => {
                                      let newArray = JSON.parse(
                                        JSON.stringify(okrObjectives)
                                      );

                                      let max_grade = newArray[index].max_grade;
                                      let total_weight = 0;
                                      newArray[index].key_results.forEach(
                                        (keyItem, keyIndex) => {
                                          if (keyIndex != subIndex) {
                                            if (keyItem.weight != null) {
                                              total_weight =
                                                total_weight +
                                                parseFloat(keyItem.weight);
                                            } else {
                                              total_weight = total_weight + 0;
                                            }
                                          } else {
                                            total_weight =
                                              total_weight +
                                              parseFloat(e.target.value);
                                          }
                                        }
                                      );
                                      if (total_weight == max_grade) {
                                        newArray[index].error = null;
                                      } else {
                                        newArray[index].error =
                                          "Total key result weight must be exual to (100)";
                                      }
                                      newArray[index].key_results[
                                        subIndex
                                      ].weight = e.target.value;
                                      setOkrObjectives(newArray);
                                    }}
                                    className="!h-[30px] text-[13px] w-[100%] border-1 border-gray-300 min-w-[100px]"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="flex justify-left w-full items-center gap-2">
                        <img
                          src={AddSmallSVG}
                          alt={"add_key_result"}
                          className={"h-[15px] w-[15px]"}
                        />
                        <span
                          className="cursor-pointer hover:underline text-color-black duration-75 italic text-[13px] text-[#0d5225]"
                          onClick={() => addKeyResult(index)}
                        >
                          Add key result
                        </span>
                      </div>
                    </div>
                  </div>
                ))}

              <div className="flex justify-left w-full mb-3 items-center gap-2">
                <img
                  src={AddSmallSVG}
                  alt={"add_stage"}
                  className={"h-[15px] w-[15px]"}
                />
                <span
                  className="p2-medium cursor-pointer hover:underline text-color-black duration-75 italic text-[13px] text-[#0d5225]"
                  onClick={() => addObjective()}
                >
                  Add New Objective
                </span>
              </div>
              <hr className="divider -mt-5" />
            </div>
            {/* <div className='mt-2'>
              <FormSwitch
                label={'Do you want evaluation to be done automatically?'}
                name={'auto_evaluate'}
                checked={auto_evaluate}
                onClick={() =>
                    setAutoEvaluate(!auto_evaluate)
                }
                value={auto_evaluate}
              />
            </div> */}
            {/* {auto_evaluate?
            <div className='flex flex-col gap-2 -mt-1 mb-4 !w-full border rounded-md p-2 md:gap-3 bg-gray-50 '>
              <div className='text-[23px] font-normal'>Auto-evaluation information</div>
              <div className='flex md:flex-row flex-col gap-2 justify-start items-center text-[13px] mr-4 flex-wrap'>
                <FormSelect
                  name={'evaluation_frequency'}
                  value={evaluation_frequency}
                  options={evaluationFrequencyOptions}
                  onChange={(selected) => {
                      setEvaluationFrequency(selected)
                      if (selected == 'weekly') {
                          setDateType('monday')
                          setDateTypeOptions([
                              {label: 'Monday', value:'monday'},
                              {label: 'Tuesday', value:'tuesday'},
                              {label: 'Wednesday', value:'wednesday'},
                              {label: 'Thursday', value:'thursday'},
                              {label: 'Friday', value:'friday'},
                              {label: 'Saturday', value:'saturday'},
                              {label: 'Sunday', value:'sunday'},
                          ])
                      } else if (selected == 'monthly') {
                        setDateType('day')
                        setDay(1)
                        setDateTypeOptions([
                            {label: 'Day', value:'day'},
                            {label: 'First', value:'first'},
                            {label: 'Second', value:'second'},
                            {label: 'Third', value:'third'},
                            {label: 'Fourth', value:'fourth'},
                            {label: 'Last', value:'last'},
                        ])
                        setDayOptions(
                            [
                                {label: '1st', value: '01'},
                                {label: '2nd', value: '02'},
                                {label: '3rd', value: '03'},
                                {label: '4th', value: '04'},
                                {label: '5th', value: '05'},
                                {label: '6th', value: '06'},
                                {label: '7th', value: '07'},
                                {label: '8th', value: '08'},
                                {label: '9th', value: '09'},
                                {label: '10th', value: '10'},
                                {label: '11th', value: '11'},
                                {label: '12th', value: '12'},
                                {label: '13th', value: '13'},
                                {label: '14th', value: '14'},
                                {label: '15th', value: '15'},
                                {label: '16th', value: '16'},
                                {label: '17th', value: '17'},
                                {label: '18th', value: '18'},
                                {label: '19th', value: '19'},
                                {label: '20th', value: '20'},
                                {label: '21st', value: '21'},
                                {label: '22nd', value: '22'},
                                {label: '23rd', value: '23'},
                                {label: '24th', value: '24'},
                                {label: '25th', value: '25'},
                                {label: '26th', value: '26'},
                                {label: '27th', value: '27'},
                                {label: '28th', value: '28'},
                                {label: 'Last', value: 'last'},
                            ]
                        )
                      } else if (selected == 'yearly') {
                        setDateType('january')
                        setDay(1)
                        setDateTypeOptions([
                            {label: 'January', value:'january'},
                            {label: 'February', value:'february'},
                            {label: 'March', value:'march'},
                            {label: 'April', value:'april'},
                            {label: 'May', value:'may'},
                            {label: 'June', value:'june'},
                            {label: 'July', value:'july'},
                            {label: 'August', value:'august'},
                            {label: 'September', value:'september'},
                            {label: 'October', value:'october'},
                            {label: 'November', value:'november'},
                            {label: 'December', value:'december'},
                        ])
                        setDayOptions(
                            [
                                {label: '1st', value: '01'},
                                {label: '2nd', value: '02'},
                                {label: '3rd', value: '03'},
                                {label: '4th', value: '04'},
                                {label: '5th', value: '05'},
                                {label: '6th', value: '06'},
                                {label: '7th', value: '07'},
                                {label: '8th', value: '08'},
                                {label: '9th', value: '09'},
                                {label: '10th', value: '10'},
                                {label: '11th', value: '11'},
                                {label: '12th', value: '12'},
                                {label: '13th', value: '13'},
                                {label: '14th', value: '14'},
                                {label: '15th', value: '15'},
                                {label: '16th', value: '16'},
                                {label: '17th', value: '17'},
                                {label: '18th', value: '18'},
                                {label: '19th', value: '19'},
                                {label: '20th', value: '20'},
                                {label: '21st', value: '21'},
                                {label: '22nd', value: '22'},
                                {label: '23rd', value: '23'},
                                {label: '24th', value: '24'},
                                {label: '25th', value: '25'},
                                {label: '26th', value: '26'},
                                {label: '27th', value: '27'},
                                {label: '28th', value: '28'},
                                {label: 'Last', value: 'last'},
                            ]
                        )
                      }
                  }}
                  label='How Often?'
                  extraClass={'!max-w-[120px] !min-w-[120px] !text-[12px] !pl-2'}
                />
                <div className={'flex md:mt-3 gap-2 items-center'}>
                  {evaluation_frequency !== 'daily' ?
                  <>
                      <span className='min-w-fit pb-1'>on</span>
                      <FormSelect
                          options={dateTypeOptions}
                          value={date_type}
                          
                          onChange={(selected) => {
                              setDateType(selected)
                              if (evaluation_frequency == 'monthly' && selected !== 'day') {
                                setDay('monday')
                                setDayOptions(
                                    [
                                        {label: 'Monday', value:'monday'},
                                        {label: 'Tuesday', value:'tuesday'},
                                        {label: 'Wednesday', value:'wednesday'},
                                        {label: 'Thursday', value:'thursday'},
                                        {label: 'Friday', value:'friday'},
                                        {label: 'Saturday', value:'saturday'},
                                        {label: 'Sunday', value:'sunday'},
                                    ],
                                )
                              } else {
                                  setDay(1)
                                  setDayOptions(
                                      [
                                          {label: '1st', value: '01'},
                                          {label: '2nd', value: '02'},
                                          {label: '3rd', value: '03'},
                                          {label: '4th', value: '04'},
                                          {label: '5th', value: '05'},
                                          {label: '6th', value: '06'},
                                          {label: '7th', value: '07'},
                                          {label: '8th', value: '08'},
                                          {label: '9th', value: '09'},
                                          {label: '10th', value: '10'},
                                          {label: '11th', value: '11'},
                                          {label: '12th', value: '12'},
                                          {label: '13th', value: '13'},
                                          {label: '14th', value: '14'},
                                          {label: '15th', value: '15'},
                                          {label: '16th', value: '16'},
                                          {label: '17th', value: '17'},
                                          {label: '18th', value: '18'},
                                          {label: '19th', value: '19'},
                                          {label: '20th', value: '20'},
                                          {label: '21st', value: '21'},
                                          {label: '22nd', value: '22'},
                                          {label: '23rd', value: '23'},
                                          {label: '24th', value: '24'},
                                          {label: '25th', value: '25'},
                                          {label: '26th', value: '26'},
                                          {label: '27th', value: '27'},
                                          {label: '28th', value: '28'},
                                          {label: 'Last', value: 'last'},
                                      ]
                                  )
                              }
                          }}
                          extraClass={'!max-w-[100px] !min-w-[100px] !text-[13px] !pl-2'}
                      />
                      {evaluation_frequency !== 'weekly' ?
                      <FormSelect
                        options={dayOptions}
                        value={day}
                        onChange={(selected) => {
                          setDay(selected)
                        }}
                        extraClass={'!max-w-[100px] !min-w-[100px] !text-[13px]'}
                      />
                      :
                      null}
                  </> :
                  null
                  }
                  <span className='max-w-fit min-w-fit pb-1'>every</span>
                  <div>
                    <FormInput
                      name='interval_count'
                      type='number'
                      className={'!max-w-[40px]'}
                      value={interval_count}
                      onInput={(e) => {
                        setIntervalCount(e.target.value);
                      }}
                    />
                  </div>
                  {evaluation_frequency == 'daily' ?
                  <span className='min-w-fit pb-1'>day(s), </span>
                  :evaluation_frequency == 'weekly' ?
                  <span className='min-w-fit pb-1'>week(s), </span>
                  :evaluation_frequency == 'monthly' ?
                  <span className='min-w-fit pb-1'>month(s), </span>
                  :evaluation_frequency == 'yearly' ?
                  <span className='min-w-fit pb-1'>year(s), </span>
                  :
                  null}
                  <span className='min-w-fit pb-1'>starts on</span>
                  <div>
                    <FormInput
                      name='start_date'
                      type='date'
                      
                      className={'!max-w-[150px] !min-w-[150px] !pb-[15px] !text-[13px]'}
                      value={auto_evaluation_start_date}
                      onInput={(e) => {
                          setAutoEvaluationStartDate(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className={'flex md:mt-3 gap-2 items-center'}>
                    <span className='min-w-fit pb-1'>ends</span>
                    <FormSelect
                      options={endTypeOptions}
                      value={end_type}
                      
                      onChange={(selected) => {
                        setEndType(selected);
                      }}
                      extraClass={'!max-w-[80px] !min-w-[80px] !text-[13px] !pl-2'}
                    />
                    {end_type !== 'none' && (
                      <>
                        {end_type == 'by' ?
                        <FormInput
                          name='end_date'
                          type='date'
                          value={auto_evaluation_end_date}
                          onInput={(e) => {
                            setAutoEvaluationEndDate(e.target.value);
                          }}
                          className={'!max-w-[150px] !min-w-[150px] !pb-[15px] !text-[13px]'}
                        />
                        :
                        <div className='flex gap-2 items-center'>
                          <FormInput
                            name='occurrences'
                            type='text'
                            className={'!max-w-[50px] !pl-2'}
                            
                            value={occurrences}
                            onInput={(e) => {
                              setOccurences(e.target.value)
                            }}
                          />
                          <span className='min-w-fit pb-1'>occurrences</span>
                        </div>
                        }
                      </>
                    )}
                </div>
              </div>
            </div>
            :
            null} */}

            <div className="w-full">
              <Button
                text={buttonText}
                theme={"third"}
                type="button"
                onClick={() => submitForm()}
                loading={addOKRloading || editOKRloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddPerformanceOKR;
