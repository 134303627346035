import React, { useEffect, useState } from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import {
  overtimeApplicationStatusConfig,
  PAGINATION_DEFAULT,
} from '../../../utils/constants';
import DataTable from '../../datatable';
import Modal from '../modal';
import ApproveOvertime from './approve-overtime-application';
import { useDeclineOvertimeApplication, useGetOvertimeApplications } from '../../../redux/employees/hook/overtime';

function OvertimeApplications({ isOpen, closeModal }) {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: '',
  });

  const [overtimeData, setOvertimeData] = useState();

  const [showApprove, setShowApprove] = useState(false);

  const { data: overtime_applications } = useGetOvertimeApplications(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
  );

  const { mutateAsync: declineApplication } = useDeclineOvertimeApplication();

  const tableConfig = {
    headers: [
      'Date Created',
      'Employee Name',
      'Mobile',
      'Description',
      'Overtime Hours',
      'Rate Per Hour',
      'Amount',
      'Status',
    ],
    keys: [
      'created',
      'employeeName',
      'mobile',
      'description',
      'overtime_hours',
      'overtime_rate_per_hour',
      'amount',
      'status',
    ],
    mobileHeader: {
      left: {
        title: 'Employee Name',
        key: 'employeeName',
      },
      right: {
        title: 'Status',
        key: 'status',
      },
    },
  };

  const actionConfig = [
    {
      name: 'Approve',
      color: 'green',
      action: (id) => {
        let index = overtime_applications.data.findIndex(
          (item) => item.id === id,
        );
        setOvertimeData(overtime_applications.data[index]);
        setShowApprove(true);
      },
    },
    {
      name: 'Decline',
      color: 'red',
      action: (id) => {
        let index = overtime_applications.data.findIndex(
          (item) => item.id === id,
        );
        declineApplication(overtime_applications.data[index].id);
      },
    },
  ];

  return (
    <Modal
      scrollable={true}
      className='!min-h-screen !min-w-[100%] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      {showApprove ? (
        <ApproveOvertime
          isOpen={showApprove}
          closeModal={() => setShowApprove(false)}
          overtime={overtimeData}
        />
      ) : null}
      <div className='flex flex-col !min-h-fit overflow-auto'>
        <div className='pt-4 pr-8 pl-8'>
          <div className='text-center flex flex-col'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Overtime Applications</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
        </div>
        <hr className='divider mb-2' />
        <DataTable
          data={overtime_applications}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          statusConfig={overtimeApplicationStatusConfig}
          updatePagination={(data) => setPagination(data)}
          computedActions={true}
          searchData={true}
          noCheck={true}
        />
      </div>
    </Modal>
  );
}

export default OvertimeApplications;
