import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  employeeStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../utils/constants";
import Button from "../../button";
import DataTable from "../../datatable";
import {
  useGetDepartmentEmployees,
  useRemoveEmployeesFromDepartment,
} from "../../../redux/human-resources/hook/departments";
import AddEmployeeToDepartment from "../../modal/hr-section/departments/add-employee-to-department";

const DepartmentOverviewTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });
  const { id } = useParams();

  const { data: department_employees } = useGetDepartmentEmployees(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const [showAddEmployeeToDepartment, setShowAddEmployeeToDepartment] =
    useState(false);
  const { mutateAsync: removeFromDepartment } =
    useRemoveEmployeesFromDepartment();

  const tableConfig = {
    headers: ["Date Added", "Employee Name", "Email", "Mobile"],
    keys: ["created", "employeeName", "employee_email", "employee_mobile"],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Mobile",
        key: "employee_mobile",
      },
    },
  };

  const actionConfig = [
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = department_employees.data.findIndex(
          (item) => item.id == id
        );
        if (index != -1) {
          removeFromDepartment({
            departmentemployee_lists:
              department_employees.data[index].id.toString(),
          });
        }
      },
    },
    {
      name: "Move",
      color: "red",
      action: (id) => {
        let index = department_employees.data.findIndex(
          (item) => item.id == id
        );
        if (index != -1) {
          removeFromDepartment({
            departmentemployee_lists:
              department_employees.data[index].id.toString(),
          });
        }
      },
    },
  ];

  const bulkActionConfig = [
    {
      label: "Remove",
      value: "active",
      action: (data) => {
        let newData = data.split(",");
        let result = [];

        newData.forEach((id) => {
          let index = department_employees.data.findIndex(
            (item) => item.id == id
          );
          if (index != -1) {
            result.push(department_employees.data[index].id);
          }
        });
        removeFromDepartment({ departmentemployee_lists: result.join(",") });
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddEmployeeToDepartment ? (
        <AddEmployeeToDepartment
          isOpen={showAddEmployeeToDepartment}
          departmentData={id}
          closeModal={() => setShowAddEmployeeToDepartment(false)}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Employee List
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Add Employees To Department"
              type="submit"
              leftIcon={"add"}
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              onClick={() => setShowAddEmployeeToDepartment(true)}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2" />
      <DataTable
        data={department_employees}
        tableConfig={tableConfig}
        bulkActionConfig={bulkActionConfig}
        statusConfig={employeeStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        searchData={true}
        filterByStatus={true}
      />
    </div>
  );
};

export default DepartmentOverviewTab;
