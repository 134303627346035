import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CloseSVG from '../../../assets/svg/close.svg';
import { usePurchaseSingleAirtime } from '../../../redux/airtime/hook';
import { formValidation } from '../../../utils/functions';
import Button from '../../button';
import FormInput from '../../form-input';
import FormSelect from '../../form-select';
import Modal from '../modal';

function SingleAirtime({ isOpen, closeModal, refresh }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const history = useHistory();

  const { mutateAsync: runAirtime, isLoading: airtimeloading } =
    usePurchaseSingleAirtime();
  const [network, setNetwork] = useState('mtn');

  const networkOptions = [
    { value: 'mtn', label: 'MTN' },
    { value: 'airtel', label: 'Airtel' },
    { value: 'glo', label: 'Glo' },
    { value: 'etisalat', label: 'Etisalat' },
  ];

  function onAirtimePurchaseSuccess(ref) {
    history.push(`/airtime/preview/${ref}`);
  }

  const submitForm = async (data) => {
    const first_name = DOMPurify.sanitize(data?.first_name);
    const last_name = DOMPurify.sanitize(data?.last_name);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const description = DOMPurify.sanitize(data?.description);
    const amount = DOMPurify.sanitize(data?.amount);

    let payload = {
      first_name,
      last_name,
      mobile,
      network,
      description,
      amount,
    };

    await runAirtime(payload).then((response) => {
      onAirtimePurchaseSuccess(response.data.ref);
    });
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-4' onSubmit={handleSubmit(submitForm)}>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Purchase Airtime</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 pr-2'>
                  <FormInput
                    label='First Name'
                    name='first_name'
                    type='text'
                    placeholder={'Enter recipient first name'}
                    readOnly={airtimeloading}
                    error={errors.first_name}
                    errorMessage={
                      errors.first_name && errors.first_name.message
                    }
                    inputRef={register(formValidation('text', true))}
                  />
                </div>

                <div className='w-full md:w-1/2 pl-2'>
                  <FormInput
                    label='Last Name'
                    name='last_name'
                    type='text'
                    placeholder={'Enter recipient last name'}
                    readOnly={airtimeloading}
                    error={errors.last_name}
                    errorMessage={errors.last_name && errors.last_name.message}
                    inputRef={register(formValidation('text', true))}
                  />
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-1/2 pr-2'>
                  <FormInput
                    label='Mobile Number'
                    name='mobile'
                    type='text'
                    placeholder={'Enter recipient mobile'}
                    readOnly={airtimeloading}
                    error={errors.mobile}
                    errorMessage={errors.mobile && errors.mobile.message}
                    inputRef={register(formValidation('text', true))}
                  />
                </div>

                <div className='w-full md:w-1/2 pl-2'>
                  <FormSelect
                    defaultValue={network}
                    options={networkOptions}
                    onChange={(selected) => {
                      setNetwork(selected);
                    }}
                    label='Select Network'
                  />
                </div>
              </div>
              <div className='flex flex-row md:flex-col w-full justify-between'>
                <div className='w-full md:w-2/3 pr-2'>
                  <FormInput
                    label='Description'
                    name='description'
                    type='text'
                    placeholder={'Enter description'}
                    readOnly={airtimeloading}
                    error={errors.description}
                    errorMessage={errors.description && errors.description.message}
                    inputRef={register(formValidation('text', true))}
                  />
                </div>
                <div className='w-full md:w-1/3 pl-2'>
                  <FormInput
                    label='Amount'
                    name='amount'
                    type='number'
                    placeholder={'Enter amount'}
                    inputRef={register(formValidation('number', true, 0))}
                    readOnly={airtimeloading}
                    error={errors.amount}
                    errorMessage={errors.amount && errors.amount.message}
                  />
                </div>
              </div>
            </div>

            <div className='w-full mt-[20px]'>
              <Button
                text='Run Airtime'
                type='submit'
                disabled={!isValid}
                loading={airtimeloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default SingleAirtime;
