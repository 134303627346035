import { Axios } from './axios';

const login = async (payload) => {
  const { data } = await Axios.post('/login', payload);
  return data;
};

const googleLogin = async (payload) => {
  const { data } = await Axios.post('/google_auth', payload);
  return data;
};

const register = async (payload) => {
  const { data } = await Axios.post('/register', payload,
  {
    timeout: 0
  });
  return data;
};

const passwordRecover = async (payload) => {
  const { data } = await Axios.post('/recover-password', payload);
  return data;
};

const passwordReset = async (payload) => {
  const { data } = await Axios.post('/reset-password', payload);
  return data;
};

const mobilePasswordReset = async (payload) => {
  const { data } = await Axios.post('/mobile/reset-password-email', payload);
  return data;
};

const mobilePasswordResetMobile = async (payload) => {
  const { data } = await Axios.post('/mobile/reset-password-mobile', payload);
  return data;
};

const adminPasswordSet = async (payload) => {
  const { data } = await Axios.post('/set-password', payload);
  return data;
};

const updatePassword = async (payload) => {
  const { data } = await Axios.post('/change-password', payload);
  return data;
};

const fetchUserWithToken = async (payload) => {
  const { data } = await Axios.post('/user/token/' + payload);
  return data;
};

const fetchUserWithEmailToken = async (payload) => {
  const { data } = await Axios.post(
    '/user/email-verification-token/' + payload,
  );
  return data;
};

const profile = async () => {
  const { data } = await Axios.get('/me');
  return data;
};

const verifyEmail = async (payload) => {
  const { data } = await Axios.post('/verify-email', payload);
  return data;
};

const phoneVerification = async (payload) => {
  const { data } = await Axios.post('/phone-verification', payload);
  return data;
};

const bvnVerification = async (payload) => {
  const { data } = await Axios.post('/bvn-verification', payload,
  {
    timeout: 0
  });
  return data;
};

const cacVerification = async (payload) => {
  const { data } = await Axios.post('/cac-verification', payload,
  {
    timeout: 0
  });
  return data;
};

const resendEmailVerification = async (payload) => {
  const { data } = await Axios.post('/resend-verification-email', payload);
  return data;
};

const switchSection = async (payload) => {
  const { data } = await Axios.get(`/switch-section/${payload}`);
  return data;
};

const updateMobile = async (payload) => {
  const { data } = await Axios.post('/update-mobile', payload);
  return data;
};

const updateProfile = async (payload) => {
  const { data } = await Axios.put('/me', payload);
  return data;
};

const enable2Fa = async (payload) => {
  const { data } = await Axios.post('/enable-two-fa', payload);
  return data;
};

const activate2fa = async (payload) => {
  const { data } = await Axios.post('/activate-two-fa', payload);
  return data;
};

const disable2Fa = async (payload) => {
  const { data } = await Axios.get('/disable-two-fa', payload);
  return data;
};

const login2Fa = async (payload) => {
  const { data } = await Axios.post('/two-fa-login', payload);
  return data;
};

const backupLogin2Fa = async (payload) => {
  const { data } = await Axios.post('/backup-login', payload);
  return data;
};

//Transaction PIN

const addTransactionPIN = async (payload) => {
  const { data } = await Axios.post('/transaction-pin', payload);
  return data;
};

const requestPINChangeOTP = async () => {
  const { data } = await Axios.get('/request-transaction-pin-otp');
  return data;
};



const updateTransactionPIN = async (payload) => {
  const { data } = await Axios.put('/transaction-pin', payload);
  return data;
};

export const user = {
  login,
  googleLogin,
  register,
  profile,
  passwordRecover,
  passwordReset,
  mobilePasswordReset,
  mobilePasswordResetMobile,
  updatePassword,
  adminPasswordSet,
  fetchUserWithToken,
  fetchUserWithEmailToken,
  verifyEmail,
  phoneVerification,
  bvnVerification,
  cacVerification,
  resendEmailVerification,
  
  switchSection,
  updateProfile,
  updateMobile,

  enable2Fa,
  activate2fa,
  disable2Fa,
  login2Fa,
  backupLogin2Fa,

  requestPINChangeOTP,
  addTransactionPIN,
  updateTransactionPIN,
};
