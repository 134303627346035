export const onboardingSteps = [
  {
    title: 'first-time',
    steps: [
      {
        selector: '#dashboard',
        content:
          'See an overview for your payroll, employees, wallet balances in one view',
        title: 'Dashboard Overview',
        nextStepRoute: '/employees',
      },
      {
        selector: '#employees',
        content:
          'Manage your employees salary, payroll, reimbursement,bonus and more',
        title: 'Employee Management',
        nextStepRoute: '/employee-hmo',
      },
      {
        selector: '#hmo',
        content:
          'Manage your advance and basic payroll with ability to preview and view transaction history',
        title: 'HMO Management',
        nextStepRoute: '/payroll',
      },
      {
        selector: '#payroll',
        content:
          'Pay your employees easily with our Advanced and Basic payroll. Also keep track of your payroll transactions from this page',
        title: 'Payroll Management',
        nextStepRoute: '/airtime',
      },
      {
        selector: '#airtime',
        content:
          'Purchase airtime for employees. You can buy single or multiple airtime for employees',
        title: 'Airtime Purchase',
        nextStepRoute: '/wallet',
      },
      {
        selector: '#wallet',
        content:
          'Manage your funds and download your account statement from here',
        title: 'Wallet Management',
        nextStepRoute: '/statutory-payments',
      },
      
      {
        selector: '#statutory',
        content:
          'Manage statutory payments for Pensions, PAYE Tax, NHF, NHIS, NSITF, ITF',
        title: 'Statutory Compliance',
        nextStepRoute: '/settings',
      },
      {
        selector: '#settings',
        content:
          'Manage all your company settings from this page. Manage admins, update your company information, choose your plans and billings, approver settings, payroll settings and account verification.',
        title: 'Settings',
        nextStepRoute: '/dashboard',
      },
      {
        selector: '#dashboard',
        content:
          'Enjoy your freedom and ease.',
        title: 'Settings',
        nextStepRoute: '',
      },
    ],
  },
  {
    title: 'payroll-employee-guide',
    steps: [
      {
        selector: '#employee-overview',
        content:
          'You can manage your employee data from this tab. You can add new employees, update employee data, activate/deactivate employees, upload employee profile picture',
        title: 'Employee Overview',
        nextStepRoute: '/employee?tab=1',
      },
      {
        selector: '#salary-overview',
        content:
        'You can manage your employee salaries. You can add, review, update or delete employee salaries',
        title: 'Salary Overview',
        nextStepRoute: '/employee?tab=2',
      },
      {
        selector: '#payroll-group-overview',
        content:
          'See an overview for your payroll, employees, wallet balance in one view',
        title: 'Dashboard Overview',
        nextStepRoute: '/employee?tab=3',
      },
      {
        selector: '#bonus-overview',
        content:
          'See an overview for your payroll, employees, wallet balance in one view',
        title: 'Dashboard Overview',
        nextStepRoute: '/employee?tab=4',
      },
      {
        selector: '#salary-advance-overview',
        content:
          'See an overview for your payroll, employees, wallet balance in one view',
        title: 'Dashboard Overview',
        nextStepRoute: '/employee?tab=5',
      },
      {
        selector: '#reimbursements-overview',
        content:
          'See an overview for your payroll, employees, wallet balance in one view',
        title: 'Dashboard Overview',
        nextStepRoute: '/employee?tab=6',
      },
      {
        selector: '#suspensions-overview',
        content:
          'See an overview for your payroll, employees, wallet balance in one view',
        title: 'Dashboard Overview',
        nextStepRoute: '/employee?tab=7',
      },
    ],
  },
  {
    title: 'new-company',
    steps: [
      {
        selector: '#first-step',
        content: 'This is my first Step',
      },
      {
        selector: '#second-step',
        content: 'This is my second Step',
      },
    ],
  },
  {
    title: 'fund-wallet',
    steps: [
      {
        selector: '#first-step',
        content: 'This is my first Step',
      },
      {
        selector: '#second-step',
        content: 'This is my second Step',
      },
    ],
  },
];

