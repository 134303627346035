import { Axios } from '../axios';

//Salaries
const getEmployeeSalaries = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(`/salaries?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  {
    timeout: 0
  });

  return data?.data;
};

const getSalariesByBranch = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/salaries-by-branch/${payload.branch_id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
  );

  return data?.data;
};

const addSalary = async (payload) => {
  const { data } = await Axios.post('/salaries', payload);
  return data;
};

const editSalary = async (payload) => {
  const { data } = await Axios.put('/salaries/' + payload.id, payload.body);
  return data;
};

const addBulkSalary = async (payload) => {
  const { data } = await Axios.post('/salaries/multiple', payload,
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const getEmployeeSalary = async (payload) => {
  const { data } = await Axios.get('/salaries/' + payload);
  return data?.data;
};

const getSalaryByEmployee = async (payload) => {
  const { data } = await Axios.get('/salaries/by-employee/' + payload);
  return data?.data;
};

const deleteSalary = async (payload) => {
  const { data } = await Axios.delete('/salaries/' + payload);
  return data;
};

//Salary Reviews
const getSalaryReviews = async (payload) => {
  let start_date = payload.start_date ? '&start_date=' + payload.start_date : '';
  let end_date = payload.end_date ? '&end_date=' + payload.end_date : '';
  let search = payload.search ? '&search=' + payload.search : '';

  const { data } = await Axios.get(
    `/salary-reviews?limit=${payload.limit}&page=${payload.page}${start_date}${end_date}${search}`,
  );

  return data?.data;
};

const getSalaryReview = async (payload) => {
  const { data } = await Axios.get('/salary-reviews/' + payload);
  return data?.data;
};

const addSalaryReview = async (payload) => {
  const { data } = await Axios.post('/salary-reviews', payload);
  return data;
};

const deleteSalaryReview = async (payload) => {
  const { data } = await Axios.delete('/salary-reviews/' + payload);
  return data;
};

const addBulkSalaryReview = async (payload) => {
  const { data } = await Axios.post('/salary-reviews/bulk', payload,
  {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  });
  return data;
};

const getSalaryReviewByEmployee = async (payload) => {
  const { data } = await Axios.get('/salary-reviews/by-employee/' + payload.id, payload);
  return data?.data;
};

const bulkDeleteSalaryReview = async (payload) => {

  const { data } = await Axios.post('/salary-reviews/bulk-delete', payload );
  return data;
};

export const employeeSalaries = {
  //Salary
  getEmployeeSalaries,
  getSalariesByBranch,
  addSalary,
  getEmployeeSalary,
  editSalary,
  getSalaryByEmployee,
  addBulkSalary,
  deleteSalary,

  //Salary Review
  getSalaryReviews,
  getSalaryReview,
  addSalaryReview,
  deleteSalaryReview,
  addBulkSalaryReview,
  getSalaryReviewByEmployee,
  bulkDeleteSalaryReview
};
