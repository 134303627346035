import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useHistory } from "react-router-dom";
import AddBranch from "../../../components/modal/hr-section/branches/add-branch";
import DataTable from "../../../components/datatable";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import {
  useDeleteBranch,
  useGetBranches,
  useGetBranchSummary,
} from "../../../redux/human-resources/hook/branches";
import UnauthorizedPage from "../../errors/unauthorized";
import { useSelector } from "react-redux";
import BranchSVG from "../../../assets/svg/branch-widget-icon.svg";
import DoYouWantToModal from "../../../components/modal/do-you-want-to";
import AddEmployeeToBranch from "../../../components/modal/hr-section/branches/add-employee-to-branch";
import AddTeamsToBranch from "../../../components/modal/hr-section/branches/add-branch-team";
import AddDepartmentsToBranch from "../../../components/modal/hr-section/branches/add-branch-department";

const HRBranchPage = () => {
  useEffect(() => {}, []);

  const { user } = useSelector((state) => state.userSlice);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (user.permissions.company_permission.branch_read !== 1) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: branches } = useGetBranches(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const { mutateAsync: removeBranch } = useDeleteBranch();

  const history = useHistory();

  const { data: getBranchSummary } = useGetBranchSummary();
  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [tableType, setTableType] = useState("branch");

  const switchFunction = () => {
    if (tableType === "branch") {
      setTableType("table");
    } else {
      setTableType("branch");
    }
  };

  const [branchSummary, setBranchSummary] = useState([]);

  const tableConfig = {
    headers: [
      "Branch Name",
      "Location",
      "Type",
      "Employees",
      "Teams",
      "Departments",
      "Manager",
    ],
    keys: [
      "name",
      "location",
      "branch_type",
      "employee_count",
      "team_count",
      "department_count",
      "branch",
    ],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Branch Name",
        key: "name",
      },
    },
  };

  const [showAddEmployeeToBranch, setShowAddEmployeeToBranch] = useState(false);
  const [showAddTeamToBranch, setShowAddTeamToBranch] = useState(false);
  const [showAddDepartmentToBranch, setShowAddDepartmentToBranch] =
    useState(false);

  const [branchAddData, setBranchAddData] = useState(false);

  const actionConfig = [
    {
      name: "View",
      color: "green",
      action: (id) => {
        let index = branches.data.findIndex((item) => item.id === id);
        history.push("/branch/view/" + branches.data[index].id);
      },
    },

    {
      name: "Add Employees",
      color: "orange",
      action: (id) => {
        let index = branches.data.findIndex((item) => item.id === id);
        setBranchAddData(branches.data[index].id);
        setShowAddEmployeeToBranch(true);
      },
    },
    {
      name: "Add Teams",
      color: "orange",
      action: (id) => {
        let index = branches.data.findIndex((item) => item.id === id);
        setBranchAddData(branches.data[index].id);
        setShowAddTeamToBranch(true);
      },
    },
    {
      name: "Add Department",
      color: "orange",
      action: (id) => {
        let index = branches.data.findIndex((item) => item.id === id);
        setBranchAddData(branches.data[index].id);
        setShowAddDepartmentToBranch(true);
      },
    },
    {
      name: "Update",
      color: "orange",
      action: (id) => {
        let index = branches.data.findIndex((item) => item.id === id);
        setTitle("Edit Branch");
        setButtonText("UPDATE");
        setBranchData(branches.data[index]);
        setShowAddBranch(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = branches.data.findIndex((item) => item.id === id);
        setDeleteId(branches.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  const [showAddBranch, setShowAddBranch] = useState(false);
  const [branchData, setBranchData] = useState();
  const [title, setTitle] = useState("Add Branch");
  const [buttonText, setButtonText] = useState("ADD");

  const deleteSingleBranch = async (id) => {
    let index = branches.data.findIndex((item) => item.id === id);
    await removeBranch(branches.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };
  useEffect(() => {
    if (getBranchSummary) {
      setBranchSummary([
        {
          title: "TOTAL BRANCHES",
          value: getBranchSummary?.branch_count,
          icon: BranchSVG,
        },
      ]);
    } else {
      setBranchSummary([
        {
          title: "TOTAL BRANCHES",
          value: 0,
          icon: BranchSVG,
        },
      ]);
    }
  }, [getBranchSummary]);

  return (
    <DashboardLayout title="Branches Management">
      {showAddDepartmentToBranch ? (
        <AddDepartmentsToBranch
          isOpen={showAddDepartmentToBranch}
          branchData={branchAddData}
          closeModal={() => setShowAddDepartmentToBranch(false)}
        />
      ) : null}
      {showAddTeamToBranch ? (
        <AddTeamsToBranch
          isOpen={showAddTeamToBranch}
          branchData={branchAddData}
          closeModal={() => setShowAddTeamToBranch(false)}
        />
      ) : null}
      {showAddEmployeeToBranch ? (
        <AddEmployeeToBranch
          isOpen={showAddEmployeeToBranch}
          branchData={branchAddData}
          closeModal={() => setShowAddEmployeeToBranch(false)}
        />
      ) : null}
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {showDoYouWant ? (
            <DoYouWantToModal
              isOpen={showDoYouWant}
              closeModal={() => setShowDoYouWant(false)}
              id={deleteId}
              title={"Are you sure you want to delete this branch?"}
              buttonText={"DELETE BRANCH"}
              btnFunction={deleteSingleBranch}
            />
          ) : null}
          {showAddBranch ? (
            <AddBranch
              isOpen={showAddBranch}
              closeModal={() => setShowAddBranch(false)}
              branchData={branchData}
              title={title}
              buttonText={buttonText}
            />
          ) : null}
          <div className="flex flex-col -mt-2">
            <div className="flex flex-col bg-white mt-[10px] rounded-md">
              <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
                <div className="p3-bold header-4">Branches</div>
                <div className="flex flex-col md:flex-row items-start gap-[10px] md:items-center">
                  <div className="md:w-fit w-[100%]">
                    <Button
                      text="New Branch"
                      type="button"
                      theme="primary"
                      className="flex gap-2 !h-[35px] w-fit p-2 "
                      textClass={"!text-[11px]"}
                      leftIcon={"add"}
                      onClick={() => {
                        setTitle("Add Branch");
                        setButtonText("Save Branch");
                        setBranchData(null);
                        setShowAddBranch(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className="divider mt-2 mb-2" />
              <DataTable
                data={branches}
                tableConfig={tableConfig}
                actionConfig={actionConfig}
                updatePagination={(data) => setPagination(data)}
                computedActions={true}
                tableType={tableType}
                switchFunction={switchFunction}
                layoutSwitch={true}
                searchData={true}
                noCheck={true}
              />
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default HRBranchPage;
