import React, { useState } from 'react';
import Button from '../../../button';
import ButtonSelect from '../../../button-select';
import CloseSVG from '../../../../assets/svg/close.svg';
import Modal from '../../modal';
import AddEmployeeOnboarding from './add-onboarding';
import AddBulkEmployeeOnboarding from './add-bulk-onboarding';

function SelectOnboardingOption({ isOpen, closeModal }) {
  const [option, setOption] = useState('single');
  const [showAddOnboarding, setShowAddOnboarding] = useState(false);
  const [showAddBulkExit, setShowAddBulkExit] = useState(false);

  function submit() {
    if (option === 'single') {
      setShowAddOnboarding(true);
    } else if (option === 'bulk') {
      setShowAddBulkExit(true);
    }
  }

  return (
    <>
      {showAddOnboarding ? (
        <AddEmployeeOnboarding
          isOpen={showAddOnboarding}
          closeModal={() => setShowAddOnboarding(false)}
          onboardingData={null}
          title={'Add Employee Onboarding'}
          buttonText={'SAVE ONBOARDING'}
        />
      ) : null}

      <AddBulkEmployeeOnboarding
        isOpen={showAddBulkExit}
        closeModal={() => setShowAddBulkExit(false)}
      />

      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Select onboarding upload option</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col items-center w-full min-h-fit'>
            <span className='text-[15px] -mb-3'>
              Select your desired option to add onboarding
            </span>
            <div className='flex flex-col justify-center h-full min-h-[400px] max-w-[85%] w-full'>
              <ButtonSelect
                title={'Add Single Employee Onboarding'}
                subtitle={'Add employee onboarding one after the other'}
                isActive={option === 'single'}
                onClick={() => setOption('single')}
              />

              <ButtonSelect
                title={'Add Multiple Employee Onboarding'}
                subtitle={
                  'Select multiple employees for onboarding'
                }
                isActive={option === 'bulk'}
                onClick={() => setOption('bulk')}
                className='mt-8'
              />

              <div className='w-full mt-[20px]'>
                <Button text='NEXT' type='submit' onClick={() => submit()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SelectOnboardingOption;
