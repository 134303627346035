import React from "react";
import DataTable from "../../datatable";
import { useState, useEffect } from "react";
import {
  PAGINATION_DEFAULT,
  reImbursementStatusConfig,
} from "../../../utils/constants";
import {
  useDeclineReimbursementDisbursement,
  useDeleteReimbursement,
  useSingleGetEmployeeReimbursements,
} from "../../../redux/employees/hook/reimbursements";
import { useParams } from "react-router-dom";
import AddReImbursement from "../../modal/employees/add-reimbursement";
import Button from "../../button";
import PayReimbursement from "../../modal/employees/pay-reimbursement";
import EmployeeReimbursementApplications from "../../modal/employees/view-employee/employee-reimbursement-applications";

const SingleEmployeeReimbursementTab = () => {
  useEffect(() => {}, []);
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
  });
  const { id } = useParams();

  const { mutateAsync: deleteReimbursement } = useDeleteReimbursement();

  const { data: employeeReimbursements } = useSingleGetEmployeeReimbursements(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter
  );
  const [showPay, setShowPay] = useState(false);

  const [applicationPay, setApplicationPay] = useState(false);

  const [reimbursementData, setReimbursementData] = useState();

  const [showReimbursementApplications, setShowReimbursementApplications] =
    useState(false);

  const { mutateAsync: declineReimbursement } =
    useDeclineReimbursementDisbursement();

  const [showAddReImbursement, setShowAddReImbursement] = useState(false);
  const [reimbData, setReimbData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const tableConfig = {
    headers: [
      "Employee Name",
      "Phone Number",
      "Purpose",
      "Type",
      "Month",
      "Amount",
      "Status",
    ],
    keys: [
      "employeeName",
      "phone",
      "expenses_description",
      "typeLabel",
      "formatted_month",
      "formatted_expenses_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Status",
        key: "status",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "green",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        setButtonText("Update");
        setTitle("Update Reimbursement");
        setReimbData(employeeReimbursements.data[index]);
        setShowAddReImbursement(true);
      },
    },
    {
      name: "Pay Now",
      color: "green",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        setReimbursementData(employeeReimbursements.data[index]);
        setApplicationPay(false);
        setShowPay(true);
      },
    },
    {
      name: "Approve Payment",
      color: "green",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        setReimbursementData(employeeReimbursements.data[index]);
        setApplicationPay(true);
        setShowPay(true);
      },
    },
    {
      name: "Decline Payment",
      color: "red",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        declineReimbursement(employeeReimbursements.data[index].id);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        deleteReimbursement(employeeReimbursements.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showPay ? (
        <PayReimbursement
          isOpen={showPay}
          closeModal={() => setShowPay(false)}
          applicationPay={applicationPay}
          reimbursement={reimbursementData}
        />
      ) : null}
      {showReimbursementApplications ? (
        <EmployeeReimbursementApplications
          isOpen={showReimbursementApplications}
          closeModal={() => setShowReimbursementApplications(false)}
        />
      ) : null}
      {showAddReImbursement ? (
        <AddReImbursement
          isOpen={showAddReImbursement}
          closeModal={() => {
            setShowAddReImbursement(false);
          }}
          reimbData={reimbData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Employee Reimbursements
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Add Reimbursement"
              type="submit"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setReimbData(null);
                setButtonText("Save");
                setTitle("Add Reimbursement");
                setShowAddReImbursement(true);
              }}
            />
          </div>
          <div className="md:w-fit w-[100%]">
            <Button
              text="Applications"
              type="submit"
              theme="rpimary"
              leftIcon={"view"}
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              onClick={() => setShowReimbursementApplications(true)}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-2" />
      <DataTable
        data={employeeReimbursements}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        statusConfig={reImbursementStatusConfig}
        computedActions={true}
        noCheck={true}
      />
    </div>
  );
};

export default SingleEmployeeReimbursementTab;
