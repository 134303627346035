import React, { useEffect } from "react";
import { useState } from "react";
import classNames from "classnames";
import FormSelect from "../form-select";
import FormSwitch from "../form-switch";
import Button from "../button";
import CollapsingIcon from "../collapsing-icon";
import { Collapse } from "reactstrap";
import FormInput from "../form-input";
import { useForm } from "react-hook-form";
import {
  useGetSalarySettings,
  useUpdateSalarySettings,
} from "../../redux/company/hook";
import LoadingBar from "../loader-bar";
import SettingsSVG from "../../assets/svg/settings-image.svg";
import PlusSVG from "../../assets/svg/plus-icon.svg";

const SalarySettings = () => {
  const { data: salarySettings, isLoading } = useGetSalarySettings();
  const [dayCount, setDayCount] = useState("seven_days");
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(true);
  const [totalAllowance, setTotalAllowance] = useState();
  const { mutateAsync: updateSalarySettings, isLoading: loading } =
    useUpdateSalarySettings();

  const {
    register,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });

  const [formData, setFormData] = useState({
    tax: true,
    pension: true,
    nhf: false,
    nhis: false,
    nstf: true,
    itf: true,
    can_apply_for_advance: true,
    can_apply_for_reimbursements: true,
    can_apply_for_overtime: false,
    overtime_hourly_rate: 1000,
    max_overtime_hours_monthly: 10,
    basic_salary: 40,
    housing_allowance: 10,
    transport_allowance: 15,
    leave_allowance: 10,
    lunch_allowance: 10,
    utility_allowance: 10,
    other_allowance: 5,
    pension_calculator: "gross",
    employer_pension: 8,
    employee_pension: 10,
    employee_nhis: 5,
    employer_nhis: 10,
    nhf_percentage: 2.5,
    benefit_in_kind: 0,
  });

  const [additionalAllowance, setAdditionalAllowance] = useState([]);

  const [allowanceTitle, setAllowanceTitle] = useState();

  const [addingAllowance, setAddingAllowance] = useState(false);

  const addAdditionalAllowance = () => {
    let newArray = JSON.parse(JSON.stringify(additionalAllowance));

    let name = allowanceTitle.toLowerCase();
    newArray.push({
      title: allowanceTitle,
      name: name.split(" ").join("_"),
      rate: 0,
    });
    setAdditionalAllowance(newArray);
    setAllowanceTitle();
    setAddingAllowance(false);
  };

  const removeAdditionalAllowance = (index) => {
    let newArray = JSON.parse(JSON.stringify(additionalAllowance));

    let data = [...newArray];
    if (index > -1) {
      // only splice array when item is found
      data.splice(index, 1); // 2nd parameter means remove one item only
      setAdditionalAllowance([...data]);
    }
  };
  useEffect(() => {
    if (salarySettings) {
      setFormData({
        tax: salarySettings?.tax,
        pension: salarySettings?.pension,
        nhf: salarySettings?.nhf,
        nhis: salarySettings?.nhis,
        nstf: salarySettings?.nstf,
        itf: salarySettings?.itf,
        can_apply_for_reimbursements:
          salarySettings?.can_apply_for_reimbursements,
        can_apply_for_advance: salarySettings?.can_apply_for_advance,
        can_apply_for_overtime:
          salarySettings?.can_apply_for_overtime === 1 ? true : false,
        overtime_hourly_rate: salarySettings?.overtime_hourly_rate,
        max_overtime_hours_monthly: salarySettings?.max_overtime_hours_monthly,
        basic_salary: salarySettings?.basic_salary,
        transport_allowance: salarySettings?.transport_allowance,
        housing_allowance: salarySettings?.housing_allowance,
        leave_allowance: salarySettings?.leave_allowance,
        lunch_allowance: salarySettings?.lunch_allowance,
        utility_allowance: salarySettings?.utility_allowance,
        other_allowance: salarySettings?.other_allowance,
        pension_calculator: salarySettings?.pension_calculator,
        employer_pension: salarySettings?.employer_pension,
        employee_pension: salarySettings?.employee_pension,
        employee_nhis: salarySettings?.employee_nhis,
        employer_nhis: salarySettings?.employer_nhis,
        nhf_percentage: salarySettings?.nhf_percentage,

        benefit_in_kind: 0,
      });
      setDayCount(salarySettings?.days_count);
      setTotalAllowance(salarySettings?.total_rate);
      setAdditionalAllowance(
        salarySettings?.additional_allowances
          ? JSON.parse(salarySettings?.additional_allowances)
          : []
      );
    }
  }, [salarySettings]);

  const rateSettings = [
    {
      title: "Calculate PAYE tax",
      name: "tax",
    },
    {
      title: "Calculate Pension",
      name: "pension",
    },
    {
      title: "Calculate National Housing Fund (NHF)",
      name: "nhf",
    },
    {
      title: "Calculate National Health Insurance Scheme (NHIS)",
      name: "nhis",
    },
  ];
  const applicationSettings = [
    {
      title: "Can employees apply for salary advances?",
      name: "can_apply_for_advance",
    },
    {
      title: "Can employees apply for reimbursements?",
      name: "can_apply_for_reimbursements",
    },
    {
      title: "Can employees apply for Overtime?",
      name: "can_apply_for_overtime",
    },
  ];

  const AdvanceSalarySettings = [
    {
      title: "Basic Pay(%)",
      name: "basic_salary",
    },
    {
      title: "Housing Allowance(%)",
      name: "housing_allowance",
    },
    {
      title: "Transport Allowance(%)",
      name: "transport_allowance",
    },
    {
      title: "Leave Allowance(%)",
      name: "leave_allowance",
    },
    {
      title: "Lunch Allowance(%)",
      name: "lunch_allowance",
    },
    {
      title: "Utility Allowance(%)",
      name: "utility_allowance",
    },
    {
      title: "Other Allowance(%)",
      name: "other_allowance",
    },
  ];

  useEffect(() => {
    let additional_allowance = 0;
    if (additionalAllowance.length > 0) {
      additionalAllowance.forEach((element) => {
        additional_allowance += parseInt(element.rate);
      });
    }
    let computeTotalAllowance =
      parseInt(formData.basic_salary) +
      parseInt(formData.housing_allowance) +
      parseInt(formData.transport_allowance) +
      parseInt(formData.benefit_in_kind) +
      parseInt(formData.leave_allowance) +
      parseInt(formData.lunch_allowance) +
      parseInt(formData.utility_allowance) +
      parseInt(formData.other_allowance) +
      parseInt(additional_allowance);
    setTotalAllowance(computeTotalAllowance || 0);
  }, [formData, additionalAllowance]);

  const statutorySettings = [
    {
      title: "Employee Pension (%)",
      name: "employee_pension",
    },
    {
      title: "Employer Pension (%)",
      name: "employer_pension",
    },
    {
      title: "Employer NHIS (%) - (ReadOnly)",
      name: "employer_nhis",
    },
    {
      title: "Employee NHIS (%) - (ReadOnly)",
      name: "employee_nhis",
    },
    {
      title: "NHF (%) - (ReadOnly)",
      name: "nhf_percentage",
    },
  ];

  const dayCountOptions = [
    { value: "seven_days", label: "Calendar Days" },
    { value: "week_days", label: "Working Days" },
  ];

  const pensionCalculatorOptions = [
    { value: "bht", label: "Basic, Housing and Transport" },
    { value: "gross", label: "Gross Pay" },
  ];

  function submit() {
    updateSalarySettings({
      ...formData,
      days_count: dayCount,
      additional_allowances: JSON.stringify(additionalAllowance),
    }).then(() => {
      setShowAdvancedSettings(false);
    });
  }

  return (
    <>
      <div className="flex flex-col bg-white w-full box-shadow h-full min-h-fit rounded -mt-2 pb-2">
        <div className="flex flex-col px-4 my-3 ">
          <span className="header-4">Company Payroll Settings</span>
          <span className="text-[12px] text-color-gray pt-1">
            Update and edit your company payroll settings
          </span>
        </div>
        <hr className="divider mb-2 -mt-1" />
        <div className="flex flex-col md:flex-row">
          <div className="flex w-full max-w-[100%] px-4 md:px-0 flex-col">
            {isLoading ? (
              <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                <LoadingBar loading={isLoading} />
              </div>
            ) : (
              <div className="flex flex-row p-2">
                <div className="flex flex-col md:w-3/5 w-full">
                  <div className={"flex w-full flex-col -mb-[15px]"}>
                    {rateSettings.map((item, index) => (
                      <div className="mb-[10px]">
                        <FormSwitch
                          label={item.title}
                          name={item.name}
                          checked={formData[item.name]}
                          onClick={() =>
                            setFormData({
                              ...formData,
                              [item.name]: !formData[item.name],
                            })
                          }
                          value={formData[item.name]}
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    className={
                      "flex w-full flex-col mb-3 p-3 pb-0 pt-4 border rounded-md mt-2"
                    }
                  >
                    {applicationSettings.map((item, index) => (
                      <div className="flex flex-col gap-2 w-full">
                        <FormSwitch
                          label={item.title}
                          name={item.name}
                          checked={formData[item.name]}
                          onClick={() =>
                            setFormData({
                              ...formData,
                              [item.name]: !formData[item.name],
                            })
                          }
                          value={formData[item.name]}
                        />
                        {item.name === "can_apply_for_overtime" &&
                        formData[item.name] ? (
                          <div className="grid md:grid-cols-2 grid-cols-1 gap-2 p-2 border rounded-md w-full -mt-4 mb-2 bg-gray-50">
                            <FormInput
                              label={"Overtime Hourly Rate (NGN)"}
                              name={"overtime_hourly_rate"}
                              type="number"
                              className={"!bg-white text-[14px]"}
                              value={formData["overtime_hourly_rate"]}
                              onInput={(e) =>
                                setFormData({
                                  ...formData,
                                  overtime_hourly_rate: e.target.value,
                                })
                              }
                            />
                            <FormInput
                              label={"Total Available Overtime Hours"}
                              name={"max_overtime_hours_monthly"}
                              type="number"
                              className={"!bg-white text-[14px]"}
                              value={formData["max_overtime_hours_monthly"]}
                              onInput={(e) =>
                                setFormData({
                                  ...formData,
                                  max_overtime_hours_monthly: e.target.value,
                                })
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                  <div
                    className={classNames(
                      "flex flex-row justify-between items-center cursor-pointer bg-highlight rounded-md px-2 duration-300 hover:font-extrabold",
                      {
                        "pt-3 pb-3 px-2 font-extrabold bg-highlight rounded-md mb-2":
                          showAdvancedSettings,
                      }
                    )}
                    onClick={() =>
                      setShowAdvancedSettings(!showAdvancedSettings)
                    }
                  >
                    <span className="text-[14px]">
                      Advance Payroll Settings
                    </span>
                    <div className="md:pr-4">
                      <CollapsingIcon
                        defaultPosition="left"
                        isOpen={showAdvancedSettings}
                      />
                    </div>
                  </div>
                  {/* Advanced Salary Settings */}
                  <div
                    className={classNames("flex flex-col border p-3 rounded", {
                      hidden: !showAdvancedSettings,
                    })}
                  >
                    <div className="flex justify-end">
                      <span
                        className={classNames(
                          "font-extrabold",
                          {
                            "text-green-700": totalAllowance === 100,
                          },
                          {
                            "text-color-error": totalAllowance !== 100,
                          }
                        )}
                      >
                        Total Gross Allowance ({totalAllowance}%)
                      </span>
                    </div>
                    <Collapse isOpen={showAdvancedSettings}>
                      <div className="mt-2 mb-2 text-color-black text-left text-[13px]">
                        Total Gross Allowance must be equal to 100%
                      </div>
                      <div className="grid md:grid-cols-3 grid-cols-2 gap-2 w-full">
                        {AdvanceSalarySettings.map((item) => (
                          <FormInput
                            label={item.title}
                            name={item.name}
                            type="number"
                            className={"!bg-white text-[14px]"}
                            value={formData[item.name]}
                            onInput={(e) =>
                              setFormData({
                                ...formData,
                                [item.name]: e.target.value,
                              })
                            }
                          />
                        ))}
                      </div>
                      <hr className="divider mt-2 mb-2" />
                      <div className="text-[15px] font-extrabold mb-2">
                        Additional Allowances
                      </div>

                      {addingAllowance ? (
                        <form className="form p-2 border bg-highlight rounded-md">
                          <div className="flex flex-col md:flex-row justify-between gap-3 text-[14px]">
                            <div className="w-full">
                              <FormInput
                                label="Allowance Name"
                                name="allowance_title"
                                value={allowanceTitle}
                                placeholder={"Enter Allowance Name"}
                                onInput={(e) => {
                                  setAllowanceTitle(e.target.value);
                                }}
                                className={"!bg-white text-[14px]"}
                                type="text"
                                error={errors.allowance_title}
                                errorMessage={
                                  errors.allowance_title &&
                                  errors.allowance_title.message
                                }
                              />
                            </div>
                          </div>
                          <div className="flex md:flex-row justify-end mt-[10px] gap-4">
                            <Button
                              text="Cancel"
                              type="button"
                              onClick={() => {
                                setAddingAllowance(!addingAllowance);
                                setAllowanceTitle(null);
                              }}
                              textClass={"!text-[11px]"}
                              className={"h-[35px] w-fit px-2"}
                              theme={"secondary"}
                            />
                            <Button
                              text={"Add Allowance"}
                              type="button"
                              disabled={!allowanceTitle}
                              onClick={() => {
                                addAdditionalAllowance();
                              }}
                              textClass={"!text-[11px]"}
                              className={"h-[35px] w-fit px-2"}
                            />
                          </div>
                        </form>
                      ) : (
                        <>
                          {additionalAllowance.length > 0 ? (
                            <>
                              <div className="grid md:grid-cols-3 grid-cols-2 gap-2 w-full">
                                {additionalAllowance.map((item, index) => (
                                  <div className="flex flex-col">
                                    <FormInput
                                      label={item.title + " (%)"}
                                      name={item.name}
                                      type="number"
                                      onInput={(e) => {
                                        let newArray = JSON.parse(
                                          JSON.stringify(additionalAllowance)
                                        );
                                        newArray[index].rate = e.target.value;
                                        setAdditionalAllowance(newArray);
                                      }}
                                      className={"!bg-white text-[14px]"}
                                      readOnly={loading}
                                      value={item.rate}
                                    />
                                    <div
                                      className="flex text-[12px] text-red-500 -mt-3 hover:underline duration-300 cursor-pointer hover:font-semibold"
                                      onClick={() => {
                                        removeAdditionalAllowance(index);
                                      }}
                                    >
                                      Remove
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div
                                className="flex items-end justify-start gap-2 p-2 border bg-gray-100 w-fit rounded-md hover:bg-secondary-2-extralight cursor-pointer mt-3"
                                onClick={() => {
                                  setAddingAllowance(true);
                                }}
                              >
                                <img src={PlusSVG} alt="" width={"18px"} />
                                <span className="text-[14px] font-semibold">
                                  Another Allowance
                                </span>
                              </div>
                            </>
                          ) : (
                            <div
                              className="flex items-end justify-start gap-2 p-2 border bg-gray-100 w-fit rounded-md hover:bg-secondary-2-extralight cursor-pointer"
                              onClick={() => {
                                setAddingAllowance(true);
                              }}
                            >
                              <img src={PlusSVG} alt="" width={"18px"} />
                              <span className="text-[14px] font-semibold">
                                Another Allowance
                              </span>
                            </div>
                          )}
                        </>
                      )}
                      <hr className="divider mt-2" />
                      <div className="grid md:grid-cols-3 grid-cols-1 gap-2 w-full">
                        {formData.pension ? (
                          <div className="col-span-2">
                            <FormSelect
                              value={formData["pension_calculator"]}
                              options={pensionCalculatorOptions}
                              onChange={(selected) => {
                                setFormData({
                                  ...formData,
                                  pension_calculator: selected,
                                });
                              }}
                              extraClass={"!bg-white text-[14px]"}
                              label="Pension Calculator"
                            />
                          </div>
                        ) : null}
                        <div className="col-span-1">
                          <FormSelect
                            value={dayCount}
                            options={dayCountOptions}
                            onChange={(selected) => {
                              setDayCount(selected);
                            }}
                            label="Day Count"
                            extraClass={"!bg-white text-[14px]"}
                          />
                        </div>
                      </div>

                      {formData.pension || formData.nhis || formData.nhf ? (
                        <>
                          <hr className="divider mt-2" />
                          <div className="mb-2 text-color-black font-semibold text-left">
                            Statutory Rates (%)
                          </div>
                          <div className="grid md:grid-cols-2 grid-cols-1 gap-2 w-full">
                            {statutorySettings.map((item) => (
                              <>
                                {!formData.pension &&
                                (item.name == "employer_pension" ||
                                  item.name ==
                                    "employee_pension") ? null : !formData.nhis &&
                                  (item.name == "employer_nhis" ||
                                    item.name ==
                                      "employee_nhis") ? null : !formData.nhf &&
                                  item.name == "nhf_percentage" ? null : (
                                  <FormInput
                                    label={item.title}
                                    name={item.name}
                                    type="text"
                                    readOnly={
                                      item.name === "employer_pension" ||
                                      item.name === "employee_pension"
                                        ? false
                                        : true
                                    }
                                    onInput={(e) =>
                                      setFormData({
                                        ...formData,
                                        [item.name]: parseFloat(e.target.value),
                                      })
                                    }
                                    className={"!bg-white text-[14px]"}
                                    value={formData[item.name]}
                                  />
                                )}
                              </>
                            ))}
                          </div>
                        </>
                      ) : null}
                    </Collapse>
                  </div>
                  <div className="flex items-center justify-end w-full mt-[20px]">
                    <Button
                      text="UPDATE SALARY SETTINGS"
                      type="submit"
                      loading={loading}
                      onClick={() => submit()}
                      className="flex gap-2 !h-[35px] w-fit px-2 "
                      textClass={"!text-[11px]"}
                    />
                  </div>
                </div>
                <div className="hidden md:flex flex-row md:w-2/5 justify-center">
                  <img
                    src={SettingsSVG}
                    alt={"Refresh"}
                    className={"h-[300px] w-[80%]"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SalarySettings;
