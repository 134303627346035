import { Axios } from '../axios';

const getPurchaseOrders = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get( `/purchase-orders?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
    return data?.data;
};

const getPurchaseOrdersByVendor = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get( `/purchase-orders/by-vendor/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
    return data?.data;
};

const getInvoiceReceivedByPurchaseOrder = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
    const { data } = await Axios.get( `/purchase-orders/invoice-received/${payload.id}?limit=${payload.limit}&page=${payload.page}${filter}${search}`);
    return data?.data;
};

const updatePurchaseOrder = async (payload) => {

    const { data } = await Axios.put( '/purchase-orders/' + payload.id, payload.data, );
    return data;
};

const getPurchaseOrder= async (payload) => {
    const { data } = await Axios.get('/purchase-orders/' + payload);
    return data.data;
};

const createSinglePurchaseOrder = async (payload) => {
    const { data } = await Axios.post('/purchase-orders', payload);
    return data;
};

const markPurchaseOrderPaid = async (payload) => {
    const { data } = await Axios.post( `/purchase-orders/bulk-activation/${payload}`);
    return data;
};

const markPurchaseOrderUnpaid = async (payload) => {
    const { data } = await Axios.post( `/purchase-orders/bulk-activation/${payload}`);
    return data;
};

const getPurchaseOrderPaymentHistory = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
    let start_date = payload.start_date != -1 ? '&start_date=' + payload.start_date : '';
    let end_date = payload.end_date != -1 ? '&end_date=' + payload.end_date : '';

    const { data } = await Axios.get( `/purchase-orders/transactions/history?limit=${payload.limit}&page=${payload.page}${search}${filter}${start_date}${end_date}`,);

    return data?.data;
};

const sendPurchaseOrder = async (payload) => {
    const { data } = await Axios.post(`/purchase-orders/send-purchase-order/${payload}` );
    return data;
};

const purchaseOrderInvoices = async (payload) => {
    const { data } = await Axios.post(`/purchase-orders/invoice-received/${payload}` );
    return data;
};

const deletePurchaseOrder = async (payload) => {
    const { data } = await Axios.delete('purchase-orders/'+payload);
    return data;
};

export const purchase_orders = {
    getPurchaseOrders,
    getPurchaseOrdersByVendor,
    getInvoiceReceivedByPurchaseOrder,
    updatePurchaseOrder,
    getPurchaseOrder,
    createSinglePurchaseOrder,
    markPurchaseOrderPaid,
    markPurchaseOrderUnpaid,
    getPurchaseOrderPaymentHistory,
    sendPurchaseOrder,
    purchaseOrderInvoices,
    deletePurchaseOrder,
};