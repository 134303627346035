import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useDeclineBasicPayroll,
  useDeleteBasicPayroll,
  useGetBasicPayroll,
} from "../../../redux/payroll/hook";
import {
  PAGINATION_DEFAULT,
  basicPayrollStatusConfig,
  basicStatusConfig,
} from "../../../utils/constants";
import DataTable from "../../datatable";
import RunBasicPayroll from "../../modal/payroll/run-basic-payroll";
import Button from "../../button";
import { useSelector } from "react-redux";
import { useGetEmployees } from "../../../redux/employees/hook";
import { useGetEmployeeSalaries } from "../../../redux/employees/hook/salaries";
import LoadingBar from "../../loader-bar";
import DoYouWantToModal from "../../modal/do-you-want-to";
import InfoModal from "../../modal/info-modal";
import HelpSVG from "../../../assets/svg/help.svg";

export const BasicPayrollTab = () => {
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const { data: basicPayroll } = useGetBasicPayroll(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { user } = useSelector((state) => state.userSlice);

  const { data: employees } = useGetEmployees(1, 1, 1, "");

  const { data: employeeSalaries, isLoading: salariesLoading } =
    useGetEmployeeSalaries(1, 1, 1, "");

  const history = useHistory();
  const { mutateAsync: deletePayroll } = useDeleteBasicPayroll();

  const [deleteId, setDeleteId] = useState(null);

  const deleteAdvancedPayroll = async (ref) => {
    let index = basicPayroll.data.findIndex((item) => item.ref === ref);
    await deletePayroll(basicPayroll.data[index].ref).then(() => {
      setShowDoYouWant(false);
    });
  };

  const [showDoYouWant, setShowDoYouWant] = useState(false);

  const [showInfoModal, setShowInfoModal] = useState(false);

  const { mutateAsync: declinePayment, isLoading: declineLoading } =
    useDeclineBasicPayroll();
  const [showBasicPayroll, setShowBasicPayroll] = useState(false);

  function Continue(id, type) {
    let index = basicPayroll.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      if (type === "preview") {
        history.push(`/payroll/basic/preview/${basicPayroll.data[index].ref}`);
      } else if (type === "view") {
        history.push(`/payroll/basic/view/${basicPayroll.data[index].ref}`);
      } else if (type === "approve") {
        history.push(
          `/payroll/basic/preview/${basicPayroll.data[index].ref}?approve=true`
        );
      } else if (type === "decline") {
        declinePayment(basicPayroll.data[index].ref);
      } else if (type === "delete") {
        setDeleteId(basicPayroll.data[index].ref);
        setShowDoYouWant(true);
      }
    }
  }

  const tableConfig = {
    // title: 'Basic Payroll Summary',
    headers: [
      "Date Created",
      "Description",
      "Ref. Number",
      "Month",
      "Total Amount",
      "Status",
    ],
    keys: [
      "created",
      "desc",
      "ref",
      "month",
      "formatted_total_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Description",
        key: "desc",
      },
    },
  };

  const actionConfig = [
    {
      name: "Continue",
      color: "green",
      action: (id) => {
        Continue(id, "preview");
      },
    },
    {
      name: "View",
      color: "green",
      action: (id) => {
        Continue(id, "view");
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        Continue(id, "delete");
      },
    },
    {
      name: "Approve",
      color: "orange",
      action: (id) => {
        Continue(id, "approve");
      },
    },
    {
      name: "Decline",
      color: "red",
      action: (id) => {
        Continue(id, "decline");
      },
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-end w-full -mt-5">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={HelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      {showInfoModal ? (
        <InfoModal
          isOpen={showInfoModal}
          type={"employee-payments"}
          closeModal={() => setShowInfoModal(false)}
        />
      ) : null}
      {showDoYouWant ? (
        <DoYouWantToModal
          isOpen={showDoYouWant}
          closeModal={() => setShowDoYouWant(false)}
          id={deleteId}
          title={"Are you sure you want to delete this payroll?"}
          subtitle={
            "All information on this payroll will be removed from the portal. No payment refund will be made."
          }
          buttonText={"DELETE PAYROLL"}
          btnFunction={deleteAdvancedPayroll}
        />
      ) : null}
      {showBasicPayroll ? (
        <RunBasicPayroll
          isOpen={showBasicPayroll}
          source="default"
          closeModal={() => setShowBasicPayroll(false)}
        />
      ) : null}
      <div className="flex flex-col bg-white rounded -mt-6 border">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
            Employee Payment Summary
          </div>
          {salariesLoading || declineLoading ? (
            <LoadingBar loading={salariesLoading} />
          ) : employeeSalaries?.data.length > 0 ? (
            <>
              {user?.permissions.payroll_permission?.basic_payroll_write ===
                1 || user?.permissions.company_permission.role === "Owner" ? (
                <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
                  <div className="md:w-fit w-[100%]" id="second-step">
                    <Button
                      text="Make Employee Payment"
                      type="button"
                      theme="primary"
                      className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                      textClass={"!text-[11px]"}
                      leftIcon={"purchase"}
                      onClick={() => {
                        setShowBasicPayroll(true);
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
              <div className="md:w-fit w-[100%]">
                <Button
                  text={
                    employees?.data?.length > 0 ? "ADD SALARY" : "ADD EMPLOYEE"
                  }
                  type="button"
                  className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  textClass={"!text-[11px]"}
                  leftIcon={"add"}
                  onClick={() => {
                    if (employees?.data?.length > 0) {
                      history.push(
                        "/employees/onboarding/add-salary-information?type=single&salary=true"
                      );
                    } else {
                      history.push("/employees/onboarding/select-option");
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={basicPayroll}
          tableConfig={tableConfig}
          noCheck={true}
          statusConfig={basicPayrollStatusConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          filterByStatus={basicStatusConfig}
          searchData={true}
          computedActions={true}
        />
      </div>
    </div>
  );
};
