import React, { useEffect } from "react";
import DataTable from "../../../components/datatable";
import { useState } from "react";
import {
  payrollHistoryStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../utils/constants";
import {
  useCheckBulkTransactionStatus,
  useCheckTransactionStatus,
  useGetPayrollHistory,
  useRetryTransaction,
} from "../../../redux/payroll/hook/index";
import Button from "../../button";
import { RetryToAnotherBank } from "../../modal/wallet/retry-to-another-bank";

const PayrollHistoryTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
    start_date: "",
    end_date: "",
  });

  const { data: payrollHistory } = useGetPayrollHistory(
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search,
    pagination?.start_date,
    pagination?.end_date
  );

  const { mutateAsync: checkTransactionStatus } = useCheckTransactionStatus();

  const { mutateAsync: refreshTransactionStatus, isLoading: refreshLoading } =
    useCheckBulkTransactionStatus();

  const { mutateAsync: retryTransaction } = useRetryTransaction();

  const [showRetryToAnotherBank, setShowRetryToAnotherBank] = useState(false);
  const [transactionId, setTransactionId] = useState();

  const tableConfig = {
    headers: [
      "Date Created",
      "Name",
      "Description",
      "Status",
      "Fee",
      "Amount",
      "Source",
    ],
    keys: [
      "created",
      "name",
      "info",
      "source",
      "fees",
      "formatted_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Date Created",
        key: "created",
      },
      right: {
        title: "Amount",
        key: "amount",
      },
    },
  };

  const dateConfig = {
    startDate: "0000-00-00",
    endDate: "0000-00-00",
  };

  const actionConfig = [
    {
      name: "Check Status",
      color: "green",
      action: async (id) => {
        let index = payrollHistory.data.findIndex((item) => item.id === id);
        await checkTransactionStatus(payrollHistory.data[index].id);
      },
    },
    {
      name: "Retry",
      color: "red",
      action: (id) => {
        let index = payrollHistory.data.findIndex((item) => item.id === id);
        retryTransaction(payrollHistory.data[index].id);
      },
    },
    {
      name: "Retry To Another Bank",
      color: "red",
      action: (id) => {
        let index = payrollHistory.data.findIndex((item) => item.id === id);
        setTransactionId(payrollHistory.data[index].id);
        setShowRetryToAnotherBank(true);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded border">
      {showRetryToAnotherBank ? (
        <RetryToAnotherBank
          isOpen={showRetryToAnotherBank}
          transaction_id={transactionId}
          closeModal={() => setShowRetryToAnotherBank(false)}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Payroll Transaction History
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]" id="second-step">
            <Button
              text="Refresh Transactions"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"refresh"}
              loading={refreshLoading}
              onClick={() => {
                refreshTransactionStatus();
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={payrollHistory}
        refresh={false}
        noCheck={true}
        tableConfig={tableConfig}
        filterByDate={dateConfig}
        filterByStatus={payrollHistoryStatusConfig}
        statusConfig={payrollHistoryStatusConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        searchData={true}
        computedActions={true}
      />
    </div>
  );
};

export default PayrollHistoryTab;
