import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { employeeSalaries } from './../../../api/employees/salaries';
import moment from 'moment';

export function useGetSalaryReviews(limit, page, start_date, end_date, search) {
  // const formatter = new Intl.NumberFormat('en-NG');
  return useQuery(
    ['salary-reviews', { limit, page, start_date, end_date, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeSalaries.getSalaryReviews({
        limit,
        page,
        start_date, 
        end_date,
        search,
      });
    },
    {
      select: (data) => {
        
        data?.data?.forEach((review) => {
            review.employeeName = `${review.first_name} ${review.last_name}`;
            review.created = moment(review.created).format('MMMM DD, YYYY HH:mm:ss');
            review.review_start_date = moment(review.review_start_date).format('MMMM DD, YYYY');
            review.computedActions = [0,1]
        });
        return data;
      },
      onSuccess: (response, variables, context) => {
        store.dispatch(setAlert(true, 'success', 'Salary Reviews fetched successfully.'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetSalaryReview(id) {
  return useQuery(
      ['team', {id} ],
      () => {
          return employeeSalaries.getSalaryReview(id);
      },
      {
        enabled: id !=null,
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
      },
  );
}

export function useAddSalaryReview() {
  return useMutation(
    (payload) => {
      return employeeSalaries.addSalaryReview(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('salary-reviews');
        queryClient.invalidateQueries('employee-salary-review');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useAddBulkSalaryReview() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return employeeSalaries.addBulkSalaryReview(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('salary-reviews');
          queryClient.invalidateQueries('employee-salary-review');
          store.dispatch(setAlert(true, 'success', response.message));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useDeleteSalaryReview() {
    return useMutation(
        (payload) => {
            return employeeSalaries.deleteSalaryReview(payload);
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries('salary-reviews');
            queryClient.invalidateQueries('employee-salary-review');
            store.dispatch(setAlert(true, 'success', 'Salary review deleted successfully'));
          },
          onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
          },
        },
    );
}

export function useBulkDeleteSalaryReview() {
    return useMutation(
        (payload) => {
            return employeeSalaries.bulkDeleteSalaryReview(payload);
        },
        {
            onSuccess: () => {
            queryClient.invalidateQueries('salary-reviews');
            queryClient.invalidateQueries('employee-salary-review');
            store.dispatch(setAlert(true, 'success', 'Added successfully'));
            },
        },
    );
}

export function useGetSalaryReviewsByEmployee(id, limit, page, start_date, end_date) {
  return useQuery(
    ['employee-salary-reviews', { id, limit, page, start_date, end_date }],
    () => {
      return employeeSalaries.getSalaryReviewByEmployee({id, limit, page, start_date, end_date});
    },
    {
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((review) => {
            review.employeeName = `${review.first_name} ${review.last_name}`;
            review.created = moment(review.created).format('MMMM DD, YYYY HH:mm:ss');
            review.review_start_date = moment(review.review_start_date).format('MMMM DD, YYYY');
            review.computedActions = [0,1]
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}
