import { Axios } from '../axios';

const getTrainingSummary = async () => {
  const { data } = await Axios.get('/trainings/summary');
  return data?.data;
};

const getEmployeeTrainings = async (payload) => {
    let search = payload.search ? '&search=' + payload.search : '';
    let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';
  
    const { data } = await Axios.get(
      `/trainings?limit=${payload.limit}&page=${payload.page}${filter}${search}`,
    );
  
    return data?.data;
};

const getEmployeeTrainingEmployees = async (payload) => {
  let search = payload.search ? '&search=' + payload.search : '';
  let filter = payload.statusFilter && payload.statusFilter != -1 ? '&filter=' + payload.statusFilter : '';

  const { data } = await Axios.get(
    `/trainings/employees/${payload.id}?limit=${payload.limit}&page=${payload.page}${search}`,
  );

  return data?.data;
};

const getEmployeeTraining = async (payload) => {
    const { data } = await Axios.get('/trainings/' + payload);
    return data?.data;
};

const addEmployeeTraining = async (payload) => {
    const { data } = await Axios.post('/trainings', payload,
    {
      timeout: 0
    });
    return data;
};

const updateEmployeeTraining = async (payload) => {
    const { data } = await Axios.put(
      '/trainings/' + payload.id,
      payload.data,
    );
    return data;
};

const markTrainingComplete = async (payload) => {
    const { data } = await Axios.post(
      '/trainers/change-status/'+ payload.id, payload.data,
    );
    return data;
};

const deleteTraining = async (payload) => {
    const { data } = await Axios.delete(
      '/trainings/' + payload
    );
    return data;
};

const removeEmployeesFromTraining = async (payload) => {
  const { data } = await Axios.delete(
    '/trainings/remove-employees/'+payload
  );
  return data;
};

const addEmployeesToTraining = async (payload) => {
  const { data } = await Axios.post('/training/add-multiple-employees', payload);
  return data;
};

export const employeeTrainings = {
    getEmployeeTrainings,
    getEmployeeTrainingEmployees,
    getEmployeeTraining,
    addEmployeeTraining,
    updateEmployeeTraining,
    markTrainingComplete,
    deleteTraining,
    getTrainingSummary,
    removeEmployeesFromTraining,
    addEmployeesToTraining
  };