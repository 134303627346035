import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { useEffect } from 'react';

export function useCheckOnboarding() {

  const { user, twoFaToken } = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { section } = useSelector((state) => state.sectionSlice);
  const location = useLocation();
  const history = useHistory();

  if (twoFaToken) {
    if (location.pathname.split("/")[1] !== 'verify-2fa') {
      return history.push(`/verify-2fa/${twoFaToken?.token}`);
    }
  } else if (user) {
    if (user.onboardingStatus === 0 || !user.onboardingStatus) {
      if (location.pathname != '/onboarding/about-company') {
        history.push('/onboarding/about-company');
      }
      return;
      
    } else if (user.onboardingStatus === 1) {
      if (location.pathname != '/onboarding/rate-settings') {
        history.push('/onboarding/rate-settings');
      }
      return;
    } else if (
      user &&
      user.onboardingStatus === 2 &&
      location.pathname === '/onboarding/rate-settings'
    ) {
      history.push('/');
      return;
    } else {
      if (company && section) {
        return history.push(section.entryRoute);
      } else {
        return;
      }
    }
  } else {
    if (location.pathname.split("/")[1] === 'verify-2fa') {
      return history.push('/');
    }
  }
}
