import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { store } from '../..';
import { queryClient } from '../../..';
import { departments } from '../../../api/hr/department-management';
import { setAlert, setIsLoading } from '../../components/components-slice';


export function useGetDepartmentSummary() {
  return useQuery(['department-summary'], () => {
    return departments.getDepartmentSummary();
  });
} 

export function useGetDepartments(limit, page, search) {
    return useQuery(
      ['departments', { limit, page, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return departments.getDepartments({
          limit,
          page,
          search,
        });
      },
      {
        select: (data) => {
          data?.data?.data?.forEach((department) => {
            department.created = moment(department.created).format('MMMM D, YYYY');
            department.computedActions = [0,1,2,3, 4]
            department.department_manager = department.manager_setting?.manager ? department.manager_setting?.manager: ''
            if (department && department.description && department.description.length >= 30) {
              department.description = department.description.substring(0, 30) + '....';
            }
          });
  
          return data?.data;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetOnboardingDepartments(limit, page, statusFilter, search) {
  return useQuery(
    ['onboarding-departments', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return departments.getDepartments({
        limit,
        page,
        statusFilter,
        search,
      });
    },
    {
      select: (data) => {
        let newData = [];
        newData.push({ value: '', label: 'No Department Selected' });
        data?.data?.forEach((item) => {
          newData.push({ value: item.id, label: item.name });
        });
        return newData;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
    
  );
}

export function useAddDepartment() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return departments.addDepartment(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('departments');
          store.dispatch(setAlert(true, 'success', response.message));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useEditDepartment() {
    return useMutation(
      (payload) => {
        store.dispatch(setIsLoading(true));
        return departments.updateDepartment(payload);
      },
      {
        onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('departments');
          queryClient.invalidateQueries('department-settings');
          store.dispatch(setAlert(true, 'success', response.message));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useGetDepartment(id) {
    return useQuery(
        ['department', {id}],
        () => {
            return departments.getDepartment({id});
        },
        {
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useDeleteDepartment() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return departments.deleteDepartment(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('departments');
        queryClient.invalidateQueries('department-settings');
        store.dispatch(setAlert(true, 'success', response.message));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useGetDepartmentEmployees(id, limit, page, statusFilter, search) {
    return useQuery(
      ['department-employees', { id, limit, page, statusFilter, search }],
      () => {
        store.dispatch(setIsLoading(true));
        return departments.getDepartmentEmployees({
          id,
          limit,
          page,
          statusFilter,
          search,
        });
      },
      {
        
        enabled: id !== null,
        select: (data) => {
          if (data?.employees) {
            data.employees.name = data?.name;
            data.employees.id = data?.id;
          }
          if (data?.employees?.data) {
            data.employees.data.forEach((employee) => {
              employee.created = moment(employee.created).format('MMMM DD, YYYY')
              employee.computedActions = [0];
              employee.employeeName = `${employee.employee_first_name} ${employee.employee_last_name}`;
            });
          }
          return data?.employees;
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        
      },
    );
}

export function useAddEmployeesToDepartment() {
    return useMutation(
      (payload) => {
        return departments.addEmployeesToDepartment(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('departments');
          queryClient.invalidateQueries('department');
          queryClient.invalidateQueries('department-employees');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useRemoveEmployeesFromDepartment() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return departments.removeEmployeesFromDepartment(payload);
        },
        {
            onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('department-employees');
            store.dispatch(setAlert(true, 'success', response.message));
            },
            onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useMoveEmployeesToDepartment() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return departments.moveEmployeesToDepartment(payload);
        },
        {
            onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('department-employees');
            store.dispatch(setAlert(true, 'success', response.message));
            },
            onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useGetDepartmentTeams(id, limit, page, search) {
  return useQuery(
    ['department-teams', { id, limit, page, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return departments.getDepartmentTeams({
        id,
        limit,
        page,
        search,
      });
    },
    {
      
      enabled: id !== null,
      select: (data) => {
        data?.data?.forEach((department_teams) => {
          department_teams.created = department_teams.created.split('T')[0]
          department_teams.computedActions = [0, 1];
        });
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      
    },
  );
}

export function useAddTeamsToDepartment() {
    return useMutation(
      (payload) => {
        return departments.addTeamsToDepartment(payload);
      },
      {
        onSuccess: (response, variables, context) => {
          queryClient.invalidateQueries('department-teams');
          queryClient.invalidateQueries('departments');
          queryClient.invalidateQueries('departments');
          store.dispatch(setAlert(true, 'success', response.message));
        },
      },
    );
}

export function useRemoveTeamsFromDepartment() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return departments.removeTeamsFromDepartment(payload);
        },
        {
            onSuccess: (response, variables, context) => {
            queryClient.invalidateQueries('department-teams');
            store.dispatch(setAlert(true, 'success', response.message));
            },
            onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useMoveTeamToDepartment() {
    return useMutation(
        (payload) => {
            store.dispatch(setIsLoading(true));
            return departments.moveTeamsToDepartment(payload);
        },
        {
            onSuccess: (response, variables, context) => {
              queryClient.invalidateQueries('department-teams');
              store.dispatch(setAlert(true, 'success', response.message));
            },
            onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useGetDepartmentSettings(id) {
    return useQuery(
      ['department-settings',  id ],
      () => {
        store.dispatch(setIsLoading(true));
        return departments.getDepartmentSettings(id);
      },
      {
        enabled: id !== null,
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        
      },
    );
}