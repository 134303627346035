import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../../components/datatable';
import { PAGINATION_DEFAULT, leaveStatusConfig } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { useGetCabinetDocuments, useGetCabinetLeaves } from '../../redux/employee-folder/hook';

const DocumentFolder = ({employeeId}) => {

    const { user } = useSelector((state) => state.userSlice);

    const [pagination, setPagination] = useState({
        limit: PAGINATION_DEFAULT.limit,
        page: PAGINATION_DEFAULT.page,
        statusFilter: PAGINATION_DEFAULT.statusFilter,
        search: '',
    });

    const { data: leaves } = useGetCabinetDocuments(
        employeeId,
        pagination?.limit,
        pagination?.page,
        pagination?.statusFilter,
        pagination?.search,
    );

    const tableConfig = {
        headers: ['Date Uploaded', 'Employee Name', 'File Name' ],
        keys: ['created', 'employeeName', 'name' ],
        mobileHeader: {
            left: {
                title: 'Date Uploaded',
                key: 'created',
            },
            right: {
                title: 'File Name',
                key: 'name',
            },
        },
    };

    return (
        <div className='flex flex-col'>
            <div className='mt-9'>
                <div className='flex md:flex-row gap-4 flex-wrap-reverse'>
                    <div className='w-[100%]'>
                        <DataTable
                        data={leaves}
                        tableConfig={tableConfig}
                        statusConfig={leaveStatusConfig}
                        noCheck={true}
                        updatePagination={(data) => setPagination(data)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentFolder;