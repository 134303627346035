import React from "react";
import DataTable from "../../../datatable";
import { useState, useEffect } from "react";
import {
  invoiceReceivedStatus,
  PAGINATION_DEFAULT,
} from "../../../../utils/constants";
import Button from "../../../button";
import {
  useDeleteInvoiceReceived,
  useGetInvoicesReceived,
} from "../../../../redux/spend-management/invoice-received/hook";
import AddInvoiceReceived from "../../../modal/spend-management/vendor/add-invoice-received";
import { useParams } from "react-router-dom";

export const VendorInvoicesReceivedTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });
  const { id } = useParams();

  const { data: invoices_received } = useGetInvoicesReceived(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const { mutateAsync: deleteInvoiceReceived } = useDeleteInvoiceReceived();

  const [showAddInvoiceReceived, setShowAddInvoiceReceived] = useState(false);
  const [invoiceReceivedData, setInvoiceReceivedData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const tableConfig = {
    // title: 'Bonus Management',
    headers: [
      "Invoice Date",
      "Vendor Name",
      "Mobile",
      "Due Date",
      "Invoice #",
      "Total Amount",
      "Status",
      "Due Status",
    ],
    keys: [
      "invoice_date",
      "vendor_name",
      "vendor_mobile",
      "invoice_due_date",
      "invoice_number",
      "total_amount",
      "status",
      "due_status",
    ],
    mobileHeader: {
      left: {
        title: "Vendor Name",
        key: "vendor_name",
      },
      right: {
        title: "Total Amount",
        key: "total_amount",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "orange",
      action: (id) => {
        let index = invoices_received.data.findIndex((item) => item.id === id);
        setInvoiceReceivedData(invoices_received.data[index]);
        setButtonText("UPDATE");
        setTitle("Update Invoice Received");
        setShowAddInvoiceReceived(true);
      },
    },
    {
      name: "Download",
      color: "green",
      action: (id) => {
        let index = invoices_received.data.findIndex((item) => item.id === id);
        window.open(invoices_received.data[index].invoice_url, "_blank");
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = invoices_received.data.findIndex((item) => item.id === id);
        deleteInvoiceReceived(invoices_received.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddInvoiceReceived ? (
        <AddInvoiceReceived
          isOpen={showAddInvoiceReceived}
          closeModal={() => setShowAddInvoiceReceived(false)}
          invoiceReceivedData={invoiceReceivedData}
          title={title}
          buttonText={buttonText}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Invoices Received
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Add Invoice Received"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setInvoiceReceivedData(null);
                setButtonText("SAVE");
                setTitle("Add Invoice Received");
                setShowAddInvoiceReceived(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-2" />
      <DataTable
        data={invoices_received}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        statusConfig={invoiceReceivedStatus}
        updatePagination={(data) => setPagination(data)}
        noCheck={true}
        computedActions={true}
      />
    </div>
  );
};
