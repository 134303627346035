import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import CollapsingIcon from '../../collapsing-icon';
import FormSwitch from '../../form-switch';
import { useAddCompanyAdmin, useUpdateCompanyAdmin, } from '../../../redux/company/hook';
import Modal from '../modal';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/components/components-slice';

function AddAdminPermissions({
  title,
  isOpen,
  closeModal,
  source = 'add',
  sourceData,
  payloadData,
  onCompleted,
}) {

  const [showMainPermissions, setShowMainPermissions] = useState([
    {
      title: 'Non-module Permissions',
      section: 'non-section',
      has_read_write: false,
      show: false,
      readValue: true,
      writeValue: true,
    },
    {
      title: 'Payroll Module Permissions',
      section: 'payroll',
      has_read_write: true,
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['section_payroll_read', 'section_payroll_write'],
    },
    {
      title: 'People Module Permissions',
      section: 'people',
      has_read_write: true,
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['section_hr_read', 'section_hr_write'],
    },
    {
      title: 'Spend management Permissions',
      section: 'spend',
      has_read_write: true,
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['spendmanagement_read', 'spendmanagement_write'],
    },
    
  ]);

  const [showSubMainPermissions, setShowSubMainPermissions] = useState([
    {
      title: 'Employee Page Permissions',
      section: 'non-section',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['employee_read', 'employee_write'],
      tabs: [
        {
          show: false,
          title: 'Employee Overview',
          readValue: true,
          writeValue: false,
          keys: ['employee_overview_read', 'employee_overview_write'],
        },
        {
          show: false,
          title: 'Salaries',
          readValue: false,
          writeValue: false,
          keys: ['salary_read', 'salary_write'],
        },
        {
          show: false,
          title: 'Overtime',
          readValue: false,
          writeValue: false,
          keys: ['overtime_read', 'overtime_write'],
        },
        {
          show: false,
          title: 'Other Dedcutions',
          readValue: false,
          writeValue: false,
          keys: ['other_deductions_read', 'other_deductions_write'],
        },
        {
          show: false,
          title: 'Payroll Groups',
          readValue: true,
          writeValue: false,
          keys: ['employee_group_read', 'employee_group_write'],
        },
        
        {
          show: false,
          title: 'Bonuses',
          readValue: true,
          writeValue: false,
          keys: ['bonus_read', 'bonus_write'],
        },
        {
          show: false,
          title: 'Salary Advance/Loans',
          readValue: true,
          writeValue: false,
          keys: ['advance_read', 'advance_write'],
        },
        {
          show: false,
          title: 'Expenses Claim',
          readValue: true,
          writeValue: false,
          keys: ['reimbursement_read', 'reimbursement_write'],
        },
        {
          show: false,
          title: 'Suspension',
          readValue: true,
          writeValue: false,
          keys: ['suspension_read', 'suspension_write'],
        },
        {
          show: false,
          title: 'Guarantor Tab',
          readValue: true,
          writeValue: false,
          keys: ['guarantor_read', 'guarantor_write'],
        },
        {
          show: false,
          title: 'Queries',
          readValue: true,
          writeValue: false,
          keys: ['query_read', 'query_write'],
        },
        {
          show: false,
          title: 'Documents',
          readValue: true,
          writeValue: false,
          keys: ['employee_doc_read', 'employee_doc_write'],
        },
      ],
    },
    {
      title: 'HMO Page Permissions',
      section: 'non-section',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['hmo_read', 'hmo_write'],
      tabs: [
        {
          show: false,
          title: 'HMO Beneficiaries',
          readValue: true,
          writeValue: false,
          keys: ['beneficiary_read', 'beneficiary_write'],
        },
        {
          show: false,
          title: 'HMO Groups',
          readValue: true,
          writeValue: false,
          keys: ['hmo_group_read', 'hmo_group_write'],
        },
        {
          show: false,
          title: 'HMO Settings',
          readValue: true,
          writeValue: false,
          keys: ['hmo_settings_read', 'hmo_settings_write'],
        },
        {
          show: false,
          title: 'HMO Payment History',
          readValue: true,
          writeValue: false,
          keys: ['hmo_payments_read', 'hmo_payments_write'],
        },
      ],
    },
    {
      title: 'Document Page Permissions',
      section: 'non-section',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['doc_read', 'doc_write'],
      tabs: []
    },
    {
      title: 'Airtime Page Permissions',
      section: 'non-section',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['airtime_read', 'airtime_write'],
      tabs: [
        {
          show: false,
          title: 'Airtime Overview Tab',
          readValue: true,
          writeValue: false,
          keys: ['airtime_overview_read', 'airtime_overview_write'],
        },
        {
          show: false,
          title: 'Airtime History Tab',
          readValue: true,
          writeValue: false,
          keys: ['airtime_history_read', 'airtime_history_write'],
        },
      ],
    },
    {
      title: 'Report Page Permissions',
      section: 'non-section',
      show: false,
      readValue: true,
      writeValue: true,
      keys: ['report_read', 'report_write'],
      tabs: []
    },
    {
      title: 'Settings Page Permissions',
      section: 'non-section',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['setting_read', 'setting_write'],
      tabs: []
    },
    {
      title: 'Wallet Page Permissions',
      section: 'non-section',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['wallet_read', 'wallet_write'],
      tabs: []
    },
    {
      title: 'Payroll Page Permissions',
      section: 'payroll',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['payroll_read', 'payroll_write'],
      tabs: [
        {
          show: false,
          title: 'Advanced Payroll Tab',
          readValue: true,
          writeValue: false,
          keys: ['advanced_payroll_read', 'advanced_payroll_write'],
        },
        {
          show: false,
          title: 'Basic Payroll Tab',
          readValue: true,
          writeValue: false,
          keys: ['basic_payroll_read', 'basic_payroll_write'],
        },
        {
          show: false,
          title: 'Payroll History Tab',
          readValue: true,
          writeValue: false,
          keys: ['payroll_history_read', 'payroll_history_write'],
        },
      ],
    },
    {
      title: 'Statutory Compliance Page Permissions',
      section: 'payroll',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['statutory_read', 'statutory_write'],
      tabs: [
        {
          show: false,
          title: 'Pensions',
          readValue: false,
          writeValue: false,
          keys: ['pension_read', 'pension_write'],
        },
        {
          show: false,
          title: 'Taxes',
          readValue: false,
          writeValue: false,
          keys: ['taxes_read', 'taxes_write'],
        },
        {
          show: false,
          title: 'NHF',
          readValue: false,
          writeValue: false,
          keys: ['nhf_read', 'nhf_write'],
        },
        {
          show: false,
          title: 'NHIS',
          readValue: false,
          writeValue: false,
          keys: ['nhis_read', 'nhis_write'],
        },
        {
          show: false,
          title: 'NSITF',
          readValue: false,
          writeValue: false,
          keys: ['nsitf_read', 'nsitf_write'],
        },
        {
          show: false,
          title: 'ITF',
          readValue: false,
          writeValue: false,
          keys: ['itf_read', 'itf_write'],
        },
      ],
    },
    {
      title: 'Recruitment Page Permissions',
      section: 'people',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['recruitment_read', 'recruitment_write'],
      tabs: [
        {
          show: false,
          title: 'Recruitment Overview Tab',
          readValue: true,
          writeValue: false,
          keys: ['recruitment_overview_read', 'recruitment_overview_write'],
        },
        {
          show: false,
          title: 'Recruitment Workflow/Stages Tab',
          readValue: true,
          writeValue: false,
          keys: ['recruitment_workflow_read', 'recruitment_workflow_write'],
        },
        {
          show: false,
          title: 'Recruitment Email Tab',
          readValue: true,
          writeValue: false,
          keys: ['recruitment_email_read', 'recruitment_email_write'],
        },
      ],
    },
    {
      title: 'Employee Onboarding Page Permissions',
      section: 'people',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['employee_onboarding_read', 'employee_onboarding_write'],
      tabs: [
        {
          show: false,
          title: 'Employee Onboarding Overview Tab',
          readValue: true,
          writeValue: false,
          keys: ['employee_onboarding_overview_read', 'employee_onboarding_overview_write'],
        },
        {
          show: false,
          title: 'Onboarding Stages Tab',
          readValue: true,
          writeValue: false,
          keys: ['onboarding_workflow_read', 'onboarding_workflow_write'],
        },
      ],
    },
    {
      title: 'Team Page Permissions',
      section: 'people',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['team_read', 'team_write'],
      tabs: [
        {
          show: false,
          title: 'Team Overview',
          readValue: true,
          writeValue: false,
          keys: ['team_overview_read', 'team_overview_write'],
        },
      ],
    },
    {
      title: 'Department Page Permissions',
      section: 'people',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['department_read', 'department_write'],
      tabs: [
        {
          show: false,
          title: 'Department Overview',
          readValue: true,
          writeValue: false,
          keys: ['department_overview_read', 'department_overview_write'],
        },
      ],
    },
    {
      title: 'Branch Page Permissions',
      section: 'people',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['branch_read', 'branch_write'],
      tabs: [
        {
          show: false,
          title: 'Branch Overview',
          readValue: true,
          writeValue: false,
          keys: ['branch_overview_read', 'branch_overview_write'],
        },
        {
          show: false,
          title: 'Branch Payroll Tab',
          readValue: true,
          writeValue: false,
          keys: ['branch_payroll_read', 'branch_payroll_write'],
        },
        {
          show: false,
          title: 'Branch Transactions Tab',
          readValue: true,
          writeValue: false,
          keys: ['branch_transactions_read', 'branch_transactions_write'],
        },
      ],
    },
    {
      title: 'Attendance Page Permissions',
      section: 'people',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['attendance_read', 'attendance_write'],
      tabs: [
        {
          show: false,
          title: 'Attendance Overview',
          readValue: true,
          writeValue: false,
          keys: ['attendance_overview_read', 'attendance_overview_write'],
        },
        {
          show: false,
          title: 'Attendance Schedule',
          readValue: true,
          writeValue: false,
          keys: ['attendance_schedule_read', 'attendance_schedule_write'],
        },
        {
          show: false,
          title: 'Attendance Settings',
          readValue: true,
          writeValue: false,
          keys: ['attendance_settings_read', 'attendance_settings_write'],
        },
      ],
    },
    {
      title: 'Leave Page Permissions',
      section: 'people',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['leave_read', 'leave_write'],
      tabs: [
        {
          show: false,
          title: 'Leave Overview Tab',
          readValue: true,
          writeValue: false,
          keys: ['leave_overview_read', 'leave_overview_write'],
        },
        {
          show: false,
          title: 'Leave Types Tab',
          readValue: true,
          writeValue: false,
          keys: ['leave_type_read', 'leave_type_write'],
        },
        {
          show: false,
          title: 'Leave Settings Tab',
          readValue: true,
          writeValue: false,
          keys: ['leave_settings_read', 'leave_settings_write'],
        },
      ],
    },
    {
      title: 'Promotion Page Permissions',
      section: 'people',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['promotion_read', 'promotion_write'],
      tabs: [
        {
          show: false,
          title: 'Promotion Overview',
          readValue: true,
          writeValue: false,
          keys: ['promotion_overview_read', 'promotion_overview_write'],
        },
      ],
    },
    {
      title: 'Training Page Permissions',
      section: 'people',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['training_read', 'training_write'],
      tabs: [
        {
          show: false,
          title: 'Training Overview Tab',
          readValue: true,
          writeValue: false,
          keys: ['training_overview_read', 'training_overview_write'],
        },
        {
          show: false,
          title: 'Trainers Tab',
          readValue: true,
          writeValue: false,
          keys: ['trainer_read', 'trainer_write'],
        },
      ],
    },
    {
      title: 'Performance Page Permissions',
      section: 'people',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['performance_read', 'performance_write'],
      tabs: [
        {
          show: false,
          title: 'Performance Overview',
          readValue: true,
          writeValue: false,
          keys: ['performance_overview_read', 'performance_overview_write'],
        },
        {
          show: false,
          title: 'Performance Workflow',
          readValue: true,
          writeValue: false,
          keys: ['performance_workflow_read', 'performance_workflow_write'],
        },
        {
          show: false,
          title: 'Performance OKR',
          readValue: true,
          writeValue: false,
          keys: ['okr_read', 'okr_write'],
        },
        {
          show: false,
          title: 'Performance Indicators',
          readValue: true,
          writeValue: false,
          keys: ['indicators_read', 'indicators_write'],
        },
        {
          show: false,
          title: 'Performance Smart Goals',
          readValue: true,
          writeValue: false,
          keys: ['smart_goals_read', 'smart_goals_write'],
        },
        {
          show: false,
          title: 'Performance Technical Appraisal',
          readValue: true,
          writeValue: false,
          keys: ['technical_read', 'technical_write'],
        },
      ],
    },
    {
      title: 'Exit Page Permissions',
      section: 'people',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['exit_read', 'exit_write'],
      tabs: [
        {
          show: false,
          title: 'Resignation Tab',
          readValue: true,
          writeValue: false,
          keys: ['resignation_read', 'resignation_write'],
        },
        {
          show: false,
          title: 'Termination Tab',
          readValue: true,
          writeValue: false,
          keys: ['termination_read', 'termination_write'],
        },
        {
          show: false,
          title: 'Exit Workflow Tab',
          readValue: true,
          writeValue: false,
          keys: ['exit_workflow_read', 'exit_workflow_write'],
        },
        {
          show: false,
          title: 'Exit Questions Tab',
          readValue: true,
          writeValue: false,
          keys: ['exit_question_read', 'exit_question_write'],
        },
      ],
    },
    {
      title: 'Customer Page Permissions',
      section: 'spend',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['customer_read', 'customer_write'],
      tabs: [
        {
          show: false,
          title: 'Customer List Tab',
          readValue: true,
          writeValue: false,
          keys: ['customer_list_read', 'customer_list_write'],
        },
        {
          show: false,
          title: 'Invoices Tab',
          readValue: true,
          writeValue: false,
          keys: ['invoice_read', 'invoice_write'],
        },
      ],
    },
    {
      title: 'Vendor Page Permissions',
      section: 'spend',
      show: false,
      readValue: true,
      writeValue: false,
      keys: ['vendor_read', 'vendor_write'],
      tabs: [
        {
          show: false,
          title: 'Vendor Overview Tab',
          readValue: true,
          writeValue: false,
          keys: ['vendor_overview_read', 'vendor_overview_write'],
        },
        {
          show: false,
          title: 'Payment Tab',
          readValue: true,
          writeValue: false,
          keys: ['vendor_payment_read', 'vendor_payment_write'],
        },
        {
          show: false,
          title: 'Purchase Order Tab',
          readValue: true,
          writeValue: false,
          keys: ['purchase_order_read', 'purchase_order_write'],
        },
        {
          show: false,
          title: 'Invoice Received Tab',
          readValue: true,
          writeValue: false,
          keys: ['invoice_received_read', 'invoice_received_write'],
        },
        {
          show: false,
          title: 'Vendor History Tab',
          readValue: true,
          writeValue: false,
          keys: ['vendor_history_read', 'vendor_history_write'],
        },
      ],
    },
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (sourceData) {
      if (source === 'edit' || source === 'view') {
        const permissions = sourceData.permissions;
        setShowMainPermissions([
          {
            title: 'Non-module Permissions',
            section: 'non-section',
            has_read_write: false,
            show: false,
            readValue: true,
            writeValue: true,
          },
          {
            title: 'Payroll Module Permissions',
            section: 'payroll',
            has_read_write: true,
            show: false,
            readValue: permissions.section_permission.payroll_read == 1? true: false,
            writeValue: permissions.section_permission.payroll_write == 1? true: false,
            keys: ['section_payroll_read', 'section_payroll_write'],
          },
          {
            title: 'People Module Permissions',
            section: 'people',
            has_read_write: true,
            show: false,
            readValue: permissions.section_permission.hr_read == 1? true: false,
            writeValue: permissions.section_permission.hr_write == 1? true: false,
            keys: ['section_hr_read', 'section_hr_write'],
          },
          {
            title: 'Spend management Module Permissions',
            section: 'spend',
            has_read_write: true,
            show: false,
            readValue: permissions.section_permission.spendmanagement_read == 1? true: false,
            writeValue: permissions.section_permission.spendmanagement_write == 1? true: false,
            keys: ['spendmanagement_read', 'spendmanagement_write'],
          },
          
        ])
        setShowSubMainPermissions([
          {
            title: 'Employee Page Permissions',
            section: 'non-section',
            show: false,
            readValue: permissions.company_permission?.employee_read == 1? true: false,
            writeValue: permissions.company_permission?.employee_write == 1? true: false,
            keys: ['employee_read', 'employee_write'],
            tabs: [
              {
                show: false,
                title: 'Employee Overview Tab',
                readValue: permissions.employee_permission?.employee_read == 1? true: false,
                writeValue: permissions.employee_permission?.employee_write == 1? true: false,
                keys: ['employee_overview_read', 'employee_overview_write'],
              },
              
              {
                show: false,
                title: 'Salaries Tab',
                readValue: permissions.employee_permission?.salary_read == 1? true: false,
                writeValue: permissions.employee_permission?.salary_write == 1? true: false,
                keys: ['salary_read', 'salary_write'],
              },
              {
                show: false,
                title: 'Employee Overtime Tab',
                readValue: permissions.employee_permission?.overtime_read == 1? true: false,
                writeValue: permissions.employee_permission?.overtime_write == 1? true: false,
                keys: ['overtime_read', 'overtime_write'],
              },
              {
                show: false,
                title: 'Other Dedcutions Tab',
                readValue: permissions.employee_permission?.other_deductions_read == 1? true: false,
                writeValue: permissions.employee_permission?.other_deductions_write == 1? true: false,
                keys: ['other_deductions_read', 'other_deductions_write'],
              },
              {
                show: false,
                title: 'Payroll Groups Tab',
                readValue: permissions.employee_permission?.group_read == 1? true: false,
                writeValue: permissions.employee_permission?.group_write == 1? true: false,
                keys: ['employee_group_read', 'employee_group_write'],
              },
              {
                show: false,
                title: 'Bonus Tab',
                readValue: permissions.employee_permission?.bonus_read == 1? true: false,
                writeValue: permissions.employee_permission?.bonus_write == 1? true: false,
                keys: ['bonus_read', 'bonus_write'],
              },
              {
                show: false,
                title: 'Salary Advance Tab',
                readValue: permissions.employee_permission?.advance_read == 1? true: false,
                writeValue: permissions.employee_permission?.advance_write == 1? true: false,
                keys: ['advance_read', 'advance_write'],
              },
              {
                show: false,
                title: 'Reimbursements Tab',
                readValue: permissions.employee_permission?.reimbursement_read == 1? true: false,
                writeValue: permissions.employee_permission?.reimbursement_write == 1? true: false,
                keys: ['reimbursement_read', 'reimbursement_write'],
              },
              {
                show: false,
                title: 'Suspension Tab',
                readValue: permissions.employee_permission?.suspension_read == 1? true: false,
                writeValue: permissions.employee_permission?.suspension_write == 1? true: false,
                keys: ['suspension_read', 'suspension_write'],
              },
              {
                show: false,
                title: 'Guarantor Tab',
                readValue: permissions.employee_permission?.guarantor_read == 1? true: false,
                writeValue: permissions.employee_permission?.guarantor_write == 1? true: false,
                keys: ['guarantor_read', 'guarantor_write'],
              },
              {
                show: false,
                title: 'Query Tab',
                readValue: permissions.employee_permission?.query_read == 1? true: false,
                writeValue: permissions.employee_permission?.query_write == 1? true: false,
                keys: ['query_read', 'query_write'],
              },
              {
                show: false,
                title: 'Document Tab',
                readValue: permissions.employee_permission?.doc_read == 1? true: false,
                writeValue: permissions.employee_permission?.doc_write == 1? true: false,
                keys: ['employee_doc_read', 'employee_doc_write'],
              },
            ],
          },
          {
            title: 'HMO Page Permissions',
            section: 'non-section',
            show: false,
            readValue: permissions.company_permission?.hmo_read == 1? true: false,
            writeValue: permissions.company_permission?.hmo_write == 1? true: false,
            keys: ['hmo_read', 'hmo_write'],
            tabs: [
              {
                show: false,
                title: 'HMO Beneficiaries Tab',
                readValue: permissions.hmo_permission?.beneficiary_read == 1? true: false,
                writeValue: permissions.hmo_permission?.beneficiary_write == 1? true: false,
                keys: ['beneficiary_read', 'beneficiary_write'],
              },
              {
                show: false,
                title: 'HMO Groups Tab',
                readValue: permissions.hmo_permission?.hmo_group_read == 1? true: false,
                writeValue: permissions.hmo_permission?.hmo_group_write == 1? true: false,
                keys: ['hmo_group_read', 'hmo_group_write'],
              },
              {
                show: false,
                title: 'HMO Settings Tab',
                readValue: permissions.hmo_permission?.hmo_settings_read == 1? true: false,
                writeValue: permissions.hmo_permission?.hmo_settings_write == 1? true: false,
                keys: ['hmo_settings_read', 'hmo_settings_write'],
              },
              {
                show: false,
                title: 'HMO Payment History Tab',
                readValue: permissions.hmo_permission?.hmo_payments_read == 1? true: false,
                writeValue: permissions.hmo_permission?.hmo_payments_write == 1? true: false,
                keys: ['hmo_payments_read', 'hmo_payments_write'],
              },
            ],
          },
          {
            title: 'Document Page Permissions',
            section: 'non-section',
            show: false,
            readValue: permissions.company_permission?.doc_read == 1? true: false,
            writeValue: permissions.company_permission?.doc_write == 1? true: false,
            keys: ['doc_read', 'doc_write'],
            tabs: []
          },
          {
            title: 'Airtime Page Permissions',
            section: 'non-section',
            show: false,
            readValue: permissions.company_permission?.airtime_read == 1? true: false,
            writeValue: permissions.company_permission?.airtime_write == 1? true: false,
            keys: ['airtime_read', 'airtime_write'],
            tabs: [
              {
                show: false,
                title: 'Airtime Overview Tab',
                readValue: permissions.airtime_permission?.airtime_read == 1? true: false,
                writeValue: permissions.airtime_permission?.airtime_write == 1? true: false,
                keys: ['airtime_overview_read', 'airtime_overview_write'],
              },
              {
                show: false,
                title: 'Airtime History Tab',
                readValue: permissions.airtime_permission?.airtime_history_read == 1? true: false,
                writeValue: permissions.airtime_permission?.airtime_history_write == 1? true: false,
                keys: ['airtime_history_read', 'airtime_history_write'],
              },
            ],
          },
          {
            title: 'Report Page Permissions',
            section: 'non-section',
            show: false,
            readValue: permissions.company_permission?.report_read == 1? true: false,
            writeValue: permissions.company_permission?.report_write == 1? true: false,
            keys: ['report_read', 'report_write'],
            tabs: []
          },
          {
            title: 'Settings Page Permissions',
            section: 'non-section',
            show: false,
            readValue: permissions.company_permission?.setting_read == 1? true: false,
            writeValue: permissions.company_permission?.setting_write == 1? true: false,
            keys: ['setting_read', 'setting_write'],
            tabs: []
          },
          {
            title: 'Wallet Page Permissions',
            section: 'non-section',
            show: false,
            readValue: permissions.company_permission?.wallet_read == 1? true: false,
            writeValue: permissions.company_permission?.wallet_write == 1? true: false,
            keys: ['wallet_read', 'wallet_write'],
            tabs: []
          },
          {
            title: 'Payroll Page Permissions',
            section: 'payroll',
            show: false,
            readValue: permissions.company_permission?.payroll_read == 1? true: false,
            writeValue: permissions.company_permission?.payroll_write == 1? true: false,
            keys: ['payroll_read', 'payroll_write'],
            tabs: [
              {
                show: false,
                title: 'Advanced Payroll Tab',
                readValue: permissions.airtime_permission?.advanced_payroll_read == 1? true: false,
                writeValue: permissions.airtime_permission?.advanced_payroll_write == 1? true: false,
                keys: ['advanced_payroll_read', 'advanced_payroll_write'],
              },
              {
                show: false,
                title: 'Basic Payroll Tab',
                readValue: permissions.airtime_permission?.basic_payroll_read == 1? true: false,
                writeValue: permissions.airtime_permission?.basic_payroll_write == 1? true: false,
                keys: ['basic_payroll_read', 'basic_payroll_write'],
              },
              {
                show: false,
                title: 'Payroll History Tab',
                readValue: permissions.airtime_permission?.history_read == 1? true: false,
                writeValue: permissions.airtime_permission?.history_write == 1? true: false,
                keys: ['payroll_history_read', 'payroll_history_write'],
              },
            ],
          },
          {
            title: 'Statutory Compliance Page Permissions',
            section: 'payroll',
            show: false,
            readValue: permissions.company_permission?.statutory_read == 1? true: false,
            writeValue: permissions.company_permission?.statutory_write == 1? true: false,
            keys: ['statutory_read', 'statutory_write'],
            tabs: [
              {
                show: false,
                title: 'Pension Tab',
                readValue: permissions.statutory_payment_permission?.pension_read == 1? true: false,
                writeValue: permissions.statutory_payment_permission?.pension_write == 1? true: false,
                keys: ['pension_read', 'pension_write'],
              },
              {
                show: false,
                title: 'Taxes Tab',
                readValue: permissions.statutory_payment_permission?.taxes_read == 1? true: false,
                writeValue: permissions.statutory_payment_permission.taxes_write == 1? true: false,
                keys: ['taxes_read', 'taxes_write'],
              },
              {
                show: false,
                title: 'NHF Tab',
                readValue: permissions.statutory_payment_permission?.nhf_read == 1? true: false,
                writeValue: permissions.statutory_payment_permission?.nhf_write == 1? true: false,
                keys: ['nhf_read', 'nhf_write'],
              },
              {
                show: false,
                title: 'NHIS Tab',
                readValue: permissions.statutory_payment_permission?.nhis_read == 1? true: false,
                writeValue: permissions.statutory_payment_permission?.nhis_write == 1? true: false,
                keys: ['nhis_read', 'nhis_write'],
              },
              {
                show: false,
                title: 'NSITF Tab',
                readValue: permissions.statutory_payment_permission?.nsitf_read == 1? true: false,
                writeValue: permissions.statutory_payment_permission?.nsitf_write == 1? true: false,
                keys: ['nsitf_read', 'nsitf_write'],
              },
              {
                show: false,
                title: 'ITF Tab',
                readValue: permissions.statutory_payment_permission?.itf_read == 1? true: false,
                writeValue: permissions.statutory_payment_permission?.itf_write == 1? true: false,
                keys: ['itf_read', 'itf_write'],
              },
            ],
          },
          {
            title: 'Recruitment Page Permissions',
            section: 'people',
            show: false,
            readValue: permissions.company_permission?.recruitment_read == 1? true: false,
            writeValue: permissions.company_permission?.recruitment_write == 1? true: false,
            keys: ['recruitment_read', 'recruitment_write'],
            tabs: [
              {
                show: false,
                title: 'Recruitment Overview Tab',
                readValue: permissions.recruitment_permission?.recruitment_read == 1? true: false,
                writeValue: permissions.recruitment_permission?.recruitment_write == 1? true: false,
                keys: ['recruitment_overview_read', 'recruitment_overview_write'],
              },
              {
                show: false,
                title: 'Recruitment Workflow Tab',
                readValue: permissions.recruitment_permission?.workflow_read == 1? true: false,
                writeValue: permissions.recruitment_permission?.workflow_write == 1? true: false,
                keys: ['recruitment_workflow_read', 'recruitment_workflow_write'],
              },
              {
                show: false,
                title: 'Recruitment Email Tab',
                readValue: permissions.recruitment_permission?.email_read == 1? true: false,
                writeValue: permissions.recruitment_permission?.email_write == 1? true: false,
                keys: ['recruitment_email_read', 'recruitment_email_write'],
              },
            ],
          },
          {
            title: 'Employee Onboarding Page Permissions',
            section: 'people',
            show: false,
            readValue: permissions.company_permission?.employee_onboarding_read == 1? true: false,
            writeValue: permissions.company_permission?.employee_onboarding_write == 1? true: false,
            keys: ['employee_onboarding_read', 'employee_onboarding_write'],
            tabs: [
              {
                show: false,
                title: 'Employee Onboarding Overview Tab',
                readValue: permissions.employee_onboarding_permission?.employee_onboarding_read == 1? true: false,
                writeValue: permissions.employee_onboarding_permission?.employee_onboarding_write == 1? true: false,
                keys: ['employee_onboarding_overview_read', 'employee_onboarding_overview_write'],
              },
              {
                show: false,
                title: 'Onboarding Workflow Tab',
                readValue: permissions.employee_onboarding_permission?.workflow_read == 1? true: false,
                writeValue: permissions.employee_onboarding_permission?.workflow_write == 1? true: false,
                keys: ['onboarding_workflow_read', 'onboarding_workflow_write'],
              },
            ],
          },
          {
            title: 'Team Page Permissions',
            section: 'people',
            show: false,
            readValue: permissions.company_permission?.team_read == 1? true: false,
            writeValue: permissions.company_permission?.team_write == 1? true: false,
            keys: ['team_read', 'team_write'],
            tabs: [
              {
                show: false,
                title: 'Team Overview Tab',
                readValue: permissions.team_permission?.team_read == 1? true: false,
                writeValue: permissions.team_permission?.team_write == 1? true: false,
                keys: ['team_overview_read', 'team_overview_write'],
              },
            ],
          },
          {
            title: 'Department Page Permissions',
            section: 'people',
            show: false,
            readValue: permissions.company_permission?.department_read == 1? true: false,
            writeValue: permissions.company_permission?.department_write == 1? true: false,
            keys: ['department_read', 'department_write'],
            tabs: [
              {
                show: false,
                title: 'Department Overview Tab',
                readValue: permissions.department_permission?.department_read == 1? true: false,
                writeValue: permissions.department_permission?.department_write == 1? true: false,
                keys: ['department_overview_read', 'department_overview_write'],
              },
            ],
          },
          {
            title: 'Branch Page Permissions',
            section: 'people',
            show: false,
            readValue: permissions.company_permission?.branch_read == 1? true: false,
            writeValue: permissions.company_permission?.branch_write == 1? true: false,
            keys: ['branch_read', 'branch_write'],
            tabs: [
              {
                show: false,
                title: 'Branch Overview Tab',
                readValue: permissions.branch_permission?.branch_read == 1? true: false,
                writeValue: permissions.branch_permission?.branch_write == 1? true: false,
                keys: ['branch_overview_read', 'branch_overview_write'],
              },
              {
                show: false,
                title: 'Branch Payroll Tab',
                readValue: permissions.branch_permission?.branch_payroll_read == 1? true: false,
                writeValue: permissions.branch_permission?.branch_payroll_write == 1? true: false,
                keys: ['branch_payroll_read', 'branch_payroll_write'],
              },
              {
                show: false,
                title: 'Branch Transactions Tab',
                readValue: permissions.branch_permission?.branch_transactions_read == 1? true: false,
                writeValue: permissions.branch_permission?.branch_transactions_write == 1? true: false,
                keys: ['branch_transactions_read', 'branch_transactions_write'],
              },
            ],
          },
          {
            title: 'Attendance Page Permissions',
            section: 'people',
            show: false,
            readValue: permissions.company_permission?.attendance_read == 1? true: false,
            writeValue: permissions.company_permission?.attendance_write == 1? true: false,
            keys: ['attendance_read', 'attendance_write'],
            tabs: [
              {
                show: false,
                title: 'Attendance Overview Tab',
                readValue: permissions.attendance_permission?.attendance_read == 1? true: false,
                writeValue: permissions.attendance_permission?.attendance_write == 1? true: false,
                keys: ['attendance_overview_read', 'attendance_overview_write'],
              },
              {
                show: false,
                title: 'Attendance Schedule Tab',
                readValue: permissions.attendance_permission?.attendance_schedule_read == 1? true: false,
                writeValue: permissions.attendance_permission?.attendance_schedule_write == 1? true: false,
                keys: ['attendance_schedule_read', 'attendance_schedule_write'],
              },
              {
                show: false,
                title: 'Attendance Settings Tab',
                readValue: permissions.attendance_permission?.attendance_settings_read == 1? true: false,
                writeValue: permissions.attendance_permission?.attendance_settings_write == 1? true: false,
                keys: ['attendance_settings_read', 'attendance_settings_write'],
              },
            ],
          },
          {
            title: 'Leave Page Permissions',
            section: 'people',
            show: false,
            readValue: permissions.company_permission?.leave_read == 1? true: false,
            writeValue: permissions.company_permission?.leave_write == 1? true: false,
            keys: ['leave_read', 'leave_write'],
            tabs: [
              {
                show: false,
                title: 'Leave Overview Tab',
                readValue: permissions.leave_permission?.leave_read == 1? true: false,
                writeValue: permissions.leave_permission?.leave_write == 1? true: false,
                keys: ['leave_overview_read', 'leave_overview_write'],
              },
              {
                show: false,
                title: 'Leave Types Tab',
                readValue: permissions.leave_permission?.leave_type_read == 1? true: false,
                writeValue: permissions.leave_permission?.leave_type_write == 1? true: false,
                keys: ['leave_type_read', 'leave_type_write'],
              },
              {
                show: false,
                title: 'Leave Settings Tab',
                readValue: permissions.leave_permission?.leave_settings_read == 1? true: false,
                writeValue: permissions.leave_permission?.leave_settings_write == 1? true: false,
                keys: ['leave_settings_read', 'leave_settings_write'],
              },
            ],
          },
          {
            title: 'Promotion Page Permissions',
            section: 'people',
            show: false,
            readValue: permissions.company_permission?.promotion_read == 1? true: false,
            writeValue: permissions.company_permission?.promotion_write == 1? true: false,
            keys: ['promotion_read', 'promotion_write'],
            tabs: [
              {
                show: false,
                title: 'Promotion Overview Tab',
                readValue: permissions.promotion_permission?.promotion_read == 1? true: false,
                writeValue: permissions.promotion_permission?.promotion_write == 1? true: false,
                keys: ['promotion_overview_read', 'promotion_overview_write'],
              },
            ],
          },
          {
            title: 'Training Page Permissions',
            section: 'people',
            show: false,
            readValue: permissions.company_permission?.training_read == 1? true: false,
            writeValue: permissions.company_permission?.training_write == 1? true: false,
            keys: ['training_read', 'training_write'],
            tabs: [
              {
                show: false,
                title: 'Training Overview Tab',
                readValue: permissions.training_permission?.training_read == 1? true: false,
                writeValue: permissions.training_permission?.training_write == 1? true: false,
                keys: ['training_overview_read', 'training_overview_write'],
              },
              {
                show: false,
                title: 'Trainers Tab',
                readValue: permissions.training_permission.trainer_read == 1? true: false,
                writeValue: permissions.training_permission.trainer_write == 1? true: false,
                keys: ['trainer_read', 'trainer_write'],
              },
            ],
          },
          {
            title: 'Performance Page Permissions',
            section: 'people',
            show: false,
            readValue: permissions.company_permission?.performance_read == 1? true: false,
            writeValue: permissions.company_permission?.performance_write == 1? true: false,
            keys: ['performance_read', 'performance_write'],
            tabs: [
              {
                show: false,
                title: 'Performance Overview Tab',
                readValue: permissions.performance_permission?.performance_read == 1? true: false,
                writeValue: permissions.performance_permission?.performance_write == 1? true: false,
                keys: ['performance_overview_read', 'performance_overview_write'],
              },
              {
                show: false,
                title: 'Performance Workflow Tab',
                readValue: permissions.performance_permission?.workflow_read == 1? true: false,
                writeValue: permissions.performance_permission?.workflow_write == 1? true: false,
                keys: ['performance_workflow_read', 'performance_workflow_write'],
              },
              {
                show: false,
                title: 'Performance OKR Tab',
                readValue: permissions.performance_permission?.okr_read == 1? true: false,
                writeValue: permissions.performance_permission?.okr_write == 1? true: false,
                keys: ['okr_read', 'okr_write'],
              },
              {
                show: false,
                title: 'Performance Indicators Tab',
                readValue: permissions.performance_permission?.indicators_read == 1? true: false,
                writeValue: permissions.performance_permission?.indicators_write == 1? true: false,
                keys: ['indicators_read', 'indicators_write'],
              },
              {
                show: false,
                title: 'Performance Smart Goals Tab',
                readValue: permissions.performance_permission?.smart_goals_read == 1? true: false,
                writeValue: permissions.performance_permission?.smart_goals_write == 1? true: false,
                keys: ['smart_goals_read', 'smart_goals_write'],
              },
              {
                show: false,
                title: 'Performance Technical Appraisals Tab',
                readValue: permissions.performance_permission?.technical_read == 1? true: false,
                writeValue: permissions.performance_permission?.technical_write == 1? true: false,
                keys: ['technical_read', 'technical_write'],
              },
            ],
          },
          {
            title: 'Exit Page Permissions',
            section: 'people',
            show: false,
            readValue: permissions.company_permission?.performance_read == 1? true: false,
            writeValue: permissions.company_permission?.performance_write == 1? true: false,
            keys: ['exit_read', 'exit_write'],
            tabs: [
              {
                show: false,
                title: 'Resignation Tab',
                readValue: permissions.exit_permission?.resignation_read == 1? true: false,
                writeValue: permissions.exit_permission?.resignation_write == 1? true: false,
                keys: ['resignation_read', 'resignation_write'],
              },
              {
                show: false,
                title: 'Termination Tab',
                readValue: permissions.exit_permission?.termination_read == 1? true: false,
                writeValue: permissions.exit_permission?.termination_write == 1? true: false,
                keys: ['termination_read', 'termination_write'],
              },
              {
                show: false,
                title: 'Exit Workflow',
                readValue: permissions.exit_permission?.workflow_read == 1? true: false,
                writeValue: permissions.exit_permission?.workflow_write == 1? true: false,
                keys: ['exit_workflow_read', 'exit_workflow_write'],
              },
              {
                show: false,
                title: 'Exit Questions Tab',
                readValue: permissions.exit_permission.question_read == 1? true: false,
                writeValue: permissions.exit_permission.question_write == 1? true: false,
                keys: ['exit_question_read', 'exit_question_write'],
              },
            ],
          },
          {
            title: 'Customer Page Permissions',
            section: 'spend',
            show: false,
            readValue: permissions.company_permission?.customer_read == 1? true: false,
            writeValue: permissions.company_permission?.customer_write == 1? true: false,
            keys: ['customer_read', 'customer_write'],
            tabs: [
              {
                show: false,
                title: 'Customer List Tab',
                readValue: permissions.customer_permission?.customer_read == 1? true: false,
                writeValue: permissions.customer_permission?.customer_write == 1? true: false,
                keys: ['customer_list_read', 'customer_list_write'],
              },
              {
                show: false,
                title: 'Invoices Tab',
                readValue: permissions.customer_permission?.invoice_read == 1? true: false,
                writeValue: permissions.customer_permission?.invoice_write == 1? true: false,
                keys: ['invoice_read', 'invoice_write'],
              },
            ],
          },
          {
            title: 'Vendor Page Permissions',
            section: 'spend',
            show: false,
            readValue: permissions.company_permission?.vendor_read == 1? true: false,
            writeValue: permissions.company_permission?.vendor_write == 1? true: false,
            keys: ['vendor_read', 'vendor_write'],
            tabs: [
              {
                show: false,
                title: 'Vendor Overview Tab',
                readValue: permissions.vendor_permission?.vendor_read == 1? true: false,
                writeValue: permissions.vendor_permission?.vendor_write == 1? true: false,
                keys: ['vendor_overview_read', 'vendor_overview_write'],
              },
              {
                show: false,
                title: 'Payment Tab',
                readValue: permissions.vendor_permission?.payment_read == 1? true: false,
                writeValue: permissions.vendor_permission?.payment_write == 1? true: false,
                keys: ['vendor_payment_read', 'vendor_payment_write'],
              },
              {
                show: false,
                title: 'Purchase Order Tab',
                readValue: permissions.vendor_permission?.purchase_order_read == 1? true: false,
                writeValue: permissions.vendor_permission?.purchase_order_write == 1? true: false,
                keys: ['purchase_order_read', 'purchase_order_write'],
              },
              {
                show: false,
                title: 'Invoice Received Tab',
                readValue: permissions.vendor_permission?.invoice_received_read == 1? true: false,
                writeValue: permissions.vendor_permission?.invoice_received_write == 1? true: false,
                keys: ['invoice_received_read', 'invoice_received_write'],
              },
              {
                show: false,
                title: 'Vendor History Tab',
                readValue: permissions.vendor_permission?.history_read == 1? true: false,
                writeValue: permissions.vendor_permission?.history_write == 1? true: false,
                keys: ['vendor_history_read', 'vendor_history_write'],
              },
            ],
          },
        ]);
      }
    }
  }, [sourceData, source]);

  const { mutateAsync: addCompanyAdmin, isLoading: loading } = useAddCompanyAdmin();

  const { mutateAsync: updateCompanyAdmin, isLoading: updateLoading } = useUpdateCompanyAdmin();

  const grantAllAccess = async (index, type) => {
    let newArray = JSON.parse(
      JSON.stringify(showSubMainPermissions),
    );

    newArray[index].tabs.forEach(item => {
      if(type == 'add') {
        item.readValue = true
        item.writeValue = true
      } else {
        item.readValue = false
        item.writeValue = false
      }
      
    });
    setShowSubMainPermissions(newArray);
  }

  const grantFullAccess = async (type) => {
    let newArray = JSON.parse(
      JSON.stringify(showMainPermissions),
    );

    newArray.forEach(item => {
      if (item.has_read_write) {
        if(type == 'add') {
          item.readValue = true
          item.writeValue = true
        } else {
          item.readValue = false
          item.writeValue = false
        }
      }
    });
    setShowMainPermissions(newArray)


    let newArraySub = JSON.parse(
      JSON.stringify(showSubMainPermissions),
    );

    newArraySub.forEach(item => {
      if(type == 'add') {
        item.readValue = true
        item.writeValue = true
      } else {
        item.readValue = false
        item.writeValue = false
      }
      item.tabs.forEach(subItem => {
        if(type == 'add') {
          subItem.readValue = true
          subItem.writeValue = true
        } else {
          subItem.readValue = false
          subItem.writeValue = false
        }
      })
    });
    setShowSubMainPermissions(newArraySub);
    if(type == 'add') {
      dispatch(setAlert(true, 'info', 'You have granted full access to this admin. Click save to confirm'));
    } else {
      dispatch(setAlert(true, 'info', 'You have revoked all access from this admin. Click save to confirm'));
    }
  }

  const submit = async () => {
    let permissions = {};

    //compute permission keys
    showMainPermissions.forEach((element) => {
      if (element.has_read_write) {
          permissions[element.keys[0]] = element.readValue ? 1 : 0;
          permissions[element.keys[1]] = element.writeValue ? 1 : 0;
      }
    });

    showSubMainPermissions.forEach((element) => {
      permissions[element.keys[0]] = element.readValue ? 1 : 0;
      permissions[element.keys[1]] = element.writeValue ? 1 : 0;
      element.tabs.forEach((item) => {
        permissions[item.keys[0]] = item.readValue ? 1 : 0;
        permissions[item.keys[1]] = item.writeValue ? 1 : 0;
      });
    });

    if (source === 'add') {
      let payload = { ...payloadData, ...permissions };
      await addCompanyAdmin(payload).then(() => {
        onCompleted();
      });
    } else if (source === 'edit') {
      
      let payload = {
        id: sourceData.id,
        data: { ...payloadData, ...permissions },
      };

      await updateCompanyAdmin(payload).then(() => {
        onCompleted();
      });
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <div className='pl-8 pr-8 pt-4'>
          <div className='flex flex-row justify-between items-center'>
            <div className='flex flex-col'>
              <span className='header-4'>
                {title ? title : 'Add Admin Permissions'}
              </span>
              <span className='p3 text-color-gray pt-1 p4-regular'>
                Read Access allows view only; Write Access allows change
              </span>
            </div>

            <div className='cursor-pointer' onClick={() => closeModal()}>
              <img src={CloseSVG} alt='close' />
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='flex flex-col mx-8'>
          <div className='flex flex-col md:flex-col w-full justify-between'>
            <div className='flex justify-end w-full md:mt-0 h-[25px] gap-2 items-center'>
              <Button
                text='GRANT FULL ACCESS'
                type='button'
                theme={'primary'}
                className='h-[25px] w-fit p-2'
                onClick={() => grantFullAccess('add')}
                textClass={'!text-[9px]'}
              />
              <Button
                text='Remove FULL ACCESS'
                type='button'
                className='h-[25px] w-fit p-2 !bg-red-600'
                onClick={() => grantFullAccess('remove')}
                textClass={'!text-[9px] '}
              />
            </div>
            <hr className='divider mt-2 mb-1' />
            {showMainPermissions.map((item, index) => (
              <>
                <div
                  className={classNames('flex flex-row justify-between items-center cursor-pointer hover:bg-secondary-2-extralight hover:rounded-md hover:px-2 duration-300 text-[15px]', {
                    'pt-3 pb-3 font-extrabold mb-2 bg-secondary-2-extralight px-2 rounded-md': item.show,
                  })}
                  onClick={() => {
                    let newArray = JSON.parse(
                      JSON.stringify(showMainPermissions),
                    );

                    newArray[index].show = !item.show;
                    setShowMainPermissions(newArray);
                  }}
                >
                  <span>{item.title}</span>
                  <div className='pr-4'>
                    <CollapsingIcon defaultPosition='left' isOpen={item.show} />
                  </div>
                </div>
                <div
                  className={classNames('flex flex-col p-3 border bg-gray-50 rounded-md', {
                    hidden: !item.show,
                  })}
                >
                  <Collapse isOpen={item.show}>
                    {item.has_read_write?
                    <div className='flex flex-row items-center justify-betweeen gap-16 p-2 bg-gray-100 border rounded  max-h-[35px] mb-2 hover:bg-gray-200 duration-300'>
                      <div className='text-[12px] w-[100%] font-extrabold'>
                        {item.title}
                      </div>
                      <div className='flex flex-row items-center justify-betweeen gap-2 p-2 max-h-[35px] w-[40%] mt-4'>
                        <FormSwitch
                          label={'Read'}
                          name={item.keys[0]}
                          checked={item.readValue}
                          onClick={() => {
                            let newArray = JSON.parse(
                              JSON.stringify(showMainPermissions),
                            );

                            newArray[index].readValue =
                              !item.readValue;
                              setShowMainPermissions(newArray);
                          }}
                          classNames={'flex gap-8 p5-medium !pb-0'}
                        />
                        <FormSwitch
                          label={'Write'}
                          name={item.keys[1]}
                          checked={item.writeValue}
                          onClick={() => {
                            let newArray = JSON.parse(
                              JSON.stringify(showMainPermissions),
                            );

                            newArray[index].writeValue =
                              !item.writeValue;
                              setShowMainPermissions(newArray);
                          }}
                          classNames={'flex gap-8 p5-medium !pb-0'}
                        />
                      </div>
                    </div>
                    :
                    null
                    }
                    {item.readValue?
                    <>
                      {showSubMainPermissions.map((subItem, subIndex) => (
                        <>
                        {item.section == subItem.section?
                        <>
                          <div
                            className={classNames('flex flex-row justify-between items-center cursor-pointer hover:bg-secondary-2-extralight hover:rounded-md hover:px-2 duration-300 text-[13px] pl-4', {
                              'pt-3 pb-3 font-extrabold mb-2 bg-secondary-2-extralight px-2 rounded-md': subItem.show,
                            })}
                            onClick={() => {
                              let newArray = JSON.parse(JSON.stringify(showSubMainPermissions));
                              newArray[subIndex].show = !subItem.show;
                              setShowSubMainPermissions(newArray);
                            }}
                          >
                            <span>{subItem.title}</span>
                            <div className='pr-4'>
                              <CollapsingIcon
                                label='Select Access'
                                defaultPosition='left'
                                isOpen={subItem.show}
                              />
                            </div>
                          </div>
                          <Collapse isOpen={subItem.show}>
                            <div className='flex flex-row items-center justify-betweeen gap-16 p-2 bg-gray-100 border rounded  max-h-[35px] mb-2 hover:bg-gray-200 duration-300'>
                              <div className='text-[12px] w-[100%] font-extrabold'>
                                {subItem.title}
                              </div>
                              <div className='flex flex-row items-center justify-betweeen gap-2 p-2 max-h-[35px] w-[40%] mt-4'>
                                <FormSwitch
                                  label={'Read'}
                                  name={subItem.keys[0]}
                                  checked={subItem.readValue}
                                  onClick={() => {
                                    let newArray = JSON.parse(
                                      JSON.stringify(showSubMainPermissions),
                                    );

                                    newArray[subIndex].readValue =
                                      !subItem.readValue;
                                      setShowSubMainPermissions(newArray);
                                  }}
                                  classNames={'flex gap-8 p5-medium !pb-0'}
                                />
                                <FormSwitch
                                  label={'Write'}
                                  name={subItem.keys[1]}
                                  checked={subItem.writeValue}
                                  onClick={() => {
                                    let newArray = JSON.parse(
                                      JSON.stringify(showSubMainPermissions),
                                    );

                                    newArray[subIndex].writeValue =
                                      !subItem.writeValue;
                                      setShowSubMainPermissions(newArray);
                                  }}
                                  classNames={'flex gap-8 p5-medium !pb-0'}
                                />
                              </div>
                            </div>
                            
                            {subItem.readValue && subItem.tabs && subItem.tabs.length > 0?
                            <>
                              <div className='flex justify-end w-full md:mt-0 h-[50px] gap-2 items-center'>
                                <Button
                                  text='GRANT ALL ACCESS'
                                  type='button'
                                  theme={'primary'}
                                  className='h-[25px] w-fit p-2'
                                  onClick={() => grantAllAccess(subIndex, 'add')}
                                  textClass={'!text-[9px] '}
                                />
                                <Button
                                  text='Remove ALL ACCESS'
                                  type='button'
                                  className='h-[25px] w-fit p-2 !bg-red-600'
                                  onClick={() => grantAllAccess(subIndex, 'remove')}
                                  textClass={'!text-[9px] '}
                                />
                              </div>
                              {subItem.tabs && subItem.tabs.length > 0 ?
                              <div className='flex flex-col gap-2 border bg-gray-50 rounded-md p-3'>
                                {subItem.tabs.map((subTabItem, subTabIndex) => (
                                <div className='flex flex-row items-center justify-betweeen gap-16 p-2 bg-gray-100 border rounded max-h-[35px] hover:bg-gray-200 duration-300'>
                                  <div className='text-[11px] w-1/2'>
                                    {subTabItem.title}
                                  </div>
                                  <div className='flex flex-row items-center justify-betweeen gap-2 p-2 max-h-[35px] w-1/2 mt-4'>
                                    <FormSwitch
                                      label={'Read'}
                                      name={subTabItem.keys[0]}
                                      checked={subTabItem.readValue}
                                      onClick={() => {
                                        let newArray = JSON.parse(
                                          JSON.stringify(showSubMainPermissions),
                                        );
        
                                        newArray[subIndex].tabs[subTabIndex].readValue =
                                          !subTabItem.readValue;
                                          setShowSubMainPermissions(newArray);
                                      }}
                                      classNames={'flex gap-8 p5-medium !pb-0'}
                                    />
                                    <FormSwitch
                                      label={'Write'}
                                      name={subTabItem.keys[1]}
                                      checked={subTabItem.writeValue}
                                      onClick={() => {
                                        let newArray = JSON.parse(
                                          JSON.stringify(showSubMainPermissions),
                                        );
        
                                        newArray[subIndex].tabs[subTabIndex].writeValue =
                                          !subTabItem.writeValue;
                                          setShowSubMainPermissions(newArray);
                                      }}
                                      classNames={'flex gap-8 p5-medium !pb-0'}
                                    />
                                  </div>
                                  
                                </div>
                                ))}
                              </div>
                              :
                              null
                              }
                              <hr className='divider' />
                            </>
                            :
                            null
                            }
                            
                          
                          </Collapse>
                        </>
                        :
                        null}
                          
                        </>
                      ))}
                    </>
                    :
                    null}
                    
                  </Collapse>
                </div>
                <hr className='divider mt-1 mb-1' />
              </>
            ))}
          </div>
          {source !== 'view' ? (
            <>
              <div className='w-full mt-[20px]'>
                <Button
                  text='Save and Close'
                  type='submit'
                  loading={loading || updateLoading}
                  onClick={() => {
                    submit();
                  }}
                />
              </div>
              <div
                className='flex justify-center w-full cursor-pointer'
                onClick={() => closeModal()}
              >
                <span className='p2-medium text-color-black mt-3 pb-3'>
                  Back
                </span>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}

export default AddAdminPermissions;
