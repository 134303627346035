import React, { useEffect, useRef, useState } from 'react';
import TickSVG from '../../assets/svg/tick-primary.svg';
import { useHistory } from 'react-router-dom';
import Logo from '../logo';
import { Initials } from '../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { updateModalStatus } from '../../redux/components/components-slice';
import { store } from '../../redux';
import { setSection } from '../../redux/section/reducer';

const SingleEmployeeExitLayout = ({ children, step, company_name, logo }) => {

  const { employeeExit } = useSelector((state) => state.employeeSlice);
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateModalStatus(false));
  }, [])

  const [steps, setSteps] = useState([
    {
      title: 'Verification',
      completed: false,
    },
    {
      title: 'Exit Questions',
      completed: false,
    },
    {
      title: 'Exit',
      completed: false,
    },
  ]);

  useEffect(() => {
    if (step) {
      setCurrentStep(step);
    }
  }, [step, currentStep]);

  useEffect(() => {
    store.dispatch(setSection({
        entryRoute: '/dashboard',
        name: 'core hr',
        sidebarItems: []
    }))
  }, [])

  return (
    <div
      className='flex flex-col h-screen min-h-screen max-h-screen overflow-auto'
    >
      <div className='flex flex-col lg:bg-hr-primary-1'>
        {/* Desktop Sidebar */}
        <div className='flex flex-col justify-center lg:justify-start lg:px-0'>
          <div>
            <div className='flex flex-col mt-[25px]'>
              {/* Mobile Stepper */}
              <div className='flex lg:hidden justify-center w-full mb-[10px]'>
                <div className='flex flex-row w-[85%] my-3'>
                  {steps.map((item, index) => (
                    <div
                      key={index}
                      className={`${
                        index + 1 !== steps.length
                          ? 'flex flex-row w-full items-center'
                          : ''
                      }`}
                    >
                      <div>
                        <div
                          className={`flex items-center justify-center w-[30px] h-[30px] rounded-[30px] ${
                            index + 1 <= currentStep
                              ? 'bg-secondary-2'
                              : 'border border-color-black'
                          }  ${
                            index + 1 === currentStep
                              ? 'opacity-100'
                              : 'opacity-40'
                          }`}
                        >
                          {index <  currentStep-1  ? (
                            <img
                              src={TickSVG}
                              alt='tick'
                              style={{ width: '16px', height: '16px' }}
                            />
                          ) : (
                            <span
                              className={`text-[16px] font-bold ${
                                index + 1 <= currentStep
                                  ? 'text-white'
                                  : 'text-color-black'
                              }`}
                            >
                              {index + 1}
                            </span>
                          )}
                        </div>
                      </div>

                      {index + 1 !== steps.length ? (
                        <div className='bg-color-border w-full h-[1px]'></div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
              <div className='flex flex-col lg:flex-row w-full '>
                <div className='w-full lg:w-[35%] flex flex-col justify-between'>

                  {/* Desktop Stepper */}
                  <div className='hidden lg:flex w-full mt-5 pr-4'>
                    <div className='flex flex-col w-[85%] ml-[12%] mr-[12%]'>
                      <div className='flex flex-row gap-4 items-center justify-center'>
                        <div className='flex flex-row items-center'>
                          <div 
                            className='text-[20px] flex font-bold justify-center items-center h-[75px] w-[75px] rounded-[75px] border-[3px] header-3 relative hover:scale-150 hover:m-2 hover:opacity-25 duration-500'
                          >
                            {employeeExit?.logo?
                            <div>
                              <img src={employeeExit?.logo} alt='email_icon' className='object-fit min-w-[65px] min-h-[65px] rounded-[65px] max-w-[65px] max-h-[65px]' />
                            </div>
                            :
                            <>
                            {Initials (employeeExit?.company_name)}
                            </>
                            }
                            
                          </div>
                        </div>
                        <button
                          className='hidden lg:flex text-color-white header-3 text-[20px] mb-2 justify-center items-center'
                          onClick={() => history.push('/dashboard')}
                        >
                          {employeeExit?.company_name}
                        </button>
                      </div>
                      
                      <div className='mt-3 mb-5 flex flex-col'>
                        <span className='text-white header-4 text-center mb-2'>
                          Welcome to the onboarding portal
                        </span>
                        <span className='text-white header-5-regular text-justify'>
                          Your employer has added you to the onboarding process. You just have to quickly identify yourself
                        </span>
                      </div>
                      {steps.map((item, index) => (
                        <div
                          key={index} 
                          className='flex flex-row mb-[50px]'>
                          <div className='flex items-center w-[15%]'>
                            <div
                              className={`flex items-center justify-center  w-[50px] h-[50px] rounded-[50px] ${
                                index + 1 <= currentStep
                                  ? 'bg-white'
                                  : 'border border-color-black bg-white'
                              }  ${
                                index + 1 == currentStep
                                  ? 'opacity-100'
                                  : 'opacity-40 '
                              }`}
                            >
                              {index <  currentStep-1  ? (
                                <img
                                  src={TickSVG}
                                  alt='tick'
                                  style={{ width: '20px', height: '20px' }}
                                />
                              ) : (
                                <span
                                  className={`header-3 ${
                                    index + 1 <= currentStep
                                      ? 'text-secondary-2'
                                      : 'text-secondary-2'
                                  }`}
                                >
                                  {index + 1}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='flex flex-col w-[85%] justify-center'>
                            <span
                              className={`text-[18px] font-bold text-white leading-[23px] pl-3 ${
                                index + 1 == currentStep
                                  ? 'text-opacity-100'
                                  : 'text-opacity-50'
                              }`}
                            >
                              {item.title}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    
                  </div>
                  <div className={'flex items-center justify-center text-color-white gap-2'}>
                    Powered By: <a href='https://www.ropay.africa' target={'_blank'} rel="noreferrer">{<Logo width='80px' height='40px' white />}</a>
                  </div>
                </div>

                <div className='w-full lg:w-[65%] -mt-6 h-screen'>
                  <div className='w-full bg-white h-full'>
                    {/* Page components */}
                    <div className='lg:max-h-[100vh] overflow-auto h-full'>
                      <div className='w-full flex items-center justify-center flex-col'>
                        <div className='w-full pr-8 pl-8 flex flex-col lg:flex-row'>
                          <div className='w-full pb-[30px] !h-full'>
                            {children}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleEmployeeExitLayout;
