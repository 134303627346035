import React, { useEffect } from "react";
import DataTable from "../../../datatable";
import { useState } from "react";
import {
  recruitmentApplicationStatusConfig,
  PAGINATION_DEFAULT,
} from "../../../../utils/constants";
import { useHistory, useParams } from "react-router-dom";
import { useGetRejectedRecruitmentApplications } from "../../../../redux/human-resources/hook/recruitment";
import MoveApplicant from "../../../modal/hr-section/recruitment/move-applicant";
import ViewResume from "../../../modal/hr-section/recruitment/view-resume";
import ReviewStatus from "../../../modal/hr-section/recruitment/review-applicant";
import SendApplicantsEmail from "../../../modal/hr-section/recruitment/send-applicants-email";
import SendStatusEmail from "../../../modal/hr-section/recruitment/send-email-by-status";
import Button from "../../../button";

const RejectedListTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: 2,
    search: "",
  });
  const { id } = useParams();
  const history = useHistory();

  const { data: recruitment_applications } =
    useGetRejectedRecruitmentApplications(
      id,
      pagination?.limit,
      pagination?.page,
      2,
      pagination?.search
    );

  const [showMoveApplicant, setshowMoveApplicant] = useState(false);
  const [showResume, setshowResume] = useState(false);
  const [showMarkReviewed, setMarkReviewed] = useState(false);
  const [applicant, setApplicant] = useState();
  const [showSendEmailByStatus, setShowSendEmailByStatus] = useState(false);

  const [showSendEmail, setShowSendEmail] = useState(false);

  const [applicantIds, setApplicantIds] = useState(false);

  const tableConfig = {
    headers: [
      "Date Added",
      "Applicant Name",
      "Email",
      "Mobile",
      "Review Status",
    ],
    keys: ["created", "applicantName", "email", "mobile", "status"],
    mobileHeader: {
      left: {
        title: "Applicant Name",
        key: "applicantName",
      },
      right: {
        title: "Review Status",
        key: "status",
      },
    },
  };

  const actionConfig = [
    {
      name: "View Applicant",
      color: "green",
      action: (id) => {
        let index = recruitment_applications.data.findIndex(
          (item) => item.id === id
        );
        history.push(
          `/recruitment/application/${recruitment_applications.data[index].id}`
        );
      },
    },
    {
      name: "View Resume",
      color: "orange",
      action: (id) => {
        let index = recruitment_applications.data.findIndex(
          (item) => item.id === id
        );
        setApplicant(recruitment_applications.data[index]);
        setshowResume(true);
      },
    },
    {
      name: "Move Applicant",
      color: "green",
      action: (id) => {
        let index = recruitment_applications.data.findIndex(
          (item) => item.id === id
        );
        setApplicant(recruitment_applications.data[index]);
        setshowMoveApplicant(true);
      },
    },
    {
      name: "Change Status",
      color: "green",
      action: (id) => {
        let index = recruitment_applications.data.findIndex(
          (item) => item.id === id
        );
        setApplicant(recruitment_applications.data[index]);
        setMarkReviewed(true);
      },
    },
    {
      name: "Send Email",
      color: "orange",
      action: (id) => {
        let index = recruitment_applications.data.findIndex(
          (item) => item.id === id
        );
        setApplicantIds(recruitment_applications.data[index].id.toString());
        setShowSendEmail(true);
      },
    },
  ];

  const bulkActionConfig = [
    {
      label: "Send Email",
      value: "send-email",
      action: (data) => {
        let newData = data.split(",");
        let result = [];
        newData.forEach((id) => {
          let index = recruitment_applications.data.findIndex(
            (item) => item.id == id
          );
          if (index != -1) {
            result.push(recruitment_applications.data[index].id);
          }
        });
        setApplicantIds(result.join(","));
        setShowSendEmail(true);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showSendEmailByStatus ? (
        <SendStatusEmail
          title={"Send Email"}
          isOpen={showSendEmailByStatus}
          closeModal={() => setShowSendEmailByStatus(false)}
          buttonText="Send"
        />
      ) : null}
      {showSendEmail ? (
        <SendApplicantsEmail
          title={"Review Status"}
          isOpen={showSendEmail}
          closeModal={() => setShowSendEmail(false)}
          applicantIds={applicantIds}
          buttonText="Review Applicant"
        />
      ) : null}
      {showMarkReviewed ? (
        <ReviewStatus
          title={"Review Status"}
          isOpen={showMarkReviewed}
          closeModal={() => setMarkReviewed(false)}
          applicant={applicant}
          buttonText="Review Applicant"
        />
      ) : null}
      {showMoveApplicant ? (
        <MoveApplicant
          title={"Move Applicant"}
          isOpen={showMoveApplicant}
          closeModal={() => setshowMoveApplicant(false)}
          applicant={applicant}
          buttonText="Move Applicant"
        />
      ) : null}
      {showResume ? (
        <ViewResume
          isOpen={showResume}
          closeModal={() => setshowResume(false)}
          title=" Move Applicant Stage"
          applicant={applicant}
          buttonText="Move Applicant"
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Auto Rejected Applicants
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Send Email By Status"
              rightIcon={"email"}
              type="submit"
              className="flex gap-2 !h-[35px] w-fit p-2 "
              textClass={"!text-[11px]"}
              onClick={() => {
                setShowSendEmailByStatus(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={recruitment_applications}
        tableConfig={tableConfig}
        bulkActionConfig={bulkActionConfig}
        statusConfig={recruitmentApplicationStatusConfig}
        searchData={true}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
      />
    </div>
  );
};

export default RejectedListTab;
