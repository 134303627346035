import React from 'react';
import { useState } from 'react';
import DataTable from '../../components/datatable';
import { PAGINATION_DEFAULT, attendanceStatusConfig, leaveStatusConfig } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { useGetCabinetAttendance } from '../../redux/employee-folder/hook';

const AttendanceFolder = ({employeeId}) => {

    const { user } = useSelector((state) => state.userSlice);

    const [pagination, setPagination] = useState({
        limit: PAGINATION_DEFAULT.limit,
        page: PAGINATION_DEFAULT.page,
        statusFilter: PAGINATION_DEFAULT.statusFilter,
        search: '',
    });

    const { data: leaves } = useGetCabinetAttendance(
        employeeId,
        pagination?.limit,
        pagination?.page,
        pagination?.statusFilter,
        pagination?.search,
    );


    const tableConfig = {
        headers: [
          'Date',
          'Employee',
          'Mobile',
          'Clock In',
          'Clock Out',
          'Status',
        ],
        keys: [
          'attendance_date',
          'employeeName',
          'mobile',
          'clockin_time',
          'clockout_time',
          'status',
        ],
        mobileHeader: {
          left: {
            title: 'Employee Name',
            key: 'employeeName',
          },
          right: {
            title: 'Status',
            key: 'status',
          },
        },
      };

    return (
        <div className='flex flex-col'>
            <div className='mt-9'>
                <div className='flex md:flex-row gap-4 flex-wrap-reverse'>
                    <div className='w-[100%]'>
                        <DataTable
                        data={leaves}
                        tableConfig={tableConfig}
                        statusConfig={attendanceStatusConfig}
                        noCheck={true}
                        updatePagination={(data) => setPagination(data)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AttendanceFolder;