import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import moment from 'moment';
import { employeeOtherDeduction } from '../../../api/employees/other-deductions';
import { formatNumber } from '../../../utils/functions';

export function useGetemployeeOtherDeductions(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-other-deductions', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeOtherDeduction.getOtherDeductions({
        limit,
        page,
        statusFilter, 
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((otherDeduction) => {
          otherDeduction.amount = parseFloat(otherDeduction.amount);
          otherDeduction.formatted_amount = formatNumber(parseFloat(otherDeduction.amount), 2);
          otherDeduction.created = moment(otherDeduction.created).format('MMMM D, YYYY');
          otherDeduction.formatted_month = moment(otherDeduction.month).format('MMMM, YYYY');
          otherDeduction.employeeName = `${otherDeduction.first_name} ${otherDeduction.last_name}`;

          switch (otherDeduction.status) {
            case 1:
              otherDeduction.computedActions = [0];
              break;
            case 2:
              otherDeduction.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetOtherDeductionsReport(start_date, end_date) {
  return useQuery(
    ['other-deductions-report', { start_date, end_date }],
    () => {
      return employeeOtherDeduction.getOtherDeductionsReport({ start_date, end_date });
    },
    {
      select: (data) => {
        let formatted_months = []
        data?.months?.forEach(element => {
          formatted_months.push(moment(element+'-01').format('MMM YY'));
        });
        data.formatted_months = formatted_months;
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
    
  );
}

export function useAddOtherDeduction() {
  return useMutation(
    (payload) => {
      return employeeOtherDeduction.addOtherDeduction(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-other-deductions');
        store.dispatch(setAlert(true, 'success', 'Other Deduction Added successfully'));
      },
    },
  );
}

export function useEditOtherDeductions() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeOtherDeduction.editOtherDeduction(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-other-deductions');
        store.dispatch(setAlert(true, 'success', 'Other Deduction updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkAddOtherDeductions() {
  return useMutation(
    (payload) => {
      return employeeOtherDeduction.bulkAddOtherDeduction(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('employee-other-deductions');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useDeleteOtherDeductions() {
  return useMutation(
    (payload) => {
      return employeeOtherDeduction.deleteOtherDeduction(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee-other-deductions');
        store.dispatch(setAlert(true, 'success', 'Other Deductions deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleGetemployeeOtherDeduction(id, limit, page, statusFilter) {
  return useQuery(
    ['single-employee-other-deductions', { id, limit, page, statusFilter }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeOtherDeduction.getSingleEmployeeOtherDeduction({ id, limit, page, statusFilter });
    },
    {
      select(data) {
        data?.data?.forEach((otherDeduction) => {
          otherDeduction.amount = parseFloat(otherDeduction.amount);
          otherDeduction.formatted_amount = formatNumber(parseFloat(otherDeduction.amount), 2);
          otherDeduction.created = moment(otherDeduction.created).format('MMMM D, YYYY');
          otherDeduction.formatted_month = moment(otherDeduction.month).format('MMMM, YYYY');
          otherDeduction.employeeName = `${otherDeduction.first_name} ${otherDeduction.last_name}`;

          switch (otherDeduction.status) {
            case 1:
              otherDeduction.computedActions = [0];
              break;
            case 2:
              otherDeduction.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}
