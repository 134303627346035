import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../components/button";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import { useUpdateCompany } from "../../../redux/company/hook";
import {
  PAGINATION_DEFAULT,
  pensionStatusConfig,
} from "../../../utils/constants";
import { useRefPension } from "../../../redux/statutory-compliance/hook/pension";
import PensionDetailedView from "../../../components/modal/statutory-compliance/pension-full-view";
import StatutorySummary from "../../../components/modal/statutory-compliance/statutory-summary";
import { useSelector } from "react-redux";
import FormInput from "../../../components/form-input";
import { useGetCompanyAccountDetails } from "../../../redux/wallet/hook";

const PensionPreviewPage = () => {
  useEffect(() => {}, []);
  const { data: companyAccountDetails } = useGetCompanyAccountDetails();

  const { company } = useSelector((state) => state.companySlice);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const [showPensionSummary, setShowPensionSummary] = useState(false);
  const [pensionCode, setPensionCode] = useState();
  const [viewData, setViewData] = useState();
  const [companyName, setCompanyName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();

  const [showPensionDetailedView, setShowPensionDetailedView] = useState(false);
  const { mutateAsync: updateCompany, isLoading: loading } = useUpdateCompany();

  const { refId } = useParams();
  const { data: pension } = useRefPension(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  useEffect(() => {
    if (company) {
      setPensionCode(company.employerPensionCode);
      setCompanyName(company.company_name);
      setEmail(company.email);
      setMobile(company.mobile);
    }
  }, [company]);

  const tableConfig = {
    headers: [
      "Employee",
      "RSA Info",
      "Employee Contr.",
      "Employer Contr.",
      "Status",
    ],
    keys: [
      "employeeName",
      "pfa_data",
      "table_employeeContribution",
      "table_employerContribution",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Total",
        key: "total_pension",
      },
    },
  };

  const actionConfig = [
    {
      name: "View/Update",
      color: "green",
      action: (id) => {
        let index = pension.data.findIndex((item) => item.id === id);
        setShowPensionDetailedView(true);
        setViewData(pension.data[index]);
      },
    },
  ];
  const companyPensionUpdate = async () => {
    let payload = {
      company_name: companyName,
      email: email,
      mobile: mobile,
      employerPensionCode: pensionCode,
    };

    await updateCompany(payload);
  };

  return (
    <DashboardLayout title="Pension Preview">
      <div className="flex flex-col">
        <div className="flex flex-col md:flex-row justify-between gap-3">
          <div className="flex flex-col md:w-2/3 w-full">
            <span className="h4 text-color-black">
              A Quick Preview Before You Submit
            </span>
            <span className="text-[14px] text-color-gray -mt-2">
              Please spend a brief moment reviewing your Pensions before you
              submit for Payment. Only Employees with PFA and correct RSA PINS
              will be paid and filed
            </span>
          </div>
          <div className="flex md:justify-end justify-center md:w-1/3 w-full">
            <Button
              text="PAY AND FILE"
              type="button"
              loading={false}
              disabled={false}
              className="h-[56px] w-full md:w-[220px] "
              onClick={() => setShowPensionSummary(true)}
            />
          </div>
        </div>

        <div className="">
          <>
            {showPensionDetailedView ? (
              <PensionDetailedView
                isOpen={showPensionDetailedView}
                closeModal={() => setShowPensionDetailedView(false)}
                viewData={viewData}
              />
            ) : null}
            {showPensionSummary ? (
              <StatutorySummary
                refValue={refId}
                type={"pension"}
                isOpen={showPensionSummary}
                closeModal={() => setShowPensionSummary(false)}
                takeHome={pension.formatted_total_pension}
                transCost={pension.formatted_total_trans_cost}
                totalAmount={pension.formatted_total_payable}
              />
            ) : null}
            <div className="flex flex-col md:flex-row gap-4 mt-4">
              <div className="w-[100%] md:w-[75%] rounded-md bg-white">
                <DataTable
                  data={pension}
                  tableConfig={tableConfig}
                  searchData={true}
                  actionConfig={actionConfig}
                  statusConfig={pensionStatusConfig}
                  updatePagination={(data) => setPagination(data)}
                  computedActions={true}
                  noCheck={true}
                />
              </div>
              <div className="md:w-[25%] flex flex-col md:pl-8 md:mt-0 rounded-md bg-white w-full p-3">
                <div className="flex flex-row justify-between items-center">
                  <div className="w-[70%]">
                    <FormInput
                      label="Company Pension Code"
                      name="pension_code"
                      className={"rounded-br-none rounded-tr-none"}
                      type="text"
                      readOnly={false}
                      value={pensionCode}
                      onInput={(e) => {
                        setPensionCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-[30%] mt-[10px]">
                    <Button
                      text="UPDATE"
                      type="submit"
                      disabled={
                        companyName && email && mobile && pensionCode
                          ? false
                          : true
                      }
                      loading={loading}
                      textClass="!text-[11px]"
                      theme="primary"
                      className={`h-[35px] pl-1 pr-1 rounded-bl-none rounded-tl-none`}
                      onClick={() => {
                        companyPensionUpdate();
                      }}
                    />
                  </div>
                </div>
                <hr className="divider mt-2" />
                <div className="mb-2">
                  <div className="flex flex-row justify-between">
                    <span className="text-[13px] text-secondary-2 font-bold">
                      Employee Pension
                    </span>
                    <span className="text-[13px]  text-color-black">
                      {pension?.formatted_total_employee_pension}
                    </span>
                  </div>
                  <hr className="divider" />
                </div>
                <div className="mb-2">
                  <div className="flex flex-row justify-between">
                    <span className="text-[13px] text-secondary-2 font-bold">
                      Employer Pension
                    </span>
                    <span className="text-[13px]  text-color-black">
                      {pension?.formatted_total_employer_pension}
                    </span>
                  </div>
                  <hr className="divider" />
                </div>
                <div className="mb-2">
                  <div className="flex flex-row justify-between">
                    <span className="text-[13px] text-secondary-2 font-bold">
                      Fees
                    </span>
                    <span className="text-[13px]  text-color-black">
                      {pension?.formatted_total_trans_cost}
                    </span>
                  </div>
                </div>
                <hr className="divider" />
                <div>
                  <div className="flex flex-row justify-between">
                    <span className="text-[16px] text-secondary-2 font-extrabold">
                      Total Pension
                    </span>
                    <span className="text-[15px]  text-color-black font-bold">
                      {pension?.formatted_total_payable}
                    </span>
                  </div>
                </div>
                <hr className="divider" />
                <div>
                  <div className="flex flex-row justify-between">
                    <span className="text-[13px] text-secondary-2 font-bold">
                      Total Remitted
                    </span>
                    <span className="text-[15px]  text-color-black font-bold">
                      {pension?.formatted_pension_remitted}
                    </span>
                  </div>
                </div>
                <hr className="divider" />
                <div>
                  <div className="flex flex-row justify-between">
                    <span className="text-[16px] text-hr-primary-1 font-extrabold">
                      Balance Payable
                    </span>
                    <span className="text-[15px] text-color-black font-bold">
                      {pension?.formatted_pension_balance}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PensionPreviewPage;
