import React, { useEffect, useState } from "react";
import { toggleSidebar } from "../../../redux/components/components-slice";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../../components/layouts/dashboard";
import Button from "../../../components/button";
import { useHistory } from "react-router-dom";
import FormSelect from "../../../components/form-select";
import WalletSVG from "../../../assets/svg/dashboard-wallet.svg";
import PayinSVG from "../../../assets/svg/payin.svg";
import PayoutSVG from "../../../assets/svg/payout.svg";
import VendorSVG from "../../../assets/svg/vendor-icon.svg";
import InvoiceSVG from "../../../assets/svg/invoice-icon.svg";
import { useGetSpendDashboard } from "../../../redux/dashboard/hook";
import LoadingBar from "../../../components/loader-bar";
import { useHelpMeOnboard } from "../../../redux/company/hook";
import { formatNumber } from "../../../utils/functions";
import SpendChart from "../../../components/payroll-section/dashboard/spend-chart";
import SpendOverviewChart from "../../../components/payroll-section/dashboard/spend-overview-chart";
import moment from "moment";
import SelectVendorPayOption from "../../../components/modal/spend-management/vendor/select-vendor-pay-option";

const SpendDashboardPage = () => {
  useEffect(() => {}, []);

  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.userSlice);
  const { data: dashboard, isLoading: dashboardLoading } =
    useGetSpendDashboard();
  const [donutData, setDonutData] = useState(null);
  const [spendStat, setspendStats] = useState(null);

  const [showSelectVendorPayOption, setShowSelectVendorPayOption] =
    useState(false);

  const { mutateAsync: helpMeOnboard, isLoading: onboardLoading } =
    useHelpMeOnboard();

  useEffect(() => {
    if (dashboard) {
      updatePayrollOverview();
      updatespendStats();
    }
    updatePayrollOverview();
    updatespendStats();
  }, [dashboard]);

  useEffect(() => {
    if (window.innerWidth < 851) {
      dispatch(toggleSidebar());
    }
  }, []);

  const [sortOption, setSortOption] = useState("Last 6 months");

  const sortOptions = [{ value: "Last 6 months", label: "Last 6 months" }];

  function updatePayrollOverview() {
    let data = {
      data: {
        labels: ["Inflow", "Outflow", "Charges"],
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
        datasets: [
          {
            cutout: "70%",
            label: "# of Votes",
            data: [dashboard?.inflows, dashboard?.outflows, dashboard?.charges],
            backgroundColor: [
              "rgba(0, 25, 132, 1)",
              "rgba(247, 148, 33, 1)",
              "rgba(68, 175, 105, 1)",
            ],
          },
        ],
      },
      legends: [
        {
          title: "Inflows",
          color: "bg-secondary-2",
        },
        {
          title: "Outflows",
          color: "bg-[#f79421]",
        },
        {
          title: "Charges",
          color: "bg-[#0d5225]",
        },
      ],
      total: formatNumber(
        parseInt(dashboard?.inflows) +
          parseInt(dashboard?.outflows) +
          parseInt(dashboard?.charges),
        2
      ),
    };

    setDonutData(data);
  }

  function updatespendStats() {
    let data = {
      data: {
        labels: ["", "Sep", "Oct", "Nov", "Dec", "Jan", "Mar"],
        datasets: [
          {
            label: "Inflow",
            data: [],
            fill: true,
            borderWidth: 2,
            // backgroundColor: '',
            borderColor: "rgb(0, 25, 132)",
            tension: 0.5,
            pointRadius: 0.5,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 3,
            pointBorderWidth: 0,
            pointBorderColor: "rgb(153, 6, 26)",
            pointHoverBorderColor: "rgb(239, 160, 11)",
          },
          {
            label: "Outflow",
            data: [],
            fill: true,
            borderWidth: 2,
            // backgroundColor: '',
            borderColor: "rgb(239, 11, 11)",
            tension: 0.5,
            pointRadius: 0.5,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 3,
            pointBorderWidth: 0,
            pointBorderColor: "rgb(239, 11, 11)",
            pointHoverBorderColor: "rgb(239, 11, 11)",
          },
        ],
      },
      options: {
        options: {
          plugins: {
            tooltip: {
              backgroundColor: "rgb(236,243,251)",
              borderColor: "rgb(236,243,251)",
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 500000,
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return formatNumber(parseInt(value), 2);
              },
            },
          },
        },
      },
    };

    dashboard?.lastSixMonths?.reverse();

    let amounts = [];
    let months = [];

    dashboard?.lastSixMonths?.forEach((element) => {
      amounts.push(element.amount);
      months.push(moment(element.month + "-01").format("MMM, YY"));
    });

    data.data.labels = months;
    data.data.datasets[0].data = amounts;

    dashboard?.lastSixMonthsOutflows?.reverse();

    let amounts2 = [];
    let months2 = [];

    dashboard?.lastSixMonthsOutflows?.forEach((element) => {
      amounts2.push(element.amount);
      months2.push(moment(element.month).format("MMM, YYYY"));
    });

    data.data.labels = months2;
    data.data.datasets[1].data = amounts2;

    setspendStats(data);
  }

  const onboard = () => {
    helpMeOnboard();
  };

  return (
    <DashboardLayout title="Spend Dashboard">
      {showSelectVendorPayOption ? (
        <SelectVendorPayOption
          isOpen={showSelectVendorPayOption}
          closeModal={() => setShowSelectVendorPayOption(false)}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4">
        <div className="flex flex-col">
          <span className="header-3"> Welcome {user?.first_name},</span>
          <span className="text-[14px] text-gray-500">
            Need help getting started? Let’s help you onboard
          </span>
        </div>
      </div>
      {dashboardLoading ? (
        <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[15px]">
          <LoadingBar loading={dashboardLoading} />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col bg-color-white md:flex-row justify-between mt-[25px] h-fit rounded-md border">
            <div className="flex flex-col p-4 md:w-[70%] w-full md:border-r border-r-0 gap-4 min-h-[320px] border-gray-200">
              <div className="flex flex-row w-full border rounded-md py-3 md:h-[100px] h-fit border-gray-200 pr-0 md:gap-3 justify-center md:px-4 px-2">
                <div className="flex flex-col items-center justify-center gap-2 w-1/2 md:border-r border-gray-300">
                  <span className="font-bold text-[10px] text-color-gray flex flex-col text-center">
                    WALLET BALANCE
                  </span>
                  <span className="font-semibold text-[18px]">
                    {dashboard?.walletBalance
                      ? formatNumber(dashboard.walletBalance, 2)
                      : "0.00"}
                  </span>
                </div>
                <div className="flex flex-col justify-center items-center gap-2 w-1/2 border-gray-300">
                  <span className="font-bold text-[10px] text-color-gray flex flex-col text-center">
                    SCHEDULED PAYMENTS
                  </span>
                  <span className="font-semibold text-[18px]">
                    {dashboard?.scheduled_payments
                      ? formatNumber(dashboard.scheduled_payments, 2)
                      : "0.00"}
                  </span>
                </div>
              </div>
              <div className="w-[100%] md:w-[100%] flex flex-col !min-h-[200px] gap-2">
                <div className="flex flex-row justify-between items-center pl-4 pr-3">
                  <span className="p2">Spend stats</span>
                  <div className="hidden md:flex flex-row items-center gap-2">
                    <div className="bg-spend-primary-1 w-[10px] h-[10px] rounded-[10px] pr-2"></div>
                    <span className="text-[13px] font-semibold">Inflows</span>
                    <div className="bg-[#ef0b0b] w-[10px] h-[10px] rounded-[10px] pr-2 ml-4"></div>
                    <span className="text-[13px] font-semibold">Outflow</span>
                  </div>
                  <div className="flex items-center h-[27px]">
                    <FormSelect
                      defaultValue={sortOption}
                      options={sortOptions}
                      onChange={(selected) => {
                        setSortOption(selected);
                      }}
                      type="default"
                      extraClass="h-[27px] w-[140px] !text-[12px]"
                    />
                  </div>
                </div>
                <div className="w-[100%]">
                  {spendStat ? <SpendChart data={spendStat} /> : null}
                </div>
              </div>
            </div>
            <div className="flex flex-col md:w-[30%] w-full">
              <hr className="md:hidden divider md:-mt-2 md:mb-1" />
              <div className="flex min-w-full h-1/5 ">
                <div className="flex items-center justify-center min-w-[30%] min-h-[60px]">
                  <img src={WalletSVG} alt="" height={"50px"} width={"50px"} />
                </div>
                <div className="flex flex-col items-center justify-center gap-2 min-w-[70%]">
                  <span className="font-bold text-[10px] leading-[12px] text-color-gray flex flex-col text-center">
                    CUSTOMERS
                  </span>
                  <span className="font-semibold text-[15px]">
                    {dashboard?.customer_count
                      ? formatNumber(dashboard.customer_count, 2)
                      : "0"}
                  </span>
                </div>
              </div>
              <hr className="divider md:-mt-2 md:-mb-1" />
              <div className="flex min-w-full h-1/5 ">
                <div className="flex items-center justify-center min-w-[30%] min-h-[60px]">
                  <img src={WalletSVG} alt="" height={"50px"} width={"50px"} />
                </div>
                <div className="flex flex-col items-center justify-center gap-2 min-w-[70%]">
                  <span className="font-bold text-[10px] leading-[12px] text-color-gray flex flex-col text-center">
                    VENDORS
                  </span>
                  <span className="font-semibold text-[15px]">
                    {dashboard?.vendor_count
                      ? formatNumber(dashboard.vendor_count, 2)
                      : "0"}
                  </span>
                </div>
              </div>
              <hr className="divider md:-mt-2 md:-mb-1" />
              <div className="flex min-w-full  h-1/5 ">
                <div className="flex items-center justify-center min-w-[30%] min-h-[60px]">
                  <img src={PayoutSVG} alt="" height={"50px"} width={"50px"} />
                </div>
                <div className="flex flex-col items-center justify-center gap-2 min-w-[70%]">
                  <span className="font-bold text-[10px] leading-[12px] text-color-gray flex flex-col text-center">
                    TOTAL PAYIN
                  </span>
                  <span className="font-semibold text-[15px]">
                    {dashboard?.inflows
                      ? formatNumber(dashboard.inflows, 2)
                      : "0.00"}
                  </span>
                </div>
              </div>
              <hr className="divider md:-mt-2 md:-mb-1" />
              <div className="flex min-w-full h-1/5 ">
                <div className="flex items-center justify-center min-w-[30%] min-h-[60px]">
                  <img src={PayinSVG} alt="" height={"50px"} width={"50px"} />
                </div>
                <div className="flex flex-col items-center justify-center gap-2 min-w-[70%]">
                  <span className="font-bold text-[10px] leading-[12px] text-color-gray flex flex-col text-center">
                    TOTAL PAYOUT
                  </span>
                  <span className="font-semibold text-[15px]">
                    {dashboard?.outflows
                      ? formatNumber(dashboard.outflows, 2)
                      : "0.00"}
                  </span>
                </div>
              </div>
              <hr className="divider md:-mt-2 md:-mb-1" />
              <div className="flex flex-col bg-color-white md:p-4 pt-4 lg:flex-row justify-between md:min-h-[250px] min-h-[250px] w-full mt-3">
                <SpendOverviewChart data={donutData} />
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col w-full gap-4 mt-2">
            <div className="flex flex-col bg-color-white p-4 justify-between md:min-h-[200px] md:max-h-[200px] min-h-[200px] md:w-1/3 w-full rounded-md border">
              <div className="flex flex-col items-start justify-start w-full min-h-[60px] gap-3">
                <img src={InvoiceSVG} alt="" height={"50px"} width={"50px"} />
                <div className="w-full min-h-[60px] text-[14px]">Invoices</div>
              </div>
              <div className="w-full">
                <Button
                  text="CREATE INVOICE"
                  type="button"
                  theme="primary"
                  className={`h-[35px] px-2 rounded-md  min-w-fit shadow-none`}
                  textClass={"!text-[15px]"}
                  onClick={() => {
                    history.push(`/customers/invoice`);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col bg-color-white p-4 justify-between md:min-h-[200px] md:max-h-[200px] min-h-[200px] md:w-1/3 w-full rounded-md border">
              <div className="flex flex-col items-start justify-start w-full min-h-[60px] gap-3">
                <img src={PayoutSVG} alt="" height={"50px"} width={"50px"} />
                <div className="w-full min-h-[60px] text-[14px]">
                  Purchase Orders
                </div>
              </div>
              <div className="w-full">
                <Button
                  text="CREATE PURCHASE ORDER"
                  type="button"
                  theme="primary"
                  className={`h-[35px] px-2 rounded-md  min-w-fit shadow-none`}
                  textClass={"!text-[15px]"}
                  onClick={() => {
                    history.push(`/vendors/purchase-order`);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col bg-color-white p-4 justify-between md:min-h-[200px] md:max-h-[200px] min-h-[200px] md:w-1/3 w-full rounded-md border">
              <div className="flex flex-col items-start justify-start w-full min-h-[60px] gap-3">
                <img src={VendorSVG} alt="" height={"50px"} width={"50px"} />
                <div className="w-full min-h-[60px] text-[14px]">Vendors</div>
              </div>
              <div className="w-full">
                <Button
                  text="PAY VENDOR"
                  type="button"
                  theme="primary"
                  className={`h-[35px] px-2 rounded-md  min-w-fit shadow-none`}
                  textClass={"!text-[15px]"}
                  onClick={() => setShowSelectVendorPayOption(true)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default SpendDashboardPage;
