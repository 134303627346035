import DOMPurify from 'dompurify';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../../assets/svg/close.svg';
import { formValidation } from '../../../../utils/functions';
import Button from '../../../button';
import FormInput from '../../../form-input';
import DragSVG from '../../../../assets/svg/reorder.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddSmallSVG from '../../../../assets/svg/add-icon-small.svg';
import { store } from '../../../../redux';
import { setAlert } from '../../../../redux/components/components-slice';
import FormSwitch from '../../../form-switch';
import {
  useAddExitWorkflow,
  useUpdateExitWorkflow,
} from '../../../../redux/employees/hook/exit';
import Modal from '../../modal';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  paddingRight: 16,
  paddingLeft: 16,
  margin: `0 0 20px 0`,
  background: 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  background: 'whitesmoke',
  padding: grid,
  width: '100%',
});

function AddExitWorkflow({
  isOpen,
  closeModal,
  workflowData,
  title,
  buttonText,
}) {
  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(subWorkflow[sInd], source.index, destination.index);
      const newState = [...subWorkflow];
      newState[sInd] = items;
      setSubWorkflow(newState);
    } else {
      const result = move(
        subWorkflow[sInd],
        subWorkflow[dInd],
        source,
        destination,
      );
      const newState = [...subWorkflow];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setSubWorkflow(newState.filter((group) => group.length));
    }
  }

  const [maxId, setMaxId] = useState(4);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [subWorkflow, setSubWorkflow] = useState([
    [
      {
        id: `item-${1}`,
        workflow_id: null,
        item_id: null,
        name: '',
        task: '',
        description: '',
        employee_can_remark: 1,
      },
      {
        id: `item-${2}`,
        workflow_id: null,
        item_id: null,
        name: '',
        task: '',
        description: '',
        employee_can_remark: 1,
      },
    ],
  ]);

  useEffect(() => {
    if (workflowData) {
      let newSubWorkflows = [];
      let item_id = 1;
      workflowData.sub_workflows.forEach((item) => {
        let newsubflow = {
          id: `item-${item_id}`,
          workflow_id: item.workflow_id,
          item_id: item.id,
          description: item.description,
          name: item.name,
          task: item.task,
          employee_can_remark: item.employee_can_remark,
        };
        newSubWorkflows.push(newsubflow);
        item_id++;
      });
      setSubWorkflow([newSubWorkflows]);
      setWorkflowId(workflowData.id);
      setWorkflowType('edit');
      setName(workflowData.name);
      setDescription(workflowData.description);
    }
  }, [workflowData]);

  const [remove, setRemove] = useState([]);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [workflowType, setWorkflowType] = useState('add');
  const [workflowId, setWorkflowId] = useState(null);

  const { mutateAsync: addWorkflow, isLoading: addWorkflowloading } =
    useAddExitWorkflow();
  const { mutateAsync: editWorkflow, isLoading: editWorkflowloading } =
    useUpdateExitWorkflow();

  const addStage = () => {
    let newArray = JSON.parse(JSON.stringify(subWorkflow[0]));
    newArray = [
      ...newArray,
      ...[
        {
          id: `item-${maxId + 1}`,
          item_id: null,
          name: '',
          task: '',
          company_id: null,
          workflow_id: null,
          description: '',
          employee_can_remark: 1,
        },
      ],
    ];
    setMaxId(maxId + 1);
    setSubWorkflow([newArray]);
  };

  const removeStage = (subIndex) => {
    let newArray = JSON.parse(JSON.stringify(subWorkflow[0]));

    if (newArray[subIndex].item_id !== null) {
      let removeData = remove;
      removeData.push(newArray[subIndex].item_id);
      setRemove(removeData);
    }
    let data = [...newArray];
    if (subIndex > -1) {
      // only splice array when item is found
      data.splice(subIndex, 1); // 2nd parameter means remove one item only
      setSubWorkflow([[...data]]);
    }
  };

  const submitForm = async () => {
    if (subWorkflow && subWorkflow[0].length > 0) {
      let newSubWorkflows = [];
      subWorkflow[0].forEach((item) => {
        if (item.name) {
          let newsubflow = {
            id: item.item_id,
            workflow_id: item.workflow_id,
            name: item.name,
            task: item.task,
            description: item.description,
            employee_can_remark: item.employee_can_remark,
          };
          newSubWorkflows.push(newsubflow);
        }
      });
      if (newSubWorkflows.length > 0) {
        if (workflowType === 'add') {
          let payload = {
            name,
            type: 'exit',
            description,
            sub_workflows: newSubWorkflows,
          };
          await addWorkflow(payload).then(() => {
            closeModal();
          });
        } else if (workflowType === 'edit') {
          let payload = {
            name,
            description,
            sub_workflows: newSubWorkflows,
            type: 'exit',
            remove: remove.join(','),
          };
          let editPayload = {
            workflow_id: workflowId,
            data: payload,
          };
          await editWorkflow(editPayload).then(() => {
            closeModal();
          });
        }
      } else {
        store.dispatch(
          setAlert(true, 'error', 'You must have at least one stage'),
        );
      }
    } else {
      store.dispatch(
        setAlert(true, 'error', 'You must have at least one stage'),
      );
    }
  };

  return (
    <Modal
      scrollable={true}
      className='!max-w-[600px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <form className='form pb-6'>
          <div className='pl-6 pr-6 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>{title}</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col max-h-fit mx-8'>
            <div className='flex flex-col border bg-highlight p-2 rounded-md'>
              <FormInput
                label='Workflow Name'
                name='name'
                type='text'
                placeholder='Enter workflow name'
                value={name}
                onInput={(e) => setName(e.target.value)}
                readOnly={addWorkflowloading || editWorkflowloading}
                className={'bg-white'}
              />
              <FormInput
                label={'Workflow Description'}
                name='description'
                placeholder='Enter description'
                value={description}
                onInput={(e) => setDescription(e.target.value)}
                readOnly={addWorkflowloading || editWorkflowloading}
                className={'bg-white'}
              />
            </div>
            <div className='flex flex-col mt-3'>
              <span className='header-4 text-hr-primary-1'>Exit Stages</span>
              <span className='p3 text-[12px] text-gray-500'>
                Add Exit Stages. Drag and Drop to change order.
              </span>
            </div>
            <hr className='divider mt-2' />
            <div className='flex rounded'>
              <DragDropContext onDragEnd={onDragEnd}>
                {subWorkflow.map((el, ind) => (
                  <Droppable key={ind} droppableId={`${ind}`}>
                    {(provided, snapshot) => (
                      <div
                        className='p-4 rounded'
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        {el.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className='border-r-4 border-r-primary-3 p-4 pt-3 pb-2 rounded-[5px]'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                              >
                                <div className='flex flex-col justify-around flex-wrap'>
                                  <div className='flex flex-row items-center justify-between md:w-[100%] w-full'>
                                    <div className='flex flex-row gap-2 items-center'>
                                      <img
                                        src={DragSVG}
                                        alt={'Refresh'}
                                        className={
                                          'h-[18px] w-[22px] mr-3 cursor-grabbing'
                                        }
                                      />
                                      <FormInput
                                        name={index + '_stage_name'}
                                        placeholder={'Stage Name'}
                                        type='text'
                                        readOnly={false}
                                        value={item.name}
                                        onInput={(e) => {
                                          let newArray = JSON.parse(
                                            JSON.stringify(subWorkflow[ind]),
                                          );
                                          newArray[index].name = e.target.value;
                                          setSubWorkflow([newArray]);
                                        }}
                                        className='!h-[30px] md:w-[250px] w-[225px] text-[13px] border-1 border-gray-300'
                                      />
                                    </div>

                                    <div
                                      className='italic text-red-500 text-[12px] font-semibold cursor-pointer hover:underline'
                                      onClick={() => {
                                        removeStage(index);
                                      }}
                                    >
                                      Remove
                                    </div>
                                  </div>
                                  <div className='flex flex-row items-center justify-between md:w-[100%] w-full'>
                                    <FormInput
                                      label={'Employee task'}
                                      name={index + '_stage_task'}
                                      placeholder={'Stage Task'}
                                      type='text'
                                      readOnly={false}
                                      value={item.task}
                                      onInput={(e) => {
                                        let newArray = JSON.parse(
                                          JSON.stringify(subWorkflow[ind]),
                                        );

                                        newArray[index].task = e.target.value;
                                        setSubWorkflow([newArray]);
                                      }}
                                      className='!h-[30px] md:w-[375px] w-[330px] text-[13px] border-1 border-gray-300'
                                    />
                                  </div>
                                  <div className='flex flex-row items-center justify-between md:w-[100%] w-full text-[14px]'>
                                    <FormInput
                                      label={'Brief Description'}
                                      name={index + '_stage_description'}
                                      placeholder={'Stage Description'}
                                      type='text'
                                      readOnly={false}
                                      value={item.description}
                                      onInput={(e) => {
                                        let newArray = JSON.parse(
                                          JSON.stringify(subWorkflow[ind]),
                                        );

                                        newArray[index].description =
                                          e.target.value;
                                        setSubWorkflow([newArray]);
                                      }}
                                      className='!h-[30px] md:w-[375px] w-[330px] text-[13px] border-1 border-gray-300'
                                    />
                                  </div>
                                  <div className='flex flex-row  justify-between items-center md:w-[100%] w-full'>
                                    <div className='w-full md:w-[60%] mt-2 text-[13px]'>
                                      <FormSwitch
                                        label={'Employee Can Remark?'}
                                        name={'switch-edit-mode'}
                                        checked={
                                          item.employee_can_remark == 1
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          let newArray = JSON.parse(
                                            JSON.stringify(subWorkflow[ind]),
                                          );

                                          newArray[index].employee_can_remark =
                                            item.employee_can_remark == 1
                                              ? 0
                                              : 1;
                                          setSubWorkflow([newArray]);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </DragDropContext>
            </div>
            <div className='flex flex-row items-center gap-2 mt-3 justify-left w-full cursor-pointer'>
              <img
                src={AddSmallSVG}
                alt={'add_stage'}
                className={'h-[15px] w-[15px]'}
              />
              <span
                className='p4-medium hover:underline hover:italic hover:font-bold text-primary-3 duration-300 text-[13px]'
                onClick={() => addStage(true)}
              >
                Add New Stage
              </span>
            </div>
            <hr className='divider mt-3 ' />
            <div className='w-full'>
              <Button
                text={buttonText}
                theme={'third'}
                type='submit'
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
                loading={addWorkflowloading || editWorkflowloading}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddExitWorkflow;
