import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import { PAGINATION_DEFAULT } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import AddPerformanceWorkflow from "../modal/hr-section/performance/add-workflow";
import Button from "../button";
import {
  useDeleteExitQuestion,
  useGetExitQuestions,
} from "../../redux/employees/hook/exit";
import AddExitQuestions from "../modal/hr-section/employee-exit/add-exit-questions";
import UpdateExitQuestions from "../modal/hr-section/employee-exit/update-question";

const ExitInterviewQuestionsTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });

  const { data: exit_questions } = useGetExitQuestions(
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const [questionData, setQuestionData] = useState(null);
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const [tableType, setTableType] = useState("exit-questions");

  const switchFunction = () => {
    if (tableType === "exit-questions") {
      setTableType("table");
    } else {
      setTableType("exit-questions");
    }
  };

  const { mutateAsync: deleteQuestion, isLoading: deleteQuestionLoading } =
    useDeleteExitQuestion();
  const [showAddQuestions, setShowAddQuestions] = useState(null);
  const [showUpdateQuestions, setShowUpdateQuestions] = useState(null);

  const history = useHistory();

  const tableConfig = {
    headers: ["Date Added", "Question", "Question Type"],
    keys: ["created", "question", "question_type"],
    mobileHeader: {
      left: {
        title: "Question",
        key: "question",
      },
      right: {
        title: "Question Type",
        key: "question_type",
      },
    },
  };

  const actionConfig = [
    {
      name: "Edit",
      color: "green",
      action: (id) => {
        let index = exit_questions.data.findIndex((item) => item.id === id);
        setTitle("Update Exit Questions");
        setButtonText("Update Question");
        setQuestionData(exit_questions.data[index]);
        setShowUpdateQuestions(true);
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = exit_questions.data.findIndex((item) => item.id === id);
        deleteQuestion(exit_questions.data[index].id);
      },
    },
  ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showUpdateQuestions ? (
        <UpdateExitQuestions
          isOpen={showUpdateQuestions}
          questionData={questionData}
          closeModal={() => setShowUpdateQuestions(false)}
          title={title}
        />
      ) : null}
      {showAddQuestions ? (
        <AddExitQuestions
          isOpen={showAddQuestions}
          closeModal={() => setShowAddQuestions(false)}
          title={title}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Exit Questions
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="New Questions"
              type="button"
              theme="primary"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              leftIcon={"add"}
              onClick={() => {
                setTitle("Add Exit Questions");
                setButtonText("Save Questions");
                setQuestionData(null);
                setShowAddQuestions(true);
              }}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2 mb-1" />
      <DataTable
        data={exit_questions}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        tableType={tableType}
        switchFunction={switchFunction}
        layoutSwitch={true}
        searchData={true}
      />
    </div>
  );
};

export default ExitInterviewQuestionsTab;
