import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Button from "../../../components/button";
import FormInput from "../../../components/form-input";
import FormSelect from "../../../components/form-select";
import EmployeeRegistrationLayout from "../../../components/layouts/employee-registration";
import EmployeeVerification from "../../../components/modal/employees/employee-verification";
import { formValidation } from "../../../utils/functions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { countriesAndState, AllCountries } from "../../../constants/countries";
import Logo from "../../../components/logo";

function CompleteProfileInformationPage() {
  useEffect(() => {}, []);

  const [dob, setDob] = useState();
  const [empAddress, setEmpAddress] = useState();
  const [empCity, setEmpCity] = useState();
  const [gender, setGender] = useState("Male");
  const [maritalStatus, setMaritalStatus] = useState("single");
  const [country, setCountry] = useState("Nigeria");
  const [state, setState] = useState("Lagos");
  const [stateOptions, setStateOptions] = useState([]);
  const [showVerification, setShowVerification] = useState(true);
  const { employee } = useSelector((state) => state.employeeSlice);
  const [employeeId, setEmployeeId] = useState();
  const [employmentDate, setEmploymentDate] = useState();
  const { token } = useParams();

  useEffect(() => {
    if (employee) {
      setEmployeeId(employee.id);
      setEmploymentDate(employee.date_joined);
    }
  }, [employee]);

  const history = useHistory();

  const {
    register,
    formState: { isValid },
    errors,
  } = useForm({
    mode: "onChange",
  });
  const [loading, setLoading] = useState(false);

  const genderOptions = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
  ];

  const maritalOptions = [
    {
      label: "Single",
      value: "single",
    },
    {
      label: "Married",
      value: "married",
    },
  ];

  useEffect(() => {
    if (country) {
      let getStates = countriesAndState.find((a) => a.name === country);

      if (getStates) {
        let computedStates = [];

        getStates.states.map((item, index) => {
          computedStates.push({ label: item, value: item });
        });

        setStateOptions(computedStates);
      }
    }
  }, [country]);

  const submitForm = async () => {
    setLoading(true);
    const first_name = employee?.first_name;
    const last_name = employee?.last_name;
    const email = employee?.email;
    const mobile = employee?.mobile;
    const staff_number = employee?.staff_number;
    const job_position = employee?.job_position;
    const street_address = empAddress;
    const city = empCity;
    const empCountry = country;
    const empState = state;
    const empDob = dob;
    const empGender = gender;
    const dateJoined = employmentDate;

    let payload = {
      employeeId: employeeId,
      first_name: first_name,
      last_name: last_name,
      email: email,
      mobile: mobile,
      staff_number: staff_number,
      job_position: job_position,
      street_address: street_address,
      city: city,
      country: empCountry,
      state: empState,
      dob: empDob,
      gender: empGender,
      date_joined: dateJoined,
      marital_status: maritalStatus,
    };
    history.push(
      "/employees/single-employee-onboarding/employees/" + token,
      payload
    );
    setLoading(false);
  };

  return (
    <EmployeeRegistrationLayout
      step="1"
      company_name={employee?.company_name}
      logo={employee?.logo}
    >
      <div className="flex flex-col justify-center w-full md:pt-5 min-h-fit overflow-y-auto gap-4">
        <div className="flex md:min-h-fit flex-col w-full max-w-[600px] px-5 md:px-0">
          {showVerification ? (
            <EmployeeVerification
              isOpen={showVerification}
              closeModal={() => setShowVerification(false)}
            />
          ) : null}
          <form className="form min-h-fit">
            <div className="w-full text-center flex flex-col pb-[25px] pt-2">
              <span className="header-2-regular text-secondary-2">
                Profile Information
              </span>
              <span className="header-5-regular text-secondary-2">
                View your personal information and enter your contact
                information
              </span>
            </div>
            <hr className="divider -mt-2" />
            <div className="md:hidden flex flex-col md:w-1/2 w-full md:pr-2">
              <span className="header-3 text-color-gray">Company Name</span>
              <span className="header-4 mt-1 text-color-black font-bold">
                {employee?.company_name}
              </span>
              <hr className="divider" />
            </div>
            {employee ? (
              <div className="flex flex-col min-h-fit overflow-auto mb-[10px]">
                <div className="flex flex-row w-full justify-between">
                  <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                    <span className="header-5 text-color-gray">First Name</span>
                    <span className="mt-1 text-color-black font-bold">
                      {employee?.first_name}
                    </span>
                  </div>
                  <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                    <span className="header-5 text-color-gray">Last name</span>
                    <span className="mt-1 text-color-black font-bold">
                      {employee?.last_name}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row w-full justify-between mt-3 gap-3">
                  <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                    <span className="header-5 text-color-gray">Email</span>
                    <span className="mt-1 text-color-black font-bold">
                      {employee?.email}
                    </span>
                  </div>
                  <div className="flex flex-col md:w-1/2 w-full md:pr-2">
                    <span className="header-5 text-color-gray">
                      Phone number
                    </span>
                    <span className="mt-1 text-color-black font-bold">
                      {employee?.mobile}
                    </span>
                  </div>
                </div>
                <hr className="divider" />
                <div className="flex md:flex-row flex-col w-full justify-between">
                  <div className="w-full md:w-1/2 md:pr-2">
                    <FormInput
                      label="Date Of Birth"
                      name="dob"
                      type="date"
                      onInput={(e) => setDob(e.target.value)}
                      inputRef={register(formValidation("text", true))}
                      error={errors.dob}
                      errorMessage={errors.dob && errors.dob.message}
                    />
                  </div>
                  <div className="w-full md:w-1/2 md:pl-2">
                    <FormSelect
                      value={gender}
                      options={genderOptions}
                      onChange={(selected) => {
                        setGender(selected);
                      }}
                      label="Gender"
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col w-full justify-between">
                  <div className="w-full md:w-1/2 md:pr-2">
                    <FormSelect
                      value={maritalStatus}
                      options={maritalOptions}
                      onChange={(selected) => {
                        setMaritalStatus(selected);
                      }}
                      label="Marital Status"
                    />
                  </div>
                  <div className="w-full md:w-1/2 md:pl-2">
                    <FormSelect
                      defaultValue={country}
                      options={AllCountries}
                      onChange={(selected) => {
                        setCountry(selected);
                      }}
                      label="Country"
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col w-full justify-between">
                  <div className="w-full md:w-1/2 md:pr-2">
                    {country == "Nigeria" ? (
                      <FormSelect
                        defaultValue={state}
                        options={stateOptions}
                        onChange={(selected) => {
                          setState(selected);
                        }}
                        label="State"
                      />
                    ) : (
                      <FormInput
                        label="State"
                        name="state"
                        type="text"
                        value={state}
                        onInput={(e) => setState(e.target.value)}
                        error={errors.state}
                        errorMessage={errors.state && errors.state.message}
                      />
                    )}
                  </div>
                  <div className="w-full md:w-1/2 md:pl-2">
                    <FormInput
                      label="City"
                      name="city"
                      type="text"
                      readOnly={loading}
                      onInput={(e) => setEmpCity(e.target.value)}
                      inputRef={register(formValidation("text", true))}
                      error={errors.city}
                      errorMessage={errors.city && errors.city.message}
                    />
                  </div>
                </div>
                <FormInput
                  label="Street Address"
                  name="street_address"
                  type="text"
                  readOnly={loading}
                  onInput={(e) => setEmpAddress(e.target.value)}
                  inputRef={register(formValidation("text", true))}
                  error={errors.street_address}
                  errorMessage={
                    errors.street_address && errors.street_address.message
                  }
                />
                <div className="w-full mt-[20px]">
                  <Button
                    text="Continue"
                    className={"!bg-secondary-2"}
                    type="submit"
                    loading={loading}
                    onClick={() => submitForm()}
                  />
                </div>
              </div>
            ) : null}
          </form>
        </div>
        <div
          className={
            "md:hidden flex items-center justify-center text-color-black gap-2"
          }
        >
          Powered By:{" "}
          <a href="https://www.ropay.africa" target={"_blank"} rel="noreferrer">
            <Logo width="80px" height="40px" blue />
          </a>
        </div>
      </div>
    </EmployeeRegistrationLayout>
  );
}

export default CompleteProfileInformationPage;
