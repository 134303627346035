import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import FormSwitch from '../../../form-switch';
import { useGetEmployeeSummary } from '../../../../redux/employees/hook';
import { useSubscribe } from '../../../../redux/human-resources/hook/subscription';
import Modal from '../../modal';
import { formatNumber } from '../../../../utils/functions';

function SelectSubscriptionPlan({ isOpen, closeModal, subscriptionData }) {

  const { mutateAsync: subscribe, isLoading: subscribeLoading } =
    useSubscribe();

  const [autoRenew, setAutoRenew] = useState(false);
  const [billingCycle, setBillingCycle] = useState('monthly');

  const { data: getEmployeeSummary } = useGetEmployeeSummary();

  const submitForm = async () => {
    let payload = {
      payment_plan_id:
        billingCycle === 'monthly'
          ? subscriptionData?.payment_plans[0].id
          : subscriptionData?.payment_plans[1].id,
      auto_renew: autoRenew ? 1 : 0,
    };

    await subscribe(payload).then(() => {
      closeModal();
    });
  };

  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col overflow-auto'>
          <form className='form'>
            <div className='pl-8 pr-8 pt-4'>
              <div className='flex flex-row justify-between items-center'>
                <span className='header-4'>People Subscription Plan</span>
                <div className='cursor-pointer' onClick={() => closeModal()}>
                  <img src={CloseSVG} alt='close' />
                </div>
              </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col'>
              <div className='flex flex-col md:mx-14 items-center p-2'>
                <span className='header-3'>
                  {subscriptionData?.package_name}
                </span>
                <span className='p5 text-center'>
                  {'Save more on annual payment plan'}
                </span>

                <div className='flex flex-row items-center mt-3 gap-1'>
                  <Button
                    text='Monthly Plan'
                    type='button'
                    className='!h-[35px] px-3 min-w-[160px] border-1'
                    textClass='!text-[12px]'
                    theme={billingCycle === 'monthly' ? 'primary' : 'secondary'}
                    onClick={() => setBillingCycle('monthly')}
                  />
                  <Button
                    text='Annual Plan'
                    type='button'
                    className='!h-[35px] px-3 min-w-[160px] border-1'
                    textClass='!text-[12px]'
                    theme={billingCycle === 'annual' ? 'primary' : 'secondary'}
                    onClick={() => setBillingCycle('annual')}
                  />
                </div>
              </div>
              
              <hr className='divider' />
              <div className='flex flex-col md:mx-14 items-center p-2'>
                <span className='p4 text-center'>
                  {'TOTAL ACTIVE EMPLOYEES'}
                </span>
                <span className='header-4'>{getEmployeeSummary?.active}</span>
              </div>
              <hr className='divider' />
              <div className='flex flex-col md:mx-14 items-center p-2'>
                <span className='p4 text-center'>
                  {billingCycle === 'monthly' ? 'MONTHLY' : 'ANNUAL'}
                  {' PRICE'}
                </span>
                <span className=' header-4'>
                  {billingCycle === 'monthly' ?
                    <>
                      {getEmployeeSummary?.active < 11 ?
                      `${formatNumber(10000, 2)}`
                      : getEmployeeSummary?.active < 26 ?
                      `${formatNumber(20000, 2)}`
                      :
                      `${formatNumber(50000, 2)}`
                      }
                    </>
                    : 
                    <>
                      {getEmployeeSummary?.active < 11 ?
                      `${formatNumber(100000, 2)}`
                      : getEmployeeSummary?.active < 26 ?
                      `${formatNumber(200000, 2)}`
                      :
                      `${formatNumber(500000, 2)}`
                      }
                    </>
                  }
                </span>
              </div>
              <hr className='divider' />
              <div className='w-full md:w-[70%] mt-2 px-4'>
                <FormSwitch
                  label={'Auto Renew'}
                  name={'switch-auto-renew'}
                  checked={autoRenew}
                  onClick={() => setAutoRenew(!autoRenew)}
                  value={autoRenew}
                />
              </div>
              <div className='w-full mt-[20px] pb-4 px-4'>
                <Button
                  text={'SUBSCRIBE'}
                  type='button'
                  loading={subscribeLoading}
                  onClick={() => {
                    submitForm();
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default SelectSubscriptionPlan;
