import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PAGINATION_DEFAULT } from "../../../../utils/constants";
import Button from "../../../button";
import DataTable from "../../../datatable";
import {
  useGetBranchTeams,
  useRemoveTeamsFromBranch,
} from "../../../../redux/human-resources/hook/branches";
import AddTeamsToBranch from "../../../modal/hr-section/branches/add-branch-team";

const BranchTeamTab = () => {
  useEffect(() => {}, []);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    search: "",
  });
  const { id } = useParams();

  const { data: branch_teams } = useGetBranchTeams(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.search
  );

  const [showAddTeamToBranch, setShowAddTeamToBranch] = useState(false);
  const { mutateAsync: removeFromBranch } = useRemoveTeamsFromBranch();

  const removeTeamsFromGroup = (id) => {
    let index = branch_teams.teams.data.findIndex((item) => item.id == id);
    let payload = {
      branch: branch_teams.id,
      team_ids: branch_teams.teams.data[index].id,
    };
    removeFromBranch(payload);
  };

  const tableConfig = {
    headers: ["Date Added", "Branch Name", "Team Name"],
    keys: ["created", "branch_name", "team_name"],
    mobileHeader: {
      left: {
        title: "Date Added",
        key: "created",
      },
      right: {
        title: "Team Name",
        key: "name",
      },
    },
  };

  const actionConfig = [
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        removeTeamsFromGroup(id);
      },
    },
  ];

  // const bulkActionConfig = [
  //   {
  //     label: 'Remove',
  //     value: 'active',
  //     action: () => {},
  //   },
  // ];

  return (
    <div className="flex flex-col bg-white rounded">
      {showAddTeamToBranch ? (
        <AddTeamsToBranch
          isOpen={showAddTeamToBranch}
          branchData={id}
          closeModal={() => setShowAddTeamToBranch(false)}
        />
      ) : null}
      <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
        <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Teams
        </div>
        <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
          <div className="md:w-fit w-[100%]">
            <Button
              text="Add Teams To Branch"
              type="submit"
              className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
              textClass={"!text-[11px]"}
              onClick={() => setShowAddTeamToBranch(true)}
            />
          </div>
        </div>
      </div>
      <hr className="divider mt-2" />
      <DataTable
        data={branch_teams}
        tableConfig={tableConfig}
        actionConfig={actionConfig}
        updatePagination={(data) => setPagination(data)}
        computedActions={true}
        searchData={true}
      />
    </div>
  );
};

export default BranchTeamTab;
