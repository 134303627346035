import React from 'react';
import CloseSVG from '../../../assets/svg/close.svg';
import FormInput from '../../form-input';
import Modal from '../modal';
import { StatusText } from '../../fragments/status-text';
import { payrollHistoryStatusConfig } from '../../../utils/constants';
import { formatNumber } from '../../../utils/functions';

function ReimbursementDetailedView({
  isOpen,
  closeModal,
  viewData
}) {

  return (
    <Modal
      scrollable={true}
      className='!max-w-[700px] !rounded-none'
      isOpen={isOpen}
      centered={true}
    >
      <div className='flex flex-col overflow-auto'>
        <div className='form'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4 font-bold'>
                Reimbursement Payment Summary
              </span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col md:flex-col w-full justify-between'>
              {viewData ? (
                <>
                    <div className='flex gap-4 items-center justify-between'>
                        <div className='flex flex-col'>
                            <span className='h4-regular text-color-gray'>
                                Employee name
                            </span>
                            <span className='h4-regular text-color-black-2'>
                                {viewData.first_name}{' '}
                                {viewData.last_name}
                            </span>
                        </div>
                        <StatusText
                            code={viewData.status}
                            statusConfig={payrollHistoryStatusConfig}
                        />
                    </div>
                    <span className='h4-regular text-color-gray mt-2'>
                        Bank Information
                    </span>
                    <span className='h4-regular text-color-black-2'>
                        Bank Name: {viewData.bank} <br />
                        Account Number: {viewData.account_number}
                        <br />
                    </span>
                    <hr className='divider' />
                    <div className='flex flex-col md:flex-row justify-between'>
                        <div className='w-full md:w-1/2 md:pr-2'>
                        <FormInput
                            label={'Expenses Amount'}
                            name='expenses_amount'
                            type='text'
                            value={formatNumber(viewData.amount, 2)}
                            readOnly
                        />
                        </div>
                        <div className='w-full md:w-1/2 md:pr-2'>
                        <FormInput
                            label='Fee'
                            name='net_pay'
                            type='text'
                            readOnly
                            value={formatNumber(viewData?.trans_cost, 2)}
                        />
                        </div>
                    </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ReimbursementDetailedView;
