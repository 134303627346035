import { useMutation, useQuery } from '@tanstack/react-query';
import { store } from '../..';
import { queryClient } from '../../..';
import { setAlert, setIsLoading } from '../../components/components-slice';
import { employeeBonuses } from './../../../api/employees/bonuses';
import moment from 'moment';
import { formatNumber } from '../../../utils/functions';

export function useGetEmployeeBonuses(limit, page, statusFilter, search) {
  return useQuery(
    ['employee-bonuses', { limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeBonuses.getEmployeeBonuses({
        limit,
        page,
        statusFilter, 
        search,
      });
    },
    {
      select(data) {
        data?.data?.forEach((bonus) => {
          bonus.amount = parseFloat(bonus.amount);
          bonus.formatted_amount = formatNumber(parseFloat(bonus.amount).toFixed(2), 2);
          bonus.created = moment(bonus.created).format('MMMM D, YYYY');
          bonus.formatted_month = moment(bonus.month).format('MMMM, YYYY');
          bonus.employeeName = `${bonus.first_name} ${bonus.last_name}`;

          switch (bonus.status) {
            case 1:
              bonus.computedActions = [0];
              break;
            case 2:
              bonus.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}


export function useGetBonusReport(start_date, end_date) {
  return useQuery(
    ['bonus-report', { start_date, end_date }],
    () => {
      return employeeBonuses.getBonusReport({ start_date, end_date });
    },
    {
      select: (data) => {
        let formatted_months = []
        data?.months?.forEach(element => {
          formatted_months.push(moment(element+'-01').format('MMM YY'));
        });
        data.formatted_months = formatted_months;
        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
    
  );
}

export function useAddBonus() {
  return useMutation(
    (payload) => {
      return employeeBonuses.addBonus(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-bonuses');
        store.dispatch(setAlert(true, 'success', 'Bonus Added successfully'));
      },
    },
  );
}

export function useEditBonus() {
  return useMutation(
    (payload) => {
      store.dispatch(setIsLoading(true));
      return employeeBonuses.editBonus(payload);
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('employee-bonuses');
        store.dispatch(setAlert(true, 'success', 'Bonus updated successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useBulkAddBonus() {
  return useMutation(
    (payload) => {
      return employeeBonuses.bulkAddBonus(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('employee-bonuses');
        store.dispatch(setAlert(true, 'success', response.message));
      },
    },
  );
}

export function useDeleteBonus() {
  return useMutation(
    (payload) => {
      return employeeBonuses.deleteBonus(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee-bonuses');
        store.dispatch(setAlert(true, 'success', 'Bonus deleted successfully'));
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
    },
  );
}

export function useSingleGetEmployeeBonuses(id, limit, page, statusFilter) {
  return useQuery(
    ['single-employee-bonuses', { id, limit, page, statusFilter }],
    () => {
      store.dispatch(setIsLoading(true));
      return employeeBonuses.getSingleEmployeeBonuses({ id, limit, page, statusFilter });
    },
    {
      select(data) {
        data?.data?.forEach((bonus) => {
          bonus.amount = parseFloat(bonus.amount);
          bonus.created = moment(bonus.created).format('MMMM D, YYYY');
          bonus.formatted_amount = formatNumber(parseFloat(bonus.amount).toFixed(2), 2);
          bonus.formatted_month = moment(bonus.month).format('MMMM, YYYY');
          bonus.employeeName = `${bonus.first_name} ${bonus.last_name}`;

          switch (bonus.status) {
            case 1:
              bonus.computedActions = [0];
              break;
            case 2:
              bonus.computedActions = [0, 1, 2];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}
