import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../components/layouts/dashboard";
import { useParams } from "react-router-dom";
import LoadingBar from "../../../../components/loader-bar";
import Button from "../../../../components/button";
import classNames from "classnames";
import TickSVG from "../../../../assets/svg/tick.svg";
import moment from "moment";
import { EmployeeAnswerView } from "../../../../components/modal/hr-section/employee-onboarding/onboarding-answer-view";
import {
  useGetSingleEmployeeOnboarding,
  useMarkOnboardingComplete,
  useMarkOnboardingStageComplete,
} from "../../../../redux/employees/hook/onboarding";
import { Initials } from "../../../../utils/functions";
import { StatusText } from "../../../../components/fragments/status-text";
import {
  onboardingEmployeeStatusConfig,
  onboardingStatusConfig,
} from "../../../../utils/constants";
import ViewFile from "../../../../components/modal/hr-section/employee-onboarding/view-file";
import OnboardingQuestions from "../../../../components/modal/hr-section/employee-onboarding/stage-questions";

const ViewEmployeeOnboarding = () => {
  useEffect(() => {}, []);

  const { id } = useParams();

  const { data: employee_onboarding, isLoading } =
    useGetSingleEmployeeOnboarding(id);

  const [onboardingStages, setOnboardingStages] = useState(null);

  const [showFile, setshowFile] = useState(false);
  const [showViewQuestions, setshowViewQuestions] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [fileData, setFileData] = useState(null);

  const [currentData, setCurrentData] = useState(null);
  const [onboardingId, setOnboardingId] = useState(null);

  const { mutateAsync: markStage, isLoading: markStageloading } =
    useMarkOnboardingStageComplete();
  const { mutateAsync: markOnboarding, isLoading: markOnboardingloading } =
    useMarkOnboardingComplete();

  const markStageStatus = async () => {
    let stage = employee_onboarding?.workflow?.sub_workflows[currentIndex - 1];
    let payload = {
      subworkflow_id: stage?.id,
      employer_status: stage?.employer_status == 1 ? 0 : 1,
    };
    await markStage(payload);
  };

  const markOnboardingStatus = async () => {
    let payload = {
      onboarding_id: onboardingId,
      status: employee_onboarding?.status == 1 ? 0 : 1,
    };
    await markOnboarding(payload);
  };

  useEffect(() => {
    if (currentIndex) {
      if (
        employee_onboarding?.workflow &&
        employee_onboarding?.workflow.sub_workflows
      ) {
        setCurrentData(
          employee_onboarding?.workflow.sub_workflows[currentIndex - 1]
        );
      }
    }
  }, [currentIndex]);

  useEffect(() => {
    if (employee_onboarding) {
      setOnboardingId(employee_onboarding?.id);
      if (
        employee_onboarding?.workflow &&
        employee_onboarding?.workflow.sub_workflows
      ) {
        setOnboardingStages(employee_onboarding?.workflow?.sub_workflows);
      }
    }
  }, [employee_onboarding]);

  useEffect(() => {
    if (onboardingStages) {
      setCurrentData(
        employee_onboarding?.workflow?.sub_workflows[currentIndex - 1]
      );
    }
  }, [onboardingStages]);

  return (
    <DashboardLayout title="Employee Onboarding Page">
      '
      {showViewQuestions ? (
        <OnboardingQuestions
          isOpen={showViewQuestions}
          closeModal={() => setshowViewQuestions(false)}
          stage={employee_onboarding?.workflow?.sub_workflows[currentIndex - 1]}
        />
      ) : null}
      {showFile ? (
        <ViewFile
          isOpen={showFile}
          closeModal={() => setshowFile(false)}
          stage={fileData}
        />
      ) : null}
      <div className="flex flex-col gap-4 -mt-8">
        <div className="flex flex-row items-center box-shadow h-[104px]">
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <div className="flex flex-row px-[55px] justify-between  w-full">
              <div className="flex flex-row items-center">
                <div className="flex font-bold justify-center items-center h-[58px] w-[58px] rounded-[58px] border-[3px] header-3 border-secondary-2 bg-green-200">
                  {Initials(
                    employee_onboarding?.first_name,
                    employee_onboarding?.last_name
                  )}
                </div>
                <div className="flex flex-col pl-4 h-[48px] content-center justify-center">
                  <span className="p1-bold">
                    {employee_onboarding?.first_name +
                      " " +
                      employee_onboarding?.last_name}
                  </span>
                  <span className="p4 text-color-gray">
                    {employee_onboarding?.email}
                  </span>
                </div>
              </div>
              {employee_onboarding ? (
                <div className="flex flex-row items-center">
                  <StatusText
                    statusConfig={onboardingStatusConfig}
                    code={employee_onboarding?.status}
                    className={"py-2 rounded p1-bold px-3 p2-bold text-[13px]"}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="flex flex-col bg-white min-h-fit">
          <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
            <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
              Employee Onboarding Profile
            </div>
            <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
              <div className="md:w-fit w-[100%]">
                <Button
                  text={
                    employee_onboarding?.status === 1
                      ? "Mark Onboarding not Reviewed"
                      : "Mark Onboarding Reviewed"
                  }
                  type="button"
                  className={
                    employee_onboarding?.status === 1
                      ? "flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center bg-red-500"
                      : "flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                  }
                  onClick={() => {
                    markOnboardingStatus();
                  }}
                  leftIcon={employee_onboarding?.status === 1 ? null : "check"}
                  loading={markOnboardingloading}
                  textClass={"!text-[11px]"}
                />
              </div>
            </div>
          </div>
          <hr className="divider" />
          {isLoading ? (
            <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
              <LoadingBar loading={isLoading} />
            </div>
          ) : (
            <>
              {employee_onboarding ? (
                <>
                  <div className="flex md:flex-row flex-col">
                    <div className=" flex flex-col gap-4 w-full">
                      <div>
                        {isLoading ? (
                          <div className="flex flex-col w-full text-center justify-center pb-[25px] pt-[5px]">
                            <LoadingBar loading={isLoading} />
                          </div>
                        ) : (
                          <>
                            {onboardingStages ? (
                              <div className="flex flex-col md:flex-row h-full p-4 pt-1">
                                <div className="flex flex-col md:max-w-[400px] w-full p-4 pt-3 pb-3 border border-gray-400 rounded">
                                  <span className="header-4 text-color-gray mb-2">
                                    Onboarding Stages
                                  </span>
                                  {onboardingStages?.map((item, index) => (
                                    <div
                                      className={classNames(
                                        "w-full max-w-[100%] md:max-w-[450px] h-[80px] rounded-md flex flex-row items-center px-3 justify-between cursor-pointer  border-1 mb-3 duration-500",
                                        {
                                          "bg-green-50 cursor-default":
                                            index === currentIndex - 1,
                                          "hover:bg-gray-50":
                                            index !== currentIndex - 1,
                                        }
                                      )}
                                      onClick={() => {
                                        if (index !== currentIndex - 1) {
                                          setCurrentIndex(index + 1);
                                        }
                                      }}
                                      key={index}
                                    >
                                      <div className="flex flex-col">
                                        <span className="h6">{item.name}</span>
                                        <span className="p5">
                                          {item.description}
                                        </span>
                                      </div>
                                      <div className="flex flex-col">
                                        <div
                                          className={`flex items-center justify-center w-[30px] h-[30px] rounded-[30px] p-2 ${
                                            item.employee_status == 1
                                              ? "bg-[#0d5225]"
                                              : "border border-color-black"
                                          }`}
                                        >
                                          {item.employee_status == 1 ? (
                                            <img
                                              src={TickSVG}
                                              alt="tick"
                                              style={{
                                                width: "25px",
                                                height: "325px",
                                              }}
                                            />
                                          ) : (
                                            <div className="header-4">
                                              {index + 1}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div className="flex flex-col gap-2 w-[100%] md:ml-4 duration-500">
                                  <div className="border border-color-line rounded pr-4 pl-4 pt-4 pb-2 flex flex-col">
                                    {currentData ? (
                                      <>
                                        <div className="flex flex-row gap-3 items-center justify-end -mt-2">
                                          {currentData.questions &&
                                          currentData.questions.length > 0 ? (
                                            <Button
                                              text="View Questions"
                                              type="button"
                                              leftIcon={"view"}
                                              className="flex gap-2 !h-[32px] px-3 !max-w-fit"
                                              onClick={() => {
                                                setshowViewQuestions(true);
                                              }}
                                              textClass={"!text-[11px]"}
                                            />
                                          ) : null}
                                          <Button
                                            text={
                                              currentData?.employer_status == 0
                                                ? "Mark stage Reviewed"
                                                : "Mark stage not Reviewed"
                                            }
                                            type="button"
                                            className={
                                              currentData?.employer_status == 0
                                                ? "!h-[32px] p-2 px-3 w-fit hover:bg-hr-primary-1"
                                                : "!h-[32px] p-2 px-3 w-fit hover:bg-hr-primary-1 bg-red-500"
                                            }
                                            textClass="!text-[11px] !text-hr-primary-1 hover:text-white"
                                            theme="primary"
                                            loading={markStageloading}
                                            onClick={() =>
                                              markStageStatus(true)
                                            }
                                          />
                                        </div>
                                        <hr className="divider" />
                                        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full bg-highlight p-2 rounded">
                                          <div className="flex flex-col">
                                            <span className="header-5 text-color-gray">
                                              Onboarding Stage
                                            </span>
                                            <span className="text-[18px] text-color-black font-bold duration-500">
                                              {currentData?.name}
                                            </span>
                                          </div>
                                          <div className="flex flex-col">
                                            <span className="header-5 text-color-gray">
                                              Date Added
                                            </span>
                                            <span className="text-[15px] text-color-black font-bold duration-500">
                                              {moment(
                                                currentData?.created
                                              ).format("MMMM D, YYYY")}
                                            </span>
                                          </div>
                                        </div>
                                        <hr className="divider" />
                                        <div className="grid md:grid-cols-2  grid-cols-1 gap-4 w-full">
                                          <div className="flex flex-col">
                                            <span className="header-5 text-color-gray mb-2">
                                              Employee Completion Status
                                            </span>
                                            <div className="flex flex-row items-center">
                                              <StatusText
                                                statusConfig={
                                                  onboardingEmployeeStatusConfig
                                                }
                                                code={
                                                  currentData?.employee_status
                                                }
                                                className={
                                                  "py-2 rounded p1-bold px-3 p2-bold text-[13px]"
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="flex flex-col">
                                            <span className="header-5 text-color-gray mb-2">
                                              Review Status
                                            </span>
                                            <div className="flex flex-row items-center">
                                              <StatusText
                                                statusConfig={
                                                  onboardingStatusConfig
                                                }
                                                code={
                                                  currentData?.employer_status
                                                }
                                                className={
                                                  "py-2 rounded p1-bold px-3 p2-bold text-[13px]"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {currentData.upload_file == 1 ? (
                                          <>
                                            <hr className="divider" />
                                            <div className="grid md:grid-cols-1  grid-cols-1 gap-4 w-full ">
                                              <div className="flex flex-row justify-btween items-center gap-8">
                                                <div className="flex flex-col w-1/2">
                                                  <span className="header-5 text-color-gray">
                                                    Uploaded file
                                                  </span>
                                                  <span className="header-5 text-color-black">
                                                    {currentData.filename}
                                                  </span>
                                                </div>

                                                <div className="flex flex-row gap-3 items-center">
                                                  <Button
                                                    text="View File"
                                                    type="submit"
                                                    className="flex gap-2 !h-[32px] w-fit px-2 "
                                                    textClass={"!text-[11px]"}
                                                    onClick={() => {
                                                      setFileData(currentData);
                                                      setshowFile(true);
                                                    }}
                                                  />
                                                  <a
                                                    className="italic text-hr-primary-1 text-[14px]"
                                                    href={currentData.file_url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    {" "}
                                                    Download
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : null}
                                        {currentData.collect_document == 1 ? (
                                          <>
                                            <hr className="divider mt-2" />
                                            <div className="grid md:grid-cols-1  grid-cols-1 gap-4 w-full ">
                                              <div className="flex flex-row justify-btween items-center gap-8">
                                                <div className="flex flex-col w-1/2">
                                                  <span className="header-5 text-color-gray">
                                                    Collected file
                                                  </span>
                                                  <span className="header-5 text-color-black">
                                                    {currentData.document_name}
                                                  </span>
                                                </div>
                                                {currentData.requested_file ? (
                                                  <div className="flex flex-row gap-3 items-center">
                                                    <Button
                                                      text="View collected file"
                                                      type="submit"
                                                      className="!h-[32px] px-3 !max-w-fit"
                                                      onClick={() => {
                                                        setFileData(
                                                          currentData.requested_file
                                                        );
                                                        setshowFile(true);
                                                      }}
                                                      textClass="!header-6"
                                                    />
                                                    <a
                                                      className="italic text-hr-primary-1 text-[14px]"
                                                      href={
                                                        currentData
                                                          .requested_file
                                                          ?.file_url
                                                      }
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      {" "}
                                                      Download
                                                    </a>
                                                  </div>
                                                ) : (
                                                  <div className="italic text-hr-primary-1 text-[14px]">
                                                    No File Uploaded by Employee
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        ) : null}
                                        <hr className="divider mt-2" />
                                        <div className="flex flex-col gap-4 w-full md:w-[100%] duration-500">
                                          <EmployeeAnswerView
                                            stage={
                                              employee_onboarding?.workflow
                                                ?.sub_workflows[
                                                currentIndex - 1
                                              ]
                                            }
                                            title={
                                              "Employee Onboarding Answers"
                                            }
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <LoadingBar loading={isLoading} />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewEmployeeOnboarding;
