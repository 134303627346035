import React, { useEffect, useState } from "react";
import CloseSVG from "../../../../assets/svg/close.svg";
import Button from "../../../button";
import FormSelect from "../../../form-select";
import {
  useGetOnboardingStages,
  useMoveBulkRecruitmentApplicantStage,
} from "../../../../redux/human-resources/hook/recruitment";
import Modal from "../../modal";

function MoveApplicant({ isOpen, closeModal, refresh, applicant, title }) {
  useEffect(() => {}, []);
  // const history = useHistory();
  const [stage, setStage] = useState();
  const [applicantId, setApplicantId] = useState();

  const { mutateAsync: moveApplicant, isLoading: loading } =
    useMoveBulkRecruitmentApplicantStage();

  useEffect(() => {
    if (applicant) {
      setStage(applicant.stage);
      setApplicantId(applicant.id);
    }
  }, [applicant]);

  const { data: recruitmentStages } = useGetOnboardingStages(
    applicant?.recruitment_id
  );

  const submitForm = async () => {
    let requestData = {
      stage: stage,
      applicant_ids: applicantId.toString(),
    };

    await moveApplicant(requestData).then((result) => {
      if (result.status === "success") {
        closeModal();
      }
    });
  };

  return (
    <Modal
      scrollable={true}
      className="!max-w-[600px] !rounded-none"
      isOpen={isOpen}
      centered={true}
    >
      <div className="flex flex-col pb-4 !min-h-fit overflow-auto">
        <div className="pl-8 pr-8 pt-4">
          <div className="flex flex-row justify-between items-center">
            <span className="header-4">Advance Applicant</span>
            <div className="cursor-pointer" onClick={() => closeModal()}>
              <img src={CloseSVG} alt="close" />
            </div>
          </div>
        </div>
        <hr className="divider mb-2" />
        <div className="flex flex-col mx-8">
          <>
            <div className="flex flex-col w-full h-fit">
              <div className="flex flex-col border bg-secondary-2-extralight p-2 rounded-md">
                <span className="text-[15px] font-extrabold text-color-gray">
                  Applicant name
                </span>
                <span className="text-[13px] text-color-black-2 font-semibold">
                  {applicant?.first_name} {applicant?.last_name}
                </span>
              </div>
              <hr className="divider mb-4 mt-2" />
              <div>
                {recruitmentStages ? (
                  <FormSelect
                    value={stage}
                    options={recruitmentStages}
                    readOnly={recruitmentStages ? false : true}
                    onChange={(selected) => {
                      setStage(selected);
                    }}
                    label="Select Stage"
                  />
                ) : null}
                <div className="w-full mt-[20px]">
                  <Button
                    text="ADVANCE APPLICANT"
                    type="submit"
                    disabled={stage && applicantId ? false : true}
                    loading={loading}
                    onClick={() => {
                      submitForm();
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </Modal>
  );
}

export default MoveApplicant;
