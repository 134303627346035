import React from 'react';
import { useSelector } from 'react-redux';

function LoadingBar({loading, sidebar=false}) {
  const { section } = useSelector((state) => state.sectionSlice);
  return (
    <>
      {loading ? (
      <div className='loader'>
        <div className='loaderBar' style={sidebar? {background:'#FFFFFF'} : section && section.name === 'core hr'? {background:'#0d5225'}: section && section.name === 'spend management'? {background:'#30015c'}:{}}>
        </div>
      </div>
        ) : null}
    </>
  );
}
export default LoadingBar;
