import { store } from '..';
import { setAlert, setIsLoading } from '../components/components-slice';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../..';
import moment from 'moment';
import { myCabinet } from '../../api/my-cabinet';
import { setEmployeeOnboarding } from '../employees/reducer';
import { formatNumber } from '../../utils/functions';

export function useGetCabinetEmployee(employee_id) {
    return useQuery(
      ['cabinet-employee', { employee_id }],
      () => {
        store.dispatch(setIsLoading(true));
        return myCabinet.getProfile(employee_id);
      },
      {
        enabled: employee_id !== null,
        onSuccess: (response, variables, context) => {
          store.dispatch(setAlert(true, 'success', 'Employee profile fetch successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
      },
    );
}

export function useGetCabinetPayroll(employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
        ['cabinet-payrolls', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
        () => {
            store.dispatch(setIsLoading(true));
            return myCabinet.getPayrolls({
                employee_id,
                limit,
                page,
                statusFilter, 
                search,
                start_date, 
                end_date,
            });
        },
        {
        select(data) {
            data?.data?.forEach((payroll) => {
              payroll.net_pay = parseFloat(payroll.net_pay);
              payroll.created = moment(payroll.created).format('MMMM D, YYYY');
              payroll.formatted_month = moment(payroll.month).format('MMMM, YYYY');
              payroll.employeeName = `${payroll.employee_first_name} ${payroll.employee_last_name}`;
              payroll.computedActions = [0]
            });
    
            return data;
        },
        onSuccess: () => {
            store.dispatch(setAlert(true, 'success', 'Payrolls fetched successfully.'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
        },
    );
}

export function useGetCabinetBonuses(employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
        ['cabinet-bonuses', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
        () => {
            store.dispatch(setIsLoading(true));
            return myCabinet.getBonuses({
                employee_id,
                limit,
                page,
                statusFilter, 
                search, 
                start_date, 
                end_date,
            });
        },
        {
        select(data) {
            data?.data?.forEach((bonus) => {
            bonus.amount = parseFloat(bonus.amount);
            bonus.created = moment(bonus.created).format('MMMM D, YYYY');
            bonus.formatted_month = moment(bonus.month).format('MMMM, YYYY');
            bonus.employeeName = `${bonus.first_name} ${bonus.last_name}`;
            });
    
            return data;
        },
        onSuccess: () => {
            store.dispatch(setAlert(true, 'success', 'Bonuses fetched successfully.'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
        },
    );
}

export function useGetCabinetSalaryAdvances(employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
        ['cabinet-salary-advances', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
        () => {
            store.dispatch(setIsLoading(true));
            return myCabinet.getSalaryAdvances({
                employee_id,
                limit,
                page,
                statusFilter, 
                search, 
                start_date, 
                end_date,
            });
        },
        {
        select(data) {
            data?.data?.forEach((employee) => {
                employee.created = moment(employee.created).format('MMMM D, YYYY');
                employee.formatted_month = moment(employee.month).format('MMMM, YYYY');
                employee.amount = parseFloat(employee.amount);
                employee.employeeName = `${employee.first_name} ${employee.last_name}`;
                employee.status = parseInt(employee.status)
                if (employee.status == 2) {
                  employee.status = 0
                }
                switch (parseInt(employee.status)) {
                  case 0:
                    employee.computedActions = [0, 1];
                    break;
                  default:
                    break;
                }
            });
    
            return data;
        },
        onSuccess: () => {
            store.dispatch(setAlert(true, 'success', 'Salary Advances fetched successfully.'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
        },
    );
}

export function useGetCabinetReimbursements(employee_id, limit, page, statusFilter, search, start_date, end_date,) {
  return useQuery(
    ['cabinet-reimbursements', { employee_id, limit, page, statusFilter, search }],
    () => {
      store.dispatch(setIsLoading(true));
      return myCabinet.getReimbursements({ employee_id, limit, page, statusFilter, search });
    },
    {
      select: (data) => {
        data?.data?.forEach((employee) => {
          employee.expenses_amount = parseFloat(
            employee.expenses_amount,
          );
          employee.formatted_expenses_amount = formatNumber(parseFloat(overtime.expenses_amount.toFixed(2)), 2);
          employee.employeeName = `${employee.first_name} ${employee.last_name}`;
          if (employee.paid_once == 2) {
            employee.paid_once = 0
          }
          employee.status = employee.paid_once

          if (employee.type == 0 || employee.type == 1) {
            employee.typeLabel = 'One Time';
            employee.formatted_month = moment(employee.month).format('MMMM, YYYY');
          } else if (employee.type === 2) {
            employee.typeLabel = 'Recurring';
            employee.month = '-';
            employee.formatted_month = '-';
          }
          //compute action items
          switch (employee.paid_once) {
            case 0:
              employee.computedActions = [0, 1, 4];
              break;
            case 3:
              employee.computedActions = [2, 3, 4];
              break;
            default:
              break;
          }
        });

        return data;
      },
      onSettled: (data, error, variables, context) => {
        store.dispatch(setIsLoading(false));
      },
      // keepPreviousData: true
    },
  );
}

export function useGetCabinetSuspensions(employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
        ['cabinet-suspensions', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
        () => {
            store.dispatch(setIsLoading(true));
            return myCabinet.getSuspensions({
                employee_id,
                limit,
                page,
                statusFilter, 
                search, 
                start_date, 
                end_date,
            });
        },
        {
        select(data) {
            data?.data?.forEach((bonus) => {
            bonus.amount = parseFloat(bonus.amount);
            bonus.created = moment(bonus.created).format('MMMM D, YYYY');
            bonus.formatted_month = moment(bonus.month).format('MMMM, YYYY');
            bonus.employeeName = `${bonus.first_name} ${bonus.last_name}`;
            });
    
            return data;
        },
        onSuccess: () => {
            store.dispatch(setAlert(true, 'success', 'Bonuses fetched successfully.'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
        },
    );
}

export function useGetCabinetQueries(employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
        ['cabinet-queries', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
        () => {
            store.dispatch(setIsLoading(true));
            return myCabinet.getQueries({
                employee_id,
                limit,
                page,
                statusFilter, 
                search, 
                start_date, 
                end_date,
            });
        },
        {
        select(data) {
            data?.data?.forEach((bonus) => {
            bonus.created = moment(bonus.created).format('MMMM D, YYYY');
            bonus.employeeName = `${bonus.first_name} ${bonus.last_name}`;
            });
    
            return data;
        },
        onSuccess: () => {
            store.dispatch(setAlert(true, 'success', 'Queries fetched successfully.'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
        },
    );
}

export function useGetCabinetDocuments (employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
      ['cabinet-documents', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
      () => {
        store.dispatch(setIsLoading(true));
        return myCabinet.getDocuments({
            employee_id, limit, page, statusFilter, search, start_date, end_date,
        });
      },
      {
        select: (data) => {
            data?.data?.forEach((document) => {
                document.computedActions = [0, 1];
                document.employeeName = `${document.first_name} ${document.last_name}`;
                document.created = moment(document?.created).format('MMMM D, YYYY');
            });
  
            return data;
        },
        onSuccess: () => {
            store.dispatch(setAlert(true, 'success', 'Employee documents fetched successfully'));
        },
        onSettled: (data, error, variables, context) => {
            store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetCabinetLeaves (employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
      ['cabinet-leaves', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
      () => {
        store.dispatch(setIsLoading(true));
        return myCabinet.getLeaves({
            employee_id, limit, page, statusFilter, search, start_date, end_date,
        });
      },
      {
        select: (data) => {
            if (data?.data) {
                data?.data?.forEach((leave) => {
                  leave.date_created = leave.created.split('T')[0];
                  leave.status = leave.final_status;
                  leave.period = `${moment(leave.start_date).format('MMMM D, YYYY')} to ${moment(leave.end_date).format('MMMM D, YYYY')}`
                  leave.employeeName = `${leave.first_name} ${leave.last_name}`;
                });
            }
          return data;
        },
        onSuccess: () => {
          store.dispatch(setAlert(true, 'success', 'Leaves fetched successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetCabinetOnboarding(employee_id) {
    return useQuery(
        ['cabinet-onboarding', { employee_id }],
        () => {
            store.dispatch(setIsLoading(true));
            return myCabinet.getOnboarding(employee_id);
        },
        {
            enabled: employee_id !== null,
            onSuccess: (response, variables, context) => {
                store.dispatch(setEmployeeOnboarding(response.data));
                store.dispatch(setAlert(true, 'success', response.message));
            },
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useGetCabinetPerformanceAppraisals (employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
      ['cabinet-performances', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
      () => {
        store.dispatch(setIsLoading(true));
        return myCabinet.getPerformanceAppraisals({
            employee_id, limit, page, statusFilter, search, start_date, end_date,
        });
      },
      {
        select: (data) => {
            if (data?.data) {
                data?.data?.forEach((performance) => {
                    performance.employeeName = `${performance.first_name} ${performance.last_name}`
                    performance.period = `${moment(performance.evaluation_start).format('MMMM D, YYYY')} to ${moment(performance.evaluation_end).format('MMMM D, YYYY')}`
                    performance.created = moment(performance.created).format('MMMM D, YYYY');
                });
            }
          return data;
        },
        onSuccess: () => {
          store.dispatch(setAlert(true, 'success', 'Performance appraisals fetched successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetCabinetGuarantors(employee_id) {
    return useQuery(
      ['cabinet-guarantors', employee_id],
      () => {
        store.dispatch(setIsLoading(true));
        return myCabinet.getGuarantors(employee_id);
      },
      {
        onSuccess: () => {
            store.dispatch(setAlert(true, 'success', 'Guarantors fetched successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetCabinetTrainings (employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
      ['cabinet-trainings', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
      () => {
        store.dispatch(setIsLoading(true));
        return myCabinet.getTrainings({
            employee_id, limit, page, statusFilter, search, start_date, end_date,
        });
      },
      {
        select: (data) => {
            if (data?.data) {
                data?.data?.forEach((employee) => {
                    employee.employeeName = `${employee.first_name} ${employee.last_name}`;
                    employee.created = moment(employee.created).format('MMMM D, YYYY');
                    employee.computedActions = [0, 1];
                });
            }
          return data;
        },
        onSuccess: () => {
            store.dispatch(setAlert(true, 'success', 'Trainings fetched successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetCabinetAttendance (employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
      ['cabinet-attendance', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
      () => {
        store.dispatch(setIsLoading(true));
        return myCabinet.getAttendance({
            employee_id, limit, page, statusFilter, search, start_date, end_date,
        });
      },
      {
        select: (data) => {
            if (data?.data) {
                data?.data?.forEach((attendance) => {
                    
                    attendance.employeeName = `${attendance.first_name} ${attendance.last_name}`;
                    attendance.created = moment(attendance.created).format('MMMM DD, YYYY');
                    attendance.attendance_date = moment(attendance.attendance_date).format('MMMM DD, YYYY');
                    attendance.computedActions = [0, 1, 2];
                    if (!attendance.status) {
                        attendance.status = 0
                    }
                });
            }
          return data;
        },
        onSuccess: () => {
            store.dispatch(setAlert(true, 'success', 'Attendance fetched successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetCabinetHmo(employee_id) {
    return useQuery(
        ['cabinet-hmo', { employee_id }],
        () => {
            store.dispatch(setIsLoading(true));
            return myCabinet.getHmoBeneficiary(employee_id);
        },
        {
            enabled: employee_id !== null,
            onSuccess: () => {
                store.dispatch(setAlert(true, 'success', 'HMO Information fetched successfully'));
            },
            onSettled: (data, error, variables, context) => {
                store.dispatch(setIsLoading(false));
            },
        },
    );
}

export function useGetCabinetTaxes (employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
      ['cabinet-taxes', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
      () => {
        store.dispatch(setIsLoading(true));
        return myCabinet.getTaxPayments({
            employee_id, limit, page, statusFilter, search, start_date, end_date,
        });
      },
      {
        select: (data) => {
            if (data?.data) {
                data?.data?.forEach((leave) => {
                  leave.date_created = leave.created.split('T')[0];
                  leave.status = leave.final_status;
                  leave.period = `${moment(leave.start_date).format('MMMM D, YYYY')} to ${moment(leave.end_date).format('MMMM D, YYYY')}`
                  leave.employeeName = `${leave.first_name} ${leave.last_name}`;
                });
            }
          return data;
        },
        onSuccess: () => {
            store.dispatch(setAlert(true, 'success', 'Trainings fetched successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetCabinetPensions (employee_id, limit, page, statusFilter, search, start_date, end_date,) {
    return useQuery(
      ['cabinet-pensions', { employee_id, limit, page, statusFilter, search, start_date, end_date, }],
      () => {
        store.dispatch(setIsLoading(true));
        return myCabinet.getPensionPayments({
            employee_id, limit, page, statusFilter, search, start_date, end_date,
        });
      },
      {
        select: (data) => {
            if (data?.data) {
                data?.data?.forEach((leave) => {
                  leave.date_created = leave.created.split('T')[0];
                  leave.status = leave.final_status;
                  leave.period = `${moment(leave.start_date).format('MMMM D, YYYY')} to ${moment(leave.end_date).format('MMMM D, YYYY')}`
                  leave.employeeName = `${leave.first_name} ${leave.last_name}`;
                });
            }
          return data;
        },
        onSuccess: () => {
            store.dispatch(setAlert(true, 'success', 'Trainings fetched successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}

export function useGetCabinetPromotions (employee_id, limit, page) {
    return useQuery(
      ['cabinet-promotions', { employee_id, limit, page}],
      () => {
        store.dispatch(setIsLoading(true));
        return myCabinet.getPromotions({
            employee_id, limit, page
        });
      },
      {
        select: (data) => {
            if (data?.data) {
                data?.data?.forEach((promotion) => {
                    promotion.created = moment(promotion.created).format('MMMM D, YYYY');
                    promotion.formatted_effective_date = moment(promotion.effective_date).format('MMMM, YYYY');
                    promotion.employeeName = `${promotion.first_name} ${promotion.last_name}`;
                    promotion.computedActions = [0, 1, 2, 3];
                });
          
            }
          return data;
        },
        onSuccess: () => {
          store.dispatch(setAlert(true, 'success', 'Promotions fetched successfully'));
        },
        onSettled: (data, error, variables, context) => {
          store.dispatch(setIsLoading(false));
        },
        // keepPreviousData: true
      },
    );
}


